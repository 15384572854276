import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-beta',
  templateUrl: './beta.component.html',
  styleUrls: ['./beta.component.css', '../../../app.component.css'],
})
export class BetaComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object
  ) { }

  ngOnInit() { }

  goToBeta() {
    if (isPlatformBrowser(this.platformId)) {
      window.open('https://testflight.apple.com/join/cGrXgV68');
    }
  }
}
