import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { langs } from '../../../utils/langs';
import stringsJson from '../../../../assets/strings/strings.json';
import { stringFormat } from '../../../utils/utils';
import { PurchasesService } from '../purchases.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-city-preview',
  templateUrl: './city-preview.component.html',
  styleUrls: ['./city-preview.component.css', '../../../app.component.css'],
})
export class CityPreviewComponent implements OnInit {
  @Input() city;

  constructor(private route: ActivatedRoute, private purchasesService: PurchasesService, private userService: UserService) { }

  ngOnInit() {
    console.log('preview city', this.city);

  }

}