{
  "cities": [
    {
      "id": 70,
      "name": "Hong Kong",
      "country": "HK",
      "latLng": {
        "lat": 22.3069,
        "lng": 114.1831
      }
    },
    {
      "id": 15,
      "name": "Bangkok",
      "country": "TH",
      "latLng": {
        "lat": 13.75,
        "lng": 100.5167
      }
    },
    {
      "id": 33,
      "name": "London",
      "country": "GB",
      "latLng": {
        "lat": 51.5072,
        "lng": -0.1275
      }
    },
    {
      "id": 1083,
      "name": "Macau",
      "country": "MO",
      "latLng": {
        "lat": 22.203,
        "lng": 113.545
      }
    },
    {
      "id": 135,
      "name": "Singapore",
      "country": "SG",
      "latLng": {
        "lat": 1.3,
        "lng": 103.8
      }
    },
    {
      "id": 34,
      "name": "Paris",
      "country": "FR",
      "latLng": {
        "lat": 48.8566,
        "lng": 2.3522
      }
    },
    {
      "id": 310,
      "name": "Dubai",
      "country": "AE",
      "latLng": {
        "lat": 25.2697,
        "lng": 55.3094
      }
    },
    {
      "id": 50,
      "name": "Kuala Lumpur",
      "country": "MY",
      "latLng": {
        "lat": 3.1478,
        "lng": 101.6953
      }
    },
    {
      "id": 20,
      "name": "Istanbul",
      "country": "TR",
      "latLng": {
        "lat": 41.01,
        "lng": 28.9603
      }
    },
    {
      "id": 2,
      "name": "Delhi",
      "country": "IN",
      "latLng": {
        "lat": 28.6667,
        "lng": 77.2167
      }
    },
    {
      "id": 5472,
      "name": "Antalya",
      "country": "TR",
      "latLng": {
        "lat": 36.9081,
        "lng": 30.6956
      }
    },
    {
      "id": 23,
      "name": "Shenzhen",
      "country": "CN",
      "latLng": {
        "lat": 22.535,
        "lng": 114.054
      }
    },
    {
      "id": 6,
      "name": "Mumbai",
      "country": "IN",
      "latLng": {
        "lat": 19.0758,
        "lng": 72.8775
      }
    },
    {
      "id": 5268,
      "name": "Phuket",
      "country": "TH",
      "latLng": {
        "lat": 7.8881,
        "lng": 98.3975
      }
    },
    {
      "id": 271,
      "name": "Rome",
      "country": "IT",
      "latLng": {
        "lat": 41.8931,
        "lng": 12.4828
      }
    },
    {
      "id": 0,
      "name": "Tokyo",
      "country": "JP",
      "latLng": {
        "lat": 35.6839,
        "lng": 139.7744
      }
    },
    {
      "id": 285,
      "name": "Taipei",
      "country": "TW",
      "latLng": {
        "lat": 25.0478,
        "lng": 121.5319
      }
    },
    {
      "id": 436,
      "name": "Mecca",
      "country": "SA",
      "latLng": {
        "lat": 21.4225,
        "lng": 39.8261
      }
    },
    {
      "id": 9,
      "name": "Guangzhou",
      "country": "CN",
      "latLng": {
        "lat": 23.1288,
        "lng": 113.259
      }
    },
    {
      "id": 581,
      "name": "Medina",
      "country": "SA",
      "latLng": {
        "lat": 24.4667,
        "lng": 39.6
      }
    },
    {
      "id": 5,
      "name": "Seoul",
      "country": "KR",
      "latLng": {
        "lat": 37.56,
        "lng": 126.99
      }
    },
    {
      "id": 708,
      "name": "Amsterdam",
      "country": "NL",
      "latLng": {
        "lat": 52.3667,
        "lng": 4.8833
      }
    },
    {
      "id": 452,
      "name": "Agra",
      "country": "IN",
      "latLng": {
        "lat": 27.1724,
        "lng": 78.0131
      }
    },
    {
      "id": 91,
      "name": "Miami",
      "country": "US",
      "latLng": {
        "lat": 25.7839,
        "lng": -80.2102
      }
    },
    {
      "id": 18,
      "name": "Osaka",
      "country": "JP",
      "latLng": {
        "lat": 34.752,
        "lng": 135.4582
      }
    },
    {
      "id": 7,
      "name": "Shanghai",
      "country": "CN",
      "latLng": {
        "lat": 31.1667,
        "lng": 121.4667
      }
    },
    {
      "id": 25,
      "name": "Ho Chi Minh City",
      "country": "VN",
      "latLng": {
        "lat": 10.8167,
        "lng": 106.6333
      }
    },
    {
      "id": 958,
      "name": "Denpasar",
      "country": "ID",
      "latLng": {
        "lat": -8.6667,
        "lng": 115.2167
      }
    },
    {
      "id": 159,
      "name": "Barcelona",
      "country": "ES",
      "latLng": {
        "lat": 41.3825,
        "lng": 2.1769
      }
    },
    {
      "id": 362,
      "name": "Las Vegas",
      "country": "US",
      "latLng": {
        "lat": 36.2333,
        "lng": -115.2654
      }
    },
    {
      "id": 27,
      "name": "Los Angeles",
      "country": "US",
      "latLng": {
        "lat": 34.1139,
        "lng": -118.4068
      }
    },
    {
      "id": 511,
      "name": "Milan",
      "country": "IT",
      "latLng": {
        "lat": 45.4669,
        "lng": 9.19
      }
    },
    {
      "id": 31,
      "name": "Chennai",
      "country": "IN",
      "latLng": {
        "lat": 13.0825,
        "lng": 80.275
      }
    },
    {
      "id": 392,
      "name": "Vienna",
      "country": "AT",
      "latLng": {
        "lat": 48.2083,
        "lng": 16.3725
      }
    },
    {
      "id": 1418,
      "name": "Johor Bahru",
      "country": "MY",
      "latLng": {
        "lat": 1.4556,
        "lng": 103.7611
      }
    },
    {
      "id": 246,
      "name": "Jaipur",
      "country": "IN",
      "latLng": {
        "lat": 26.9167,
        "lng": 75.8667
      }
    },
    {
      "id": 210,
      "name": "Berlin",
      "country": "DE",
      "latLng": {
        "lat": 52.5167,
        "lng": 13.3833
      }
    },
    {
      "id": 10,
      "name": "Cairo",
      "country": "EG",
      "latLng": {
        "lat": 30.0444,
        "lng": 31.2358
      }
    },
    {
      "id": 409,
      "name": "Orlando",
      "country": "US",
      "latLng": {
        "lat": 28.4772,
        "lng": -81.3369
      }
    },
    {
      "id": 14,
      "name": "Moscow",
      "country": "RU",
      "latLng": {
        "lat": 55.7558,
        "lng": 37.6178
      }
    },
    {
      "id": 2309,
      "name": "Venice",
      "country": "IT",
      "latLng": {
        "lat": 45.4397,
        "lng": 12.3319
      }
    },
    {
      "id": 102,
      "name": "Madrid",
      "country": "ES",
      "latLng": {
        "lat": 40.4167,
        "lng": -3.7167
      }
    },
    {
      "id": 3201,
      "name": "Ha Long",
      "country": "VN",
      "latLng": {
        "lat": 20.9516,
        "lng": 107.0852
      }
    },
    {
      "id": 84,
      "name": "Riyadh",
      "country": "SA",
      "latLng": {
        "lat": 24.65,
        "lng": 46.71
      }
    },
    {
      "id": 731,
      "name": "Dublin",
      "country": "IE",
      "latLng": {
        "lat": 53.3497,
        "lng": -6.2603
      }
    },
    {
      "id": 1710,
      "name": "Florence",
      "country": "IT",
      "latLng": {
        "lat": 43.7714,
        "lng": 11.2542
      }
    },
    {
      "id": 705,
      "name": "Jerusalem",
      "country": "IL",
      "latLng": {
        "lat": 31.7833,
        "lng": 35.2167
      }
    },
    {
      "id": 54,
      "name": "Hanoi",
      "country": "VN",
      "latLng": {
        "lat": 21.0245,
        "lng": 105.8412
      }
    },
    {
      "id": 126,
      "name": "Toronto",
      "country": "CA",
      "latLng": {
        "lat": 43.7417,
        "lng": -79.3733
      }
    },
    {
      "id": 174,
      "name": "Johannesburg",
      "country": "ZA",
      "latLng": {
        "lat": -26.2044,
        "lng": 28.0416
      }
    },
    {
      "id": 155,
      "name": "Sydney",
      "country": "AU",
      "latLng": {
        "lat": -33.865,
        "lng": 151.2094
      }
    },
    {
      "id": 482,
      "name": "Munich",
      "country": "DE",
      "latLng": {
        "lat": 48.1375,
        "lng": 11.575
      }
    },
    {
      "id": 1,
      "name": "Jakarta",
      "country": "ID",
      "latLng": {
        "lat": -6.2146,
        "lng": 106.8451
      }
    },
    {
      "id": 11,
      "name": "Beijing",
      "country": "CN",
      "latLng": {
        "lat": 39.904,
        "lng": 116.4075
      }
    },
    {
      "id": 128,
      "name": "Saint Petersburg",
      "country": "RU",
      "latLng": {
        "lat": 59.95,
        "lng": 30.3167
      }
    },
    {
      "id": 421,
      "name": "Brussels",
      "country": "BE",
      "latLng": {
        "lat": 50.8353,
        "lng": 4.3314
      }
    },
    {
      "id": 424,
      "name": "Budapest",
      "country": "HU",
      "latLng": {
        "lat": 47.4983,
        "lng": 19.0408
      }
    },
    {
      "id": 867,
      "name": "Naples",
      "country": "IT",
      "latLng": {
        "lat": 40.8333,
        "lng": 14.25
      }
    },
    {
      "id": 734,
      "name": "Lisbon",
      "country": "PT",
      "latLng": {
        "lat": 38.708,
        "lng": -9.139
      }
    },
    {
      "id": 493,
      "name": "Kyoto",
      "country": "JP",
      "latLng": {
        "lat": 35.0117,
        "lng": 135.7683
      }
    },
    {
      "id": 397,
      "name": "Zhuhai",
      "country": "CN",
      "latLng": {
        "lat": 22.2769,
        "lng": 113.5678
      }
    },
    {
      "id": 339,
      "name": "Vancouver",
      "country": "CA",
      "latLng": {
        "lat": 49.25,
        "lng": -123.1
      }
    },
    {
      "id": 3911,
      "name": "Chiang Mai",
      "country": "TH",
      "latLng": {
        "lat": 18.7889,
        "lng": 98.9833
      }
    },
    {
      "id": 626,
      "name": "Copenhagen",
      "country": "DK",
      "latLng": {
        "lat": 55.6805,
        "lng": 12.5615
      }
    },
    {
      "id": 8,
      "name": "Mexico City",
      "country": "MX",
      "latLng": {
        "lat": 19.4333,
        "lng": -99.1333
      }
    },
    {
      "id": 214,
      "name": "San Francisco",
      "country": "US",
      "latLng": {
        "lat": 37.7562,
        "lng": -122.443
      }
    },
    {
      "id": 167,
      "name": "Melbourne",
      "country": "AU",
      "latLng": {
        "lat": -37.8136,
        "lng": 144.9631
      }
    },
    {
      "id": 415,
      "name": "Warsaw",
      "country": "PL",
      "latLng": {
        "lat": 52.23,
        "lng": 21.0111
      }
    },
    {
      "id": 13,
      "name": "Kolkata",
      "country": "IN",
      "latLng": {
        "lat": 22.5727,
        "lng": 88.3639
      }
    },
    {
      "id": 872,
      "name": "Cebu City",
      "country": "PH",
      "latLng": {
        "lat": 10.293,
        "lng": 123.902
      }
    },
    {
      "id": 516,
      "name": "Auckland",
      "country": "NZ",
      "latLng": {
        "lat": -36.85,
        "lng": 174.7833
      }
    },
    {
      "id": 143,
      "name": "Guilin",
      "country": "CN",
      "latLng": {
        "lat": 25.2667,
        "lng": 110.2833
      }
    },
    {
      "id": 969,
      "name": "Honolulu",
      "country": "US",
      "latLng": {
        "lat": 21.3294,
        "lng": -157.846
      }
    },
    {
      "id": 17,
      "name": "Buenos Aires",
      "country": "AR",
      "latLng": {
        "lat": -34.5997,
        "lng": -58.3819
      }
    },
    {
      "id": 858,
      "name": "Chiba",
      "country": "JP",
      "latLng": {
        "lat": 35.6073,
        "lng": 140.1064
      }
    },
    {
      "id": 701,
      "name": "Stockholm",
      "country": "SE",
      "latLng": {
        "lat": 59.3294,
        "lng": 18.0686
      }
    },
    {
      "id": 45,
      "name": "Lima",
      "country": "PE",
      "latLng": {
        "lat": -12.06,
        "lng": -77.0375
      }
    },
    {
      "id": 8242,
      "name": "Da Nang",
      "country": "VN",
      "latLng": {
        "lat": 16.0748,
        "lng": 108.224
      }
    },
    {
      "id": 1847,
      "name": "Nice",
      "country": "FR",
      "latLng": {
        "lat": 43.7034,
        "lng": 7.2663
      }
    },
    {
      "id": 337,
      "name": "Fukuoka",
      "country": "JP",
      "latLng": {
        "lat": 33.6,
        "lng": 130.4167
      }
    },
    {
      "id": 487,
      "name": "Abu Dhabi",
      "country": "AE",
      "latLng": {
        "lat": 24.4511,
        "lng": 54.3969
      }
    },
    {
      "id": 1560,
      "name": "Jeju",
      "country": "KR",
      "latLng": {
        "lat": 33.5097,
        "lng": 126.5219
      }
    },
    {
      "id": 2436,
      "name": "Porto",
      "country": "PT",
      "latLng": {
        "lat": 41.1495,
        "lng": -8.6108
      }
    },
    {
      "id": 28,
      "name": "Rio de Janeiro",
      "country": "BR",
      "latLng": {
        "lat": -22.9083,
        "lng": -43.1964
      }
    },
    {
      "id": 6897,
      "name": "Krabi",
      "country": "TH",
      "latLng": {
        "lat": 8.0592,
        "lng": 98.9189
      }
    },
    {
      "id": 217,
      "name": "Montreal",
      "country": "CA",
      "latLng": {
        "lat": 45.5089,
        "lng": -73.5617
      }
    },
    {
      "id": 24,
      "name": "Bangalore",
      "country": "IN",
      "latLng": {
        "lat": 12.9791,
        "lng": 77.5913
      }
    },
    {
      "id": 51,
      "name": "Chicago",
      "country": "US",
      "latLng": {
        "lat": 41.8373,
        "lng": -87.6862
      }
    },
    {
      "id": 29,
      "name": "Chengdu",
      "country": "CN",
      "latLng": {
        "lat": 30.66,
        "lng": 104.0633
      }
    },
    {
      "id": 162,
      "name": "Boston",
      "country": "US",
      "latLng": {
        "lat": 42.3188,
        "lng": -71.0846
      }
    },
    {
      "id": 1444,
      "name": "Edinburgh",
      "country": "GB",
      "latLng": {
        "lat": 55.953,
        "lng": -3.189
      }
    },
    {
      "id": 414,
      "name": "San Jose",
      "country": "US",
      "latLng": {
        "lat": 37.3019,
        "lng": -121.8486
      }
    },
    {
      "id": 26,
      "name": "Tehran",
      "country": "IR",
      "latLng": {
        "lat": 35.7,
        "lng": 51.4167
      }
    },
    {
      "id": 121,
      "name": "Houston",
      "country": "US",
      "latLng": {
        "lat": 29.7863,
        "lng": -95.3889
      }
    },
    {
      "id": 406,
      "name": "Hamburg",
      "country": "DE",
      "latLng": {
        "lat": 53.55,
        "lng": 10.0
      }
    },
    {
      "id": 739,
      "name": "Cape Town",
      "country": "ZA",
      "latLng": {
        "lat": -33.925,
        "lng": 18.425
      }
    },
    {
      "id": 3,
      "name": "Manila",
      "country": "PH",
      "latLng": {
        "lat": 14.6,
        "lng": 120.9833
      }
    },
    {
      "id": 62,
      "name": "Bogota",
      "country": "CO",
      "latLng": {
        "lat": 4.6126,
        "lng": -74.0705
      }
    },
    {
      "id": 81,
      "name": "Xi\u0027an",
      "country": "CN",
      "latLng": {
        "lat": 34.2667,
        "lng": 108.9
      }
    },
    {
      "id": 738,
      "name": "Beirut",
      "country": "LB",
      "latLng": {
        "lat": 33.8869,
        "lng": 35.5131
      }
    },
    {
      "id": 2685,
      "name": "Geneva",
      "country": "CH",
      "latLng": {
        "lat": 46.2,
        "lng": 6.15
      }
    },
    {
      "id": 715,
      "name": "Colombo",
      "country": "LK",
      "latLng": {
        "lat": 6.9167,
        "lng": 79.8333
      }
    },
    {
      "id": 191,
      "name": "Xiamen",
      "country": "CN",
      "latLng": {
        "lat": 24.4797,
        "lng": 118.0819
      }
    },
    {
      "id": 179,
      "name": "Casablanca",
      "country": "MA",
      "latLng": {
        "lat": 33.5992,
        "lng": -7.62
      }
    },
    {
      "id": 398,
      "name": "Bucharest",
      "country": "RO",
      "latLng": {
        "lat": 44.4,
        "lng": 26.0833
      }
    },
    {
      "id": 124,
      "name": "Atlanta",
      "country": "US",
      "latLng": {
        "lat": 33.7627,
        "lng": -84.4224
      }
    },
    {
      "id": 544,
      "name": "Sofia",
      "country": "BG",
      "latLng": {
        "lat": 42.6979,
        "lng": 23.3217
      }
    },
    {
      "id": 169,
      "name": "Dalian",
      "country": "CN",
      "latLng": {
        "lat": 38.9,
        "lng": 121.6
      }
    },
    {
      "id": 195,
      "name": "Amman",
      "country": "JO",
      "latLng": {
        "lat": 31.95,
        "lng": 35.9333
      }
    },
    {
      "id": 527,
      "name": "Montevideo",
      "country": "UY",
      "latLng": {
        "lat": -34.8667,
        "lng": -56.1667
      }
    },
    {
      "id": 60,
      "name": "Pune",
      "country": "IN",
      "latLng": {
        "lat": 18.5196,
        "lng": 73.8553
      }
    },
    {
      "id": 88,
      "name": "Hangzhou",
      "country": "CN",
      "latLng": {
        "lat": 30.25,
        "lng": 120.1675
      }
    },
    {
      "id": 1226,
      "name": "Durban",
      "country": "ZA",
      "latLng": {
        "lat": -29.8583,
        "lng": 31.025
      }
    },
    {
      "id": 108,
      "name": "Dallas",
      "country": "US",
      "latLng": {
        "lat": 32.7936,
        "lng": -96.7662
      }
    },
    {
      "id": 336,
      "name": "Accra",
      "country": "GH",
      "latLng": {
        "lat": 5.6037,
        "lng": -0.187
      }
    },
    {
      "id": 35,
      "name": "Tianjin",
      "country": "CN",
      "latLng": {
        "lat": 39.1467,
        "lng": 117.2056
      }
    },
    {
      "id": 379,
      "name": "Quito",
      "country": "EC",
      "latLng": {
        "lat": -0.22,
        "lng": -78.5125
      }
    },
    {
      "id": 107,
      "name": "Qingdao",
      "country": "CN",
      "latLng": {
        "lat": 36.1167,
        "lng": 120.4
      }
    },
    {
      "id": 19,
      "name": "Lagos",
      "country": "NG",
      "latLng": {
        "lat": 6.45,
        "lng": 3.4
      }
    }
  ]
}
