import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { setSeoText } from 'src/app/utils/utils';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css'],
})
export class PolicyComponent implements OnInit {
  allTours;

  constructor(private route: ActivatedRoute, @Inject(PLATFORM_ID) public platformId: Object, @Inject(DOCUMENT) public _document: Document) { }

  ngOnInit() {
    setSeoText(this._document, 'AllTours - Privacy Policy', 'AllTours privacy policy for using the platform.');
    if (isPlatformBrowser(this.platformId)) {
      this.allTours = window.location.hostname.includes('alltours');
    }
  }
}
