<div style="background: #fff; color: #000a; min-height: 100vh">
  <div class="container">
    <div class="row">
      <nav class="col-12" aria-label="breadcrumb">
        <ol class="breadcrumb" style="background-color: #0000; color: #3b4d00">
          <li class="breadcrumb-item">
            <a class="bread-link" href="">{{ strings.home }}</a>
          </li>
          <li class="breadcrumb-item">
            <a class="bread-link" href="/city">{{ strings.cities }}</a>
          </li>
          <li class="breadcrumb-item">
            <a class="bread-link" href="/city/{{ cityId }}">{{ city.names[language] }}</a>
          </li>
          <li class="breadcrumb-item">
            <a class="bread-link" href="/city/{{ cityId }}/tour/{{ tourId }}">{{ strings.tour }}</a>
          </li>
          <li class="breadcrumb-item" aria-current="page">{{ strings.reviews }}</li>
        </ol>
      </nav>
    </div>
    <div class="row" *ngIf="!loading">
      <div *ngFor="let review of reviews" class="col-12">
        <app-tour-review [review]="review"></app-tour-review>
        <hr />
      </div>
    </div>
    <div class="row" style="height: 90vh; width: 100%" *ngIf="loading">
      <div class="loader" style="position: absolute; top: 50%; right: 50%"></div>
    </div>
  </div>
</div>