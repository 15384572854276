<div style="display: flex">
  <div style="position: absolute; width: 100%">
    <img *ngIf="!loadingCity && isCityPage" style="width: 100%; height: 36vh; object-fit: cover"
      onerror="this.onerror=null; this.src='../../../assets/images/alltours/screenshots/audio.jpg'"
      alt="Cityscape of {{ city.name }}" src="{{ city.image ? city.image.large : '' }}" />
    <img *ngIf="!loadingCity && !isCityPage" style="width: 100%; height: 36vh; object-fit: cover"
      alt="Guy wearing headphones" src="../../../assets/images/alltours/screenshots/audio.jpg" />
    <div *ngIf="loadingCity" style="width: 100%; height: 36vh; object-fit: cover;" class="shimmer">

    </div>
  </div>
  <div class="imgCover" style="height: 36vh"></div>
  <div class="container ontopCover">
    <div class="row">
      <nav class="col-12" aria-label="breadcrumb">
        <ol class="breadcrumb" style="background-color: #0000">
          <li class="breadcrumb-item">
            <a class="bread-link text-capitalize" href="">{{ strings.home }}</a>
          </li>
          <li class="breadcrumb-item">
            <a class="bread-link text-capitalize" href="/tour/{{ countryName.toLowerCase() }}">
              {{ countryName}}
            </a>
          </li>
          <li class="breadcrumb-item text-capitalize" aria-current="page" *ngIf="city != undefined">
            {{ city.names[language] ?? city.name }}
          </li>
        </ol>
      </nav>
    </div>
    <div class="cityLanding row">
      <div>
        <div class="col-12">
          <h1 class="title text-center">{{ title }}</h1>
        </div>
        <div class="col-12">
          <p style="font-size: 16px; font-weight: 500; color: #ffffffcc">{{ subTitle }}</p>
        </div>
        <div *ngIf="!loadingTours && tours.length == 0" class="col-12">
          <button type="button" (click)="goToApp()" class="ctaBtn">
            {{ strings.get_alltours }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div style="background: #fafafa; color: #000000aa">
  <div class="container" style="position: relative">
    <div class="row" *ngIf="loadingTours && isCityPage">
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let number of [0,1,2]">
        <app-tour-preview [tour]="undefined"></app-tour-preview>
      </div>
    </div>
    <div class="row" *ngIf="loadingTours && !isCityPage">
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let number of [0,1,2,3,4,5]">
        <app-tour-preview [tour]="undefined"></app-tour-preview>
      </div>
    </div>
    <div class="row" [@fadeInUp]="loadingTours ? 'out' : 'in'">
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let tour of tours.slice(0,3)">
        <app-tour-preview [tour]="tour"></app-tour-preview>
      </div>
    </div>
    <div class="card" style="padding: 0; margin-top: 32px; margin-bottom: 64px;" *ngIf="isCityPage">
      <div class="row">
        <div class="col-12 col-lg-8">
          <app-landmark-map [landmarks]="cityLandmarks" [city]="city"
            (onLandmarkClicked)="onLoginToMakeTour()"></app-landmark-map>
          <!-- <img
            style="height: 40vh; overflow: hidden; border-radius: 8px; width: 100%; object-fit: cover;"
            src="../../../../assets/images/alltours/map_example.png" alt="Map" /> -->
        </div>
        <div class="col-12 col-lg-4">
          <div class="mapInfoHolder">
            <h5 style="font-size: 18px;">
              Create your own tour in {{ localisedCityName }}
            </h5>
            <div *ngFor="let step of howItWorksCreateTour"
              style="display: flex; padding-top: 12px; padding-bottom: 12px;">
              <span class="material-symbols-rounded icon">{{ step.icon }}</span>
              <div style="margin-left: 12px;">
                <b class="primaryText">{{ step.title }}</b>
                <p class="secondaryText">{{ step.description }}</p>
              </div>
            </div>
            <div style="margin-top: auto; text-align: center;">
              <button type="button" (click)="onLoginToMakeTour()" class="ctaBtnMini"
                style="width: 100%; margin-top: 16px; max-width: 400px;">
                Create a tour
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="tours != undefined && tours.length > 3" [@fadeInUp]="loadingTours ? 'out' : 'in'"
      style="margin-bottom: 64px;">
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let tour of tours.slice(3,12)">
        <app-tour-preview [tour]="tour"></app-tour-preview>
      </div>
    </div>
    <div class="row" style="margin-top: 48px;" *ngIf="!isCityPage">
      <div class="col-12">
        <h3 style="padding-top: 16px; padding-bottom: 16px">Top Cities</h3>
      </div>
      <div *ngFor="let city of countryCities.slice(0, 6)" class="col-6 col-md-4 col-lg-3 col-xl-2">
        <app-city-preview [city]="city"></app-city-preview>
      </div>
      <div class="col-12" *ngIf="countryCities.length > 6">
        <h3 style="padding-top: 16px; padding-bottom: 16px">Other Cities</h3>
      </div>
      <div *ngFor="let city of countryCities.slice(6, countryCities.length)" class="col-lg-2 col-md-3 col-sm-4 col-6">
        <a class="city-name" href="/tour/{{ city.country.toLowerCase() }}/{{ city.name.toLowerCase() }}">
          {{ city.names[language] ?? city.name }}
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="isCityPage">
        <div id="article" class="card" style="min-height:100vh; padding:32px;" [innerHtml]="article">

        </div>
      </div>
      <div class="col-12" style="text-align: center">
        <button type="button" (click)="goToApp()" style="margin: 64px; background:#3B4D00" class="ctaBtnMini">
          {{strings.get_alltours}}
        </button>
      </div>
    </div>
  </div>
</div>
<div [innerHTML]="jsonLD"></div>
<button id="loginButton" type="button" style="display: none;" data-bs-toggle="modal"
  data-bs-target="#loginModalCity"></button>
<button id="downloadAppButton" type="button" style="display: none;" data-bs-toggle="modal"
  data-bs-target="#downloadAppModal"></button>

<div id="loginModalCity" class="modal" tabindex="-1" style="color: #000">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Please login</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="color: #000a">
        <app-user-login (successfulLogin)="onSuccessfulLogin($event)"
          [loginMessage]="'To create a tour, please login first...'">
        </app-user-login>
      </div>
    </div>
  </div>
</div>

<div id="downloadAppModal" class="modal" tabindex="-1" style="color: #000">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">To create your own tour:</h5>
        <button id="closeLoginButton" type="button" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body" style="color: #000a">
        <app-download-app [lastStepToShow]="'createTour'">

        </app-download-app>
      </div>
    </div>
  </div>
</div>