{
  "countries": {
    "al": {
      "title": "Albania Audio Tours: Discover the Unexpected",
      "description": "Explore Albania with self-guided audio tours. Visit Tirana, Berat, and other iconic cities, or create your own custom tour."
    },
    "ad": {
      "title": "Andorra Audio Tours: Explore the Pyrenean Gem",
      "description": "Discover Andorra with self-guided audio tours. Visit Andorra la Vella and more, or create your own mountain escape."
    },
    "at": {
      "title": "Austria Audio Tours: Explore Music & History",
      "description": "Discover Austria’s charm with audio tours. Visit Vienna, Salzburg, and more, or create your own cultural journey."
    },
    "by": {
      "title": "Belarus Audio Tours: Explore History & Nature",
      "description": "Discover Belarus with self-guided audio tours. Explore Minsk, Brest, and more, or create your own journey."
    },
    "be": {
      "title": "Belgium Audio Tours: Explore Art & Culture",
      "description": "Experience Belgium with self-guided audio tours. Discover Brussels, Bruges, and more, or create your own tour."
    },
    "ba": {
      "title": "Bosnia Audio Tours: Discover Scenic Beauty",
      "description": "Explore Bosnia with self-guided audio tours. Visit Sarajevo, Mostar, and more, or create your own cultural tour."
    },
    "bg": {
      "title": "Bulgaria Audio Tours: History and Adventure",
      "description": "Discover Bulgaria with self-guided audio tours. Explore Sofia, Plovdiv, and more, or design your own journey."
    },
    "hr": {
      "title": "Croatia Audio Tours: Discover Adriatic Gems",
      "description": "Explore Croatia with self-guided audio tours. Visit Dubrovnik, Split, and more, or create your own seaside journey."
    },
    "cy": {
      "title": "Cyprus Audio Tours: Island of Legends",
      "description": "Explore Cyprus with self-guided audio tours. Visit Nicosia, Limassol, and more, or create your own Mediterranean journey."
    },
    "cz": {
      "title": "Czechia Audio Tours: Discover Castles & Culture",
      "description": "Discover Czechia with self-guided audio tours. Explore Prague, Brno, and more, or create your own historical tour."
    },
    "dk": {
      "title": "Denmark Audio Tours: Explore Scandinavian Beauty",
      "description": "Discover Denmark with audio tours. Visit Copenhagen, Aarhus, and more, or create your own unique adventure."
    },
    "ee": {
      "title": "Estonia Audio Tours: Discover the Digital Nation",
      "description": "Experience Estonia with self-guided audio tours. Visit Tallinn, Tartu, and more, or create your own exploration."
    },
    "fi": {
      "title": "Finland Audio Tours: Explore Land of Lakes",
      "description": "Discover Finland with audio tours. Visit Helsinki, Lapland, and more, or create your own Nordic adventure."
    },
    "fr": {
      "title": "France Audio Tours: History, Art & Adventure",
      "description": "Explore France with self-guided audio tours. Visit Paris, Bordeaux, and more, or create your own cultural journey."
    },
    "de": {
      "title": "Germany Audio Tours: Discover Culture & History",
      "description": "Experience Germany with audio tours. Visit Berlin, Munich, and more, or create your own custom journey."
    },
    "gr": {
      "title": "Greece Audio Tours: Discover Ancient Wonders",
      "description": "Explore Greece with self-guided audio tours. Visit Athens, Santorini, and more, or create your own adventure."
    },
    "hu": {
      "title": "Hungary Audio Tours: Explore Budapest and Beyond",
      "description": "Discover Hungary with self-guided audio tours. Visit Budapest, Lake Balaton, and more, or create your own journey."
    },
    "is": {
      "title": "Iceland Audio Tours: Explore Land of Fire & Ice",
      "description": "Experience Iceland with audio tours. Discover Reykjavik, natural wonders, and more, or create your own adventure."
    },
    "ie": {
      "title": "Ireland Audio Tours: Discover Celtic Charm",
      "description": "Explore Ireland with self-guided audio tours. Visit Dublin, Galway, and more, or create your own magical journey."
    },
    "it": {
      "title": "Italy Audio Tours: History, Art & Cuisine",
      "description": "Discover Italy with self-guided audio tours. Explore Rome, Venice, and more, or create your own Italian escape."
    },
    "lv": {
      "title": "Latvia Audio Tours: Discover Baltic Beauty",
      "description": "Explore Latvia with self-guided audio tours. Visit Riga, Jurmala, and more, or create your own unique tour."
    },
    "lt": {
      "title": "Lithuania Audio Tours: History & Adventure",
      "description": "Discover Lithuania with audio tours. Visit Vilnius, Kaunas, and more, or create your own cultural journey."
    },
    "lu": {
      "title": "Luxembourg Audio Tours: Explore Small Wonders",
      "description": "Experience Luxembourg with audio tours. Visit Luxembourg City and more, or create your own tour of this charming country."
    },
    "mt": {
      "title": "Malta Audio Tours: Discover Island History",
      "description": "Explore Malta with self-guided audio tours. Visit Valletta, Mdina, and more, or create your own Mediterranean adventure."
    },
    "nl": {
      "title": "Netherlands Audio Tours: Explore Art & Canals",
      "description": "Discover the Netherlands with audio tours. Visit Amsterdam, Utrecht, and more, or create your own journey."
    },
    "no": {
      "title": "Norway Audio Tours: Explore Fjords & Nature",
      "description": "Experience Norway with audio tours. Visit Oslo, Bergen, and more, or create your own Nordic adventure."
    },
    "pl": {
      "title": "Poland Audio Tours: Discover History & Charm",
      "description": "Explore Poland with self-guided audio tours. Visit Warsaw, Krakow, and more, or create your own journey."
    },
    "pt": {
      "title": "Portugal Audio Tours: Discover Coast & Culture",
      "description": "Discover Portugal with self-guided audio tours. Visit Lisbon, Porto, and more, or create your own coastal adventure."
    },
    "ro": {
      "title": "Romania Audio Tours: History & Natural Beauty",
      "description": "Explore Romania with self-guided audio tours. Visit Bucharest, Transylvania, and more, or create your own adventure."
    },
    "sk": {
      "title": "Slovakia Audio Tours: Explore Scenic Beauty",
      "description": "Discover Slovakia with audio tours. Visit Bratislava, the Tatras, and more, or create your own journey."
    },
    "si": {
      "title": "Slovenia Audio Tours: Nature & Culture",
      "description": "Explore Slovenia with self-guided audio tours. Visit Ljubljana, Bled, and more, or create your own adventure."
    },
    "es": {
      "title": "Eswatini Audio Tours: Culture & Nature",
      "description": "Discover Eswatini (Swaziland) with self-guided audio tours. Visit Mbabane, Mlilwane Wildlife Sanctuary, and cultural landmarks, or create your own tour."
    },
    "se": {
      "title": "Sweden Audio Tours: Explore Nordic Beauty",
      "description": "Experience Sweden with audio tours. Visit Stockholm, Gothenburg, and more, or create your own Scandinavian journey."
    },
    "ch": {
      "title": "Switzerland Audio Tours: Nature & History",
      "description": "Discover Switzerland with self-guided audio tours. Visit Zurich, Geneva, and more, or create your own Alpine escape."
    },
    "tr": {
      "title": "Turkey Audio Tours: East Meets West",
      "description": "Explore Turkey with self-guided audio tours. Visit Istanbul, Cappadocia, and more, or create your own unique journey."
    },
    "ua": {
      "title": "Ukraine Audio Tours: Explore Resilience & Culture",
      "description": "Discover Ukraine with self-guided audio tours. Visit Kyiv, Lviv, and more, or create your own meaningful journey."
    },
    "gb": {
      "title": "UK Audio Tours: Discover History & Culture",
      "description": "Explore the UK with self-guided audio tours. Visit London, Edinburgh, and more, or create your own British adventure."
    },
    "af": {
      "title": "Afghanistan Audio Tours: Explore Rich History",
      "description": "Discover Afghanistan with self-guided audio tours. Visit Kabul, Herat, and more, or create your own cultural journey."
    },
    "am": {
      "title": "Armenia Audio Tours: Discover Ancient Landmarks",
      "description": "Explore Armenia with self-guided audio tours. Visit Yerevan, Garni, and more, or create your own historic adventure."
    },
    "az": {
      "title": "Azerbaijan Audio Tours: Land of Fire",
      "description": "Discover Azerbaijan with audio tours. Visit Baku, Gobustan, and more, or create your own journey across this vibrant country."
    },
    "bh": {
      "title": "Bahrain Audio Tours: Explore Gulf Heritage",
      "description": "Discover Bahrain with self-guided audio tours. Visit Manama, Riffa, and more, or create your own Gulf adventure."
    },
    "bd": {
      "title": "Bangladesh Audio Tours: Rivers & Culture",
      "description": "Explore Bangladesh with self-guided audio tours. Visit Dhaka, Sundarbans, and more, or create your own unique journey."
    },
    "bt": {
      "title": "Bhutan Audio Tours: Explore Himalayan Beauty",
      "description": "Discover Bhutan with audio tours. Visit Thimphu, Paro, and more, or create your own adventure in the Himalayas."
    },
    "bn": {
      "title": "Brunei Audio Tours: Discover Serenity",
      "description": "Explore Brunei with self-guided audio tours. Visit Bandar Seri Begawan and more, or create your own journey in this peaceful nation."
    },
    "kh": {
      "title": "Cambodia Audio Tours: Discover Angkor & Beyond",
      "description": "Explore Cambodia with self-guided audio tours. Visit Siem Reap, Phnom Penh, and more, or create your own adventure."
    },
    "cn": {
      "title": "China Audio Tours: Culture, History & Adventure",
      "description": "Discover China with self-guided audio tours. Visit Beijing, Shanghai, and more, or create your own journey across this vast country."
    },
    "ge": {
      "title": "Georgia Audio Tours: Crossroads of Europe & Asia",
      "description": "Discover Georgia with audio tours. Visit Tbilisi, Batumi, and more, or create your own adventure in the Caucasus."
    },
    "in": {
      "title": "India Audio Tours: Culture, History & Diversity",
      "description": "Explore India with self-guided audio tours. Visit Delhi, Jaipur, and more, or create your own unique journey."
    },
    "id": {
      "title": "Indonesia Audio Tours: Islands & Culture",
      "description": "Discover Indonesia with self-guided audio tours. Visit Bali, Jakarta, and more, or create your own island adventure."
    },
    "ir": {
      "title": "Iran Audio Tours: Ancient Persia Awaits",
      "description": "Explore Iran with self-guided audio tours. Visit Tehran, Isfahan, and more, or create your own journey through history."
    },
    "iq": {
      "title": "Iraq Audio Tours: Cradle of Civilization",
      "description": "Discover Iraq with self-guided audio tours. Visit Baghdad, Babylon, and more, or create your own historic journey."
    },
    "il": {
      "title": "Israel Audio Tours: History, Faith & Culture",
      "description": "Explore Israel with self-guided audio tours. Visit Jerusalem, Tel Aviv, and more, or create your own meaningful journey."
    },
    "jp": {
      "title": "Japan Audio Tours: Tradition & Modernity",
      "description": "Discover Japan with self-guided audio tours. Visit Tokyo, Kyoto, and more, or create your own unique journey."
    },
    "jo": {
      "title": "Jordan Audio Tours: Ancient Wonders Await",
      "description": "Explore Jordan with self-guided audio tours. Visit Petra, Amman, and more, or create your own desert adventure."
    },
    "kz": {
      "title": "Kazakhstan Audio Tours: Discover Vast Landscapes",
      "description": "Discover Kazakhstan with audio tours. Visit Almaty, Astana, and more, or create your own adventure across the steppes."
    },
    "kr": {
      "title": "South Korea Audio Tours: Tradition & Tech",
      "description": "Explore South Korea with self-guided audio tours. Visit Seoul, Busan, and more, or create your own journey."
    },
    "kw": {
      "title": "Kuwait Audio Tours: Explore Gulf Heritage",
      "description": "Discover Kuwait with self-guided audio tours. Visit Kuwait City and more, or create your own Gulf journey."
    },
    "kg": {
      "title": "Kyrgyzstan Audio Tours: Mountains & Culture",
      "description": "Explore Kyrgyzstan with self-guided audio tours. Visit Bishkek, Issyk-Kul, and more, or create your own adventure."
    },
    "la": {
      "title": "Laos Audio Tours: Nature & Serenity",
      "description": "Discover Laos with audio tours. Visit Luang Prabang, Vientiane, and more, or create your own journey."
    },
    "lb": {
      "title": "Lebanon Audio Tours: Mediterranean Charm",
      "description": "Explore Lebanon with self-guided audio tours. Visit Beirut, Byblos, and more, or create your own journey."
    },
    "my": {
      "title": "Malaysia Audio Tours: Rainforests & Cities",
      "description": "Discover Malaysia with audio tours. Visit Kuala Lumpur, Penang, and more, or create your own adventure."
    },
    "mv": {
      "title": "Maldives Audio Tours: Island Bliss Awaits",
      "description": "Explore the Maldives with self-guided audio tours. Discover Male and more, or create your own tropical journey."
    },
    "mn": {
      "title": "Mongolia Audio Tours: Steppes & History",
      "description": "Discover Mongolia with self-guided audio tours. Visit Ulaanbaatar, Gobi Desert, and more, or create your own journey."
    },
    "mm": {
      "title": "Myanmar Audio Tours: Temples & Culture",
      "description": "Explore Myanmar with self-guided audio tours. Visit Yangon, Bagan, and more, or create your own adventure."
    },
    "np": {
      "title": "Nepal Audio Tours: Himalayas & Culture",
      "description": "Discover Nepal with self-guided audio tours. Visit Kathmandu, Pokhara, and more, or create your own mountain adventure."
    },
    "om": {
      "title": "Oman Audio Tours: Desert & Coast",
      "description": "Explore Oman with self-guided audio tours. Visit Muscat, Nizwa, and more, or create your own Arabian journey."
    },
    "pk": {
      "title": "Pakistan Audio Tours: Mountains & Heritage",
      "description": "Discover Pakistan with self-guided audio tours. Visit Islamabad, Lahore, and more, or create your own journey."
    },
    "ph": {
      "title": "Philippines Audio Tours: Islands & Adventure",
      "description": "Explore the Philippines with self-guided audio tours. Visit Manila, Cebu, and more, or create your own tropical journey."
    },
    "qa": {
      "title": "Qatar Audio Tours: Culture & Modernity",
      "description": "Discover Qatar with audio tours. Visit Doha, The Pearl, and more, or create your own Gulf adventure."
    },
    "sa": {
      "title": "Saudi Arabia Audio Tours: History & Desert",
      "description": "Explore Saudi Arabia with self-guided audio tours. Visit Riyadh, Jeddah, and more, or create your own Arabian journey."
    },
    "sg": {
      "title": "Singapore Audio Tours: Explore the Lion City",
      "description": "Discover Singapore with audio tours. Explore Marina Bay, Chinatown, and more, or create your own urban journey."
    },
    "lk": {
      "title": "Sri Lanka Audio Tours: History & Beaches",
      "description": "Discover Sri Lanka with audio tours. Visit Colombo, Galle, and more, or create your own tropical adventure."
    },
    "tj": {
      "title": "Tajikistan Audio Tours: Mountains & Culture",
      "description": "Explore Tajikistan with self-guided audio tours. Visit Dushanbe, Pamir Highway, and more, or create your own journey."
    },
    "th": {
      "title": "Thailand Audio Tours: Beaches & Temples",
      "description": "Discover Thailand with self-guided audio tours. Visit Bangkok, Chiang Mai, and more, or create your own adventure."
    },
    "tm": {
      "title": "Turkmenistan Audio Tours: Explore History",
      "description": "Discover Turkmenistan with self-guided audio tours. Visit Ashgabat, Merv, and more, or create your own journey."
    },
    "ae": {
      "title": "UAE Audio Tours: Luxury & Culture",
      "description": "Explore the UAE with audio tours. Visit Dubai, Abu Dhabi, and more, or create your own Gulf journey."
    },
    "uz": {
      "title": "Uzbekistan Audio Tours: Silk Road Adventures",
      "description": "Discover Uzbekistan with self-guided audio tours. Visit Samarkand, Bukhara, and more, or create your own historic journey."
    },
    "vn": {
      "title": "Vietnam Audio Tours: History & Scenery",
      "description": "Explore Vietnam with self-guided audio tours. Visit Hanoi, Ho Chi Minh City, and more, or create your own adventure."
    },
    "ye": {
      "title": "Yemen Audio Tours: Explore Ancient Arabia",
      "description": "Discover Yemen with self-guided audio tours. Visit Sana'a, Socotra, and more, or create your own unique journey."
    },
    "ag": {
      "title": "Antigua & Barbuda Audio Tours: Island Paradise",
      "description": "Explore Antigua & Barbuda with self-guided audio tours. Discover St. John's, beaches, and more, or create your own Caribbean journey."
    },
    "bs": {
      "title": "Bahamas Audio Tours: Tropical Bliss",
      "description": "Discover the Bahamas with self-guided audio tours. Visit Nassau, Grand Bahama, and more, or create your own island adventure."
    },
    "bb": {
      "title": "Barbados Audio Tours: Island Life Awaits",
      "description": "Explore Barbados with self-guided audio tours. Visit Bridgetown, beaches, and more, or create your own tropical journey."
    },
    "bz": {
      "title": "Belize Audio Tours: History & Nature",
      "description": "Explore Belize with self-guided audio tours. Visit Mayan ruins, rainforests, and coastal towns, or create your own journey through this tropical paradise."
    },
    "ca": {
      "title": "Canada Audio Tours: Nature & Cities",
      "description": "Explore Canada with self-guided audio tours. Visit Toronto, Vancouver, and more, or create your own scenic adventure."
    },
    "cr": {
      "title": "Costa Rica Audio Tours: Adventure & Wildlife",
      "description": "Discover Costa Rica with self-guided audio tours. Visit San José, Arenal, and more, or create your own eco-tour in this lush destination."
    },
    "cu": {
      "title": "Cuba Audio Tours: History & Vibrance",
      "description": "Explore Cuba with self-guided audio tours. Visit Havana, Santiago de Cuba, and more, or create your own Cuban journey."
    },
    "dm": {
      "title": "Dominica Audio Tours: Nature's Paradise",
      "description": "Discover Dominica with self-guided audio tours. Visit Roseau, rainforests, and more, or create your own island adventure."
    },
    "do": {
      "title": "Dominican Republic Audio Tours: Beaches & Culture",
      "description": "Explore the Dominican Republic with audio tours. Visit Santo Domingo, Punta Cana, and more, or create your own tropical escape."
    },
    "sv": {
      "title": "El Salvador Audio Tours: Culture & Beaches",
      "description": "Explore El Salvador with self-guided audio tours. Visit San Salvador, volcanoes, and coastal gems, or create your own journey through this vibrant nation."
    },
    "gd": {
      "title": "Grenada Audio Tours: Explore the Spice Isle",
      "description": "Explore Grenada with self-guided audio tours. Visit St. George's, beaches, and more, or create your own Caribbean adventure."
    },
    "gt": {
      "title": "Guatemala Audio Tours: Mayan Heritage",
      "description": "Discover Guatemala with self-guided audio tours. Visit Antigua, Tikal, and more, or create your own journey through this historic country."
    },
    "ht": {
      "title": "Haiti Audio Tours: Vibrant Culture & History",
      "description": "Explore Haiti with self-guided audio tours. Visit Port-au-Prince, Jacmel, and more, or create your own unique adventure."
    },
    "hn": {
      "title": "Honduras Audio Tours: Culture & Coastlines",
      "description": "Explore Honduras with self-guided audio tours. Visit Tegucigalpa, Copán, and more, or create your own adventure in this diverse nation."
    },
    "jm": {
      "title": "Jamaica Audio Tours: Music, Beaches & Culture",
      "description": "Explore Jamaica with self-guided audio tours. Visit Kingston, Montego Bay, and more, or create your own island journey."
    },
    "mx": {
      "title": "Mexico Audio Tours: History & Adventure",
      "description": "Discover Mexico with self-guided audio tours. Visit Mexico City, Cancún, and more, or create your own vibrant journey."
    },
    "ni": {
      "title": "Nicaragua Audio Tours: Lakes & Volcanoes",
      "description": "Discover Nicaragua with self-guided audio tours. Visit Managua, Granada, and more, or create your own journey in this scenic destination."
    },
    "pa": {
      "title": "Panama Audio Tours: Canal & Beyond",
      "description": "Explore Panama with self-guided audio tours. Visit Panama City, beaches, and rainforests, or create your own adventure in this unique country."
    },
    "kn": {
      "title": "St. Kitts & Nevis Audio Tours: Twin Island Paradise",
      "description": "Explore St. Kitts & Nevis with self-guided audio tours. Visit Basseterre, beaches, and more, or create your own Caribbean escape."
    },
    "lc": {
      "title": "St. Lucia Audio Tours: Explore Island Beauty",
      "description": "Discover St. Lucia with self-guided audio tours. Visit Castries, the Pitons, and more, or create your own tropical adventure."
    },
    "vc": {
      "title": "St. Vincent Audio Tours: Untouched Caribbean",
      "description": "Explore St. Vincent with self-guided audio tours. Visit Kingstown, beaches, and more, or create your own serene journey."
    },
    "tt": {
      "title": "Trinidad & Tobago Audio Tours: Culture & Beaches",
      "description": "Discover Trinidad & Tobago with audio tours. Visit Port of Spain, beaches, and more, or create your own island adventure."
    },
    "us": {
      "title": "USA Audio Tours: Explore Iconic Destinations",
      "description": "Explore the USA with self-guided audio tours. Visit New York, Los Angeles, and more, or create your own journey."
    },
    "ar": {
      "title": "Argentina Audio Tours: Culture & Scenery",
      "description": "Discover Argentina with self-guided audio tours. Visit Buenos Aires, Patagonia, and more, or create your own journey through this stunning country."
    },
    "bo": {
      "title": "Bolivia Audio Tours: Nature & History",
      "description": "Explore Bolivia with self-guided audio tours. Visit La Paz, Uyuni, and more, or create your own adventure in this diverse country."
    },
    "br": {
      "title": "Brazil Audio Tours: Beaches & Vibrance",
      "description": "Discover Brazil with self-guided audio tours. Visit Rio de Janeiro, São Paulo, and more, or create your own journey through Brazil's wonders."
    },
    "cl": {
      "title": "Chile Audio Tours: Mountains & Culture",
      "description": "Explore Chile with self-guided audio tours. Visit Santiago, Patagonia, and more, or create your own journey through Chile’s landscapes."
    },
    "co": {
      "title": "Colombia Audio Tours: Culture & Nature",
      "description": "Discover Colombia with self-guided audio tours. Visit Bogotá, Cartagena, and more, or create your own adventure through vibrant Colombia."
    },
    "ec": {
      "title": "Ecuador Audio Tours: Andes & Galapagos",
      "description": "Explore Ecuador with self-guided audio tours. Visit Quito, the Galapagos Islands, and more, or create your own journey in this unique country."
    },
    "gy": {
      "title": "Guyana Audio Tours: Nature & Adventure",
      "description": "Discover Guyana with self-guided audio tours. Visit Georgetown, rainforests, and more, or create your own eco-tour in this beautiful nation."
    },
    "py": {
      "title": "Paraguay Audio Tours: Culture & Tranquility",
      "description": "Explore Paraguay with self-guided audio tours. Visit Asunción, natural parks, and more, or create your own journey in this peaceful country."
    },
    "pe": {
      "title": "Peru Audio Tours: History & Landscapes",
      "description": "Discover Peru with self-guided audio tours. Visit Lima, Machu Picchu, and more, or create your own adventure in the land of the Incas."
    },
    "sr": {
      "title": "Suriname Audio Tours: Hidden Gem of South America",
      "description": "Explore Suriname with self-guided audio tours. Visit Paramaribo, rainforests, and more, or create your own journey in this diverse nation."
    },
    "uy": {
      "title": "Uruguay Audio Tours: Culture & Coastline",
      "description": "Discover Uruguay with self-guided audio tours. Visit Montevideo, Punta del Este, and more, or create your own relaxing journey."
    },
    "ve": {
      "title": "Venezuela Audio Tours: Nature & Heritage",
      "description": "Explore Venezuela with self-guided audio tours. Visit Caracas, Angel Falls, and more, or create your own adventure in this stunning country."
    },
    "au": {
      "title": "Australia Audio Tours: Discover the Land Down Under",
      "description": "Explore Australia with self-guided audio tours. Visit Sydney, Melbourne, the Great Barrier Reef, and more, or create your own tour through this vast land."
    },
    "fj": {
      "title": "Fiji Audio Tours: Islands & Culture",
      "description": "Discover Fiji with self-guided audio tours. Explore tropical islands, beaches, and cultural sites, or create your own personalized adventure."
    },
    "ki": {
      "title": "Kiribati Audio Tours: Remote Islands & Traditions",
      "description": "Explore Kiribati with self-guided audio tours. Discover its remote islands, unique culture, and breathtaking scenery, or create your own journey."
    },
    "mh": {
      "title": "Marshall Islands Audio Tours: Island Life & History",
      "description": "Explore the Marshall Islands with self-guided audio tours. Visit Majuro, Bikini Atoll, and more, or create your own tour of this Pacific paradise."
    },
    "mp": {
      "title": "Northern Mariana Islands Audio Tours: Pacific Paradise",
      "description": "Discover the Northern Mariana Islands with self-guided audio tours. Explore Saipan, Tinian, and more, or create your own tour in this tropical haven."
    },
    "nz": {
      "title": "New Zealand Audio Tours: Nature & Culture",
      "description": "Explore New Zealand with self-guided audio tours. Visit Auckland, Rotorua, Queenstown, and more, or create your own journey in this scenic country."
    },
    "pg": {
      "title": "Papua New Guinea Audio Tours: Culture & Nature",
      "description": "Discover Papua New Guinea with self-guided audio tours. Visit Port Moresby, rainforests, and indigenous villages, or create your own unique tour."
    },
    "pw": {
      "title": "Palau Audio Tours: Islands & Marine Life",
      "description": "Explore Palau with self-guided audio tours. Discover stunning beaches, marine life, and local culture, or create your own personalized adventure."
    },
    "ws": {
      "title": "Samoa Audio Tours: Culture & Coastlines",
      "description": "Explore Samoa with self-guided audio tours. Discover Upolu, Savai'i, and more, or create your own journey through this beautiful island nation."
    },
    "to": {
      "title": "Tonga Audio Tours: Islands & Traditions",
      "description": "Discover Tonga with self-guided audio tours. Visit Nuku'alofa, beautiful beaches, and more, or create your own adventure in this Pacific paradise."
    },
    "tv": {
      "title": "Tuvalu Audio Tours: Remote Islands & Marine Life",
      "description": "Explore Tuvalu with self-guided audio tours. Discover its islands, beaches, and coral reefs, or create your own adventure in this remote paradise."
    },
    "vu": {
      "title": "Vanuatu Audio Tours: Nature & Culture",
      "description": "Discover Vanuatu with self-guided audio tours. Explore Port Vila, volcanoes, and traditional villages, or create your own personalized journey."
    },
    "dz": {
      "title": "Algeria Audio Tours: Culture & Desert Adventures",
      "description": "Explore Algeria with self-guided audio tours. Visit Algiers, the Sahara Desert, and ancient ruins, or create your own personalized adventure."
    },
    "ao": {
      "title": "Angola Audio Tours: Nature & Culture",
      "description": "Discover Angola with self-guided audio tours. Explore Luanda, Luena, and beautiful landscapes, or create your own adventure through this diverse country."
    },
    "bj": {
      "title": "Benin Audio Tours: History & Culture",
      "description": "Explore Benin with self-guided audio tours. Visit Ouidah, Cotonou, and historical sites, or create your own journey through this West African gem."
    },
    "bw": {
      "title": "Botswana Audio Tours: Safari & Culture",
      "description": "Discover Botswana with self-guided audio tours. Visit the Okavango Delta, Chobe National Park, and more, or create your own safari adventure."
    },
    "bf": {
      "title": "Burkina Faso Audio Tours: Culture & Heritage",
      "description": "Explore Burkina Faso with self-guided audio tours. Visit Ouagadougou, historical sites, and natural wonders, or create your own tour of this West African country."
    },
    "bi": {
      "title": "Burundi Audio Tours: Nature & Culture",
      "description": "Discover Burundi with self-guided audio tours. Visit Bujumbura, Lake Tanganyika, and cultural landmarks, or create your own adventure in this East African nation."
    },
    "cm": {
      "title": "Cameroon Audio Tours: Culture & Nature",
      "description": "Explore Cameroon with self-guided audio tours. Visit Yaoundé, Mount Cameroon, and diverse landscapes, or create your own personalized tour."
    },
    "cv": {
      "title": "Cape Verde Audio Tours: Islands & Culture",
      "description": "Discover Cape Verde with self-guided audio tours. Visit Mindelo, Santiago, and tropical islands, or create your own adventure in this archipelago."
    },
    "cf": {
      "title": "Central African Republic Audio Tours: Nature & Culture",
      "description": "Explore the Central African Republic with self-guided audio tours. Discover Bangui, wildlife reserves, and natural landscapes, or create your own tour."
    },
    "td": {
      "title": "Chad Audio Tours: Desert & Wildlife",
      "description": "Explore Chad with self-guided audio tours. Visit N'Djamena, Zakouma National Park, and the Sahara Desert, or create your own adventure in this diverse country."
    },
    "km": {
      "title": "Comoros Audio Tours: Islands & Culture",
      "description": "Discover Comoros with self-guided audio tours. Explore Moroni, beautiful beaches, and lush landscapes, or create your own personalized adventure."
    },
    "cg": {
      "title": "Congo Audio Tours: Culture & Nature",
      "description": "Explore Congo with self-guided audio tours. Visit Brazzaville, Ogooué River, and lush forests, or create your own journey in this Central African country."
    },
    "cd": {
      "title": "Democratic Republic of the Congo Audio Tours: Adventure & Culture",
      "description": "Discover the Democratic Republic of the Congo with self-guided audio tours. Visit Kinshasa, wildlife reserves, and scenic landscapes, or create your own adventure."
    },
    "dj": {
      "title": "Djibouti Audio Tours: Deserts & Marine Life",
      "description": "Explore Djibouti with self-guided audio tours. Discover Lake Assal, the Red Sea coast, and local culture, or create your own tour in this unique country."
    },
    "eg": {
      "title": "Egypt Audio Tours: Ancient Wonders & Culture",
      "description": "Explore Egypt with self-guided audio tours. Visit the Pyramids, Cairo, Luxor, and the Nile, or create your own tour of this ancient land."
    },
    "gq": {
      "title": "Equatorial Guinea Audio Tours: Islands & Culture",
      "description": "Discover Equatorial Guinea with self-guided audio tours. Visit Malabo, Bioko Island, and local attractions, or create your own adventure in this island nation."
    },
    "er": {
      "title": "Eritrea Audio Tours: Culture & History",
      "description": "Explore Eritrea with self-guided audio tours. Visit Asmara, Massawa, and historical landmarks, or create your own journey through this East African country."
    },
    "et": {
      "title": "Ethiopia Audio Tours: History & Culture",
      "description": "Explore Ethiopia with self-guided audio tours. Visit Addis Ababa, Lalibela, and historical sites, or create your own journey through this ancient land."
    },
    "ga": {
      "title": "Gabon Audio Tours: Wildlife & Nature",
      "description": "Discover Gabon with self-guided audio tours. Explore Libreville, Loango National Park, and lush rainforests, or create your own adventure."
    },
    "gm": {
      "title": "Gambia Audio Tours: Culture & Nature",
      "description": "Explore Gambia with self-guided audio tours. Visit Banjul, the River Gambia National Park, and cultural landmarks, or create your own adventure."
    },
    "gh": {
      "title": "Ghana Audio Tours: Culture & History",
      "description": "Discover Ghana with self-guided audio tours. Visit Accra, Cape Coast Castle, and cultural landmarks, or create your own journey through this vibrant country."
    },
    "gn": {
      "title": "Guinea Audio Tours: Culture & Nature",
      "description": "Explore Guinea with self-guided audio tours. Visit Conakry, Fouta Djallon, and scenic landscapes, or create your own tour in this West African country."
    },
    "gw": {
      "title": "Guinea-Bissau Audio Tours: Islands & Culture",
      "description": "Discover Guinea-Bissau with self-guided audio tours. Explore Bissau, the Bijagos Islands, and local culture, or create your own adventure in this West African gem."
    },
    "ke": {
      "title": "Kenya Audio Tours: Safaris & Culture",
      "description": "Explore Kenya with self-guided audio tours. Visit Nairobi, Maasai Mara, and diverse wildlife, or create your own safari adventure in this East African country."
    },
    "lr": {
      "title": "Liberia Audio Tours: Culture & History",
      "description": "Discover Liberia with self-guided audio tours. Visit Monrovia, Sapo National Park, and cultural landmarks, or create your own adventure through this West African nation."
    },
    "ly": {
      "title": "Libya Audio Tours: Ancient Ruins & Desert",
      "description": "Explore Libya with self-guided audio tours. Visit Tripoli, the ancient ruins of Sabratha, and the Sahara Desert, or create your own adventure."
    },
    "ma": {
      "title": "Morocco Audio Tours: Culture & History",
      "description": "Discover Morocco with self-guided audio tours. Visit Marrakesh, Fez, and historical sites, or create your own journey through this North African country."
    },
    "mz": {
      "title": "Mozambique Audio Tours: Beaches & Culture",
      "description": "Explore Mozambique with self-guided audio tours. Visit Maputo, Bazaruto Archipelago, and local culture, or create your own adventure."
    },
    "na": {
      "title": "Namibia Audio Tours: Deserts & Wildlife",
      "description": "Discover Namibia with self-guided audio tours. Visit Windhoek, the Namib Desert, and Etosha National Park, or create your own safari adventure."
    },
    "ne": {
      "title": "Niger Audio Tours: Desert & Culture",
      "description": "Explore Niger with self-guided audio tours. Visit Niamey, the Sahara Desert, and cultural landmarks, or create your own journey in this West African country."
    },
    "ng": {
      "title": "Nigeria Audio Tours: Culture & History",
      "description": "Discover Nigeria with self-guided audio tours. Visit Lagos, Abuja, and historical landmarks, or create your own adventure through this West African giant."
    },
    "re": {
      "title": "Réunion Audio Tours: Islands & Nature",
      "description": "Explore Réunion with self-guided audio tours. Discover beautiful landscapes, volcanic mountains, and local culture, or create your own adventure."
    },
    "rw": {
      "title": "Rwanda Audio Tours: Wildlife & Culture",
      "description": "Explore Rwanda with self-guided audio tours. Visit Kigali, Volcanoes National Park, and cultural landmarks, or create your own safari adventure."
    },
    "st": {
      "title": "São Tomé and Príncipe Audio Tours: Islands & Culture",
      "description": "Discover São Tomé and Príncipe with self-guided audio tours. Visit São Tomé, Príncipe, and tropical landscapes, or create your own adventure."
    },
    "sc": {
      "title": "Seychelles Audio Tours: Islands & Marine Life",
      "description": "Explore Seychelles with self-guided audio tours. Visit Mahé, Praslin, and stunning beaches, or create your own adventure in this tropical paradise."
    },
    "sl": {
      "title": "Sierra Leone Audio Tours: History & Culture",
      "description": "Discover Sierra Leone with self-guided audio tours. Visit Freetown, historical sites, and local culture, or create your own personalized tour."
    },
    "so": {
      "title": "Somalia Audio Tours: Culture & Coastlines",
      "description": "Explore Somalia with self-guided audio tours. Discover Mogadishu, the coast, and cultural landmarks, or create your own journey through this East African nation."
    },
    "za": {
      "title": "South Africa Audio Tours: Safaris & Culture",
      "description": "Discover South Africa with self-guided audio tours. Visit Cape Town, Kruger Park, and cultural landmarks, or create your own safari adventure."
    },
    "ss": {
      "title": "South Sudan Audio Tours: Nature & History",
      "description": "Explore South Sudan with self-guided audio tours. Visit Juba, wildlife reserves, and historical landmarks, or create your own adventure."
    },
    "sd": {
      "title": "Sudan Audio Tours: Desert & Ancient Sites",
      "description": "Discover Sudan with self-guided audio tours. Explore Khartoum, the Pyramids of Meroë, and the Sahara Desert, or create your own adventure."
    },
    "tg": {
      "title": "Togo Audio Tours: Culture & History",
      "description": "Explore Togo with self-guided audio tours. Visit Lomé, historical landmarks, and cultural sites, or create your own personalized journey."
    },
    "tn": {
      "title": "Tunisia Audio Tours: Ancient Ruins & Culture",
      "description": "Discover Tunisia with self-guided audio tours. Visit Tunis, Carthage, and ancient ruins, or create your own adventure through this North African country."
    },
    "ug": {
      "title": "Uganda Audio Tours: Safaris & Culture",
      "description": "Explore Uganda with self-guided audio tours. Visit Kampala, Bwindi Impenetrable Forest, and wildlife reserves, or create your own safari adventure."
    },
    "zm": {
      "title": "Zambia Audio Tours: Wildlife & Culture",
      "description": "Discover Zambia with self-guided audio tours. Visit Lusaka, Victoria Falls, and wildlife parks, or create your own safari adventure."
    },
    "zw": {
      "title": "Zimbabwe Audio Tours: History & Wildlife",
      "description": "Explore Zimbabwe with self-guided audio tours. Visit Harare, Victoria Falls, and Hwange National Park, or create your own tour of this southern African nation."
    }
  },
  "cities": {
    "subTitles": [
      "Explore City Landmarks",
      "Discover Historic City Tours",
      "City Sightseeing Adventures",
      "Uncover Local City Gems",
      "Guided City Walking Tour",
      "City Tour Experience",
      "Explore Hidden City Spots",
      "Self-Guided City Journey",
      "Your City Adventure Awaits",
      "Personalized City Tour",
      "Discover Famous City Sights",
      "City Landmarks Walkthrough",
      "Explore the Best of the City",
      "City Exploration Made Easy",
      "Walking Tour of the City",
      "Unveil the City's Secrets",
      "Navigate City Highlights",
      "Journey Through the City",
      "Discover Your City Story",
      "City Explorer's Guide",
      "Walk the City Streets",
      "Step into the City's History",
      "Historic City Tour",
      "Tour the City's Wonders",
      "Unlock City Hidden Treasures",
      "City Walking Adventure",
      "Explore the City's Past",
      "Follow the City Trails",
      "The Best City Experience",
      "Explore Famous City Landmarks",
      "Your Self-Guided City Tour",
      "Tour the City at Your Pace",
      "Discover City Wonders",
      "Discover the Heart of the City",
      "Your City Journey Begins",
      "Walking Through City History",
      "Explore the City's Best Kept Secrets",
      "City Tour at Your Own Pace",
      "Personalized City Exploration",
      "Uncover the City's Stories",
      "Experience City Culture",
      "City's Must-See Highlights",
      "Explore the City's Legacy",
      "Journey Through Historic Streets",
      "Discover the City's Charm",
      "Explore City Hotspots",
      "Your Local City Experience",
      "Step into the City's Culture",
      "Tour the City's Icons",
      "Wander the City Streets",
      "Your Ultimate City Tour",
      "Discover City Attractions",
      "Unveil the City’s Wonders",
      "Historic Streets of the City",
      "Explore Hidden City Highlights",
      "Experience City Landmarks",
      "Your Personal City Walk",
      "Explore the City’s Mysteries",
      "Self-Guided City Adventure",
      "Tour the Best City Views",
      "Find the City’s Treasures",
      "Discover Local City Culture",
      "Uncover City Heritage",
      "City Exploration on Foot",
      "Walk Through City Legends",
      "City Secrets Revealed",
      "Uncover City History",
      "Tour the City's Heart",
      "Explore Iconic City Views",
      "Discover the City’s Roots",
      "Walk the Historic Pathways",
      "City Storytelling Experience",
      "Walk Through City Landmarks",
      "Discover the City’s Essence",
      "Explore the City’s Old World",
      "City Heritage Trail",
      "Self-Guided Historical Tour",
      "Explore the Hidden Corners",
      "Step Through Time in the City",
      "City Adventure Starts Here",
      "Discover the City’s Past",
      "Historic City Walking Tour",
      "Explore City Legends",
      "Walk Through the Ages",
      "City’s Rich History Revealed",
      "A Journey Through City Streets",
      "Explore the City Like a Local",
      "Discover the City's Soul",
      "Tour the City's Most Famous Spots",
      "Uncover the City's Art and Culture",
      "Step into the City’s History",
      "Experience the City’s Evolution",
      "Navigate the City’s Rich Heritage",
      "Uncover the City’s Legacy",
      "Explore the City’s Hidden Spots",
      "Your City, Your Tour",
      "Discover the City on Foot",
      "Self-Guided Journey Through the City",
      "Experience the City’s Vibrancy",
      "Tour the City’s Greatest Landmarks",
      "City Tour Like Never Before",
      "Explore the City’s Secrets",
      "Walk Through City Moments",
      "Discover the City's Treasures",
      "Explore the City Beyond the Tourist Spots",
      "The Ultimate City Exploration",
      "Walk Through the City’s Beauty",
      "Tour the City’s Rich Culture",
      "Explore the City’s Iconic Views",
      "Explore Hidden City Paths",
      "Discover the City’s Timeless Beauty",
      "Uncover the City’s Cultural Heart"
    ],
    "descriptions": [
      "Create a custom %s self-guided audio tour! Our easy-to-use platform lets you design a personalized audio tour in just 2 minutes—no tech skills required.",
      "Design your own %s self-guided audio tour! Personalize your experience and explore the city's best spots with just a few clicks.",
      "Create a personalized %s audio tour in minutes! No technical skills needed—just choose your landmarks and start exploring.",
      "Build a custom %s audio tour for your next adventure! Create a self-guided experience and discover the city's highlights at your pace.",
      "Design your own %s self-guided audio tour today! Easy and quick—start your tour in just 2 minutes.",
      "Create a personalized %s audio journey! Discover the city's iconic landmarks with a tour that’s made just for you.",
      "Explore %s like never before with a custom audio tour! Easily create a personalized experience and start your adventure now.",
      "Build a self-guided %s audio tour today! Personalize your route, pick your stops, and experience the city your way.",
      "Design your own %s tour with ease! Quickly create a self-guided audio tour to explore the city's best landmarks.",
      "Create a custom %s audio tour in just minutes! Personalize your route and explore the city’s highlights at your own pace.",
      "Unlock the best of %s with a custom audio tour! Create a personalized experience and start exploring.",
      "Create your own %s self-guided audio tour! Tailor your route and explore the city like never before.",
      "Design a personalized %s audio adventure! Create a self-guided tour with your chosen landmarks in minutes.",
      "Explore %s in your own way! Create a custom self-guided audio tour and discover the city's hidden gems.",
      "Build your own %s audio tour today! Personalize your route and explore the city's landmarks at your own pace.",
      "Create your personalized %s self-guided audio tour! Discover the city’s highlights in minutes, no tech experience required.",
      "Design your %s self-guided audio tour! Personalize your journey with our easy-to-use platform and start exploring.",
      "Create a custom %s audio tour today! Choose your stops and explore the city with a self-guided experience.",
      "Create your personalized %s audio journey! Explore the city's landmarks in just minutes with our easy-to-use platform.",
      "Build your own %s self-guided audio tour! Discover the city’s best landmarks at your pace with a custom experience.",
      "Design a personalized %s tour in minutes! Choose your route, landmarks, and create a unique self-guided experience.",
      "Create your custom %s audio tour! Personalize your experience with landmarks and start your journey today.",
      "Build a self-guided %s tour! Personalize your route and enjoy the city's highlights at your own pace.",
      "Create a %s self-guided audio tour tailored to you! Choose your favorite landmarks and explore the city in minutes.",
      "Discover the best of %s with a custom audio tour! Build your personalized self-guided journey in minutes.",
      "Design your own %s audio experience today! Choose the landmarks you want to visit and start your journey.",
      "Create a %s self-guided tour and explore the city at your own pace! Personalize your audio journey in minutes.",
      "Build your own %s audio tour! Customize your experience and explore the city's top landmarks with ease.",
      "Create a personalized %s self-guided journey today! Discover the city’s best spots at your own pace.",
      "Design a custom %s audio tour and explore the city like a local! Choose your landmarks and start your adventure.",
      "Create your personalized %s tour! Discover the city's best highlights with a self-guided audio tour in minutes.",
      "Unlock the best of %s with a custom self-guided audio tour! Create a personalized experience and start exploring.",
      "Design a personalized %s self-guided audio adventure! Choose your route and landmarks in just a few minutes.",
      "Create your own %s audio tour now! Personalize your journey and explore the city’s top landmarks at your own pace.",
      "Design your personalized %s self-guided audio journey! Explore the city's best spots with ease in just a few clicks.",
      "Create a %s audio tour tailored to you! Explore the city's most iconic landmarks at your own pace.",
      "Build a custom %s self-guided audio tour today! Personalize your experience and enjoy exploring the city.",
      "Create your custom %s audio journey! Personalize the route and landmarks for a one-of-a-kind city tour.",
      "Design your own %s audio experience! Choose your landmarks and explore the city with a personalized tour.",
      "Build your personalized %s self-guided audio tour! Explore the city at your pace with a tailored experience.",
      "Create a %s audio tour designed just for you! Personalize your route and discover the city's best landmarks.",
      "Design a custom %s self-guided audio tour! Choose the landmarks you want to explore and start your adventure.",
      "Create your own %s audio journey today! Build a self-guided tour with personalized landmarks and start exploring.",
      "Create a personalized %s self-guided audio tour in minutes! Explore the city's top spots at your pace.",
      "Unlock %s with a custom self-guided audio tour! Choose your stops and start your personalized adventure.",
      "Design your %s tour today! Build a self-guided audio journey that takes you to the city's best spots.",
      "Create your own %s audio adventure! Personalize your self-guided tour and explore the city's hidden gems.",
      "Build a custom %s audio tour in minutes! Choose your favorite landmarks and start exploring today.",
      "Create a personalized %s self-guided journey! Discover the city’s top landmarks with a unique tour.",
      "Design your %s audio tour! Personalize your route and explore the city like never before.",
      "Create a custom %s self-guided tour and start your adventure! Personalize the route and landmarks at your pace.",
      "Create your own %s self-guided audio experience! Choose your stops and enjoy exploring the city at your pace.",
      "Design a personalized %s audio journey today! Explore the city’s best landmarks with a custom tour.",
      "Create a %s audio tour like no other! Customize your route and landmarks, then start your self-guided adventure.",
      "Design your own %s self-guided tour! Personalize your experience with ease and start exploring today.",
      "Create a personalized %s self-guided audio tour! Discover the city's top landmarks at your own pace.",
      "Build a custom %s audio journey now! Explore the city's best spots with a personalized self-guided tour.",
      "Design a personalized %s audio adventure! Customize your landmarks and enjoy exploring the city.",
      "Create a self-guided %s tour today! Personalize your route and explore the city's best highlights.",
      "Explore %s your way with a custom audio tour! Create a self-guided journey in minutes and start your adventure.",
      "Design your own %s audio tour today! Personalize your journey and explore the city at your own pace.",
      "Create a %s self-guided audio tour today! Build your own journey and explore the city's most famous landmarks.",
      "Design a custom %s audio tour! Personalize your experience and enjoy exploring the city at your own pace.",
      "Create your personalized %s self-guided tour now! Discover the city's hidden gems with ease.",
      "Design a custom %s audio journey! Personalize your route and explore the city's best spots.",
      "Create a %s audio adventure at your pace! Personalize your route and start your self-guided journey today.",
      "Design your personalized %s self-guided tour! Choose your landmarks and explore the city's best spots.",
      "Create your own %s self-guided journey today! Build your personalized audio tour and explore the city.",
      "Design a custom %s audio tour! Personalize your experience and start your adventure in minutes.",
      "Create a personalized %s self-guided audio experience! Choose your landmarks and explore the city's beauty.",
      "Create your custom %s audio journey today! Explore the city's best landmarks with a self-guided tour.",
      "Build a self-guided %s audio tour! Personalize your route and explore the city’s most iconic landmarks."
    ]
  },
  "tours": {
    "00af2331-5554-47ba-91ee-809a63b70d82": {
      "title": "Chengalpattu Chronicles: Exploring Perumbakkam, Medavakkam, and Jalladiampet",
      "description": "Embark on an enchanting tour through the vibrant neighborhoods of Chennai! Begin your journey in Perumbakkam, where modernity meets tranquility amidst serene lakes. Explore the cultural tapestry of Jalladiampet, offering glimpses of local life and hidden temples. Immerse yourself in the bustling vibrancy of Medavakkam, brimming with lively markets and delicious street food. Each stop unveils unique stories and experiences, making this tour an unforgettable exploration of Chennai's diverse charm."
    },
    "026f4ce0-8fc4-4329-8c97-986a6b936e4a": {
      "title": "Lima's Historic Landmarks: From Heroes to Art",
      "description": "Step into a journey through time with our captivating tour of Lima's most iconic landmarks. Begin your adventure in the lush greenery of University Park, where history and academia blend seamlessly. Stroll through its serene pathways before heading to the distinguished Panteón de los Próceres, a monument honoring the heroes of Peruvian independence.\n\nNext, be awed by the grandeur of the Palace of Justice, Lima's architectural marvel that stands as a testament to the city's rich judicial history. As you explore these iconic sites and more, let the vibrant culture and storied past of Lima unfold before your eyes. Don’t miss this chance to immerse yourself in the heart of Peru’s captivating capital."
    },
    "041328e9-d5c3-4c1f-aae4-4883044879e1": {
      "title": "Colombo Chronicles: Unveiling the Treasures of History",
      "description": "Discover the vibrant heartbeat of Colombo on this captivating city tour, starting with the historic Victoria Masonic Temple. Marvel at its architectural grandeur and delve into its mysterious heritage. Next, immerse yourself in modern luxury at Cinnamon Life Colombo, an urban sanctuary offering a blend of exquisite shopping, dining, and entertainment. Explore the culturally rich Slave Island, where a tapestry of colonial history and local traditions awaits. Whether strolling through bustling markets or savoring the bustling nightlife, this tour promises a delightful journey through the past and present of Sri Lanka's capital."
    },
    "0699c6ef-51e4-4375-a16f-d8873fe39faa": {
      "title": "Bray's Historic Highlights Audio Tour",
      "description": "Embark on an unforgettable journey through Dunleary and Bray! Start with a visit to the historic Bray Boxing Club, where champions train and the spirit of sportsmanship thrives. Next, head to the iconic Carlisle Grounds, a celebrated venue pulsating with local sports energy. Capture tranquility at Christ Church, Bray, a stunning architectural gem resonating with history and serenity. Dotted with other hidden gems, this tour weaves a rich tapestry of athleticism, culture, and beauty in enchanting coastal towns. Join us for an enthralling exploration where every stop tells its own compelling story!"
    },
    "088c7a02-3a92-4909-b801-990706297ae8": {
      "title": "Hidden Gems of Bratislava: A Journey through History and Culture",
      "description": "Embark on an enchanting journey through the heart of Slovakia’s vibrant capital, Bratislava. Begin your adventure with the majestic Bratislava Castle, perched high above the city, offering panoramic views of the Danube River and rich historical insights. Continue to the charming House of the Good Shepherd, a pristine example of Rococo architecture showcasing unique timepieces. Wander through the hallowed halls of St Martin's Cathedral, where centuries of history and stunning Gothic design converge. Discover the hidden gems and captivating stories of this beautiful city – every corner beckons with a new adventure!"
    },
    "09c2ce22-807e-4f17-a50e-39a670cedb3f": {
      "title": "Top landmarks in Istanbul",
      "description": "Discover the heart of Istanbul on this mesmerizing journey through history and architecture. Begin at the majestic Hagia Sophia, an architectural marvel that has stood for over 1,500 years, bridging the Byzantine and Ottoman empires with its awe-inspiring domes and mosaics. Next, find tranquility at the Zeynep Sultan Mosque, a hidden gem that offers a serene retreat amidst the bustling city. Descend into the eerie yet captivating Basilica Cistern, an underground wonder adorned with ancient columns and mystical waters. This tour promises an unforgettable blend of art, history, and culture, immersing you in the timeless charm of Istanbul. Join us and let the wonders of the past come alive before your eyes!"
    },
    "0C574286-CAAA-4243-B314-A2EDA7763F8F": {
      "title": "हैदराबाद के महल और मस्जिद यात्रा",
      "description": "हैदराबाद की यह अद्भुत यात्रा आपको एक ऐतिहासिक और सांस्कृतिक धरोहरों की यात्रा पर ले जाएगी! \n\n**चौमहल्ला पैलेस** आपको निज़ामों की शाही जीवनशैली का राजसी अनुभव देगा। इसकी वास्तुकला और कला आपको मुग्ध कर देंगी। इसके बाद **जामा मस्जिद** की शांति और भव्यता में खो जाइए।\n\n**कलबुर्गी** में अद्वितीय ऐतिहासिक स्थल और वास्तुकला आपको भारत के शानदार अतीत में ले जाएंगी। **मक्का मस्जिद** की विशालकायता और उसकी वास्तुकला आपके रोंगटे खड़े कर देगी।\n\nहर कदम पर आपको हैदराबाद का इतिहास, संस्कृति और उसकी दिलकश विरासत नजर आएगी। आइए और इस यात्रा का हिस्सा बनिए, जहां हर स्थल अपने आप में एक कहानी कहता है!"
    },
    "0d7c3ee2-4e37-4571-89b8-346f39da9a84": {
      "title": "Sky High Views and Beachside Wonders",
      "description": "Embark on an unforgettable journey through the vibrant Gold Coast! Begin your adventure atop Q1, marveling at breathtaking panoramic views from one of the world's tallest residential towers. Stroll through Circle on Cavill, a bustling hub brimming with top-notch dining, shopping, and entertainment options. Take in the creativity and culture at the Gold Coast City Art Gallery, where captivating exhibitions await. Each stop brings new delights and hidden gems, promising a day filled with excitement and discovery. Don't miss this chance to explore the best of the Gold Coast!"
    },
    "10cd5a9e-e2ed-4370-b33c-3b40263e2c3a": {
      "title": "Hobart's Historic Heartbeat",
      "description": "Embark on an enchanting journey through the heart of Hobart, where history and modernity meld seamlessly. Begin your adventure at Constitution Dock, a bustling waterfront hub brimming with maritime charm. Stroll along the picturesque wharves, savor fresh seafood, and soak in the vibrant atmosphere of this iconic harbor. Next, venture to Hobart Private Hospital, not just a place of healing but also a testament to the city’s advanced medical care and commitment to wellness. Discover hidden gems around every corner, from quaint cafes to artisanal shops, and immerse yourself in the rich culture and warm hospitality that define this captivating city. Experience the best of Hobart in one unforgettable tour!"
    },
    "11BA63DA-9DE2-4406-B286-FBDD96A4779C": {
      "title": "Bankstown Highlights: A Journey through Landmarks and Culture",
      "description": "Embark on an unforgettable journey through Bankstown, Sydney, where cultural diversity and vibrant life await you. Start your day at Bankstown Central, a bustling shopping hub where you can indulge in retail therapy and savor delectable cuisine from around the world. Next, stroll over to Bankstown Oval, a lush green space perfect for a relaxing break or to catch an exciting local cricket match. Continue your exploration at the magnificent Al-Rasool Al-A'dham Mosque, an architectural gem offering a serene atmosphere and an insight into the area's rich cultural heritage. Discover the hidden gems of Bankstown and experience the harmonious blend of tradition and modernity in this dynamic suburb!"
    },
    "15BB0760-1746-408A-A496-BE914E2E2EC9": {
      "title": "Old Ahmedabad Echoes: Bridge to Tomb Tour",
      "description": "Embark on a captivating journey through Ahmedabad, beginning with the majestic Nehru Bridge, which spans the Sabarmati River and offers picturesque views of the cityscape. Next, dive into history at Wajihuddin's Tomb, a serene and intricately crafted mausoleum that whispers tales of the past. Immerse yourself in the vibrant culture and heritage of one of India's most dynamic cities as you explore these and more enchanting spots. This tour promises a delightful blend of historical marvels and urban charm, making it an unforgettable experience."
    },
    "163b1745-4797-4448-9542-5b5d8f4d45ff": {
      "title": "Discovering the Renaissance Gems: A Historic Audio Tour of Florence",
      "description": "Embark on a captivating journey through the heart of Florence, beginning with the iconic Ponte Vecchio, where the timeless charm of artisan shops lines the ancient bridge. Stroll towards Loggia dei Lanzi, an open-air gallery showcasing magnificent Renaissance sculptures under its grand arches. Delve into history with the Roman Theatre of Florence, where echoes of ancient performances linger in the atmosphere. Each step reveals layers of art, history, and architectural splendor, promising an unforgettable exploration of Florence's most enchanting landmarks."
    },
    "19618698-E00F-4512-AA95-5EAD77BBBE42": {
      "title": "The Silicon Valley Journey Tour",
      "description": "Embark on an exhilarating journey through Cupertino, a vibrant city at the heart of Silicon Valley! Begin your adventure at the Vallco Shopping Mall, a dynamic destination brimming with trendy shops, delightful eateries, and entertainment galore. Then, delve into the future at the Apple Advanced Technology Group, where cutting-edge innovations come to life and offer a glimpse into tomorrow's technology. Continue to Homestead High School, steeped in history and the alma mater of some of the greatest minds in tech, providing a unique blend of nostalgia and inspiration. With each stop, you'll uncover the rich tapestry of ingenuity and culture that defines Cupertino. Don't miss out on this tech-tastic tour!"
    },
    "19980eb1-6b71-4552-a652-f5d96a36df62": {
      "title": "Discovering Milan's Historic Treasures",
      "description": "Embark on a captivating journey through Milan's historic and cultural heart with our enchanting tour! Begin your adventure at Piazza Mercanti, a medieval square brimming with ancient charm, where the past whispers tales through its grand edifices. From there, step into the dazzling world of La Scala, one of the most prestigious opera houses in the world, and feel the timeless elegance of its storied performances. Continue your exploration with a visit to the opulent Palazzo Arese Bethlen, an exquisite example of Milanese baroque architecture, rich in art and history. Along the way, uncover hidden gems and marvel at the seamless blend of tradition and modernity that defines this magnificent city. Join us for an unforgettable experience in Milan, where every corner tells a story and every moment is a masterpiece!"
    },
    "19FB6FFC-09C8-4972-8956-BF22101A4137": {
      "title": "Sacred Spaces of Mathura: A Spiritual Journey",
      "description": "Embark on a captivating journey through Mathura, a city steeped in divine heritage and vibrant history. Begin your adventure at the grand Jama Mosque, an architectural marvel echoing tales from the past. Next, feel the sacred energy at the Krishna Janmasthan Temple Complex, believed to be the birthplace of Lord Krishna. Wander through serene temples, bustling local markets, and soak in the spiritual ambiance that makes Mathura a mystical paradise for all who visit. This tour promises to leave you enchanted and deeply connected to India's rich cultural tapestry."
    },
    "1b06777f-0b96-446c-a5cd-954ad16fbbe9": {
      "title": "Townsville City: Heritage and Culture Trail",
      "description": "Embark on a captivating journey through Townsville, where every stop offers a unique glimpse into the heart of tropical North Queensland. Begin at the Museum of Tropical Queensland, where intriguing exhibits bring the region's rich history and vibrant marine life to life. Next, dive into an awe-inspiring underwater adventure at Reef HQ, the world's largest living coral reef aquarium. Wander through the serene Anzac Memorial Park, a beautiful tribute to the fallen heroes, offering tranquil spots for reflection. With additional hidden gems sprinkled throughout your itinerary, this Townsville tour promises an unforgettable blend of culture, nature, and history. Don't miss out on this exciting escapade in the tropical paradise!"
    },
    "1bda1cab-7d1d-47c6-adfb-56a3f44cc81e": {
      "title": "Cultural Heart of Adelaide",
      "description": "Embark on an enchanting journey through the heart of Adelaide with our vibrant city tour! Begin your exploration at the iconic Victoria Square, where modern design meets rich history, creating a picturesque hub that radiates the city's charm. Delight your senses at the bustling Adelaide Central Market, a food lover's paradise filled with fresh produce, gourmet treats, and the aromas of multicultural cuisines. Step back in time at the grand Adelaide Town Hall, a stunning heritage building that whispers tales of the city's past and resonates with cultural events. As you wander through these captivating sites and more, immerse yourself in the unique blend of culture, history, and culinary delight that Adelaide offers, ensuring an unforgettable experience in this southern Australian gem."
    },
    "1d19f1b7-4eb1-4244-815d-56656802d810": {
      "title": "Glorious Ghent: Unveiling the Historic Treasures",
      "description": "Embark on a captivating journey through the heart of Ghent with our exclusive tour! Begin your adventure at the stunning Roman Catholic Diocese of Ghent, where age-old traditions and spiritual serenity await. Marvel at the majestic St. Bavo's Cathedral, home to the world-renowned Ghent Altarpiece, whose splendor will leave you breathless. Ascend the iconic Belfry of Ghent, a UNESCO World Heritage site, and enjoy panoramic views that will etch themselves into your memory. Discover hidden gems and soak in the rich history and vibrant culture of this charming Belgian city. Don't miss this unforgettable experience!"
    },
    "1fea28e8-430a-40bb-a52b-b26b8474fc5e": {
      "title": "Journey Through Singapore's Iconic Landmarks: From Modern Skyscrapers to Historic Heritage",
      "description": "Discover the vibrant heart of Singapore on this captivating tour! Begin at the iconic Republic Plaza, an architectural marvel that soars into the skyline, offering breathtaking views and a glimpse into the city's modernity. Stroll through the verdant oasis of Hong Lim Park, a serene escape amidst the urban bustle, perfect for a moment of reflection and relaxation. Then, experience the elegance of UOB Plaza, a beacon of finance and sophistication, where art and commerce converge. Along the way, uncover hidden gems and cultural treasures that make Singapore a dazzling fusion of tradition and innovation. Join us for an unforgettable journey through this dynamic cityscape!"
    },
    "1fff4fa5-8bd3-4875-8474-7ff04b1c507f": {
      "title": "South Brisbane Cultural Odyssey Tour",
      "description": "Embark on an enchanting journey through the vibrant heart of South Brisbane, beginning at the picturesque South Bank ferry wharf, where the serene Brisbane River sets the tone for your adventure. Stroll along lush paths to discover the captivating charm of the Nepalese Peace Pagoda, an exquisite example of handcrafted artistry that offers a tranquil oasis amidst the bustling cityscape. Feel your excitement rise as you approach the iconic Wheel of Brisbane, a colossal observation wheel that promises breathtaking panoramic views of the city and beyond. Immerse yourself in the dynamic blend of nature and culture as you explore this lively area, peppered with delightful surprises and hidden gems waiting to be uncovered. Each step reveals a unique story, making this tour an unforgettable experience that captures the essence of Brisbane’s spirit."
    },
    "216A9660-B55C-45BE-AE25-247315C52390": {
      "title": "Majestic Muar: Delving into History and Culture",
      "description": "Embark on an enchanting journey through Muar, the Gem of Johor! Begin your adventure along the picturesque Muar River, where serene waters and lush greenery create a perfect harmony. Wander through the charming streets of Muar town, where heritage buildings and vibrant local markets await your curious exploration. Discover the civic pride at Muar Municipal Council’s historic and modern architecture, showcasing the town’s unique blend of tradition and innovation. Uncover hidden treasures and savor delicious local cuisine as you delve deeper into this captivating town. This tour promises a delightful blend of natural beauty, cultural richness, and unforgettable experiences."
    },
    "2214CEBB-132B-4DCA-ADBD-E100A9794BF3": {
      "title": "The Silicon Valley Tech Trail: Exploring Innovation in Cupertino",
      "description": "Welcome to Cupertino, a vibrant hub of innovation and history! Begin your journey at the Vallco Shopping Mall, where cutting-edge retail meets delightful dining. Explore the Apple Advanced Technology Group, the birthplace of groundbreaking tech that shapes our future. Step into history at Homestead High School, the alma mater of tech legends. Embark on this captivating tour and immerse yourself in the essence of Silicon Valley!"
    },
    "231da9f6-7c04-4d77-ac85-fae1e9403dce": {
      "title": "The Olympic Odyssey: Exploring London's Sporting Legacy",
      "description": "Embark on a thrilling adventure through London's vibrant East End, starting at the picturesque Old Ford Lock, where serene waterways blend with urban charm. Next, feel the pulse of world-class sports at the iconic London Stadium, a marvel of modern architecture and home to unforgettable events. Ascend the magnificent ArcelorMittal Orbit, the UK's tallest sculpture, for breathtaking panoramic views of the city. This tour perfectly balances history, sport, and art, offering an unforgettable experience in one of London's most dynamic areas."
    },
    "232BDC75-F9AA-4A4F-B803-B9738427A4CC": {
      "title": "The Grand Tapestry: A Journey Through Saint Petersburg's Historic Landmarks",
      "description": "Embark on an enchanting journey through the heart of Saint Petersburg, where history and opulence await at every turn. Begin your adventure at the illustrious Hermitage Museum, home to an awe-inspiring collection of art and culture within the grand Winter Palace. Stroll along the picturesque Palace Embankment, capturing the breathtaking views of the Neva River and the architectural marvels that line its shores. Experience the timeless elegance of the Vladimir Palace, a gem of Imperial Russia. Each stop on this tour promises to immerse you in the rich tapestry of Saint Petersburg’s storied past, leaving you with unforgettable memories of this magnificent city."
    },
    "26e2ca03-5bb8-4c60-8176-6ff6d7cdf72a": {
      "title": "New Delhi tour",
      "description": "Embark on an enlightening journey through New Delhi, where history, art, and modernity converge. Begin your adventure with a visit to the esteemed Ministry of Defence, where you can delve into India's strategic prowess. Next, step into the National Museum of India, an oasis of ancient treasures and rich cultural heritage that tells the nation's tale through millennia. Lose yourself in the myriad expressions of contemporary creativity at the National Gallery of Modern Art, a haven for art enthusiasts. Each stop offers a unique glimpse into the dynamic tapestry of India's past, present, and future, making this tour an unforgettable sojourn in the heart of the capital."
    },
    "27101a6d-0dda-469e-ab51-ae036ef59f78": {
      "title": "Coimbatore Landmarks Audio Tour",
      "description": "Embark on an unforgettable cultural and historical journey through Coimbatore, starting at the vibrant Jawaharlal Nehru Stadium, where the city's sporting spirit comes alive. Next, find peace and spiritual solace at the revered Thandu Mariamman Temple, a serene sanctuary that offers a glimpse into Coimbatore's rich religious heritage. Delve into the past with a visit to the Siege of Coimbatore site, where history echoes through the ages, telling tales of valor and resilience. This tour promises a captivating blend of excitement, spirituality, and historical intrigue, capturing the essence of Coimbatore in every step."
    },
    "289768FD-471F-4989-A1A2-1B6E0CE62CF8": {
      "title": "Stratford's Scenic Wonders: Quantum Cloud & Greenwich Peninsula Ecology Park Audio Tour",
      "description": "Discover the vibrant fusion of art, nature, and modernity on this enchanting tour through London’s Stratford and Greenwich Peninsula. Begin your adventure with a mesmerizing visit to the Quantum Cloud, a striking work of contemporary art by Antony Gormley, where you’ll witness how science and creativity intertwine in a captivating display. Then, immerse yourself in the serene beauty of Greenwich Peninsula Ecology Park, a hidden urban oasis teeming with wildlife and lush greenery. As you wander through these diverse attractions and other local gems, you’ll experience the dynamic and multifaceted pulse of London, blending innovation with tranquility in a truly unforgettable journey."
    },
    "2a27d0bb-7558-4ce0-89d1-f1406f5b28f8": {
      "title": "The Historic London Audio Adventure",
      "description": "Embark on a captivating journey through the vibrant heart of London, where history, culture, and modernity blend seamlessly. Begin your adventure at The Theatre, where the timeless stories of Shakespeare and contemporary performances come to life on world-renowned stages. Stroll down Old Street, a hub of creativity and innovation, lined with quirky cafes, eclectic boutiques, and striking murals. Continue to Commercial Street, where Victorian architecture meets bustling markets and chic eateries. Each stop on this tour offers a unique glimpse into the soul of London, promising an unforgettable experience filled with charm and excitement."
    },
    "2bc1a91f-8c51-4e06-a319-56e8692315ae": {
      "title": "Adelaide's Heritage and Green Spaces",
      "description": "Discover the vibrant heart of Adelaide on this captivating tour! Begin your journey at the iconic Victoria Square, where history and modernity blend seamlessly. Wander through the bustling Adelaide Central Market, Australia's premier food hub, offering a cornucopia of fresh produce, gourmet delights, and local specialties. Pay homage at the South African War Memorial, a poignant tribute to Australia’s brave soldiers. Each stop along the way reveals a unique facet of Adelaide’s rich cultural tapestry, promising a day filled with exploration, flavors, and heartfelt stories. Don’t miss the chance to immerse yourself in the essence of this charming city!"
    },
    "2bde2117-085c-47ba-af28-7012fb3fe8b8": {
      "title": "Kagithane walking tour",
      "description": "Embark on a captivating journey through the historical and cultural heart of Istanbul in Kagithane! Begin your exploration at the serene Teşvikiye Mosque, where Ottoman elegance meets modern sophistication. Next, wander through the lush, romantic gardens of Ihlamur Pavilion, a hidden gem that exudes 19th-century charm and royal opulence. Continue to the majestic Yıldız Hamidiye Mosque, a masterpiece of Ottoman architecture, showcasing intricate design and tranquil beauty. Along the way, discover delightful surprises and timeless treasures, making this tour an unforgettable adventure through Istanbul's rich past and vibrant present."
    },
    "2cfa5e6b-cc18-4039-9335-69046508b79a": {
      "title": "Wollongong's Historic and Coastal Gems",
      "description": "Embark on an enchanting journey through Wollongong’s captivating coastal heritage! Begin your tour at the historic Wollongong Breakwater Lighthouse, where the timeless architecture meets the serene beauty of the ocean. Stroll along to the iconic Wollongong Head Lighthouse, offering panoramic vistas that will take your breath away. Continue to Flagstaff Hill Fort, where history comes alive with fascinating tales of the past. As you explore these landmarks and other hidden gems, uncover stories of maritime adventures and enjoy the stunning coastal views. This tour promises a perfect blend of natural beauty and historical intrigue—an experience not to be missed!"
    },
    "2d6f3a74-bb37-4664-96a7-e2a08b45e6b9": {
      "title": "The Historic Streets of London: Charing Cross, Soho, and Downing Street Audio Tour",
      "description": "Embark on an unforgettable journey through the heart of London, starting with the charming Charing Cross Road, renowned for its iconic bookstores and bustling energy. Wander the vibrant streets of Soho, where eclectic nightlife, world-class dining, and artistic flair converge in a tapestry of sensory delights. Then, step into history as you stroll down Downing Street, home to the Prime Minister’s residence at Number 10 and steeped in political heritage. This tour seamlessly blends literary landmarks, cultural hotspots, and historic grandeur, offering a captivating glimpse into the soul of one of the world's most iconic cities. Don't miss the chance to immerse yourself in the rich tapestry of London's past and present!"
    },
    "31CB165A-CCCA-4C58-98A0-D75B2A6A01CC": {
      "title": "Heritage and Harmony: Exploring Colchester's Landmarks",
      "description": "Embark on a captivating journey through Halifax and beyond, as you explore the Nova Scotia Agricultural College, an institution where innovative farming meets cutting-edge research. Feel the pulse of community spirit at the Colchester Legion Stadium, a hub for exciting sports and memorable events. Wander through the enchanting paths of Victoria Park in Truro, Nova Scotia, where cascading waterfalls and serene woodland trails offer a perfect escape into nature. Discover the hidden gems and local charm of Nova Scotia on this enriching adventure packed with unique experiences and picturesque landscapes."
    },
    "328251E4-BF7C-44E3-83F1-690A441D8F37": {
      "title": "Hidden Gems of San Francisco: From Hills to Heights",
      "description": "Embark on a captivating journey through the heart of San Francisco! Begin your adventure at the serene Kite Hill, where sweeping city views and vibrant wildflowers set the tone. Next, explore the unique landscape of the Badlands, a hidden gem showcasing rugged beauty right in the city. Immerse yourself in the lively atmosphere of the Market and Noe station area, where charming shops and delectable eateries await. With each stop, you'll discover a different facet of San Francisco's charm and diversity. Join us for a memorable experience that highlights the city's natural splendor and vibrant urban life!"
    },
    "33295A15-F3C7-49C4-AB2A-328FDF0233AB": {
      "title": "Historic Halifax Highlights: Landmarks and Legacy Tour",
      "description": "Embark on an unforgettable journey through Halifax and its surroundings! Your exciting tour kicks off at the vibrant Rath Eastlink Community Centre, where you can indulge in top-notch recreational activities. Next, discover the inspiring stories and strong spirit at the Nova Institution for Women, an integral part of the community. Continue to the Colchester Legion Stadium, a hub of local sports events and cultural festivities. With several other hidden gems to explore along the way, this tour offers a perfect blend of history, community, and leisure. Get ready to experience the heart and soul of Halifax like never before!"
    },
    "33780afc-0fc3-44ad-8235-293e274ca6d6": {
      "title": "Historic Heart of The Rocks",
      "description": "Embark on an unforgettable journey through Sydney's iconic treasures! Stroll through The Rocks, where cobblestone streets whisper tales of the city's storied past and charming boutiques invite exploration. Delve into artistic wonders at the Museum of Contemporary Art Australia, where contemporary masterpieces ignite your imagination. Visit Cadmans Cottage, Sydney's oldest surviving residential building, and step back in time to the colonial era. Each stop on this tour offers a unique glimpse into the heart and soul of Sydney, blending history, art, and culture in the most captivating way."
    },
    "34a661fe-65d0-411c-aa42-f8940aa95ab6": {
      "title": "Istanbul walking tour",
      "description": "Embark on a captivating journey through Istanbul's rich tapestry of history and spirituality. Begin at the Ecumenical Patriarchate of Constantinople, a cornerstone of Orthodox Christianity, where whispers of ancient liturgies still resonate. Continue to the Gül Mosque, a magnificent fusion of Byzantine beauty and Ottoman charm. Marvel at the exquisite details of the Eski Imaret Mosque, a testament to the city's diverse architectural heritage. Each stop on this tour offers a unique glimpse into the heart of Istanbul's multi-layered past, promising an unforgettable experience filled with awe and wonder."
    },
    "38359744-4717-483a-9f58-5f340eec31f4": {
      "title": "Maningning ng Manila: Paglalakbay sa Kasaysayan at Kultura ng Maynila",
      "description": "Sama-sama tayong libutin ang makulay at makasaysayang puso ng Maynila! Magsisimula tayo sa Quinta Market, kung saan matutunghayan mo ang sari-saring mga kalakal at lutong Pinoy. Pagkatapos, tutungo tayo sa Plaza Miranda, na puno ng buhay at kasaysayan. Dito, alamin natin ang makabagbag-damdaming kwento ng Plaza Miranda bombing, isang mahalagang bahagi ng ating kasaysayan. Huwag palampasin ang pagkakataong ito upang damhin ang tunay na diwa ng Maynila, mula sa makasaysayang mga kaganapan hanggang sa makukulay na mga pamilihan. Tara na at maglakbay sa puso ng ating lungsod!"
    },
    "3A92CC4E-40BE-4350-9146-E8BD02373EDB": {
      "title": "London's Glittering Nightlife Tour",
      "description": "Embark on an exhilarating nocturnal journey through London's premier nightlife hotspots! Begin your adventure at the iconic Chinawhite, known for its glamorous ambiance and celebrity sightings. Next, dive into the opulent Aura Mayfair, where luxury meets an electrifying party atmosphere. Continue the revelry at Rosy Wilde, an artistic haven blending cutting-edge aesthetics with an unforgettable nightlife experience. This exclusive tour offers a blend of sophistication, vibrancy, and sheer excitement, guaranteeing an unforgettable night in the heart of London."
    },
    "3B2F6CF2-38B9-46E9-B45C-A7DE632EB10C": {
      "title": "Heritage Treasures of Hyderabad Audio Tour",
      "description": "Embark on a mesmerizing journey through the historic heart of Hyderabad, where grandeur and spirituality intertwine. Begin your exploration at the splendid Chowmahalla Palace, once the seat of the Nizams, with its regal courtyards and dazzling chandeliers. Feel a sense of reverence at Jama Mosque in nearby Kalaburagi, an architectural gem that exudes serenity and majesty. Then, return to the city's pulsating core to marvel at the majestic Makkah Masjid, one of the largest and oldest mosques in India, whose magnificent minarets pierce the sky. Witness a tapestry of history, culture, and faith weave together as you traverse these iconic landmarks, and let Hyderabad's timeless allure capture your heart."
    },
    "3BF6211C-56E2-4392-B9CC-6A96DE0BB2E4": {
      "title": "Yogyakarta Heritage and Highlights Audio Tour",
      "description": "Welcome to the heart of Yogyakarta, where history and culture intertwine to create an unforgettable journey! Begin your adventure at the iconic Tugu Yogyakarta, a symbol of the city’s spirit and resilience. Stroll down the vibrant and bustling Jalan Malioboro, a shopaholic’s paradise, where you can find everything from traditional batik to mouthwatering local delicacies. Immerse yourself in the rich history of Indonesia at the Fort Vredeburg Museum, a colonial-era stronghold now home to exhibitions that vividly tell the story of a nation's struggle for independence. This tour is a delightful blend of sights, sounds, and experiences that will leave you enchanted by the magic of Yogyakarta!"
    },
    "3a547d0a-88ff-4d71-ba38-63be128f0c58": {
      "title": "Historic and Cultural Odyssey of Geelong",
      "description": "Embark on a fascinating journey through Geelong, starting with the whimsical charm of Hi-Lite Park, a delightful amusement destination for all ages. Unearth the intriguing history of HM Prison Geelong as you explore its eerie corridors and learn about its storied past. Visit the stunning Christ Church, Geelong, where breathtaking architecture and serene surroundings invite contemplation and awe. Each stop offers a unique glimpse into the rich tapestry of Geelong’s heritage and present-day marvels. Discover the spirit of this vibrant city on a tour that promises to captivate your senses and awaken your curiosity!"
    },
    "3d025fed-187f-46c4-836f-8aa770930f10": {
      "title": "The Historic Heartbeat of Bendigo",
      "description": "Embark on an enchanting adventure through the treasures of Bendigo! Begin your journey at the awe-inspiring Sacred Heart Cathedral, where stunning architecture meets serene spirituality. Delve into history at the Central Deborah Gold Mine, a fascinating plunge into Bendigo's vibrant mining past where you can experience the thrill of gold rush days. Immerse yourself in artistic brilliance at the Bendigo Art Gallery, home to dynamic exhibits and exquisite collections. Each stop offers a unique blend of culture, history, and beauty—promising an unforgettable exploration of one of Victoria's most captivating cities!"
    },
    "45BF39FC-D34E-43C0-8BE7-183F476784C2": {
      "title": "The Apple Experience: Exploring Cupertino's Tech Empire",
      "description": "Embark on a captivating journey through Cupertino, California, where innovation meets serene beauty. Discover the heart of technology at Apple University and immerse yourself in the history and cutting-edge advancements of one of the world's most iconic companies. Stroll through the lush, picturesque landscapes of Cupertino, visit charming local cafes, and enjoy the unique blend of modernity and tranquility. This tour promises a fascinating peek into the world of Silicon Valley, making it an unforgettable experience for tech enthusiasts and serene sightseers alike."
    },
    "4651B6B9-E3C2-46B7-BEB4-0F3395046194": {
      "title": "Spiritual and Scenic Shimla Highlights Tour",
      "description": "Embark on an enchanting journey through the heart of Shimla, starting with the iconic Christ Church, an architectural gem showcasing neo-Gothic splendor. Stroll through the bustling Lakkar Bazaar, where vibrant wooden handicrafts beckon from quaint shops, offering a slice of local life. Explore The Ridge, Shimla’s bustling hub, where panoramic views of the surrounding mountains and the majestic sight of the Christ Church create a mesmerizing backdrop. This tour promises an unforgettable blend of history, culture, and natural beauty, making your visit to Shimla truly magical."
    },
    "4744f2ae-033a-4ee5-8f3e-72e869b87542": {
      "title": "Theatrical Treasures: Exploring London's Iconic Landmarks",
      "description": "Embark on an enchanting tour through the vibrant heart of London! Begin your journey at The Theatre, modern elegance where captivating performances come to life. Stroll through the sleek, urban oasis of Citypoint, marveling at its architectural grandeur and dynamic atmosphere. Next, venture to Commercial Street, a bustling thoroughfare brimming with chic boutiques, trendy cafes, and rich history. Each stop offers a unique snapshot of London's eclectic charm, ensuring an unforgettable adventure in one of the world's most exciting cities."
    },
    "4BB00825-186D-494D-9A17-56DA39C78CF7": {
      "title": "Surakarta Cultural Gems Tour",
      "description": "Embark on an unforgettable journey through the vibrant heart of Surakarta! Discover the eclectic essence of the Surakarta Metropolitan Area, where modernity meets tradition. Marvel at the exquisite art collection in the Tumurun Private Museum, a hidden gem showcasing masterpieces that captivate the soul. Stroll through the majestic Pura Mangkunegaran, a royal palace steeped in history and romance, where intricate architecture whispers tales of bygone eras. This tour promises a tapestry of cultural richness, artistic splendor, and historical wonders that will leave you enchanted at every turn. Come and experience the magic of Surakarta!"
    },
    "4BD7FD4E-B319-4139-BCAB-027648356C2E": {
      "title": "The Innovators' Journey: Exploring Cupertino's Technological Icons",
      "description": "Embark on a captivating journey through Cupertino, a vibrant hub of innovation and culture. Begin your adventure at Homestead High School, where technology luminaries like Steve Jobs and Steve Wozniak once roamed the halls. Feel the excitement and energy of a place that sparked countless dreams. Next, dive into the cutting-edge world of the Apple Advanced Technology Group, where you'll witness the forefront of futuristic tech developments and gain insights into the innovations shaping our future. Along the way, we'll explore other intriguing spots, blending a rich history with the pulse of current advancements. This tour promises an inspiring blend of education, tech marvels, and a peek into the future, all set in the heart of Silicon Valley. Don't miss this unique opportunity to walk in the footsteps of legends and be inspired by the world-changing innovations emerging today!"
    },
    "4FC4A3EC-21A3-4E70-BBE5-77D51B700071": {
      "title": "Teramo Treasures: An Audio Journey Through History and Sport",
      "description": "Embark on a captivating journey through the charming city of Teramo, where history and passion come alive. Begin at the thrilling Stadio Gaetano Bonolis, the pride of local sports enthusiasts, where you can feel the electrifying energy of football matches. Dive into ancient history at the Museo Archeologico Francesco Savini, home to a stunning collection of artifacts that narrate the region's rich past. Witness the exciting atmosphere of the Interamnia World Cup, an international handball tournament that brings together competitors from around the globe. Each step in Teramo reveals a delightful blend of cultural treasures and vibrant local life, promising an unforgettable adventure."
    },
    "4a606289-4552-40b6-9b7e-f8f216367859": {
      "title": "Top landmarks in Istanbul",
      "description": "Embark on an enchanting journey through Istanbul's timeless splendor! Begin at the awe-inspiring Hagia Sophia, where the grandeur of Byzantine and Ottoman artistry unfurls before your eyes. Next, uncover the hidden gem that is Hagia Irene, a serene sanctuary whispering tales of ancient empires. Stroll through the opulent courtyards of Topkapı Palace, once the heart of Ottoman power, where gleaming treasures and lush gardens mesmerize. This immersive tour promises to captivate your senses and transport you to the golden eras of this extraordinary city. Join us for an unforgettable adventure through Istanbul's most iconic landmarks!"
    },
    "4b884ed7-7b3d-4a87-bf4e-f8bce65dd074": {
      "title": "Cultural Marvels of Chennai: A Journey Through History and Art",
      "description": "Embark on an enchanting journey through the vibrant city of Chennai, where every corner brims with culture and history. Start your adventure at Spencer Plaza, one of the oldest shopping malls in India, offering a dynamic mix of modern stores and traditional handicrafts. Next, delve into the world of literature at the Connemara Public Library, a historic haven for book lovers with its vast collection and stunning architecture. Continue your exploration at the Government Museum, Chennai, where fascinating exhibits on archaeology, numismatics, and natural history await. This tour promises a delightful blend of shopping, knowledge, and history, perfect for an unforgettable day in Chennai."
    },
    "4dff3ddc-39a6-43b8-b80e-79325dd9723e": {
      "title": "نيودلهي - جولة نيودلهي",
      "description": "مرحبًا بك في جولة ساحرة في قلب مدينة نيودلهي! ستبدأ رحلتك بزيارة بيت حيدر آباد، حيث ستكتشف جمال العمارة الهندية الرائعة وتاريخها العريق. ثم ستتوجه لاستكشاف سوق البنغالية، الذي يُعد جنة لعشاق الطعام مع مجموعة متنوعة من الأطباق الشهية والحلويات التقليدية. بعد ذلك، ستغوص في عالم الطوابع البريدية من خلال زيارة المتحف الوطني للبريد، حيث ستكتشف مجموعة نادرة ومذهلة من الطوابع التي تأخذك في رحلة عبر الزمن. ولا تنسَ زيارة المعالم الأخرى التي تزين هذه المدينة الرائعة، لتعيش تجربة فريدة لا تُنسى، تجمع بين التاريخ والثقافة والمذاق الهندي الأصيل."
    },
    "50e817fc-e70c-4bd1-ba2e-5391d36d8a69": {
      "title": "Agra tour",
      "description": "Embark on a mesmerizing journey through Agra, where history and grandeur come alive. Begin at the Shah Jahani Mahal, a regal gem within the Red Fort, showcasing the opulence of Mughal architecture. Continue to the serene Nagina Masjid, an exquisite pearl of spiritual tranquility. Delve deeper into Agra Fort, a UNESCO World Heritage site, where ancient tales echo through its majestic walls. Marvel at the ethereal beauty of the Moti Masjid, known as the Pearl Mosque, radiating divine serenity. This enchanting tour promises to immerse you in the timeless splendor of Agra's rich cultural heritage."
    },
    "512B2459-3A09-485E-A0DB-31CC2EDBBDC6": {
      "title": "Bridges and Melodies: A Saint Petersburg Cultural Journey",
      "description": "Embark on an enchanting journey through the heart of Saint Petersburg! Begin your adventure at the historic Novo-Kamenniy Bridge, an architectural marvel that offers picturesque views of the city's canals. Continue to the Bryantsev Youth Theatre, where the vibrant world of Russian theater comes alive, captivating audiences of all ages with its dynamic performances. Dive into the rich cultural tapestry at the Rimsky-Korsakov Apartment and Museum, stepping into the very space where one of Russia's greatest composers crafted his masterpieces. Each stop on this tour promises a unique glimpse into the artistry and history of Saint Petersburg, leaving you with memorable experiences and a deeper appreciation for this magnificent city."
    },
    "5236515D-D757-473C-B8CE-203FB61ED111": {
      "title": "The Vientiane Landmark Expedition",
      "description": "Embark on an enchanting journey through Vientiane, the serene capital of Laos, as you explore its most iconic landmarks. Begin with the majestic Patuxai, an awe-inspiring monument reminiscent of the Arc de Triomphe, offering panoramic views over the city. Continue to the Sacred Heart Cathedral, a tranquil sanctuary exuding colonial charm and spiritual serenity. Wander through leafy boulevards, discover hidden temples, and immerse yourself in the city’s rich cultural tapestry. This tour promises a perfect blend of history, spirituality, and Laotian charm that will captivate your senses and leave lasting memories."
    },
    "53D48F62-0576-4F51-A31C-685B6F1A6133": {
      "title": "Cultural and Historic Gems of Didube, Tbilisi",
      "description": "Embark on a captivating journey through Tbilisi, where history, culture, and recreation converge. Begin your adventure at Mushthaid Garden, an oasis of tranquility, perfect for a leisurely stroll amidst lush greenery. Next, delve into the rich cultural tapestry of Georgia at the Art Palace of Georgia - Museum of Cultural History, an architectural gem housing fascinating artifacts and art. Feel the pulse of Georgian sports culture with a visit to Central Stadium, witnessing the excitement and energy that fills this iconic venue. This tour promises an unforgettable experience, weaving together the vibrant threads of Tbilisi’s heritage and contemporary charm."
    },
    "5826f61e-a562-4d62-9641-b67091ed67d2": {
      "title": "Historical Essence of Hanoi: Exploring Legendary Landmarks",
      "description": "Embark on an enchanting journey through the heart of Hanoi, where history and elegance converge. Begin your tour at the storied Lycée Albert Sarraut, an architectural gem that whispers tales of a bygone era. Revel in the timeless luxury of the Sofitel Legend Metropole Hanoi, an icon of colonial grandeur where illustrious guests have stayed. Then, marvel at the impressive facade of the State Bank of Vietnam, a testament to the city's rich heritage. Alongside these historical landmarks, discover hidden gems and vibrant local spots that encapsulate the charm and spirit of Hanoi. Join us for a captivating adventure that intertwines the old-world charm with the vivacious energy of modern-day Vietnam!"
    },
    "59381821-044D-47F4-BBBB-74B924271F92": {
      "title": "Nature and Nostalgia: The Blue Whale Audio Tour",
      "description": "Embark on an enchanting journey through the heart of Manhattan! Begin your adventure at the John Burroughs Association, where you'll immerse yourself in the spirit of America's naturalist icon. Next, marvel at the architectural grandeur of The Beresford, one of New York City's most iconic residential buildings boasting majestic views over Central Park. Wander through the vibrant streets, discovering hidden gems and illustrious landmarks that define the soul of the Big Apple. This captivating tour promises a perfect blend of nature, history, and urban splendor. Join us to experience the undeniable allure of Manhattan!"
    },
    "599bb017-38c7-4a0e-8f8a-64d37a282034": {
      "title": "The Vientiane Landmark Discovery Tour",
      "description": "Embark on an enchanting journey through Vientiane's cultural gems! Begin your adventure at the Lao National Museum, where you’ll uncover the rich history and heritage of Laos. Wander through the National Library of Laos, where ancient manuscripts and literary treasures await. Experience tranquility at the Vientiane Jamia Mosque, a serene oasis of spirituality. As you explore more hidden treasures of this vibrant city, you'll find Vientiane’s unique blend of tradition, history, and spirituality captivating. This tour promises an unforgettable glimpse into the heart of Lao culture!"
    },
    "59F7B501-71B1-41F0-B801-3AEA15E6C11F": {
      "title": "The Putrajaya Landmark Discovery Tour",
      "description": "Embark on an exquisite journey through Putrajaya, the crown jewel of Malaysia’s administrations. Begin with the majestic Palace of Justice, an architectural marvel that embodies the grandeur of modern governance. Dive into the intriguing world of aviation at the Air Accident Investigation Bureau, where every exhibit tells a dramatic tale of the skies. Stroll across the stunning Seri Setia Bridge, an elegant feat of engineering that offers breathtaking panoramas of the city’s serene landscapes and waterways. With each stop, discover the seamless blend of modernity and tradition that makes Putrajaya a must-visit destination. Come, uncover the essence of Malaysia’s administrative heart!"
    },
    "59db67da-419e-4a97-aa68-65c06bc64401": {
      "title": "The Enchanting Era: A Journey through London's Theatrical Past",
      "description": "Embark on a captivating journey through the heart of London, where history and modernity intertwine seamlessly. Begin your adventure at The Theatre, the first home of Shakespeare’s plays, immersing yourself in the echoes of Elizabethan drama. Stroll to the Curtain Theatre, a gem of theatrical heritage, where stories of the past come to life. Witness the impressive Citypoint tower, an icon of contemporary architecture, offering a striking contrast to your historic explorations. Along the way, discover hidden gems and vibrant cityscapes that make each moment unforgettable. Join us as we explore the enchanting layers of London, where every corner tells a tale."
    },
    "5A133392-4BA8-4257-87CB-E0C5C8196A60": {
      "title": "Bankstown Highlights Audio Tour",
      "description": "Discover the vibrant heart of Bankstown with our exclusive tour, designed for curious explorers! Begin your adventure at Bankstown Central, where retail therapy meets delightful dining experiences. Wander through a medley of boutique shops and savor multicultural cuisine in this bustling shopping hub. Next, breathe in the fresh air at Bankstown Oval, the perfect spot for a leisurely stroll or a serene afternoon watching local cricket matches. Feel moved by the beauty and tranquility of the Al-Rasool Al-A'dham Mosque, a symbol of unity and peace, where stunning architecture and spiritual ambiance converge. Round off your journey with visits to hidden gems, uncovering the rich tapestry of culture and community that makes Bankstown a unique and welcoming destination. Join us and create unforgettable memories in the heart of Sydney’s southwestern suburbs!"
    },
    "5BF07175-87F1-4AE4-BBDC-DAF1BD8EF44F": {
      "title": "Malioboro Memories and Tugu Tales: An Audio Journey Through Yogyakarta",
      "description": "Embark on an enchanting tour of Yogyakarta, starting with the iconic Grand Inna Malioboro, where rich history and modern comfort blend seamlessly. Journey through the heart of the city to Tugu Yogyakarta, an emblematic landmark that captures the spirit of Javanese culture and resilience. Discover hidden gems and cultural treasures as you explore the vibrant streets and alluring sights. Every corner of this magnificent city promises a delightful surprise, making your visit an unforgettable adventure. Join us for an experience that perfectly intertwines tradition, beauty, and excitement in Yogyakarta!"
    },
    "5b4f8e1b-2fe0-4d3e-a9f4-3e078b13184a": {
      "title": "Capital City Sights: A Wellington Audio Adventure",
      "description": "Embark on an enchanting journey through Wellington's vibrant heart! Begin at the iconic Embassy Theatre, where cinematic history and opulent architecture converge. Wander through the energetic pulse of Courtenay Place, abundant with trendy cafes and enticing nightlife. Delve into creativity at Toi Pōneke Arts Centre, a haven for local artistry and dynamic exhibitions. Discover more hidden gems, each adding a unique flavor to your immersive adventure in New Zealand's cultural capital. This tour promises to captivate your senses and spark your imagination at every turn!"
    },
    "5e19aa92-2094-450e-a707-51de2d1c7b99": {
      "title": "Brilliance of Bulwell audio tour ",
      "description": "Embark on an enchanting journey through Bulwell, Nottingham, where history and culture intertwine. Begin your adventure at the Church of St Mary the Virgin and All Souls, a beacon of spiritual grace with its intricate architecture and serene ambiance. Then, step back in time at the Old Town Hall, Bulwell, and imagine the vibrant community life of yesteryears. Conclude your tour with a visit to Keuper Waterstones—a haven for book lovers featuring a captivating collection that promises to stir your imagination. This tour offers a perfect blend of historical marvels and literary delights, making it an unforgettable experience."
    },
    "5e6fb255-345d-48fc-8ba1-afa2c85ef511": {
      "title": "Historic and Modern Symphony of Cairns City",
      "description": "Embark on an enchanting journey through the vibrant city of Cairns, where history, culture, and nature seamlessly blend. Begin your adventure at the Cairns War Memorial, a poignant tribute to the heroes of the past. Continue with a visit to St Monica's Cathedral, adorned with mesmerizing stained glass windows depicting the Great Barrier Reef's splendor. Dive into the mesmerizing world of marine life at the Cairns Aquarium, where the mysteries of the ocean reveal themselves. With each stop, you'll uncover the unique charm and beauty that make Cairns a must-visit destination. Join us for a day of discovery and wonder in tropical paradise!"
    },
    "60f92309-5ad9-41be-a4f8-bc3406316ceb": {
      "title": "Cultural Heart of Adelaide",
      "description": "Embark on an enchanting journey through the heart of Adelaide with our vibrant city tour! Begin your exploration at the iconic Victoria Square, where modern design meets rich history, creating a picturesque hub that radiates the city's charm. Delight your senses at the bustling Adelaide Central Market, a food lover's paradise filled with fresh produce, gourmet treats, and the aromas of multicultural cuisines. Step back in time at the grand Adelaide Town Hall, a stunning heritage building that whispers tales of the city's past and resonates with cultural events. As you wander through these captivating sites and more, immerse yourself in the unique blend of culture, history, and culinary delight that Adelaide offers, ensuring an unforgettable experience in this southern Australian gem."
    },
    "627726ED-FB5F-49A8-BDE3-263CB82A92A3": {
      "title": "Historic Gems of Ahmedabad: Sidi Saiyyed Mosque & Bhadra Fort Audio Tour",
      "description": "Embark on a captivating journey through the heart of Ahmedabad, where history and artistry unite! Begin your exploration at the iconic Sidi Saiyyed Mosque, renowned for its exquisite stone latticework windows that will leave you in awe. Continue to Bhadra Fort, a formidable structure brimming with tales of valor and grandeur. Meander through ancient streets, unearth hidden gems, and soak in the rich cultural tapestry of this vibrant city. This tour promises an unforgettable blend of architectural splendor and historical intrigue. Join us and let Ahmedabad enchant you!"
    },
    "64B50000-DC1F-42E0-8B2C-A0A83761802E": {
      "title": "Nightlife Rendezvous: Exploring London's Upscale Hotspots",
      "description": "Embark on a dazzling nightlife adventure in the heart of London, starting with the iconic Chinawhite nightclub, where opulence meets electric energy. Dance the night away to world-class DJs in an atmosphere pulsing with glamour. Next, step into the luxurious Aura Mayfair, an exclusive hotspot that promises an unforgettable experience with its chic ambiance and celebrity sightings. Savor exquisite cocktails, mingle with London’s elite, and let the night unfold in a whirlwind of excitement and sophistication. Join us for an extraordinary evening that captures the very essence of London's vibrant nightlife!"
    },
    "65778397-a18b-4129-98b3-ccbace77453d": {
      "title": "Heritage and Harmony in Brisbane City",
      "description": "Discover the vibrant heart of Brisbane on this captivating tour that takes you through some of the city's most iconic landmarks and hidden gems! Begin your journey at the historic Brisbane City Hall, an architectural marvel with its grand clock tower and elegant interiors. Then, immerse yourself in the bustling energy of Queen Street Mall, a premier shopping and dining destination brimming with lively street performers and chic boutiques. Experience the magic of the Anywhere Festival, where you'll be dazzled by innovative performances in unexpected locations. This tour offers a delightful blend of culture, history, and entertainment, promising unforgettable moments at every turn. Join us for an incredible adventure in beautiful Brisbane!"
    },
    "671c0edc-84f8-47fd-991c-d29685075180": {
      "title": "Cairns Cultural & Historical Highlights Audio Tour",
      "description": "Discover the dynamic heart of Cairns City on this captivating tour, perfect for both leisure and adventure seekers. Start your journey at Cairns Central, the vibrant retail hub offering an unparalleled shopping experience with an array of boutiques, cafes, and entertainment options. Stroll to the iconic Barrier Reef Hotel, a heritage-rich venue drenched in charm, where you can relax with a cool drink and soak up the lively atmosphere. Tune into Star 102.7, the region's popular radio station, and groove to the sounds shaping the city. Explore a few more hidden gems and must-see spots in the area, each with its own unique vibe, making every moment in Cairns City unforgettable. This tour blends modern comforts with local flair, ensuring you experience the best of what Cairns has to offer."
    },
    "69aae597-fa85-45c2-ad20-c423019b221b": {
      "title": "Art and Memory Walk in Parkes",
      "description": "Embark on a captivating journey through Canberra’s rich history and vibrant beauty! Begin at the illustrious Old Parliament House, where you’ll step back in time to explore Australia’s political heritage. Next, delve into the fascinating collections of the National Archives of Australia, where the nation's memory is meticulously preserved. Stroll through the stunning blooms of the National Rose Garden, a serene oasis that promises to enchant with its vibrant colors and fragrant scents. This tour seamlessly blends history, culture, and natural beauty, offering an unforgettable experience in Australia's charming capital."
    },
    "6C45CCEC-A779-44A6-9EDC-47A076105D71": {
      "title": "The Charming Trails of Kajang: A Journey through UTAR, Bandar Sungai Long, and Bandar Mahkota Cheras",
      "description": "Embark on a captivating journey through Kajang, where modern education and charming neighborhoods intertwine! Begin your adventure at Universiti Tunku Abdul Rahman, an oasis of academic excellence with its vibrant campus life. Next, take a stroll through the bustling streets of Bandar Mahkota Cheras and Bandar Sungai Long, where you’ll find a delightful mix of local culture, mouthwatering cuisine, and friendly faces. Discover hidden gems and explore other intriguing spots along the way, experiencing the unique blend of tradition and modernity that makes Kajang truly special. Join us for an unforgettable tour and let Kajang enchant you!"
    },
    "6DF1A6DC-50F4-4D8E-8C48-7026F559B2B9": {
      "title": "Cultural Chronicles of Copperfield Road: A Journey Through History and Leisure",
      "description": "Embark on a captivating journey through Stratford's rich tapestry of history and modern vibrancy. Start with the Ragged School Museum, where the nostalgia of Victorian-era classrooms and engaging exhibitions breathe life into the past. Feel the pulse of athletic prowess at Mile End Stadium, a hub for sports enthusiasts and lively events. Then, bask in the serene, picturesque charm of Salmon Lane Lock, where waters flow with tranquility. Each location offers its own unique story, promising a tour brimming with diverse experiences and unforgettable memories. Join us for an adventure that seamlessly blends heritage and contemporary allure."
    },
    "6aa32cc0-904d-4743-896f-a026dbe1136d": {
      "title": "Pompeii's Timeless Traces Tour",
      "description": "Embark on a mesmerizing journey through time with a captivating tour of Pompeii’s most iconic landmarks! Begin your adventure at the Suburban Baths, marveling at the intricate frescoes and state-of-the-art engineering from ancient times. Next, immerse yourself in the spiritual aura of the Temple of Apollo, where history whispers through the well-preserved columns and statues. Stand in awe at the Temple of Jupiter, with its commanding view of Mount Vesuvius framing the backdrop. Each site offers a fascinating glimpse into the daily lives and endurance of Pompeii's ancient inhabitants. Join us for an unforgettable exploration of this extraordinary, once-buried city!"
    },
    "7051ed67-1b5a-4d1c-95a5-2c00e3831a71": {
      "title": "The Heart of Ballarat (Central District Tour)",
      "description": "Embark on an enchanting journey through the heart of Ballarat, where history and culture intertwine seamlessly. Start your adventure at the Art Gallery of Ballarat, a treasure trove of inspiring artworks that span centuries, offering a deep dive into Australia's rich artistic heritage. Next, tune into the local pulse at 3BA, the vibrant radio station that's been the voice of the community for generations. As you stroll through the city, make your way to the Post Office Gallery, an architectural gem that hosts ever-changing exhibitions reflecting the region's dynamic spirit. Alongside these highlights, uncover the charm of Ballarat’s hidden gems, each with its own story to tell. This tour promises a delightful blend of artistic splendor, historical intrigue, and local flavor, making it a truly unforgettable experience."
    },
    "753DDD30-95FA-4643-88AF-4A875A5F3CD1": {
      "title": "The Silicon Valley Journey: Exploring Innovation and Legacy",
      "description": "Welcome to the heart of innovation and history in Cupertino! Your adventure begins at Vallco Shopping Mall, a hub of retail therapy and entertainment. Next, immerse yourself in cutting-edge technology at the Apple Advanced Technology Group, where future-defining innovations come to life. Step back in time with a visit to Homestead High School, the alma mater of tech giants. Explore a blend of commercial delight, tech prowess, and educational heritage in a tour that showcases Cupertino's vibrant spirit. Don't miss out on this unique journey through the cradle of Silicon Valley!"
    },
    "778E21CC-75F8-4318-9F88-CC9D2A48E43E": {
      "title": "अहमदाबाद का ऐतिहासिक सफर : सिदी सैयद जाली से ऐलिस ब्रिज तक",
      "description": "आपका स्वागत है अहमदाबाद के अद्वितीय और ऐतिहासिक दौरे पर! इस यात्रा में हम आपको ले चलेंगे सिदी सैय्यद मस्जिद, जहाँ की जालीदार खिड़कियों की कला आपको मोहित कर देगी। हज़रत पीर मोहम्मद शाह लाइब्रेरी में आपको शांति और ज्ञान का खज़ाना मिलेगा। ऐतिहासिक शहर अहमदाबाद की गलियों में घूमते हुए हम आपको यहाँ की समृद्ध विरासत और संस्कृति का अनुभव कराएंगे। यह दौरा ना केवल जानकारी से भरा होगा, बल्कि आपको एक अद्भुत अनुभव भी देगा जिसे आप लंबे समय तक याद रखेंगे।"
    },
    "799B052E-D14A-4ADD-87FA-EAAD5A9359F0": {
      "title": "The Silicon Valley Tech Trail: Unveiling Cupertino's Innovations",
      "description": "Welcome to an exclusive tour in Cupertino, the heart of Silicon Valley! Embark on a fascinating journey starting with a visit to the renowned Apple Advanced Technology Group. Witness the innovation and cutting-edge technology that drives one of the world's most influential companies. Continue your adventure with visits to nearby tech landmarks and charming local spots, enjoying the perfect blend of pioneering technology and the vibrant Californian culture. Whether you're a tech enthusiast or just curious, this tour promises to leave you inspired and amazed!"
    },
    "7DB45C73-3C4D-49EC-AE52-AF9A6BEBF20F": {
      "title": "The Innovators' Journey: Exploring Cupertino's Tech Legacy",
      "description": "Discover the innovation pulse of Cupertino with our exclusive tour! Begin your journey at the iconic Vallco Shopping Mall, a hub of retail therapy and entertainment. Dive into the future at the Apple Advanced Technology Group, where cutting-edge breakthroughs come to life. Relish the nostalgia at Homestead High School, the alma mater of tech giants. Along the way, explore a few hidden gems and local secrets that make Cupertino a blend of history, innovation, and charm. Join us for a day filled with excitement, inspiration, and a touch of Silicon Valley magic!"
    },
    "7d3c7515-0ec0-415a-b092-a7b4ab102c76": {
      "title": "서울 랜드마크 오디오 투어: N 서울타워, 조선 신사, 국제 마을 민속 박물관, 초준 텍스타일 & 퀼트 아트 박물관, 계조 신사, 릴라 예술 고등학교, 명동 성당, 로마 가톨릭 서울 대교구, 영락교회, 대한극장. 이 투어의 한국어 이름은 서울 랜드마크 오디오 투어 : 빛나는 역사와 예술의 여정입니다.",
      "description": "서울에서의 여행은 잊을 수 없는 순간들로 가득합니다! 우선, 남산서울타워에서는 서울의 멋진 전경을 한눈에 담아보세요. 고풍스러운 조선신궁에서는 한국 역사의 숨결을 느끼며, 전통과 현대가 조화롭게 공존하는 모습을 볼 수 있습니다. 글로벌빌리지민속박물관에서는 다양한 문화와 전통을 생생하게 체험할 수 있어 흥미진진한 시간이 기다리고 있습니다. 서울의 다채로운 매력을 만끽하며, 당신만의 특별한 추억을 만들어보세요!"
    },
    "7d6839a7-06e8-4b57-ae76-2ec142e2c8a5": {
      "title": "The Majestic Journey: Unveiling Phnom Penh's Landmarks",
      "description": "Embark on an enchanting journey through the heart of Phnom Penh, where history and culture intertwine. Begin your adventure at the serene Wat Langka, a spiritual haven that whispers ancient Buddhist tales. Marvel at the grandeur of the Independence Monument, a symbol of Cambodia's freedom and resilience. Pay homage to a revered leader at the Norodom Sihanouk Memorial, honoring the nation's beloved King-Father. This captivating tour promises a blend of tranquility, historical richness, and vibrant cultural heritage, offering an unforgettable glimpse into the soul of Cambodia."
    },
    "7dacec70-cefe-4365-a918-6d6a2f942782": {
      "title": "Jewels of Luangphabang: Unveiling the Sacred Temples and Royal Splendor",
      "description": "Embark on a captivating journey through Louangphabang, starting with the majestic Wat Manorom, a temple steeped in history and adorned with intricate sculptures. Descend into the mesmerizing Tham An Mah, a sacred cave echoing with ancient spiritual reverence. Continue to Wat Hosian Voravihane, where serene gardens and splendid architecture offer a tranquil retreat. Along the way, discover hidden gems and immerse yourself in the rich tapestry of culture and tradition that defines this enchanting city. Let Louangphabang's timeless beauty and profound spirituality leave an indelible mark on your soul."
    },
    "8133389C-8FD5-489E-8FF3-6D706CEF5511": {
      "title": "East End Echoes: Mile End, Ragged School, Salmon Lane",
      "description": "Embark on an unforgettable journey through Stratford and its vibrant surroundings! Begin your day with a visit to Mile End Stadium, where the energetic ambiance fuels your excitement. Next, dive into history at the Ragged School Museum, a hidden gem that offers a fascinating glimpse into Victorian-era education. Wander to the tranquil Salmon Lane Lock, where the serene waters provide a perfect backdrop for reflection and photos. This tour promises a delightful blend of modern thrills and historical charm, ensuring an adventure filled with wonder and discovery."
    },
    "830b4c7f-117c-47e7-9ed0-740ea258c7b4": {
      "title": "Venetian Splendors: A Journey Through Time",
      "description": "Embark on an enchanting journey through the heart of Venice, where history and charm intertwine in the most captivating way. Begin your tour at Tolentini, a splendid architectural gem, before wandering to the House of Carlo Goldoni, the birthplace of the legendary playwright, offering a nostalgic glimpse into 18th-century Venetian culture. Stroll through the vibrant Campo San Polo, the city's largest campo, brimming with local life and historical intrigue. Along the way, discover hidden gems and secret corners, each revealing their own tales of Venice's glorious past. This tour promises an immersive experience, blending cultural heritage with the mesmerizing beauty of the floating city."
    },
    "83126797-a47b-40fa-9896-db112acaca74": {
      "title": "Southern Heritage Odyssey: Perumbakkam to Jalladiampet Audio Tour",
      "description": "Embark on a captivating journey through Chennai's vibrant locales! Begin your adventure in Perumbakkam, where urban modernity meets serene lakes, offering a perfect blend of tranquility and excitement. Next, explore Jalladiampet, a hidden gem teeming with local charm and unique cultural experiences. As you wander into Medavakkam, discover bustling markets, delightful eateries, and the warmth of traditional Tamil hospitality. Each stop on this tour presents a unique facet of Chennai's rich tapestry, making for an unforgettable travel experience filled with discovery and delight."
    },
    "8330a613-0e9f-4422-8c1e-a46bee88b82a": {
      "title": "Legends of Lincoln: Unveiling History's Treasures",
      "description": "Embark on a captivating journey through Lincoln, where history and culture intertwine in the most enchanting ways. Begin your adventure at Lincoln Drill Hall, an iconic venue brimming with vibrant performances and community spirit. Then, dive into the rich history of The Collection, Lincolnshire, where fascinating archeological finds and dynamic exhibitions await. Wander through the ancient Jew’s House, one of England’s oldest and most intriguing buildings, offering a glimpse into medieval life. Along the way, discover charming streets, hidden gems, and the timeless allure of Lincoln, a city that gracefully bridges the past and present. This tour promises a tapestry of unforgettable experiences that will leave you deeply enchanted."
    },
    "857f43d4-ded2-4115-b067-0c65a0ceb160": {
      "title": "Heritage Walk and Art Exploration in Wagga Wagga",
      "description": "Embark on an enchanting tour of Wagga Wagga, starting with the historic Wagga Wagga railway station, where timeless architecture meets modern-day charm. Continue your journey at the bustling Wagga Wagga Marketplace, a haven for shoppers and food lovers alike, bursting with local treasures and culinary delights. Stroll through the serene Victory Memorial Gardens, a lush oasis of tranquility, brimming with vibrant flowers and poignant memorials. Uncover the gems of Wagga Wagga, where each stop offers a blend of history, culture, and natural beauty, promising an unforgettable experience."
    },
    "8620b422-2863-406c-82ff-323b92baad9f": {
      "title": "Heritage and Harmony: Discover Oldenburg's Historic Highlights",
      "description": "Embark on a captivating journey through the charming city of Oldenburg! Begin your exploration along the tranquil banks of the Haaren River, where the gentle flow of water creates a serene backdrop perfect for a leisurely stroll. Next, visit the historic Lappan, an iconic bell tower that stands as a testament to Oldenburg's rich history and offers a glimpse into the past with its impressive architecture.\n\nContinue your adventure at Haus Graf Anton Günther, a remarkable building steeped in the legacy of one of Oldenburg's most celebrated counts. This site is a treasure trove of stories and heritage, offering an intimate look at the life and times of the city’s aristocracy.\n\nThroughout your tour, discover the hidden gems that Oldenburg has to offer. Immerse yourself in a blend of history, culture, and scenic beauty, and let the spirit of this delightful city captivate your senses. Whether it's through interactive exhibits, picturesque scenery, or quaint cafes, this tour promises an enchanting experience you won't soon forget."
    },
    "89d2f24f-95ab-45ee-bf1a-e996407fdf73": {
      "title": "Heritage Walk: A Stroll through Launceston's Historic Heart",
      "description": "Embark on a captivating tour of Launceston, where history and beauty seamlessly blend. Begin your journey at the iconic Albert Hall, an architectural marvel with a rich heritage. Stroll through the serene City Park, where lush greenery and charming Japanese monkeys delight visitors of all ages. Immerse yourself in the rich cultural tapestry at the Queen Victoria Museum and Art Gallery, where intriguing exhibits and stunning artworks await. Each stop offers a unique glimpse into Launceston's vibrant past and present, creating an unforgettable experience steeped in elegance and charm. Discover the magic of Launceston on this enchanting adventure!"
    },
    "8C4956C2-2F99-4D06-AA9C-111508BE9C9B": {
      "title": "Cultural Gems of Saint Petersburg: Bridge, Theatre, and Composer's Home",
      "description": "Experience the enchanting allure of Saint Petersburg with a captivating tour that starts at the Novo-kamenniy Bridge, where history and beauty intertwine over tranquil waters. Delight in the vibrant performances at the Bryantsev Youth Theatre, a cultural gem that enchants audiences of all ages. Next, immerse yourself in the world of classical music at the Rimsky-Korsakov Apartment and Museum, where the legendary composer’s legacy comes alive through his preserved living quarters and fascinating memorabilia. Discover the magic of this historic city, where every corner holds a story waiting to be explored. This tour promises a perfect blend of history, culture, and inspiration."
    },
    "8c05fbd7-0c08-42c5-971b-15719761950d": {
      "title": "Laos Odyssey: Discovering Vientiane's Timeless Treasures",
      "description": "Embark on a captivating journey through Vientiane, the heart of the Kingdom of Laos. Start your adventure at That Dam, the iconic Black Stupa shrouded in myth and legend, capturing the essence of ancient Laos. Explore the serene streets of Vientiane, where French colonial architecture meets traditional Lao charm. Delve into the vibrant local markets, sample delectable Lao cuisine, and immerse yourself in the rich culture and history of this enchanting city. This tour promises a perfect blend of mystery, heritage, and culinary delights."
    },
    "8d68bc6d-664a-45ef-bf4d-c57d63f66ec1": {
      "title": "Heritage and Harmony in East Melbourne",
      "description": "Embark on a captivating journey through Melbourne's historic landmarks, starting with the majestic Parliament House, a splendid example of Victorian architecture. Located on Spring Street, this iconic building is home to the Parliament of Victoria and offers a fascinating glimpse into the state's legislative history. As you stroll through the grand chambers and lush gardens, you'll be transported back in time, marveling at intricate details and awe-inspiring interiors. Explore nearby attractions, each with its own unique charm, and discover why Melbourne is renowned for its rich cultural heritage. Join us for an unforgettable experience that blends history, beauty, and intrigue at every turn!"
    },
    "8e991a55-b89f-4852-80d1-068a9e3d22e5": {
      "title": "Gandhi Irwin Road Historical Gems Audio Tour",
      "description": "Embark on an immersive journey through Chennai, where history and modernity intertwine seamlessly! Begin at the majestic Chennai Egmore railway station, a masterpiece of Indo-Saracenic architecture. Wander through the historic corridors of Madras Presidency, absorbing tales of colonial grandeur. Feel time stand still at Fort St. George, the first British fortress in India, as you explore its museums and ancient relics. Immerse yourself in the vibrant culture, bustling markets, and serene seascapes of this fascinating city. This tour is a captivating blend of heritage, stories, and experiences that will leave you enchanted and eager for more."
    },
    "9056f5e4-5e2a-48a6-8769-5c96c75bbf10": {
      "title": "Cedar Rapids Historical and Cultural Journey",
      "description": "Embark on a captivating journey through Cedar Rapids, where history and culture blend seamlessly. Begin your adventure at the United States District Court for the Northern District of Iowa, a hub of profound judicial history. Next, lose yourself in the grandeur of the Paramount Theatre, an architectural gem that hosts an array of enchanting performances. Continue to the Linn County Courthouse, an emblem of justice with its stately facade. Each destination offers a unique glimpse into the heart of this vibrant city, promising an unforgettable exploration of its rich heritage and dynamic present."
    },
    "92738698-7B6F-46A7-80C4-401C191D23E2": {
      "title": "Bankstown Highlights Audio Tour",
      "description": "Embark on a captivating journey through the vibrant heart of Bankstown, Sydney! Begin your tour at the majestic Al-Rasool Al-A'dham Mosque, where stunning architecture meets spiritual serenity. Next, immerse yourself in the local sports culture at the historic Bankstown Oval, a haven for cricket enthusiasts. Then, dive into a world of knowledge and innovation at the Bankstown City Library and Information Service, a modern hub filled with endless resources and community spirit. Discover hidden gems and experience the cultural richness that makes Bankstown a lively and diverse destination. Join us and let Bankstown's charm leave a lasting impression!"
    },
    "937208e4-4fb5-4551-a1d8-8d80d0fe3132": {
      "title": "The Barcelona Historic Landmarks Audio Tour",
      "description": "Embark on a captivating journey through the heart of Barcelona, beginning with the artistic treasures of the Museu Frederic Marès, where fascinating sculptures, and eclectic collections await. Wander through history at the Barcelona Cathedral, marveling at its Gothic splendor and serene cloisters. Dive deeper into spiritual heritage with a visit to the Roman Catholic Archdiocese of Barcelona, breathing in the sacred atmosphere. Each stop weaves a rich tapestry of art, culture, and history, promising an unforgettable exploration of this enchanting city."
    },
    "94E2ACBA-7BA1-425B-AD11-3FB3FFCCDC4B": {
      "title": "Heritage Harmony: Ahmedabad Audio Adventure",
      "description": "Embark on a captivating journey through the heart of Ahmedabad, where history and culture intertwine beautifully. Begin with the splendid Ahmed Shah's Mosque, an architectural marvel showcasing the grandeur of the Sultanate era. Wander through the serene surroundings of Magen Abraham Synagogue, the city’s only Jewish place of worship, echoing tales of a bygone community. Discover the elegance of Sardar Khan's Roza, a tranquil mausoleum that whispers stories of the past. This tour offers a rich tapestry of religious diversity and historical depth, ensuring an unforgettable experience for every traveler. Delve into the soul of Ahmedabad and let the city's heritage enchant you."
    },
    "95458097-CE91-4CAB-8D48-02AC56E25847": {
      "title": "Architectural Wonders of Chandigarh: An Audio Tour of the Capitol Complex",
      "description": "Embark on an architectural odyssey in the dynamic city of Chandigarh, beginning with the awe-inspiring Palace of Assembly. Marvel at the ingenuity of Le Corbusier as you explore the Chandigarh Capitol Complex, a UNESCO World Heritage site that captures the essence of modernist design. Wander through the city's meticulously planned landscapes and discover hidden gems around every corner. This tour promises an enriching experience, blending visionary architecture with the vibrant culture of Chandigarh, leaving you spellbound and yearning for more."
    },
    "96a449e4-48dc-4660-b8d7-e74a4ba174dd": {
      "title": "Het Betoverende Amsterdamse Avontuur",
      "description": "Welkom in het hart van Amsterdam, waar historie, cultuur en moderne invloeden samenkomen voor een onvergetelijke ervaring!\n\nTijdens deze fascinerende tour beginnen we met een mysterieuze verkenning van de **Amsterdamse Kerker**. Durf jij deze spookachtige attractie vol gruwelijke verhalen en donkere geschiedenis aan? Achter elke hoek wacht een nieuw avontuur.\n\nVervolgens wandel je over de bruisende **Kalverstraat**, dé winkelstraat van Amsterdam. Hier vind je een mix van internationaal bekende merken en unieke boetiekjes, perfect voor een middagje shoppen en mensen kijken.\n\nWe vervolgen onze weg naar het serene **Begijnhof**, een betoverend hofje dat een rustpunt is te midden van de stad. Deze historische schuilplaats voor alleenstaande vrouwen biedt een glimp in het leven van de vroegere begijnen, met zijn prachtige huizen en de sfeervolle Engelse Kerk.\n\nOnderweg ontdek je nog vele andere verborgen pareltjes die Amsterdam zijn unieke charme geven, van pittoreske grachten tot gezellige café's. Laat je leiden door de verhalen van de stad en geniet van een dag vol avonturen!\n\nKlinkt dit als de perfecte dag in Amsterdam? Sluit je dan aan bij onze tour en beleef de magie van deze veelzijdige stad."
    },
    "975bae46-4364-4abe-91a5-9a3c85e74867": {
      "title": "The Vibrant City Expedition: Unveiling London's Rich Heritage",
      "description": "Embark on an unforgettable journey through the heart of London, where history and modernity intertwine seamlessly. Begin at The Theatre, a hub of creativity and performance, where the city’s vibrant arts scene comes to life. Stroll down Commercial Street, a bustling area brimming with eclectic markets and trendy eateries, perfect for immersing yourself in local culture. Continue to Citypoint, a striking skyscraper that offers breathtaking views of the city’s skyline and a taste of London's architectural prowess. Each stop on this tour is a unique gateway to discovering the rich tapestry that makes London one of the world’s most exciting cities."
    },
    "986b0ad9-8d78-4be1-886c-d44e720d9525": {
      "title": "Icons and Imprints: A Journey Through Little India's Legacy",
      "description": "Embark on an unforgettable journey through Singapore with our captivating city tour! Begin your adventure at City Square Mall, where modern retail therapy meets vibrancy. Uncover the poignant history at the Collapse of Hotel New World site, a testament to resilience and heritage. Immerse yourself in the serene ambiance of the Mahatma Gandhi Memorial Hall, reflecting on the life and legacy of an iconic leader. Join us for a day filled with inspiring stories, diverse culture, and architectural marvels, making this an experience you won’t want to miss!"
    },
    "98c2102b-ece9-446d-a3d6-fb8f1810814a": {
      "title": "Chennai's Majestic Mosques Audio Tour",
      "description": "Embark on a spiritual and historical journey through the heart of Chennai with our exclusive mosque tour. Begin at the majestic Hafiz Ahmad Khan Mosque, where the tranquility of its architecture and ambiance offers a serene retreat. Next, marvel at the rich heritage of the Triplicane Labbai Jamaath Mosque, a symbol of community and tradition. Your journey crescendos at the iconic Triplicane Big Mosque, renowned for its grandiose structure and historical significance. Along the way, explore a tapestry of other significant sites, each narrating its own unique story of faith, culture, and history. Join us for an unforgettable experience that promises to enlighten and inspire."
    },
    "9CDA123A-229A-4DE4-ADCC-30F31704487C": {
      "title": "Tech Trail: Exploring Cupertino's Icons",
      "description": "Embark on a captivating adventure in Cupertino, the heart of Silicon Valley! Begin your journey at the intriguing Vallco Shopping Mall, a blend of unique shops and vibrant entertainment. Marvel at the cutting-edge innovations at the Apple Advanced Technology Group, where the future is being shaped before your eyes. Discover a blend of technology, culture, and excitement as you explore more hidden gems within this dynamic city. Unveil the essence of Cupertino and be inspired every step of the way!"
    },
    "9ac5090b-97e3-4c0d-b929-0e4028c2aa0d": {
      "title": "Almaty tour",
      "description": "Embark on an unforgettable journey through Almaty's cultural and historical gems! Begin at the iconic Palace of the Republic, with its grand halls and vibrant performances capturing the heart of Kazakhstan. Next, immerse yourself in youthful creativity at the Pioneers Palace, where the spirit of innovation thrives. Deepen your understanding of Kazakhstan's rich heritage at the Central State Museum, home to fascinating artifacts and compelling exhibits. This tour promises a captivating blend of tradition and modernity, offering a rich tapestry of experiences that will leave you enchanted. Join us for a day of exploration and discovery in Almaty!"
    },
    "B5D78234-3BD0-49AC-BD55-980D44153A33": {
      "title": "Tech Capital Trek: From Vallco to Apple",
      "description": "Embark on an exciting exploration of Cupertino, where innovation meets nostalgia. Begin your journey at the once-vibrant Vallco Shopping Mall, a hidden gem echoing tales of the past. Dive into the future with a visit to the Apple Advanced Technology Group, the heart of groundbreaking advancements. Relive history at Homestead High School, alma mater of legends. Each stop, a chapter in Cupertino’s captivating story, promises discoveries, inspiration, and a touch of Silicon Valley magic. Don’t miss this unique blend of tech marvels and historic charm!"
    },
    "BAED328B-3336-4D09-AE09-809DCDFD3398": {
      "title": "The Nightlife Expedition: From Aura Mayfair to Chinawhite with Rosy Wilde",
      "description": "Embark on an electrifying journey through London's vibrant nightlife, starting at the exclusive Aura Mayfair, where elegance meets exuberance in a setting frequented by A-listers. Continue the adventure at the legendary Chinawhite nightclub, renowned for its chic ambiance and unforgettable parties. Then, step into the artistic world of Rosy Wilde, a unique space blending creativity and culture. This tour promises a whirlwind of glamour, excitement, and unforgettable experiences in one of the world's most dynamic cities. Join us for an unforgettable night you won't want to end!"
    },
    "C5D7597B-7390-4D15-9C79-F240DD7E7314": {
      "title": "Heritage Echoes: Exploring Ahmedabad's Cultural Gems",
      "description": "Embark on an unforgettable journey through the vibrant city of Ahmedabad with our exclusive cultural tour. Begin your adventure at the Gujarati Sahitya Parishad, where the rich treasures of Gujarati literature and heritage await. Next, step back in time as you explore the mesmerizing Wajihuddin's Tomb, an exquisite example of medieval architecture. Feel the spirituality and history converge at Shahpur Mosque, a serene oasis of devotion amidst the city's bustling heart. Along the way, discover hidden gems and immerse yourself in the eclectic mix of tradition and modernity that makes Ahmedabad a truly captivating destination. Join us for a day filled with stories, culture, and unforgettable experiences!"
    },
    "CA113F20-DF2A-4826-B6B9-03846C1B0E3F": {
      "title": "Heritage Highlights of Edinburgh of the Seven Seas",
      "description": "Embark on an unforgettable journey to Edinburgh of the Seven Seas, the most remote settlement in the world on the island of Tristan da Cunha! Begin your exploration at St. Mary's School, where the stories of island education come alive. Continue your adventure with a visit to St. Mary's Church, a charming sanctuary offering serene moments and rich history. Next, feel the spiritual calm and architectural beauty at St. Joseph Church. As you wander through these unique landmarks and others, immerse yourself in the island's profound tranquility, stunning landscapes, and the warm embrace of a close-knit community. This tour promises a rare glimpse into life at the edge of the world!"
    },
    "CDFEB0D2-FDD9-4D24-B469-97142C1E8E62": {
      "title": "Heritage of Vientiane: Exploring the Heart of Laos",
      "description": "Embark on a captivating tour of Vientiane, where history, culture, and spirituality converge in a city of timeless charm. Begin your journey at the Sacred Heart Cathedral, Vientiane, an architectural gem imbued with serene elegance and spiritual solace. From there, stroll towards the grandeur of the Presidential Palace, Laos, a testament to colonial splendor and modern governance, adorned with historic significance. This tour offers a rich tapestry of experiences, from the tranquil to the majestic, in a city that whispers stories of the past and vibrant glimpses of the present. Let Vientiane enchant you at every turn!"
    },
    "DDBED8B8-471D-4A1E-9CDD-6160795C121C": {
      "title": "In the Heart of Democracy: Exploring Historic Parliament House and Jaipur Column",
      "description": "Embark on a captivating journey through the heart of India's vibrant capital with our exclusive New Delhi tour. Begin your exploration at the historic Old Parliament House, a stunning architectural marvel that has witnessed the shaping of a nation. Continue to the iconic Jaipur Column, where history and grandeur converge in an awe-inspiring vista. As you traverse through a mix of classic landmarks and hidden gems, immerse yourself in the rich tapestry of culture, politics, and heritage that defines New Delhi. This tour promises an enriching experience, leaving you with unforgettable memories of India’s magnificent capital."
    },
    "E2FC15B7-F55D-44C6-A4AD-3CCB515E5A4E": {
      "title": "Heritage and Harmony: Kuala Lumpur's Nature and History Audio Tour",
      "description": "Embark on an unforgettable journey through the heart of Kuala Lumpur, where history, nature, and culture intertwine. Begin your adventure at the National Museum of Malaysia, where ancient artifacts and vivid exhibits bring the nation's rich past to life. Stroll through the lush oasis of the Perdana Botanical Gardens, an urban sanctuary teeming with vibrant flora and serene lakes. Dive into the legacy of Malaysia's second Prime Minister at the Tun Abdul Razak Memorial, a treasure trove of personal memorabilia and significant historical moments. Immerse yourself in the diverse attractions of Kuala Lumpur, each stop offering a unique story and a fresh perspective."
    },
    "EC927A48-78D4-4C59-A1E1-27954E16E2D4": {
      "title": "Historic Splendors of Hyderabad: A Journey Through Time",
      "description": "Embark on a regal journey through the heart of Hyderabad with our exclusive tour! Start by marveling at the splendor of Chowmahalla Palace, a royal abode echoing the grandeur of the Nizams. Feel the serenity wash over you at Jama Mosque in Kalaburagi, a classic example of Persian architecture. Experience spiritual elegance at the majestic Makkah Masjid, one of the oldest mosques in India. Each location promises to captivate with its unique history, intricate architecture, and timeless beauty. Join us for an unforgettable adventure through Hyderabad's most iconic landmarks!"
    },
    "F2E1254E-D034-4240-B5EB-960478D9C123": {
      "title": "The Innovation Trail: Exploring Cupertino's Technological Legacy",
      "description": "Embark on an exciting journey through the heart of innovation and tradition in Cupertino! Start by exploring the freshly revitalized Vallco Shopping Mall, where modern retail experiences and dining delights await. Dive into the future at the Apple Advanced Technology Group, a hub of cutting-edge research and development that fuels the tech giant's most ambitious projects. Then, take a nostalgic stroll through Homestead High School, alma mater of tech legends, where the spirit of creativity and discovery is truly tangible. This tour offers a unique blend of shopping, technology, and education, perfect for enthusiasts of all ages!"
    },
    "F302DB84-E6CF-495B-91DD-B5BD00B47D24": {
      "title": "The Silicon Valley Journey: Exploring Innovation in Cupertino",
      "description": "Welcome to Cupertino, the heart of Silicon Valley! Start your adventure at the newly reimagined Vallco Shopping Mall, a dazzling destination for shopping, dining, and entertainment. Dive into innovation with an exclusive visit to the Apple Advanced Technology Group, where the future of tech is being shaped. Relive the memories of greatness as you walk through Homestead High School, alma mater to legends like Steve Jobs. Each stop on this journey through Cupertino not only enriches your understanding of modern technology and history but also invites you to be a part of the vibrant community that drives the world forward. Come and experience where creativity meets tradition in this dynamic corner of California!"
    },
    "F328424E-AC8E-4964-9985-25C7CED6EFC0": {
      "title": "Denpasar Discovery Audio Tour: From Heritage to Healing",
      "description": "Embark on an enthralling journey through Denpasar, the pulsating heart of Bali! Begin your adventure at Ngurah Rai Stadium, where the vibrant energy of local sports events will captivate you. Next, delve into the advanced medical marvel of Bali Mandara Eye Hospital, a testament to the island's commitment to cutting-edge healthcare. Dive into Bali's rich cultural tapestry at the Bali Museum, where centuries-old artifacts and art pieces tell the story of this enchanting land. Along the way, discover hidden gems and savor the lively ambiance of Denpasar, a city where tradition meets modernity. Your unforgettable Balinese adventure awaits!"
    },
    "F49B007D-38B5-433B-8FF7-70082610B1AC": {
      "title": "Tech Explorer: Unveiling Cupertino's Innovations",
      "description": "Embark on an exciting journey in Cupertino, a vibrant blend of innovation and leisure! Begin your tour at the Vallco Shopping Mall, where you'll find a diverse range of shops, dining options, and entertainment for a perfect start. Next, dive into the future at the Apple Advanced Technology Group. Witness groundbreaking technologies that are set to reshape our world, and perhaps catch a glimpse of the innovative minds behind them. Explore several other hidden gems and bustling spots in the heart of Silicon Valley, making this a day filled with discovery, excitement, and unforgettable experiences. Join us for a captivating adventure in Cupertino, where every corner sparks curiosity!"
    },
    "FD5C5FE2-5B2C-47DB-85FF-EEF5903E7624": {
      "title": "Hyderabad Heritage Odyssey: A Gateway Through Time",
      "description": "Embark on a mesmerizing journey through Hyderabad's rich tapestry of history and culture with our exclusive tour. Start at the splendid Jama Mosque in Kalaburagi, a stunning example of Islamic architecture. Then, make your way to the majestic Makkah Masjid, one of Hyderabad's oldest and largest mosques. Feel the spiritual aura as you visit the sacred Bhagyalakshmi Temple, nestled beside the iconic Charminar. Along the way, uncover hidden gems and local mysteries that make Hyderabad an enchanting blend of the old and the new. This tour promises an unforgettable immersion into the heart and soul of this vibrant city."
    },
    "a311d62a-a126-40f3-98d7-e9a9c02a1d7c": {
      "title": "Echoes of Granada: Exploring Heritage and History",
      "description": "Embark on an enchanting journey through the heart of Granada, where history and beauty intertwine. Begin at the Monasterio de San Jerónimo, marveling at its stunning architecture and serene cloisters. Stroll to Puerta Real, a bustling hub blending the old and new worlds. Next, be captivated by the grandeur of Granada Cathedral, an architectural jewel radiating Gothic and Renaissance splendor. Each location unveils a unique chapter of Granada's rich tapestry, inviting you to explore its vibrant culture and timeless charm. Discover the magic of Granada in every corner!"
    },
    "a36690a3-4e86-4c51-a52a-a7fee2f73a92": {
      "title": "The London Legends: A Journey Through Time and Theatre",
      "description": "Embark on an exhilarating journey through the heart of London, starting with a captivating performance at The Theatre on Commercial Street, where history and the arts collide in a spectacular fashion. Stroll through the vibrant and eclectic neighborhoods, soaking in the dynamic energy of urban life. Then, head to Citypoint, a striking architectural masterpiece standing tall amidst the city's skyline, offering breathtaking views and a bustling hub of modern business and leisure. Along the way, discover hidden gems and iconic landmarks, making this tour an unforgettable blend of culture, history, and contemporary allure."
    },
    "a3c7596e-bc57-4250-9a72-63bd0a603c64": {
      "title": "Jaipur tour",
      "description": "Embark on an enchanting journey through the vibrant heart of Jaipur! Begin your adventure at the iconic Hawa Mahal, where the intricate lattice work and the wafting breeze create a regal aura. Next, descend into the tranquil sanctity of Kalki Mandir, whose serene atmosphere is sure to provide a moment of peace. Continue your exploration with a visit to the revered Govind Dev Ji Temple, where the spiritual ambiance and age-old traditions come alive. Each stop offers a unique blend of history, culture, and spirituality, making this tour an unforgettable Indian odyssey."
    },
    "a8019f89-566b-4fcf-b5bf-f471554dcd44": {
      "title": "The Splendors of Sacramento: Landmarks & Legacies Tour",
      "description": "Embark on a captivating journey through Sacramento's rich history and cultural gems! Begin your adventure at the Sacramento City Library, where the past and present converge in a treasure trove of knowledge. Marvel at the opulence of the Leland Stanford Mansion, a restored Victorian gem that whispers tales of California’s Gilded Age. Delve into the wealth of resources at the California State Library, exploring rare collections and historical archives. With every step, discover a city that blends timeless elegance with vibrant modernity, in an enriching tour crafted for the curious traveler. Join us for an unforgettable exploration of Sacramento's finest landmarks!"
    },
    "a8cc577c-a9e6-41c7-a3cd-060811cd85a6": {
      "title": "Top landmarks in Sofia",
      "description": "Experience the Heartbeat of Sofia: Embark on a captivating journey through Bulgaria's storied capital. Begin your adventure with a visit to the Bulgarian National Bank, a hub of financial history and architectural splendor. Next, feel the roar of excitement at the Vasil Levski National Stadium, a legendary venue for sports and cultural events. Then, let the grandeur of the National Palace of Culture overwhelm your senses as you explore this modern hub for art and performances. With every step, Sofia unveils its rich past and vibrant present, offering an unforgettable blend of history, culture, and excitement. Join us for an extraordinary tour where Sofia's landmarks become your playground!"
    },
    "ab240d15-b2b3-41bd-90cf-ee76205b9ffd": {
      "title": "Discover Kolhapur's Iconic Landmarks Tour",
      "description": "Embark on an extraordinary journey through Kolhapur, where history, culture, and spirituality converge. Begin your adventure at the iconic Khasbag Wrestling Stadium, the largest and oldest mud-wrestling arena in Maharashtra, where the thrill of traditional Kushti wrestling comes to life. Next, seek blessings at the revered Mahalakshmi Temple, an architectural marvel and a significant Shakti Peeth, exuding divine energy and serenity. Immerse yourself in the regal aura of the Chhatrapati Shahu Stadium, a celebrated sports hub named after the visionary ruler who pioneered social reforms. As you explore further, discover the myriad charms of Kolhapur—a city replete with opulent palaces, vibrant marketplaces, and delectable cuisine that promises an unforgettable cultural experience."
    },
    "acd88f23-d005-42dd-8d96-646c2a438ab6": {
      "title": "Discover Darwin: A Journey Through History, Culture, and Wildlife",
      "description": "Embark on an unforgettable adventure in the heart of Darwin! Begin your journey at the lush Bicentennial Park, a serene oasis with scenic views and historical monuments. Wander down to Lameroo Beach, where turquoise waters meet soft sands, offering a perfect spot to relax and soak in the beauty of the coastline. Next, step back in time at Lyons Cottage, the oldest stone building in Darwin, and delve into its rich heritage and captivating stories. This tour promises a delightful mix of nature, history, and breathtaking landscapes, making it a must-experience for any traveler!"
    },
    "aec93063-6ac1-4169-ad6c-c81fa71f6d21": {
      "title": "Chennai Suburban Chronicles: Perumbakkam, Medavakkam & Jalladiampet Audio Tour",
      "description": "Embark on a captivating journey through the vibrant suburbs of Chennai, encompassing the lively locales of Perumbakkam, Jalladiampet, and Medavakkam. Experience the perfect blend of urban charm and scenic beauty as you traverse bustling markets, serene lakes, and ancient temples. Immerse yourself in the local culture, savor authentic South Indian delicacies, and uncover the hidden gems nestled within these enchanting neighborhoods. This tour promises a delightful glimpse into the heart and soul of modern Chennai, welcoming you with open arms and unforgettable memories."
    },
    "af0b0195-bddc-49b1-baa0-4fad15623ab5": {
      "title": "अहमदाबाद के ऐतिहासिक स्मारकों की ध्वनिक प्रवेशयात्रा",
      "description": "अहमदाबाद की इस अद्भुत यात्रा में आपका स्वागत है। सबसे पहले, आपको रानी रूपमती की मस्जिद के दुर्लभ स्थापत्य कला के दर्शन होंगे, जहाँ पत्थरों पर की गई नक्काशी मंत्रमुग्ध कर देगी। इसके बाद आपका कदम बढ़ेगा सिदी सय्यद की मस्जिद की ओर, जो अपनी जालीदार खिड़कियों के लिए विश्व प्रसिद्ध है। यहाँ की जाली ट्री ऑफ़ लाइफ़ को देखकर आप चकित रह जाएंगे। फिर चलें वजीहुद्दीन की दरगाह की ओर, जो अपनी शांतिपूर्ण माहौल में आपको आत्मिक शांति का अनुभव कराएगी। इस सफर में और भी कई स्थानों की खोज करें और इस ऐतिहासिक शहर की संस्कृति और धरोहर को अपने दिल में बसाएं। आइये, इस मोहक यात्रा का हिस्सा बनें और अहमदाबाद की अद्वितीय विरासत को निहारें।"
    },
    "b25758d3-f5c8-46e9-9da9-2f62b7521743": {
      "title": "Ipswich: Walk Through Time",
      "description": "Embark on a captivating journey through Ipswich, where history and charm intertwine to create an unforgettable experience. Begin your adventure at the Central Congregational Church Manse, a beacon of architectural beauty and spiritual heritage. Wander next to Gooloowan, an exquisite relic of Victorian grandeur, enveloped in lush gardens that whisper tales of yesteryear. Lastly, indulge in the rich legacy of the Ipswich Club House, a sanctuary of refinement that has welcomed distinguished guests for generations. Each stop invites you to step back in time and immerse yourself in the storied past of this vibrant city—truly a tour to remember."
    },
    "b4a4f543-d4f4-419d-9003-1232b5389b4f": {
      "title": "Exploring Nottingham's Historic Gems: From St Mary the Virgin to St John the Divine",
      "description": "Embark on a captivating journey through the heart of Nottingham, beginning with the majestic Church of St Mary the Virgin and All Souls, Bulwell. Admire the stunning architecture and sense the deep history within its sacred walls. Then, step back in time at the Old Town Hall, Bulwell, where you can feel the echoes of the past in this iconic civic building. Stroll through the charming streets of Bulwell, where old-world charm meets vibrant local culture. Discover hidden gems and immerse yourself in the stories these historic treasures hold. This tour promises a delightful blend of heritage and contemporary allure, offering an unforgettable exploration of Nottingham's rich tapestry."
    },
    "b82440f9-98e1-4084-b530-0e8f36c15560": {
      "title": "Iconic Manhattan: Journey through Landmarks and Legends",
      "description": "Explore the enchanting heart of Manhattan with a delightful tour through Central Park! Begin your journey with a whimsical ride on the historic Central Park Carousel, an iconic treasure loved by all ages. Next, wander over to The Dairy, a charming Victorian cottage that now serves as a visitor center, offering a glimpse into the park’s storied past. Follow the winding paths to the majestic Statue of William Shakespeare, where you can ponder the Bard’s legacy in a lush, serene setting. Each stop unveils a new layer of the park's magic, making this an unforgettable adventure through one of New York City’s most beloved landmarks."
    },
    "be511d40-917a-4c0f-96ff-5d96fc5fe5f9": {
      "title": "Timeless Treasures of Tbilisi: An Audio Tour",
      "description": "Embark on an enchanting journey through Tbilisi, where history and modernity merge seamlessly. Begin at the stunning Bridge of Peace, a contemporary marvel offering panoramic views of the city's picturesque landscape. Wander through the historic Abanotubani district, famed for its sulfur baths and charming cobblestone streets that whisper tales of yore. Stand in awe at the Metekhi Church, perched majestically over the Mtkvari River, bearing witness to centuries of Georgian resilience and faith. This tour is a delightful blend of Tbilisi's vibrant culture, ancient traditions, and architectural splendor, promising an unforgettable adventure in the heart of Georgia."
    },
    "c0176bd8-6b1c-49aa-a21e-a52449b4d3a9": {
      "title": "Heritage Highlights of Newcastle",
      "description": "Embark on an enchanting journey through Newcastle's vibrant cultural and historical landscape. Begin your adventure at the magnificent Newcastle Civic Theatre, where timeless architecture meets captivating performances. Dive deeper into the city's rich heritage at the Newcastle Museum, where interactive exhibits and fascinating artifacts bring history to life. As the day turns to night, let loose at the iconic Argyle House, a hotspot for nightlife with its electrifying atmosphere. Along the way, uncover hidden gems and delightful surprises in this dynamic city that seamlessly blends the past with the present. Your Newcastle tour promises unforgettable memories and endless excitement!"
    },
    "c1bead35-4b42-422b-a179-16e2628f9b30": {
      "title": "ทัวร์สุดฤทธิ์เมืองกรุง: พระปราบ, วัดเทพสิรินทร์, โรงเรียนเทพสิรินทร์, สถานีรถไฟแห่งประเทศไทย, รองเมือง, วัดบรมนิเวศ, คลองมหานาค, สะพานชาตุรภักดิ์รังสฤทธิ์, สี่แยกมหานาค, โรงพยาบาลบางกอกแอดเวนติส, ถนนลานหลวง\n\nทัวร์เส้นทางตามรอยศิลปะแห่งเมืองกรุงเทพ",
      "description": "ยินดีต้อนรับสู่การผจญภัยในกรุงเทพฯ! วันนี้เราจะพาคุณสำรวจเขตป้อมปราบศัตรูพ่าย เริ่มต้นที่วัดเทพศิรินทราวาสราชวรวิหาร สถานที่ประดิษฐานพระบรมศพพร้อมความสงบและความศักดิ์สิทธิ์ จากนั้นเราเดินทางต่อสู่โรงเรียนเทพศิรินทร์ โรงเรียนที่มีประวัติศาสตร์ยาวนานและเป็นหนึ่งในสถาบันการศึกษาชั้นนำของประเทศ สุดท้ายแต่ไม่น้อยไปกว่านั้น เราจะพาคุณเยี่ยมชมสถานที่น่าตื่นเต้นอื่น ๆ ในพื้นที่นี้ มาร่วมสนุกและเต็มอิ่มกับวัฒนธรรม ประวัติศาสตร์ และความงดงามของกรุงเทพฯในทัวร์นี้กันเถอะ!"
    },
    "c1d7c231-26ab-4731-b26c-cbf4af7e3c8f": {
      "title": "Chengalpattu Chronicles: Discovering Perumbakkam, Medavakkam, and Jalladiampet",
      "description": "Embark on a captivating journey through the enchanting neighborhoods of Chennai, starting with the vibrant locality of Perumbakkam. Here, modernity meets tradition, offering a delightful blend of bustling markets and serene residential areas. Next, explore Jalladiampet, where you can immerse yourself in the local culture and savor authentic South Indian cuisine. Discover the charm of Medavakkam, a rapidly growing suburb that promises a unique mix of urban development and natural beauty, with picturesque lakes and lush green parks. This tour is perfect for those seeking a true taste of Chennai's diverse and dynamic spirit!"
    },
    "c21d20e7-cdfe-493a-affe-658ed7bde33d": {
      "title": "The Historic London Audio Adventure: From The Theatre to Commercial Street",
      "description": "Embark on an unforgettable journey through the vibrant heart of London, starting with the historic allure of The Theatre. Wander through the eclectic streets of Old Street, where modern art meets bohemian charm. Marvel at the sleek, architectural grace of Citypoint, a testament to London's innovative spirit. Each stop offers a unique glimpse into the city's rich tapestry of history, culture, and modernity, promising an adventure filled with memorable moments and delightful discoveries. Join us for a captivating exploration that showcases the best of London's timeless appeal and contemporary vigor!"
    },
    "c6bc80c9-4aec-4d1f-a85b-c0b84ccff624": {
      "title": "Jaipur Division tour",
      "description": "Embark on a mesmerizing journey through the historic splendor of Jaipur! Your adventure starts at the majestic Amber Fort, where grandiose palaces and intricate carvings take you back to the royal Rajput era. Marvel at the formidable Jaigarh Fort, boasting panoramic views and tales of ancient battles. Discover serenity at the Jagat Shiromani Temple, a stunning sanctuary adorned with intricate architecture and divine ambiance. Delve into the charming town of Amer, where cobbled streets and vibrant markets pulse with rich cultural heritage. This tour promises a delightful blend of history, art, and local charm – an experience you’ll treasure forever!"
    },
    "c897ce1a-0bdf-4896-b384-e74e8c600690": {
      "title": "The Historic Heart of London: From Charing Cross to Downing Street",
      "description": "Experience the vibrant heart of London on this captivating tour! Begin your journey at Charing Cross Road, famed for its eclectic bookshops and dynamic atmosphere. From there, make your way to the iconic Trafalgar Square, where grand historical monuments and the majestic National Gallery await. Next, stroll over to Downing Street, the seat of British political power, and see the renowned black door of Number 10. Along the way, you'll encounter charming alleyways, traditional pubs, and bustling markets, making this a truly unforgettable exploration of London's rich history and contemporary culture."
    },
    "ca5bc0ea-d5c4-45e1-9e2b-ceb88bf5312a": {
      "title": "Bath's Historic Landmarks Audio Tour",
      "description": "Embark on an enchanting journey through Bath as you stroll along the elegant Great Pulteney Street, a grand boulevard lined with stunning Georgian architecture. Your adventure continues at the Holburne Museum, where art and history blend seamlessly within this beautifully restored building and its vibrant gardens. Immerse yourself in tranquility at the picturesque Parade Gardens, offering riverside views and floral splendor. Each stop promises a unique taste of Bath’s rich heritage and timeless charm, creating a tour that's both captivating and unforgettable."
    },
    "cf54bf05-5e42-4aba-8689-f901a9c9dde6": {
      "title": "Journey through the Hidden Gems of Chennai",
      "description": "Embark on a captivating journey through the vibrant suburbs of Chennai, beginning with the serene locale of Perumbakkam, where the tranquil surroundings and modern developments create a perfect blend of nature and urbanity. Continue your exploration in Jalladiampet, a charming area known for its friendly neighborhoods and bustling markets that offer a glimpse into local life. Next, venture into Medavakkam, a rapidly growing suburb that boasts lush green spaces and a rich cultural tapestry. Along the way, discover hidden gems, savor delicious South Indian cuisine, and immerse yourself in the dynamic spirit of Chennai's thriving suburban landscape. This tour promises an enriching experience filled with vivid contrasts and delightful surprises."
    },
    "d2b1af54-6c04-405f-8a5c-aa8b87ff2e15": {
      "title": "Rediscover Red Hook: Brooklyn's Hidden Gems Tour",
      "description": "Embark on a vibrant journey through Brooklyn with our exclusive tour! Begin your adventure at Pioneer Works, where art and innovation collide in a mesmerizing space of creativity. Satisfy your culinary cravings at Defonte's, a legendary deli known for its mouth-watering sandwiches and old-school charm. Immerse yourself in the world of vintage stringed treasures at RetroFret, a haven for music enthusiasts seeking rare guitars and expert craftsmanship. Join us for an unforgettable day of culture, cuisine, and discovery in the heart of Brooklyn!"
    },
    "d664d897-28e7-43a3-a840-93522ba92299": {
      "title": "Ancient Mysteries of Siem Reap: Unveiling the Legends and Landmarks",
      "description": "Embark on a captivating journey through the heart of Cambodian history with our exclusive Siem Reap tour. Begin by marveling at the majestic Baphuon, an architectural wonder of ancient Khmer engineering. Next, step into the grandeur of The Royal Palace, a treasure trove of cultural richness and royal splendor. Wander through the enigmatic gates of Angkor Thom, the historic capital of the great Khmer Empire, and discover the impressive Phimeanakas, once the king's celestial palace. Along the way, visit other awe-inspiring sites that showcase the timeless beauty and mystique of this enchanting land. Let the echoes of a glorious past guide your adventure, leaving you with memories to cherish forever."
    },
    "d7b8cec7-0283-449d-8bbe-6247a7f34478": {
      "title": "Melbourne Majesty: Landmarks and Legends Tour",
      "description": "Embark on an unforgettable journey through Melbourne's rich heritage! Begin your day exploring the grandeur of Parliament House, Melbourne, where history and politics converge in stunning neo-classical architecture. Stroll down to 120 Collins Street, a pinnacle of modern elegance, boasting some of the city's finest views. Then, find tranquility amidst the awe-inspiring Gothic Revival beauty of St Paul's Cathedral, Melbourne. Each stop offers a unique glimpse into the heart of this vibrant city. Discover the perfect blend of historical splendor and contemporary marvels on this captivating tour!"
    },
    "d94342fe-6217-479d-8023-7eb15025269c": {
      "title": "Kagithane walking tour",
      "description": "Embark on an exciting journey through Kağıthane and discover some of Istanbul's hidden gems. Begin your adventure at the bustling Istanbul Cevahir, one of Europe's largest shopping malls, where you can indulge in world-class shopping and dining. Then, step back in time with a visit to the historic Etfal Hospital Clock Tower, an emblematic piece of the city's rich heritage. Continue your exploration at Nişantaşı Anatolian High School, a renowned institution that boasts stunning architecture and a prestigious legacy. Along the way, uncover the charm of Kağıthane's local spots and immerse yourself in the vibrant atmosphere of this fascinating district. Your tour promises a blend of culture, history, and modern allure, creating unforgettable memories in the heart of Istanbul."
    },
    "db95e0c8-6337-4c72-a0eb-424425f0de60": {
      "title": "Enchanting Nottingham: A Journey Through History",
      "description": "Embark on a captivating journey through the heart of Nottingham, where history, culture, and vibrant city life converge. Begin your adventure at the majestic Nottingham Cathedral, where stunning architecture and serene atmosphere set the stage. Stroll to the bustling Old Market Square, the beating heart of the city, offering a lively array of shops, eateries, and captivating street performances. Immerse yourself in the retail paradise of Victoria Centre, a shopping haven with an eclectic mix of high-street and luxury brands. Discover hidden gems and enjoy a seamless blend of the old and new in this enchanting tour that promises unforgettable memories."
    },
    "df16a203-0fe4-4814-a461-7e949d9b0a59": {
      "title": "Townsville Treasures: A Historic and Cultural Journey",
      "description": "Embark on an enriching journey through the heart of Townsville City, where history, culture, and natural beauty converge. Your adventure begins at the Museum of Tropical Queensland, a haven for curious minds with its captivating exhibitions on the region's maritime heritage and diverse ecosystems. Wander through Anzac Memorial Park, a tranquil oasis that pays homage to the bravery of those who served, and offers serene views perfect for reflection. As you stroll past the iconic Queen's Hotel, allow its vintage charm to transport you to an era of elegance and architectural splendor. This tour promises a delightful blend of discovery, nostalgia, and the vibrant local spirit of Townsville, ensuring an unforgettable experience."
    },
    "e1d443c7-f51c-4cf9-bb77-1e3f7fe012c0": {
      "title": "Exploring Houston's Historic Gems: From Main Street Market Square to Spirit of the Confederacy",
      "description": "Embark on a captivating journey through Houston's vibrant heart with our exclusive tour of the Main Street Market Square Historic District. Wander through the charming streets steeped in history and charm, where stunning architecture meets lively contemporary culture. Take a serene stroll through Market Square Park, a lush urban oasis perfect for unwinding amidst the bustle of the city. Continue to the picturesque Sesquicentennial Park, offering stunning views of downtown Houston and the tranquil waters of Buffalo Bayou. This tour offers a perfect blend of historical insights, natural beauty, and modern allure—an unforgettable experience for all!"
    },
    "e3e8f68d-38ab-4414-b402-537ffb13ab02": {
      "title": "Top landmarks in Istanbul",
      "description": "Embark on an unforgettable journey through Istanbul's rich tapestry of history and culture! Start at the awe-inspiring Hagia Sophia, where centuries of art and architecture stand in magnificent harmony. Feel the whispers of the past at the majestic Sublime Porte, once the grand gateway to Ottoman power. Continue your adventure to the opulent Topkapı Palace, where sultans once lived in unparalleled splendor. Along the way, discover hidden gems and enchanting stories that make this timeless city one of the world's most captivating destinations. Join us for a tour filled with wonder, history, and the magic of Istanbul!"
    },
    "e416bc09-fb3f-4d2a-ac75-8882528b0a6e": {
      "title": "Exploring the Historic Gems of Tunis: From Mosques to Monuments",
      "description": "Embark on an enchanting journey through the historic heart of Tunis! Begin your adventure at Bab el Bhar, the iconic gateway that seamlessly melds the ancient medina with the modern city. Wander through the intriguing aisles of the Diocesan Library of Tunis, where time stands still amidst rare manuscripts and captivating stories. Feel the spiritual aura at Hammouda Pacha Mosque, a marvel of Ottoman architecture, boasting intricate designs and a serene ambiance. As you explore these and other hidden gems, immerse yourself in the rich tapestry of Tunisian culture and history. This tour is a mesmerizing blend of the past and present, promising memories that will linger long after your adventure ends."
    },
    "e4ef8353-3604-473f-ae98-4528d0620032": {
      "title": "Tokyo Landmark Odyssey: Exploring the Essence of Tradition and Modernity",
      "description": "**東京の魅力を発見する心躍る旅**\n\n東京の中心で、GranTokyo、八重洲、そしてアーティゾン美術館といった素晴らしいスポットを巡る特別なツアーへようこそ。まずはGranTokyoでその先進的なデザインと絶景を楽しみましょう。そして、八重洲の活気あるショッピングエリアでショッピングやグルメを満喫。さらに、アーティゾン美術館では美術の世界に浸り、圧巻のコレクションに心を奪われます。その他にも見逃せないスポットが満載のこの旅、東京の新旧が織りなす独特な文化をたっぷりと体感してみませんか？"
    },
    "e52d1b3f-b0e6-43bf-a407-bc753a5a6a10": {
      "title": "Cultural Heart of Adelaide",
      "description": "Embark on an enchanting journey through the heart of Adelaide with our vibrant city tour! Begin your exploration at the iconic Victoria Square, where modern design meets rich history, creating a picturesque hub that radiates the city's charm. Delight your senses at the bustling Adelaide Central Market, a food lover's paradise filled with fresh produce, gourmet treats, and the aromas of multicultural cuisines. Step back in time at the grand Adelaide Town Hall, a stunning heritage building that whispers tales of the city's past and resonates with cultural events. As you wander through these captivating sites and more, immerse yourself in the unique blend of culture, history, and culinary delight that Adelaide offers, ensuring an unforgettable experience in this southern Australian gem."
    },
    "e9622abb-d523-486e-aed7-0387dfedf311": {
      "title": "Sydney's Majestic Skyline Journey",
      "description": "Experience the best of Sydney with our captivating city tour! Begin your adventure with a visit to the iconic Sydney Opera House, where architectural marvel meets artistic brilliance. Ascend to new heights at the Sydney Tower for panoramic views that will leave you breathless. Wander through the lush greenery of Hyde Park, a tranquil oasis amidst the urban hustle. Discover hidden gems and immerse yourself in the vibrant culture and history of the city. This tour promises unforgettable sights, stories, and a true taste of Sydney's charm. Don’t miss out on this enchanting journey through Australia's most stunning metropolis!"
    },
    "ec386799-4f5e-45b6-85f8-ba74a072af25": {
      "title": "The Exploring Panaji's Cultural Gems Tour",
      "description": "Embark on an enchanting cultural journey through the vibrant heart of Panaji! Begin your exploration at Sunaparanta, Goa Centre for the Arts, where creativity and innovation come alive through captivating exhibits and artistic expressions. Continue your adventure at the Krishnadas Shama Goa State Central Library, a treasure trove of knowledge and history, perfect for literature enthusiasts and curious minds alike.\n\nAs you stroll through the charming streets of Fontainhas, Panaji’s Latin Quarter, you'll be transported to another world with its picturesque Portuguese-style architecture, vibrant facades, and quaint, narrow lanes. Let yourself be captivated by the rich heritage and unique charm of this historical neighborhood. Delve deeper into local culture with additional hidden gems and serene spots around Panaji, each offering a new perspective of this beautiful city. Join us for an unforgettable tour that seamlessly blends art, history, and the enchanting allure of Panaji!"
    },
    "ee7378c6-b4ea-4f70-a807-c7ed1d281a6e": {
      "title": "Toowoomba City Heritage Walk: Step into History",
      "description": "Embark on an enchanting journey through the heart of Toowoomba, starting with a visit to the iconic Empire Theatre, a beacon of culture and elegance with its stunning art deco architecture and vibrant performances. Stroll to the majestic Toowoomba City Hall, a historical landmark that beautifully encapsulates the city’s rich heritage. Uncover the charm of Carlton House, an architectural gem that adds a touch of old-world sophistication to your tour. Each step through Toowoomba’s bustling streets reveals hidden treasures and captivating stories, making this tour an unforgettable blend of history, culture, and local charm. Join us and let Toowoomba's timeless allure captivate your senses!"
    },
    "efa9f737-fd1f-4e3c-b4c5-bf4e87612ba4": {
      "title": "Coastal Chronicles: Exploring Kochi's Historic Treasures",
      "description": "Embark on an enchanting journey through the historic heart of Kochi! Start your adventure at Fort Kochi Beach, where the whispers of the Arabian Sea blend with the rich tapestry of colonial history. Stroll along the sandy shores as you absorb the blend of Indian and European influences that make this beach a true cultural melting pot. Next, stand in awe at the Santa Cruz Cathedral Basilica, a revered edifice of spiritual grandeur and architectural marvel, steeped in over five centuries of fascinating history. Continue to the Roman Catholic Diocese of Cochin, where the serene ambiance and intricate designs provide a serene respite. Discover a myriad of hidden gems and local delights along the way, making this tour a delightful tapestry of scenic beauty, historical depth, and spiritual richness."
    },
    "f19a8ef6-0141-443e-9e80-8517f1b3a058": {
      "title": "Rome's Timeless Treasures: A Journey Through Ancient Landmarks",
      "description": "Embark on an enchanting journey through the heart of Rome, beginning with the majestic Arch of Constantine, a timeless triumphal arch celebrating the emperor's victory. As you wander, discover the intriguing Bocca della Verità, the ancient stone mask said to reveal truths and secrets. Venture further into history with a visit to the Forum Boarium, a bustling center of commerce in ancient times, replete with fascinating ruins and iconic temples. This tour is a captivating blend of grandeur and mystery, where each step unveils a new chapter of Rome's storied past."
    },
    "f9d9771a-66b8-4d56-b4c5-b46c2edc6d21": {
      "title": "Cultural and Historical Highlights of Townsville City",
      "description": "Embark on an enchanting journey through Townsville, where history, art, and architecture intertwine. Begin your adventure at the stunning St James' Cathedral, an iconic landmark that boasts Gothic Revival splendor and serene gardens. Stroll over to the historic Stanton House, a testament to the city's rich heritage with its grand façade and intriguing past. Next, awaken your senses at the Perc Tucker Regional Gallery, where contemporary art meets traditional masterpieces, promising an inspiring experience for every visitor. This tour promises a captivating blend of cultural treasures, making it an unforgettable day in Townsville."
    },
    "fa03730c-a7f7-4167-9818-15ea462874ec": {
      "title": "Discovering Lisbon's Iconic Treasures: From Bairro Alto to Praça do Comércio",
      "description": "Discover the enchanting charm of Lisbon with a captivating tour through its most iconic locales. Begin your journey in Bairro Alto, a vibrant neighborhood known for its lively nightlife, historic architecture, and artistic soul. Marvel at the detailed craftsmanship of the Statue of António Vieira, a tribute to one of Portugal's most influential Jesuits. Then, step into the timeless elegance of Café A Brasileira, where literary legends once sipped coffee, and immerse yourself in the rich cultural atmosphere. This tour embraces a perfect blend of history, art, and vivacious local spirit, promising an unforgettable experience in the heart of Lisbon."
    },
    "fce97060-4bd5-4c3a-b9d1-3802c30c0dfc": {
      "title": "Discovering Warsaw's Cultural Treasures: A Journey through Historic Landmarks!",
      "description": "Serdecznie zapraszamy na fascynującą wycieczkę po Warszawie, podczas której odwiedzisz jedne z najważniejszych i najbardziej urokliwych miejsc w stolicy Polski. Nasza trasa rozpocznie się na Placu Trzech Krzyży, gdzie historia spotyka się z nowoczesnością. Następnie udamy się do Giełdy Papierów Wartościowych, serca finansowego miasta, gdzie poczujemy puls warszawskiego rynku kapitałowego. Kolejnym punktem wycieczki będzie Muzeum Narodowe w Warszawie, skarbnica polskiej sztuki i kultury. Odkryjemy tam dzieła, które zachwycają swoją różnorodnością i bogactwem. Cała trasa obfituje w ciekawe przystanki, które ukazują zarówno historyczne, jak i współczesne oblicze Warszawy. Przygotuj się na niezapomnianą przygodę pełną wrażeń i wiedzy!"
    },
    "fd48f23e-9d1f-4640-b8dd-1946afa3a7be": {
      "title": "Colonial Chronicles: The Legacy of Chennai",
      "description": "Embark on an unforgettable journey through the heart of Chennai, beginning with the historical Madras Presidency and its rich colonial legacy. Immerse yourself in the fascinating stories of Fort St. George, the first English fortress in India, where the past echoes through ancient walls. Explore the bustling streets and vibrant culture of Chennai, each corner offering a new discovery. From its majestic architecture to its colorful markets, this tour promises a blend of history, tradition, and modern urban charm, leaving you captivated at every turn."
    }
  }
}