import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AdminAuthGuard, AdminAuthService } from '../admin-auth-service';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css'],
})
export class AdminLoginComponent implements OnInit {
  adminCode = 'alltours4life';
  enteredCode = '';
  returnUrl;

  constructor(
    private authGuard: AdminAuthGuard,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) public platformId: Object
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  loginUser() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.adminCode == this.enteredCode) {
        localStorage.setItem('adminToken', 'secretAdminToken');
        localStorage.setItem('is_admin', 'true');
        if (this.returnUrl != undefined) {
          this.router.navigateByUrl(this.returnUrl);
        } else {
          this.router.navigate(['/admin']);
        }
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    }
  }
}
