import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import countriesNamesJson from '../../../../../assets/json/countries.json';
import citiesJson from '../../../../../assets/json/mini_cities.json';
import { Router } from '@angular/router';
import { PartnerService } from '../../partner-service';
import { logEventMethod } from 'src/app/utils/logging';
import { Auth, createUserWithEmailAndPassword } from '@angular/fire/auth';
import { getFunctions, httpsCallableData } from '@angular/fire/functions';
import { doc, getFirestore, setDoc } from '@angular/fire/firestore';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { setSeoText } from 'src/app/utils/utils';

@Component({
  selector: 'app-partner-signup',
  templateUrl: './partner-signup.component.html',
  styleUrls: ['./partner-signup.component.css', '../../../../app.component.css'],
})
export class PartnerSignupComponent implements OnInit {

  editMode = false

  cities = citiesJson.cities
  filteredCities = []
  selectedCities = []
  showCitySearch = true

  socialLinks = []

  partnerType;
  partner;
  loading = false;
  submitted = false;
  password;
  passwordConfirm;
  countryNames

  constructor(private router: Router, private partnerService: PartnerService, private auth: Auth, @Inject(DOCUMENT) public _document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    this.countryNames = JSON.parse(JSON.stringify(countriesNamesJson));
    if (isPlatformBrowser(this.platformId)) {
      logEventMethod("partner_signup_page_view");
    }
    setSeoText(this._document, 'AllTours - Partner Signup', 'Join AllTours and earn a 40% commission for every tour purchased through your referral. Start your journey to unlimited earning potential today!');
  }

  selectPartnerType(id) {
    this.partnerType = id;
    if (isPlatformBrowser(this.platformId)) {
      logEventMethod("partner_type_selected", { type: id });
    }
    setTimeout(() => {
      this.setupForm();
    }, 500);
  }

  setupForm() {
    var form = this._document.getElementById('signupForm')
    let thisRef = this
    form.addEventListener('submit', function (event) {
      event.preventDefault()
      const formProps = Object.fromEntries(new FormData(event.target as any) as any);
      thisRef.password = formProps.password
      thisRef.passwordConfirm = formProps.passwordConfirm
      thisRef.partner = {
        name: formProps.contactName,
        email: formProps.emailAddress,
        partnerType: thisRef.partnerType,
        agreedToTerms: formProps.agreedToTerms != undefined,
      }
      if (thisRef.partnerType == thisRef.INDIVIDUAL) {
        thisRef.partner.socialLinks = thisRef.getSocialLinks()
      } else {
        thisRef.partner.businessName = formProps.businessName
        thisRef.partner.city = thisRef.selectedCities[0]
        thisRef.partner.cities = thisRef.selectedCities
      }

      thisRef.submitted = true
      if (thisRef.passesValidation()) {
        thisRef.signUp()
      }
    }, false)
  }

  passesValidation() {
    if (this.partnerType == this.INDIVIDUAL) {
      if (this.partner.socialLinks.length == 0) {
        alert("Please enter a link to your social media or your website.")
        return false
      }
    } else {
      if (!this.validateText(this.partner.businessName, "a business name")) return false
      if (this.partner.cities.length == 0) {
        alert("Please select a city from the dropdown")
        return false
      }
    }

    if (!this.validateText(this.partner.name, "your name")) return false
    if (!this.validateText(this.partner.email, "an email address")) return false
    if (!this.validateText(this.password, "a password")) return false
    if (this.password.length < 6) {
      alert("Password must be at least 6 characters")
      return false
    }
    if (this.password != this.passwordConfirm) {
      alert("Passwords do not match")
      return false
    }
    let validEmailRegex = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!validEmailRegex.test(this.partner.email)) {
      alert("Please enter a valid email address")
      return false
    }
    if (!this.partner.agreedToTerms) {
      alert("Please agree to the terms and conditions")
      return false
    }
    console.log("Passed validation")
    return true
  }

  validateText(text, textName) {
    if (text == undefined || text.trim().length == 0) {
      alert(`Please enter ${textName}`)
      return false
    }
    return true
  }

  signUp() {
    this.loading = true;
    createUserWithEmailAndPassword(this.auth, this.partner.email, this.password).then(async userCredential => {
      console.log("user created", userCredential.user.email);
      let name = this.partner.partnerType == this.INDIVIDUAL ? this.partner.name : this.partner.businessName
      const getPartnerLinkId = httpsCallableData(getFunctions(), 'getPartnerLinkId');
      getPartnerLinkId({ businessName: name }).subscribe(async (result: any) => {
        let linkId = result;
        console.log("link ID generated", linkId)
        var user = userCredential.user;
        this.partner.id = linkId
        this.partner.authId = user.uid
        this.partner.level = 1
        this.partner.commission = 0.4
        await setDoc(doc(getFirestore(), 'partners', linkId), this.partner);
        let loginSuccess = await this.partnerService.loggedInAsPartner()
        if (isPlatformBrowser(this.platformId)) {
          logEventMethod("partner_signup_success", { type: this.partnerType });
          let navigateSuccess = await this.router.navigate(['/partner']);
          if (!navigateSuccess) {
            console.error('failed to navigate to partner dashboard');
            window.location.href = `${window.location.origin}/partner`;
          }
        }
        this.loading = false
      });
    })
      .catch(error => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error('error on signup', errorCode, errorMessage);
        alert(errorMessage);
        this.loading = false;
      });
  }

  filterCities(value) {
    const filterValue = value.toLowerCase();
    this.filteredCities = this.cities.filter(city => city.name.toLowerCase().includes(filterValue)).slice(0, 10);
  }

  selectCity(city) {
    this.showCitySearch = false
    this.selectedCities.push(city)
    this.filteredCities = []
    let field = this._document.getElementById('citySearchInput') as any
    field.value = city.name
  }

  removeCity(city) {
    this.selectedCities = this.selectedCities.filter(c => c != city)
    this.showCitySearch = this.selectedCities.length == 0
  }

  removeSocialLink(link) {
    this.socialLinks = this.socialLinks.filter(l => l != link)
  }

  addToLinks() {
    let field = this._document.getElementById("newLinkInput") as any
    let newLink = field.value
    field.value = ""
    this.socialLinks.push(newLink)
  }

  getSocialLinks() {
    let field = this._document.getElementById("newLinkInput") as any
    let link = field.value
    var links = [...this.socialLinks]
    links.push(link)
    return [...new Set(links)];
  }

  ACCOMMODATION = 'accommodation';
  INDIVIDUAL = 'individual';
  OTHER_BUSINESS = 'other_business';

  partnerTypes = [
    {
      id: this.ACCOMMODATION,
      title: 'Accommodation',
      description: 'Hotel/Hostel',
      icon: 'hotel',
    },
    {
      id: this.INDIVIDUAL,
      title: 'Individual',
      description: 'Influencer/Personality',
      icon: 'person',
    },
    {
      id: this.OTHER_BUSINESS,
      title: 'Other Business',
      description: 'Shop/Restaurant',
      icon: 'business',
    },
  ];
}
