{
  "cities": [
    {
      "id": 0,
      "name": "Tokyo",
      "names": {
        "es": "Tokio",
        "zh": "东京",
        "de": "Tokio",
        "ja": "東京都",
        "th": "โตเกียว",
        "pt": "Tóquio"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6839,
        "lng": 139.7744
      },
      "country": "Japan",
      "importance": 0
    },
    {
      "id": 1,
      "name": "Jakarta",
      "names": {
        "es": "Yakarta",
        "zh": "雅加达",
        "ja": "ジャカルタ",
        "th": "จาการ์ตา",
        "pt": "Jacarta"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.2146,
        "lng": 106.8451
      },
      "country": "Indonesia",
      "importance": 0
    },
    {
      "id": 2,
      "name": "Delhi",
      "names": {
        "zh": "新德里",
        "ja": "デリー",
        "th": "นิวเดลี",
        "pt": "Deli"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.6667,
        "lng": 77.2167
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 3,
      "name": "Manila",
      "names": {
        "fr": "Manille",
        "zh": "马尼拉",
        "ja": "マニラ",
        "th": "มะนิลา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6,
        "lng": 120.9833
      },
      "country": "Philippines",
      "importance": 0
    },
    {
      "id": 4,
      "name": "Sao Paulo",
      "names": {
        "zh": "圣保罗",
        "ja": "サンパウロ",
        "th": "เซาเปาโล",
        "pt": "São Paulo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5504,
        "lng": -46.6339
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 5,
      "name": "Seoul",
      "names": {
        "es": "Seúl",
        "fr": "Séoul",
        "zh": "首尔",
        "ja": "ソウル",
        "th": "โซล",
        "pt": "Seul"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.56,
        "lng": 126.99
      },
      "country": "South Korea",
      "importance": 0
    },
    {
      "id": 6,
      "name": "Mumbai",
      "names": {
        "zh": "孟买",
        "ja": "ムンバイ",
        "th": "มุมไบ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.0758,
        "lng": 72.8775
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 7,
      "name": "Shanghai",
      "names": {
        "zh": "上海",
        "ja": "上海",
        "th": "เซี่ยงไฮ้"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1667,
        "lng": 121.4667
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 8,
      "name": "Mexico City",
      "names": {
        "es": "Ciudad de México",
        "fr": "Mexico",
        "zh": "墨西哥城市",
        "de": "Mexiko Stadt",
        "it": "Città del Messico",
        "ja": "メキシコシティ",
        "th": "เม็กซิโกซิตี้",
        "pt": "Cidade do México"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4333,
        "lng": -99.1333
      },
      "country": "Mexico",
      "importance": 0
    },
    {
      "id": 9,
      "name": "Guangzhou",
      "names": {
        "zh": "广州",
        "ja": "広州",
        "th": "กว่างโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1288,
        "lng": 113.259
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 10,
      "name": "Cairo",
      "names": {
        "es": "El Cairo",
        "fr": "Le Caire",
        "zh": "开罗",
        "de": "Kairo",
        "it": "Il Cairo",
        "id": "Kairo",
        "ja": "カイロ",
        "th": "ไคโร"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.0444,
        "lng": 31.2358
      },
      "country": "Egypt",
      "importance": 0
    },
    {
      "id": 11,
      "name": "Beijing",
      "names": {
        "zh": "北京",
        "de": "Peking",
        "it": "Pechino",
        "ja": "北京",
        "th": "ปักกิ่ง",
        "pt": "Pequim"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.904,
        "lng": 116.4075
      },
      "country": "China",
      "importance": 0
    },
    {
      "id": 12,
      "name": "New York",
      "names": {
        "es": "Nueva York",
        "zh": "纽约",
        "ja": "ニューヨーク",
        "th": "นิวยอร์ก",
        "pt": "Nova york"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6943,
        "lng": -73.9249
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 13,
      "name": "Kolkata",
      "names": {
        "zh": "加尔各答",
        "ja": "コルカタ",
        "th": "โกลกาตา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.5727,
        "lng": 88.3639
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 14,
      "name": "Moscow",
      "names": {
        "es": "Moscú",
        "fr": "Moscou",
        "zh": "莫斯科",
        "de": "Moskau",
        "it": "Mosca",
        "id": "Moskow",
        "ja": "モスクワ",
        "th": "มอสโก"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7558,
        "lng": 37.6178
      },
      "country": "Russia",
      "importance": 0
    },
    {
      "id": 15,
      "name": "Bangkok",
      "names": {
        "zh": "曼谷",
        "ja": "バンコク",
        "th": "กรุงเทพฯ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.75,
        "lng": 100.5167
      },
      "country": "Thailand",
      "importance": 0
    },
    {
      "id": 16,
      "name": "Dhaka",
      "names": {
        "zh": "达卡",
        "it": "Dacca",
        "ja": "ダッカ",
        "th": "ธากา"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 23.7289,
        "lng": 90.3944
      },
      "country": "Bangladesh",
      "importance": 0
    },
    {
      "id": 17,
      "name": "Buenos Aires",
      "names": {
        "zh": "布宜诺斯艾利斯",
        "ja": "ブエノスアイレス",
        "th": "บัวโนสไอเรส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5997,
        "lng": -58.3819
      },
      "country": "Argentina",
      "importance": 0
    },
    {
      "id": 18,
      "name": "Osaka",
      "names": {
        "zh": "大阪",
        "ja": "大阪",
        "th": "โอซาก้า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.752,
        "lng": 135.4582
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 19,
      "name": "Lagos",
      "names": {
        "zh": "拉戈斯",
        "ja": "ラゴス",
        "th": "ลากอส"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.45,
        "lng": 3.4
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 20,
      "name": "Istanbul",
      "names": {
        "es": "Estambul",
        "zh": "伊斯坦布尔",
        "ja": "イスタンブール",
        "th": "อิสตันบูล"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.01,
        "lng": 28.9603
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 21,
      "name": "Karachi",
      "names": {
        "zh": "卡拉奇",
        "de": "Karatschi",
        "ja": "カラチ",
        "th": "การาจี"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 24.86,
        "lng": 67.01
      },
      "country": "Pakistan",
      "importance": 1
    },
    {
      "id": 22,
      "name": "Kinshasa",
      "names": {
        "zh": "金沙萨",
        "ja": "キンシャサ",
        "th": "กินชาซา"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -4.3317,
        "lng": 15.3139
      },
      "country": "Congo (Kinshasa)",
      "importance": 0
    },
    {
      "id": 23,
      "name": "Shenzhen",
      "names": {
        "zh": "深圳",
        "ja": "深セン",
        "th": "เซินเจิ้น"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.535,
        "lng": 114.054
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 24,
      "name": "Bangalore",
      "names": {
        "fr": "Bangalore,",
        "zh": "班加罗尔",
        "ja": "バンガロール",
        "th": "บังกาลอร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 12.9791,
        "lng": 77.5913
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 25,
      "name": "Ho Chi Minh City",
      "names": {
        "es": "Ciudad Ho Chi Minh",
        "fr": "Ho Chi Minh-Ville",
        "zh": "胡志明市",
        "id": "Kota Ho Chi Minh",
        "ja": "ホーチミン市",
        "th": "เมืองโฮจิมินห์",
        "pt": "Cidade de Ho Chi Minh"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.8167,
        "lng": 106.6333
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 26,
      "name": "Tehran",
      "names": {
        "es": "Teherán",
        "fr": "Téhéran",
        "zh": "德黑兰",
        "de": "Teheran",
        "it": "Teheran",
        "id": "Teheran",
        "ja": "テヘラン",
        "th": "เตหะราน",
        "pt": "Teerã"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7,
        "lng": 51.4167
      },
      "country": "Iran",
      "importance": 0
    },
    {
      "id": 27,
      "name": "Los Angeles",
      "names": {
        "zh": "洛杉矶",
        "ja": "ロサンゼルス",
        "th": "ลอสแองเจลิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1139,
        "lng": -118.4068
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 28,
      "name": "Rio de Janeiro",
      "names": {
        "es": "Río de Janeiro",
        "zh": "里约热内卢",
        "ja": "リオデジャネイロ",
        "th": "ริโอเดอจาเนโร"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9083,
        "lng": -43.1964
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 29,
      "name": "Chengdu",
      "names": {
        "zh": "成都",
        "ja": "成都",
        "th": "เฉิงตู"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.66,
        "lng": 104.0633
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 30,
      "name": "Baoding",
      "names": {
        "zh": "保定"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.8671,
        "lng": 115.4845
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 31,
      "name": "Chennai",
      "names": {
        "zh": "钦奈",
        "ja": "チェンナイ",
        "th": "เจนไน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 13.0825,
        "lng": 80.275
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 32,
      "name": "Lahore",
      "names": {
        "zh": "拉合尔",
        "ja": "ラホール",
        "th": "์"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.5497,
        "lng": 74.3436
      },
      "country": "Pakistan",
      "importance": 1
    },
    {
      "id": 33,
      "name": "London",
      "names": {
        "es": "Londres",
        "fr": "Londres",
        "zh": "伦敦",
        "it": "Londra",
        "ja": "ロンドン",
        "th": "ลอนดอน",
        "pt": "Londres"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5072,
        "lng": -0.1275
      },
      "country": "United Kingdom",
      "importance": 0
    },
    {
      "id": 34,
      "name": "Paris",
      "names": {
        "es": "París",
        "zh": "巴黎",
        "it": "Parigi",
        "ja": "パリ",
        "th": "ปารีส"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8566,
        "lng": 2.3522
      },
      "country": "France",
      "importance": 0
    },
    {
      "id": 35,
      "name": "Tianjin",
      "names": {
        "zh": "天津",
        "ja": "天津",
        "th": "เทียนจิน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.1467,
        "lng": 117.2056
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 36,
      "name": "Linyi",
      "names": {
        "zh": "临沂"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.0606,
        "lng": 118.3425
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 37,
      "name": "Shijiazhuang",
      "names": {
        "zh": "石家庄",
        "ja": "石家荘",
        "th": "ฉือเจียจวง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0422,
        "lng": 114.5086
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 38,
      "name": "Zhengzhou",
      "names": {
        "zh": "郑州",
        "ja": "鄭州",
        "th": "เจิ้งโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7492,
        "lng": 113.6605
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 39,
      "name": "Nanyang",
      "names": {
        "zh": "南洋",
        "th": "นันยาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9987,
        "lng": 112.5292
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 40,
      "name": "Hyderabad",
      "names": {
        "zh": "海得拉巴",
        "ja": "ハイデラバード",
        "th": "ในไฮเดอราบาด"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 17.3617,
        "lng": 78.4747
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 41,
      "name": "Wuhan",
      "names": {
        "zh": "武汉",
        "ja": "武漢",
        "th": "หวู่ฮั่น"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.5872,
        "lng": 114.2881
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 42,
      "name": "Handan",
      "names": {
        "zh": "邯郸",
        "ja": "邯鄲"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.6116,
        "lng": 114.4894
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 43,
      "name": "Nagoya",
      "names": {
        "zh": "名古屋",
        "ja": "名古屋",
        "th": "นาโกย่า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1167,
        "lng": 136.9333
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 44,
      "name": "Weifang",
      "names": {
        "zh": "潍坊",
        "ja": "濰坊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.7167,
        "lng": 119.1
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 45,
      "name": "Lima",
      "names": {
        "zh": "利马",
        "ja": "リマ",
        "th": "ลิมา"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -12.06,
        "lng": -77.0375
      },
      "country": "Peru",
      "importance": 0
    },
    {
      "id": 46,
      "name": "Zhoukou",
      "names": {
        "zh": "周口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.625,
        "lng": 114.6418
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 47,
      "name": "Luanda",
      "names": {
        "zh": "罗安达",
        "ja": "ルアンダ",
        "th": "ลูอันดา"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -8.8383,
        "lng": 13.2344
      },
      "country": "Angola",
      "importance": 0
    },
    {
      "id": 48,
      "name": "Ganzhou",
      "names": {
        "zh": "赣州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.8292,
        "lng": 114.9336
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 49,
      "name": "Tongshan",
      "names": {
        "zh": "铜山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.261,
        "lng": 117.1859
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 50,
      "name": "Kuala Lumpur",
      "names": {
        "zh": "吉隆坡",
        "ja": "クアラルンプール",
        "th": "กัวลาลัมเปอร์"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.1478,
        "lng": 101.6953
      },
      "country": "Malaysia",
      "importance": 0
    },
    {
      "id": 51,
      "name": "Chicago",
      "names": {
        "zh": "芝加哥",
        "ja": "シカゴ",
        "th": "ชิคาโก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8373,
        "lng": -87.6862
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 52,
      "name": "Heze",
      "names": {
        "zh": "菏泽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.2333,
        "lng": 115.4333
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 53,
      "name": "Chongqing",
      "names": {
        "zh": "重庆",
        "ja": "重慶",
        "th": "ฉงชิ่ง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.55,
        "lng": 106.5069
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 54,
      "name": "Hanoi",
      "names": {
        "zh": "河内",
        "ja": "ハノイ",
        "th": "ฮานอย"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.0245,
        "lng": 105.8412
      },
      "country": "Vietnam",
      "importance": 0
    },
    {
      "id": 55,
      "name": "Fuyang",
      "names": {
        "zh": "阜阳",
        "ja": "阜陽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.8986,
        "lng": 115.8045
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 56,
      "name": "Changsha",
      "names": {
        "zh": "长沙",
        "ja": "長沙",
        "th": "ฉางชา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.1987,
        "lng": 112.9709
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 57,
      "name": "Dongguan",
      "names": {
        "zh": "东莞",
        "ja": "東莞",
        "th": "ตงกวน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.0475,
        "lng": 113.7493
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 58,
      "name": "Jining",
      "names": {
        "zh": "济宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4,
        "lng": 116.5667
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 59,
      "name": "Jinan",
      "names": {
        "zh": "济南",
        "ja": "済南",
        "th": "จี่หนาน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.6667,
        "lng": 116.9833
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 60,
      "name": "Pune",
      "names": {
        "zh": "浦那",
        "ja": "プネー",
        "th": "ปูน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 18.5196,
        "lng": 73.8553
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 61,
      "name": "Foshan",
      "names": {
        "zh": "佛山",
        "ja": "佛山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.0292,
        "lng": 113.1056
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 62,
      "name": "Bogota",
      "names": {
        "es": "Bogotá",
        "zh": "波哥大",
        "ja": "ボゴタ",
        "th": "โบโกตา",
        "pt": "Bogotá"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6126,
        "lng": -74.0705
      },
      "country": "Colombia",
      "importance": 0
    },
    {
      "id": 64,
      "name": "Nanjing",
      "names": {
        "zh": "南京",
        "ja": "南京",
        "th": "หนานจิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.05,
        "lng": 118.7667
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 63,
      "name": "Ahmedabad",
      "names": {
        "zh": "艾哈迈达巴德",
        "ja": "アーメダバッド",
        "th": "อาเมดาบัด"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.03,
        "lng": 72.58
      },
      "country": "India",
      "importance": 2
    },
    {
      "id": 65,
      "name": "Changchun",
      "names": {
        "zh": "长春",
        "ja": "長春",
        "th": "ฉางชุน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.9,
        "lng": 125.2
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 66,
      "name": "Tangshan",
      "names": {
        "zh": "唐山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.6292,
        "lng": 118.1742
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 67,
      "name": "Cangzhou",
      "names": {
        "zh": "沧州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.3037,
        "lng": 116.8452
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 68,
      "name": "Dar es Salaam",
      "names": {
        "zh": "达累斯萨拉姆",
        "de": "Dars Salaam",
        "ja": "ダルエスサラーム",
        "th": "ดาร์เอสซาลาแฮม"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.8,
        "lng": 39.2833
      },
      "country": "Tanzania",
      "importance": 0
    },
    {
      "id": 69,
      "name": "Hefei",
      "names": {
        "zh": "合肥",
        "ja": "合肥",
        "th": "เหอเฟย์"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8639,
        "lng": 117.2808
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 70,
      "name": "Hong Kong",
      "names": {
        "zh": "香港",
        "de": "Hongkong",
        "id": "Hongkong",
        "ja": "香港",
        "th": "ฮ่องกง"
      },
      "countryCode": "HK",
      "latLng": {
        "lat": 22.3069,
        "lng": 114.1831
      },
      "country": "Hong Kong",
      "importance": 0
    },
    {
      "id": 71,
      "name": "Shaoyang",
      "names": {
        "zh": "邵阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.2418,
        "lng": 111.4725
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 72,
      "name": "Zhanjiang",
      "names": {
        "zh": "湛江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.1967,
        "lng": 110.4031
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 73,
      "name": "Shangqiu",
      "names": {
        "zh": "商丘"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.4259,
        "lng": 115.6467
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 74,
      "name": "Nantong",
      "names": {
        "zh": "南通",
        "ja": "南通",
        "th": "หนานทง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.9829,
        "lng": 120.8873
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 75,
      "name": "Yancheng",
      "names": {
        "zh": "盐城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.3936,
        "lng": 120.1339
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 76,
      "name": "Nanning",
      "names": {
        "zh": "南宁",
        "th": "หนานหนิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.8192,
        "lng": 108.315
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 77,
      "name": "Hengyang",
      "names": {
        "zh": "衡阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.8968,
        "lng": 112.5857
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 78,
      "name": "Zhumadian",
      "names": {
        "zh": "驻马店"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9773,
        "lng": 114.0253
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 79,
      "name": "Shenyang",
      "names": {
        "zh": "沉阳",
        "ja": "瀋陽",
        "th": "เสิ่นหยาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.8039,
        "lng": 123.4258
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 80,
      "name": "Xingtai",
      "names": {
        "zh": "邢台"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.0659,
        "lng": 114.4753
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 81,
      "name": "Xi\u0027an",
      "names": {
        "zh": "西安",
        "id": "Xian",
        "ja": "西安",
        "th": "ซีอาน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.2667,
        "lng": 108.9
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 82,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.45,
        "lng": -70.6667
      },
      "country": "Chile",
      "importance": 0
    },
    {
      "id": 83,
      "name": "Yantai",
      "names": {
        "zh": "烟台"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3997,
        "lng": 121.2664
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 84,
      "name": "Riyadh",
      "names": {
        "es": "Riad",
        "fr": "Riyad",
        "zh": "利雅得",
        "de": "Riad",
        "ja": "リヤド",
        "th": "ริยาด"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 24.65,
        "lng": 46.71
      },
      "country": "Saudi Arabia",
      "importance": 0
    },
    {
      "id": 85,
      "name": "Luoyang",
      "names": {
        "zh": "洛阳",
        "ja": "洛陽",
        "th": "ลั่วหยาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.6587,
        "lng": 112.4245
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 86,
      "name": "Kunming",
      "names": {
        "zh": "昆明",
        "ja": "昆明",
        "th": "คุนหมิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0433,
        "lng": 102.7061
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 87,
      "name": "Shangrao",
      "names": {
        "zh": "上饶"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.4419,
        "lng": 117.9633
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 88,
      "name": "Hangzhou",
      "names": {
        "zh": "杭州",
        "ja": "杭州",
        "th": "หางโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.25,
        "lng": 120.1675
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 89,
      "name": "Bijie",
      "names": {
        "zh": "毕节"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.3019,
        "lng": 105.2863
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 90,
      "name": "Quanzhou",
      "names": {
        "zh": "泉州",
        "ja": "泉州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.9139,
        "lng": 118.5858
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 91,
      "name": "Miami",
      "names": {
        "zh": "迈阿密",
        "ja": "マイアミ",
        "th": "ไมอามี่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.7839,
        "lng": -80.2102
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 92,
      "name": "Wuxi",
      "names": {
        "zh": "无锡",
        "ja": "無錫",
        "th": "อู่"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.5667,
        "lng": 120.2833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 93,
      "name": "Huanggang",
      "names": {
        "zh": "皇岗口岸"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.45,
        "lng": 114.875
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 94,
      "name": "Maoming",
      "names": {
        "zh": "茂名"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.6618,
        "lng": 110.9178
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 95,
      "name": "Nanchong",
      "names": {
        "zh": "南充"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.7991,
        "lng": 106.0784
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 96,
      "name": "Zunyi",
      "names": {
        "zh": "遵义"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.705,
        "lng": 106.9336
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 97,
      "name": "Qujing",
      "names": {
        "zh": "曲靖"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.5102,
        "lng": 103.8029
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 99,
      "name": "Xinyang",
      "names": {
        "zh": "信阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.1264,
        "lng": 114.0672
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 98,
      "name": "Baghdad",
      "names": {
        "es": "Bagdad",
        "fr": "Bagdad",
        "zh": "巴格达",
        "de": "Bagdad",
        "id": "Bagdad",
        "ja": "バグダッド",
        "th": "กรุงแบกแดด",
        "pt": "Bagdá"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.35,
        "lng": 44.4167
      },
      "country": "Iraq",
      "importance": 0
    },
    {
      "id": 100,
      "name": "Jieyang",
      "names": {
        "zh": "揭阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.5533,
        "lng": 116.3649
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 101,
      "name": "Khartoum",
      "names": {
        "es": "Jartum",
        "zh": "喀土穆",
        "ja": "ハルツーム",
        "th": "ซูดาน",
        "pt": "Cartum"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 15.6031,
        "lng": 32.5265
      },
      "country": "Sudan",
      "importance": 0
    },
    {
      "id": 102,
      "name": "Madrid",
      "names": {
        "zh": "马德里",
        "ja": "マドリード",
        "th": "มาดริด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.4167,
        "lng": -3.7167
      },
      "country": "Spain",
      "importance": 0
    },
    {
      "id": 103,
      "name": "Allahabad",
      "names": {
        "zh": "阿拉哈巴德",
        "ja": "アラハバード"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.45,
        "lng": 81.85
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 104,
      "name": "Yulin",
      "names": {
        "zh": "玉林"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.6293,
        "lng": 110.1507
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 105,
      "name": "Changde",
      "names": {
        "zh": "常德",
        "th": "ฉางเต๋อ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.0397,
        "lng": 111.6839
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 106,
      "name": "Liaocheng",
      "names": {
        "zh": "聊城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.45,
        "lng": 115.9833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 107,
      "name": "Qingdao",
      "names": {
        "zh": "青岛",
        "ja": "青島",
        "th": "ชิงเต่า"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.1167,
        "lng": 120.4
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 108,
      "name": "Dallas",
      "names": {
        "zh": "达拉斯",
        "ja": "ダラス",
        "th": "ดัลลัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7936,
        "lng": -96.7662
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 109,
      "name": "Nangandao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.2992,
        "lng": 113.8851
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 110,
      "name": "Xiangyang",
      "names": {
        "zh": "襄阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.0654,
        "lng": 112.1531
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 111,
      "name": "Philadelphia",
      "names": {
        "es": "Filadelfia",
        "fr": "Philadelphie",
        "zh": "费城",
        "id": "Filadelfia",
        "ja": "フィラデルフィア",
        "th": "ฟิลาเดลเฟีย",
        "pt": "Filadélfia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0077,
        "lng": -75.1339
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 113,
      "name": "Lu\u0027an",
      "names": {
        "zh": "潞安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.7542,
        "lng": 116.5078
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 112,
      "name": "Giza",
      "names": {
        "fr": "Gizeh",
        "zh": "吉萨",
        "de": "Gizeh",
        "ja": "ギザ",
        "th": "กิซ่า"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 29.987,
        "lng": 31.2118
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 114,
      "name": "Zhaotong",
      "names": {
        "zh": "昭通"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.3328,
        "lng": 103.7144
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 115,
      "name": "Yichun",
      "names": {
        "zh": "宜春",
        "id": "Di Yichun"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.8041,
        "lng": 114.383
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 116,
      "name": "Dezhou",
      "names": {
        "zh": "德州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.4513,
        "lng": 116.3105
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 118,
      "name": "Nanchang",
      "names": {
        "zh": "南昌",
        "ja": "南昌",
        "th": "หนานฉาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.6842,
        "lng": 115.8872
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 117,
      "name": "Nairobi",
      "names": {
        "zh": "内罗毕",
        "ja": "ナイロビ",
        "th": "ไนโรบี"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.2864,
        "lng": 36.8172
      },
      "country": "Kenya",
      "importance": 0
    },
    {
      "id": 119,
      "name": "Tai\u0027an",
      "names": {
        "zh": "泰安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.2001,
        "lng": 117.0809
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 120,
      "name": "Dazhou",
      "names": {
        "zh": "达州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.2152,
        "lng": 107.4947
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 121,
      "name": "Houston",
      "names": {
        "zh": "休斯顿",
        "ja": "ヒューストン",
        "th": "ฮูสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.7863,
        "lng": -95.3889
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 123,
      "name": "Yongzhou",
      "names": {
        "zh": "永州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.4515,
        "lng": 111.5953
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 124,
      "name": "Atlanta",
      "names": {
        "zh": "亚特兰大",
        "ja": "アトランタ",
        "th": "แอตแลนตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7627,
        "lng": -84.4224
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 122,
      "name": "Guadalajara",
      "names": {
        "zh": "瓜达拉哈拉",
        "ja": "グアダラハラ",
        "th": "กวาดาลาฮารา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6767,
        "lng": -103.3475
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 125,
      "name": "Rangoon",
      "names": {
        "zh": "仰光",
        "ja": "ラングーン",
        "th": "ย่างกุ้ง"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 16.795,
        "lng": 96.16
      },
      "country": "Myanmar",
      "importance": 0
    },
    {
      "id": 126,
      "name": "Toronto",
      "names": {
        "zh": "多伦多",
        "ja": "トロント",
        "th": "โตรอนโต"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.7417,
        "lng": -79.3733
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 127,
      "name": "Suihua",
      "names": {
        "zh": "绥化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.6384,
        "lng": 126.9808
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 128,
      "name": "Saint Petersburg",
      "names": {
        "es": "San Petersburgo",
        "fr": "Saint-Pétersbourg",
        "zh": "圣彼得堡",
        "it": "San Pietroburgo",
        "ja": "セントピーターズバーグ",
        "th": "เซนต์ปีเตอร์สเบิร์ก",
        "pt": "São Petersburgo"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.95,
        "lng": 30.3167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 129,
      "name": "Washington",
      "names": {
        "zh": "华盛顿",
        "ja": "ワシントン",
        "th": "วอชิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9047,
        "lng": -77.0163
      },
      "country": "United States",
      "importance": 0
    },
    {
      "id": 130,
      "name": "Qiqihar",
      "names": {
        "zh": "齐齐哈尔"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.3398,
        "lng": 123.9512
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 131,
      "name": "Suzhou",
      "names": {
        "zh": "苏州",
        "ja": "蘇州",
        "th": "ซูโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.6333,
        "lng": 116.9683
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 132,
      "name": "Shantou",
      "names": {
        "zh": "汕头",
        "ja": "汕頭",
        "th": "ซัวเถา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3735,
        "lng": 116.6941
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 133,
      "name": "Weinan",
      "names": {
        "zh": "渭南"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.4996,
        "lng": 109.4684
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 134,
      "name": "Changzhou",
      "names": {
        "zh": "常州",
        "ja": "常州",
        "th": "ฉางโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8122,
        "lng": 119.9692
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 135,
      "name": "Singapore",
      "names": {
        "es": "Singapur",
        "fr": "Singapour",
        "zh": "新加坡",
        "de": "Singapur",
        "id": "Singapura",
        "ja": "シンガポール",
        "th": "สิงคโปร์",
        "pt": "Singapura"
      },
      "countryCode": "SG",
      "latLng": {
        "lat": 1.3,
        "lng": 103.8
      },
      "country": "Singapore",
      "importance": 0
    },
    {
      "id": 136,
      "name": "Fuzhou",
      "names": {
        "zh": "福州",
        "ja": "福州",
        "th": "ฝูโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.0769,
        "lng": 119.2917
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 137,
      "name": "Pudong",
      "names": {
        "zh": "浦东",
        "ja": "浦東",
        "th": "ผู่ตง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.2231,
        "lng": 121.5397
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 138,
      "name": "Belo Horizonte",
      "names": {
        "zh": "Belo Horizo nte",
        "ja": "ベロオリゾンテ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.9281,
        "lng": -43.9419
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 139,
      "name": "Zhangzhou",
      "names": {
        "zh": "漳州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.5093,
        "lng": 117.6612
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 140,
      "name": "Yuncheng",
      "names": {
        "zh": "运城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.0304,
        "lng": 110.998
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 141,
      "name": "Suzhou",
      "names": {
        "zh": "苏州",
        "ja": "蘇州",
        "th": "ซูโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.304,
        "lng": 120.6164
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 142,
      "name": "Xianyang",
      "names": {
        "zh": "咸阳",
        "th": "เซียนหยาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.35,
        "lng": 108.7167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 143,
      "name": "Guilin",
      "names": {
        "zh": "桂林",
        "ja": "桂林",
        "th": "กุ้ยหลิน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.2667,
        "lng": 110.2833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 144,
      "name": "Taizhou",
      "names": {
        "zh": "台州",
        "th": "ในไถโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.4831,
        "lng": 119.9
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 145,
      "name": "Abidjan",
      "names": {
        "zh": "阿比让",
        "ja": "アビジャン",
        "th": "อาบิดจาน"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 5.3364,
        "lng": -4.0267
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 0
    },
    {
      "id": 146,
      "name": "Huaihua",
      "names": {
        "zh": "怀化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.5494,
        "lng": 109.9592
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 147,
      "name": "Ji\u0027an",
      "names": {
        "zh": "吉安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.1172,
        "lng": 114.9793
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 148,
      "name": "Xiaoganzhan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9273,
        "lng": 113.911
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 149,
      "name": "Pingdingshan",
      "names": {
        "zh": "平顶山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.735,
        "lng": 113.2999
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 150,
      "name": "Jiujiang",
      "names": {
        "zh": "九江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.7048,
        "lng": 116.0021
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 152,
      "name": "Guiyang",
      "names": {
        "zh": "贵阳",
        "th": "กุ้ยหยาง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.5794,
        "lng": 106.7078
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 151,
      "name": "Surat",
      "names": {
        "zh": "苏拉特",
        "ja": "スーラト",
        "th": "สุราษฎร์ธานี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 21.17,
        "lng": 72.83
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 153,
      "name": "Alexandria",
      "names": {
        "es": "Alejandría",
        "fr": "Alexandrie",
        "zh": "亚历山大",
        "it": "Alessandria",
        "ja": "アレクサンドリア",
        "th": "ซานเดรีย"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.2,
        "lng": 29.9167
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 154,
      "name": "Bozhou",
      "names": {
        "zh": "亳州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.8626,
        "lng": 115.7742
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 155,
      "name": "Sydney",
      "names": {
        "zh": "雪梨",
        "ja": "シドニー",
        "th": "ซิดนีย์"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -33.865,
        "lng": 151.2094
      },
      "country": "Australia",
      "importance": 1
    },
    {
      "id": 156,
      "name": "Huizhou",
      "names": {
        "zh": "惠州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1115,
        "lng": 114.4152
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 157,
      "name": "Huai\u0027an",
      "names": {
        "zh": "淮安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.5,
        "lng": 119.1331
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 158,
      "name": "Chenzhou",
      "names": {
        "zh": "郴州",
        "th": "เฉินโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.7989,
        "lng": 113.0267
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 159,
      "name": "Barcelona",
      "names": {
        "fr": "Barcelone",
        "zh": "巴塞罗那",
        "it": "Barcellona",
        "ja": "バルセロナ",
        "th": "บาร์เซโลนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.3825,
        "lng": 2.1769
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 160,
      "name": "Anqing",
      "names": {
        "zh": "安庆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.5,
        "lng": 117.0333
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 161,
      "name": "Suqian",
      "names": {
        "zh": "宿迁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.9331,
        "lng": 118.2831
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 162,
      "name": "Boston",
      "names": {
        "zh": "波士顿",
        "ja": "ボストン",
        "th": "บอสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3188,
        "lng": -71.0846
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 163,
      "name": "Jiangmen",
      "names": {
        "zh": "江门"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5833,
        "lng": 113.0833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 164,
      "name": "Mianyang",
      "names": {
        "zh": "绵阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.4669,
        "lng": 104.7385
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 165,
      "name": "Harbin",
      "names": {
        "zh": "哈尔滨",
        "ja": "ハルビン",
        "th": "ฮาร์บิน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.75,
        "lng": 126.6333
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 166,
      "name": "Huanglongsi",
      "names": {
        "zh": "黄龙寺"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7936,
        "lng": 114.3403
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 168,
      "name": "Zibo",
      "names": {
        "zh": "淄博"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.7831,
        "lng": 118.0497
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 167,
      "name": "Melbourne",
      "names": {
        "zh": "墨尔本",
        "ja": "メルボルン",
        "th": "เมลเบิร์น"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -37.8136,
        "lng": 144.9631
      },
      "country": "Australia",
      "importance": 1
    },
    {
      "id": 169,
      "name": "Dalian",
      "names": {
        "zh": "大连",
        "ja": "大連",
        "th": "ต้าเหลียน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.9,
        "lng": 121.6
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 170,
      "name": "Hengshui",
      "names": {
        "zh": "衡水"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7348,
        "lng": 115.686
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 171,
      "name": "Yibin",
      "names": {
        "zh": "宜宾"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.7596,
        "lng": 104.64
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 172,
      "name": "Yangzhou",
      "names": {
        "zh": "扬州",
        "ja": "揚州",
        "th": "โจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.3912,
        "lng": 119.4363
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 173,
      "name": "Timbio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.3528,
        "lng": -76.6819
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 174,
      "name": "Johannesburg",
      "names": {
        "es": "Johannesburgo",
        "zh": "约翰内斯堡",
        "ja": "ヨハネスブルグ",
        "th": "โจฮันเนสเบิร์ก",
        "pt": "Joanesburgo"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.2044,
        "lng": 28.0416
      },
      "country": "South Africa",
      "importance": 1
    },
    {
      "id": 175,
      "name": "Yiyang",
      "names": {
        "zh": "益阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.5833,
        "lng": 112.3333
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 176,
      "name": "Guigang",
      "names": {
        "zh": "贵港"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.0961,
        "lng": 109.6092
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 177,
      "name": "Xinpu",
      "names": {
        "zh": "新埔"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.5906,
        "lng": 119.1801
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 178,
      "name": "Meizhou",
      "names": {
        "zh": "梅州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.2998,
        "lng": 116.1191
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 179,
      "name": "Casablanca",
      "names": {
        "zh": "卡萨布兰卡",
        "ja": "カサブランカ",
        "th": "คาซาบลังกา"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.5992,
        "lng": -7.62
      },
      "country": "Morocco",
      "importance": 1
    },
    {
      "id": 180,
      "name": "Langfang",
      "names": {
        "zh": "廊坊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.5196,
        "lng": 116.7006
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 181,
      "name": "Zhangjiakou",
      "names": {
        "zh": "张家口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.8108,
        "lng": 114.8811
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 182,
      "name": "Chifeng",
      "names": {
        "zh": "赤峰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.2663,
        "lng": 118.9223
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 183,
      "name": "Linfen",
      "names": {
        "zh": "临汾"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0812,
        "lng": 111.5087
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 184,
      "name": "Jiangguanchi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.0244,
        "lng": 113.8201
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 185,
      "name": "Kabul",
      "names": {
        "fr": "Kaboul",
        "zh": "喀布尔",
        "ja": "カブール",
        "th": "คาบูล",
        "pt": "Cabul"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.5328,
        "lng": 69.1658
      },
      "country": "Afghanistan",
      "importance": 0
    },
    {
      "id": 186,
      "name": "Phoenix",
      "names": {
        "zh": "凤凰",
        "it": "Fenice",
        "ja": "フェニックス",
        "th": "ฟินิกซ์",
        "pt": "Fénix"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5722,
        "lng": -112.0891
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 187,
      "name": "Luzhou",
      "names": {
        "zh": "泸州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.8918,
        "lng": 105.4409
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 188,
      "name": "Taiyuan",
      "names": {
        "zh": "太原",
        "ja": "太原",
        "th": "ไท่หยวน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.8733,
        "lng": 112.5425
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 189,
      "name": "Zhaoqing",
      "names": {
        "zh": "肇庆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.05,
        "lng": 112.4667
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 190,
      "name": "Xiaoxita",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.7083,
        "lng": 111.2803
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 191,
      "name": "Xiamen",
      "names": {
        "zh": "厦门",
        "ja": "厦門",
        "th": "เซียะเหมิ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.4797,
        "lng": 118.0819
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 192,
      "name": "Fuzhou",
      "names": {
        "zh": "福州",
        "ja": "福州",
        "th": "ฝูโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.9814,
        "lng": 116.3577
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 193,
      "name": "Liuzhou",
      "names": {
        "zh": "柳州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.3264,
        "lng": 109.4281
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 194,
      "name": "Zhuzhou",
      "names": {
        "zh": "株洲"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.8407,
        "lng": 113.1469
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 195,
      "name": "Amman",
      "names": {
        "zh": "安曼",
        "ja": "アンマン",
        "th": "อัมมาน",
        "pt": "Amã"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 31.95,
        "lng": 35.9333
      },
      "country": "Jordan",
      "importance": 0
    },
    {
      "id": 196,
      "name": "Jeddah",
      "names": {
        "es": "Jedda",
        "zh": "吉达",
        "it": "Jedda",
        "ja": "ジェッダ",
        "th": "เจดดาห์"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 21.5428,
        "lng": 39.1728
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 197,
      "name": "Chuzhou",
      "names": {
        "zh": "滁州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.3062,
        "lng": 118.3115
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 198,
      "name": "Loudi",
      "names": {
        "zh": "娄底"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7378,
        "lng": 111.9974
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 199,
      "name": "Deyang",
      "names": {
        "zh": "德阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1289,
        "lng": 104.395
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 200,
      "name": "Qingyuan",
      "names": {
        "zh": "清远",
        "ja": "清遠"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6842,
        "lng": 113.0507
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 201,
      "name": "Kano",
      "names": {
        "zh": "卡诺",
        "id": "Aminu Kano International",
        "ja": "カノ",
        "th": "โคโน"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 12.0,
        "lng": 8.5167
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 202,
      "name": "Wuhu",
      "names": {
        "zh": "芜湖"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.334,
        "lng": 118.3622
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 203,
      "name": "Seattle",
      "names": {
        "zh": "西雅图",
        "ja": "シアトル",
        "th": "ซีแอตเทิล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6211,
        "lng": -122.3244
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 204,
      "name": "Yokohama",
      "names": {
        "zh": "横滨",
        "ja": "横浜",
        "th": "โยโกฮาม่า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4333,
        "lng": 139.6333
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 205,
      "name": "Binzhou",
      "names": {
        "zh": "滨州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3806,
        "lng": 118.0125
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 206,
      "name": "Baojishi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.3609,
        "lng": 107.1751
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 207,
      "name": "Zaozhuang",
      "names": {
        "zh": "枣庄"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.8667,
        "lng": 117.55
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 208,
      "name": "Neijiang",
      "names": {
        "zh": "内江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.5872,
        "lng": 105.0635
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 209,
      "name": "Baicheng",
      "names": {
        "zh": "白城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.901,
        "lng": 106.6194
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 210,
      "name": "Berlin",
      "names": {
        "es": "Berlín",
        "zh": "柏林",
        "it": "Berlino",
        "ja": "ベルリン",
        "th": "เบอร์ลิน",
        "pt": "Berlim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.5167,
        "lng": 13.3833
      },
      "country": "Germany",
      "importance": 0
    },
    {
      "id": 211,
      "name": "Anshan",
      "names": {
        "zh": "鞍山",
        "th": "อันชาน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.1066,
        "lng": 122.9895
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 212,
      "name": "Lanzhou",
      "names": {
        "zh": "兰州",
        "ja": "蘭州",
        "th": "หลานโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0617,
        "lng": 103.8318
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 213,
      "name": "Puyang",
      "names": {
        "zh": "濮阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7639,
        "lng": 115.03
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 214,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7562,
        "lng": -122.443
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 215,
      "name": "Jiaozuo",
      "names": {
        "zh": "焦作"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.229,
        "lng": 113.2304
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 216,
      "name": "Hechi",
      "names": {
        "zh": "河池"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.6928,
        "lng": 108.085
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 217,
      "name": "Montreal",
      "names": {
        "fr": "Montréal",
        "zh": "蒙特利尔",
        "ja": "モントリオール",
        "th": "มอนทรีออล"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.5089,
        "lng": -73.5617
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 218,
      "name": "Detroit",
      "names": {
        "zh": "底特律",
        "ja": "デトロイト",
        "th": "ดีทรอยต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3834,
        "lng": -83.1024
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 219,
      "name": "Chengde",
      "names": {
        "zh": "承德",
        "th": "เฉิงเต๋อ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.9739,
        "lng": 117.9322
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 220,
      "name": "Busan",
      "names": {
        "zh": "釜山",
        "ja": "釜山",
        "th": "ปูซาน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.1,
        "lng": 129.0403
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 222,
      "name": "Hanzhong",
      "names": {
        "zh": "汉中",
        "th": "แฮน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.0794,
        "lng": 107.026
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 221,
      "name": "Algiers",
      "names": {
        "es": "Argel",
        "fr": "Alger",
        "zh": "阿尔及尔",
        "de": "Algier",
        "it": "Algeri",
        "id": "Aljir",
        "ja": "アルジェ",
        "th": "แอลเจียร์",
        "pt": "Argel"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7764,
        "lng": 3.0586
      },
      "country": "Algeria",
      "importance": 0
    },
    {
      "id": 223,
      "name": "Shiyan",
      "names": {
        "zh": "十堰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.6351,
        "lng": 110.7755
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 225,
      "name": "Siping",
      "names": {
        "zh": "四平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.1715,
        "lng": 124.3644
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 224,
      "name": "Lucknow",
      "names": {
        "zh": "勒克瑙",
        "ja": "ラクナウ",
        "th": "ลัคเนา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.847,
        "lng": 80.947
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 226,
      "name": "Yulinshi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.2655,
        "lng": 109.7388
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 227,
      "name": "Changzhi",
      "names": {
        "zh": "长治"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.1953,
        "lng": 113.097
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 228,
      "name": "Qinzhou",
      "names": {
        "zh": "钦州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.95,
        "lng": 108.6167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 229,
      "name": "Bazhou",
      "names": {
        "zh": "霸州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8576,
        "lng": 106.7559
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 230,
      "name": "Qincheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.5809,
        "lng": 105.7311
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 231,
      "name": "Zhongshan",
      "names": {
        "zh": "中山",
        "ja": "中山",
        "th": "จงซาน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5333,
        "lng": 113.35
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 232,
      "name": "Suining",
      "names": {
        "zh": "遂宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.5098,
        "lng": 105.5737
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 233,
      "name": "Leshan",
      "names": {
        "zh": "乐山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.5854,
        "lng": 103.7575
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 234,
      "name": "San Diego",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンディエゴ",
        "th": "ซานดิเอโก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8312,
        "lng": -117.1225
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 236,
      "name": "Guang\u0027an",
      "names": {
        "zh": "广安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4673,
        "lng": 106.6336
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 235,
      "name": "Faisalabad",
      "names": {
        "zh": "费萨拉巴德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.418,
        "lng": 73.079
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 237,
      "name": "Tongren",
      "names": {
        "zh": "同仁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7233,
        "lng": 109.1885
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 238,
      "name": "Bengbu",
      "names": {
        "zh": "蚌埠"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9354,
        "lng": 117.3531
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 239,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.7892,
        "lng": -63.1975
      },
      "country": "Bolivia",
      "importance": 1
    },
    {
      "id": 240,
      "name": "Qinhuangdao",
      "names": {
        "zh": "秦皇岛"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.9398,
        "lng": 119.5881
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 241,
      "name": "Tongliao",
      "names": {
        "zh": "通辽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.6172,
        "lng": 122.264
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 242,
      "name": "Jinzhou",
      "names": {
        "zh": "锦州",
        "ja": "錦州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.1144,
        "lng": 121.1292
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 243,
      "name": "Zhenjiang",
      "names": {
        "zh": "镇江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.2109,
        "lng": 119.4551
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 244,
      "name": "Urumqi",
      "names": {
        "zh": "乌鲁木齐",
        "ja": "ウルムチ",
        "th": "อุรุมชี"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.8225,
        "lng": 87.6125
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 245,
      "name": "Heyuan",
      "names": {
        "zh": "河源",
        "th": "เหอหยวน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7503,
        "lng": 114.6923
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 246,
      "name": "Jaipur",
      "names": {
        "zh": "斋浦尔",
        "ja": "ジャイプール",
        "th": "ชัยปุระ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.9167,
        "lng": 75.8667
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 247,
      "name": "Xinzhou",
      "names": {
        "zh": "忻州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4178,
        "lng": 112.7233
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 248,
      "name": "Wuzhou",
      "names": {
        "zh": "梧州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.4833,
        "lng": 111.3167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 250,
      "name": "Chaoyang",
      "names": {
        "zh": "朝阳",
        "ja": "朝陽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.5757,
        "lng": 120.4486
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 249,
      "name": "Addis Ababa",
      "names": {
        "fr": "Addis-Abeba",
        "it": "Addis Abeba",
        "ja": "アディスアベバ",
        "pt": "Adis ababa"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 9.0272,
        "lng": 38.7369
      },
      "country": "Ethiopia",
      "importance": 0
    },
    {
      "id": 251,
      "name": "Brasilia",
      "names": {
        "zh": "巴西利亚",
        "ja": "ブラジリア",
        "th": "บราซิเลีย",
        "pt": "Brasília"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.7939,
        "lng": -47.8828
      },
      "country": "Brazil",
      "importance": 0
    },
    {
      "id": 252,
      "name": "Mashhad",
      "names": {
        "zh": "马什哈德",
        "ja": "マシュハド"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.3069,
        "lng": 59.6042
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 253,
      "name": "Shaoguan",
      "names": {
        "zh": "韶关"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.8011,
        "lng": 113.5927
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 255,
      "name": "Shanwei",
      "names": {
        "zh": "汕尾"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.7664,
        "lng": 115.3331
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 254,
      "name": "Kuwait City",
      "names": {
        "es": "Ciudad de Kuwait",
        "fr": "Koweït",
        "zh": "科威特市",
        "de": "Kuwait-Stadt",
        "id": "Kota Kuwait",
        "ja": "クウェートシティ",
        "th": "เมืองคูเวต",
        "pt": "Cidade do kuwait"
      },
      "countryCode": "KW",
      "latLng": {
        "lat": 29.375,
        "lng": 47.98
      },
      "country": "Kuwait",
      "importance": 0
    },
    {
      "id": 257,
      "name": "Minneapolis",
      "names": {
        "zh": "明尼阿波利斯",
        "ja": "ミネアポリス",
        "th": "มินนิอาโปลิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.9635,
        "lng": -93.2678
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 258,
      "name": "Kyiv",
      "names": {
        "es": "Kiev",
        "fr": "Kiev",
        "zh": "基辅",
        "de": "Kiew",
        "it": "Kiev",
        "pt": "Kiev"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.45,
        "lng": 30.5236
      },
      "country": "Ukraine",
      "importance": 0
    },
    {
      "id": 256,
      "name": "Quezon City",
      "names": {
        "es": "Ciudad de Quezon",
        "zh": "奎松市",
        "de": "Quezon-Stadt",
        "it": "Città di quezon",
        "id": "Kota Quezon",
        "ja": "ケゾンシティ",
        "th": "เมืองเกซอน",
        "pt": "Cidade de Quezon"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6333,
        "lng": 121.0333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 259,
      "name": "Sanaa",
      "names": {
        "zh": "萨那",
        "id": "Sana\u0027a",
        "ja": "サヌア"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 15.35,
        "lng": 44.2
      },
      "country": "Yemen",
      "importance": 0
    },
    {
      "id": 260,
      "name": "Meishan",
      "names": {
        "zh": "梅山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.0575,
        "lng": 103.8381
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 261,
      "name": "Guatemala City",
      "names": {
        "es": "Ciudad de Guatemala",
        "zh": "危地马拉市",
        "de": "Guatemala-Stadt",
        "it": "Città del Guatemala",
        "id": "Kota Guatemala",
        "ja": "グアテマラシティ",
        "th": "กัวเตมาลาซิตี้",
        "pt": "Cidade da Guatemala"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6099,
        "lng": -90.5252
      },
      "country": "Guatemala",
      "importance": 0
    },
    {
      "id": 262,
      "name": "Incheon",
      "names": {
        "zh": "仁川",
        "ja": "仁川",
        "th": "อินชอน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4639,
        "lng": 126.6486
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 263,
      "name": "Ningde",
      "names": {
        "zh": "宁德"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.6617,
        "lng": 119.5228
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 264,
      "name": "Tampa",
      "names": {
        "zh": "坦帕",
        "ja": "タンパ",
        "th": "แทมปา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.9942,
        "lng": -82.4451
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 265,
      "name": "Daqing",
      "names": {
        "zh": "大庆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.5979,
        "lng": 125.008
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 266,
      "name": "Putian",
      "names": {
        "zh": "莆田"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.4394,
        "lng": 119.0103
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 269,
      "name": "Salvador",
      "names": {
        "zh": "萨尔瓦多",
        "ja": "サルバドール",
        "th": "ซัลวาดอร์"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.9708,
        "lng": -38.5108
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 268,
      "name": "Surabaya",
      "names": {
        "zh": "泗水",
        "ja": "スラバヤ",
        "th": "สุราบายา"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.2458,
        "lng": 112.7378
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 270,
      "name": "Denver",
      "names": {
        "zh": "丹佛",
        "ja": "デンバー",
        "th": "เดนเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7621,
        "lng": -104.8759
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 267,
      "name": "Bandung",
      "names": {
        "zh": "万隆",
        "ja": "バンドン",
        "th": "บันดุง"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.95,
        "lng": 107.5667
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 271,
      "name": "Rome",
      "names": {
        "es": "Roma",
        "zh": "罗马",
        "de": "Rom",
        "it": "Roma",
        "id": "Roma",
        "ja": "ローマ",
        "th": "โรม",
        "pt": "Roma"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.8931,
        "lng": 12.4828
      },
      "country": "Italy",
      "importance": 0
    },
    {
      "id": 272,
      "name": "La Paz",
      "names": {
        "zh": "拉巴斯",
        "ja": "ラパス"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -16.4942,
        "lng": -68.1475
      },
      "country": "Bolivia",
      "importance": 0
    },
    {
      "id": 273,
      "name": "Hohhot",
      "names": {
        "zh": "呼和浩特"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.8151,
        "lng": 111.6629
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 274,
      "name": "Xiangtan",
      "names": {
        "zh": "湘潭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.8431,
        "lng": 112.9228
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 275,
      "name": "Pyongyang",
      "names": {
        "zh": "平壤",
        "de": "Pjöngjang",
        "ja": "平壌",
        "th": "เปียงยาง"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.03,
        "lng": 125.73
      },
      "country": "North Korea",
      "importance": 0
    },
    {
      "id": 277,
      "name": "Weihai",
      "names": {
        "zh": "威海"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.5,
        "lng": 122.1
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 276,
      "name": "Taichung",
      "names": {
        "zh": "台中",
        "ja": "台中",
        "th": "ไตซุง"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.15,
        "lng": 120.6667
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 278,
      "name": "Rizhao",
      "names": {
        "zh": "日照"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4164,
        "lng": 119.4331
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 279,
      "name": "Mudanjiang",
      "names": {
        "zh": "牡丹江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.5861,
        "lng": 129.5997
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 280,
      "name": "Kaohsiung",
      "names": {
        "zh": "高雄",
        "ja": "高雄",
        "th": "เกาสง"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 22.6167,
        "lng": 120.3
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 281,
      "name": "Guayaquil",
      "names": {
        "zh": "瓜亚基尔",
        "ja": "グアヤキル",
        "th": "ไกวย์อากิล"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -2.19,
        "lng": -79.8875
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 282,
      "name": "Tieling",
      "names": {
        "zh": "铁岭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.2841,
        "lng": 123.8365
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 283,
      "name": "Cawnpore",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.4725,
        "lng": 80.3311
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 284,
      "name": "Dingxi",
      "names": {
        "zh": "定西"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5806,
        "lng": 104.6263
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 286,
      "name": "Nanping",
      "names": {
        "zh": "南平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.6448,
        "lng": 118.1728
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 285,
      "name": "Taipei",
      "names": {
        "zh": "台北",
        "ja": "台北",
        "th": "ไทเป"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 25.0478,
        "lng": 121.5319
      },
      "country": "Taiwan",
      "importance": 3
    },
    {
      "id": 287,
      "name": "Zigong",
      "names": {
        "zh": "自贡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.3498,
        "lng": 104.7645
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 288,
      "name": "Chaozhou",
      "names": {
        "zh": "潮州",
        "th": "แต้จิ๋ว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.67,
        "lng": 116.63
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 289,
      "name": "Baotou",
      "names": {
        "zh": "包头"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.6562,
        "lng": 109.8345
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 291,
      "name": "Longyan",
      "names": {
        "zh": "龙岩"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0881,
        "lng": 117.0244
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 292,
      "name": "Ankang",
      "names": {
        "zh": "安康",
        "id": "Nankang"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.6877,
        "lng": 109.0235
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 293,
      "name": "Baoshan",
      "names": {
        "zh": "宝山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.1211,
        "lng": 99.169
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 294,
      "name": "Huludao",
      "names": {
        "zh": "葫芦岛"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.7094,
        "lng": 120.8378
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 295,
      "name": "Antananarivo",
      "names": {
        "zh": "塔那那利佛",
        "ja": "アンタナナリボ",
        "th": "อันตานานาริโว"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -18.9386,
        "lng": 47.5214
      },
      "country": "Madagascar",
      "importance": 0
    },
    {
      "id": 290,
      "name": "Gulou",
      "names": {
        "zh": "鼓楼"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.0819,
        "lng": 119.2981
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 296,
      "name": "Yanjiang",
      "names": {
        "zh": "沿江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.1256,
        "lng": 104.6397
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 298,
      "name": "Santo Domingo",
      "names": {
        "ja": "サントドミンゴ",
        "th": "ซานโตโดมิงโก"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4764,
        "lng": -69.8933
      },
      "country": "Dominican Republic",
      "importance": 0
    },
    {
      "id": 297,
      "name": "Chattogram",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 22.335,
        "lng": 91.8325
      },
      "country": "Bangladesh",
      "importance": 1
    },
    {
      "id": 299,
      "name": "Sanming",
      "names": {
        "zh": "三明"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.2658,
        "lng": 117.6302
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 300,
      "name": "Longba",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.535,
        "lng": 105.349
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 301,
      "name": "Brooklyn",
      "names": {
        "zh": "布鲁克林",
        "ja": "ブルックリン",
        "th": "บรูคลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6501,
        "lng": -73.9496
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 302,
      "name": "Yangjiang",
      "names": {
        "zh": "阳江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.8556,
        "lng": 111.9627
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 303,
      "name": "Jiamusi",
      "names": {
        "zh": "佳木斯"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.8081,
        "lng": 130.3653
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 304,
      "name": "Ibadan",
      "names": {
        "zh": "伊巴丹",
        "th": "อีบาดัน"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.3964,
        "lng": 3.9167
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 305,
      "name": "Luohe",
      "names": {
        "zh": "漯河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.583,
        "lng": 114.0109
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 306,
      "name": "Lincang",
      "names": {
        "zh": "临沧"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.8864,
        "lng": 100.0871
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 308,
      "name": "Xuanzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9475,
        "lng": 118.7518
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 307,
      "name": "Medellin",
      "names": {
        "es": "Medellín",
        "zh": "麦德林",
        "ja": "メデイン",
        "pt": "Medellín"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2447,
        "lng": -75.5748
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 309,
      "name": "Yunfu",
      "names": {
        "zh": "云浮",
        "th": "หยุนฝู"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9242,
        "lng": 112.0353
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 310,
      "name": "Dubai",
      "names": {
        "fr": "Dubaï",
        "zh": "迪拜",
        "ja": "ドバイ",
        "th": "ดูไบ"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.2697,
        "lng": 55.3094
      },
      "country": "United Arab Emirates",
      "importance": 1
    },
    {
      "id": 312,
      "name": "Tashkent",
      "names": {
        "fr": "Tachkent",
        "zh": "塔什干",
        "de": "Taschkent",
        "ja": "タシケント",
        "th": "ทาชเคนต์"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.3,
        "lng": 69.2667
      },
      "country": "Uzbekistan",
      "importance": 0
    },
    {
      "id": 311,
      "name": "Mirzapur",
      "names": {
        "zh": "米尔扎布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.15,
        "lng": 82.58
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 313,
      "name": "Guangyuan",
      "names": {
        "zh": "广元"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.4353,
        "lng": 105.8398
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 315,
      "name": "Huangshi",
      "names": {
        "zh": "黄石理工"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.2018,
        "lng": 115.0326
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 314,
      "name": "Cali",
      "names": {
        "zh": "卡利",
        "ja": "カリ",
        "th": "กาลี"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.44,
        "lng": -76.5197
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 317,
      "name": "Daegu",
      "names": {
        "zh": "大邱",
        "ja": "大邱",
        "th": "แทกู"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.8667,
        "lng": 128.6
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 316,
      "name": "Ouagadougou",
      "names": {
        "es": "Uagadugú",
        "zh": "瓦加杜古",
        "ja": "ワガドゥグー",
        "th": "วากาดูกู"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.3686,
        "lng": -1.5275
      },
      "country": "Burkina Faso",
      "importance": 0
    },
    {
      "id": 318,
      "name": "Fortaleza",
      "names": {
        "zh": "福塔莱萨",
        "ja": "フォルタレザ",
        "th": "ฟอร์ตาเลซา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.7275,
        "lng": -38.5275
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 320,
      "name": "Douala",
      "names": {
        "zh": "杜阿拉",
        "ja": "ドゥアラ",
        "th": "ดูอาลา"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.05,
        "lng": 9.7
      },
      "country": "Cameroon",
      "importance": 1
    },
    {
      "id": 322,
      "name": "Dandong",
      "names": {
        "zh": "丹东"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.1167,
        "lng": 124.3833
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 319,
      "name": "Yaounde",
      "names": {
        "fr": "Yaoundé",
        "zh": "雅温得",
        "it": "Yaoundé",
        "th": "ยาอุนเด"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 3.8578,
        "lng": 11.5181
      },
      "country": "Cameroon",
      "importance": 0
    },
    {
      "id": 324,
      "name": "Yingkou",
      "names": {
        "zh": "营口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.6653,
        "lng": 122.2297
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 323,
      "name": "Lianshan",
      "names": {
        "zh": "连山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.7523,
        "lng": 120.828
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 325,
      "name": "Nagpur",
      "names": {
        "zh": "那格浦尔",
        "ja": "ナーグプル",
        "th": "นาคปุระ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 21.1539,
        "lng": 79.0831
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 321,
      "name": "Jilin",
      "names": {
        "zh": "吉林",
        "ja": "吉林省",
        "th": "จี๋หลิน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.8519,
        "lng": 126.5481
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 326,
      "name": "Omdurman",
      "names": {
        "zh": "恩图曼"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 15.6167,
        "lng": 32.48
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 329,
      "name": "Yuxi",
      "names": {
        "zh": "玉溪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.3495,
        "lng": 102.5432
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 327,
      "name": "Bekasi",
      "names": {
        "zh": "勿加泗"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.2333,
        "lng": 107.0
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 328,
      "name": "Ghaziabad",
      "names": {
        "zh": "加济阿巴德",
        "ja": "ガジアバード"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.6667,
        "lng": 77.4167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 330,
      "name": "Brisbane",
      "names": {
        "zh": "布里斯班",
        "ja": "ブリスベン",
        "th": "บริสเบน"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -27.4678,
        "lng": 153.0281
      },
      "country": "Australia",
      "importance": 1
    },
    {
      "id": 331,
      "name": "Anshun",
      "names": {
        "zh": "安顺"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.2456,
        "lng": 105.934
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 333,
      "name": "Shangzhou",
      "names": {
        "zh": "商州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.868,
        "lng": 109.9244
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 334,
      "name": "Huainan",
      "names": {
        "zh": "淮南"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.4831,
        "lng": 117.0164
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 332,
      "name": "Depok",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.394,
        "lng": 106.8225
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 335,
      "name": "Kuaidamao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.7302,
        "lng": 125.9471
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 336,
      "name": "Accra",
      "names": {
        "zh": "阿克拉",
        "ja": "アクラ",
        "th": "อักกรา"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 5.6037,
        "lng": -0.187
      },
      "country": "Ghana",
      "importance": 0
    },
    {
      "id": 337,
      "name": "Fukuoka",
      "names": {
        "zh": "福冈",
        "ja": "福岡",
        "th": "ฟุกุโอกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.6,
        "lng": 130.4167
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 338,
      "name": "Jincheng",
      "names": {
        "zh": "金城",
        "ja": "金城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4906,
        "lng": 112.8483
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 339,
      "name": "Vancouver",
      "names": {
        "zh": "温哥华",
        "ja": "バンクーバー",
        "th": "แวนคูเวอร์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.25,
        "lng": -123.1
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 340,
      "name": "Tangerang",
      "names": {
        "zh": "唐格朗"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.1783,
        "lng": 106.6319
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 341,
      "name": "Sanmenxia",
      "names": {
        "zh": "三门峡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7736,
        "lng": 111.195
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 342,
      "name": "Laibin",
      "names": {
        "zh": "来宾"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7333,
        "lng": 109.2333
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 343,
      "name": "Queens",
      "names": {
        "zh": "皇后",
        "ja": "クイーンズ",
        "th": "ควีนส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7498,
        "lng": -73.7976
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 344,
      "name": "Qinbaling",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7278,
        "lng": 107.64
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 345,
      "name": "Xining",
      "names": {
        "zh": "西宁",
        "ja": "西寧",
        "th": "ซีหนิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.6239,
        "lng": 101.7787
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 346,
      "name": "Ma\u0027anshan",
      "names": {
        "zh": "马鞍山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.6858,
        "lng": 118.5101
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 347,
      "name": "Yan\u0027an",
      "names": {
        "zh": "延安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.5952,
        "lng": 109.4863
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 348,
      "name": "Baku",
      "names": {
        "es": "Bakú",
        "fr": "Bakou",
        "zh": "巴库",
        "ja": "バクー",
        "th": "บากู"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.3667,
        "lng": 49.8352
      },
      "country": "Azerbaijan",
      "importance": 0
    },
    {
      "id": 349,
      "name": "Gaoping",
      "names": {
        "zh": "高平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.7824,
        "lng": 106.1281
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 350,
      "name": "Harare",
      "names": {
        "zh": "哈拉雷",
        "ja": "ハラレ",
        "th": "ฮาแรร์"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -17.8292,
        "lng": 31.0522
      },
      "country": "Zimbabwe",
      "importance": 0
    },
    {
      "id": 351,
      "name": "Havana",
      "names": {
        "es": "La Habana",
        "fr": "La Havane",
        "zh": "哈瓦那",
        "de": "Havanna",
        "it": "L\u0027Avana",
        "ja": "ハバナ",
        "th": "คิวบา"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 23.1367,
        "lng": -82.3589
      },
      "country": "Cuba",
      "importance": 0
    },
    {
      "id": 352,
      "name": "Phnom Penh",
      "names": {
        "ja": "プノンペン",
        "th": "พนมเปญ"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.5696,
        "lng": 104.921
      },
      "country": "Cambodia",
      "importance": 0
    },
    {
      "id": 353,
      "name": "Ningbo",
      "names": {
        "zh": "宁波",
        "ja": "寧波",
        "th": "หนิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.875,
        "lng": 121.5492
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 354,
      "name": "Mogadishu",
      "names": {
        "es": "Mogadiscio",
        "fr": "Mogadiscio",
        "zh": "摩加迪沙",
        "de": "Mogadischu",
        "it": "Mogadiscio",
        "ja": "モガディシュ",
        "th": "โมกาได",
        "pt": "Mogadíscio"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": 2.0408,
        "lng": 45.3425
      },
      "country": "Somalia",
      "importance": 0
    },
    {
      "id": 355,
      "name": "Puning",
      "names": {
        "zh": "普宁市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.2993,
        "lng": 116.1586
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 357,
      "name": "Huaibei",
      "names": {
        "zh": "淮北"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.9562,
        "lng": 116.789
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 358,
      "name": "Qingyang",
      "names": {
        "zh": "庆阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.8141,
        "lng": 118.5792
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 356,
      "name": "Medan",
      "names": {
        "zh": "棉兰",
        "th": "เมดาน"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 3.6667,
        "lng": 98.6667
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 359,
      "name": "Riverside",
      "names": {
        "zh": "滨江",
        "ja": "リバーサイド",
        "th": "ริเวอร์ไซด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9381,
        "lng": -117.3948
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 360,
      "name": "Baltimore",
      "names": {
        "zh": "巴尔的摩",
        "ja": "ボルチモア",
        "th": "บัลติมอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.3051,
        "lng": -76.6144
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 362,
      "name": "Las Vegas",
      "names": {
        "zh": "拉斯维加斯",
        "ja": "ラスベガス",
        "th": "ลาสเวกัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.2333,
        "lng": -115.2654
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 361,
      "name": "Haiphong",
      "names": {
        "zh": "海防",
        "ja": "ハイフォン",
        "th": "ไฮฟอง"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.8,
        "lng": 106.6667
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 363,
      "name": "Chongzuo",
      "names": {
        "zh": "崇左"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.4167,
        "lng": 107.3667
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 364,
      "name": "Rawalpindi",
      "names": {
        "zh": "拉瓦尔品第",
        "ja": "ラワルピンディー",
        "th": "ราวัลปินดี"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.6007,
        "lng": 73.0679
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 366,
      "name": "Portland",
      "names": {
        "zh": "波特兰",
        "ja": "ポートランド",
        "th": "พอร์ตแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.5372,
        "lng": -122.65
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 368,
      "name": "Hezhou",
      "names": {
        "zh": "贺州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.4164,
        "lng": 111.5478
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 365,
      "name": "Kumasi",
      "names": {
        "zh": "库马西"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 6.6833,
        "lng": -1.6167
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 369,
      "name": "Pingliang",
      "names": {
        "zh": "平凉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5412,
        "lng": 106.6819
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 367,
      "name": "Vadodara",
      "names": {
        "zh": "瓦多达拉",
        "ja": "ワドダラ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.3,
        "lng": 73.2
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 370,
      "name": "San Antonio",
      "names": {
        "zh": "圣安东尼奥",
        "ja": "サンアントニオ",
        "th": "ซานอันโตนิโอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.4658,
        "lng": -98.5253
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 371,
      "name": "Perth",
      "names": {
        "zh": "珀斯",
        "ja": "パース",
        "th": "เพิร์ธ"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -31.9522,
        "lng": 115.8589
      },
      "country": "Australia",
      "importance": 1
    },
    {
      "id": 372,
      "name": "Vishakhapatnam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.7333,
        "lng": 83.3167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 373,
      "name": "Shengli",
      "names": {
        "zh": "胜利"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.45,
        "lng": 118.4667
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 375,
      "name": "Baicheng",
      "names": {
        "zh": "白城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.6148,
        "lng": 122.832
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 374,
      "name": "Gujranwala",
      "names": {
        "zh": "吉兰瓦拉"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.15,
        "lng": 74.1833
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 377,
      "name": "St. Louis",
      "names": {
        "es": "San Luis",
        "fr": "Saint-Louis",
        "zh": "圣路易斯",
        "ja": "セントルイス",
        "th": "เซนต์หลุยส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6358,
        "lng": -90.2451
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 376,
      "name": "Fushun",
      "names": {
        "zh": "抚顺",
        "ja": "撫順",
        "th": "ฟุชุน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.8708,
        "lng": 123.8917
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 379,
      "name": "Quito",
      "names": {
        "zh": "基多",
        "ja": "キト",
        "th": "กีโต"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -0.22,
        "lng": -78.5125
      },
      "country": "Ecuador",
      "importance": 0
    },
    {
      "id": 380,
      "name": "Minsk",
      "names": {
        "zh": "明斯克",
        "ja": "ミンスク",
        "th": "มินสก์"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 53.9022,
        "lng": 27.5618
      },
      "country": "Belarus",
      "importance": 0
    },
    {
      "id": 378,
      "name": "Bamako",
      "names": {
        "zh": "巴马科",
        "ja": "バマコ",
        "th": "บามาโก"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 12.6458,
        "lng": -7.9922
      },
      "country": "Mali",
      "importance": 0
    },
    {
      "id": 381,
      "name": "Indore",
      "names": {
        "zh": "印多尔",
        "ja": "インドール",
        "th": "อินดอร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.7206,
        "lng": 75.8472
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 382,
      "name": "Yinchuan",
      "names": {
        "zh": "银川",
        "ja": "銀川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4795,
        "lng": 106.2254
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 383,
      "name": "Kananga",
      "names": {
        "zh": "卡南加"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.8961,
        "lng": 22.4167
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 384,
      "name": "Peshawar",
      "names": {
        "zh": "白沙瓦",
        "ja": "ペシャワル",
        "th": "เพชาวาร์"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.0,
        "lng": 71.5
      },
      "country": "Pakistan",
      "importance": 1
    },
    {
      "id": 385,
      "name": "Sapporo",
      "names": {
        "zh": "札幌",
        "ja": "札幌",
        "th": "ซัปโปโร"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 43.0621,
        "lng": 141.3544
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 386,
      "name": "Esfahan",
      "names": {
        "fr": "Ispahan",
        "zh": "伊斯法罕",
        "th": "เอสฟาฮาน"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 32.6447,
        "lng": 51.6675
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 387,
      "name": "Caracas",
      "names": {
        "zh": "加拉加斯",
        "ja": "カラカス",
        "th": "คารากัส"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.5,
        "lng": -66.9333
      },
      "country": "Venezuela",
      "importance": 0
    },
    {
      "id": 388,
      "name": "Pingxiang",
      "names": {
        "zh": "萍乡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.6333,
        "lng": 113.85
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 390,
      "name": "Tijuana",
      "names": {
        "zh": "蒂华纳",
        "ja": "ティファナ",
        "th": "ตีฮัวนา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 32.525,
        "lng": -117.0333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 391,
      "name": "Almaty",
      "names": {
        "zh": "阿拉木图",
        "ja": "アルマトイ",
        "th": "อัลมาตี"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.25,
        "lng": 76.9
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 389,
      "name": "Aleppo",
      "names": {
        "fr": "Alep",
        "zh": "阿勒颇",
        "ja": "アレッポ",
        "th": "อาเลปโป"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.2,
        "lng": 37.15
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 392,
      "name": "Vienna",
      "names": {
        "es": "Viena",
        "fr": "Vienne",
        "zh": "维也纳",
        "de": "Wien",
        "id": "Wina",
        "ja": "ウィーン",
        "th": "เวียนนา",
        "pt": "Viena"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2083,
        "lng": 16.3725
      },
      "country": "Austria",
      "importance": 0
    },
    {
      "id": 394,
      "name": "Sacramento",
      "names": {
        "zh": "萨克拉门托",
        "ja": "サクラメント",
        "th": "แซคราเมนโต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.5667,
        "lng": -121.4683
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 395,
      "name": "Blantyre",
      "names": {
        "zh": "布兰太尔",
        "ja": "ブランタイア",
        "th": "แบลนไทร์"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -15.7861,
        "lng": 35.0058
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 397,
      "name": "Zhuhai",
      "names": {
        "zh": "珠海",
        "ja": "珠海",
        "th": "จูไห่"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.2769,
        "lng": 113.5678
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 393,
      "name": "Thane",
      "names": {
        "zh": "塞恩",
        "ja": "セイン",
        "th": "โขดหิน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.18,
        "lng": 72.9633
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 396,
      "name": "Tainan",
      "names": {
        "zh": "台南",
        "ja": "台南",
        "th": "ไถหนาน"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 22.9833,
        "lng": 120.1833
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 398,
      "name": "Bucharest",
      "names": {
        "es": "Bucarest",
        "fr": "Bucarest",
        "zh": "布加勒斯特",
        "de": "Bukarest",
        "it": "Bucarest",
        "ja": "ブカレスト",
        "th": "บูคาเรสต์",
        "pt": "Bucareste"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.4,
        "lng": 26.0833
      },
      "country": "Romania",
      "importance": 0
    },
    {
      "id": 399,
      "name": "Curitiba",
      "names": {
        "zh": "库里提巴",
        "ja": "クリティバ",
        "th": "กูรีตีบา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.4297,
        "lng": -49.2719
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 401,
      "name": "Xiping",
      "names": {
        "zh": "西坪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.082,
        "lng": 113.2981
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 400,
      "name": "Multan",
      "names": {
        "zh": "木尔坦",
        "de": "Multän"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.1978,
        "lng": 71.4711
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 403,
      "name": "Jixi",
      "names": {
        "zh": "鸡西"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.2937,
        "lng": 130.965
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 404,
      "name": "Saidu Sharif",
      "names": {
        "zh": "Sharif Sharif",
        "ja": "Sharif",
        "th": "sadau sharif",
        "pt": "Sharif Sharif"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.75,
        "lng": 72.3572
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 405,
      "name": "Liaoyang",
      "names": {
        "zh": "辽阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.2643,
        "lng": 123.1772
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 402,
      "name": "Ecatepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6097,
        "lng": -99.06
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 406,
      "name": "Hamburg",
      "names": {
        "es": "Hamburgo",
        "fr": "Hambourg",
        "zh": "汉堡",
        "it": "Amburgo",
        "ja": "ハンブルク",
        "th": "ฮัมบูร์ก",
        "pt": "Hamburgo"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.55,
        "lng": 10.0
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 407,
      "name": "Meru",
      "names": {
        "zh": "梅鲁"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 0.05,
        "lng": 37.65
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 408,
      "name": "Brazzaville",
      "names": {
        "zh": "布拉柴维尔",
        "th": "บราซซาวิล"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.2667,
        "lng": 15.2833
      },
      "country": "Congo (Brazzaville)",
      "importance": 0
    },
    {
      "id": 409,
      "name": "Orlando",
      "names": {
        "zh": "奥兰多",
        "ja": "オーランド",
        "th": "ออร์แลนโด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.4772,
        "lng": -81.3369
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 410,
      "name": "Fuxin",
      "names": {
        "zh": "阜新"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.0127,
        "lng": 121.6486
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 411,
      "name": "Wuwei",
      "names": {
        "zh": "武威"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9278,
        "lng": 102.6329
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 412,
      "name": "Manaus",
      "names": {
        "zh": "马瑙斯",
        "ja": "マナウス",
        "th": "มาเนาส์"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.1,
        "lng": -60.0167
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 413,
      "name": "Bhopal",
      "names": {
        "zh": "博帕尔",
        "id": "Di Bhopal",
        "ja": "ボパール",
        "th": "โภปาล"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.25,
        "lng": 77.4167
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 414,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3019,
        "lng": -121.8486
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 415,
      "name": "Warsaw",
      "names": {
        "es": "Varsovia",
        "fr": "Varsovie",
        "zh": "华沙",
        "de": "Warschau",
        "it": "Varsavia",
        "ja": "ワルシャワ",
        "th": "วอร์ซอ",
        "pt": "Varsóvia"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.23,
        "lng": 21.0111
      },
      "country": "Poland",
      "importance": 0
    },
    {
      "id": 416,
      "name": "Lubumbashi",
      "names": {
        "zh": "卢本巴希",
        "ja": "ルブンバシ"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -11.6697,
        "lng": 27.4581
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 417,
      "name": "Davao",
      "names": {
        "zh": "达沃",
        "ja": "ダバオ",
        "th": "ดาเวา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0667,
        "lng": 125.6
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 418,
      "name": "Haikou",
      "names": {
        "zh": "海口",
        "th": "ไหโข่ว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 20.02,
        "lng": 110.32
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 420,
      "name": "Shuyangzha",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.1299,
        "lng": 118.7734
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 419,
      "name": "Damascus",
      "names": {
        "es": "Damasco",
        "fr": "Damas",
        "zh": "大马士革",
        "de": "Damaskus",
        "it": "Damasco",
        "id": "Damaskus",
        "ja": "ダマスカス",
        "th": "ดามัสกัส",
        "pt": "Damasco"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.5131,
        "lng": 36.2919
      },
      "country": "Syria",
      "importance": 0
    },
    {
      "id": 421,
      "name": "Brussels",
      "names": {
        "es": "Bruselas",
        "fr": "Bruxelles",
        "zh": "布鲁塞尔",
        "de": "Brüssel",
        "it": "Bruxelles",
        "ja": "ブリュッセル",
        "th": "บรัสเซลส์",
        "pt": "Bruxelas"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8353,
        "lng": 4.3314
      },
      "country": "Belgium",
      "importance": 0
    },
    {
      "id": 422,
      "name": "Lusaka",
      "names": {
        "zh": "卢萨卡",
        "ja": "ルサカ",
        "th": "ลูซากา"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -15.4167,
        "lng": 28.2833
      },
      "country": "Zambia",
      "importance": 0
    },
    {
      "id": 423,
      "name": "Hyderabad City",
      "names": {
        "es": "Ciudad de Hyderabad",
        "zh": "海得拉巴城",
        "de": "Hyderabad-Stadt",
        "it": "Città di Hyderabad",
        "id": "Kota Hyderabad",
        "ja": "ハイデラバードシティ",
        "th": "เมืองไฮเดอราบัด",
        "pt": "Cidade de hyderabad"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 25.3792,
        "lng": 68.3683
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 424,
      "name": "Budapest",
      "names": {
        "zh": "布达佩斯",
        "ja": "ブダペスト",
        "th": "บูดาเปสต์",
        "pt": "Budapeste"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4983,
        "lng": 19.0408
      },
      "country": "Hungary",
      "importance": 0
    },
    {
      "id": 425,
      "name": "Shuozhou",
      "names": {
        "zh": "朔州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.3408,
        "lng": 112.4292
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 426,
      "name": "Cleveland",
      "names": {
        "zh": "克利夫兰",
        "ja": "クリーブランド",
        "th": "คลีฟแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.4767,
        "lng": -81.6804
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 427,
      "name": "Benxi",
      "names": {
        "zh": "本溪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.292,
        "lng": 123.761
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 428,
      "name": "Baiyin",
      "names": {
        "zh": "白银"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.5448,
        "lng": 104.1766
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 429,
      "name": "Pittsburgh",
      "names": {
        "zh": "匹兹堡",
        "ja": "ピッツバーグ",
        "th": "พิตส์เบิร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4396,
        "lng": -79.9762
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 431,
      "name": "Mosul",
      "names": {
        "fr": "Mossoul",
        "zh": "摩苏尔",
        "ja": "モースル",
        "th": "โมซุล"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.3667,
        "lng": 43.1167
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 433,
      "name": "Austin",
      "names": {
        "zh": "奥斯汀",
        "ja": "オースティン",
        "th": "ออสติน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.3004,
        "lng": -97.7522
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 430,
      "name": "Patna",
      "names": {
        "zh": "巴特那",
        "ja": "パトナ",
        "th": "ปัฏนา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.61,
        "lng": 85.1414
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 434,
      "name": "Sanzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.82,
        "lng": 108.4
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 435,
      "name": "Beihai",
      "names": {
        "zh": "北海",
        "ja": "北海"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.4667,
        "lng": 109.1
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 436,
      "name": "Mecca",
      "names": {
        "es": "La Meca",
        "fr": "La mecque",
        "zh": "麦加",
        "de": "Mekka",
        "it": "La Mecca",
        "id": "Mekkah",
        "ja": "メッカ",
        "th": "เมกกะ",
        "pt": "Meca"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 21.4225,
        "lng": 39.8261
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 437,
      "name": "Heihe",
      "names": {
        "zh": "黑河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 50.2458,
        "lng": 127.4886
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 438,
      "name": "Jingdezhen",
      "names": {
        "zh": "景德镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.2942,
        "lng": 117.2036
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 439,
      "name": "Conakry",
      "names": {
        "zh": "科纳克里",
        "ja": "コナクリ",
        "th": "โกนากรี",
        "pt": "Conacri"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 9.538,
        "lng": -13.6773
      },
      "country": "Guinea",
      "importance": 0
    },
    {
      "id": 441,
      "name": "Cincinnati",
      "names": {
        "zh": "辛辛那提",
        "ja": "シンシナティ",
        "th": "ซินซินนาติ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1413,
        "lng": -84.5061
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 432,
      "name": "Caloocan City",
      "names": {
        "es": "Ciudad de Caloocan",
        "fr": "Ville caloocane",
        "zh": "卡卢坎市",
        "de": "Kalookan-Stadt",
        "it": "Città di Caloocan",
        "id": "Kota caloocan",
        "ja": "カロカンシティ",
        "th": "เมือง Caloocan",
        "pt": "Cidade de Caloocan"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.65,
        "lng": 120.9667
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 440,
      "name": "Kampala",
      "names": {
        "zh": "坎帕拉",
        "ja": "カンパラ",
        "th": "กัมปาลา"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.3136,
        "lng": 32.5811
      },
      "country": "Uganda",
      "importance": 0
    },
    {
      "id": 442,
      "name": "Recife",
      "names": {
        "zh": "累西腓",
        "ja": "レシフェ",
        "th": "เรซีเฟ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.0539,
        "lng": -34.8808
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 443,
      "name": "Yushan",
      "names": {
        "zh": "玉山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.3867,
        "lng": 120.9766
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 445,
      "name": "Kansas City",
      "names": {
        "es": "Ciudad de Kansas",
        "zh": "堪萨斯城",
        "id": "Kota Kansas",
        "ja": "カンザスシティ",
        "th": "เมืองแคนซัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1239,
        "lng": -94.5541
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 444,
      "name": "Zhongli",
      "names": {
        "zh": "忠丽"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.965,
        "lng": 121.2168
      },
      "country": "Taiwan",
      "importance": 3
    },
    {
      "id": 446,
      "name": "Manhattan",
      "names": {
        "zh": "曼哈顿",
        "ja": "マンハッタン",
        "th": "แมนฮัตตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7834,
        "lng": -73.9662
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 447,
      "name": "Bilaspur",
      "names": {
        "zh": "比拉斯布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.15,
        "lng": 82.0167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 448,
      "name": "Semarang",
      "names": {
        "zh": "三宝垄",
        "th": "เซมารัง"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.9667,
        "lng": 110.4167
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 449,
      "name": "Ludhiana",
      "names": {
        "zh": "卢迪亚纳",
        "ja": "ルディアーナ",
        "th": "ลูเธียนา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 30.9083,
        "lng": 75.8486
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 451,
      "name": "Chengtangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.0833,
        "lng": 117.15
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 450,
      "name": "Novosibirsk",
      "names": {
        "zh": "新西伯利亚",
        "ja": "ノボシビルスク",
        "th": "โนโว"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.0333,
        "lng": 82.9167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 453,
      "name": "Karaj",
      "names": {
        "zh": "卡拉季"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.8327,
        "lng": 50.9915
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 452,
      "name": "Agra",
      "names": {
        "zh": "阿格拉",
        "ja": "アグラ",
        "th": "ในอักกรา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.1724,
        "lng": 78.0131
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 455,
      "name": "Indianapolis",
      "names": {
        "zh": "印第安纳波利斯",
        "ja": "インディアナポリス",
        "th": "อินเดียแนโพลิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7771,
        "lng": -86.1458
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 454,
      "name": "Wenzhou",
      "names": {
        "zh": "温州",
        "ja": "温州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.9991,
        "lng": 120.6561
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 456,
      "name": "Leon de los Aldama",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1167,
        "lng": -101.6833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 458,
      "name": "Kalyan",
      "names": {
        "zh": "格利扬"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.2502,
        "lng": 73.1602
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 457,
      "name": "Puebla",
      "names": {
        "zh": "普埃布拉",
        "ja": "プエブラ",
        "th": "ปวยบลา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0333,
        "lng": -98.1833
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 461,
      "name": "Hebi",
      "names": {
        "zh": "鹤壁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7497,
        "lng": 114.2887
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 462,
      "name": "Shiraz",
      "names": {
        "zh": "设拉子",
        "ja": "シーラーズ",
        "th": "ชีราซ"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 29.61,
        "lng": 52.5425
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 464,
      "name": "Columbus",
      "names": {
        "zh": "哥伦布",
        "ja": "コロンバス",
        "th": "โคลัมบัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9862,
        "lng": -82.985
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 459,
      "name": "Madurai",
      "names": {
        "zh": "马杜赖",
        "ja": "マドゥライ",
        "th": "ซาน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 9.9197,
        "lng": 78.1194
      },
      "country": "India",
      "importance": 2
    },
    {
      "id": 460,
      "name": "Mbuji-Mayi",
      "names": {
        "zh": "姆布吉马伊"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -6.1209,
        "lng": 23.5967
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 465,
      "name": "Tabriz",
      "names": {
        "zh": "大不里士",
        "ja": "タブリーズ"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 38.0833,
        "lng": 46.2833
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 463,
      "name": "Jamshedpur",
      "names": {
        "zh": "贾姆谢德布尔",
        "th": "ชัมเศทปุระ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.8,
        "lng": 86.1833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 466,
      "name": "Maracaibo",
      "names": {
        "zh": "马拉开波",
        "th": "มาราไกโบ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.6333,
        "lng": -71.6333
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 467,
      "name": "Kawasaki",
      "names": {
        "zh": "川崎",
        "ja": "川崎",
        "th": "คาวาซากิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5167,
        "lng": 139.7
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 470,
      "name": "Zhangjiajie",
      "names": {
        "zh": "张家界",
        "th": "จางเจียเจี้ย"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.1255,
        "lng": 110.4844
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 469,
      "name": "Palembang",
      "names": {
        "zh": "巨港",
        "th": "ปาเลมบัง"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -2.9833,
        "lng": 104.7644
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 468,
      "name": "Aba",
      "names": {
        "zh": "阿坝",
        "ja": "はABA",
        "th": "เอบีเอ"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.1167,
        "lng": 7.3667
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 471,
      "name": "Kobe",
      "names": {
        "zh": "神户",
        "ja": "神戸",
        "th": "โกเบ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6913,
        "lng": 135.183
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 472,
      "name": "Charlotte",
      "names": {
        "zh": "夏洛特",
        "ja": "シャーロット",
        "th": "ชาร์ล็อตต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.208,
        "lng": -80.8304
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 473,
      "name": "Changshu",
      "names": {
        "zh": "常熟"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.65,
        "lng": 120.7333
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 474,
      "name": "Lianjiang",
      "names": {
        "zh": "廉江市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.6146,
        "lng": 110.2794
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 475,
      "name": "Ximeicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.9633,
        "lng": 118.3811
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 476,
      "name": "Jianguang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.1958,
        "lng": 115.7833
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 477,
      "name": "Yucheng",
      "names": {
        "zh": "禹城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.9888,
        "lng": 103.0007
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 478,
      "name": "Belem",
      "names": {
        "zh": "贝伦",
        "ja": "ベレン",
        "th": "เบเลง",
        "pt": "Belém"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.4558,
        "lng": -48.5039
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 479,
      "name": "Guiping",
      "names": {
        "zh": "桂平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.4,
        "lng": 110.0833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 480,
      "name": "Leizhou",
      "names": {
        "zh": "雷州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 20.9147,
        "lng": 110.0806
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 481,
      "name": "Gwangju",
      "names": {
        "zh": "光州",
        "ja": "光州",
        "th": "กวางจู"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.1667,
        "lng": 126.9167
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 482,
      "name": "Munich",
      "names": {
        "es": "Múnich",
        "zh": "慕尼黑",
        "de": "München",
        "it": "Monaco di baviera",
        "ja": "ミュンヘン",
        "th": "มิวนิค",
        "pt": "Munique"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.1375,
        "lng": 11.575
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 483,
      "name": "Nasik",
      "names": {
        "zh": "纳西克",
        "ja": "ナーシク",
        "th": "ซิก"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.0,
        "lng": 73.7833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 484,
      "name": "Porto Alegre",
      "names": {
        "ja": "ポルトアレグレ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -30.0328,
        "lng": -51.23
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 485,
      "name": "Valencia",
      "names": {
        "fr": "Valence",
        "zh": "瓦伦西亚",
        "ja": "バレンシア",
        "th": "วาเลนเซีย",
        "pt": "Valência"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1667,
        "lng": -68.0
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 486,
      "name": "Onitsha",
      "names": {
        "zh": "奥尼查"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.1667,
        "lng": 6.7833
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 487,
      "name": "Abu Dhabi",
      "names": {
        "zh": "阿布扎比",
        "ja": "アブダビ",
        "th": "อาบูดาบี"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 24.4511,
        "lng": 54.3969
      },
      "country": "United Arab Emirates",
      "importance": 0
    },
    {
      "id": 488,
      "name": "Virginia Beach",
      "names": {
        "es": "Playa de Virginia",
        "zh": "弗吉尼亚海滩",
        "id": "Pantai Virginia",
        "ja": "バージニアビーチ",
        "th": "เวอร์จิเนียบีช",
        "pt": "Praia de virgínia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.7335,
        "lng": -76.0435
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 490,
      "name": "Zapopan",
      "names": {
        "zh": "萨波潘"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7167,
        "lng": -103.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 489,
      "name": "Daejeon",
      "names": {
        "zh": "大田",
        "ja": "大田（テジョン）",
        "th": "แทจอน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.351,
        "lng": 127.385
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 491,
      "name": "Yekaterinburg",
      "names": {
        "es": "Ekaterimburgo",
        "zh": "叶卡捷琳堡",
        "de": "Jekaterinburg",
        "it": "Ekaterinburg",
        "th": "เยคาเตรินบูร์ก"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.8356,
        "lng": 60.6128
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 492,
      "name": "Huazhou",
      "names": {
        "zh": "化州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.6832,
        "lng": 112.079
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 493,
      "name": "Kyoto",
      "names": {
        "zh": "京都议定书",
        "ja": "京都",
        "th": "เกียวโต",
        "pt": "De Quioto"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0117,
        "lng": 135.7683
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 494,
      "name": "Shuangyashan",
      "names": {
        "zh": "双鸭山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.6388,
        "lng": 131.1545
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 495,
      "name": "Pizhou",
      "names": {
        "zh": "邳州市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.3422,
        "lng": 118.0097
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 496,
      "name": "El Kelaa des Srarhna",
      "names": {
        "zh": "El Kelaa des Srarna"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.05,
        "lng": -7.4
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 497,
      "name": "Kharkiv",
      "names": {
        "zh": "哈尔科夫",
        "th": "คาร์คิฟ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.0,
        "lng": 36.2292
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 498,
      "name": "Yangshe",
      "names": {
        "zh": "杨舍"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8775,
        "lng": 120.5512
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 499,
      "name": "Guyuan",
      "names": {
        "zh": "固原"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.008,
        "lng": 106.2782
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 501,
      "name": "Muscat",
      "names": {
        "zh": "马斯喀特",
        "id": "Muskat",
        "ja": "マスカット",
        "th": "มัสกัต"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 23.6139,
        "lng": 58.5922
      },
      "country": "Oman",
      "importance": 0
    },
    {
      "id": 502,
      "name": "Bronx",
      "names": {
        "zh": "布朗克斯",
        "ja": "ブロンクス",
        "th": "บรองซ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8501,
        "lng": -73.8662
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 503,
      "name": "Gaozhou",
      "names": {
        "zh": "高州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.9135,
        "lng": 110.8481
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 504,
      "name": "Chizhou",
      "names": {
        "zh": "池州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6583,
        "lng": 117.4849
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 500,
      "name": "Khulna",
      "names": {
        "zh": "库尔纳"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 22.8167,
        "lng": 89.55
      },
      "country": "Bangladesh",
      "importance": 1
    },
    {
      "id": 506,
      "name": "Ulaanbaatar",
      "names": {
        "es": "Ulán Bator",
        "fr": "Oulan-Bator",
        "zh": "乌兰巴托",
        "ja": "ウランバートル",
        "th": "อูลานบาตอร์"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 47.9214,
        "lng": 106.9055
      },
      "country": "Mongolia",
      "importance": 0
    },
    {
      "id": 505,
      "name": "Faridabad",
      "names": {
        "zh": "法里达巴德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.4353,
        "lng": 77.3147
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 507,
      "name": "Goiania",
      "names": {
        "zh": "戈亚尼亚",
        "de": "Goiânia",
        "th": "กัวเนีย",
        "pt": "Goiânia"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.6806,
        "lng": -49.2564
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 508,
      "name": "Fuqing",
      "names": {
        "zh": "福清"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.7232,
        "lng": 119.3735
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 510,
      "name": "Pingdu",
      "names": {
        "zh": "平度"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.7833,
        "lng": 119.9556
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 509,
      "name": "Belgrade",
      "names": {
        "es": "Belgrado",
        "zh": "贝尔格莱德",
        "de": "Belgrad",
        "it": "Belgrado",
        "ja": "ベオグラード",
        "th": "เบลเกรด",
        "pt": "Belgrado"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.8167,
        "lng": 20.4667
      },
      "country": "Serbia",
      "importance": 0
    },
    {
      "id": 512,
      "name": "Aurangabad",
      "names": {
        "zh": "奥兰加巴德",
        "th": "ออรันกาบัด"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.88,
        "lng": 75.32
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 513,
      "name": "Yutan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.3147,
        "lng": 112.554
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 511,
      "name": "Milan",
      "names": {
        "es": "Milán",
        "zh": "米兰",
        "de": "Mailand",
        "it": "Milano",
        "ja": "ミラノ",
        "th": "มิลาน",
        "pt": "Milão"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4669,
        "lng": 9.19
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 514,
      "name": "Milwaukee",
      "names": {
        "zh": "密尔沃基",
        "ja": "ミルウォーキー",
        "th": "มิลวอกี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0642,
        "lng": -87.9673
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 515,
      "name": "Huangshan",
      "names": {
        "zh": "黄山",
        "ja": "黄山市",
        "th": "หวงซาน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.7132,
        "lng": 118.3151
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 516,
      "name": "Auckland",
      "names": {
        "zh": "奥克兰",
        "ja": "オークランド",
        "th": "โอ๊คแลนด์"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -36.85,
        "lng": 174.7833
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 518,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.45,
        "lng": -70.7
      },
      "country": "Dominican Republic",
      "importance": 1
    },
    {
      "id": 517,
      "name": "Makassar",
      "names": {
        "zh": "望加锡",
        "ja": "マカッサル",
        "th": "มากัสซาร์"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -5.1331,
        "lng": 119.4136
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 521,
      "name": "Liangshi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.2578,
        "lng": 111.7351
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 520,
      "name": "Rajkot",
      "names": {
        "zh": "拉杰果德",
        "ja": "ラージコット"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.2969,
        "lng": 70.7984
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 519,
      "name": "Prague",
      "names": {
        "es": "Praga",
        "zh": "布拉格",
        "de": "Prag",
        "it": "Praga",
        "id": "Praha",
        "ja": "プラハ",
        "th": "ปราก",
        "pt": "Praga"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.0833,
        "lng": 14.4167
      },
      "country": "Czechia",
      "importance": 0
    },
    {
      "id": 522,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.4167,
        "lng": -64.1833
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 524,
      "name": "Al Basrah",
      "names": {
        "de": "Alte Basrah",
        "th": "อัลบาอาร์ห์",
        "pt": "Al basáh"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 30.515,
        "lng": 47.81
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 523,
      "name": "Guarulhos",
      "names": {
        "th": "กัวรุลฮอส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.4667,
        "lng": -46.5333
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 526,
      "name": "Juarez",
      "names": {
        "es": "Juárez",
        "zh": "华雷斯",
        "th": "ฮัวเรซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.7386,
        "lng": -106.487
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 525,
      "name": "Saitama",
      "names": {
        "zh": "埼玉",
        "ja": "埼玉",
        "th": "ไซตามะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8617,
        "lng": 139.6453
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 527,
      "name": "Montevideo",
      "names": {
        "zh": "蒙得维的亚（Montevideo）",
        "ja": "モンテビデオ",
        "th": "มอนเตวิเดโอ",
        "pt": "Montevidéu"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.8667,
        "lng": -56.1667
      },
      "country": "Uruguay",
      "importance": 0
    },
    {
      "id": 528,
      "name": "Xintai",
      "names": {
        "zh": "新泰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.91,
        "lng": 117.78
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 529,
      "name": "Wusong",
      "names": {
        "zh": "吴淞"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9333,
        "lng": 117.7667
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 530,
      "name": "Meerut",
      "names": {
        "zh": "密拉特",
        "ja": "メーラト",
        "th": "รุท"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.99,
        "lng": 77.7
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 531,
      "name": "Yushu",
      "names": {
        "zh": "玉树"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.8249,
        "lng": 126.5451
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 532,
      "name": "Rongcheng",
      "names": {
        "zh": "荣成"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.2312,
        "lng": 104.0966
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 533,
      "name": "Huazhou",
      "names": {
        "zh": "化州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.654,
        "lng": 110.6294
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 534,
      "name": "Yangquan",
      "names": {
        "zh": "阳泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.8576,
        "lng": 113.5762
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 536,
      "name": "Baishan",
      "names": {
        "zh": "白山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.9377,
        "lng": 126.4179
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 537,
      "name": "Dayan",
      "names": {
        "zh": "达杨",
        "ja": "ダヤン"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.8808,
        "lng": 100.2208
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 535,
      "name": "Adelaide",
      "names": {
        "zh": "阿德莱德",
        "ja": "アデレード",
        "th": "แอดิเลด"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -34.9275,
        "lng": 138.6
      },
      "country": "Australia",
      "importance": 1
    },
    {
      "id": 538,
      "name": "Haicheng",
      "names": {
        "zh": "海城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.8523,
        "lng": 122.7474
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 539,
      "name": "Jiangyin",
      "names": {
        "zh": "江阴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.9087,
        "lng": 120.2653
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 541,
      "name": "Wuzhong",
      "names": {
        "zh": "吴忠"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9874,
        "lng": 106.1919
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 542,
      "name": "Cacuaco",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.7833,
        "lng": 13.3667
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 544,
      "name": "Sofia",
      "names": {
        "zh": "索非亚",
        "ja": "ソフィア",
        "th": "โซเฟีย"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.6979,
        "lng": 23.3217
      },
      "country": "Bulgaria",
      "importance": 0
    },
    {
      "id": 543,
      "name": "Barranquilla",
      "names": {
        "zh": "巴兰基亚",
        "th": "ในบาร์รันกียา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.9639,
        "lng": -74.7964
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 545,
      "name": "Soweto",
      "names": {
        "zh": "索韦托",
        "ja": "ソウェト"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.2678,
        "lng": 27.8586
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 546,
      "name": "Jabalpur",
      "names": {
        "zh": "贾巴尔普尔",
        "ja": "ジャバルプル",
        "th": "จา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.1667,
        "lng": 79.9333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 547,
      "name": "Rucheng",
      "names": {
        "zh": "汝城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.3852,
        "lng": 120.5634
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 548,
      "name": "Nizhniy Novgorod",
      "names": {
        "de": "Nischniy Novgorod"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3269,
        "lng": 44.0075
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 540,
      "name": "Huaiyin",
      "names": {
        "zh": "淮阴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.5819,
        "lng": 119.028
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 549,
      "name": "Shaoyang",
      "names": {
        "zh": "邵阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9387,
        "lng": 119.8404
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 551,
      "name": "Comayaguela",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 14.0833,
        "lng": -87.2167
      },
      "country": "Honduras",
      "importance": 3
    },
    {
      "id": 552,
      "name": "Laiwu",
      "names": {
        "zh": "莱芜"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.1833,
        "lng": 117.6667
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 553,
      "name": "Sharjah",
      "names": {
        "zh": "沙迦",
        "ja": "シャルジャ",
        "th": "ชาร์จาห์"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.3575,
        "lng": 55.3919
      },
      "country": "United Arab Emirates",
      "importance": 1
    },
    {
      "id": 554,
      "name": "Jingling",
      "names": {
        "es": "Tintineo",
        "zh": "玎",
        "de": "Geklimper",
        "ja": "ジャラジャラ",
        "th": "การทำเสียงกรุ๊งกริ้ง",
        "pt": "Tinido"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6667,
        "lng": 113.1667
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 550,
      "name": "Dhanbad",
      "names": {
        "zh": "丹巴德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.7928,
        "lng": 86.435
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 555,
      "name": "Kazan",
      "names": {
        "zh": "喀山",
        "ja": "カザン",
        "th": "คาซาน"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7908,
        "lng": 49.1144
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 557,
      "name": "Yongcheng",
      "names": {
        "zh": "永城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.9317,
        "lng": 116.4459
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 558,
      "name": "Calgary",
      "names": {
        "zh": "卡尔加里",
        "ja": "カルガリー",
        "th": "คาลการี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 51.05,
        "lng": -114.0667
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 559,
      "name": "Can Tho",
      "names": {
        "es": "Puede",
        "fr": "Peut-être",
        "zh": "可以吗？",
        "de": "Kann das",
        "it": "Posso",
        "id": "Bisa tho",
        "ja": "できます",
        "th": "สรรพสินค้า",
        "pt": "Tho"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.0333,
        "lng": 105.7833
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 560,
      "name": "Abuja",
      "names": {
        "zh": "阿布贾",
        "ja": "アブジャ",
        "th": "อาบูจา"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.0556,
        "lng": 7.4914
      },
      "country": "Nigeria",
      "importance": 0
    },
    {
      "id": 556,
      "name": "Suwon",
      "names": {
        "zh": "水原",
        "ja": "水原",
        "th": "ซูวอน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.2858,
        "lng": 127.01
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 561,
      "name": "Mandalay",
      "names": {
        "zh": "曼德勒",
        "ja": "マンダレー",
        "th": "มัณฑะเลย์"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 21.9831,
        "lng": 96.0844
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 562,
      "name": "Beidao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.602,
        "lng": 105.918
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 563,
      "name": "Xiangshui",
      "names": {
        "zh": "响水"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.5964,
        "lng": 104.8314
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 564,
      "name": "Dadukou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.5849,
        "lng": 101.7149
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 565,
      "name": "Vila Velha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": 3.2167,
        "lng": -51.2167
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 568,
      "name": "Providence",
      "names": {
        "es": "La Providencia",
        "zh": "普罗维登斯",
        "ja": "プロビデンス",
        "th": "พร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.823,
        "lng": -71.4187
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 566,
      "name": "Chelyabinsk",
      "names": {
        "zh": "车里雅宾斯克",
        "de": "Tscheljabinsk",
        "ja": "チェリャビンスク",
        "th": "เชเลียบินสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.15,
        "lng": 61.4
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 570,
      "name": "Qom",
      "names": {
        "zh": "库姆"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.64,
        "lng": 50.8764
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 567,
      "name": "Mombasa",
      "names": {
        "zh": "蒙巴萨",
        "ja": "モンバサ",
        "th": "มอมบาซา"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -4.05,
        "lng": 39.6667
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 571,
      "name": "Zhangye",
      "names": {
        "zh": "张掖"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.9355,
        "lng": 100.4553
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 569,
      "name": "Varanasi",
      "names": {
        "zh": "瓦拉纳西",
        "ja": "バラナシ",
        "th": "พาราณสี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.3189,
        "lng": 83.0128
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 572,
      "name": "Hiroshima",
      "names": {
        "zh": "广岛",
        "ja": "広島",
        "th": "ฮิโรชิมา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4,
        "lng": 132.45
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 573,
      "name": "Maiduguri",
      "names": {
        "zh": "迈杜古里"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 11.8333,
        "lng": 13.15
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 576,
      "name": "Rosario",
      "names": {
        "zh": "罗萨里奥",
        "ja": "ロザリオ",
        "th": "โรซาริโอ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.9575,
        "lng": -60.6394
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 574,
      "name": "Maputo",
      "names": {
        "zh": "马普托",
        "ja": "マプト",
        "th": "มาปูโต"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -25.9153,
        "lng": 32.5764
      },
      "country": "Mozambique",
      "importance": 0
    },
    {
      "id": 578,
      "name": "Xinyu",
      "names": {
        "zh": "新余",
        "th": "ซินหยู"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.795,
        "lng": 114.9242
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 575,
      "name": "Doha",
      "names": {
        "zh": "多哈",
        "ja": "ドーハ",
        "th": "โดฮา"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.3,
        "lng": 51.5333
      },
      "country": "Qatar",
      "importance": 0
    },
    {
      "id": 579,
      "name": "Ahvaz",
      "names": {
        "zh": "阿瓦士"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 31.3203,
        "lng": 48.6692
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 580,
      "name": "Jacksonville",
      "names": {
        "zh": "杰克逊维尔",
        "ja": "ジャクソンビル",
        "th": "แจ็กสันวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.3322,
        "lng": -81.6749
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 581,
      "name": "Medina",
      "names": {
        "zh": "麦地那",
        "ja": "メディナ",
        "th": "เมดินา"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 24.4667,
        "lng": 39.6
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 582,
      "name": "Srinagar",
      "names": {
        "zh": "斯利那加",
        "ja": "スリナガル",
        "th": "ศรีนคร"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 34.0911,
        "lng": 74.8061
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 583,
      "name": "Omsk",
      "names": {
        "zh": "鄂木斯克",
        "ja": "オムスク",
        "th": "ออมสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.9667,
        "lng": 73.3833
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 584,
      "name": "Liaoyuan",
      "names": {
        "zh": "辽源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.8976,
        "lng": 125.1381
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 586,
      "name": "Yingtan",
      "names": {
        "zh": "鹰潭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.2333,
        "lng": 117.0
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 585,
      "name": "Cilacap",
      "names": {
        "zh": "芝拉扎"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.7167,
        "lng": 109.017
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 577,
      "name": "Pikine",
      "names": {},
      "countryCode": "SN",
      "latLng": {
        "lat": 14.75,
        "lng": -17.4
      },
      "country": "Senegal",
      "importance": 3
    },
    {
      "id": 588,
      "name": "Samara",
      "names": {
        "zh": "萨马拉",
        "ja": "サマラ",
        "th": "ซามารา"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.1833,
        "lng": 50.1167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 589,
      "name": "Guankou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.2363,
        "lng": 113.6984
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 587,
      "name": "Bandar Lampung",
      "names": {
        "de": "Bandar-Lampe",
        "ja": "バンダルランプ",
        "th": "ผ้าพันแผล"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -5.4294,
        "lng": 105.2625
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 590,
      "name": "Ulsan",
      "names": {
        "zh": "蔚山",
        "ja": "蔚山",
        "th": "อุลซาน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.55,
        "lng": 129.3167
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 591,
      "name": "Dingzhou",
      "names": {
        "zh": "定州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.5158,
        "lng": 114.9845
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 592,
      "name": "Campinas",
      "names": {
        "zh": "坎皮纳斯",
        "ja": "カンピナス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9009,
        "lng": -47.0573
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 593,
      "name": "Lianyuan",
      "names": {
        "zh": "涟源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.6961,
        "lng": 111.6659
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 595,
      "name": "Rongcheng",
      "names": {
        "zh": "荣成"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.8239,
        "lng": 112.9019
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 597,
      "name": "Nay Pyi Taw",
      "names": {
        "fr": "Taw Nay Pyi",
        "ja": "Pyi Taw"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 19.7475,
        "lng": 96.115
      },
      "country": "Myanmar",
      "importance": 0
    },
    {
      "id": 596,
      "name": "Kaiyuan",
      "names": {
        "zh": "开元"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0656,
        "lng": 113.8153
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 598,
      "name": "Kigali",
      "names": {
        "zh": "基加利",
        "ja": "キガリ",
        "th": "คิกาลี"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -1.9536,
        "lng": 30.0606
      },
      "country": "Rwanda",
      "importance": 0
    },
    {
      "id": 599,
      "name": "Leiyang",
      "names": {
        "zh": "耒阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.4179,
        "lng": 112.8457
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 600,
      "name": "Yichun",
      "names": {
        "zh": "宜春",
        "id": "Di Yichun"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.7235,
        "lng": 128.8893
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 601,
      "name": "Benin City",
      "names": {
        "es": "Ciudad de Benin",
        "fr": "Bénin City",
        "zh": "贝宁市",
        "de": "Benin-Stadt",
        "id": "Kota Benin",
        "ja": "ベナンシティ",
        "th": "เมืองเบนิน",
        "pt": "Cidade benim"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.3176,
        "lng": 5.6145
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 594,
      "name": "Dakar",
      "names": {
        "zh": "达喀尔",
        "ja": "ダカール",
        "th": "ดาการ์"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.7319,
        "lng": -17.4572
      },
      "country": "Senegal",
      "importance": 0
    },
    {
      "id": 603,
      "name": "Xiantao",
      "names": {
        "zh": "仙桃"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.3833,
        "lng": 113.4
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 602,
      "name": "Birmingham",
      "names": {
        "zh": "伯明翰",
        "ja": "バーミンガム",
        "th": "เบอร์มิงแฮม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.48,
        "lng": -1.9025
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 606,
      "name": "Monterrey",
      "names": {
        "zh": "蒙特雷",
        "ja": "モンテレー",
        "th": "มอนเตร์เรย์"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.6667,
        "lng": -100.3
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 604,
      "name": "Amritsar",
      "names": {
        "zh": "阿姆利则",
        "ja": "アムリトサル",
        "th": "อัมริตซาร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 31.6269,
        "lng": 74.877
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 608,
      "name": "Yingchuan",
      "names": {
        "zh": "颍川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.1511,
        "lng": 113.4733
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 607,
      "name": "Aligarh",
      "names": {
        "zh": "阿里格尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.88,
        "lng": 78.08
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 605,
      "name": "Callao",
      "names": {
        "zh": "卡亚俄"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -12.0611,
        "lng": -77.1333
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 611,
      "name": "Nampo",
      "names": {
        "zh": "南浦"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 38.7689,
        "lng": 125.4505
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 613,
      "name": "Tegucigalpa",
      "names": {
        "zh": "特古西加尔巴",
        "ja": "テグシガルパ",
        "th": "เตกูซิกัลปา"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.0942,
        "lng": -87.2067
      },
      "country": "Honduras",
      "importance": 0
    },
    {
      "id": 610,
      "name": "Tripoli",
      "names": {
        "es": "Trípoli",
        "zh": "的黎波里",
        "de": "Tripolis",
        "ja": "トリポリ",
        "th": "ตริโปลี",
        "pt": "Trípoli"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.8752,
        "lng": 13.1875
      },
      "country": "Libya",
      "importance": 0
    },
    {
      "id": 612,
      "name": "Rostov",
      "names": {
        "zh": "罗斯托夫",
        "ja": "ロストフ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.2333,
        "lng": 39.7167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 614,
      "name": "Tbilisi",
      "names": {
        "fr": "Tbilissi",
        "zh": "第比利斯",
        "de": "Tiflis",
        "ja": "トビリシ",
        "th": "ทบิลิซี"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 41.7225,
        "lng": 44.7925
      },
      "country": "Georgia",
      "importance": 0
    },
    {
      "id": 615,
      "name": "Guwahati",
      "names": {
        "zh": "古瓦哈提",
        "ja": "グワーハーティー",
        "th": "วาฮาติ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.1722,
        "lng": 91.7458
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 616,
      "name": "Ufa",
      "names": {
        "zh": "乌法"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.7261,
        "lng": 55.9475
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 617,
      "name": "Fes",
      "names": {
        "fr": "Fès",
        "zh": "非斯",
        "it": "Fès",
        "ja": "フェス",
        "th": "เฟส"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.0433,
        "lng": -5.0033
      },
      "country": "Morocco",
      "importance": 1
    },
    {
      "id": 609,
      "name": "Ciudad Nezahualcoyotl",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.41,
        "lng": -99.03
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 618,
      "name": "Bien Hoa",
      "names": {
        "de": "Bienhau",
        "it": "Bien HAA"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.9508,
        "lng": 106.8221
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 620,
      "name": "Ikare",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.5304,
        "lng": 5.76
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 621,
      "name": "Salt Lake City",
      "names": {
        "zh": "盐湖城",
        "ja": "ソルトレイクシティ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7777,
        "lng": -111.9306
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 622,
      "name": "Bhilai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.2186,
        "lng": 81.4314
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 625,
      "name": "Xibeijie",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.737,
        "lng": 98.5049
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 624,
      "name": "Tamale",
      "names": {
        "zh": "塔马利",
        "ja": "タマレ"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 9.4075,
        "lng": -0.8533
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 628,
      "name": "Hanchuan",
      "names": {
        "zh": "汉川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.652,
        "lng": 113.8274
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 629,
      "name": "Gongzhuling",
      "names": {
        "zh": "公主岭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.5036,
        "lng": 124.8088
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 619,
      "name": "N\u0027Djamena",
      "names": {
        "zh": "恩贾梅纳",
        "th": "เอ็นจาเมนา"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 12.11,
        "lng": 15.05
      },
      "country": "Chad",
      "importance": 0
    },
    {
      "id": 626,
      "name": "Copenhagen",
      "names": {
        "es": "Copenhague",
        "fr": "Copenhague",
        "zh": "哥本哈根",
        "de": "Kopenhagen",
        "ja": "コペンハーゲン",
        "th": "โคเปนเฮเกน",
        "pt": "Copenhaga"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.6805,
        "lng": 12.5615
      },
      "country": "Denmark",
      "importance": 0
    },
    {
      "id": 630,
      "name": "Krasnoyarsk",
      "names": {
        "zh": "克拉斯诺亚尔斯克",
        "de": "Krasnojarsk",
        "id": "Di Krasnoyarsk",
        "ja": "クラスノヤルスク"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0089,
        "lng": 92.8719
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 631,
      "name": "Cologne",
      "names": {
        "es": "Colonia",
        "zh": "科隆",
        "de": "Köln",
        "it": "Colonia",
        "ja": "ケルン",
        "th": "โคโลญ",
        "pt": "Colónia"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9422,
        "lng": 6.9578
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 632,
      "name": "Yicheng",
      "names": {
        "zh": "宜城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.3697,
        "lng": 119.8239
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 633,
      "name": "Zhufeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0,
        "lng": 119.4167
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 635,
      "name": "Nashville",
      "names": {
        "zh": "纳什维尔",
        "ja": "ナッシュビル",
        "th": "แนชวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1715,
        "lng": -86.7843
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 637,
      "name": "Nur-Sultan",
      "names": {
        "es": "Nur-sultán",
        "zh": "光明，苏丹",
        "ja": "nur-スルタン",
        "th": "นู-สุลต่าน",
        "pt": "Nur-sultão"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.1333,
        "lng": 71.4333
      },
      "country": "Kazakhstan",
      "importance": 0
    },
    {
      "id": 634,
      "name": "Sao Goncalo",
      "names": {
        "ja": "サンゴンカロ",
        "th": "เซากอนคลาโก",
        "pt": "São Gonçalo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8269,
        "lng": -43.0539
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 627,
      "name": "Nezahualcoyotl",
      "names": {
        "es": "Nezahualcóyotl"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4,
        "lng": -98.9889
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 638,
      "name": "Nouakchott",
      "names": {
        "zh": "努瓦克肖特",
        "ja": "ヌアクショット",
        "th": "นูแอกชอต"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 18.0858,
        "lng": -15.9785
      },
      "country": "Mauritania",
      "importance": 0
    },
    {
      "id": 623,
      "name": "Haora",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.5736,
        "lng": 88.3251
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 636,
      "name": "Yerevan",
      "names": {
        "fr": "Erevan",
        "zh": "埃里温",
        "ja": "エレバン",
        "th": "เยเรวาน"
      },
      "countryCode": "AM",
      "latLng": {
        "lat": 40.1814,
        "lng": 44.5144
      },
      "country": "Armenia",
      "importance": 0
    },
    {
      "id": 640,
      "name": "Richmond",
      "names": {
        "zh": "里士满",
        "ja": "リッチモンド",
        "th": "ริชมอนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.5295,
        "lng": -77.4756
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 639,
      "name": "Vereeniging",
      "names": {
        "zh": "弗里尼欣"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.6736,
        "lng": 27.9319
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 644,
      "name": "Taixing",
      "names": {
        "zh": "泰兴市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.1724,
        "lng": 120.0142
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 641,
      "name": "Sao Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "เซาหลุยส์",
        "pt": "São Luis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.53,
        "lng": -44.3028
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 643,
      "name": "San Pedro Sula",
      "names": {
        "zh": "圣佩德罗萨拉",
        "ja": "サンペドロスラ"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.5,
        "lng": -88.0333
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 642,
      "name": "Ranchi",
      "names": {
        "zh": "兰契",
        "ja": "ラーンチー",
        "th": "แรนชิ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.3556,
        "lng": 85.3347
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 645,
      "name": "Gwalior",
      "names": {
        "zh": "瓜廖尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.215,
        "lng": 78.1931
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 646,
      "name": "Memphis",
      "names": {
        "zh": "孟菲斯",
        "ja": "メンフィス",
        "th": "เมมฟิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1046,
        "lng": -89.9773
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 649,
      "name": "Edmonton",
      "names": {
        "zh": "埃德蒙顿",
        "ja": "エドモントン",
        "th": "เอดมันตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 53.5344,
        "lng": -113.4903
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 647,
      "name": "Goyang",
      "names": {
        "zh": "高阳"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.6564,
        "lng": 126.835
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 651,
      "name": "Mizhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.99,
        "lng": 119.3801
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 653,
      "name": "Xishan",
      "names": {
        "zh": "西山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.6609,
        "lng": 113.4946
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 654,
      "name": "Ezhou",
      "names": {
        "zh": "鄂州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.3972,
        "lng": 114.8842
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 655,
      "name": "Barquisimeto",
      "names": {
        "zh": "巴基西梅托"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0678,
        "lng": -69.3467
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 657,
      "name": "Hegang",
      "names": {
        "zh": "鹤岗"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.3139,
        "lng": 130.2775
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 656,
      "name": "Sendai",
      "names": {
        "zh": "仙台",
        "ja": "仙台",
        "th": "เซนได"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.2683,
        "lng": 140.8694
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 652,
      "name": "Tunis",
      "names": {
        "es": "Túnez",
        "zh": "突尼斯",
        "it": "Tunisi",
        "id": "Tunisia",
        "ja": "チュニス",
        "th": "ตูนิส"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8008,
        "lng": 10.18
      },
      "country": "Tunisia",
      "importance": 0
    },
    {
      "id": 650,
      "name": "Bishkek",
      "names": {
        "fr": "Bichkek",
        "zh": "比什凯克",
        "de": "Bischkek",
        "ja": "ビシュケク",
        "th": "บิชเคก"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.8667,
        "lng": 74.5667
      },
      "country": "Kyrgyzstan",
      "importance": 0
    },
    {
      "id": 659,
      "name": "Mexicali",
      "names": {
        "zh": "墨西卡利",
        "ja": "メヒカリ",
        "th": "เม็กซิกาลี"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 32.6633,
        "lng": -115.4678
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 648,
      "name": "Bezwada",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.5167,
        "lng": 80.6167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 661,
      "name": "Perm",
      "names": {
        "zh": "彼尔姆",
        "ja": "パーマ",
        "th": "ดัด"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.0139,
        "lng": 56.2489
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 660,
      "name": "Voronezh",
      "names": {
        "zh": "沃罗涅日",
        "th": "โวโรเนซ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.6717,
        "lng": 39.2106
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 662,
      "name": "Changwon",
      "names": {
        "zh": "昌原",
        "th": "ชางวอน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.2281,
        "lng": 128.6811
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 663,
      "name": "Zhongwei",
      "names": {
        "zh": "中卫"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.5139,
        "lng": 105.1884
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 664,
      "name": "Shouguang",
      "names": {
        "zh": "寿光"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8833,
        "lng": 118.7333
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 666,
      "name": "Raleigh",
      "names": {
        "zh": "罗利",
        "ja": "ローリー",
        "th": "ราลี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.8325,
        "lng": -78.6435
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 667,
      "name": "Cartagena",
      "names": {
        "zh": "卡塔赫纳",
        "ja": "カルタヘナ",
        "th": "คาร์"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4236,
        "lng": -75.5253
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 670,
      "name": "Jodhpur",
      "names": {
        "zh": "焦特布尔",
        "ja": "ジョードプル",
        "th": "โช ธ ปุระ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.2944,
        "lng": 73.0278
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 669,
      "name": "Matola",
      "names": {
        "zh": "马托拉"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -25.9667,
        "lng": 32.4667
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 671,
      "name": "Ogbomoso",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 8.1333,
        "lng": 4.25
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 665,
      "name": "Bogor",
      "names": {
        "zh": "茂物",
        "ja": "ボゴール",
        "th": "โบกอร์"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.5917,
        "lng": 106.8
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 674,
      "name": "Taizhou",
      "names": {
        "zh": "台州",
        "th": "ในไถโจว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.6583,
        "lng": 121.4221
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 676,
      "name": "Bagam",
      "names": {
        "zh": "巴加姆"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 1.0678,
        "lng": 104.0167
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 675,
      "name": "Managua",
      "names": {
        "zh": "马那瓜",
        "it": "Di Managua",
        "ja": "マナグア",
        "th": "มานากัว",
        "pt": "Manágua"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.15,
        "lng": -86.2667
      },
      "country": "Nicaragua",
      "importance": 0
    },
    {
      "id": 672,
      "name": "Shymkent",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 42.3,
        "lng": 69.6
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 673,
      "name": "Niamey",
      "names": {
        "zh": "尼亚美",
        "ja": "ニアメ",
        "th": "นีอาเม"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 13.5086,
        "lng": 2.1111
      },
      "country": "Niger",
      "importance": 0
    },
    {
      "id": 668,
      "name": "Chandigarh",
      "names": {
        "zh": "昌迪加尔",
        "ja": "チャンディーガル",
        "th": "ดิการห์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 30.7353,
        "lng": 76.7911
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 677,
      "name": "Shubra al Khaymah",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.1286,
        "lng": 31.2422
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 678,
      "name": "Maceio",
      "names": {
        "fr": "Maceió",
        "zh": "马塞约",
        "de": "Maceió",
        "ja": "マセイオ",
        "th": "มาเซโอ",
        "pt": "Maceió"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.6658,
        "lng": -35.735
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 680,
      "name": "Wafangdian",
      "names": {
        "zh": "瓦房店"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.6271,
        "lng": 121.9972
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 681,
      "name": "Zhongxiang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.169,
        "lng": 112.5853
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 679,
      "name": "Monrovia",
      "names": {
        "zh": "蒙罗维亚",
        "id": "Monrovia sprigg Payne",
        "ja": "モンロビア",
        "th": "มันโรเวีย"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 6.3106,
        "lng": -10.8047
      },
      "country": "Liberia",
      "importance": 0
    },
    {
      "id": 684,
      "name": "New Orleans",
      "names": {
        "es": "Nueva orleans",
        "fr": "Nouvelle orléans",
        "zh": "新奥尔良",
        "ja": "ニューオーリンズ",
        "pt": "Nova orleans"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.0687,
        "lng": -89.9288
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 682,
      "name": "Odesa",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.4775,
        "lng": 30.7326
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 687,
      "name": "Volgograd",
      "names": {
        "es": "Volgogrado",
        "zh": "伏尔加格勒",
        "de": "Wolgograd",
        "th": "โวลกอกราด"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 48.7086,
        "lng": 44.5147
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 689,
      "name": "Islamabad",
      "names": {
        "zh": "伊斯兰堡",
        "ja": "イスラマバード",
        "th": "กรุงอิสลามาบัด"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.6989,
        "lng": 73.0369
      },
      "country": "Pakistan",
      "importance": 0
    },
    {
      "id": 688,
      "name": "Mysore",
      "names": {
        "zh": "迈索尔",
        "ja": "マイソール",
        "th": "ซอร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 12.3086,
        "lng": 76.6531
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 690,
      "name": "Xinyi",
      "names": {
        "zh": "信义"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.3559,
        "lng": 110.9369
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 686,
      "name": "Thu Duc",
      "names": {
        "es": "Tu duc",
        "id": "Kutu",
        "pt": "Qui duc"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.7924,
        "lng": 106.7518
      },
      "country": "Vietnam",
      "importance": 2
    },
    {
      "id": 685,
      "name": "Villahermosa",
      "names": {
        "zh": "比亚埃尔莫萨"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9892,
        "lng": -92.9281
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 691,
      "name": "Raipur",
      "names": {
        "zh": "赖布尔",
        "ja": "ライプル"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 21.2379,
        "lng": 81.6337
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 692,
      "name": "Arequipa",
      "names": {
        "zh": "阿雷基帕",
        "ja": "アレキパ",
        "th": "อาเรคิปา"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -16.3988,
        "lng": -71.5369
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 693,
      "name": "Port Harcourt",
      "names": {
        "zh": "哈科特港",
        "de": "Port-Harcourt",
        "it": "Porto harcourt",
        "ja": "ポートハーコート",
        "th": "พอร์ตฮาร์คอร์ต"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 4.75,
        "lng": 7.0
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 694,
      "name": "Louisville",
      "names": {
        "zh": "路易斯维尔",
        "ja": "ルイビル",
        "th": "หลุยส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.1663,
        "lng": -85.6485
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 695,
      "name": "Zaoyang",
      "names": {
        "zh": "枣阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.1287,
        "lng": 112.7581
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 697,
      "name": "Xingyi",
      "names": {
        "zh": "兴义"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.091,
        "lng": 104.9011
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 696,
      "name": "Shuizhai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.4433,
        "lng": 114.8994
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 698,
      "name": "Kota",
      "names": {
        "zh": "哥打京",
        "ja": "コタ",
        "th": "โกตา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.18,
        "lng": 75.83
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 699,
      "name": "Quetta",
      "names": {
        "zh": "奎达"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.192,
        "lng": 67.007
      },
      "country": "Pakistan",
      "importance": 1
    },
    {
      "id": 852,
      "name": "Bareilly",
      "names": {
        "zh": "巴瑞利"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.364,
        "lng": 79.415
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 853,
      "name": "Quang Ha",
      "names": {
        "de": "Quadha",
        "ja": "Quangha"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 16.06,
        "lng": 108.25
      },
      "country": "Vietnam",
      "importance": 3
    },
    {
      "id": 854,
      "name": "Xingcheng",
      "names": {
        "zh": "兴城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.1681,
        "lng": 115.6669
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 855,
      "name": "Dongtai",
      "names": {
        "zh": "东泰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.8534,
        "lng": 120.3037
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 700,
      "name": "Ottawa",
      "names": {
        "zh": "渥太华",
        "ja": "オタワ",
        "th": "ออตตาวา"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.4247,
        "lng": -75.695
      },
      "country": "Canada",
      "importance": 0
    },
    {
      "id": 683,
      "name": "Port-au-Prince",
      "names": {
        "es": "Port-au-Príncipe",
        "zh": "端口太子港王子",
        "ja": "ポート・オ・プリンス",
        "th": "พอร์ต-AU-เจ้าชาย"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.5425,
        "lng": -72.3386
      },
      "country": "Haiti",
      "importance": 0
    },
    {
      "id": 857,
      "name": "Yingcheng",
      "names": {
        "zh": "英成"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.1878,
        "lng": 113.4042
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 860,
      "name": "Luocheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.7645,
        "lng": 111.5745
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 859,
      "name": "Pekanbaru",
      "names": {
        "zh": "北干巴鲁"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.5333,
        "lng": 101.45
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 858,
      "name": "Chiba",
      "names": {
        "zh": "千叶",
        "ja": "千葉",
        "th": "ชิบะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6073,
        "lng": 140.1064
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 861,
      "name": "Dnipro",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.4675,
        "lng": 35.04
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 862,
      "name": "Danyang",
      "names": {
        "zh": "丹阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.9948,
        "lng": 119.575
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 863,
      "name": "Natal",
      "names": {
        "zh": "纳塔尔",
        "ja": "ナタール",
        "th": "นาตาล"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.9838,
        "lng": -60.2699
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 864,
      "name": "Nada",
      "names": {
        "zh": "纳达",
        "ja": "灘"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 19.5,
        "lng": 109.5833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 865,
      "name": "Zamboanga City",
      "names": {
        "es": "Ciudad de Zamboanga",
        "fr": "Zambobana City",
        "zh": "Zamboanga市",
        "de": "Zamboanga-Stadt",
        "id": "Kota Zamboanga",
        "ja": "ザンボンガ市",
        "th": "เมือง Zamboanga",
        "pt": "Cidade de zamboanga"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.9167,
        "lng": 122.0833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 701,
      "name": "Stockholm",
      "names": {
        "es": "De Estocolmo",
        "zh": "斯德哥尔摩",
        "it": "Stoccolma",
        "ja": "ストックホルム",
        "th": "สตอกโฮล์ม",
        "pt": "Estocolmo"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.3294,
        "lng": 18.0686
      },
      "country": "Sweden",
      "importance": 0
    },
    {
      "id": 658,
      "name": "Kathmandu",
      "names": {
        "es": "Katmandú",
        "fr": "Katmandou",
        "zh": "加德满都",
        "ja": "カトマンズ",
        "th": "กาฐมาณฑุ"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.7167,
        "lng": 85.3667
      },
      "country": "Nepal",
      "importance": 0
    },
    {
      "id": 866,
      "name": "Kirkuk",
      "names": {
        "fr": "Kirkouk",
        "zh": "基尔库克"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 35.4667,
        "lng": 44.4
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 870,
      "name": "Oklahoma City",
      "names": {
        "zh": "俄克拉荷马城",
        "id": "Kota Oklahoma",
        "ja": "オクラホマシティ",
        "th": "โอคลาโฮมาซิตี้",
        "pt": "Cidade de oklahoma"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.4676,
        "lng": -97.5136
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 869,
      "name": "Huilong",
      "names": {
        "zh": "回龙"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.8131,
        "lng": 121.6574
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 868,
      "name": "Wuchuan",
      "names": {
        "zh": "武川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.4283,
        "lng": 110.7749
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 867,
      "name": "Naples",
      "names": {
        "es": "Nápoles",
        "zh": "那不勒斯",
        "de": "Neapel",
        "it": "Napoli",
        "ja": "ナポリ",
        "th": "เนเปิลส์",
        "pt": "Nápoles"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.8333,
        "lng": 14.25
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 872,
      "name": "Cebu City",
      "names": {
        "es": "Ciudad de Cebu",
        "fr": "City de Cebu",
        "zh": "宿务市",
        "de": "Cebu-Stadt",
        "it": "Città di Cebu",
        "id": "Kota Cebu",
        "ja": "セブシティ",
        "th": "เมืองเซบู",
        "pt": "Cidade de cebu"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.293,
        "lng": 123.902
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 702,
      "name": "Asmara",
      "names": {
        "zh": "阿斯马拉",
        "ja": "アスマラ"
      },
      "countryCode": "ER",
      "latLng": {
        "lat": 15.3333,
        "lng": 38.9167
      },
      "country": "Eritrea",
      "importance": 0
    },
    {
      "id": 871,
      "name": "Morelia",
      "names": {
        "zh": "莫雷利亚",
        "th": "มอเรเลีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7683,
        "lng": -101.1894
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 873,
      "name": "Shaoxing",
      "names": {
        "zh": "绍兴",
        "ja": "紹興"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.0,
        "lng": 120.5833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 856,
      "name": "Al Mansurah",
      "names": {
        "ja": "アルマンサーラ",
        "th": "อัลแมนซุราห์"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.05,
        "lng": 31.3833
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 874,
      "name": "Coimbatore",
      "names": {
        "zh": "哥印拜陀",
        "ja": "コインバトール",
        "th": "โคอิมบาโตร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.0,
        "lng": 76.9667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 878,
      "name": "Taishan",
      "names": {
        "zh": "泰山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.2486,
        "lng": 112.785
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 879,
      "name": "Fangchenggang",
      "names": {
        "zh": "防城港"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.6,
        "lng": 108.3
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 880,
      "name": "Solapur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.6715,
        "lng": 75.9104
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 703,
      "name": "Freetown",
      "names": {
        "zh": "弗里敦",
        "id": "Freetown lungi International",
        "ja": "フリータウン",
        "th": "ฟรีทาวน์"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 8.4833,
        "lng": -13.2331
      },
      "country": "Sierra Leone",
      "importance": 0
    },
    {
      "id": 875,
      "name": "Santo Domingo Este",
      "names": {
        "fr": "Este de Santo Domingo",
        "ja": "サントドミンゴエステ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4855,
        "lng": -69.8734
      },
      "country": "Dominican Republic",
      "importance": 1
    },
    {
      "id": 877,
      "name": "Songnam",
      "names": {
        "zh": "松南"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4386,
        "lng": 127.1378
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 704,
      "name": "Vientiane",
      "names": {
        "zh": "万象",
        "ja": "ビエンチャン",
        "th": "เวียงจันทน์"
      },
      "countryCode": "LA",
      "latLng": {
        "lat": 17.9667,
        "lng": 102.6
      },
      "country": "Laos",
      "importance": 0
    },
    {
      "id": 881,
      "name": "Tangier",
      "names": {
        "es": "Tánger",
        "fr": "Tanger",
        "zh": "丹吉尔",
        "de": "Tanger",
        "it": "Tangeri",
        "ja": "タンジール",
        "th": "แทนเจียร์",
        "pt": "Tânger"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.7767,
        "lng": -5.8039
      },
      "country": "Morocco",
      "importance": 1
    },
    {
      "id": 882,
      "name": "Anqiu",
      "names": {
        "zh": "安丘"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.3619,
        "lng": 119.1072
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 883,
      "name": "Feicheng",
      "names": {
        "zh": "肥城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.186,
        "lng": 116.772
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 884,
      "name": "Kermanshah",
      "names": {
        "zh": "克尔曼沙阿"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.3167,
        "lng": 47.0686
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 876,
      "name": "Setagaya",
      "names": {
        "zh": "世田谷",
        "ja": "世田谷"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6464,
        "lng": 139.6533
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 887,
      "name": "Khartoum North",
      "names": {
        "es": "Jartum North",
        "fr": "Khartoum nord",
        "zh": "喀土穆北",
        "ja": "カルタムノース",
        "th": "คาร์ทูมเหนือ"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 15.6333,
        "lng": 32.6333
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 886,
      "name": "Meishan",
      "names": {
        "zh": "梅山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.1736,
        "lng": 112.839
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 888,
      "name": "Kisangani",
      "names": {
        "zh": "基桑加尼"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 0.5153,
        "lng": 25.1911
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 885,
      "name": "Kitakyushu",
      "names": {
        "zh": "北九州市",
        "ja": "北九州",
        "th": "คิตะกีวซู"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.8833,
        "lng": 130.8833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 889,
      "name": "Aguascalientes",
      "names": {
        "zh": "阿瓜斯卡连特斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.876,
        "lng": -102.296
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 891,
      "name": "Donetsk",
      "names": {
        "zh": "顿涅茨克",
        "ja": "ドネツク",
        "th": "โดเนตสค์"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0089,
        "lng": 37.8042
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 890,
      "name": "Marrakech",
      "names": {
        "zh": "马拉喀什",
        "de": "Marrakesch",
        "id": "Marakesh",
        "ja": "マラケシュ",
        "th": "มาร์ราเกช"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 31.6295,
        "lng": -7.9811
      },
      "country": "Morocco",
      "importance": 1
    },
    {
      "id": 893,
      "name": "Taihe",
      "names": {
        "zh": "泰和"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.8706,
        "lng": 105.3784
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 894,
      "name": "Bridgeport",
      "names": {
        "zh": "布里奇波特",
        "ja": "ブリッジポート",
        "th": "บริดจ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1918,
        "lng": -73.1953
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 896,
      "name": "Xin\u0027an",
      "names": {
        "zh": "新安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.3662,
        "lng": 118.3369
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 897,
      "name": "Taihecun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 45.768,
        "lng": 131.0063
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 892,
      "name": "Trujillo",
      "names": {
        "zh": "特鲁希略",
        "ja": "トルヒーヨ",
        "th": "ตรูฮีโย"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -8.1119,
        "lng": -79.0289
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 705,
      "name": "Jerusalem",
      "names": {
        "es": "Jerusalén",
        "fr": "Jérusalem",
        "zh": "耶路撒冷",
        "it": "Gerusalemme",
        "id": "Yerusalem",
        "ja": "エルサレム",
        "th": "กรุงเยรูซาเล็ม",
        "pt": "Jerusalém"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.7833,
        "lng": 35.2167
      },
      "country": "Israel",
      "importance": 0
    },
    {
      "id": 895,
      "name": "Trichinopoly",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.8269,
        "lng": 78.6928
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 900,
      "name": "Padang",
      "names": {
        "zh": "巴东",
        "ja": "パダン",
        "th": "ปาดัง"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.9556,
        "lng": 100.3606
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 902,
      "name": "Buffalo",
      "names": {
        "fr": "Buffle",
        "zh": "布法罗",
        "de": "Büffel",
        "ja": "バッファロー",
        "th": "ควาย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.9016,
        "lng": -78.8487
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 901,
      "name": "Qingzhou",
      "names": {
        "zh": "青州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.6967,
        "lng": 118.4797
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 903,
      "name": "Lichuan",
      "names": {
        "zh": "利川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.2965,
        "lng": 108.9378
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 898,
      "name": "Naucalpan de Juarez",
      "names": {
        "es": "Naucalpan de Juárez",
        "it": "Naucadalpan de juarez",
        "th": "คนไม่มีชัย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4753,
        "lng": -99.2378
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 904,
      "name": "Daye",
      "names": {
        "zh": "大冶"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.1003,
        "lng": 114.9699
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 905,
      "name": "Fort Worth",
      "names": {
        "fr": "Fort",
        "zh": "沃斯堡",
        "it": "Forte",
        "id": "Nilainya",
        "ja": "フォートワース",
        "th": "ป้อมปราการ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7811,
        "lng": -97.3473
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 906,
      "name": "Hengzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.6896,
        "lng": 109.2674
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 899,
      "name": "Owerri",
      "names": {
        "zh": "奥韦里"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.4833,
        "lng": 7.0333
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 907,
      "name": "Campo Grande",
      "names": {
        "ja": "カンポグランデ",
        "pt": "Campó"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.4686,
        "lng": -54.6222
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 908,
      "name": "Zhuanghe",
      "names": {
        "zh": "庄河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.6896,
        "lng": 122.9664
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 909,
      "name": "Hartford",
      "names": {
        "zh": "哈特福德",
        "ja": "ハートフォード",
        "th": "ฮาร์ตฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7661,
        "lng": -72.6834
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 910,
      "name": "Bobo-Dioulasso",
      "names": {
        "es": "Bobo dioulasso-",
        "zh": "波波-迪乌拉索",
        "it": "-Bobo dioulasso",
        "ja": "ボボ-dioulasso"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 11.1833,
        "lng": -4.2833
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 911,
      "name": "Ad Dammam",
      "names": {
        "es": "Dammam ad",
        "de": "Ad-Dammam",
        "it": "Dammam ad",
        "ja": "広告ダンマム",
        "th": "โฆษณา Dammam"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 26.4333,
        "lng": 50.1
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 912,
      "name": "Lhasa",
      "names": {
        "fr": "Lhassa",
        "zh": "拉萨",
        "ja": "ラサ市",
        "th": "ลาซา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.6458,
        "lng": 91.1408
      },
      "country": "China",
      "importance": 1
    },
    {
      "id": 913,
      "name": "Jiaozhou",
      "names": {
        "zh": "胶州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.2481,
        "lng": 119.9625
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 915,
      "name": "Merida",
      "names": {
        "es": "Mérida",
        "zh": "梅里达",
        "ja": "メリダ",
        "th": "เมริดา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.97,
        "lng": -89.62
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 916,
      "name": "Yangchun",
      "names": {
        "zh": "阳春"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.1717,
        "lng": 111.7846
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 920,
      "name": "Hubli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.3619,
        "lng": 75.1231
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 706,
      "name": "Bangui",
      "names": {
        "zh": "班吉",
        "ja": "バンギ",
        "th": "บังกี"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 4.3732,
        "lng": 18.5628
      },
      "country": "Central African Republic",
      "importance": 0
    },
    {
      "id": 921,
      "name": "Abeokuta",
      "names": {
        "zh": "阿贝奥库塔"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.15,
        "lng": 3.35
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 922,
      "name": "Cancun",
      "names": {
        "es": "Cancún",
        "zh": "坎昆",
        "ja": "カンクン",
        "th": "แคนคูน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1606,
        "lng": -86.8475
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 923,
      "name": "Tucson",
      "names": {
        "zh": "图森",
        "ja": "ツーソン",
        "th": "ทูซอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.1545,
        "lng": -110.8782
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 919,
      "name": "Antipolo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5842,
        "lng": 121.1763
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 918,
      "name": "Dengtalu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0819,
        "lng": 114.3481
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 914,
      "name": "Taguig City",
      "names": {
        "zh": "Taguig城市",
        "de": "Taguig-Stadt",
        "it": "Città taguig",
        "id": "Kota Taguig",
        "ja": "タグヒの市",
        "th": "เมือง Taguig",
        "pt": "Cidade taguig"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5167,
        "lng": 121.05
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 924,
      "name": "Krasnodar",
      "names": {
        "zh": "克拉斯诺达尔",
        "th": "ครัสโนดาร์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 45.0333,
        "lng": 38.9833
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 707,
      "name": "Panama City",
      "names": {
        "es": "Ciudad de Panamá",
        "zh": "巴拿马城",
        "de": "Panama-Stadt",
        "id": "Kota Panama",
        "ja": "パナマシティ",
        "th": "ปานามาซิตี้",
        "pt": "Cidade do Panamá"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 9.0,
        "lng": -79.5
      },
      "country": "Panama",
      "importance": 0
    },
    {
      "id": 926,
      "name": "Chihuahua",
      "names": {
        "zh": "奇瓦瓦",
        "ja": "チワワ",
        "th": "ชิวาวา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.6353,
        "lng": -106.0889
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 927,
      "name": "Klang",
      "names": {
        "zh": "巴生",
        "th": "กลาง"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.0333,
        "lng": 101.45
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 925,
      "name": "Natal",
      "names": {
        "zh": "纳塔尔",
        "ja": "ナタール",
        "th": "นาตาล"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.795,
        "lng": -35.2089
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 929,
      "name": "Ar Ramadi",
      "names": {
        "fr": "Aramadi",
        "zh": "arramadi"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.4258,
        "lng": 43.2992
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 930,
      "name": "Laiyang",
      "names": {
        "zh": "莱阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.9758,
        "lng": 120.7136
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 932,
      "name": "Jalandhar",
      "names": {
        "zh": "贾朗达尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 31.3256,
        "lng": 75.5792
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 933,
      "name": "Kaifeng Chengguanzhen",
      "names": {
        "zh": "开封成侯镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.8519,
        "lng": 114.3481
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 928,
      "name": "Turin",
      "names": {
        "es": "Turín",
        "zh": "都灵",
        "it": "Torino",
        "ja": "トリノ",
        "th": "ตูริน",
        "pt": "Turim"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.0667,
        "lng": 7.7
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 938,
      "name": "Gaomi",
      "names": {
        "zh": "高密市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.3833,
        "lng": 119.75
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 936,
      "name": "Marseille",
      "names": {
        "es": "Marsella",
        "zh": "马赛",
        "it": "Marsiglia",
        "ja": "マルセイユ",
        "th": "มาร์เซย์",
        "pt": "Marselha"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.2964,
        "lng": 5.37
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 917,
      "name": "Bucheon",
      "names": {
        "zh": "富川"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4989,
        "lng": 126.7831
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 939,
      "name": "Ipoh",
      "names": {
        "zh": "怡保",
        "ja": "イポー",
        "th": "อีโปห์"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 4.6,
        "lng": 101.07
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 940,
      "name": "Hai\u0027an",
      "names": {
        "zh": "海安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.532,
        "lng": 120.4604
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 935,
      "name": "Malang",
      "names": {
        "zh": "玛琅",
        "ja": "マラン"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.98,
        "lng": 112.62
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 937,
      "name": "Saltillo",
      "names": {
        "zh": "萨尔蒂约",
        "ja": "サルティヨ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.4333,
        "lng": -101.0
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 708,
      "name": "Amsterdam",
      "names": {
        "zh": "阿姆斯特丹",
        "ja": "アムステルダム",
        "th": "อัมสเตอร์ดัม",
        "pt": "Amesterdão"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3667,
        "lng": 4.8833
      },
      "country": "Netherlands",
      "importance": 0
    },
    {
      "id": 934,
      "name": "Ikeja",
      "names": {
        "zh": "伊凯贾"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.6186,
        "lng": 3.3426
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 943,
      "name": "Weichanglu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1792,
        "lng": 119.9333
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 942,
      "name": "Hermosillo",
      "names": {
        "zh": "埃莫西约",
        "ja": "エルモシヨ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 29.0989,
        "lng": -110.9542
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 945,
      "name": "Leping",
      "names": {
        "zh": "乐平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.9632,
        "lng": 117.1203
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 941,
      "name": "Oran",
      "names": {
        "zh": "奥兰"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.6969,
        "lng": -0.6331
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 944,
      "name": "Shache",
      "names": {
        "zh": "莎车"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4261,
        "lng": 77.25
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 931,
      "name": "Sale",
      "names": {
        "es": "Venta",
        "fr": "Vente",
        "zh": "销售",
        "de": "Verkauf",
        "it": "Vendita",
        "id": "Dijual",
        "ja": "販売",
        "th": "ขาย",
        "pt": "Venda"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.05,
        "lng": -6.8167
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 946,
      "name": "Hailun",
      "names": {
        "zh": "海伦"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.4585,
        "lng": 126.9632
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 947,
      "name": "Macheng",
      "names": {
        "zh": "麻城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1817,
        "lng": 115.0189
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 948,
      "name": "Akure",
      "names": {
        "zh": "阿库雷"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.25,
        "lng": 5.195
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 952,
      "name": "Omaha",
      "names": {
        "zh": "奥马哈",
        "ja": "オマハ",
        "th": "โอมาฮา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2627,
        "lng": -96.0522
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 949,
      "name": "Ilorin",
      "names": {
        "zh": "伊洛林"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 8.5,
        "lng": 4.55
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 950,
      "name": "Teresina",
      "names": {
        "zh": "特雷西纳"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.0949,
        "lng": -42.8042
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 954,
      "name": "Erbil",
      "names": {
        "zh": "伊尔比尔"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.1911,
        "lng": 44.0094
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 953,
      "name": "Saratov",
      "names": {
        "zh": "萨拉托夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.5333,
        "lng": 46.0
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 955,
      "name": "Iguacu",
      "names": {
        "fr": "Iguaçu",
        "zh": "伊瓜苏",
        "pt": "Iguaçu"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.74,
        "lng": -43.47
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 956,
      "name": "El Paso",
      "names": {
        "ja": "エルパソ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.8479,
        "lng": -106.4309
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 951,
      "name": "Yuci",
      "names": {
        "zh": "榆次"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.6823,
        "lng": 112.7281
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 959,
      "name": "Dehui",
      "names": {
        "zh": "德惠"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.5323,
        "lng": 125.6965
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 960,
      "name": "Bhubaneshwar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.2644,
        "lng": 85.8281
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 709,
      "name": "Lome",
      "names": {
        "es": "Lomé",
        "fr": "Lomé",
        "zh": "洛美",
        "ja": "ロメ",
        "th": "โลเม",
        "pt": "Lomé"
      },
      "countryCode": "TG",
      "latLng": {
        "lat": 6.1319,
        "lng": 1.2228
      },
      "country": "Togo",
      "importance": 0
    },
    {
      "id": 958,
      "name": "Denpasar",
      "names": {
        "zh": "登巴萨",
        "ja": "デンパサール"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.6667,
        "lng": 115.2167
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 961,
      "name": "Tongchuan",
      "names": {
        "zh": "铜川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.9057,
        "lng": 108.9422
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 962,
      "name": "Cheongju",
      "names": {
        "zh": "清州",
        "id": "Cheongju-si"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.6372,
        "lng": 127.4897
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 963,
      "name": "Warri",
      "names": {
        "zh": "瓦里"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.5167,
        "lng": 5.75
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 964,
      "name": "Pointe-Noire",
      "names": {
        "zh": "黑角",
        "ja": "ポイント-ノアレ",
        "th": "ปอยท์นัวร์"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.7889,
        "lng": 11.8653
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 966,
      "name": "Rongjiawan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.1409,
        "lng": 113.1087
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 965,
      "name": "Sakai",
      "names": {
        "zh": "堺",
        "ja": "堺",
        "th": "ซาไก"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5667,
        "lng": 135.4833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 968,
      "name": "Renqiu",
      "names": {
        "zh": "任丘"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7094,
        "lng": 116.1008
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 967,
      "name": "Butterworth",
      "names": {
        "zh": "北海",
        "ja": "バターワース",
        "th": "บัตเตอร์เวิ"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.3992,
        "lng": 100.3639
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 969,
      "name": "Honolulu",
      "names": {
        "zh": "火奴鲁鲁",
        "ja": "ホノルル",
        "th": "โฮโนลูลู"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 21.3294,
        "lng": -157.846
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 970,
      "name": "Xindi",
      "names": {
        "zh": "新地"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.8182,
        "lng": 113.4653
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 971,
      "name": "Wu\u0027an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.6941,
        "lng": 114.1847
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 972,
      "name": "Sao Bernardo do Campo",
      "names": {
        "zh": "圣贝纳多做坎波",
        "ja": "サンベルナルドドカンポ",
        "pt": "São Bernardo do Campo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6939,
        "lng": -46.565
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 973,
      "name": "Gaoyou",
      "names": {
        "zh": "高邮市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.7847,
        "lng": 119.4432
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 976,
      "name": "Yiyang",
      "names": {
        "zh": "益阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.4103,
        "lng": 112.3913
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 975,
      "name": "Hejian",
      "names": {
        "zh": "河间"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4451,
        "lng": 116.0897
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 977,
      "name": "Puxi",
      "names": {
        "zh": "浦西"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.2125,
        "lng": 114.735
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 974,
      "name": "Culiacan",
      "names": {
        "zh": "库利亚坎"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.8069,
        "lng": -107.3939
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 978,
      "name": "Zijinglu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7513,
        "lng": 112.9854
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 980,
      "name": "McAllen",
      "names": {
        "zh": "麦卡伦",
        "ja": "マッカレン",
        "th": "แมก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.2273,
        "lng": -98.2471
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 957,
      "name": "Pasig City",
      "names": {
        "es": "Ciudad de Pasig",
        "fr": "Ville de Pasig",
        "zh": "帕西格市",
        "de": "Pasig-Stadt",
        "id": "Kota pasig",
        "ja": "パシィグシティ",
        "th": "เมือง Pasig",
        "pt": "Cidade pasig"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.575,
        "lng": 121.0833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 981,
      "name": "Queretaro",
      "names": {
        "es": "Querétaro",
        "zh": "克雷塔罗",
        "de": "Querétaro",
        "ja": "ケレタロ",
        "th": "เกเรตาโร"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5875,
        "lng": -100.3928
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 979,
      "name": "Joao Pessoa",
      "names": {
        "pt": "João Pessoa"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.12,
        "lng": -34.88
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 982,
      "name": "Abaete",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.1583,
        "lng": -45.4522
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 983,
      "name": "Palermo",
      "names": {
        "fr": "Palerme",
        "zh": "巴勒莫",
        "ja": "パレルモ",
        "th": "ปาแลร์โม"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.8883,
        "lng": -75.4339
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 984,
      "name": "Qingping",
      "names": {
        "zh": "清平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.538,
        "lng": 113.3796
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 710,
      "name": "Libreville",
      "names": {
        "zh": "利伯维尔",
        "th": "ลีเบรอวิล"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": 0.3901,
        "lng": 9.4544
      },
      "country": "Gabon",
      "importance": 0
    },
    {
      "id": 986,
      "name": "Niigata",
      "names": {
        "zh": "新泻",
        "ja": "新潟",
        "th": "นิอิกาตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.9161,
        "lng": 139.0364
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 711,
      "name": "Zagreb",
      "names": {
        "zh": "萨格勒布",
        "it": "Zagabria",
        "ja": "ザグレブ",
        "th": "ซาเกร็บ"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.8131,
        "lng": 15.9772
      },
      "country": "Croatia",
      "importance": 0
    },
    {
      "id": 985,
      "name": "Valencia",
      "names": {
        "fr": "Valence",
        "zh": "瓦伦西亚",
        "ja": "バレンシア",
        "th": "วาเลนเซีย",
        "pt": "Valência"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.47,
        "lng": -0.3764
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 987,
      "name": "Leeds",
      "names": {
        "zh": "利兹",
        "ja": "リーズ",
        "th": "ลีดส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.7997,
        "lng": -1.5492
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 989,
      "name": "Hamamatsu",
      "names": {
        "zh": "滨松",
        "ja": "浜松"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7167,
        "lng": 137.7333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 990,
      "name": "Xiangxiang",
      "names": {
        "zh": "湘乡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7389,
        "lng": 112.5223
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 988,
      "name": "Moradabad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.8437,
        "lng": 78.7548
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 991,
      "name": "Chaohucun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.6783,
        "lng": 117.7353
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 713,
      "name": "Lilongwe",
      "names": {
        "zh": "利隆圭",
        "ja": "リロングウェ",
        "th": "ลิลองเว"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -13.9833,
        "lng": 33.7833
      },
      "country": "Malawi",
      "importance": 0
    },
    {
      "id": 712,
      "name": "Dushanbe",
      "names": {
        "zh": "杜尚别",
        "de": "Duschanbe",
        "ja": "ドゥシャンベ",
        "th": "ดูชานเบ"
      },
      "countryCode": "TJ",
      "latLng": {
        "lat": 38.5731,
        "lng": 68.7864
      },
      "country": "Tajikistan",
      "importance": 0
    },
    {
      "id": 994,
      "name": "Lubango",
      "names": {
        "zh": "卢班戈"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -14.9167,
        "lng": 13.5
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 993,
      "name": "Homs",
      "names": {
        "zh": "霍姆斯"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.7333,
        "lng": 36.7167
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 995,
      "name": "San Luis Potosi",
      "names": {
        "zh": "圣路易斯波托西",
        "ja": "サンルイスポトシ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.1511,
        "lng": -100.9761
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 996,
      "name": "Cencheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9297,
        "lng": 111.0186
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 997,
      "name": "Dali",
      "names": {
        "zh": "大理",
        "ja": "ダリ",
        "th": "ต้าหลี่"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.7003,
        "lng": 100.1564
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 992,
      "name": "Fuyang",
      "names": {
        "zh": "阜阳",
        "ja": "阜陽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.0553,
        "lng": 119.95
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 998,
      "name": "Hamhung",
      "names": {
        "zh": "咸兴"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.9167,
        "lng": 127.5333
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 1000,
      "name": "Hempstead",
      "names": {
        "zh": "亨普斯特德",
        "ja": "ヘムステッド",
        "th": "สเตด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6629,
        "lng": -73.6089
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1002,
      "name": "Al `Ayn",
      "names": {
        "es": "Al \u0027Ayn",
        "zh": "al` ayn",
        "de": "Al` Ayn",
        "ja": "Al` Ayn",
        "pt": "Al \u0027Ayn"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 24.2075,
        "lng": 55.7447
      },
      "country": "United Arab Emirates",
      "importance": 3
    },
    {
      "id": 999,
      "name": "Krakow",
      "names": {
        "es": "Cracovia",
        "fr": "Cracovie",
        "zh": "克拉科夫",
        "de": "Krakau",
        "it": "Cracovia",
        "ja": "クラクフ",
        "th": "คราคูฟ",
        "pt": "Cracóvia"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0614,
        "lng": 19.9372
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 1003,
      "name": "Songzi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.1772,
        "lng": 111.7732
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1001,
      "name": "Frankfurt",
      "names": {
        "fr": "Francfort",
        "zh": "法兰克福",
        "it": "Francoforte",
        "ja": "フランクフルト",
        "th": "แฟรงค์เฟิร์ต"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1136,
        "lng": 8.6797
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1004,
      "name": "Laixi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8667,
        "lng": 120.5333
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1006,
      "name": "Zhongba",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.7761,
        "lng": 104.7406
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1005,
      "name": "Bahawalpur",
      "names": {
        "zh": "巴哈瓦尔布尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 29.3956,
        "lng": 71.6722
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 714,
      "name": "Cotonou",
      "names": {
        "es": "De Cotonú",
        "zh": "科托努"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.402,
        "lng": 2.518
      },
      "country": "Benin",
      "importance": 0
    },
    {
      "id": 1009,
      "name": "Albuquerque",
      "names": {
        "zh": "阿尔伯克基",
        "ja": "アルバカーキ",
        "th": "อัลบูเคอร์คี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1053,
        "lng": -106.6464
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1008,
      "name": "Qingnian",
      "names": {
        "zh": "青年"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8494,
        "lng": 115.7061
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1007,
      "name": "Kaduna",
      "names": {
        "zh": "卡杜纳"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.5105,
        "lng": 7.4165
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1010,
      "name": "Xinhualu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.3962,
        "lng": 113.7249
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1012,
      "name": "Guangshui",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.6189,
        "lng": 113.8229
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1014,
      "name": "Samarinda",
      "names": {
        "zh": "三马林达"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.5022,
        "lng": 117.1536
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 715,
      "name": "Colombo",
      "names": {
        "zh": "科伦坡",
        "ja": "コロンボ",
        "th": "โคลัมโบ"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 6.9167,
        "lng": 79.8333
      },
      "country": "Sri Lanka",
      "importance": 0
    },
    {
      "id": 1018,
      "name": "Sizhan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.0099,
        "lng": 106.3694
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1019,
      "name": "Ciudad Guayana",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.3596,
        "lng": -62.6517
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 1015,
      "name": "Pietermaritzburg",
      "names": {
        "zh": "彼得马里茨堡"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -29.6009,
        "lng": 30.3796
      },
      "country": "South Africa",
      "importance": 1
    },
    {
      "id": 1016,
      "name": "Changhua",
      "names": {
        "zh": "彰化"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.0667,
        "lng": 120.5333
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 1017,
      "name": "Kolhapur",
      "names": {
        "zh": "戈尔哈布尔",
        "ja": "コラープル",
        "th": "คอล"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.7,
        "lng": 74.2333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1020,
      "name": "Cucuta",
      "names": {
        "fr": "Cúcuta",
        "zh": "库库塔",
        "de": "Cúcuta",
        "id": "Kukuta"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.9075,
        "lng": -72.5047
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 1021,
      "name": "Licheng",
      "names": {
        "zh": "里程"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.4174,
        "lng": 119.4786
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1024,
      "name": "Tyumen",
      "names": {
        "zh": "秋明"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.15,
        "lng": 65.5333
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1023,
      "name": "Thiruvananthapuram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 8.5,
        "lng": 76.8997
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 1025,
      "name": "Nampula",
      "names": {
        "zh": "楠普拉"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -15.1167,
        "lng": 39.2667
      },
      "country": "Mozambique",
      "importance": 1
    },
    {
      "id": 1011,
      "name": "Nerima",
      "names": {
        "ja": "練馬区"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.75,
        "lng": 139.6167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1026,
      "name": "Zaporizhzhia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.8378,
        "lng": 35.1383
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 716,
      "name": "Pretoria",
      "names": {
        "zh": "比勒陀利亚",
        "ja": "プレトリア",
        "th": "พริทอเรีย",
        "pt": "Pretória"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.7464,
        "lng": 28.1881
      },
      "country": "South Africa",
      "importance": 0
    },
    {
      "id": 1027,
      "name": "Chengguan",
      "names": {
        "zh": "城关"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5256,
        "lng": 113.6976
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1029,
      "name": "Nehe",
      "names": {
        "zh": "讷河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.48,
        "lng": 124.8738
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1030,
      "name": "Birmingham",
      "names": {
        "zh": "伯明翰",
        "ja": "バーミンガム",
        "th": "เบอร์มิงแฮม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5277,
        "lng": -86.7987
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1028,
      "name": "Kumamoto",
      "names": {
        "zh": "熊本",
        "ja": "熊本",
        "th": "คุมาโมโตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.8031,
        "lng": 130.7078
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1022,
      "name": "Ota-ku",
      "names": {
        "zh": "太田-ku",
        "it": "-Di OTA ku",
        "ja": "大田-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5614,
        "lng": 139.7161
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1031,
      "name": "Zunhua",
      "names": {
        "zh": "遵化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.1881,
        "lng": 117.9593
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1033,
      "name": "Orumiyeh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.5486,
        "lng": 45.0675
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1034,
      "name": "Wugang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.7345,
        "lng": 110.6293
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1036,
      "name": "Shuangqiao",
      "names": {
        "zh": "双桥"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.0833,
        "lng": 112.5833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1035,
      "name": "Osogbo",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.7597,
        "lng": 4.5761
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1037,
      "name": "Comodoro Rivadavia",
      "names": {
        "zh": "科迪多罗·里瓦亚维亚",
        "ja": "コモドロリバデビア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -45.8667,
        "lng": -67.5
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 1039,
      "name": "Langzhong",
      "names": {
        "zh": "阆中"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.5504,
        "lng": 105.9938
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1038,
      "name": "Cagayan de Oro",
      "names": {
        "ja": "カガヤンデオロ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.4833,
        "lng": 124.65
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1040,
      "name": "Qian\u0027an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.0059,
        "lng": 118.6973
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1013,
      "name": "Tlajomulco de Zuniga",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4736,
        "lng": -103.4431
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1042,
      "name": "Zouping",
      "names": {
        "zh": "邹平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8625,
        "lng": 117.7424
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1043,
      "name": "An Najaf",
      "names": {
        "es": "Un najaf",
        "fr": "Un najaf",
        "zh": "一个najaf",
        "de": "Ein najaf",
        "it": "Un najaf",
        "id": "Najaf",
        "ja": "Najaf",
        "th": "najaf",
        "pt": "Um najaf"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.029,
        "lng": 44.3396
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 1041,
      "name": "Lviv",
      "names": {
        "ja": "リヴィウ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.8419,
        "lng": 24.0315
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 1044,
      "name": "Sagamihara",
      "names": {
        "zh": "相模原",
        "ja": "相模原市"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5667,
        "lng": 139.3667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1045,
      "name": "Sarasota",
      "names": {
        "zh": "萨拉索塔",
        "ja": "サラソタ",
        "th": "ซาราโซตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.3386,
        "lng": -82.5431
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1046,
      "name": "Mississauga",
      "names": {
        "zh": "米西索加",
        "ja": "ミシソーガ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.6,
        "lng": -79.65
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 1047,
      "name": "Okayama",
      "names": {
        "zh": "冈山",
        "ja": "岡山",
        "th": "โอกายา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.65,
        "lng": 133.9167
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1048,
      "name": "Anlu",
      "names": {
        "zh": "安陆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.2575,
        "lng": 113.6783
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1049,
      "name": "Dayton",
      "names": {
        "zh": "代顿",
        "ja": "デイトン",
        "th": "เดย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7797,
        "lng": -84.1998
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1050,
      "name": "Changsha",
      "names": {
        "zh": "长沙",
        "ja": "長沙",
        "th": "ฉางชา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.3762,
        "lng": 112.6877
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1052,
      "name": "Shihezi",
      "names": {
        "zh": "石河子"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.3,
        "lng": 86.0333
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1051,
      "name": "Torreon",
      "names": {
        "es": "Torreón",
        "zh": "托雷翁"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.5394,
        "lng": -103.4486
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1054,
      "name": "Enugu",
      "names": {
        "zh": "埃努古",
        "ja": "エヌーグー"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.4403,
        "lng": 7.4942
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1032,
      "name": "Valenzuela",
      "names": {
        "zh": "巴伦苏埃拉"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.7,
        "lng": 120.9833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1058,
      "name": "Yatou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1653,
        "lng": 122.4867
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1057,
      "name": "Yanggok",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.6367,
        "lng": 127.2142
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1060,
      "name": "Xichang",
      "names": {
        "zh": "西昌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.8983,
        "lng": 102.2706
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1059,
      "name": "Santo Andre",
      "names": {
        "ja": "サントアンドレ",
        "th": "ซานโตอังเดร"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6572,
        "lng": -46.5333
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1061,
      "name": "Bhiwandi",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.3,
        "lng": 73.0667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1064,
      "name": "Chengxiang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.4515,
        "lng": 121.1043
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1053,
      "name": "George Town",
      "names": {
        "fr": "Ville de George",
        "zh": "乔治镇",
        "ja": "ジョージタウン",
        "th": "จอร์จทาวน์",
        "pt": "Cidade de george"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.4145,
        "lng": 100.3292
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 1065,
      "name": "Saharanpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.964,
        "lng": 77.546
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 717,
      "name": "Winnipeg",
      "names": {
        "zh": "温尼伯",
        "ja": "ウイニペグ",
        "th": "วินนิเพก"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.8844,
        "lng": -97.1464
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 718,
      "name": "Quebec City",
      "names": {
        "es": "Ciudad de Quebec",
        "fr": "Québec",
        "zh": "魁北克市",
        "de": "Quebec-Stadt",
        "it": "Città di quebec",
        "id": "Kota Quebec",
        "ja": "ケベックシティ",
        "th": "เมืองควิเบก",
        "pt": "Cidade de quebec"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.8139,
        "lng": -71.2081
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 1069,
      "name": "Dashiqiao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.6328,
        "lng": 122.5021
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1066,
      "name": "Warangal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.9756,
        "lng": 79.6011
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1071,
      "name": "Rochester",
      "names": {
        "zh": "罗切斯特",
        "ja": "ロチェスター",
        "th": "โรเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.168,
        "lng": -77.6162
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1072,
      "name": "Fresno",
      "names": {
        "zh": "弗雷斯诺",
        "ja": "フレズノ",
        "th": "เฟรสโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.7831,
        "lng": -119.7941
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1063,
      "name": "Dasmarinas",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3294,
        "lng": 120.9367
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1056,
      "name": "Jaboatao",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.1803,
        "lng": -35.0014
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1070,
      "name": "Latakia",
      "names": {
        "zh": "拉塔基亚",
        "ja": "ラタキア"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.5236,
        "lng": 35.7917
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 1055,
      "name": "Soledad",
      "names": {
        "zh": "索莱达",
        "ja": "ソウルダッド"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.92,
        "lng": -74.77
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 1075,
      "name": "General Santos",
      "names": {
        "fr": "Général Santos",
        "zh": "一般桑托斯",
        "it": "Generale Santos",
        "ja": "一般的なサントス",
        "th": "ซานโตสทั่วไป"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.1167,
        "lng": 125.1667
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1076,
      "name": "Hamah",
      "names": {
        "zh": "哈马",
        "de": "Hamäh"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.1333,
        "lng": 36.75
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 1081,
      "name": "Qianxi",
      "names": {
        "zh": "千禧"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.0284,
        "lng": 106.0327
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1068,
      "name": "Osasco",
      "names": {
        "zh": "奥萨斯库"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5328,
        "lng": -46.7919
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1084,
      "name": "Bauchi",
      "names": {
        "zh": "包奇"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.3158,
        "lng": 9.8442
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1085,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.2567,
        "lng": -79.8692
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 719,
      "name": "Oslo",
      "names": {
        "zh": "奥斯陆（Oslo）",
        "ja": "オスロ",
        "th": "ออสโล"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.9111,
        "lng": 10.7528
      },
      "country": "Norway",
      "importance": 0
    },
    {
      "id": 1074,
      "name": "Salem",
      "names": {
        "zh": "塞勒姆",
        "ja": "セーラム",
        "th": "ซาเลม"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.65,
        "lng": 78.1667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1082,
      "name": "Tolyatti",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.5167,
        "lng": 49.4167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 1078,
      "name": "Shishi",
      "names": {
        "zh": "石狮"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.7355,
        "lng": 118.6434
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1073,
      "name": "Banjarmasin",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.319,
        "lng": 114.5921
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1087,
      "name": "Miluo Chengguanzhen",
      "names": {
        "zh": "米洛成川镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.8049,
        "lng": 113.0745
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1079,
      "name": "Guadalupe",
      "names": {
        "zh": "瓜达卢佩",
        "ja": "グアダルペ",
        "th": "กัวดาลูป"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.6775,
        "lng": -100.2597
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1088,
      "name": "Gaizhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.4019,
        "lng": 122.3499
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1089,
      "name": "Malegaon",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.55,
        "lng": 74.55
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1067,
      "name": "Edogawa",
      "names": {
        "zh": "江户川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6924,
        "lng": 139.8757
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1086,
      "name": "Lodz",
      "names": {
        "zh": "罗兹",
        "ja": "ウッチ",
        "th": "ลอดซ์"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.7769,
        "lng": 19.4547
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 1090,
      "name": "Karbala\u0027",
      "names": {
        "es": "Karbala \u0027",
        "fr": "Karbala \u0027",
        "zh": "卡尔巴拉\u0027",
        "de": "Karbala \u0027",
        "it": "Karbala \u0027",
        "id": "Karbala \u0027",
        "ja": "karbala \u0027",
        "th": "karbala \u0027",
        "pt": "Karbala \u0027"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.6167,
        "lng": 44.0333
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 1062,
      "name": "City of Paranaque",
      "names": {
        "es": "Ciudad de paranaque",
        "fr": "Ville de Paranque",
        "zh": "Paranaque市",
        "de": "Stadt Paranaque",
        "it": "Città di Paranaque",
        "id": "Kota paranaque",
        "ja": "パラナク市",
        "th": "เมืองพารานา",
        "pt": "Cidade de paranaque"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4667,
        "lng": 121.0167
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1091,
      "name": "Leling",
      "names": {
        "zh": "乐陵"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7333,
        "lng": 117.2167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1092,
      "name": "Sao Jose dos Campos",
      "names": {
        "zh": "圣何塞DOS坎波尔",
        "ja": "サンノゼノドスカンポス",
        "th": "เซา Jose Dos Campos",
        "pt": "São José dos Campos"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.1789,
        "lng": -45.8869
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1094,
      "name": "Jianshe",
      "names": {
        "zh": "建设"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.2189,
        "lng": 113.7693
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1095,
      "name": "Shizuoka",
      "names": {
        "zh": "静冈",
        "ja": "静岡",
        "th": "ชิซึโอกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9756,
        "lng": 138.3825
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1096,
      "name": "Jingcheng",
      "names": {
        "zh": "精诚"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.0058,
        "lng": 120.2547
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1093,
      "name": "Sevilla",
      "names": {
        "zh": "塞维利亚",
        "ja": "セビリア",
        "th": "เซบีย่า",
        "pt": "Sevilha"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.39,
        "lng": -5.99
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 1098,
      "name": "Allentown",
      "names": {
        "zh": "阿伦敦",
        "ja": "アレンタウン",
        "th": "แอลเลนทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5961,
        "lng": -75.4756
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1080,
      "name": "Adachi",
      "names": {
        "zh": "足立",
        "ja": "足立"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7833,
        "lng": 139.8
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1101,
      "name": "Xinmin",
      "names": {
        "zh": "新民"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.9952,
        "lng": 122.8224
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1100,
      "name": "Rasht",
      "names": {
        "zh": "拉什特"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 37.2833,
        "lng": 49.6
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1103,
      "name": "Xigaze",
      "names": {
        "zh": "日喀则"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.2649,
        "lng": 88.8799
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1102,
      "name": "Zhongshu",
      "names": {
        "zh": "中枢"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.8116,
        "lng": 106.4133
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1099,
      "name": "Tasikmalaya",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.3333,
        "lng": 108.2
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 1097,
      "name": "Kochi",
      "names": {
        "zh": "高知",
        "ja": "高知",
        "th": "โคชิ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 9.9667,
        "lng": 76.2833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1105,
      "name": "As Sulaymaniyah",
      "names": {
        "es": "Como Sulaymaniyah",
        "fr": "Comme Sulaymaniyah",
        "zh": "作为苏莱曼尼亚",
        "de": "Als Sulaymaniyah",
        "it": "Come Sulaymaniyah",
        "id": "Sebagai sulaymaniyah",
        "ja": "SulayManiyahとして",
        "th": "เป็น Sulaymaniyah",
        "pt": "Como Sulaymaniyah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 35.55,
        "lng": 45.4333
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 1106,
      "name": "Zaragoza",
      "names": {
        "fr": "Saragosse",
        "zh": "萨拉戈萨",
        "it": "Saragozza",
        "ja": "サラゴサ",
        "th": "ซาราโกซา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.65,
        "lng": -0.8833
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 1107,
      "name": "Acapulco de Juarez",
      "names": {
        "es": "Acapulco de Juárez",
        "ja": "アカプルコデフレズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8636,
        "lng": -99.8825
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1104,
      "name": "Gorakhpur",
      "names": {
        "zh": "戈勒克布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.7611,
        "lng": 83.3667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1108,
      "name": "Bahar",
      "names": {
        "zh": "巴哈"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.9069,
        "lng": 48.4414
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 1110,
      "name": "Guankou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9933,
        "lng": 103.624
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1111,
      "name": "Tulsa",
      "names": {
        "zh": "塔尔萨",
        "ja": "タルサ",
        "th": "ทัล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1284,
        "lng": -95.9042
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1114,
      "name": "Songyang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.4603,
        "lng": 113.028
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1109,
      "name": "Palermo",
      "names": {
        "fr": "Palerme",
        "zh": "巴勒莫",
        "ja": "パレルモ",
        "th": "ปาแลร์โม"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.1111,
        "lng": 13.3517
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 1115,
      "name": "Cape Coral",
      "names": {
        "de": "Kapszone",
        "ja": "ケープサンゴ",
        "th": "ปะการังเคป"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.6446,
        "lng": -81.9956
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1116,
      "name": "Ch\u0027ongjin",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 41.8,
        "lng": 129.7831
      },
      "country": "North Korea",
      "importance": 2
    },
    {
      "id": 1118,
      "name": "Qionghu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.8417,
        "lng": 112.3595
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1120,
      "name": "Zhaodong",
      "names": {
        "zh": "肇东"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.0635,
        "lng": 125.9773
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1119,
      "name": "Ribeirao Preto",
      "names": {
        "ja": "リベイラオプレト",
        "pt": "Ribeirão Preto"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.1783,
        "lng": -47.8067
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1117,
      "name": "Puyang Chengguanzhen",
      "names": {
        "zh": "濮阳成庄镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7004,
        "lng": 114.98
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1121,
      "name": "Huambo",
      "names": {
        "zh": "万博"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -12.7667,
        "lng": 15.7333
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 1077,
      "name": "Bacoor",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4624,
        "lng": 120.9645
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1122,
      "name": "Wenchang",
      "names": {
        "zh": "文昌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.054,
        "lng": 116.9507
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1112,
      "name": "Tlaquepaque",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6167,
        "lng": -103.3167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 720,
      "name": "Athens",
      "names": {
        "es": "Atenas",
        "fr": "Athènes",
        "zh": "雅典",
        "de": "Athen",
        "it": "Atene",
        "id": "Athena",
        "ja": "アテネ",
        "th": "เอเธนส์",
        "pt": "Atenas"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9842,
        "lng": 23.7281
      },
      "country": "Greece",
      "importance": 0
    },
    {
      "id": 1123,
      "name": "Shulan",
      "names": {
        "zh": "舒兰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.4079,
        "lng": 126.9429
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1125,
      "name": "Catia La Mar",
      "names": {
        "ja": "カチアラマル"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.6038,
        "lng": -67.0303
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 1127,
      "name": "Al Hufuf",
      "names": {
        "th": "อัล hufuf",
        "pt": "Al hufluf"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 25.3608,
        "lng": 49.5997
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 1129,
      "name": "Jalingo",
      "names": {
        "zh": "贾林戈"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 8.9195,
        "lng": 11.3264
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1126,
      "name": "Sargodha",
      "names": {
        "zh": "萨戈达"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.0836,
        "lng": 72.6711
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 1130,
      "name": "Bouake",
      "names": {
        "fr": "Bouaké",
        "zh": "布瓦凯"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 7.6833,
        "lng": -5.0331
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 721,
      "name": "Bujumbura",
      "names": {
        "zh": "布琼布拉",
        "ja": "ブジュンブラ",
        "th": "บูจุมบูรา"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.3825,
        "lng": 29.3611
      },
      "country": "Burundi",
      "importance": 0
    },
    {
      "id": 1113,
      "name": "Mazatlan",
      "names": {
        "es": "Mazatlán",
        "zh": "马萨特兰",
        "ja": "マサトラン",
        "th": "มาซาต์ลัน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.2167,
        "lng": -106.4167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1134,
      "name": "Lingbao Chengguanzhen",
      "names": {
        "zh": "灵宝成川镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.5221,
        "lng": 110.8786
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1131,
      "name": "Sialkot City",
      "names": {
        "es": "Ciudad de Sialkot",
        "zh": "Sialkot市",
        "de": "Sialkot-Stadt",
        "it": "Città di Sialkot",
        "id": "Kota sialkot",
        "ja": "シアロコットシティ",
        "th": "เมือง Sialkot"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.5,
        "lng": 74.5333
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 1135,
      "name": "Hwasu-dong",
      "names": {
        "zh": "hwasu栋",
        "ja": "hwasu-ドン",
        "th": "hwasu ดอง"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.2,
        "lng": 126.7667
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1128,
      "name": "Durango",
      "names": {
        "zh": "杜兰戈",
        "ja": "ドゥランゴ",
        "th": "ดูรังโก"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.0167,
        "lng": -104.6667
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 1137,
      "name": "Shimoga",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.9167,
        "lng": 75.5667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1124,
      "name": "Tlalnepantla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5367,
        "lng": -99.1947
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1138,
      "name": "Bulawayo",
      "names": {
        "zh": "布拉瓦约",
        "th": "บูลาวาโย"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -20.1667,
        "lng": 28.5667
      },
      "country": "Zimbabwe",
      "importance": 1
    },
    {
      "id": 1133,
      "name": "Ansan",
      "names": {
        "zh": "安山"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.3236,
        "lng": 126.8219
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1139,
      "name": "Xiping",
      "names": {
        "zh": "西坪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.6005,
        "lng": 103.8166
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1136,
      "name": "Hue",
      "names": {
        "es": "Matiz",
        "fr": "Teinte",
        "zh": "色调",
        "de": "Farbton",
        "ja": "色相",
        "th": "ฮิว",
        "pt": "Matiz"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 16.4637,
        "lng": 107.5908
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1142,
      "name": "Concord",
      "names": {
        "zh": "康科德",
        "it": "A Concord",
        "ja": "コンコルド",
        "th": "คองคอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9722,
        "lng": -122.0016
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1140,
      "name": "Sanhe",
      "names": {
        "zh": "三河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.9808,
        "lng": 117.0701
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1132,
      "name": "San Jose del Monte",
      "names": {
        "zh": "圣何塞德尔蒙特",
        "ja": "サンノゼルモンテ",
        "th": "ซานโฮเซ่เดลมอนเต"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8139,
        "lng": 121.0453
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1145,
      "name": "Ch\u0027onan",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.8064,
        "lng": 127.1522
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1144,
      "name": "Tiruppur",
      "names": {
        "zh": "蒂鲁布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.1075,
        "lng": 77.3398
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1143,
      "name": "Contagem",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.9319,
        "lng": -44.0539
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1141,
      "name": "Guntur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.3,
        "lng": 80.45
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1150,
      "name": "Guixi",
      "names": {
        "zh": "贵溪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.2861,
        "lng": 117.2083
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1149,
      "name": "Izhevsk",
      "names": {
        "zh": "伊热夫斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.85,
        "lng": 53.2167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1148,
      "name": "Dusseldorf",
      "names": {
        "es": "Düsseldorf",
        "fr": "Düsseldorf",
        "zh": "杜塞尔多夫",
        "de": "Düsseldorf",
        "it": "Düsseldorf",
        "ja": "デュッセルドルフ",
        "th": "ดึสเซลดอร์ฟ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2311,
        "lng": 6.7724
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 1151,
      "name": "Sorocaba",
      "names": {
        "zh": "索罗卡巴"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5019,
        "lng": -47.4578
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1153,
      "name": "Tengyue",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0208,
        "lng": 98.4972
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1154,
      "name": "Wuxue",
      "names": {
        "zh": "武穴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.8518,
        "lng": 115.5539
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1156,
      "name": "Colorado Springs",
      "names": {
        "zh": "科罗拉多斯普林斯",
        "ja": "コロラドスプリングス",
        "th": "โคโลราโดสปริงส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8674,
        "lng": -104.7606
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 722,
      "name": "Helsinki",
      "names": {
        "zh": "赫尔辛基",
        "ja": "ヘルシンキ",
        "th": "เฮลซิงกิ",
        "pt": "Helsínquia"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.1756,
        "lng": 24.9342
      },
      "country": "Finland",
      "importance": 0
    },
    {
      "id": 1147,
      "name": "Honcho",
      "names": {
        "zh": "本町",
        "ja": "班長"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6947,
        "lng": 139.9825
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1159,
      "name": "Ruiming",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.8833,
        "lng": 116.0333
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1157,
      "name": "Qufu",
      "names": {
        "zh": "曲阜"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6,
        "lng": 116.9833
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1158,
      "name": "Gaobeidian",
      "names": {
        "zh": "高碑店"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.3257,
        "lng": 115.8678
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 723,
      "name": "Skopje",
      "names": {
        "zh": "斯科普里",
        "ja": "スコピエ",
        "th": "สโกเปีย"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9833,
        "lng": 21.4333
      },
      "country": "Macedonia",
      "importance": 0
    },
    {
      "id": 724,
      "name": "Chisinau",
      "names": {
        "zh": "基希讷乌",
        "id": "Kishinev",
        "th": "คีชีเนา"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 47.0228,
        "lng": 28.8353
      },
      "country": "Moldova",
      "importance": 0
    },
    {
      "id": 1160,
      "name": "Wroclaw",
      "names": {
        "es": "De Breslavia",
        "zh": "弗罗茨瓦夫",
        "de": "Breslau",
        "ja": "ヴロツワフ",
        "th": "รอกลอว์"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.11,
        "lng": 17.0325
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 1152,
      "name": "Petaling Jaya",
      "names": {
        "fr": "Pétaling jaya",
        "zh": "脾petaling杰阿",
        "ja": "花屋ジャヤ"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.1073,
        "lng": 101.6067
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 1161,
      "name": "Gold Coast",
      "names": {
        "es": "Costa de oro",
        "fr": "La Côte d\u0027Or",
        "zh": "黄金海岸",
        "de": "Goldküste",
        "it": "Costa d\u0027oro",
        "id": "Pantai emas",
        "ja": "ゴールドコースト",
        "th": "ชายฝั่งทอง",
        "pt": "Costa dourada"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -28.0167,
        "lng": 153.4
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 1155,
      "name": "Kikwit",
      "names": {
        "zh": "基奎特"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.0333,
        "lng": 18.8167
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 1162,
      "name": "Xinshi",
      "names": {
        "zh": "新市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.0236,
        "lng": 113.1079
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1168,
      "name": "Barnaul",
      "names": {
        "zh": "巴尔瑙尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.3567,
        "lng": 83.7872
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1167,
      "name": "Jin\u0027e",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.3395,
        "lng": 105.2868
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1164,
      "name": "Aracaju",
      "names": {
        "zh": "阿拉卡茹",
        "ja": "アラカジュ",
        "th": "อาราคาจู"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.9167,
        "lng": -37.05
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 1165,
      "name": "Cochabamba",
      "names": {
        "zh": "科恰班巴"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.3935,
        "lng": -66.157
      },
      "country": "Bolivia",
      "importance": 1
    },
    {
      "id": 1173,
      "name": "Benghazi",
      "names": {
        "es": "Bengasi",
        "zh": "班加西",
        "de": "Banghazi"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.1167,
        "lng": 20.0667
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 1166,
      "name": "Jeonju",
      "names": {
        "zh": "全州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.8219,
        "lng": 127.1489
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 1171,
      "name": "Rotterdam",
      "names": {
        "zh": "鹿特丹",
        "ja": "ロッテルダム",
        "th": "ร็อตเตอร์ดัม",
        "pt": "Roterdão"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9225,
        "lng": 4.4792
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 1170,
      "name": "Stuttgart",
      "names": {
        "zh": "斯图加特",
        "it": "Stoccarda",
        "ja": "シュトゥットガルト",
        "th": "ชตุทท์การ์ท",
        "pt": "Estugarda"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7761,
        "lng": 9.1775
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 1174,
      "name": "Kryvyi Rih",
      "names": {
        "ja": "クリボイリ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.9086,
        "lng": 33.3433
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 1169,
      "name": "Makati City",
      "names": {
        "es": "Ciudad de Makati",
        "fr": "City Makati",
        "zh": "马卡蒂市",
        "de": "Makati-Stadt",
        "id": "Kota Makati",
        "ja": "マカティシティ",
        "th": "เมืองมาคาติ",
        "pt": "Cidade de makati"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.55,
        "lng": 121.0333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1177,
      "name": "Charleston",
      "names": {
        "zh": "查尔斯顿",
        "ja": "チャールストン",
        "th": "ชาร์ลสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8153,
        "lng": -79.9628
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1179,
      "name": "Xinxing",
      "names": {
        "zh": "新兴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.8734,
        "lng": 124.1453
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1180,
      "name": "Suohe",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7879,
        "lng": 113.392
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1182,
      "name": "Zhuangyuan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3056,
        "lng": 120.8275
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1183,
      "name": "Glasgow",
      "names": {
        "zh": "格拉斯哥",
        "ja": "グラスゴー",
        "th": "กลาสโกว์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.8611,
        "lng": -4.25
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 1184,
      "name": "Namangan",
      "names": {
        "zh": "纳曼干"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.9953,
        "lng": 71.6725
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 1175,
      "name": "Raurkela",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.2492,
        "lng": 84.8828
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1185,
      "name": "Ulyanovsk",
      "names": {
        "zh": "乌里扬诺夫斯克",
        "de": "Uljanowsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.3167,
        "lng": 48.3667
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1181,
      "name": "Mangalore",
      "names": {
        "zh": "芒格洛尔",
        "ja": "マンガロール",
        "th": "สวิงก์โวลล์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 12.8703,
        "lng": 74.8806
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1186,
      "name": "Irkutsk",
      "names": {
        "fr": "Irkoutsk",
        "zh": "伊尔库茨克",
        "ja": "イルクーツク",
        "th": "อีร์คุตสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.2833,
        "lng": 104.3
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1187,
      "name": "Nanded",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.17,
        "lng": 77.3
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1192,
      "name": "Springfield",
      "names": {
        "zh": "斯普林菲尔德",
        "ja": "スプリングフィールド",
        "th": "สปริงฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1155,
        "lng": -72.5395
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1191,
      "name": "Bazhou",
      "names": {
        "zh": "霸州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.1235,
        "lng": 116.386
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1193,
      "name": "Turpan",
      "names": {
        "zh": "吐鲁番"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.95,
        "lng": 89.1822
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1188,
      "name": "Jos",
      "names": {
        "zh": "乔斯",
        "id": "Yos"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.9333,
        "lng": 8.8833
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1176,
      "name": "Halwan",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 29.8419,
        "lng": 31.3342
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 1190,
      "name": "Villa Nueva",
      "names": {
        "zh": "别墅Nueva",
        "ja": "ヴィラヌエバ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5314,
        "lng": -90.5964
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 1189,
      "name": "Pontianak",
      "names": {
        "zh": "坤甸"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.0206,
        "lng": 109.3414
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1195,
      "name": "Meihekou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.5279,
        "lng": 125.678
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1196,
      "name": "Jurong",
      "names": {
        "zh": "裕廊",
        "th": "จูร่ง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.9579,
        "lng": 119.1595
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1197,
      "name": "Feira de Santana",
      "names": {
        "th": "เฟริร่าเดอซานตานา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.2669,
        "lng": -38.9669
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1198,
      "name": "Khabarovsk",
      "names": {
        "zh": "哈巴罗夫斯克",
        "de": "Chabarowsk",
        "id": "Khabarovsk Novy",
        "ja": "ハバロフスク"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 48.4833,
        "lng": 135.0667
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1199,
      "name": "Xinji",
      "names": {
        "zh": "辛集"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9423,
        "lng": 115.2118
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1163,
      "name": "Ta`izz",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 13.5789,
        "lng": 44.0219
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 1172,
      "name": "Changping",
      "names": {
        "zh": "昌平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.2248,
        "lng": 116.1944
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1202,
      "name": "Sanya",
      "names": {
        "zh": "三亚",
        "ja": "三亜",
        "th": "ซานย่า"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 18.2536,
        "lng": 109.5019
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 725,
      "name": "Riga",
      "names": {
        "zh": "里加",
        "ja": "リガ",
        "th": "ริกา"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9475,
        "lng": 24.1069
      },
      "country": "Latvia",
      "importance": 0
    },
    {
      "id": 1178,
      "name": "Chimalhuacan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4167,
        "lng": -98.9
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1201,
      "name": "Kandahar",
      "names": {
        "zh": "坎大哈",
        "ja": "カンダハル",
        "th": "กันดาฮาร์"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 31.6078,
        "lng": 65.7053
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 1200,
      "name": "Serang",
      "names": {
        "zh": "西冷"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.12,
        "lng": 106.1503
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1205,
      "name": "Zhangshu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.0667,
        "lng": 115.5375
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1207,
      "name": "Grand Rapids",
      "names": {
        "fr": "Grands rapides",
        "zh": "大急流",
        "ja": "グランドラピッズ",
        "th": "แกรนด์แรพิดส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.962,
        "lng": -85.6562
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1204,
      "name": "Yaroslavl",
      "names": {
        "zh": "雅罗斯拉夫尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.6167,
        "lng": 39.85
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1209,
      "name": "Kuantan",
      "names": {
        "zh": "关丹",
        "th": "กวนตัน"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.8167,
        "lng": 103.3333
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 1208,
      "name": "Vladivostok",
      "names": {
        "zh": "海参崴",
        "ja": "ウラジオストック",
        "th": "วลา"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.1167,
        "lng": 131.9
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1194,
      "name": "Las Pinas City",
      "names": {
        "es": "La ciudad de las pinas",
        "ja": "ラス・ピナスシティ",
        "pt": "Cidade de las pinas"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.45,
        "lng": 120.9833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1211,
      "name": "Cuttack",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.465,
        "lng": 85.8793
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1203,
      "name": "San Miguel de Tucuman",
      "names": {
        "ja": "サンミゲルデチューマン"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.8167,
        "lng": -65.2167
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 1210,
      "name": "Gothenburg",
      "names": {
        "es": "Gotemburgo",
        "fr": "Göteborg",
        "zh": "哥德堡",
        "de": "Göteborg",
        "it": "Göteborg",
        "ja": "ヨーテボリ",
        "th": "โกเทนเบิร์ก",
        "pt": "Gotemburgo"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.6717,
        "lng": 11.981
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 1216,
      "name": "Uberlandia",
      "names": {
        "zh": "乌贝兰迪亚",
        "pt": "Uberlândia"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.9189,
        "lng": -48.2769
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1215,
      "name": "Zhuozhou",
      "names": {
        "zh": "涿州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.4887,
        "lng": 115.9918
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1217,
      "name": "Tianchang",
      "names": {
        "zh": "天长市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.6853,
        "lng": 119.0041
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1212,
      "name": "Jambi",
      "names": {
        "zh": "占碑",
        "ja": "ジャンビ"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -1.59,
        "lng": 103.61
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1214,
      "name": "Bacolod",
      "names": {
        "zh": "巴科洛得"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6765,
        "lng": 122.9509
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1222,
      "name": "Tumkur",
      "names": {
        "zh": "杜姆古尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 13.3422,
        "lng": 77.1017
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1225,
      "name": "Qamdo",
      "names": {
        "zh": "昌都"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1375,
        "lng": 97.1777
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1221,
      "name": "Tuxtla",
      "names": {
        "zh": "图斯特拉 - ",
        "th": "ตุซตลากูตี"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7528,
        "lng": -93.1167
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 1224,
      "name": "Balikpapan",
      "names": {
        "zh": "巴厘巴板"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -1.1489,
        "lng": 116.9031
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 1223,
      "name": "Leipzig",
      "names": {
        "zh": "莱比锡",
        "it": "Lipsia",
        "ja": "ライプチヒ",
        "th": "ไลพ์ซิก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3333,
        "lng": 12.3833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1227,
      "name": "Chanda",
      "names": {
        "zh": "尚达"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.95,
        "lng": 79.3
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1226,
      "name": "Durban",
      "names": {
        "zh": "德班",
        "ja": "ダーバン",
        "th": "เดอร์บัน"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -29.8583,
        "lng": 31.025
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 1229,
      "name": "Al Hillah",
      "names": {
        "id": "Al Hilah",
        "ja": "アルヒラ",
        "th": "อัลฮิฮ่า"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 23.4895,
        "lng": 46.7564
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 1228,
      "name": "Kagoshima",
      "names": {
        "zh": "鹿儿岛",
        "ja": "鹿児島",
        "th": "คะโงะชิมะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 31.58,
        "lng": 130.5281
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1234,
      "name": "Brampton",
      "names": {
        "zh": "布兰普顿",
        "ja": "ブランプトン",
        "th": "แบรมพ์ตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.6833,
        "lng": -79.7667
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 1231,
      "name": "Sihui",
      "names": {
        "zh": "四会"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3448,
        "lng": 112.6956
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1218,
      "name": "Kawaguchi",
      "names": {
        "zh": "川口",
        "ja": "川口"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8078,
        "lng": 139.7242
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1232,
      "name": "Mar del Plata",
      "names": {
        "ja": "マーデルプラタ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.0,
        "lng": -57.55
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 1230,
      "name": "Makhachkala",
      "names": {
        "zh": "马哈奇卡拉"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 42.9833,
        "lng": 47.4833
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1233,
      "name": "Irapuato",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6667,
        "lng": -101.35
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1235,
      "name": "Luocheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9793,
        "lng": 104.28
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1236,
      "name": "Pereira",
      "names": {
        "zh": "佩雷拉",
        "ja": "ペレイラ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8143,
        "lng": -75.6946
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 1237,
      "name": "Mission Viejo",
      "names": {
        "zh": "使命viejo",
        "it": "Missione viejo",
        "id": "Misi viejo",
        "ja": "ミッションヴィエホ",
        "pt": "Missão Viejo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6095,
        "lng": -117.655
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1239,
      "name": "Reynosa",
      "names": {
        "zh": "雷诺萨"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.0922,
        "lng": -98.2778
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1240,
      "name": "Chuxiong",
      "names": {
        "zh": "楚雄"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.0461,
        "lng": 101.5436
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1241,
      "name": "Zahedan",
      "names": {
        "zh": "扎黑丹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 29.4833,
        "lng": 60.8667
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1238,
      "name": "Dortmund",
      "names": {
        "zh": "多特蒙德",
        "ja": "ドルトムント",
        "th": "ดอร์ทมุนด์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5139,
        "lng": 7.4653
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1213,
      "name": "Cimahi",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.8833,
        "lng": 107.5333
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 1243,
      "name": "Albany",
      "names": {
        "zh": "奥尔巴尼",
        "ja": "アルバニー",
        "th": "ออลบานี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6664,
        "lng": -73.7987
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 1245,
      "name": "Knoxville",
      "names": {
        "zh": "诺克斯维尔",
        "ja": "ノックスビル",
        "th": "นอกซ์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9692,
        "lng": -83.9496
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1242,
      "name": "Jinhua",
      "names": {
        "zh": "金华"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.1046,
        "lng": 119.6494
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1247,
      "name": "Shangzhi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 45.2116,
        "lng": 127.962
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1246,
      "name": "Cuiaba",
      "names": {
        "fr": "Cuiabá",
        "zh": "库亚巴",
        "th": "กุยาบา",
        "pt": "Cuiabá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.5958,
        "lng": -56.0969
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 1244,
      "name": "Shah Alam",
      "names": {
        "zh": "莎阿拉姆",
        "th": "ชาห์อาลัม"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.0833,
        "lng": 101.5333
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 1249,
      "name": "Botou",
      "names": {
        "zh": "泊头"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.074,
        "lng": 116.57
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1220,
      "name": "Suginami-ku",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6833,
        "lng": 139.6167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1248,
      "name": "Essen",
      "names": {
        "zh": "埃森",
        "ja": "エッセン",
        "th": "เอสเซน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4508,
        "lng": 7.0131
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1219,
      "name": "Itabashi",
      "names": {
        "zh": "板桥"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7667,
        "lng": 139.6833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1250,
      "name": "Bucaramanga",
      "names": {
        "zh": "布卡拉曼加"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.1186,
        "lng": -73.1161
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 1252,
      "name": "Genoa",
      "names": {
        "es": "Génova",
        "fr": "Gênes",
        "zh": "热那亚",
        "de": "Genua",
        "it": "Genova",
        "ja": "ジェノヴァ",
        "th": "เจนัว",
        "pt": "Génova"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.4072,
        "lng": 8.934
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 726,
      "name": "Kingston",
      "names": {
        "zh": "金士顿",
        "ja": "キングストン",
        "th": "คิงส์ตัน"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 17.9714,
        "lng": -76.7931
      },
      "country": "Jamaica",
      "importance": 0
    },
    {
      "id": 1256,
      "name": "At Ta\u0027if",
      "names": {
        "es": "En ta\u0027if",
        "fr": "À Ta\u0027if",
        "zh": "在ta\u0027if",
        "de": "Bei ta\u0027if",
        "it": "A ta\u0027if",
        "id": "Di ta\u0027f",
        "ja": "ta\u0027ifで",
        "th": "ที่ Ta\u0027if",
        "pt": "Na ta\u0027if"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 21.2667,
        "lng": 40.4167
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 1253,
      "name": "Port Sudan",
      "names": {
        "es": "Port Sudán",
        "fr": "Soudan du port",
        "zh": "苏丹港",
        "de": "Port-Sudan",
        "it": "Porto sudan",
        "ja": "ポートスーダン",
        "th": "พอร์ตซูดาน",
        "pt": "Porto sudão"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 19.6158,
        "lng": 37.2164
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 1257,
      "name": "Bakersfield",
      "names": {
        "zh": "贝克斯菲尔德",
        "ja": "ベーカーズフィールド",
        "th": "เบเกอร์สฟีลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.3529,
        "lng": -119.0359
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1258,
      "name": "Wancheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 18.8,
        "lng": 110.4
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1261,
      "name": "Ogden",
      "names": {
        "zh": "奥格登",
        "ja": "オグデン",
        "th": "อ็อกเดน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2278,
        "lng": -111.9682
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1255,
      "name": "Dehra Dun",
      "names": {
        "th": "เดช",
        "pt": "Dun dehra dun"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 30.318,
        "lng": 78.029
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 1259,
      "name": "Malaga",
      "names": {
        "es": "Málaga",
        "zh": "马拉加",
        "ja": "マラガ",
        "th": "มาลากา",
        "pt": "Málaga"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.7194,
        "lng": -4.42
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 1262,
      "name": "Xiulin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.7211,
        "lng": 112.4037
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1263,
      "name": "Fu\u0027an",
      "names": {
        "zh": "福安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.1,
        "lng": 119.6333
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1260,
      "name": "Hachioji",
      "names": {
        "zh": "八王子",
        "ja": "八王子"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6667,
        "lng": 139.3167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 728,
      "name": "Vilnius",
      "names": {
        "zh": "维尔纽斯",
        "ja": "ビリニュス",
        "th": "วิลนีอุส"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 54.6833,
        "lng": 25.2833
      },
      "country": "Lithuania",
      "importance": 0
    },
    {
      "id": 1254,
      "name": "Puente Alto",
      "names": {
        "zh": "普恩特阿尔托"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.6167,
        "lng": -70.5833
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 1251,
      "name": "Anyang",
      "names": {
        "zh": "安阳",
        "ja": "安養"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.3925,
        "lng": 126.9269
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1264,
      "name": "Tomsk",
      "names": {
        "zh": "托木斯克",
        "ja": "トムスク"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.4886,
        "lng": 84.9522
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 727,
      "name": "Rabat",
      "names": {
        "es": "De Rabat",
        "zh": "拉巴特",
        "ja": "ラバト",
        "th": "ราบัต"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.0253,
        "lng": -6.8361
      },
      "country": "Morocco",
      "importance": 0
    },
    {
      "id": 1266,
      "name": "Kumul",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.8322,
        "lng": 93.5155
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1268,
      "name": "Luofeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.359,
        "lng": 120.396
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1269,
      "name": "Lingyuan",
      "names": {
        "zh": "凌源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.2407,
        "lng": 119.3957
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1271,
      "name": "Baton Rouge",
      "names": {
        "zh": "巴顿胭脂",
        "ja": "バトンルージュ",
        "th": "แบตันรูช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.4418,
        "lng": -91.131
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 1265,
      "name": "Tonala",
      "names": {
        "zh": "托纳拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6167,
        "lng": -103.2333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1270,
      "name": "Irbid",
      "names": {
        "zh": "伊尔比德"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 32.5556,
        "lng": 35.85
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 1272,
      "name": "Huzhou",
      "names": {
        "zh": "湖州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.8695,
        "lng": 120.0958
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1273,
      "name": "Wencheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.6167,
        "lng": 110.75
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1083,
      "name": "Macau",
      "names": {
        "es": "Macao",
        "fr": "Macao",
        "zh": "澳门",
        "it": "Macao",
        "ja": "マカオ",
        "th": "มาเก๊า"
      },
      "countryCode": "MO",
      "latLng": {
        "lat": 22.203,
        "lng": 113.545
      },
      "country": "Macau",
      "importance": 3
    },
    {
      "id": 1275,
      "name": "Ciudad Bolivar",
      "names": {
        "es": "Ciudad Bolívar",
        "zh": "Ciudad玻利亚尔"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.1167,
        "lng": -63.55
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 729,
      "name": "San Salvador",
      "names": {
        "zh": "圣萨尔瓦多",
        "ja": "サンサルバドール",
        "th": "ซานซัลวาดอร์"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.6989,
        "lng": -89.1914
      },
      "country": "El Salvador",
      "importance": 0
    },
    {
      "id": 1267,
      "name": "Bristol",
      "names": {
        "zh": "布里斯托尔",
        "ja": "ブリストル",
        "th": "บริสตอล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.45,
        "lng": -2.5833
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 1274,
      "name": "Bremen",
      "names": {
        "fr": "Brême",
        "zh": "不来梅",
        "it": "Brema",
        "ja": "ブレーメン",
        "th": "เบรเมิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.0769,
        "lng": 8.8089
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 1276,
      "name": "Durgapur",
      "names": {
        "zh": "督伽坡"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.55,
        "lng": 87.32
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1278,
      "name": "Shenzhou",
      "names": {
        "zh": "神舟"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0005,
        "lng": 115.5541
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1280,
      "name": "Akron",
      "names": {
        "zh": "阿克伦",
        "ja": "アクロン",
        "th": "แอครอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0798,
        "lng": -81.5219
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1282,
      "name": "New Haven",
      "names": {
        "es": "Nuevo refugio",
        "zh": "新天堂",
        "de": "Neuer Hafen",
        "it": "Nuovo rifugio",
        "id": "Surga Baru",
        "ja": "ニューヘブン",
        "th": "ใหม่สวรรค์",
        "pt": "Novo refúgio"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.3112,
        "lng": -72.9246
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1281,
      "name": "Kuiju",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8528,
        "lng": 119.3904
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1277,
      "name": "Orenburg",
      "names": {
        "zh": "奥伦堡"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.7667,
        "lng": 55.1
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1283,
      "name": "Zhenzhou",
      "names": {
        "zh": "郑州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.2739,
        "lng": 119.1619
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1279,
      "name": "Asansol",
      "names": {
        "zh": "阿散索尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.6833,
        "lng": 86.9667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 730,
      "name": "Djibouti",
      "names": {
        "es": "Yibuti",
        "zh": "吉布提",
        "de": "Dschibuti",
        "it": "Gibuti",
        "ja": "ジブチ",
        "th": "จิบูตี"
      },
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.595,
        "lng": 43.1481
      },
      "country": "Djibouti",
      "importance": 0
    },
    {
      "id": 1285,
      "name": "Jieshou",
      "names": {
        "zh": "界首"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 33.2605,
        "lng": 115.3618
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1286,
      "name": "Benguela",
      "names": {
        "zh": "本格拉"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -12.55,
        "lng": 13.4167
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 1289,
      "name": "Columbia",
      "names": {
        "fr": "-Britannique",
        "zh": "哥伦比亚",
        "de": "Kolumbien",
        "ja": "コロンビア",
        "th": "โคลัมเบีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0376,
        "lng": -80.9037
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 1288,
      "name": "Dangyang",
      "names": {
        "zh": "当阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.8258,
        "lng": 111.791
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1287,
      "name": "Ensenada",
      "names": {
        "zh": "恩塞纳达",
        "ja": "エンセナダ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.8578,
        "lng": -116.6058
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1290,
      "name": "Kemerovo",
      "names": {
        "zh": "克麦罗沃"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.3333,
        "lng": 86.0667
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1206,
      "name": "Bagcilar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0406,
        "lng": 28.8261
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 1291,
      "name": "Herat",
      "names": {
        "zh": "赫拉特",
        "ja": "ヘラート",
        "th": "แรต"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.3738,
        "lng": 62.1792
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 1294,
      "name": "Juiz de Fora",
      "names": {
        "it": "Juiz de foork",
        "ja": "ジュエス・デフォーア"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.75,
        "lng": -43.35
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1293,
      "name": "Bhavnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.765,
        "lng": 72.1369
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1292,
      "name": "Dresden",
      "names": {
        "es": "Dresde",
        "fr": "Dresde",
        "zh": "德累斯顿",
        "it": "Dresda",
        "ja": "ドレスデン",
        "th": "เดรสเดิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.05,
        "lng": 13.74
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 1296,
      "name": "Hamadan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.8,
        "lng": 48.5167
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1295,
      "name": "Luanzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.7396,
        "lng": 118.6978
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 731,
      "name": "Dublin",
      "names": {
        "es": "Dublín",
        "zh": "都柏林",
        "it": "Dublino",
        "ja": "ダブリン",
        "th": "ดับลิน"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.3497,
        "lng": -6.2603
      },
      "country": "Ireland",
      "importance": 0
    },
    {
      "id": 1299,
      "name": "Chiclayo",
      "names": {
        "zh": "奇克拉约"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -6.763,
        "lng": -79.8366
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 1284,
      "name": "Surakarta",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.5667,
        "lng": 110.8167
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 1297,
      "name": "Novokuznetsk",
      "names": {
        "th": "โนโวคูนซ์เนตสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.75,
        "lng": 87.1167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 1303,
      "name": "Cabinda",
      "names": {
        "zh": "卡宾达"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -5.5667,
        "lng": 12.2
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 1302,
      "name": "Amravati",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.9333,
        "lng": 77.75
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1304,
      "name": "Korla",
      "names": {
        "zh": "库尔勒",
        "ja": "コルラ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.7646,
        "lng": 86.1527
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1305,
      "name": "Huanghua",
      "names": {
        "zh": "黄骅"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.371,
        "lng": 117.3329
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1301,
      "name": "Al Hudaydah",
      "names": {
        "ja": "アル・ハイドダ"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.8022,
        "lng": 42.9511
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 1307,
      "name": "Londrina",
      "names": {
        "zh": "隆德里纳",
        "ja": "ロンドリーナ",
        "th": "โลนดรีนา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.31,
        "lng": -51.1628
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1308,
      "name": "Tabuk",
      "names": {
        "zh": "塔布克"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 28.3838,
        "lng": 36.555
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 1300,
      "name": "Manchester",
      "names": {
        "zh": "曼彻斯特",
        "ja": "マンチェスター",
        "th": "แมนเชสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4794,
        "lng": -2.2453
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 1298,
      "name": "Nellore",
      "names": {
        "pt": "Nelore"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 14.4333,
        "lng": 79.9667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1310,
      "name": "Samarkand",
      "names": {
        "zh": "撒马尔罕",
        "it": "Samarcanda"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.6542,
        "lng": 66.9597
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 1311,
      "name": "Xingcheng",
      "names": {
        "zh": "兴城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.6189,
        "lng": 120.7205
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1312,
      "name": "Kaiyuan",
      "names": {
        "zh": "开元"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.538,
        "lng": 124.0371
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1314,
      "name": "Valledupar",
      "names": {
        "zh": "巴耶杜帕尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4603,
        "lng": -73.2597
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 1315,
      "name": "Fengcheng",
      "names": {
        "zh": "凤城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.4543,
        "lng": 124.0646
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1306,
      "name": "Muntinlupa City",
      "names": {
        "es": "Ciudad de Muntinlupa",
        "zh": "Muntinlupa市",
        "de": "Muntinlupa-Stadt",
        "it": "Città di Muntinlupa",
        "id": "Kota Muntinlupa",
        "th": "เมือง Muntinlupa",
        "pt": "Cidade de muntinlupa"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3833,
        "lng": 121.05
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1313,
      "name": "Hannover",
      "names": {
        "fr": "Hanovre",
        "zh": "汉诺威",
        "ja": "ハノーバー",
        "th": "ฮันโนเวอร์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.3744,
        "lng": 9.7386
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 1317,
      "name": "Ghulja",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.9,
        "lng": 81.35
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1316,
      "name": "Ajmer",
      "names": {
        "zh": "阿杰梅尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.468,
        "lng": 74.639
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1318,
      "name": "Tinnevelly",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 8.7289,
        "lng": 77.7081
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1321,
      "name": "Fuding",
      "names": {
        "zh": "福鼎"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.2,
        "lng": 120.2
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1309,
      "name": "Heroica Matamoros",
      "names": {
        "fr": "Héroica matamoros",
        "id": "Heroica mataoros",
        "ja": "ヒロイカマタモロス",
        "pt": "Heróica matamoros"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.8797,
        "lng": -97.5042
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1322,
      "name": "An Nasiriyah",
      "names": {
        "es": "Un nasiriyah",
        "fr": "Une nasiriyah",
        "zh": "一个nasiriyah",
        "de": "Ein Nasiriyah",
        "it": "Una nasiriyah",
        "id": "Seorang nasiriyah",
        "ja": "ナシリヤ",
        "th": "nasiriyah",
        "pt": "Uma nasiriyah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 31.0439,
        "lng": 46.2575
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 1320,
      "name": "Xiangyang",
      "names": {
        "zh": "襄阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.2999,
        "lng": 108.4816
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1324,
      "name": "Ibague",
      "names": {
        "es": "Ibagué",
        "zh": "伊瓦格",
        "id": "Ibagué",
        "pt": "Ibagué"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4378,
        "lng": -75.2006
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 1323,
      "name": "Al Hillah",
      "names": {
        "id": "Al Hilah",
        "ja": "アルヒラ",
        "th": "อัลฮิฮ่า"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.4833,
        "lng": 44.4333
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 1319,
      "name": "City of Calamba",
      "names": {
        "es": "Ciudad de Calamba",
        "fr": "Ville de Calamba",
        "zh": "卡拉姆邦市",
        "de": "Stadt Calamba",
        "it": "Città di Calamba",
        "id": "Kota Calamba",
        "ja": "カランバ市",
        "th": "เมืองแคลิฟอร์เนีย",
        "pt": "Cidade de Calamba"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2167,
        "lng": 121.1667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 732,
      "name": "The Hague",
      "names": {
        "es": "La Haya",
        "fr": "La Haye",
        "zh": "海牙",
        "de": "Den Haag",
        "it": "L\u0027Aia",
        "id": "Den haag",
        "ja": "ハーグ",
        "th": "กรุงเฮก",
        "pt": "A Haia"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.08,
        "lng": 4.31
      },
      "country": "Netherlands",
      "importance": 0
    },
    {
      "id": 1328,
      "name": "Kerman",
      "names": {
        "zh": "克尔曼"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 30.2833,
        "lng": 57.0667
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1325,
      "name": "Ryazan",
      "names": {
        "zh": "梁赞",
        "de": "Rjasan"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.6167,
        "lng": 39.7167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1327,
      "name": "Chang\u0027an",
      "names": {
        "zh": "长安",
        "ja": "長安",
        "th": "ช้าง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.4761,
        "lng": 113.448
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1326,
      "name": "Kassala",
      "names": {
        "zh": "卡萨拉",
        "ja": "カッサラー"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 15.45,
        "lng": 36.4
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 1331,
      "name": "Aksu",
      "names": {
        "zh": "阿克苏"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.1664,
        "lng": 80.25
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1332,
      "name": "Salta",
      "names": {
        "zh": "萨尔塔",
        "ja": "サルタ",
        "th": "ซัลตา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.7883,
        "lng": -65.4106
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 1330,
      "name": "Poznan",
      "names": {
        "zh": "波兹南",
        "ja": "ポズナン",
        "th": "พอซนัน"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.4083,
        "lng": 16.9336
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 1334,
      "name": "Wuhai",
      "names": {
        "zh": "乌海"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.6844,
        "lng": 106.8158
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1335,
      "name": "Mingguang",
      "names": {
        "zh": "明光"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.7816,
        "lng": 117.9899
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1333,
      "name": "Astrakhan",
      "names": {
        "zh": "阿斯特拉罕",
        "de": "Astrachan",
        "ja": "アストラカン",
        "th": "มณฑลแอซทระแคน"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 46.3333,
        "lng": 48.0167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1146,
      "name": "Tripoli",
      "names": {
        "es": "Trípoli",
        "zh": "的黎波里",
        "de": "Tripolis",
        "ja": "トリポリ",
        "th": "ตริโปลี",
        "pt": "Trípoli"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 34.4333,
        "lng": 35.8333
      },
      "country": "Lebanon",
      "importance": 1
    },
    {
      "id": 1336,
      "name": "Naberezhnyye Chelny",
      "names": {
        "fr": "Naberezhnnyye Chelny",
        "de": "Naberezhnnyye chelny"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7,
        "lng": 52.3333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 1339,
      "name": "Ardabil",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 38.25,
        "lng": 48.3
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1337,
      "name": "Antwerp",
      "names": {
        "es": "Amberes",
        "fr": "D\u0027Anvers",
        "zh": "安特卫普",
        "de": "Antwerpen",
        "it": "Anversa",
        "id": "Antwerpen",
        "ja": "アントワープ",
        "th": "แอนต์เวิร์ป",
        "pt": "Antuérpia"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.2211,
        "lng": 4.3997
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 1338,
      "name": "Touba",
      "names": {
        "zh": "图巴"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.8667,
        "lng": -15.8833
      },
      "country": "Senegal",
      "importance": 3
    },
    {
      "id": 1340,
      "name": "Bikaner",
      "names": {
        "zh": "比卡内尔",
        "th": "พิฆเนร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.0181,
        "lng": 73.3169
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1343,
      "name": "Ndola",
      "names": {
        "zh": "恩多拉"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.9683,
        "lng": 28.6337
      },
      "country": "Zambia",
      "importance": 1
    },
    {
      "id": 1346,
      "name": "Villavicencio",
      "names": {
        "zh": "比亚维森西奥"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.1425,
        "lng": -73.6294
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 1344,
      "name": "Himeji",
      "names": {
        "zh": "姬路",
        "ja": "姫路",
        "th": "ฮิเมจิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8167,
        "lng": 134.6833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1347,
      "name": "Ailan Mubage",
      "names": {
        "fr": "Mubage ailan",
        "zh": "艾兰粉刷",
        "ja": "イランのムーグ",
        "th": "กูว",
        "pt": "Mubagem de Ailan"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.9167,
        "lng": 81.3167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1348,
      "name": "Bandar `Abbas",
      "names": {
        "zh": "Bandar` ABBAS",
        "ja": "バンダル `ABBAS"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 27.2,
        "lng": 56.25
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1351,
      "name": "Santa Teresa del Tuy",
      "names": {
        "zh": "圣特雷萨del Tuy",
        "ja": "サンタテレサデルチュー"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2333,
        "lng": -66.65
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 1355,
      "name": "Port Said",
      "names": {
        "es": "Puerto dijo",
        "fr": "Dit Port",
        "zh": "港口说",
        "de": "Hafen sagte",
        "it": "Porto detto",
        "id": "Kata pelabuhan",
        "ja": "港は言った",
        "th": "พอร์ตกล่าวว่า",
        "pt": "Porto disse"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.25,
        "lng": 32.2833
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 733,
      "name": "Asuncion",
      "names": {
        "es": "Asunción",
        "zh": "亚松森",
        "ja": "アスンシオン",
        "th": "อะซุนซิออง",
        "pt": "Assunção"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.3,
        "lng": -57.6333
      },
      "country": "Paraguay",
      "importance": 0
    },
    {
      "id": 1353,
      "name": "Penza",
      "names": {
        "zh": "奔萨"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.2,
        "lng": 45.0
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1350,
      "name": "Ciudad Apodaca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7833,
        "lng": -100.1833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1329,
      "name": "Koto-ku",
      "names": {
        "zh": "科托-ku",
        "ja": "江東-ku",
        "th": "ขิม-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6667,
        "lng": 139.8167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1356,
      "name": "Yucheng",
      "names": {
        "zh": "禹城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.9329,
        "lng": 116.6403
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1342,
      "name": "Agartala",
      "names": {
        "zh": "阿加尔塔拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.8333,
        "lng": 91.2667
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 1354,
      "name": "Soacha",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5781,
        "lng": -74.2144
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 1352,
      "name": "Maipu",
      "names": {
        "zh": "迈普"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.5167,
        "lng": -70.7667
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 1358,
      "name": "Arak",
      "names": {
        "zh": "阿拉克"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.08,
        "lng": 49.7
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1345,
      "name": "Tultitlan de Mariano Escobedo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.645,
        "lng": -99.1694
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1357,
      "name": "Meknes",
      "names": {
        "fr": "Meknès",
        "zh": "梅克内斯"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.8833,
        "lng": -5.55
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 1359,
      "name": "Pohang",
      "names": {
        "zh": "浦项",
        "id": "Pohang-si",
        "ja": "浦項"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.0322,
        "lng": 129.365
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 1349,
      "name": "Bac Ninh",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 21.1861,
        "lng": 106.0763
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1360,
      "name": "Longzhou",
      "names": {
        "zh": "龙州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.32,
        "lng": 112.23
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1361,
      "name": "Anda",
      "names": {
        "zh": "安达",
        "id": "Andari",
        "th": "อันดา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.4078,
        "lng": 125.3252
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1362,
      "name": "Jinghong",
      "names": {
        "zh": "景洪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.0057,
        "lng": 100.7948
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1341,
      "name": "Lyon",
      "names": {
        "zh": "里昂",
        "it": "Lione",
        "ja": "リヨン",
        "th": "ลียง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.76,
        "lng": 4.84
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 1364,
      "name": "Utsunomiya",
      "names": {
        "zh": "宇都宫",
        "ja": "宇都宮"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.55,
        "lng": 139.8833
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1363,
      "name": "Sheffield",
      "names": {
        "zh": "谢菲尔德",
        "ja": "シェフィールド",
        "th": "เชฟฟิลด์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.3808,
        "lng": -1.4703
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 1368,
      "name": "Mesa",
      "names": {
        "zh": "梅萨",
        "ja": "メサ",
        "th": "เมซ่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4017,
        "lng": -111.718
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1369,
      "name": "Surrey",
      "names": {
        "zh": "萨里",
        "ja": "サリー",
        "th": "เซอร์เรย์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.19,
        "lng": -122.8489
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 1365,
      "name": "Suez",
      "names": {
        "zh": "苏伊士",
        "ja": "スエズ",
        "th": "คลองสุเอซ"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 29.9667,
        "lng": 32.5333
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 1370,
      "name": "Heshan",
      "names": {
        "zh": "鹤山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.7697,
        "lng": 112.9578
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1371,
      "name": "Zhoushan",
      "names": {
        "zh": "舟山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.9887,
        "lng": 122.2049
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1373,
      "name": "Jiaji",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.2431,
        "lng": 110.4642
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1374,
      "name": "Santa Marta",
      "names": {
        "zh": "圣玛尔塔",
        "ja": "サンタマルタ",
        "th": "ซานตามาร์ตา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 11.2361,
        "lng": -74.2017
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 1366,
      "name": "Nuremberg",
      "names": {
        "zh": "纽伦堡",
        "de": "Nürnberg",
        "it": "Norimberga",
        "ja": "ニュルンベルク",
        "th": "นูเรมเบิร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4539,
        "lng": 11.0775
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1375,
      "name": "Joinvile",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -26.3039,
        "lng": -48.8458
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1372,
      "name": "Ujjain",
      "names": {
        "zh": "乌贾因"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.1828,
        "lng": 75.7772
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1376,
      "name": "Beining",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.5961,
        "lng": 121.7928
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1379,
      "name": "Maturin",
      "names": {
        "zh": "马图林"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.7457,
        "lng": -63.1832
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 1367,
      "name": "Liverpool",
      "names": {
        "zh": "利物浦",
        "ja": "リバプール",
        "th": "ลิเวอร์พูล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4075,
        "lng": -2.9919
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 1382,
      "name": "Yanji",
      "names": {
        "zh": "延吉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.9044,
        "lng": 129.5067
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1383,
      "name": "Yicheng",
      "names": {
        "zh": "宜城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.7117,
        "lng": 112.2551
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1386,
      "name": "Al `Amarah",
      "names": {
        "zh": "Al`amarah",
        "de": "Al` Amarah",
        "ja": "Al` Amarah",
        "th": "อัล `Amarah",
        "pt": "Al \u0027Amarah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 31.8333,
        "lng": 47.15
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 1378,
      "name": "Carrefour",
      "names": {
        "zh": "家乐福",
        "ja": "カルフール",
        "th": "คาร์ฟูร์"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.5333,
        "lng": -72.4
      },
      "country": "Haiti",
      "importance": 3
    },
    {
      "id": 1381,
      "name": "Ananindeua",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -1.3658,
        "lng": -48.3719
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1387,
      "name": "Choloma",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 15.6333,
        "lng": -88.0
      },
      "country": "Honduras",
      "importance": 3
    },
    {
      "id": 1385,
      "name": "Lipetsk",
      "names": {
        "zh": "利佩茨克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.6167,
        "lng": 39.6
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1380,
      "name": "Homyel\u0027",
      "names": {
        "es": "Homyel \u0027",
        "fr": "Homyel \u0027",
        "de": "Homyel \u0027",
        "it": "Homyel \u0027",
        "id": "Homyel \u0027",
        "ja": "homyel \u0027",
        "th": "homyel \u0027",
        "pt": "Homyel \u0027"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 52.4417,
        "lng": 30.9833
      },
      "country": "Belarus",
      "importance": 1
    },
    {
      "id": 1388,
      "name": "Sevastopol",
      "names": {
        "es": "Sebastopol",
        "fr": "Sébastopol",
        "zh": "塞瓦斯托波尔"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 44.6,
        "lng": 33.5333
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 1389,
      "name": "Encheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.1879,
        "lng": 112.3131
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1377,
      "name": "Hai Duong",
      "names": {
        "zh": "海都",
        "ja": "ハイ・ドゥーン"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.9399,
        "lng": 106.3309
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1390,
      "name": "Aden",
      "names": {
        "es": "Adén",
        "zh": "亚丁",
        "ja": "アデン",
        "th": "เอเดน"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 12.8,
        "lng": 45.0333
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 1395,
      "name": "Palm Bay",
      "names": {
        "es": "Palmita",
        "fr": "Palmier",
        "zh": "棕榈湾",
        "de": "Palmbucht",
        "it": "Baia di palme",
        "id": "Teluk telapak tangan",
        "ja": "パームベイ",
        "pt": "Baía"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.955,
        "lng": -80.6627
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1393,
      "name": "Jhansi",
      "names": {
        "zh": "占西"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.4486,
        "lng": 78.5696
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1392,
      "name": "Matsuyama",
      "names": {
        "zh": "松山",
        "ja": "松山",
        "th": "มัตซุยา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.8333,
        "lng": 132.7667
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 734,
      "name": "Lisbon",
      "names": {
        "es": "Lisboa",
        "fr": "Lisbonne",
        "zh": "里斯本",
        "de": "Lissabon",
        "it": "Lisbona",
        "ja": "リスボン",
        "th": "ลิสบอน",
        "pt": "Lisboa"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.708,
        "lng": -9.139
      },
      "country": "Portugal",
      "importance": 0
    },
    {
      "id": 1394,
      "name": "Kashgar",
      "names": {
        "zh": "喀什",
        "ja": "カシュガル",
        "th": "คัชการ์"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.45,
        "lng": 75.9833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1384,
      "name": "Ulhasnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.2167,
        "lng": 73.15
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1398,
      "name": "Monteria",
      "names": {
        "es": "Montería",
        "zh": "蒙特里亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.76,
        "lng": -75.8856
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 1391,
      "name": "Kitwe",
      "names": {
        "zh": "基特韦"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.8208,
        "lng": 28.2119
      },
      "country": "Zambia",
      "importance": 3
    },
    {
      "id": 1401,
      "name": "Davangere",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.4667,
        "lng": 75.9167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1396,
      "name": "Pingtung",
      "names": {
        "zh": "屏东"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 22.6761,
        "lng": 120.4942
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 1402,
      "name": "Jammu",
      "names": {
        "zh": "查谟",
        "ja": "ジャンムー",
        "th": "ชัมมู"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 32.7333,
        "lng": 74.85
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1403,
      "name": "Yiwu",
      "names": {
        "zh": "义乌",
        "ja": "義烏",
        "th": "อี้หวู่"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.3081,
        "lng": 120.0698
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1406,
      "name": "Ile-Ife",
      "names": {
        "zh": "异亮氨酸-生命",
        "ja": "イル・ife"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.4667,
        "lng": 4.5667
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 1407,
      "name": "Kirov",
      "names": {
        "zh": "基洛夫",
        "th": "คิรอฟ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.6,
        "lng": 49.65
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1410,
      "name": "Provo",
      "names": {
        "zh": "普罗沃",
        "ja": "プロボ",
        "th": "โพรโว"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.2457,
        "lng": -111.6457
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1411,
      "name": "Meicheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6412,
        "lng": 116.5689
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1408,
      "name": "Jiaxing",
      "names": {
        "zh": "嘉兴",
        "ja": "嘉興",
        "th": "เจียซิ่ง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.7522,
        "lng": 120.75
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1404,
      "name": "Belas",
      "names": {
        "zh": "贝拉什"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -9.0689,
        "lng": 13.1608
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 1405,
      "name": "Talatona",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.9167,
        "lng": 13.1833
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 1414,
      "name": "Sukkur",
      "names": {
        "zh": "苏库尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 27.6995,
        "lng": 68.8673
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 1416,
      "name": "Taozhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.8956,
        "lng": 119.411
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1409,
      "name": "Mykolaiv",
      "names": {
        "ja": "ムィコラーイウ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 46.9667,
        "lng": 32.0
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 1419,
      "name": "Worcester",
      "names": {
        "zh": "伍斯特",
        "ja": "ウスター",
        "th": "เวอร์ซ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2705,
        "lng": -71.8079
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1420,
      "name": "Hongjiang",
      "names": {
        "zh": "洪江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.1167,
        "lng": 109.95
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1421,
      "name": "Chimbote",
      "names": {
        "zh": "钦博特"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -9.0745,
        "lng": -78.5936
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 1423,
      "name": "Qaraghandy",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 49.8,
        "lng": 73.1167
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 1397,
      "name": "Lapu-Lapu City",
      "names": {
        "es": "Ciudad de Lapu-Lapu",
        "fr": "La Lapu-Lapu City",
        "zh": "拉普 - 拉普市",
        "de": "Lapu-Lapu-Stadt",
        "id": "Kota Lapu-Lapu",
        "ja": "ラプラパ都市",
        "th": "เมือง Lapu-Lapu",
        "pt": "Cidade de Lapu-Lapu"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3127,
        "lng": 123.9488
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1418,
      "name": "Johor Bahru",
      "names": {
        "zh": "柔佛州柔佛州",
        "ja": "ジョホールバール",
        "th": "ยะโฮร์บาห์รู"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 1.4556,
        "lng": 103.7611
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 1400,
      "name": "Imus",
      "names": {
        "zh": "伊穆斯",
        "th": "มัส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4297,
        "lng": 120.9367
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1412,
      "name": "Niteroi",
      "names": {
        "zh": "尼泰罗伊",
        "pt": "Niterói"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8833,
        "lng": -43.1036
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1399,
      "name": "Ichikawa",
      "names": {
        "zh": "市川",
        "ja": "市川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7219,
        "lng": 139.9311
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1428,
      "name": "Beipiao",
      "names": {
        "zh": "北票市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.802,
        "lng": 120.7621
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1425,
      "name": "Dengtacun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.4237,
        "lng": 123.3203
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1430,
      "name": "Murrieta",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.572,
        "lng": -117.1909
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1426,
      "name": "Zhijiang",
      "names": {
        "zh": "江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4271,
        "lng": 111.7506
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1429,
      "name": "Suoluntun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 45.1804,
        "lng": 124.82
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1422,
      "name": "Duisburg",
      "names": {
        "zh": "杜伊斯堡",
        "ja": "ジュイスブルク",
        "th": "สบูร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4322,
        "lng": 6.7611
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1427,
      "name": "Chengjiao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4043,
        "lng": 114.0593
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1424,
      "name": "Ixtapaluca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3186,
        "lng": -98.8822
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1433,
      "name": "Celaya",
      "names": {
        "zh": "塞拉亚"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5222,
        "lng": -100.8122
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1415,
      "name": "Oujda-Angad",
      "names": {
        "zh": "乌季达-angad"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.6867,
        "lng": -1.9114
      },
      "country": "Morocco",
      "importance": 1
    },
    {
      "id": 1435,
      "name": "Duyun",
      "names": {
        "zh": "都匀"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.2672,
        "lng": 107.5143
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1437,
      "name": "Nyala",
      "names": {
        "zh": "尼亚拉"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 12.05,
        "lng": 24.8833
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 1413,
      "name": "Matsudo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7878,
        "lng": 139.9031
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1431,
      "name": "Kota Bharu",
      "names": {
        "zh": "哥打巴鲁",
        "ja": "コタ・バル"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 6.1333,
        "lng": 102.25
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 1442,
      "name": "Yuanping",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7299,
        "lng": 112.7134
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1417,
      "name": "Higashi-osaka",
      "names": {
        "zh": "东大阪",
        "ja": "東大阪",
        "th": "Higashi-โอซาก้า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6794,
        "lng": 135.6008
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1438,
      "name": "Larkana",
      "names": {
        "zh": "拉卡纳"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 27.56,
        "lng": 68.2264
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 1440,
      "name": "`Ajman",
      "names": {
        "zh": "`阿治曼",
        "th": "`อัจมาน"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.3994,
        "lng": 55.4797
      },
      "country": "United Arab Emirates",
      "importance": 1
    },
    {
      "id": 1434,
      "name": "Vinh",
      "names": {
        "zh": "永"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 18.6733,
        "lng": 105.6922
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1436,
      "name": "Los Mochis",
      "names": {
        "ja": "ロスモチス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7835,
        "lng": -108.9937
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1432,
      "name": "Ciudad Lopez Mateos",
      "names": {
        "es": "Ciudad López Mateos"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.55,
        "lng": -99.2833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1441,
      "name": "Cheboksary",
      "names": {
        "zh": "切伯克萨瑞"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1333,
        "lng": 47.25
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1445,
      "name": "Belgaum",
      "names": {
        "zh": "贝尔高姆"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 15.8667,
        "lng": 74.5
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1444,
      "name": "Edinburgh",
      "names": {
        "es": "Edimburgo",
        "zh": "爱丁堡",
        "it": "Edimburgo",
        "ja": "エジンバラ",
        "th": "เอดินบะระ",
        "pt": "Edimburgo"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.953,
        "lng": -3.189
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 1439,
      "name": "Nishinomiya-hama",
      "names": {
        "zh": "西宫-哈马",
        "ja": "西宮-hama"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7333,
        "lng": 135.3333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1443,
      "name": "Toulouse",
      "names": {
        "zh": "图卢兹",
        "ja": "トゥールーズ",
        "th": "ตูลูส"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6045,
        "lng": 1.444
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 1447,
      "name": "Shahe",
      "names": {
        "zh": "沙河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8622,
        "lng": 114.502
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1448,
      "name": "Serra",
      "names": {
        "zh": "塞拉",
        "ja": "セラ",
        "th": "ร่า"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.1289,
        "lng": -40.3078
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1446,
      "name": "Tula",
      "names": {
        "zh": "图拉",
        "ja": "トゥーラ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.2,
        "lng": 37.6167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1449,
      "name": "Gaoping",
      "names": {
        "zh": "高平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7911,
        "lng": 112.9259
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1450,
      "name": "Greenville",
      "names": {
        "zh": "格林维尔",
        "ja": "グリーンヴィル",
        "th": "กรีนวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.8353,
        "lng": -82.3647
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1452,
      "name": "Dunhua",
      "names": {
        "zh": "敦化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.3667,
        "lng": 128.2333
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1451,
      "name": "Gulbarga",
      "names": {
        "zh": "古尔伯加"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 17.3333,
        "lng": 76.8333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1453,
      "name": "Brookhaven",
      "names": {
        "zh": "布鲁克海文",
        "th": "บรุกเฮเวน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8321,
        "lng": -72.9518
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1456,
      "name": "Wichita",
      "names": {
        "zh": "威奇托",
        "ja": "ウィチタ",
        "th": "วิชิตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6896,
        "lng": -97.3442
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1457,
      "name": "Toledo",
      "names": {
        "zh": "托莱多",
        "ja": "トレド",
        "th": "โทเลโด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6639,
        "lng": -83.5822
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1458,
      "name": "Kaihua",
      "names": {
        "zh": "开化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3715,
        "lng": 104.2437
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1454,
      "name": "Az Zarqa\u0027",
      "names": {
        "es": "AZ Zarqa \u0027",
        "fr": "Az zarqa \u0027",
        "de": "Az zarqa \u0027",
        "it": "AZ ZARQA \u0027",
        "id": "Az zarqa \u0027",
        "ja": "zarqa \u0027",
        "th": "AZ Zarqa \u0027",
        "pt": "AZ ZARQA \u0027"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 32.0833,
        "lng": 36.1
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 1460,
      "name": "Fuyuan",
      "names": {
        "zh": "富源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.3614,
        "lng": 134.2875
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1459,
      "name": "Jamnagar",
      "names": {
        "zh": "贾姆讷格尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.47,
        "lng": 70.07
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1455,
      "name": "Sylhet",
      "names": {
        "zh": "锡尔赫特"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 24.9,
        "lng": 91.8667
      },
      "country": "Bangladesh",
      "importance": 1
    },
    {
      "id": 1461,
      "name": "Dhulia",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.9,
        "lng": 74.7833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1465,
      "name": "Jiannan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.3446,
        "lng": 104.1994
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1463,
      "name": "Florianopolis",
      "names": {
        "zh": "佛洛莉亚诺波里斯",
        "ja": "フロリアノーポリス",
        "th": "โฟลเรียนอโปลิส",
        "pt": "Florianópolis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.5933,
        "lng": -48.553
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 1464,
      "name": "Chiniot",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.7167,
        "lng": 72.9833
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 1466,
      "name": "Oita",
      "names": {
        "zh": "大分",
        "ja": "大分",
        "th": "โออิตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.2333,
        "lng": 131.6067
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1470,
      "name": "Staten Island",
      "names": {
        "es": "Isla de Staten",
        "zh": "斯塔滕岛",
        "id": "Pulau Staten",
        "ja": "スタテン島",
        "th": "เกาะสเตเทน",
        "pt": "Ilha de staten"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5834,
        "lng": -74.1496
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1468,
      "name": "Nangong",
      "names": {
        "zh": "南宫"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3606,
        "lng": 115.3803
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 735,
      "name": "Bratislava",
      "names": {
        "zh": "布拉迪斯拉发",
        "ja": "ブラチスラバ",
        "th": "บราติสลาวา"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.1447,
        "lng": 17.1128
      },
      "country": "Slovakia",
      "importance": 0
    },
    {
      "id": 1467,
      "name": "Kaliningrad",
      "names": {
        "es": "Kaliningrado",
        "zh": "加里宁格勒",
        "ja": "カリーニングラード",
        "th": "คาลินินกราด",
        "pt": "Kaliningrado"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.7167,
        "lng": 20.5
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1469,
      "name": "Kurashiki",
      "names": {
        "zh": "仓敷",
        "ja": "倉敷"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.585,
        "lng": 133.7719
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1475,
      "name": "Macapa",
      "names": {
        "fr": "Macapá",
        "zh": "马卡帕",
        "it": "Macapá",
        "id": "Macapá",
        "pt": "Macapá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": 0.033,
        "lng": -51.0653
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 1462,
      "name": "Gaya",
      "names": {
        "zh": "格雅",
        "ja": "ガヤー"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.75,
        "lng": 85.0167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1477,
      "name": "Des Moines",
      "names": {
        "ja": "デモイン",
        "th": "ด้อม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5725,
        "lng": -93.6105
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 1476,
      "name": "Shekhupura",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.7083,
        "lng": 74.0
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 1478,
      "name": "Piura",
      "names": {
        "zh": "皮乌拉"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -5.2008,
        "lng": -80.6253
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 1473,
      "name": "Vila Velha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.3364,
        "lng": -40.2936
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1480,
      "name": "Jiaojiangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.6804,
        "lng": 121.45
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1481,
      "name": "Laohekou",
      "names": {
        "zh": "老河口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.3849,
        "lng": 111.6695
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1472,
      "name": "San Juan",
      "names": {
        "zh": "圣胡安",
        "ja": "サンファン",
        "th": "ซานฮวน"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.5375,
        "lng": -68.5364
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 736,
      "name": "Kitchener",
      "names": {
        "zh": "基奇纳",
        "ja": "キッチナー",
        "th": "คิช"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.4186,
        "lng": -80.4728
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 1485,
      "name": "Beian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 48.2395,
        "lng": 126.5037
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1486,
      "name": "Fujin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 47.2489,
        "lng": 132.0341
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1471,
      "name": "Kucukcekmece",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0,
        "lng": 28.8
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 1474,
      "name": "Mazar-e Sharif",
      "names": {
        "fr": "Mazar-eharif"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 36.7,
        "lng": 67.1167
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 1488,
      "name": "Xiaoyi",
      "names": {
        "zh": "孝义"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1449,
        "lng": 111.7718
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1490,
      "name": "Qingzhen",
      "names": {
        "zh": "清镇",
        "th": "ชิงเจิน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.5555,
        "lng": 106.4646
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1491,
      "name": "Ba`qubah",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.7447,
        "lng": 44.6436
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 1493,
      "name": "Buraydah",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 26.3333,
        "lng": 43.9667
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 1487,
      "name": "Leicester",
      "names": {
        "zh": "莱斯特",
        "ja": "レスター",
        "th": "เลสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.6344,
        "lng": -1.1319
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 1494,
      "name": "Gdansk",
      "names": {
        "zh": "格但斯克",
        "de": "Danzig",
        "it": "Danzica",
        "ja": "グダニスク"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.3667,
        "lng": 18.6333
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 1497,
      "name": "Campos",
      "names": {
        "zh": "坎波斯",
        "ja": "カンポス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.7539,
        "lng": -41.3239
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1483,
      "name": "Angeles City",
      "names": {
        "es": "Ciudad de angeles",
        "zh": "安吉利斯市",
        "de": "Angeles-Stadt",
        "ja": "アンヘレスシティ",
        "th": "เมืองแองเจลิส",
        "pt": "Cidade de angeles"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.1472,
        "lng": 120.5847
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1501,
      "name": "Long Beach",
      "names": {
        "es": "Playa larga",
        "fr": "Longue plage",
        "zh": "长滩",
        "de": "Langer Strand",
        "it": "Lunga spiaggia",
        "id": "Pantai panjang",
        "ja": "長い砂浜",
        "th": "หาดยาว",
        "pt": "Longa praia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7981,
        "lng": -118.1675
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1502,
      "name": "Kuqa",
      "names": {
        "zh": "库车"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.7152,
        "lng": 82.9604
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1499,
      "name": "Kanazawa",
      "names": {
        "zh": "金泽",
        "ja": "金沢",
        "th": "คานาซาว่า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.5611,
        "lng": 136.6565
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1498,
      "name": "Calabar",
      "names": {
        "zh": "卡拉巴尔",
        "ja": "カラバル",
        "th": "คาลาบาร์"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 4.95,
        "lng": 8.325
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1496,
      "name": "Manado",
      "names": {
        "zh": "万鸦老",
        "th": "มานาโด"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 1.4931,
        "lng": 124.8413
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1505,
      "name": "Port St. Lucie",
      "names": {
        "fr": "Port Saint-Lucie",
        "zh": "港口圣卢西莉",
        "ja": "ポートセントルーシー",
        "th": "พอร์ตเซนต์ลูซี",
        "pt": "Porto St. Lucie"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.2796,
        "lng": -80.3883
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1504,
      "name": "Cranbourne",
      "names": {},
      "countryCode": "AU",
      "latLng": {
        "lat": -38.0996,
        "lng": 145.2834
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 1503,
      "name": "Jalgaon",
      "names": {
        "zh": "贾尔冈"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 21.0167,
        "lng": 75.5667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1506,
      "name": "Murcia",
      "names": {
        "zh": "穆尔西亚",
        "ja": "ムルシア",
        "th": "มูร์เซีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.9861,
        "lng": -1.1303
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 1510,
      "name": "Xicheng",
      "names": {
        "zh": "西城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3629,
        "lng": 103.1545
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1508,
      "name": "Fukuyama",
      "names": {
        "zh": "福山",
        "ja": "福山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4858,
        "lng": 133.3625
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1489,
      "name": "Lanus",
      "names": {
        "zh": "拉努斯",
        "de": "Lanús"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7,
        "lng": -58.4
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 1512,
      "name": "Pyeongtaek",
      "names": {
        "zh": "平泽"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.9947,
        "lng": 127.0889
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1513,
      "name": "Kurnool",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.8222,
        "lng": 78.035
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1500,
      "name": "Iloilo",
      "names": {
        "zh": "伊洛伊洛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7167,
        "lng": 122.5667
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1514,
      "name": "Denton",
      "names": {
        "zh": "登顿",
        "ja": "デントン",
        "th": "เด็นทัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.2176,
        "lng": -97.1419
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1495,
      "name": "Marikina City",
      "names": {
        "es": "Ciudad de Marikina",
        "zh": "Marikina市",
        "id": "Kota Marikina",
        "ja": "マリキナシティ",
        "th": "เมืองมาริกิน่า",
        "pt": "Cidade de marikina"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.65,
        "lng": 121.1
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1518,
      "name": "Jian\u0027ou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.0387,
        "lng": 118.3215
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1519,
      "name": "Huadian",
      "names": {
        "zh": "华电"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.9633,
        "lng": 126.7478
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1515,
      "name": "Melaka",
      "names": {
        "zh": "马六甲",
        "ja": "マラッカ",
        "th": "มะละกา"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 2.1889,
        "lng": 102.2511
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 1492,
      "name": "Katsushika-ku",
      "names": {
        "zh": "葛饰-ku",
        "ja": "葛飾区-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7333,
        "lng": 139.85
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1507,
      "name": "Maua",
      "names": {
        "zh": "马乌阿"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6678,
        "lng": -46.4608
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1522,
      "name": "Minzhu",
      "names": {
        "zh": "民主"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.7192,
        "lng": 127.337
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1521,
      "name": "Kota Kinabalu",
      "names": {
        "ja": "コタキナバル"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.975,
        "lng": 116.0725
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 1509,
      "name": "Tel Aviv-Yafo",
      "names": {
        "zh": "特拉维夫 - 亚菲",
        "th": "เทลอาวีฟ - ยูโฟ"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.08,
        "lng": 34.78
      },
      "country": "Israel",
      "importance": 1
    },
    {
      "id": 1526,
      "name": "Udaipur",
      "names": {
        "zh": "乌代浦",
        "ja": "ウダイプール",
        "th": "อุทัยปุระ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.5833,
        "lng": 73.6833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1525,
      "name": "Balashikha",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.8,
        "lng": 37.95
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 1517,
      "name": "General Trias",
      "names": {
        "es": "Trios Generales",
        "fr": "Trias généraux",
        "zh": "一般三叠",
        "de": "Allgemeine TRIAS",
        "it": "Trias generale",
        "id": "Trias Umum",
        "ja": "一般的なトライアス",
        "th": "เทียสทั่วไป",
        "pt": "Trias gerais"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3833,
        "lng": 120.8833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1511,
      "name": "Amagasaki",
      "names": {
        "zh": "尼崎",
        "ja": "尼崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7333,
        "lng": 135.4
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1528,
      "name": "Mariupol",
      "names": {
        "zh": "马里乌波尔"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.1306,
        "lng": 37.5639
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 1527,
      "name": "Kursk",
      "names": {
        "fr": "Koursk",
        "zh": "库尔斯克",
        "th": "เคิร์สต์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.7372,
        "lng": 36.1872
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1533,
      "name": "Constantine",
      "names": {
        "es": "Constantino",
        "fr": "Constantin",
        "zh": "君士坦丁",
        "it": "Costantino",
        "ja": "コンスタンティン",
        "th": "คอนสแตนติน",
        "pt": "Constantino"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.365,
        "lng": 6.6147
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 1529,
      "name": "Eslamshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.5522,
        "lng": 51.235
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 1524,
      "name": "Rajshahi",
      "names": {
        "zh": "拉吉沙伊"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 24.3667,
        "lng": 88.6
      },
      "country": "Bangladesh",
      "importance": 1
    },
    {
      "id": 1532,
      "name": "Barcelona",
      "names": {
        "fr": "Barcelone",
        "zh": "巴塞罗那",
        "it": "Barcellona",
        "ja": "バルセロナ",
        "th": "บาร์เซโลนา"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1333,
        "lng": -64.6833
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 1534,
      "name": "Tanbei",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.0907,
        "lng": 112.9317
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1536,
      "name": "Madison",
      "names": {
        "zh": "麦迪逊",
        "ja": "マディソン",
        "th": "เมดิสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0826,
        "lng": -89.3931
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 1535,
      "name": "Ado-Ekiti",
      "names": {
        "zh": "的ADO-埃基蒂",
        "ja": "ADOを-ekiti",
        "th": "ความกังวลใจ-ekiti"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.6167,
        "lng": 5.2167
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1531,
      "name": "Hsinchu",
      "names": {
        "zh": "新竹",
        "ja": "新竹"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.8047,
        "lng": 120.9714
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 1537,
      "name": "Bellary",
      "names": {
        "zh": "贝拉里"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 15.15,
        "lng": 76.915
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1541,
      "name": "Reno",
      "names": {
        "zh": "里诺",
        "ja": "リノ",
        "th": "รีโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.5497,
        "lng": -119.8483
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1539,
      "name": "Santiago de Cuba",
      "names": {
        "zh": "圣地亚哥德古巴",
        "de": "Santiago de Kuba",
        "th": "ซานติอาโกเดอคิวบา"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.0217,
        "lng": -75.8294
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 1540,
      "name": "Yongji",
      "names": {
        "zh": "永济"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.867,
        "lng": 110.4417
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1538,
      "name": "Rodriguez",
      "names": {
        "es": "Rodríguez",
        "zh": "·罗德里格斯",
        "ja": "ロドリゲス",
        "th": "โรด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.7167,
        "lng": 121.1167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1542,
      "name": "Danjiangkou",
      "names": {
        "zh": "丹江口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.5408,
        "lng": 111.5098
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1516,
      "name": "Taoyuan District",
      "names": {
        "es": "Distrito taoyuan",
        "fr": "District de Taoyuan",
        "zh": "桃园区",
        "de": "Taoyuaner Bezirk",
        "it": "Distretto di Taoyuan",
        "id": "Distrik Taoyuan",
        "ja": "桃園地区",
        "th": "เขตเถาหยวน",
        "pt": "Distrito de Taoyuan"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.9889,
        "lng": 121.3111
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 1530,
      "name": "San Nicolas de los Garza",
      "names": {
        "ja": "サンニコラスデロスガザ",
        "th": "ซานนิโคลัสเดอลอสการ์ซา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.75,
        "lng": -100.2833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1543,
      "name": "Sao Jose do Rio Preto",
      "names": {
        "zh": "圣何塞做里约普雷托",
        "id": "Sao Jose melakukan Rio Preto",
        "ja": "サンノゼはリオプレトをする",
        "th": "Sao Jose ทำ Rio Preto",
        "pt": "São José do Rio Preto"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.82,
        "lng": -49.3789
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1546,
      "name": "Harrisburg",
      "names": {
        "zh": "哈里斯堡",
        "ja": "ハリスバーグ",
        "th": "แฮร์ริส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.2752,
        "lng": -76.8843
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 1545,
      "name": "Andijon",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.7,
        "lng": 72.35
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 1484,
      "name": "Pasay City",
      "names": {
        "es": "Ciudad de Pasay",
        "fr": "Passay City",
        "zh": "Pasay城市",
        "de": "Pasay-Stadt",
        "id": "Kota Pasay",
        "ja": "パサイシティ",
        "th": "เมือง Pasay"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.55,
        "lng": 121.0
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1548,
      "name": "Ning\u0027an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.3439,
        "lng": 129.4656
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1549,
      "name": "Little Rock",
      "names": {
        "es": "Pequeña roca",
        "fr": "Petit rocher",
        "zh": "小石头",
        "de": "Kleiner Felsen",
        "it": "Piccola roccia",
        "id": "Batu kecil",
        "ja": "リトルロック",
        "th": "หินเล็ก ๆ",
        "pt": "Pedra pequena"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.7256,
        "lng": -92.3576
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 737,
      "name": "Tallinn",
      "names": {
        "es": "Tallin",
        "zh": "塔林",
        "ja": "タリン",
        "th": "ทาลลินน์"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 59.4372,
        "lng": 24.745
      },
      "country": "Estonia",
      "importance": 0
    },
    {
      "id": 1553,
      "name": "Jinchang",
      "names": {
        "zh": "金昌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.5168,
        "lng": 102.1866
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1555,
      "name": "Guangming",
      "names": {
        "zh": "光明"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.3357,
        "lng": 122.7765
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1482,
      "name": "Kagithane",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0719,
        "lng": 28.9664
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 1552,
      "name": "Sunch\u0027on",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 39.4167,
        "lng": 125.9333
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 1523,
      "name": "Bukavu",
      "names": {
        "zh": "布卡武"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -2.4908,
        "lng": 28.8428
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 1544,
      "name": "Fujisawa",
      "names": {
        "zh": "藤泽",
        "ja": "藤沢"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3388,
        "lng": 139.4911
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1556,
      "name": "Sangli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.8667,
        "lng": 74.5667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1558,
      "name": "Beira",
      "names": {
        "zh": "贝拉",
        "ja": "ベイラ",
        "th": "เบียรา"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -19.8333,
        "lng": 34.85
      },
      "country": "Mozambique",
      "importance": 1
    },
    {
      "id": 1557,
      "name": "Tuticorin",
      "names": {
        "zh": "杜蒂戈林"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 8.7833,
        "lng": 78.1333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1561,
      "name": "Caxias do Sul",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.1678,
        "lng": -51.1789
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1560,
      "name": "Jeju",
      "names": {
        "zh": "济州",
        "ja": "済州",
        "th": "เชจู"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 33.5097,
        "lng": 126.5219
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 1547,
      "name": "Machida",
      "names": {
        "zh": "町田",
        "ja": "町田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5486,
        "lng": 139.4467
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1563,
      "name": "Manizales",
      "names": {
        "zh": "马尼萨莱斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0661,
        "lng": -75.4847
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 1551,
      "name": "Zurich",
      "names": {
        "es": "Zúrich",
        "zh": "苏黎世",
        "de": "Zürich",
        "it": "Zurigo",
        "ja": "チューリッヒ",
        "th": "ซูริค",
        "pt": "Zurique"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3744,
        "lng": 8.5411
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 1559,
      "name": "Kupang",
      "names": {
        "zh": "古邦"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -10.1633,
        "lng": 123.5778
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1562,
      "name": "Santos",
      "names": {
        "zh": "·桑托斯",
        "ja": "サントス",
        "th": "โทส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.9608,
        "lng": -46.3339
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1554,
      "name": "Kashiwa",
      "names": {
        "zh": "柏",
        "ja": "柏"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8544,
        "lng": 139.9689
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 739,
      "name": "Cape Town",
      "names": {
        "es": "Ciudad del Cabo",
        "fr": "Le Cap",
        "zh": "开普敦",
        "de": "Kapstadt",
        "it": "Città del Capo",
        "id": "Kota",
        "ja": "ケープタウン",
        "th": "เมืองเคป",
        "pt": "Cidade do Cabo"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.925,
        "lng": 18.425
      },
      "country": "South Africa",
      "importance": 0
    },
    {
      "id": 1564,
      "name": "Stavropol",
      "names": {
        "zh": "斯塔夫罗波尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 45.05,
        "lng": 41.9833
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1568,
      "name": "Zanjan",
      "names": {
        "zh": "赞詹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.6667,
        "lng": 48.4833
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1572,
      "name": "Oakland",
      "names": {
        "zh": "奥克兰",
        "ja": "オークランド",
        "th": "โอกแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7903,
        "lng": -122.2165
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1574,
      "name": "Kashan",
      "names": {
        "zh": "卡尚"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 33.9833,
        "lng": 51.4333
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 1570,
      "name": "Welkom",
      "names": {
        "zh": "韦尔科姆"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -27.9831,
        "lng": 26.7208
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 1571,
      "name": "Ulan-Ude",
      "names": {
        "fr": "Oulan-UDE",
        "zh": "乌兰乌德",
        "th": "อูลาน-ude"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.8272,
        "lng": 107.6064
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1566,
      "name": "Calicut",
      "names": {
        "zh": "卡利卡特"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.25,
        "lng": 75.7667
      },
      "country": "India",
      "importance": 2
    },
    {
      "id": 1575,
      "name": "Kenitra",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 34.25,
        "lng": -6.5833
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 1579,
      "name": "Sidi Bouzid",
      "names": {
        "zh": "西德贝德"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.0381,
        "lng": 9.4858
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 1576,
      "name": "Uyo",
      "names": {
        "zh": "乌约"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.05,
        "lng": 7.9333
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1578,
      "name": "Masan",
      "names": {
        "zh": "马山"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.1833,
        "lng": 128.55
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1520,
      "name": "Tanta",
      "names": {
        "zh": "坦塔"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.7833,
        "lng": 31.0
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 1580,
      "name": "Porto Velho",
      "names": {
        "ja": "ポルトVelho"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.7619,
        "lng": -63.9039
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 1569,
      "name": "Cusco",
      "names": {
        "zh": "库斯科",
        "ja": "クスコ",
        "th": "กุสโก"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.5222,
        "lng": -71.9833
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 1567,
      "name": "Veracruz",
      "names": {
        "zh": "韦拉克鲁斯",
        "ja": "ベラクルス",
        "th": "เวรากรูซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1903,
        "lng": -96.1533
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1577,
      "name": "Sokoto",
      "names": {
        "zh": "索科托"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 13.0622,
        "lng": 5.2339
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1587,
      "name": "Yan\u0027an Beilu",
      "names": {
        "zh": "延安贝卢",
        "ja": "ヤンのビルー"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.0222,
        "lng": 87.2961
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1583,
      "name": "Florencio Varela",
      "names": {
        "zh": "Florencio瓦雷拉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8167,
        "lng": -58.2833
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 1586,
      "name": "Aomori",
      "names": {
        "zh": "青森",
        "ja": "青森",
        "th": "อาโอโมริ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 40.8222,
        "lng": 140.7475
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1585,
      "name": "Akola",
      "names": {
        "zh": "阿科拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.7333,
        "lng": 77.0
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1479,
      "name": "Mandaluyong City",
      "names": {
        "es": "Ciudad Mandaluyong",
        "zh": "曼努永市",
        "id": "Kota Mandaluyong",
        "ja": "マンダルヨンシティ",
        "th": "เมืองแมนดาลูยอง",
        "pt": "Cidade de mandaluyong"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5833,
        "lng": 121.0333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1581,
      "name": "Sihung",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.3803,
        "lng": 126.803
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1582,
      "name": "Xalapa",
      "names": {
        "zh": "哈拉帕"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.54,
        "lng": -96.9275
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 1590,
      "name": "Durham",
      "names": {
        "zh": "达勒姆",
        "ja": "ダーラム",
        "th": "เดอแรม",
        "pt": "County Durham"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9794,
        "lng": -78.9031
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1589,
      "name": "Mogi das Cruzes",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5228,
        "lng": -46.1931
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1593,
      "name": "Buenaventura",
      "names": {
        "zh": "布埃纳文图拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.8772,
        "lng": -77.0267
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 1594,
      "name": "Yazd",
      "names": {
        "zh": "亚兹德",
        "th": "ยาซด์"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 31.8972,
        "lng": 54.3678
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1595,
      "name": "Laval",
      "names": {
        "zh": "拉瓦尔",
        "ja": "ラヴァル",
        "th": "ลาวาล"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.5833,
        "lng": -73.75
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 1565,
      "name": "Yogyakarta",
      "names": {
        "zh": "日惹",
        "ja": "ジョグジャカルタ",
        "th": "ยอร์กยาการ์ตา"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.8014,
        "lng": 110.3644
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1592,
      "name": "Likasi",
      "names": {
        "zh": "利卡西"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -10.9833,
        "lng": 26.7333
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 1598,
      "name": "Winston-Salem",
      "names": {
        "zh": "温斯顿塞勒姆",
        "ja": "ウィンストン・セーラム",
        "th": "วินสตัน-ซาเลม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1029,
        "lng": -80.2611
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1596,
      "name": "Toyota",
      "names": {
        "zh": "丰田",
        "ja": "トヨタ",
        "th": "โตโยต้า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0833,
        "lng": 137.1567
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1588,
      "name": "Agadir",
      "names": {
        "zh": "阿加迪尔",
        "ja": "アガディール",
        "th": "อากาดีร์"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 30.4167,
        "lng": -9.5833
      },
      "country": "Morocco",
      "importance": 1
    },
    {
      "id": 1600,
      "name": "Elazig",
      "names": {
        "zh": "埃拉泽",
        "th": "เอลาซิก"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6667,
        "lng": 39.2167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 1584,
      "name": "Uijeongbu",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.7486,
        "lng": 127.0389
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1601,
      "name": "Hpa-An",
      "names": {
        "es": "HPA-un",
        "fr": "HPa-un",
        "zh": "杂多酸-一个",
        "it": "HPa un-",
        "ja": "hpa-た",
        "th": "hpa ข้อ",
        "pt": "HPA-um"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 16.8906,
        "lng": 97.6333
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 1603,
      "name": "Bonita Springs",
      "names": {
        "ja": "ボニータスプリングス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.3559,
        "lng": -81.7861
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1604,
      "name": "Mishan",
      "names": {
        "zh": "密山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.542,
        "lng": 131.8666
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1605,
      "name": "Hailin",
      "names": {
        "zh": "海林"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.5735,
        "lng": 129.3825
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1606,
      "name": "Seremban",
      "names": {
        "zh": "芙蓉"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 2.7297,
        "lng": 101.9381
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 1608,
      "name": "Zhengjiatun",
      "names": {
        "zh": "郑家屯"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.513,
        "lng": 123.5003
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1607,
      "name": "Lecheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.1307,
        "lng": 113.3472
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1602,
      "name": "Palma",
      "names": {
        "zh": "帕尔马",
        "ja": "パルマ",
        "th": "เกาะ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.5667,
        "lng": 2.65
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 1599,
      "name": "Tver",
      "names": {
        "zh": "特维尔",
        "de": "Twer",
        "th": "ตเวียร์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.8578,
        "lng": 35.9219
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1610,
      "name": "Pencheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.6792,
        "lng": 115.6611
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 741,
      "name": "Wellington",
      "names": {
        "zh": "惠灵顿",
        "ja": "ウェリントン",
        "th": "เวลลิงตัน"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.2889,
        "lng": 174.7772
      },
      "country": "New Zealand",
      "importance": 0
    },
    {
      "id": 740,
      "name": "Tirana",
      "names": {
        "zh": "地拉那",
        "ja": "ティラナ",
        "th": "ติรานา"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 41.33,
        "lng": 19.82
      },
      "country": "Albania",
      "importance": 0
    },
    {
      "id": 1613,
      "name": "El Obeid",
      "names": {
        "fr": "El obéide",
        "zh": "elobeid",
        "it": "El obere",
        "ja": "エルボイド",
        "pt": "Elaborada"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 13.1833,
        "lng": 30.2167
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 1611,
      "name": "Magnitogorsk",
      "names": {
        "zh": "马格尼托哥尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.3833,
        "lng": 59.0333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 1614,
      "name": "Dalai",
      "names": {
        "es": "Lama",
        "fr": "Lama",
        "zh": "达赖",
        "ja": "ダライ",
        "th": "ดาไลลา",
        "pt": "Lama"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.505,
        "lng": 124.2863
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1616,
      "name": "Kolwezi",
      "names": {
        "zh": "科卢韦齐"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -10.7167,
        "lng": 25.4667
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 1609,
      "name": "Luhansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5833,
        "lng": 39.3333
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 1615,
      "name": "Xingren",
      "names": {
        "zh": "兴仁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.4352,
        "lng": 105.1907
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1612,
      "name": "Takamatsu",
      "names": {
        "zh": "高松",
        "ja": "高松"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.35,
        "lng": 134.05
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1617,
      "name": "Wenlan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3689,
        "lng": 103.3881
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1618,
      "name": "Indio",
      "names": {
        "zh": "因迪奥",
        "ja": "インディオ",
        "th": "อินดิโอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7346,
        "lng": -116.2346
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1619,
      "name": "Palm Coast",
      "names": {
        "es": "Costa de palma",
        "fr": "Palmier",
        "zh": "棕榈海岸",
        "de": "Palmküste",
        "it": "Palmea",
        "id": "Pantai Palm",
        "ja": "パームコースト",
        "th": "ฝ่ามือ",
        "pt": "Palmeira"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.5392,
        "lng": -81.246
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1621,
      "name": "Arusha",
      "names": {
        "zh": "阿鲁沙",
        "ja": "アルーシャ",
        "th": "อารูชา"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -3.3667,
        "lng": 36.6833
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 1622,
      "name": "Fenyang",
      "names": {
        "zh": "汾阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.2652,
        "lng": 111.7793
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1573,
      "name": "Shinagawa-ku",
      "names": {
        "zh": "品川-ku",
        "ja": "品川-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6,
        "lng": 139.7333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1625,
      "name": "Chattanooga",
      "names": {
        "zh": "查塔努加",
        "ja": "チャタヌーガ",
        "th": "นู"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.0657,
        "lng": -85.2488
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1623,
      "name": "Paju",
      "names": {
        "zh": "坡州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.8328,
        "lng": 126.8169
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 1597,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3167,
        "lng": 121.1167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1628,
      "name": "Nagqu",
      "names": {
        "zh": "那曲"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.4766,
        "lng": 92.0569
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1627,
      "name": "Jhang City",
      "names": {
        "es": "Ciudad de Jhang",
        "zh": "江市",
        "id": "Kota jhang",
        "th": "เมืองจาง",
        "pt": "Cidade de Jhang"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.2681,
        "lng": 72.3181
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 1630,
      "name": "Rio Branco",
      "names": {
        "zh": "里约布兰科",
        "ja": "リオ・ブランコ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.9781,
        "lng": -67.8117
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 1629,
      "name": "Jayapura",
      "names": {
        "zh": "查亚普拉",
        "ja": "ジャヤプラ",
        "th": "จายาปุระ"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -2.5333,
        "lng": 140.7167
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1631,
      "name": "Toyama",
      "names": {
        "zh": "富山",
        "ja": "富山",
        "th": "โทะยะมะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.7,
        "lng": 137.22
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1633,
      "name": "Sanandaj",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.3097,
        "lng": 46.9989
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1632,
      "name": "Fangting",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1282,
        "lng": 104.1695
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1634,
      "name": "Linghai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.1676,
        "lng": 121.3558
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1591,
      "name": "Diadema",
      "names": {
        "zh": "迪亚德马"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6858,
        "lng": -46.6228
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1636,
      "name": "Spokane",
      "names": {
        "zh": "斯波坎",
        "ja": "スポケーン",
        "th": "สโปแคน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6671,
        "lng": -117.433
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1637,
      "name": "Sochi",
      "names": {
        "fr": "Sotchi",
        "zh": "索契",
        "de": "Sotschi",
        "ja": "ソチ",
        "th": "โซ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.5853,
        "lng": 39.7203
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 742,
      "name": "Dodoma",
      "names": {
        "zh": "多多马"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.1835,
        "lng": 35.746
      },
      "country": "Tanzania",
      "importance": 0
    },
    {
      "id": 1638,
      "name": "Bhagalpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.25,
        "lng": 87.0167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1626,
      "name": "Kisumu",
      "names": {
        "zh": "基苏木"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.1,
        "lng": 34.75
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 1550,
      "name": "Zhangjiakou Shi Xuanhua Qu",
      "names": {
        "fr": "Zhangjiakou shi xuanhua",
        "zh": "张家口石宣华曲"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.5944,
        "lng": 115.0243
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1640,
      "name": "Turkmenabat",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 39.1,
        "lng": 63.5667
      },
      "country": "Turkmenistan",
      "importance": 1
    },
    {
      "id": 1624,
      "name": "Mataram",
      "names": {
        "zh": "马塔兰",
        "ja": "マタラム"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.5833,
        "lng": 116.1167
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1641,
      "name": "Zaria",
      "names": {
        "zh": "扎里亚"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 11.0667,
        "lng": 7.7
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 1644,
      "name": "Taiping",
      "names": {
        "zh": "太平",
        "th": "ไทปิง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.0691,
        "lng": 108.0351
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1620,
      "name": "Binan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3333,
        "lng": 121.0833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1646,
      "name": "Maracay",
      "names": {
        "zh": "马拉凯"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2469,
        "lng": -67.5958
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 1639,
      "name": "Ivanovo",
      "names": {
        "zh": "伊万诺沃"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.0,
        "lng": 41.0
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1642,
      "name": "Bryansk",
      "names": {
        "fr": "Briansk",
        "zh": "布良斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.25,
        "lng": 34.3667
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1647,
      "name": "Jiexiu",
      "names": {
        "zh": "介休"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.0282,
        "lng": 111.9103
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1648,
      "name": "Santa Fe",
      "names": {
        "zh": "圣达菲",
        "ja": "サンタフェ",
        "th": "ซานตาเฟ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.6333,
        "lng": -60.7
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 1649,
      "name": "Campina Grande",
      "names": {
        "id": "Grandina",
        "ja": "カンパナグランデ",
        "pt": "Camponês"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.2306,
        "lng": -35.8811
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1650,
      "name": "Nagasaki",
      "names": {
        "zh": "长崎",
        "ja": "長崎",
        "th": "นางาซากิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.7833,
        "lng": 129.8667
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1652,
      "name": "Syracuse",
      "names": {
        "es": "Siracusa",
        "zh": "锡拉丘兹",
        "de": "Syrakus",
        "it": "Siracusa",
        "ja": "シラキュース",
        "th": "ซีราคิวส์",
        "pt": "Siracusa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0409,
        "lng": -76.1438
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1651,
      "name": "Szczecin",
      "names": {
        "zh": "什切青",
        "ja": "シュチェチン",
        "th": "สเกซซีน"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.4247,
        "lng": 14.5553
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 743,
      "name": "Halifax",
      "names": {
        "zh": "哈利法克斯",
        "ja": "ハリファックス",
        "th": "แฮลิแฟกซ์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.6475,
        "lng": -63.5906
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 1653,
      "name": "Maringa",
      "names": {
        "es": "Maringá",
        "fr": "Maringá",
        "zh": "马林加",
        "it": "Maringá",
        "id": "Maringá",
        "pt": "Maringá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.425,
        "lng": -51.9389
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1655,
      "name": "Qazvin",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.2688,
        "lng": 50.0041
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1645,
      "name": "Mbale",
      "names": {
        "zh": "姆巴莱"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.0806,
        "lng": 34.175
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 1657,
      "name": "Jundiai",
      "names": {
        "pt": "Jundiaí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.1864,
        "lng": -46.8842
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1660,
      "name": "Lancaster",
      "names": {
        "zh": "兰开斯特",
        "ja": "ランカスター",
        "th": "แลงคาสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0421,
        "lng": -76.3012
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1635,
      "name": "Toyonaka",
      "names": {
        "zh": "丰中"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7833,
        "lng": 135.4667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1659,
      "name": "Gifu",
      "names": {
        "zh": "岐阜",
        "ja": "岐阜",
        "th": "กิฟุ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4167,
        "lng": 136.7667
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1661,
      "name": "Sikar",
      "names": {
        "zh": "锡格尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.6119,
        "lng": 75.1397
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1662,
      "name": "Jiangjiafan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.0304,
        "lng": 112.1
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1663,
      "name": "Dera Ghazi Khan",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.05,
        "lng": 70.6333
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 1665,
      "name": "Arlington",
      "names": {
        "zh": "阿灵顿",
        "ja": "アーリントン",
        "th": "อาร์ลิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.6998,
        "lng": -97.125
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1667,
      "name": "Miyazaki",
      "names": {
        "zh": "宫崎",
        "ja": "宮崎",
        "th": "มิยาซากิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 31.9167,
        "lng": 131.4167
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1656,
      "name": "Quilon",
      "names": {
        "zh": "奎隆"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 8.8853,
        "lng": 76.5864
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1669,
      "name": "Stockton",
      "names": {
        "zh": "斯托克顿",
        "ja": "ストックトン",
        "th": "สต็อกตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9765,
        "lng": -121.3109
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1666,
      "name": "Xushan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.1697,
        "lng": 121.2665
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1668,
      "name": "Yuyao",
      "names": {
        "zh": "余姚市",
        "th": "หยูเหยา"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.0372,
        "lng": 121.1546
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1658,
      "name": "Hirakata",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8167,
        "lng": 135.65
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1671,
      "name": "Sandakan",
      "names": {
        "zh": "山打根",
        "ja": "サンダカン"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.8388,
        "lng": 118.1173
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 744,
      "name": "Bissau",
      "names": {
        "zh": "比绍",
        "ja": "ビサウ",
        "th": "บิสเซา"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 11.8592,
        "lng": -15.5956
      },
      "country": "Guinea-Bissau",
      "importance": 0
    },
    {
      "id": 1673,
      "name": "Hejin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5914,
        "lng": 110.706
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1676,
      "name": "Poughkeepsie",
      "names": {
        "zh": "波基普西",
        "ja": "ポキプシー"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6949,
        "lng": -73.921
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1674,
      "name": "Thanh Hoa",
      "names": {
        "it": "Grazie hoa",
        "ja": "タンホア"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 19.8075,
        "lng": 105.7764
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1682,
      "name": "Lobito",
      "names": {
        "zh": "洛比托"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -12.3597,
        "lng": 13.5308
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 1683,
      "name": "Saurimo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -9.6589,
        "lng": 20.3934
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 1681,
      "name": "Yola",
      "names": {
        "zh": "约拉"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.2,
        "lng": 12.4833
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1654,
      "name": "Carapicuiba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5228,
        "lng": -46.8358
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1678,
      "name": "Nha Trang",
      "names": {
        "ja": "ニャチャン",
        "th": "นาค"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 12.2495,
        "lng": 109.1908
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1687,
      "name": "Ad Diwaniyah",
      "names": {
        "fr": "Ad diangeur",
        "zh": "Adiwaniyah",
        "it": "Annuncio diwaniyah",
        "ja": "Diwaniyah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 31.9892,
        "lng": 44.9247
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 1688,
      "name": "Piracicaba",
      "names": {
        "zh": "皮拉西卡巴"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7253,
        "lng": -47.6492
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1689,
      "name": "Hancheng",
      "names": {
        "zh": "韩城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4768,
        "lng": 110.4419
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1679,
      "name": "Belgorod",
      "names": {
        "zh": "别尔哥罗德",
        "th": "เบลโกรอด"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.6,
        "lng": 36.6
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1690,
      "name": "Karamay",
      "names": {
        "zh": "克拉玛依"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.5928,
        "lng": 84.8711
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1692,
      "name": "Augusta",
      "names": {
        "zh": "奥古斯塔",
        "ja": "オーガスタ",
        "th": "ออกัสตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.3645,
        "lng": -82.0708
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1686,
      "name": "Gujrat",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.5736,
        "lng": 74.0789
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 1677,
      "name": "Yokosuka",
      "names": {
        "zh": "横须贺",
        "ja": "横須賀",
        "th": "โยโกะสึกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.25,
        "lng": 139.6667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1694,
      "name": "Tieli",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.9838,
        "lng": 128.04
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1693,
      "name": "Bhilwara",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.35,
        "lng": 74.6333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1684,
      "name": "Olinda",
      "names": {
        "zh": "奥林达",
        "ja": "オリンダ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.0089,
        "lng": -34.855
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1643,
      "name": "Asyut",
      "names": {
        "es": "Asiut",
        "zh": "艾斯尤特"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 27.1869,
        "lng": 31.1714
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 1698,
      "name": "Boise",
      "names": {
        "zh": "博伊西",
        "ja": "ボイジー",
        "th": "บอยซี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.6007,
        "lng": -116.2312
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 1697,
      "name": "Bologna",
      "names": {
        "es": "Bolonia",
        "fr": "Bologne",
        "zh": "博洛尼亚",
        "ja": "ボローニャ",
        "th": "โบโลญญา",
        "pt": "Bolonha"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.4939,
        "lng": 11.3428
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 1696,
      "name": "Nizamabad",
      "names": {
        "zh": "尼扎马巴德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 18.6704,
        "lng": 78.1
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1701,
      "name": "Aqtobe",
      "names": {
        "ja": "アクトベ"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 50.2836,
        "lng": 57.2297
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 1702,
      "name": "Oxnard",
      "names": {
        "zh": "奥克斯纳德",
        "th": "ออกซ์นาร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1963,
        "lng": -119.1815
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1695,
      "name": "Cilegon",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.0167,
        "lng": 106.0167
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 1703,
      "name": "Oyo",
      "names": {
        "zh": "奥约",
        "th": "โอโย"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.8333,
        "lng": 3.9333
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 1672,
      "name": "Taytay",
      "names": {
        "zh": "泰泰"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5692,
        "lng": 121.1325
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1670,
      "name": "Bhatpara",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.8667,
        "lng": 88.4167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1664,
      "name": "Mwanza",
      "names": {
        "zh": "姆万扎"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -2.5167,
        "lng": 32.9
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 1704,
      "name": "Tarlac City",
      "names": {
        "es": "Ciudad de Tarlac",
        "zh": "塔拉克市",
        "de": "Tarlac-Stadt",
        "it": "Città di Tarlac",
        "id": "Kota tarlac",
        "ja": "タラックシティ",
        "th": "เมือง Tarlac",
        "pt": "Cidade de tarlac"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.4802,
        "lng": 120.5979
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1705,
      "name": "Okazaki",
      "names": {
        "zh": "冈崎",
        "ja": "岡崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.95,
        "lng": 137.1667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1707,
      "name": "Ha\u0027il",
      "names": {
        "zh": "哈伊勒"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 27.5236,
        "lng": 41.7001
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 1675,
      "name": "Minamisuita",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7667,
        "lng": 135.5167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1709,
      "name": "Lianzhou",
      "names": {
        "zh": "连州市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.7868,
        "lng": 112.3735
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1708,
      "name": "Yidu",
      "names": {
        "zh": "宜都"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.388,
        "lng": 111.4505
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1713,
      "name": "Scranton",
      "names": {
        "zh": "斯克兰顿",
        "ja": "スクラントン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.4044,
        "lng": -75.6649
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1691,
      "name": "Kakinada",
      "names": {
        "zh": "卡基纳达"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.9333,
        "lng": 82.2167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1712,
      "name": "Nuevo Laredo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 27.4861,
        "lng": -99.5069
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1715,
      "name": "London",
      "names": {
        "es": "Londres",
        "fr": "Londres",
        "zh": "伦敦",
        "it": "Londra",
        "ja": "ロンドン",
        "th": "ลอนดอน",
        "pt": "Londres"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.9836,
        "lng": -81.2497
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 1711,
      "name": "Christchurch",
      "names": {
        "zh": "基督城",
        "ja": "クライストチャーチ",
        "th": "ไครสต์เชิร์ช"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -43.5309,
        "lng": 172.6365
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 1717,
      "name": "Gusau",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 12.1667,
        "lng": 6.6667
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1718,
      "name": "Modesto",
      "names": {
        "zh": "莫德斯托",
        "ja": "モデスト",
        "th": "โมเดสโต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6374,
        "lng": -121.0028
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1720,
      "name": "Kissimmee",
      "names": {
        "zh": "基西米",
        "ja": "キシミー",
        "th": "คิสซิมมี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.3042,
        "lng": -81.4164
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1714,
      "name": "Brno",
      "names": {
        "zh": "布尔诺",
        "ja": "ブルノ",
        "th": "เบอร์โน"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.1953,
        "lng": 16.6083
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 1719,
      "name": "Pasto",
      "names": {
        "zh": "帕斯托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.21,
        "lng": -77.2747
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 745,
      "name": "Canberra",
      "names": {
        "zh": "堪培拉",
        "ja": "キャンベラ",
        "th": "แคนเบอร์รา"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -35.2931,
        "lng": 149.1269
      },
      "country": "Australia",
      "importance": 0
    },
    {
      "id": 1710,
      "name": "Florence",
      "names": {
        "es": "Florencia",
        "zh": "佛罗伦萨",
        "de": "Florenz",
        "it": "Firenze",
        "ja": "フィレンツェ",
        "th": "ฟลอเรนซ์",
        "pt": "Florença"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.7714,
        "lng": 11.2542
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 1700,
      "name": "Tetouan",
      "names": {
        "es": "Tetuán",
        "fr": "Tétouan",
        "zh": "得土安"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.5667,
        "lng": -5.3667
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 1685,
      "name": "Esenler",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0794,
        "lng": 28.8539
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 1680,
      "name": "Malabon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6625,
        "lng": 120.9567
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1716,
      "name": "Novi Sad",
      "names": {
        "es": "Novi triste",
        "fr": "Novi triste",
        "zh": "诺维悲伤",
        "de": "Novi traurig",
        "it": "Novi triste",
        "id": "Novi sedih",
        "ja": "ノヴィ悲しい",
        "th": "โนวีเศร้า",
        "pt": "Novi triste"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.2644,
        "lng": 19.8317
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 1725,
      "name": "Aurora",
      "names": {
        "zh": "奥罗拉",
        "ja": "オーロラ",
        "th": "ออโรรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7087,
        "lng": -104.7273
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1730,
      "name": "Youngstown",
      "names": {
        "zh": "扬斯敦",
        "ja": "ヤングスタウン",
        "th": "ยังส์ทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0993,
        "lng": -80.6463
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1722,
      "name": "Ichinomiya",
      "names": {
        "zh": "一宫"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3039,
        "lng": 136.8031
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1721,
      "name": "Las Palmas",
      "names": {
        "zh": "拉斯帕尔马斯",
        "ja": "ラスパルマ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 28.1272,
        "lng": -15.4314
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 1729,
      "name": "Usulutan",
      "names": {
        "es": "Usulután"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.35,
        "lng": -88.45
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 1728,
      "name": "Parbhani",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.2704,
        "lng": 76.76
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1724,
      "name": "Huancayo",
      "names": {
        "zh": "万卡约"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -12.0667,
        "lng": -75.2167
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 1726,
      "name": "Betim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.9678,
        "lng": -44.1978
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1727,
      "name": "Sintra",
      "names": {
        "zh": "辛特拉",
        "ja": "シントラ",
        "th": "ซินตรา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7974,
        "lng": -9.3904
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 1731,
      "name": "Iquitos",
      "names": {
        "zh": "伊基托斯",
        "ja": "イキトス",
        "th": "อีกีโตส"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -3.7333,
        "lng": -73.25
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 1723,
      "name": "Panihati",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.69,
        "lng": 88.37
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1706,
      "name": "Cainta",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5667,
        "lng": 121.1167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1732,
      "name": "Helixi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6267,
        "lng": 118.9861
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1733,
      "name": "Manukau City",
      "names": {
        "es": "Ciudad de Manukau",
        "zh": "曼府市",
        "it": "Città di Manukau",
        "id": "Kota Manukau",
        "ja": "マヌカウシティ",
        "th": "เมือง Manukau",
        "pt": "Cidade de manukau"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -37.0,
        "lng": 174.885
      },
      "country": "New Zealand",
      "importance": 3
    },
    {
      "id": 1734,
      "name": "Cumana",
      "names": {
        "es": "Cumaná",
        "zh": "库马纳"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.45,
        "lng": -64.1667
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 1737,
      "name": "Latur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.4004,
        "lng": 76.57
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1735,
      "name": "Rohtak",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.9,
        "lng": 76.5667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1741,
      "name": "Khorramabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.4667,
        "lng": 48.35
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 1699,
      "name": "Esenyurt",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0343,
        "lng": 28.6801
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 1738,
      "name": "Lipa City",
      "names": {
        "es": "Ciudad de LIPA",
        "fr": "Ville de Lipa",
        "zh": "Lipa市",
        "de": "Lipa-Stadt",
        "it": "Città di Lipa",
        "id": "Kota Lipa",
        "th": "เมือง Lipa",
        "pt": "Cidade lipo"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9411,
        "lng": 121.1622
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1743,
      "name": "Butuan",
      "names": {
        "zh": "布图安"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.9534,
        "lng": 125.5288
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 746,
      "name": "Juba",
      "names": {
        "zh": "朱巴",
        "ja": "ジューバ",
        "th": "จูบา"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 4.85,
        "lng": 31.6
      },
      "country": "South Sudan",
      "importance": 0
    },
    {
      "id": 1742,
      "name": "Ambon",
      "names": {
        "zh": "安汶",
        "ja": "アンボン"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.705,
        "lng": 128.17
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1736,
      "name": "Vinnytsia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.2167,
        "lng": 28.4333
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 1740,
      "name": "Bello",
      "names": {
        "zh": "贝洛",
        "ja": "ベリョ",
        "th": "เบลโล"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3319,
        "lng": -75.5581
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 1745,
      "name": "Toyohashi",
      "names": {
        "zh": "丰桥",
        "ja": "豊橋"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7692,
        "lng": 137.3916
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1749,
      "name": "Anguo",
      "names": {
        "zh": "安国"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4177,
        "lng": 115.3204
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1748,
      "name": "Lengshuijiang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.6858,
        "lng": 111.4279
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1752,
      "name": "Panshi",
      "names": {
        "zh": "磐石"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.9392,
        "lng": 126.0561
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1753,
      "name": "Madan",
      "names": {
        "zh": "马丹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 30.3307,
        "lng": 48.2797
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 1744,
      "name": "Keelung",
      "names": {
        "zh": "基隆",
        "ja": "基隆"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 25.1283,
        "lng": 121.7419
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 1755,
      "name": "Az Zubayr",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 30.3892,
        "lng": 47.7081
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 1751,
      "name": "Rajapalaiyam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 9.4204,
        "lng": 77.58
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1756,
      "name": "Al Qatif",
      "names": {
        "ja": "アルカイチフ",
        "th": "อัลกาติฟิ"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 26.5196,
        "lng": 50.0115
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 1757,
      "name": "Takasaki",
      "names": {
        "zh": "高崎",
        "ja": "高崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3167,
        "lng": 139.0
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1760,
      "name": "Nagano",
      "names": {
        "zh": "长野",
        "ja": "長野",
        "th": "โนะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.6486,
        "lng": 138.1928
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1758,
      "name": "Huichang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.9136,
        "lng": 112.7852
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1761,
      "name": "Bauru",
      "names": {
        "zh": "包鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.3147,
        "lng": -49.0606
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1747,
      "name": "La Florida",
      "names": {
        "fr": "La Floride",
        "zh": "La佛罗里达州",
        "th": "ลาฟลอริด้า"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.55,
        "lng": -70.5667
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 1754,
      "name": "Plovdiv",
      "names": {
        "zh": "普罗夫迪夫"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.1421,
        "lng": 24.7415
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 1765,
      "name": "Anapolis",
      "names": {
        "pt": "Anápolis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.3269,
        "lng": -48.9528
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1746,
      "name": "Baguio City",
      "names": {
        "es": "Ciudad de Baguio",
        "zh": "碧瑶市",
        "de": "Baguio-Stadt",
        "it": "Baguio città",
        "id": "Kota Baguio",
        "ja": "バギオシティ",
        "th": "เมืองบาเกียว",
        "pt": "Cidade de baguio"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.4152,
        "lng": 120.5956
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1759,
      "name": "Vitsyebsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.1833,
        "lng": 30.1667
      },
      "country": "Belarus",
      "importance": 1
    },
    {
      "id": 1764,
      "name": "Tecamac",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7131,
        "lng": -98.9683
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1763,
      "name": "Bochum",
      "names": {
        "zh": "波鸿",
        "ja": "ボーフム",
        "th": "โบคุม"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4833,
        "lng": 7.2167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1739,
      "name": "Mandaue City",
      "names": {
        "es": "Ciudad de Mandaue",
        "zh": "曼德市",
        "de": "Mandaue-Stadt",
        "id": "Kota Mandaue",
        "ja": "マンダウエ市",
        "th": "เมือง Mandaue",
        "pt": "Cidade de macaue"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3333,
        "lng": 123.9333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1762,
      "name": "Yanjiao",
      "names": {
        "zh": "燕郊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.9432,
        "lng": 116.8036
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1768,
      "name": "Wonsan",
      "names": {
        "zh": "元山"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.1475,
        "lng": 127.4461
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 1770,
      "name": "Iligan",
      "names": {
        "zh": "伊利甘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.2333,
        "lng": 124.25
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1766,
      "name": "Coventry",
      "names": {
        "zh": "考文垂",
        "ja": "コベントリー",
        "th": "โคเวนทรี"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4081,
        "lng": -1.5106
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 1776,
      "name": "Montes Claros",
      "names": {
        "zh": "蒙特兰克斯",
        "ja": "モルトクラロス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.7322,
        "lng": -43.8636
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1775,
      "name": "Blumenau",
      "names": {
        "zh": "布卢梅瑙"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.9189,
        "lng": -49.0658
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1769,
      "name": "Caerdydd",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4817,
        "lng": -3.1792
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 738,
      "name": "Beirut",
      "names": {
        "fr": "Beyrouth",
        "zh": "贝鲁特",
        "ja": "ベイルート",
        "th": "เบรุต",
        "pt": "Beirute"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 33.8869,
        "lng": 35.5131
      },
      "country": "Lebanon",
      "importance": 0
    },
    {
      "id": 1780,
      "name": "Surgut",
      "names": {
        "zh": "苏尔古特"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 61.25,
        "lng": 73.4333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 1773,
      "name": "Bengkulu",
      "names": {
        "zh": "明古鲁"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.7956,
        "lng": 102.2592
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1778,
      "name": "Shuanghejiedao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.3866,
        "lng": 106.7756
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1785,
      "name": "Wudalianchi",
      "names": {
        "zh": "五大连池"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.6433,
        "lng": 126.1408
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1777,
      "name": "Petion-Ville",
      "names": {
        "es": "Pétion-Ville",
        "fr": "Pélion-ville",
        "zh": "petion-市政厅",
        "ja": "petion-ヴィル",
        "th": "petion-วิลล์"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.5098,
        "lng": -72.2856
      },
      "country": "Haiti",
      "importance": 3
    },
    {
      "id": 1782,
      "name": "Palu",
      "names": {
        "zh": "帕卢"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.895,
        "lng": 119.8594
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1779,
      "name": "Umuahia",
      "names": {
        "zh": "乌穆阿希亚"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.5333,
        "lng": 7.4833
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1786,
      "name": "Sungai Petani",
      "names": {},
      "countryCode": "MY",
      "latLng": {
        "lat": 5.65,
        "lng": 100.48
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 1792,
      "name": "Taraz",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 42.8833,
        "lng": 71.3667
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 1793,
      "name": "Cubal",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.1117,
        "lng": 14.3672
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 1784,
      "name": "Mahilyow",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.9089,
        "lng": 30.343
      },
      "country": "Belarus",
      "importance": 1
    },
    {
      "id": 1796,
      "name": "Yong\u0027an",
      "names": {
        "zh": "永安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.9733,
        "lng": 117.3593
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1789,
      "name": "Hrodna",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.6667,
        "lng": 23.8167
      },
      "country": "Belarus",
      "importance": 1
    },
    {
      "id": 1798,
      "name": "Manisa",
      "names": {
        "zh": "马尼萨"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6333,
        "lng": 27.4167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 1790,
      "name": "Vladimir",
      "names": {
        "zh": "弗拉基米尔",
        "ja": "ウラジミール",
        "th": "วลาดิมีร์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1286,
        "lng": 40.4058
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1800,
      "name": "Minatitlan",
      "names": {
        "es": "Minatitlán",
        "it": "Minatitlán",
        "id": "Minatitlán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9833,
        "lng": -94.55
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1788,
      "name": "Vitoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビトリア",
        "th": "วีโตเรีย",
        "pt": "Vitória"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.3189,
        "lng": -40.3378
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 1801,
      "name": "Nizhniy Tagil",
      "names": {
        "de": "Nischniy Tagil",
        "id": "Tagil nizhniy",
        "ja": "Nizhniyのタギール"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.9167,
        "lng": 59.9667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 747,
      "name": "Yamoussoukro",
      "names": {
        "es": "Yamusukro",
        "zh": "亚穆苏克罗"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 6.8161,
        "lng": -5.2742
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 0
    },
    {
      "id": 1791,
      "name": "Sao Vicente",
      "names": {
        "zh": "圣维森特",
        "ja": "サンビセンテ",
        "pt": "São Vicente"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.9633,
        "lng": -46.3922
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1774,
      "name": "Orizaba",
      "names": {
        "zh": "欧瑞扎巴"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.85,
        "lng": -97.1
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1771,
      "name": "Cabuyao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.275,
        "lng": 121.125
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1803,
      "name": "Hongzhai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.0476,
        "lng": 104.6394
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1797,
      "name": "Wuppertal",
      "names": {
        "zh": "伍珀塔尔",
        "ja": "ヴッパータール"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2667,
        "lng": 7.1833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1781,
      "name": "Gedaref",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 14.0333,
        "lng": 35.3833
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 1799,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0333,
        "lng": 120.6833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1794,
      "name": "Kawagoe",
      "names": {
        "zh": "川越",
        "ja": "川越"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9333,
        "lng": 139.4833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1807,
      "name": "Pavlodar",
      "names": {
        "zh": "巴甫洛达尔"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 52.3156,
        "lng": 76.9564
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 1783,
      "name": "Pokhara",
      "names": {
        "zh": "博卡拉",
        "ja": "ポカラ",
        "th": "โปขระ"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 28.2097,
        "lng": 83.9853
      },
      "country": "Nepal",
      "importance": 1
    },
    {
      "id": 1811,
      "name": "Van",
      "names": {
        "zh": "·范·",
        "ja": "・ヴァン・",
        "th": "รถตู้"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5019,
        "lng": 43.4167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 1804,
      "name": "Wakayama",
      "names": {
        "zh": "和歌山",
        "ja": "和歌山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2333,
        "lng": 135.1667
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1806,
      "name": "Rahimyar Khan",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 28.4202,
        "lng": 70.2952
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 1750,
      "name": "Kita-ku",
      "names": {
        "zh": "北-ku",
        "ja": "北区-ku",
        "th": "คิตะ-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.75,
        "lng": 139.7333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1813,
      "name": "Neiva",
      "names": {
        "zh": "内瓦"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.9275,
        "lng": -75.2875
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 1809,
      "name": "Itaquaquecetuba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.4864,
        "lng": -46.3486
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1808,
      "name": "Gimpo",
      "names": {
        "zh": "金浦",
        "ja": "金浦",
        "th": "กิมโป"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.6236,
        "lng": 126.7142
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 1812,
      "name": "Corrientes",
      "names": {
        "zh": "科连特斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.4833,
        "lng": -58.8167
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 1816,
      "name": "Licheng",
      "names": {
        "zh": "里程"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.4935,
        "lng": 110.3902
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1810,
      "name": "Nara",
      "names": {
        "zh": "奈良",
        "ja": "奈良",
        "th": "นารา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6833,
        "lng": 135.7833
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1821,
      "name": "Yakeshi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 49.2842,
        "lng": 120.7283
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1787,
      "name": "Nam Dinh",
      "names": {
        "ja": "ナムニ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.42,
        "lng": 106.1683
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1818,
      "name": "Sinuiju",
      "names": {
        "zh": "新义州"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 40.1,
        "lng": 124.4
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 1819,
      "name": "Cabimas",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4,
        "lng": -71.4333
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 1814,
      "name": "Arkhangelsk",
      "names": {
        "zh": "阿尔汉格尔斯克",
        "de": "Archangelsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 64.55,
        "lng": 40.5333
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1815,
      "name": "Batangas",
      "names": {
        "zh": "八打雁",
        "th": "บาทางกัส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.75,
        "lng": 121.05
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1823,
      "name": "Fayetteville",
      "names": {
        "zh": "费耶特维尔",
        "ja": "フェイエットヴィル",
        "th": "ฟาเยตต์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0713,
        "lng": -94.166
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1820,
      "name": "Yueqing",
      "names": {
        "zh": "乐清市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.1188,
        "lng": 120.9621
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1795,
      "name": "Ibb",
      "names": {
        "zh": "伊布"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 13.9667,
        "lng": 44.1667
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 1822,
      "name": "Ahmadnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.0833,
        "lng": 74.7333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1827,
      "name": "Anaheim",
      "names": {
        "zh": "阿纳海姆",
        "ja": "アナハイム",
        "th": "อนาไฮม์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.839,
        "lng": -117.8572
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1828,
      "name": "Semey",
      "names": {
        "zh": "塞梅伊"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 50.4111,
        "lng": 80.2275
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 1825,
      "name": "Holguin",
      "names": {
        "es": "Holguín",
        "zh": "霍尔金"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.8872,
        "lng": -76.2631
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 1826,
      "name": "Qoqon",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.5404,
        "lng": 70.94
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 1830,
      "name": "Yingmen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.83,
        "lng": 97.73
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1832,
      "name": "Palmira",
      "names": {
        "zh": "帕尔米拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.5347,
        "lng": -76.2956
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 1831,
      "name": "Cariacica",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.2639,
        "lng": -40.42
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1837,
      "name": "Antofagasta",
      "names": {
        "zh": "安多法加斯大",
        "th": "อานโตฟากัสตา"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -23.6464,
        "lng": -70.398
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 1833,
      "name": "Tapachula",
      "names": {
        "zh": "塔帕丘拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 14.9,
        "lng": -92.2667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1836,
      "name": "Hatay",
      "names": {
        "zh": "哈塔伊",
        "ja": "ハタイ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.2025,
        "lng": 36.1606
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 1829,
      "name": "Varna",
      "names": {
        "zh": "瓦尔纳",
        "ja": "ヴァルナ",
        "th": "วาร์"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.2114,
        "lng": 27.9111
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 1824,
      "name": "Takatsuki",
      "names": {
        "zh": "高月"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.85,
        "lng": 135.6167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1839,
      "name": "Chita",
      "names": {
        "zh": "赤塔",
        "ja": "チタ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.0333,
        "lng": 113.5
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1841,
      "name": "Caruaru",
      "names": {
        "zh": "卡鲁阿鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.2828,
        "lng": -35.9758
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1843,
      "name": "Pensacola",
      "names": {
        "zh": "彭萨科拉",
        "ja": "ペンサコーラ",
        "th": "เพนซาโคลา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.4427,
        "lng": -87.1886
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1844,
      "name": "Victorville",
      "names": {
        "zh": "维克托维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5277,
        "lng": -117.3536
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1817,
      "name": "Koshigaya",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8911,
        "lng": 139.7908
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1835,
      "name": "Bydgoszcz",
      "names": {
        "zh": "比得哥什",
        "ja": "ブィドゴシュチュ",
        "th": "บิดกอชช์"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1167,
        "lng": 18.0
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 1805,
      "name": "Bilbao",
      "names": {
        "zh": "毕尔巴鄂",
        "ja": "ビルバオ",
        "th": "บิลเบา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.2569,
        "lng": -2.9236
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 1767,
      "name": "Shinjuku",
      "names": {
        "zh": "新宿",
        "ja": "新宿",
        "th": "ชินจุกุ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7,
        "lng": 139.7167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1845,
      "name": "Tanch\u0027on",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 40.458,
        "lng": 128.911
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 1849,
      "name": "Kusti",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 13.17,
        "lng": 32.66
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 1850,
      "name": "Cuddapah",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.4667,
        "lng": 78.8167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1834,
      "name": "Konak",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4183,
        "lng": 27.1347
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 1842,
      "name": "Utrecht",
      "names": {
        "zh": "乌得勒支",
        "ja": "ユトレヒト",
        "th": "อูเทรกต์"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0889,
        "lng": 5.1156
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 1854,
      "name": "Lancaster",
      "names": {
        "zh": "兰开斯特",
        "ja": "ランカスター",
        "th": "แลงคาสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.6935,
        "lng": -118.1753
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1853,
      "name": "Vitoria da Conquista",
      "names": {
        "pt": "Vitória da Conquista"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -14.8658,
        "lng": -40.8389
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1855,
      "name": "Makiivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0556,
        "lng": 37.9611
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 1857,
      "name": "Greensboro",
      "names": {
        "zh": "格林斯博罗",
        "ja": "グリーンズボロ",
        "th": "กรีนสโบโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0956,
        "lng": -79.8269
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1852,
      "name": "Otsu",
      "names": {
        "zh": "大津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0167,
        "lng": 135.85
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1863,
      "name": "Corpus Christi",
      "names": {
        "zh": "语料库克里斯蒂",
        "de": "Der Leib Christi",
        "ja": "コーパスクリスティ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.726,
        "lng": -97.3755
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1859,
      "name": "Gomez Palacio",
      "names": {
        "zh": "戈麦斯帕拉西奥",
        "ja": "ゴメスパラシオ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.5611,
        "lng": -103.4983
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1860,
      "name": "Franca",
      "names": {
        "zh": "纳弗兰卡",
        "ja": "フランカ",
        "th": "กลาง"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.5389,
        "lng": -47.4008
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1865,
      "name": "Yeosu",
      "names": {
        "zh": "丽水"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 34.7368,
        "lng": 127.7458
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 1862,
      "name": "Kaluga",
      "names": {
        "zh": "卡卢加"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.5333,
        "lng": 36.2667
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1838,
      "name": "Rajahmundry",
      "names": {
        "zh": "拉贾蒙德里"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.9833,
        "lng": 81.7833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1851,
      "name": "Simferopol",
      "names": {
        "zh": "辛菲罗波尔",
        "ja": "シンフェロポリ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 44.9484,
        "lng": 34.1
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 1867,
      "name": "Alwar",
      "names": {
        "zh": "阿尔瓦尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.5667,
        "lng": 76.6167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1869,
      "name": "Lianran",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.9211,
        "lng": 102.4778
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1848,
      "name": "Sumqayit",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5917,
        "lng": 49.6397
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 1846,
      "name": "Tokorozawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7994,
        "lng": 139.4689
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1847,
      "name": "Nice",
      "names": {
        "es": "Bonito",
        "fr": "Joli",
        "zh": "尼斯",
        "de": "Nett",
        "it": "Simpatico",
        "id": "Bagus",
        "ja": "ニース",
        "th": "ดี",
        "pt": "Legal"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.7034,
        "lng": 7.2663
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 1772,
      "name": "Nakano",
      "names": {
        "zh": "中野",
        "ja": "中野"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7074,
        "lng": 139.6638
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1840,
      "name": "Chitungwiza",
      "names": {},
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.0,
        "lng": 31.1
      },
      "country": "Zimbabwe",
      "importance": 3
    },
    {
      "id": 1861,
      "name": "Brest",
      "names": {
        "zh": "布雷斯特",
        "ja": "ブレスト",
        "th": "เบรสต์"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 52.0847,
        "lng": 23.6569
      },
      "country": "Belarus",
      "importance": 1
    },
    {
      "id": 1870,
      "name": "Buon Ma Thuot",
      "names": {
        "ja": "ブンマサート"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 12.6667,
        "lng": 108.05
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1866,
      "name": "Lublin",
      "names": {
        "zh": "卢布林",
        "ja": "ルブリン",
        "th": "ริน"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.2333,
        "lng": 22.5667
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 1871,
      "name": "Cuernavaca",
      "names": {
        "zh": "库埃纳瓦卡",
        "ja": "クエルナバカ",
        "th": "กูเอร์นาวากา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9186,
        "lng": -99.2342
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 1856,
      "name": "East London",
      "names": {
        "es": "Este de Londres",
        "zh": "东伦敦",
        "id": "London Timur",
        "ja": "イーストロンドン",
        "th": "ตะวันออกลอนดอน"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.0153,
        "lng": 27.9116
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 1876,
      "name": "Kaesong",
      "names": {
        "zh": "开城"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 37.964,
        "lng": 126.5644
      },
      "country": "North Korea",
      "importance": 2
    },
    {
      "id": 1875,
      "name": "Jitpur",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 27.6666,
        "lng": 85.3333
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 1877,
      "name": "Petrolina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -9.3928,
        "lng": -40.5078
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1879,
      "name": "Tangdong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.9755,
        "lng": 113.2302
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1874,
      "name": "Alicante",
      "names": {
        "zh": "阿利坎特",
        "ja": "アリカンテ",
        "th": "อาลีกันเต"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.3453,
        "lng": -0.4831
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 1878,
      "name": "Chinju",
      "names": {
        "zh": "晋州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.1928,
        "lng": 128.0847
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1873,
      "name": "Randburg",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.0936,
        "lng": 28.0064
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 1882,
      "name": "Iwaki",
      "names": {
        "zh": "岩城",
        "ja": "いわき"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.0333,
        "lng": 140.8833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 748,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 48.4283,
        "lng": -123.3647
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 1886,
      "name": "Hirosaki",
      "names": {
        "zh": "弘前",
        "ja": "弘前"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 40.6031,
        "lng": 140.4642
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1885,
      "name": "Yingzhong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.2381,
        "lng": 119.8133
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1858,
      "name": "Uruapan",
      "names": {
        "zh": "乌鲁阿潘"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4208,
        "lng": -102.0628
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1892,
      "name": "Fort Wayne",
      "names": {
        "fr": "Forte wayne",
        "zh": "韦恩堡",
        "ja": "フォートウェイン",
        "th": "ฟอร์ตเวน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0886,
        "lng": -85.1437
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1881,
      "name": "Belfast",
      "names": {
        "zh": "贝尔法斯特",
        "ja": "ベルファスト",
        "th": "เบลฟัสต์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.5964,
        "lng": -5.93
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 1889,
      "name": "Bielefeld",
      "names": {
        "zh": "比勒费尔德",
        "ja": "ビーレフェルト",
        "th": "บีเลเฟลด์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.0167,
        "lng": 8.5333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1864,
      "name": "Muzaffarpur",
      "names": {
        "zh": "穆扎法尔布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.12,
        "lng": 85.3833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1872,
      "name": "Beni",
      "names": {
        "zh": "贝尼",
        "ja": "ベニ"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 0.4911,
        "lng": 29.4731
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 1887,
      "name": "Vina del Mar",
      "names": {
        "es": "Viña del mar"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.0245,
        "lng": -71.5518
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 1880,
      "name": "Hangu",
      "names": {
        "zh": "汉沽"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.232,
        "lng": 117.777
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1894,
      "name": "Wad Medani",
      "names": {
        "de": "Wath medani",
        "ja": "ワットメダニ",
        "th": "ปึกมานาน"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 14.4,
        "lng": 33.51
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 1903,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7366,
        "lng": -117.8819
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1904,
      "name": "Flint",
      "names": {
        "fr": "Silex",
        "zh": "火石",
        "it": "Selce",
        "ja": "フリント",
        "th": "หินเหล็กไฟ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0235,
        "lng": -83.6922
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1896,
      "name": "Soledad de Graciano Sanchez",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.1833,
        "lng": -100.9333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1883,
      "name": "Blida",
      "names": {
        "zh": "卜利达"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.4722,
        "lng": 2.8333
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 1899,
      "name": "Maebashi",
      "names": {
        "zh": "前桥"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3833,
        "lng": 139.0667
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1909,
      "name": "Islip",
      "names": {
        "zh": "艾斯利普"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7384,
        "lng": -73.1887
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1901,
      "name": "Ganca",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.6828,
        "lng": 46.3606
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 1908,
      "name": "Asahikawa",
      "names": {
        "zh": "旭川",
        "ja": "旭川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 43.7706,
        "lng": 142.365
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1895,
      "name": "Ribeirao das Neves",
      "names": {
        "it": "Ribeirao das nefes",
        "pt": "Ribeirão das Neves"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.7669,
        "lng": -44.0869
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1905,
      "name": "Kendari",
      "names": {
        "zh": "肯达里"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.9675,
        "lng": 122.5947
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 1911,
      "name": "Wonju",
      "names": {
        "zh": "原州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.3417,
        "lng": 127.9208
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 749,
      "name": "Maseru",
      "names": {
        "zh": "马塞卢",
        "ja": "マセル",
        "th": "มาเซรู"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -29.31,
        "lng": 27.48
      },
      "country": "Lesotho",
      "importance": 0
    },
    {
      "id": 1914,
      "name": "Lafia",
      "names": {
        "zh": "拉菲亚"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 8.5,
        "lng": 8.5167
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1910,
      "name": "Dahuk",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.85,
        "lng": 42.9833
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 1897,
      "name": "Bonn",
      "names": {
        "zh": "波恩",
        "ja": "ボン",
        "th": "บอนน์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.7339,
        "lng": 7.0997
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1912,
      "name": "Mathura",
      "names": {
        "zh": "马图拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.5035,
        "lng": 77.6722
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1898,
      "name": "Kamarhati",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.67,
        "lng": 88.37
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1902,
      "name": "Mymensingh",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 24.7504,
        "lng": 90.38
      },
      "country": "Bangladesh",
      "importance": 1
    },
    {
      "id": 750,
      "name": "Nicosia",
      "names": {
        "fr": "Nicosie",
        "zh": "尼科西亚",
        "ja": "ニコシア",
        "th": "นิโคเซีย",
        "pt": "Nicósia"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 35.1725,
        "lng": 33.365
      },
      "country": "Cyprus",
      "importance": 0
    },
    {
      "id": 1906,
      "name": "Thai Nguyen",
      "names": {
        "es": "Tailandés nguyen",
        "zh": "泰国尼文",
        "ja": "タイのNguyen",
        "th": "ไทยเหงียน"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.6,
        "lng": 105.85
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1916,
      "name": "Bamiantong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.9164,
        "lng": 130.5212
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1890,
      "name": "Cuenca",
      "names": {
        "zh": "昆卡",
        "ja": "クエンカ",
        "th": "เควงคา"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -2.8974,
        "lng": -79.0045
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 1907,
      "name": "Smolensk",
      "names": {
        "zh": "斯摩棱斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.7828,
        "lng": 32.0453
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1915,
      "name": "Koriyama",
      "names": {
        "zh": "郡山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.4128,
        "lng": 140.295
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 1893,
      "name": "Ciudad Obregon",
      "names": {
        "es": "Ciudad Obregón"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.4939,
        "lng": -109.9389
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1917,
      "name": "Patiala",
      "names": {
        "zh": "帕蒂亚拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 30.3204,
        "lng": 76.385
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1919,
      "name": "Izmir",
      "names": {
        "zh": "伊兹密尔",
        "ja": "イズミル",
        "th": "อิซเมียร์"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4127,
        "lng": 27.1384
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 1922,
      "name": "Markham",
      "names": {
        "zh": "马克姆",
        "ja": "マーカム",
        "th": "มาร์คัม"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.8767,
        "lng": -79.2633
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 1924,
      "name": "Pelotas",
      "names": {
        "zh": "佩洛塔斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -31.7719,
        "lng": -52.3428
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1923,
      "name": "Saugor",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.83,
        "lng": 78.71
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1929,
      "name": "Fayetteville",
      "names": {
        "zh": "费耶特维尔",
        "ja": "フェイエットヴィル",
        "th": "ฟาเยตต์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.0846,
        "lng": -78.9776
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1927,
      "name": "Bijapur",
      "names": {
        "th": "พิช"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.8244,
        "lng": 75.7154
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1921,
      "name": "Cabanatuan City",
      "names": {
        "es": "Ciudad del Cabanatuan",
        "de": "Cabanatuan-Stadt",
        "id": "Kota cabanatuan",
        "ja": "カバナタン市",
        "pt": "Cidade de Cabanatuan"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.4833,
        "lng": 120.9667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1884,
      "name": "Salamanca",
      "names": {
        "zh": "萨拉曼卡",
        "ja": "サラマンカ",
        "th": "ซาลามันกา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5703,
        "lng": -101.1972
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1930,
      "name": "Ulanhot",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.0726,
        "lng": 122.0719
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1920,
      "name": "Vung Tau",
      "names": {
        "id": "Lawan",
        "ja": "ヴungtau",
        "th": "หวู่เต่า",
        "pt": "Vógina tau"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.4042,
        "lng": 107.1417
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1931,
      "name": "Yunzhong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.8143,
        "lng": 113.0946
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1933,
      "name": "Al Fallujah",
      "names": {
        "ja": "アルフォールザー",
        "th": "อัลฟอลลูจาห์"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.35,
        "lng": 43.7833
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 1936,
      "name": "Boa Vista",
      "names": {
        "ja": "ボアビスタ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": 2.82,
        "lng": -60.6719
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 1925,
      "name": "Roodepoort",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.1625,
        "lng": 27.8725
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 1926,
      "name": "Volzhskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 48.7833,
        "lng": 44.7667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 1941,
      "name": "Pucallpa",
      "names": {
        "zh": "普卡尔帕"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -8.3833,
        "lng": -74.55
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 1802,
      "name": "San Pedro",
      "names": {
        "zh": "圣佩德罗",
        "ja": "サンペドロ",
        "th": "ซานเปโดร"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3583,
        "lng": 121.0583
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1934,
      "name": "Kochi",
      "names": {
        "zh": "高知",
        "ja": "高知",
        "th": "โคชิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5589,
        "lng": 133.5314
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1945,
      "name": "Caucaia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.7328,
        "lng": -38.6558
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1948,
      "name": "Jackson",
      "names": {
        "zh": "杰克逊",
        "ja": "ジャクソン",
        "th": "แจ็คสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.3163,
        "lng": -90.2124
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 1947,
      "name": "Uberaba",
      "names": {
        "zh": "乌贝拉巴"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.7478,
        "lng": -47.9319
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1943,
      "name": "Kuching",
      "names": {
        "zh": "古晋",
        "ja": "クチン",
        "th": "กูชิง"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 1.5397,
        "lng": 110.3542
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 1932,
      "name": "Cotabato",
      "names": {
        "zh": "哥塔巴托"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.2167,
        "lng": 124.25
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 1868,
      "name": "Brahmapur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.32,
        "lng": 84.8
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1950,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.4458,
        "lng": -122.7067
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1937,
      "name": "Cluj-Napoca",
      "names": {
        "zh": "克卢日 - 纳波卡",
        "ja": "クルージュ\u003dナポカ",
        "th": "ลูช-napoca"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.78,
        "lng": 23.5594
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 1942,
      "name": "Yildirim",
      "names": {
        "zh": "耶尔德勒姆"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1878,
        "lng": 29.0822
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 1953,
      "name": "Lansing",
      "names": {
        "zh": "兰辛",
        "ja": "ランシング",
        "th": "แลนซิง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7142,
        "lng": -84.5601
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 1951,
      "name": "Gonaives",
      "names": {
        "fr": "Gonaïves",
        "zh": "戈纳伊夫"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.4456,
        "lng": -72.6883
      },
      "country": "Haiti",
      "importance": 1
    },
    {
      "id": 1888,
      "name": "Nazret",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 8.55,
        "lng": 39.2667
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 1946,
      "name": "Gonder",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 12.6,
        "lng": 37.4667
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 1938,
      "name": "Canoas",
      "names": {
        "zh": "卡诺阿斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.92,
        "lng": -51.18
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1949,
      "name": "Mekele",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 13.4969,
        "lng": 39.4769
      },
      "country": "Ethiopia",
      "importance": 1
    },
    {
      "id": 1940,
      "name": "Bari",
      "names": {
        "zh": "巴里",
        "ja": "バリ",
        "th": "บารี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.1253,
        "lng": 16.8667
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 1956,
      "name": "San Juan",
      "names": {
        "zh": "圣胡安",
        "ja": "サンファン",
        "th": "ซานฮวน"
      },
      "countryCode": "PR",
      "latLng": {
        "lat": 18.4037,
        "lng": -66.0636
      },
      "country": "Puerto Rico",
      "importance": 0
    },
    {
      "id": 1955,
      "name": "Binxian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.0364,
        "lng": 108.0764
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1957,
      "name": "Lexington",
      "names": {
        "zh": "列克星敦",
        "ja": "レキシントン",
        "th": "เล็กซิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.0423,
        "lng": -84.4587
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1961,
      "name": "Kaiyuan",
      "names": {
        "zh": "开元"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7147,
        "lng": 103.2585
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 1963,
      "name": "Uige",
      "names": {
        "zh": "威热",
        "pt": "Uíge"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -7.6087,
        "lng": 15.0613
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 751,
      "name": "Windhoek",
      "names": {
        "zh": "温得和克",
        "ja": "ウィントフック",
        "th": "วินด์ฮุก"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -22.57,
        "lng": 17.0836
      },
      "country": "Namibia",
      "importance": 0
    },
    {
      "id": 1959,
      "name": "Dongyang",
      "names": {
        "zh": "东阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.2785,
        "lng": 120.2282
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1958,
      "name": "Hotan",
      "names": {
        "zh": "和田"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1012,
        "lng": 79.9327
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1966,
      "name": "Ann Arbor",
      "names": {
        "es": "Annico",
        "zh": "安娜堡",
        "ja": "アナーバー",
        "th": "แอนอาร์เบอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2755,
        "lng": -83.7312
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1935,
      "name": "Minna",
      "names": {
        "zh": "大众"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.6139,
        "lng": 6.5569
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1964,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.8845,
        "lng": -4.7796
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 1965,
      "name": "Camaguey",
      "names": {
        "es": "Camagüey",
        "zh": "卡马圭"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.3786,
        "lng": -77.9186
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 1918,
      "name": "Taourirt",
      "names": {
        "zh": "陶里尔特"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.41,
        "lng": -2.89
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 1967,
      "name": "San Salvador de Jujuy",
      "names": {
        "zh": "圣萨尔瓦多德枣",
        "ja": "サンサルバドールデュジュエ",
        "th": "ซานซัลวาดอร์เดอสวรรค์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.1856,
        "lng": -65.2994
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 1969,
      "name": "Al Kut",
      "names": {
        "de": "Alk",
        "ja": "アルカット",
        "th": "อัลกุด"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.4907,
        "lng": 45.8304
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 1928,
      "name": "Sukabumi",
      "names": {
        "zh": "苏卡布米"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.9197,
        "lng": 106.9272
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 1973,
      "name": "Menongue",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.6556,
        "lng": 17.6842
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 1960,
      "name": "Ciudad del Este",
      "names": {
        "ja": "シウダードデルエステ"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.5167,
        "lng": -54.6167
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 1970,
      "name": "Shahjanpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.8804,
        "lng": 79.905
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1974,
      "name": "Junagadh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.52,
        "lng": 70.47
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1976,
      "name": "Henderson",
      "names": {
        "zh": "亨德森",
        "ja": "ヘンダーソン",
        "th": "เฮนเดอร์สัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0133,
        "lng": -115.038
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1952,
      "name": "Guasave",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.5744,
        "lng": -108.4706
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1978,
      "name": "Maroua",
      "names": {
        "zh": "马鲁阿"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 10.5823,
        "lng": 14.3275
      },
      "country": "Cameroon",
      "importance": 1
    },
    {
      "id": 1980,
      "name": "Asan",
      "names": {
        "zh": "峨山",
        "ja": "アサン",
        "th": "อาซาน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.35,
        "lng": 126.9167
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1983,
      "name": "Nukus",
      "names": {
        "zh": "努库斯"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 42.4647,
        "lng": 59.6022
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 1979,
      "name": "Kaech\u0027on",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 39.6986,
        "lng": 125.9061
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 1981,
      "name": "Tehuacan",
      "names": {
        "es": "Tehuacán",
        "zh": "特瓦坎"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.4617,
        "lng": -97.3928
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1968,
      "name": "Timisoara",
      "names": {
        "zh": "蒂米什瓦拉",
        "ja": "ティミショアラ"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.7597,
        "lng": 21.23
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 1984,
      "name": "Huntsville",
      "names": {
        "zh": "亨茨维尔",
        "ja": "ハンツヴィル",
        "th": "ฮันต์สวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.6988,
        "lng": -86.6412
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 1982,
      "name": "Coatzacoalcos",
      "names": {
        "zh": "夸察夸尔科斯",
        "ja": "コアツァコアルコス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.15,
        "lng": -94.4333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 1985,
      "name": "Oskemen",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 49.99,
        "lng": 82.6149
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 1987,
      "name": "Zalantun",
      "names": {
        "zh": "扎兰屯"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.0033,
        "lng": 122.7365
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1971,
      "name": "Cherepovets",
      "names": {
        "zh": "切列波韦茨"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.1333,
        "lng": 37.9167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 1944,
      "name": "Tepic",
      "names": {
        "zh": "特皮克"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.5083,
        "lng": -104.8931
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 1975,
      "name": "Katsina",
      "names": {
        "zh": "卡齐纳",
        "th": "คัตซินา"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 12.9889,
        "lng": 7.6008
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 1939,
      "name": "Gwangmyeongni",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4772,
        "lng": 126.8664
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 1977,
      "name": "Poltava",
      "names": {
        "zh": "波尔塔瓦"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.5744,
        "lng": 34.5686
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 752,
      "name": "Port Moresby",
      "names": {
        "zh": "港口港口",
        "ja": "ポートモレスビー",
        "th": "พอร์ตมอร์สบี้",
        "pt": "Porto morres"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -9.4789,
        "lng": 147.1494
      },
      "country": "Papua New Guinea",
      "importance": 0
    },
    {
      "id": 1993,
      "name": "Boaco",
      "names": {
        "zh": "博阿科"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.4667,
        "lng": -85.6667
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 1991,
      "name": "Munster",
      "names": {
        "zh": "明斯特",
        "de": "Münster",
        "ja": "ミュンスター",
        "th": "มอนสเตอร์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.9625,
        "lng": 7.6256
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 1990,
      "name": "Cirebon",
      "names": {
        "zh": "井里汶"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.7167,
        "lng": 108.5667
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 1954,
      "name": "Naha",
      "names": {
        "zh": "那霸",
        "ja": "那覇",
        "th": "นะฮะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 26.2122,
        "lng": 127.6789
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 1891,
      "name": "Al Fayyum",
      "names": {
        "ja": "アルファイム",
        "th": "อัลฟียา"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 29.3,
        "lng": 30.8333
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 1988,
      "name": "Trichur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.52,
        "lng": 76.21
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1998,
      "name": "Mobile",
      "names": {
        "es": "Móvil",
        "fr": "Portable",
        "zh": "移动",
        "ja": "モバイル",
        "th": "โทรศัพท์มือถือ",
        "pt": "Móvel"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.6783,
        "lng": -88.1162
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2001,
      "name": "Bor",
      "names": {
        "zh": "博尔",
        "th": "บ่อ"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 6.2167,
        "lng": 31.55
      },
      "country": "South Sudan",
      "importance": 1
    },
    {
      "id": 1994,
      "name": "Ca Mau",
      "names": {
        "th": "แคลิฟอร์เนีย"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 9.1833,
        "lng": 105.15
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 1900,
      "name": "Thessaloniki",
      "names": {
        "es": "Salónica",
        "fr": "Thessalonique",
        "zh": "塞萨洛尼基",
        "it": "Salonicco",
        "ja": "テッサロニキ",
        "th": "เทสซาโล"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.6403,
        "lng": 22.9356
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 1972,
      "name": "San Miguelito",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 9.033,
        "lng": -79.5
      },
      "country": "Panama",
      "importance": 2
    },
    {
      "id": 1997,
      "name": "Saransk",
      "names": {
        "zh": "萨兰斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.1833,
        "lng": 45.1833
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2002,
      "name": "Kasur",
      "names": {
        "zh": "卡苏尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.1167,
        "lng": 74.45
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 1999,
      "name": "Barddhaman",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.25,
        "lng": 87.85
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 1986,
      "name": "Nantes",
      "names": {
        "zh": "南特",
        "ja": "ナント",
        "th": "นองต์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.2181,
        "lng": -1.5528
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 2003,
      "name": "Yakou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.2937,
        "lng": 113.5203
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 1989,
      "name": "Binangonan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4514,
        "lng": 121.1919
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 1962,
      "name": "Temara",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.9234,
        "lng": -6.9076
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 1996,
      "name": "Vologda",
      "names": {
        "zh": "沃洛格达"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.2167,
        "lng": 39.9
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2013,
      "name": "Fort Collins",
      "names": {
        "es": "Collins",
        "zh": "柯林斯堡",
        "de": "Fortkoffer",
        "ja": "フォートコリンズ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5478,
        "lng": -105.0656
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2012,
      "name": "Zarzal",
      "names": {
        "zh": "萨尔萨尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.3942,
        "lng": -76.0703
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 2015,
      "name": "Asheville",
      "names": {
        "zh": "阿什维尔",
        "ja": "アッシュビル",
        "th": "แอชวิล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.5704,
        "lng": -82.5536
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2016,
      "name": "Santa Clarita",
      "names": {
        "zh": "圣克莱丽塔",
        "ja": "サンタクラリタ",
        "th": "ซานตาคลาริต้า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.4175,
        "lng": -118.4964
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2014,
      "name": "Port Elizabeth",
      "names": {
        "zh": "伊丽莎白港",
        "it": "Porto Elizabeth",
        "ja": "ポートエリザベス",
        "th": "พอร์ตเอลิซาเบ ธ"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.9581,
        "lng": 25.6
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 2017,
      "name": "Gorgan",
      "names": {
        "zh": "戈尔甘"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.83,
        "lng": 54.48
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 2000,
      "name": "Tanza",
      "names": {
        "zh": "坦扎"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3944,
        "lng": 120.8531
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2021,
      "name": "Yakutsk",
      "names": {
        "zh": "雅库茨克",
        "ja": "ヤクーツク",
        "th": "ยาคุตสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 62.0272,
        "lng": 129.7319
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2004,
      "name": "Orel",
      "names": {
        "zh": "奥廖尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.9686,
        "lng": 36.0694
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2023,
      "name": "Ponta Grossa",
      "names": {
        "ja": "ポンタグロッサ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.095,
        "lng": -50.1619
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2024,
      "name": "Catania",
      "names": {
        "es": "De Catania",
        "fr": "Catane",
        "zh": "卡塔尼亚",
        "ja": "カターニア",
        "th": "กาตาเนีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.5027,
        "lng": 15.0873
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 2008,
      "name": "Guaruja",
      "names": {
        "th": "กูอารูจา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.9936,
        "lng": -46.2564
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2018,
      "name": "Quy Nhon",
      "names": {
        "es": "Query nhon"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 13.7696,
        "lng": 109.2314
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2011,
      "name": "Datang",
      "names": {
        "zh": "大唐",
        "th": "ต้อนรับ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9481,
        "lng": 113.9276
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2019,
      "name": "Sambalpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.4704,
        "lng": 83.9701
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2010,
      "name": "Purnea",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.78,
        "lng": 87.47
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2022,
      "name": "Yokkaichi",
      "names": {
        "zh": "四日市",
        "ja": "四日市"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9667,
        "lng": 136.6167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2025,
      "name": "Chalco",
      "names": {
        "zh": "中国铝业"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2647,
        "lng": -98.8975
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2020,
      "name": "Mannheim",
      "names": {
        "zh": "曼海姆",
        "ja": "マンハイム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4878,
        "lng": 8.4661
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2027,
      "name": "Shahriar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.6597,
        "lng": 51.0592
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2005,
      "name": "Shahr-e Qods",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7214,
        "lng": 51.1089
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2031,
      "name": "St. Catharines",
      "names": {
        "zh": "圣柳树",
        "de": "St. Katharines",
        "it": "San Catarro",
        "ja": "セントキャサリンズ",
        "th": "เซนต์แคธารี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.1833,
        "lng": -79.2333
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2028,
      "name": "Kurgan",
      "names": {
        "zh": "库尔干"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.4408,
        "lng": 65.3411
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2033,
      "name": "Niagara Falls",
      "names": {
        "es": "Cataratas del Niágara",
        "fr": "Chutes du Niagara",
        "zh": "尼亚加拉瀑布",
        "de": "Niagarafälle",
        "it": "Cascate del Niagara",
        "id": "Air terjun niagara",
        "ja": "ナイアガラの滝",
        "pt": "Cataratas do Niágara"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.06,
        "lng": -79.1067
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2006,
      "name": "Safi",
      "names": {
        "zh": "萨菲",
        "ja": "サフィ",
        "th": "โครก"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.2833,
        "lng": -9.2333
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 2026,
      "name": "Karlsruhe",
      "names": {
        "zh": "卡尔斯鲁厄",
        "ja": "カールスルーエ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.0167,
        "lng": 8.4
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2035,
      "name": "St. Paul",
      "names": {
        "es": "San Pablo",
        "fr": "Saint-Paul",
        "zh": "圣保罗",
        "ja": "セントポール",
        "th": "เซนต์พอล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.9477,
        "lng": -93.104
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 1913,
      "name": "Nakuru",
      "names": {
        "zh": "纳库鲁"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.2833,
        "lng": 36.0667
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 2030,
      "name": "Sariwon",
      "names": {
        "zh": "沙里院"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 38.5039,
        "lng": 125.7589
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 2039,
      "name": "Puerto Princesa",
      "names": {
        "zh": "Puerto Printesa",
        "ja": "プエルトプリンセサ",
        "th": "เปอร์โตปรินเซซา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.75,
        "lng": 118.75
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2037,
      "name": "Hisar",
      "names": {
        "zh": "希萨尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 29.1489,
        "lng": 75.7367
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2036,
      "name": "Vladikavkaz",
      "names": {
        "zh": "弗拉季高加索"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.04,
        "lng": 44.6775
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2029,
      "name": "Kasugai",
      "names": {
        "zh": "春日井"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2477,
        "lng": 136.9724
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2034,
      "name": "Firozabad",
      "names": {
        "zh": "菲罗扎巴德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.15,
        "lng": 78.3949
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2043,
      "name": "Ciudad Santa Catarina",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.6833,
        "lng": -100.45
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2038,
      "name": "Puerto La Cruz",
      "names": {
        "zh": "波多黎各克鲁兹",
        "ja": "プエルトラルス",
        "th": "เปอร์โตลาครูซ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2167,
        "lng": -64.6167
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2044,
      "name": "Vaughan",
      "names": {
        "zh": "沃恩",
        "ja": "ヴォーン",
        "th": "วอฮ์น"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.8333,
        "lng": -79.5
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2032,
      "name": "Matadi",
      "names": {
        "zh": "马塔迪瀑布"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.8167,
        "lng": 13.4833
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 2042,
      "name": "Ciudad Victoria",
      "names": {
        "ja": "シウダードビクトリア"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.7333,
        "lng": -99.1333
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 2009,
      "name": "Playa del Carmen",
      "names": {
        "ja": "プラヤデルカルメン"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6275,
        "lng": -87.0811
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2054,
      "name": "Akita",
      "names": {
        "zh": "秋田",
        "ja": "秋田",
        "th": "อาคิตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 39.7197,
        "lng": 140.1025
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2048,
      "name": "Kurume",
      "names": {
        "zh": "久留米",
        "ja": "久留米"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.3167,
        "lng": 130.5167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2059,
      "name": "Antioch",
      "names": {
        "es": "Antioquía",
        "fr": "Antioche",
        "zh": "安提阿",
        "de": "Antiochien",
        "it": "Antiochia",
        "id": "Antiokhia",
        "ja": "アンティオキア",
        "th": "ออค",
        "pt": "Antioquia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9789,
        "lng": -121.7958
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2058,
      "name": "Taubate",
      "names": {
        "zh": "陶巴特"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.0333,
        "lng": -45.55
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2057,
      "name": "Iksan",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.9439,
        "lng": 126.9544
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 2049,
      "name": "Vila Nova de Gaia",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1333,
        "lng": -8.6167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 2060,
      "name": "Lakeland",
      "names": {
        "zh": "莱克兰",
        "ja": "レイクランド",
        "th": "เลกแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.0556,
        "lng": -81.9545
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2055,
      "name": "Awka",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 6.2,
        "lng": 7.0667
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 2056,
      "name": "San Bernardo",
      "names": {
        "zh": "圣伯纳多",
        "ja": "サンベルナルド",
        "th": "ซานเบอร์นาร์โด"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.6,
        "lng": -70.7167
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 2064,
      "name": "San Juan del Rio",
      "names": {
        "zh": "圣胡安德尔里奥",
        "ja": "サンファンデルリオ",
        "th": "ซานฮวนเดลริโอ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3833,
        "lng": -99.9833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2053,
      "name": "Armenia",
      "names": {
        "fr": "L\u0027Arménie",
        "zh": "亚美尼亚",
        "de": "Armenien",
        "ja": "アルメニア",
        "th": "อาร์เมเนีย",
        "pt": "Armênia"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5389,
        "lng": -75.6725
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 2067,
      "name": "Qianzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.3185,
        "lng": 109.7318
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2065,
      "name": "Popayan",
      "names": {
        "es": "Popayán",
        "zh": "波帕扬"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.4411,
        "lng": -76.6061
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 2051,
      "name": "Paulista",
      "names": {
        "zh": "圣保罗",
        "ja": "パウリスタ",
        "th": "พอลลิส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.9408,
        "lng": -34.8728
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2061,
      "name": "Mardan",
      "names": {
        "zh": "马尔丹"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.1958,
        "lng": 72.0447
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 2071,
      "name": "Quzhou",
      "names": {
        "zh": "衢州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.9545,
        "lng": 118.8763
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2068,
      "name": "Newcastle",
      "names": {
        "zh": "纽卡斯尔",
        "ja": "ニューカッスル",
        "th": "นิวคาสเซิล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.0077,
        "lng": -1.6578
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2045,
      "name": "Awasa",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 7.05,
        "lng": 38.4667
      },
      "country": "Ethiopia",
      "importance": 1
    },
    {
      "id": 2052,
      "name": "Oaxaca",
      "names": {
        "zh": "瓦哈卡",
        "ja": "オアハカ",
        "th": "โออาซากา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0606,
        "lng": -96.7253
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 753,
      "name": "Porto-Novo",
      "names": {
        "zh": "波多诺伏",
        "ja": "ポルトノボ",
        "th": "ปอร์โต-โนโว"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.4833,
        "lng": 2.6167
      },
      "country": "Benin",
      "importance": 0
    },
    {
      "id": 754,
      "name": "Sucre",
      "names": {
        "zh": "苏克雷",
        "ja": "スクレ",
        "th": "ซูเกร"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -19.0431,
        "lng": -65.2592
      },
      "country": "Bolivia",
      "importance": 0
    },
    {
      "id": 2062,
      "name": "Soc Trang",
      "names": {
        "id": "Trang SoC",
        "th": "SoC ตรัง"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 9.6,
        "lng": 105.9719
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2070,
      "name": "Coatepeque",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7,
        "lng": -91.8667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 2074,
      "name": "Merida",
      "names": {
        "es": "Mérida",
        "zh": "梅里达",
        "ja": "メリダ",
        "th": "เมริดา"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.5833,
        "lng": -71.1333
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 2050,
      "name": "Curepipe",
      "names": {
        "zh": "居尔皮普"
      },
      "countryCode": "MU",
      "latLng": {
        "lat": -20.3162,
        "lng": 57.5166
      },
      "country": "Mauritius",
      "importance": 3
    },
    {
      "id": 2076,
      "name": "Ar Raqqah",
      "names": {
        "zh": "arraqqah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.95,
        "lng": 39.01
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 2046,
      "name": "Oakashicho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6431,
        "lng": 134.9975
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2040,
      "name": "Podolsk",
      "names": {
        "zh": "波多利斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.4311,
        "lng": 37.5456
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2077,
      "name": "Linhai",
      "names": {
        "zh": "临海"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.8523,
        "lng": 121.1409
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2069,
      "name": "Ciudad General Escobedo",
      "names": {
        "zh": "ciudad一般escobedo",
        "id": "Ciudad Jenderal Escobedo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7933,
        "lng": -100.1583
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2066,
      "name": "Praia Grande",
      "names": {
        "zh": "普拉亚格兰德",
        "id": "Pujian",
        "ja": "プライアグランデ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.0058,
        "lng": -46.4028
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2080,
      "name": "Bahia Blanca",
      "names": {
        "ja": "バイアブランカ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.7167,
        "lng": -62.2667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 2078,
      "name": "Jember",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -8.1727,
        "lng": 113.6873
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 2084,
      "name": "Al Mubarraz",
      "names": {
        "ja": "アルマラズ",
        "th": "อัล mubarraz"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 25.4416,
        "lng": 49.6642
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 2086,
      "name": "Al Kharj",
      "names": {
        "ja": "アルカルジ",
        "th": "อัล kharj"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 24.1556,
        "lng": 47.312
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 2047,
      "name": "Pekalongan",
      "names": {
        "zh": "北加浪岸"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.8883,
        "lng": 109.6753
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2085,
      "name": "Petropolis",
      "names": {
        "es": "Petrópolis",
        "zh": "彼得罗波利斯",
        "pt": "Petrópolis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.505,
        "lng": -43.1789
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2075,
      "name": "Murmansk",
      "names": {
        "fr": "Mourmansk",
        "zh": "摩尔曼斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 68.9667,
        "lng": 33.0833
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 1995,
      "name": "Toshima",
      "names": {
        "es": "De toshima"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7333,
        "lng": 139.7167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2083,
      "name": "Iskenderun",
      "names": {
        "zh": "伊斯肯德伦"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.5804,
        "lng": 36.17
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 2091,
      "name": "Pachuca",
      "names": {
        "zh": "帕丘卡",
        "th": "ปาชูกา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1,
        "lng": -98.75
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 2094,
      "name": "Oyster Bay",
      "names": {
        "es": "Bahía de ostras",
        "fr": "Huîtrie",
        "zh": "牡蛎湾",
        "id": "Teluk Oyster",
        "ja": "オイスターベイ",
        "th": "อ่าวหอยนางรม",
        "pt": "Baía de ostras"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7845,
        "lng": -73.5139
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2079,
      "name": "Valladolid",
      "names": {
        "zh": "巴利亚多利德",
        "ja": "バリャドリッド",
        "th": "บายาโดลิด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.6528,
        "lng": -4.7236
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 2081,
      "name": "Central Coast",
      "names": {
        "es": "Costa central",
        "fr": "Côte centrale",
        "zh": "中央海岸",
        "de": "Zentrale Küste",
        "it": "Costa Centrale",
        "id": "Pantai pusat",
        "ja": "中央沿岸",
        "th": "ชายฝั่งตอนกลาง",
        "pt": "Costa central"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -33.3,
        "lng": 151.2
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 2073,
      "name": "Bialystok",
      "names": {
        "zh": "比亚韦斯托克"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1167,
        "lng": 23.1667
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 2089,
      "name": "Yangsan",
      "names": {
        "zh": "梁山"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.3386,
        "lng": 129.0386
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 2072,
      "name": "Tampico",
      "names": {
        "zh": "坦皮科",
        "ja": "タンピコ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.2553,
        "lng": -97.8686
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2096,
      "name": "Valparaiso",
      "names": {
        "es": "Valparaíso",
        "zh": "瓦尔帕莱索",
        "th": "บัลปาราอีโซ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.0458,
        "lng": -71.6197
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 2098,
      "name": "Limeira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5647,
        "lng": -47.4017
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2082,
      "name": "Rampur",
      "names": {
        "zh": "兰布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.788,
        "lng": 79.019
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2095,
      "name": "Tagum",
      "names": {
        "zh": "塔古姆"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.4478,
        "lng": 125.8078
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2090,
      "name": "Comilla",
      "names": {
        "zh": "库米拉"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 23.45,
        "lng": 91.2
      },
      "country": "Bangladesh",
      "importance": 3
    },
    {
      "id": 2092,
      "name": "Augsburg",
      "names": {
        "zh": "奥格斯堡",
        "ja": "アウクスブルク",
        "th": "ออกซ์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.3717,
        "lng": 10.8983
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2097,
      "name": "Silang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2306,
        "lng": 120.975
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2101,
      "name": "Kocaeli",
      "names": {
        "zh": "科贾埃利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.7656,
        "lng": 29.9406
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 2088,
      "name": "Chernihiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 51.4939,
        "lng": 31.2947
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2106,
      "name": "Vila Teixeira da Silva",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.1958,
        "lng": 15.8556
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 2099,
      "name": "Rangpur",
      "names": {
        "zh": "伦格布尔"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 25.75,
        "lng": 89.2444
      },
      "country": "Bangladesh",
      "importance": 1
    },
    {
      "id": 2100,
      "name": "Vigo",
      "names": {
        "zh": "维哥",
        "th": "วีโก้"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.2314,
        "lng": -8.7124
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2110,
      "name": "Aizawl",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.7333,
        "lng": 92.7167
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 2103,
      "name": "Bali",
      "names": {
        "zh": "巴厘岛",
        "ja": "バリ島",
        "th": "บาหลี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.65,
        "lng": 88.34
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2093,
      "name": "Bradford",
      "names": {
        "zh": "布拉德福德",
        "ja": "ブラッドフォード",
        "th": "แบรดฟอร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.8,
        "lng": -1.75
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2108,
      "name": "Mabalacat",
      "names": {
        "zh": "马巴拉卡特"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.2167,
        "lng": 120.5833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2105,
      "name": "Ismailia",
      "names": {
        "zh": "伊斯梅利亚"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.5833,
        "lng": 32.2667
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 2113,
      "name": "Thies",
      "names": {
        "zh": "蒂斯"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.7833,
        "lng": -16.9167
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 2104,
      "name": "Panipat",
      "names": {
        "zh": "巴尼伯德",
        "th": "พานิพัท"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 29.3833,
        "lng": 76.9667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2120,
      "name": "Santarem",
      "names": {
        "zh": "圣塔伦",
        "pt": "Santarém"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.4428,
        "lng": -54.7078
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2123,
      "name": "Resistencia",
      "names": {
        "zh": "抵抗"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.4514,
        "lng": -58.9867
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 2118,
      "name": "Groznyy",
      "names": {
        "zh": "格罗兹尼"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.3125,
        "lng": 45.6986
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2063,
      "name": "Mbeya",
      "names": {
        "zh": "姆贝亚"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -8.9,
        "lng": 33.45
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 2119,
      "name": "Kherson",
      "names": {
        "zh": "赫尔松",
        "de": "Cherson"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 46.6333,
        "lng": 32.6
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2125,
      "name": "Juliaca",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -15.4908,
        "lng": -70.1269
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 2121,
      "name": "Bafoussam",
      "names": {
        "zh": "巴富萨姆"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 5.4667,
        "lng": 10.4167
      },
      "country": "Cameroon",
      "importance": 1
    },
    {
      "id": 2124,
      "name": "Brasov",
      "names": {
        "zh": "布拉索夫",
        "ja": "ブラショフ"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.65,
        "lng": 25.6
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 2134,
      "name": "Trenton",
      "names": {
        "zh": "特伦顿",
        "ja": "トレントン",
        "th": "เทรนตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.2236,
        "lng": -74.7641
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 2135,
      "name": "Atyrau",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 47.1167,
        "lng": 51.8833
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 2109,
      "name": "Batna",
      "names": {
        "zh": "巴特纳"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.55,
        "lng": 6.1667
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 2132,
      "name": "Morioka",
      "names": {
        "zh": "盛冈",
        "ja": "盛岡",
        "th": "โมริโอกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 39.6833,
        "lng": 141.15
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2114,
      "name": "Iasi",
      "names": {
        "zh": "雅西"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.1622,
        "lng": 27.5889
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 2112,
      "name": "Tambov",
      "names": {
        "zh": "坦波夫",
        "de": "Tambow"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.7167,
        "lng": 41.4333
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2102,
      "name": "Montpellier",
      "names": {
        "zh": "蒙彼利埃",
        "ja": "モンペリエ",
        "th": "มอนต์เพเลียร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6119,
        "lng": 3.8772
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 2136,
      "name": "Ipswich",
      "names": {
        "zh": "伊普斯维奇",
        "ja": "イプスウィッチ",
        "th": "อิปสวิช"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.0594,
        "lng": 1.1556
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2139,
      "name": "Hulin",
      "names": {
        "zh": "胡林"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.7671,
        "lng": 132.9646
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2122,
      "name": "Hong\u0027an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 47.21,
        "lng": 123.61
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2138,
      "name": "Adana",
      "names": {
        "zh": "阿达纳",
        "ja": "アダナ",
        "th": "อาดานา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0,
        "lng": 35.325
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 2128,
      "name": "Karimnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.4333,
        "lng": 79.15
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2117,
      "name": "Santa Maria",
      "names": {
        "zh": "圣玛丽亚",
        "ja": "サンタマリア",
        "th": "ซานตามาเรีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8183,
        "lng": 120.9563
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2140,
      "name": "Lincoln",
      "names": {
        "zh": "林肯",
        "ja": "リンカーン",
        "th": "ลิงคอล์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.809,
        "lng": -96.6788
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 2126,
      "name": "Graz",
      "names": {
        "zh": "格拉茨",
        "ja": "グラーツ",
        "th": "กราซ"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.0708,
        "lng": 15.4386
      },
      "country": "Austria",
      "importance": 1
    },
    {
      "id": 2141,
      "name": "Bhuj",
      "names": {
        "zh": "普杰"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.2504,
        "lng": 69.81
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2115,
      "name": "Pendik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8775,
        "lng": 29.2514
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 2116,
      "name": "Nottingham",
      "names": {
        "zh": "诺丁汉",
        "ja": "ノッティンガム",
        "th": "น็อตติงแฮม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.9533,
        "lng": -1.15
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2142,
      "name": "Farg`ona",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.3864,
        "lng": 71.7864
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 2137,
      "name": "Kaunas",
      "names": {
        "zh": "考纳斯",
        "ja": "カウナス",
        "th": "เคานาส"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 54.9,
        "lng": 23.9333
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 2133,
      "name": "Setif",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.19,
        "lng": 5.41
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 2145,
      "name": "Springfield",
      "names": {
        "zh": "斯普林菲尔德",
        "ja": "スプリングフィールド",
        "th": "สปริงฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.1943,
        "lng": -93.2916
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 755,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 9.9333,
        "lng": -84.0833
      },
      "country": "Costa Rica",
      "importance": 0
    },
    {
      "id": 2146,
      "name": "Anchorage",
      "names": {
        "zh": "安克雷奇",
        "ja": "アンカレッジ",
        "th": "ทอดสมอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 61.1508,
        "lng": -149.1091
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2129,
      "name": "San Lorenzo",
      "names": {
        "zh": "圣洛伦佐",
        "ja": "サンロレンツォ",
        "th": "ซานลอเรนโซ"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.3431,
        "lng": -57.5094
      },
      "country": "Paraguay",
      "importance": 3
    },
    {
      "id": 2149,
      "name": "Plano",
      "names": {
        "zh": "普莱诺",
        "ja": "プラノ",
        "th": "พลาโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0502,
        "lng": -96.7487
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2143,
      "name": "Ichalkaranji",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.7,
        "lng": 74.47
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2147,
      "name": "Punto Fijo",
      "names": {
        "ja": "プントフィジョー"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 11.7167,
        "lng": -70.1833
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2151,
      "name": "Irvine",
      "names": {
        "zh": "欧文",
        "ja": "アーバイン",
        "th": "เออร์ไวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6772,
        "lng": -117.7738
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2152,
      "name": "Camacari",
      "names": {
        "zh": "卡马萨里",
        "pt": "Camaçari"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.6978,
        "lng": -38.3239
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2041,
      "name": "Meguro",
      "names": {
        "zh": "目黑",
        "ja": "目黒"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6333,
        "lng": 139.6833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 756,
      "name": "Ljubljana",
      "names": {
        "zh": "卢布尔雅那",
        "it": "Lubiana",
        "ja": "リュブリャナ",
        "th": "ลูบลิยานา"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.05,
        "lng": 14.5167
      },
      "country": "Slovenia",
      "importance": 0
    },
    {
      "id": 2148,
      "name": "Sincelejo",
      "names": {
        "zh": "辛塞莱霍"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2994,
        "lng": -75.3958
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 2131,
      "name": "Sekondi",
      "names": {
        "zh": "塞康第"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 4.9433,
        "lng": -1.704
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 2162,
      "name": "Tacna",
      "names": {
        "zh": "塔克纳",
        "ja": "タクナ"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -18.0556,
        "lng": -70.2483
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 2160,
      "name": "Cascavel",
      "names": {
        "zh": "卡斯卡韦尔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.9558,
        "lng": -53.455
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2157,
      "name": "Korhogo",
      "names": {
        "zh": "科霍戈"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 9.4578,
        "lng": -5.6294
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 2158,
      "name": "Hospet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.2667,
        "lng": 76.4
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2153,
      "name": "San Cristobal",
      "names": {
        "es": "San Cristóbal",
        "ja": "サンクリストバル"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 7.7682,
        "lng": -72.2322
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 2159,
      "name": "Bhatinda",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 30.2133,
        "lng": 74.9519
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2111,
      "name": "Kunp\u0027o",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.3675,
        "lng": 126.9469
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 2154,
      "name": "Bolton",
      "names": {
        "zh": "博尔顿",
        "ja": "ボルトン",
        "th": "โบลตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.578,
        "lng": -2.429
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2165,
      "name": "Davenport",
      "names": {
        "zh": "达文波特",
        "ja": "ダヴェンポート",
        "th": "ดาเวน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5563,
        "lng": -90.6052
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2155,
      "name": "San Pablo",
      "names": {
        "zh": "圣巴勃罗",
        "ja": "サンパブロ",
        "th": "ซานปาโบล"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.07,
        "lng": 121.325
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2156,
      "name": "Suzano",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5428,
        "lng": -46.3108
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 1992,
      "name": "Az Zaqaziq",
      "names": {
        "ja": "zaqaziq"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.5833,
        "lng": 31.5167
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 2161,
      "name": "Ostrava",
      "names": {
        "zh": "奥斯特拉瓦",
        "ja": "オストラヴァ",
        "th": "ออสตราวา"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.8356,
        "lng": 18.2925
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 2144,
      "name": "Strasbourg",
      "names": {
        "es": "Estrasburgo",
        "zh": "斯特拉斯堡",
        "de": "Straßburg",
        "it": "Strasburgo",
        "ja": "ストラスブール",
        "th": "สตราสบูร์ก",
        "pt": "Estrasburgo"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5833,
        "lng": 7.7458
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 2167,
      "name": "Barinas",
      "names": {
        "zh": "巴里纳斯"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.6226,
        "lng": -70.2075
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 2166,
      "name": "Fukushima",
      "names": {
        "zh": "福岛",
        "ja": "福島",
        "th": "ฟุกุชิมะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.7608,
        "lng": 140.4733
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2168,
      "name": "Coro",
      "names": {
        "zh": "冠状",
        "ja": "コロ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 11.395,
        "lng": -69.6816
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 2169,
      "name": "Bago",
      "names": {
        "zh": "巴格"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 17.3433,
        "lng": 96.4981
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 2107,
      "name": "Delmas",
      "names": {
        "zh": "戴尔马斯"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.55,
        "lng": -72.3
      },
      "country": "Haiti",
      "importance": 3
    },
    {
      "id": 2170,
      "name": "Fuquan",
      "names": {
        "zh": "福泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.7039,
        "lng": 107.5087
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2163,
      "name": "Constanta",
      "names": {
        "es": "Constanza",
        "zh": "康斯坦察",
        "ja": "コンスタンツァ"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.1733,
        "lng": 28.6383
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 2150,
      "name": "Ibaraki",
      "names": {
        "zh": "茨城",
        "ja": "茨城",
        "th": "จังหวัดอิบารากิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8164,
        "lng": 135.5686
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2172,
      "name": "Sannai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.16,
        "lng": 80.83
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2173,
      "name": "Huozhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.5726,
        "lng": 111.7176
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2176,
      "name": "Rockford",
      "names": {
        "zh": "罗克福德",
        "ja": "ロックフォード",
        "th": "ฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2597,
        "lng": -89.0641
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2174,
      "name": "Temuco",
      "names": {
        "zh": "特木科",
        "th": "เตมูโก"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -38.7399,
        "lng": -72.5901
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 2178,
      "name": "Ica",
      "names": {
        "zh": "颈内动脉",
        "ja": "のICA"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -14.0667,
        "lng": -75.7333
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 2171,
      "name": "Tongchuanshi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.08,
        "lng": 109.03
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2181,
      "name": "Newark",
      "names": {
        "zh": "纽瓦克",
        "ja": "ニューアーク",
        "th": "นวร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7245,
        "lng": -74.1725
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2180,
      "name": "Osh",
      "names": {
        "fr": "SST",
        "zh": "奥什",
        "de": "Osch"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 40.5333,
        "lng": 72.7833
      },
      "country": "Kyrgyzstan",
      "importance": 1
    },
    {
      "id": 2182,
      "name": "Jining",
      "names": {
        "zh": "济宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.03,
        "lng": 113.08
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2183,
      "name": "Chuncheon",
      "names": {
        "zh": "春川"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.8747,
        "lng": 127.7342
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 2164,
      "name": "Haifa",
      "names": {
        "fr": "Haïfa",
        "zh": "海法",
        "ja": "ハイファ",
        "th": "ไฮฟา"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.8,
        "lng": 34.9833
      },
      "country": "Israel",
      "importance": 1
    },
    {
      "id": 2184,
      "name": "Malard",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.6658,
        "lng": 50.9767
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2188,
      "name": "South Bend",
      "names": {
        "es": "Curva sur",
        "fr": "Se plier au sud",
        "zh": "南弯",
        "it": "Sudend",
        "id": "Tikungan selatan",
        "ja": "サウスベンド",
        "th": "โค้งใต้",
        "pt": "Curva sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6767,
        "lng": -86.2696
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2175,
      "name": "Sterlitamak",
      "names": {
        "zh": "斯捷尔利塔马克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.6333,
        "lng": 55.95
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2189,
      "name": "Bukhara",
      "names": {
        "fr": "Boukhara",
        "zh": "布哈拉",
        "ja": "ブハラ"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.7747,
        "lng": 64.4286
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 2190,
      "name": "Shreveport",
      "names": {
        "zh": "什里夫波特",
        "ja": "シュリーブポート",
        "th": "ช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.4656,
        "lng": -93.7956
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2191,
      "name": "Sumbe",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -11.2052,
        "lng": 13.8417
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 2199,
      "name": "Round Lake Beach",
      "names": {
        "fr": "Plage du lac rond",
        "zh": "圆湖海滩",
        "de": "Runder Strander Strand",
        "it": "Beach Round Lake",
        "ja": "ラウンドレイクビーチ",
        "th": "ชายหาดรอบทะเลสาบ",
        "pt": "Praia redonda do lago"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.379,
        "lng": -88.0811
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2179,
      "name": "Lucena",
      "names": {
        "zh": "卢塞纳"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9333,
        "lng": 121.6167
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2198,
      "name": "Zhuji",
      "names": {
        "zh": "诸暨市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.7169,
        "lng": 120.2314
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2186,
      "name": "Long Xuyen",
      "names": {
        "es": "Larga xuyen",
        "zh": "龙旭树",
        "it": "Lungo Xuyen",
        "id": "Panjang xuyen",
        "ja": "長いXuyen",
        "th": "ลอง xuyen"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.3686,
        "lng": 105.4234
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2193,
      "name": "Wiesbaden",
      "names": {
        "zh": "威斯巴登",
        "ja": "ウィスバーデン",
        "th": "วีสบาเดิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.0825,
        "lng": 8.24
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 2187,
      "name": "Petrozavodsk",
      "names": {
        "zh": "彼得罗扎沃茨克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 61.7833,
        "lng": 34.35
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2200,
      "name": "Gyeongsan",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.8167,
        "lng": 128.7333
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 2196,
      "name": "Barasat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.72,
        "lng": 88.48
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2202,
      "name": "Governador Valadares",
      "names": {
        "es": "Gobernador valadares",
        "fr": "Gouvernadeur Valadares",
        "zh": "治理阀门",
        "it": "Governador Valaranes",
        "id": "Valadares Gubernur",
        "ja": "ガバダルバラダール",
        "th": "หน่วยวาจา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.8508,
        "lng": -41.9489
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2185,
      "name": "Pematangsiantar",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 2.96,
        "lng": 99.06
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2206,
      "name": "Shaowu",
      "names": {
        "zh": "邵武"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.3417,
        "lng": 117.4869
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2208,
      "name": "Nizhnevartovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 60.9389,
        "lng": 76.595
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2197,
      "name": "Kostroma",
      "names": {
        "zh": "科斯特罗马"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.7667,
        "lng": 40.9333
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2192,
      "name": "Viet Tri",
      "names": {
        "zh": "越来越",
        "th": "เวียตไตร"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.3136,
        "lng": 105.3947
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2195,
      "name": "Sunderland",
      "names": {
        "zh": "桑德兰",
        "ja": "サンダーランド",
        "th": "ซันเดอร์แลนด์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.906,
        "lng": -1.381
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2210,
      "name": "Dire Dawa",
      "names": {
        "de": "Dicke Dawa",
        "id": "Dawa Dawa",
        "th": "ด้วงดาวะ"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 9.5833,
        "lng": 41.8667
      },
      "country": "Ethiopia",
      "importance": 1
    },
    {
      "id": 2127,
      "name": "Itagui",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1726,
        "lng": -75.6096
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 2212,
      "name": "Owo",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.1664,
        "lng": 5.58
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 2205,
      "name": "Katowice",
      "names": {
        "zh": "卡托维兹",
        "ja": "カトヴィツェ",
        "th": "คาโตวีตเซ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.25,
        "lng": 19.0
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 2194,
      "name": "Cherkasy",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4444,
        "lng": 32.0597
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2214,
      "name": "Savannah",
      "names": {
        "zh": "萨凡纳",
        "ja": "サバンナ",
        "th": "สะวันนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.0281,
        "lng": -81.1784
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2216,
      "name": "Gatineau",
      "names": {
        "zh": "加蒂诺",
        "th": "แกติโน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.4833,
        "lng": -75.65
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2217,
      "name": "Windsor",
      "names": {
        "zh": "温莎",
        "ja": "ウィンザー",
        "th": "วินด์เซอร์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.2833,
        "lng": -83.0
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2215,
      "name": "Posadas",
      "names": {
        "zh": "波萨达斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.3667,
        "lng": -55.8969
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 2177,
      "name": "Tegal",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.8667,
        "lng": 109.1333
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2219,
      "name": "Myrtle Beach",
      "names": {
        "es": "Playa Myrtle",
        "zh": "默特尔海滩",
        "id": "Pantai Myrtle",
        "ja": "マートルビーチ",
        "th": "หาดไมร์เทิล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7096,
        "lng": -78.8843
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 757,
      "name": "Sarajevo",
      "names": {
        "zh": "萨拉热窝",
        "ja": "サラエボ",
        "th": "ซาราเยโว"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.8563,
        "lng": 18.4132
      },
      "country": "Bosnia And Herzegovina",
      "importance": 0
    },
    {
      "id": 2221,
      "name": "Kunsan",
      "names": {
        "zh": "群山"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 35.9786,
        "lng": 126.7114
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 2218,
      "name": "Mbandaka",
      "names": {
        "zh": "姆班达卡"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 0.0486,
        "lng": 18.2603
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 2222,
      "name": "Qarshi",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 38.8667,
        "lng": 65.8
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 2224,
      "name": "Chula Vista",
      "names": {
        "ja": "チュラビスタ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.6281,
        "lng": -117.0145
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2209,
      "name": "Linxia Chengguanzhen",
      "names": {
        "zh": "临夏成庄镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6,
        "lng": 103.2167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 758,
      "name": "Nassau",
      "names": {
        "zh": "拿骚",
        "ja": "ナッソー",
        "th": "นัสเซา"
      },
      "countryCode": "BS",
      "latLng": {
        "lat": 25.0667,
        "lng": -77.3333
      },
      "country": "The Bahamas",
      "importance": 0
    },
    {
      "id": 2211,
      "name": "Khmelnytskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.42,
        "lng": 27.0
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2226,
      "name": "Ratlam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.3167,
        "lng": 75.0667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2228,
      "name": "Crato",
      "names": {
        "zh": "克拉图"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.4639,
        "lng": -63.04
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2227,
      "name": "Yeosu",
      "names": {
        "zh": "丽水"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 34.7607,
        "lng": 127.6622
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 2230,
      "name": "Eugene",
      "names": {
        "zh": "尤金",
        "ja": "ユージーン",
        "th": "ยูจีน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0563,
        "lng": -123.1173
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2204,
      "name": "San Mateo",
      "names": {
        "zh": "圣马特奥",
        "ja": "サンマテオ",
        "th": "ซานมาเทโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6969,
        "lng": 121.1219
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2229,
      "name": "Tsu",
      "names": {
        "zh": "富士通"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7186,
        "lng": 136.5056
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2007,
      "name": "Gungoren",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.035,
        "lng": 28.8575
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 2207,
      "name": "Sfax",
      "names": {
        "zh": "斯法克斯"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 34.74,
        "lng": 10.76
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 2201,
      "name": "Los Alcarrizos",
      "names": {
        "zh": "洛斯阿尔卡拉斯",
        "ja": "ロスアルカリゾス"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.5167,
        "lng": -70.0167
      },
      "country": "Dominican Republic",
      "importance": 3
    },
    {
      "id": 2232,
      "name": "Palmas",
      "names": {
        "zh": "帕尔马斯",
        "ja": "パルマス",
        "th": "ปาลมาส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.2128,
        "lng": -48.3603
      },
      "country": "Brazil",
      "importance": 1
    },
    {
      "id": 2236,
      "name": "Majene",
      "names": {
        "zh": "马杰内"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.5336,
        "lng": 118.966
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 2235,
      "name": "Sorong",
      "names": {
        "zh": "索龙"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.8667,
        "lng": 131.25
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2203,
      "name": "Taboao da Serra",
      "names": {
        "it": "Tabùo da serra",
        "pt": "Tabôao da serra"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6019,
        "lng": -46.7528
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2241,
      "name": "Oral",
      "names": {
        "fr": "Par voie orale",
        "zh": "口服",
        "it": "Orale",
        "id": "Lisan",
        "ja": "オーラル",
        "th": "ช่องปาก"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.2225,
        "lng": 51.3725
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 2240,
      "name": "Gabela",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -10.85,
        "lng": 14.3667
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 2242,
      "name": "Dayr az Zawr",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.3333,
        "lng": 40.15
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 2244,
      "name": "Sarta",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.5504,
        "lng": 53.1
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 2213,
      "name": "Southampton",
      "names": {
        "zh": "南安普敦",
        "ja": "サザンプトン",
        "th": "เซาแธมป์ตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.9025,
        "lng": -1.4042
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2246,
      "name": "Santo Domingo de los Colorados",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -0.2542,
        "lng": -79.1719
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 2130,
      "name": "Sumida",
      "names": {
        "zh": "墨田",
        "ja": "スミダ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7,
        "lng": 139.8167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2247,
      "name": "Novorossiysk",
      "names": {
        "zh": "新罗西斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.7167,
        "lng": 37.7667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2248,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.9833,
        "lng": -89.5333
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 2249,
      "name": "Gombe",
      "names": {
        "zh": "贡贝"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.2904,
        "lng": 11.17
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 2231,
      "name": "Bijiao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9311,
        "lng": 113.2018
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2251,
      "name": "Espoo",
      "names": {
        "zh": "埃斯波",
        "ja": "エスポー",
        "th": "เอสโป"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.21,
        "lng": 24.66
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 2254,
      "name": "Bamenda",
      "names": {
        "zh": "巴门达"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 5.9333,
        "lng": 10.1667
      },
      "country": "Cameroon",
      "importance": 1
    },
    {
      "id": 2233,
      "name": "Craiova",
      "names": {
        "zh": "克拉约瓦"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.3333,
        "lng": 23.8167
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 2258,
      "name": "Canton",
      "names": {
        "es": "Cantón",
        "zh": "广州",
        "de": "Kanton",
        "it": "Cantone",
        "ja": "広東",
        "th": "ตำบล",
        "pt": "Cantão"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8075,
        "lng": -81.3677
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2220,
      "name": "Malmo",
      "names": {
        "fr": "Malmö",
        "zh": "马尔默",
        "de": "Malmö",
        "ja": "マルメ",
        "th": "มัลโม"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 55.5833,
        "lng": 13.0333
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 2256,
      "name": "Handwara",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 34.4,
        "lng": 74.28
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2238,
      "name": "Binjai",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 3.6,
        "lng": 98.4853
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2250,
      "name": "Gijon",
      "names": {
        "es": "Gijón",
        "zh": "希洪",
        "id": "Gijón",
        "ja": "ヒホン",
        "th": "จิชง"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.5333,
        "lng": -5.7
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2252,
      "name": "Drug",
      "names": {
        "es": "Drogas",
        "fr": "Médicament",
        "zh": "药物",
        "it": "La droga",
        "id": "Obat",
        "ja": "薬剤",
        "th": "ยาเสพติด",
        "pt": "Drogas"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 21.19,
        "lng": 81.28
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2253,
      "name": "Mito",
      "names": {
        "zh": "有丝分裂",
        "ja": "水戸",
        "th": "โตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3667,
        "lng": 140.4667
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2243,
      "name": "Brahmanbaria",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 23.9667,
        "lng": 91.1
      },
      "country": "Bangladesh",
      "importance": 3
    },
    {
      "id": 2237,
      "name": "Thai Binh",
      "names": {
        "es": "Tailandés binh",
        "zh": "泰国宾馆",
        "id": "Binh Thailand",
        "ja": "タイビン",
        "th": "binh ไทย",
        "pt": "Binh tailandês"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.4461,
        "lng": 106.3422
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2262,
      "name": "Ichihara",
      "names": {
        "zh": "市原",
        "ja": "市原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4981,
        "lng": 140.1156
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2245,
      "name": "Plymouth",
      "names": {
        "zh": "普利茅斯",
        "ja": "プリマス",
        "th": "พลีมัธ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.3714,
        "lng": -4.1422
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2261,
      "name": "Aswan",
      "names": {
        "es": "Asuán",
        "fr": "Assouan",
        "zh": "阿斯旺",
        "de": "Assuan",
        "it": "Assuan",
        "ja": "アスワン",
        "th": "อัสวาน"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 24.0889,
        "lng": 32.8997
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 2268,
      "name": "Lubbock",
      "names": {
        "zh": "拉伯克",
        "ja": "ラボック",
        "th": "บ็อค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5659,
        "lng": -101.8878
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2269,
      "name": "Yanbu`",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 24.0943,
        "lng": 38.0493
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 2271,
      "name": "Tshikapa",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -6.4167,
        "lng": 20.8
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 2225,
      "name": "Maradi",
      "names": {
        "zh": "马拉迪"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 13.5,
        "lng": 7.1
      },
      "country": "Niger",
      "importance": 1
    },
    {
      "id": 2255,
      "name": "Floridablanca",
      "names": {
        "zh": "佛罗里达布兰卡"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0697,
        "lng": -73.0978
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 2272,
      "name": "Anantapur",
      "names": {
        "zh": "阿嫩达布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 14.6833,
        "lng": 77.6
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2274,
      "name": "Reading",
      "names": {
        "es": "Leyendo",
        "fr": "En train de lire",
        "zh": "阅读",
        "de": "Lesen",
        "it": "Lettura",
        "id": "Bacaan",
        "ja": "読み取り",
        "th": "การอ่าน",
        "pt": "Leitura"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.34,
        "lng": -75.9267
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2275,
      "name": "Winter Haven",
      "names": {
        "fr": "Havre d\u0027hiver",
        "zh": "冬天避风港",
        "de": "Winterhafen",
        "it": "Rifugio invernale",
        "id": "Surga musim dingin",
        "ja": "ウィンターヘイブン",
        "th": "สวรรค์",
        "pt": "Refúgio de inverno"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.0118,
        "lng": -81.7017
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2277,
      "name": "Maraba",
      "names": {
        "zh": "马拉巴",
        "pt": "Marabá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.3689,
        "lng": -49.1178
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2278,
      "name": "Salem",
      "names": {
        "zh": "塞勒姆",
        "ja": "セーラム",
        "th": "ซาเลม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.9231,
        "lng": -123.0246
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 2276,
      "name": "Myeik",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 12.4394,
        "lng": 98.6003
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 2259,
      "name": "Yoshkar-Ola",
      "names": {
        "zh": "约什卡尔奥拉"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.6328,
        "lng": 47.8958
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2265,
      "name": "Chernivtsi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2908,
        "lng": 25.9344
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2267,
      "name": "Sumare",
      "names": {
        "es": "Sumaré",
        "pt": "Sumaré"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8219,
        "lng": -47.2669
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2281,
      "name": "Djelfa",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.6667,
        "lng": 3.25
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 2270,
      "name": "Sumy",
      "names": {
        "zh": "苏梅"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.9068,
        "lng": 34.7992
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2282,
      "name": "Suncheon",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 34.9506,
        "lng": 127.4875
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 2284,
      "name": "St. Petersburg",
      "names": {
        "es": "San Petersburgo",
        "fr": "Saint-Pétersbourg",
        "zh": "圣彼得堡",
        "it": "San Pietroburgo",
        "ja": "サンクトペテルブルク",
        "th": "เซนต์ปีเตอร์สเบิร์ก",
        "pt": "São Petersburgo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.7931,
        "lng": -82.6652
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2260,
      "name": "Nalchik",
      "names": {
        "zh": "纳尔奇克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.4833,
        "lng": 43.6167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2285,
      "name": "Engels",
      "names": {
        "zh": "恩格斯",
        "de": "Duits",
        "ja": "エンゲルス",
        "th": "เองเงิลส์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.4667,
        "lng": 46.1167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2264,
      "name": "Imphal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.82,
        "lng": 93.95
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 2279,
      "name": "Yao",
      "names": {
        "zh": "姚明",
        "ja": "ヤオ",
        "th": "ยาว"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6269,
        "lng": 135.6008
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2289,
      "name": "Nogales",
      "names": {
        "zh": "诺加利斯",
        "ja": "ノガレス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.1833,
        "lng": -111.0
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 2290,
      "name": "Dezful",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.3878,
        "lng": 48.4033
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2288,
      "name": "Oruro",
      "names": {
        "zh": "奥鲁罗"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.9667,
        "lng": -67.1167
      },
      "country": "Bolivia",
      "importance": 3
    },
    {
      "id": 2263,
      "name": "Zhytomyr",
      "names": {
        "fr": "Jitomir"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.2544,
        "lng": 28.6578
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2292,
      "name": "Lafayette",
      "names": {
        "zh": "老佛爷",
        "ja": "ラファイエット",
        "th": "ลาฟาแยต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.2084,
        "lng": -92.0323
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2291,
      "name": "Sao Jose dos Pinhais",
      "names": {
        "zh": "圣何塞Dos Pinhais",
        "ja": "サンノゼノドスピナハイ",
        "pt": "São José dos Pinhais"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.535,
        "lng": -49.2058
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2293,
      "name": "Kyongju",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.85,
        "lng": 129.2167
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 2295,
      "name": "Dumai",
      "names": {
        "zh": "杜迈"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 1.6667,
        "lng": 101.45
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2294,
      "name": "Nagaoka",
      "names": {
        "zh": "长冈",
        "ja": "長岡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.4333,
        "lng": 138.8333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2296,
      "name": "Taluqan",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.7167,
        "lng": 69.5167
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 2287,
      "name": "Wenling",
      "names": {
        "zh": "温岭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.3797,
        "lng": 121.3718
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2283,
      "name": "Jalalabad",
      "names": {
        "zh": "贾拉拉巴德",
        "ja": "ジャラーラーバード",
        "th": "จาลาลาบัด"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.4303,
        "lng": 70.4528
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 2299,
      "name": "Nawabshah",
      "names": {
        "zh": "讷瓦布沙阿"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 26.2442,
        "lng": 68.41
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 2239,
      "name": "Fuchu",
      "names": {
        "zh": "府中",
        "ja": "府中"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6689,
        "lng": 139.4778
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2257,
      "name": "Talisay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.25,
        "lng": 123.8333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2300,
      "name": "Volta Redonda",
      "names": {
        "ja": "ボルタレドンダ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5231,
        "lng": -44.1042
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2223,
      "name": "Kafr ad Dawwar",
      "names": {
        "zh": "KAFR广告Dawwar",
        "it": "Kafr addabent dawwar",
        "th": "Kafr โฆษณา Dawwar"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.1339,
        "lng": 30.1297
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 2302,
      "name": "Laredo",
      "names": {
        "zh": "拉雷多",
        "th": "ลาเรโด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.5629,
        "lng": -99.4875
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2280,
      "name": "Barueri",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5111,
        "lng": -46.8764
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2297,
      "name": "Gent",
      "names": {
        "zh": "根特",
        "ja": "ゲント",
        "th": "สุภาพบุรุษ"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0536,
        "lng": 3.7253
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 2307,
      "name": "Jersey City",
      "names": {
        "es": "Ciudad de Jersey",
        "zh": "泽西市",
        "de": "Jersey-Stadt",
        "ja": "ジャージーシティ",
        "th": "เมืองเจอร์ซีย์",
        "pt": "Cidade de jersey"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7161,
        "lng": -74.0682
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2304,
      "name": "Wollongong",
      "names": {
        "zh": "卧龙岗",
        "th": "ลองกอง"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -34.4331,
        "lng": 150.8831
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 2311,
      "name": "Concord",
      "names": {
        "zh": "康科德",
        "it": "A Concord",
        "ja": "コンコルド",
        "th": "คองคอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.3933,
        "lng": -80.6366
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2316,
      "name": "Columbus",
      "names": {
        "zh": "哥伦布",
        "ja": "コロンバス",
        "th": "โคลัมบัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.51,
        "lng": -84.8771
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2309,
      "name": "Venice",
      "names": {
        "es": "Venecia",
        "fr": "Venise",
        "zh": "威尼斯",
        "de": "Venedig",
        "it": "Venezia",
        "ja": "ヴェネツィア",
        "th": "เวนิส",
        "pt": "Veneza"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4397,
        "lng": 12.3319
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 2286,
      "name": "Malolos",
      "names": {
        "zh": "马洛洛斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8433,
        "lng": 120.8114
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2318,
      "name": "Chandler",
      "names": {
        "zh": "钱德勒",
        "ja": "チャンドラー",
        "th": "เทียน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.2826,
        "lng": -111.8516
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2266,
      "name": "Heroica Nogales",
      "names": {
        "fr": "Héroïque nogales",
        "ja": "ヒロイカノガレス",
        "pt": "Heróica nogales"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.3186,
        "lng": -110.9458
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2313,
      "name": "Ciudad Benito Juarez",
      "names": {
        "es": "Ciudad Benito Juárez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.65,
        "lng": -100.0833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2303,
      "name": "Kakogawacho-honmachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7569,
        "lng": 134.8414
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2312,
      "name": "Olongapo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8333,
        "lng": 120.2833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2319,
      "name": "Fukui",
      "names": {
        "zh": "福井",
        "ja": "福井",
        "th": "ฟุกุอิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0641,
        "lng": 136.2196
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2310,
      "name": "Quang Ngai",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 15.1206,
        "lng": 108.7922
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2322,
      "name": "Kingston upon Hull",
      "names": {
        "es": "Kingston al casco",
        "fr": "Kingston sur Hull",
        "zh": "赫尔的金士顿",
        "de": "Kingston auf Rumpf",
        "it": "Kingston su scafo",
        "id": "Kingston atas Hull",
        "ja": "キングストンアッパーハル",
        "pt": "Kingston no casco"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.7444,
        "lng": -0.3325
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2323,
      "name": "Mossoro",
      "names": {
        "zh": "莫索罗",
        "pt": "Mossoró"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1878,
        "lng": -37.3439
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2321,
      "name": "Kunduz",
      "names": {
        "zh": "昆都士"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 36.728,
        "lng": 68.8725
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 2317,
      "name": "Monchengladbach",
      "names": {
        "es": "Mönchengladbach",
        "fr": "Mönchengladbach",
        "zh": "门兴格拉德巴赫",
        "de": "Mönchengladbach",
        "it": "Mönchengladbach",
        "id": "Mönchengladbach",
        "ja": "メンヒェングラットバッハ",
        "pt": "Mönchengladbach"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2,
        "lng": 6.4333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2314,
      "name": "Gelsenkirchen",
      "names": {
        "zh": "盖尔森基兴",
        "ja": "ゲルゼンキルヒェン",
        "th": "เกลเซนเคียร์เคิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5167,
        "lng": 7.1
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2325,
      "name": "Misratah",
      "names": {
        "th": "มิสราทาห์"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.3778,
        "lng": 15.0901
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 2330,
      "name": "McKinney",
      "names": {
        "zh": "麦金尼",
        "ja": "マッキニー"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.2015,
        "lng": -96.6669
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2329,
      "name": "Huayin",
      "names": {
        "zh": "华银"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.5664,
        "lng": 110.0866
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2234,
      "name": "Minato",
      "names": {
        "zh": "凑",
        "ja": "港区",
        "th": "มินาโตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6581,
        "lng": 139.7514
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2331,
      "name": "Scottsdale",
      "names": {
        "zh": "斯科茨代尔",
        "ja": "スコッツデール",
        "th": "สกอตส์เดล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6872,
        "lng": -111.8651
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2332,
      "name": "Killeen",
      "names": {
        "zh": "基林",
        "th": "คิลลีน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.0754,
        "lng": -97.7296
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2324,
      "name": "Wutong",
      "names": {
        "zh": "梧桐"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6326,
        "lng": 120.5474
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2315,
      "name": "Hiratsuka",
      "names": {
        "zh": "平冢",
        "ja": "平塚"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3231,
        "lng": 139.3422
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2334,
      "name": "Tallahassee",
      "names": {
        "zh": "塔拉哈西",
        "ja": "タラハシー",
        "th": "แทลลาแฮสซี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.4551,
        "lng": -84.2526
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 2328,
      "name": "Etawah",
      "names": {
        "zh": "埃塔瓦"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.7855,
        "lng": 79.015
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2301,
      "name": "Annaba",
      "names": {
        "zh": "安纳巴",
        "th": "อันนาบะ"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.9,
        "lng": 7.7667
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 2327,
      "name": "Verona",
      "names": {
        "fr": "Vérone",
        "zh": "维罗纳",
        "ja": "ヴェローナ",
        "th": "เวโรนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4386,
        "lng": 10.9928
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 2336,
      "name": "Antsirabe",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -19.8667,
        "lng": 47.0333
      },
      "country": "Madagascar",
      "importance": 2
    },
    {
      "id": 2333,
      "name": "Bergen",
      "names": {
        "zh": "卑尔根",
        "ja": "ベルゲン",
        "th": "เบอร์เกน"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.3925,
        "lng": 5.3233
      },
      "country": "Norway",
      "importance": 1
    },
    {
      "id": 2305,
      "name": "Bordeaux",
      "names": {
        "es": "Burdeos",
        "zh": "波尔多",
        "ja": "ボルドー",
        "th": "บอร์โด"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.84,
        "lng": -0.58
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 2337,
      "name": "Ondo",
      "names": {
        "zh": "音头",
        "ja": "オンド"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.0904,
        "lng": 4.84
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 2335,
      "name": "Horlivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.3336,
        "lng": 38.0925
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 2341,
      "name": "Peoria",
      "names": {
        "zh": "皮奥里亚",
        "ja": "ピオリア",
        "th": "พีโอเรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.752,
        "lng": -89.6153
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 759,
      "name": "Bloemfontein",
      "names": {
        "zh": "布隆方丹",
        "ja": "ブルームフォンテーン",
        "th": "บลูมฟอนเทน"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -29.1,
        "lng": 26.2167
      },
      "country": "South Africa",
      "importance": 0
    },
    {
      "id": 2339,
      "name": "Foz do Iguacu",
      "names": {
        "zh": "foz做iguacu",
        "ja": "フォスドイグアス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.54,
        "lng": -54.5875
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2338,
      "name": "Ap Da Loi",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 11.9304,
        "lng": 108.42
      },
      "country": "Vietnam",
      "importance": 3
    },
    {
      "id": 2345,
      "name": "Damaturu",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 11.7803,
        "lng": 11.9786
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 2306,
      "name": "Nonthaburi",
      "names": {
        "zh": "暖武里府",
        "th": "นนทบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8667,
        "lng": 100.5167
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 2342,
      "name": "Gravatai",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.9433,
        "lng": -50.9939
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2349,
      "name": "Wilmington",
      "names": {
        "zh": "威尔明顿",
        "ja": "ウィルミントン",
        "th": "วิลมิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.21,
        "lng": -77.8866
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2326,
      "name": "Derby",
      "names": {
        "zh": "德比",
        "ja": "ダービー",
        "th": "ดาร์บี้"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.9217,
        "lng": -1.4769
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2273,
      "name": "Westminster",
      "names": {
        "zh": "威斯敏斯特",
        "ja": "ウェストミンスター",
        "th": "เวสต์มินสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4947,
        "lng": -0.1353
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2350,
      "name": "Raichur",
      "names": {
        "zh": "赖久尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.2,
        "lng": 77.3667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2352,
      "name": "Daloa",
      "names": {
        "zh": "达洛亚"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 6.89,
        "lng": -6.45
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 2351,
      "name": "Kuala Terengganu",
      "names": {
        "zh": "吉尔瓦·滕甘卢",
        "ja": "クアラ・テレンガー",
        "th": "กัวลาธิการ"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.3303,
        "lng": 103.1408
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 2353,
      "name": "San Pedro Carcha",
      "names": {
        "zh": "圣佩德罗卡拉",
        "ja": "サンペドロカルチャ",
        "pt": "San Pedro Cocha"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4768,
        "lng": -90.312
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 2354,
      "name": "Mocamedes",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -15.1953,
        "lng": 12.1508
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 2348,
      "name": "Turmero",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2283,
        "lng": -67.4753
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2356,
      "name": "Montgomery",
      "names": {
        "zh": "蒙哥马利",
        "ja": "モンゴメリー",
        "th": "มอนต์กอเมอรี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.3473,
        "lng": -86.2666
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 2346,
      "name": "Tokushima",
      "names": {
        "zh": "德岛",
        "ja": "徳島",
        "th": "โทะกุชิมะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.0667,
        "lng": 134.55
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2308,
      "name": "Marilao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.7581,
        "lng": 120.9481
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2358,
      "name": "Gilbert",
      "names": {
        "zh": "吉尔伯特",
        "ja": "ギルバート",
        "th": "กิลเบิร์ต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.3101,
        "lng": -111.7463
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 760,
      "name": "Fort-de-France",
      "names": {
        "zh": "法兰西堡",
        "ja": "フォート・ド・フランス",
        "th": "ป้อม-เดอฝรั่งเศส"
      },
      "countryCode": "MQ",
      "latLng": {
        "lat": 14.6104,
        "lng": -61.08
      },
      "country": "Martinique",
      "importance": 0
    },
    {
      "id": 2361,
      "name": "Kutahya",
      "names": {
        "zh": "屈塔希亚"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.4242,
        "lng": 29.9833
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 2360,
      "name": "Vitoria-Gasteiz",
      "names": {
        "es": "Vitoria",
        "zh": "维多利亚",
        "ja": "ビトリア・gasteiz",
        "th": "วีโตเรีย-gasteiz"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.85,
        "lng": -2.6833
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 2363,
      "name": "Shinozaki",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.95,
        "lng": 130.9333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2370,
      "name": "El Fasher",
      "names": {
        "pt": "El bajeiro"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 13.63,
        "lng": 25.35
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 2372,
      "name": "Glendale",
      "names": {
        "zh": "格伦代尔",
        "ja": "グレンデール",
        "th": "เกลนเดล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5791,
        "lng": -112.2311
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2362,
      "name": "Bharatpur",
      "names": {
        "zh": "巴拉普尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.2172,
        "lng": 77.49
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2373,
      "name": "Santiago del Estero",
      "names": {
        "id": "Santiago del Esterero",
        "ja": "サンティアゴデルテロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.7844,
        "lng": -64.2669
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 2375,
      "name": "Begusarai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.42,
        "lng": 86.13
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2344,
      "name": "Kediri",
      "names": {
        "zh": "谏义里"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.8111,
        "lng": 112.0047
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2376,
      "name": "North Las Vegas",
      "names": {
        "zh": "北拉斯维加斯",
        "de": "Nord-Las Vegas",
        "id": "Las Vegas Utara",
        "ja": "ノースラスベガス",
        "th": "นอร์ทลาสเวกัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.288,
        "lng": -115.0901
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2367,
      "name": "Kanggye",
      "names": {
        "zh": "江界"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 40.9667,
        "lng": 126.6
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 2374,
      "name": "Hakodate",
      "names": {
        "zh": "函馆",
        "ja": "函館",
        "th": "ฮะโกะดะเตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 41.7686,
        "lng": 140.7289
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2343,
      "name": "Chiayi",
      "names": {
        "zh": "嘉义",
        "ja": "嘉義"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 23.48,
        "lng": 120.4497
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 2365,
      "name": "Tacloban",
      "names": {
        "zh": "塔克洛班"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.2444,
        "lng": 125.0039
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2320,
      "name": "Soka",
      "names": {
        "zh": "创价",
        "ja": "創価"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8256,
        "lng": 139.8056
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2384,
      "name": "Higuey",
      "names": {
        "es": "Higüey"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.6181,
        "lng": -68.7111
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 2380,
      "name": "Los Teques",
      "names": {
        "fr": "Los Tequès",
        "zh": "洛杉矶足球",
        "ja": "ロステケス"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.3411,
        "lng": -67.0406
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 2381,
      "name": "Jinshi",
      "names": {
        "zh": "金石"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.6334,
        "lng": 111.8746
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2357,
      "name": "Wolverhampton",
      "names": {
        "zh": "伍尔弗汉普顿",
        "ja": "ウルヴァーハンプトン",
        "th": "แฮมป์ตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.5833,
        "lng": -2.1333
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2386,
      "name": "Bata",
      "names": {
        "zh": "巴塔",
        "ja": "バタ",
        "th": "บาจา"
      },
      "countryCode": "GQ",
      "latLng": {
        "lat": 1.865,
        "lng": 9.77
      },
      "country": "Equatorial Guinea",
      "importance": 1
    },
    {
      "id": 2366,
      "name": "Rach Gia",
      "names": {
        "ja": "Giaをラッキングします"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.0125,
        "lng": 105.0808
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2378,
      "name": "Sonipat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.9958,
        "lng": 77.0114
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2383,
      "name": "Iwo",
      "names": {
        "zh": "硫磺岛",
        "ja": "硫黄島",
        "th": "อิ"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.63,
        "lng": 4.18
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 2368,
      "name": "Taganrog",
      "names": {
        "zh": "塔甘罗格"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.2333,
        "lng": 38.9
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2388,
      "name": "Babol",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.55,
        "lng": 52.6833
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2355,
      "name": "La Paz",
      "names": {
        "zh": "拉巴斯",
        "ja": "ラパス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.1422,
        "lng": -110.3108
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 2347,
      "name": "Arua",
      "names": {
        "zh": "阿鲁阿"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.03,
        "lng": 30.91
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 2393,
      "name": "Al Bayda\u0027",
      "names": {
        "es": "Al Bayda \u0027",
        "fr": "Al Bayda \u0027",
        "de": "Al Bayda \u0027",
        "it": "Al bayda \u0027",
        "id": "Al Bayda \u0027",
        "ja": "アルベイダ \u0027",
        "th": "Al Bayda \u0027",
        "pt": "Al bayda \u0027"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.7628,
        "lng": 21.755
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 2394,
      "name": "Kurmuk",
      "names": {
        "zh": "库尔穆克"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 10.5563,
        "lng": 34.2848
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 761,
      "name": "New Delhi",
      "names": {
        "es": "Nueva delhi",
        "zh": "新德里",
        "de": "Neu-Delhi",
        "it": "Nuova delhi",
        "ja": "ニューデリー",
        "th": "นิวเดลี",
        "pt": "Nova Delhi"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.6139,
        "lng": 77.209
      },
      "country": "India",
      "importance": 0
    },
    {
      "id": 2385,
      "name": "Juazeiro do Norte",
      "names": {
        "zh": "Juazeiro做北部",
        "de": "JuAreiro do Norte",
        "id": "Janairo do norte",
        "ja": "jaazeiro do norte",
        "th": "Juazeiro ทำ Norte"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.2128,
        "lng": -39.315
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2371,
      "name": "Las Condes",
      "names": {
        "fr": "Las couches",
        "ja": "ラスコンズ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.4167,
        "lng": -70.5833
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 2364,
      "name": "Goma",
      "names": {
        "zh": "戈马",
        "id": "Coma",
        "ja": "ゴマ"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -1.6794,
        "lng": 29.2336
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 2359,
      "name": "Rishon LeZiyyon",
      "names": {
        "ja": "Rishon Leziyon"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.9711,
        "lng": 34.7894
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 2389,
      "name": "Komsomol\u0027sk-na-Amure",
      "names": {
        "zh": "komsomol\u0027sk-钠amure",
        "id": "Komsomol\u0027sk-na-amuré",
        "ja": "komsomol\u0027sk-をNA-amure",
        "th": "komsomol\u0027sk-นา-amure"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.55,
        "lng": 137.0
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 2369,
      "name": "Varzea Grande",
      "names": {
        "zh": "瓦尔齐亚格兰德",
        "ja": "バルジアグランデ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.65,
        "lng": -56.14
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2398,
      "name": "Manzhouli",
      "names": {
        "zh": "满洲里"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 49.5881,
        "lng": 117.4525
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2400,
      "name": "Palangkaraya",
      "names": {
        "zh": "帕朗卡拉亚"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -2.21,
        "lng": 113.92
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 2392,
      "name": "Galati",
      "names": {
        "zh": "加拉茨",
        "th": "กาลาติ"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.4233,
        "lng": 28.0425
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 2401,
      "name": "Hafr al Batin",
      "names": {
        "id": "Hapr al batin"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 28.4337,
        "lng": 45.9601
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 2379,
      "name": "Stoke-on-Trent",
      "names": {
        "zh": "斯托克对特伦特",
        "ja": "ストークオントレント",
        "th": "จี้บนเทรนต์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.0,
        "lng": -2.1833
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2390,
      "name": "Aachen",
      "names": {
        "es": "Aquisgrán",
        "zh": "亚琛",
        "it": "Aquisgrana",
        "ja": "アーヘン",
        "th": "อาเคน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.7762,
        "lng": 6.0838
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2397,
      "name": "Braunschweig",
      "names": {
        "zh": "不伦瑞克",
        "ja": "ブラウンシュヴァイク",
        "th": "รอนช"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2692,
        "lng": 10.5211
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2406,
      "name": "Portsmouth",
      "names": {
        "zh": "朴茨茅斯",
        "ja": "ポーツマス",
        "th": "พอร์ทสมัธ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.8058,
        "lng": -1.0872
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2407,
      "name": "Sibu",
      "names": {
        "zh": "诗巫"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 2.3,
        "lng": 111.8167
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 2409,
      "name": "Parana",
      "names": {
        "es": "Paraná",
        "zh": "巴拉那",
        "ja": "パラナ",
        "th": "ปารานา",
        "pt": "Paraná"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.7444,
        "lng": -60.5175
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 2403,
      "name": "Sahiwal",
      "names": {
        "zh": "萨希瓦尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.6706,
        "lng": 73.1064
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 2399,
      "name": "Gdynia",
      "names": {
        "zh": "格丁尼亚",
        "ja": "グディニャ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.5189,
        "lng": 18.5319
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 2087,
      "name": "Navotas",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6667,
        "lng": 120.9417
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2412,
      "name": "Imperatriz",
      "names": {
        "zh": "因佩拉特里斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.5258,
        "lng": -47.4758
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2410,
      "name": "Santa Clara",
      "names": {
        "zh": "圣克拉拉",
        "ja": "サンタクララ",
        "th": "ซานตาคลาร่า"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.4067,
        "lng": -79.9531
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 2411,
      "name": "Yamagata",
      "names": {
        "zh": "山形",
        "ja": "山形",
        "th": "ยะมะงะตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.25,
        "lng": 140.3333
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2415,
      "name": "Tsukuba-kenkyugakuen-toshi",
      "names": {
        "zh": "筑波-kenkyugakuen-都市",
        "ja": "筑波-kenkyugakuen-トシ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0333,
        "lng": 140.0667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2404,
      "name": "Kiel",
      "names": {
        "zh": "基尔",
        "ja": "キール",
        "th": "คีล"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.3233,
        "lng": 10.1394
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 2391,
      "name": "Rivne",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.6197,
        "lng": 26.2514
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2413,
      "name": "Oruro",
      "names": {
        "zh": "奥鲁罗"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.9799,
        "lng": -67.13
      },
      "country": "Bolivia",
      "importance": 1
    },
    {
      "id": 2420,
      "name": "Saskatoon",
      "names": {
        "zh": "萨斯卡通",
        "ja": "サスカトゥーン",
        "th": "ซัสคาทูน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 52.1333,
        "lng": -106.6833
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2423,
      "name": "Beichengqu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.4348,
        "lng": 113.1565
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2421,
      "name": "Abertawe",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 51.6167,
        "lng": -3.95
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2377,
      "name": "A Coruna",
      "names": {
        "es": "A coruña",
        "fr": "Un corunal",
        "zh": "Coruna",
        "de": "Ein Coruna",
        "it": "Una coruna",
        "ja": "コルーナ",
        "th": "coruna",
        "pt": "Uma coruna"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.3667,
        "lng": -8.3833
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 2417,
      "name": "Syktyvkar",
      "names": {
        "zh": "瑟克特夫卡尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 61.6667,
        "lng": 50.8167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2416,
      "name": "Barnsley",
      "names": {
        "zh": "巴恩斯利",
        "th": "บาร์นสลีย์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5547,
        "lng": -1.4791
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2426,
      "name": "Chesapeake",
      "names": {
        "zh": "切萨皮克",
        "ja": "チェサピーク",
        "th": "เชสส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.6778,
        "lng": -76.3024
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2418,
      "name": "Khimki",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.8892,
        "lng": 37.445
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2396,
      "name": "Thu Dau Mot",
      "names": {
        "zh": "星期四",
        "de": "Do dau mot",
        "id": "Kamis Dau Mot",
        "th": "ทู่เดา"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.0042,
        "lng": 106.6583
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2414,
      "name": "Merlo",
      "names": {
        "zh": "梅洛"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6653,
        "lng": -58.7275
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 2298,
      "name": "Damanhur",
      "names": {
        "zh": "达曼胡尔"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.05,
        "lng": 30.4667
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 2422,
      "name": "Jessore",
      "names": {
        "zh": "杰索尔"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 23.1704,
        "lng": 89.2
      },
      "country": "Bangladesh",
      "importance": 3
    },
    {
      "id": 2427,
      "name": "Fuji",
      "names": {
        "zh": "富士",
        "ja": "富士",
        "th": "ฟูจิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1614,
        "lng": 138.6764
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2433,
      "name": "Sabzevar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.2167,
        "lng": 57.6667
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2432,
      "name": "Santa Maria",
      "names": {
        "zh": "圣玛丽亚",
        "ja": "サンタマリア",
        "th": "ซานตามาเรีย"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6839,
        "lng": -53.8069
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2431,
      "name": "Tanga",
      "names": {
        "zh": "坦加",
        "ja": "タンガ",
        "th": "ทังกา"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.0667,
        "lng": 39.1
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 2428,
      "name": "Chemnitz",
      "names": {
        "zh": "开姆尼茨",
        "ja": "ケムニッツ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8333,
        "lng": 12.9167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2435,
      "name": "Bahir Dar",
      "names": {
        "es": "Bahir",
        "zh": "巴赫尔达尔",
        "id": "Badir",
        "ja": "バヘルダー"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 11.585,
        "lng": 37.39
      },
      "country": "Ethiopia",
      "importance": 1
    },
    {
      "id": 2440,
      "name": "Myitkyina",
      "names": {
        "zh": "密支那"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 25.3867,
        "lng": 97.3936
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 2434,
      "name": "Ramgundam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.8,
        "lng": 79.45
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2425,
      "name": "Hapur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.7437,
        "lng": 77.7628
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2441,
      "name": "Barnstable",
      "names": {
        "zh": "巴恩斯特布尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6722,
        "lng": -70.3599
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2424,
      "name": "Tuy Hoa",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 13.0869,
        "lng": 109.3086
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2444,
      "name": "Norfolk",
      "names": {
        "zh": "诺福克",
        "ja": "ノーフォーク",
        "th": "นอร์ฟอล์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.8945,
        "lng": -76.259
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2439,
      "name": "Sasebo",
      "names": {
        "zh": "佐世保"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.18,
        "lng": 129.7156
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2438,
      "name": "Sapele",
      "names": {
        "zh": "沙比利"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.8261,
        "lng": 5.6536
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 2419,
      "name": "Biratnagar",
      "names": {
        "zh": "比拉特纳加尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 26.4833,
        "lng": 87.2833
      },
      "country": "Nepal",
      "importance": 1
    },
    {
      "id": 2402,
      "name": "Chigasaki",
      "names": {
        "zh": "茅崎",
        "ja": "茅ヶ崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3339,
        "lng": 139.4047
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2448,
      "name": "Zhangping",
      "names": {
        "zh": "漳平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.2938,
        "lng": 117.4148
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2442,
      "name": "Haeju",
      "names": {
        "zh": "海州"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 38.0333,
        "lng": 125.7167
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 2446,
      "name": "Diaobingshancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.4391,
        "lng": 123.5426
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2449,
      "name": "Sao Carlos",
      "names": {
        "zh": "圣卡洛斯",
        "ja": "サンカルロス",
        "th": "เซาคาร์ลอส",
        "pt": "São Carlos"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.0178,
        "lng": -47.8908
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2451,
      "name": "Fremont",
      "names": {
        "zh": "弗里蒙特",
        "ja": "フリーモント",
        "th": "ฟรีมอนต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.5265,
        "lng": -121.9852
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2437,
      "name": "Baruta",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4335,
        "lng": -66.8754
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2457,
      "name": "Kennewick",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 46.1978,
        "lng": -119.1732
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2447,
      "name": "Tarapoto",
      "names": {
        "zh": "塔拉波托"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -6.4833,
        "lng": -76.3667
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 2454,
      "name": "Shanhu",
      "names": {
        "zh": "珊瑚"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.5908,
        "lng": 120.8176
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2458,
      "name": "Atushi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.7114,
        "lng": 76.1753
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2382,
      "name": "Chofugaoka",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6506,
        "lng": 139.5408
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2453,
      "name": "Anju",
      "names": {
        "zh": "安居房",
        "ja": "アンジュ"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.6167,
        "lng": 125.6667
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 2462,
      "name": "Garland",
      "names": {
        "es": "Guirnalda",
        "zh": "花环",
        "de": "Girlande",
        "it": "Ghirlanda",
        "ja": "ガーランド",
        "th": "พวงมาลัย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.91,
        "lng": -96.6305
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2463,
      "name": "Irving",
      "names": {
        "zh": "欧文",
        "ja": "アーヴィング",
        "th": "เออร์วิง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8584,
        "lng": -96.9702
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2466,
      "name": "Longueuil",
      "names": {
        "zh": "隆格伊"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.5333,
        "lng": -73.5167
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2429,
      "name": "Mokpo",
      "names": {
        "zh": "木浦"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 34.7936,
        "lng": 126.3886
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 2452,
      "name": "Ipatinga",
      "names": {
        "zh": "伊帕廷加"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.4678,
        "lng": -42.5369
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2469,
      "name": "Viamao",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -30.0808,
        "lng": -51.0228
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2408,
      "name": "Yato",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4693,
        "lng": 139.4616
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2470,
      "name": "Matsumoto",
      "names": {
        "zh": "松本",
        "ja": "松本",
        "th": "มัตสึโมโตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.2381,
        "lng": 137.9719
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2459,
      "name": "Chimoio",
      "names": {
        "zh": "希莫尤"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -19.1167,
        "lng": 33.45
      },
      "country": "Mozambique",
      "importance": 1
    },
    {
      "id": 2461,
      "name": "Novo Hamburgo",
      "names": {
        "zh": "诺瓦汉堡",
        "ja": "ノボハンブルゴ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6778,
        "lng": -51.1308
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2468,
      "name": "Kosice",
      "names": {
        "zh": "科希策"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7167,
        "lng": 21.25
      },
      "country": "Slovakia",
      "importance": 1
    },
    {
      "id": 2479,
      "name": "Lishui",
      "names": {
        "zh": "丽水"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.45,
        "lng": 119.9167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2445,
      "name": "Gonzalez Catan",
      "names": {
        "ja": "ゴンザレツカターン"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7708,
        "lng": -58.6464
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 2481,
      "name": "Wuyishan",
      "names": {
        "zh": "武夷山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7562,
        "lng": 118.0267
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2467,
      "name": "Halle",
      "names": {
        "zh": "哈利",
        "ja": "ハレ",
        "th": "ฮัลลี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4828,
        "lng": 11.9697
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2472,
      "name": "Shenmu",
      "names": {
        "zh": "神木"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.827,
        "lng": 110.499
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2476,
      "name": "As Sib",
      "names": {
        "es": "Como sib",
        "fr": "Comme sib",
        "zh": "作为sib",
        "de": "Als Sib",
        "it": "Come sib",
        "id": "Sebagai sib",
        "ja": "SIBとして",
        "th": "เป็น sib",
        "pt": "Como sib"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 23.6802,
        "lng": 58.1825
      },
      "country": "Oman",
      "importance": 3
    },
    {
      "id": 2436,
      "name": "Porto",
      "names": {
        "zh": "波尔图",
        "ja": "ポルト",
        "th": "ปอร์โต"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1495,
        "lng": -8.6108
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 2450,
      "name": "Aarhus",
      "names": {
        "zh": "奥胡斯",
        "ja": "オーフス",
        "th": "ออฮุส"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 56.1572,
        "lng": 10.2107
      },
      "country": "Denmark",
      "importance": 2
    },
    {
      "id": 2482,
      "name": "Amol",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.4669,
        "lng": 52.3569
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2486,
      "name": "Visalia",
      "names": {
        "zh": "维塞利亚",
        "th": "วิเซเลีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3276,
        "lng": -119.3269
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2487,
      "name": "Al Jubayl",
      "names": {
        "ja": "アルjubayl",
        "th": "อัล Jubayl"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 27.0046,
        "lng": 49.646
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 2485,
      "name": "Nizhnekamsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.6333,
        "lng": 51.8167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2456,
      "name": "Monclova",
      "names": {
        "zh": "蒙克洛瓦"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.9103,
        "lng": -101.4222
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2474,
      "name": "Taiping",
      "names": {
        "zh": "太平",
        "th": "ไทปิง"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 4.85,
        "lng": 100.7333
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 2484,
      "name": "Pathein",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 16.7667,
        "lng": 94.7333
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 2492,
      "name": "Atlantic City",
      "names": {
        "fr": "Atlantic",
        "zh": "大西洋城",
        "de": "Atlantische Stadt",
        "ja": "アトランティックシティ",
        "th": "แอตแลนติกซิตี",
        "pt": "Atlântico"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.3797,
        "lng": -74.4527
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2489,
      "name": "Chishui",
      "names": {
        "zh": "赤水"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.5902,
        "lng": 105.6946
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2483,
      "name": "Mirpur Khas",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 25.5269,
        "lng": 69.0111
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 2478,
      "name": "Ivano-Frankivsk",
      "names": {
        "zh": "伊万诺 -  frankivsk",
        "de": "Iwano-frankiwsk"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.9228,
        "lng": 24.7106
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2477,
      "name": "P\u0027yong-dong",
      "names": {
        "zh": "p\u0027yong栋",
        "ja": "p\u0027yong-ドン",
        "th": "p\u0027yong ดอง"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.25,
        "lng": 125.85
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 2490,
      "name": "Centurion",
      "names": {
        "zh": "百夫长",
        "ja": "センチュリオン",
        "th": "นายก"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.8603,
        "lng": 28.1894
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 2493,
      "name": "Pakdasht",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.4817,
        "lng": 51.6803
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2443,
      "name": "Petah Tiqwa",
      "names": {
        "id": "Payah Tiqwa"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0833,
        "lng": 34.8833
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 2497,
      "name": "Abha",
      "names": {
        "zh": "艾卜哈"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 18.2167,
        "lng": 42.5
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 2473,
      "name": "Springs",
      "names": {
        "es": "Muelles",
        "fr": "Ressorts",
        "zh": "弹簧",
        "ja": "スプリングス",
        "th": "น้ำพุ"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.2547,
        "lng": 28.4428
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 2387,
      "name": "Al Minya",
      "names": {
        "zh": "米尼亚",
        "ja": "アルミンヤ",
        "th": "อัลมินายา"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 28.0833,
        "lng": 30.75
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 2498,
      "name": "Garoua",
      "names": {
        "zh": "加鲁阿"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 9.3,
        "lng": 13.4
      },
      "country": "Cameroon",
      "importance": 1
    },
    {
      "id": 2340,
      "name": "Bunkyo-ku",
      "names": {
        "ja": "文京-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7167,
        "lng": 139.75
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2495,
      "name": "Magdeburg",
      "names": {
        "es": "Magdeburgo",
        "zh": "马格德堡",
        "ja": "マクデブルク",
        "th": "แม็ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1317,
        "lng": 11.6392
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 2475,
      "name": "Zinder",
      "names": {
        "zh": "津德尔"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 13.8,
        "lng": 8.9833
      },
      "country": "Niger",
      "importance": 1
    },
    {
      "id": 2499,
      "name": "Bole",
      "names": {
        "fr": "Fût",
        "zh": "博乐",
        "ja": "木の幹",
        "th": "ลำต้น"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.898,
        "lng": 82.0726
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2494,
      "name": "Shakhty",
      "names": {
        "zh": "沙赫特"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.7,
        "lng": 40.2333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2500,
      "name": "Ganda",
      "names": {
        "zh": "甘达"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -13.0167,
        "lng": 14.6333
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 2491,
      "name": "Uluberiya",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.47,
        "lng": 88.11
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2471,
      "name": "Banda Aceh",
      "names": {
        "de": "Banda-Aceh",
        "ja": "バンダアチェ"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 5.55,
        "lng": 95.3175
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 2501,
      "name": "Najafabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.6342,
        "lng": 51.3667
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2395,
      "name": "Shibuya-ku",
      "names": {
        "zh": "涩谷-ku",
        "ja": "渋谷区ku",
        "th": "ชิบุยะ-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6583,
        "lng": 139.7014
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2502,
      "name": "Bayamo",
      "names": {
        "zh": "巴亚莫"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.3817,
        "lng": -76.6428
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 2507,
      "name": "Paradise",
      "names": {
        "es": "Paraíso",
        "fr": "Paradis",
        "zh": "天堂",
        "de": "Paradies",
        "it": "Paradiso",
        "ja": "パラダイス",
        "th": "สวรรค์",
        "pt": "Paraíso"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0807,
        "lng": -115.1369
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2505,
      "name": "Kamianske",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5076,
        "lng": 34.6132
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 2460,
      "name": "Limassol",
      "names": {
        "zh": "利马索尔",
        "ja": "リマソル",
        "th": "ลีมาซอล"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 34.675,
        "lng": 33.0443
      },
      "country": "Cyprus",
      "importance": 1
    },
    {
      "id": 2506,
      "name": "Borujerd",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.8972,
        "lng": 48.7514
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2496,
      "name": "Ibb",
      "names": {
        "zh": "伊布"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 13.9759,
        "lng": 44.1709
      },
      "country": "Yemen",
      "importance": 3
    },
    {
      "id": 2504,
      "name": "Porbandar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.6425,
        "lng": 69.6047
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2510,
      "name": "Macae",
      "names": {
        "zh": "马卡埃",
        "pt": "Macaé"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.3708,
        "lng": -41.7869
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2511,
      "name": "Miri",
      "names": {
        "zh": "美里",
        "ja": "ミリ",
        "th": "มีรี"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 4.4028,
        "lng": 113.9933
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 2508,
      "name": "Singaraja",
      "names": {
        "zh": "新加拉惹",
        "th": "การาจา"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.1167,
        "lng": 115.0833
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 2515,
      "name": "Messina",
      "names": {
        "fr": "Messine",
        "zh": "墨西拿",
        "ja": "メッシーナ",
        "th": "เมสสินา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.1936,
        "lng": 15.5542
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 2509,
      "name": "Elche",
      "names": {
        "zh": "埃尔切"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.2669,
        "lng": -0.6983
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2517,
      "name": "Nashua",
      "names": {
        "zh": "纳舒厄",
        "ja": "ナシュア",
        "th": "แมนเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7491,
        "lng": -71.491
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2430,
      "name": "Avcilar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9792,
        "lng": 28.7214
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 2514,
      "name": "Bo",
      "names": {
        "zh": "博",
        "ja": "ボー",
        "th": "บ่อ"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 7.9564,
        "lng": -11.74
      },
      "country": "Sierra Leone",
      "importance": 1
    },
    {
      "id": 2522,
      "name": "Arlington",
      "names": {
        "zh": "阿灵顿",
        "ja": "アーリントン",
        "th": "อาร์ลิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8786,
        "lng": -77.1011
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2524,
      "name": "Hialeah",
      "names": {
        "zh": "海厄利亚"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.8696,
        "lng": -80.3046
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2525,
      "name": "York",
      "names": {
        "zh": "纽约",
        "ja": "ヨーク",
        "th": "นิวยอร์ก",
        "pt": "Iorque"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9651,
        "lng": -76.7315
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2464,
      "name": "Lille",
      "names": {
        "zh": "里尔",
        "ja": "リール",
        "th": "ลีล"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.6278,
        "lng": 3.0583
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 2519,
      "name": "Arapiraca",
      "names": {
        "zh": "阿拉皮拉卡"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.75,
        "lng": -36.65
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2518,
      "name": "San Cristobal",
      "names": {
        "es": "San Cristóbal",
        "ja": "サンクリストバル"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4167,
        "lng": -70.1333
      },
      "country": "Dominican Republic",
      "importance": 1
    },
    {
      "id": 2527,
      "name": "Burnaby",
      "names": {
        "zh": "伯纳比"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2667,
        "lng": -122.9667
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2520,
      "name": "Okara",
      "names": {
        "zh": "奥卡拉"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.81,
        "lng": 73.4597
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 2526,
      "name": "Saga",
      "names": {
        "zh": "佐贺",
        "ja": "佐賀",
        "th": "ความกล้าหาญ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.2667,
        "lng": 130.3
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2523,
      "name": "Saidpur",
      "names": {
        "zh": "赛伊德布尔"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 25.8004,
        "lng": 89.0
      },
      "country": "Bangladesh",
      "importance": 3
    },
    {
      "id": 2533,
      "name": "Marilia",
      "names": {
        "zh": "马里利亚",
        "it": "Marília"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2139,
        "lng": -49.9458
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2536,
      "name": "Jiayuguan",
      "names": {
        "zh": "嘉峪关"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.8112,
        "lng": 98.2862
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2532,
      "name": "Dzerzhinsk",
      "names": {
        "zh": "捷尔任斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.2333,
        "lng": 43.45
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2513,
      "name": "Granada",
      "names": {
        "fr": "Grenade",
        "zh": "格拉纳达",
        "ja": "グラナダ",
        "th": "กรานาดา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.1781,
        "lng": -3.6008
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 2516,
      "name": "Eindhoven",
      "names": {
        "zh": "埃因霍温",
        "ja": "アイントホーフェン",
        "th": "ไอนด์โฮเฟ่น"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4344,
        "lng": 5.4842
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 762,
      "name": "Gaborone",
      "names": {
        "zh": "哈博罗内",
        "ja": "ガボローネ",
        "th": "กาโบโรเน"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -24.6569,
        "lng": 25.9086
      },
      "country": "Botswana",
      "importance": 0
    },
    {
      "id": 2534,
      "name": "Bratsk",
      "names": {
        "zh": "布拉茨克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.152,
        "lng": 101.633
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2529,
      "name": "Rancagua",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -34.1654,
        "lng": -70.7398
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 2512,
      "name": "Machala",
      "names": {
        "zh": "马查拉"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -3.2667,
        "lng": -79.9667
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 2545,
      "name": "Neuquen",
      "names": {
        "es": "Neuquén",
        "fr": "Neuquén",
        "zh": "内乌肯",
        "id": "Neuquén",
        "ja": "ネウケン"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.9573,
        "lng": -68.0455
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 2542,
      "name": "Qarchak",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.4394,
        "lng": 51.5689
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 2538,
      "name": "Indaiatuba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.0903,
        "lng": -47.2181
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2544,
      "name": "Ormoc",
      "names": {
        "zh": "奥尔莫克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.0167,
        "lng": 124.6167
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2530,
      "name": "Freiburg im Breisgau",
      "names": {
        "it": "Friburgo im breisgau",
        "th": "ไฟรบูร์ก IM Breisgau"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.995,
        "lng": 7.85
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2548,
      "name": "Fresnillo",
      "names": {
        "zh": "弗雷斯尼洛"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.175,
        "lng": -102.8675
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2537,
      "name": "Eloy Alfaro",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -2.1733,
        "lng": -79.8311
      },
      "country": "Ecuador",
      "importance": 3
    },
    {
      "id": 2480,
      "name": "Quilmes",
      "names": {
        "zh": "基尔梅斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7167,
        "lng": -58.2667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 2552,
      "name": "North Hempstead",
      "names": {
        "es": "Hempstead del Norte",
        "fr": "Hempstead nord",
        "zh": "北半部分",
        "de": "Nordhanpstead",
        "id": "Hempstead Utara",
        "ja": "ノースヘムスステッド",
        "th": "ศอกเหนือ",
        "pt": "Hempstead do Norte"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7912,
        "lng": -73.6688
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2553,
      "name": "Evansville",
      "names": {
        "zh": "埃文斯维尔",
        "th": "เอวันส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9881,
        "lng": -87.5341
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2550,
      "name": "Orsk",
      "names": {
        "zh": "奥尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.229,
        "lng": 58.4681
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2549,
      "name": "Singkawang",
      "names": {
        "zh": "山口洋"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.9,
        "lng": 108.9833
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2554,
      "name": "Marka",
      "names": {
        "zh": "马尔卡"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": 1.7156,
        "lng": 44.7703
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 2535,
      "name": "Barishal",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 22.7,
        "lng": 90.3667
      },
      "country": "Bangladesh",
      "importance": 1
    },
    {
      "id": 2556,
      "name": "Pali",
      "names": {
        "zh": "巴利",
        "ja": "パーリ語",
        "th": "ภาษาบาลี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.7725,
        "lng": 73.3233
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2557,
      "name": "Noginsk",
      "names": {
        "zh": "诺金斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 64.4833,
        "lng": 91.2333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2555,
      "name": "Cotia",
      "names": {
        "zh": "科蒂亚"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6039,
        "lng": -46.9189
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2547,
      "name": "Americana",
      "names": {
        "zh": "美国",
        "ja": "アメリカーナ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7389,
        "lng": -47.3308
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2531,
      "name": "Kasukabe",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9753,
        "lng": 139.7525
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2560,
      "name": "Rondonopolis",
      "names": {
        "pt": "Rondonópolis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.4708,
        "lng": -54.6358
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2539,
      "name": "Envigado",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1719,
        "lng": -75.5803
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 2551,
      "name": "Vizianagaram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.1167,
        "lng": 83.4167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2561,
      "name": "Coban",
      "names": {
        "es": "Cobán",
        "zh": "科万"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4833,
        "lng": -90.3667
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 2562,
      "name": "Guantanamo",
      "names": {
        "es": "Guantánamo",
        "zh": "关塔那摩",
        "ja": "グアンタナモ",
        "th": "กวาน",
        "pt": "Guantánamo"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.1383,
        "lng": -75.2061
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 2488,
      "name": "Neya",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7661,
        "lng": 135.6281
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2558,
      "name": "Kropyvnytskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5103,
        "lng": 32.2667
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2503,
      "name": "Puducherry",
      "names": {
        "it": "Pondicherry"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.93,
        "lng": 79.83
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 2567,
      "name": "Mage",
      "names": {
        "es": "Mago",
        "zh": "法师",
        "de": "Magier",
        "ja": "魔法使い",
        "th": "นักเวทย์",
        "pt": "Mago"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.6528,
        "lng": -43.0408
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2570,
      "name": "Avondale",
      "names": {
        "zh": "埃文代尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.3858,
        "lng": -112.3236
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2571,
      "name": "Brownsville",
      "names": {
        "zh": "布朗斯维尔",
        "ja": "ブラウンズヴィル",
        "th": "บราวน์สวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.998,
        "lng": -97.4565
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2572,
      "name": "Dasoguz",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 41.8167,
        "lng": 59.9831
      },
      "country": "Turkmenistan",
      "importance": 1
    },
    {
      "id": 2563,
      "name": "Krefeld",
      "names": {
        "zh": "克雷费尔德",
        "ja": "クレーフェルト"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3333,
        "lng": 6.5667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2541,
      "name": "Ageoshimo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9775,
        "lng": 139.5933
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2574,
      "name": "Jacarei",
      "names": {
        "pt": "Jacareí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.305,
        "lng": -45.9658
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2576,
      "name": "Araraquara",
      "names": {
        "zh": "阿拉拉夸拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.7939,
        "lng": -48.1758
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2577,
      "name": "Angarsk",
      "names": {
        "zh": "安加尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.5667,
        "lng": 103.9167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2565,
      "name": "Kolpino",
      "names": {
        "zh": "科尔皮诺"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.73,
        "lng": 30.65
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2591,
      "name": "Mubi",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 10.2604,
        "lng": 13.2606
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 2528,
      "name": "Meycauayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.7333,
        "lng": 120.95
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2590,
      "name": "Varamin",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.2714,
        "lng": 51.6511
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2589,
      "name": "Padangsidempuan",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 1.3667,
        "lng": 99.2667
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2593,
      "name": "Hunchun",
      "names": {
        "zh": "珲春"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.8679,
        "lng": 130.3585
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2580,
      "name": "Nyanza",
      "names": {
        "zh": "尼安扎"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -2.3496,
        "lng": 29.74
      },
      "country": "Rwanda",
      "importance": 1
    },
    {
      "id": 2569,
      "name": "Nadiad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.7,
        "lng": 72.87
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2594,
      "name": "Tampere",
      "names": {
        "zh": "坦佩雷",
        "ja": "タンペレ",
        "th": "ตัมเปเร"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4981,
        "lng": 23.76
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 2587,
      "name": "Toamasina",
      "names": {
        "zh": "阿马西纳"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -18.1492,
        "lng": 49.4023
      },
      "country": "Madagascar",
      "importance": 1
    },
    {
      "id": 2588,
      "name": "Karnal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.6804,
        "lng": 76.97
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2575,
      "name": "Quetzaltenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8333,
        "lng": -91.5167
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 2585,
      "name": "Bac Lieu",
      "names": {
        "es": "Bacista",
        "fr": "Bac au lieu",
        "zh": "bac代替",
        "ja": "バクレ",
        "pt": "Bacieu"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 9.2833,
        "lng": 105.7167
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2595,
      "name": "Sakarya",
      "names": {
        "zh": "萨卡里亚"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.7833,
        "lng": 30.4
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 2602,
      "name": "Carlos Manuel de Cespedes",
      "names": {
        "it": "Carlos Manuel de Cansedes",
        "id": "Carlos Manuel de engedes"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.5767,
        "lng": -78.2775
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 2596,
      "name": "Blagoveshchensk",
      "names": {
        "zh": "布拉戈维申斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.2578,
        "lng": 127.5364
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2578,
      "name": "Nagercoil",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 8.17,
        "lng": 77.43
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2581,
      "name": "Takarazuka",
      "names": {
        "zh": "宝冢",
        "ja": "宝塚"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8114,
        "lng": 135.3406
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2564,
      "name": "Huixquilucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3611,
        "lng": -99.3508
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2603,
      "name": "Chapeco",
      "names": {
        "fr": "Chapecó",
        "pt": "Chapecó"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.0958,
        "lng": -52.6178
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2546,
      "name": "Banfield",
      "names": {
        "zh": "班费尔德"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.75,
        "lng": -58.3833
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 2543,
      "name": "Toluca",
      "names": {
        "zh": "托卢卡",
        "ja": "トルカ",
        "th": "โตลูกา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2925,
        "lng": -99.6569
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 2583,
      "name": "Atsugicho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4333,
        "lng": 139.3667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 763,
      "name": "Paramaribo",
      "names": {
        "zh": "帕拉马里博",
        "ja": "パラマリボ",
        "th": "ปารามาริโบ"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.8667,
        "lng": -55.1667
      },
      "country": "Suriname",
      "importance": 0
    },
    {
      "id": 2455,
      "name": "Narayanganj",
      "names": {
        "zh": "纳拉仰甘"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 23.6167,
        "lng": 90.5
      },
      "country": "Bangladesh",
      "importance": 3
    },
    {
      "id": 2586,
      "name": "Itapevi",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5489,
        "lng": -46.9342
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2606,
      "name": "Staryy Oskol",
      "names": {
        "es": "Scaryy Oskol",
        "ja": "聖職者Oskol",
        "th": "สเตรีย์โอสถ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.2981,
        "lng": 37.835
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2600,
      "name": "Czestochowa",
      "names": {
        "zh": "琴斯托霍瓦"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.8096,
        "lng": 19.1222
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 2573,
      "name": "Probolinggo",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.75,
        "lng": 113.2167
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2584,
      "name": "Tarrasa",
      "names": {
        "zh": "塔拉萨"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.5611,
        "lng": 2.0081
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2521,
      "name": "Badalona",
      "names": {
        "zh": "巴达洛纳"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.4333,
        "lng": 2.2333
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2605,
      "name": "Tanjore",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.8,
        "lng": 79.15
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2608,
      "name": "Ji\u0027an Shi",
      "names": {
        "zh": "济安施",
        "ja": "江南市"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.1231,
        "lng": 126.1815
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2597,
      "name": "Velikiy Novgorod",
      "names": {
        "zh": "Velikiy诺夫哥罗德"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.521,
        "lng": 31.2758
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2613,
      "name": "Barra do Dande",
      "names": {
        "zh": "巴拉做丹德",
        "ja": "バラダダンデ"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -8.4728,
        "lng": 13.3731
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 2604,
      "name": "Sandton",
      "names": {
        "zh": "桑顿"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.107,
        "lng": 28.0517
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 2614,
      "name": "Hobart",
      "names": {
        "zh": "霍巴特",
        "ja": "ホバート",
        "th": "โฮบาร์ต"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -42.8806,
        "lng": 147.325
      },
      "country": "Australia",
      "importance": 1
    },
    {
      "id": 764,
      "name": "Dili",
      "names": {
        "zh": "帝力",
        "ja": "ディリ",
        "th": "ดิลี",
        "pt": "Díli"
      },
      "countryCode": "TL",
      "latLng": {
        "lat": -8.5536,
        "lng": 125.5783
      },
      "country": "Timor-Leste",
      "importance": 0
    },
    {
      "id": 2618,
      "name": "Formosa",
      "names": {
        "zh": "福尔摩沙",
        "ja": "台湾",
        "th": "ฟอร์โมซา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.1847,
        "lng": -58.1758
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 2615,
      "name": "Swindon",
      "names": {
        "zh": "史云顿",
        "ja": "スウィンドン",
        "th": "สวินดอน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.56,
        "lng": -1.78
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2612,
      "name": "Presidente Prudente",
      "names": {
        "fr": "PREMIÈRE PRUDENTE",
        "zh": "Presencee Prudente",
        "ja": "プレジデンテプルデンテ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.1258,
        "lng": -51.3889
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2598,
      "name": "Longjin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.8711,
        "lng": 113.0684
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2592,
      "name": "Korolev",
      "names": {
        "zh": "科罗廖夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.9167,
        "lng": 37.8167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2620,
      "name": "Gueckedou",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 8.5667,
        "lng": -10.1333
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 2624,
      "name": "Neyshabur",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.22,
        "lng": 58.82
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2579,
      "name": "Sousse",
      "names": {
        "zh": "苏斯",
        "th": "ซูสส์"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.8333,
        "lng": 10.6333
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 2616,
      "name": "Ota",
      "names": {
        "zh": "太田",
        "ja": "大田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.2917,
        "lng": 139.3758
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2611,
      "name": "Rufisque",
      "names": {
        "zh": "吕菲斯克"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.7167,
        "lng": -17.2667
      },
      "country": "Senegal",
      "importance": 3
    },
    {
      "id": 2628,
      "name": "Gulfport",
      "names": {
        "zh": "格尔夫波特",
        "th": "กัลฟ์พอร์ต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.4271,
        "lng": -89.0703
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2619,
      "name": "Sambhal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.58,
        "lng": 78.55
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2629,
      "name": "San Felipe",
      "names": {
        "zh": "圣费利佩",
        "ja": "サンフェリペ",
        "th": "ซานเฟลิเป้"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.3353,
        "lng": -68.7458
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 2630,
      "name": "Saveh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.0211,
        "lng": 50.3564
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2627,
      "name": "Hachinohe",
      "names": {
        "zh": "八户",
        "ja": "八戸"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 40.5122,
        "lng": 141.4883
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2634,
      "name": "Appleton",
      "names": {
        "zh": "阿普尔顿",
        "ja": "アップルトン",
        "th": "แอปเปิลตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.2779,
        "lng": -88.3891
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2582,
      "name": "Campeche",
      "names": {
        "zh": "坎佩切",
        "ja": "カンペチェ",
        "th": "กัมเปเช"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.85,
        "lng": -90.5306
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 2633,
      "name": "La Vega",
      "names": {
        "zh": "拉维加",
        "ja": "ラベガ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.2242,
        "lng": -70.5283
      },
      "country": "Dominican Republic",
      "importance": 1
    },
    {
      "id": 2637,
      "name": "Juazeiro",
      "names": {
        "zh": "茹阿泽鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.4139,
        "lng": -40.5028
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2599,
      "name": "Ashdod",
      "names": {
        "zh": "阿什多德",
        "de": "Asdod",
        "id": "Asdod",
        "th": "ชาวเมืองอัชโดด"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.7978,
        "lng": 34.6503
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 2638,
      "name": "Kremenchuk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0775,
        "lng": 33.4239
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 2622,
      "name": "Polokwane",
      "names": {
        "th": "โพโลเควน"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -23.9,
        "lng": 29.45
      },
      "country": "South Africa",
      "importance": 1
    },
    {
      "id": 2625,
      "name": "Radom",
      "names": {
        "zh": "拉多姆"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.4036,
        "lng": 21.1567
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 2639,
      "name": "Itabuna",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -14.7858,
        "lng": -39.28
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2640,
      "name": "Al Khubar",
      "names": {
        "de": "Alkhubar",
        "ja": "アルクバル",
        "th": "อัล khubar"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 26.3,
        "lng": 50.2167
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 2644,
      "name": "Tulua",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.0847,
        "lng": -76.1986
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 2643,
      "name": "Zakhu",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 37.1436,
        "lng": 42.6819
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 2635,
      "name": "Khomeyni Shahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.7,
        "lng": 51.5211
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2642,
      "name": "Shiliguri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.72,
        "lng": 88.42
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2617,
      "name": "Ternopil",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.5667,
        "lng": 25.6
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2647,
      "name": "Bitung",
      "names": {
        "zh": "比通"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 1.4472,
        "lng": 125.1978
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2632,
      "name": "Santo Tomas",
      "names": {
        "ja": "サントトマス",
        "th": "ซานโตโทมัส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0833,
        "lng": 121.1833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2653,
      "name": "San Miguel",
      "names": {
        "zh": "圣米格尔",
        "ja": "サンミゲル",
        "th": "ซานมิเกล"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.4833,
        "lng": -88.1833
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 2659,
      "name": "Bremerton",
      "names": {
        "zh": "布雷默顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.5436,
        "lng": -122.7122
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2661,
      "name": "Hickory",
      "names": {
        "zh": "山核桃",
        "ja": "ヒッコリー",
        "th": "ชนิดหนึ่ง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.7425,
        "lng": -81.323
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2641,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8942,
        "lng": -96.9347
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2657,
      "name": "Itaborai",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7475,
        "lng": -42.8558
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2648,
      "name": "Naihati",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.9,
        "lng": 88.42
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2465,
      "name": "Arakawa",
      "names": {
        "zh": "荒川",
        "ja": "荒川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7333,
        "lng": 139.7833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2663,
      "name": "Tacoma",
      "names": {
        "zh": "塔科马",
        "ja": "タコマ",
        "th": "ทาโคมา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.2431,
        "lng": -122.4531
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2621,
      "name": "Rennes",
      "names": {
        "zh": "雷恩",
        "ja": "レンヌ",
        "th": "แรนส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1147,
        "lng": -1.6794
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 2652,
      "name": "Butembo",
      "names": {
        "zh": "布滕博"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 0.1251,
        "lng": 29.299
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 2650,
      "name": "Manta",
      "names": {
        "zh": "曼塔",
        "ja": "マンタ",
        "th": "ราหู"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -0.95,
        "lng": -80.7162
      },
      "country": "Ecuador",
      "importance": 3
    },
    {
      "id": 2654,
      "name": "Oviedo",
      "names": {
        "zh": "奥维多",
        "ja": "オビエド",
        "th": "โอเบียโด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.36,
        "lng": -5.845
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 2656,
      "name": "Concepcion",
      "names": {
        "es": "Concepción",
        "zh": "康塞普西翁",
        "ja": "コンセプシオン",
        "th": "กอนเซปซิยอน"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -36.8271,
        "lng": -73.0503
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 2568,
      "name": "Apapa",
      "names": {
        "zh": "阿帕帕"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.4489,
        "lng": 3.3589
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 2664,
      "name": "Denov",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 38.2772,
        "lng": 67.8872
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 2649,
      "name": "Tilburg",
      "names": {
        "zh": "蒂尔堡"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.57,
        "lng": 5.07
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 2655,
      "name": "Netanya",
      "names": {
        "th": "นาตาเนีย"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.3328,
        "lng": 34.86
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 2666,
      "name": "Qostanay",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 53.2144,
        "lng": 63.6246
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 2645,
      "name": "Mainz",
      "names": {
        "fr": "Mayence",
        "zh": "美因茨",
        "ja": "マインツ",
        "th": "ไมนซ์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.0,
        "lng": 8.2667
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 2660,
      "name": "Alor Setar",
      "names": {
        "zh": "阿洛斯·森林",
        "de": "Alor-Setar",
        "id": "Seterar"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 6.1167,
        "lng": 100.3667
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 2670,
      "name": "Gujiao",
      "names": {
        "zh": "古交"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9069,
        "lng": 112.17
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2610,
      "name": "Jose C. Paz",
      "names": {
        "zh": "何塞C.帕兹"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5167,
        "lng": -58.7667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 2667,
      "name": "Banjarbaru",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.4425,
        "lng": 114.8325
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2669,
      "name": "Valencia",
      "names": {
        "fr": "Valence",
        "zh": "瓦伦西亚",
        "ja": "バレンシア",
        "th": "วาเลนเซีย",
        "pt": "Valência"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.9,
        "lng": 125.0833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2626,
      "name": "Lutsk",
      "names": {
        "de": "Luzk",
        "th": "ลัตสก์"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.7478,
        "lng": 25.3244
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 2673,
      "name": "Petropavl",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 54.8622,
        "lng": 69.1408
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 2672,
      "name": "Cartagena",
      "names": {
        "zh": "卡塔赫纳",
        "ja": "カルタヘナ",
        "th": "คาร์"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.6,
        "lng": -0.9819
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2623,
      "name": "Sabadell",
      "names": {
        "zh": "萨瓦德尔"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.5483,
        "lng": 2.1075
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2671,
      "name": "Lubeck",
      "names": {
        "es": "Lübeck",
        "fr": "Lübeck",
        "zh": "吕贝克",
        "de": "Lübeck",
        "it": "Lubecca",
        "id": "Lübeck",
        "ja": "リューベック",
        "th": "ลือเบกค์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.8697,
        "lng": 10.6864
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2646,
      "name": "Hortolandia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8583,
        "lng": -47.22
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2678,
      "name": "San Bernardino",
      "names": {
        "zh": "圣伯纳迪诺",
        "ja": "サンバーナリディノ",
        "th": "ซานเบอร์นาดิโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1417,
        "lng": -117.2946
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2677,
      "name": "Gangneung",
      "names": {
        "zh": "江陵"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.7556,
        "lng": 128.8961
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 2675,
      "name": "Marg`ilon",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.4667,
        "lng": 71.7167
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 2682,
      "name": "College Station",
      "names": {
        "es": "Estación universitaria",
        "fr": "Station de collège",
        "zh": "大学站",
        "de": "College",
        "it": "Stazione del college",
        "id": "Stasiun perguruan tinggi",
        "ja": "カレッジステーション",
        "th": "สถานีวิทยาลัย",
        "pt": "Estação de faculdade"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.5852,
        "lng": -96.296
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2683,
      "name": "Golmud",
      "names": {
        "zh": "格尔木"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.4067,
        "lng": 94.9006
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2684,
      "name": "Kalamazoo",
      "names": {
        "zh": "卡拉马祖",
        "ja": "カラマズー",
        "th": "คาลา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2749,
        "lng": -85.5882
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2686,
      "name": "Regina",
      "names": {
        "zh": "里贾纳",
        "ja": "レジーナ",
        "th": "ริไจนา"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 50.4547,
        "lng": -104.6067
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 2687,
      "name": "Thousand Oaks",
      "names": {
        "es": "Mil robles",
        "fr": "Mille chênes",
        "zh": "千橡木",
        "de": "Tausend Eichen",
        "it": "Mille querce",
        "id": "Ribu pohon ek",
        "ja": "千貝",
        "th": "พันโอ๊ก",
        "pt": "Milhares de carvalhos"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1914,
        "lng": -118.8755
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2540,
      "name": "Guadalupe",
      "names": {
        "zh": "瓜达卢佩",
        "ja": "グアダルペ",
        "th": "กัวดาลูป"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.7528,
        "lng": -102.5078
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2680,
      "name": "As Samawah",
      "names": {
        "es": "Como Samawah",
        "fr": "Comme Samawah",
        "zh": "作为撒瓦",
        "de": "Als Samawah",
        "it": "Come Samawah",
        "id": "Sebagai samawah",
        "ja": "サマワとして",
        "th": "เช่นเดียวกับซาวาห์",
        "pt": "Como samawah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 31.3167,
        "lng": 45.2833
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 2665,
      "name": "Matrah",
      "names": {},
      "countryCode": "OM",
      "latLng": {
        "lat": 23.6167,
        "lng": 58.5667
      },
      "country": "Oman",
      "importance": 3
    },
    {
      "id": 2679,
      "name": "Cua",
      "names": {
        "zh": "河口"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1667,
        "lng": -66.8833
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2693,
      "name": "Roanoke",
      "names": {
        "zh": "罗阿诺克",
        "ja": "ロアノーク",
        "th": "โน๊ค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.2785,
        "lng": -79.958
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2681,
      "name": "Vantaa",
      "names": {
        "zh": "万塔机场",
        "ja": "ヴァンター"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.3,
        "lng": 25.0333
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 2697,
      "name": "Fontana",
      "names": {
        "zh": "丰塔纳",
        "ja": "フォンタナ",
        "th": "ตานา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0968,
        "lng": -117.4599
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2688,
      "name": "La Victoria",
      "names": {
        "th": "ลาวิคตอเรีย"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2278,
        "lng": -67.3336
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2699,
      "name": "Warnes",
      "names": {},
      "countryCode": "BO",
      "latLng": {
        "lat": -17.5103,
        "lng": -63.1647
      },
      "country": "Bolivia",
      "importance": 3
    },
    {
      "id": 2696,
      "name": "Sete Lagoas",
      "names": {
        "es": "Sede lagoas",
        "ja": "セットラゴアス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.4658,
        "lng": -44.2469
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2674,
      "name": "Sao Leopoldo",
      "names": {
        "zh": "圣莱奥波戈",
        "ja": "サンレオポルド",
        "pt": "São Leopoldo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.76,
        "lng": -51.1469
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2698,
      "name": "Sikasso",
      "names": {
        "zh": "锡卡索"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 11.3167,
        "lng": -5.6667
      },
      "country": "Mali",
      "importance": 1
    },
    {
      "id": 2651,
      "name": "Osan",
      "names": {
        "zh": "乌山"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.145,
        "lng": 127.0694
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 2695,
      "name": "Erfurt",
      "names": {
        "zh": "爱尔福特",
        "ja": "エルフルト",
        "th": "แอร์ฟูร์ท"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9781,
        "lng": 11.0292
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 2607,
      "name": "Secunderabad",
      "names": {
        "zh": "塞康德拉巴德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 17.45,
        "lng": 78.5
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2668,
      "name": "Sa Dec",
      "names": {
        "es": "Sa dic",
        "de": "SA",
        "it": "Sambre",
        "id": "Sa Desember",
        "pt": "Sa de dezembro"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.3105,
        "lng": 105.7397
      },
      "country": "Vietnam",
      "importance": 2
    },
    {
      "id": 2694,
      "name": "Rui\u0027an",
      "names": {
        "zh": "瑞安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.778,
        "lng": 120.6526
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2701,
      "name": "El Tigre",
      "names": {
        "fr": "El tagre",
        "zh": "埃尔蒂格雷",
        "ja": "エルティグレ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.8858,
        "lng": -64.2611
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2704,
      "name": "Moreno Valley",
      "names": {
        "es": "Valle de Moreno",
        "fr": "Vallée de Moreno",
        "zh": "莫雷诺山谷",
        "de": "Morteno-Tal",
        "id": "Lembah Moreno",
        "ja": "モレノ渓谷"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9244,
        "lng": -117.2045
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2662,
      "name": "Luton",
      "names": {
        "zh": "卢顿",
        "ja": "ルートン",
        "th": "ลูตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.8783,
        "lng": -0.4147
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2702,
      "name": "Divinopolis",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.1389,
        "lng": -44.8839
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2703,
      "name": "Colombo",
      "names": {
        "zh": "科伦坡",
        "ja": "コロンボ",
        "th": "โคลัมโบ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.2919,
        "lng": -49.2239
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2706,
      "name": "Sidi Bel Abbes",
      "names": {
        "zh": "斯蒂贝布斯"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.2,
        "lng": -0.6333
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 2705,
      "name": "Jerez de la Frontera",
      "names": {
        "zh": "赫雷斯德拉弗隆特拉"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.6817,
        "lng": -6.1378
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2707,
      "name": "Limbe",
      "names": {
        "zh": "林贝"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.0167,
        "lng": 9.2167
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 2690,
      "name": "Babruysk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.15,
        "lng": 29.2333
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 2708,
      "name": "Kure",
      "names": {
        "zh": "吴"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2492,
        "lng": 132.5658
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2691,
      "name": "Northampton",
      "names": {
        "zh": "北安普顿",
        "ja": "ノースアンプトン",
        "th": "นอร์ท"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.2304,
        "lng": -0.8938
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2609,
      "name": "Bursa",
      "names": {
        "zh": "布尔萨",
        "ja": "ブルサ",
        "th": "บูร์ซา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1833,
        "lng": 29.0667
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 2714,
      "name": "Criciuma",
      "names": {
        "zh": "克里西乌马"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.6775,
        "lng": -49.3697
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2721,
      "name": "Babylon",
      "names": {
        "es": "Babilonia",
        "fr": "Babylone",
        "zh": "巴比伦",
        "it": "Babilonia",
        "id": "Babel",
        "ja": "バビロン",
        "th": "บาบิโลน",
        "pt": "Babilônia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6924,
        "lng": -73.3585
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2722,
      "name": "Chungju",
      "names": {
        "zh": "忠州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.9706,
        "lng": 127.9322
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 2727,
      "name": "Barrancabermeja",
      "names": {
        "zh": "巴兰卡韦梅哈"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0675,
        "lng": -73.8472
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 2720,
      "name": "Burgas",
      "names": {
        "zh": "布尔加斯",
        "th": "บูร์กาส"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.503,
        "lng": 27.4702
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 2559,
      "name": "Taito",
      "names": {
        "zh": "泰拓",
        "ja": "台東区"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7126,
        "lng": 139.78
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2676,
      "name": "Trece Martires City",
      "names": {
        "es": "Trece Martirires City",
        "de": "Trece Martes City",
        "ja": "Trece Martirs City",
        "pt": "Cidade de Trécte Martires"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2833,
        "lng": 120.8667
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2725,
      "name": "Pagadian",
      "names": {
        "zh": "帕加迪安"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.8333,
        "lng": 123.4333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2709,
      "name": "Padova",
      "names": {
        "zh": "帕多瓦",
        "ja": "パドバ",
        "th": "ปาโดวา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4064,
        "lng": 11.8778
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 2717,
      "name": "Isesaki",
      "names": {
        "zh": "伊势崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3167,
        "lng": 139.2
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2728,
      "name": "San-Pedro",
      "names": {
        "es": "San Pedro,",
        "fr": "-San Pedro",
        "zh": "的SAN-佩德罗",
        "it": "-San Pedro",
        "ja": "サン・ペドロ",
        "th": "ซาน-เปโดร"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 4.7704,
        "lng": -6.64
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 2710,
      "name": "Arroyo Naranjo",
      "names": {
        "ja": "南城アロヨ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 23.0436,
        "lng": -82.3328
      },
      "country": "Cuba",
      "importance": 3
    },
    {
      "id": 2729,
      "name": "Bukit Mertajam",
      "names": {},
      "countryCode": "MY",
      "latLng": {
        "lat": 5.3631,
        "lng": 100.4667
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 2737,
      "name": "Dongning",
      "names": {
        "zh": "东宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.0608,
        "lng": 131.1187
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2712,
      "name": "Ploiesti",
      "names": {
        "zh": "普洛耶什蒂"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.9386,
        "lng": 26.0225
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 2715,
      "name": "Pskov",
      "names": {
        "zh": "普斯科夫",
        "de": "Pskow",
        "ja": "プスコフ",
        "th": "ปัสคอฟ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.8167,
        "lng": 28.3333
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2740,
      "name": "Burutu",
      "names": {
        "zh": "布鲁图"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 5.35,
        "lng": 5.5167
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 2700,
      "name": "Mostoles",
      "names": {
        "zh": "莫斯托莱斯"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.3333,
        "lng": -3.8667
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2713,
      "name": "Oberhausen",
      "names": {
        "zh": "奥伯豪森",
        "ja": "オーバーハウゼン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4699,
        "lng": 6.8514
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2726,
      "name": "Legazpi City",
      "names": {
        "es": "Ciudad de Legazpi",
        "fr": "La ville de Legazpi",
        "zh": "Legazpi市",
        "de": "Legazpi-Stadt",
        "id": "Kota Legazpi",
        "ja": "レガツィシティ",
        "th": "เมือง Legazpi",
        "pt": "Cidade de Legazpi"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.1333,
        "lng": 123.7333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2724,
      "name": "Tunja",
      "names": {
        "zh": "通哈"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5403,
        "lng": -73.3614
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 2736,
      "name": "Panabo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.3,
        "lng": 125.6833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2731,
      "name": "Ijebu-Ode",
      "names": {
        "zh": "ijebu-常微分方程",
        "ja": "ijebu-オード",
        "th": "ijebu-บทกวี"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.8204,
        "lng": 3.92
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 2719,
      "name": "Naga City",
      "names": {
        "es": "Ciudad de Naga",
        "fr": "Ville de Naga",
        "zh": "纳卡市",
        "de": "Naga-Stadt",
        "it": "Città naga",
        "id": "Kota naga",
        "ja": "長永市",
        "th": "เมืองนาค",
        "pt": "Cidade de naga"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.6167,
        "lng": 123.1667
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2747,
      "name": "Puerto Cabello",
      "names": {
        "zh": "波多黎各",
        "ja": "プエルトカベロ",
        "th": "เปอร์โตคาแชลโล"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4705,
        "lng": -68.0296
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2750,
      "name": "North Port",
      "names": {
        "es": "Puerto norte",
        "fr": "Port nord",
        "zh": "北口",
        "de": "Nordhafen",
        "it": "Porto nord",
        "id": "Pelabuhan utara",
        "ja": "ノースポート",
        "th": "พอร์ตเหนือ",
        "pt": "Porto norte"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.0577,
        "lng": -82.1975
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2733,
      "name": "Rostock",
      "names": {
        "zh": "罗斯托克",
        "ja": "ロストック"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.0833,
        "lng": 12.1333
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 2723,
      "name": "Maracanau",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.8769,
        "lng": -38.6258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2744,
      "name": "Ich\u0027on",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.2792,
        "lng": 127.4425
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 2748,
      "name": "Beersheba",
      "names": {
        "zh": "别是巴",
        "id": "Bersyeba",
        "pt": "Berseba"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.2589,
        "lng": 34.7978
      },
      "country": "Israel",
      "importance": 1
    },
    {
      "id": 2716,
      "name": "Bila Tserkva",
      "names": {
        "ja": "ビラ・ツェルクバ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.7956,
        "lng": 30.1167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 2753,
      "name": "Mary",
      "names": {
        "es": "María",
        "zh": "玛丽",
        "de": "Maria",
        "ja": "メアリー",
        "th": "แมรี่",
        "pt": "Maria"
      },
      "countryCode": "TM",
      "latLng": {
        "lat": 37.6,
        "lng": 61.8333
      },
      "country": "Turkmenistan",
      "importance": 1
    },
    {
      "id": 2754,
      "name": "Fargo",
      "names": {
        "zh": "法戈",
        "ja": "ファーゴ",
        "th": "ฟาร์โก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.8652,
        "lng": -96.8292
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2734,
      "name": "Cork",
      "names": {
        "es": "Corcho",
        "zh": "科克",
        "de": "Kork",
        "it": "Sughero",
        "id": "Sumbat",
        "ja": "コルク",
        "th": "ก๊อก",
        "pt": "Cortiça"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 51.9,
        "lng": -8.4731
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 2735,
      "name": "Guarenas",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4739,
        "lng": -66.5383
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2692,
      "name": "Mohammedia",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.6833,
        "lng": -7.3833
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 2732,
      "name": "Taisheng",
      "names": {
        "zh": "泰盛"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.2938,
        "lng": 113.353
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2738,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 28.4667,
        "lng": -16.25
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 2743,
      "name": "Pinghu",
      "names": {
        "zh": "平湖"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.7005,
        "lng": 121.0104
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2752,
      "name": "Lubuklinggau",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.2967,
        "lng": 102.8617
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2760,
      "name": "Waitakere",
      "names": {
        "zh": "怀塔尔基市"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -36.849,
        "lng": 174.543
      },
      "country": "New Zealand",
      "importance": 3
    },
    {
      "id": 2751,
      "name": "Almere",
      "names": {
        "zh": "阿尔默勒"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3758,
        "lng": 5.2256
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 2658,
      "name": "Zamora",
      "names": {
        "zh": "萨莫拉",
        "ja": "サモラ",
        "th": "ซาโมร่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9833,
        "lng": -102.2833
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 2759,
      "name": "Kharagpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.3302,
        "lng": 87.3237
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2764,
      "name": "Green Bay",
      "names": {
        "es": "Bahía verde",
        "fr": "Verte",
        "zh": "绿湾",
        "de": "Grüne Bucht",
        "it": "Baia verde",
        "ja": "グリーンベイ",
        "th": "กรีนเบย์",
        "pt": "Baía verde"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.515,
        "lng": -87.9896
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2761,
      "name": "Monywa",
      "names": {
        "zh": "蒙育瓦"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 22.1086,
        "lng": 95.1358
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 2762,
      "name": "Dindigul",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.369,
        "lng": 77.9804
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2758,
      "name": "Toledo",
      "names": {
        "zh": "托莱多",
        "ja": "トレド",
        "th": "โทเลโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3833,
        "lng": 123.65
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2770,
      "name": "Spring Valley",
      "names": {
        "es": "Valle de primavera",
        "fr": "Vallée de printemps",
        "zh": "春谷",
        "de": "Frühlingsvalley",
        "it": "Primavera",
        "id": "Lembah musim semi",
        "ja": "スプリングバレー",
        "th": "หุบเขาฤดูใบไม้ผลิ",
        "pt": "Vale de primavera"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0987,
        "lng": -115.2619
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2631,
      "name": "Nishitokyo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7258,
        "lng": 139.5386
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2745,
      "name": "Milton Keynes",
      "names": {
        "zh": "米尔顿凯恩斯",
        "ja": "ミルトンケインズ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.04,
        "lng": -0.76
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2746,
      "name": "Marawi City",
      "names": {
        "es": "Ciudad de Marawi",
        "fr": "Ville de Marawi",
        "zh": "马拉维市",
        "de": "Marawi-Stadt",
        "id": "Kota marawi",
        "ja": "マラウイシティ",
        "th": "เมืองมาร์วี่",
        "pt": "Cidade de marawi"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.0,
        "lng": 124.3
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2763,
      "name": "Morogoro",
      "names": {
        "zh": "莫罗戈罗"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.8242,
        "lng": 37.6633
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 2769,
      "name": "Portoviejo",
      "names": {
        "zh": "波托维耶霍"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -1.0544,
        "lng": -80.4544
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 2739,
      "name": "Solihull",
      "names": {
        "zh": "索利赫尔"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.413,
        "lng": -1.778
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2636,
      "name": "Damietta",
      "names": {
        "zh": "达米埃塔"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.4167,
        "lng": 31.8214
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 2689,
      "name": "Shimla",
      "names": {
        "zh": "西姆拉",
        "ja": "シムラー",
        "th": "ชิมลา"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 31.1033,
        "lng": 77.1722
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 2755,
      "name": "Cascais",
      "names": {
        "zh": "卡斯卡伊斯",
        "ja": "カスカイス",
        "th": "กาส์เซส์"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6969,
        "lng": -9.4206
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 2767,
      "name": "Cidade de Nacala",
      "names": {
        "ja": "De Nacalaを準備します"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -14.55,
        "lng": 40.6833
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 2777,
      "name": "Kalemie",
      "names": {
        "zh": "卡莱米"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.9475,
        "lng": 29.1947
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 2768,
      "name": "Talca",
      "names": {
        "zh": "塔尔卡"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -35.4269,
        "lng": -71.6656
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 2786,
      "name": "Zanzibar",
      "names": {
        "zh": "桑给巴尔",
        "ja": "ザンジバル",
        "th": "แซนซิบาร์"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.1667,
        "lng": 39.2
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 2756,
      "name": "Northcote",
      "names": {
        "zh": "诺斯科特"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -36.7913,
        "lng": 174.7758
      },
      "country": "New Zealand",
      "importance": 3
    },
    {
      "id": 2789,
      "name": "Amarillo",
      "names": {
        "zh": "阿马里洛",
        "ja": "アマリロ",
        "th": "อามาริลโล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1988,
        "lng": -101.8311
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2781,
      "name": "Ingraj Bazar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.0,
        "lng": 88.15
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2773,
      "name": "San Carlos City",
      "names": {
        "es": "Ciudad de san carlos",
        "zh": "圣卡洛斯市",
        "id": "Kota San Carlos",
        "ja": "サンカルロスシティ",
        "th": "ซานคาร์ลอสซิตี้",
        "pt": "Cidade de san carlos"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9281,
        "lng": 120.3489
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2787,
      "name": "Jiutai",
      "names": {
        "zh": "九台"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.1447,
        "lng": 125.8443
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2783,
      "name": "Mytishchi",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.9167,
        "lng": 37.7333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2776,
      "name": "Phan Thiet",
      "names": {
        "zh": "藩切",
        "ja": "ファンティエット",
        "th": "พานเทียต"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.9375,
        "lng": 108.1583
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 2791,
      "name": "Ziguinchor",
      "names": {
        "zh": "济金绍尔"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 12.5833,
        "lng": -16.2667
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 2793,
      "name": "Puqi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 29.7204,
        "lng": 113.88
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2779,
      "name": "Loures",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.8333,
        "lng": -9.1667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 2788,
      "name": "Qa\u0027em Shahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.4611,
        "lng": 52.8606
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 2771,
      "name": "Linz",
      "names": {
        "zh": "林茨",
        "ja": "リンツ",
        "th": "ลินซ์"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.3,
        "lng": 14.2833
      },
      "country": "Austria",
      "importance": 1
    },
    {
      "id": 2785,
      "name": "Ongole",
      "names": {
        "zh": "翁戈尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 15.5,
        "lng": 80.05
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2780,
      "name": "Biskra",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.85,
        "lng": 5.7333
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 2772,
      "name": "Trieste",
      "names": {
        "zh": "里雅斯特",
        "de": "Triest",
        "ja": "トリエステ",
        "th": "เอสเต"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.6503,
        "lng": 13.7703
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 2792,
      "name": "Ternate",
      "names": {
        "zh": "特尔纳特"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.7833,
        "lng": 127.3667
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2797,
      "name": "Coquimbo",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -29.9532,
        "lng": -71.338
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 2798,
      "name": "Tekirdag",
      "names": {
        "zh": "泰基尔达"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9833,
        "lng": 27.5167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 2711,
      "name": "Colon",
      "names": {
        "fr": "Côlon",
        "zh": "结肠",
        "id": "Usus besar",
        "ja": "コロン",
        "th": "ลำไส้ใหญ่",
        "pt": "Cólon"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 9.365,
        "lng": -79.875
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 2800,
      "name": "Portland",
      "names": {
        "zh": "波特兰",
        "ja": "ポートランド",
        "th": "พอร์ตแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.6773,
        "lng": -70.2715
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2790,
      "name": "Ellore",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.7117,
        "lng": 81.1031
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2806,
      "name": "La Guaira",
      "names": {
        "ja": "ラガライラ",
        "th": "คนใหญ่"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.6,
        "lng": -66.9331
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 2796,
      "name": "Mandi Burewala",
      "names": {
        "pt": "Mandi Busewala"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.15,
        "lng": 72.6833
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 2730,
      "name": "Puerto Vallarta",
      "names": {
        "zh": "波多黎各港",
        "ja": "プエルトバジャルタ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6458,
        "lng": -105.2222
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2805,
      "name": "Lanxi",
      "names": {
        "zh": "兰溪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.2167,
        "lng": 119.4722
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2807,
      "name": "Mazabuka",
      "names": {
        "zh": "马扎布卡"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -15.8667,
        "lng": 27.7667
      },
      "country": "Zambia",
      "importance": 3
    },
    {
      "id": 2802,
      "name": "Biysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.5167,
        "lng": 85.1667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2718,
      "name": "Pamplona",
      "names": {
        "fr": "Pampelune",
        "zh": "潘普洛纳",
        "ja": "パンプローナ",
        "th": "ปัมโปลนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.8167,
        "lng": -1.65
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 2811,
      "name": "Tanjungpinang",
      "names": {
        "zh": "丹戎槟榔"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.9188,
        "lng": 104.4554
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 2813,
      "name": "Djougou",
      "names": {
        "zh": "朱古"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 9.7004,
        "lng": 1.68
      },
      "country": "Benin",
      "importance": 1
    },
    {
      "id": 2799,
      "name": "Parnamirim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.9158,
        "lng": -35.2628
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2817,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.4285,
        "lng": -119.7202
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2818,
      "name": "Gainesville",
      "names": {
        "zh": "盖恩斯维尔",
        "ja": "ゲインズビル",
        "th": "เกนส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.6804,
        "lng": -82.3458
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2815,
      "name": "Luxor",
      "names": {
        "zh": "卢克索",
        "ja": "ルクソール",
        "th": "ลุกซอร์"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 25.6969,
        "lng": 32.6422
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 2823,
      "name": "Arica",
      "names": {
        "zh": "阿里卡",
        "th": "อาริคา"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -18.4784,
        "lng": -70.3211
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 2819,
      "name": "Las Tunas",
      "names": {
        "es": "Las atunes",
        "fr": "Tunas Las",
        "zh": "拉斯金枪鱼",
        "de": "Las Thuns",
        "ja": "ラスマグロ",
        "pt": "Atunas las"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.9667,
        "lng": -76.95
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 2824,
      "name": "Al Khums",
      "names": {
        "de": "Alkhums",
        "ja": "アルコム",
        "th": "อัล khums"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.6604,
        "lng": 14.26
      },
      "country": "Libya",
      "importance": 3
    },
    {
      "id": 2685,
      "name": "Geneva",
      "names": {
        "es": "Ginebra",
        "fr": "Genève",
        "zh": "日内瓦",
        "de": "Genf",
        "it": "Ginevra",
        "id": "Jenewa",
        "ja": "ジュネーブ",
        "th": "เจนีวา",
        "pt": "Genebra"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.2,
        "lng": 6.15
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 2803,
      "name": "Charleroi",
      "names": {
        "zh": "沙勒罗瓦",
        "ja": "シャルルロワ",
        "th": "ชาร์เลอรัว"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.4167,
        "lng": 4.4442
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 2821,
      "name": "Matsue",
      "names": {
        "zh": "松江",
        "ja": "松江"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4681,
        "lng": 133.0486
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2829,
      "name": "Huntington",
      "names": {
        "zh": "亨廷顿",
        "ja": "ハンティントン",
        "th": "ฮันติงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8522,
        "lng": -73.3824
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2816,
      "name": "Tarakan",
      "names": {
        "zh": "塔拉坎"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 3.3,
        "lng": 117.6333
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2765,
      "name": "Pingzhen",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 24.9439,
        "lng": 121.2161
      },
      "country": "Taiwan",
      "importance": 3
    },
    {
      "id": 2831,
      "name": "Lashkar Gah",
      "names": {
        "zh": "拉什卡尔"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 31.5938,
        "lng": 64.3716
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 2801,
      "name": "Groningen",
      "names": {
        "zh": "格罗宁根",
        "ja": "グローニンゲン",
        "th": "โกร"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2167,
        "lng": 6.5667
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 2810,
      "name": "Torun",
      "names": {
        "zh": "托伦",
        "ja": "トルン",
        "th": "โตรัน"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.0167,
        "lng": 18.6167
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 2828,
      "name": "Cajamarca",
      "names": {
        "zh": "卡哈马卡"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -7.1644,
        "lng": -78.5106
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 2836,
      "name": "Tokat",
      "names": {
        "zh": "托卡特",
        "ja": "トカト"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.3097,
        "lng": 36.5542
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 2757,
      "name": "Qina",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 26.1667,
        "lng": 32.7167
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 2832,
      "name": "Mai\u0027Adua",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 13.1906,
        "lng": 8.2117
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 2812,
      "name": "Kassel",
      "names": {
        "zh": "卡塞尔",
        "ja": "カッセル",
        "th": "คาสเซิล"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3158,
        "lng": 9.4979
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2601,
      "name": "Puri",
      "names": {
        "zh": "普里",
        "ja": "プリ",
        "th": "ในปูรี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 19.8,
        "lng": 85.8167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2835,
      "name": "Debrecen",
      "names": {
        "zh": "德布勒森",
        "ja": "デブレツェン",
        "th": "บรี"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.53,
        "lng": 21.6392
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 2839,
      "name": "Olympia",
      "names": {
        "zh": "奥林匹亚",
        "ja": "オリンピア",
        "th": "โอลิมเปีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.0417,
        "lng": -122.8959
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 2838,
      "name": "Qyzylorda",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 44.8479,
        "lng": 65.4999
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 2814,
      "name": "Haldia",
      "names": {
        "zh": "霍尔迪亚"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.03,
        "lng": 88.06
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2833,
      "name": "Jacobabad",
      "names": {
        "zh": "雅各布阿巴德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 28.2769,
        "lng": 68.4514
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 2834,
      "name": "Almeria",
      "names": {
        "es": "Almería",
        "zh": "阿尔梅里亚",
        "ja": "アルメリア",
        "th": "อัลเมเรีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.8403,
        "lng": -2.4681
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 2804,
      "name": "Aberdeen",
      "names": {
        "zh": "阿伯丁",
        "ja": "アバディーン",
        "th": "อเบอร์ดีน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 57.15,
        "lng": -2.11
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2825,
      "name": "Huacho",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -11.1083,
        "lng": -77.6083
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 2795,
      "name": "Yachiyo",
      "names": {
        "zh": "八千代"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7225,
        "lng": 140.0997
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2841,
      "name": "Oulu",
      "names": {
        "zh": "奥卢",
        "ja": "オウル",
        "th": "อูลู"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 65.0142,
        "lng": 25.4719
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 766,
      "name": "Georgetown",
      "names": {
        "zh": "乔治敦",
        "ja": "ジョージタウン",
        "th": "จอร์จทาวน์"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.7833,
        "lng": -58.1667
      },
      "country": "Guyana",
      "importance": 0
    },
    {
      "id": 2843,
      "name": "Frisco",
      "names": {
        "zh": "弗里斯科",
        "ja": "フリスコ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1555,
        "lng": -96.8215
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2741,
      "name": "Nasim Shahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.5644,
        "lng": 51.1647
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2846,
      "name": "Yonkers",
      "names": {
        "zh": "扬克斯"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9466,
        "lng": -73.8674
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2784,
      "name": "Nagareyama",
      "names": {
        "zh": "流山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8561,
        "lng": 139.9025
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2848,
      "name": "Norwich",
      "names": {
        "zh": "诺里奇",
        "ja": "ノーリッチ",
        "th": "นอริช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5495,
        "lng": -72.0882
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2840,
      "name": "Guacara",
      "names": {
        "zh": "瓜卡拉"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2333,
        "lng": -67.8833
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2844,
      "name": "Kabankalan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.9833,
        "lng": 122.8167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2809,
      "name": "Poza Rica de Hidalgo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5333,
        "lng": -97.45
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2566,
      "name": "Sidon",
      "names": {
        "es": "Sidón",
        "zh": "西顿",
        "it": "Sidone"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 33.5606,
        "lng": 35.3758
      },
      "country": "Lebanon",
      "importance": 1
    },
    {
      "id": 2742,
      "name": "Moratuwa",
      "names": {
        "zh": "莫勒图沃"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 6.7804,
        "lng": 79.88
      },
      "country": "Sri Lanka",
      "importance": 3
    },
    {
      "id": 2808,
      "name": "Zhubei",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 24.8333,
        "lng": 121.0
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 2837,
      "name": "Nema",
      "names": {
        "zh": "的NEMA"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 16.6171,
        "lng": -7.25
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 2850,
      "name": "Az Zawiyah",
      "names": {
        "ja": "Zawiyah az"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.7604,
        "lng": 12.72
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 2851,
      "name": "Kasama",
      "names": {
        "zh": "笠间"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -10.1996,
        "lng": 31.1799
      },
      "country": "Zambia",
      "importance": 1
    },
    {
      "id": 2852,
      "name": "Puerto Cortes",
      "names": {
        "id": "Cortes Puerto",
        "ja": "プエルトコルテス",
        "th": "เปอร์โตคอร์เทส"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.8833,
        "lng": -87.95
      },
      "country": "Honduras",
      "importance": 3
    },
    {
      "id": 2822,
      "name": "Sosnowiec",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.3,
        "lng": 19.1667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 2847,
      "name": "Rio Claro",
      "names": {
        "zh": "里奥克洛",
        "ja": "リオクラロ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4108,
        "lng": -47.5608
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2854,
      "name": "Lahad Datu",
      "names": {
        "zh": "拉哈德Datu",
        "ja": "ラハードのデータ"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.03,
        "lng": 118.34
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 2842,
      "name": "Maimanah",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 35.9302,
        "lng": 64.7701
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 2855,
      "name": "Bojnurd",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.4667,
        "lng": 57.3333
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 2860,
      "name": "Glendale",
      "names": {
        "zh": "格伦代尔",
        "ja": "グレンデール",
        "th": "เกลนเดล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1818,
        "lng": -118.2468
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2861,
      "name": "Huntington Beach",
      "names": {
        "es": "Playa Huntington",
        "fr": "Plage de Huntington",
        "zh": "亨廷顿海滩",
        "id": "Pantai Huntington",
        "ja": "ハンティントンビーチ",
        "th": "ฮันติงตันบีช",
        "pt": "Praia de huntington"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.696,
        "lng": -118.0025
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2859,
      "name": "Banja Luka",
      "names": {
        "ja": "バンジャルカ"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.7667,
        "lng": 17.1833
      },
      "country": "Bosnia And Herzegovina",
      "importance": 1
    },
    {
      "id": 2749,
      "name": "Huangyan",
      "names": {
        "zh": "黄岩"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.65,
        "lng": 121.25
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2849,
      "name": "Bulandshahr",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.4,
        "lng": 77.85
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2775,
      "name": "Itami",
      "names": {
        "zh": "伊丹",
        "ja": "伊丹",
        "th": "อิตามิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7867,
        "lng": 135.4056
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2868,
      "name": "Deltona",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 28.905,
        "lng": -81.2137
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2869,
      "name": "Richmond",
      "names": {
        "zh": "里士满",
        "ja": "リッチモンド",
        "th": "ริชมอนด์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.1667,
        "lng": -123.1333
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2862,
      "name": "Taranto",
      "names": {
        "es": "Tarento",
        "zh": "塔兰托",
        "ja": "ターラント",
        "th": "ชาติตารันโต"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.4181,
        "lng": 17.2408
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 2864,
      "name": "Martapura",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.4135,
        "lng": 114.8365
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 2872,
      "name": "Gemena",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": 3.25,
        "lng": 19.7667
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 2865,
      "name": "Chakradharpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.7,
        "lng": 85.63
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2874,
      "name": "Helong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.5404,
        "lng": 129.0039
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2877,
      "name": "Aurora",
      "names": {
        "zh": "奥罗拉",
        "ja": "オーロラ",
        "th": "ออโรรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7637,
        "lng": -88.2901
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2820,
      "name": "Lyubertsy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.6814,
        "lng": 37.8939
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2880,
      "name": "Bhiwani",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.7833,
        "lng": 76.1333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2883,
      "name": "Mbanza-Ngungu",
      "names": {
        "zh": "姆班扎-ngungu"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.25,
        "lng": 14.8667
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 2866,
      "name": "Tocuyito",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0889,
        "lng": -68.0922
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2863,
      "name": "Zhugang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.1277,
        "lng": 121.2288
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2881,
      "name": "Higashi-Hiroshima",
      "names": {
        "zh": "东-广岛",
        "ja": "東・広島",
        "th": "Higashi-ฮิโรชิมา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4167,
        "lng": 132.7333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2826,
      "name": "Gurgaon",
      "names": {
        "zh": "古尔冈",
        "ja": "グルガオン",
        "th": "คุร์เคาน์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 28.45,
        "lng": 77.02
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2878,
      "name": "Burhanpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.3,
        "lng": 76.2267
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2887,
      "name": "Rio Grande",
      "names": {
        "zh": "里奥格兰德",
        "ja": "リオグランデ",
        "th": "ริโอแกรนด์",
        "pt": "Rude"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -32.035,
        "lng": -52.0989
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2830,
      "name": "Ciudad Madero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.25,
        "lng": -97.8333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2774,
      "name": "Kodaira",
      "names": {
        "zh": "小平"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7285,
        "lng": 139.4774
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2889,
      "name": "Rajin",
      "names": {
        "zh": "罗津"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 42.3444,
        "lng": 130.3844
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 2794,
      "name": "La Ceiba",
      "names": {
        "zh": "洛杉矶",
        "ja": "ラセイバ"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.78,
        "lng": -86.7878
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 2892,
      "name": "Brescia",
      "names": {
        "zh": "布雷西亚",
        "ja": "ブレーシア",
        "th": "เบรสชา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5389,
        "lng": 10.2203
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 2882,
      "name": "Huanuco",
      "names": {
        "es": "Huánuco",
        "zh": "瓦努科"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -9.9294,
        "lng": -76.2397
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 2858,
      "name": "Liege",
      "names": {
        "es": "Lieja",
        "fr": "Liège",
        "zh": "列日",
        "de": "Lüttich",
        "it": "Liegi",
        "id": "Liège",
        "ja": "リエージュ",
        "th": "ลีแอช"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6397,
        "lng": 5.5706
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 2893,
      "name": "Kusong",
      "names": {
        "zh": "龟城"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 39.9667,
        "lng": 125.1667
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 2886,
      "name": "Zipaquira",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0247,
        "lng": -74.0014
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 2888,
      "name": "Prokopyevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.8833,
        "lng": 86.7167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2873,
      "name": "Oradea",
      "names": {
        "zh": "奥拉迪亚",
        "ja": "オラデア",
        "th": "ออราเดีย"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.0722,
        "lng": 21.9211
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 2879,
      "name": "Khammam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.25,
        "lng": 80.15
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2853,
      "name": "Kouribga",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 32.88,
        "lng": -6.9
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 2876,
      "name": "Madhyamgram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.7,
        "lng": 88.45
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2871,
      "name": "Alcala de Henares",
      "names": {
        "es": "Alcalá de Henares"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.4818,
        "lng": -3.3643
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2856,
      "name": "Norwich",
      "names": {
        "zh": "诺里奇",
        "ja": "ノーリッチ",
        "th": "นอริช"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.63,
        "lng": 1.297
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2891,
      "name": "Ghandinagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.22,
        "lng": 72.68
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 2906,
      "name": "Tempe",
      "names": {
        "zh": "坦佩",
        "ja": "テンペ",
        "th": "เทมพี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.3881,
        "lng": -111.9318
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2898,
      "name": "Parma",
      "names": {
        "fr": "Parme",
        "zh": "帕尔马",
        "ja": "パルマ",
        "th": "ปาร์มา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.8015,
        "lng": 10.328
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 2867,
      "name": "Alvorada",
      "names": {
        "zh": "阿尔沃拉达"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.99,
        "lng": -51.0839
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2895,
      "name": "Acarigua",
      "names": {
        "zh": "阿卡里瓜"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.5597,
        "lng": -69.2019
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2904,
      "name": "Iskandar",
      "names": {
        "zh": "干达"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.5507,
        "lng": 69.6807
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 2905,
      "name": "Karakopru",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.1803,
        "lng": 38.8111
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 2910,
      "name": "Overland Park",
      "names": {
        "es": "Parque Overland",
        "fr": "Parc terrestre",
        "zh": "欧弗兰帕克",
        "it": "Parco Overland",
        "id": "Taman Overland",
        "ja": "オーバーランドパーク",
        "th": "สวนสาธารณะ",
        "pt": "Parque Overland"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.887,
        "lng": -94.687
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2902,
      "name": "Koronadal",
      "names": {
        "zh": "科罗纳达尔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.5,
        "lng": 124.85
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2909,
      "name": "La Serena",
      "names": {
        "th": "ลาเซเรน่า"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -29.9027,
        "lng": -71.252
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 2897,
      "name": "San Pedro de Macoris",
      "names": {
        "ja": "サンペドロデマコリス",
        "th": "ซานเปโดรเดอมาโกเรส"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4572,
        "lng": -69.3061
      },
      "country": "Dominican Republic",
      "importance": 1
    },
    {
      "id": 2901,
      "name": "Suzuka",
      "names": {
        "zh": "铃鹿",
        "ja": "鈴鹿"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8822,
        "lng": 136.5842
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2857,
      "name": "Baharampur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.1,
        "lng": 88.25
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2911,
      "name": "Bandar-e Bushehr",
      "names": {
        "ja": "バンダルeブシェー",
        "pt": "Bandar-e busiahr"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 28.9667,
        "lng": 50.8333
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 2900,
      "name": "Brikama",
      "names": {},
      "countryCode": "GM",
      "latLng": {
        "lat": 13.2804,
        "lng": -16.6599
      },
      "country": "The Gambia",
      "importance": 1
    },
    {
      "id": 2913,
      "name": "Tchitato",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -7.345,
        "lng": 20.8198
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 2916,
      "name": "Richmond Hill",
      "names": {
        "zh": "里士满山",
        "ja": "リッチモンドヒル",
        "th": "ริชมอนด์ฮิลล์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.8667,
        "lng": -79.4333
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2827,
      "name": "El Jadid",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.2342,
        "lng": -8.5228
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 2915,
      "name": "Yuzhno-Sakhalinsk",
      "names": {
        "zh": "南萨哈林斯克",
        "de": "Juschno-sachalinsk",
        "th": "yuzhno-ซาคาลินสก์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 46.95,
        "lng": 142.7333
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 2903,
      "name": "Cuautla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8167,
        "lng": -98.95
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 2914,
      "name": "Anseong",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.0078,
        "lng": 127.2797
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 2921,
      "name": "Grand Prairie",
      "names": {
        "es": "Pradera",
        "zh": "大草原",
        "de": "Grand Prärie",
        "id": "Padang rumput",
        "ja": "グランドプレーリー",
        "th": "แกรนด์ทุ่งหญ้า",
        "pt": "Grand pradaria"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.6872,
        "lng": -97.0209
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2919,
      "name": "Guanajuato",
      "names": {
        "zh": "瓜纳华托",
        "th": "กวานาวาโต"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0178,
        "lng": -101.2567
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 2923,
      "name": "Tebessa",
      "names": {
        "fr": "Tébessa",
        "zh": "特贝萨"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.4,
        "lng": 8.1167
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 2778,
      "name": "Kamirenjaku",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6833,
        "lng": 139.5594
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2925,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 7.894,
        "lng": -67.473
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 2926,
      "name": "Al Jahra\u0027",
      "names": {
        "es": "Al Jahra \u0027",
        "fr": "Al Jahra \u0027",
        "de": "Al jahrra \u0027",
        "it": "Al Jahra \u0027",
        "id": "Al Jahra \u0027",
        "ja": "アルハーラ \u0027",
        "th": "อัลจารา \u0027",
        "pt": "Al Jahra \u0027"
      },
      "countryCode": "KW",
      "latLng": {
        "lat": 29.3375,
        "lng": 47.6581
      },
      "country": "Kuwait",
      "importance": 3
    },
    {
      "id": 2920,
      "name": "Peterborough",
      "names": {
        "zh": "彼得伯勒",
        "ja": "ピーターバラ",
        "th": "ปีเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.5725,
        "lng": -0.2431
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2908,
      "name": "Tanauan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0833,
        "lng": 121.15
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2894,
      "name": "Suhaj",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 26.5606,
        "lng": 31.6917
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 2933,
      "name": "Oakville",
      "names": {
        "zh": "奥克维尔",
        "ja": "オークヴィル",
        "th": "โอก"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.45,
        "lng": -79.6833
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 2884,
      "name": "Eldoret",
      "names": {
        "zh": "埃尔多雷特"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 0.5167,
        "lng": 35.2833
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 2934,
      "name": "Sunrise Manor",
      "names": {
        "zh": "日出庄园",
        "it": "Padrone di alba",
        "ja": "日の出のマナー",
        "th": "คฤหาสน์พระอาทิตย์ขึ้น",
        "pt": "Mansão do nascer do sol"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1785,
        "lng": -115.049
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2782,
      "name": "Bene Beraq",
      "names": {
        "id": "Benu",
        "ja": "ベネベラック"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0807,
        "lng": 34.8338
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 2930,
      "name": "Yamaguchi",
      "names": {
        "zh": "山口",
        "ja": "山口",
        "th": "ยามากูชิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.1781,
        "lng": 131.4739
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 2936,
      "name": "Bukan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.5211,
        "lng": 46.2089
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2912,
      "name": "Prato",
      "names": {
        "zh": "普拉托",
        "ja": "プラート"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.8808,
        "lng": 11.0966
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 2924,
      "name": "Kumagaya",
      "names": {
        "zh": "熊谷"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1472,
        "lng": 139.3886
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2928,
      "name": "La Plata",
      "names": {
        "zh": "拉普拉塔",
        "de": "LA-Plata",
        "ja": "ラプラタ",
        "th": "ลาพลาตา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.9333,
        "lng": -57.95
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 2766,
      "name": "Bani Suwayf",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 29.0667,
        "lng": 31.0833
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 2929,
      "name": "San Juan Sacatepequez",
      "names": {
        "fr": "San Juan Sacatepequezez",
        "zh": "圣胡安sacatepequez",
        "id": "San Juan Sacatepequezz",
        "th": "ซานฮวน Sacatepequez"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7189,
        "lng": -90.6442
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 2922,
      "name": "Quelimane",
      "names": {
        "zh": "克利马内"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -17.8764,
        "lng": 36.8872
      },
      "country": "Mozambique",
      "importance": 1
    },
    {
      "id": 2939,
      "name": "Aracatuba",
      "names": {
        "zh": "阿拉萨图巴",
        "pt": "Araçatuba"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.2089,
        "lng": -50.4328
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2937,
      "name": "Hyesan",
      "names": {
        "zh": "惠山"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 41.4,
        "lng": 128.1833
      },
      "country": "North Korea",
      "importance": 2
    },
    {
      "id": 2875,
      "name": "Beni Mellal",
      "names": {
        "id": "Melel",
        "ja": "ベニメ細胞",
        "th": "เบนิเมน"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.3394,
        "lng": -6.3608
      },
      "country": "Morocco",
      "importance": 1
    },
    {
      "id": 2941,
      "name": "Guanare",
      "names": {
        "zh": "瓜纳雷"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.0436,
        "lng": -69.7489
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 2940,
      "name": "Castanhal",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -1.2969,
        "lng": -47.9219
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2942,
      "name": "Waco",
      "names": {
        "zh": "韦科",
        "th": "เวโก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.5598,
        "lng": -97.1881
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2890,
      "name": "Fuenlabrada",
      "names": {
        "zh": "丰拉夫拉达"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.2833,
        "lng": -3.8
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2918,
      "name": "Gorontalo",
      "names": {
        "zh": "哥伦打洛"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.5411,
        "lng": 123.0594
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 2938,
      "name": "Kielce",
      "names": {
        "zh": "凯尔采",
        "ja": "キェルツェ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.8725,
        "lng": 20.6319
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 2931,
      "name": "Muar",
      "names": {},
      "countryCode": "MY",
      "latLng": {
        "lat": 2.05,
        "lng": 102.56
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 2935,
      "name": "Godoy Cruz",
      "names": {
        "ja": "ゴドイクルーズ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.9167,
        "lng": -68.8333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 2954,
      "name": "Balakovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.039,
        "lng": 47.7839
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2948,
      "name": "Bago",
      "names": {
        "zh": "巴格"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.5388,
        "lng": 122.8384
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 2949,
      "name": "Valera",
      "names": {
        "zh": "瓦莱拉",
        "ja": "バレラ",
        "th": "วาเลร่า"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.3206,
        "lng": -70.6078
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2956,
      "name": "Aydin",
      "names": {
        "zh": "艾登"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8481,
        "lng": 27.8453
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 2960,
      "name": "Salinas",
      "names": {
        "zh": "萨利纳斯",
        "ja": "サリナス",
        "th": "ซาลินาส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.6884,
        "lng": -121.6317
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2961,
      "name": "Malaybalay",
      "names": {
        "zh": "马来巴来"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.1575,
        "lng": 125.1278
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2952,
      "name": "Armavir",
      "names": {
        "zh": "阿尔马维尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 45.0,
        "lng": 41.1167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2845,
      "name": "Isidro Casanova",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7,
        "lng": -58.5833
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 2964,
      "name": "Chibia",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -15.1833,
        "lng": 13.7
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 2885,
      "name": "Hino",
      "names": {
        "zh": "日野",
        "ja": "日野",
        "th": "ฮีโน่"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6714,
        "lng": 139.395
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2968,
      "name": "Rybinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.05,
        "lng": 38.8333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 2927,
      "name": "Mahbubnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.7333,
        "lng": 77.9833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2932,
      "name": "Cap-Haitien",
      "names": {
        "fr": "Cap-haïtien",
        "zh": "海地角",
        "ja": "キャップ-haitien",
        "th": "หมวก-haitien"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.75,
        "lng": -72.2
      },
      "country": "Haiti",
      "importance": 1
    },
    {
      "id": 2957,
      "name": "Batu",
      "names": {
        "zh": "巴图",
        "ja": "バトゥ",
        "th": "บาตู"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.8672,
        "lng": 112.5239
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 2963,
      "name": "Santa Barbara d\u0027Oeste",
      "names": {
        "es": "Santa Bárbara d\u0027Oeste",
        "zh": "圣巴巴拉d\u0027oeste",
        "it": "Santa Barbara D\u0027Eeste",
        "ja": "サンタバーバラデのエステ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7539,
        "lng": -47.4139
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 768,
      "name": "Saint-Denis",
      "names": {
        "zh": "圣 - 丹尼斯",
        "ja": "サン・デニス",
        "th": "เซนต์เดนิส"
      },
      "countryCode": "RE",
      "latLng": {
        "lat": -20.8789,
        "lng": 55.4481
      },
      "country": "Reunion",
      "importance": 0
    },
    {
      "id": 2970,
      "name": "Cachoeiro de Itapemirim",
      "names": {
        "es": "Cachoeiro de itapemirimim",
        "ja": "カチョーイ・デ・イタペミリム"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.8489,
        "lng": -41.1128
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2967,
      "name": "Saddiqabad",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 28.3006,
        "lng": 70.1302
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 2974,
      "name": "Ngaoundere",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 7.3214,
        "lng": 13.5839
      },
      "country": "Cameroon",
      "importance": 1
    },
    {
      "id": 2969,
      "name": "Chongju",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 39.65,
        "lng": 125.3333
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 2973,
      "name": "Potosi",
      "names": {
        "es": "Potosí",
        "fr": "Potosí",
        "zh": "波托西",
        "de": "Potosí",
        "ja": "ポトシ州",
        "th": "โปโตซี"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -19.5833,
        "lng": -65.75
      },
      "country": "Bolivia",
      "importance": 1
    },
    {
      "id": 2976,
      "name": "Waterbury",
      "names": {
        "zh": "沃特伯里",
        "th": "วอเตอร์เบอรี่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5583,
        "lng": -73.0361
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2958,
      "name": "Son Tay",
      "names": {
        "es": "Hijo tay",
        "fr": "Fils tay",
        "zh": "儿子tay",
        "de": "Sohn Tay",
        "it": "Figlio tay",
        "ja": "息子のティー",
        "th": "ลูกชายเทย์",
        "pt": "Filho Tay"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.1382,
        "lng": 105.505
      },
      "country": "Vietnam",
      "importance": 2
    },
    {
      "id": 2978,
      "name": "Donghua",
      "names": {
        "zh": "东华"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.2175,
        "lng": 106.6545
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 2953,
      "name": "Mwene-Ditu",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -7.0,
        "lng": 23.45
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 2965,
      "name": "Odawara",
      "names": {
        "zh": "小田原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2647,
        "lng": 139.1522
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2977,
      "name": "Parakou",
      "names": {
        "zh": "帕拉库"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 9.34,
        "lng": 2.62
      },
      "country": "Benin",
      "importance": 1
    },
    {
      "id": 2870,
      "name": "Holon",
      "names": {
        "zh": "弄"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0167,
        "lng": 34.7667
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 2959,
      "name": "Anjomachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9667,
        "lng": 137.0833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2982,
      "name": "Araure",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.5667,
        "lng": -69.2167
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 2985,
      "name": "Clarksville",
      "names": {
        "zh": "克拉克斯维尔",
        "ja": "クラークスヴィル",
        "th": "คลาร์กสวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.5695,
        "lng": -87.342
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2972,
      "name": "Hagen",
      "names": {
        "zh": "哈根",
        "ja": "ハーゲン",
        "th": "ฮาเก็น"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3594,
        "lng": 7.475
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 2971,
      "name": "Nandyal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.5204,
        "lng": 78.48
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 2984,
      "name": "Pinar del Rio",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.4122,
        "lng": -83.6719
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 2899,
      "name": "Kenema",
      "names": {
        "zh": "凯内马"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 7.8833,
        "lng": -11.1833
      },
      "country": "Sierra Leone",
      "importance": 1
    },
    {
      "id": 2983,
      "name": "Digos",
      "names": {
        "zh": "迪戈斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.75,
        "lng": 125.35
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 2991,
      "name": "Angra dos Reis",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.0069,
        "lng": -44.3178
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2979,
      "name": "Mutare",
      "names": {
        "zh": "穆塔雷"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.9728,
        "lng": 32.6694
      },
      "country": "Zimbabwe",
      "importance": 1
    },
    {
      "id": 2993,
      "name": "Sobral",
      "names": {
        "zh": "索布拉尔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.6856,
        "lng": -40.3442
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2988,
      "name": "Selcuklu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9312,
        "lng": 32.4996
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 2992,
      "name": "San Francisco de Macoris",
      "names": {
        "zh": "旧金山de Macoris",
        "ja": "サンフランシスコデマコリス",
        "th": "ซานฟรานซิสโกเดอแมคโอริส",
        "pt": "São Francisco de Macoris"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.3,
        "lng": -70.25
      },
      "country": "Dominican Republic",
      "importance": 1
    },
    {
      "id": 2955,
      "name": "Donostia",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 43.32,
        "lng": -1.98
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 2998,
      "name": "Laayoune",
      "names": {
        "fr": "Laâyoune",
        "id": "Laayoune Hassan I"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 27.15,
        "lng": -13.2
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 2990,
      "name": "Prabumulih",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.462,
        "lng": 104.2229
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 2999,
      "name": "Cedar Rapids",
      "names": {
        "fr": "Cèdre rapides",
        "zh": "雪松急流",
        "de": "CEDAR-Rapids",
        "it": "Rapids di cedro",
        "ja": "杉の急流"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.9665,
        "lng": -91.6782
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2966,
      "name": "Kishiwada",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4667,
        "lng": 135.3667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2997,
      "name": "Iquique",
      "names": {
        "zh": "伊基克",
        "th": "อีกีเก"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -20.2141,
        "lng": -70.1524
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 2907,
      "name": "Dese",
      "names": {
        "th": "เหล่านี้"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 11.1333,
        "lng": 39.6333
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 3002,
      "name": "Gharyan",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 32.1669,
        "lng": 13.0167
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 2945,
      "name": "Leganes",
      "names": {
        "es": "Leganés",
        "fr": "Leganés",
        "zh": "莱冈斯"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.3281,
        "lng": -3.7644
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 2986,
      "name": "Numazu",
      "names": {
        "ja": "沼津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0956,
        "lng": 138.8636
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 2995,
      "name": "Turku",
      "names": {
        "zh": "图尔库",
        "ja": "トゥルク"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4517,
        "lng": 22.27
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 2950,
      "name": "Bournemouth",
      "names": {
        "zh": "伯恩茅斯",
        "ja": "ボーンマス",
        "th": "มัธ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.72,
        "lng": -1.88
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3004,
      "name": "Tottori",
      "names": {
        "zh": "鸟取",
        "ja": "鳥取",
        "th": "ทตโตะริ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5011,
        "lng": 134.235
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 769,
      "name": "Malabo",
      "names": {
        "zh": "马拉博",
        "ja": "マラボ",
        "th": "มาลาโบ"
      },
      "countryCode": "GQ",
      "latLng": {
        "lat": 3.7521,
        "lng": 8.7737
      },
      "country": "Equatorial Guinea",
      "importance": 0
    },
    {
      "id": 2994,
      "name": "Xiashi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.5333,
        "lng": 120.6833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3006,
      "name": "Joetsu",
      "names": {
        "ja": "上越"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.15,
        "lng": 138.2333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3005,
      "name": "Chaedok",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 40.6723,
        "lng": 129.2027
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 2996,
      "name": "Guatire",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4717,
        "lng": -66.5406
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3007,
      "name": "Sioux Falls",
      "names": {
        "fr": "Sioux tombe",
        "zh": "Sioux瀑布",
        "de": "Sioux fällt",
        "it": "Sioux cade",
        "ja": "スーフォールズ",
        "th": "น้ำตก Sioux"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.5397,
        "lng": -96.732
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3001,
      "name": "San Diego",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンディエゴ",
        "th": "ซานดิเอโก"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2558,
        "lng": -67.9539
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3000,
      "name": "Kofu",
      "names": {
        "zh": "甲府"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6667,
        "lng": 138.5667
      },
      "country": "Japan",
      "importance": 1
    },
    {
      "id": 767,
      "name": "Gibraltar",
      "names": {
        "zh": "直布罗陀",
        "it": "Gibilterra",
        "ja": "ジブラルタル",
        "th": "ยิบรอลตาร์"
      },
      "countryCode": "GI",
      "latLng": {
        "lat": 36.1324,
        "lng": -5.3781
      },
      "country": "Gibraltar",
      "importance": 0
    },
    {
      "id": 3008,
      "name": "Calbayog City",
      "names": {
        "es": "Ciudad de Calbayog",
        "zh": "卡巴亚城市",
        "id": "Kota Calbayog",
        "ja": "カルバヨグシティ",
        "th": "เมืองคาร์บาโย่",
        "pt": "Cidade de Calbayog"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.0667,
        "lng": 124.6
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3010,
      "name": "Kairouan",
      "names": {
        "zh": "凯鲁万"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.6833,
        "lng": 10.1
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 3012,
      "name": "Shendi",
      "names": {
        "zh": "圣地"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 16.6805,
        "lng": 33.42
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 2951,
      "name": "Pasuruan",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.6406,
        "lng": 112.9065
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3011,
      "name": "Cabo Frio",
      "names": {
        "de": "Cabo-Frio"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8789,
        "lng": -42.0189
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2946,
      "name": "Madiun",
      "names": {
        "zh": "茉莉芬"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -7.63,
        "lng": 111.5231
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3015,
      "name": "Ed Damazin",
      "names": {
        "zh": "德达纳顿",
        "ja": "エドダマジン",
        "th": "เอ็ด Damazin"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 11.7704,
        "lng": 34.35
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 3019,
      "name": "Huntington",
      "names": {
        "zh": "亨廷顿",
        "ja": "ハンティントン",
        "th": "ฮันติงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.4109,
        "lng": -82.4344
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3018,
      "name": "Dunhuang",
      "names": {
        "zh": "敦煌",
        "ja": "敦煌",
        "th": "ตุนหวง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.1667,
        "lng": 94.6833
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 2943,
      "name": "Basildon",
      "names": {
        "zh": "巴塞尔顿"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5761,
        "lng": 0.4886
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2944,
      "name": "Beylikduzu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9819,
        "lng": 28.64
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 3013,
      "name": "Kayapinar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.94,
        "lng": 40.19
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 3021,
      "name": "Modena",
      "names": {
        "fr": "Modène",
        "zh": "摩德纳",
        "ja": "モデナ",
        "th": "โมเดนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.6458,
        "lng": 10.9257
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 3024,
      "name": "Jamaame",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 0.0722,
        "lng": 42.7506
      },
      "country": "Somalia",
      "importance": 3
    },
    {
      "id": 3027,
      "name": "Semnan",
      "names": {
        "zh": "塞姆南"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.5833,
        "lng": 53.3833
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 2980,
      "name": "Narsingdi",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 23.9,
        "lng": 90.7167
      },
      "country": "Bangladesh",
      "importance": 3
    },
    {
      "id": 3028,
      "name": "Temirtau",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 50.0549,
        "lng": 72.9646
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 3032,
      "name": "Ontario",
      "names": {
        "fr": "L\u0027Ontario",
        "zh": "安大略省",
        "ja": "オンタリオ州",
        "th": "ออนแทรีโอ",
        "pt": "Ontário"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0393,
        "lng": -117.6064
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3031,
      "name": "Yei",
      "names": {
        "zh": "耶伊"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 4.0904,
        "lng": 30.68
      },
      "country": "South Sudan",
      "importance": 3
    },
    {
      "id": 3020,
      "name": "Mahesana",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.6,
        "lng": 72.4
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3033,
      "name": "Luzhang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.8519,
        "lng": 98.8562
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3030,
      "name": "Passo Fundo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -28.2628,
        "lng": -52.4069
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3035,
      "name": "Hagerstown",
      "names": {
        "zh": "黑格斯敦"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.6401,
        "lng": -77.7217
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2962,
      "name": "Ferraz de Vasconcelos",
      "names": {
        "ja": "フェラズデブサンセロス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5411,
        "lng": -46.3689
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 2975,
      "name": "Pak Kret",
      "names": {
        "ja": "パックケット",
        "th": "ปากเกร็ด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.9125,
        "lng": 100.4978
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 3038,
      "name": "Erie",
      "names": {
        "fr": "Érié",
        "zh": "伊利",
        "ja": "エリー",
        "th": "อีรี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1168,
        "lng": -80.0733
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3039,
      "name": "Vancouver",
      "names": {
        "zh": "温哥华",
        "ja": "バンクーバー",
        "th": "แวนคูเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.6366,
        "lng": -122.5967
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3040,
      "name": "Nampa",
      "names": {
        "zh": "楠帕",
        "th": "นัมปา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.5845,
        "lng": -116.5631
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3034,
      "name": "Al Qamishli",
      "names": {
        "ja": "アルカミリ",
        "th": "อัล Qamishli"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 37.05,
        "lng": 41.2167
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 3042,
      "name": "Fianarantsoa",
      "names": {
        "zh": "菲亚纳兰楚阿"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -21.4333,
        "lng": 47.0833
      },
      "country": "Madagascar",
      "importance": 1
    },
    {
      "id": 3041,
      "name": "Geelong",
      "names": {
        "zh": "吉朗",
        "ja": "ジーロング",
        "th": "จี"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -38.15,
        "lng": 144.35
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 3045,
      "name": "Severodvinsk",
      "names": {
        "zh": "北德文斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 64.5667,
        "lng": 39.85
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3029,
      "name": "Toyokawa",
      "names": {
        "zh": "丰川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8268,
        "lng": 137.3759
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3044,
      "name": "Siguiri",
      "names": {
        "zh": "锡吉里"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 11.4189,
        "lng": -9.1644
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 3025,
      "name": "Breda",
      "names": {
        "zh": "布雷达",
        "ja": "ブレダ",
        "th": "เบรดา"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5875,
        "lng": 4.775
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 3051,
      "name": "Cuango",
      "names": {
        "zh": "宽果"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -9.1444,
        "lng": 18.0464
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 2987,
      "name": "Raiganj",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.6167,
        "lng": 88.1167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3050,
      "name": "Trondheim",
      "names": {
        "zh": "特隆赫姆",
        "ja": "トロンハイム",
        "th": "ทรอนไฮม์"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 63.44,
        "lng": 10.4
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 3049,
      "name": "Itajai",
      "names": {
        "es": "Itajaí",
        "zh": "伊塔雅伊",
        "pt": "Itajaí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.9078,
        "lng": -48.6619
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3052,
      "name": "Aqtau",
      "names": {
        "zh": "阿克套",
        "th": "อัคตาอู"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.6525,
        "lng": 51.1575
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 3046,
      "name": "Batala",
      "names": {
        "zh": "巴塔拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 31.8186,
        "lng": 75.2028
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3057,
      "name": "Burlington",
      "names": {
        "zh": "伯灵顿",
        "ja": "バーリントン",
        "th": "เบอร์ลิงตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.3167,
        "lng": -79.8
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 3053,
      "name": "Nis",
      "names": {
        "zh": "新谢克尔",
        "ja": "NISの"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.3192,
        "lng": 21.8961
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 3023,
      "name": "Getafe",
      "names": {
        "zh": "赫塔菲",
        "ja": "ヘタフェ",
        "th": "เกตาเฟ่"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.3047,
        "lng": -3.7311
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 3048,
      "name": "Bhusaval",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.02,
        "lng": 75.83
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3043,
      "name": "Banjar",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.3667,
        "lng": 108.5333
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 3061,
      "name": "Dourados",
      "names": {
        "zh": "朵拉多"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2208,
        "lng": -54.8058
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3059,
      "name": "Pinrang",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.7857,
        "lng": 119.6522
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 3062,
      "name": "Spartanburg",
      "names": {
        "zh": "斯巴达堡"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.9437,
        "lng": -81.9257
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3065,
      "name": "Gastonia",
      "names": {
        "th": "แกสโตเนีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2494,
        "lng": -81.1853
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3047,
      "name": "Rzeszow",
      "names": {
        "zh": "热舒夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.05,
        "lng": 22.0
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 3063,
      "name": "Meram",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8364,
        "lng": 32.4383
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 3067,
      "name": "Fort Lauderdale",
      "names": {
        "zh": "劳德代尔堡",
        "ja": "フォートローダーデール"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.1412,
        "lng": -80.1464
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2917,
      "name": "Chilpancingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.55,
        "lng": -99.5
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 3060,
      "name": "Sorsogon",
      "names": {
        "zh": "索索贡"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9667,
        "lng": 124.0
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3054,
      "name": "Bahraigh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.5742,
        "lng": 81.5958
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3017,
      "name": "Reims",
      "names": {
        "zh": "兰斯",
        "ja": "ランス",
        "th": "ไรมส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.2628,
        "lng": 4.0347
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3058,
      "name": "Potsdam",
      "names": {
        "zh": "波茨坦",
        "ja": "ポツダム",
        "th": "พอทสดัม"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4,
        "lng": 13.0667
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 3069,
      "name": "Nova Friburgo",
      "names": {
        "ja": "ノヴァフリブルゴ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2819,
        "lng": -42.5308
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3073,
      "name": "Khowy",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 38.5503,
        "lng": 44.9519
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 2405,
      "name": "Shrirampur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.75,
        "lng": 88.34
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3070,
      "name": "Tonk",
      "names": {
        "zh": "坦克",
        "ja": "トンク",
        "th": "โสเภณี"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.1505,
        "lng": 75.79
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3056,
      "name": "Abakan",
      "names": {
        "zh": "阿巴甘"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.7167,
        "lng": 91.4167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 3074,
      "name": "Sirsa",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.4904,
        "lng": 75.03
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3016,
      "name": "Khujand",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 40.2833,
        "lng": 69.6167
      },
      "country": "Tajikistan",
      "importance": 1
    },
    {
      "id": 2981,
      "name": "Braga",
      "names": {
        "zh": "布拉加",
        "ja": "ブラガ",
        "th": "บรากา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5503,
        "lng": -8.42
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 3071,
      "name": "Reggio di Calabria",
      "names": {
        "de": "Reggio di Kalabrien"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.1144,
        "lng": 15.65
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 3081,
      "name": "Epe",
      "names": {
        "zh": "珍珠棉"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.5573,
        "lng": 3.9912
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 3079,
      "name": "Petropavlovsk-Kamchatskiy",
      "names": {
        "zh": "彼得罗巴甫洛夫斯克罗巴甫洛夫斯克",
        "ja": "ペトロパブロフスクカムチャツキー",
        "th": "เปโตรปัฟลอฟสค์-คัมชัตสกี"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.0167,
        "lng": 158.65
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 3036,
      "name": "Fardis",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7225,
        "lng": 50.9883
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 3078,
      "name": "York",
      "names": {
        "zh": "纽约",
        "ja": "ヨーク",
        "th": "นิวยอร์ก",
        "pt": "Iorque"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.9583,
        "lng": -1.0803
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 2989,
      "name": "Tachikawa",
      "names": {
        "zh": "立川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7139,
        "lng": 139.4079
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3082,
      "name": "Sittwe",
      "names": {
        "zh": "实兑"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 20.1444,
        "lng": 92.8969
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 3088,
      "name": "Turbo",
      "names": {
        "zh": "涡轮增压",
        "ja": "ターボ",
        "th": "เทอร์โบ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.0931,
        "lng": -76.7283
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 3090,
      "name": "La Rioja",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -29.4131,
        "lng": -66.8558
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 3093,
      "name": "Ayacucho",
      "names": {
        "zh": "阿亚库乔"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.1631,
        "lng": -74.2244
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 3089,
      "name": "Hinthada",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 17.6483,
        "lng": 95.4679
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 3072,
      "name": "Berazategui",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7679,
        "lng": -58.2133
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 3087,
      "name": "Funtua",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 11.5204,
        "lng": 7.32
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 3094,
      "name": "Obuase",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 6.2,
        "lng": -1.6833
      },
      "country": "Ghana",
      "importance": 3
    },
    {
      "id": 3076,
      "name": "Jaunpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.7333,
        "lng": 82.6833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3103,
      "name": "Longjing",
      "names": {
        "zh": "龙井"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.77,
        "lng": 129.4197
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3064,
      "name": "Braila",
      "names": {
        "zh": "布勒伊拉"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.2692,
        "lng": 27.9575
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 3108,
      "name": "Lorain",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 41.4409,
        "lng": -82.184
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3105,
      "name": "Ilheus",
      "names": {
        "es": "Ilhéus",
        "pt": "Ilhéus"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -14.7889,
        "lng": -39.0489
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3085,
      "name": "Lhokseumawe",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 5.18,
        "lng": 97.1506
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3091,
      "name": "Madanapalle",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.55,
        "lng": 78.5
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3075,
      "name": "Tangail",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 24.25,
        "lng": 89.92
      },
      "country": "Bangladesh",
      "importance": 3
    },
    {
      "id": 3092,
      "name": "Palopo",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.0,
        "lng": 120.2
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3022,
      "name": "Guri",
      "names": {
        "zh": "古里"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.5947,
        "lng": 127.1428
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 3110,
      "name": "Tongjiang",
      "names": {
        "zh": "通江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.6502,
        "lng": 132.503
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3111,
      "name": "Murfreesboro",
      "names": {
        "th": "เมอร์ฟรีสโบโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.8492,
        "lng": -86.4119
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 2896,
      "name": "Hugli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.9,
        "lng": 88.39
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3109,
      "name": "Barra Mansa",
      "names": {
        "zh": "巴拉曼萨",
        "ja": "バラマンサ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5439,
        "lng": -44.1708
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3112,
      "name": "High Point",
      "names": {
        "es": "Punto álgido",
        "fr": "Point fort",
        "zh": "高点",
        "de": "Hoher Punkt",
        "it": "Punto alto",
        "id": "Pokok",
        "ja": "ハイポイント",
        "th": "จุดสูงสุด",
        "pt": "Ponto alto"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9907,
        "lng": -79.9938
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3080,
      "name": "Phan Rang-Thap Cham",
      "names": {
        "th": "พานรังรัง - ทับ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.5643,
        "lng": 108.9886
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3068,
      "name": "Uji",
      "names": {
        "zh": "宇治",
        "ja": "宇治"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8808,
        "lng": 135.7794
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3115,
      "name": "Tarija",
      "names": {
        "zh": "塔里哈"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -21.5317,
        "lng": -64.7311
      },
      "country": "Bolivia",
      "importance": 1
    },
    {
      "id": 3114,
      "name": "Divo",
      "names": {
        "ja": "ディーヴォ"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 5.8372,
        "lng": -5.3572
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 3101,
      "name": "Saarbrucken",
      "names": {
        "es": "Saarbrücken",
        "fr": "Sarrebruck",
        "zh": "萨尔布吕肯",
        "de": "Saarbrücken",
        "it": "Saarbrücken",
        "id": "Saarbrücken",
        "ja": "ザールブリュッケン",
        "pt": "Saarbrücken"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.2333,
        "lng": 7.0
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 3121,
      "name": "Paita",
      "names": {
        "zh": "帕伊塔"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -5.0667,
        "lng": -81.1
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 3077,
      "name": "Dosquebradas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8361,
        "lng": -75.6761
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 3086,
      "name": "Roxas City",
      "names": {
        "es": "Ciudad de Roxas",
        "zh": "罗克斯市",
        "it": "Città di Roxas",
        "id": "Kota roxas",
        "ja": "ロキサスシティ",
        "th": "เมือง Roxas",
        "pt": "Cidade de roxas"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.5833,
        "lng": 122.75
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3102,
      "name": "Purwakarta",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.54,
        "lng": 107.44
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 3123,
      "name": "Newport News",
      "names": {
        "es": "Newsport noticias",
        "zh": "纽波特新闻",
        "id": "Berita Newport",
        "ja": "ニューポートニュース",
        "th": "ข่าวนิวพอร์ต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.1051,
        "lng": -76.5185
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3113,
      "name": "Hamm",
      "names": {
        "zh": "哈姆",
        "ja": "ハム",
        "th": "แฮมม์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6667,
        "lng": 7.8167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3107,
      "name": "Klerksdorp",
      "names": {
        "zh": "克莱克斯多普"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.8667,
        "lng": 26.6667
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 3099,
      "name": "Tiaret",
      "names": {
        "zh": "提亚雷特"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.3667,
        "lng": 1.3167
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3117,
      "name": "Guli",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.9008,
        "lng": 120.0284
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3116,
      "name": "Al `Arish",
      "names": {
        "es": "Al \u0027arish",
        "zh": "Al` arish",
        "de": "Al` Arish",
        "id": "Al `wasst",
        "ja": "Al`アニャシュ",
        "th": "อัล `arish",
        "pt": "Arish ã"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.1249,
        "lng": 33.8006
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 3124,
      "name": "Townsville",
      "names": {
        "zh": "汤斯维尔",
        "ja": "タウンズビル",
        "th": "ทาวน์สวิลล์"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -19.25,
        "lng": 146.8167
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 3098,
      "name": "Boma",
      "names": {
        "zh": "博马",
        "ja": "ボマ"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.85,
        "lng": 13.05
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 3128,
      "name": "Salalah",
      "names": {
        "zh": "塞拉莱"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 17.0197,
        "lng": 54.0897
      },
      "country": "Oman",
      "importance": 1
    },
    {
      "id": 3125,
      "name": "Langsa",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 4.4667,
        "lng": 97.95
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3120,
      "name": "Gliwice",
      "names": {
        "zh": "格利维采"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.3011,
        "lng": 18.6498
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 3104,
      "name": "Split",
      "names": {
        "zh": "分裂",
        "ja": "分割",
        "th": "แยก"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.51,
        "lng": 16.45
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 3132,
      "name": "Birjand",
      "names": {
        "zh": "比尔詹德"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 32.8664,
        "lng": 59.2211
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 3084,
      "name": "Jima",
      "names": {
        "zh": "岛",
        "ja": "岛"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 7.6667,
        "lng": 36.8333
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 3026,
      "name": "Basel",
      "names": {
        "es": "Basilea",
        "fr": "Bâle",
        "zh": "巴塞尔",
        "it": "Basilea",
        "ja": "バーゼル",
        "th": "บาเซิล",
        "pt": "Basileia"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.5606,
        "lng": 7.5906
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 3127,
      "name": "Teziutlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8178,
        "lng": -97.3667
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 3095,
      "name": "Nijmegen",
      "names": {
        "zh": "奈梅亨",
        "ja": "ナイメーヘン"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8475,
        "lng": 5.8625
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 3137,
      "name": "Rancho Cucamonga",
      "names": {
        "ja": "Cucamonga Rancho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1248,
        "lng": -117.5666
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3138,
      "name": "Norilsk",
      "names": {
        "zh": "诺里尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 69.3333,
        "lng": 88.2167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3140,
      "name": "Nossa Senhora do Socorro",
      "names": {
        "ja": "Nossa SenhoraはSocorroです"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.855,
        "lng": -37.1258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3133,
      "name": "Lin\u0027an",
      "names": {
        "zh": "临安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6236,
        "lng": 102.8097
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3144,
      "name": "Hemet",
      "names": {
        "zh": "赫米特",
        "th": "เฮเมต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7341,
        "lng": -116.9969
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3014,
      "name": "Shibin al Kawm",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.55,
        "lng": 31.0
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 3135,
      "name": "Vellore",
      "names": {
        "zh": "韦洛尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 12.9204,
        "lng": 79.15
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3147,
      "name": "Ciudad Valles",
      "names": {
        "ja": "シウダードバリュー"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.9833,
        "lng": -99.0167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3149,
      "name": "Kadugli",
      "names": {
        "zh": "卡杜格利"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 11.01,
        "lng": 29.7
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 3097,
      "name": "Alleppey",
      "names": {
        "zh": "阿勒皮"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 9.5004,
        "lng": 76.37
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3151,
      "name": "Linhares",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.3987,
        "lng": -40.0651
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3096,
      "name": "San Luis Rio Colorado",
      "names": {
        "zh": "圣路易斯里奥科罗拉多",
        "ja": "サンルイスリオコロラド州",
        "th": "ซานหลุยส์ริโอโคโลราโด"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 32.4767,
        "lng": -114.7625
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3153,
      "name": "Letpandan",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 17.7866,
        "lng": 95.7507
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 3131,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -37.7833,
        "lng": 175.2833
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 3159,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.9789,
        "lng": -122.0346
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3158,
      "name": "Rio Verde",
      "names": {
        "zh": "里约佛得角",
        "ja": "リオヴェルデ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -17.7978,
        "lng": -50.9278
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3106,
      "name": "Toulon",
      "names": {
        "zh": "土伦",
        "it": "Tolone",
        "ja": "ツーロン",
        "th": "ตูลง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1258,
        "lng": 5.9306
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3136,
      "name": "Bejaia",
      "names": {
        "zh": "贝贾亚"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7511,
        "lng": 5.0642
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3162,
      "name": "Danbury",
      "names": {
        "zh": "丹伯里",
        "ja": "ダンベリー",
        "th": "แดนบิวรี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.4016,
        "lng": -73.471
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3157,
      "name": "Saint-Louis",
      "names": {
        "zh": "圣路易",
        "ja": "サンルイ",
        "th": "เซนต์หลุยส์"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 16.0333,
        "lng": -16.5
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 3163,
      "name": "Peoria",
      "names": {
        "zh": "皮奥里亚",
        "ja": "ピオリア",
        "th": "พีโอเรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7844,
        "lng": -112.2989
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3166,
      "name": "Catumbela",
      "names": {
        "zh": "卡通贝拉"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -12.4167,
        "lng": 13.5333
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3168,
      "name": "Oceanside",
      "names": {
        "zh": "欧申赛德",
        "ja": "オーシャンサイド",
        "th": "โอเชียนไซด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.2247,
        "lng": -117.3083
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3009,
      "name": "Sultanbeyli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9679,
        "lng": 29.2666
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 3167,
      "name": "Eregli",
      "names": {
        "zh": "埃雷利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2583,
        "lng": 31.425
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 3171,
      "name": "Shibirghan",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.665,
        "lng": 65.752
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 3142,
      "name": "Santa Maria Texmelucan",
      "names": {
        "fr": "Santa Maria Texmeucan",
        "zh": "圣玛丽亚Texmelucan",
        "de": "Santa Maria Texmelcan",
        "ja": "サンタマリアテキサスメルカン"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2833,
        "lng": -98.4333
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 3130,
      "name": "Matosinhos",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1867,
        "lng": -8.6844
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3146,
      "name": "Les Cayes",
      "names": {},
      "countryCode": "HT",
      "latLng": {
        "lat": 18.2,
        "lng": -73.75
      },
      "country": "Haiti",
      "importance": 1
    },
    {
      "id": 3129,
      "name": "Salatiga",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.3389,
        "lng": 110.5022
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3176,
      "name": "Maragheh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.3919,
        "lng": 46.2392
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3156,
      "name": "Plzen",
      "names": {
        "zh": "皮尔森"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.7414,
        "lng": 13.3825
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 3066,
      "name": "Amadora",
      "names": {
        "zh": "阿马多拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.75,
        "lng": -9.2333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3134,
      "name": "Chicoloapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4167,
        "lng": -98.9
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3145,
      "name": "Yangmei",
      "names": {
        "zh": "杨梅"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.9167,
        "lng": 121.15
      },
      "country": "Taiwan",
      "importance": 3
    },
    {
      "id": 3181,
      "name": "Sirjan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.47,
        "lng": 55.73
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3169,
      "name": "Harar",
      "names": {
        "zh": "哈勒尔"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 9.32,
        "lng": 42.15
      },
      "country": "Ethiopia",
      "importance": 1
    },
    {
      "id": 3055,
      "name": "Narashino",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6808,
        "lng": 140.0267
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3184,
      "name": "Elk Grove",
      "names": {
        "zh": "麋鹿树林",
        "de": "Elchhain",
        "it": "Grove alci",
        "id": "Rusa rusa",
        "ja": "エルクグローブ",
        "pt": "Grove de alces"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.416,
        "lng": -121.3842
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3188,
      "name": "Luziania",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -16.2528,
        "lng": -47.95
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 770,
      "name": "Podgorica",
      "names": {
        "zh": "波德戈里察",
        "th": "พอดกอรีตซา"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.4397,
        "lng": 19.2661
      },
      "country": "Montenegro",
      "importance": 0
    },
    {
      "id": 3154,
      "name": "Zabrze",
      "names": {
        "zh": "扎布热",
        "ja": "ザブジェ",
        "th": "เมานต์"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.3,
        "lng": 18.7833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 3126,
      "name": "Dagupan City",
      "names": {
        "zh": "达格普兰市",
        "de": "Dagupan-Stadt",
        "id": "Kota Dagupan",
        "ja": "ダグパン市",
        "th": "เมืองดากมูล",
        "pt": "Cidade dagupã"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.043,
        "lng": 120.334
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3141,
      "name": "Baranavichy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.1167,
        "lng": 25.9833
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 3170,
      "name": "Burgos",
      "names": {
        "zh": "布尔戈斯",
        "ja": "ブルゴス",
        "th": "บูร์โกส"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.3408,
        "lng": -3.6997
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 3189,
      "name": "Leon",
      "names": {
        "es": "León",
        "zh": "莱昂",
        "ja": "レオン",
        "th": "ลีออง"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.4333,
        "lng": -86.8833
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 3155,
      "name": "Almada",
      "names": {
        "zh": "阿尔马达"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6803,
        "lng": -9.1583
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3198,
      "name": "Osmaniye",
      "names": {
        "zh": "奥斯曼尼耶"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.075,
        "lng": 36.25
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 3196,
      "name": "Piedras Negras",
      "names": {
        "ja": "ピエラスヌグラス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.7,
        "lng": -100.5231
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3200,
      "name": "Linjiang",
      "names": {
        "zh": "临江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.8471,
        "lng": 126.9301
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3192,
      "name": "Eskisehir",
      "names": {
        "zh": "埃斯基谢希尔",
        "ja": "エスキシェヒル"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.7767,
        "lng": 30.5206
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 3186,
      "name": "Carupano",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.6722,
        "lng": -63.2403
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3119,
      "name": "Bida",
      "names": {
        "zh": "比达",
        "th": "บิดา"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.0804,
        "lng": 6.01
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 3174,
      "name": "Cuddalore",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 11.75,
        "lng": 79.75
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3173,
      "name": "Olsztyn",
      "names": {
        "zh": "奥尔什丁"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.78,
        "lng": 20.4942
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 3203,
      "name": "Pembroke Pines",
      "names": {
        "es": "Pines Pembroke",
        "fr": "Pemembroke Pines",
        "zh": "Pembroke松树",
        "de": "Pembroke-Kiefern",
        "it": "Pini di Pembroke",
        "th": "เพมโบรกไพน์",
        "pt": "Pinheiros de Pembroke"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.0128,
        "lng": -80.3382
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3204,
      "name": "Tlemcen",
      "names": {
        "zh": "特莱姆森"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.8828,
        "lng": -1.3167
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3187,
      "name": "Lubao",
      "names": {
        "zh": "卢巴奥"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9333,
        "lng": 120.6
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3182,
      "name": "Mexico",
      "names": {
        "fr": "Mexique",
        "zh": "墨西哥",
        "de": "Mexiko",
        "it": "Messico",
        "id": "Meksiko",
        "ja": "メキシコ",
        "th": "เม็กซิโก",
        "pt": "México"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0667,
        "lng": 120.7167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3207,
      "name": "Shahin Shahr",
      "names": {
        "zh": "沙林莎拉"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 32.8639,
        "lng": 51.5475
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3185,
      "name": "Syzran",
      "names": {
        "zh": "塞兹兰"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.1667,
        "lng": 48.4667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3209,
      "name": "Vallejo",
      "names": {
        "zh": "瓦列霍",
        "th": "เลโค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.1133,
        "lng": -122.2358
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3202,
      "name": "Deo",
      "names": {
        "zh": "上帝",
        "th": "โอ"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.6561,
        "lng": 84.4356
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3179,
      "name": "Saint-Etienne",
      "names": {
        "zh": "圣艾蒂安",
        "ja": "サン・etienne",
        "th": "เซนต์ etienne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.4347,
        "lng": 4.3903
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3143,
      "name": "Kamakurayama",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3197,
        "lng": 139.5525
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3201,
      "name": "Ha Long",
      "names": {
        "es": "Ha largo",
        "fr": "Ha longue",
        "zh": "哈哈",
        "de": "Ha lang",
        "it": "A lungo",
        "id": "Ha panjang",
        "th": "ยาว",
        "pt": "Há muito"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.9516,
        "lng": 107.0852
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3190,
      "name": "Chirala",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.82,
        "lng": 80.35
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3195,
      "name": "Bielsko-Biala",
      "names": {
        "zh": "别尔斯克 - 比亚瓦"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.8225,
        "lng": 19.0444
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 3212,
      "name": "Albacete",
      "names": {
        "zh": "阿尔巴塞特",
        "ja": "アルバセテ",
        "th": "อัลบาเซเต้"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.9956,
        "lng": -1.8558
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 3205,
      "name": "Hitachi",
      "names": {
        "zh": "日立",
        "ja": "日立",
        "th": "ฮิตาชิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.6,
        "lng": 140.65
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3219,
      "name": "Barrie",
      "names": {
        "zh": "巴里",
        "ja": "バリー",
        "th": "แบร์รี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.3711,
        "lng": -79.6769
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 3210,
      "name": "Polomolok",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.2167,
        "lng": 125.0667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3193,
      "name": "Castellon de la Plana",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 39.9831,
        "lng": -0.0331
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 3183,
      "name": "Ludwigshafen",
      "names": {
        "zh": "路德维希港",
        "ja": "ルートヴィヒスハーフェン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4811,
        "lng": 8.4353
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3217,
      "name": "Sacaba",
      "names": {
        "zh": "萨卡瓦"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.4042,
        "lng": -66.0408
      },
      "country": "Bolivia",
      "importance": 3
    },
    {
      "id": 2947,
      "name": "General Mariano Alvarez",
      "names": {
        "fr": "Général Mariano Alvarez",
        "zh": "玛利亚诺·阿尔瓦雷斯",
        "it": "Generale Mariano Alvarez",
        "ja": "一般的なマリアーノアルバレス"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3,
        "lng": 121.0
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3211,
      "name": "Banyuwangi",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -8.195,
        "lng": 114.3696
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 3206,
      "name": "Kindu",
      "names": {
        "zh": "金杜"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -2.95,
        "lng": 25.9167
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 3221,
      "name": "Kaolack",
      "names": {},
      "countryCode": "SN",
      "latLng": {
        "lat": 14.1389,
        "lng": -16.0764
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 3213,
      "name": "Bidar",
      "names": {
        "zh": "比德尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 17.9,
        "lng": 77.55
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3215,
      "name": "Moca",
      "names": {
        "zh": "当代艺术馆"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.3833,
        "lng": -70.5167
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 3148,
      "name": "Santander",
      "names": {
        "zh": "桑坦德",
        "ja": "サンタンデール",
        "th": "ซานทานแดร์"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.4628,
        "lng": -3.805
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 3165,
      "name": "Oeiras",
      "names": {
        "zh": "奥埃拉什"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.697,
        "lng": -9.3017
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3214,
      "name": "San Miguel",
      "names": {
        "zh": "圣米格尔",
        "ja": "サンミゲル",
        "th": "ซานมิเกล"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.1458,
        "lng": 120.9783
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3122,
      "name": "Bade",
      "names": {
        "zh": "巴德",
        "ja": "バーデ",
        "th": "รับสั่ง"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.9575,
        "lng": 121.2989
      },
      "country": "Taiwan",
      "importance": 3
    },
    {
      "id": 3222,
      "name": "Izumo",
      "names": {
        "zh": "出云",
        "ja": "出雲"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3681,
        "lng": 132.755
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3218,
      "name": "Reggio Emilia",
      "names": {
        "zh": "雷焦艾米利亚",
        "ja": "レジオエミリア"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.7,
        "lng": 10.6333
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 3160,
      "name": "Ait Melloul",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.3342,
        "lng": -9.4972
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 3220,
      "name": "Krasnogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.8167,
        "lng": 37.3333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3227,
      "name": "Garden Grove",
      "names": {
        "es": "Grove",
        "fr": "Jardin grove",
        "zh": "花园树林",
        "de": "Gartenhain",
        "it": "Giardino Grove",
        "id": "Kebun",
        "ja": "ガーデングローブ",
        "th": "สวนป่า",
        "pt": "Grove de jardim"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7787,
        "lng": -117.9601
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3199,
      "name": "Bytom",
      "names": {
        "zh": "比托姆"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.347,
        "lng": 18.923
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 3228,
      "name": "Volgodonsk",
      "names": {
        "zh": "伏尔加顿斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.5167,
        "lng": 42.15
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3233,
      "name": "Enterprise",
      "names": {
        "es": "La empresa",
        "fr": "Entreprise",
        "zh": "企业",
        "de": "Unternehmen",
        "id": "Perusahaan",
        "ja": "エンタープライズ",
        "th": "องค์กร",
        "pt": "Empresa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0164,
        "lng": -115.2208
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3225,
      "name": "Surigao",
      "names": {
        "zh": "苏里高"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.7833,
        "lng": 125.4833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3231,
      "name": "Makurdi",
      "names": {
        "zh": "马库尔迪"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.7306,
        "lng": 8.5361
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 3216,
      "name": "Mulheim",
      "names": {
        "fr": "Mülheim",
        "zh": "米尔海姆",
        "de": "Mülheim",
        "it": "Mülheim",
        "pt": "Mülheim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4275,
        "lng": 6.8825
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3235,
      "name": "Medford",
      "names": {
        "zh": "梅德福",
        "ja": "メドフォード",
        "th": "เมดฟอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3372,
        "lng": -122.8537
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3178,
      "name": "Alcorcon",
      "names": {
        "es": "Alcorcón",
        "fr": "Alcorcón",
        "de": "Alcorcón"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.35,
        "lng": -3.8333
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 3230,
      "name": "Kohat",
      "names": {
        "zh": "科哈特"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.5869,
        "lng": 71.4414
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 3237,
      "name": "Ussuriysk",
      "names": {
        "zh": "乌苏里斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.8,
        "lng": 131.95
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3240,
      "name": "Uvira",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -3.3953,
        "lng": 29.1378
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 3246,
      "name": "Cary",
      "names": {
        "zh": "卡里",
        "ja": "ケアリー",
        "th": "แครี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.782,
        "lng": -78.8191
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3234,
      "name": "Loja",
      "names": {
        "zh": "洛哈"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -3.9906,
        "lng": -79.205
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 3245,
      "name": "Malayer",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.2942,
        "lng": 48.82
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3241,
      "name": "Tomakomai",
      "names": {
        "zh": "苫小牧",
        "ja": "苫小牧"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 42.6341,
        "lng": 141.6055
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3251,
      "name": "Marysville",
      "names": {
        "zh": "马里斯维尔",
        "th": "แมรีส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 48.0809,
        "lng": -122.1561
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3161,
      "name": "Comitan",
      "names": {
        "es": "Comitán",
        "zh": "科米坦"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.2511,
        "lng": -92.1342
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3242,
      "name": "Portmore",
      "names": {},
      "countryCode": "JM",
      "latLng": {
        "lat": 17.9667,
        "lng": -76.8667
      },
      "country": "Jamaica",
      "importance": 3
    },
    {
      "id": 3249,
      "name": "Metouia",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 33.96,
        "lng": 10.0
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 3037,
      "name": "Chuo-ku",
      "names": {
        "zh": "中央-ku",
        "ja": "中央区-ku",
        "th": "ชูโอ-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6667,
        "lng": 139.7667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3239,
      "name": "Concepcion",
      "names": {
        "es": "Concepción",
        "zh": "康塞普西翁",
        "ja": "コンセプシオン",
        "th": "กอนเซปซิยอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3249,
        "lng": 120.6554
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3252,
      "name": "San Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "ซานหลุยส์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.2994,
        "lng": -66.3392
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 3232,
      "name": "Kamensk-Ural\u0027skiy",
      "names": {
        "zh": "卡缅斯克-ural\u0027skiy"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.4,
        "lng": 61.9333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3243,
      "name": "Machilipatnam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.1875,
        "lng": 81.1389
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3254,
      "name": "Corona",
      "names": {
        "zh": "电晕",
        "ja": "コロナ",
        "th": "โคโรนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8616,
        "lng": -117.5649
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3247,
      "name": "Kluang",
      "names": {
        "zh": "居銮"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 2.0336,
        "lng": 103.3194
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 3177,
      "name": "Tebingtinggi",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 3.3283,
        "lng": 99.1625
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3118,
      "name": "Urayasu",
      "names": {
        "zh": "浦安",
        "ja": "浦安"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6536,
        "lng": 139.9017
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3253,
      "name": "Puerto Montt",
      "names": {
        "ja": "プエルトモンテ",
        "th": "เปอร์โตมอนต์"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -41.4718,
        "lng": -72.9396
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 3208,
      "name": "Le Havre",
      "names": {
        "zh": "勒阿弗尔",
        "ja": "ルアーブル",
        "th": "เลออาฟวร์",
        "pt": "Leigo"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.49,
        "lng": 0.1
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3258,
      "name": "Al Marj",
      "names": {
        "de": "Alte Marj",
        "it": "Al marmo",
        "ja": "アルマージュ"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.5005,
        "lng": 20.83
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 3152,
      "name": "Ciudad del Carmen",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6333,
        "lng": -91.8333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3260,
      "name": "Ocala",
      "names": {
        "zh": "奥卡拉",
        "ja": "オカラ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.178,
        "lng": -82.1511
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3257,
      "name": "Fenglu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.6728,
        "lng": 102.9149
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3255,
      "name": "Medinipur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.4333,
        "lng": 87.3333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3259,
      "name": "Sosan",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.7817,
        "lng": 126.4522
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 3264,
      "name": "Santa Rita",
      "names": {
        "zh": "圣丽塔",
        "ja": "サンタリタ",
        "th": "ซานตาริต้า"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2003,
        "lng": -67.5514
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3238,
      "name": "Sakura",
      "names": {
        "zh": "樱花",
        "ja": "さくら",
        "th": "ซากุระ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7239,
        "lng": 140.2239
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3180,
      "name": "Chetumal",
      "names": {
        "zh": "切图马尔"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5036,
        "lng": -88.3053
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 3250,
      "name": "Nishio",
      "names": {
        "zh": "西尾"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8667,
        "lng": 137.0667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3267,
      "name": "Fredericksburg",
      "names": {
        "zh": "弗雷德里克斯堡",
        "ja": "フレデリックスバーグ",
        "th": "เฟรเดอริ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2992,
        "lng": -77.4872
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3248,
      "name": "Novocherkassk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.4222,
        "lng": 40.0939
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3229,
      "name": "Cuauhtemoc",
      "names": {
        "fr": "Cuauhtémoc",
        "zh": "夸特莫克"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.405,
        "lng": -106.8667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3223,
      "name": "Baliuag",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.95,
        "lng": 120.9
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3271,
      "name": "Mahabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.765,
        "lng": 45.721
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 3244,
      "name": "Bordj Bou Arreridj",
      "names": {
        "fr": "BORDJ BOU ARERIDJ",
        "de": "Bordj Bou AreRIDJ"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.0667,
        "lng": 4.7667
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3226,
      "name": "Francisco Morato",
      "names": {
        "zh": "弗朗西斯科莫托",
        "ja": "フランシスコモラト",
        "th": "ฟรานซิสโก Morato"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2817,
        "lng": -46.7425
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3256,
      "name": "Oldenburg",
      "names": {
        "zh": "奥尔登堡",
        "ja": "オルデンブルク",
        "th": "โอลเดน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.1439,
        "lng": 8.2139
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3270,
      "name": "Huich\u0027on",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 40.1711,
        "lng": 126.2758
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 3236,
      "name": "N\u0027Zerekore",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 7.76,
        "lng": -8.83
      },
      "country": "Guinea",
      "importance": 1
    },
    {
      "id": 3262,
      "name": "Gondomar",
      "names": {
        "zh": "贡多马尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.15,
        "lng": -8.5333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3266,
      "name": "Baramula",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 34.2,
        "lng": 74.34
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3276,
      "name": "Zlatoust",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.1667,
        "lng": 59.6667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3263,
      "name": "Jamalpur",
      "names": {
        "zh": "加木尔普"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 24.9004,
        "lng": 89.95
      },
      "country": "Bangladesh",
      "importance": 3
    },
    {
      "id": 3268,
      "name": "Oyama",
      "names": {
        "zh": "大山",
        "ja": "大山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3147,
        "lng": 139.8003
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3279,
      "name": "Riohacha",
      "names": {
        "zh": "里奥阿查"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 11.5442,
        "lng": -72.9069
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 3280,
      "name": "Catape",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.7667,
        "lng": 15.0833
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3275,
      "name": "Basuo",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.102,
        "lng": 108.6391
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3278,
      "name": "Patra",
      "names": {
        "zh": "帕特雷",
        "ja": "パトラ",
        "th": "ภัทรา"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.25,
        "lng": 21.7333
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 3283,
      "name": "Gainesville",
      "names": {
        "zh": "盖恩斯维尔",
        "ja": "ゲインズビル",
        "th": "เกนส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.2903,
        "lng": -83.8301
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3282,
      "name": "Guarapuava",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -25.395,
        "lng": -51.4578
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3272,
      "name": "Itapecerica da Serra",
      "names": {
        "fr": "Itapeperica da serra",
        "th": "Itapecericaa da Serra"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.7172,
        "lng": -46.8494
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3284,
      "name": "Itu",
      "names": {
        "es": "UIT",
        "fr": "UIT",
        "zh": "国际电联",
        "ja": "のITU",
        "th": "ไอทียู",
        "pt": "UIT"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2642,
        "lng": -47.2992
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3172,
      "name": "Chandannagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.8667,
        "lng": 88.3833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3194,
      "name": "Tiantoujiao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.0236,
        "lng": 114.0927
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3292,
      "name": "Kismaayo",
      "names": {
        "zh": "基斯麦犹"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": -0.3603,
        "lng": 42.5489
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 3293,
      "name": "Edirne",
      "names": {
        "zh": "埃迪尔内"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.6781,
        "lng": 26.5594
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 3285,
      "name": "Fatehpur",
      "names": {
        "zh": "法地",
        "pt": "Sikri"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.8804,
        "lng": 80.8
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3281,
      "name": "Tuguegarao",
      "names": {
        "zh": "土格加劳"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.6167,
        "lng": 121.7167
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3297,
      "name": "Kuytun",
      "names": {
        "zh": "奎屯"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.4196,
        "lng": 84.9012
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3286,
      "name": "Al Kufah",
      "names": {
        "ja": "アルクフー",
        "th": "อัลคูฟาห์"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.03,
        "lng": 44.4
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 3303,
      "name": "Oshawa",
      "names": {
        "zh": "奥沙瓦",
        "ja": "オシャワ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.9,
        "lng": -78.85
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 3274,
      "name": "Rustenburg",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.6667,
        "lng": 27.2336
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 3294,
      "name": "Takaoka",
      "names": {
        "zh": "高冈",
        "ja": "高岡",
        "th": "ทากาโอกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.7541,
        "lng": 137.0257
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3305,
      "name": "Tanjungbalai",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 2.9667,
        "lng": 99.8
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3310,
      "name": "Kushiro",
      "names": {
        "zh": "钏路",
        "ja": "釧路"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 42.9833,
        "lng": 144.3833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3291,
      "name": "Iwata",
      "names": {
        "zh": "岩田",
        "ja": "岩田",
        "th": "อิวะตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7178,
        "lng": 137.8514
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3306,
      "name": "Obihiro",
      "names": {
        "zh": "带广",
        "ja": "帯広"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 42.9236,
        "lng": 143.1967
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3300,
      "name": "Perugia",
      "names": {
        "fr": "Pérouse",
        "zh": "佩鲁贾",
        "ja": "ペルージャ",
        "th": "เปรู"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.1121,
        "lng": 12.3888
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 3313,
      "name": "Bechar",
      "names": {
        "zh": "贝沙尔"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 31.6333,
        "lng": -2.2
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3261,
      "name": "Uppsala",
      "names": {
        "zh": "乌普萨拉",
        "ja": "ウプサラ",
        "th": "อุปซอลา"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.8498,
        "lng": 17.6389
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 3265,
      "name": "Warrington",
      "names": {
        "zh": "沃灵顿",
        "ja": "ウォリントン",
        "th": "วอร์ริงตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.3917,
        "lng": -2.5972
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3302,
      "name": "Udipi",
      "names": {
        "zh": "乌迪比"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 13.3322,
        "lng": 74.7461
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3288,
      "name": "Ha Tinh",
      "names": {
        "zh": "哈蒂",
        "ja": "ハハチン",
        "th": "ฮ่าฮ่า"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 18.3431,
        "lng": 105.9058
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3307,
      "name": "Midsayap",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.1917,
        "lng": 124.5333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3289,
      "name": "Kimberley",
      "names": {
        "zh": "金伯利",
        "ja": "キンバリー",
        "th": "คิมเบอร์ลีย์"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -28.7386,
        "lng": 24.7586
      },
      "country": "South Africa",
      "importance": 1
    },
    {
      "id": 3317,
      "name": "Saqqez",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.25,
        "lng": 46.2736
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3269,
      "name": "Ambato",
      "names": {
        "zh": "安巴托"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -1.2417,
        "lng": -78.6197
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 3319,
      "name": "Fukang",
      "names": {
        "zh": "阜康"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.1523,
        "lng": 87.9793
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3311,
      "name": "Idlib",
      "names": {
        "zh": "伊德利卜"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.9333,
        "lng": 36.6333
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 3175,
      "name": "Lagos de Moreno",
      "names": {
        "zh": "拉各斯德莫雷诺",
        "ja": "ラゴスデモエノ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.3564,
        "lng": -101.9292
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3290,
      "name": "Tenali",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.243,
        "lng": 80.64
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3316,
      "name": "Cienfuegos",
      "names": {
        "zh": "圣菲哥斯",
        "ja": "シエンフエーゴス"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.1456,
        "lng": -80.4364
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 3191,
      "name": "San Andres Tuxtla",
      "names": {
        "es": "San Andrés Tuxtla",
        "zh": "圣安德里斯Tuxtla",
        "ja": "サンアンドレスTUXTLA"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.4487,
        "lng": -95.2126
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3299,
      "name": "Osnabruck",
      "names": {
        "es": "Osnabrück",
        "fr": "Osnabrück",
        "zh": "奥斯纳布吕克",
        "de": "Osnabrück",
        "id": "Osnabrück",
        "th": "ออสนาบรุ๊ค",
        "pt": "Osnabrück"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2789,
        "lng": 8.0431
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3323,
      "name": "Turkistan",
      "names": {
        "zh": "土耳其斯坦"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.3019,
        "lng": 68.2692
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 3325,
      "name": "Bocoio",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.4667,
        "lng": 14.1333
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3224,
      "name": "Niiza",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7933,
        "lng": 139.5653
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3320,
      "name": "Pikit",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.05,
        "lng": 124.6667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3312,
      "name": "Sitalpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.63,
        "lng": 80.75
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3314,
      "name": "Conjeeveram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 12.8308,
        "lng": 79.7078
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3304,
      "name": "Klaipeda",
      "names": {
        "zh": "克莱佩达",
        "th": "ไคลเปดา"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.7075,
        "lng": 21.1428
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 3309,
      "name": "Hadano",
      "names": {
        "zh": "秦野"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3747,
        "lng": 139.2203
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3326,
      "name": "Chillan",
      "names": {
        "fr": "Chillán",
        "zh": "奇廉"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -36.6067,
        "lng": -72.1033
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 3327,
      "name": "Abu Hulayfah",
      "names": {
        "zh": "阿布·赫拉伊法"
      },
      "countryCode": "KW",
      "latLng": {
        "lat": 29.3333,
        "lng": 48.0
      },
      "country": "Kuwait",
      "importance": 3
    },
    {
      "id": 3335,
      "name": "Quibala",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -10.7311,
        "lng": 15.0003
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3315,
      "name": "Proddatur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.73,
        "lng": 78.55
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3308,
      "name": "Leverkusen",
      "names": {
        "zh": "勒沃库森",
        "ja": "レバークーゼン",
        "th": "เลเวอร์คูเซ่น"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0333,
        "lng": 6.9833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3334,
      "name": "Myingyan",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 21.4618,
        "lng": 95.3914
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 3336,
      "name": "Teresopolis",
      "names": {
        "pt": "Teresópolis"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4119,
        "lng": -42.9658
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3150,
      "name": "Piraeus",
      "names": {
        "es": "El Pireo",
        "fr": "Le Pirée",
        "zh": "比雷埃夫斯",
        "de": "Piräus",
        "it": "Pireo",
        "ja": "ピレウス"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.943,
        "lng": 23.6469
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 3341,
      "name": "Wau",
      "names": {
        "zh": "墙"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 7.7,
        "lng": 27.9833
      },
      "country": "South Sudan",
      "importance": 1
    },
    {
      "id": 3338,
      "name": "Pocheon",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.8944,
        "lng": 127.1992
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 3296,
      "name": "Skikda",
      "names": {
        "zh": "斯基克达"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.8667,
        "lng": 6.9
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3329,
      "name": "Muridke",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.802,
        "lng": 74.255
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 3343,
      "name": "Muzaffargarh",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.0703,
        "lng": 71.1933
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 3344,
      "name": "Kebili",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 33.705,
        "lng": 8.965
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 3345,
      "name": "Jizzax",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.1167,
        "lng": 67.85
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 3348,
      "name": "Mostar",
      "names": {
        "zh": "莫斯塔尔",
        "ja": "モスタル",
        "th": "ตาร์"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.3494,
        "lng": 17.8125
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 3340,
      "name": "Sao Jose de Ribamar",
      "names": {
        "zh": "圣何塞德里亚马尔",
        "ja": "サンホセdeリバマール",
        "pt": "São José de Ribamar"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.5619,
        "lng": -44.0539
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3332,
      "name": "Ipswich",
      "names": {
        "zh": "伊普斯维奇",
        "ja": "イプスウィッチ",
        "th": "อิปสวิช"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -27.6167,
        "lng": 152.7667
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 3339,
      "name": "Navsari",
      "names": {
        "zh": "瑙萨里"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.8504,
        "lng": 72.92
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3350,
      "name": "El Geneina",
      "names": {
        "ja": "エルジェネーナ"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 13.45,
        "lng": 22.44
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 3324,
      "name": "Huddersfield",
      "names": {
        "zh": "哈德斯菲尔德",
        "ja": "ハッダーズフィールド",
        "th": "กริฟฟิธ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.645,
        "lng": -1.7798
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3277,
      "name": "Haarlem",
      "names": {
        "zh": "哈勒姆",
        "th": "ฮาร์เล็ม"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3803,
        "lng": 4.6406
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 3353,
      "name": "Manchester",
      "names": {
        "zh": "曼彻斯特",
        "ja": "マンチェスター",
        "th": "แมนเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.9848,
        "lng": -71.4447
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3342,
      "name": "Ube",
      "names": {
        "zh": "宇部",
        "ja": "宇部",
        "th": "อุเบะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.9517,
        "lng": 131.2467
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3352,
      "name": "Bandar-e Mahshahr",
      "names": {
        "de": "Bandar-E Mahshaahr",
        "ja": "バンダルeマヒンシャール"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 30.5589,
        "lng": 49.1981
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3346,
      "name": "Simao",
      "names": {
        "zh": "思茅",
        "de": "Simão",
        "it": "Simão",
        "pt": "Simão"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.7807,
        "lng": 100.9782
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3355,
      "name": "Anaco",
      "names": {
        "zh": "阿纳科"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.4333,
        "lng": -64.4667
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3358,
      "name": "Reading",
      "names": {
        "es": "Leyendo",
        "fr": "En train de lire",
        "zh": "阅读",
        "de": "Lesen",
        "it": "Lettura",
        "id": "Bacaan",
        "ja": "読み取り",
        "th": "การอ่าน",
        "pt": "Leitura"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4542,
        "lng": -0.9731
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3361,
      "name": "Tuxtepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.1,
        "lng": -96.1167
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 3356,
      "name": "Sullana",
      "names": {
        "zh": "苏亚纳"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -4.9039,
        "lng": -80.6853
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 3301,
      "name": "Jiutepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8833,
        "lng": -99.1667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3362,
      "name": "Jutiapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.2917,
        "lng": -89.8958
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 3366,
      "name": "Andong",
      "names": {
        "zh": "安东"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.5656,
        "lng": 128.725
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 3367,
      "name": "Ash Shatrah",
      "names": {
        "zh": "灰沙拉",
        "ja": "灰Shatrah",
        "pt": "Cinza shatrah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 31.4175,
        "lng": 46.1772
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 3354,
      "name": "Godhra",
      "names": {
        "zh": "戈特拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.7772,
        "lng": 73.6203
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3368,
      "name": "Rafsanjan",
      "names": {
        "zh": "拉夫桑詹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 30.4,
        "lng": 56.0
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3360,
      "name": "Sariaya",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9667,
        "lng": 121.5333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3331,
      "name": "Metro",
      "names": {
        "fr": "Métro",
        "zh": "地铁",
        "de": "U-Bahn",
        "it": "La metropolitana",
        "ja": "メトロ",
        "th": "รถไฟใต้ดิน"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -5.1167,
        "lng": 105.3
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3295,
      "name": "Nador",
      "names": {
        "zh": "纳多尔"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.1667,
        "lng": -2.9333
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 3330,
      "name": "Tema",
      "names": {
        "zh": "特马",
        "ja": "テマ",
        "th": "ธีม"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 5.6667,
        "lng": -0.0167
      },
      "country": "Ghana",
      "importance": 3
    },
    {
      "id": 3359,
      "name": "Zemun",
      "names": {
        "zh": "泽蒙"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.85,
        "lng": 20.4
      },
      "country": "Serbia",
      "importance": 2
    },
    {
      "id": 3372,
      "name": "Ndalatando",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -9.3,
        "lng": 14.9167
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 3363,
      "name": "Budaun",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.03,
        "lng": 79.09
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3375,
      "name": "Jequie",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -13.8578,
        "lng": -40.0839
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3318,
      "name": "Zhanlicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.2881,
        "lng": 116.2594
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3370,
      "name": "Bontang",
      "names": {
        "zh": "邦坦"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 0.1333,
        "lng": 117.5
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3351,
      "name": "Jinggang",
      "names": {
        "zh": "靖港"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.267,
        "lng": 113.2304
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3378,
      "name": "Sherbrooke",
      "names": {
        "zh": "舍布鲁克"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.4,
        "lng": -71.9
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 3373,
      "name": "Apeldoorn",
      "names": {
        "zh": "阿珀尔多伦",
        "ja": "アペルドールン"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.21,
        "lng": 5.97
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 3381,
      "name": "Papantla de Olarte",
      "names": {
        "es": "Papantla de olario",
        "it": "Papantla de Ollare"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4478,
        "lng": -97.32
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3371,
      "name": "Tulancingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0833,
        "lng": -98.3667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3387,
      "name": "Tacheng",
      "names": {
        "zh": "塔城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.8511,
        "lng": 83.1488
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3389,
      "name": "Bayamon",
      "names": {},
      "countryCode": "PR",
      "latLng": {
        "lat": 18.3793,
        "lng": -66.1635
      },
      "country": "Puerto Rico",
      "importance": 3
    },
    {
      "id": 3287,
      "name": "Kandy",
      "names": {
        "zh": "康提",
        "th": "แคน"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 7.297,
        "lng": 80.6385
      },
      "country": "Sri Lanka",
      "importance": 1
    },
    {
      "id": 3322,
      "name": "Bhisho",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -32.8494,
        "lng": 27.4381
      },
      "country": "South Africa",
      "importance": 1
    },
    {
      "id": 3349,
      "name": "Naic",
      "names": {
        "fr": "Scian"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3167,
        "lng": 120.7667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3369,
      "name": "Al Ghardaqah",
      "names": {
        "de": "Algardaqah",
        "ja": "アルハルダカ",
        "th": "อัล ghardaqah",
        "pt": "Al ghedaqah"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 27.2578,
        "lng": 33.8117
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 3379,
      "name": "Kidapawan",
      "names": {
        "zh": "基达帕万"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0083,
        "lng": 125.0894
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3364,
      "name": "Chittoor",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.2,
        "lng": 79.1167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3390,
      "name": "Braganca Paulista",
      "names": {
        "ja": "ブラガンカパウリスタ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9189,
        "lng": -46.5425
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3392,
      "name": "Pindamonhangaba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9239,
        "lng": -45.4617
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3394,
      "name": "Giron",
      "names": {
        "th": "เร่"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0731,
        "lng": -73.1681
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 3376,
      "name": "Dong Hoi",
      "names": {
        "zh": "董河",
        "ja": "ドンホイ",
        "th": "ดงฮอย"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 17.4833,
        "lng": 106.6
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3403,
      "name": "Khanpur",
      "names": {
        "zh": "汗布尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 28.6453,
        "lng": 70.6567
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 3384,
      "name": "Southend",
      "names": {
        "zh": "绍森德",
        "id": "London Southend",
        "ja": "サウスエンド",
        "th": "เอนด์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.55,
        "lng": 0.71
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3337,
      "name": "Ciudad Acuna",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 29.3242,
        "lng": -100.9317
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3383,
      "name": "Jose Maria Ezeiza",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8333,
        "lng": -58.5167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 3396,
      "name": "Khost",
      "names": {
        "zh": "霍斯特"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.3333,
        "lng": 69.9167
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 3397,
      "name": "Koudougou",
      "names": {
        "zh": "库杜古"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.25,
        "lng": -2.3667
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 3399,
      "name": "Saint-Marc",
      "names": {
        "zh": "圣 - 马克",
        "ja": "サンマルク",
        "th": "เซนต์มาร์ค"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.1167,
        "lng": -72.7
      },
      "country": "Haiti",
      "importance": 3
    },
    {
      "id": 3402,
      "name": "Gaziantep",
      "names": {
        "zh": "加济安泰普"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0667,
        "lng": 37.3833
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 3400,
      "name": "Taunggyi",
      "names": {
        "zh": "东枝"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 20.7833,
        "lng": 96.9667
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 3382,
      "name": "Dhamar",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 14.55,
        "lng": 44.4017
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 3100,
      "name": "Tyre",
      "names": {
        "es": "Neumáticos",
        "fr": "Pneus",
        "zh": "轮胎",
        "de": "Reifen",
        "it": "Pneumatico",
        "id": "Ban",
        "ja": "タイヤ",
        "th": "ยาง",
        "pt": "Pneu"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 33.2667,
        "lng": 35.2
      },
      "country": "Lebanon",
      "importance": 2
    },
    {
      "id": 3404,
      "name": "Huaycan",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -12.0139,
        "lng": -76.825
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 3405,
      "name": "San Nicolas de los Arroyos",
      "names": {
        "zh": "圣尼古拉斯德洛斯阿罗罗斯",
        "ja": "サンニコラスデロスアロヨス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.3333,
        "lng": -60.2167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 3407,
      "name": "Miyakonojo",
      "names": {
        "zh": "都城"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 31.7197,
        "lng": 131.0617
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3412,
      "name": "Shahr-e Kord",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.3256,
        "lng": 50.8644
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 3413,
      "name": "Ekibastuz",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.7298,
        "lng": 75.3266
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 3393,
      "name": "Enschede",
      "names": {
        "zh": "恩斯赫德",
        "th": "เอนสเกเด"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2236,
        "lng": 6.8956
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 3409,
      "name": "Kramatorsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.7208,
        "lng": 37.5556
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 3420,
      "name": "Champaign",
      "names": {
        "zh": "香槟分校",
        "ja": "シャンペーン",
        "th": "ที่ราบ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.1144,
        "lng": -88.2735
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3421,
      "name": "Alexandria",
      "names": {
        "es": "Alejandría",
        "fr": "Alexandrie",
        "zh": "亚历山大",
        "it": "Alessandria",
        "ja": "アレクサンドリア",
        "th": "ซานเดรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8185,
        "lng": -77.0861
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3417,
      "name": "Chirchiq",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.4667,
        "lng": 69.5833
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 3386,
      "name": "Solingen",
      "names": {
        "zh": "索林根",
        "ja": "ゾーリンゲン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1667,
        "lng": 7.0833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3419,
      "name": "Jijiga",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 9.35,
        "lng": 42.8
      },
      "country": "Ethiopia",
      "importance": 1
    },
    {
      "id": 3398,
      "name": "Darmstadt",
      "names": {
        "zh": "达姆施塔特",
        "ja": "ダルムシュタット",
        "th": "มสตัด"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.8667,
        "lng": 8.65
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3426,
      "name": "Hayward",
      "names": {
        "zh": "海沃德",
        "ja": "ヘイワード",
        "th": "เฮย์เวิร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6328,
        "lng": -122.0772
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3416,
      "name": "Catamarca",
      "names": {
        "zh": "卡塔马卡"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -28.4686,
        "lng": -65.7792
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 3414,
      "name": "Hat Yai",
      "names": {
        "es": "Sombrero yai",
        "fr": "Chapeau yai",
        "zh": "帽子亚",
        "de": "Hut yai",
        "it": "Cappello yai",
        "id": "Topi yai",
        "ja": "ハットヤイ",
        "th": "หาดใหญ่",
        "pt": "Chapéu yai"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.0167,
        "lng": 100.4667
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 3424,
      "name": "Ravenna",
      "names": {
        "es": "Rávena",
        "fr": "Ravenne",
        "zh": "拉文纳",
        "ja": "ラヴェンナ",
        "th": "ราเวนนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.4161,
        "lng": 12.2017
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 3401,
      "name": "Arad",
      "names": {
        "zh": "阿拉德",
        "ja": "アラド",
        "th": "อาราด"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.1667,
        "lng": 21.3167
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 3415,
      "name": "Szeged",
      "names": {
        "zh": "塞格德",
        "th": "ซีเกด"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.25,
        "lng": 20.1667
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 3385,
      "name": "Ibirite",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.0219,
        "lng": -44.0589
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3432,
      "name": "Quipungo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.8167,
        "lng": 14.5167
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3365,
      "name": "Benoni",
      "names": {
        "zh": "伯诺尼"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.1883,
        "lng": 28.3206
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 3430,
      "name": "Puerto Plata",
      "names": {
        "ja": "プエルトプラタ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.7958,
        "lng": -70.6944
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 3406,
      "name": "Heidelberg",
      "names": {
        "zh": "海德堡",
        "ja": "ハイデルベルク",
        "th": "ไฮเดลเบิร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4122,
        "lng": 8.71
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3391,
      "name": "Zhengding",
      "names": {
        "zh": "正定"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.1522,
        "lng": 114.5771
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3433,
      "name": "Cadiz",
      "names": {
        "es": "Cádiz",
        "fr": "Cadix",
        "zh": "加的斯",
        "it": "Cadice",
        "ja": "カディス",
        "th": "กาดิซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.95,
        "lng": 123.3
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3441,
      "name": "Springfield",
      "names": {
        "zh": "斯普林菲尔德",
        "ja": "スプリングフィールド",
        "th": "สปริงฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.771,
        "lng": -89.6538
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 3374,
      "name": "Elektrostal",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.8,
        "lng": 38.45
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3439,
      "name": "Samarra\u0027",
      "names": {
        "es": "Samarra \u0027",
        "fr": "Samarra \u0027",
        "zh": "萨马拉\u0027",
        "de": "Samarra \u0027",
        "it": "Samarra \u0027",
        "id": "Samarra \u0027",
        "ja": "samarra \u0027",
        "th": "samarra \u0027",
        "pt": "Samarra \u0027"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 34.194,
        "lng": 43.875
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 3437,
      "name": "Matsuzaka",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5781,
        "lng": 136.5275
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3428,
      "name": "Ogaki",
      "names": {
        "zh": "大垣",
        "ja": "大垣"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3594,
        "lng": 136.6128
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3411,
      "name": "Livorno",
      "names": {
        "fr": "Livourne",
        "zh": "里窝那",
        "ja": "リボルノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.55,
        "lng": 10.3167
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 3445,
      "name": "Rio Cuarto",
      "names": {
        "zh": "里约Cuarto",
        "ja": "リオカートロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.123,
        "lng": -64.3478
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 3410,
      "name": "Seixal",
      "names": {
        "zh": "塞沙尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6333,
        "lng": -9.0833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3427,
      "name": "Bharuch",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.6948,
        "lng": 72.9805
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3328,
      "name": "Odense",
      "names": {
        "zh": "欧登塞",
        "ja": "オーデンセ",
        "th": "โอเดนเซ"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.4004,
        "lng": 10.3833
      },
      "country": "Denmark",
      "importance": 2
    },
    {
      "id": 3442,
      "name": "Ilagan",
      "names": {
        "zh": "伊拉甘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.1333,
        "lng": 121.8833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3438,
      "name": "Haripur",
      "names": {
        "zh": "赫里布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 31.52,
        "lng": 75.98
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3435,
      "name": "Guimaraes",
      "names": {
        "zh": "吉马良斯",
        "de": "Guimarães",
        "id": "Guimarães",
        "pt": "Guimarães"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.445,
        "lng": -8.2908
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3440,
      "name": "San Cristobal",
      "names": {
        "es": "San Cristóbal",
        "ja": "サンクリストバル"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7367,
        "lng": -92.6383
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3273,
      "name": "Sao Caetano do Sul",
      "names": {
        "fr": "Sao caeetano do sul",
        "th": "เซา caetano do sul",
        "pt": "São Caetano do Sul"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6228,
        "lng": -46.5508
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3418,
      "name": "La Laguna",
      "names": {
        "ja": "ララグナ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 28.4853,
        "lng": -16.3167
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 3451,
      "name": "Lakewood",
      "names": {
        "zh": "莱克伍德",
        "ja": "レイクウッド",
        "th": "เลกวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.6977,
        "lng": -105.1168
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3444,
      "name": "Konya",
      "names": {
        "zh": "科尼亚",
        "ja": "コンヤ",
        "th": "คอนยา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8714,
        "lng": 32.4847
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 3449,
      "name": "Gojra",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.1487,
        "lng": 72.6866
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 3453,
      "name": "Lafayette",
      "names": {
        "zh": "老佛爷",
        "ja": "ラファイエット",
        "th": "ลาฟาแยต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.399,
        "lng": -86.8594
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3450,
      "name": "Teixeira de Freitas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -17.535,
        "lng": -39.7419
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3197,
      "name": "Banha",
      "names": {
        "pt": "O banha"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.4628,
        "lng": 31.1797
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 3298,
      "name": "Grenoble",
      "names": {
        "zh": "格勒诺布尔",
        "ja": "グルノーブル",
        "th": "เกรอน็อบล์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.1715,
        "lng": 5.7224
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3448,
      "name": "Villanueva",
      "names": {
        "zh": "维拉纽瓦"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.3167,
        "lng": -88.0167
      },
      "country": "Honduras",
      "importance": 3
    },
    {
      "id": 3456,
      "name": "Calama",
      "names": {
        "zh": "卡拉马"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -22.4624,
        "lng": -68.9272
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 3380,
      "name": "San Miguel",
      "names": {
        "zh": "圣米格尔",
        "ja": "サンミゲル",
        "th": "ซานมิเกล"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5333,
        "lng": -58.7167
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 771,
      "name": "Manama",
      "names": {
        "zh": "麦纳麦",
        "ja": "マナマ",
        "th": "มานามา"
      },
      "countryCode": "BH",
      "latLng": {
        "lat": 26.225,
        "lng": 50.5775
      },
      "country": "Bahrain",
      "importance": 0
    },
    {
      "id": 3422,
      "name": "George",
      "names": {
        "es": "Jorge",
        "zh": "乔治·",
        "it": "Giorgio",
        "ja": "ジョージ",
        "th": "จอร์จ"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.9667,
        "lng": 22.45
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 3459,
      "name": "Mandi Bahauddin",
      "names": {
        "de": "Mandi Bahaudin",
        "ja": "マンディバハウジン"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.5861,
        "lng": 73.4917
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 3434,
      "name": "Chau Doc",
      "names": {
        "id": "Dokter"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.7,
        "lng": 105.1167
      },
      "country": "Vietnam",
      "importance": 3
    },
    {
      "id": 3460,
      "name": "Isiro",
      "names": {
        "zh": "伊西罗"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.7833,
        "lng": 27.6167
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 3333,
      "name": "Itapetininga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5917,
        "lng": -48.0531
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3462,
      "name": "Xiaping",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.7646,
        "lng": 114.3093
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3431,
      "name": "Nan Zhuang",
      "names": {
        "zh": "南庄",
        "th": "น่านจ้วง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9839,
        "lng": 113.0139
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3465,
      "name": "Guaymas",
      "names": {
        "zh": "瓜伊马斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.1667,
        "lng": -110.6667
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 3395,
      "name": "Dijon",
      "names": {
        "zh": "第戎",
        "ja": "ディジョン",
        "th": "ในดีชง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.3167,
        "lng": 5.0167
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 3468,
      "name": "Frederick",
      "names": {
        "zh": "弗雷德里克",
        "de": "Friedrich",
        "it": "Federico",
        "ja": "フレデリック",
        "th": "เฟรดเดอริก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.4336,
        "lng": -77.4141
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3469,
      "name": "Lajes",
      "names": {
        "zh": "拉日斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.8158,
        "lng": -50.3258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3429,
      "name": "Saharsa",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.88,
        "lng": 86.6
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3457,
      "name": "Chaoshan",
      "names": {
        "zh": "潮汕"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.0701,
        "lng": 113.8693
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3423,
      "name": "Herne",
      "names": {
        "zh": "赫恩",
        "ja": "ヘルネ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5426,
        "lng": 7.219
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3467,
      "name": "Arnavutkoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1833,
        "lng": 28.7333
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 3455,
      "name": "Danao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.5333,
        "lng": 123.9333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3436,
      "name": "Amersfoort",
      "names": {
        "zh": "阿默斯福特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.15,
        "lng": 5.38
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 3480,
      "name": "Lake Charles",
      "names": {
        "es": "Lago Charles",
        "fr": "Lac Charles",
        "zh": "查尔斯湖",
        "it": "Lago Charles",
        "ja": "チャールズ湖",
        "th": "ทะเลสาบชาร์ลส์",
        "pt": "Lago charles"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.2012,
        "lng": -93.2122
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3474,
      "name": "Ghorahi",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 28.0408,
        "lng": 82.4861
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 3473,
      "name": "Capas",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3312,
        "lng": 120.5898
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3476,
      "name": "Cam Pha",
      "names": {
        "es": "Cámara PHA",
        "zh": "凸轮pha",
        "it": "Campha",
        "ja": "カムパ",
        "th": "แคมพ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.0065,
        "lng": 107.2726
      },
      "country": "Vietnam",
      "importance": 2
    },
    {
      "id": 3481,
      "name": "Odessa",
      "names": {
        "zh": "敖德萨",
        "ja": "オデッサ",
        "th": "โอเดสซา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.8831,
        "lng": -102.3406
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3475,
      "name": "Vidisha",
      "names": {
        "zh": "毗底沙"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 23.5239,
        "lng": 77.8061
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3466,
      "name": "Pathankot",
      "names": {
        "zh": "伯坦果德"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 32.2689,
        "lng": 75.6497
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3478,
      "name": "Ar Ramtha",
      "names": {
        "id": "Ar ramuan"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 32.5592,
        "lng": 36.0069
      },
      "country": "Jordan",
      "importance": 2
    },
    {
      "id": 3488,
      "name": "Tuscaloosa",
      "names": {
        "zh": "塔斯卡卢萨",
        "ja": "タスカルーサ",
        "th": "ทัสคาลูซา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.2348,
        "lng": -87.5267
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3487,
      "name": "Timon",
      "names": {
        "zh": "蒂蒙",
        "th": "ทิมอน"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.0939,
        "lng": -42.8369
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3491,
      "name": "Horad Barysaw",
      "names": {
        "es": "Horad Baranda",
        "id": "Barysaw horad"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 54.226,
        "lng": 28.4922
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 3470,
      "name": "Pitesti",
      "names": {
        "zh": "皮特什蒂",
        "ja": "ピテシュチ",
        "th": "ปีเตสตี"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.8667,
        "lng": 24.8833
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 3490,
      "name": "Souk Ahras",
      "names": {
        "es": "Souk ahros"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.2864,
        "lng": 7.9511
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3496,
      "name": "Warner Robins",
      "names": {
        "zh": "华纳罗宾斯",
        "de": "Warner-Robins",
        "ja": "ワーナーロビン",
        "th": "วอร์เนอร์โรบิน",
        "pt": "Robins da Warner"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.597,
        "lng": -83.6529
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3464,
      "name": "Chlef",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.1647,
        "lng": 1.3317
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3495,
      "name": "Caxias",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.8589,
        "lng": -43.3558
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3485,
      "name": "Ordu",
      "names": {
        "zh": "奥尔杜"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9833,
        "lng": 37.8833
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 3408,
      "name": "Pandi",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8667,
        "lng": 120.95
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3497,
      "name": "Palmdale",
      "names": {
        "zh": "帕姆代尔",
        "th": "ปาล์มเดล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5944,
        "lng": -118.1057
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3452,
      "name": "Camarajibe",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.0219,
        "lng": -34.9808
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3461,
      "name": "El Eulma",
      "names": {
        "ja": "エルマ"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.1528,
        "lng": 5.69
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 3458,
      "name": "Salzburg",
      "names": {
        "es": "Salzburgo",
        "fr": "Salzbourg",
        "zh": "萨尔茨堡",
        "it": "Salisburgo",
        "ja": "ザルツブルク",
        "th": "ซาลซ์บูร์ก",
        "pt": "Salzburgo"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.7972,
        "lng": 13.0477
      },
      "country": "Austria",
      "importance": 1
    },
    {
      "id": 3500,
      "name": "Melitopol",
      "names": {
        "zh": "梅利托波尔"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 46.8333,
        "lng": 35.3667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 3454,
      "name": "Uiwang",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.3447,
        "lng": 126.9683
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 3472,
      "name": "Hanam",
      "names": {
        "zh": "哈纳姆"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.5392,
        "lng": 127.2147
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 3504,
      "name": "Hollywood",
      "names": {
        "zh": "好莱坞",
        "ja": "ハリウッド",
        "th": "ฮอลลีวู้ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.0294,
        "lng": -80.1679
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3506,
      "name": "Midland",
      "names": {
        "zh": "米德兰",
        "ja": "ミッドランド",
        "th": "มิดแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.0249,
        "lng": -102.1137
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3501,
      "name": "Zango",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 12.9333,
        "lng": 8.5333
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 3446,
      "name": "Middelburg",
      "names": {
        "zh": "米德尔堡"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.7684,
        "lng": 29.4783
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 3510,
      "name": "Mahajanga",
      "names": {
        "zh": "马任加"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -15.67,
        "lng": 46.345
      },
      "country": "Madagascar",
      "importance": 1
    },
    {
      "id": 3463,
      "name": "Batumi",
      "names": {
        "fr": "Batoumi",
        "zh": "巴统"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 41.6458,
        "lng": 41.6417
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 3513,
      "name": "Leesburg",
      "names": {
        "ja": "リーズバーグ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.7657,
        "lng": -81.8996
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3447,
      "name": "Angers",
      "names": {
        "zh": "昂热",
        "ja": "アンジェ",
        "th": "อองเชส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.4736,
        "lng": -0.5542
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3509,
      "name": "Alagoinhas",
      "names": {
        "zh": "阿拉戈伊尼亚斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.1356,
        "lng": -38.4189
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3515,
      "name": "Port Arthur",
      "names": {
        "zh": "亚瑟港",
        "it": "Porto Arthur",
        "ja": "ポートアーサー",
        "th": "พอร์ตอาร์เธอร์",
        "pt": "Porto arthur"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.8554,
        "lng": -93.9264
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3512,
      "name": "Gandajika",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -6.75,
        "lng": 23.9667
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 3486,
      "name": "Nalgonda",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.05,
        "lng": 79.27
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3484,
      "name": "Hitachi-Naka",
      "names": {
        "zh": "日立-那珂",
        "ja": "日立那珂",
        "th": "ฮิตาชิ-Naka"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3964,
        "lng": 140.5344
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3517,
      "name": "Lucapa",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.4228,
        "lng": 20.7393
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3516,
      "name": "Almetyevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.9,
        "lng": 52.3
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3498,
      "name": "Hoofddorp",
      "names": {
        "zh": "霍夫多普",
        "th": "ฮุฟด์ดอร์ป"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3008,
        "lng": 4.6653
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 3482,
      "name": "Cagliari",
      "names": {
        "zh": "卡利亚里",
        "ja": "カリアリ",
        "th": "กาลยารี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.2167,
        "lng": 9.1167
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 3505,
      "name": "Madinat as Sadis min Uktubar",
      "names": {
        "es": "Madinat como sadis min uktubar",
        "fr": "Madinat en tant que SADIS MIN UKTUBAR",
        "zh": "Madinat担任Sadis Min Uktubar",
        "de": "Madinat als Sadis min Uktubar",
        "it": "Madinat come Sadis Min Uktubar",
        "id": "Madinat sebagai Sadis Min Ukubar",
        "ja": "Sadis Min UktubarとしてのMadinat",
        "th": "Madinat เป็น Sadis Min Uktubar",
        "pt": "Madinat como Sadis Min Uktubar"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 29.9361,
        "lng": 30.9269
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 3483,
      "name": "Zaanstad",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4697,
        "lng": 4.7767
      },
      "country": "Netherlands",
      "importance": 3
    },
    {
      "id": 3477,
      "name": "Esmeraldas",
      "names": {
        "zh": "埃斯梅拉达斯"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": 0.95,
        "lng": -79.6667
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 3507,
      "name": "Dibrugarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.4833,
        "lng": 95.0
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3522,
      "name": "Barreiras",
      "names": {
        "zh": "巴雷拉斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.1528,
        "lng": -44.99
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3521,
      "name": "Moanda",
      "names": {
        "zh": "莫安达"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.9229,
        "lng": 12.355
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 3519,
      "name": "Seogwipo",
      "names": {
        "zh": "西归浦",
        "ja": "西帰浦"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 33.2497,
        "lng": 126.56
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 3493,
      "name": "Neuss",
      "names": {
        "zh": "诺伊斯"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2003,
        "lng": 6.6939
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3489,
      "name": "Palo Negro",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.16,
        "lng": -67.5582
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3508,
      "name": "Veraval",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.9,
        "lng": 70.37
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3531,
      "name": "Muskegon",
      "names": {
        "zh": "马斯基根",
        "th": "มัสกี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.2281,
        "lng": -86.2563
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3528,
      "name": "Hoeryong",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 42.4333,
        "lng": 129.75
      },
      "country": "North Korea",
      "importance": 2
    },
    {
      "id": 3520,
      "name": "Tochigi",
      "names": {
        "zh": "栃木县",
        "ja": "栃木",
        "th": "จังหวัดโทะชิงิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3824,
        "lng": 139.7341
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3503,
      "name": "Balurghat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.2167,
        "lng": 88.7667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3529,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.3528,
        "lng": 121.0676
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3471,
      "name": "Poblacion",
      "names": {
        "zh": "波夫拉西翁"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.4167,
        "lng": 123.9667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3527,
      "name": "Ixtlahuaca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5689,
        "lng": -99.7669
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3511,
      "name": "Salavat",
      "names": {
        "zh": "萨拉瓦特"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.3667,
        "lng": 55.9333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3479,
      "name": "Kariya",
      "names": {
        "zh": "刈谷",
        "ja": "刈谷"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9893,
        "lng": 137.0021
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3538,
      "name": "Macon",
      "names": {
        "zh": "梅肯",
        "ja": "メーコン",
        "th": "แมคอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8065,
        "lng": -83.6974
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3377,
      "name": "Krishnanagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.4,
        "lng": 88.5
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3524,
      "name": "Fyzabad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.7504,
        "lng": 82.17
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3534,
      "name": "Dutse",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 11.8283,
        "lng": 9.3158
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 3530,
      "name": "Newcastle",
      "names": {
        "zh": "纽卡斯尔",
        "ja": "ニューカッスル",
        "th": "นิวคาสเซิล"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -32.9167,
        "lng": 151.75
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 3542,
      "name": "Kansas City",
      "names": {
        "es": "Ciudad de Kansas",
        "zh": "堪萨斯城",
        "id": "Kota Kansas",
        "ja": "カンザスシティ",
        "th": "เมืองแคนซัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1234,
        "lng": -94.7443
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3537,
      "name": "Ueda",
      "names": {
        "zh": "上田",
        "ja": "上田",
        "th": "อุเอดะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4019,
        "lng": 138.2491
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3523,
      "name": "Tete",
      "names": {
        "zh": "太特",
        "th": "หนิง"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -16.1579,
        "lng": 33.5898
      },
      "country": "Mozambique",
      "importance": 1
    },
    {
      "id": 3545,
      "name": "Golmeh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.7842,
        "lng": 46.595
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 3003,
      "name": "Barakpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.75,
        "lng": 88.3667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3533,
      "name": "Sinpo",
      "names": {
        "zh": "新报"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 40.0833,
        "lng": 128.25
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 3551,
      "name": "Sunnyvale",
      "names": {
        "zh": "桑尼维尔",
        "ja": "サニーベール",
        "th": "ซันนีเวล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3836,
        "lng": -122.0256
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3514,
      "name": "Regensburg",
      "names": {
        "es": "Ratisbona",
        "zh": "雷根斯堡",
        "ja": "レーゲンスブルク",
        "th": "เรเกนสบูร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.0167,
        "lng": 12.0833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3321,
      "name": "Ramat Gan",
      "names": {
        "fr": "Ramate gan",
        "zh": "拉马特甘",
        "ja": "ラマトガン",
        "th": "รามัตกาน"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.07,
        "lng": 34.8235
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 3549,
      "name": "Pocos de Caldas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.7878,
        "lng": -46.5608
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3535,
      "name": "Silchar",
      "names": {
        "zh": "锡尔杰尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.7904,
        "lng": 92.79
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3554,
      "name": "Mudon",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 16.2618,
        "lng": 97.7215
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 3525,
      "name": "Noda",
      "names": {
        "zh": "野田",
        "ja": "野田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.955,
        "lng": 139.8747
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3553,
      "name": "Baubau",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -5.4667,
        "lng": 122.633
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3548,
      "name": "Kawashiri",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.83,
        "lng": 135.4172
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3347,
      "name": "San Martin Texmelucan de Labastida",
      "names": {
        "fr": "San Martin Texmeucan de Labastida",
        "zh": "圣马丁Texmelucan de Labastida",
        "ja": "サンマーティンテキサスメルカンデラバスティダ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2693,
        "lng": -98.4283
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3494,
      "name": "Oxford",
      "names": {
        "zh": "牛津",
        "ja": "オックスフォード",
        "th": "ออกซ์ฟอร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.7519,
        "lng": -1.2578
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3559,
      "name": "Abaetetuba",
      "names": {
        "zh": "阿巴埃特图巴"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.7178,
        "lng": -48.8828
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3562,
      "name": "Gonbad-e Kavus",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.25,
        "lng": 55.1672
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3565,
      "name": "Miass",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.05,
        "lng": 60.1
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3557,
      "name": "Gwangyang",
      "names": {
        "zh": "光阳"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 34.9403,
        "lng": 127.7017
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 3563,
      "name": "Al Manaqil",
      "names": {
        "es": "Al manáqil",
        "ja": "アルマンキル"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 14.2504,
        "lng": 32.98
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 3540,
      "name": "Shantipur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.25,
        "lng": 88.43
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3572,
      "name": "Pomona",
      "names": {
        "zh": "波莫纳",
        "th": "โพโมนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0585,
        "lng": -117.7626
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3547,
      "name": "Hindupur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.83,
        "lng": 77.49
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3575,
      "name": "Escondido",
      "names": {
        "th": "เอสคอนดิโด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1347,
        "lng": -117.0722
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3558,
      "name": "Beawar",
      "names": {
        "zh": "贝阿沃尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.1011,
        "lng": 74.3203
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3568,
      "name": "Nakhodka",
      "names": {
        "zh": "纳霍德卡"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 42.8167,
        "lng": 132.8833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3574,
      "name": "Foggia",
      "names": {
        "zh": "福贾",
        "ja": "フォッジャ",
        "th": "ฟอกเกีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.4584,
        "lng": 15.5519
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 3578,
      "name": "As Suwayhirah as Sahil",
      "names": {
        "es": "Como suwayhirah como sahil",
        "fr": "Comme suwayhirah comme Sahil",
        "zh": "作为Suwayhirah作为撒希尔",
        "de": "Als suwayhirah als sahil",
        "it": "Come Suwayhirah come Sahil",
        "id": "Sebagai suwayhirah sebagai sahil",
        "ja": "SuwayhirahとしてSahilとして",
        "th": "เช่นเดียวกับ Suwayhirah ในฐานะ Sahil",
        "pt": "Como Suayhirah como Sahil"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 24.362,
        "lng": 56.7344
      },
      "country": "Oman",
      "importance": 3
    },
    {
      "id": 3584,
      "name": "Pasadena",
      "names": {
        "zh": "帕萨迪纳",
        "ja": "パサデナ",
        "th": "พาซาดีน่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.6583,
        "lng": -95.1499
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3556,
      "name": "Erode",
      "names": {
        "es": "Erosionar",
        "fr": "Éroder",
        "zh": "侵蚀",
        "de": "Erodieren",
        "it": "Erodere",
        "id": "Mengikis",
        "ja": "浸食",
        "th": "กัดกร่อน",
        "pt": "Corroer"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.3428,
        "lng": 77.7274
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3539,
      "name": "Dahuaishu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.2601,
        "lng": 111.6743
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3536,
      "name": "Minglanilla",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.245,
        "lng": 123.7964
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3583,
      "name": "Manzanillo",
      "names": {
        "zh": "马惹尼诺",
        "de": "In manzanillo"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.3397,
        "lng": -77.1086
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 3571,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7833,
        "lng": 121.0
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3588,
      "name": "Patos de Minas",
      "names": {
        "ja": "パトス・デ・ミナス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.5871,
        "lng": -46.5149
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3569,
      "name": "Quevedo",
      "names": {
        "zh": "克韦多"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -1.0333,
        "lng": -79.45
      },
      "country": "Ecuador",
      "importance": 3
    },
    {
      "id": 3550,
      "name": "Logrono",
      "names": {
        "es": "Logroño",
        "fr": "Logroño",
        "zh": "洛格罗尼奥",
        "ja": "ログローニョ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.465,
        "lng": -2.4456
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 3590,
      "name": "Copiapo",
      "names": {
        "es": "Copiapó",
        "zh": "科皮亚波"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -27.3665,
        "lng": -70.3323
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 3581,
      "name": "Miskolc",
      "names": {
        "zh": "米什科尔茨",
        "ja": "ミシュコルツ"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 48.1,
        "lng": 20.7833
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 3492,
      "name": "Colima",
      "names": {
        "zh": "科利马",
        "ja": "コリマ州"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2433,
        "lng": -103.7247
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 3388,
      "name": "Villeurbanne",
      "names": {
        "zh": "维勒班"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.7667,
        "lng": 4.8803
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 3591,
      "name": "Kragujevac",
      "names": {
        "zh": "克拉古耶瓦茨"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.0142,
        "lng": 20.9394
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 3592,
      "name": "Badajoz",
      "names": {
        "zh": "巴达霍斯",
        "th": "บาดาโฮซ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.8803,
        "lng": -6.9753
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 3579,
      "name": "Paderborn",
      "names": {
        "zh": "帕德博恩",
        "ja": "パーダーボルン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7167,
        "lng": 8.7667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3567,
      "name": "Delicias",
      "names": {
        "zh": "德利西亚斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.1931,
        "lng": -105.4717
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3595,
      "name": "Araguaina",
      "names": {
        "zh": "阿拉瓜伊纳",
        "th": "อารากวัยนา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.1908,
        "lng": -48.2069
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3425,
      "name": "Higashimurayama",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7547,
        "lng": 139.4686
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3560,
      "name": "Kukichuo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0622,
        "lng": 139.6669
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3546,
      "name": "Fengyicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6636,
        "lng": 116.6028
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3606,
      "name": "Shahrud",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.4181,
        "lng": 54.9764
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3566,
      "name": "Mzuzu",
      "names": {
        "zh": "姆祖祖"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -11.45,
        "lng": 34.0333
      },
      "country": "Malawi",
      "importance": 3
    },
    {
      "id": 3601,
      "name": "Valle de Santiago",
      "names": {
        "ja": "ヴァッレデサンティアゴ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3928,
        "lng": -101.1969
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3600,
      "name": "Valdivia",
      "names": {
        "zh": "瓦尔迪维亚"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -39.8142,
        "lng": -73.2459
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 3576,
      "name": "Vryheid",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -27.7694,
        "lng": 30.7914
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 773,
      "name": "Willemstad",
      "names": {
        "zh": "威廉斯塔德",
        "ja": "ウィレムスタット",
        "th": "วิลเลมสตัด"
      },
      "countryCode": "CW",
      "latLng": {
        "lat": 12.108,
        "lng": -68.935
      },
      "country": "Curaçao ",
      "importance": 0
    },
    {
      "id": 3532,
      "name": "Sievierodonetsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.95,
        "lng": 38.4833
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 3543,
      "name": "Yilan",
      "names": {
        "zh": "宜兰"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.75,
        "lng": 121.75
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 3564,
      "name": "Tororo",
      "names": {
        "zh": "托罗罗"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.6928,
        "lng": 34.1808
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 3587,
      "name": "M\u0027Sila",
      "names": {
        "zh": "姆西拉"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.7058,
        "lng": 4.5419
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3597,
      "name": "Ocumare del Tuy",
      "names": {
        "es": "Ocumare del tiy",
        "fr": "OCUMARE DEL TYY",
        "it": "Ocubare del tuy",
        "ja": "オブマーレデルチュー"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1136,
        "lng": -66.7814
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3608,
      "name": "Kashikishi",
      "names": {},
      "countryCode": "ZM",
      "latLng": {
        "lat": -9.3172,
        "lng": 28.7331
      },
      "country": "Zambia",
      "importance": 3
    },
    {
      "id": 3609,
      "name": "Penjamo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4311,
        "lng": -101.7222
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3611,
      "name": "New Bedford",
      "names": {
        "fr": "Nouveau Bedford",
        "zh": "新贝德福德",
        "de": "Neues Bedford",
        "it": "Nuovo Bedford",
        "id": "Bedford baru",
        "ja": "ニューベッドフォード",
        "th": "ใหม่ Bedford"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6697,
        "lng": -70.9428
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3589,
      "name": "Mariveles",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4333,
        "lng": 120.4833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3573,
      "name": "Cao Lanh",
      "names": {
        "ja": "カオラニ",
        "th": "เฉา lanh"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.4603,
        "lng": 105.6331
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3612,
      "name": "Saumlaki",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.975,
        "lng": 131.3075
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 3593,
      "name": "Nimes",
      "names": {
        "es": "De Nimes",
        "fr": "Nîmes",
        "zh": "尼姆",
        "ja": "ニーム",
        "th": "นีม"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.838,
        "lng": 4.361
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3586,
      "name": "Kerch",
      "names": {
        "fr": "Kertch",
        "zh": "刻赤"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 45.3386,
        "lng": 36.4681
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 3618,
      "name": "Concordia",
      "names": {
        "zh": "康科迪亚",
        "ja": "コンコルディア",
        "th": "คอนคอร์เดีย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.3922,
        "lng": -58.0169
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 3596,
      "name": "Rimini",
      "names": {
        "zh": "里米尼",
        "ja": "リミニ",
        "th": "ริมินี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.0594,
        "lng": 12.5683
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 3615,
      "name": "Imabari",
      "names": {
        "zh": "今治",
        "ja": "今治"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.0661,
        "lng": 132.9978
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 772,
      "name": "Port Louis",
      "names": {
        "zh": "港口路易斯",
        "ja": "ポートルイス",
        "th": "พอร์ตหลุยส์",
        "pt": "Porto louis"
      },
      "countryCode": "MU",
      "latLng": {
        "lat": -20.1667,
        "lng": 57.5
      },
      "country": "Mauritius",
      "importance": 0
    },
    {
      "id": 3357,
      "name": "Musashino",
      "names": {
        "zh": "武藏野",
        "ja": "武蔵野"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7178,
        "lng": 139.5661
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3621,
      "name": "Sagay",
      "names": {
        "zh": "萨盖"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.9,
        "lng": 123.4167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3628,
      "name": "`Ajlun",
      "names": {},
      "countryCode": "JO",
      "latLng": {
        "lat": 32.3325,
        "lng": 35.7517
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 3627,
      "name": "Marvdasht",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.8742,
        "lng": 52.8025
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3614,
      "name": "Potchefstroom",
      "names": {
        "zh": "波切夫斯特鲁姆"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.715,
        "lng": 27.1033
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 3631,
      "name": "Fairfield",
      "names": {
        "zh": "费尔菲尔德",
        "ja": "フェアフィールド",
        "th": "แฟร์ฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2592,
        "lng": -122.0324
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3617,
      "name": "Abbottabad",
      "names": {
        "zh": "阿伯塔巴德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.15,
        "lng": 73.2167
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 3624,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.6833,
        "lng": 121.55
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3555,
      "name": "Taza",
      "names": {
        "zh": "塔扎"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.2144,
        "lng": -4.0088
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 3636,
      "name": "Naperville",
      "names": {
        "zh": "内珀维尔",
        "ja": "ネーパーヴィル"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7483,
        "lng": -88.1657
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3580,
      "name": "Sayama",
      "names": {
        "zh": "狭山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8531,
        "lng": 139.4122
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3639,
      "name": "Dundee",
      "names": {
        "zh": "邓迪",
        "ja": "ダンディー",
        "th": "ดันดี"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 56.462,
        "lng": -2.9707
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3642,
      "name": "Bellevue",
      "names": {
        "zh": "贝尔维尤",
        "ja": "ベルビュー",
        "th": "แบล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.5951,
        "lng": -122.1535
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3643,
      "name": "Binghamton",
      "names": {
        "zh": "宾厄姆顿",
        "ja": "ビンガムトン",
        "th": "บิงแฮมตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1014,
        "lng": -75.9093
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3616,
      "name": "Arnhem",
      "names": {
        "zh": "阿纳姆",
        "ja": "アーネム"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9833,
        "lng": 5.9167
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 3561,
      "name": "Consolacion",
      "names": {
        "de": "Consolación"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.4,
        "lng": 123.95
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3623,
      "name": "Lang Son",
      "names": {
        "es": "Hijo de lang",
        "fr": "Fils de Lang",
        "zh": "郎儿子",
        "de": "Lang Sohn",
        "it": "Lang figlio",
        "id": "Langsing",
        "th": "พระบุตร",
        "pt": "Filho de lang"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.8478,
        "lng": 106.7578
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3645,
      "name": "Mbanza Kongo",
      "names": {
        "es": "Mbbanz kongo"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -6.2667,
        "lng": 14.25
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 3637,
      "name": "Quilpue",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -33.0498,
        "lng": -71.4415
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 3443,
      "name": "Rafael Castillo",
      "names": {
        "zh": "拉斐尔卡斯蒂略",
        "th": "ราฟาเอลคาร์ติลเลีย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7167,
        "lng": -58.6167
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 3622,
      "name": "Marbella",
      "names": {
        "zh": "马贝拉",
        "ja": "マルベージャ",
        "th": "มาร์เบลลา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.5167,
        "lng": -4.8833
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 3646,
      "name": "Nchelenge",
      "names": {},
      "countryCode": "ZM",
      "latLng": {
        "lat": -9.3467,
        "lng": 28.7344
      },
      "country": "Zambia",
      "importance": 3
    },
    {
      "id": 3607,
      "name": "Kutaisi",
      "names": {
        "zh": "库塔伊西"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 42.25,
        "lng": 42.7
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 3599,
      "name": "Komaki",
      "names": {
        "zh": "小牧"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2833,
        "lng": 136.9167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3544,
      "name": "Siem Reap",
      "names": {
        "fr": "Siem récolter",
        "zh": "暹粒市",
        "de": "Siem-Reap",
        "ja": "シェムリアップ",
        "th": "เสียมเรียบ"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 13.3622,
        "lng": 103.8597
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 3585,
      "name": "Talcahuano",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -36.7145,
        "lng": -73.1141
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 3648,
      "name": "Turbat",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 26.0031,
        "lng": 63.0544
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 3164,
      "name": "Orekhovo-Borisovo Yuzhnoye",
      "names": {
        "zh": "奥雷库沃博士湾玉津福"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.6031,
        "lng": 37.7331
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3641,
      "name": "Kopeysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.1,
        "lng": 61.6167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3654,
      "name": "Elkhart",
      "names": {
        "zh": "埃尔克哈特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6916,
        "lng": -85.9628
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3649,
      "name": "Mati",
      "names": {
        "zh": "马蒂"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.95,
        "lng": 126.2333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3594,
      "name": "Sumbawanga",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -7.9667,
        "lng": 31.6167
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 3653,
      "name": "Osorno",
      "names": {
        "zh": "奥索尔诺"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -40.5667,
        "lng": -73.15
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 3655,
      "name": "Topeka",
      "names": {
        "zh": "托皮卡",
        "ja": "トピーカ",
        "th": "โทพีกา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0346,
        "lng": -95.6955
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 3651,
      "name": "Malakal",
      "names": {
        "zh": "马拉卡尔"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 9.5333,
        "lng": 31.65
      },
      "country": "South Sudan",
      "importance": 1
    },
    {
      "id": 3629,
      "name": "Chingola",
      "names": {
        "zh": "钦戈拉"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.5447,
        "lng": 27.8708
      },
      "country": "Zambia",
      "importance": 3
    },
    {
      "id": 3650,
      "name": "Mangghystau",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.6905,
        "lng": 51.1417
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 3502,
      "name": "Kafr ash Shaykh",
      "names": {
        "es": "Kafr ceniza shaykh",
        "ja": "カフェ灰シェイク"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.1,
        "lng": 30.95
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 3658,
      "name": "Joliet",
      "names": {
        "zh": "乔利埃特",
        "ja": "ジョリエット"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5189,
        "lng": -88.1499
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3518,
      "name": "Tama",
      "names": {
        "zh": "多摩",
        "ja": "多摩",
        "th": "มะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6333,
        "lng": 139.45
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3634,
      "name": "Sibiu",
      "names": {
        "zh": "锡比乌",
        "ja": "シビウ",
        "th": "ซีบีอู"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.8,
        "lng": 24.15
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 3657,
      "name": "Mogi Guacu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.3719,
        "lng": -46.9419
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3626,
      "name": "Dongsheng",
      "names": {
        "zh": "东升"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.8869,
        "lng": 113.4563
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3632,
      "name": "Habra",
      "names": {
        "zh": "哈布拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.83,
        "lng": 88.63
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3638,
      "name": "Yonago",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4281,
        "lng": 133.3311
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3656,
      "name": "Man",
      "names": {
        "es": "Hombre",
        "fr": "L\u0027homme",
        "zh": "男子",
        "de": "Mann",
        "it": "Uomo",
        "id": "Pria",
        "ja": "おとこのひと",
        "th": "ชาย",
        "pt": "Cara"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 7.4004,
        "lng": -7.55
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 3669,
      "name": "Wamba",
      "names": {
        "zh": "万巴"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.1404,
        "lng": 27.99
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 3604,
      "name": "Gulu",
      "names": {
        "zh": "古卢"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.7667,
        "lng": 32.3056
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 3647,
      "name": "Ambala",
      "names": {
        "zh": "安巴拉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 30.3786,
        "lng": 76.7725
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3663,
      "name": "Cairns",
      "names": {
        "zh": "凯恩斯",
        "ja": "ケアンズ",
        "th": "เมืองแครนส์"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -16.92,
        "lng": 145.78
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 3668,
      "name": "Cam Ranh",
      "names": {
        "zh": "凸轮ranh",
        "it": "Camramo",
        "ja": "カムラン",
        "th": "ลูกเบี้ยว ranh"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.902,
        "lng": 109.2207
      },
      "country": "Vietnam",
      "importance": 3
    },
    {
      "id": 3603,
      "name": "Clermont-Ferrand",
      "names": {
        "zh": "克莱蒙费朗",
        "ja": "クレルモンフェランフランス",
        "th": "มอนต์-ferrand"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.7831,
        "lng": 3.0824
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3582,
      "name": "Florencio Varela",
      "names": {
        "zh": "Florencio瓦雷拉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8167,
        "lng": -58.3833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 3670,
      "name": "Odintsovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.6733,
        "lng": 37.2733
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3676,
      "name": "Keren",
      "names": {
        "zh": "凯伦"
      },
      "countryCode": "ER",
      "latLng": {
        "lat": 15.7833,
        "lng": 38.45
      },
      "country": "Eritrea",
      "importance": 1
    },
    {
      "id": 3662,
      "name": "Dar`a",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.625,
        "lng": 36.105
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 3619,
      "name": "West Bromwich",
      "names": {
        "id": "Bromwich Barat",
        "th": "เวสต์บรอมวิช"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.519,
        "lng": -1.995
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3577,
      "name": "Riobamba",
      "names": {
        "zh": "里奥班巴"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -1.6743,
        "lng": -78.6483
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 3684,
      "name": "Santo Agostinho",
      "names": {
        "ja": "サントアゴスチンホ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.29,
        "lng": -35.03
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3630,
      "name": "Mauli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 30.7194,
        "lng": 76.7181
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3679,
      "name": "Colina",
      "names": {
        "zh": "科利纳"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.2,
        "lng": -70.6833
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 3671,
      "name": "Dadu",
      "names": {
        "zh": "大渡河"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 26.7319,
        "lng": 67.775
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 3685,
      "name": "Beaumont",
      "names": {
        "zh": "博蒙特",
        "ja": "ボーモント",
        "th": "โบมอนต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.0849,
        "lng": -94.1451
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3674,
      "name": "Pleiku",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 13.9715,
        "lng": 108.0147
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3687,
      "name": "Garcia",
      "names": {
        "es": "García",
        "zh": "·加西亚",
        "ja": "ガルシア",
        "th": "การ์เซีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.8167,
        "lng": -100.5833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3659,
      "name": "Pyatigorsk",
      "names": {
        "de": "Pjatigorsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.0333,
        "lng": 43.05
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3678,
      "name": "Calapan",
      "names": {
        "zh": "卡拉潘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.3667,
        "lng": 121.2
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3625,
      "name": "Iruma",
      "names": {
        "zh": "入间"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8358,
        "lng": 139.3911
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3672,
      "name": "Franco da Rocha",
      "names": {
        "ja": "フランコダロチャ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3286,
        "lng": -46.7244
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3686,
      "name": "Parnaiba",
      "names": {
        "zh": "巴纳伊巴",
        "pt": "Parnaíba"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.905,
        "lng": -41.7769
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3677,
      "name": "Newport",
      "names": {
        "zh": "纽波特",
        "ja": "ニューポート",
        "th": "นิวพอร์ต"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5833,
        "lng": -3.0
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3633,
      "name": "Mostaganem",
      "names": {
        "zh": "穆斯塔加奈姆"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.9333,
        "lng": 0.0903
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3675,
      "name": "Etah",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.63,
        "lng": 78.67
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3695,
      "name": "Bani",
      "names": {
        "zh": "巴尼",
        "ja": "バニ",
        "th": "ซุ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.29,
        "lng": -70.33
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 3613,
      "name": "Gloucester",
      "names": {
        "zh": "告士打道",
        "ja": "グロスター",
        "th": "กลอสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.8644,
        "lng": -2.2444
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3700,
      "name": "Quchan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.1118,
        "lng": 58.5015
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3605,
      "name": "Pointe-a-Pitre",
      "names": {
        "fr": "Pointe-à-Pitre",
        "zh": "普安特-一个-pitre",
        "ja": "ポイント・ア・pitre",
        "th": "ปวงรูปแบบ pitre"
      },
      "countryCode": "GP",
      "latLng": {
        "lat": 16.2415,
        "lng": -61.533
      },
      "country": "Guadeloupe",
      "importance": 3
    },
    {
      "id": 3702,
      "name": "Porto Seguro",
      "names": {
        "zh": "波尔图赛尔罗",
        "ja": "ポルトセグロ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.45,
        "lng": -39.065
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3664,
      "name": "Rubtsovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 51.5167,
        "lng": 81.2
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3697,
      "name": "Matanzas",
      "names": {
        "zh": "马坦萨斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 23.0494,
        "lng": -81.5736
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 3708,
      "name": "Paterson",
      "names": {
        "zh": "帕特森",
        "ja": "パターソン",
        "th": "ถนนแพเตอร์สัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9147,
        "lng": -74.1624
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3709,
      "name": "Merced",
      "names": {
        "zh": "梅塞德",
        "ja": "マーセド",
        "th": "เมอร์เซด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3057,
        "lng": -120.4778
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3701,
      "name": "Berezniki",
      "names": {
        "zh": "别列兹尼基"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.4167,
        "lng": 56.7833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3705,
      "name": "Villa de Cura",
      "names": {
        "ja": "ヴィラデーラ",
        "th": "วิลล่าเดอคาร่า"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0386,
        "lng": -67.4894
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3693,
      "name": "Khairpur Mir\u0027s",
      "names": {
        "de": "Khairpur Mir ist",
        "id": "Khairpur Mir",
        "ja": "Khairpur Mir",
        "pt": "Khairpur Mir"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 27.5333,
        "lng": 68.7667
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 3712,
      "name": "Flores",
      "names": {
        "zh": "弗洛雷斯",
        "ja": "フローレス",
        "th": "ฟลอเรส"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 16.9333,
        "lng": -89.8833
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 3691,
      "name": "Arayat",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.1493,
        "lng": 120.7692
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3698,
      "name": "David",
      "names": {
        "zh": "大卫",
        "ja": "デビッド",
        "th": "เดวิด"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.4333,
        "lng": -82.4333
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 3665,
      "name": "Poole",
      "names": {
        "zh": "普尔",
        "ja": "プール",
        "th": "พูล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.7167,
        "lng": -1.9833
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3713,
      "name": "Comayagua",
      "names": {
        "zh": "科马亚瓜"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.46,
        "lng": -87.65
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 3683,
      "name": "Yima",
      "names": {
        "zh": "义马"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7473,
        "lng": 111.8609
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3718,
      "name": "Saguenay",
      "names": {
        "zh": "萨古恩河"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 48.4167,
        "lng": -71.0667
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 3660,
      "name": "Moshi",
      "names": {
        "zh": "莫希"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -3.3349,
        "lng": 37.3404
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 3714,
      "name": "Dongxing",
      "names": {
        "zh": "东兴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.5833,
        "lng": 108.05
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3682,
      "name": "Larisa",
      "names": {
        "zh": "拉里莎",
        "id": "Lárisa"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 39.6385,
        "lng": 22.4131
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 3710,
      "name": "Kolar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.1333,
        "lng": 78.1333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3688,
      "name": "Urdaneta",
      "names": {
        "zh": "乌达内塔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9761,
        "lng": 120.5711
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3610,
      "name": "Odivelas",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.8,
        "lng": -9.1833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3696,
      "name": "Xintang",
      "names": {
        "zh": "新塘"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.7824,
        "lng": 113.1514
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3716,
      "name": "Barranca",
      "names": {
        "zh": "巴兰卡"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -10.7539,
        "lng": -77.761
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 3652,
      "name": "Bacau",
      "names": {
        "zh": "巴克乌"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.5833,
        "lng": 26.9167
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 3720,
      "name": "Kumba",
      "names": {
        "zh": "昆巴"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.6333,
        "lng": 9.45
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 3711,
      "name": "Al Mukalla",
      "names": {
        "ja": "アルムカラ"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.5333,
        "lng": 49.1333
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 3680,
      "name": "Kolomna",
      "names": {
        "zh": "科洛姆纳"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.0833,
        "lng": 38.7833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3726,
      "name": "Pueblo",
      "names": {
        "zh": "普韦布洛",
        "ja": "プエブロ",
        "th": "ปวย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2705,
        "lng": -104.6112
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3721,
      "name": "Ruse",
      "names": {
        "zh": "鲁塞",
        "ja": "虚勢",
        "th": "อุบาย"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.8445,
        "lng": 25.9539
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 3570,
      "name": "Asaka",
      "names": {
        "zh": "朝霞"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7972,
        "lng": 139.5936
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3725,
      "name": "Florencia",
      "names": {
        "zh": "弗洛伦西亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.6142,
        "lng": -75.6117
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 3724,
      "name": "Bharatpur",
      "names": {
        "zh": "巴拉普尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.6833,
        "lng": 84.4333
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 3729,
      "name": "Ajdabiya",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 30.77,
        "lng": 20.22
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 3731,
      "name": "Tyler",
      "names": {
        "zh": "泰勒",
        "ja": "タイラー",
        "th": "ไทเลอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.3184,
        "lng": -95.3062
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3707,
      "name": "Kakamigahara",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3989,
        "lng": 136.8486
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3739,
      "name": "Torrance",
      "names": {
        "zh": "托兰斯",
        "ja": "トーランス",
        "th": "เทอร์เรซ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8346,
        "lng": -118.3417
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3733,
      "name": "Ciego de Avila",
      "names": {
        "ja": "Cieego de Avila"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.8481,
        "lng": -78.7631
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 3743,
      "name": "Levis",
      "names": {
        "zh": "李维斯",
        "ja": "リーバイス",
        "th": "เลวิส"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.8,
        "lng": -71.1833
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 3640,
      "name": "Disuq",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 31.1325,
        "lng": 30.6478
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 3730,
      "name": "Calabayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.7667,
        "lng": 121.7833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3728,
      "name": "Coimbra",
      "names": {
        "zh": "科英布拉",
        "ja": "コインブラ",
        "th": "โกอินบรา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.2111,
        "lng": -8.4289
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 3737,
      "name": "Ghazni",
      "names": {
        "zh": "加兹尼",
        "th": "ซนิ"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.5492,
        "lng": 68.4233
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 3738,
      "name": "El Progreso",
      "names": {
        "pt": "El progresso"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.4,
        "lng": -87.8
      },
      "country": "Honduras",
      "importance": 3
    },
    {
      "id": 3742,
      "name": "Jau",
      "names": {
        "zh": "雅乌"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2958,
        "lng": -48.5578
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3644,
      "name": "Salamanca",
      "names": {
        "zh": "萨拉曼卡",
        "ja": "サラマンカ",
        "th": "ซาลามันกา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.9667,
        "lng": -5.6639
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 3689,
      "name": "Le Mans",
      "names": {
        "zh": "勒芒",
        "ja": "ルマン",
        "th": "เลอม็อง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.0077,
        "lng": 0.1984
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3706,
      "name": "Kusatsu",
      "names": {
        "zh": "草津",
        "ja": "草津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0167,
        "lng": 135.9667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3703,
      "name": "Shillong",
      "names": {
        "zh": "西隆",
        "ja": "シロン",
        "th": "ซิลลอง"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.5744,
        "lng": 91.8789
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 3745,
      "name": "Jaragua do Sul",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -26.4858,
        "lng": -49.0669
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3727,
      "name": "Aix-en-Provence",
      "names": {
        "zh": "普罗旺斯地区艾克斯",
        "ja": "エックスアンプロヴァンス",
        "th": "เมืองอายซ์ออง-โปรวองซ์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.5263,
        "lng": 5.4454
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3723,
      "name": "Malasiqui",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9167,
        "lng": 120.4167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3748,
      "name": "Los Angeles",
      "names": {
        "zh": "洛杉矶",
        "ja": "ロサンゼルス",
        "th": "ลอสแองเจลิส"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -37.4707,
        "lng": -72.3517
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 3704,
      "name": "Cape Coast",
      "names": {
        "es": "Costa del cabo",
        "fr": "Cale",
        "zh": "海岸",
        "de": "Kap-Küste",
        "ja": "ケープコースト",
        "th": "ชายฝั่งเคป",
        "pt": "Costa do Cabo"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 5.1,
        "lng": -1.25
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 3734,
      "name": "Birkenhead",
      "names": {
        "zh": "伯肯黑德"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.393,
        "lng": -3.014
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3661,
      "name": "Bizerte",
      "names": {
        "zh": "比塞大"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 37.2744,
        "lng": 9.8739
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 3746,
      "name": "Jinjiang",
      "names": {
        "zh": "锦江之星",
        "th": "จิ่นเจียง"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 19.7386,
        "lng": 110.0058
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3736,
      "name": "Ashikaga",
      "names": {
        "zh": "足利"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3403,
        "lng": 139.4497
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3751,
      "name": "Yuma",
      "names": {
        "zh": "尤马",
        "ja": "ユマ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.5992,
        "lng": -114.5488
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3750,
      "name": "Telford",
      "names": {
        "zh": "德福",
        "ja": "テルフォード",
        "th": "เทลฟอร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.6766,
        "lng": -2.4469
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3722,
      "name": "Cekme",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0382,
        "lng": 29.2003
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 3741,
      "name": "Huelva",
      "names": {
        "zh": "韦尔瓦",
        "ja": "ウエルバ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.25,
        "lng": -6.95
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 3754,
      "name": "Moundou",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 8.5667,
        "lng": 16.0833
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 3732,
      "name": "Nawabganj",
      "names": {},
      "countryCode": "BD",
      "latLng": {
        "lat": 24.5804,
        "lng": 88.35
      },
      "country": "Bangladesh",
      "importance": 3
    },
    {
      "id": 3602,
      "name": "Toda",
      "names": {
        "zh": "户田",
        "ja": "戸田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8175,
        "lng": 139.6778
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3753,
      "name": "Lichinga",
      "names": {
        "zh": "利欣加"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -13.3,
        "lng": 35.2456
      },
      "country": "Mozambique",
      "importance": 1
    },
    {
      "id": 3692,
      "name": "Settat",
      "names": {
        "zh": "塞塔特"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.0023,
        "lng": -7.6198
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 3744,
      "name": "Bhimavaram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.5333,
        "lng": 81.5333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3764,
      "name": "Kelowna",
      "names": {
        "zh": "基洛纳",
        "ja": "ケロウナ",
        "th": "คีโลว์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.8881,
        "lng": -119.4956
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 3666,
      "name": "Negombo",
      "names": {
        "zh": "尼甘布",
        "th": "เนกอมโบ"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 7.2111,
        "lng": 79.8386
      },
      "country": "Sri Lanka",
      "importance": 3
    },
    {
      "id": 3765,
      "name": "Metairie",
      "names": {
        "zh": "梅泰里"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.9977,
        "lng": -90.178
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3715,
      "name": "Okinawa",
      "names": {
        "zh": "冲绳",
        "ja": "沖縄",
        "th": "โอกินาวา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 26.3342,
        "lng": 127.8056
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3766,
      "name": "Namacunde",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -17.3,
        "lng": 15.85
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3755,
      "name": "Tetovo",
      "names": {
        "zh": "泰托沃"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0103,
        "lng": 20.9714
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 3749,
      "name": "Boca Chica",
      "names": {
        "zh": "博卡奇卡",
        "ja": "ボカチカ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4539,
        "lng": -69.6064
      },
      "country": "Dominican Republic",
      "importance": 3
    },
    {
      "id": 3735,
      "name": "Altay",
      "names": {
        "zh": "阿勒泰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.8666,
        "lng": 88.1166
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3757,
      "name": "Maykop",
      "names": {
        "zh": "迈科普"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.6,
        "lng": 40.0833
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 3756,
      "name": "Bahawalnagar",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 29.9944,
        "lng": 73.2536
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 3673,
      "name": "Misato",
      "names": {
        "zh": "美里",
        "ja": "ミサト"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8303,
        "lng": 139.8725
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3541,
      "name": "Moriguchi",
      "names": {
        "zh": "守口",
        "ja": "守口"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7358,
        "lng": 135.5617
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3752,
      "name": "Preston",
      "names": {
        "zh": "普雷斯顿",
        "ja": "プレストン",
        "th": "เพรสตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.759,
        "lng": -2.699
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3768,
      "name": "Mandsaur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.03,
        "lng": 75.08
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3777,
      "name": "Surprise",
      "names": {
        "es": "Sorpresa",
        "zh": "惊喜",
        "de": "Überraschung",
        "it": "Sorpresa",
        "id": "Kejutan",
        "ja": "驚き",
        "th": "แปลกใจ",
        "pt": "Surpresa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.68,
        "lng": -112.4524
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3778,
      "name": "Columbia",
      "names": {
        "fr": "-Britannique",
        "zh": "哥伦比亚",
        "de": "Kolumbien",
        "ja": "コロンビア",
        "th": "โคลัมเบีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9478,
        "lng": -92.3256
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3776,
      "name": "Jahrom",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 28.5,
        "lng": 53.56
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3779,
      "name": "Athens",
      "names": {
        "es": "Atenas",
        "fr": "Athènes",
        "zh": "雅典",
        "de": "Athen",
        "it": "Atene",
        "id": "Athena",
        "ja": "アテネ",
        "th": "เอเธนส์",
        "pt": "Atenas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9508,
        "lng": -83.3689
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3780,
      "name": "Roseville",
      "names": {
        "zh": "罗斯维尔",
        "ja": "ローズビル",
        "th": "โรสวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.7703,
        "lng": -121.3196
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3770,
      "name": "Nong\u0027an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.419,
        "lng": 125.1702
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3781,
      "name": "Thornton",
      "names": {
        "zh": "桑顿",
        "ja": "ソーントン",
        "th": "ธอร์นตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9197,
        "lng": -104.9438
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3694,
      "name": "Bocaue",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8,
        "lng": 120.9333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3785,
      "name": "Abbotsford",
      "names": {
        "zh": "阿伯茨福德",
        "ja": "アボッツフォード",
        "th": "แอบบอท"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.05,
        "lng": -122.3167
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 3783,
      "name": "Khuzdar",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 27.8,
        "lng": 66.6167
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 3760,
      "name": "Fukayacho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1975,
        "lng": 139.2814
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3784,
      "name": "Tepatitlan de Morelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.817,
        "lng": -102.733
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3635,
      "name": "Pemba",
      "names": {
        "zh": "奔巴岛",
        "ja": "ペンバ"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -12.9667,
        "lng": 40.5167
      },
      "country": "Mozambique",
      "importance": 1
    },
    {
      "id": 3740,
      "name": "Bilbays",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.4167,
        "lng": 31.5667
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 3762,
      "name": "Gweru",
      "names": {
        "zh": "圭鲁"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -19.458,
        "lng": 29.82
      },
      "country": "Zimbabwe",
      "importance": 1
    },
    {
      "id": 3717,
      "name": "Khasavyurt",
      "names": {
        "zh": "哈萨维尤尔特"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.25,
        "lng": 46.5833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 3789,
      "name": "Miramar",
      "names": {
        "zh": "米拉马",
        "ja": "ミラマー",
        "th": "มิรามาร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.9773,
        "lng": -80.3351
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3759,
      "name": "Kamalshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.8622,
        "lng": 50.8717
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 3772,
      "name": "Rio das Ostras",
      "names": {
        "id": "Rio das Östra"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5269,
        "lng": -41.945
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3792,
      "name": "Pasadena",
      "names": {
        "zh": "帕萨迪纳",
        "ja": "パサデナ",
        "th": "พาซาดีน่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1597,
        "lng": -118.139
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3769,
      "name": "Rybnik",
      "names": {
        "zh": "雷布尼克",
        "th": "ริบนิค"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0833,
        "lng": 18.5
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 3763,
      "name": "Tabaco",
      "names": {
        "zh": "塔瓦科"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.35,
        "lng": 123.7333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3775,
      "name": "Fujita",
      "names": {
        "zh": "藤田",
        "ja": "藤田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9194,
        "lng": 138.2339
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3796,
      "name": "Mesquite",
      "names": {
        "zh": "梅斯基特",
        "ja": "メスキート",
        "th": "ซีซั่น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7623,
        "lng": -96.5889
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3681,
      "name": "Mejicanos",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.7403,
        "lng": -89.2131
      },
      "country": "El Salvador",
      "importance": 3
    },
    {
      "id": 3788,
      "name": "Porac",
      "names": {
        "zh": "波拉克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0719,
        "lng": 120.5419
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3767,
      "name": "Irakleio",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 35.3403,
        "lng": 25.1344
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 3782,
      "name": "Middlesbrough",
      "names": {
        "zh": "米德尔斯堡",
        "ja": "ミドルスブラ",
        "th": "มิดเดิ้ลสโบรช์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.5767,
        "lng": -1.2355
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3802,
      "name": "Olathe",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 38.8832,
        "lng": -94.8198
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3803,
      "name": "Santa Maria",
      "names": {
        "zh": "圣玛丽亚",
        "ja": "サンタマリア",
        "th": "ซานตามาเรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.9334,
        "lng": -120.4432
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3795,
      "name": "Shizhaobi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.921,
        "lng": 115.7774
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3800,
      "name": "Paranagua",
      "names": {
        "zh": "巴拉那瓜",
        "pt": "Paranaguá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.5208,
        "lng": -48.5094
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3807,
      "name": "Houma",
      "names": {
        "zh": "侯马"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.5799,
        "lng": -90.7058
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3801,
      "name": "Iguala de la Independencia",
      "names": {
        "zh": "Iguala de la Indoolencia",
        "ja": "イグアラデランタンシア"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.35,
        "lng": -99.5333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3790,
      "name": "Ozamiz City",
      "names": {
        "es": "Ciudad de Ozamiz",
        "zh": "奥兹米兹市",
        "de": "Ozamiz-Stadt",
        "id": "Kota Ozamiz",
        "ja": "オザミスシティ",
        "th": "เมือง Ozamiz",
        "pt": "Cidade de ozamiz"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.15,
        "lng": 123.85
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3806,
      "name": "Yaritagua",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0753,
        "lng": -69.1283
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3791,
      "name": "Pecs",
      "names": {
        "zh": "佩奇",
        "th": "เพซ"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.0708,
        "lng": 18.2331
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 3620,
      "name": "Centro Habana",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 23.1333,
        "lng": -82.3833
      },
      "country": "Cuba",
      "importance": 3
    },
    {
      "id": 3798,
      "name": "Lleida",
      "names": {
        "zh": "莱里达",
        "ja": "リェイダ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.6167,
        "lng": 0.6333
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 3719,
      "name": "Cuautitlan Izcalli",
      "names": {
        "id": "Cuaiitlan izcalli"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.65,
        "lng": -99.25
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3814,
      "name": "Torbat-e Heydariyeh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.2739,
        "lng": 59.2194
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3786,
      "name": "Batu Pahat",
      "names": {
        "es": "Batuida",
        "zh": "巴图娃娃",
        "ja": "バトゥパハット"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 1.85,
        "lng": 102.93
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 3813,
      "name": "Al `Aqabah",
      "names": {
        "zh": "Al`aqabah",
        "ja": "Al`Aカババ",
        "th": "อัล `Aqabah",
        "pt": "Al \u0027Aqabah"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 29.5167,
        "lng": 35.0
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 3552,
      "name": "Peristeri",
      "names": {
        "zh": "佩里斯特里"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0167,
        "lng": 23.6833
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 3598,
      "name": "Mallawi",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 27.7306,
        "lng": 30.8425
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 3816,
      "name": "Dawei",
      "names": {
        "zh": "大为"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 14.0367,
        "lng": 98.1867
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 3809,
      "name": "Fusagasuga",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.3372,
        "lng": -74.3644
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 3819,
      "name": "Atbara",
      "names": {
        "zh": "阿特巴拉"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 17.7167,
        "lng": 33.9833
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 3526,
      "name": "Kanasin",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9344,
        "lng": -89.5578
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3810,
      "name": "La Romana",
      "names": {
        "zh": "拉罗马纳"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.43,
        "lng": -68.97
      },
      "country": "Dominican Republic",
      "importance": 1
    },
    {
      "id": 3758,
      "name": "Brest",
      "names": {
        "zh": "布雷斯特",
        "ja": "ブレスト",
        "th": "เบรสต์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.39,
        "lng": -4.49
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3804,
      "name": "Masaya",
      "names": {
        "zh": "马萨亚"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.9667,
        "lng": -86.1
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 3815,
      "name": "Momostenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0444,
        "lng": -91.4083
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 3820,
      "name": "Pakpattan",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.35,
        "lng": 73.4
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 3823,
      "name": "Botucatu",
      "names": {
        "zh": "博图卡图"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8858,
        "lng": -48.445
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3811,
      "name": "Tanay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4972,
        "lng": 121.2864
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3787,
      "name": "Ruda Slaska",
      "names": {
        "ja": "RUDAスラスカ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2701,
        "lng": 18.8742
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 3817,
      "name": "Zielona Gora",
      "names": {
        "ja": "ジエロナゴーラ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.9397,
        "lng": 15.505
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 3747,
      "name": "Blackpool",
      "names": {
        "zh": "布莱克浦",
        "ja": "ブラックプール",
        "th": "แบล็คพูล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.8142,
        "lng": -3.0503
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3830,
      "name": "Coquitlam",
      "names": {
        "zh": "高贵林",
        "th": "ควิท"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2839,
        "lng": -122.7919
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 3793,
      "name": "Kumbakonam",
      "names": {
        "zh": "贡伯戈讷姆"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 10.9805,
        "lng": 79.4
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3831,
      "name": "Carrollton",
      "names": {
        "zh": "卡罗尔顿",
        "ja": "キャロルトン",
        "th": "แคร์รอลล์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.989,
        "lng": -96.8999
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3834,
      "name": "Grand Junction",
      "names": {
        "es": "Gran cruce",
        "fr": "Grand jonction",
        "zh": "大交界处",
        "de": "Großankömmling",
        "it": "Grande incrocio",
        "id": "Persimpangan",
        "ja": "グランドジャンクション",
        "th": "ชุมทางแกรนด์",
        "pt": "Junção grande"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0876,
        "lng": -108.5673
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3835,
      "name": "Kokshetau",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 53.2824,
        "lng": 69.3969
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 3761,
      "name": "Lausanne",
      "names": {
        "zh": "洛桑",
        "ja": "ローザンヌ",
        "th": "โลซาน"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.5333,
        "lng": 6.6333
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 3841,
      "name": "Piranshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.6944,
        "lng": 45.1417
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3833,
      "name": "Ishizaki",
      "names": {
        "zh": "石崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.4281,
        "lng": 141.3061
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3818,
      "name": "Kuwana",
      "names": {
        "zh": "桑名"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0622,
        "lng": 136.6839
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3844,
      "name": "Charleston",
      "names": {
        "zh": "查尔斯顿",
        "ja": "チャールストン",
        "th": "ชาร์ลสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.3484,
        "lng": -81.6323
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 3845,
      "name": "Orange",
      "names": {
        "es": "Naranja",
        "zh": "橙色",
        "it": "Arancia",
        "id": "Jeruk",
        "ja": "オレンジ",
        "th": "สีส้ม",
        "pt": "Laranja"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8038,
        "lng": -117.8218
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3846,
      "name": "Fullerton",
      "names": {
        "zh": "馥敦",
        "ja": "フラートン",
        "th": "ฟุลเลอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8841,
        "lng": -117.9279
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3794,
      "name": "Kovrov",
      "names": {
        "zh": "科夫罗夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3606,
        "lng": 41.3197
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3847,
      "name": "Sancti Spiritus",
      "names": {
        "id": "Sancti spiritu"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.9339,
        "lng": -79.4439
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 3805,
      "name": "Medea",
      "names": {
        "zh": "美狄亚",
        "ja": "メデア"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.2675,
        "lng": 2.75
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3840,
      "name": "Tiruvannamalai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 12.2604,
        "lng": 79.1
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3827,
      "name": "Koga",
      "names": {
        "zh": "古贺",
        "ja": "古賀"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1833,
        "lng": 139.7167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3843,
      "name": "Dipolog",
      "names": {
        "zh": "第波罗"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.5667,
        "lng": 123.3333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3821,
      "name": "Chicacole",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.3,
        "lng": 83.9
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3852,
      "name": "Shunan",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.0553,
        "lng": 131.8061
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3822,
      "name": "Pabna",
      "names": {
        "zh": "巴布纳"
      },
      "countryCode": "BD",
      "latLng": {
        "lat": 24.0004,
        "lng": 89.25
      },
      "country": "Bangladesh",
      "importance": 3
    },
    {
      "id": 3838,
      "name": "Candelaria",
      "names": {
        "zh": "坎德拉里亚"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9311,
        "lng": 121.4233
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 3839,
      "name": "Tsuchiura",
      "names": {
        "ja": "土浦"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0667,
        "lng": 140.2
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3858,
      "name": "Greeley",
      "names": {
        "zh": "格里利",
        "ja": "グリーリー",
        "th": "ฮิ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4151,
        "lng": -104.7706
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3842,
      "name": "Umtata",
      "names": {
        "zh": "乌姆塔塔"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -31.58,
        "lng": 28.79
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 3865,
      "name": "Negage",
      "names": {
        "zh": "加吉"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -7.7667,
        "lng": 15.2667
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3828,
      "name": "Tan An",
      "names": {
        "es": "Broncearse",
        "fr": "Bronzer",
        "zh": "晒黑",
        "de": "Bräunen",
        "it": "Abbronzatura",
        "id": "Tan sebuah",
        "ja": "タンan",
        "th": "ตาล",
        "pt": "Tan um"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.5322,
        "lng": 106.4042
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3826,
      "name": "La Trinidad",
      "names": {
        "zh": "拉特立尼达",
        "ja": "ラトリニダード",
        "th": "ลาตรินิแดด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.4621,
        "lng": 120.5877
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3870,
      "name": "Bankura",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.25,
        "lng": 87.0667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3869,
      "name": "Jyvaskyla",
      "names": {
        "fr": "Jyväskylä",
        "zh": "于韦斯屈莱",
        "de": "Jyväskylä"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.2333,
        "lng": 25.7333
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 3857,
      "name": "Mandya",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 12.5242,
        "lng": 76.8958
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3862,
      "name": "Palhoca",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -27.6444,
        "lng": -48.6678
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3850,
      "name": "Jolo",
      "names": {
        "zh": "霍洛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.05,
        "lng": 121.0
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3859,
      "name": "Ch\u0027ungmu",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 34.8458,
        "lng": 128.4236
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 3868,
      "name": "Atibaia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.1172,
        "lng": -46.5506
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3873,
      "name": "Quillacollo",
      "names": {},
      "countryCode": "BO",
      "latLng": {
        "lat": -17.3975,
        "lng": -66.2817
      },
      "country": "Bolivia",
      "importance": 3
    },
    {
      "id": 3866,
      "name": "Gunungsitoli",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 1.1167,
        "lng": 97.5667
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 3881,
      "name": "Porto Amboim",
      "names": {
        "fr": "Porto Amboime",
        "th": "ปอร์โตแอมโบม"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -10.7183,
        "lng": 13.75
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3856,
      "name": "Ingolstadt",
      "names": {
        "zh": "因戈尔施塔特",
        "ja": "インゴルシュタット"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7636,
        "lng": 11.4261
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 3824,
      "name": "Mino",
      "names": {
        "fr": "Miño",
        "zh": "美浓",
        "ja": "美濃"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8269,
        "lng": 135.4706
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3880,
      "name": "Curico",
      "names": {
        "es": "Curicó",
        "zh": "库里科"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -34.9854,
        "lng": -71.2394
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 3874,
      "name": "Garanhuns",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.8903,
        "lng": -36.4928
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3875,
      "name": "Livingstone",
      "names": {
        "zh": "利文斯通",
        "ja": "リビングストン",
        "th": "ลิฟวิงสโตน"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -17.85,
        "lng": 25.8667
      },
      "country": "Zambia",
      "importance": 3
    },
    {
      "id": 3872,
      "name": "Vila Franca de Xira",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.95,
        "lng": -8.9833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3667,
      "name": "Gisenyi",
      "names": {
        "zh": "吉塞尼"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -1.6928,
        "lng": 29.25
      },
      "country": "Rwanda",
      "importance": 3
    },
    {
      "id": 3855,
      "name": "Darwin",
      "names": {
        "zh": "达尔文",
        "ja": "ダーウィン",
        "th": "ดาร์วิน"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -12.4381,
        "lng": 130.8411
      },
      "country": "Australia",
      "importance": 1
    },
    {
      "id": 3887,
      "name": "Carolina",
      "names": {
        "fr": "Caroline",
        "zh": "南卡罗来纳州",
        "ja": "カロライナ州",
        "th": "แคโรไลนา"
      },
      "countryCode": "PR",
      "latLng": {
        "lat": 18.4054,
        "lng": -65.9792
      },
      "country": "Puerto Rico",
      "importance": 3
    },
    {
      "id": 3883,
      "name": "Gingoog",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.8167,
        "lng": 125.1
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3888,
      "name": "Marivan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.5269,
        "lng": 46.1761
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3690,
      "name": "Barrechid",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.27,
        "lng": -7.5872
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 3699,
      "name": "San Justo",
      "names": {
        "it": "San jousto"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6833,
        "lng": -58.55
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 3825,
      "name": "Shizuishan",
      "names": {
        "zh": "石嘴山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.2333,
        "lng": 106.769
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3882,
      "name": "Nasugbu",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0667,
        "lng": 120.6333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3876,
      "name": "Bima",
      "names": {
        "zh": "玛"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.4667,
        "lng": 118.717
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3891,
      "name": "Stara Zagora",
      "names": {
        "id": "Sara Zagora"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.4257,
        "lng": 25.6346
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 3808,
      "name": "Tours",
      "names": {
        "zh": "旅游",
        "de": "Touren",
        "ja": "ツアー",
        "th": "ทัวร์",
        "pt": "Passeios"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.3936,
        "lng": 0.6892
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3864,
      "name": "Carcar",
      "names": {
        "zh": "卡尔卡尔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1167,
        "lng": 123.6333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3893,
      "name": "Chech\u0027on",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.1361,
        "lng": 128.2119
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 3895,
      "name": "Jinotega",
      "names": {
        "zh": "希诺特加"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.0833,
        "lng": -86.0
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 3832,
      "name": "San Jose del Cabo",
      "names": {
        "zh": "圣何塞德德布诺",
        "ja": "サンノゼデルカボ",
        "th": "ซานโฮเซ่เดลคาโบ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.0614,
        "lng": -109.7081
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3903,
      "name": "Las Cruces",
      "names": {
        "fr": "Cruces Las",
        "zh": "拉斯克拉斯",
        "de": "Laskürze",
        "it": "Las si croga",
        "ja": "ラスクルーセス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.3265,
        "lng": -106.7892
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3877,
      "name": "Dos Hermanas",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 37.2836,
        "lng": -5.9222
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 3897,
      "name": "Pageralam",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -4.0217,
        "lng": 103.2522
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 3900,
      "name": "Termiz",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 37.2242,
        "lng": 67.2783
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 3907,
      "name": "Panama City",
      "names": {
        "es": "Ciudad de Panamá",
        "zh": "巴拿马城",
        "de": "Panama-Stadt",
        "id": "Kota Panama",
        "ja": "パナマシティ",
        "th": "ปานามาซิตี้",
        "pt": "Cidade do Panamá"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.1995,
        "lng": -85.6004
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3878,
      "name": "Kisarazu",
      "names": {
        "zh": "木更津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3761,
        "lng": 139.9169
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3894,
      "name": "Norzagaray",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9167,
        "lng": 121.05
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3909,
      "name": "Harlingen",
      "names": {
        "zh": "哈林根",
        "th": "ฮาร์ลิงเจน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.1917,
        "lng": -97.6976
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3886,
      "name": "Yaizu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8667,
        "lng": 138.3167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3861,
      "name": "Birganj",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 27.0,
        "lng": 84.8667
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 3913,
      "name": "Tobruk",
      "names": {
        "fr": "Tobrouk",
        "zh": "托布鲁克",
        "th": "บรุค"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.0833,
        "lng": 23.95
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 3910,
      "name": "Toowoomba",
      "names": {
        "zh": "图文巴"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -27.5667,
        "lng": 151.95
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 3916,
      "name": "Ramapo",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 41.1404,
        "lng": -74.1072
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3799,
      "name": "Ebina",
      "names": {
        "zh": "海老名"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4464,
        "lng": 139.3908
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3901,
      "name": "Floridablanca",
      "names": {
        "zh": "佛罗里达布兰卡"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9333,
        "lng": 120.5
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3889,
      "name": "Santa Tecla",
      "names": {
        "zh": "圣塔雷加",
        "ja": "サンタテクラ",
        "th": "ซานตาเทคล่า"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.6742,
        "lng": -89.2899
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 3906,
      "name": "Rionegro",
      "names": {
        "zh": "里奥内格罗"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1533,
        "lng": -75.3742
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 3863,
      "name": "Lira",
      "names": {
        "zh": "里拉",
        "ja": "リラ",
        "th": "ลีร่า"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.2489,
        "lng": 32.9
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 3860,
      "name": "Tarragona",
      "names": {
        "fr": "Tarragone",
        "zh": "塔拉戈纳",
        "ja": "タラゴナ",
        "th": "ตาราโกนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.1187,
        "lng": 1.2453
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 3915,
      "name": "Pyay",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 18.8165,
        "lng": 95.2114
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 3884,
      "name": "Maia",
      "names": {
        "zh": "玛雅",
        "ja": "マイア"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2333,
        "lng": -8.6167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3812,
      "name": "Ituzaingo",
      "names": {
        "zh": "伊图萨因戈"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6582,
        "lng": -58.6672
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 3923,
      "name": "West Valley City",
      "names": {
        "es": "Ciudad de West Valley",
        "zh": "西山谷市",
        "de": "Westtalstadt",
        "id": "Kota Lembah Barat",
        "ja": "ウェストバレーシティ",
        "pt": "Cidade do Vale do Oeste"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6889,
        "lng": -112.0115
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3871,
      "name": "Kigoma",
      "names": {
        "zh": "基戈马"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -4.8833,
        "lng": 29.6333
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 3921,
      "name": "May Pen",
      "names": {
        "es": "Pluma",
        "fr": "Pen",
        "zh": "可以笔",
        "de": "Mai Pen",
        "it": "Penna",
        "id": "Dapat pena",
        "th": "อาจปากกา",
        "pt": "Pode caneta"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 17.95,
        "lng": -77.25
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 3924,
      "name": "Andimeshk",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.45,
        "lng": 48.35
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3920,
      "name": "Urganch",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.5345,
        "lng": 60.6249
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 3898,
      "name": "Tizi Ouzou",
      "names": {
        "ja": "尾塚"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7169,
        "lng": 4.0497
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3914,
      "name": "Tauranga",
      "names": {
        "zh": "陶兰加",
        "ja": "タウランガ",
        "th": "เทารางงา"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -37.6858,
        "lng": 176.1667
      },
      "country": "New Zealand",
      "importance": 3
    },
    {
      "id": 3925,
      "name": "Santa Rita",
      "names": {
        "zh": "圣丽塔",
        "ja": "サンタリタ",
        "th": "ซานตาริต้า"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.1139,
        "lng": -34.9778
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3890,
      "name": "Inazawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.25,
        "lng": 136.7833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3919,
      "name": "Cartago",
      "names": {
        "zh": "卡塔戈",
        "ja": "カルタゴ",
        "th": "คาร์ตาโก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.75,
        "lng": -75.91
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 3932,
      "name": "Teofilo Otoni",
      "names": {
        "ja": "テオフィロオトニ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -17.8578,
        "lng": -41.505
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3929,
      "name": "El Oued",
      "names": {
        "fr": "Eluge",
        "zh": "eloued",
        "ja": "エルウォー",
        "pt": "El ured"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.3683,
        "lng": 6.8675
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3936,
      "name": "Shahreza",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.0089,
        "lng": 51.8667
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3937,
      "name": "Zabol",
      "names": {
        "id": "Zabul",
        "th": "ซาโบล"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 31.0308,
        "lng": 61.4972
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3935,
      "name": "Moron",
      "names": {
        "es": "Morón",
        "fr": "Idiot",
        "zh": "笨蛋",
        "de": "Trottel",
        "id": "Bodoh",
        "ja": "バカ",
        "th": "คนโง่",
        "pt": "Imbecil"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 49.6375,
        "lng": 100.1614
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 3904,
      "name": "\u0027s-Hertogenbosch",
      "names": {
        "zh": "\u0027s-斯海尔托亨博斯",
        "th": "\u0027เอส-hertogenbosch"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6833,
        "lng": 5.3167
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 3939,
      "name": "Hampton",
      "names": {
        "zh": "汉普顿",
        "ja": "ハンプトン",
        "th": "แฮมป์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.0551,
        "lng": -76.363
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3930,
      "name": "Ashqelon",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 31.6658,
        "lng": 34.5664
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 3928,
      "name": "Nakhon Ratchasima",
      "names": {
        "ja": "ナコーンラーチャシーマ",
        "th": "นครราชสีมา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.9736,
        "lng": 102.0831
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 3942,
      "name": "Trois-Rivieres",
      "names": {
        "zh": "三河-rivieres",
        "ja": "トロワ-rivieres",
        "th": "ทรัวส์-rivieres"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.35,
        "lng": -72.55
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 3934,
      "name": "Laghouat",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.8,
        "lng": 2.865
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3912,
      "name": "Brighton",
      "names": {
        "zh": "布赖顿",
        "ja": "ブライトン",
        "th": "ไบรตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.8284,
        "lng": -0.1395
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3902,
      "name": "Targu-Mures",
      "names": {
        "es": "Targu Mures-",
        "fr": "-Targu mures",
        "de": "Târgu-mures",
        "it": "Targu Mures-",
        "pt": "Târgu-Mures"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.5497,
        "lng": 24.5597
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 3922,
      "name": "Zafarwal",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.3463,
        "lng": 74.8999
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 3945,
      "name": "Londuimbali",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.2419,
        "lng": 15.3133
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3853,
      "name": "Dumaguete City",
      "names": {
        "es": "Ciudad Dumaguete",
        "zh": "杜马格特市",
        "de": "Dumaguete-Stadt",
        "it": "Città di Dumaguete",
        "id": "Kota Dumaguete",
        "ja": "ダムゲテシティ",
        "th": "เมือง Dumaguete",
        "pt": "Cidade de dumaguete"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.3103,
        "lng": 123.3081
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3949,
      "name": "Navoiy",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.0833,
        "lng": 65.3833
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 3829,
      "name": "Sale",
      "names": {
        "es": "Venta",
        "fr": "Vente",
        "zh": "销售",
        "de": "Verkauf",
        "it": "Vendita",
        "id": "Dijual",
        "ja": "販売",
        "th": "ขาย",
        "pt": "Venda"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.424,
        "lng": -2.322
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 3905,
      "name": "Salerno",
      "names": {
        "zh": "萨勒诺",
        "ja": "サレルノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6806,
        "lng": 14.7597
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 3953,
      "name": "Warren",
      "names": {
        "zh": "沃伦",
        "ja": "ウォーレン",
        "th": "วอร์เรน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4934,
        "lng": -83.027
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3926,
      "name": "Daraga",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.1619,
        "lng": 123.6939
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3892,
      "name": "Amiens",
      "names": {
        "zh": "亚眠",
        "ja": "アミアン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.892,
        "lng": 2.299
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3955,
      "name": "Mauldin",
      "names": {
        "zh": "莫尔丁"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.7849,
        "lng": -82.3005
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3956,
      "name": "Bloomington",
      "names": {
        "zh": "布卢明顿",
        "ja": "ブルーミントン",
        "th": "ในบลูมิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4757,
        "lng": -88.9703
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3940,
      "name": "Famalicao",
      "names": {
        "es": "Famalicão",
        "fr": "Famalicão",
        "pt": "Famalicão"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.4167,
        "lng": -8.5167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 3896,
      "name": "Tiraspol",
      "names": {
        "zh": "蒂拉斯波尔",
        "ja": "ティラスポリ"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 46.85,
        "lng": 29.6333
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 774,
      "name": "Bern",
      "names": {
        "es": "Berna",
        "fr": "Berne",
        "zh": "伯尔尼",
        "ja": "ベルン",
        "th": "เบิร์น",
        "pt": "Berna"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.948,
        "lng": 7.4474
      },
      "country": "Switzerland",
      "importance": 0
    },
    {
      "id": 3951,
      "name": "Idfu",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 24.9781,
        "lng": 32.8789
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 3959,
      "name": "Coral Springs",
      "names": {
        "fr": "Ressorts coralliens",
        "zh": "珊瑚泉",
        "de": "Korallenfedern",
        "it": "Sorgenti di corallo",
        "id": "Pegas karang",
        "ja": "サンゴスプリングス",
        "th": "ปะการังสปริงส์",
        "pt": "Corais"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.2702,
        "lng": -80.2591
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3957,
      "name": "Jiangshan",
      "names": {
        "zh": "江山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.7412,
        "lng": 118.6225
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3867,
      "name": "Yunxian Chengguanzhen",
      "names": {
        "zh": "云县成庄镇",
        "ja": "雲母Chengguanzhen"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.8082,
        "lng": 110.8136
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 3954,
      "name": "Isahaya",
      "names": {
        "zh": "谏早"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.8442,
        "lng": 130.0536
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3927,
      "name": "Milagro",
      "names": {
        "zh": "米拉格罗",
        "ja": "ミラグロ"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -2.1347,
        "lng": -79.5872
      },
      "country": "Ecuador",
      "importance": 3
    },
    {
      "id": 3952,
      "name": "Segou",
      "names": {
        "fr": "Ségou",
        "zh": "塞古",
        "de": "Ségou"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 13.45,
        "lng": -6.2667
      },
      "country": "Mali",
      "importance": 1
    },
    {
      "id": 3950,
      "name": "Tando Allahyar",
      "names": {
        "it": "Tando alahyar",
        "ja": "タンドアアレラル"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 25.4667,
        "lng": 68.7167
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 3933,
      "name": "Hagonoy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8333,
        "lng": 120.7333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3967,
      "name": "Hassan",
      "names": {
        "zh": "·哈桑",
        "ja": "ハッサン",
        "th": "ฮัสซัน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 13.005,
        "lng": 76.1028
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3964,
      "name": "Matagalpa",
      "names": {
        "zh": "马塔加尔帕"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.9167,
        "lng": -85.9167
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 3969,
      "name": "Round Rock",
      "names": {
        "es": "Roca redonda",
        "fr": "Rock rond",
        "zh": "圆形岩石",
        "de": "Runde Felsen",
        "it": "Roccia rotonda",
        "id": "Batu bundar",
        "ja": "ラウンドロック",
        "th": "หินกลม",
        "pt": "Rodada"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.5254,
        "lng": -97.666
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3968,
      "name": "Pitalito",
      "names": {
        "zh": "皮塔利托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.8539,
        "lng": -76.0514
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 3972,
      "name": "Yakima",
      "names": {
        "zh": "亚基马",
        "ja": "ヤキマ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.5923,
        "lng": -120.5496
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3941,
      "name": "Naga",
      "names": {
        "zh": "那伽",
        "ja": "ナーガ",
        "th": "นาค"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2167,
        "lng": 123.75
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3885,
      "name": "Uppsala",
      "names": {
        "zh": "乌普萨拉",
        "ja": "ウプサラ",
        "th": "อุปซอลา"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.8601,
        "lng": 17.64
      },
      "country": "Sweden",
      "importance": 3
    },
    {
      "id": 3973,
      "name": "Khorramshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 30.4333,
        "lng": 48.1833
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 3975,
      "name": "Crato",
      "names": {
        "zh": "克拉图"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.2339,
        "lng": -39.4089
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3977,
      "name": "Ouargla",
      "names": {
        "zh": "瓦尔格拉"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 31.95,
        "lng": 5.3167
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 765,
      "name": "Male",
      "names": {
        "es": "VARÓN",
        "fr": "Mâle",
        "zh": "雄性",
        "de": "Männlich",
        "it": "Maschio",
        "id": "Laki-laki",
        "ja": "男性",
        "th": "เพศชาย",
        "pt": "Masculino"
      },
      "countryCode": "MV",
      "latLng": {
        "lat": 4.175,
        "lng": 73.5083
      },
      "country": "Maldives",
      "importance": 0
    },
    {
      "id": 3978,
      "name": "Sinop",
      "names": {
        "zh": "锡诺普"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.8639,
        "lng": -55.5039
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3965,
      "name": "Gondia",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.45,
        "lng": 80.2
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3944,
      "name": "Zhangmu Touwei",
      "names": {
        "zh": "张谟·托威",
        "ja": "Zhangmu Touii"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9078,
        "lng": 114.0603
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 3943,
      "name": "Torrejon de Ardoz",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 40.4614,
        "lng": -3.4978
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 3966,
      "name": "Gyor",
      "names": {
        "fr": "Györ",
        "zh": "杰尔",
        "th": "เจอร์"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6842,
        "lng": 17.6344
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 3946,
      "name": "Rehovot",
      "names": {
        "zh": "雷霍沃特"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.8914,
        "lng": 34.8078
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 3979,
      "name": "San Carlos",
      "names": {
        "zh": "圣卡洛斯",
        "ja": "サンカルロス",
        "th": "ซานคาร์ลอส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.4929,
        "lng": 123.4095
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3911,
      "name": "Chiang Mai",
      "names": {
        "zh": "清迈",
        "ja": "チェンマイ",
        "th": "เชียงใหม่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.7889,
        "lng": 98.9833
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 3982,
      "name": "Cameta",
      "names": {
        "pt": "Cametá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.2439,
        "lng": -49.4958
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3960,
      "name": "Innsbruck",
      "names": {
        "zh": "因斯布鲁克",
        "ja": "インスブルック",
        "th": "อินส์บรุค"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.2683,
        "lng": 11.3933
      },
      "country": "Austria",
      "importance": 1
    },
    {
      "id": 3985,
      "name": "San Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "ซานหลุยส์"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 16.2,
        "lng": -89.44
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 3986,
      "name": "Sterling Heights",
      "names": {
        "es": "Alturas de la Esterlina",
        "fr": "Hauteurs sterling",
        "zh": "英镑高度",
        "de": "Sterlinghöhen",
        "it": "Altezze di sterline",
        "ja": "スターリングハイツ",
        "th": "สเตอร์ลิงไฮทส์",
        "pt": "Alturas esterlinas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5809,
        "lng": -83.0305
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3962,
      "name": "Ome",
      "names": {
        "zh": "甲酯"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7883,
        "lng": 139.275
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3971,
      "name": "Talavera",
      "names": {
        "zh": "塔拉韦拉",
        "ja": "タラベラ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.5839,
        "lng": 120.9189
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 3988,
      "name": "Kent",
      "names": {
        "zh": "肯特",
        "ja": "ケント",
        "th": "เคนท์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.3887,
        "lng": -122.2129
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3989,
      "name": "Ferrara",
      "names": {
        "zh": "费拉拉",
        "ja": "フェラーラ",
        "th": "เฟอร์รารา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.8353,
        "lng": 11.6199
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 3990,
      "name": "San Juan",
      "names": {
        "zh": "圣胡安",
        "ja": "サンファン",
        "th": "ซานฮวน"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.81,
        "lng": -71.23
      },
      "country": "Dominican Republic",
      "importance": 1
    },
    {
      "id": 3908,
      "name": "Blitar",
      "names": {
        "zh": "勿里达"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.1,
        "lng": 112.15
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 3991,
      "name": "Calabozo",
      "names": {
        "zh": "卡拉沃索"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.9219,
        "lng": -67.4283
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3983,
      "name": "Ibarra",
      "names": {
        "zh": "伊巴拉",
        "th": "โลเม"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": 0.3628,
        "lng": -78.13
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 3993,
      "name": "Chicomba",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.1333,
        "lng": 14.9167
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 3995,
      "name": "Guelph",
      "names": {
        "zh": "圭尔夫",
        "ja": "グエルフ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.55,
        "lng": -80.25
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 775,
      "name": "Papeete",
      "names": {
        "zh": "帕皮提"
      },
      "countryCode": "PF",
      "latLng": {
        "lat": -17.5334,
        "lng": -149.5667
      },
      "country": "French Polynesia",
      "importance": 0
    },
    {
      "id": 3879,
      "name": "Parla",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 40.2372,
        "lng": -3.7742
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 3849,
      "name": "Tultepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.685,
        "lng": -99.1281
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 3999,
      "name": "Ji-Parana",
      "names": {
        "es": "Ji-Paraná",
        "zh": "ji-巴拉那",
        "ja": "ji-パラナ",
        "th": "ji-ปารานา",
        "pt": "Ji-Paraná"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.8853,
        "lng": -61.9517
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 3836,
      "name": "Al Khmissat",
      "names": {
        "ja": "アルKmissat"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.81,
        "lng": -6.06
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 3963,
      "name": "Jijel",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.8206,
        "lng": 5.7667
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3974,
      "name": "Limoges",
      "names": {
        "zh": "利摩日",
        "ja": "リモージュ",
        "th": "ลิโมจส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.8353,
        "lng": 1.2625
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 3976,
      "name": "Tra Vinh",
      "names": {
        "ja": "トラヴィン",
        "pt": "Trapaceiro"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 9.9369,
        "lng": 106.3411
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3996,
      "name": "Buea",
      "names": {
        "zh": "布埃亚"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.1667,
        "lng": 9.2333
      },
      "country": "Cameroon",
      "importance": 1
    },
    {
      "id": 3958,
      "name": "Apopa",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.8,
        "lng": -89.1833
      },
      "country": "El Salvador",
      "importance": 3
    },
    {
      "id": 4003,
      "name": "Spanish Town",
      "names": {
        "es": "Pueblo español",
        "fr": "Ville d\u0027espagnol",
        "zh": "西班牙小镇",
        "de": "Spanische Stadt",
        "it": "Città spagnola",
        "id": "Kota Spanyol",
        "ja": "スペインの町",
        "th": "เมืองสเปน",
        "pt": "Cidade espanhola"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 17.9961,
        "lng": -76.9547
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 4010,
      "name": "Burlington",
      "names": {
        "zh": "伯灵顿",
        "ja": "バーリントン",
        "th": "เบอร์ลิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0758,
        "lng": -79.4686
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3992,
      "name": "Pilibhit",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.6333,
        "lng": 79.7667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3994,
      "name": "Palghat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.7792,
        "lng": 76.6547
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3773,
      "name": "Vlore",
      "names": {
        "fr": "Vlorë"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 40.45,
        "lng": 19.4833
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 4015,
      "name": "Marand",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 38.4167,
        "lng": 45.7667
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4017,
      "name": "Quibdo",
      "names": {
        "zh": "基布多"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6923,
        "lng": -76.6582
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 4011,
      "name": "Nueva Concepcion",
      "names": {
        "es": "Nueva concepción",
        "id": "Concepcion nueva",
        "ja": "ヌエバ協議"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.1997,
        "lng": -91.2997
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 3851,
      "name": "Zama",
      "names": {
        "zh": "座间"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4886,
        "lng": 139.4075
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3917,
      "name": "Shuangcheng",
      "names": {
        "zh": "双城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.3503,
        "lng": 126.28
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4005,
      "name": "Surat Thani",
      "names": {
        "zh": "苏叻他尼",
        "ja": "スラットターニー",
        "th": "สุราษฎร์ธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.1397,
        "lng": 99.3306
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 4006,
      "name": "Narita",
      "names": {
        "zh": "成田",
        "ja": "成田",
        "th": "นาริตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7833,
        "lng": 140.3167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4022,
      "name": "Bellingham",
      "names": {
        "zh": "贝灵汉",
        "ja": "ベリンガム",
        "th": "เบลลิงแฮม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 48.7543,
        "lng": -122.4687
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4019,
      "name": "Pouso Alegre",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2281,
        "lng": -45.9336
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4013,
      "name": "Abohar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 30.1204,
        "lng": 74.29
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3980,
      "name": "Ninh Binh",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 20.2581,
        "lng": 105.9797
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3918,
      "name": "Angono",
      "names": {
        "zh": "安戈诺"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5234,
        "lng": 121.1536
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4009,
      "name": "Silay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8,
        "lng": 122.9667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4026,
      "name": "Jiroft",
      "names": {
        "zh": "吉罗夫特"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 28.6781,
        "lng": 57.7406
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4012,
      "name": "City of Isabela",
      "names": {
        "es": "Ciudad de Isabela",
        "fr": "Ville d\u0027Isabela",
        "zh": "伊莎贝拉市",
        "de": "Stadt Isabela",
        "it": "Città di Isabela",
        "id": "Kota Isabela",
        "ja": "イザベラ市",
        "th": "เมืองอิซาเบลา",
        "pt": "Cidade de Isabela"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.7,
        "lng": 121.9667
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 3998,
      "name": "Nanqiaotou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.7217,
        "lng": 113.2926
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4027,
      "name": "Santa Clara",
      "names": {
        "zh": "圣克拉拉",
        "ja": "サンタクララ",
        "th": "ซานตาคลาร่า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3645,
        "lng": -121.968
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3997,
      "name": "Los Guayos",
      "names": {
        "ja": "ロスグアヨス"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1833,
        "lng": -67.9333
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 3771,
      "name": "Inezgane",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.3658,
        "lng": -9.5381
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 3970,
      "name": "Abiko",
      "names": {
        "zh": "我孙子"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8642,
        "lng": 140.0282
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4025,
      "name": "Udon Thani",
      "names": {
        "zh": "乌冬掌",
        "ja": "うどん",
        "th": "อุดรธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4253,
        "lng": 102.7902
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 3854,
      "name": "Mojokerto",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.4722,
        "lng": 112.4336
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 4023,
      "name": "Onomichi",
      "names": {
        "zh": "尾道"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4089,
        "lng": 133.205
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4033,
      "name": "Lucheng",
      "names": {
        "zh": "鹿城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 30.0553,
        "lng": 101.9648
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4034,
      "name": "Racine",
      "names": {
        "zh": "拉辛",
        "ja": "ラシーン",
        "th": "ไซน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7274,
        "lng": -87.8135
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4032,
      "name": "Sannar",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 13.55,
        "lng": 33.6
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 4035,
      "name": "Greenville",
      "names": {
        "zh": "格林维尔",
        "ja": "グリーンヴィル",
        "th": "กรีนวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.5956,
        "lng": -77.3768
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4007,
      "name": "Relizane",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.7372,
        "lng": 0.5558
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 3961,
      "name": "Battambang",
      "names": {
        "zh": "马德望",
        "th": "พระตะบอง"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 13.1028,
        "lng": 103.1983
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 4028,
      "name": "Vitoria de Santo Antao",
      "names": {
        "pt": "Vitória de Santo Antao"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.1264,
        "lng": -35.3075
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4040,
      "name": "Taldyqorghan",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 45.0167,
        "lng": 78.3667
      },
      "country": "Kazakhstan",
      "importance": 1
    },
    {
      "id": 4039,
      "name": "Esteli",
      "names": {
        "es": "Estelí",
        "zh": "埃斯特利"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.0833,
        "lng": -86.35
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 4041,
      "name": "Cambridge",
      "names": {
        "zh": "剑桥",
        "ja": "ケンブリッジ",
        "th": "เคมบริดจ์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.3972,
        "lng": -80.3114
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4030,
      "name": "Huejutla de Reyes",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1333,
        "lng": -98.4167
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 4043,
      "name": "Baidoa",
      "names": {
        "zh": "拜多阿"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": 3.1167,
        "lng": 43.65
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 4018,
      "name": "Girardot",
      "names": {
        "zh": "希拉多特"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.3036,
        "lng": -74.8039
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4002,
      "name": "Palakollu",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.5333,
        "lng": 81.7333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4036,
      "name": "Hanumangarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.5833,
        "lng": 74.3167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4047,
      "name": "Stamford",
      "names": {
        "zh": "斯坦福",
        "ja": "スタンフォード",
        "th": "สแตมฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1035,
        "lng": -73.5583
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3797,
      "name": "Kokubunji",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7108,
        "lng": 139.4622
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4016,
      "name": "Kanchrapara",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.97,
        "lng": 88.43
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4004,
      "name": "Parepare",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -4.0167,
        "lng": 119.6236
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 4049,
      "name": "Cienaga",
      "names": {
        "es": "Ciénaga",
        "zh": "沼泽"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 11.0067,
        "lng": -74.2467
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4051,
      "name": "Chinguar",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.55,
        "lng": 16.3333
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 4014,
      "name": "Tychy",
      "names": {
        "zh": "蒂黑"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.1667,
        "lng": 19.0
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 3987,
      "name": "Stavanger",
      "names": {
        "zh": "斯塔万格",
        "ja": "スタヴァンゲル",
        "th": "สตา"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.9701,
        "lng": 5.7333
      },
      "country": "Norway",
      "importance": 1
    },
    {
      "id": 3947,
      "name": "Batticaloa",
      "names": {
        "zh": "拜蒂克洛"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 7.717,
        "lng": 81.7
      },
      "country": "Sri Lanka",
      "importance": 3
    },
    {
      "id": 4057,
      "name": "Elizabeth",
      "names": {
        "zh": "伊丽莎白",
        "it": "Elisabetta",
        "ja": "エリザベス",
        "th": "เอลิซาเบธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6657,
        "lng": -74.1912
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4053,
      "name": "Mansa",
      "names": {
        "zh": "曼萨",
        "ja": "マンサ"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -11.1822,
        "lng": 28.8833
      },
      "country": "Zambia",
      "importance": 1
    },
    {
      "id": 3931,
      "name": "Mataro",
      "names": {
        "es": "Mataró",
        "fr": "Mataró",
        "zh": "马塔罗",
        "it": "Mataró"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.5333,
        "lng": 2.45
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 4021,
      "name": "Cholula de Rivadabia",
      "names": {
        "fr": "Cholla de rivadiabia"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0633,
        "lng": -98.3064
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 4060,
      "name": "Araras",
      "names": {
        "zh": "阿拉拉斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.3569,
        "lng": -47.3839
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4001,
      "name": "Guagua",
      "names": {
        "zh": "瓜瓜"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9667,
        "lng": 120.6333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4054,
      "name": "Songnim",
      "names": {
        "zh": "松林"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 38.7333,
        "lng": 125.6333
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 4068,
      "name": "Kamina",
      "names": {
        "zh": "卡米纳"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -8.7351,
        "lng": 24.998
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 3837,
      "name": "Bat Yam",
      "names": {
        "es": "Ñame",
        "fr": "Battez-vous",
        "zh": "蝙蝠山药",
        "de": "Bat-Yams",
        "it": "Battito",
        "id": "Kelelawar",
        "ja": "バットヤム",
        "th": "ค้างคาวมันเทศ",
        "pt": "Inhame do morcego"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0231,
        "lng": 34.7503
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 777,
      "name": "Reykjavik",
      "names": {
        "es": "Reykjavík",
        "zh": "雷克雅未克",
        "ja": "レイキャビク",
        "th": "เรคยาวิก"
      },
      "countryCode": "IS",
      "latLng": {
        "lat": 64.1475,
        "lng": -21.935
      },
      "country": "Iceland",
      "importance": 0
    },
    {
      "id": 4024,
      "name": "Kislovodsk",
      "names": {
        "zh": "基斯洛沃茨克",
        "de": "Kislowodsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.91,
        "lng": 42.72
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4020,
      "name": "Rustavi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.5333,
        "lng": 45.0
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 4055,
      "name": "Santo Tomas",
      "names": {
        "ja": "サントトマス",
        "th": "ซานโตโทมัส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.5333,
        "lng": 125.6167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4062,
      "name": "Puno",
      "names": {
        "zh": "普诺",
        "th": "ปูโน"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -15.8433,
        "lng": -70.0236
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 4000,
      "name": "Rijeka",
      "names": {
        "zh": "里耶卡"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3272,
        "lng": 14.4411
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 4050,
      "name": "Tariba",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 7.8167,
        "lng": -72.2167
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4042,
      "name": "Fengcheng",
      "names": {
        "zh": "凤城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.4313,
        "lng": 112.027
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4056,
      "name": "Vasteras",
      "names": {
        "zh": "韦斯特罗斯",
        "id": "Västerås"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.6173,
        "lng": 16.5422
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 776,
      "name": "Luxembourg",
      "names": {
        "es": "Luxemburgo",
        "zh": "卢森堡",
        "de": "Luxemburg",
        "it": "Lussemburgo",
        "id": "Luksemburg",
        "ja": "ルクセンブルク",
        "th": "ลักเซมเบิร์ก",
        "pt": "Luxemburgo"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.6106,
        "lng": 6.1328
      },
      "country": "Luxembourg",
      "importance": 0
    },
    {
      "id": 4038,
      "name": "Saida",
      "names": {
        "zh": "赛达"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.8303,
        "lng": 0.1517
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 4074,
      "name": "Iwakuni",
      "names": {
        "zh": "岩国",
        "ja": "岩国"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.1669,
        "lng": 132.2197
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4078,
      "name": "Whitby",
      "names": {
        "zh": "惠特比",
        "ja": "ウィットビー",
        "th": "วิตบี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.8833,
        "lng": -78.9417
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4065,
      "name": "Rosario",
      "names": {
        "zh": "罗萨里奥",
        "ja": "ロザリオ",
        "th": "โรซาริโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.846,
        "lng": 121.206
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4081,
      "name": "Sakaka",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 30.0,
        "lng": 40.1333
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 3938,
      "name": "Apatzingan de la Constitucion",
      "names": {
        "es": "Apatzingan de la Constitución",
        "de": "Apatzingan de la konkurrieren",
        "id": "Apatzingan de la konstituus",
        "ja": "Apatzingan de la構成"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0886,
        "lng": -102.3508
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 4083,
      "name": "Tumen",
      "names": {
        "zh": "图们"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.9627,
        "lng": 129.8413
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4037,
      "name": "Annecy",
      "names": {
        "zh": "阿纳西",
        "ja": "アヌシー",
        "th": "อานเนอซี"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.916,
        "lng": 6.133
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 4064,
      "name": "Ahmadpur East",
      "names": {
        "es": "Ahmadpur este",
        "fr": "Ahmadpur Est",
        "zh": "Ahmadpur东方",
        "id": "Ahmadpur timur",
        "th": "Ahmadpur ตะวันออก"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 29.1453,
        "lng": 71.2617
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 4085,
      "name": "Al Hasakah",
      "names": {
        "zh": "alakah",
        "it": "Al hakakah",
        "ja": "アルハサカ",
        "th": "อัลฮาวากาห์"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.4833,
        "lng": 40.75
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 4067,
      "name": "Vihari",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.0419,
        "lng": 72.3528
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 4091,
      "name": "Guasdualito",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 7.2467,
        "lng": -70.7292
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4094,
      "name": "Darnah",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 32.7648,
        "lng": 22.6391
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 4061,
      "name": "Jaranwala",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.3342,
        "lng": 73.4194
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 3948,
      "name": "Tabora",
      "names": {
        "zh": "塔博拉"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.0167,
        "lng": 32.8
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 4052,
      "name": "Wurzburg",
      "names": {
        "es": "Würzburg",
        "zh": "维尔茨堡",
        "de": "Würzburg",
        "it": "Würzburg",
        "id": "Würzburg",
        "ja": "ヴュルツブルク",
        "pt": "Würzburg"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7944,
        "lng": 9.9294
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4096,
      "name": "Salmas",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 38.2,
        "lng": 44.7667
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4092,
      "name": "Domodedovo",
      "names": {
        "zh": "多莫杰多沃"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.4333,
        "lng": 37.75
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 778,
      "name": "Praia",
      "names": {
        "zh": "普拉亚",
        "ja": "プライア"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 14.9177,
        "lng": -23.5092
      },
      "country": "Cabo Verde",
      "importance": 0
    },
    {
      "id": 4058,
      "name": "Opole",
      "names": {
        "zh": "奥波莱"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.6722,
        "lng": 17.9253
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 4044,
      "name": "Furth",
      "names": {
        "es": "Fürth",
        "fr": "Fürth",
        "zh": "菲尔特",
        "de": "Fürth",
        "ja": "フュルト",
        "th": "เฟือธ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4783,
        "lng": 10.9903
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4098,
      "name": "Jazan",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 16.8892,
        "lng": 42.5611
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 4087,
      "name": "Guimba",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6581,
        "lng": 120.7689
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4105,
      "name": "Johnson City",
      "names": {
        "zh": "约翰逊市",
        "ja": "ジョンソンシティ",
        "th": "จอห์นสันซิตี้",
        "pt": "Cidade de johnson"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3406,
        "lng": -82.3803
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4071,
      "name": "Tukuyu",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -9.2583,
        "lng": 33.6417
      },
      "country": "Tanzania",
      "importance": 3
    },
    {
      "id": 4031,
      "name": "Zhaozhou",
      "names": {
        "zh": "肇州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7527,
        "lng": 114.7775
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4099,
      "name": "Marica",
      "names": {
        "zh": "马里卡",
        "th": "มาริก้า"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9189,
        "lng": -42.8189
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4109,
      "name": "Bam",
      "names": {
        "zh": "的BAM",
        "ja": "バム",
        "th": "แบม"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 29.1083,
        "lng": 58.3583
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4063,
      "name": "Koforidua",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 6.1,
        "lng": -0.2667
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 4075,
      "name": "Seto",
      "names": {
        "zh": "濑户",
        "ja": "瀬戸"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2167,
        "lng": 137.0833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4117,
      "name": "Nefteyugansk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 61.1,
        "lng": 72.6
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4104,
      "name": "Omiyacho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2222,
        "lng": 138.6214
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 3848,
      "name": "Koganei",
      "names": {
        "zh": "小金井"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6994,
        "lng": 139.5031
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4114,
      "name": "Osaki",
      "names": {
        "zh": "大崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.5772,
        "lng": 140.9556
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4097,
      "name": "Cubatao",
      "names": {
        "pt": "Cubatão"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.8953,
        "lng": -46.4256
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4120,
      "name": "Angren",
      "names": {
        "zh": "安格连"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.0167,
        "lng": 70.1333
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 4119,
      "name": "Pakokku",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 21.332,
        "lng": 95.0866
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 4123,
      "name": "Leiria",
      "names": {
        "zh": "莱里亚",
        "th": "เลเรีย"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.7431,
        "lng": -8.8069
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 4086,
      "name": "Hathras",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.6,
        "lng": 78.05
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4130,
      "name": "Lynchburg",
      "names": {
        "zh": "林奇堡",
        "ja": "リンチバーグ",
        "th": "ลินช์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.4003,
        "lng": -79.1909
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4103,
      "name": "Neftekamsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0833,
        "lng": 54.25
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3981,
      "name": "Kamez",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 41.3833,
        "lng": 19.7667
      },
      "country": "Albania",
      "importance": 3
    },
    {
      "id": 4077,
      "name": "Bataysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.1333,
        "lng": 39.75
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4122,
      "name": "Duitama",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8219,
        "lng": -73.0297
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4008,
      "name": "Kayseri",
      "names": {
        "zh": "开塞利",
        "ja": "カイセリ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7225,
        "lng": 35.4875
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 4136,
      "name": "Catabola",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.1167,
        "lng": 17.3
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 4059,
      "name": "Novocheboksarsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1167,
        "lng": 47.5
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 3899,
      "name": "Ksar El Kebir",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 35.0,
        "lng": -5.9
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 4137,
      "name": "Simi Valley",
      "names": {
        "fr": "Simi Vallée",
        "zh": "西米山谷",
        "de": "Simi-Tal",
        "ja": "シミ渓谷",
        "pt": "SIMI VALEY"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.2663,
        "lng": -118.749
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4116,
      "name": "Santana de Parnaiba",
      "names": {
        "fr": "Santana de Parnaïba",
        "ja": "サンタナデパルニバ",
        "pt": "Santana de Parnaíba"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.4439,
        "lng": -46.9178
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4126,
      "name": "Latina",
      "names": {
        "zh": "拉丁美洲",
        "ja": "ラティーナ",
        "th": "ลาติ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.4676,
        "lng": 12.9037
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4102,
      "name": "Heilbronn",
      "names": {
        "zh": "海尔布隆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.1404,
        "lng": 9.218
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4107,
      "name": "Ulm",
      "names": {
        "zh": "乌尔姆",
        "ja": "ウルム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.3984,
        "lng": 9.9916
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4110,
      "name": "Tay Ninh",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 11.3131,
        "lng": 106.0963
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 3499,
      "name": "San Juan",
      "names": {
        "zh": "圣胡安",
        "ja": "サンファン",
        "th": "ซานฮวน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6,
        "lng": 121.0333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4138,
      "name": "Barbacena",
      "names": {
        "zh": "巴巴塞纳"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.2258,
        "lng": -43.7739
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4133,
      "name": "Guntakal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.17,
        "lng": 77.38
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4135,
      "name": "Sabara",
      "names": {
        "zh": "萨巴拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.8858,
        "lng": -43.8069
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4134,
      "name": "Iizuka",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.6458,
        "lng": 130.6914
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4113,
      "name": "Pforzheim",
      "names": {
        "zh": "普福尔茨海姆",
        "ja": "プフォルツハイム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.895,
        "lng": 8.705
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4100,
      "name": "Orebro",
      "names": {
        "zh": "厄勒布鲁",
        "th": "โอเรโบร"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.2669,
        "lng": 15.1965
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 4143,
      "name": "Magway",
      "names": {
        "th": "มาเกว"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 20.15,
        "lng": 94.95
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 4131,
      "name": "Pangkalpinang",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.1,
        "lng": 106.1
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 4080,
      "name": "Pinsk",
      "names": {
        "zh": "平斯克"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 52.1153,
        "lng": 26.1031
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 4144,
      "name": "Malanje",
      "names": {
        "zh": "马兰热"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -9.5469,
        "lng": 16.3387
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 4045,
      "name": "Serpukhov",
      "names": {
        "zh": "谢尔普霍夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.9167,
        "lng": 37.4
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4148,
      "name": "Gashua",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 12.8705,
        "lng": 11.04
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 4151,
      "name": "Novomoskovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.0333,
        "lng": 38.2667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4128,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.6167,
        "lng": 120.3167
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4150,
      "name": "Shchelkovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.9167,
        "lng": 38.0
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4118,
      "name": "Payakumbuh",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.2244,
        "lng": 100.6325
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 4125,
      "name": "Navadwip",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.4088,
        "lng": 88.3657
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4164,
      "name": "Amherst",
      "names": {
        "zh": "阿默斯特",
        "ja": "アマースト",
        "th": "แอมเฮิร์สต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0117,
        "lng": -78.7569
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4154,
      "name": "Kumbo",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 6.2,
        "lng": 10.66
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 4152,
      "name": "Santa Lucia Cotzumalguapa",
      "names": {
        "es": "Santa Lucía Cotzumalguapa",
        "fr": "Santa Lucia Cotzumalalguapa",
        "zh": "Santa Lucia Cotzumal Guapa",
        "it": "Santa Lucia Cotzumalguapapapapa",
        "ja": "サンタルシアコッツマルグパ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.3333,
        "lng": -91.0167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 4166,
      "name": "Uruguaiana",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.755,
        "lng": -57.0878
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4168,
      "name": "Fort Smith",
      "names": {
        "zh": "史密斯堡",
        "ja": "フォートスミス",
        "th": "ฟอร์ตสมิ ธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.3493,
        "lng": -94.3695
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4162,
      "name": "Bonao",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.9333,
        "lng": -70.4
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 4153,
      "name": "Cam Ranh",
      "names": {
        "zh": "凸轮ranh",
        "it": "Camramo",
        "ja": "カムラン",
        "th": "ลูกเบี้ยว ranh"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.9136,
        "lng": 109.1369
      },
      "country": "Vietnam",
      "importance": 2
    },
    {
      "id": 4121,
      "name": "Basirhat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.6572,
        "lng": 88.8942
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4169,
      "name": "Resende",
      "names": {
        "zh": "雷森迪"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4689,
        "lng": -44.4469
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4048,
      "name": "Villa Alemana",
      "names": {
        "zh": "别墅alemana",
        "ja": "ヴィラアレマナ",
        "th": "วิลล่า alemana"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.0428,
        "lng": -71.3744
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 4124,
      "name": "Larache",
      "names": {
        "zh": "拉腊什"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.1833,
        "lng": -6.15
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 4158,
      "name": "Amatitlan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4741,
        "lng": -90.6247
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 4079,
      "name": "Zoetermeer",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0611,
        "lng": 4.4933
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 4174,
      "name": "Kenosha",
      "names": {
        "zh": "基诺沙"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5864,
        "lng": -87.8765
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4140,
      "name": "Halisahar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.95,
        "lng": 88.42
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4149,
      "name": "Zwolle",
      "names": {
        "zh": "兹沃勒"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5167,
        "lng": 6.1
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 4145,
      "name": "Magelang",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.4706,
        "lng": 110.2178
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 4029,
      "name": "Leiden",
      "names": {
        "zh": "莱顿",
        "ja": "ライデン",
        "th": "ไลเดน"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1544,
        "lng": 4.4947
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 4177,
      "name": "Norman",
      "names": {
        "zh": "诺曼",
        "ja": "ノーマン",
        "th": "นอร์แมน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2335,
        "lng": -97.3471
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4066,
      "name": "Salto",
      "names": {
        "zh": "萨尔图",
        "ja": "サルト"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -31.3883,
        "lng": -57.9606
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 4146,
      "name": "Jizhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.5455,
        "lng": 115.5663
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4186,
      "name": "South Lyon",
      "names": {
        "fr": "Lyon du Sud",
        "zh": "南里昂",
        "de": "Südlyon",
        "it": "South Lione",
        "id": "Lyon Selatan",
        "ja": "サウスリヨン",
        "pt": "Lyon sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4614,
        "lng": -83.6526
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4175,
      "name": "Ouahigouya",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 13.5833,
        "lng": -2.4167
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 4142,
      "name": "Rishra",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.71,
        "lng": 88.35
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4115,
      "name": "Balneario de Camboriu",
      "names": {
        "it": "Balneario de Camboriuu",
        "pt": "Balneário de camboriu"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.9908,
        "lng": -48.635
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4178,
      "name": "Zhaxi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.844,
        "lng": 105.0451
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4188,
      "name": "Pervouralsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.9167,
        "lng": 59.95
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4155,
      "name": "Giugliano in Campania",
      "names": {
        "es": "Giugliano en Campania",
        "fr": "Giugliano en Campanie",
        "zh": "朱格利亚诺在坎帕尼亚",
        "de": "Giugliano in Kampanien",
        "id": "Giugliano di Campania",
        "ja": "カンパニアのGiugliano",
        "th": "Giugliano ใน Campania",
        "pt": "Giugliano em Campania"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.9319,
        "lng": 14.1956
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 4189,
      "name": "New Mirpur",
      "names": {
        "es": "Nuevo Mirpur",
        "fr": "Nouveau mirpur",
        "zh": "新的mirpur",
        "de": "Neue Mirpur",
        "it": "Nuova Mirpur",
        "id": "Mirpur Baru",
        "ja": "新しいミルプル",
        "th": "ใหม่ Mirpur",
        "pt": "Novo mirpur"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.1333,
        "lng": 73.75
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 4194,
      "name": "Boulder",
      "names": {
        "zh": "博尔德",
        "ja": "ボルダー",
        "th": "โบลเดอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0249,
        "lng": -105.2523
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4070,
      "name": "Baigou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.1098,
        "lng": 116.0139
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4076,
      "name": "Drohobych",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.35,
        "lng": 23.5
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 4191,
      "name": "Noksan",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.2039,
        "lng": 127.0847
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 4108,
      "name": "Bajos de Haina",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.42,
        "lng": -70.03
      },
      "country": "Dominican Republic",
      "importance": 3
    },
    {
      "id": 4165,
      "name": "Magalang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.2167,
        "lng": 120.6667
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 4129,
      "name": "Exeter",
      "names": {
        "zh": "埃克塞特",
        "ja": "エクセター",
        "th": "เอ็กซีเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.7167,
        "lng": -3.5333
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4195,
      "name": "Bimbo",
      "names": {
        "zh": "宾博",
        "ja": "やりまん",
        "th": "สมอง"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 4.3313,
        "lng": 18.5163
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 4184,
      "name": "Wolfsburg",
      "names": {
        "zh": "沃尔夫斯堡",
        "ja": "ヴォルフスブルク",
        "th": "โวล์ฟสบวร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4231,
        "lng": 10.7872
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4082,
      "name": "My Tho",
      "names": {
        "es": "Mi tho",
        "fr": "Mon tho",
        "zh": "我的",
        "de": "Mein tho",
        "it": "Il mio tho",
        "id": "Tho saya",
        "ja": "私の",
        "th": "ของฉัน",
        "pt": "Meu tho"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.3543,
        "lng": 106.364
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 4173,
      "name": "Fugu",
      "names": {
        "zh": "东方",
        "ja": "ふぐ"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.0259,
        "lng": 111.0683
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4185,
      "name": "Savannakhet",
      "names": {
        "zh": "沙湾拿吉"
      },
      "countryCode": "LA",
      "latLng": {
        "lat": 16.55,
        "lng": 104.75
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 4198,
      "name": "Khrustalnyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.1214,
        "lng": 38.9453
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 4201,
      "name": "Rudnyy",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 52.9527,
        "lng": 63.13
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 4101,
      "name": "Kaspiysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 42.8833,
        "lng": 47.6333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4206,
      "name": "Magangue",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2467,
        "lng": -74.7594
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4127,
      "name": "Cambridge",
      "names": {
        "zh": "剑桥",
        "ja": "ケンブリッジ",
        "th": "เคมบริดจ์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.2053,
        "lng": 0.1192
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4204,
      "name": "Kirishima",
      "names": {
        "zh": "雾岛",
        "ja": "霧島"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 31.7411,
        "lng": 130.7631
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4207,
      "name": "Maicao",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 11.3778,
        "lng": -72.2414
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4196,
      "name": "Baia Mare",
      "names": {
        "fr": "Baie mare",
        "ja": "バイアマーレ",
        "pt": "Baia égua"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.6597,
        "lng": 23.5819
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 4211,
      "name": "Derbent",
      "names": {
        "zh": "杰尔宾特"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 42.0692,
        "lng": 48.2958
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 4180,
      "name": "Setubal",
      "names": {
        "es": "Setúbal",
        "zh": "塞图巴尔",
        "th": "เซตูบัล",
        "pt": "Setúbal"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.5236,
        "lng": -8.8935
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 4212,
      "name": "Melipilla",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -33.7,
        "lng": -71.2167
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 4112,
      "name": "Monza",
      "names": {
        "zh": "蒙扎",
        "ja": "モンツァ",
        "th": "มอนซา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5836,
        "lng": 9.2736
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4132,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2833,
        "lng": 121.4167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4176,
      "name": "Duma",
      "names": {
        "fr": "Douma",
        "zh": "杜马",
        "ja": "下院",
        "th": "ดูมา"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.5722,
        "lng": 36.4019
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 4216,
      "name": "Longtian",
      "names": {
        "zh": "龙田"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.3512,
        "lng": 114.1293
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4219,
      "name": "Abilene",
      "names": {
        "zh": "阿比林",
        "ja": "アビリーン",
        "th": "ลีน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.4543,
        "lng": -99.7384
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4220,
      "name": "Punta Arenas",
      "names": {
        "fr": "Arènes de punta",
        "zh": "蓬塔阿雷纳斯",
        "id": "Arena Punta",
        "ja": "プンタアリーナ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -53.1627,
        "lng": -70.9081
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 4222,
      "name": "Lehigh Acres",
      "names": {
        "fr": "Lehigh hecres",
        "zh": "Lehigh亩",
        "ja": "エーカーをリーフします"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.612,
        "lng": -81.6388
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4183,
      "name": "Gorzow Wielkopolski",
      "names": {
        "th": "กอริโทาวส์วิลล์สกี้"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.7333,
        "lng": 15.25
      },
      "country": "Poland",
      "importance": 1
    },
    {
      "id": 4200,
      "name": "Indramayu",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -6.3356,
        "lng": 108.319
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 4218,
      "name": "Gagnoa",
      "names": {
        "zh": "加尼奥阿"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 6.1333,
        "lng": -5.9333
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 4221,
      "name": "Varginha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.5508,
        "lng": -45.43
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4193,
      "name": "Algeciras",
      "names": {
        "zh": "阿尔赫西拉斯",
        "ja": "アルヘシラス"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.1275,
        "lng": -5.4539
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 4205,
      "name": "Gapan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3075,
        "lng": 120.9453
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4226,
      "name": "Kaya",
      "names": {
        "zh": "卡亚",
        "ja": "カヤ",
        "th": "ยะ"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 13.0833,
        "lng": -1.0833
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 4224,
      "name": "Munch\u0027on",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 39.259,
        "lng": 127.356
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 4182,
      "name": "Gexianzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.0694,
        "lng": 115.6591
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4225,
      "name": "Zacatecas",
      "names": {
        "zh": "萨卡特卡斯",
        "th": "ซากาเตกัส"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.7736,
        "lng": -102.5736
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 4231,
      "name": "Braganca",
      "names": {
        "es": "Bragança",
        "fr": "Bragança",
        "zh": "布拉干萨",
        "de": "Bragança",
        "pt": "Bragança"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.0628,
        "lng": -46.7728
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4106,
      "name": "Mediouna",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.45,
        "lng": -7.51
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 4235,
      "name": "Las Margaritas",
      "names": {
        "zh": "拉斯玛尔加里塔斯",
        "id": "Las Margarita",
        "ja": "ラスマーガリータス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3078,
        "lng": -91.9683
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4072,
      "name": "Shiyan",
      "names": {
        "zh": "十堰"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1251,
        "lng": 113.8633
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4214,
      "name": "Pati",
      "names": {
        "zh": "帕蒂",
        "ja": "パチ"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.7415,
        "lng": 111.0347
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 4234,
      "name": "Kahta",
      "names": {
        "zh": "卡赫塔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7803,
        "lng": 38.6217
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 4232,
      "name": "Bayawan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.3667,
        "lng": 122.8
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4237,
      "name": "Machiques",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0667,
        "lng": -72.5667
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4223,
      "name": "Dabrowa Gornicza",
      "names": {
        "pt": "Dabrwa gornicza"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.3239,
        "lng": 19.1947
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 4192,
      "name": "San Pedro Garza Garcia",
      "names": {
        "zh": "圣佩德罗加拉加加西亚",
        "ja": "サンペドロガルザガルシア"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.6667,
        "lng": -100.3
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4238,
      "name": "Ciudad Hidalgo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6917,
        "lng": -100.5536
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4243,
      "name": "Behbahan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 30.5958,
        "lng": 50.2417
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4156,
      "name": "Cagua",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1875,
        "lng": -67.4611
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4190,
      "name": "Brits",
      "names": {
        "es": "Los británicos",
        "zh": "布里茨",
        "de": "Briten",
        "it": "Inglesi"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.6167,
        "lng": 27.7667
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 4240,
      "name": "Jalapa",
      "names": {
        "zh": "哈拉帕",
        "th": "ฮลาปา"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6379,
        "lng": -89.9904
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 4247,
      "name": "Pearland",
      "names": {
        "zh": "皮尔兰",
        "th": "เพียร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.5585,
        "lng": -95.3215
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4229,
      "name": "Ise",
      "names": {
        "zh": "伊势",
        "ja": "伊勢"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4833,
        "lng": 136.7167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4249,
      "name": "Maina",
      "names": {
        "zh": "马伊纳"
      },
      "countryCode": "GU",
      "latLng": {
        "lat": 13.4692,
        "lng": 144.7332
      },
      "country": "Guam",
      "importance": 3
    },
    {
      "id": 4199,
      "name": "Cherkessk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.2222,
        "lng": 42.0575
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 4209,
      "name": "Uruma",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 26.3792,
        "lng": 127.8575
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4248,
      "name": "Mufulira",
      "names": {
        "zh": "穆富利拉"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.5546,
        "lng": 28.2604
      },
      "country": "Zambia",
      "importance": 3
    },
    {
      "id": 4251,
      "name": "Edea",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 3.8,
        "lng": 10.1333
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 4161,
      "name": "Aalborg",
      "names": {
        "zh": "奥尔堡",
        "ja": "オールボー"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 57.0337,
        "lng": 9.9166
      },
      "country": "Denmark",
      "importance": 1
    },
    {
      "id": 4252,
      "name": "Honmachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 32.5178,
        "lng": 130.6181
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4254,
      "name": "Ciudad Ojeda",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2,
        "lng": -71.3
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4171,
      "name": "Leon",
      "names": {
        "es": "León",
        "zh": "莱昂",
        "ja": "レオン",
        "th": "ลีออง"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.6056,
        "lng": -5.57
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 4187,
      "name": "Gyumri",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.7894,
        "lng": 43.8475
      },
      "country": "Armenia",
      "importance": 1
    },
    {
      "id": 4246,
      "name": "Xiangcheng",
      "names": {
        "zh": "襄城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.476,
        "lng": 100.5505
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4250,
      "name": "Dharmavaram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.4142,
        "lng": 77.715
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4139,
      "name": "Colchester",
      "names": {
        "zh": "科尔切斯特",
        "ja": "コルチェスター",
        "th": "โคลเชสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.8917,
        "lng": 0.903
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4260,
      "name": "Chinandega",
      "names": {
        "zh": "奇南德加"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.6242,
        "lng": -87.1297
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 4210,
      "name": "Hoi An",
      "names": {
        "fr": "Hoi un",
        "zh": "海安",
        "ja": "ホイアン",
        "th": "ฮอยอัน"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 15.8777,
        "lng": 108.3327
      },
      "country": "Vietnam",
      "importance": 3
    },
    {
      "id": 4264,
      "name": "Dorud",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.4933,
        "lng": 49.075
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4262,
      "name": "Kashiwara",
      "names": {
        "zh": "柏原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5094,
        "lng": 135.7925
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4215,
      "name": "Maastricht",
      "names": {
        "zh": "马斯特里赫特",
        "ja": "マーストリヒト",
        "th": "มาสทริชต์"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.8667,
        "lng": 5.6833
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 4267,
      "name": "Ondjiva",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -17.0667,
        "lng": 15.7333
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 4227,
      "name": "Alberton",
      "names": {
        "zh": "艾伯顿"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.2672,
        "lng": 28.1219
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 4265,
      "name": "Guarapari",
      "names": {
        "zh": "瓜拉帕里"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.6578,
        "lng": -40.5108
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4069,
      "name": "Temperley",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7667,
        "lng": -58.3833
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 4256,
      "name": "Kamalia",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.7258,
        "lng": 72.6447
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 4271,
      "name": "Tsuruoka",
      "names": {
        "zh": "鹤冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.7217,
        "lng": 139.8217
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4230,
      "name": "Lianhe",
      "names": {
        "zh": "联合"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.1414,
        "lng": 129.2577
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4273,
      "name": "Berkeley",
      "names": {
        "zh": "大学伯克利分校",
        "ja": "バークレー",
        "th": "เบิร์กลีย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.8723,
        "lng": -122.276
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 3774,
      "name": "Boulogne-Billancourt",
      "names": {
        "zh": "布洛涅 - 比扬古",
        "ja": "ブローニュビヤンクール"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8352,
        "lng": 2.2409
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 4275,
      "name": "Richardson",
      "names": {
        "zh": "·理查森",
        "ja": "リチャードソン",
        "th": "ริชาร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.9717,
        "lng": -96.7093
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4276,
      "name": "Redding",
      "names": {
        "zh": "雷丁",
        "ja": "レディング",
        "th": "เรดดิง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5698,
        "lng": -122.365
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4259,
      "name": "Hoa Binh",
      "names": {
        "ja": "ホアビン"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.8175,
        "lng": 105.3375
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 4277,
      "name": "Arvada",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 39.8321,
        "lng": -105.1511
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4268,
      "name": "Port-de-Paix",
      "names": {
        "zh": "和平港",
        "id": "Port de paix",
        "ja": "ポート・ド・ペ",
        "th": "พอร์ตเดอ Paix"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.95,
        "lng": -72.8333
      },
      "country": "Haiti",
      "importance": 1
    },
    {
      "id": 4272,
      "name": "Upington",
      "names": {
        "zh": "阿平顿"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -28.4572,
        "lng": 21.2425
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 4280,
      "name": "East Los Angeles",
      "names": {
        "es": "Este de los angeles",
        "fr": "Est Los Angeles",
        "zh": "东洛杉矶",
        "id": "Los Angeles Timur",
        "ja": "イーストロサンゼルス",
        "th": "ตะวันออกลอสแองเจลิส",
        "pt": "Leste los angeles"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0326,
        "lng": -118.1691
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4281,
      "name": "Siracusa",
      "names": {
        "zh": "锡拉库扎",
        "ja": "シラクーサ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.0692,
        "lng": 15.2875
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4244,
      "name": "Baidyabati",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.79,
        "lng": 88.32
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4270,
      "name": "Wuling",
      "names": {
        "zh": "五菱"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.4421,
        "lng": 114.23
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4261,
      "name": "Puruliya",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.3333,
        "lng": 86.3667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4046,
      "name": "Mit Ghamr",
      "names": {
        "es": "Mith ghamr"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.7192,
        "lng": 31.2628
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 4278,
      "name": "Tabuk",
      "names": {
        "zh": "塔布克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.45,
        "lng": 121.4583
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4257,
      "name": "Ghazipur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.5833,
        "lng": 83.5667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4279,
      "name": "Apartado",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.8847,
        "lng": -76.635
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4288,
      "name": "Navojoa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 27.0813,
        "lng": -109.4461
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4167,
      "name": "Porlamar",
      "names": {
        "zh": "波拉马尔"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.9556,
        "lng": -63.8478
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4203,
      "name": "Bergamo",
      "names": {
        "es": "Bérgamo",
        "fr": "Bergame",
        "zh": "贝加莫",
        "ja": "ベルガモ",
        "th": "แบร์กาโม"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.695,
        "lng": 9.67
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4283,
      "name": "Apucarana",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5508,
        "lng": -51.4608
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4291,
      "name": "St. George",
      "names": {
        "es": "San Jorge",
        "zh": "圣乔治",
        "ja": "セントジョージ",
        "th": "เซนต์จอร์จ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.077,
        "lng": -113.577
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4285,
      "name": "Chilapa de Alvarez",
      "names": {
        "ja": "チラパデアルバレズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5944,
        "lng": -99.1778
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4293,
      "name": "Billings",
      "names": {
        "zh": "比林斯",
        "ja": "ビリングズ",
        "th": "บิลลิงส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.7891,
        "lng": -108.5524
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4294,
      "name": "Catchiungo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.5667,
        "lng": 16.2333
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 4255,
      "name": "Fier",
      "names": {
        "ja": "フィエル"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 40.7167,
        "lng": 19.55
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 4245,
      "name": "Kotamobagu",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 0.7333,
        "lng": 124.3167
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 4290,
      "name": "Kot Addu",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.47,
        "lng": 70.9644
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 4305,
      "name": "Yuba City",
      "names": {
        "es": "Ciudad de Yuba",
        "zh": "yuba城",
        "de": "Yuba-Stadt",
        "id": "Kota Yuba",
        "ja": "ユバ市",
        "th": "เมืองยูบะ",
        "pt": "Cidade de yuba"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1357,
        "lng": -121.6381
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4266,
      "name": "Ribeirao Pires",
      "names": {
        "es": "PIRES RIBEIRAO",
        "fr": "Preirao pre",
        "id": "Pires Ribeirao",
        "th": "ริเบเลรา pires",
        "pt": "Ribeirão Pires"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.7108,
        "lng": -46.4128
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4295,
      "name": "Barcelos",
      "names": {
        "zh": "巴塞罗斯",
        "id": "Barbelos"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5333,
        "lng": -8.6167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 4084,
      "name": "Ramos Mejia",
      "names": {
        "zh": "拉莫斯梅嘉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.65,
        "lng": -58.5667
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 4302,
      "name": "Baisha",
      "names": {
        "zh": "白沙"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 29.4774,
        "lng": 119.2853
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4284,
      "name": "Valinhos",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9708,
        "lng": -46.9958
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4111,
      "name": "High Wycombe",
      "names": {
        "es": "Wycombe alto",
        "zh": "高威力",
        "it": "Alto wycombe",
        "id": "Wycombe tinggi",
        "ja": "高wycombe",
        "th": "wycombe สูง",
        "pt": "Alto Wycombe"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.6287,
        "lng": -0.7482
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4308,
      "name": "Kuopio",
      "names": {
        "zh": "库奥皮奥",
        "ja": "クオピオ"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.8925,
        "lng": 27.6783
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 4208,
      "name": "Delgado",
      "names": {
        "zh": "德尔加多",
        "th": "เดลกาโด"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.7167,
        "lng": -89.1667
      },
      "country": "El Salvador",
      "importance": 3
    },
    {
      "id": 4307,
      "name": "Sertaozinho",
      "names": {
        "pt": "Sertãozinho"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.1378,
        "lng": -47.99
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4073,
      "name": "Ban Bang Pu Mai",
      "names": {
        "es": "BAN BANT PU MAI",
        "zh": "禁止Bang Pu Mai",
        "it": "Bang Bang PU Mai",
        "th": "บ้านบางปู่ใหม่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5441,
        "lng": 100.6175
      },
      "country": "Thailand",
      "importance": 3
    },
    {
      "id": 4159,
      "name": "Gateshead",
      "names": {
        "zh": "盖茨黑德",
        "th": "เกทส์เฮด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.95,
        "lng": -1.6
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4242,
      "name": "Guelma",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.4619,
        "lng": 7.4258
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 4274,
      "name": "Plock",
      "names": {
        "zh": "普沃茨克"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.55,
        "lng": 19.7
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 4310,
      "name": "Ghardaia",
      "names": {
        "zh": "盖尔达耶",
        "id": "Ghardaïa"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 32.4833,
        "lng": 3.6667
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 4312,
      "name": "San Carlos",
      "names": {
        "zh": "圣卡洛斯",
        "ja": "サンカルロス",
        "th": "ซานคาร์ลอส"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.65,
        "lng": -68.5833
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 4313,
      "name": "Ciudad Choluteca",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 13.3167,
        "lng": -87.2167
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 4316,
      "name": "Rochester",
      "names": {
        "zh": "罗切斯特",
        "ja": "ロチェスター",
        "th": "โรเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0151,
        "lng": -92.4778
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4160,
      "name": "Pakxe",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 15.1167,
        "lng": 105.7833
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 4304,
      "name": "Ebetsu",
      "names": {
        "ja": "江別市"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 43.1039,
        "lng": 141.5361
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4292,
      "name": "Gottingen",
      "names": {
        "es": "Göttingen",
        "fr": "Göttingen",
        "zh": "哥廷根",
        "de": "Göttingen",
        "it": "Gottinga",
        "id": "Göttingen",
        "ja": "ゲッティンゲン",
        "pt": "Göttingen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5339,
        "lng": 9.9356
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4325,
      "name": "Leominster",
      "names": {
        "zh": "莱姆斯特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5209,
        "lng": -71.7717
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4326,
      "name": "Ajax",
      "names": {
        "zh": "Ajax的",
        "ja": "のAjax",
        "th": "อาแจ็กซ์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.8583,
        "lng": -79.0364
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4327,
      "name": "Kingsport",
      "names": {
        "zh": "金斯波特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.5224,
        "lng": -82.5453
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4269,
      "name": "Shuixi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5111,
        "lng": 113.3161
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4323,
      "name": "Nazarabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.9522,
        "lng": 50.6075
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4311,
      "name": "Candaba",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0933,
        "lng": 120.8283
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4317,
      "name": "Catanduva",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.1378,
        "lng": -48.9728
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4330,
      "name": "Duluth",
      "names": {
        "zh": "德卢斯",
        "ja": "ダルース",
        "th": "ลู"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.7756,
        "lng": -92.1392
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4297,
      "name": "Tizayuca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8333,
        "lng": -98.9833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4299,
      "name": "Mohammad Shahr",
      "names": {
        "zh": "穆罕默德沙河",
        "th": "โมฮัมหมัดชาห์ร์",
        "pt": "Mahammad shahr"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7631,
        "lng": 50.9172
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 4334,
      "name": "Elblag",
      "names": {
        "ja": "エルブロンク"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.1667,
        "lng": 19.4
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 4333,
      "name": "Toledo",
      "names": {
        "zh": "托莱多",
        "ja": "トレド",
        "th": "โทเลโด"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.7139,
        "lng": -53.7428
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4233,
      "name": "Barretos",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.5569,
        "lng": -48.5678
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4241,
      "name": "Pescara",
      "names": {
        "zh": "佩斯卡拉",
        "ja": "ペスカーラ",
        "th": "เปสการา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.4643,
        "lng": 14.2142
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4339,
      "name": "Paulo Afonso",
      "names": {
        "zh": "保罗Afonso",
        "th": "เปาโล Afonso"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.4078,
        "lng": -38.2219
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4289,
      "name": "Perpignan",
      "names": {
        "zh": "佩皮尼昂",
        "ja": "ペルピニャン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 42.6986,
        "lng": 2.8956
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 4336,
      "name": "Araucaria",
      "names": {
        "zh": "南洋杉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.5928,
        "lng": -49.41
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4344,
      "name": "Rock Hill",
      "names": {
        "es": "Colina de rock",
        "fr": "Rock colline",
        "zh": "摇滚山",
        "de": "Rockhügel",
        "id": "Bukit rock",
        "ja": "ロックヒル",
        "th": "หิน",
        "pt": "Colina"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.9416,
        "lng": -81.0244
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4340,
      "name": "Guaratingueta",
      "names": {
        "zh": "瓜拉廷格塔",
        "de": "Guaratinguetá",
        "pt": "Guaratinguetá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8167,
        "lng": -45.2278
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4239,
      "name": "Slough",
      "names": {
        "zh": "斯劳",
        "ja": "レディング",
        "th": "คราบ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.51,
        "lng": -0.59
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4348,
      "name": "Gilroy",
      "names": {
        "zh": "吉尔罗伊"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.0047,
        "lng": -121.5855
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4338,
      "name": "Totonicapan",
      "names": {
        "es": "Totonicapán",
        "pt": "Totonicapán"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9108,
        "lng": -91.3606
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 4352,
      "name": "Cambridge",
      "names": {
        "zh": "剑桥",
        "ja": "ケンブリッジ",
        "th": "เคมบริดจ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3759,
        "lng": -71.1185
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4342,
      "name": "Huaraz",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -9.5333,
        "lng": -77.5333
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 4157,
      "name": "Jandira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5278,
        "lng": -46.9028
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4258,
      "name": "Orekhovo-Zuyevo",
      "names": {
        "zh": "奥列霍沃-祖耶沃"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.8,
        "lng": 38.9667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4298,
      "name": "Darjeeling",
      "names": {
        "zh": "大吉岭",
        "ja": "ダージリン",
        "th": "ดาร์จีลิง"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.0375,
        "lng": 88.2631
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4170,
      "name": "Daitocho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7119,
        "lng": 135.6233
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4320,
      "name": "Dordrecht",
      "names": {
        "zh": "多德雷赫特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.81,
        "lng": 4.67
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 4088,
      "name": "Miramar",
      "names": {
        "zh": "米拉马",
        "ja": "ミラマー",
        "th": "มิรามาร์"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.3375,
        "lng": -97.8694
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 4332,
      "name": "Sopur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 34.3,
        "lng": 74.47
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4360,
      "name": "Nowshera",
      "names": {
        "zh": "瑙谢拉",
        "th": "นาว"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.0153,
        "lng": 71.9747
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 4362,
      "name": "Barcarena Nova",
      "names": {
        "ja": "バルカレナノバ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.5058,
        "lng": -48.6258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4359,
      "name": "Yongqing",
      "names": {
        "zh": "永庆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.2958,
        "lng": 116.4897
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4370,
      "name": "Sugar Land",
      "names": {
        "es": "Tierra de azúcar",
        "fr": "Sucre",
        "zh": "糖陆",
        "de": "Zuckerland",
        "it": "Zucchero",
        "id": "Tanah gula",
        "ja": "シュガーランド",
        "th": "ที่ดินน้ำตาล",
        "pt": "Terra de açúcar"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.5935,
        "lng": -95.6357
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4300,
      "name": "Calumpit",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9167,
        "lng": 120.7667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4361,
      "name": "Butwal",
      "names": {
        "zh": "布特瓦尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.7,
        "lng": 83.45
      },
      "country": "Nepal",
      "importance": 1
    },
    {
      "id": 4368,
      "name": "Santa Cruz do Sul",
      "names": {
        "zh": "圣克鲁斯Do Sul",
        "ja": "サンタクルーズはスルをします",
        "th": "ซานตาครูซทำซ้า"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.7178,
        "lng": -52.4258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4365,
      "name": "Birigui",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.2889,
        "lng": -50.34
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4141,
      "name": "Matamoros",
      "names": {
        "zh": "马塔莫罗斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.533,
        "lng": -103.25
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4095,
      "name": "Kadoma",
      "names": {
        "zh": "卡多马"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7333,
        "lng": 135.5833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4287,
      "name": "Guelmim",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 28.9833,
        "lng": -10.0667
      },
      "country": "Morocco",
      "importance": 1
    },
    {
      "id": 4343,
      "name": "Bruges",
      "names": {
        "es": "Brujas",
        "zh": "布鲁日",
        "de": "Brügge",
        "ja": "ブルージュ",
        "th": "บรูจส์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.2089,
        "lng": 3.2242
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 4286,
      "name": "Cachoeirinha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.9508,
        "lng": -51.0939
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4328,
      "name": "Xuqiaocun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4355,
        "lng": 120.3645
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4147,
      "name": "Kampong Cham",
      "names": {
        "ja": "カンポンチャム",
        "th": "กม่งจาม"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 12.0,
        "lng": 105.45
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 4350,
      "name": "Bandundu",
      "names": {
        "zh": "班顿杜"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -3.31,
        "lng": 17.38
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 4329,
      "name": "Dinalupihan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8833,
        "lng": 120.4667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4376,
      "name": "Malita",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.4,
        "lng": 125.6
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4380,
      "name": "Texas City",
      "names": {
        "es": "Ciudad de Texas",
        "fr": "Ville de Texas",
        "zh": "德克萨斯城",
        "it": "Città del Texas",
        "id": "Kota texas",
        "ja": "テキサスシティ",
        "th": "เมืองเท็กซัส",
        "pt": "Cidade do Texas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.4128,
        "lng": -94.9658
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4321,
      "name": "Gudivada",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.43,
        "lng": 80.99
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4381,
      "name": "Bama",
      "names": {
        "zh": "巴马"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 11.5204,
        "lng": 13.69
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 4354,
      "name": "Lahti",
      "names": {
        "zh": "拉赫蒂",
        "ja": "ラハティ"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.9804,
        "lng": 25.655
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 4366,
      "name": "Ligao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2167,
        "lng": 123.5167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4383,
      "name": "Iowa City",
      "names": {
        "es": "Ciudad de Iowa",
        "zh": "爱荷华州市",
        "de": "Iowa-Stadt",
        "it": "Città di Iowa",
        "id": "Kota Iowa",
        "ja": "アイオワシティ",
        "th": "เมืองไอโอวา",
        "pt": "Cidade de iowa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6559,
        "lng": -91.5303
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4382,
      "name": "Codo",
      "names": {
        "zh": "科多"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.455,
        "lng": -43.8858
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4318,
      "name": "Nouadhibou",
      "names": {
        "zh": "努瓦迪布"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 20.95,
        "lng": -17.0333
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 4358,
      "name": "Trento",
      "names": {
        "zh": "特伦托",
        "de": "Trient",
        "ja": "トレント",
        "th": "เทรนโต"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 46.0667,
        "lng": 11.1167
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 4331,
      "name": "Dongsheng",
      "names": {
        "zh": "东升"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.6199,
        "lng": 113.2895
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4236,
      "name": "Blackburn",
      "names": {
        "zh": "布莱克本",
        "ja": "ブラックバーン",
        "th": "แบล็คเบิร์น"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.748,
        "lng": -2.482
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4386,
      "name": "Saginaw",
      "names": {
        "zh": "萨吉诺",
        "ja": "サギノー",
        "th": "นอว์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.4199,
        "lng": -83.9501
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4335,
      "name": "Nazran",
      "names": {
        "zh": "纳兹兰"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.2167,
        "lng": 44.7667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4378,
      "name": "Aizuwakamatsu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 37.4947,
        "lng": 139.9297
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4324,
      "name": "Besancon",
      "names": {
        "es": "Besançon",
        "zh": "贝桑松",
        "it": "Besançon",
        "ja": "ブザンソン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.24,
        "lng": 6.02
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 4374,
      "name": "Forli",
      "names": {
        "zh": "弗利",
        "ja": "フォルリ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.2225,
        "lng": 12.0408
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4372,
      "name": "Votorantim",
      "names": {
        "zh": "巴西工业集团"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5469,
        "lng": -47.4378
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4390,
      "name": "Agadez",
      "names": {
        "zh": "阿加德兹",
        "th": "อะกาเดซ"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 16.9959,
        "lng": 7.9828
      },
      "country": "Niger",
      "importance": 1
    },
    {
      "id": 4388,
      "name": "Nobeoka",
      "names": {
        "zh": "延冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.5822,
        "lng": 131.665
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4349,
      "name": "Charallave",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2431,
        "lng": -66.8622
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4394,
      "name": "Uribia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 11.7139,
        "lng": -72.2658
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4396,
      "name": "Chico",
      "names": {
        "zh": "奇科",
        "ja": "チコ",
        "th": "ชิโก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7575,
        "lng": -121.8152
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4179,
      "name": "Villa de Alvarez",
      "names": {
        "ja": "ヴィラデアルバレス",
        "th": "วิลลาเดออัลวาเรซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.25,
        "lng": -103.7333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4389,
      "name": "Luanshya",
      "names": {
        "zh": "卢安夏"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -13.1333,
        "lng": 28.4
      },
      "country": "Zambia",
      "importance": 3
    },
    {
      "id": 4314,
      "name": "Handa",
      "names": {
        "zh": "汉达",
        "ja": "がHanda"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8992,
        "lng": 136.9267
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4387,
      "name": "Kabwe",
      "names": {
        "zh": "卡布韦"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -14.4333,
        "lng": 28.45
      },
      "country": "Zambia",
      "importance": 1
    },
    {
      "id": 4377,
      "name": "La Asuncion",
      "names": {
        "es": "LA Asunción",
        "ja": "ラ・アスンシオン",
        "th": "ลา",
        "pt": "La assunção"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 11.0333,
        "lng": -63.8628
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 4253,
      "name": "Khenifra",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 32.93,
        "lng": -5.66
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 4353,
      "name": "Nevinnomyssk",
      "names": {
        "zh": "涅温诺梅斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.6333,
        "lng": 41.9333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4202,
      "name": "Anderlecht",
      "names": {
        "zh": "安德莱赫特",
        "th": "อันเดอร์เลชท์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8392,
        "lng": 4.3297
      },
      "country": "Belgium",
      "importance": 3
    },
    {
      "id": 4400,
      "name": "San Martin",
      "names": {
        "es": "San Martín",
        "zh": "圣马丁",
        "ja": "サンマーティン",
        "th": "ซานมาร์ติน"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.0806,
        "lng": -68.4706
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 4367,
      "name": "Bottrop",
      "names": {
        "zh": "博特罗普",
        "ja": "ボトロップ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5232,
        "lng": 6.9253
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4395,
      "name": "Piedecuesta",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.9886,
        "lng": -73.0503
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4407,
      "name": "Langley",
      "names": {
        "zh": "兰利",
        "ja": "ラングレー",
        "th": "แลงลีย์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.1044,
        "lng": -122.5827
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4408,
      "name": "Dover",
      "names": {
        "zh": "多佛",
        "ja": "ドーバー",
        "th": "โดเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.161,
        "lng": -75.5203
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 4411,
      "name": "Heroica Guaymas",
      "names": {
        "fr": "Heroiica Guaymas",
        "it": "Guaymas Heroica",
        "id": "Guaymas Heroica",
        "ja": "ヒロイカグアイマス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.9183,
        "lng": -110.8989
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4217,
      "name": "Munuf",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.4667,
        "lng": 30.9333
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 4405,
      "name": "Banfora",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 10.6308,
        "lng": -4.7589
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 4197,
      "name": "Matsubara",
      "names": {
        "zh": "松原",
        "ja": "松原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5781,
        "lng": 135.5517
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4392,
      "name": "Adilabad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.6667,
        "lng": 78.5333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4346,
      "name": "Apalit",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9496,
        "lng": 120.7587
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4415,
      "name": "Facatativa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8147,
        "lng": -74.3553
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4373,
      "name": "Yulu",
      "names": {
        "zh": "玉露"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.5193,
        "lng": 116.4055
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4413,
      "name": "Nkongsamba",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 4.95,
        "lng": 9.9167
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 4410,
      "name": "Kindia",
      "names": {
        "zh": "金迪亚",
        "th": "คินเดีย"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.06,
        "lng": -12.87
      },
      "country": "Guinea",
      "importance": 1
    },
    {
      "id": 4417,
      "name": "Cassongue",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -11.8333,
        "lng": 15.0
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 4422,
      "name": "Clearwater",
      "names": {
        "zh": "克利尔沃特",
        "ja": "クリアウォーター",
        "th": "เคลียร์วอเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.9789,
        "lng": -82.7622
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4421,
      "name": "Tandil",
      "names": {
        "zh": "坦迪尔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.3167,
        "lng": -59.1333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 4416,
      "name": "Guadalajara de Buga",
      "names": {
        "zh": "瓜达拉哈拉德怪",
        "id": "Guadalajara de Buba",
        "ja": "グアダラハラデガ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.9036,
        "lng": -76.2986
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4426,
      "name": "Araguari",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -18.6489,
        "lng": -48.1869
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4428,
      "name": "Port-Gentil",
      "names": {
        "zh": "端口让蒂尔",
        "it": "Port gentil",
        "ja": "ポートgentil",
        "th": "พอร์ต gentil"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -0.7167,
        "lng": 8.7833
      },
      "country": "Gabon",
      "importance": 1
    },
    {
      "id": 4431,
      "name": "Coeur d\u0027Alene",
      "names": {
        "it": "COEUR D\u0027ALEENE",
        "ja": "コーデアレーン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.7041,
        "lng": -116.7935
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4433,
      "name": "Bage",
      "names": {
        "zh": "巴热"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -31.3308,
        "lng": -54.1069
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4369,
      "name": "Konosu",
      "names": {
        "zh": "鸿巢"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0658,
        "lng": 139.5222
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4414,
      "name": "Samal",
      "names": {
        "zh": "萨马勒"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.05,
        "lng": 125.7333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4419,
      "name": "Manpo",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 41.157,
        "lng": 126.29
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 4437,
      "name": "Seaside",
      "names": {
        "es": "Playa",
        "fr": "Mer",
        "zh": "海边",
        "de": "Strand",
        "it": "Mare",
        "ja": "海辺",
        "th": "ริมทะเล",
        "pt": "Mar"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.6224,
        "lng": -121.8191
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4427,
      "name": "Tatui",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3556,
        "lng": -47.8569
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4440,
      "name": "Independence",
      "names": {
        "es": "Independencia",
        "fr": "L\u0027indépendance",
        "zh": "独立性",
        "de": "Unabhängigkeit",
        "it": "Indipendenza",
        "id": "Kemerdekaan",
        "ja": "独立",
        "th": "เป็นอิสระ",
        "pt": "Independência"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0871,
        "lng": -94.3501
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4441,
      "name": "Smithtown",
      "names": {
        "zh": "史密斯敦"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8662,
        "lng": -73.2164
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4430,
      "name": "Sassari",
      "names": {
        "zh": "萨萨里",
        "ja": "サッサリ",
        "th": "ซาสซารี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.7267,
        "lng": 8.5592
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4398,
      "name": "Huanren",
      "names": {
        "zh": "桓仁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.2671,
        "lng": 125.3529
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4315,
      "name": "Varzea Paulista",
      "names": {
        "zh": "瓦尔西亚保利斯塔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2108,
        "lng": -46.8278
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4341,
      "name": "Alcobendas",
      "names": {
        "zh": "阿尔科文达斯"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.5333,
        "lng": -3.6333
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 4337,
      "name": "Metz",
      "names": {
        "zh": "梅茨",
        "ja": "メス",
        "th": "เมตซ์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.1203,
        "lng": 6.1778
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 4089,
      "name": "La Granja",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -33.5431,
        "lng": -70.6319
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 4418,
      "name": "Nyiregyhaza",
      "names": {
        "zh": "尼赖吉哈佐",
        "it": "Nyíregyháza"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.95,
        "lng": 21.7167
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 4423,
      "name": "Yavatmal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.4,
        "lng": 78.1333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 3139,
      "name": "Titagarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.74,
        "lng": 88.37
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4436,
      "name": "Santander de Quilichao",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.0083,
        "lng": -76.4839
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4432,
      "name": "Conselheiro Lafaiete",
      "names": {
        "zh": "Concelheiro Lafaiete"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.66,
        "lng": -43.7858
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4449,
      "name": "West Jordan",
      "names": {
        "es": "JORDAN OESTE",
        "fr": "Jordanie occidentale",
        "zh": "西约旦",
        "de": "West Jordanien",
        "it": "Giordania occidentale",
        "id": "Jordan Barat",
        "ja": "ウェストジョーダン",
        "th": "จอร์แดนตะวันตก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6024,
        "lng": -112.0008
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4163,
      "name": "Honcho",
      "names": {
        "zh": "本町",
        "ja": "班長"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7581,
        "lng": 139.5297
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4351,
      "name": "Cheltenham",
      "names": {
        "zh": "切尔滕纳姆",
        "it": "Di Cheltenham",
        "ja": "チェルトナム",
        "th": "บี้แบน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.9,
        "lng": -2.0667
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4434,
      "name": "Chittaurgarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.8894,
        "lng": 74.6239
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4403,
      "name": "Xai-Xai",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -25.05,
        "lng": 33.65
      },
      "country": "Mozambique",
      "importance": 1
    },
    {
      "id": 4347,
      "name": "Gabes",
      "names": {
        "fr": "Gabès",
        "zh": "加贝斯"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 33.8814,
        "lng": 10.0983
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 4444,
      "name": "Boke",
      "names": {
        "zh": "博凯"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.94,
        "lng": -14.3
      },
      "country": "Guinea",
      "importance": 1
    },
    {
      "id": 4439,
      "name": "Coronel",
      "names": {
        "zh": "科罗内尔",
        "ja": "コロネル",
        "th": "เนล"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -37.0167,
        "lng": -73.1333
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 4409,
      "name": "Narasaraopet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.236,
        "lng": 80.054
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4446,
      "name": "Himamaylan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1,
        "lng": 122.8667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4303,
      "name": "Orleans",
      "names": {
        "fr": "-Orléans",
        "zh": "新奥尔良",
        "ja": "オーリンズ",
        "th": "ออร์ลีนส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.9025,
        "lng": 1.909
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 4443,
      "name": "Dharan",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 26.8167,
        "lng": 87.2667
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 4375,
      "name": "Ikoma",
      "names": {
        "zh": "生驹",
        "ja": "生駒"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6919,
        "lng": 135.7006
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4450,
      "name": "Bongao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.0292,
        "lng": 119.7731
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4456,
      "name": "Ramenskoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.5669,
        "lng": 38.2303
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4402,
      "name": "Walbrzych",
      "names": {
        "zh": "瓦乌布日赫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.7667,
        "lng": 16.2833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 4458,
      "name": "Itapipoca",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.4939,
        "lng": -39.5789
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4379,
      "name": "Ain Beida",
      "names": {
        "zh": "艾琳贝达",
        "ja": "北田"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.7964,
        "lng": 7.3928
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 4356,
      "name": "Dimitrovgrad",
      "names": {
        "zh": "季米特洛夫格勒"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.2333,
        "lng": 49.5833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4455,
      "name": "Karatsu",
      "names": {
        "zh": "唐津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.45,
        "lng": 129.9686
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4460,
      "name": "Sabha",
      "names": {
        "zh": "塞卜哈",
        "it": "Sebha",
        "th": "บา"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 27.0333,
        "lng": 14.4333
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 4412,
      "name": "Kyzyl",
      "names": {
        "zh": "克孜勒"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.7167,
        "lng": 94.45
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 4424,
      "name": "Beppu",
      "names": {
        "zh": "别府",
        "ja": "別府"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.2847,
        "lng": 131.4911
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4406,
      "name": "Reutlingen",
      "names": {
        "zh": "罗伊特林根",
        "ja": "ロイトリンゲン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4833,
        "lng": 9.2167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4461,
      "name": "Tahoua",
      "names": {
        "zh": "塔瓦"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 14.9,
        "lng": 5.2599
      },
      "country": "Niger",
      "importance": 1
    },
    {
      "id": 4457,
      "name": "Ra\u0027s al Khaymah",
      "names": {
        "fr": "Ra Al Khaymah",
        "zh": "Ra的Al Khaymah",
        "ja": "RAのAl Khaymah",
        "pt": "Adora al khaymah"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.7851,
        "lng": 55.9479
      },
      "country": "United Arab Emirates",
      "importance": 1
    },
    {
      "id": 4454,
      "name": "Khon Kaen",
      "names": {
        "th": "ขอนแก่น"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.4333,
        "lng": 102.8333
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 4465,
      "name": "Brandon",
      "names": {
        "zh": "布兰登",
        "ja": "ブランドン",
        "th": "แบรนดอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.9367,
        "lng": -82.3
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4464,
      "name": "Valle de La Pascua",
      "names": {
        "ja": "バルデラパスカ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.2033,
        "lng": -66.0103
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4467,
      "name": "Bloomington",
      "names": {
        "zh": "布卢明顿",
        "ja": "ブルーミントン",
        "th": "ในบลูมิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1637,
        "lng": -86.5257
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4263,
      "name": "Urasoe",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 26.2458,
        "lng": 127.7219
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4451,
      "name": "Phatthaya",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 12.9496,
        "lng": 100.893
      },
      "country": "Thailand",
      "importance": 3
    },
    {
      "id": 779,
      "name": "Sri Jayewardenepura Kotte",
      "names": {
        "de": "SRI Jaywardepura Kotte",
        "ja": "スリ・ジエワデネプラコッテ",
        "th": "ศรี Jayewardenepura Kotte"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 6.9,
        "lng": 79.9164
      },
      "country": "Sri Lanka",
      "importance": 0
    },
    {
      "id": 4466,
      "name": "San Ildefonso",
      "names": {
        "zh": "San Indrefonso",
        "de": "San Ildefono",
        "ja": "サンィルデフィンソ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0789,
        "lng": 120.9419
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4371,
      "name": "Linkoping",
      "names": {
        "fr": "Linköping",
        "zh": "林克平"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.4094,
        "lng": 15.6257
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 4469,
      "name": "Nasushiobara",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.9617,
        "lng": 140.0461
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4473,
      "name": "Pedro Juan Caballero",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -22.5667,
        "lng": -55.7167
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 4474,
      "name": "Tres Lagoas",
      "names": {
        "zh": "训练拉古拉斯",
        "ja": "ラゴアストレス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.7511,
        "lng": -51.6783
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4425,
      "name": "Buzau",
      "names": {
        "zh": "布泽乌"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.1517,
        "lng": 26.8167
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 4477,
      "name": "El Monte",
      "names": {
        "ja": "エルモンテ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0739,
        "lng": -118.0291
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4475,
      "name": "Koencho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 43.8081,
        "lng": 143.8942
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4479,
      "name": "North Charleston",
      "names": {
        "es": "Charleston del Norte",
        "zh": "北查尔斯顿",
        "de": "Nord-Charleston",
        "it": "Charleston del Nord",
        "id": "Charleston Utara",
        "ja": "ノースチャールストン",
        "th": "ชาร์ลสตันตอนเหนือ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.9066,
        "lng": -80.0722
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4480,
      "name": "Carlsbad",
      "names": {
        "zh": "卡尔斯巴德",
        "ja": "カールスバッド",
        "th": "คาร์ลสแบด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1247,
        "lng": -117.2837
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4319,
      "name": "Chelmsford",
      "names": {
        "zh": "切姆斯福德",
        "ja": "チェルムスフォード",
        "th": "เชล์มส"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.7361,
        "lng": 0.4798
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4401,
      "name": "Los Banos",
      "names": {
        "ja": "ロスバノス"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1667,
        "lng": 121.2167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4482,
      "name": "Puerto Madryn",
      "names": {
        "zh": "波多黎各",
        "ja": "プエルトマドリン"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -42.773,
        "lng": -65.0366
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 4468,
      "name": "Toliara",
      "names": {
        "zh": "图利亚拉"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -23.35,
        "lng": 43.6667
      },
      "country": "Madagascar",
      "importance": 1
    },
    {
      "id": 4459,
      "name": "Guanabacoa",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 23.1252,
        "lng": -82.3007
      },
      "country": "Cuba",
      "importance": 3
    },
    {
      "id": 4393,
      "name": "Obninsk",
      "names": {
        "zh": "奥布宁斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.1,
        "lng": 36.6167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4490,
      "name": "St. Cloud",
      "names": {
        "es": "Nube",
        "fr": "St. nuage",
        "zh": "圣云",
        "de": "St. Wolke",
        "ja": "聖雲",
        "th": "เซนต์คลาวด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.5339,
        "lng": -94.1718
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4452,
      "name": "Swabi",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 34.1167,
        "lng": 72.4667
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 4478,
      "name": "Niihama",
      "names": {
        "zh": "新居滨"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.9603,
        "lng": 133.2834
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4391,
      "name": "Uzhhorod",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6239,
        "lng": 22.295
      },
      "country": "Ukraine",
      "importance": 1
    },
    {
      "id": 4484,
      "name": "Sano",
      "names": {
        "zh": "佐野",
        "ja": "佐野"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3144,
        "lng": 139.5783
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4420,
      "name": "Mendoza",
      "names": {
        "zh": "门多萨",
        "ja": "メンドーサ",
        "th": "เมนโดซา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.8833,
        "lng": -68.8333
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 3083,
      "name": "Dam Dam",
      "names": {
        "es": "Presa de presa",
        "fr": "Barrage",
        "zh": "大坝",
        "de": "Dammdamm",
        "it": "Diga",
        "id": "Bendungan",
        "ja": "ダムダム",
        "th": "เขื่อน",
        "pt": "Barragem"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.62,
        "lng": 88.42
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4494,
      "name": "Las Delicias",
      "names": {
        "de": "Las-Delicias",
        "it": "Las Delilias"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.2,
        "lng": -105.5
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 4499,
      "name": "Temecula",
      "names": {
        "ja": "テメキュラ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4928,
        "lng": -117.1315
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4453,
      "name": "Qiaotou",
      "names": {
        "zh": "桥头"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.935,
        "lng": 101.6736
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4493,
      "name": "Hatsukaichi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.35,
        "lng": 132.3333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4495,
      "name": "Bijeljina",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.75,
        "lng": 19.2167
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 4503,
      "name": "Clovis",
      "names": {
        "zh": "克洛维斯",
        "ja": "クローヴィス",
        "th": "โคลวิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.8278,
        "lng": -119.6831
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4462,
      "name": "Shacheng",
      "names": {
        "zh": "沙城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.4027,
        "lng": 115.5126
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4357,
      "name": "Giyon",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 8.5304,
        "lng": 37.97
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 4496,
      "name": "Heshan",
      "names": {
        "zh": "鹤山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.8163,
        "lng": 108.8847
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4485,
      "name": "Ariana",
      "names": {
        "zh": "阿里亚纳"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8625,
        "lng": 10.1956
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 4489,
      "name": "Sogamoso",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.7143,
        "lng": -72.9339
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4483,
      "name": "Iriga City",
      "names": {
        "es": "Ciudad de Iriga",
        "zh": "Iriga市",
        "de": "Iriga-Stadt",
        "it": "Città di Iriga",
        "id": "Kota iriga",
        "ja": "イリガシティ",
        "th": "เมืองอิริก้า",
        "pt": "Cidade iriga"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4167,
        "lng": 123.4167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4385,
      "name": "Plaridel",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8869,
        "lng": 120.8569
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4501,
      "name": "Bet Shemesh",
      "names": {
        "es": "Apuesta shemesh",
        "zh": "打赌骗子",
        "de": "Säugetieren",
        "it": "Scommessa Shemesh",
        "ja": "賭けシミシュ",
        "th": "เดิมพันเซมช",
        "pt": "Apostar shemah"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.7514,
        "lng": 34.9886
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 3984,
      "name": "Jaramana",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.4833,
        "lng": 36.35
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 4093,
      "name": "Shashemene",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 7.2004,
        "lng": 38.59
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 4507,
      "name": "Valparai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.3204,
        "lng": 76.97
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4213,
      "name": "Cadiz",
      "names": {
        "es": "Cádiz",
        "fr": "Cadix",
        "zh": "加的斯",
        "it": "Cadice",
        "ja": "カディス",
        "th": "กาดิซ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.535,
        "lng": -6.2975
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 4472,
      "name": "Berdiansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.7598,
        "lng": 36.7845
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 4491,
      "name": "Salto",
      "names": {
        "zh": "萨尔图",
        "ja": "サルト"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2008,
        "lng": -47.2869
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4511,
      "name": "Meridian",
      "names": {
        "fr": "Méridien",
        "zh": "子午线",
        "ja": "メリディアン",
        "th": "เที่ยงวันสำคัญ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.6112,
        "lng": -116.3968
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4512,
      "name": "Saanich",
      "names": {
        "zh": "桑列治"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 48.484,
        "lng": -123.381
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4399,
      "name": "Machakos",
      "names": {
        "zh": "马查科斯"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.5167,
        "lng": 37.2667
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 4497,
      "name": "Siddipet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.1,
        "lng": 78.85
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4506,
      "name": "San Juan",
      "names": {
        "zh": "圣胡安",
        "ja": "サンファン",
        "th": "ซานฮวน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.826,
        "lng": 121.396
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 4510,
      "name": "Bintulu",
      "names": {
        "zh": "民都鲁"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 3.17,
        "lng": 113.03
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 4487,
      "name": "Koblenz",
      "names": {
        "zh": "科布伦茨",
        "it": "Coblenza",
        "ja": "コブレンツ",
        "th": "โคเบลนซ์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3597,
        "lng": 7.5978
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4498,
      "name": "Kankan",
      "names": {
        "zh": "康康"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.39,
        "lng": -9.31
      },
      "country": "Guinea",
      "importance": 1
    },
    {
      "id": 4384,
      "name": "Petrzalka",
      "names": {
        "de": "Petržalka",
        "it": "Petržalka"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.1333,
        "lng": 17.1167
      },
      "country": "Slovakia",
      "importance": 3
    },
    {
      "id": 4515,
      "name": "Cuito",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.3833,
        "lng": 16.9333
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 4509,
      "name": "Kakegawa",
      "names": {
        "zh": "挂川",
        "ja": "掛川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7686,
        "lng": 137.9983
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4488,
      "name": "Oktyabr\u0027skiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.4833,
        "lng": 53.4833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4492,
      "name": "Zhijiang",
      "names": {
        "zh": "江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.4367,
        "lng": 109.678
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4521,
      "name": "Quilengues",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.0814,
        "lng": 14.0764
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 4522,
      "name": "The Woodlands",
      "names": {
        "es": "Los bosques",
        "fr": "Les bois",
        "zh": "林地",
        "de": "Die Wälder",
        "it": "I boschi",
        "id": "Hutan",
        "ja": "森林地帯",
        "th": "ป่าไม้",
        "pt": "As florestas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.1738,
        "lng": -95.5134
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4438,
      "name": "Helsingborg",
      "names": {
        "zh": "赫尔辛堡",
        "ja": "ヘルシグボリ",
        "th": "เฮลซิงบอร์ก"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.0424,
        "lng": 12.721
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 4519,
      "name": "Tawau",
      "names": {
        "zh": "斗湖"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 4.2448,
        "lng": 117.8911
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 4309,
      "name": "Poa",
      "names": {
        "fr": "Programme d\u0027action",
        "zh": "早熟禾",
        "th": "ไม่แสดงราคา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.5286,
        "lng": -46.345
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4518,
      "name": "Nagahama",
      "names": {
        "zh": "长滨"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3833,
        "lng": 136.2833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4500,
      "name": "Bremerhaven",
      "names": {
        "zh": "不来梅港",
        "ja": "ブレーマーハーフェン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.55,
        "lng": 8.5833
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 4527,
      "name": "Maranguape",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.89,
        "lng": -38.6858
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4517,
      "name": "Pul-e Khumri",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 35.95,
        "lng": 68.7
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 4535,
      "name": "Mungo",
      "names": {
        "zh": "蒙哥",
        "ja": "マンゴー",
        "th": "มังโก"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -11.6667,
        "lng": 16.1667
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 4397,
      "name": "Guiguinto",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8333,
        "lng": 120.8833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4540,
      "name": "Ponce",
      "names": {
        "zh": "庞塞",
        "ja": "ポンセ",
        "th": "เซ"
      },
      "countryCode": "PR",
      "latLng": {
        "lat": 18.012,
        "lng": -66.6198
      },
      "country": "Puerto Rico",
      "importance": 3
    },
    {
      "id": 4536,
      "name": "Kipushi",
      "names": {
        "zh": "基普希"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -11.76,
        "lng": 27.25
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 4539,
      "name": "Sa-ch\u0027on",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.2347,
        "lng": 128.3575
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 4532,
      "name": "Bukittinggi",
      "names": {
        "zh": "武吉丁宜"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -0.3097,
        "lng": 100.3753
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 4533,
      "name": "Caraguatatuba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.62,
        "lng": -45.4128
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4531,
      "name": "Uba",
      "names": {
        "zh": "乌巴"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.12,
        "lng": -42.9428
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4530,
      "name": "Itatiba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.0058,
        "lng": -46.8389
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4546,
      "name": "Novyy Urengoy",
      "names": {
        "de": "Novyy-Urengoy",
        "it": "Noveyy Urengoy"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 66.0847,
        "lng": 76.6789
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4471,
      "name": "Durres",
      "names": {
        "it": "Durazzo"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 41.3242,
        "lng": 19.4558
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 4534,
      "name": "Manolo Fortich",
      "names": {
        "ja": "マノロフォーチッヒ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.3697,
        "lng": 124.8644
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4486,
      "name": "Sloviansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.87,
        "lng": 37.625
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 4526,
      "name": "Hikone",
      "names": {
        "zh": "彦根"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.25,
        "lng": 136.25
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4544,
      "name": "Euriapolis",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -16.3778,
        "lng": -39.58
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4476,
      "name": "Mangaldan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.07,
        "lng": 120.4025
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4525,
      "name": "Hofu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.05,
        "lng": 131.5667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4549,
      "name": "Westminster",
      "names": {
        "zh": "威斯敏斯特",
        "ja": "ウェストミンスター",
        "th": "เวสต์มินสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.8837,
        "lng": -105.0624
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4470,
      "name": "Tokai",
      "names": {
        "zh": "东海",
        "ja": "東海",
        "th": "โตไก"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0167,
        "lng": 136.9
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4537,
      "name": "Jendouba",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.5011,
        "lng": 8.7803
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 4556,
      "name": "Costa Mesa",
      "names": {
        "ja": "コスタメサ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6667,
        "lng": -117.9135
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4552,
      "name": "Sokode",
      "names": {
        "zh": "索科德"
      },
      "countryCode": "TG",
      "latLng": {
        "lat": 8.9833,
        "lng": 1.1333
      },
      "country": "Togo",
      "importance": 1
    },
    {
      "id": 4542,
      "name": "Libmanan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7,
        "lng": 123.0667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4561,
      "name": "Monroe",
      "names": {
        "zh": "梦露",
        "ja": "モンロー",
        "th": "มอนโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.5183,
        "lng": -92.0774
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4557,
      "name": "San Carlos de Bariloche",
      "names": {
        "zh": "圣卡洛斯德巴里洛切",
        "ja": "サンカルロスデバリローチェ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -41.15,
        "lng": -71.3
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 4547,
      "name": "Montero",
      "names": {
        "zh": "蒙特罗"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -17.3333,
        "lng": -63.3833
      },
      "country": "Bolivia",
      "importance": 3
    },
    {
      "id": 4306,
      "name": "Fujimino",
      "names": {
        "th": "ฟูยิมิโนะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8794,
        "lng": 139.5197
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4550,
      "name": "Tayabas",
      "names": {
        "zh": "塔亚巴斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0167,
        "lng": 121.5833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4565,
      "name": "Utica",
      "names": {
        "zh": "尤蒂卡",
        "ja": "ユーティカ",
        "th": "ยูทิกา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0961,
        "lng": -75.226
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4566,
      "name": "Carora",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1692,
        "lng": -70.0783
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4559,
      "name": "Kristiansand",
      "names": {
        "zh": "克里斯蒂安桑"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.1467,
        "lng": 7.9956
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 4322,
      "name": "Lomas de Zamora",
      "names": {
        "ja": "ローマデザモラ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7667,
        "lng": -58.4
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 4563,
      "name": "Higashiomi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1128,
        "lng": 136.2078
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4558,
      "name": "Otaru",
      "names": {
        "zh": "小樽",
        "ja": "小樽"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 43.1833,
        "lng": 141.0
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4502,
      "name": "Ciudad de la Costa",
      "names": {},
      "countryCode": "UY",
      "latLng": {
        "lat": -34.8167,
        "lng": -55.95
      },
      "country": "Uruguay",
      "importance": 3
    },
    {
      "id": 4570,
      "name": "Oshu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 39.1444,
        "lng": 141.1389
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4574,
      "name": "Campo Largo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -25.4589,
        "lng": -49.5278
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4564,
      "name": "Santa Lucia",
      "names": {
        "es": "Santa Lucía",
        "zh": "圣卢西亚",
        "ja": "サンタルシア",
        "th": "ซานตาลูเซีย"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.2606,
        "lng": -66.6639
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4576,
      "name": "Shkoder",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 42.0667,
        "lng": 19.5
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 4516,
      "name": "Recklinghausen",
      "names": {
        "zh": "雷克林豪森"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6167,
        "lng": 7.2
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4548,
      "name": "Madrid",
      "names": {
        "zh": "马德里",
        "ja": "マドリード",
        "th": "มาดริด"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7306,
        "lng": -74.2639
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4579,
      "name": "Santana",
      "names": {
        "zh": "桑塔纳",
        "ja": "サンタナ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -0.035,
        "lng": -51.175
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4585,
      "name": "Pompano Beach",
      "names": {
        "es": "Peach Pompano",
        "zh": "庞帕诺海滩",
        "id": "Pantai Pompano",
        "ja": "ポンパノビーチ",
        "th": "หาดปอมทาโน",
        "pt": "Praia de pompano"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.2428,
        "lng": -80.1312
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4364,
      "name": "Akishima",
      "names": {
        "zh": "昭岛"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7056,
        "lng": 139.3536
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4282,
      "name": "Saint-Denis",
      "names": {
        "zh": "圣 - 丹尼斯",
        "ja": "サン・デニス",
        "th": "เซนต์เดนิส"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9356,
        "lng": 2.3539
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 4545,
      "name": "Osmanabad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.1667,
        "lng": 76.05
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4580,
      "name": "Olmaliq",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.85,
        "lng": 69.6
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 4587,
      "name": "Corumba",
      "names": {
        "es": "Corumbá",
        "zh": "科伦巴",
        "it": "Corumbá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.0089,
        "lng": -57.6528
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4524,
      "name": "Paarl",
      "names": {
        "zh": "帕尔"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.7242,
        "lng": 18.9558
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 4584,
      "name": "Araruama",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8728,
        "lng": -42.3428
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4543,
      "name": "Bergisch Gladbach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9917,
        "lng": 7.1367
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4538,
      "name": "Erlangen",
      "names": {
        "zh": "爱尔兰根",
        "ja": "エアランゲン",
        "th": "แอร์ลังเงิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.5964,
        "lng": 11.0044
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4589,
      "name": "West Palm Beach",
      "names": {
        "zh": "西棕榈滩",
        "id": "Pantai Palm Barat",
        "ja": "ウェストパームビーチ",
        "th": "หาดฝ่ามือตะวันตก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.7468,
        "lng": -80.1316
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4583,
      "name": "Jaen",
      "names": {
        "es": "Jaén",
        "zh": "哈恩"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.7667,
        "lng": -3.7711
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 4578,
      "name": "Subic",
      "names": {
        "zh": "苏比克",
        "ja": "スービック"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9,
        "lng": 120.2333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4592,
      "name": "Funchal",
      "names": {
        "zh": "丰沙尔",
        "ja": "フンシャル",
        "th": "ฟุงชาล"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 32.65,
        "lng": -16.9167
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 4586,
      "name": "Baybay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6833,
        "lng": 124.8333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4591,
      "name": "Ichinoseki",
      "names": {
        "zh": "一关"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.9347,
        "lng": 141.1266
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4514,
      "name": "Iringa",
      "names": {
        "zh": "伊林加"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -7.77,
        "lng": 35.69
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 4594,
      "name": "Colatina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.5389,
        "lng": -40.6308
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4582,
      "name": "Bou Saada",
      "names": {
        "zh": "布苏萨达"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.2083,
        "lng": 4.1739
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 4513,
      "name": "Daet",
      "names": {
        "zh": "达特"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1167,
        "lng": 122.95
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4567,
      "name": "Laoag",
      "names": {
        "zh": "拉瓦格"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 18.1951,
        "lng": 120.5918
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4600,
      "name": "Waterloo",
      "names": {
        "zh": "滑铁卢",
        "ja": "ワーテルロー",
        "th": "วอเตอร์ลู"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.492,
        "lng": -92.3522
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4555,
      "name": "Vicenza",
      "names": {
        "zh": "维琴察",
        "ja": "ヴィチェンツァ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.55,
        "lng": 11.55
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4601,
      "name": "Parintins",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.6278,
        "lng": -56.7358
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4603,
      "name": "Terrebonne",
      "names": {
        "zh": "泰勒博恩"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.7,
        "lng": -73.6333
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4610,
      "name": "Everett",
      "names": {
        "zh": "埃弗雷特",
        "ja": "エベレット",
        "th": "เอเวอเร็ตต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.9525,
        "lng": -122.1669
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4613,
      "name": "El Centro",
      "names": {
        "ja": "エルセンロ",
        "pt": "El cidro"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7867,
        "lng": -115.5586
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4577,
      "name": "Jena",
      "names": {
        "zh": "耶拿",
        "ja": "イエナ",
        "th": "เจ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9272,
        "lng": 11.5864
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4616,
      "name": "Villa Mercedes",
      "names": {
        "zh": "梅赛德斯别墅",
        "ja": "ヴィラメルセデス",
        "th": "วิลล่าเมอร์เซเดส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.6667,
        "lng": -65.4667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 4445,
      "name": "Rouen",
      "names": {
        "zh": "鲁昂",
        "ja": "ルーアン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.4428,
        "lng": 1.0886
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 781,
      "name": "Moroni",
      "names": {
        "zh": "莫罗尼",
        "th": "โมโรนี",
        "pt": "Morôni"
      },
      "countryCode": "KM",
      "latLng": {
        "lat": -11.7036,
        "lng": 43.2536
      },
      "country": "Comoros",
      "importance": 0
    },
    {
      "id": 4617,
      "name": "Jawhar",
      "names": {
        "zh": "乔哈尔",
        "de": "Gauhars"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": 2.7833,
        "lng": 45.5
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 4625,
      "name": "Santa Fe",
      "names": {
        "zh": "圣达菲",
        "ja": "サンタフェ",
        "th": "ซานตาเฟ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.6619,
        "lng": -105.9819
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 4569,
      "name": "Champdani",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.8,
        "lng": 88.37
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4598,
      "name": "Terni",
      "names": {
        "zh": "特尔尼",
        "ja": "テルニ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.5667,
        "lng": 12.65
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4621,
      "name": "Gafsa",
      "names": {
        "zh": "加夫萨"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 34.4167,
        "lng": 8.7833
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 4345,
      "name": "Kasuga",
      "names": {
        "zh": "春日",
        "ja": "春日"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5328,
        "lng": 130.4703
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4628,
      "name": "Downey",
      "names": {
        "zh": "唐尼",
        "ja": "ダウニー",
        "th": "ดาวนีย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9379,
        "lng": -118.1312
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4573,
      "name": "Kamyshin",
      "names": {
        "zh": "卡梅申"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.0833,
        "lng": 45.4
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4629,
      "name": "Spring Hill",
      "names": {
        "es": "Cuesta",
        "fr": "Colline de printemps",
        "zh": "春山",
        "de": "Springhügel",
        "it": "Collina",
        "id": "Pegas",
        "ja": "スプリングヒル",
        "th": "ฤดูใบไม้ผลิ",
        "pt": "Colina de mola"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.4797,
        "lng": -82.53
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4590,
      "name": "Nsukka",
      "names": {
        "zh": "恩苏卡"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.8567,
        "lng": 7.3958
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 4624,
      "name": "Nguru",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 12.8804,
        "lng": 10.45
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 4632,
      "name": "Lowell",
      "names": {
        "zh": "洛厄尔",
        "ja": "ローウェル",
        "th": "โลเวลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6389,
        "lng": -71.3217
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4571,
      "name": "Remscheid",
      "names": {
        "zh": "雷姆沙伊德",
        "ja": "レムシャイト"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1802,
        "lng": 7.1872
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4560,
      "name": "Alphen aan den Rijn",
      "names": {
        "es": "Alfen aan den rijn",
        "fr": "Alphan aan den rijn"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1333,
        "lng": 4.65
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 4631,
      "name": "Catalao",
      "names": {
        "de": "Catalão",
        "pt": "Catalão"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.17,
        "lng": -47.9419
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4619,
      "name": "Tuzla",
      "names": {
        "zh": "图兹拉",
        "ja": "トゥズラ"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.5381,
        "lng": 18.6761
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 4620,
      "name": "Tarnow",
      "names": {
        "es": "Tarnów",
        "fr": "Tarnów",
        "zh": "塔尔努夫",
        "it": "Tarnów",
        "th": "ทานูฟ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0125,
        "lng": 20.9883
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 4604,
      "name": "Namur",
      "names": {
        "zh": "那慕尔",
        "ja": "ナミュール",
        "th": "มูร์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.4667,
        "lng": 4.8667
      },
      "country": "Belgium",
      "importance": 1
    },
    {
      "id": 4635,
      "name": "Centennial",
      "names": {
        "es": "Centenario",
        "fr": "Centenaire",
        "zh": "百年纪念",
        "ja": "センテニアル",
        "th": "หนึ่งร้อยปี",
        "pt": "Centenário"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.5926,
        "lng": -104.8674
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4627,
      "name": "Khushab",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.2917,
        "lng": 72.35
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 4640,
      "name": "Elgin",
      "names": {
        "zh": "埃尔金",
        "ja": "エルジン",
        "th": "เอลกิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0385,
        "lng": -88.3229
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4636,
      "name": "Puerto Barrios",
      "names": {
        "zh": "波多巴斯巴黎斯",
        "de": "Puerto-Barrios",
        "ja": "プエルトバリオス"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.73,
        "lng": -88.6
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 4637,
      "name": "Fasa",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 28.9381,
        "lng": 53.6481
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4529,
      "name": "Rosario",
      "names": {
        "zh": "罗萨里奥",
        "ja": "ロザリオ",
        "th": "โรซาริโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4167,
        "lng": 120.85
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4643,
      "name": "Manzanillo",
      "names": {
        "zh": "马惹尼诺",
        "de": "In manzanillo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0522,
        "lng": -104.3158
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4642,
      "name": "Kiffa",
      "names": {
        "zh": "基法"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 16.6164,
        "lng": -11.4044
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 4638,
      "name": "As Salamiyah",
      "names": {
        "es": "Como salamiyah",
        "fr": "Comme Salamiyah",
        "zh": "作为萨拉梅耶",
        "de": "Als Salamiyah",
        "it": "Come Salamiyah",
        "id": "Sebagai salamiyah",
        "ja": "サラミヤとして",
        "th": "เป็นซาลามิยาห์",
        "pt": "Como salamiyah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.0111,
        "lng": 37.0531
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 4644,
      "name": "Nikopol",
      "names": {
        "zh": "尼科波尔"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.5772,
        "lng": 34.3575
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 4611,
      "name": "Pingquan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.9937,
        "lng": 118.6735
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4626,
      "name": "Bandar-e Anzali",
      "names": {
        "ja": "バンダル\u003dアンザリ"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 37.4667,
        "lng": 49.4667
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4602,
      "name": "Trier",
      "names": {
        "zh": "特里尔",
        "ja": "トリーア",
        "th": "เทรียร์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7557,
        "lng": 6.6394
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4612,
      "name": "Kazo",
      "names": {
        "zh": "嘉藏"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1314,
        "lng": 139.6018
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4649,
      "name": "Borazjan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.2667,
        "lng": 51.2158
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4650,
      "name": "Richmond",
      "names": {
        "zh": "里士满",
        "ja": "リッチモンド",
        "th": "ริชมอนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9477,
        "lng": -122.339
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4447,
      "name": "San Antonio Enchisi",
      "names": {
        "ja": "サンアントニオエンチジ",
        "th": "ซานอันโตนิโอเอนชิซี"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7072,
        "lng": -99.7867
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 4646,
      "name": "Yenangyaung",
      "names": {
        "zh": "仁安羌"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 20.4583,
        "lng": 94.8732
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 4647,
      "name": "Quibor",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.9281,
        "lng": -69.5778
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4651,
      "name": "Socopo",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.2322,
        "lng": -70.8206
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4634,
      "name": "Ahuachapan",
      "names": {
        "zh": "阿瓦查潘"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.9214,
        "lng": -89.845
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 4608,
      "name": "Manzini",
      "names": {
        "zh": "曼齐尼"
      },
      "countryCode": "SZ",
      "latLng": {
        "lat": -26.4833,
        "lng": 31.3667
      },
      "country": "Swaziland",
      "importance": 1
    },
    {
      "id": 4633,
      "name": "Sonsonate",
      "names": {
        "zh": "松索纳特"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.7167,
        "lng": -89.7167
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 4653,
      "name": "Genhe",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 50.7783,
        "lng": 121.5213
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4363,
      "name": "Martinez de la Torre",
      "names": {
        "es": "Martínez de la Torre"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0667,
        "lng": -97.05
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4463,
      "name": "Fujimi",
      "names": {
        "ja": "フジミ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8567,
        "lng": 139.5492
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4301,
      "name": "Cuautitlan",
      "names": {
        "es": "Cuautitlán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6833,
        "lng": -99.1833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4605,
      "name": "Changbang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4555,
        "lng": 120.4433
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4648,
      "name": "Ourinhos",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9789,
        "lng": -49.8708
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4657,
      "name": "Baneh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.9975,
        "lng": 45.8853
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4442,
      "name": "Argenteuil",
      "names": {
        "zh": "阿根特尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.95,
        "lng": 2.25
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 4661,
      "name": "Broken Arrow",
      "names": {
        "es": "Flecha rota",
        "fr": "Flèche cassée",
        "zh": "破碎的箭头",
        "de": "Gebrochener Pfeil",
        "it": "Freccia spezzata",
        "id": "Panah",
        "ja": "折れた矢",
        "th": "ลูกศรแตก",
        "pt": "Flecha quebrada"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0365,
        "lng": -95.7809
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4404,
      "name": "Rochdale",
      "names": {
        "zh": "罗奇代尔",
        "th": "โรชเดล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.6136,
        "lng": -2.161
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4655,
      "name": "Jeonghae",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.5653,
        "lng": 126.8561
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 4658,
      "name": "Shirayamamachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.5144,
        "lng": 136.5656
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4665,
      "name": "Milton",
      "names": {
        "zh": "米尔顿",
        "ja": "ミルトン",
        "th": "มิลตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.5083,
        "lng": -79.8833
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4654,
      "name": "Montego Bay",
      "names": {
        "zh": "蒙特哥湾",
        "id": "Teluk Montego",
        "ja": "モンテゴベイ",
        "th": "อ่าวมอนเตโก",
        "pt": "Baía de Montego"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.4667,
        "lng": -77.9167
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 4596,
      "name": "Asela",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 7.95,
        "lng": 39.1167
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 4615,
      "name": "Omuta",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.0303,
        "lng": 130.4461
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4669,
      "name": "Miami Gardens",
      "names": {
        "es": "Jardines de Miami",
        "fr": "Jardins de Miami",
        "zh": "迈阿密花园",
        "ja": "マイアミガーデンズ",
        "th": "สวนไมอามี",
        "pt": "Jardins de Miami"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.9433,
        "lng": -80.2426
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 780,
      "name": "Bridgetown",
      "names": {
        "zh": "布里奇敦",
        "th": "บริดจ์ทาวน์"
      },
      "countryCode": "BB",
      "latLng": {
        "lat": 13.0975,
        "lng": -59.6167
      },
      "country": "Barbados",
      "importance": 0
    },
    {
      "id": 4528,
      "name": "Hualien",
      "names": {
        "zh": "花莲",
        "ja": "花蓮",
        "th": "ฮัวเหลียน"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 23.9722,
        "lng": 121.6064
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 4656,
      "name": "Msaken",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 35.7333,
        "lng": 10.5833
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 4660,
      "name": "Tinaquillo",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.9167,
        "lng": -68.3
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4670,
      "name": "Pisco",
      "names": {
        "zh": "皮斯科",
        "ja": "ピスコ"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.71,
        "lng": -76.2032
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 4674,
      "name": "Altamira",
      "names": {
        "zh": "阿尔塔米拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.2028,
        "lng": -52.2058
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4666,
      "name": "Yoju",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.2939,
        "lng": 127.6383
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 4228,
      "name": "Montreuil",
      "names": {
        "zh": "蒙特勒伊"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8611,
        "lng": 2.4436
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 4504,
      "name": "Bernal",
      "names": {
        "zh": "伯纳尔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7,
        "lng": -58.2833
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 4541,
      "name": "Doncaster",
      "names": {
        "zh": "唐卡斯特",
        "ja": "ドンカスター",
        "th": "ดอนคาสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5228,
        "lng": -1.1325
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4681,
      "name": "Bend",
      "names": {
        "es": "Curva",
        "fr": "Pliez",
        "zh": "弯曲",
        "de": "Kurve",
        "it": "Piegare",
        "ja": "ベンド",
        "th": "โค้ง",
        "pt": "Dobrar"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0562,
        "lng": -121.3087
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4678,
      "name": "Itabira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.6189,
        "lng": -43.2269
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4618,
      "name": "Winterthur",
      "names": {
        "zh": "温特图尔"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.4992,
        "lng": 8.7267
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 4684,
      "name": "Burlington",
      "names": {
        "zh": "伯灵顿",
        "ja": "バーリントン",
        "th": "เบอร์ลิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.4877,
        "lng": -73.2314
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4683,
      "name": "Kandi",
      "names": {},
      "countryCode": "BJ",
      "latLng": {
        "lat": 11.1304,
        "lng": 2.94
      },
      "country": "Benin",
      "importance": 1
    },
    {
      "id": 4645,
      "name": "Dali",
      "names": {
        "zh": "大理",
        "ja": "ダリ",
        "th": "ต้าหลี่"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7953,
        "lng": 109.9378
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4508,
      "name": "Rotherham",
      "names": {
        "zh": "罗瑟勒姆",
        "ja": "ロザラム",
        "th": "ร็อตเธอร์แฮม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.43,
        "lng": -1.357
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4672,
      "name": "Dera Ismail Khan",
      "names": {
        "de": "Era Ismail Khan"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.8167,
        "lng": 70.9167
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 4671,
      "name": "Kecskemet",
      "names": {
        "zh": "凯奇凯梅特",
        "de": "Kecskemét"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9074,
        "lng": 19.6917
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 4562,
      "name": "Esteban Echeverria",
      "names": {
        "ja": "エスベンエハビリア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8167,
        "lng": -58.4667
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 4682,
      "name": "Quezon",
      "names": {
        "zh": "奎松",
        "ja": "ケソン",
        "th": "เควซอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.7333,
        "lng": 125.1
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4687,
      "name": "Aguachica",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.3067,
        "lng": -73.6153
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4680,
      "name": "Naujan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.3233,
        "lng": 121.3028
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4688,
      "name": "Glan",
      "names": {
        "zh": "格兰"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 5.8167,
        "lng": 125.2
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4693,
      "name": "Jurupa Valley",
      "names": {
        "es": "Valle de jurupa",
        "fr": "Vallée de Jurupa",
        "zh": "Jurupa山谷",
        "de": "Jurupa-Tal",
        "it": "Valle di Jurupa",
        "id": "Lembah Jurupa",
        "th": "หุบเขาจูราปะ",
        "pt": "Vale do Jurupa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.001,
        "lng": -117.4705
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4690,
      "name": "Bayugan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.7143,
        "lng": 125.7481
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4696,
      "name": "Gualeguaychu",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -33.0078,
        "lng": -58.5111
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 4700,
      "name": "Sandy Springs",
      "names": {
        "zh": "桑迪泉",
        "de": "Sandstrings",
        "it": "Sorgenti sabbiose",
        "id": "Pegas berpasir",
        "ja": "サンディスプリングス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9366,
        "lng": -84.3703
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4676,
      "name": "Drammen",
      "names": {
        "zh": "德拉门",
        "th": "ดรัมเมน"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.7439,
        "lng": 10.2045
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 4692,
      "name": "Medenine",
      "names": {
        "zh": "梅德宁"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 33.3547,
        "lng": 10.5053
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 4704,
      "name": "Gresham",
      "names": {
        "zh": "格雷沙姆",
        "ja": "グレシャム",
        "th": "เกรชัม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.5023,
        "lng": -122.4413
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4505,
      "name": "Kamagaya",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7769,
        "lng": 140.0008
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4668,
      "name": "Tacurong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6833,
        "lng": 124.6667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4695,
      "name": "Taoyang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.3754,
        "lng": 103.8612
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4697,
      "name": "Beja",
      "names": {
        "zh": "贝雅"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.7256,
        "lng": 9.1817
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 4703,
      "name": "Yongju",
      "names": {
        "zh": "荣州"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.8747,
        "lng": 128.5864
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 4685,
      "name": "Sandacho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8894,
        "lng": 135.2253
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4435,
      "name": "Berkane",
      "names": {
        "zh": "贝尔卡内"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.9167,
        "lng": -2.3167
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 4709,
      "name": "Lewisville",
      "names": {
        "th": "ลูอิสวิ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0452,
        "lng": -96.9823
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4663,
      "name": "Marugame",
      "names": {
        "zh": "丸龟"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2894,
        "lng": 133.7978
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4568,
      "name": "Tangjia",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.3566,
        "lng": 113.5919
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4713,
      "name": "Hillsboro",
      "names": {
        "zh": "希尔斯伯勒",
        "ja": "ヒルズボロ",
        "th": "โบโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.5272,
        "lng": -122.9361
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4714,
      "name": "Worthing",
      "names": {
        "zh": "沃辛",
        "ja": "ワージング",
        "th": "เวอร์ทิง"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.8147,
        "lng": -0.3714
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4711,
      "name": "Ipiales",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 0.8289,
        "lng": -77.6406
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 4715,
      "name": "San Buenaventura",
      "names": {
        "zh": "圣布纳留村",
        "ja": "サンブナベントゥーラ",
        "th": "ซานบัวเวนทูรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.2741,
        "lng": -119.2314
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4607,
      "name": "Murom",
      "names": {
        "zh": "穆罗姆"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.5725,
        "lng": 42.0514
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4710,
      "name": "Tuxpam de Rodriguez Cano",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.95,
        "lng": -97.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4717,
      "name": "Sao Mateus",
      "names": {
        "zh": "圣马特斯",
        "ja": "サンマテウス",
        "pt": "São Mateus"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.7158,
        "lng": -39.8589
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4718,
      "name": "Balombo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.35,
        "lng": 14.7667
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 4520,
      "name": "Mulhouse",
      "names": {
        "zh": "米卢斯",
        "ja": "ミュルーズ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.75,
        "lng": 7.34
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 4708,
      "name": "Soubre",
      "names": {
        "zh": "苏布雷"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 5.7836,
        "lng": -6.5939
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 4701,
      "name": "Talisay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7333,
        "lng": 122.9667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4677,
      "name": "Taitung",
      "names": {
        "zh": "台东",
        "ja": "台東"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 22.7583,
        "lng": 121.1444
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 4664,
      "name": "Bangaon",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.07,
        "lng": 88.82
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4575,
      "name": "Dolgoprudnyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.9333,
        "lng": 37.5
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4722,
      "name": "St. John\u0027s",
      "names": {
        "zh": "圣约翰的",
        "id": "St. John",
        "ja": "セントジョンズ",
        "th": "เซนต์จอห์น"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 47.4817,
        "lng": -52.7971
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 4723,
      "name": "Jacksonville",
      "names": {
        "zh": "杰克逊维尔",
        "ja": "ジャクソンビル",
        "th": "แจ็กสันวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.7288,
        "lng": -77.3941
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4606,
      "name": "Pulilan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.902,
        "lng": 120.849
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4706,
      "name": "Maxixe",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -23.8597,
        "lng": 35.3472
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 4694,
      "name": "Kasserine",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 35.1667,
        "lng": 8.8333
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 4721,
      "name": "Matruh",
      "names": {
        "zh": "马特鲁"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.3333,
        "lng": 27.2333
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 4726,
      "name": "Pottstown",
      "names": {
        "zh": "波茨敦"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.2507,
        "lng": -75.6444
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4609,
      "name": "Tondabayashicho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4992,
        "lng": 135.5972
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4639,
      "name": "Yessentuki",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.0431,
        "lng": 42.8644
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4729,
      "name": "Moncton",
      "names": {
        "zh": "蒙克顿",
        "th": "มองก์ตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.1328,
        "lng": -64.7714
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4662,
      "name": "Xishancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6014,
        "lng": 116.3257
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4652,
      "name": "Mascara",
      "names": {
        "es": "Rímel",
        "zh": "睫毛膏",
        "de": "Wimperntusche",
        "id": "Maskara",
        "ja": "マスカラ",
        "th": "มาสคาร่า",
        "pt": "Rímel"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.4,
        "lng": 0.1333
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 4554,
      "name": "Khenchela",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.4167,
        "lng": 7.1333
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 4730,
      "name": "Yasuj",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 30.6667,
        "lng": 51.5833
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 4733,
      "name": "Islamabad",
      "names": {
        "zh": "伊斯兰堡",
        "ja": "イスラマバード",
        "th": "กรุงอิสลามาบัด"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 33.73,
        "lng": 75.15
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4614,
      "name": "Khardah",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.72,
        "lng": 88.38
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4727,
      "name": "Cawayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.9667,
        "lng": 122.6167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4731,
      "name": "Tipitapa",
      "names": {
        "zh": "蒂皮塔帕"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.2,
        "lng": -86.1
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 4551,
      "name": "Mopti",
      "names": {
        "zh": "莫普提"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 14.49,
        "lng": -4.18
      },
      "country": "Mali",
      "importance": 1
    },
    {
      "id": 4732,
      "name": "Alto Hospicio",
      "names": {
        "ja": "アルトホスピオ",
        "th": "อัลโตโฮสเตส"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -20.25,
        "lng": -70.1167
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 4572,
      "name": "Habikino",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5578,
        "lng": 135.6061
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4716,
      "name": "Novoshakhtinsk",
      "names": {
        "th": "โนโวชาคทินสค์"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.7667,
        "lng": 39.9167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4737,
      "name": "Sivas",
      "names": {
        "zh": "锡瓦斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.75,
        "lng": 37.0167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 4734,
      "name": "Maramag",
      "names": {
        "zh": "马拉马格"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.7667,
        "lng": 125.0
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4728,
      "name": "Tadpatri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.9097,
        "lng": 78.0083
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4736,
      "name": "Birnin Kebbi",
      "names": {
        "ja": "ビルニンケブビ"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 12.4504,
        "lng": 4.1999
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 4741,
      "name": "Inglewood",
      "names": {
        "zh": "英格伍德",
        "ja": "イングルウッド",
        "th": "อิงเกิลวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9566,
        "lng": -118.3444
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4743,
      "name": "Sarh",
      "names": {
        "zh": "萨尔"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 9.15,
        "lng": 18.3833
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 4744,
      "name": "An Nuhud",
      "names": {
        "es": "Un nuhud",
        "fr": "Un nuhud",
        "zh": "nuhud",
        "de": "Ein Nuhud",
        "it": "Un nuhud",
        "id": "Sebuah nuhud",
        "ja": "ヌムド",
        "th": "nuhud",
        "pt": "Um nuhud"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 12.6904,
        "lng": 28.42
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 4745,
      "name": "San Rafael",
      "names": {
        "zh": "圣拉斐尔",
        "ja": "サンラファエル",
        "th": "ซานราฟาเอล"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6175,
        "lng": -68.3356
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 4588,
      "name": "Zhukovskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.6011,
        "lng": 38.1161
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4739,
      "name": "Seversk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.6,
        "lng": 84.85
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4749,
      "name": "Thunder Bay",
      "names": {
        "es": "Bahía de truenos",
        "fr": "Tonnerre",
        "zh": "雷湾",
        "de": "Donnerbucht",
        "id": "Teluk",
        "ja": "サンダーベイ",
        "th": "อ่าวทันเดอร์",
        "pt": "Baía"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 48.3822,
        "lng": -89.2461
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4599,
      "name": "Tuzla",
      "names": {
        "zh": "图兹拉",
        "ja": "トゥズラ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8637,
        "lng": 29.3194
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 4751,
      "name": "Baia Farta",
      "names": {
        "ja": "バイアファルタ"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -12.6128,
        "lng": 13.1987
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 4623,
      "name": "Alkmaar",
      "names": {
        "zh": "阿尔克马尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6289,
        "lng": 4.744
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 4523,
      "name": "Mazhang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 21.2757,
        "lng": 110.3221
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4553,
      "name": "Castelar",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.65,
        "lng": -58.65
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 4702,
      "name": "Lingayen",
      "names": {
        "zh": "林加延"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0167,
        "lng": 120.2333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4673,
      "name": "Labe",
      "names": {
        "zh": "拉贝"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 11.3167,
        "lng": -12.2833
      },
      "country": "Guinea",
      "importance": 1
    },
    {
      "id": 4760,
      "name": "Chaman",
      "names": {
        "zh": "查曼"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.921,
        "lng": 66.4597
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 4747,
      "name": "Escuintla",
      "names": {
        "zh": "埃斯昆特拉"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.3004,
        "lng": -90.78
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 4595,
      "name": "Maidstone",
      "names": {
        "zh": "梅德斯通",
        "th": "เมดสโตน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.272,
        "lng": 0.529
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4762,
      "name": "Umm Qasr",
      "names": {
        "zh": "嗯qasr",
        "de": "Ähm qasr"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 30.0342,
        "lng": 47.9294
      },
      "country": "Iraq",
      "importance": 3
    },
    {
      "id": 4759,
      "name": "Carmen",
      "names": {
        "zh": "卡门",
        "ja": "カルメン",
        "th": "การ์เมน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.2,
        "lng": 124.7833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4767,
      "name": "Kogon Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.7211,
        "lng": 64.5458
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 4768,
      "name": "League City",
      "names": {
        "es": "Ciudad de la liga",
        "fr": "Ville de la Ligue",
        "zh": "联盟城",
        "de": "Liga-Stadt",
        "it": "Città della lega",
        "id": "Kota liga",
        "ja": "リーグシティ",
        "th": "ลีกซิตี้",
        "pt": "Cidade da liga"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.4873,
        "lng": -95.1087
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4581,
      "name": "Kawit",
      "names": {
        "zh": "卡维特"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4333,
        "lng": 120.9
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4725,
      "name": "Samsun",
      "names": {
        "zh": "萨姆松"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2867,
        "lng": 36.33
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 4755,
      "name": "Emmen",
      "names": {
        "zh": "埃门"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.7833,
        "lng": 6.9
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 4712,
      "name": "Mineshita",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1186,
        "lng": 138.9186
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4773,
      "name": "Eau Claire",
      "names": {
        "id": "Orang bodoh",
        "ja": "オークレア"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.8199,
        "lng": -91.4949
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4771,
      "name": "Morales",
      "names": {
        "zh": "莫拉莱斯",
        "ja": "モラレス",
        "th": "โมราเลส"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4725,
        "lng": -88.8414
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 4641,
      "name": "Shinyanga",
      "names": {
        "zh": "希尼安加"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -3.6619,
        "lng": 33.4231
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 4630,
      "name": "Basingstoke",
      "names": {
        "zh": "贝辛斯托克"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.2667,
        "lng": -1.0876
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4735,
      "name": "Jalpaiguri",
      "names": {
        "zh": "杰尔拜古里"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.5167,
        "lng": 88.7333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4774,
      "name": "Bolzano",
      "names": {
        "zh": "波尔查诺",
        "de": "Bozen",
        "ja": "ボルツァーノ",
        "th": "โบลซาโน"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 46.5,
        "lng": 11.35
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4775,
      "name": "Turlock",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 37.5053,
        "lng": -120.8588
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4764,
      "name": "Bento Goncalves",
      "names": {
        "ja": "ベントゴンカルブ",
        "pt": "Bento Gonçalves"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.1708,
        "lng": -51.5189
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4777,
      "name": "Temple",
      "names": {
        "es": "Templo",
        "zh": "寺",
        "de": "Tempel",
        "it": "Tempio",
        "id": "Kuil",
        "ja": "寺",
        "th": "วัด",
        "pt": "Templo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.1076,
        "lng": -97.3894
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4772,
      "name": "Chichicastenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9442,
        "lng": -91.1105
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 4778,
      "name": "Chongshan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 18.7787,
        "lng": 109.5117
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4779,
      "name": "Ilebo",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -4.3167,
        "lng": 20.6
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 4738,
      "name": "Siauliai",
      "names": {
        "zh": "希奥利艾"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9281,
        "lng": 23.3167
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 4781,
      "name": "Dieppe",
      "names": {
        "zh": "迪耶普"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.0989,
        "lng": -64.7242
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4355,
      "name": "Reutov",
      "names": {
        "zh": "列乌托夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7667,
        "lng": 37.8667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4780,
      "name": "Taungoo",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 18.9483,
        "lng": 96.4179
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 4746,
      "name": "Koszalin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.1903,
        "lng": 16.1817
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 4787,
      "name": "Noyabrsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 63.2017,
        "lng": 75.4517
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4699,
      "name": "Botosani",
      "names": {
        "zh": "博托沙尼"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.7486,
        "lng": 26.6694
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 4667,
      "name": "Chorzow",
      "names": {
        "es": "Chorzów",
        "zh": "霍茹夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.3,
        "lng": 18.95
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 4788,
      "name": "Yopal",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.3306,
        "lng": -72.3906
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 4765,
      "name": "Suriapet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.15,
        "lng": 79.6167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4748,
      "name": "Tajimi",
      "names": {
        "zh": "多治见"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3328,
        "lng": 137.1322
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4792,
      "name": "Chabahar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 25.2836,
        "lng": 60.6236
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4785,
      "name": "Artem",
      "names": {
        "zh": "阿尔乔姆",
        "ja": "アルチョーム"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.35,
        "lng": 132.1833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4757,
      "name": "Douliu",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 23.7075,
        "lng": 120.5439
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 4719,
      "name": "Crawley",
      "names": {
        "zh": "克劳利",
        "ja": "クローリー",
        "th": "คราว"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.1092,
        "lng": -0.1872
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4795,
      "name": "Orsha",
      "names": {
        "zh": "奥尔沙"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 54.5092,
        "lng": 30.4258
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 4791,
      "name": "As Safirah",
      "names": {
        "es": "Como safirah",
        "fr": "Comme Safirah",
        "zh": "作为Safirah",
        "de": "Als Safirah",
        "it": "Come Safirah",
        "id": "Sebagai safirah",
        "ja": "Safirahとして",
        "th": "เป็น Safirah",
        "pt": "Como Safirah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.0769,
        "lng": 37.3725
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 4799,
      "name": "Sioux City",
      "names": {
        "es": "La ciudad de Sioux",
        "id": "Kota sioux",
        "ja": "スーシティ",
        "pt": "Cidade de sioux"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4959,
        "lng": -96.3901
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4800,
      "name": "Salisbury",
      "names": {
        "zh": "索尔兹伯里",
        "ja": "ソールズベリー",
        "th": "ซูเปอร์มาร์เก็ต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.3756,
        "lng": -75.5867
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4798,
      "name": "La Banda",
      "names": {
        "ja": "ラバンダ",
        "pt": "Laja"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.7333,
        "lng": -64.25
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 4790,
      "name": "Catbalogan",
      "names": {
        "zh": "卡巴洛甘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.7833,
        "lng": 124.8833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4784,
      "name": "Longjiang",
      "names": {
        "zh": "龙江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.3404,
        "lng": 123.18
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4793,
      "name": "Nakhon Si Thammarat",
      "names": {
        "ja": "ナコンSi Thammarat",
        "th": "นครศรีธรรมราช",
        "pt": "Nakhon Si Tamammarat"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.4363,
        "lng": 99.963
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 4797,
      "name": "Patos",
      "names": {
        "zh": "帕托斯",
        "ja": "パトス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.0244,
        "lng": -37.28
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4805,
      "name": "Davie",
      "names": {
        "zh": "大卫"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.0789,
        "lng": -80.287
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4802,
      "name": "Passos",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.7189,
        "lng": -46.61
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4809,
      "name": "Daly City",
      "names": {
        "zh": "戴利市",
        "id": "Kota Daly",
        "ja": "ダリシティ",
        "th": "เมืองดาลี่",
        "pt": "Cidade de daly"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6862,
        "lng": -122.4685
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4786,
      "name": "Tiaong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.95,
        "lng": 121.3167
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 4689,
      "name": "Carmona",
      "names": {
        "zh": "卡莫纳",
        "ja": "カルモナ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3167,
        "lng": 121.05
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4740,
      "name": "El Limon",
      "names": {
        "de": "El-Limoner"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.3003,
        "lng": -67.6336
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 4754,
      "name": "Yevpatoriia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.2,
        "lng": 33.3583
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 4776,
      "name": "Launceston",
      "names": {
        "zh": "朗塞斯顿",
        "ja": "ローンセストン",
        "th": "ลอนเซสตัน"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -41.4419,
        "lng": 147.145
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 4796,
      "name": "Raba",
      "names": {
        "zh": "拉巴"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.4614,
        "lng": 118.747
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 4742,
      "name": "Reus",
      "names": {
        "zh": "雷乌斯",
        "ja": "レウス",
        "th": "เรอุส"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.1549,
        "lng": 1.1087
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 4750,
      "name": "Pavlohrad",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.52,
        "lng": 35.87
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 4806,
      "name": "Aihua",
      "names": {
        "zh": "爱华"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.4629,
        "lng": 100.135
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4804,
      "name": "Komatsu",
      "names": {
        "zh": "小松",
        "ja": "コマツ",
        "th": "รถ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4167,
        "lng": 136.45
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4810,
      "name": "Contramaestre",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 20.3,
        "lng": -76.2506
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 4817,
      "name": "Ariquemes",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -9.9161,
        "lng": -63.0408
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4815,
      "name": "Lo Barnechea",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -33.35,
        "lng": -70.5167
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 4807,
      "name": "Temoaya",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4686,
        "lng": -99.5933
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4825,
      "name": "Tikrit",
      "names": {
        "zh": "提克里特"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 34.6,
        "lng": 43.6833
      },
      "country": "Iraq",
      "importance": 1
    },
    {
      "id": 4816,
      "name": "Azare",
      "names": {
        "zh": "阿扎雷"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 11.6804,
        "lng": 10.19
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 4829,
      "name": "Highlands Ranch",
      "names": {
        "es": "Rancho de tierras altas",
        "zh": "高地牧场",
        "id": "Peternakan dataran tinggi",
        "ja": "ハイランド牧場",
        "th": "ฟาร์มไฮแลนด์",
        "pt": "Rancho de Highlands"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.5419,
        "lng": -104.9708
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4830,
      "name": "Allen",
      "names": {
        "zh": "艾伦",
        "ja": "アレン",
        "th": "อัลเลน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1088,
        "lng": -96.6735
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4831,
      "name": "Tarim",
      "names": {
        "zh": "塔里木"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 16.05,
        "lng": 49.0
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 4691,
      "name": "Caen",
      "names": {
        "zh": "卡昂",
        "ja": "カーン",
        "th": "แคน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.18,
        "lng": -0.37
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 4813,
      "name": "Mang La",
      "names": {
        "fr": "Manghe",
        "zh": "莽洛",
        "it": "Manging",
        "id": "Mang",
        "ja": "マンラ",
        "th": "มังกา"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 14.3838,
        "lng": 107.9833
      },
      "country": "Vietnam",
      "importance": 3
    },
    {
      "id": 4822,
      "name": "Pushkino",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0167,
        "lng": 37.85
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4821,
      "name": "Kiryu",
      "names": {
        "ja": "桐生"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4053,
        "lng": 139.3306
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4832,
      "name": "Malungun",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.2667,
        "lng": 125.2833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4833,
      "name": "Mazyr",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.05,
        "lng": 29.2333
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 4766,
      "name": "Charsadda",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 34.1453,
        "lng": 71.7308
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 4770,
      "name": "Alchevsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.4672,
        "lng": 38.7983
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 4835,
      "name": "Robat Karim",
      "names": {
        "ja": "ロバットカリム"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.4847,
        "lng": 51.0828
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4842,
      "name": "Erzurum",
      "names": {
        "zh": "埃尔祖鲁姆"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.9097,
        "lng": 41.2756
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 4758,
      "name": "Queenstown",
      "names": {
        "zh": "皇后镇",
        "ja": "クイーンズタウン",
        "th": "ควีนส์ทาวน์"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -31.9,
        "lng": 26.8833
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 4808,
      "name": "Achinsk",
      "names": {
        "zh": "阿钦斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.2817,
        "lng": 90.5039
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4622,
      "name": "Ash Shaykh `Uthman",
      "names": {
        "es": "Ash Shaykh \u0027Uthman",
        "zh": "Ash shaykh`uthman",
        "de": "Ash shaykh `athman",
        "ja": "灰Shaykh `Uthman",
        "pt": "Cinza Shaykh `Uthman"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 12.8866,
        "lng": 45.0279
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 4843,
      "name": "Kongolo",
      "names": {
        "zh": "孔戈洛"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -5.3795,
        "lng": 26.98
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 4826,
      "name": "Bulan",
      "names": {
        "zh": "都婆兰"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.6714,
        "lng": 123.875
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4827,
      "name": "Malacatan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9106,
        "lng": -92.0581
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 4841,
      "name": "Granada",
      "names": {
        "fr": "Grenade",
        "zh": "格拉纳达",
        "ja": "グラナダ",
        "th": "กรานาดา"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.93,
        "lng": -85.9561
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 4847,
      "name": "Texcoco",
      "names": {
        "zh": "特斯科科"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.52,
        "lng": -98.88
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4818,
      "name": "Paco do Lumiar",
      "names": {
        "zh": "Paco做邪恶",
        "de": "Paco lumiar",
        "id": "Paco melakukan Lumiar",
        "th": "Paco ทำ lumiar"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.5319,
        "lng": -44.1078
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4844,
      "name": "Sultan Kudarat",
      "names": {
        "zh": "苏丹·库拉特",
        "ja": "スルタンクダラ",
        "th": "สุลต่าน kudarat",
        "pt": "Sultão kudarat"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.2333,
        "lng": 124.25
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4853,
      "name": "West Covina",
      "names": {
        "zh": "西科维纳",
        "de": "West-Covina",
        "it": "Covina ovest",
        "id": "Covina Barat",
        "ja": "ウェストコヴィナ",
        "pt": "Covina Ocidental"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0555,
        "lng": -117.9112
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4852,
      "name": "Ndjamba",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.7,
        "lng": 16.0667
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 4850,
      "name": "Al Faw",
      "names": {
        "es": "Alfombra",
        "fr": "Alternance",
        "zh": "一汽",
        "de": "Alten",
        "it": "Al ferro",
        "ja": "アルフォー",
        "th": "อัลฟอว์"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 29.98,
        "lng": 48.47
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 4794,
      "name": "Jincheng",
      "names": {
        "zh": "金城",
        "ja": "金城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.5529,
        "lng": 113.1933
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4837,
      "name": "Kandhkot",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 28.4,
        "lng": 69.3
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 4857,
      "name": "Sparks",
      "names": {
        "es": "Chispas",
        "fr": "Étincelles",
        "zh": "火花",
        "de": "Funken",
        "it": "Scintille",
        "ja": "火花",
        "th": "ประกายไฟ",
        "pt": "Faíscas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.5729,
        "lng": -119.7157
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4858,
      "name": "Waterloo",
      "names": {
        "zh": "滑铁卢",
        "ja": "ワーテルロー",
        "th": "วอเตอร์ลู"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.4667,
        "lng": -80.5167
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4752,
      "name": "Tagbilaran City",
      "names": {
        "zh": "Tagbilaran城市",
        "de": "Tagbilaran-Stadt",
        "it": "Città tagbilaran",
        "id": "Kota Tagbilaran",
        "ja": "タグビララン市",
        "pt": "Cidade tagbilaran"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.65,
        "lng": 123.85
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 4855,
      "name": "Salzgitter",
      "names": {
        "zh": "萨尔茨基特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1503,
        "lng": 10.3593
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4819,
      "name": "Koutiala",
      "names": {
        "zh": "库佳拉"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 12.3833,
        "lng": -5.4667
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 4593,
      "name": "Nancy",
      "names": {
        "zh": "南希",
        "ja": "ナンシー",
        "th": "แนนซี่"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6936,
        "lng": 6.1846
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 4597,
      "name": "Parral",
      "names": {
        "zh": "帕拉尔"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.9333,
        "lng": -105.6667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4864,
      "name": "Wichita Falls",
      "names": {
        "fr": "Wichita tombe",
        "zh": "威奇塔瀑布",
        "ja": "ウィチタが落ちます"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9072,
        "lng": -98.5291
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4824,
      "name": "Ourense",
      "names": {
        "th": "อูเรนเซ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.3364,
        "lng": -7.8633
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 4863,
      "name": "Sergiyev Posad",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3,
        "lng": 38.1333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4782,
      "name": "Arzamas",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.3833,
        "lng": 43.8
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4789,
      "name": "Monastir",
      "names": {
        "zh": "莫纳斯提尔",
        "ja": "モナスティール",
        "th": "สตีร์"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.778,
        "lng": 10.8262
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 4812,
      "name": "Vespasiano",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.6919,
        "lng": -43.9228
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4854,
      "name": "Masbate",
      "names": {
        "zh": "马斯巴特"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.3667,
        "lng": 123.6167
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4867,
      "name": "Trindade",
      "names": {
        "zh": "特林达迪"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.6489,
        "lng": -49.4889
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4873,
      "name": "San Mateo",
      "names": {
        "zh": "圣马特奥",
        "ja": "サンマテオ",
        "th": "ซานมาเทโอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.5521,
        "lng": -122.3122
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4849,
      "name": "Mmabatho",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.85,
        "lng": 25.6333
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 4861,
      "name": "Malatya",
      "names": {
        "zh": "马拉蒂亚"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3554,
        "lng": 38.3337
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 4871,
      "name": "Khak-e `Ali",
      "names": {
        "zh": "Khak-e`Ali"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.1283,
        "lng": 50.1764
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 4823,
      "name": "Yelets",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.6167,
        "lng": 38.4667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4872,
      "name": "Suruc",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.9761,
        "lng": 38.4253
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 4870,
      "name": "Maumere",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -8.6189,
        "lng": 122.2123
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 4846,
      "name": "Liberec",
      "names": {
        "zh": "利贝雷茨",
        "ja": "リベレツ"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.77,
        "lng": 15.0584
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 4848,
      "name": "Novara",
      "names": {
        "zh": "诺瓦拉",
        "ja": "ノバラ",
        "th": "โนวารา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.45,
        "lng": 8.6167
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4851,
      "name": "Balanga",
      "names": {
        "zh": "巴兰加"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6833,
        "lng": 120.5333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 4879,
      "name": "Gillingham",
      "names": {
        "zh": "吉灵厄姆",
        "th": "จิลลิ่งแฮม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.385,
        "lng": 0.55
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4874,
      "name": "Arapongas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.4189,
        "lng": -51.4239
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4720,
      "name": "Ikeda",
      "names": {
        "zh": "池田",
        "ja": "池田",
        "th": "อิเคดะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8167,
        "lng": 135.4333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4889,
      "name": "Acailandia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.9469,
        "lng": -47.505
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4860,
      "name": "Tadepallegudem",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.8333,
        "lng": 81.5
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4705,
      "name": "Adigrat",
      "names": {
        "zh": "阿迪格拉特"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 14.2804,
        "lng": 39.47
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 4881,
      "name": "Abengourou",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 6.7297,
        "lng": -3.4964
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 782,
      "name": "Thimphu",
      "names": {
        "zh": "廷布",
        "ja": "ティンプー",
        "th": "ทิมพู"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.4833,
        "lng": 89.6333
      },
      "country": "Bhutan",
      "importance": 0
    },
    {
      "id": 4836,
      "name": "Kroonstad",
      "names": {
        "zh": "克龙斯塔德"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -27.6456,
        "lng": 27.2317
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 4892,
      "name": "Columbia",
      "names": {
        "fr": "-Britannique",
        "zh": "哥伦比亚",
        "de": "Kolumbien",
        "ja": "コロンビア",
        "th": "โคลัมเบีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.2004,
        "lng": -76.859
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4883,
      "name": "Sindangan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.2333,
        "lng": 123.0
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4896,
      "name": "Norwalk",
      "names": {
        "zh": "诺瓦克",
        "th": "วอล์ค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9069,
        "lng": -118.0829
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4895,
      "name": "EdDamer",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 17.59,
        "lng": 33.96
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 4481,
      "name": "Bansbaria",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.97,
        "lng": 88.4
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4839,
      "name": "Toride",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9114,
        "lng": 140.0503
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4900,
      "name": "Dolisie",
      "names": {
        "zh": "多利西"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.1961,
        "lng": 12.6731
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 4724,
      "name": "Salford",
      "names": {
        "zh": "索尔福德",
        "ja": "ソルフォード",
        "th": "ฟอบส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.483,
        "lng": -2.2931
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4897,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -36.6203,
        "lng": -64.2906
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 4894,
      "name": "Saijo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.9196,
        "lng": 133.1812
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4845,
      "name": "Moers",
      "names": {
        "zh": "莫尔斯"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4592,
        "lng": 6.6197
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 4891,
      "name": "Kilinochchi",
      "names": {
        "zh": "基利诺奇"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 9.4004,
        "lng": 80.3999
      },
      "country": "Sri Lanka",
      "importance": 3
    },
    {
      "id": 4886,
      "name": "Coronel Fabriciano",
      "names": {
        "es": "CORONEL FABRICANO",
        "it": "Coronel Fabricaiano",
        "ja": "Coronel Fraviano",
        "th": "ผ้าโคโรนา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.5189,
        "lng": -42.6289
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4869,
      "name": "Inzai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8322,
        "lng": 140.1458
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4686,
      "name": "Uitenhage",
      "names": {
        "zh": "埃滕哈赫"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.75,
        "lng": 25.4
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 4913,
      "name": "Tantoyuca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.35,
        "lng": -98.2333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4914,
      "name": "Biak",
      "names": {
        "zh": "比亚克"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -1.18,
        "lng": 136.08
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 4904,
      "name": "Wigan",
      "names": {
        "zh": "维冈竞技",
        "ja": "ウィガン",
        "th": "วีแกน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5448,
        "lng": -2.6318
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4856,
      "name": "Berdsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.75,
        "lng": 83.1
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4866,
      "name": "Konotop",
      "names": {
        "zh": "科诺托普"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 51.2369,
        "lng": 33.2027
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 4910,
      "name": "Yunfu",
      "names": {
        "zh": "云浮",
        "th": "หยุนฝู"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.6331,
        "lng": 104.4181
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4920,
      "name": "Rialto",
      "names": {
        "zh": "里亚尔托",
        "ja": "リアルト"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1174,
        "lng": -117.3894
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4763,
      "name": "Musoma",
      "names": {
        "zh": "穆索马"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -1.5069,
        "lng": 33.8042
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 4926,
      "name": "Manteca",
      "names": {
        "zh": "曼特卡",
        "ja": "マンテカ",
        "th": "แมนเทกา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7927,
        "lng": -121.2264
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4884,
      "name": "Lower Hutt",
      "names": {
        "es": "Hutt inferior",
        "fr": "Basse Hutt",
        "zh": "下赫特",
        "de": "Niedrigeres Hutt",
        "it": "Hutt più basso",
        "id": "Hutt bawah",
        "ja": "下のHUTT",
        "th": "ต่ำสุดฮัทท์",
        "pt": "Hutt inferior"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.2167,
        "lng": 174.9167
      },
      "country": "New Zealand",
      "importance": 3
    },
    {
      "id": 4925,
      "name": "Lida",
      "names": {
        "zh": "利达"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 53.8872,
        "lng": 25.3028
      },
      "country": "Belarus",
      "importance": 3
    },
    {
      "id": 4928,
      "name": "Bumba",
      "names": {
        "zh": "本巴"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.19,
        "lng": 22.46
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 4801,
      "name": "Vinh Long",
      "names": {
        "es": "Vinh largo",
        "de": "Vinh lang",
        "ja": "長いvinh",
        "th": "vinh ยาว",
        "pt": "Vinh longo"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.255,
        "lng": 105.9753
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 4929,
      "name": "Araxa",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.5928,
        "lng": -46.9408
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4919,
      "name": "Bundi",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.4383,
        "lng": 75.6372
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4918,
      "name": "Mazatenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5333,
        "lng": -91.5
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 4930,
      "name": "Almirante Tamandare",
      "names": {
        "ja": "アルミランテタマンダー"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.325,
        "lng": -49.31
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4876,
      "name": "Chikushino",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.4964,
        "lng": 130.5156
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4935,
      "name": "Soasio",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 0.6094,
        "lng": 127.5697
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 4915,
      "name": "Elista",
      "names": {
        "zh": "埃利斯塔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 46.3167,
        "lng": 44.2667
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 4911,
      "name": "Dongguazhen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.079,
        "lng": 101.502
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4906,
      "name": "San Rafael",
      "names": {
        "zh": "圣拉斐尔",
        "ja": "サンラファエル",
        "th": "ซานราฟาเอล"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.95,
        "lng": 120.9667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4936,
      "name": "Piacenza",
      "names": {
        "zh": "皮亚琴察",
        "ja": "ピアチェンツァ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.05,
        "lng": 9.7
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 4937,
      "name": "Miryang",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.4933,
        "lng": 128.7489
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 4887,
      "name": "Bouskoura",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.4489,
        "lng": -7.6486
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 4893,
      "name": "Paniqui",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6667,
        "lng": 120.5833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4939,
      "name": "Mengdingjie",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.5517,
        "lng": 99.0653
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4933,
      "name": "Yilong",
      "names": {
        "zh": "亿泷"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7081,
        "lng": 102.496
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4942,
      "name": "Parang",
      "names": {
        "es": "Machete malayo",
        "zh": "帕朗",
        "ja": "パラン",
        "th": "ฮอกกี้",
        "pt": "Parão"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.3744,
        "lng": 124.2686
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4901,
      "name": "Negapatam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.7667,
        "lng": 79.8333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4932,
      "name": "Sao Lourenco da Mata",
      "names": {
        "zh": "圣卢森诺达马塔",
        "ja": "サンルレンコダマタ",
        "pt": "São Lourenço da Mata"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.0019,
        "lng": -35.0178
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4945,
      "name": "Sangju",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.4153,
        "lng": 128.1606
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 4949,
      "name": "Arden-Arcade",
      "names": {
        "zh": "雅顿-街机",
        "ja": "アーデン-アーケード",
        "th": "อาร์เดน-อาเขต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6034,
        "lng": -121.381
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4820,
      "name": "Brovary",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.5114,
        "lng": 30.7903
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 4912,
      "name": "Siegen",
      "names": {
        "zh": "锡根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8756,
        "lng": 8.0167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4907,
      "name": "Telde",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 27.9985,
        "lng": -15.4167
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 4954,
      "name": "El Cajon",
      "names": {
        "ja": "エルカヨン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8017,
        "lng": -116.9605
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4948,
      "name": "Guihulngan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1167,
        "lng": 123.2667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4921,
      "name": "Diyarbakir",
      "names": {
        "zh": "迪亚巴克尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9108,
        "lng": 40.2367
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 4838,
      "name": "Saint Helens",
      "names": {
        "zh": "圣海伦",
        "ja": "セントヘレンズ",
        "th": "นักบุญเฮเลนส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4541,
        "lng": -2.7461
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4172,
      "name": "Jirja",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 26.3383,
        "lng": 31.8917
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 4950,
      "name": "Noginsk",
      "names": {
        "zh": "诺金斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.85,
        "lng": 38.4333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4957,
      "name": "Burbank",
      "names": {
        "zh": "伯班克",
        "ja": "バーバンク",
        "th": "เบอร์แบงก์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1879,
        "lng": -118.3235
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4958,
      "name": "Longmont",
      "names": {
        "zh": "朗蒙特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.1686,
        "lng": -105.1005
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4959,
      "name": "Lakewood",
      "names": {
        "zh": "莱克伍德",
        "ja": "レイクウッド",
        "th": "เลกวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0763,
        "lng": -74.2031
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4909,
      "name": "Balti",
      "names": {
        "zh": "巴蒂",
        "ja": "バルティー語",
        "th": "บัลติ"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 47.7617,
        "lng": 27.9289
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 4927,
      "name": "Messaad",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.1542,
        "lng": 3.4969
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 4940,
      "name": "Satu Mare",
      "names": {
        "es": "Mare satu",
        "fr": "Satu",
        "de": "Satu-Mare",
        "ja": "サトゥヤレ",
        "th": "แม่ม้า satu",
        "pt": "Satur Mare"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.79,
        "lng": 22.89
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 4938,
      "name": "Houzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.62,
        "lng": 111.21
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4960,
      "name": "Mayari",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 20.6592,
        "lng": -75.6781
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 4707,
      "name": "Ban Mangkon",
      "names": {
        "es": "Prohibir mangkon",
        "fr": "Bangkon",
        "zh": "禁止漫步",
        "ja": "禁止マンゴン",
        "th": "บ้านมังกร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6138,
        "lng": 100.6104
      },
      "country": "Thailand",
      "importance": 3
    },
    {
      "id": 4964,
      "name": "Kashmar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.2383,
        "lng": 58.4656
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4966,
      "name": "Delta",
      "names": {
        "zh": "三角洲",
        "ja": "デルタ",
        "th": "เดลต้า"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.0847,
        "lng": -123.0586
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4448,
      "name": "Jounie",
      "names": {},
      "countryCode": "LB",
      "latLng": {
        "lat": 33.9697,
        "lng": 35.6156
      },
      "country": "Lebanon",
      "importance": 2
    },
    {
      "id": 4961,
      "name": "Mositai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 45.5266,
        "lng": 119.6506
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4916,
      "name": "Yishi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.1379,
        "lng": 110.764
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4898,
      "name": "Isehara",
      "names": {
        "zh": "伊势原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4028,
        "lng": 139.315
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4934,
      "name": "Novokuybyshevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.1,
        "lng": 49.9167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4429,
      "name": "Shibuya",
      "names": {
        "zh": "涩谷",
        "ja": "渋谷区",
        "th": "ชิบุย่า"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6536,
        "lng": 139.7092
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4969,
      "name": "Sabratah",
      "names": {
        "zh": "塞卜拉泰"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.7933,
        "lng": 12.4885
      },
      "country": "Libya",
      "importance": 3
    },
    {
      "id": 4963,
      "name": "Igarassu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.8339,
        "lng": -34.9058
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4679,
      "name": "Al Fqih Ben Calah",
      "names": {
        "id": "Al fqih ben cala",
        "th": "อัล Fqih Ben Calah",
        "pt": "Al fqih ben oclah"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.6,
        "lng": -6.7667
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 4865,
      "name": "Fugangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.5899,
        "lng": 116.5924
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4941,
      "name": "Hildesheim",
      "names": {
        "zh": "希尔德斯海姆",
        "ja": "ヒルデスハイム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.15,
        "lng": 9.95
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 4962,
      "name": "Dhangadhi",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 28.7056,
        "lng": 80.575
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 4868,
      "name": "Girona",
      "names": {
        "zh": "赫罗纳",
        "ja": "ジローナ",
        "th": "เจโรนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.9833,
        "lng": 2.8167
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 4951,
      "name": "Bethal",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.45,
        "lng": 29.45
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 4859,
      "name": "Worcester",
      "names": {
        "zh": "伍斯特",
        "ja": "ウスター",
        "th": "เวอร์ซ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.192,
        "lng": -2.22
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4973,
      "name": "Shushtar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.0436,
        "lng": 48.8569
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 4971,
      "name": "Boryeong",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.3333,
        "lng": 126.6167
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 4090,
      "name": "Al Hajar al Aswad",
      "names": {
        "it": "Al Hajar Al Asswad"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.4581,
        "lng": 36.3053
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 4978,
      "name": "Renton",
      "names": {
        "zh": "伦顿",
        "ja": "レントン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.4784,
        "lng": -122.1919
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4968,
      "name": "Chishtian",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 29.7958,
        "lng": 72.8578
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 4974,
      "name": "Teluk Intan",
      "names": {},
      "countryCode": "MY",
      "latLng": {
        "lat": 4.0259,
        "lng": 101.0213
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 4980,
      "name": "Chatham",
      "names": {
        "zh": "查塔姆",
        "ja": "チャタム",
        "th": "ชาตัม"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.4229,
        "lng": -82.1324
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4981,
      "name": "`Ibri",
      "names": {},
      "countryCode": "OM",
      "latLng": {
        "lat": 23.2254,
        "lng": 56.517
      },
      "country": "Oman",
      "importance": 1
    },
    {
      "id": 4982,
      "name": "Vista",
      "names": {
        "zh": "Vista中",
        "ja": "ビスタ",
        "th": "วิสต้า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1896,
        "lng": -117.2386
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4984,
      "name": "Logan",
      "names": {
        "zh": "洛根",
        "ja": "ローガン",
        "th": "โลแกน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.74,
        "lng": -111.8419
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4943,
      "name": "Nantou",
      "names": {
        "zh": "南投",
        "th": "หนานโถว"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 23.9167,
        "lng": 120.6833
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 4840,
      "name": "Onojo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5364,
        "lng": 130.4789
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4976,
      "name": "Assis",
      "names": {
        "zh": "阿西斯",
        "ja": "アシス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.6619,
        "lng": -50.4119
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4975,
      "name": "Santo Antonio de Jesus",
      "names": {
        "es": "Santo Antonio de Jesús",
        "fr": "Santo Antonio de Jésus",
        "zh": "Santo Antonio de耶稣",
        "ja": "サントアントニオデイエス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.9692,
        "lng": -39.2611
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4903,
      "name": "Eastbourne",
      "names": {
        "zh": "伊斯特本",
        "ja": "イーストボーン",
        "th": "อีสต์บอร์น"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.77,
        "lng": 0.28
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 4987,
      "name": "Prescott Valley",
      "names": {
        "es": "Valle de Prescott",
        "fr": "Vallée de Prescott",
        "zh": "Prescott山谷",
        "id": "Lembah prescott",
        "ja": "プレスコットバレー"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5983,
        "lng": -112.3176
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4803,
      "name": "Akhmim",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 26.5642,
        "lng": 31.7461
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 4811,
      "name": "Ciudad Rio Bravo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.9861,
        "lng": -98.0889
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4947,
      "name": "Bhadreswar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.82,
        "lng": 88.35
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4990,
      "name": "Balsas",
      "names": {
        "zh": "巴尔萨斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.5328,
        "lng": -46.0358
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4988,
      "name": "Trinidad",
      "names": {
        "fr": "Trinité",
        "zh": "特立尼达和多巴哥",
        "ja": "トリニダード",
        "th": "ตรินิแดด"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -14.8333,
        "lng": -64.9
      },
      "country": "Bolivia",
      "importance": 1
    },
    {
      "id": 4992,
      "name": "Vacaville",
      "names": {
        "zh": "瓦卡维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.359,
        "lng": -121.968
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4953,
      "name": "Hengnan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5337,
        "lng": 113.2485
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 4965,
      "name": "Klagenfurt",
      "names": {
        "zh": "克拉根福",
        "ja": "クラーゲンフルト",
        "th": "คลาเกนฟูร์ท"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.6167,
        "lng": 14.3
      },
      "country": "Austria",
      "importance": 1
    },
    {
      "id": 4967,
      "name": "Chilakalurupet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.0892,
        "lng": 80.1672
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4994,
      "name": "San Luis de la Paz",
      "names": {
        "zh": "圣路易斯德拉巴斯",
        "ja": "サンルイスデラパズ",
        "th": "ซานหลุยส์เดอลาปาซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.3,
        "lng": -100.5167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4985,
      "name": "Sungai Penuh",
      "names": {
        "es": "Penuh Sungai"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -2.0636,
        "lng": 101.3961
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 4991,
      "name": "Pleven",
      "names": {
        "zh": "普列文"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.4132,
        "lng": 24.6169
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 4972,
      "name": "Jingping",
      "names": {
        "zh": "净瓶灌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.5189,
        "lng": 112.2837
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4979,
      "name": "Erdenet",
      "names": {
        "zh": "额尔登特"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 49.0278,
        "lng": 104.0444
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 4998,
      "name": "Kousseri",
      "names": {
        "zh": "库塞里"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 12.0833,
        "lng": 15.0333
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 4995,
      "name": "Ain Oussera",
      "names": {
        "es": "Ain ossera",
        "ja": "アニスゼラ",
        "pt": "Ain Ossera"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.4489,
        "lng": 2.9044
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 4996,
      "name": "Liancheng",
      "names": {
        "zh": "连城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.0515,
        "lng": 105.0573
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5002,
      "name": "Edinburg",
      "names": {
        "zh": "爱丁堡",
        "th": "เอดินเบิร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.3197,
        "lng": -98.1596
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4899,
      "name": "Zomba",
      "names": {
        "zh": "松巴"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -15.3833,
        "lng": 35.3333
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 4993,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.8333,
        "lng": 125.4167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5006,
      "name": "Carmel",
      "names": {
        "zh": "卡梅尔",
        "ja": "カーメル",
        "th": "คาร์เมล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.965,
        "lng": -86.146
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5007,
      "name": "Spokane Valley",
      "names": {
        "es": "Valle de Spokane",
        "fr": "Vallée de Spokane",
        "zh": "斯波坎山谷",
        "de": "Spokane-Tal",
        "id": "Lembah Spokane",
        "ja": "スポケーン渓谷",
        "th": "สโปเคนหุบเขา",
        "pt": "Vale de Spokane"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6626,
        "lng": -117.2346
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5009,
      "name": "San Angelo",
      "names": {
        "zh": "圣安吉洛",
        "ja": "サンアンジェロ",
        "th": "ซานแองเจโล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.4424,
        "lng": -100.4506
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5008,
      "name": "Ancona",
      "names": {
        "zh": "安科纳",
        "ja": "アンコーナ",
        "th": "โคนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.6167,
        "lng": 13.5167
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 4877,
      "name": "San Vicente de Baracaldo",
      "names": {
        "ja": "サンビセンテデバラカルド"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.2972,
        "lng": -2.9917
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5003,
      "name": "Kanoya",
      "names": {
        "zh": "鹿屋"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 31.3783,
        "lng": 130.8522
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4931,
      "name": "Sakado",
      "names": {
        "zh": "坂户"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9572,
        "lng": 139.4031
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5013,
      "name": "Salihorsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.7842,
        "lng": 27.5425
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 5005,
      "name": "Oum el Bouaghi",
      "names": {
        "id": "Oum el boughi"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.8706,
        "lng": 7.115
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 4769,
      "name": "Kefar Sava",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 32.1858,
        "lng": 34.9077
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 5012,
      "name": "Muriae",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.1308,
        "lng": -42.3658
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5011,
      "name": "Karabuk",
      "names": {
        "zh": "卡拉比克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2,
        "lng": 32.6333
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 4983,
      "name": "Zheleznogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.3333,
        "lng": 35.3667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5019,
      "name": "La Crosse",
      "names": {
        "zh": "La Croosse",
        "it": "La Cross",
        "ja": "ラクロス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.824,
        "lng": -91.2268
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4986,
      "name": "Lae",
      "names": {
        "zh": "抗癫痫联盟",
        "th": "แล"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -6.7303,
        "lng": 147.0008
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 5015,
      "name": "Umuarama",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.7658,
        "lng": -53.325
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4675,
      "name": "Cavite City",
      "names": {
        "fr": "Ville cavite",
        "de": "Kavitstadt",
        "it": "Città Cavite",
        "ja": "キャビテシティ",
        "pt": "Cidade Cavita"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4833,
        "lng": 120.9
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4828,
      "name": "Playas de Rosarito",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 32.3636,
        "lng": -117.0544
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 4296,
      "name": "Kallithea",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.95,
        "lng": 23.7
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 5020,
      "name": "Ahar",
      "names": {
        "zh": "阿哈尔"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 38.475,
        "lng": 47.0603
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 5001,
      "name": "Weifen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4633,
        "lng": 111.1203
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5010,
      "name": "Port Blair",
      "names": {
        "zh": "港口布莱尔",
        "de": "Blair",
        "ja": "ポートブレア",
        "th": "พอร์ตแบลร์",
        "pt": "Porto blair"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 11.6667,
        "lng": 92.75
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 4890,
      "name": "Kalyani",
      "names": {
        "zh": "卡利亚尼"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.975,
        "lng": 88.4344
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 4989,
      "name": "Olomouc",
      "names": {
        "zh": "奥洛穆茨",
        "ja": "オロモウツ"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.5939,
        "lng": 17.2508
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 4659,
      "name": "Bukoba",
      "names": {
        "zh": "布科巴"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -1.3333,
        "lng": 31.8167
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 5022,
      "name": "Masjed Soleyman",
      "names": {
        "es": "Masjed Solyman",
        "it": "Maschio Soleyman",
        "pt": "Soleyman masjed"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 31.9333,
        "lng": 49.3
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 5014,
      "name": "Kpalime",
      "names": {},
      "countryCode": "TG",
      "latLng": {
        "lat": 6.91,
        "lng": 0.6281
      },
      "country": "Togo",
      "importance": 3
    },
    {
      "id": 4956,
      "name": "Calasiao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0167,
        "lng": 120.3667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4888,
      "name": "Kamianets-Podilskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6806,
        "lng": 26.5806
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 5024,
      "name": "Torbat-e Jam",
      "names": {
        "es": "Atasco torbat-e",
        "zh": "torbat-e果酱",
        "de": "Torbat-e Marmelade",
        "ja": "Torbat-eジャム",
        "th": "Torbat-e แยม"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.2439,
        "lng": 60.6225
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 5025,
      "name": "Edison",
      "names": {
        "zh": "爱迪生",
        "ja": "エジソン",
        "th": "เอดิสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.536,
        "lng": -74.3697
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5018,
      "name": "Diourbel",
      "names": {},
      "countryCode": "SN",
      "latLng": {
        "lat": 14.655,
        "lng": -16.2314
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 5026,
      "name": "Idaho Falls",
      "names": {
        "fr": "Idaho tombe",
        "zh": "爱达荷瀑布",
        "de": "Idaho fällt",
        "ja": "アイダホが落ちる",
        "th": "ไอดาโฮฟอลส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.4872,
        "lng": -112.0362
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5028,
      "name": "Holland",
      "names": {
        "es": "Holanda",
        "zh": "荷兰",
        "it": "Olanda",
        "id": "Belanda",
        "ja": "オランダ",
        "th": "ฮอลแลนด์",
        "pt": "HOLANDA"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7677,
        "lng": -86.0985
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5029,
      "name": "Red Deer",
      "names": {
        "es": "Ciervo rojo",
        "fr": "Cerf rouge",
        "zh": "红鹿",
        "de": "Rotwild",
        "it": "Cervo",
        "id": "Rusa merah",
        "ja": "赤い鹿",
        "th": "กวางแดง",
        "pt": "Veado vermelho"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 52.2681,
        "lng": -113.8111
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5030,
      "name": "Charlottesville",
      "names": {
        "zh": "夏洛茨维尔",
        "th": "ชาร์ลอตส์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.0375,
        "lng": -78.4855
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4997,
      "name": "Kawachinagano",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4581,
        "lng": 135.5641
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5033,
      "name": "Longview",
      "names": {
        "zh": "朗维尤",
        "ja": "ロングビュー",
        "th": "วิว"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.5193,
        "lng": -94.7621
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4905,
      "name": "Libertad",
      "names": {
        "zh": "伯塔德",
        "ja": "リベルタ",
        "th": "เสรีภาพ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6833,
        "lng": -58.6833
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 5031,
      "name": "Shahrisabz",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.05,
        "lng": 66.8333
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 4970,
      "name": "Leuven",
      "names": {
        "es": "Lovaina",
        "fr": "Louvain",
        "zh": "比利时鲁汶",
        "ja": "ルーヴェン"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8775,
        "lng": 4.7044
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 5017,
      "name": "Gangtok",
      "names": {
        "zh": "甘托克",
        "ja": "ガントク",
        "th": "กังต็อก"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 27.33,
        "lng": 88.62
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 5004,
      "name": "Gutersloh",
      "names": {
        "es": "Gütersloh",
        "fr": "Gütersloh",
        "zh": "居特斯洛",
        "de": "Gütersloh",
        "pt": "Gütersloh"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.9,
        "lng": 8.3833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5023,
      "name": "Mairipora",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3189,
        "lng": -46.5869
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5027,
      "name": "Lamitan",
      "names": {
        "zh": "拉米坦"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.65,
        "lng": 122.1333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5039,
      "name": "Woodbridge",
      "names": {
        "zh": "伍德布里奇",
        "ja": "ウッドブリッジ",
        "th": "วูดบริดจ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5611,
        "lng": -74.2943
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5032,
      "name": "Talipao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.976,
        "lng": 121.1087
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 5037,
      "name": "Formosa",
      "names": {
        "zh": "福尔摩沙",
        "ja": "台湾",
        "th": "ฟอร์โมซา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.5369,
        "lng": -47.3339
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5041,
      "name": "Tracy",
      "names": {
        "zh": "特雷西",
        "ja": "トレイシー",
        "th": "เทรซี่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7269,
        "lng": -121.4522
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5042,
      "name": "Kamloops",
      "names": {
        "zh": "坎卢普斯",
        "ja": "カムループス",
        "th": "ลูปส์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 50.6761,
        "lng": -120.3408
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5016,
      "name": "Kaiserslautern",
      "names": {
        "zh": "凯泽斯劳滕",
        "th": "เลาเทิร์น"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4447,
        "lng": 7.7689
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5040,
      "name": "Bacabal",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.225,
        "lng": -44.78
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4885,
      "name": "Delft",
      "names": {
        "zh": "代尔夫特",
        "ja": "デルフト",
        "th": "เดลฟ์"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0119,
        "lng": 4.3594
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 4181,
      "name": "Zahle",
      "names": {
        "zh": "查赫勒"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 33.8439,
        "lng": 35.9072
      },
      "country": "Lebanon",
      "importance": 1
    },
    {
      "id": 8242,
      "name": "Da Nang",
      "names": {
        "zh": "岘港",
        "ja": "ダナン",
        "th": "ดานัง",
        "pt": "Deitar-se"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 16.0748,
        "lng": 108.224
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 10124,
      "name": "Ra\u0027s Gharib",
      "names": {
        "es": "Gharib de ra",
        "fr": "Ra\u0027s Ghareib",
        "zh": "ra的gharib",
        "it": "La Gharib di Ra",
        "ja": "RAのガリブ",
        "th": "เกียริม",
        "pt": "Gharib ra"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 28.3597,
        "lng": 33.0775
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 4946,
      "name": "Mumias",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.3333,
        "lng": 34.4833
      },
      "country": "Kenya",
      "importance": 3
    },
    {
      "id": 4917,
      "name": "Songea",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -10.6833,
        "lng": 35.65
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 10116,
      "name": "Pouytenga",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.25,
        "lng": -0.4333
      },
      "country": "Burkina Faso",
      "importance": 3
    },
    {
      "id": 10119,
      "name": "Debre Zeyit",
      "names": {
        "es": "Debe Zeyit",
        "de": "Dekre Zeyit"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 8.75,
        "lng": 38.9833
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 5044,
      "name": "Dunedin",
      "names": {
        "zh": "但尼丁",
        "ja": "ダニーデン",
        "th": "ดะนีดิน"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -45.8742,
        "lng": 170.5036
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 10122,
      "name": "Andria",
      "names": {
        "zh": "安德里亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.2317,
        "lng": 16.3083
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10130,
      "name": "Boca Raton",
      "names": {
        "zh": "博卡拉顿",
        "ja": "ボカラトン",
        "pt": "Raton Boca"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.3752,
        "lng": -80.108
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10112,
      "name": "Kalisz",
      "names": {
        "zh": "卡利什"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.757,
        "lng": 18.083
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 10121,
      "name": "Chikusei",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3072,
        "lng": 139.9831
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10110,
      "name": "Xiongzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.9786,
        "lng": 116.073
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10128,
      "name": "Urgut Shahri",
      "names": {
        "id": "Shahri urgut",
        "ja": "ウルガッツシャーリ"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.4007,
        "lng": 67.2607
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 10127,
      "name": "Fengning",
      "names": {
        "zh": "丰宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.2013,
        "lng": 116.6433
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10133,
      "name": "Lafayette",
      "names": {
        "zh": "老佛爷",
        "ja": "ラファイエット",
        "th": "ลาฟาแยต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9949,
        "lng": -105.0997
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10118,
      "name": "Kani",
      "names": {
        "zh": "卡尼",
        "ja": "可児"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4258,
        "lng": 137.0611
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10111,
      "name": "Udine",
      "names": {
        "zh": "乌迪内",
        "ja": "ウディネ",
        "th": "อูดีเน"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 46.0667,
        "lng": 13.2333
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10123,
      "name": "Tinsukia",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.4892,
        "lng": 95.36
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10136,
      "name": "Zelenodol\u0027sk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.85,
        "lng": 48.5167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10139,
      "name": "Arezzo",
      "names": {
        "zh": "阿雷佐",
        "ja": "アレッツォ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.4631,
        "lng": 11.8781
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10135,
      "name": "Tsuyama",
      "names": {
        "zh": "津山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0694,
        "lng": 134.0044
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10125,
      "name": "Alaminos",
      "names": {
        "zh": "阿拉米诺斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.1553,
        "lng": 119.9808
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10134,
      "name": "Leme",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.1858,
        "lng": -47.39
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10117,
      "name": "Xiegang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.9614,
        "lng": 114.1412
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10142,
      "name": "Lee\u0027s Summit",
      "names": {
        "es": "Cumbre de Lee",
        "fr": "Sommet de Lee\u0027s",
        "zh": "李的峰会",
        "de": "Lee\u0027s Gipfel",
        "it": "Summit di Lee",
        "id": "KTT Lee",
        "ja": "リーのサミット",
        "th": "การประชุมสุดยอดของลี",
        "pt": "Cúpula de Lee"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9172,
        "lng": -94.3816
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10113,
      "name": "Legnica",
      "names": {
        "zh": "莱格尼察"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.2101,
        "lng": 16.1619
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 10138,
      "name": "Wloclawek",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.65,
        "lng": 19.05
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 10114,
      "name": "Izumisano",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4067,
        "lng": 135.3275
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4908,
      "name": "Thika",
      "names": {
        "zh": "锡卡"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.0396,
        "lng": 37.09
      },
      "country": "Kenya",
      "importance": 3
    },
    {
      "id": 10137,
      "name": "Bislig",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.1833,
        "lng": 126.35
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10143,
      "name": "Abhar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.1467,
        "lng": 49.2181
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 5046,
      "name": "Viseu",
      "names": {
        "zh": "维塞乌"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6575,
        "lng": -7.9139
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 4880,
      "name": "Ginowan",
      "names": {
        "zh": "宜野湾"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 26.2817,
        "lng": 127.7783
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10115,
      "name": "Wakefield",
      "names": {
        "zh": "韦克菲尔德",
        "ja": "ウェークフィールド",
        "th": "เวก"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.68,
        "lng": -1.49
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10141,
      "name": "Sakata",
      "names": {
        "zh": "酒田",
        "ja": "酒田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.9144,
        "lng": 139.8364
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10145,
      "name": "Khamis Mushayt",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 18.3,
        "lng": 42.7333
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 10126,
      "name": "Pili",
      "names": {
        "zh": "菌毛",
        "ja": "線毛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5833,
        "lng": 123.3
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10147,
      "name": "Rio Rancho",
      "names": {
        "zh": "里约兰乔",
        "ja": "リオラチョ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2872,
        "lng": -106.6981
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10132,
      "name": "Hasilpur",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 29.6967,
        "lng": 72.5542
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10129,
      "name": "Deventer",
      "names": {
        "zh": "德芬特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.25,
        "lng": 6.2
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 10148,
      "name": "South Fulton",
      "names": {
        "fr": "Fuldat",
        "zh": "南富尔顿",
        "de": "Südfutteron",
        "id": "Fulton Selatan",
        "ja": "サウスフルトン",
        "th": "เซาท์ฟุลตัน",
        "pt": "Fultão sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6269,
        "lng": -84.5802
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4814,
      "name": "Trincomalee",
      "names": {
        "zh": "亭可马里",
        "ja": "トリンコマリー"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 8.5667,
        "lng": 81.2333
      },
      "country": "Sri Lanka",
      "importance": 1
    },
    {
      "id": 10150,
      "name": "Breves",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -1.6819,
        "lng": -50.48
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 4783,
      "name": "Moron",
      "names": {
        "es": "Morón",
        "fr": "Idiot",
        "zh": "笨蛋",
        "de": "Trottel",
        "id": "Bodoh",
        "ja": "バカ",
        "th": "คนโง่",
        "pt": "Imbecil"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.65,
        "lng": -58.6167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 5050,
      "name": "Kohima",
      "names": {
        "zh": "科希马"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 25.6667,
        "lng": 94.1194
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 10151,
      "name": "Beaverton",
      "names": {
        "zh": "比弗顿",
        "ja": "ビーバートン",
        "th": "บีเวอร์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.4779,
        "lng": -122.8168
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 4923,
      "name": "Nyeri",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.4167,
        "lng": 36.95
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 10144,
      "name": "Tobolsk",
      "names": {
        "zh": "托博尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.1953,
        "lng": 68.2581
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4924,
      "name": "Burzaco",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8167,
        "lng": -58.3667
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 4698,
      "name": "Espejo",
      "names": {
        "zh": "埃斯佩霍"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.5333,
        "lng": -70.7167
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 5053,
      "name": "Mingacevir",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.77,
        "lng": 47.0489
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 5045,
      "name": "Ramnicu Valcea",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 45.1047,
        "lng": 24.3756
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 10156,
      "name": "Kontagora",
      "names": {
        "zh": "孔塔戈拉"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.4522,
        "lng": 5.4794
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 10120,
      "name": "Roquetas de Mar",
      "names": {
        "ja": "ロケータスデマー"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.7642,
        "lng": -2.6147
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10153,
      "name": "Senahu",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4164,
        "lng": -89.8203
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 10157,
      "name": "Khambhat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.3131,
        "lng": 72.6194
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10146,
      "name": "Cottbus",
      "names": {
        "zh": "科特布斯",
        "ja": "コットブス",
        "th": "คอตต์บุส"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7606,
        "lng": 14.3342
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5055,
      "name": "Khanty-Mansiysk",
      "names": {
        "zh": "汉特 -  mansiysk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 61.0,
        "lng": 69.0
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 10158,
      "name": "Lawrence",
      "names": {
        "fr": "Laurent",
        "zh": "劳伦斯",
        "ja": "ローレンス",
        "th": "ลอเรนซ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9597,
        "lng": -95.2641
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10140,
      "name": "Ballarat",
      "names": {
        "zh": "巴拉瑞特",
        "th": "บัลลารัต"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -37.5608,
        "lng": 143.8475
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 10154,
      "name": "Itoshima",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5572,
        "lng": 130.1958
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10159,
      "name": "Zarate",
      "names": {
        "zh": "萨拉特"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.0833,
        "lng": -59.0333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10131,
      "name": "Oton",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6931,
        "lng": 122.4736
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10162,
      "name": "Itaituba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.2758,
        "lng": -55.9839
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5054,
      "name": "Chipata",
      "names": {
        "zh": "奇帕塔"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -13.6386,
        "lng": 32.6453
      },
      "country": "Zambia",
      "importance": 1
    },
    {
      "id": 5060,
      "name": "Bismarck",
      "names": {
        "zh": "俾斯麦",
        "ja": "ビスマルク",
        "th": "บิสมาร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.8144,
        "lng": -100.7694
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 10160,
      "name": "Saku",
      "names": {
        "zh": "佐久",
        "ja": "佐久"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.2489,
        "lng": 138.4769
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10161,
      "name": "M\u0027lang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.95,
        "lng": 124.8833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5036,
      "name": "Navapolatsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.5333,
        "lng": 28.6667
      },
      "country": "Belarus",
      "importance": 3
    },
    {
      "id": 4834,
      "name": "Roubaix",
      "names": {
        "zh": "鲁贝"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.6901,
        "lng": 3.1817
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 10167,
      "name": "Iseyin",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.9667,
        "lng": 3.6
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 10170,
      "name": "Chitose",
      "names": {
        "zh": "千岁",
        "ja": "千歳"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 42.8167,
        "lng": 141.65
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10152,
      "name": "Sarapul",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.4667,
        "lng": 53.8
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10149,
      "name": "Chia",
      "names": {
        "zh": "预订网",
        "ja": "チーア",
        "th": "เจีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8633,
        "lng": -74.0528
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5065,
      "name": "Catarman",
      "names": {
        "zh": "卡塔曼"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.45,
        "lng": 124.65
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 5063,
      "name": "Santiago de Compostela",
      "names": {
        "fr": "Santiago de Compostelle"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.8778,
        "lng": -8.5444
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 5070,
      "name": "Orem",
      "names": {
        "zh": "奥勒姆",
        "ja": "オレム",
        "th": "โอเรม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.2983,
        "lng": -111.6992
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5067,
      "name": "Janakpur",
      "names": {
        "zh": "贾纳克布尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 26.7122,
        "lng": 85.9217
      },
      "country": "Nepal",
      "importance": 1
    },
    {
      "id": 10178,
      "name": "Serov",
      "names": {
        "zh": "谢罗夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.6,
        "lng": 60.5667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 4882,
      "name": "Ciudad Guzman",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7,
        "lng": -103.4667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5038,
      "name": "Nanxicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.4976,
        "lng": 116.2435
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10179,
      "name": "Middletown",
      "names": {
        "zh": "米德尔敦",
        "ja": "ミドルタウン",
        "th": "มิดเดิลทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.5032,
        "lng": -84.366
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10166,
      "name": "Paulinia",
      "names": {
        "zh": "保利尼亚",
        "pt": "Paulínia"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7611,
        "lng": -47.1542
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10175,
      "name": "Kambar",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 27.5868,
        "lng": 68.001
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10180,
      "name": "Quilenda",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -10.6333,
        "lng": 14.3333
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 10173,
      "name": "Lugo",
      "names": {
        "zh": "卢戈",
        "ja": "ルーゴ",
        "th": "ลูโก"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.0167,
        "lng": -7.55
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 5000,
      "name": "Rades",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.7667,
        "lng": 10.2833
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 10176,
      "name": "Hammamet",
      "names": {
        "zh": "哈马马特",
        "ja": "ハマメット"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.4167,
        "lng": 10.6
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 10165,
      "name": "Votkinsk",
      "names": {
        "zh": "沃特金斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 57.05,
        "lng": 54.0
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5035,
      "name": "Lincoln",
      "names": {
        "zh": "林肯",
        "ja": "リンカーン",
        "th": "ลิงคอล์น"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.2283,
        "lng": -0.5389
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10183,
      "name": "Brantford",
      "names": {
        "zh": "布兰特福德",
        "ja": "ブラントフォード"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.1667,
        "lng": -80.25
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5075,
      "name": "Isulan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6333,
        "lng": 124.6
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 10182,
      "name": "Bafra",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.5682,
        "lng": 35.9069
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 4902,
      "name": "Tourcoing",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 50.7239,
        "lng": 3.1612
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5021,
      "name": "Konan",
      "names": {
        "zh": "湖南",
        "ja": "甲南"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3333,
        "lng": 136.8667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10163,
      "name": "Dongducheon",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.9133,
        "lng": 127.0633
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 10171,
      "name": "Attock Khurd",
      "names": {
        "zh": "khurd attock",
        "it": "Atock Khurd",
        "ja": "攻撃クルド"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.7667,
        "lng": 72.3667
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10184,
      "name": "Valenca",
      "names": {
        "es": "Valença",
        "fr": "Valença",
        "zh": "瓦伦萨",
        "pt": "Valença"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -13.37,
        "lng": -39.0728
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10168,
      "name": "Fanyang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.0847,
        "lng": 118.1942
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10185,
      "name": "Yacuiba",
      "names": {},
      "countryCode": "BO",
      "latLng": {
        "lat": -22.0139,
        "lng": -63.6778
      },
      "country": "Bolivia",
      "importance": 3
    },
    {
      "id": 10181,
      "name": "Tubarao",
      "names": {
        "zh": "图巴朗",
        "pt": "Tubarão"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.4669,
        "lng": -49.0069
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5072,
      "name": "Vi Thanh",
      "names": {
        "it": "Vi di thanh"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 9.7833,
        "lng": 105.4708
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 10187,
      "name": "Ituiutaba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -18.9689,
        "lng": -49.465
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10188,
      "name": "Tucurui",
      "names": {
        "zh": "图库鲁伊",
        "pt": "Tucuruí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.7678,
        "lng": -49.6728
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10192,
      "name": "Itacoatiara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.1428,
        "lng": -58.4439
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10164,
      "name": "Worcester",
      "names": {
        "zh": "伍斯特",
        "ja": "ウスター",
        "th": "เวอร์ซ"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.645,
        "lng": 19.4436
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 10193,
      "name": "Ukhta",
      "names": {
        "zh": "乌赫塔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 63.5667,
        "lng": 53.7
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10177,
      "name": "Munakata",
      "names": {
        "zh": "宗像"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.8056,
        "lng": 130.5406
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 4756,
      "name": "Moortebeek",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8547,
        "lng": 4.3386
      },
      "country": "Belgium",
      "importance": 3
    },
    {
      "id": 10190,
      "name": "Jamundi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.2608,
        "lng": -76.5394
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10169,
      "name": "Colon",
      "names": {
        "fr": "Côlon",
        "zh": "结肠",
        "id": "Usus besar",
        "ja": "コロン",
        "th": "ลำไส้ใหญ่",
        "pt": "Cólon"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.7167,
        "lng": -89.3667
      },
      "country": "El Salvador",
      "importance": 3
    },
    {
      "id": 5074,
      "name": "Tumbes",
      "names": {
        "zh": "通贝斯"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -3.5708,
        "lng": -80.4597
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 10155,
      "name": "Es Senia",
      "names": {
        "th": "เอสเซเนีย"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.6478,
        "lng": -0.6239
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 10196,
      "name": "Barra do Pirai",
      "names": {
        "zh": "巴拉做Pirai",
        "ja": "バラはピライをします"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.47,
        "lng": -43.8258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5086,
      "name": "Arauca",
      "names": {
        "zh": "阿劳卡"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0903,
        "lng": -70.7617
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 4862,
      "name": "Nanterre",
      "names": {
        "zh": "楠泰尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8988,
        "lng": 2.1969
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 5085,
      "name": "Bunia",
      "names": {
        "zh": "布尼亚"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 1.5604,
        "lng": 30.24
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 10191,
      "name": "Cesena",
      "names": {
        "zh": "切塞纳",
        "ja": "チェゼーナ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.1333,
        "lng": 12.2333
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 10200,
      "name": "Cambe",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2758,
        "lng": -51.2778
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10202,
      "name": "San Tan Valley",
      "names": {
        "fr": "San Tan Vallée",
        "zh": "圣吨谷",
        "de": "San Tan-Tal",
        "id": "Lembah San Tan",
        "ja": "サンタン渓谷",
        "th": "หุบเขาซานตาล",
        "pt": "Vale San Tan"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1879,
        "lng": -111.5472
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10201,
      "name": "Kazerun",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.6167,
        "lng": 51.65
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 10203,
      "name": "San Marcos",
      "names": {
        "zh": "圣马科斯",
        "ja": "サンマルコス",
        "th": "ซานมาร์กอส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.135,
        "lng": -117.1744
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10198,
      "name": "Iida",
      "names": {
        "zh": "饭田",
        "ja": "飯田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5147,
        "lng": 137.8219
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10195,
      "name": "Buin",
      "names": {
        "zh": "布因"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.7333,
        "lng": -70.75
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 10174,
      "name": "Witten",
      "names": {
        "zh": "维滕",
        "ja": "ウィッテン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4333,
        "lng": 7.3333
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 10172,
      "name": "Oldham",
      "names": {
        "zh": "奥尔德姆",
        "ja": "オールダム",
        "th": "ดัม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5444,
        "lng": -2.1169
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5062,
      "name": "Yen Bai",
      "names": {
        "zh": "日元白",
        "ja": "円のバイ",
        "th": "เยนใบ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.7325,
        "lng": 104.9064
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 10206,
      "name": "Iguatu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -6.3589,
        "lng": -39.2989
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5069,
      "name": "Half Way Tree",
      "names": {
        "es": "Árbol de mitad de camino",
        "fr": "Arbre à mi-chemin",
        "zh": "一半的树",
        "de": "Halber Wechselbaum",
        "it": "Albero a metà strada",
        "id": "Pohon setengah jalan",
        "ja": "半道ツリー",
        "th": "ต้นไม้ครึ่งทาง",
        "pt": "Metade da árvore"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.0106,
        "lng": -76.7975
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 10207,
      "name": "Wujiaqu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.2,
        "lng": 87.55
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10194,
      "name": "Tomohon",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 1.3244,
        "lng": 124.8225
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 10212,
      "name": "Sandy",
      "names": {
        "es": "Arena",
        "zh": "沙滩",
        "it": "Sabbioso",
        "ja": "砂の",
        "th": "ทราย",
        "pt": "Areia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.571,
        "lng": -111.8505
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10218,
      "name": "Mezhdurechensk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.6864,
        "lng": 88.0703
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10221,
      "name": "Federal Way",
      "names": {
        "es": "Manera federal",
        "fr": "Voie fédérale",
        "zh": "联邦方式",
        "de": "Föderal",
        "it": "Modo federale",
        "id": "Cara federal",
        "ja": "連邦の方法",
        "th": "วิธีการของรัฐบาลกลาง",
        "pt": "Federal"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.3091,
        "lng": -122.3358
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10220,
      "name": "Lorca",
      "names": {
        "zh": "洛尔卡",
        "ja": "ロルカ",
        "th": "ลอร์"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.6798,
        "lng": -1.6944
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10217,
      "name": "Kelo",
      "names": {
        "zh": "凯洛"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 9.3171,
        "lng": 15.8
      },
      "country": "Chad",
      "importance": 3
    },
    {
      "id": 5057,
      "name": "Maribor",
      "names": {
        "zh": "马里博尔",
        "ja": "マリボル",
        "th": "มาริบอร์"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.55,
        "lng": 15.6333
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 10189,
      "name": "Lysychansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.9169,
        "lng": 38.4306
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 10204,
      "name": "Escalante",
      "names": {
        "zh": "埃斯卡兰蒂"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8333,
        "lng": 123.5
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5049,
      "name": "Bayeux",
      "names": {
        "zh": "巴约",
        "ja": "バイユー"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.1333,
        "lng": -34.9333
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10216,
      "name": "Leninsk-Kuznetskiy",
      "names": {
        "zh": "列宁斯克-kuznetskiy"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.65,
        "lng": 86.1667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10219,
      "name": "Erechim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -27.6339,
        "lng": -52.2739
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10186,
      "name": "Hanau",
      "names": {
        "zh": "哈瑙",
        "ja": "ハーナウ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1328,
        "lng": 8.9169
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10226,
      "name": "Muzaffarabad",
      "names": {
        "zh": "穆扎法拉巴德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 34.37,
        "lng": 73.4711
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10227,
      "name": "Greece",
      "names": {
        "es": "Grecia",
        "fr": "Grèce",
        "zh": "希腊",
        "de": "Griechenland",
        "it": "Grecia",
        "id": "Yunani",
        "ja": "ギリシャ",
        "th": "กรีซ",
        "pt": "Grécia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.2461,
        "lng": -77.6989
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10228,
      "name": "Mandeville",
      "names": {
        "zh": "曼德维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.3751,
        "lng": -90.0904
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10208,
      "name": "Aurangabad",
      "names": {
        "zh": "奥兰加巴德",
        "th": "ออรันกาบัด"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.7704,
        "lng": 84.38
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10197,
      "name": "Balayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9333,
        "lng": 120.7333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10225,
      "name": "Longkeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.0341,
        "lng": 112.0391
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10215,
      "name": "Longquan",
      "names": {
        "zh": "龙泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.3703,
        "lng": 113.7483
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10231,
      "name": "Itaperuna",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.205,
        "lng": -41.8878
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10213,
      "name": "Vanderbijlpark",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.6992,
        "lng": 27.8356
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 5095,
      "name": "Schwerin",
      "names": {
        "zh": "什未林",
        "it": "Di Schwerin",
        "ja": "シュヴェリーン",
        "th": "ชเวริน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.6333,
        "lng": 11.4167
      },
      "country": "Germany",
      "importance": 1
    },
    {
      "id": 5102,
      "name": "Rio Gallegos",
      "names": {
        "zh": "里约加里戈斯",
        "it": "Rio galegos",
        "id": "Rio gurlegos",
        "ja": "リオガレゴス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -51.6333,
        "lng": -69.2333
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 4878,
      "name": "Caseros",
      "names": {
        "zh": "卡塞罗斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6167,
        "lng": -58.5333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10199,
      "name": "Caieiras",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3644,
        "lng": -46.7408
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5104,
      "name": "Tataouine",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 32.9333,
        "lng": 10.45
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 10234,
      "name": "Hesperia",
      "names": {
        "zh": "hesperia酒店",
        "ja": "エスペリア"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.3975,
        "lng": -117.3147
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10235,
      "name": "Brockton",
      "names": {
        "zh": "布罗克顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0821,
        "lng": -71.0242
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5048,
      "name": "Dhulian",
      "names": {
        "zh": "图利扬"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.68,
        "lng": 87.97
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10211,
      "name": "Arifwala",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.2917,
        "lng": 73.0667
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10232,
      "name": "Jinbi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.7356,
        "lng": 101.3239
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10214,
      "name": "Omura",
      "names": {
        "zh": "大村"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.9214,
        "lng": 129.9539
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5106,
      "name": "Compton",
      "names": {
        "zh": "康普顿",
        "ja": "コンプトン",
        "th": "คอมป์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.893,
        "lng": -118.2275
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10223,
      "name": "Bendigo",
      "names": {
        "zh": "本迪戈"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -36.75,
        "lng": 144.2667
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 5043,
      "name": "Rayleigh",
      "names": {
        "zh": "瑞利",
        "ja": "レイリー",
        "th": "เรย์ลี"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5864,
        "lng": 0.6049
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5097,
      "name": "Szekesfehervar",
      "names": {
        "fr": "Székesfehérvár",
        "de": "Székesfehérvár",
        "pt": "Székesfehérvár"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2,
        "lng": 18.4167
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 10205,
      "name": "Japeri",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.6428,
        "lng": -43.6528
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10224,
      "name": "Kamisu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.89,
        "lng": 140.6647
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10240,
      "name": "Lecce",
      "names": {
        "zh": "莱切",
        "ja": "レッチェ",
        "th": "เลกเซ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.352,
        "lng": 18.1691
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10233,
      "name": "Xiluodu",
      "names": {
        "zh": "溪洛渡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.236,
        "lng": 103.6301
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10241,
      "name": "Caceres",
      "names": {
        "es": "Cáceres",
        "zh": "卡塞雷斯",
        "id": "Cáceres",
        "ja": "カセレス",
        "th": "คาเซเรส",
        "pt": "Cáceres"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.4833,
        "lng": -6.3667
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 10246,
      "name": "Sarov",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.9333,
        "lng": 43.3167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10248,
      "name": "Riverview",
      "names": {
        "zh": "江景",
        "th": "ริเวอร์วิว"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.8227,
        "lng": -82.3023
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10250,
      "name": "Fishers",
      "names": {
        "es": "Los pescadores",
        "fr": "Les pêcheurs",
        "zh": "渔民",
        "de": "Fischer",
        "id": "Nelayan",
        "ja": "フィッシャーズ",
        "th": "ชาวประมง",
        "pt": "Pescadores"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9589,
        "lng": -85.9661
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10230,
      "name": "Mons",
      "names": {
        "zh": "蒙斯",
        "ja": "モンス",
        "th": "มอนส์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.455,
        "lng": 3.952
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 4977,
      "name": "Oued Zem",
      "names": {
        "fr": "Zem Oued Zem",
        "zh": "zem",
        "id": "Zem oued",
        "ja": "ZEM",
        "pt": "Zem oued"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.86,
        "lng": -6.56
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10237,
      "name": "Boli",
      "names": {
        "zh": "玻璃"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.7564,
        "lng": 130.5759
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10243,
      "name": "Bogo",
      "names": {
        "zh": "博戈",
        "de": "Bogø"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 10.7361,
        "lng": 14.6108
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 10242,
      "name": "Guaiba",
      "names": {
        "zh": "瓜伊巴",
        "pt": "Guaíba"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -30.1139,
        "lng": -51.325
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10209,
      "name": "Chakdaha",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.08,
        "lng": 88.52
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5079,
      "name": "Xirdalan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.4486,
        "lng": 49.7564
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 10239,
      "name": "Shimada",
      "names": {
        "zh": "岛田",
        "ja": "島田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8364,
        "lng": 138.1761
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5111,
      "name": "Sunrise",
      "names": {
        "es": "Amanecer",
        "fr": "Lever du soleil",
        "zh": "日出",
        "de": "Sonnenaufgang",
        "it": "Alba",
        "ja": "サンライズ",
        "th": "พระอาทิตย์ขึ้น",
        "pt": "Nascer do sol"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.1547,
        "lng": -80.2997
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10222,
      "name": "Dovzhansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0846,
        "lng": 39.6516
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 10244,
      "name": "Balamban",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.4667,
        "lng": 123.7833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10255,
      "name": "Bowling Green",
      "names": {
        "es": "De boliche",
        "fr": "Vert bowling",
        "zh": "保龄球绿色",
        "de": "Barmherzig",
        "it": "Bowling verde",
        "id": "Hijau bowling",
        "ja": "ボウリンググリーン",
        "th": "โบว์ลิ่งสีเขียว",
        "pt": "Boliche verde"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.9719,
        "lng": -86.4373
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10251,
      "name": "Jinshan",
      "names": {
        "zh": "金山",
        "ja": "金山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.1496,
        "lng": 102.0742
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10247,
      "name": "Rubio",
      "names": {
        "zh": "卢比奥"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 7.7,
        "lng": -72.35
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 10249,
      "name": "Mianwali",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.5853,
        "lng": 71.5436
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 10258,
      "name": "Biu",
      "names": {
        "zh": "比乌"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.6204,
        "lng": 12.19
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 10245,
      "name": "Kulob",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 37.9119,
        "lng": 69.7808
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 10254,
      "name": "Nahiyat al Karmah",
      "names": {
        "id": "Nahiyat al karma"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.3997,
        "lng": 43.9089
      },
      "country": "Iraq",
      "importance": 3
    },
    {
      "id": 5084,
      "name": "Sibolga",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 1.7425,
        "lng": 98.7792
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 10236,
      "name": "Pesaro",
      "names": {
        "zh": "佩萨罗",
        "ja": "ペーザロ",
        "th": "เปซาโร"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.9102,
        "lng": 12.9133
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 5051,
      "name": "Hemel Hempstead",
      "names": {
        "ja": "ヘメルヘムステッド"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.7526,
        "lng": -0.4692
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 783,
      "name": "Mbabane",
      "names": {
        "zh": "姆巴巴纳",
        "th": "อัมบาบาเน"
      },
      "countryCode": "SZ",
      "latLng": {
        "lat": -26.3208,
        "lng": 31.1617
      },
      "country": "Swaziland",
      "importance": 0
    },
    {
      "id": 5068,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.4667,
        "lng": -6.2
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10210,
      "name": "Las Rozas de Madrid",
      "names": {
        "ja": "ラスロザスデマドリード",
        "th": "las rozas de มาดริด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.4917,
        "lng": -3.8733
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10259,
      "name": "Lagarto",
      "names": {
        "zh": "拉加尔图"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.9169,
        "lng": -37.65
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5066,
      "name": "Bath",
      "names": {
        "es": "Baño",
        "fr": "Une baignoire",
        "zh": "浴",
        "de": "Bad",
        "it": "Vasca da bagno",
        "id": "Mandi",
        "ja": "バスタブ",
        "th": "อาบน้ำ",
        "pt": "Banho de banheira"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.38,
        "lng": -2.36
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10264,
      "name": "Roswell",
      "names": {
        "zh": "罗斯韦尔",
        "ja": "ロズウェル",
        "th": "รอสเวล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0391,
        "lng": -84.3513
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10265,
      "name": "Menifee",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.6909,
        "lng": -117.1849
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10260,
      "name": "Lopez",
      "names": {
        "es": "López",
        "zh": "·洛佩兹",
        "ja": "ロペス",
        "th": "โลเปซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.884,
        "lng": 122.2604
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4952,
      "name": "Vitry-sur-Seine",
      "names": {
        "es": "Vitry-sur-Sena",
        "zh": "维特里塞纳河畔",
        "ja": "vitryシュルセーヌ",
        "th": "vitry-ซูร์แม่น้ำเซน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7875,
        "lng": 2.3928
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 10253,
      "name": "Solikamsk",
      "names": {
        "zh": "索利卡姆斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.6333,
        "lng": 56.7667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10262,
      "name": "Kanuma",
      "names": {
        "zh": "鹿沼"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.5672,
        "lng": 139.745
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10267,
      "name": "Plantation",
      "names": {
        "es": "Plantación",
        "zh": "人工林",
        "it": "Piantagione",
        "id": "Perkebunan",
        "ja": "プランテーション",
        "th": "สวน",
        "pt": "Da plantação"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.126,
        "lng": -80.2617
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10268,
      "name": "Dover",
      "names": {
        "zh": "多佛",
        "ja": "ドーバー",
        "th": "โดเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.1887,
        "lng": -70.8845
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5073,
      "name": "San Joaquin",
      "names": {
        "es": "San Joaquín",
        "zh": "圣乔金",
        "ja": "サンジョアキン",
        "th": "ซานโจควิน"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.5,
        "lng": -70.6167
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 10252,
      "name": "Gerona",
      "names": {
        "fr": "Gérone",
        "zh": "赫罗纳"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6,
        "lng": 120.6
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10256,
      "name": "Barletta",
      "names": {
        "zh": "巴列塔"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.3167,
        "lng": 16.2833
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 5117,
      "name": "Quincy",
      "names": {
        "zh": "昆西",
        "ja": "クインシー",
        "th": "ควินซี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2516,
        "lng": -71.0183
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10269,
      "name": "Gatchina",
      "names": {
        "zh": "加特契纳"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.5667,
        "lng": 30.1333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10272,
      "name": "Bayan Hot",
      "names": {
        "es": "Bayan caliente",
        "fr": "Bayan chaud",
        "zh": "巴兰热",
        "de": "Bayan heiß",
        "it": "Bayan caldo",
        "ja": "バヤンホット",
        "th": "เพศร้อน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.8556,
        "lng": 105.7001
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 4875,
      "name": "Villa Luzuriaga",
      "names": {
        "id": "Villa Luzuriga",
        "ja": "ヴィラルズリアガ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6667,
        "lng": -58.5833
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 10277,
      "name": "Portsmouth",
      "names": {
        "zh": "朴茨茅斯",
        "ja": "ポーツマス",
        "th": "พอร์ทสมัธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.8468,
        "lng": -76.354
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5090,
      "name": "Marmagao",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.402,
        "lng": 73.8
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10278,
      "name": "Chino",
      "names": {
        "zh": "奇诺",
        "ja": "チノ",
        "th": "สีกากี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9836,
        "lng": -117.6654
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10266,
      "name": "Grudziadz",
      "names": {
        "zh": "格鲁琼兹"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.4875,
        "lng": 18.755
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 10261,
      "name": "Jaworzno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2,
        "lng": 19.275
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 5119,
      "name": "Lynn",
      "names": {
        "zh": "琳",
        "th": "ลินน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4779,
        "lng": -70.9663
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10280,
      "name": "Cape Breton",
      "names": {
        "zh": "披风布列顿",
        "de": "Kaps",
        "id": "Jago",
        "ja": "ケープブルトン",
        "th": "เคปเบรน",
        "pt": "Capa bretão"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.1389,
        "lng": -60.1931
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10274,
      "name": "Shibata",
      "names": {
        "zh": "柴田",
        "ja": "柴田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.95,
        "lng": 139.3333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5101,
      "name": "Ceske Budejovice",
      "names": {},
      "countryCode": "CZ",
      "latLng": {
        "lat": 48.9747,
        "lng": 14.4747
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 5077,
      "name": "Petapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4962,
        "lng": -90.5525
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 10282,
      "name": "Manacapuru",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.3,
        "lng": -60.6206
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10263,
      "name": "Gera",
      "names": {
        "zh": "格拉",
        "ja": "ゲーラ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8782,
        "lng": 12.0824
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5047,
      "name": "Ashiya",
      "names": {
        "zh": "芦屋"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7278,
        "lng": 135.3033
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5089,
      "name": "Yishui",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7904,
        "lng": 118.62
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10238,
      "name": "Yi Xian",
      "names": {
        "zh": "易贤",
        "ja": "西安",
        "th": "ยี่เซียน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.3444,
        "lng": 115.4954
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10285,
      "name": "Edmond",
      "names": {
        "zh": "爱德蒙",
        "ja": "エドモンド",
        "th": "เอดมองด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.6689,
        "lng": -97.4159
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10288,
      "name": "Hanford",
      "names": {
        "zh": "汉福德"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3274,
        "lng": -119.6549
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5064,
      "name": "Malindi",
      "names": {
        "zh": "马林迪"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -3.21,
        "lng": 40.1
      },
      "country": "Kenya",
      "importance": 3
    },
    {
      "id": 10229,
      "name": "San Cugat del Valles",
      "names": {
        "zh": "圣Cugat del Valles",
        "ja": "サンクカットデルバール"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.4735,
        "lng": 2.0852
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5061,
      "name": "Herzliyya",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 32.1556,
        "lng": 34.8422
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 10273,
      "name": "Alessandria",
      "names": {
        "zh": "亚历山德里亚",
        "ja": "アレッサンドリア",
        "pt": "Alexandria"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.9133,
        "lng": 8.62
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10284,
      "name": "Monkayo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.8167,
        "lng": 126.05
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 10290,
      "name": "Dearborn",
      "names": {
        "zh": "迪尔伯恩",
        "ja": "ディアボーン",
        "th": "เดียร์บอร์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3127,
        "lng": -83.2129
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10281,
      "name": "Azumino",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3039,
        "lng": 137.9056
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10257,
      "name": "Valongo",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1833,
        "lng": -8.5
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 10289,
      "name": "Itauna",
      "names": {
        "pt": "Itaúna"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.075,
        "lng": -44.5758
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5099,
      "name": "Lingtang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6032,
        "lng": 113.074
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10287,
      "name": "Kabacan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.1167,
        "lng": 124.8167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10295,
      "name": "Vilhena",
      "names": {
        "zh": "维列纳"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.7406,
        "lng": -60.1458
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5093,
      "name": "Zifta",
      "names": {
        "zh": "济夫塔"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 30.7119,
        "lng": 31.2394
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 5125,
      "name": "Al Fujayrah",
      "names": {
        "ja": "アルフジャイラ"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.1308,
        "lng": 56.3347
      },
      "country": "United Arab Emirates",
      "importance": 1
    },
    {
      "id": 10291,
      "name": "Sanjo",
      "names": {
        "zh": "三条"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.6368,
        "lng": 138.9617
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10293,
      "name": "Yunnanyi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.3916,
        "lng": 100.6846
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10299,
      "name": "Livonia",
      "names": {
        "zh": "利沃尼亚",
        "ja": "リボニア",
        "th": "ลิโวเนีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3972,
        "lng": -83.3733
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10297,
      "name": "Mikhaylovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.1283,
        "lng": 42.0256
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10294,
      "name": "Voskresensk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.3167,
        "lng": 38.6833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5107,
      "name": "Esslingen",
      "names": {
        "zh": "埃斯林根",
        "ja": "エスリンゲン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7406,
        "lng": 9.3108
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10271,
      "name": "Daanbantayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.3333,
        "lng": 124.0167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5105,
      "name": "Ludwigsburg",
      "names": {
        "zh": "路德维希堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8975,
        "lng": 9.1919
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5133,
      "name": "South Gate",
      "names": {
        "es": "Puerta sur",
        "fr": "Porte sud",
        "zh": "南门",
        "de": "Südtor",
        "it": "Cancello del sud",
        "id": "Gerbang selatan",
        "ja": "南門",
        "th": "ประตูใต้",
        "pt": "Portão sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9448,
        "lng": -118.1926
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10303,
      "name": "Trelew",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -43.2533,
        "lng": -65.3094
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 10270,
      "name": "Michurinsk",
      "names": {
        "zh": "米丘林斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.8922,
        "lng": 40.4928
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10302,
      "name": "La Spezia",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 44.108,
        "lng": 9.8289
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10283,
      "name": "Wangjia",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6218,
        "lng": 120.7212
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5059,
      "name": "Yashio",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8228,
        "lng": 139.8392
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10309,
      "name": "Vineland",
      "names": {
        "zh": "上传时间："
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.4653,
        "lng": -74.9981
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5100,
      "name": "Yotsukaido",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.67,
        "lng": 140.1683
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10300,
      "name": "Piraquara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -25.4419,
        "lng": -49.0628
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10275,
      "name": "Santa Cruz Xoxocotlan",
      "names": {
        "zh": "圣克鲁斯Xoxocotlan",
        "ja": "サンタクルーズXoxocotlan",
        "th": "ซานตาครูซ xoxocotlan",
        "pt": "Santa Cruz xoxocótcia"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0264,
        "lng": -96.7333
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 10286,
      "name": "Lemery",
      "names": {
        "zh": "莱梅里"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9167,
        "lng": 120.8833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10296,
      "name": "Indanan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.0,
        "lng": 120.9667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5103,
      "name": "Fenggang",
      "names": {
        "zh": "凤岗"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6283,
        "lng": 116.5718
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10308,
      "name": "Bafang",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 5.1704,
        "lng": 10.18
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 10313,
      "name": "Florence",
      "names": {
        "es": "Florencia",
        "zh": "佛罗伦萨",
        "de": "Florenz",
        "it": "Firenze",
        "ja": "フィレンツェ",
        "th": "ฟลอเรนซ์",
        "pt": "Florença"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1781,
        "lng": -79.7877
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5058,
      "name": "Galle",
      "names": {
        "zh": "加勒",
        "th": "กอลล์"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 6.0395,
        "lng": 80.2194
      },
      "country": "Sri Lanka",
      "importance": 1
    },
    {
      "id": 10279,
      "name": "Kwekwe",
      "names": {
        "zh": "奎奎"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.9167,
        "lng": 29.9833
      },
      "country": "Zimbabwe",
      "importance": 3
    },
    {
      "id": 10314,
      "name": "Portsmouth",
      "names": {
        "zh": "朴茨茅斯",
        "ja": "ポーツマス",
        "th": "พอร์ทสมัธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.058,
        "lng": -70.7826
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 784,
      "name": "Noumea",
      "names": {
        "fr": "Nouméa",
        "zh": "努美阿",
        "ja": "ヌメア",
        "th": "นูเมอา"
      },
      "countryCode": "NC",
      "latLng": {
        "lat": -22.2625,
        "lng": 166.4443
      },
      "country": "New Caledonia",
      "importance": 0
    },
    {
      "id": 10276,
      "name": "Glazov",
      "names": {
        "zh": "格拉佐夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.1333,
        "lng": 52.65
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10315,
      "name": "Slidell",
      "names": {
        "zh": "斯莱德尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.2881,
        "lng": -89.7826
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10317,
      "name": "Lawton",
      "names": {
        "zh": "劳顿",
        "th": "ลอว์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.6176,
        "lng": -98.4203
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5138,
      "name": "Kirkland",
      "names": {
        "zh": "柯克兰",
        "ja": "カークランド",
        "th": "เคิร์กแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6974,
        "lng": -122.2054
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5078,
      "name": "Sidi Slimane",
      "names": {
        "it": "Sidi slipane"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 34.26,
        "lng": -5.93
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 5088,
      "name": "La Marsa",
      "names": {
        "ja": "ラマルサ"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8764,
        "lng": 10.3253
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 5056,
      "name": "Ciudad General Belgrano",
      "names": {
        "id": "Ciudad Jenderal Belgrano"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7261,
        "lng": -58.5289
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 10319,
      "name": "Rafaela",
      "names": {
        "zh": "拉斐拉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.2667,
        "lng": -61.4833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10316,
      "name": "Wukari",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.8704,
        "lng": 9.78
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 10324,
      "name": "Missoula",
      "names": {
        "zh": "米苏拉",
        "ja": "ミズーラ",
        "th": "มิสซูลา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.875,
        "lng": -114.0214
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10322,
      "name": "Itumbiara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -18.42,
        "lng": -49.2178
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10318,
      "name": "Palimbang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.2167,
        "lng": 124.2
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5108,
      "name": "Nisshin",
      "names": {
        "zh": "日进",
        "ja": "日清"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1333,
        "lng": 137.0333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10323,
      "name": "Hanamaki Onsen",
      "names": {
        "id": "Onsen hanamaki",
        "ja": "花咲温泉"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 39.3886,
        "lng": 141.1167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5087,
      "name": "La Reina",
      "names": {
        "zh": "拉雷纳",
        "ja": "ラリーナ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.45,
        "lng": -70.55
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 5141,
      "name": "Magadan",
      "names": {
        "zh": "马加丹",
        "ja": "マガダン",
        "th": "มากาดาน"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.5667,
        "lng": 150.8
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 10326,
      "name": "Auburn",
      "names": {
        "zh": "奥本",
        "ja": "オーバーン",
        "th": "สีน้ำตาลแดง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.6087,
        "lng": -85.4899
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5112,
      "name": "Wimbledon",
      "names": {
        "zh": "温布尔登",
        "ja": "ウィンブルドン",
        "th": "วิมเบิลดัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.422,
        "lng": -0.208
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10332,
      "name": "Lethbridge",
      "names": {
        "zh": "莱斯布里奇",
        "th": "เลทบริดจ์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.6942,
        "lng": -112.8328
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10327,
      "name": "Itapeva",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.9819,
        "lng": -48.8758
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10330,
      "name": "Sampit",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.5329,
        "lng": 112.95
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 10325,
      "name": "Laiyuan",
      "names": {
        "zh": "涞源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.3515,
        "lng": 114.6853
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5131,
      "name": "Hradec Kralove",
      "names": {},
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.2092,
        "lng": 15.8319
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 10328,
      "name": "Foumban",
      "names": {
        "zh": "丰班"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 5.7167,
        "lng": 10.9167
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 10306,
      "name": "Iserlohn",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3833,
        "lng": 7.6667
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 10333,
      "name": "Rapid City",
      "names": {
        "es": "Ciudad rápida",
        "fr": "Ville rapide",
        "zh": "快速城市",
        "de": "Schnelle Stadt",
        "it": "Città rapida",
        "id": "Kota cepat",
        "ja": "ラピッドシティ",
        "th": "เมืองที่รวดเร็ว",
        "pt": "Cidade rápida"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0716,
        "lng": -103.2204
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5120,
      "name": "Drobeta-Turnu Severin",
      "names": {
        "es": "DrobaTa-Turnu severin",
        "fr": "Drobeta-thermu Severin",
        "de": "DroboTa-Turnu Severin"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.6361,
        "lng": 22.6556
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 5071,
      "name": "Inagi",
      "names": {
        "zh": "稻城"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6381,
        "lng": 139.5047
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5114,
      "name": "Mtwara",
      "names": {
        "zh": "姆特瓦拉"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -10.2736,
        "lng": 40.1828
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 10334,
      "name": "Sablayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.837,
        "lng": 120.7829
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10331,
      "name": "Naju",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 35.0283,
        "lng": 126.7175
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 5110,
      "name": "Obu",
      "names": {
        "zh": "车载单元"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0167,
        "lng": 136.9667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10341,
      "name": "Terre Haute",
      "names": {
        "ja": "テレホート"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.4654,
        "lng": -87.3763
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10292,
      "name": "Higashi-Matsuyama",
      "names": {
        "zh": "东-松山",
        "ja": "東-松山",
        "th": "Higashi-มัตซุยา"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0422,
        "lng": 139.4
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10304,
      "name": "Bedford",
      "names": {
        "zh": "贝德福德",
        "ja": "ベッドフォード",
        "th": "เบดฟอร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.135,
        "lng": -0.47
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10342,
      "name": "Saint-Jean-sur-Richelieu",
      "names": {
        "zh": "圣让畔黎塞留",
        "ja": "サン・ジャン・シュルリシュリュー",
        "th": "เซนต์ฌองซูร์ Richelieu"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.3167,
        "lng": -73.2667
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5098,
      "name": "Errachidia",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 31.9319,
        "lng": -4.4244
      },
      "country": "Morocco",
      "importance": 1
    },
    {
      "id": 5083,
      "name": "Darlington",
      "names": {
        "zh": "达林顿",
        "ja": "ダーリントン",
        "th": "ดาร์ลิงตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.527,
        "lng": -1.5526
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10337,
      "name": "Kitakami",
      "names": {
        "zh": "北上"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 39.2867,
        "lng": 141.1131
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10344,
      "name": "Toms River",
      "names": {
        "fr": "Rivière Toms",
        "zh": "汤姆斯河",
        "id": "Sungai Toms",
        "ja": "トムズ川",
        "th": "แม่น้ำทอมส์",
        "pt": "Rio de toms"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9895,
        "lng": -74.1654
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5034,
      "name": "Creteil",
      "names": {
        "es": "Créteil",
        "fr": "Créteil",
        "zh": "克雷泰伊"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7911,
        "lng": 2.4628
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 10307,
      "name": "Contai",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.78,
        "lng": 87.75
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10343,
      "name": "Lavras",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.245,
        "lng": -45.0
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10336,
      "name": "Yumbo",
      "names": {
        "zh": "云博",
        "ja": "ユンボ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.5778,
        "lng": -76.4944
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10298,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0031,
        "lng": 120.4008
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 4944,
      "name": "Coatepec",
      "names": {
        "zh": "有科特佩"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4553,
        "lng": -96.9586
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5124,
      "name": "Suceava",
      "names": {
        "zh": "苏恰瓦"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.6514,
        "lng": 26.2556
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 10347,
      "name": "Suffolk",
      "names": {
        "zh": "萨福克",
        "ja": "サフォーク",
        "th": "ซัฟโฟล์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.6953,
        "lng": -76.6398
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10345,
      "name": "Ocozocoautla de Espinosa",
      "names": {
        "de": "OCOCOCOAUTLA DE Espinosa",
        "th": "ocozocoaautla de espinosa"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.75,
        "lng": -93.3667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10350,
      "name": "Clarington",
      "names": {
        "zh": "克拉灵顿"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.935,
        "lng": -78.6083
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10338,
      "name": "Acajutla",
      "names": {
        "zh": "阿卡胡特拉"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.5928,
        "lng": -89.8275
      },
      "country": "El Salvador",
      "importance": 3
    },
    {
      "id": 5139,
      "name": "Usti nad Labem",
      "names": {
        "th": "outi nad labem"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.6592,
        "lng": 14.0417
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 4922,
      "name": "Hove",
      "names": {
        "zh": "利物浦",
        "ja": "ホーブ",
        "th": "ทำให้พองขึ้น"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.8352,
        "lng": -0.1758
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10321,
      "name": "Kilosa",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.8396,
        "lng": 36.99
      },
      "country": "Tanzania",
      "importance": 3
    },
    {
      "id": 10352,
      "name": "Blacksburg",
      "names": {
        "zh": "布莱克斯堡",
        "th": "แบล็กส์เบิร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.23,
        "lng": -80.4279
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5142,
      "name": "Bender",
      "names": {
        "zh": "本德",
        "ja": "ベンダー",
        "th": "ดัด"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 46.8333,
        "lng": 29.4833
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 10351,
      "name": "Satsumasendai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 31.8133,
        "lng": 130.3042
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5094,
      "name": "Kakamega",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.2833,
        "lng": 34.75
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 10355,
      "name": "Pickering",
      "names": {
        "zh": "皮克林",
        "ja": "ピカリング",
        "th": "พิกเคอริง"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.8354,
        "lng": -79.089
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5140,
      "name": "Pardubice",
      "names": {
        "zh": "帕尔杜比采"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.0386,
        "lng": 15.7792
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 10311,
      "name": "Avignon",
      "names": {
        "es": "Aviñón",
        "zh": "阿维尼翁",
        "it": "Avignone",
        "ja": "アビニョン",
        "th": "อาวิญง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.95,
        "lng": 4.8075
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 10301,
      "name": "Hadera",
      "names": {
        "zh": "哈代拉"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.45,
        "lng": 34.9167
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 10356,
      "name": "Southport",
      "names": {
        "zh": "绍斯波特",
        "ja": "サウスポート",
        "th": "เซาท์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.6475,
        "lng": -3.0053
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10357,
      "name": "Mount Pleasant",
      "names": {
        "es": "Agradable",
        "fr": "Monture agréable",
        "zh": "坐着愉快",
        "de": "Montieren",
        "it": "Montare piacevole",
        "id": "Mount menyenangkan",
        "ja": "マウントプレザント",
        "th": "เมาท์ที่น่าพอใจ",
        "pt": "Monte agradável"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8538,
        "lng": -79.8204
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5127,
      "name": "Amstelveen",
      "names": {
        "zh": "阿姆斯特尔芬"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3019,
        "lng": 4.8581
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 10305,
      "name": "Linquan",
      "names": {
        "zh": "临泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9513,
        "lng": 110.9877
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10335,
      "name": "Grahamstown",
      "names": {
        "zh": "格雷厄姆斯敦"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.2996,
        "lng": 26.52
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 10359,
      "name": "Weiyuan",
      "names": {
        "zh": "威远"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.5025,
        "lng": 100.7075
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10310,
      "name": "Helmond",
      "names": {
        "zh": "海尔蒙德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4797,
        "lng": 5.6556
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 10366,
      "name": "Yao",
      "names": {
        "zh": "姚明",
        "ja": "ヤオ",
        "th": "ยาว"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 12.8508,
        "lng": 17.5608
      },
      "country": "Chad",
      "importance": 3
    },
    {
      "id": 10358,
      "name": "Mogi Mirim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4319,
        "lng": -46.9578
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10312,
      "name": "Samalut",
      "names": {
        "zh": "塞马卢特"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 28.3122,
        "lng": 30.71
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 10349,
      "name": "Oss",
      "names": {
        "zh": "操作系统",
        "ja": "OSSの",
        "th": "โอเอส"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7667,
        "lng": 5.5167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 10365,
      "name": "Talara",
      "names": {
        "zh": "塔拉拉"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -4.5833,
        "lng": -81.2667
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 10320,
      "name": "Velikiye Luki",
      "names": {
        "ja": "ヴェリキヤルキ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.35,
        "lng": 30.5167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5128,
      "name": "Tartu",
      "names": {
        "zh": "塔尔图",
        "ja": "タルトゥ"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.38,
        "lng": 26.7225
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 10371,
      "name": "Carson",
      "names": {
        "zh": "卡森",
        "ja": "カーソン",
        "th": "คาร์สัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8374,
        "lng": -118.2559
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10373,
      "name": "Greenburgh",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 41.033,
        "lng": -73.8413
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10362,
      "name": "Azua",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.46,
        "lng": -70.74
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 10363,
      "name": "Salaman",
      "names": {
        "zh": "萨拉曼"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6333,
        "lng": 124.0667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10374,
      "name": "Sao Felix do Xingu",
      "names": {
        "es": "SAO FELIX DO XINGUS",
        "zh": "Sao Felix做Xingu",
        "it": "Sao Felix fa Xingu",
        "th": "เซาเฟลิกซ์ทำซิงู"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.645,
        "lng": -51.995
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5092,
      "name": "San Francisco Solano",
      "names": {
        "zh": "旧金山索拉诺",
        "ja": "サンフランシスコソラノ",
        "th": "ซานฟรานซิสโก Solano",
        "pt": "São Francisco Solano"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7667,
        "lng": -58.3167
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 5076,
      "name": "Tecoman",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9089,
        "lng": -103.8747
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10346,
      "name": "Mosquera",
      "names": {
        "zh": "莫斯克拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7078,
        "lng": -74.2328
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10368,
      "name": "Votuporanga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.4228,
        "lng": -49.9728
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10375,
      "name": "Caceres",
      "names": {
        "es": "Cáceres",
        "zh": "卡塞雷斯",
        "id": "Cáceres",
        "ja": "カセレス",
        "th": "คาเซเรส",
        "pt": "Cáceres"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.0711,
        "lng": -57.6789
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10348,
      "name": "Kendu Bay",
      "names": {
        "fr": "Baie de Kendu",
        "zh": "肯德湾",
        "it": "Baia di Kendu",
        "id": "Teluk Kendu",
        "ja": "ケンド湾",
        "pt": "Baía de kendu"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.3596,
        "lng": 34.64
      },
      "country": "Kenya",
      "importance": 3
    },
    {
      "id": 10329,
      "name": "Rayachoti",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.05,
        "lng": 78.75
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10370,
      "name": "Cacapava",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.1008,
        "lng": -45.7069
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5109,
      "name": "Xiancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.2374,
        "lng": 116.3152
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10378,
      "name": "Conroe",
      "names": {
        "zh": "康罗",
        "ja": "コンロー"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.3239,
        "lng": -95.4825
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10379,
      "name": "Alafaya",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 28.5278,
        "lng": -81.1865
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10376,
      "name": "Maladzyechna",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.3136,
        "lng": 26.8517
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 5143,
      "name": "Panevezys",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.725,
        "lng": 24.3639
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 5123,
      "name": "Hastings",
      "names": {
        "zh": "黑斯廷斯",
        "ja": "ヘイスティングス",
        "th": "เฮสติ้งส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.85,
        "lng": 0.57
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10380,
      "name": "Agua Prieta",
      "names": {
        "fr": "Agua Preieta",
        "ja": "アグアプリベ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.3258,
        "lng": -109.5489
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5135,
      "name": "Miaoli",
      "names": {
        "zh": "苗栗",
        "ja": "苗栗"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.57,
        "lng": 120.82
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 10340,
      "name": "San Sebastian de los Reyes",
      "names": {
        "zh": "圣塞巴斯蒂安德洛斯雷耶斯",
        "ja": "サンセバスチャンデロスレイ",
        "th": "ซานเซบาสเตียนเดอลอสเรเยส"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.5469,
        "lng": -3.6258
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10385,
      "name": "Livermore",
      "names": {
        "zh": "利弗莫尔",
        "ja": "リバモア",
        "th": "ลิเวอร์มอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6862,
        "lng": -121.7608
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5096,
      "name": "Guercif",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 34.23,
        "lng": -3.36
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10381,
      "name": "Chauk",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 20.9085,
        "lng": 94.823
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 10386,
      "name": "Caimbambo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.9,
        "lng": 14.0833
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 10339,
      "name": "Bauan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7917,
        "lng": 121.0085
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10384,
      "name": "Pongotan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.15,
        "lng": 125.95
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10387,
      "name": "Cambambe",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -9.7431,
        "lng": 14.4914
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 10354,
      "name": "Duren",
      "names": {
        "es": "Düren",
        "fr": "Düren",
        "zh": "杜伦",
        "de": "Düren",
        "it": "Düren"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8,
        "lng": 6.4833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10383,
      "name": "Itajuba",
      "names": {
        "pt": "Itajubá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4258,
        "lng": -45.4528
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5165,
      "name": "Westminster",
      "names": {
        "zh": "威斯敏斯特",
        "ja": "ウェストミンスター",
        "th": "เวสต์มินสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7523,
        "lng": -117.9938
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5168,
      "name": "Germantown",
      "names": {
        "zh": "杰曼镇",
        "ja": "ジャーマンタウン",
        "th": "เจอร์แมนทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1755,
        "lng": -77.2643
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10372,
      "name": "Tubingen",
      "names": {
        "es": "De Tubinga",
        "fr": "Tübingen",
        "zh": "蒂宾根",
        "de": "Tübingen",
        "id": "Tübingen",
        "ja": "テュービンゲン",
        "pt": "Tübingen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.52,
        "lng": 9.0556
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10390,
      "name": "Chililabombwe",
      "names": {},
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.3667,
        "lng": 27.8333
      },
      "country": "Zambia",
      "importance": 3
    },
    {
      "id": 5122,
      "name": "Vanadzor",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.8128,
        "lng": 44.4883
      },
      "country": "Armenia",
      "importance": 1
    },
    {
      "id": 10392,
      "name": "Fundacion",
      "names": {
        "es": "La Fundación",
        "fr": "La Fondation",
        "zh": "基金会",
        "de": "Fundación",
        "pt": "Fundación"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.5172,
        "lng": -74.1922
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10395,
      "name": "Nanaimo",
      "names": {
        "zh": "纳奈莫",
        "ja": "ナナイモ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.1642,
        "lng": -123.9364
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10393,
      "name": "Sao Joao del Rei",
      "names": {
        "ja": "サンジョアオデルレイ",
        "pt": "São João del Rei"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.1358,
        "lng": -44.2619
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5166,
      "name": "Dobrich",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.5667,
        "lng": 27.8333
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 5157,
      "name": "Umea",
      "names": {
        "es": "Umeå",
        "zh": "于默奥",
        "ja": "ウメオ",
        "pt": "Umeå"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 63.8285,
        "lng": 20.2706
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 5174,
      "name": "Santa Monica",
      "names": {
        "es": "Santa Mónica",
        "zh": "圣莫尼卡",
        "ja": "サンタモニカ",
        "th": "ซานตาโมนิกา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0232,
        "lng": -118.4813
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5130,
      "name": "Itanhaem",
      "names": {
        "pt": "Itanhaém"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.1831,
        "lng": -46.7889
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10389,
      "name": "Pinamalayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.0,
        "lng": 121.4167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5173,
      "name": "Valjevo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2667,
        "lng": 19.8833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10360,
      "name": "Julu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.22,
        "lng": 115.0309
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5116,
      "name": "Watford",
      "names": {
        "zh": "沃特福德",
        "ja": "ワトフォード",
        "th": "เฟิร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.655,
        "lng": -0.3957
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10401,
      "name": "Hezuo",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.9984,
        "lng": 102.91
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10403,
      "name": "New Braunfels",
      "names": {
        "es": "Nuevos Braunfels",
        "fr": "Nouveaux Braunfels",
        "zh": "新的Braunfels",
        "de": "Neue Braunfels",
        "it": "Nuovi Braunfels",
        "id": "Braunfels baru",
        "ja": "新しいブラウンフェルズ",
        "th": "ใหม่ Braunfels",
        "pt": "Braunfels novos"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.6994,
        "lng": -98.1148
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10367,
      "name": "Penaflor",
      "names": {
        "zh": "佩尼亚弗洛尔"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.6167,
        "lng": -70.9167
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 10382,
      "name": "Imizucho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.7125,
        "lng": 137.0994
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10402,
      "name": "Pistoia",
      "names": {
        "zh": "皮斯托亚",
        "ja": "ピストイア"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.9333,
        "lng": 10.9167
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 5162,
      "name": "Babahoyo",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -1.8167,
        "lng": -79.5167
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 10369,
      "name": "Jalalpur Jattan",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.7667,
        "lng": 74.2167
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10391,
      "name": "Pisa",
      "names": {
        "fr": "Pise",
        "zh": "比萨",
        "ja": "ピサ",
        "th": "ปิซา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.7167,
        "lng": 10.4
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10388,
      "name": "Victorias",
      "names": {
        "zh": "维多利亚"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.9,
        "lng": 123.0833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10361,
      "name": "Kavali",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.9123,
        "lng": 79.9944
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10405,
      "name": "Ocana",
      "names": {
        "es": "Ocaña",
        "zh": "奥卡尼亚",
        "de": "Ocaña",
        "pt": "Ocaña"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2461,
        "lng": -73.3553
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10353,
      "name": "Modi`in Makkabbim Re`ut",
      "names": {
        "fr": "Modi`in makkabbim re",
        "zh": "modi` makkabbim重新",
        "it": "Modi`in Makkabbim Re`t",
        "ja": "Mokkabim re`ut",
        "pt": "Modi`in makkabbim re`"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.9339,
        "lng": 34.9856
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 10394,
      "name": "Ma`arrat an Nu`man",
      "names": {
        "es": "Ma`arrat un nu\u0027man",
        "fr": "Ma`arrat un nu`man",
        "zh": "Ma`arrat a nu`man",
        "de": "Ma`arrrat ein nu`sman",
        "it": "Ma è un nuzono",
        "ja": "ええとヌーマン",
        "pt": "Ma`rat um nu`"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.6433,
        "lng": 36.6683
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 10397,
      "name": "Chalchuapa",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.9833,
        "lng": -89.6833
      },
      "country": "El Salvador",
      "importance": 3
    },
    {
      "id": 10408,
      "name": "Uacu Cungo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -11.3583,
        "lng": 15.1194
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 10409,
      "name": "Luau",
      "names": {
        "zh": "卢奥",
        "ja": "ルアウ"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -10.7,
        "lng": 22.2333
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 5151,
      "name": "Francistown",
      "names": {
        "zh": "佛朗西斯顿"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -21.1736,
        "lng": 27.5125
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 10406,
      "name": "Mihara",
      "names": {
        "zh": "三原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.3975,
        "lng": 133.0786
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10396,
      "name": "Mancheral",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.8679,
        "lng": 79.4639
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10364,
      "name": "Flensburg",
      "names": {
        "zh": "弗伦斯堡",
        "ja": "フレンスブルク"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.7819,
        "lng": 9.4367
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10404,
      "name": "Compostela",
      "names": {
        "fr": "Compostelle",
        "zh": "波斯特拉",
        "ja": "コンポステラ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6667,
        "lng": 126.0833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10399,
      "name": "Kiselevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.9833,
        "lng": 86.7
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5178,
      "name": "Balkanabat",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 39.5119,
        "lng": 54.365
      },
      "country": "Turkmenistan",
      "importance": 1
    },
    {
      "id": 5155,
      "name": "Jonkoping",
      "names": {
        "fr": "Jönköping",
        "de": "Jönköping",
        "th": "เยินเชอปิง",
        "pt": "Jönköping"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.7833,
        "lng": 14.1667
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 5172,
      "name": "Catanzaro",
      "names": {
        "zh": "卡坦扎罗",
        "ja": "カタンザーロ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.91,
        "lng": 16.5875
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 5137,
      "name": "Stevenage",
      "names": {
        "zh": "斯蒂夫尼奇",
        "th": "สตีเวนิจ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.9017,
        "lng": -0.2019
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10421,
      "name": "Serdar",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 38.9667,
        "lng": 56.2667
      },
      "country": "Turkmenistan",
      "importance": 3
    },
    {
      "id": 10422,
      "name": "Cantaura",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.3005,
        "lng": -64.3564
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 10424,
      "name": "Fall River",
      "names": {
        "es": "Río de otoño",
        "fr": "Fleuve automne",
        "zh": "秋天河",
        "de": "Herbstriss",
        "it": "Caduta del fiume",
        "id": "Jadghir",
        "ja": "秋川",
        "th": "ฝั่งแม่น้ำ",
        "pt": "Rio de outono"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7137,
        "lng": -71.1014
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5181,
      "name": "Sliven",
      "names": {
        "zh": "斯利文"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.6781,
        "lng": 26.326
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 10400,
      "name": "Zwickau",
      "names": {
        "zh": "茨维考",
        "ja": "ツヴィッカウ",
        "th": "ซฟิคเคา"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.7189,
        "lng": 12.4961
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10398,
      "name": "Kansk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.2,
        "lng": 95.7
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10426,
      "name": "Conda",
      "names": {
        "zh": "康达"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -11.1667,
        "lng": 14.5
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 5169,
      "name": "Tartus",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.8833,
        "lng": 35.8833
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 10410,
      "name": "Kadiri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.12,
        "lng": 78.17
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5179,
      "name": "Ipil",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.7833,
        "lng": 122.5833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 10431,
      "name": "Prijedor",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.9667,
        "lng": 16.7
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 5144,
      "name": "Oulad Teima",
      "names": {
        "id": "Oulad Tima",
        "ja": "オールド帝人"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 30.4,
        "lng": -9.21
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 4955,
      "name": "Nikaia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9667,
        "lng": 23.6333
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 4999,
      "name": "Mestre",
      "names": {
        "zh": "梅斯特雷",
        "ja": "メストレ",
        "th": "เมสเตร"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.4906,
        "lng": 12.2381
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 10427,
      "name": "Surallah",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.3667,
        "lng": 124.7333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10413,
      "name": "Hosaina",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 7.5504,
        "lng": 37.85
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 10377,
      "name": "Ad Dakhla",
      "names": {
        "es": "Dakhla ad",
        "zh": "广告达克拉",
        "ja": "Dakhla",
        "th": "โฆษณา Dakhla"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 23.7141,
        "lng": -15.9368
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10416,
      "name": "Lucca",
      "names": {
        "fr": "Lucques",
        "zh": "卢卡",
        "ja": "ルッカ",
        "th": "ลูกา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.85,
        "lng": 10.5167
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10432,
      "name": "Lere",
      "names": {
        "zh": "莱雷"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 9.6556,
        "lng": 14.225
      },
      "country": "Chad",
      "importance": 3
    },
    {
      "id": 10434,
      "name": "Albany",
      "names": {
        "zh": "奥尔巴尼",
        "ja": "アルバニー",
        "th": "ออลบานี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.5776,
        "lng": -84.1762
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10412,
      "name": "Polangui",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2922,
        "lng": 123.4856
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10425,
      "name": "Banga",
      "names": {
        "zh": "邦加"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.3,
        "lng": 124.7833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10436,
      "name": "Humpata",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -15.0725,
        "lng": 13.3678
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 5154,
      "name": "Kalibo",
      "names": {
        "zh": "卡利博"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.7,
        "lng": 122.3667
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 10441,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.9,
        "lng": -112.6
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 10420,
      "name": "El Puerto de Santa Maria",
      "names": {
        "es": "El Puerto de Santa María"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.6,
        "lng": -6.2167
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10437,
      "name": "Sao Joao da Boa Vista",
      "names": {
        "ja": "サンヨーオダボアビスタ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.9689,
        "lng": -46.7978
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5175,
      "name": "Jalal-Abad",
      "names": {
        "zh": "贾拉尔 - 阿瓦德",
        "ja": "jalal-アバド"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 40.9375,
        "lng": 72.9861
      },
      "country": "Kyrgyzstan",
      "importance": 1
    },
    {
      "id": 10407,
      "name": "Kairana",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.3953,
        "lng": 77.2053
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10423,
      "name": "Kamensk-Shakhtinskiy",
      "names": {
        "zh": "卡缅斯克-shakhtinskiy"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 48.3167,
        "lng": 40.2667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 10435,
      "name": "Shwebo",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 22.5783,
        "lng": 95.6929
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 10429,
      "name": "Hinigaran",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2667,
        "lng": 122.85
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10430,
      "name": "Calabanga",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7167,
        "lng": 123.2333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5183,
      "name": "As Salt",
      "names": {
        "es": "Como sal",
        "fr": "Comme sel",
        "zh": "作为盐",
        "de": "Als Salz",
        "it": "Come sale",
        "id": "Sebagai garam",
        "ja": "塩として",
        "th": "เป็นเกลือ",
        "pt": "Como sal"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 32.0392,
        "lng": 35.7272
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 5191,
      "name": "Miami Beach",
      "names": {
        "es": "Playa de Miami",
        "zh": "迈阿密滩",
        "id": "Pantai Miami",
        "ja": "マイアミビーチ",
        "th": "ชายหาดไมอามี่",
        "pt": "Praia de Miami"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.8171,
        "lng": -80.1396
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10433,
      "name": "Passi",
      "names": {
        "zh": "帕西"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1,
        "lng": 122.6333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10438,
      "name": "Murcia",
      "names": {
        "zh": "穆尔西亚",
        "ja": "ムルシア",
        "th": "มูร์เซีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6,
        "lng": 123.0333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10415,
      "name": "Bogo",
      "names": {
        "zh": "博戈",
        "de": "Bogø"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.0167,
        "lng": 124.0
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5148,
      "name": "Hartlepool",
      "names": {
        "th": "เปรสตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.69,
        "lng": -1.21
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10447,
      "name": "Norwalk",
      "names": {
        "zh": "诺瓦克",
        "th": "วอล์ค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1144,
        "lng": -73.4215
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5193,
      "name": "San Leandro",
      "names": {
        "zh": "圣莱德罗",
        "ja": "サンリーンドロ",
        "th": "ซานไลน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7071,
        "lng": -122.1601
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10440,
      "name": "Candeias",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.6678,
        "lng": -38.5508
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5080,
      "name": "Wangqing",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.3126,
        "lng": 129.7572
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5190,
      "name": "Viana do Castelo",
      "names": {
        "zh": "Viana做Castelo",
        "ja": "ヴィアナドカステロ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.7,
        "lng": -8.8333
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 5177,
      "name": "Presov",
      "names": {
        "zh": "普雷绍夫",
        "ja": "プレショフ"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0,
        "lng": 21.2333
      },
      "country": "Slovakia",
      "importance": 1
    },
    {
      "id": 10451,
      "name": "Montelibano",
      "names": {
        "zh": "蒙特利瓦诺"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.9711,
        "lng": -75.4181
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10454,
      "name": "O\u0027Fallon",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 38.785,
        "lng": -90.7175
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5158,
      "name": "Poitiers",
      "names": {
        "zh": "普瓦捷",
        "ja": "ポワティエ",
        "th": "ติเยร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.58,
        "lng": 0.34
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 10411,
      "name": "Norrkoping",
      "names": {
        "fr": "Norrköping",
        "zh": "诺尔雪平",
        "th": "นอร์เชอปิง"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.5919,
        "lng": 16.1856
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 5121,
      "name": "Ben Guerir",
      "names": {
        "zh": "本格雷尔",
        "ja": "ベンギアル",
        "th": "เบ็นโกโรเรี"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 32.23,
        "lng": -7.95
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10418,
      "name": "Chosica",
      "names": {
        "zh": "乔西卡"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -11.9361,
        "lng": -76.6972
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 10419,
      "name": "Leping",
      "names": {
        "zh": "乐平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.613,
        "lng": 113.6995
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10414,
      "name": "Giessen",
      "names": {
        "zh": "吉森",
        "de": "Gießen",
        "th": "กีสเซิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.5833,
        "lng": 8.6667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10452,
      "name": "Koka",
      "names": {
        "zh": "•科卡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9661,
        "lng": 136.1663
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10457,
      "name": "Maravatio de Ocampo",
      "names": {
        "ja": "マラティオデオカンポ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8933,
        "lng": -100.4428
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10456,
      "name": "Kadirli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3698,
        "lng": 36.1031
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 10453,
      "name": "San Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "ซานหลุยส์"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.1881,
        "lng": -75.8486
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 10446,
      "name": "Dongchuan",
      "names": {
        "zh": "东川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.5086,
        "lng": 101.2356
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10443,
      "name": "Bhakkar",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.6333,
        "lng": 71.0667
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 5198,
      "name": "Bertoua",
      "names": {
        "zh": "贝尔图阿"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.5833,
        "lng": 13.6833
      },
      "country": "Cameroon",
      "importance": 1
    },
    {
      "id": 10463,
      "name": "La Grita",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.1333,
        "lng": -71.9833
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 10445,
      "name": "Solana",
      "names": {
        "zh": "索拉纳",
        "ja": "ソラナ",
        "th": "โซลานา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.65,
        "lng": 121.6833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10458,
      "name": "Ootacamund",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 11.4086,
        "lng": 76.6939
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10466,
      "name": "Newton",
      "names": {
        "zh": "牛顿",
        "ja": "ニュートン",
        "th": "นิวตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3316,
        "lng": -71.2085
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10462,
      "name": "Echague",
      "names": {
        "es": "Echagüe"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.7,
        "lng": 121.65
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10465,
      "name": "Avare",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.0989,
        "lng": -48.9258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10467,
      "name": "Zhob",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.3417,
        "lng": 69.4486
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 10460,
      "name": "Aroroy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.5125,
        "lng": 123.3989
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5115,
      "name": "Ben Arous",
      "names": {
        "es": "Ben aus",
        "zh": "本志",
        "de": "Ben erregend",
        "ja": "ベン根",
        "th": "เบ็นอาร์ออส"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.7531,
        "lng": 10.2189
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 5197,
      "name": "Prosperidad",
      "names": {
        "zh": "普罗斯佩里达"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.6057,
        "lng": 125.9153
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 5199,
      "name": "Alabel",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.1023,
        "lng": 125.2868
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 10471,
      "name": "Qal`at Bishah",
      "names": {
        "zh": "QAL毕马",
        "de": "Qal`at Bischah",
        "it": "Qal`t Bishah",
        "th": "qal`at bishaah",
        "pt": "QAL`T BISHAH"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 20.0087,
        "lng": 42.5987
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 5126,
      "name": "Brusque",
      "names": {
        "es": "Brusco",
        "fr": "Bourru",
        "zh": "粗暴",
        "id": "Kasar",
        "ja": "ぶっきらぼう",
        "th": "รีบ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.13,
        "lng": -48.93
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 786,
      "name": "Suva",
      "names": {
        "zh": "苏瓦",
        "ja": "スバ",
        "th": "ซูวา"
      },
      "countryCode": "FJ",
      "latLng": {
        "lat": -18.1333,
        "lng": 178.4333
      },
      "country": "Fiji",
      "importance": 0
    },
    {
      "id": 10461,
      "name": "Grimsby",
      "names": {
        "zh": "格里姆斯比",
        "th": "กริมสบี"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5595,
        "lng": -0.068
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5153,
      "name": "Lobnya",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0167,
        "lng": 37.4833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5163,
      "name": "Jangipur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 24.47,
        "lng": 88.07
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10475,
      "name": "Ponta Pora",
      "names": {
        "it": "Ponta Pora Pora",
        "ja": "ポンタポラ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5358,
        "lng": -55.7258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5145,
      "name": "Jaffna",
      "names": {
        "zh": "贾夫纳",
        "th": "จาฟ"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 9.6647,
        "lng": 80.0167
      },
      "country": "Sri Lanka",
      "importance": 1
    },
    {
      "id": 10449,
      "name": "Halifax",
      "names": {
        "zh": "哈利法克斯",
        "ja": "ハリファックス",
        "th": "แฮลิแฟกซ์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.725,
        "lng": -1.863
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10469,
      "name": "Pingyuanjie",
      "names": {
        "zh": "平远街"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7472,
        "lng": 103.761
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5201,
      "name": "Coronel Oviedo",
      "names": {
        "ja": "コロネル・オビエド"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.45,
        "lng": -56.44
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 10480,
      "name": "Leshou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.1902,
        "lng": 116.1205
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10483,
      "name": "Sudbury",
      "names": {
        "zh": "萨德伯里",
        "ja": "サドバリー",
        "th": "เบอรี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.49,
        "lng": -81.01
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10481,
      "name": "Sinjar",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.3209,
        "lng": 41.8766
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 10485,
      "name": "Jatai",
      "names": {
        "pt": "Jataí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -17.8808,
        "lng": -51.7139
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10439,
      "name": "Qiantangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6742,
        "lng": 116.915
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10472,
      "name": "Tacana",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.2415,
        "lng": -92.0684
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 5189,
      "name": "Al Wakrah",
      "names": {
        "ja": "アル・ワクラ",
        "th": "อัล Wakrah"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.18,
        "lng": 51.61
      },
      "country": "Qatar",
      "importance": 1
    },
    {
      "id": 10468,
      "name": "Sakai",
      "names": {
        "zh": "堺",
        "ja": "堺",
        "th": "ซาไก"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1669,
        "lng": 136.2317
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10474,
      "name": "Makilala",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.9667,
        "lng": 125.0833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10482,
      "name": "Jilotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9519,
        "lng": -99.5328
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10487,
      "name": "Wislane",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.2167,
        "lng": -8.3833
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10470,
      "name": "Maiquetia",
      "names": {
        "es": "Maiquetía",
        "zh": "迈克蒂亚",
        "pt": "Maiquetía"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.5958,
        "lng": -66.9772
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 10489,
      "name": "Muncie",
      "names": {
        "zh": "曼西",
        "th": "มันซี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.1989,
        "lng": -85.395
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5206,
      "name": "Ebolowa",
      "names": {
        "zh": "埃博洛瓦"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 2.9,
        "lng": 11.15
      },
      "country": "Cameroon",
      "importance": 1
    },
    {
      "id": 10478,
      "name": "Sao Pedro da Aldeia",
      "names": {
        "ja": "サンペドロダアルデイア",
        "pt": "São Pedro da Aldeia"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.8389,
        "lng": -42.1028
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10417,
      "name": "Hilversum",
      "names": {
        "zh": "希尔沃叙姆"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.23,
        "lng": 5.18
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 5211,
      "name": "Citrus Heights",
      "names": {
        "fr": "Hauteur d\u0027agrumes",
        "zh": "柑橘高度",
        "de": "Zitrushöhen",
        "it": "Altezze di agrumi",
        "id": "Heights Citrus",
        "ja": "柑橘類の高さ",
        "th": "ส้มสูง",
        "pt": "Alturas cítricas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6948,
        "lng": -121.288
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10490,
      "name": "Jaguey Grande",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.5292,
        "lng": -81.1325
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 10492,
      "name": "Baggao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 17.8894,
        "lng": 121.8709
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5210,
      "name": "San Juan de los Morros",
      "names": {
        "zh": "圣胡安de los Morros",
        "ja": "サンファンデロスモロス",
        "th": "ซานฮวนเดอลอสมอร์ส"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.901,
        "lng": -67.354
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 5213,
      "name": "State College",
      "names": {
        "es": "Colegio estatal",
        "fr": "Poste d\u0027État",
        "zh": "州立学院",
        "de": "Staatshelfer",
        "id": "Perguruan tinggi",
        "ja": "州立大学",
        "th": "วิทยาลัยรัฐ",
        "pt": "Faculdade estadual"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7909,
        "lng": -77.8568
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10477,
      "name": "Faridkot",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 30.67,
        "lng": 74.76
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10442,
      "name": "Calauan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.15,
        "lng": 121.3167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5129,
      "name": "Makeni",
      "names": {
        "zh": "马克尼"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 8.8817,
        "lng": -12.0442
      },
      "country": "Sierra Leone",
      "importance": 1
    },
    {
      "id": 5156,
      "name": "Ama",
      "names": {
        "zh": "美国医学会",
        "th": "อะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1884,
        "lng": 136.8037
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10484,
      "name": "Sao Goncalo do Amarante",
      "names": {
        "zh": "圣冈卡罗做苋菜",
        "id": "Sao Goncalo melakukan amarahe",
        "ja": "サンゴンカロはアマランテをします",
        "th": "Sao Goncalo ทำ Amarante",
        "pt": "São Gonçalo do Amarante"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.7928,
        "lng": -35.3289
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10496,
      "name": "Pergamino",
      "names": {
        "zh": "佩尔加米诺"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.8836,
        "lng": -60.5669
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10448,
      "name": "Madgaon",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.2719,
        "lng": 73.9583
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10455,
      "name": "Baras",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5167,
        "lng": 121.2667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10428,
      "name": "Gitarama",
      "names": {},
      "countryCode": "RW",
      "latLng": {
        "lat": -2.0696,
        "lng": 29.76
      },
      "country": "Rwanda",
      "importance": 3
    },
    {
      "id": 10493,
      "name": "Santa Cruz do Capibaribe",
      "names": {
        "fr": "Santa Cruz Do CapliBaribe",
        "zh": "圣克鲁斯做Capibaribe",
        "ja": "サンタクルーズはカプリバリビです",
        "th": "Santa Cruz ทำ Capibaribe"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.9573,
        "lng": -36.2047
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5052,
      "name": "Aubervilliers",
      "names": {
        "zh": "欧贝维利耶"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9131,
        "lng": 2.3831
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 10500,
      "name": "Koidu-Bulma",
      "names": {
        "zh": "koidu-布尔玛"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 8.4405,
        "lng": -10.85
      },
      "country": "Sierra Leone",
      "importance": 3
    },
    {
      "id": 10459,
      "name": "Sakiet ez Zit",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 34.8,
        "lng": 10.77
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 5152,
      "name": "Chester",
      "names": {
        "zh": "切斯特",
        "ja": "チェスター",
        "th": "เชสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.19,
        "lng": -2.89
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10499,
      "name": "Consolacion del Sur",
      "names": {
        "id": "Konsolasion del sur",
        "ja": "コンソリシオンデルサー",
        "pt": "Consolação del sur"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.5083,
        "lng": -83.5172
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 10476,
      "name": "Changting",
      "names": {
        "zh": "长汀"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.867,
        "lng": 116.3167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5209,
      "name": "Maasin",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1333,
        "lng": 124.85
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 10464,
      "name": "San Fabian",
      "names": {
        "zh": "圣法比安",
        "ja": "サンファビアン",
        "th": "ซานเฟเฟอร์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.15,
        "lng": 120.45
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10502,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.2046,
        "lng": -74.6765
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10479,
      "name": "Calaca",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9667,
        "lng": 120.8
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10506,
      "name": "Town \u0027n\u0027 Country",
      "names": {
        "es": "País de la ciudad",
        "fr": "Ville \u0027n\u0027 pays",
        "zh": "镇\u0027n\u0027国家",
        "de": "Stadt \u0027n\u0027 Land",
        "it": "Paese \u0027n\u0027 country",
        "id": "Negara \u0027n\u0027",
        "ja": "町の国",
        "th": "ประเทศ \u0027n\u0027 ประเทศ",
        "pt": "Cidade \u0027n\u0027 país"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.0108,
        "lng": -82.576
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10486,
      "name": "Camiling",
      "names": {
        "zh": "卡米灵"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7,
        "lng": 120.4167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5159,
      "name": "Glyfada",
      "names": {
        "zh": "格利法达",
        "th": "กลีฟาดา"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.88,
        "lng": 23.7533
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 10473,
      "name": "Ratingen",
      "names": {
        "zh": "拉廷根",
        "ja": "ラティンゲン",
        "th": "เรติงเกน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3,
        "lng": 6.85
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 10511,
      "name": "Chongoroi",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.5667,
        "lng": 13.95
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 10513,
      "name": "Decatur",
      "names": {
        "zh": "迪凯特",
        "ja": "ディケーター",
        "th": "ดีเคเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.8556,
        "lng": -88.9337
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5180,
      "name": "Takasagocho-takasemachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7658,
        "lng": 134.7906
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10491,
      "name": "Dipalpur",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.6708,
        "lng": 73.6533
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10512,
      "name": "Hounde",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 11.5,
        "lng": -3.5167
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 10504,
      "name": "Brindisi",
      "names": {
        "zh": "布林迪西",
        "ja": "ブリンディジ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6383,
        "lng": 17.9458
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10522,
      "name": "Jackson",
      "names": {
        "zh": "杰克逊",
        "ja": "ジャクソン",
        "th": "แจ็คสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2431,
        "lng": -84.4037
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10523,
      "name": "Fort Myers",
      "names": {
        "zh": "迈尔斯堡",
        "ja": "フォートマイヤーズ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.6195,
        "lng": -81.8303
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10521,
      "name": "Ad Diwem",
      "names": {
        "es": "Dijem",
        "zh": "广告Diwem",
        "de": "Adi",
        "it": "D -wem",
        "ja": "アドディウム",
        "pt": "Adivinhar"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 13.9904,
        "lng": 32.3
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 10501,
      "name": "Guadalajara",
      "names": {
        "zh": "瓜达拉哈拉",
        "ja": "グアダラハラ",
        "th": "กวาดาลาฮารา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.6337,
        "lng": -3.1674
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 10516,
      "name": "Nova Lima",
      "names": {
        "ja": "ノヴァリマ",
        "th": "โนวาลิมา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.9858,
        "lng": -43.8469
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10517,
      "name": "Wulan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.5585,
        "lng": 104.6765
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10518,
      "name": "Sao Cristovao",
      "names": {
        "zh": "圣克里娃娃",
        "ja": "サンクリスバオ",
        "th": "เซาคริสโตว้า",
        "pt": "São Cristovao"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.015,
        "lng": -37.2058
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5215,
      "name": "Lokossa",
      "names": {
        "zh": "洛科萨"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.615,
        "lng": 1.715
      },
      "country": "Benin",
      "importance": 1
    },
    {
      "id": 10514,
      "name": "Itabaiana",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -10.685,
        "lng": -37.425
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10515,
      "name": "Heyunkeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.9293,
        "lng": 112.9185
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5219,
      "name": "Mardin",
      "names": {
        "zh": "马尔丁"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3131,
        "lng": 40.735
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 5182,
      "name": "Mechelen",
      "names": {
        "es": "Malinas",
        "fr": "Malines",
        "zh": "梅赫伦",
        "ja": "メッヘレン",
        "th": "เมเคอเลิน"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0281,
        "lng": 4.4803
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 10498,
      "name": "Binmaley",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0323,
        "lng": 120.269
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10519,
      "name": "Menglang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5586,
        "lng": 99.9337
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10444,
      "name": "Dunkerque",
      "names": {
        "zh": "敦刻尔克"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 51.0383,
        "lng": 2.3775
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 5185,
      "name": "Tangxing",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7261,
        "lng": 111.7108
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10526,
      "name": "Campana",
      "names": {
        "zh": "坎帕纳",
        "ja": "カンパーナ",
        "th": "คัมพานา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.1667,
        "lng": -58.9167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10503,
      "name": "Paredes",
      "names": {
        "zh": "帕雷德斯"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2,
        "lng": -8.3333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 10497,
      "name": "Fukuroi",
      "names": {
        "zh": "袋井"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7503,
        "lng": 137.925
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10533,
      "name": "Goodyear",
      "names": {
        "zh": "固特异",
        "ja": "グッドイヤー",
        "th": "กู๊ดเยียร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.2613,
        "lng": -112.3622
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10450,
      "name": "Heerlen",
      "names": {
        "zh": "海尔伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.8833,
        "lng": 5.9833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 5217,
      "name": "Maldonado",
      "names": {
        "zh": "马尔多纳多"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.9088,
        "lng": -54.9581
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 10529,
      "name": "Gubkin",
      "names": {
        "zh": "古布金"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.2833,
        "lng": 37.55
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10536,
      "name": "Kattagan",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.2,
        "lng": 64.9167
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 10507,
      "name": "Mijas",
      "names": {
        "zh": "米哈斯",
        "ja": "ミハス"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.6,
        "lng": -4.6333
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10538,
      "name": "Tumaco",
      "names": {
        "zh": "图马科"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.81,
        "lng": -78.81
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10508,
      "name": "Tiflet",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.9,
        "lng": -6.33
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10530,
      "name": "Cardenas",
      "names": {
        "es": "Cárdenas",
        "zh": "卡德纳斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 23.0428,
        "lng": -81.2036
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 5204,
      "name": "Mersin",
      "names": {
        "zh": "梅尔辛",
        "th": "เมอร์ซิน"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.8,
        "lng": 34.6167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 10524,
      "name": "Ciudad de Atlixco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9,
        "lng": -98.45
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10547,
      "name": "Dalton",
      "names": {
        "zh": "道尔顿",
        "ja": "ダルトン",
        "th": "ดาลตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.769,
        "lng": -84.9712
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5132,
      "name": "Nuneaton",
      "names": {
        "zh": "纳尼顿",
        "ja": "ナニートン",
        "th": "อีตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.523,
        "lng": -1.468
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10495,
      "name": "Anakapalle",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.68,
        "lng": 83.02
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10505,
      "name": "Nabua",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4083,
        "lng": 123.375
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10551,
      "name": "Clarkstown",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 41.1319,
        "lng": -73.966
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5228,
      "name": "Tucupita",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.0575,
        "lng": -62.0452
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 10554,
      "name": "Cheektowaga",
      "names": {
        "zh": "奇克托瓦加",
        "th": "ชีกโทวากา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.9082,
        "lng": -78.7466
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10528,
      "name": "Tuncheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.3633,
        "lng": 110.0978
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10541,
      "name": "Novotroitsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 51.2009,
        "lng": 58.2983
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10509,
      "name": "Mobara",
      "names": {
        "zh": "茂原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4283,
        "lng": 140.2881
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5223,
      "name": "Nakhon Sawan",
      "names": {
        "ja": "ナコンサワン",
        "th": "นครสวรรค์",
        "pt": "Sawan nakhon"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.7133,
        "lng": 100.1353
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 10531,
      "name": "Yaofeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.1395,
        "lng": 111.2174
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5227,
      "name": "Loznica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.5333,
        "lng": 19.2258
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10549,
      "name": "Ubatuba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.4339,
        "lng": -45.0708
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10527,
      "name": "Dingcheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.6819,
        "lng": 110.3637
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10552,
      "name": "Guasavito",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.5655,
        "lng": -108.4718
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 10494,
      "name": "Lunen",
      "names": {
        "es": "Lünen",
        "de": "Lünen",
        "pt": "Lünen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6167,
        "lng": 7.5167
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 10540,
      "name": "Gotenba",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3086,
        "lng": 138.935
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10556,
      "name": "Olavarria",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -36.9,
        "lng": -60.3333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10488,
      "name": "Buzuluk",
      "names": {
        "zh": "布祖卢克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.7667,
        "lng": 52.2667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10543,
      "name": "Chiclana de la Frontera",
      "names": {
        "ja": "チクラナデラフロンテラ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.4167,
        "lng": -6.15
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5150,
      "name": "Aulnay-sous-Bois",
      "names": {
        "zh": "欧奈-苏布瓦",
        "ja": "aulnay-スー・ボワ",
        "th": "aulnay-Sous-บัว"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9386,
        "lng": 2.4906
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 10559,
      "name": "Bryan",
      "names": {
        "zh": "布莱恩",
        "ja": "ブライアン",
        "th": "ไบรอัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.6657,
        "lng": -96.3668
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5136,
      "name": "Ciudad de Melilla",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 35.2825,
        "lng": -2.9475
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5184,
      "name": "Mukacheve",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.4414,
        "lng": 22.7136
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 10510,
      "name": "Bantayan",
      "names": {
        "zh": "班塔延岛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.2,
        "lng": 123.7333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10548,
      "name": "San Antonio",
      "names": {
        "zh": "圣安东尼奥",
        "ja": "サンアントニオ",
        "th": "ซานอันโตนิโอ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -33.5809,
        "lng": -71.6132
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 10561,
      "name": "Zhezqazghan",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 47.7833,
        "lng": 67.7
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 5147,
      "name": "Settsu",
      "names": {
        "zh": "摄津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7772,
        "lng": 135.5622
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10550,
      "name": "Kameoka",
      "names": {
        "zh": "龟冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0133,
        "lng": 135.5725
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10564,
      "name": "Shchekino",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.0143,
        "lng": 37.5143
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10563,
      "name": "Bugulma",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.5333,
        "lng": 52.7833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10567,
      "name": "Waukegan",
      "names": {
        "zh": "沃基根"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3697,
        "lng": -87.8716
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5234,
      "name": "Hawthorne",
      "names": {
        "zh": "霍桑",
        "ja": "ホーソン",
        "th": "ฮอว์ธอร์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9146,
        "lng": -118.3476
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10562,
      "name": "Khowrasgan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.6536,
        "lng": 51.755
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 5160,
      "name": "Kitanagoya",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2503,
        "lng": 136.8861
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10558,
      "name": "Longchuan",
      "names": {
        "zh": "龙川"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.1945,
        "lng": 101.2759
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5082,
      "name": "Colombes",
      "names": {
        "ja": "コロンブ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9236,
        "lng": 2.2522
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5188,
      "name": "Volos",
      "names": {
        "zh": "沃洛斯",
        "th": "โวลอส"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 39.3611,
        "lng": 22.9425
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 10555,
      "name": "Shaoshanzhan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.91,
        "lng": 112.48
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10565,
      "name": "Potiskum",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 11.7104,
        "lng": 11.08
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 10570,
      "name": "Anderson",
      "names": {
        "zh": "安德森",
        "ja": "アンダーソン",
        "th": "แอนเดอร์สัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0891,
        "lng": -85.6893
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10573,
      "name": "North Vancouver",
      "names": {
        "zh": "北温哥华",
        "de": "Nordvancouver",
        "id": "Vancouver Utara",
        "ja": "ノースバンクーバー",
        "th": "นอร์ทแวนคูเวอร์",
        "pt": "Vancouver norte"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.3641,
        "lng": -123.0066
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10574,
      "name": "Redwood City",
      "names": {
        "fr": "Ville de Redwood",
        "zh": "红木市",
        "de": "Redwood-Stadt",
        "id": "Kota redwood",
        "ja": "レッドウッドシティ",
        "th": "เมืองเรดวูด",
        "pt": "Cidade de sequóias"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.5026,
        "lng": -122.2252
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10539,
      "name": "Keffi",
      "names": {
        "zh": "凯菲"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 8.8464,
        "lng": 7.8733
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 10520,
      "name": "Qaracuxur",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.3969,
        "lng": 49.9733
      },
      "country": "Azerbaijan",
      "importance": 3
    },
    {
      "id": 10542,
      "name": "Kapakli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.3333,
        "lng": 27.9667
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 10575,
      "name": "Cacoal",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -11.4386,
        "lng": -61.4472
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10568,
      "name": "Iga",
      "names": {
        "zh": "IGA抗体",
        "ja": "伊賀",
        "th": "จีเอ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7686,
        "lng": 136.13
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10525,
      "name": "Chiguayante",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -36.9167,
        "lng": -73.0167
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 10546,
      "name": "Cabiao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.25,
        "lng": 120.85
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5235,
      "name": "Kouvola",
      "names": {
        "zh": "科沃拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8681,
        "lng": 26.7042
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 10577,
      "name": "Guanambi",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -14.2228,
        "lng": -42.7808
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10566,
      "name": "Guinobatan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.1833,
        "lng": 123.6
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10580,
      "name": "Hoover",
      "names": {
        "zh": "胡佛",
        "ja": "フーバー",
        "th": "ฮูเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.3754,
        "lng": -86.8064
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10532,
      "name": "Kharian",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.811,
        "lng": 73.865
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10578,
      "name": "Yeysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.7106,
        "lng": 38.2778
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 10582,
      "name": "Brossard",
      "names": {
        "zh": "布罗萨尔"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.4667,
        "lng": -73.45
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10534,
      "name": "Aalst",
      "names": {
        "zh": "阿尔斯特",
        "ja": "アールスト"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.9383,
        "lng": 4.0392
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 10581,
      "name": "Cachoeira do Sul",
      "names": {
        "it": "Cachoeira do sol"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -30.0394,
        "lng": -52.8953
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10569,
      "name": "Longhua",
      "names": {
        "zh": "龙华"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.317,
        "lng": 117.7264
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10535,
      "name": "Ducheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.2445,
        "lng": 111.5342
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10579,
      "name": "Sekimachi",
      "names": {
        "zh": "关町"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4958,
        "lng": 136.9178
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5239,
      "name": "El Bayadh",
      "names": {
        "zh": "埃尔贝纳德"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.6831,
        "lng": 1.0192
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 10587,
      "name": "Lake Forest",
      "names": {
        "es": "Bosque del lago",
        "fr": "Forêt lac",
        "zh": "湖森林",
        "de": "Seewald",
        "it": "Lago Foresta",
        "id": "Hutan danau",
        "ja": "湖の森",
        "th": "ป่าทะเลสาบ",
        "pt": "Floresta do lago"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6606,
        "lng": -117.6712
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10544,
      "name": "Pariaman",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.6261,
        "lng": 100.1206
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 5237,
      "name": "Toledo",
      "names": {
        "zh": "托莱多",
        "ja": "トレド",
        "th": "โทเลโด"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.8567,
        "lng": -4.0244
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 10585,
      "name": "Batarasa",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.6667,
        "lng": 117.6167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5241,
      "name": "Pori",
      "names": {
        "zh": "波里",
        "ja": "ポリ"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4847,
        "lng": 21.7972
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 10537,
      "name": "Garulia",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.82,
        "lng": 88.37
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5208,
      "name": "Torre del Greco",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 40.7853,
        "lng": 14.3953
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 10545,
      "name": "Tagaytay",
      "names": {
        "zh": "大雅台"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1,
        "lng": 120.9333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10576,
      "name": "Chiquinquira",
      "names": {
        "es": "Chiquinquirá"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6175,
        "lng": -73.8164
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10589,
      "name": "Caratinga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.79,
        "lng": -42.1389
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10590,
      "name": "Napa",
      "names": {
        "zh": "纳帕",
        "ja": "ナパ",
        "th": "ปา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2976,
        "lng": -122.3011
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5200,
      "name": "Versailles",
      "names": {
        "es": "Versalles",
        "zh": "凡尔赛",
        "ja": "ヴェルサイユ",
        "th": "แวร์ซาย",
        "pt": "Versalhes"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8053,
        "lng": 2.135
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 10588,
      "name": "Dapitan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.6549,
        "lng": 123.4243
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10592,
      "name": "Takayama",
      "names": {
        "zh": "高山",
        "ja": "高山",
        "th": "ทาคายามะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1458,
        "lng": 137.2522
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10584,
      "name": "Villingen-Schwenningen",
      "names": {
        "zh": "菲林根-施文宁根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0603,
        "lng": 8.4586
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5248,
      "name": "Whittier",
      "names": {
        "zh": "惠蒂尔",
        "ja": "ホイッティア",
        "th": "วิตทิเออร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9678,
        "lng": -118.0188
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5246,
      "name": "Louga",
      "names": {
        "zh": "卢加"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 15.6167,
        "lng": -16.2167
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 5118,
      "name": "Monte Chingolo",
      "names": {
        "zh": "蒙特Chingolo",
        "ja": "モンテチンノーロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7333,
        "lng": -58.35
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 5233,
      "name": "Piatra Neamt",
      "names": {
        "ja": "ピトラニアム"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.9275,
        "lng": 26.3708
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 5249,
      "name": "Clifton",
      "names": {
        "zh": "克利夫顿",
        "ja": "クリフトン",
        "th": "คลิฟตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8631,
        "lng": -74.1575
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5250,
      "name": "Puerto Maldonado",
      "names": {
        "zh": "波多黎各多人",
        "ja": "プエルトマルドナド",
        "th": "เปอร์โตมัลโดเนโด"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -12.6,
        "lng": -69.1833
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 10593,
      "name": "Derry",
      "names": {
        "zh": "德里",
        "ja": "デリー",
        "th": "เดอร์รี่"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.9917,
        "lng": -7.3417
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10598,
      "name": "Junin",
      "names": {
        "zh": "朱宁"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5939,
        "lng": -60.9464
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10596,
      "name": "Walvisbaai",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -22.9494,
        "lng": 14.5069
      },
      "country": "Namibia",
      "importance": 3
    },
    {
      "id": 10599,
      "name": "Korgas",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.1256,
        "lng": 80.4144
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10600,
      "name": "Repentigny",
      "names": {},
      "countryCode": "CA",
      "latLng": {
        "lat": 45.7333,
        "lng": -73.4667
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10553,
      "name": "Treviso",
      "names": {
        "fr": "Trévise",
        "zh": "特雷维索",
        "ja": "トレビソ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.6722,
        "lng": 12.2422
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10601,
      "name": "Largo",
      "names": {
        "zh": "缓慢地",
        "ja": "ラルゴ",
        "th": "เนิบ ๆ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.9088,
        "lng": -82.7711
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10602,
      "name": "Bloomington",
      "names": {
        "zh": "布卢明顿",
        "ja": "ブルーミントン",
        "th": "ในบลูมิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.8306,
        "lng": -93.3151
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10605,
      "name": "Jacobina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -11.1808,
        "lng": -40.5178
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10604,
      "name": "Ciudad Mante",
      "names": {
        "zh": "Ciudad宫廷"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.7333,
        "lng": -98.95
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10557,
      "name": "Konstanz",
      "names": {
        "fr": "Constance",
        "zh": "康斯坦茨",
        "ja": "コンスタンツ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.6633,
        "lng": 9.1753
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 10610,
      "name": "Paracatu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -17.2217,
        "lng": -46.875
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5251,
      "name": "Hetauda",
      "names": {
        "zh": "赫托达"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.4167,
        "lng": 85.0333
      },
      "country": "Nepal",
      "importance": 1
    },
    {
      "id": 10595,
      "name": "Sumenep",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -7.0049,
        "lng": 113.8496
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 10608,
      "name": "Guider",
      "names": {
        "de": "Pfadfinderinnenführerin"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 9.9342,
        "lng": 13.9486
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 10614,
      "name": "Johns Creek",
      "names": {
        "zh": "约翰溪",
        "ja": "ジョンズクリーク"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0333,
        "lng": -84.2027
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10611,
      "name": "Yokotemachi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 39.3137,
        "lng": 140.5666
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10615,
      "name": "Newport Beach",
      "names": {
        "es": "Playa de newport",
        "zh": "纽波特海滩",
        "id": "Pantai Newport",
        "ja": "ニューポートビーチ",
        "th": "นิวพอร์ตบีช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6151,
        "lng": -117.8669
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 785,
      "name": "Honiara",
      "names": {
        "zh": "霍尼亚拉",
        "ja": "ホニアラ"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -9.4333,
        "lng": 159.95
      },
      "country": "Solomon Islands",
      "importance": 0
    },
    {
      "id": 10572,
      "name": "Ushiku",
      "names": {
        "zh": "牛久"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9833,
        "lng": 140.15
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10609,
      "name": "Senador Canedo",
      "names": {
        "de": "Senador Caneedo",
        "it": "Senador canado",
        "th": "Senador Caneto"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -16.7078,
        "lng": -49.0928
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10612,
      "name": "Sabanalarga",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.63,
        "lng": -74.9236
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10617,
      "name": "Dmitrov",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.35,
        "lng": 37.5333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10560,
      "name": "Kaizuka",
      "names": {
        "zh": "贝冢"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4378,
        "lng": 135.3586
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10619,
      "name": "Serra Talhada",
      "names": {
        "zh": "塞拉塔哈达",
        "pt": "Serra Talha"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.9858,
        "lng": -38.2958
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5255,
      "name": "Nabunturan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6078,
        "lng": 125.9664
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 10622,
      "name": "Mission",
      "names": {
        "es": "Misión",
        "zh": "使命",
        "it": "Missione",
        "id": "Misi",
        "ja": "ミッション",
        "th": "ภารกิจ",
        "pt": "Missão"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.2041,
        "lng": -98.3251
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10613,
      "name": "Bais",
      "names": {
        "zh": "拜斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.5907,
        "lng": 123.1213
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10616,
      "name": "Villa Altagracia",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.67,
        "lng": -70.17
      },
      "country": "Dominican Republic",
      "importance": 3
    },
    {
      "id": 10606,
      "name": "Science City of Munoz",
      "names": {
        "zh": "慕尼黑科学城",
        "de": "Wissenschaftsstadt von Munoz",
        "it": "Science City di Munoz",
        "id": "Sains Kota Munoz",
        "ja": "Munozの科学都市",
        "th": "วิทยาศาสตร์เมือง Munoz",
        "pt": "Cidade da Ciência de Munoz"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7167,
        "lng": 120.9
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5171,
      "name": "Higashiyamato",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7456,
        "lng": 139.4267
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5203,
      "name": "Ayase",
      "names": {
        "zh": "绫濑"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.44,
        "lng": 139.4308
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5264,
      "name": "Newmarket",
      "names": {
        "zh": "纽马基特",
        "ja": "ニューマーケット",
        "th": "นิวมาร์เก็ต"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.05,
        "lng": -79.4667
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10621,
      "name": "Para de Minas",
      "names": {
        "ja": "パラ・デ・マイナー",
        "th": "พาราเดอมินส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.86,
        "lng": -44.6078
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5265,
      "name": "Milpitas",
      "names": {
        "zh": "米尔皮塔斯",
        "ja": "ミルピタス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.4339,
        "lng": -121.8921
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10623,
      "name": "Chigorodo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.6697,
        "lng": -76.6814
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5149,
      "name": "Wako",
      "names": {
        "zh": "和光",
        "ja": "和光"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7814,
        "lng": 139.6058
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5258,
      "name": "Osijek",
      "names": {
        "zh": "奥西耶克"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.5603,
        "lng": 18.6703
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 10629,
      "name": "Troy",
      "names": {
        "zh": "特洛伊",
        "ja": "トロイ",
        "th": "ทรอย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5818,
        "lng": -83.1457
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10630,
      "name": "Madera",
      "names": {
        "zh": "马德拉",
        "ja": "マデラ",
        "th": "มาเดรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.9631,
        "lng": -120.0782
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10594,
      "name": "Luancheng",
      "names": {
        "zh": "栾城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.8846,
        "lng": 114.6523
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10631,
      "name": "Joplin",
      "names": {
        "zh": "乔普林",
        "ja": "ジョプリン",
        "th": "จอปลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.0758,
        "lng": -94.5018
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5202,
      "name": "Darayya",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.4589,
        "lng": 36.2372
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 10591,
      "name": "Torrente",
      "names": {
        "zh": "多浪迪警官"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.4365,
        "lng": -0.4679
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10571,
      "name": "Kitakoriyamacho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6494,
        "lng": 135.7828
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10620,
      "name": "El Ejido",
      "names": {
        "ja": "エル・ユジド"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.7831,
        "lng": -2.8167
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5229,
      "name": "Ath Thawrah",
      "names": {
        "de": "Athtau thawrah",
        "th": "ทธาวาราห์"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.8344,
        "lng": 38.5464
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 10618,
      "name": "Bolinao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.3333,
        "lng": 119.8833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10603,
      "name": "Marl",
      "names": {
        "fr": "Marne",
        "zh": "马尔",
        "ja": "マール",
        "th": "ดินเหนียว"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6667,
        "lng": 7.1167
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 10607,
      "name": "Aruja",
      "names": {
        "pt": "Arujá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3967,
        "lng": -46.3211
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10625,
      "name": "El Milia",
      "names": {
        "zh": "埃尔米亚"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.75,
        "lng": 6.2667
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 10627,
      "name": "Cerete",
      "names": {
        "zh": "塞雷特"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.8867,
        "lng": -75.7911
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10583,
      "name": "Chita",
      "names": {
        "zh": "赤塔",
        "ja": "チタ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0,
        "lng": 136.8667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10586,
      "name": "Kineshma",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.45,
        "lng": 42.15
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10632,
      "name": "Zheleznogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.25,
        "lng": 93.5333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10635,
      "name": "Chino Hills",
      "names": {
        "es": "Colinas chino",
        "zh": "奇诺山",
        "id": "Bukit chino",
        "ja": "チノヒルズ",
        "pt": "Colinas chino"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9508,
        "lng": -117.7253
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5220,
      "name": "Xishancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.2589,
        "lng": 116.2388
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5164,
      "name": "Acayucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9422,
        "lng": -94.9103
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10639,
      "name": "Chilliwack",
      "names": {
        "zh": "奇利瓦克"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.1577,
        "lng": -121.9509
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5214,
      "name": "San Baudilio de Llobregat",
      "names": {
        "th": "ซาน Baudilio de llobregat"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.3458,
        "lng": 2.0417
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5273,
      "name": "Alhambra",
      "names": {
        "zh": "阿罕布拉",
        "ja": "アルハンブラ",
        "th": "บรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.084,
        "lng": -118.1355
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5267,
      "name": "Ho",
      "names": {
        "zh": "何",
        "ja": "ホー",
        "th": "โฮ"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 6.6114,
        "lng": 0.4703
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 5247,
      "name": "Mbarara",
      "names": {
        "zh": "巴拉拉"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.5996,
        "lng": 30.65
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 5081,
      "name": "Komae",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6347,
        "lng": 139.5786
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5266,
      "name": "Barahona",
      "names": {
        "zh": "巴拉奥纳"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.2079,
        "lng": -71.0996
      },
      "country": "Dominican Republic",
      "importance": 1
    },
    {
      "id": 10597,
      "name": "Moriyama",
      "names": {
        "zh": "森山",
        "ja": "森山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0589,
        "lng": 135.9944
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5205,
      "name": "Ciudad de Ceuta",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 35.8867,
        "lng": -5.3
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5256,
      "name": "Ouidah",
      "names": {
        "zh": "维达"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.3604,
        "lng": 2.09
      },
      "country": "Benin",
      "importance": 1
    },
    {
      "id": 5252,
      "name": "Chaguanas",
      "names": {},
      "countryCode": "TT",
      "latLng": {
        "lat": 10.5144,
        "lng": -61.4075
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 10633,
      "name": "Talavera de la Reina",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 39.9583,
        "lng": -4.8328
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5194,
      "name": "Aylesbury",
      "names": {
        "zh": "艾尔斯伯里",
        "ja": "アリスバーリー"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.8168,
        "lng": -0.8124
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5253,
      "name": "Nazareth",
      "names": {
        "es": "Nazaret",
        "zh": "拿撒勒",
        "id": "Nazaret",
        "ja": "ナザレス",
        "th": "นาซาเร็ ธ",
        "pt": "Nazaré"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.7021,
        "lng": 35.2978
      },
      "country": "Israel",
      "importance": 1
    },
    {
      "id": 10648,
      "name": "Taytay",
      "names": {
        "zh": "泰泰"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8167,
        "lng": 119.5167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10628,
      "name": "Worms",
      "names": {
        "es": "Gusanos",
        "fr": "Vers",
        "zh": "蠕虫",
        "de": "Würmer",
        "it": "Vermi",
        "id": "Cacing",
        "ja": "ワーム",
        "th": "หนอน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6319,
        "lng": 8.3653
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5277,
      "name": "Resita",
      "names": {
        "zh": "雷希察"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.297,
        "lng": 21.8865
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 10651,
      "name": "Santana do Livramento",
      "names": {
        "zh": "桑塔纳做Livramento",
        "id": "Santana do livramamo",
        "ja": "サンタナはリブラメントを行います"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -30.8908,
        "lng": -55.5328
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5240,
      "name": "Como",
      "names": {
        "fr": "Côme",
        "zh": "科莫",
        "ja": "コモ",
        "th": "โคโม"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.8103,
        "lng": 9.0861
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10649,
      "name": "Serrinha",
      "names": {
        "zh": "塞里尼亚"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.6639,
        "lng": -39.0078
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10645,
      "name": "Maco",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.3619,
        "lng": 125.8553
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10626,
      "name": "Lqoliaa",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.2942,
        "lng": -9.4544
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10644,
      "name": "Fredrikstad",
      "names": {
        "zh": "弗雷德里克斯塔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.2167,
        "lng": 10.95
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 10637,
      "name": "Sirsilla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.38,
        "lng": 78.83
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10638,
      "name": "Pontevedra",
      "names": {
        "zh": "庞特维德拉",
        "ja": "ポンテベドラ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.4333,
        "lng": -8.6333
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 10659,
      "name": "Franklin",
      "names": {
        "zh": "富兰克林",
        "ja": "フランクリン",
        "th": "แฟรงคลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9216,
        "lng": -86.8525
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10652,
      "name": "Siaton",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.0667,
        "lng": 123.0333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10660,
      "name": "Coari",
      "names": {
        "zh": "科阿里"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.085,
        "lng": -63.1408
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10640,
      "name": "San Antonio",
      "names": {
        "zh": "圣安东尼奥",
        "ja": "サンアントニオ",
        "th": "ซานอันโตนิโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3833,
        "lng": 120.8
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5238,
      "name": "Busto Arsizio",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 45.612,
        "lng": 8.8518
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 10662,
      "name": "Melbourne",
      "names": {
        "zh": "墨尔本",
        "ja": "メルボルン",
        "th": "เมลเบิร์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.1085,
        "lng": -80.6627
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10654,
      "name": "Sao Sebastiao",
      "names": {
        "zh": "圣塞巴斯蒂亚",
        "ja": "サンセバスチオ",
        "pt": "São Sebastião"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.804,
        "lng": -45.4051
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10650,
      "name": "Wutiancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1852,
        "lng": 116.4757
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5222,
      "name": "Mingxing",
      "names": {
        "zh": "明星"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.4264,
        "lng": 112.5442
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10666,
      "name": "Port Huron",
      "names": {
        "es": "Puerto huron",
        "zh": "侯尔港",
        "de": "Huron Port",
        "it": "Porto Huron",
        "ja": "ポートヒューロン",
        "th": "พอร์ตฮูรอน",
        "pt": "Porto huron"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.9822,
        "lng": -82.4387
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10643,
      "name": "Velez-Malaga",
      "names": {
        "es": "Vélez-Málaga",
        "fr": "Velez Malaga-",
        "zh": "贝莱斯-马拉加",
        "de": "Vélez-Málaga",
        "it": "-Velez Malaga",
        "ja": "velez-マラガ",
        "th": "velez-มาลากา",
        "pt": "Velez-Málaga"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.7833,
        "lng": -4.1
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10653,
      "name": "Tual",
      "names": {
        "zh": "虚拟"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -5.6385,
        "lng": 132.745
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 10665,
      "name": "Mabinay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.7333,
        "lng": 122.9167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10636,
      "name": "Nantingcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 20.804,
        "lng": 110.0826
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5279,
      "name": "Antsiranana",
      "names": {
        "zh": "安齐拉纳纳"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -12.2765,
        "lng": 49.3115
      },
      "country": "Madagascar",
      "importance": 1
    },
    {
      "id": 10664,
      "name": "Al Hayy",
      "names": {
        "es": "Al hueco",
        "ja": "アルハイイ",
        "th": "อัลเฮย์",
        "pt": "Al heady"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.1742,
        "lng": 46.0433
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 10656,
      "name": "Seropedica",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7439,
        "lng": -43.7078
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5289,
      "name": "Upper Darby",
      "names": {
        "es": "Darby superior",
        "fr": "Haut Darby",
        "zh": "上达比",
        "de": "Oberer Darby",
        "it": "Superiore darby",
        "id": "Darby atas",
        "ja": "アッパーダービー",
        "th": "ดาร์บี้บน",
        "pt": "Alta Darby"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.949,
        "lng": -75.2892
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10673,
      "name": "Colonie",
      "names": {
        "zh": "科勒尼",
        "ja": "コロニー",
        "th": "โคโลนี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7396,
        "lng": -73.7874
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10634,
      "name": "Torrevieja",
      "names": {
        "zh": "托雷维耶哈"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.9778,
        "lng": -0.6833
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10674,
      "name": "Barra do Corda",
      "names": {
        "es": "Barra do corña",
        "zh": "巴拉做电池",
        "pt": "Barra do Cata"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.5058,
        "lng": -45.2428
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10676,
      "name": "Springfield",
      "names": {
        "zh": "斯普林菲尔德",
        "ja": "スプリングフィールド",
        "th": "สปริงฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9297,
        "lng": -83.7957
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10658,
      "name": "Marsala",
      "names": {
        "zh": "马尔萨拉",
        "ja": "マルサラ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.7981,
        "lng": 12.4342
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 10677,
      "name": "Sorriso",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.545,
        "lng": -55.7108
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10675,
      "name": "Shirvan",
      "names": {
        "zh": "希尔凡"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 37.3967,
        "lng": 57.9294
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 5254,
      "name": "Uman",
      "names": {
        "zh": "人力"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.75,
        "lng": 30.2167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 5292,
      "name": "Mountain View",
      "names": {
        "es": "Vista de la montaña",
        "zh": "山顶风光",
        "de": "Bergblick",
        "it": "Vista delle montagne",
        "id": "Pemandangan gunung",
        "ja": "マウンテンビュー",
        "th": "วิวภูเขา",
        "pt": "Vista para a montanha"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.4,
        "lng": -122.0796
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10678,
      "name": "Stockton-on-Tees",
      "names": {
        "es": "Stockton-on-T-shirt",
        "fr": "Stockton-sur-t-shirts",
        "zh": "斯托克顿对三通",
        "de": "Stockton-on-T-Shirts",
        "ja": "ストックトン・オン・ティー",
        "th": "สต็อกตันบนประเดิม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.57,
        "lng": -1.32
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10671,
      "name": "Nakatsu",
      "names": {
        "zh": "中津"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5983,
        "lng": 131.1883
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5274,
      "name": "Zilina",
      "names": {
        "zh": "日利纳"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.2167,
        "lng": 18.7333
      },
      "country": "Slovakia",
      "importance": 1
    },
    {
      "id": 10667,
      "name": "Tanjay",
      "names": {
        "zh": "坦哈伊"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.5167,
        "lng": 123.1583
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10681,
      "name": "Cruzeiro do Sul",
      "names": {
        "it": "Cruzeiro dole",
        "ja": "クルゼイロドスル"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.6308,
        "lng": -72.67
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10646,
      "name": "Mian Channun",
      "names": {
        "es": "MIAN CANNANUN",
        "fr": "Mian cannun",
        "id": "Channun Mian",
        "ja": "ミアンチャンチン",
        "pt": "Mian Channon"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 30.4397,
        "lng": 72.3544
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 5170,
      "name": "Fuengirola",
      "names": {
        "zh": "福恩吉罗拉",
        "ja": "フエンヒローラ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.5417,
        "lng": -4.625
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5242,
      "name": "Manfalut",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 27.3128,
        "lng": 30.9703
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 10642,
      "name": "Arona",
      "names": {
        "zh": "阿罗纳",
        "ja": "アロナ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 28.0996,
        "lng": -16.6809
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10655,
      "name": "Bhalwal",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.2653,
        "lng": 72.9028
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10669,
      "name": "Karatepe",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6883,
        "lng": 30.0144
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 10680,
      "name": "Calatrava",
      "names": {
        "zh": "卡拉特拉瓦"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6,
        "lng": 123.4833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10657,
      "name": "Wuyi",
      "names": {
        "zh": "五邑",
        "ja": "武夷"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7965,
        "lng": 115.892
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5287,
      "name": "Targu Jiu",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 45.0342,
        "lng": 23.2747
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 5293,
      "name": "Shumen",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.2746,
        "lng": 26.9349
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 10668,
      "name": "Cayirova",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8265,
        "lng": 29.3745
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 10685,
      "name": "El Estor",
      "names": {
        "it": "El estore",
        "ja": "エスコール"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.5333,
        "lng": -89.35
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 10690,
      "name": "Zephyrhills",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 28.2408,
        "lng": -82.1796
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10688,
      "name": "Patrocinio",
      "names": {
        "zh": "帕特罗西尼乌",
        "pt": "Patrocínio"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -18.9439,
        "lng": -46.9928
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10624,
      "name": "Cukai",
      "names": {},
      "countryCode": "MY",
      "latLng": {
        "lat": 4.2332,
        "lng": 103.4479
      },
      "country": "Malaysia",
      "importance": 3
    },
    {
      "id": 5259,
      "name": "Chervonohrad",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.3822,
        "lng": 24.2275
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 10692,
      "name": "Chaykovskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.7667,
        "lng": 54.1167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5207,
      "name": "Sabaneta",
      "names": {
        "zh": "萨瓦内塔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1508,
        "lng": -75.615
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5296,
      "name": "White Rock",
      "names": {
        "es": "Roca blanca",
        "fr": "Rock blanc",
        "zh": "白色岩石",
        "de": "Weißer Felsen",
        "it": "Roccia bianca",
        "id": "Batu bakar",
        "ja": "ホワイトロック",
        "th": "หินสีขาว",
        "pt": "Rocha branca"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.025,
        "lng": -122.8028
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10691,
      "name": "Campo Mourao",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -24.0458,
        "lng": -52.3828
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10693,
      "name": "Oleksandriia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6667,
        "lng": 33.1167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 5232,
      "name": "Owariasahi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2167,
        "lng": 137.0333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10686,
      "name": "Shikokuchuo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.9808,
        "lng": 133.5492
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5236,
      "name": "Kuznetsk",
      "names": {
        "zh": "库兹涅茨克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.1167,
        "lng": 46.6
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10696,
      "name": "Maple Ridge",
      "names": {
        "es": "Mapa de arce",
        "fr": "Mape crête",
        "zh": "枫树山",
        "de": "Ahornkamm",
        "it": "Acero",
        "ja": "メープルリッジ",
        "th": "สันเขาเมเปิ้ล",
        "pt": "Ridge Maple"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2167,
        "lng": -122.6
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 4753,
      "name": "Courbevoic",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8978,
        "lng": 2.2531
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 10699,
      "name": "St. Augustine",
      "names": {
        "es": "San Agustín",
        "fr": "Saint Augustin",
        "zh": "圣奥古斯丁",
        "de": "St. Augustinus",
        "it": "Sant\u0027Agostino",
        "ja": "セントオーガスティン",
        "th": "เซนต์ออกัสติน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.8977,
        "lng": -81.31
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5290,
      "name": "Abomey",
      "names": {
        "zh": "阿博梅"
      },
      "countryCode": "BJ",
      "latLng": {
        "lat": 7.1853,
        "lng": 1.9914
      },
      "country": "Benin",
      "importance": 1
    },
    {
      "id": 5224,
      "name": "Saint Albans",
      "names": {
        "es": "San Albans",
        "zh": "圣奥尔本",
        "ja": "セントアルバンス",
        "th": "นักบุญอัลบันส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.755,
        "lng": -0.336
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10703,
      "name": "Peterborough",
      "names": {
        "zh": "彼得伯勒",
        "ja": "ピーターバラ",
        "th": "ปีเตอร์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.3,
        "lng": -78.3167
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 10695,
      "name": "Bekobod",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.2258,
        "lng": 69.2292
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 10702,
      "name": "Harlow",
      "names": {
        "zh": "哈罗",
        "ja": "ハーロー",
        "th": "ฮาร์โลว์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.779,
        "lng": 0.128
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10698,
      "name": "Grosseto",
      "names": {
        "zh": "格罗塞托",
        "ja": "グロッセート"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.7722,
        "lng": 11.1089
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 10687,
      "name": "Carmen",
      "names": {
        "zh": "卡门",
        "ja": "カルメン",
        "th": "การ์เมน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.3606,
        "lng": 125.7064
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5218,
      "name": "Burnley",
      "names": {
        "zh": "伯恩利",
        "ja": "バーンリー",
        "th": "เบิร์นลี่ย์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.789,
        "lng": -2.248
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10661,
      "name": "Velbert",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.34,
        "lng": 7.0416
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 10710,
      "name": "Ust\u0027-Ilimsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.0,
        "lng": 102.6667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10711,
      "name": "Flagstaff",
      "names": {
        "zh": "弗拉格斯塔夫",
        "ja": "フラッグスタッフ",
        "th": "เสาธง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1872,
        "lng": -111.6195
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10647,
      "name": "Redditch",
      "names": {
        "zh": "雷迪奇"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.3,
        "lng": -1.9333
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10679,
      "name": "Kongjiazhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.7536,
        "lng": 114.7237
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10712,
      "name": "Presidencia Roque Saenz Pena",
      "names": {
        "zh": "Primidencia Roque Saenz Pena",
        "de": "Pressidencia Roque Saenz Pena"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.7833,
        "lng": -60.45
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10715,
      "name": "Aracruz",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.82,
        "lng": -40.2728
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10705,
      "name": "Ubay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.056,
        "lng": 124.4729
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5305,
      "name": "Buena Park",
      "names": {
        "zh": "布埃纳公园",
        "id": "Taman Buena",
        "ja": "ブエナパーク",
        "th": "สวนบัว",
        "pt": "Parque de buena"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8572,
        "lng": -118.0046
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10720,
      "name": "Pleasanton",
      "names": {
        "zh": "普莱森顿",
        "ja": "プレザントン",
        "th": "เพลเซนตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6663,
        "lng": -121.8805
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5195,
      "name": "Sesto San Giovanni",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5333,
        "lng": 9.2333
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 5306,
      "name": "Silver Spring",
      "names": {
        "es": "Primavera de plata",
        "fr": "Printemps argenté",
        "zh": "银泉",
        "de": "Silberfeder",
        "it": "Molla d\u0027argento",
        "id": "Pegas perak",
        "ja": "シルバースプリング",
        "th": "สปริงเงิน",
        "pt": "Primavera prata"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0028,
        "lng": -77.0207
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10716,
      "name": "Agboville",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 5.9333,
        "lng": -4.2167
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 10641,
      "name": "Yurga",
      "names": {
        "zh": "尤尔加"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7333,
        "lng": 84.9
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10714,
      "name": "Lujan",
      "names": {
        "zh": "卢汉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5703,
        "lng": -59.105
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10672,
      "name": "Bhaktapur",
      "names": {
        "zh": "巴克塔普尔",
        "ja": "バクタプル"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.6722,
        "lng": 85.4278
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 10722,
      "name": "Felipe Carrillo Puerto",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5786,
        "lng": -88.0453
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5260,
      "name": "Lucerne",
      "names": {
        "es": "Lucerna",
        "zh": "卢塞恩",
        "de": "Luzern",
        "it": "Lucerna",
        "ja": "ルツェルン",
        "th": "ลูเซิร์น"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.0523,
        "lng": 8.3059
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 10701,
      "name": "Siasi",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.5462,
        "lng": 120.8145
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10689,
      "name": "Minden",
      "names": {
        "zh": "明登",
        "ja": "ミンデン",
        "th": "มินเดิน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2883,
        "lng": 8.9167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10721,
      "name": "Xindian",
      "names": {
        "zh": "新店"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.3172,
        "lng": 101.5446
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10723,
      "name": "Planaltina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -15.4528,
        "lng": -47.6139
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10719,
      "name": "Watampone",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -4.5328,
        "lng": 120.3334
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 10682,
      "name": "Katoya",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.65,
        "lng": 88.13
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10724,
      "name": "Paranavai",
      "names": {
        "pt": "Paranavaí"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.0728,
        "lng": -52.465
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10684,
      "name": "Muroran",
      "names": {
        "zh": "室兰",
        "ja": "室蘭"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 42.3153,
        "lng": 140.9736
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5192,
      "name": "Apizaco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4167,
        "lng": -98.1333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10732,
      "name": "Atascocita",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 29.9777,
        "lng": -95.1953
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10683,
      "name": "Omihachiman",
      "names": {
        "ja": "近江八幡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1283,
        "lng": 136.0981
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10731,
      "name": "General Roca",
      "names": {
        "fr": "Général Roca",
        "zh": "一般罗卡",
        "it": "Generale Roca",
        "ja": "一般的なロカ",
        "th": "นายพล roca"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -39.0333,
        "lng": -67.5833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10736,
      "name": "Westland",
      "names": {
        "zh": "西部地区",
        "ja": "ウェストランド",
        "th": "เวสต์แลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3192,
        "lng": -83.3805
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10733,
      "name": "Kentau",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.5169,
        "lng": 68.5044
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 5161,
      "name": "Remedios de Escalada",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7167,
        "lng": -58.4
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 10739,
      "name": "Auburn",
      "names": {
        "zh": "奥本",
        "ja": "オーバーン",
        "th": "สีน้ำตาลแดง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.3039,
        "lng": -122.2108
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10740,
      "name": "Cranston",
      "names": {
        "zh": "克兰斯顿",
        "ja": "クランストン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7658,
        "lng": -71.4857
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5167,
      "name": "Xicotepec de Juarez",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3,
        "lng": -97.9667
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 10727,
      "name": "Anapa",
      "names": {
        "zh": "阿纳帕"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.8944,
        "lng": 37.3167
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 10729,
      "name": "Matao",
      "names": {
        "pt": "Matão"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.6033,
        "lng": -48.3658
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5313,
      "name": "Hakkari",
      "names": {
        "zh": "哈卡里"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5744,
        "lng": 43.7408
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 10725,
      "name": "Shuibian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.1263,
        "lng": 112.7668
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10670,
      "name": "Xiedian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.419,
        "lng": 110.8281
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10709,
      "name": "Caldas",
      "names": {
        "zh": "卡尔达斯",
        "ja": "カルダス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0886,
        "lng": -75.6361
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5315,
      "name": "Somerville",
      "names": {
        "zh": "萨默维尔",
        "ja": "サマービル",
        "th": "วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3908,
        "lng": -71.1013
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10728,
      "name": "Kimitsu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3306,
        "lng": 139.9025
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10735,
      "name": "Matalam",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0833,
        "lng": 124.9
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10744,
      "name": "Senhor do Bonfim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -10.4628,
        "lng": -40.1908
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10749,
      "name": "Folsom",
      "names": {
        "zh": "福尔瑟姆"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6668,
        "lng": -121.1422
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10726,
      "name": "Buhi",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4333,
        "lng": 123.5167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10745,
      "name": "Athi River",
      "names": {
        "es": "Río athi",
        "fr": "Rivière Athri",
        "zh": "萨尼河",
        "it": "Fiume Athi",
        "id": "Sungai Athi",
        "ja": "アティ川",
        "th": "แม่น้ำ Athi",
        "pt": "Rio athi"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.45,
        "lng": 36.9833
      },
      "country": "Kenya",
      "importance": 3
    },
    {
      "id": 10718,
      "name": "Timoteo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.5828,
        "lng": -42.6439
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10730,
      "name": "Dessau-Rosslau",
      "names": {
        "zh": "德绍-罗斯劳",
        "ja": "デッサウ-rosslau",
        "th": "เดสเซา-rosslau"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8333,
        "lng": 12.2333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10742,
      "name": "Lambunao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.05,
        "lng": 122.4833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10694,
      "name": "Bulacan",
      "names": {
        "zh": "布拉干",
        "th": "คัน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.7928,
        "lng": 120.8789
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10746,
      "name": "Novouralsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.25,
        "lng": 60.0833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10663,
      "name": "Pozzuoli",
      "names": {
        "zh": "波佐利"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.8231,
        "lng": 14.1222
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 10754,
      "name": "Springdale",
      "names": {
        "zh": "斯普林代尔",
        "ja": "スプリングデール"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1899,
        "lng": -94.1574
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10700,
      "name": "Pilar",
      "names": {
        "zh": "皮拉尔",
        "ja": "ピラル",
        "th": "ปีลาร์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.4588,
        "lng": -58.9142
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10706,
      "name": "Hengkou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.7378,
        "lng": 108.7695
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10734,
      "name": "Ita",
      "names": {
        "th": "ก็"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.4833,
        "lng": -57.35
      },
      "country": "Paraguay",
      "importance": 3
    },
    {
      "id": 10753,
      "name": "Kapalong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.5854,
        "lng": 125.7052
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5320,
      "name": "Deerfield Beach",
      "names": {
        "es": "Playa Deerfield",
        "zh": "迪尔菲尔德海滩",
        "id": "Pantai Deerfield",
        "ja": "ディアフィールドビーチ",
        "th": "หาด Deerfield",
        "pt": "Praia deerfield"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.305,
        "lng": -80.1277
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5212,
      "name": "Salina Cruz",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.1949,
        "lng": -95.2151
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 10751,
      "name": "Panzos",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3986,
        "lng": -89.6408
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 5263,
      "name": "Tecate",
      "names": {
        "zh": "塔咖提"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 32.5728,
        "lng": -116.6267
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10756,
      "name": "Warwick",
      "names": {
        "zh": "华威",
        "ja": "ワーウィック",
        "th": "วอร์วิก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7062,
        "lng": -71.4334
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10697,
      "name": "La Louviere",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.4778,
        "lng": 4.1881
      },
      "country": "Belgium",
      "importance": 3
    },
    {
      "id": 10704,
      "name": "Funza",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7175,
        "lng": -74.2094
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5323,
      "name": "Natitingou",
      "names": {},
      "countryCode": "BJ",
      "latLng": {
        "lat": 10.3204,
        "lng": 1.39
      },
      "country": "Benin",
      "importance": 1
    },
    {
      "id": 10717,
      "name": "Campo Limpo",
      "names": {
        "id": "Limpo campo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2064,
        "lng": -46.7844
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5327,
      "name": "Cicero",
      "names": {
        "fr": "Cicéron",
        "zh": "西塞罗",
        "it": "Cicerone",
        "ja": "シセロ",
        "th": "ซิเซโร",
        "pt": "Cícero"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8445,
        "lng": -87.7593
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10760,
      "name": "Yonezawa",
      "names": {
        "zh": "米泽",
        "ja": "米澤"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.9167,
        "lng": 140.1167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10764,
      "name": "Polatsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.4833,
        "lng": 28.8
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 10758,
      "name": "Kashiwazaki",
      "names": {
        "zh": "柏崎",
        "ja": "柏崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.3719,
        "lng": 138.559
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10743,
      "name": "Shiji",
      "names": {
        "zh": "世纪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.5607,
        "lng": 112.963
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10738,
      "name": "Gobernador Galvez",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -33.0251,
        "lng": -60.6337
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 10759,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.505,
        "lng": 125.9771
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5276,
      "name": "Esteio",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.8608,
        "lng": -51.1789
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10762,
      "name": "Malapatan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.9667,
        "lng": 125.2833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10713,
      "name": "Azov",
      "names": {
        "zh": "亚速",
        "de": "Asowschen"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.1,
        "lng": 39.4167
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 10747,
      "name": "Barili",
      "names": {
        "zh": "巴里利"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1167,
        "lng": 123.5333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10765,
      "name": "Meybod",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.25,
        "lng": 54.0167
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 5328,
      "name": "Atakpame",
      "names": {},
      "countryCode": "TG",
      "latLng": {
        "lat": 7.53,
        "lng": 1.12
      },
      "country": "Togo",
      "importance": 1
    },
    {
      "id": 10708,
      "name": "Hengelo",
      "names": {
        "zh": "亨厄洛"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2656,
        "lng": 6.7931
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 10741,
      "name": "Yanggao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 21.3298,
        "lng": 109.9177
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10766,
      "name": "Ipojuca",
      "names": {
        "th": "อีโปะจูกา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.4,
        "lng": -35.0625
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5308,
      "name": "Daugavpils",
      "names": {
        "zh": "陶格夫匹尔斯"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 55.8714,
        "lng": 26.5161
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 10755,
      "name": "San Cristobal Verapaz",
      "names": {
        "es": "San Cristóbal Verapaz",
        "ja": "サンクリストバルベラパズ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.365,
        "lng": -90.4792
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 10770,
      "name": "Farmington Hills",
      "names": {
        "fr": "Collines de Farmington",
        "id": "Bukit Farmington",
        "ja": "ファーミントンヒルズ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.486,
        "lng": -83.3771
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10707,
      "name": "Varese",
      "names": {
        "zh": "瓦雷泽",
        "ja": "ヴァレーズ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.8167,
        "lng": 8.8333
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 5275,
      "name": "Batley",
      "names": {
        "zh": "巴特利"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.7167,
        "lng": -1.6356
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10776,
      "name": "Necochea",
      "names": {
        "zh": "内科切阿"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.55,
        "lng": -58.7333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10778,
      "name": "Newark",
      "names": {
        "zh": "纽瓦克",
        "ja": "ニューアーク",
        "th": "นวร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0705,
        "lng": -82.4251
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10779,
      "name": "Williamsburg",
      "names": {
        "zh": "威廉斯堡",
        "ja": "ウィリアムズバーグ",
        "th": "วิลเลียม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.2692,
        "lng": -76.7076
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5257,
      "name": "Nagaoka",
      "names": {
        "zh": "长冈",
        "ja": "長岡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9267,
        "lng": 135.6956
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10781,
      "name": "Brooklyn Park",
      "names": {
        "es": "Parque de Brooklyn",
        "fr": "Parc de Brooklyn",
        "zh": "布鲁克林公园",
        "id": "Taman Brooklyn",
        "ja": "ブルックリンパーク",
        "th": "บรูคลินพาร์ค",
        "pt": "Parque Brooklyn"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.1112,
        "lng": -93.3505
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5176,
      "name": "Tala",
      "names": {
        "zh": "塔拉",
        "ja": "ターラ",
        "th": "แอด"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6525,
        "lng": -103.7028
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5330,
      "name": "Cabadbaran",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.1228,
        "lng": 125.5346
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 10782,
      "name": "Uvinza",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.1036,
        "lng": 30.3911
      },
      "country": "Tanzania",
      "importance": 3
    },
    {
      "id": 10767,
      "name": "Kilifi",
      "names": {
        "zh": "基利菲"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -3.6333,
        "lng": 39.85
      },
      "country": "Kenya",
      "importance": 3
    },
    {
      "id": 10757,
      "name": "Tagoloan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.5333,
        "lng": 124.75
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10737,
      "name": "Kamareddipet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.3167,
        "lng": 78.35
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5333,
      "name": "Zuwarah",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 32.9333,
        "lng": 12.0833
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 10783,
      "name": "Takestan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.0694,
        "lng": 49.6958
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 10771,
      "name": "Echizen",
      "names": {
        "zh": "越前",
        "ja": "越前"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9033,
        "lng": 136.1692
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10785,
      "name": "Livingston",
      "names": {
        "fr": "De Livingston",
        "zh": "利文斯顿",
        "ja": "リビングストン",
        "th": "ลิฟวิงสตัน"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.83,
        "lng": -88.75
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 10763,
      "name": "Los Patios",
      "names": {
        "zh": "洛杉矶庭院",
        "it": "Los Patio",
        "id": "Los Patio",
        "ja": "ロスパティオ",
        "pt": "Los pátios"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.8342,
        "lng": -72.505
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10761,
      "name": "Bolpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.67,
        "lng": 87.72
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10786,
      "name": "Mbalmayo",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 3.52,
        "lng": 11.5122
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 10752,
      "name": "Taroudannt",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.4711,
        "lng": -8.8778
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10787,
      "name": "Namsan",
      "names": {
        "zh": "南山"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 42.2304,
        "lng": 129.2304
      },
      "country": "North Korea",
      "importance": 2
    },
    {
      "id": 10791,
      "name": "Hattiesburg",
      "names": {
        "zh": "哈蒂斯堡",
        "th": "แฮตติสบูร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.3074,
        "lng": -89.317
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10774,
      "name": "Gumla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.0444,
        "lng": 84.5417
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10780,
      "name": "Simdega",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.62,
        "lng": 84.52
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10792,
      "name": "Alexandria",
      "names": {
        "es": "Alejandría",
        "fr": "Alexandrie",
        "zh": "亚历山大",
        "it": "Alessandria",
        "ja": "アレクサンドリア",
        "th": "ซานเดรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.2923,
        "lng": -92.4702
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5282,
      "name": "Purmerend",
      "names": {
        "zh": "皮尔默伦德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.505,
        "lng": 4.9639
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 10794,
      "name": "Meiganga",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 6.5172,
        "lng": 14.2947
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 5338,
      "name": "Lawrence",
      "names": {
        "fr": "Laurent",
        "zh": "劳伦斯",
        "ja": "ローレンス",
        "th": "ลอเรนซ์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7003,
        "lng": -71.1626
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5186,
      "name": "Nabatiye",
      "names": {},
      "countryCode": "LB",
      "latLng": {
        "lat": 33.3833,
        "lng": 35.45
      },
      "country": "Lebanon",
      "importance": 1
    },
    {
      "id": 5336,
      "name": "Puerto Ayacucho",
      "names": {
        "ja": "プエルトアヤクチョ",
        "th": "เปอร์โต Ayacucho"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 5.6631,
        "lng": -67.6264
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 10788,
      "name": "Zarzis",
      "names": {
        "zh": "扎尔纪斯"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 33.5,
        "lng": 11.1167
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 10789,
      "name": "Andahuaylas",
      "names": {
        "zh": "安达韦拉斯"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.6575,
        "lng": -73.3833
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 10790,
      "name": "Jamshoro",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 25.4283,
        "lng": 68.2822
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 10793,
      "name": "Tall `Afar",
      "names": {
        "es": "Alto \u0027lejos",
        "fr": "Grand `Afar",
        "zh": "高大的人",
        "de": "Groß `fern",
        "it": "Alto `lontano",
        "id": "Tinggi `jauh",
        "ja": "背の高い `遠く",
        "th": "สูง `ไกล",
        "pt": "Alto \u0027longe"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.3792,
        "lng": 42.4497
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 10796,
      "name": "Balad",
      "names": {
        "zh": "拜莱德"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 34.0147,
        "lng": 44.1456
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 10801,
      "name": "San Carlos del Zulia",
      "names": {
        "zh": "圣卡洛斯德尔朱莉娅",
        "ja": "サンカルロスデルズリア"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.0,
        "lng": -71.95
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 10802,
      "name": "Koktokay",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 47.0004,
        "lng": 89.4666
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10803,
      "name": "Capenda Camulemba",
      "names": {
        "de": "CADENDA CAMUEMEMBA",
        "pt": "Capendá Camulemba"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -9.4233,
        "lng": 18.4328
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 5284,
      "name": "Scunthorpe",
      "names": {
        "zh": "斯肯索普",
        "th": "สคันธอร์ป"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.5809,
        "lng": -0.6502
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10805,
      "name": "Florence",
      "names": {
        "es": "Florencia",
        "zh": "佛罗伦萨",
        "de": "Florenz",
        "it": "Firenze",
        "ja": "フィレンツェ",
        "th": "ฟลอเรนซ์",
        "pt": "Florença"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.8303,
        "lng": -87.6654
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10808,
      "name": "Ben Gardane",
      "names": {
        "es": "Ben Garnene",
        "zh": "本加德纳",
        "ja": "ベンガラネ"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 33.1389,
        "lng": 11.2167
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 10750,
      "name": "Sefrou",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.83,
        "lng": -4.83
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10748,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8772,
        "lng": 121.105
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10772,
      "name": "Sokcho",
      "names": {
        "zh": "束草"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 38.2083,
        "lng": 128.5911
      },
      "country": "South Korea",
      "importance": 2
    },
    {
      "id": 10812,
      "name": "Valdosta",
      "names": {
        "zh": "瓦尔多斯塔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.8502,
        "lng": -83.2788
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10784,
      "name": "Kottagudem",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.55,
        "lng": 80.63
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5337,
      "name": "Chiquimula",
      "names": {
        "zh": "奇基穆拉"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7833,
        "lng": -89.5333
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 10809,
      "name": "Idah",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.0833,
        "lng": 6.75
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 10811,
      "name": "Baracoa",
      "names": {
        "zh": "巴拉科阿"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.3444,
        "lng": -74.4956
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 10777,
      "name": "Arsuz",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.4128,
        "lng": 35.8867
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 10814,
      "name": "Plymouth",
      "names": {
        "zh": "普利茅斯",
        "ja": "プリマス",
        "th": "พลีมัธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.0225,
        "lng": -93.4618
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10797,
      "name": "Poblacion",
      "names": {
        "zh": "波夫拉西翁"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.8,
        "lng": 124.6333
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 5231,
      "name": "Ciudad Lerdo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.55,
        "lng": -103.5167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10807,
      "name": "Jinhe",
      "names": {
        "zh": "金河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.7815,
        "lng": 103.228
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10804,
      "name": "Fiumicino",
      "names": {
        "zh": "菲乌米奇诺",
        "ja": "フィウミチーノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.7667,
        "lng": 12.2333
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 10820,
      "name": "Buckeye",
      "names": {
        "zh": "橡树",
        "ja": "トチノキ",
        "th": "โอไฮโอ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4314,
        "lng": -112.6429
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5321,
      "name": "Targoviste",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 44.9244,
        "lng": 25.4572
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 10821,
      "name": "Georgetown",
      "names": {
        "zh": "乔治敦",
        "ja": "ジョージタウン",
        "th": "จอร์จทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.6668,
        "lng": -97.6953
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10769,
      "name": "Akiruno",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7289,
        "lng": 139.2942
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10817,
      "name": "Manhuacu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.2581,
        "lng": -42.0336
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10813,
      "name": "Patikul",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.0667,
        "lng": 121.1
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5221,
      "name": "Ezpeleta",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7517,
        "lng": -58.2344
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 5307,
      "name": "Bani Mazar",
      "names": {
        "zh": "巴尼马扎尔"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 28.5036,
        "lng": 30.8003
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 5346,
      "name": "Mahdia",
      "names": {
        "zh": "马赫迪耶",
        "th": "มาห์"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.5047,
        "lng": 11.0622
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 10806,
      "name": "Mabai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.0188,
        "lng": 104.3929
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10799,
      "name": "Vila do Conde",
      "names": {
        "it": "Vila do cond",
        "ja": "ヴィラドーコン"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.35,
        "lng": -8.75
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 5322,
      "name": "Galway",
      "names": {
        "zh": "戈尔韦",
        "ja": "ゴールウェイ",
        "th": "กัลเวย์"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.2729,
        "lng": -9.0418
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 10810,
      "name": "Robles",
      "names": {
        "zh": "罗布莱斯",
        "th": "โรเบิลส์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.35,
        "lng": 123.0667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10773,
      "name": "Neumunster",
      "names": {
        "es": "Neumünster",
        "fr": "Neumünster",
        "de": "Neumünster",
        "it": "Neumünster",
        "id": "Neumünster",
        "pt": "Neumünster"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.0714,
        "lng": 9.99
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5347,
      "name": "Cheyenne",
      "names": {
        "zh": "夏延",
        "ja": "シャイアン",
        "th": "ไชเอนน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1351,
        "lng": -104.79
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 10823,
      "name": "Alfenas",
      "names": {
        "ja": "アルフェナス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.4289,
        "lng": -45.9469
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10818,
      "name": "Torres Vedras",
      "names": {
        "zh": "托雷斯·瓦德拉斯",
        "id": "Vedras torres"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.0833,
        "lng": -9.2667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 10830,
      "name": "Cedar Park",
      "names": {
        "es": "Cedro",
        "fr": "Cèdre",
        "zh": "雪松公园",
        "it": "Parco di cedro",
        "id": "Taman Cedar",
        "ja": "シーダーパーク",
        "th": "ซีดาร์พาร์ค",
        "pt": "Parque de cedro"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.5106,
        "lng": -97.8196
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10815,
      "name": "Yalta",
      "names": {
        "zh": "雅尔塔",
        "de": "Jalta",
        "th": "ยัลตา"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 44.4994,
        "lng": 34.1553
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 10816,
      "name": "Jiangna",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6128,
        "lng": 104.3433
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5285,
      "name": "Dudley",
      "names": {
        "zh": "达德利",
        "ja": "ダドリー",
        "th": "ดัดลีย์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.508,
        "lng": -2.089
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10833,
      "name": "The Villages",
      "names": {
        "es": "Los pueblos",
        "fr": "Les villages",
        "zh": "村庄",
        "de": "Die Dörfer",
        "it": "I villaggi",
        "id": "Desa-desa",
        "ja": "村",
        "th": "หมู่บ้าน",
        "pt": "As aldeias"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.9021,
        "lng": -81.9888
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10828,
      "name": "Iwamizawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 43.1961,
        "lng": 141.7758
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10832,
      "name": "Villa Maria",
      "names": {
        "zh": "玛丽亚别墅",
        "ja": "ヴィラマリア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.4103,
        "lng": -63.2314
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 10834,
      "name": "Kokomo",
      "names": {
        "zh": "科科莫"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.464,
        "lng": -86.1277
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5352,
      "name": "Tustin",
      "names": {
        "zh": "塔斯廷"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7309,
        "lng": -117.8106
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10827,
      "name": "Yenakiieve",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2311,
        "lng": 38.2053
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 10824,
      "name": "Mangatarem",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7874,
        "lng": 120.2921
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5286,
      "name": "Ban Talat Rangsit",
      "names": {
        "zh": "班塔拉特朗格斯特",
        "ja": "バンタラットRangsit",
        "th": "บ้านตลาดรังสิต",
        "pt": "Ban rangsit Talat"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.987,
        "lng": 100.6095
      },
      "country": "Thailand",
      "importance": 3
    },
    {
      "id": 5329,
      "name": "Focsani",
      "names": {
        "zh": "佛克山尼"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.6997,
        "lng": 27.1797
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 5353,
      "name": "Lakewood",
      "names": {
        "zh": "莱克伍德",
        "ja": "レイクウッド",
        "th": "เลกวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8471,
        "lng": -118.1221
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10768,
      "name": "Gamagori",
      "names": {
        "zh": "蒲郡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8264,
        "lng": 137.2196
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10835,
      "name": "Perris",
      "names": {
        "zh": "佩里斯"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7899,
        "lng": -117.2233
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10800,
      "name": "Natori-shi",
      "names": {
        "zh": "名取市",
        "ja": "natori市",
        "th": "natori-ชิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.1717,
        "lng": 140.8919
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5354,
      "name": "Lisala",
      "names": {
        "zh": "利萨拉"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.14,
        "lng": 21.51
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 10831,
      "name": "Juventino Rosas",
      "names": {
        "zh": "尤文图罗莎"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.65,
        "lng": -101.0
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5113,
      "name": "Ciudad Lazaro Cardenas",
      "names": {
        "es": "Ciudad Lázaro Cárdenas"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9561,
        "lng": -102.1922
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 10795,
      "name": "Jaen",
      "names": {
        "es": "Jaén",
        "zh": "哈恩"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3333,
        "lng": 120.9
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10825,
      "name": "Kadoma",
      "names": {
        "zh": "卡多马"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.35,
        "lng": 29.9167
      },
      "country": "Zimbabwe",
      "importance": 3
    },
    {
      "id": 10822,
      "name": "Hanno",
      "names": {
        "zh": "汉诺",
        "ja": "飯能市",
        "th": "ฮันโน"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8519,
        "lng": 139.3181
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10837,
      "name": "Klin",
      "names": {
        "zh": "克林"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3339,
        "lng": 36.7125
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10841,
      "name": "St. Joseph",
      "names": {
        "es": "San José",
        "fr": "Saint-Joseph",
        "zh": "圣约瑟夫",
        "ja": "セントジョセフ",
        "th": "เซนต์โจเซฟ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7599,
        "lng": -94.821
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10775,
      "name": "Norderstedt",
      "names": {
        "zh": "诺德施泰特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.7064,
        "lng": 10.0103
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 10846,
      "name": "Flower Mound",
      "names": {
        "es": "Montículo de flores",
        "fr": "Monticule de fleur",
        "zh": "花丘",
        "de": "Blumenhügel",
        "it": "Tumulo di fiori",
        "id": "Gundukan bunga",
        "ja": "フラワーマウンド",
        "th": "เนินดอกไม้",
        "pt": "Monte de flores"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0344,
        "lng": -97.1146
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10847,
      "name": "Pharr",
      "names": {
        "zh": "法尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.1685,
        "lng": -98.1904
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10838,
      "name": "Ozersk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7556,
        "lng": 60.7028
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5261,
      "name": "Bumahen",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7297,
        "lng": 51.8619
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 5348,
      "name": "Ubon Ratchathani",
      "names": {
        "ja": "ウボンラッチタニ",
        "th": "อุบลราชธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2281,
        "lng": 104.8594
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 10839,
      "name": "Baharestan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.4881,
        "lng": 51.7731
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 10840,
      "name": "Huaral",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -11.5008,
        "lng": -77.2091
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 10843,
      "name": "Pato Branco",
      "names": {
        "ja": "パトブランコ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.2289,
        "lng": -52.6708
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5359,
      "name": "Pine Hills",
      "names": {
        "es": "Pino",
        "fr": "Pintumes",
        "zh": "松山",
        "de": "Kiefernhügel",
        "id": "Bukit pinus",
        "ja": "パインヒルズ",
        "th": "ไพน์เนิน",
        "pt": "Colinas de pinheiros"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.5818,
        "lng": -81.4693
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10852,
      "name": "Telemaco Borba",
      "names": {
        "id": "Telemako Borba",
        "ja": "テレマコボルバ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.3239,
        "lng": -50.6158
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10851,
      "name": "Francisco Beltrao",
      "names": {
        "de": "Francisco Belttrao",
        "th": "ฟรานซิสโก BELTRARAO"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.0808,
        "lng": -53.055
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10844,
      "name": "Orihuela",
      "names": {
        "zh": "奥里韦拉"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.0856,
        "lng": -0.9469
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10853,
      "name": "Ijui",
      "names": {
        "zh": "伊茹伊"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.3878,
        "lng": -53.915
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10858,
      "name": "Alton",
      "names": {
        "zh": "奥尔顿",
        "ja": "アルトン",
        "th": "แอลตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9034,
        "lng": -90.1523
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10862,
      "name": "Loveland",
      "names": {
        "zh": "拉夫兰",
        "ja": "ラブランド"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4166,
        "lng": -105.0623
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10863,
      "name": "Subulussalam",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 2.6422,
        "lng": 98.0042
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 5364,
      "name": "Tambacounda",
      "names": {
        "zh": "坦巴昆达"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 13.7689,
        "lng": -13.6672
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 5345,
      "name": "Nakhon Pathom",
      "names": {
        "de": "Nakhon-Pathom",
        "id": "Pathom Nakhon",
        "ja": "ナコンのパトム",
        "th": "นครปฐม",
        "pt": "Patom de Nakhon"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8194,
        "lng": 100.0581
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 10867,
      "name": "Tierralta",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.1728,
        "lng": -76.0594
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5325,
      "name": "Barreiro",
      "names": {
        "zh": "巴雷鲁"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6609,
        "lng": -9.0733
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 10856,
      "name": "Maizuru",
      "names": {
        "zh": "舞鹤",
        "ja": "舞鶴"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.45,
        "lng": 135.3333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5316,
      "name": "Bury",
      "names": {
        "es": "Enterrar",
        "fr": "L\u0027enterrer",
        "zh": "埋葬",
        "de": "Begraben",
        "ja": "ベリー",
        "th": "ฝังศพ",
        "pt": "Enterrar"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.593,
        "lng": -2.298
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10836,
      "name": "Eastleigh",
      "names": {
        "zh": "伊斯特利"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.9667,
        "lng": -1.35
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5297,
      "name": "Elbasan",
      "names": {
        "zh": "爱尔巴桑"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 41.1125,
        "lng": 20.0822
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 10842,
      "name": "Pagbilao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.972,
        "lng": 121.687
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5225,
      "name": "Villa Celina",
      "names": {
        "zh": "米利纳别墅",
        "ja": "ヴィラセリーナ",
        "th": "วิลล่าเซลินา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7006,
        "lng": -58.4825
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 10869,
      "name": "Aligudarz",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.4006,
        "lng": 49.6947
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 10870,
      "name": "Boynton Beach",
      "names": {
        "es": "Playa de boynton",
        "id": "Pantai Boynton",
        "ja": "ボイントンビーチ",
        "pt": "Praia de boynton"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.5281,
        "lng": -80.0811
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10845,
      "name": "Mooka",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4403,
        "lng": 140.0131
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10871,
      "name": "Arlit",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 18.7333,
        "lng": 7.3833
      },
      "country": "Niger",
      "importance": 2
    },
    {
      "id": 10868,
      "name": "Dondo",
      "names": {
        "zh": "栋多"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -19.6167,
        "lng": 34.75
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 10872,
      "name": "Moju",
      "names": {
        "zh": "莫茹"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.8839,
        "lng": -48.7689
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10860,
      "name": "Hengbei",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.8787,
        "lng": 115.7309
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5369,
      "name": "New Rochelle",
      "names": {
        "fr": "Nouvelle rochelle",
        "zh": "新罗谢尔",
        "de": "Neuer Rochelle",
        "it": "Nuova Rochelle",
        "id": "Rochelle baru",
        "ja": "新しいRochelle",
        "th": "ใหม่ rochelle",
        "pt": "Nova rochelle"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9305,
        "lng": -73.7836
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5324,
      "name": "Rubi",
      "names": {
        "zh": "鲁比",
        "ja": "ルビ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.4933,
        "lng": 2.0325
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10876,
      "name": "Kuvango",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -14.4667,
        "lng": 16.3
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 10873,
      "name": "Tela",
      "names": {
        "zh": "特拉"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.7833,
        "lng": -87.4667
      },
      "country": "Honduras",
      "importance": 3
    },
    {
      "id": 10878,
      "name": "Anderson",
      "names": {
        "zh": "安德森",
        "ja": "アンダーソン",
        "th": "แอนเดอร์สัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5211,
        "lng": -82.6479
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10819,
      "name": "Gyoda",
      "names": {
        "zh": "行田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1389,
        "lng": 139.4558
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10849,
      "name": "Sahuayo de Morelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0575,
        "lng": -102.7239
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 10880,
      "name": "Vyborg",
      "names": {
        "zh": "维堡",
        "de": "Wyborg"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 60.7092,
        "lng": 28.7442
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5363,
      "name": "Aveiro",
      "names": {
        "zh": "阿维罗",
        "th": "อาวีโร"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6389,
        "lng": -8.6553
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 10829,
      "name": "Bauang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.5333,
        "lng": 120.3333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10885,
      "name": "Jonesboro",
      "names": {
        "zh": "琼斯伯勒",
        "th": "โจเนสโบโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.8212,
        "lng": -90.6791
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5312,
      "name": "Kashiba",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5414,
        "lng": 135.6992
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10850,
      "name": "Jelenia Gora",
      "names": {
        "zh": "乔利诺戈拉",
        "ja": "ゼレニアゴーラ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.9,
        "lng": 15.7333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 5357,
      "name": "Banska Bystrica",
      "names": {
        "ja": "バンスカバイシュリカ"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7353,
        "lng": 19.1453
      },
      "country": "Slovakia",
      "importance": 1
    },
    {
      "id": 5356,
      "name": "Szombathely",
      "names": {
        "zh": "松博特海伊"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2333,
        "lng": 16.6333
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 5372,
      "name": "Lebanon",
      "names": {
        "es": "Líbano",
        "fr": "Le liban",
        "zh": "黎巴嫩",
        "de": "Libanon",
        "it": "Del Libano",
        "id": "Libanon",
        "ja": "レバノン",
        "th": "เลบานอน",
        "pt": "Líbano"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.3412,
        "lng": -76.4227
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10848,
      "name": "Pototan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.95,
        "lng": 122.6333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5146,
      "name": "Queluz",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7566,
        "lng": -9.2545
      },
      "country": "Portugal",
      "importance": 3
    },
    {
      "id": 10854,
      "name": "Limay",
      "names": {
        "zh": "利迈河"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5619,
        "lng": 120.5983
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10875,
      "name": "Bamban",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.65,
        "lng": 120.25
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10877,
      "name": "Manbij",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.5333,
        "lng": 37.95
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 10826,
      "name": "Pingyi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5104,
        "lng": 117.62
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5304,
      "name": "Huolu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0874,
        "lng": 114.3159
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10798,
      "name": "Manresa",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 41.7264,
        "lng": 1.8292
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 10874,
      "name": "Argao",
      "names": {
        "zh": "阿尔高"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.8833,
        "lng": 123.6
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 10888,
      "name": "Pinheiro",
      "names": {
        "zh": "皮涅罗"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.5208,
        "lng": -45.0828
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10891,
      "name": "Chitembo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.5167,
        "lng": 16.7667
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 10861,
      "name": "Macabebe",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9081,
        "lng": 120.7156
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10887,
      "name": "Kropotkin",
      "names": {
        "fr": "Kropotkine",
        "zh": "克鲁泡特金"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 45.4333,
        "lng": 40.5667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5375,
      "name": "Wa",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 10.0667,
        "lng": -2.5
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 10892,
      "name": "Parma",
      "names": {
        "fr": "Parme",
        "zh": "帕尔马",
        "ja": "パルマ",
        "th": "ปาร์มา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.3843,
        "lng": -81.7286
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10889,
      "name": "Arjona",
      "names": {
        "zh": "阿尔霍纳"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.255,
        "lng": -75.3447
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 10893,
      "name": "Bor",
      "names": {
        "zh": "博尔",
        "th": "บ่อ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3603,
        "lng": 44.0592
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10881,
      "name": "Dias d\u0027Avila",
      "names": {
        "zh": "DIAAS D\u0027Avila"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.6128,
        "lng": -38.2969
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10857,
      "name": "Kizugawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7369,
        "lng": 135.8208
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5373,
      "name": "Godawari",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 28.91,
        "lng": 80.59
      },
      "country": "Nepal",
      "importance": 1
    },
    {
      "id": 10897,
      "name": "Layton",
      "names": {
        "zh": "莱顿",
        "ja": "レイトン",
        "th": "เลย์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0769,
        "lng": -111.9621
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10898,
      "name": "Balqash",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 46.85,
        "lng": 75.0
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 10900,
      "name": "Elizabethtown",
      "names": {
        "ja": "エリザベスタウン",
        "th": "เอลิซาเบททาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.703,
        "lng": -85.877
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5303,
      "name": "Schiedam",
      "names": {
        "zh": "斯希丹"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9167,
        "lng": 4.4
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 5283,
      "name": "Rueil-Malmaison",
      "names": {
        "zh": "吕埃-马尔迈松",
        "ja": "rueil-マルメゾン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.876,
        "lng": 2.181
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 10865,
      "name": "Essaouira",
      "names": {
        "zh": "索维拉",
        "ja": "エッサウィラ",
        "th": "เอสเซาอิรา"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 31.513,
        "lng": -9.7687
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10901,
      "name": "Texarkana",
      "names": {
        "zh": "特克萨卡纳",
        "th": "แทกซาร์แคนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4487,
        "lng": -94.0815
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5294,
      "name": "Tanuku",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.75,
        "lng": 81.7
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10895,
      "name": "Narra",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.2833,
        "lng": 118.4167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10855,
      "name": "Koch Bihar",
      "names": {
        "ja": "コッホビハール"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.3167,
        "lng": 89.4333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10890,
      "name": "Chengbin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.9991,
        "lng": 110.3332
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10902,
      "name": "Fray Bartolome de Las Casas",
      "names": {
        "zh": "磨损Bartolome de las Casas",
        "it": "Mray Bartolome de las Casas",
        "ja": "Bartolome de Las Casasをお楽しみください"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.8456,
        "lng": -89.8658
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 5377,
      "name": "Lelystad",
      "names": {
        "zh": "莱利斯塔德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5167,
        "lng": 5.4833
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 10899,
      "name": "Villa Victoria",
      "names": {
        "zh": "维多利亚别墅",
        "ja": "ヴィラビクトリア",
        "th": "วิคตอเรียวิลล่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4333,
        "lng": -100.0
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10903,
      "name": "Linkou",
      "names": {
        "zh": "林口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.2819,
        "lng": 130.2519
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10906,
      "name": "Aquiraz",
      "names": {
        "th": "ในอากิราซ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.9008,
        "lng": -38.3908
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10912,
      "name": "Huebampo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.6667,
        "lng": -109.35
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 10884,
      "name": "Hasselt",
      "names": {
        "zh": "哈瑟尔特",
        "ja": "ハッセルト",
        "th": "ฮัสเซลท์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.9305,
        "lng": 5.3385
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 10907,
      "name": "Villa Canales",
      "names": {
        "id": "Calies Villa",
        "ja": "ヴィラカナレ",
        "th": "วิลล่า canales"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4816,
        "lng": -90.534
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 10911,
      "name": "Bebedouro",
      "names": {
        "zh": "贝贝多鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.9494,
        "lng": -48.4792
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5384,
      "name": "Alameda",
      "names": {
        "zh": "阿拉米达",
        "ja": "アラメダ",
        "th": "เดินเล่น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.767,
        "lng": -122.2672
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10908,
      "name": "Numan",
      "names": {
        "zh": "努曼"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 9.4536,
        "lng": 11.8367
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 5268,
      "name": "Phuket",
      "names": {
        "zh": "普吉岛",
        "ja": "プーケット",
        "th": "ภูเก็ต"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.8881,
        "lng": 98.3975
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 10864,
      "name": "Delmenhorst",
      "names": {
        "zh": "代尔门霍斯特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.0506,
        "lng": 8.6317
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10883,
      "name": "Beziers",
      "names": {
        "zh": "贝济耶",
        "ja": "ベジエ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.3476,
        "lng": 3.219
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 10859,
      "name": "Bamberg",
      "names": {
        "zh": "班贝格",
        "ja": "バンベルク"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.8917,
        "lng": 10.8917
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10866,
      "name": "Valdemoro",
      "names": {
        "zh": "巴尔德莫罗"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.1908,
        "lng": -3.6742
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5383,
      "name": "Gavle",
      "names": {
        "es": "Gävle",
        "fr": "Gävle",
        "zh": "耶夫勒",
        "de": "Gävle",
        "th": "ใกล้กับพิพิธภัณฑ์"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 60.6748,
        "lng": 17.1444
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 10894,
      "name": "Bodhan",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.67,
        "lng": 77.9
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10922,
      "name": "Unai",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -16.3578,
        "lng": -46.9058
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10917,
      "name": "Tila",
      "names": {
        "zh": "那"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.3,
        "lng": -92.4333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5341,
      "name": "Mansfield",
      "names": {
        "zh": "曼斯菲尔德",
        "ja": "マンスフィールド",
        "th": "แมนส์ฟิลด์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.1444,
        "lng": -1.1964
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10918,
      "name": "Santa Catalina",
      "names": {
        "zh": "圣卡塔利娜",
        "ja": "サンタカタリナ",
        "th": "ซานตาแคเทลนา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.3331,
        "lng": 122.8658
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10904,
      "name": "Hermosa",
      "names": {
        "zh": "埃尔莫萨",
        "ja": "エルモサ",
        "th": "เฮอร์โมซา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8333,
        "lng": 120.5
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5335,
      "name": "Fnidq",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 35.8414,
        "lng": -5.3587
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 10916,
      "name": "Tres Rios",
      "names": {
        "ja": "RIOS TRES"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.1169,
        "lng": -43.2089
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10924,
      "name": "Kankakee",
      "names": {
        "zh": "坎卡基"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1019,
        "lng": -87.8643
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10882,
      "name": "Usol\u0027ye-Sibirskoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.75,
        "lng": 103.65
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5376,
      "name": "Nitra",
      "names": {
        "zh": "尼特拉",
        "ja": "ニトラ"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.3147,
        "lng": 18.0875
      },
      "country": "Slovakia",
      "importance": 1
    },
    {
      "id": 5388,
      "name": "Watsonville",
      "names": {
        "th": "วัตสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.9206,
        "lng": -121.7706
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10933,
      "name": "Porterville",
      "names": {
        "zh": "波特维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.0644,
        "lng": -119.0338
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10934,
      "name": "Vorkuta",
      "names": {
        "zh": "沃尔库塔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 67.5,
        "lng": 64.0333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10931,
      "name": "Araripina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.55,
        "lng": -40.5667
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10926,
      "name": "Santa Ines",
      "names": {
        "zh": "圣诞老人",
        "it": "Babbo Natale",
        "ja": "サンタインイン",
        "th": "ซานตาอินส์"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.6669,
        "lng": -45.38
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5389,
      "name": "Caxito",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.5785,
        "lng": 13.6642
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 5342,
      "name": "Bracknell",
      "names": {
        "zh": "布拉克内尔"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.416,
        "lng": -0.749
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5244,
      "name": "Cozumel",
      "names": {
        "zh": "科苏梅尔",
        "ja": "コズメル島"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5104,
        "lng": -86.9493
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10936,
      "name": "Gelendzhik",
      "names": {
        "fr": "Guelendjik"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.5608,
        "lng": 38.0767
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10879,
      "name": "Pattoki",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.0214,
        "lng": 73.8528
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 5270,
      "name": "Kayes",
      "names": {
        "zh": "卡耶斯"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 14.45,
        "lng": -11.4167
      },
      "country": "Mali",
      "importance": 1
    },
    {
      "id": 10935,
      "name": "Harunabad",
      "names": {
        "zh": "哈鲁纳巴德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 29.61,
        "lng": 73.1361
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10932,
      "name": "Ende",
      "names": {
        "zh": "终结",
        "ja": "エンデ"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.8333,
        "lng": 121.65
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 10886,
      "name": "Bustos",
      "names": {
        "zh": "布斯托斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.95,
        "lng": 120.9167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10941,
      "name": "Baytown",
      "names": {
        "zh": "贝敦",
        "ja": "ベイタウン",
        "th": "เบย์ทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.7586,
        "lng": -94.9669
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 787,
      "name": "Ankara",
      "names": {
        "zh": "安卡拉",
        "ja": "アンカラ",
        "th": "อังการา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.93,
        "lng": 32.85
      },
      "country": "Turkey",
      "importance": 0
    },
    {
      "id": 10943,
      "name": "Upland",
      "names": {
        "es": "Tierras altas",
        "zh": "高地",
        "ja": "高地",
        "th": "ไร่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1178,
        "lng": -117.6603
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10905,
      "name": "Palencia",
      "names": {
        "zh": "帕伦西亚",
        "th": "ปาเลนเซีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.0167,
        "lng": -4.5333
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 5281,
      "name": "Casoria",
      "names": {
        "zh": "卡索里亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.9,
        "lng": 14.3
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 10930,
      "name": "Umingan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9,
        "lng": 120.8
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10896,
      "name": "Chinautla",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7029,
        "lng": -90.5
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 10925,
      "name": "Tecamachalco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8667,
        "lng": -97.7167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10913,
      "name": "Guildford",
      "names": {
        "zh": "吉尔福德",
        "ja": "ギルフォード",
        "th": "กิล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.2365,
        "lng": -0.5703
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5271,
      "name": "Champigny-sur-Marne",
      "names": {
        "zh": "尚皮尼马恩河畔",
        "ja": "champignyシュルマルヌ",
        "th": "champigny-ซูร์-Marne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8172,
        "lng": 2.5156
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 10909,
      "name": "Roosendaal",
      "names": {
        "zh": "罗森达尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5314,
        "lng": 4.4556
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 10945,
      "name": "Talakag",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.1333,
        "lng": 124.7333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10947,
      "name": "Nikko",
      "names": {
        "zh": "日光",
        "ja": "日光"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.7198,
        "lng": 139.6982
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5344,
      "name": "Balagtas",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8145,
        "lng": 120.9085
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10946,
      "name": "Toyooka",
      "names": {
        "zh": "丰冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.5333,
        "lng": 134.8167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10938,
      "name": "Balkh",
      "names": {
        "zh": "巴剌黑",
        "ja": "バルフ"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 36.7581,
        "lng": 66.8989
      },
      "country": "Afghanistan",
      "importance": 2
    },
    {
      "id": 5394,
      "name": "Davis",
      "names": {
        "zh": "戴维斯",
        "ja": "デイビス",
        "th": "เดวิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.5552,
        "lng": -121.7365
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10944,
      "name": "Bongabong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.7167,
        "lng": 121.3667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10949,
      "name": "Nagua",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 19.38,
        "lng": -69.85
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 10958,
      "name": "Camarillo",
      "names": {
        "zh": "卡马里奥"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.223,
        "lng": -119.0322
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10959,
      "name": "Sarqan",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 45.4203,
        "lng": 79.9149
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 5397,
      "name": "Berberati",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 4.2667,
        "lng": 15.7833
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 10954,
      "name": "La Trinidad",
      "names": {
        "zh": "拉特立尼达",
        "ja": "ラトリニダード",
        "th": "ลาตรินิแดด"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.0175,
        "lng": -91.9334
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 10914,
      "name": "Karur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 10.9504,
        "lng": 78.0833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10910,
      "name": "Viersen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2556,
        "lng": 6.3917
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5343,
      "name": "Aviles",
      "names": {
        "es": "Avilés",
        "zh": "阿维莱斯",
        "id": "Avilés",
        "th": "อาวิเล"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.5561,
        "lng": -5.9083
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5391,
      "name": "Mao",
      "names": {
        "zh": "毛泽东",
        "ja": "真央",
        "th": "เหมา"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.5667,
        "lng": -71.0833
      },
      "country": "Dominican Republic",
      "importance": 1
    },
    {
      "id": 10923,
      "name": "Marburg",
      "names": {
        "zh": "马尔堡",
        "ja": "マールブルク"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8167,
        "lng": 8.7667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5243,
      "name": "Baj Baj",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.4828,
        "lng": 88.1818
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 10929,
      "name": "Daying",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3043,
        "lng": 115.7196
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5272,
      "name": "Chatham",
      "names": {
        "zh": "查塔姆",
        "ja": "チャタム",
        "th": "ชาตัม"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.37,
        "lng": 0.52
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5401,
      "name": "Surt",
      "names": {
        "zh": "苏尔特"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 31.2,
        "lng": 16.6
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 10966,
      "name": "Shostka",
      "names": {
        "zh": "绍斯特卡"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 51.8657,
        "lng": 33.4766
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 10965,
      "name": "Balashov",
      "names": {
        "zh": "巴拉绍夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.55,
        "lng": 43.1667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10920,
      "name": "Honjo",
      "names": {
        "zh": "本庄",
        "ja": "本庄"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.2439,
        "lng": 139.1903
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10915,
      "name": "Sint-Niklaas",
      "names": {
        "zh": "圣彼-niklaas",
        "ja": "シント・niklaas",
        "th": "ซิงต์-niklaas"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.1644,
        "lng": 4.1392
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 10967,
      "name": "Gurupi",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -11.7289,
        "lng": -49.0689
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10952,
      "name": "Pyapon",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 16.2853,
        "lng": 95.6786
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 10956,
      "name": "Mafra",
      "names": {
        "zh": "马夫拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.9333,
        "lng": -9.3333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 10970,
      "name": "E\u0027erguna",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 50.2411,
        "lng": 120.172
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5230,
      "name": "Tacambaro de Codallos",
      "names": {
        "it": "Tacambaro de codaallos",
        "id": "TACAMMAMLARO DE CODALLOS",
        "ja": "タカムバロデ・コーダロス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2356,
        "lng": -101.4569
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 10960,
      "name": "Ait Ali",
      "names": {
        "ja": "アリ",
        "th": "AIT อาลี"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 30.1739,
        "lng": -9.4881
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 5309,
      "name": "Ivanteyevka",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.97,
        "lng": 37.92
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10957,
      "name": "Aira",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 31.7283,
        "lng": 130.6278
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10974,
      "name": "South Jordan",
      "names": {
        "fr": "Jordanie du Sud",
        "zh": "南约旦",
        "de": "Südjordanien",
        "it": "South Giordania",
        "id": "Jordan",
        "ja": "サウスジョーダン",
        "th": "เซาท์จอร์แดน",
        "pt": "Jordão do Sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.557,
        "lng": -111.9782
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10937,
      "name": "Tsubame",
      "names": {
        "zh": "燕"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.6667,
        "lng": 138.9667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10942,
      "name": "Siedlce",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.1833,
        "lng": 22.2833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 10969,
      "name": "Cotui",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 19.06,
        "lng": -70.15
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 5187,
      "name": "Daxincun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4427,
        "lng": 106.311
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5404,
      "name": "Karaman",
      "names": {
        "zh": "卡拉曼"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.1833,
        "lng": 33.2167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 10962,
      "name": "Dschang",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 5.45,
        "lng": 10.05
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 5393,
      "name": "Virac",
      "names": {
        "zh": "比拉克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5833,
        "lng": 124.2333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 10979,
      "name": "Battle Creek",
      "names": {
        "fr": "Ruisseau de bataille",
        "zh": "巴特溪",
        "it": "Battaglia",
        "ja": "バトルクリーク",
        "th": "การต่อสู้ครีก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.2986,
        "lng": -85.2296
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5399,
      "name": "Zrenjanin",
      "names": {
        "zh": "兹雷尼亚宁"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.3778,
        "lng": 20.3861
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10975,
      "name": "Palma Soriano",
      "names": {
        "zh": "帕尔马索里亚诺",
        "ja": "パルマソリアーノ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.2139,
        "lng": -75.9919
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 5406,
      "name": "Abeche",
      "names": {
        "fr": "Abéché",
        "zh": "阿贝歇"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 13.8331,
        "lng": 20.8347
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 10927,
      "name": "Funing",
      "names": {
        "zh": "阜宁"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.8879,
        "lng": 119.2314
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 10973,
      "name": "Gravata",
      "names": {
        "zh": "格拉瓦塔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.2008,
        "lng": -35.565
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10921,
      "name": "Armant",
      "names": {
        "zh": "艾尔曼特"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 25.6167,
        "lng": 32.5333
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 10950,
      "name": "Al Musayyib",
      "names": {
        "de": "Al MusaNyib"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.7786,
        "lng": 44.29
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 5409,
      "name": "Bellflower",
      "names": {
        "fr": "Campanule",
        "zh": "桔梗",
        "de": "Glockenblume",
        "it": "Campanula",
        "ja": "竜胆",
        "th": "ดอกไม้ชนิดหนึ่ง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.888,
        "lng": -118.1271
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10980,
      "name": "Ibiuna",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6564,
        "lng": -47.2225
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10981,
      "name": "Venado Tuerto",
      "names": {
        "es": "Venado morto",
        "id": "Veno Tuerto",
        "ja": "ヴェニド・トルト"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.75,
        "lng": -61.9667
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 5326,
      "name": "Paysandu",
      "names": {
        "es": "Paysandú",
        "zh": "派桑杜"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -32.32,
        "lng": -58.0756
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 5368,
      "name": "Pau",
      "names": {
        "zh": "波城",
        "ja": "ポー",
        "th": "โป"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.3,
        "lng": -0.37
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 10986,
      "name": "Santo Angelo",
      "names": {
        "ja": "サントアンジェロ",
        "th": "ซานโตแองเจโล"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.2989,
        "lng": -54.2628
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10940,
      "name": "Kortrijk",
      "names": {
        "fr": "Courtrai",
        "zh": "科特赖克"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8333,
        "lng": 3.2667
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 10963,
      "name": "Tuban",
      "names": {
        "zh": "图班",
        "ja": "トゥバン"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -6.8995,
        "lng": 112.05
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 5407,
      "name": "Palmerston North",
      "names": {
        "zh": "北帕默斯顿",
        "de": "Palmerston Nord",
        "ja": "パーマストンノース",
        "pt": "Palmerston norte"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -40.3549,
        "lng": 175.6095
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 10991,
      "name": "Berdychiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.8919,
        "lng": 28.6
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 5319,
      "name": "Paisley",
      "names": {
        "es": "Cachemir",
        "zh": "佩斯利",
        "ja": "ペイズリー",
        "th": "ลาย"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.8466,
        "lng": -4.4236
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10995,
      "name": "San Marcos",
      "names": {
        "zh": "圣马科斯",
        "ja": "サンマルコス",
        "th": "ซานมาร์กอส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.8736,
        "lng": -97.9381
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10983,
      "name": "Anzhero-Sudzhensk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0833,
        "lng": 86.0333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10977,
      "name": "Palo",
      "names": {
        "zh": "帕洛",
        "ja": "パロ",
        "th": "พาโล"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1583,
        "lng": 124.9917
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10968,
      "name": "Asti",
      "names": {
        "zh": "阿斯蒂",
        "ja": "アスティ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.9,
        "lng": 8.2069
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 5403,
      "name": "Pancevo",
      "names": {
        "zh": "潘切沃"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.8739,
        "lng": 20.6519
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10984,
      "name": "Infanta",
      "names": {
        "fr": "Infante",
        "zh": "公主",
        "ja": "インファンタ",
        "th": "ราชธิดาของพระเจ้าแผ่นดินสเปน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.7425,
        "lng": 121.6494
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10993,
      "name": "Itapetinga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -15.2489,
        "lng": -40.2478
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10989,
      "name": "Toyomamachi-teraike",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 38.6919,
        "lng": 141.1878
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10990,
      "name": "Daisen",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 39.4531,
        "lng": 140.4756
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5299,
      "name": "Weston-super-Mare",
      "names": {
        "es": "Weston Super Mare",
        "fr": "Weston super-mare",
        "zh": "滨海韦斯顿",
        "de": "Weston Super Mare",
        "id": "Weston Super Mare",
        "ja": "ウェストン-超マーレ",
        "th": "เวสตัน-ซุปเปอร์ม้า",
        "pt": "Weston-Super Mare"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.346,
        "lng": -2.977
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5365,
      "name": "La Rochelle",
      "names": {
        "ja": "ラロシェル"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.1591,
        "lng": -1.1517
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 10971,
      "name": "Rheine",
      "names": {
        "zh": "赖讷"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2833,
        "lng": 7.4333
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 10998,
      "name": "Quixada",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.9708,
        "lng": -39.015
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10953,
      "name": "Kahror Pakka",
      "names": {
        "ja": "カオトパクカ"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 29.6236,
        "lng": 71.9167
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 10994,
      "name": "Lins",
      "names": {
        "zh": "林斯",
        "ja": "リンス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.6786,
        "lng": -49.7425
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10972,
      "name": "Fancheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.1891,
        "lng": 113.2729
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10951,
      "name": "Ryugasaki",
      "names": {
        "zh": "龙崎"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9167,
        "lng": 140.1833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10928,
      "name": "Manaoag",
      "names": {
        "zh": "马纳瓦格"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0439,
        "lng": 120.4856
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5360,
      "name": "Katano",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7881,
        "lng": 135.68
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5416,
      "name": "Tamanrasset",
      "names": {
        "id": "Tamanghasset"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 22.785,
        "lng": 5.5228
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 11004,
      "name": "San Ramon",
      "names": {
        "es": "San Ramón",
        "zh": "圣拉蒙",
        "ja": "サンラモン",
        "th": "ซานรามอร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7625,
        "lng": -121.9365
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11003,
      "name": "Tailai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.3909,
        "lng": 123.4161
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 10939,
      "name": "Gandia",
      "names": {
        "zh": "甘迪亚"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.9667,
        "lng": -0.1833
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11006,
      "name": "Toba Tek Singh",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.9667,
        "lng": 72.4833
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 10978,
      "name": "Nabari",
      "names": {
        "zh": "名张"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6333,
        "lng": 136.1
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11001,
      "name": "Fukuchiyama",
      "names": {
        "zh": "福知山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2967,
        "lng": 135.1264
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 10985,
      "name": "Houmt Souk",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 33.8667,
        "lng": 10.85
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 11005,
      "name": "Longquan",
      "names": {
        "zh": "龙泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.0733,
        "lng": 119.1277
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11011,
      "name": "Kengtung",
      "names": {
        "zh": "景栋",
        "id": "Keng Tung"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 21.2914,
        "lng": 99.6039
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 11008,
      "name": "Ovalle",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -30.6031,
        "lng": -71.203
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 11013,
      "name": "Lake Jackson",
      "names": {
        "es": "Lago Jackson",
        "fr": "Lac Jackson",
        "zh": "杰克逊湖",
        "de": "See Jackson",
        "it": "Lago Jackson",
        "id": "Jackson",
        "ja": "湖のジャクソン",
        "th": "ทะเลสาบแจ็คสัน",
        "pt": "Lago jackson"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.0516,
        "lng": -95.4522
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5381,
      "name": "Sankt Gallen",
      "names": {
        "ja": "サンクトガレン"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.4233,
        "lng": 9.3772
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 10996,
      "name": "Espinal",
      "names": {
        "zh": "埃斯皮纳尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.1486,
        "lng": -74.8819
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11007,
      "name": "Jabuticabal",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.255,
        "lng": -48.3219
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11015,
      "name": "Bethlehem",
      "names": {
        "es": "Belén",
        "fr": "Bethléem",
        "zh": "伯利恒",
        "it": "Betlemme",
        "ja": "ベツレヘム",
        "th": "เบธเลเฮม",
        "pt": "Belém"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6266,
        "lng": -75.3679
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 10919,
      "name": "Bakhmut",
      "names": {
        "zh": "巴赫穆特"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6044,
        "lng": 38.0067
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 11000,
      "name": "Pilar",
      "names": {
        "zh": "皮拉尔",
        "ja": "ピラル",
        "th": "ปีลาร์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9333,
        "lng": 123.6833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10976,
      "name": "Stakhanov",
      "names": {
        "zh": "斯达汉诺夫"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5472,
        "lng": 38.6361
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 5134,
      "name": "Kargilik",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.8822,
        "lng": 77.4162
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5311,
      "name": "Cinisello Balsamo",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 45.55,
        "lng": 9.2167
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 10992,
      "name": "Lod",
      "names": {
        "zh": "检出限",
        "th": "ล็อด"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.95,
        "lng": 34.9
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 5379,
      "name": "Gladbeck",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5713,
        "lng": 6.9827
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5339,
      "name": "Sidi Qacem",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 34.21,
        "lng": -5.7
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 11025,
      "name": "Wyoming",
      "names": {
        "zh": "怀俄明",
        "ja": "ワイオミング州",
        "th": "ไวโอมิง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.8909,
        "lng": -85.7066
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5374,
      "name": "Maghaghah",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 28.6483,
        "lng": 30.8422
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 10997,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.4239,
        "lng": 120.9389
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11009,
      "name": "Rio Largo",
      "names": {
        "zh": "里约拉戈",
        "ja": "リオラルゴ",
        "th": "ริโอลาร์โก"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.4778,
        "lng": -35.8528
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11018,
      "name": "Goiana",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.5608,
        "lng": -35.0028
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 10955,
      "name": "Caserta",
      "names": {
        "zh": "卡塞塔",
        "ja": "カゼルタ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.0667,
        "lng": 14.3333
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 11028,
      "name": "Redencao",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.0289,
        "lng": -50.0308
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11016,
      "name": "Alcala de Guadaira",
      "names": {
        "es": "Alcalá de Guadaira",
        "ja": "アルカラデグアディーラ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.3333,
        "lng": -5.85
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11029,
      "name": "Oshkosh",
      "names": {
        "zh": "奥什科什",
        "ja": "オシュコシュ",
        "th": "ออชคอช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0228,
        "lng": -88.5619
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11032,
      "name": "Hammond",
      "names": {
        "zh": "哈蒙德",
        "ja": "ハモンド",
        "th": "แฮมมอนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6169,
        "lng": -87.491
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5424,
      "name": "Joensuu",
      "names": {
        "zh": "约恩苏",
        "ja": "ヨエンスー",
        "th": "โจเอนซู"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.6,
        "lng": 29.7639
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 10964,
      "name": "Jablah",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.3597,
        "lng": 35.9214
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 5414,
      "name": "Ramla",
      "names": {
        "zh": "拉姆拉",
        "ja": "ラムラ"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.9275,
        "lng": 34.8625
      },
      "country": "Israel",
      "importance": 1
    },
    {
      "id": 11027,
      "name": "Nakatsugawa",
      "names": {
        "zh": "中津川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4875,
        "lng": 137.5006
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11033,
      "name": "Waldorf",
      "names": {
        "zh": "华尔道夫",
        "ja": "ウォルドーフ",
        "th": "วอลดอร์ฟ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6085,
        "lng": -76.9195
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11034,
      "name": "Missouri City",
      "names": {
        "es": "Ciudad de Missouri",
        "fr": "Ville de Missouri",
        "zh": "密苏里州市",
        "it": "Città del Missouri",
        "id": "Kota Missouri",
        "ja": "ミズーリ州",
        "th": "เมืองมิสซูรี",
        "pt": "Cidade de missouri"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.563,
        "lng": -95.5365
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5370,
      "name": "Bokhtar",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 37.8364,
        "lng": 68.7803
      },
      "country": "Tajikistan",
      "importance": 1
    },
    {
      "id": 11036,
      "name": "Pasco",
      "names": {
        "zh": "帕斯科",
        "ja": "パスコ",
        "th": "พัสโก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.2506,
        "lng": -119.1304
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11020,
      "name": "Zhongcheng",
      "names": {
        "zh": "众城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.6014,
        "lng": 103.943
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11038,
      "name": "Kawartha Lakes",
      "names": {
        "es": "Lagos de Kawartha",
        "fr": "Kawartha Lacs",
        "zh": "Kawartha湖",
        "de": "Kawartha-Seen",
        "ja": "カワルタ湖",
        "pt": "Lagos kawartha"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.35,
        "lng": -78.75
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5350,
      "name": "Carlisle",
      "names": {
        "zh": "卡莱尔",
        "ja": "カーライル",
        "th": "คาร์ไลเซิล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.891,
        "lng": -2.944
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11042,
      "name": "Wheeling",
      "names": {
        "zh": "威灵",
        "ja": "ホイール",
        "th": "ล้อ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0752,
        "lng": -80.6951
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5291,
      "name": "Kiyose",
      "names": {
        "zh": "清濑"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7858,
        "lng": 139.5264
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11012,
      "name": "Xinglong",
      "names": {
        "zh": "兴隆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.4146,
        "lng": 117.493
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11035,
      "name": "Luneburg",
      "names": {
        "es": "Lüneburg",
        "fr": "Lüneburg",
        "zh": "吕讷堡",
        "de": "Lüneburg",
        "it": "Lüneburg",
        "id": "Lüneburg",
        "pt": "Lüneburg"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.2525,
        "lng": 10.4144
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11019,
      "name": "Shadrinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0833,
        "lng": 63.6333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11046,
      "name": "Brick",
      "names": {
        "es": "Ladrillo",
        "fr": "Brique",
        "zh": "砖",
        "de": "Ziegel",
        "it": "Mattoni",
        "id": "Bata",
        "ja": "レンガ",
        "th": "อิฐ",
        "pt": "Tijolo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.06,
        "lng": -74.1099
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5301,
      "name": "South Shields",
      "names": {
        "es": "Escudos del sur",
        "fr": "Boucliers du sud",
        "zh": "南盾",
        "de": "Südschirme",
        "it": "Scudi del sud",
        "id": "Perisai selatan",
        "ja": "サウスシールド",
        "pt": "Escudos sul"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.995,
        "lng": -1.43
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 10987,
      "name": "Kai",
      "names": {
        "zh": "凯",
        "ja": "カイ",
        "th": "ไก่"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6608,
        "lng": 138.5158
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11030,
      "name": "Bonab",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.3403,
        "lng": 46.0561
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 11048,
      "name": "Kendall",
      "names": {
        "zh": "肯德尔",
        "ja": "ケンダル",
        "th": "เคนดัลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.6697,
        "lng": -80.3556
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5302,
      "name": "Saint-Maur-des-Fosses",
      "names": {
        "es": "Saint-Maur-des-fossés",
        "zh": "圣 -  maur-沙漠-fosses",
        "ja": "サン・maur・デ・fosses",
        "th": "เซนต์ maur-เดส์-fosses"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7994,
        "lng": 2.4997
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 11052,
      "name": "Ardakan",
      "names": {
        "zh": "阿尔达坎"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 32.3094,
        "lng": 54.0186
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 11047,
      "name": "Acacias",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.9889,
        "lng": -73.7647
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5433,
      "name": "Baldwin Park",
      "names": {
        "zh": "鲍德温公园",
        "id": "Taman Baldwin",
        "ja": "ボールドウィンパーク",
        "th": "สวนบอลด์วิน",
        "pt": "Parque de baldwin"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0829,
        "lng": -117.9721
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5278,
      "name": "Shinkai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8367,
        "lng": 139.5803
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5358,
      "name": "Frontera",
      "names": {
        "zh": "Frontera的",
        "ja": "フロンテーラ",
        "th": "ฟรงเตอรา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.926,
        "lng": -101.449
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5422,
      "name": "Tissemsilt",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.6072,
        "lng": 1.8106
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 11010,
      "name": "Wilhelmshaven",
      "names": {
        "zh": "威廉港"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.5167,
        "lng": 8.1333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 10988,
      "name": "Myslowice",
      "names": {
        "zh": "梅斯沃维采"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2333,
        "lng": 19.1333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11026,
      "name": "Dubna",
      "names": {
        "zh": "杜伯纳"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.75,
        "lng": 37.15
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 10948,
      "name": "Dongguan",
      "names": {
        "zh": "东莞",
        "ja": "東莞",
        "th": "ตงกวน"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.014,
        "lng": 111.0768
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5262,
      "name": "Kunitachi",
      "names": {
        "zh": "国立"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6839,
        "lng": 139.4414
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11014,
      "name": "Pinamungahan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2667,
        "lng": 123.5833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11041,
      "name": "Sasagawa",
      "names": {
        "zh": "笹川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.2865,
        "lng": 140.3727
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5355,
      "name": "East Kilbride",
      "names": {
        "es": "Este kilbride",
        "fr": "Kilbride de l\u0027Est",
        "zh": "东千德",
        "de": "Osten Kilbride",
        "it": "Kilbride orientale",
        "id": "Kilbrida timur",
        "ja": "イーストキルブライド",
        "pt": "Quilômetro leste"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.7645,
        "lng": -4.1771
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5430,
      "name": "Teyateyaneng",
      "names": {},
      "countryCode": "LS",
      "latLng": {
        "lat": -29.15,
        "lng": 27.7333
      },
      "country": "Lesotho",
      "importance": 1
    },
    {
      "id": 11044,
      "name": "Ciudad Real",
      "names": {
        "fr": "Ciudad réel",
        "zh": "ciudad真实",
        "de": "Ciudad echt",
        "it": "Ciudad reale",
        "id": "Ciudad nyata",
        "th": "Ciudad จริง"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.9833,
        "lng": -3.9167
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 5432,
      "name": "Zacatecoluca",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.5,
        "lng": -88.8667
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 11057,
      "name": "Rancho Cordova",
      "names": {
        "es": "Rancho Córdova"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.574,
        "lng": -121.2523
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5386,
      "name": "Newcastle under Lyme",
      "names": {
        "es": "Newcastle bajo Lyme",
        "fr": "Newcastle Sous Lyme",
        "zh": "莱姆下的纽卡斯尔",
        "de": "Newcastle unter Lyme",
        "it": "Newcastle sotto Lyme",
        "id": "Newcastle Di Bawah Lyme",
        "ja": "ライムの下のニューカッスル",
        "th": "นิวคาสเซิลภายใต้ Lyme",
        "pt": "Newcastle sob Lyme"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.0109,
        "lng": -2.2278
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5417,
      "name": "Zadar",
      "names": {
        "zh": "扎达尔",
        "it": "Zara",
        "ja": "ザダル",
        "th": "ซาดาร์"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 44.1167,
        "lng": 15.2167
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 10982,
      "name": "Sangolqui",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -0.3344,
        "lng": -78.4475
      },
      "country": "Ecuador",
      "importance": 3
    },
    {
      "id": 5423,
      "name": "Bistrita",
      "names": {
        "zh": "比斯特里察"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 47.1333,
        "lng": 24.4833
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 5367,
      "name": "Burton upon Trent",
      "names": {
        "es": "Burton sobre Trent",
        "fr": "Burton sur Trent",
        "zh": "伯顿在特伦特",
        "de": "Burton auf Trent",
        "it": "Burton su Trento",
        "id": "Burton demi Trent",
        "ja": "トレントにバートン",
        "th": "เบอร์ตันกับเทรนต์",
        "pt": "Burton em Trent"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.8019,
        "lng": -1.6367
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11040,
      "name": "Libon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.3,
        "lng": 123.4333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 10961,
      "name": "Chernogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.8167,
        "lng": 91.2833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11022,
      "name": "Puqiancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.5723,
        "lng": 114.6122
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5245,
      "name": "Al Muharraq",
      "names": {
        "ja": "アルムハラ文字"
      },
      "countryCode": "BH",
      "latLng": {
        "lat": 26.25,
        "lng": 50.6167
      },
      "country": "Bahrain",
      "importance": 3
    },
    {
      "id": 11039,
      "name": "Bombo",
      "names": {
        "zh": "邦博河"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.5833,
        "lng": 32.5333
      },
      "country": "Uganda",
      "importance": 3
    },
    {
      "id": 11060,
      "name": "Gode",
      "names": {
        "zh": "戈德"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 5.95,
        "lng": 43.45
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 5196,
      "name": "Warabi",
      "names": {
        "zh": "蕨"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8256,
        "lng": 139.6794
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11031,
      "name": "Sapiranga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6378,
        "lng": -51.0069
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11045,
      "name": "Bugallon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9167,
        "lng": 120.1833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11063,
      "name": "Conway",
      "names": {
        "zh": "康威",
        "ja": "コンウエー",
        "th": "คอนเวย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.0754,
        "lng": -92.4694
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11002,
      "name": "Piotrkow Trybunalski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.4,
        "lng": 19.6833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 10999,
      "name": "Troisdorf",
      "names": {
        "zh": "特罗斯多夫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8161,
        "lng": 7.1556
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11064,
      "name": "Wiwili",
      "names": {
        "zh": "维维利"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.6167,
        "lng": -85.8167
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 11068,
      "name": "Gary",
      "names": {
        "zh": "加里·",
        "ja": "ゲイリー",
        "th": "แกรี่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5906,
        "lng": -87.3472
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5400,
      "name": "Fouchana",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.6987,
        "lng": 10.1693
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 11061,
      "name": "Amakusa",
      "names": {
        "zh": "天草",
        "ja": "天草"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.4586,
        "lng": 130.1931
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11043,
      "name": "Carpina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.85,
        "lng": -35.25
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11055,
      "name": "Gela",
      "names": {
        "zh": "格拉",
        "th": "เกล่า"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.0667,
        "lng": 14.25
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11070,
      "name": "Altoona",
      "names": {
        "zh": "阿尔图纳",
        "th": "แอลทูนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5082,
        "lng": -78.4007
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5295,
      "name": "Juchitan de Zaragoza",
      "names": {
        "es": "Juchtan de Zaragoza",
        "fr": "JUCHITAN DE SARAGOZA"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.436,
        "lng": -95.0198
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11062,
      "name": "Sao Bento do Sul",
      "names": {
        "ja": "サンベントドスル",
        "pt": "São Bento do Sul"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.25,
        "lng": -49.3789
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5443,
      "name": "Pazardzhik",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.1935,
        "lng": 24.3328
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 11071,
      "name": "Lodi",
      "names": {
        "zh": "洛迪",
        "ja": "ロディ",
        "th": "โลดิ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.1218,
        "lng": -121.2932
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11072,
      "name": "Arlington Heights",
      "names": {
        "fr": "Heights Arlington",
        "zh": "阿灵顿高地",
        "ja": "アーリントンの高さ",
        "th": "อาร์ลิงตันไฮทส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0955,
        "lng": -87.9825
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5428,
      "name": "Darhan",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 49.4867,
        "lng": 105.9228
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 11073,
      "name": "Darhan",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 49.6167,
        "lng": 106.35
      },
      "country": "Mongolia",
      "importance": 3
    },
    {
      "id": 11054,
      "name": "Dorsten",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.66,
        "lng": 6.9642
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11051,
      "name": "Pozorrubio",
      "names": {
        "zh": "波索鲁维奥"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.1167,
        "lng": 120.55
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11017,
      "name": "Tiznit",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 29.7,
        "lng": -9.7269
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 11066,
      "name": "Esperanza",
      "names": {
        "zh": "埃斯佩兰萨",
        "ja": "エスペランサ",
        "th": "เอสเปอรัน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.7167,
        "lng": 124.5167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11023,
      "name": "Tosu",
      "names": {
        "zh": "鸟栖"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.3778,
        "lng": 130.5061
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5371,
      "name": "Moulay Abdallah",
      "names": {
        "zh": "莫尔亚阿卜杜拉",
        "de": "Mulay Abdallah",
        "ja": "マカレアブダラ"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.1978,
        "lng": -8.5883
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 11021,
      "name": "Bayreuth",
      "names": {
        "zh": "拜罗伊特",
        "ja": "バイロイト",
        "th": "ไบ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.9481,
        "lng": 11.5783
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11058,
      "name": "Dalaguete",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.7612,
        "lng": 123.5349
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 11075,
      "name": "Piracununga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.9961,
        "lng": -47.4258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5446,
      "name": "Pernik",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.5998,
        "lng": 23.0308
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 5445,
      "name": "Bitola",
      "names": {
        "zh": "比托拉"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.0319,
        "lng": 21.3347
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 11080,
      "name": "Bolingbrook",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 41.6903,
        "lng": -88.1019
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11081,
      "name": "Rochester Hills",
      "names": {
        "zh": "罗切斯特山",
        "id": "Bukit Rochester",
        "ja": "ロチェスターヒルズ",
        "th": "โรเชสเตอร์ฮิลส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6645,
        "lng": -83.1563
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5452,
      "name": "Manokwari",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.8711,
        "lng": 134.0693
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 11050,
      "name": "Itahari",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 26.6667,
        "lng": 87.2833
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 5450,
      "name": "Naxcivan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.2089,
        "lng": 45.4122
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 5434,
      "name": "Zlin",
      "names": {
        "es": "Zlín",
        "zh": "兹林"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.2331,
        "lng": 17.6669
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 11082,
      "name": "Caninde",
      "names": {
        "zh": "卡宁德"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.3589,
        "lng": -39.3119
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5334,
      "name": "Shancheng",
      "names": {
        "zh": "山城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7904,
        "lng": 116.08
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11085,
      "name": "Winchester",
      "names": {
        "zh": "温彻斯特",
        "ja": "ウィンチェスター",
        "th": "วินเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1735,
        "lng": -78.1746
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11086,
      "name": "Framingham",
      "names": {
        "zh": "弗雷明汉",
        "ja": "フレーミングハム",
        "th": "รามิง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3085,
        "lng": -71.4368
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5456,
      "name": "Assab",
      "names": {
        "zh": "阿萨布"
      },
      "countryCode": "ER",
      "latLng": {
        "lat": 13.0078,
        "lng": 42.7411
      },
      "country": "Eritrea",
      "importance": 1
    },
    {
      "id": 11087,
      "name": "Detmold",
      "names": {
        "zh": "代特莫尔德",
        "ja": "デトモルト"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.9378,
        "lng": 8.8833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11067,
      "name": "Balingasag",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.75,
        "lng": 124.7833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11076,
      "name": "Miki",
      "names": {
        "zh": "三木",
        "ja": "三木",
        "th": "มิกิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7967,
        "lng": 134.99
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5288,
      "name": "Silao",
      "names": {
        "zh": "锡劳"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9478,
        "lng": -101.4281
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11093,
      "name": "Curvelo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -18.7558,
        "lng": -44.4308
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11088,
      "name": "Palmeira dos Indios",
      "names": {
        "th": "ปาล์มซีดอส"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.4069,
        "lng": -36.6278
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5317,
      "name": "Chalandri",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0167,
        "lng": 23.8
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 5408,
      "name": "Joyo",
      "names": {
        "zh": "卓越网"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8531,
        "lng": 135.78
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5340,
      "name": "Izumiotsu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5044,
        "lng": 135.4103
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5429,
      "name": "Karlstad",
      "names": {
        "zh": "卡尔斯塔德",
        "ja": "カールスタード",
        "th": "คาร์ลสตัด"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 59.3808,
        "lng": 13.5016
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 11094,
      "name": "Mineral\u0027nyye Vody",
      "names": {
        "fr": "Minéral\u0027nyye Vody",
        "ja": "ミネラル・ニーティーヴェーリー"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.2008,
        "lng": 43.1125
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11097,
      "name": "Hilton Head Island",
      "names": {
        "zh": "希尔顿头岛",
        "ja": "ヒルトンヘッドアイランド",
        "th": "เกาะฮิลตันหัว"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.1896,
        "lng": -80.7499
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11095,
      "name": "Kara-Balta",
      "names": {
        "zh": "卡拉-巴尔塔",
        "ja": "カラ-balta",
        "th": "คาร่า-บอลต้า"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.8306,
        "lng": 73.8857
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 5318,
      "name": "Le Kram",
      "names": {
        "th": "เลอกรัม"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8333,
        "lng": 10.3167
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 5459,
      "name": "Centreville",
      "names": {
        "es": "Centerville",
        "zh": "森特维尔",
        "de": "Centerville",
        "it": "Centerville",
        "id": "Centerville",
        "ja": "centerville",
        "th": "เซ็นเตอร์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8391,
        "lng": -77.4388
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5392,
      "name": "Okegawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0028,
        "lng": 139.5583
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11098,
      "name": "Union City",
      "names": {
        "zh": "联盟城",
        "de": "Union",
        "id": "Kota serikat",
        "ja": "ユニオンシティ",
        "th": "เมืองยูเนียน",
        "pt": "Cidade da União"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.603,
        "lng": -122.0187
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11103,
      "name": "Owensboro",
      "names": {
        "zh": "欧文斯伯勒",
        "th": "โอเวนส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7574,
        "lng": -87.1173
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11104,
      "name": "Jackson",
      "names": {
        "zh": "杰克逊",
        "ja": "ジャクソン",
        "th": "แจ็คสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.6535,
        "lng": -88.8353
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11101,
      "name": "San Ramon de la Nueva Oran",
      "names": {
        "es": "San Ramón de la Nueva Oran",
        "ja": "サンラモンデラヌエバオラン",
        "th": "ซานรามอนเดอลานูวาออรน"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -23.1361,
        "lng": -64.3222
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 11099,
      "name": "Ouro Preto",
      "names": {
        "zh": "uto preto"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.3853,
        "lng": -43.5036
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11100,
      "name": "Yelabuga",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7667,
        "lng": 52.0333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11049,
      "name": "Standerton",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.95,
        "lng": 29.25
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 11091,
      "name": "Pedro Brand",
      "names": {
        "es": "Marca Pedro",
        "fr": "Marque Pedro",
        "zh": "佩德罗品牌",
        "de": "Pedro-Marke",
        "it": "Marchio pedro",
        "id": "Merek Pedro",
        "ja": "ペドロブランド",
        "th": "แบรนด์เปโดร",
        "pt": "Marca Pedro"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.5667,
        "lng": -70.0911
      },
      "country": "Dominican Republic",
      "importance": 3
    },
    {
      "id": 11106,
      "name": "Riberalta",
      "names": {},
      "countryCode": "BO",
      "latLng": {
        "lat": -10.983,
        "lng": -66.1
      },
      "country": "Bolivia",
      "importance": 3
    },
    {
      "id": 11108,
      "name": "Prince George",
      "names": {
        "es": "Príncipe george",
        "zh": "乔治王子",
        "de": "Prinz George",
        "id": "Pangeran George",
        "ja": "ジョージプリンスジョージ",
        "th": "เจ้าชายจอร์จ",
        "pt": "Príncipe George"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 53.9169,
        "lng": -122.7494
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5361,
      "name": "Ra`ananna",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 32.1833,
        "lng": 34.8667
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 5441,
      "name": "Prey Veng",
      "names": {
        "es": "Presa veng",
        "fr": "Proie Veng",
        "zh": "猎物veng",
        "it": "Preda vend",
        "id": "Mangsa veng",
        "ja": "獲物ヴェン",
        "th": "เหยื่อ veng"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.484,
        "lng": 105.324
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 11105,
      "name": "Brookes Point",
      "names": {
        "zh": "布鲁克斯点",
        "de": "Brookes-Punkt",
        "it": "Punto di Brookes",
        "th": "จุดบรูคส์",
        "pt": "Ponto de brookes"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.7833,
        "lng": 117.8333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11053,
      "name": "Tatebayashi",
      "names": {
        "zh": "馆林"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.245,
        "lng": 139.5422
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11024,
      "name": "Kyotanabe",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8144,
        "lng": 135.7678
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5382,
      "name": "Cannes",
      "names": {
        "zh": "戛纳",
        "ja": "カンヌ",
        "th": "เมืองคานส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.5513,
        "lng": 7.0128
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5269,
      "name": "Le Bardo",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8092,
        "lng": 10.1406
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 11092,
      "name": "Tatsunocho-tominaga",
      "names": {
        "zh": "tatsunocho-富永"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8581,
        "lng": 134.5456
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5411,
      "name": "Tamworth",
      "names": {
        "zh": "塔姆沃思",
        "ja": "タムワース",
        "th": "แทมเวิร์ธ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.633,
        "lng": -1.695
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11065,
      "name": "Samundri",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 31.0639,
        "lng": 72.9611
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 11083,
      "name": "Dumangas",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8333,
        "lng": 122.7167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11089,
      "name": "Alicia",
      "names": {
        "zh": "艾丽西亚",
        "ja": "アリシア",
        "th": "อลิเซีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.7787,
        "lng": 121.6972
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11056,
      "name": "Di An",
      "names": {
        "es": "Di un",
        "fr": "Di un",
        "zh": "迪安",
        "de": "DI a",
        "th": "di",
        "pt": "Di um"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.8927,
        "lng": 106.7634
      },
      "country": "Vietnam",
      "importance": 2
    },
    {
      "id": 11114,
      "name": "Yurihonjo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 39.3858,
        "lng": 140.0489
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11077,
      "name": "Grand-Bassam",
      "names": {
        "zh": "大巴萨姆",
        "ja": "グランド-bassam",
        "th": "แกรนด์ bassam"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 5.2,
        "lng": -3.7333
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 11117,
      "name": "Yegoryevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.3667,
        "lng": 39.0167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11121,
      "name": "Siuna",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.7333,
        "lng": -84.7667
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 5460,
      "name": "Tulcea",
      "names": {
        "zh": "图尔恰"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.19,
        "lng": 28.8
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 5466,
      "name": "El Kef",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.1667,
        "lng": 8.7
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 11110,
      "name": "Nova Serrana",
      "names": {
        "ja": "ノヴァ・セラナ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.8758,
        "lng": -44.9839
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5468,
      "name": "Farah",
      "names": {
        "zh": "法拉",
        "th": "เราะห์"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 32.3436,
        "lng": 62.1194
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 5332,
      "name": "Ragusa",
      "names": {
        "zh": "拉古萨",
        "ja": "ラグーザ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 36.925,
        "lng": 14.7306
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 11107,
      "name": "Arnsberg",
      "names": {
        "zh": "阿恩斯贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3967,
        "lng": 8.0644
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5461,
      "name": "Birobidzhan",
      "names": {
        "zh": "比罗比詹"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 48.7833,
        "lng": 132.9333
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 11037,
      "name": "Mangalagiri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.43,
        "lng": 80.55
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11090,
      "name": "Joao Monlevade",
      "names": {
        "es": "Joao Montlevade",
        "fr": "Joao Monastavade",
        "pt": "João Monlevade"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.81,
        "lng": -43.1739
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11122,
      "name": "Linares",
      "names": {
        "zh": "利纳雷斯"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -35.8454,
        "lng": -71.5979
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 11127,
      "name": "Cleveland",
      "names": {
        "zh": "克利夫兰",
        "ja": "クリーブランド",
        "th": "คลีฟแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1817,
        "lng": -84.8707
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11116,
      "name": "Bawku",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 11.06,
        "lng": -0.2422
      },
      "country": "Ghana",
      "importance": 3
    },
    {
      "id": 11128,
      "name": "Wausau",
      "names": {
        "zh": "沃索"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.962,
        "lng": -89.6459
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5387,
      "name": "Harrogate",
      "names": {
        "zh": "哈罗盖特",
        "ja": "ハロゲート",
        "th": "ฮาร์โรเกท"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.9919,
        "lng": -1.5378
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11079,
      "name": "Copacabana",
      "names": {
        "zh": "科帕卡巴纳",
        "ja": "コパカバーナ",
        "th": "โคปาคาบานา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3486,
        "lng": -75.5103
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5471,
      "name": "Camden",
      "names": {
        "zh": "卡姆登",
        "ja": "カムデン",
        "th": "แคมเดน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9362,
        "lng": -75.1073
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11118,
      "name": "Kasama",
      "names": {
        "zh": "笠间"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.345,
        "lng": 140.3042
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11134,
      "name": "El Hamma",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 33.8864,
        "lng": 9.7951
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 11138,
      "name": "Anniston",
      "names": {
        "zh": "安尼斯顿",
        "ja": "アニストン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6712,
        "lng": -85.8136
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5464,
      "name": "Sanliurfa",
      "names": {
        "zh": "桑尼乌法",
        "id": "Sanliurfa guney anadoulu projesi"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.1583,
        "lng": 38.7917
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 11112,
      "name": "Molina de Segura",
      "names": {
        "ja": "モリナデセシュラ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.0548,
        "lng": -1.2131
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5474,
      "name": "Evanston",
      "names": {
        "zh": "埃文斯顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0463,
        "lng": -87.6942
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5470,
      "name": "Blagoevgrad",
      "names": {
        "zh": "布拉戈耶夫"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.0119,
        "lng": 23.0897
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 11120,
      "name": "Tupi",
      "names": {
        "es": "Tupí",
        "zh": "图皮",
        "ja": "トゥピ族"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.3333,
        "lng": 124.95
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11142,
      "name": "Apple Valley",
      "names": {
        "es": "Valle de manzana",
        "fr": "Vallée de pomme",
        "zh": "苹果谷",
        "de": "Apfeltal",
        "id": "Lembah Apple",
        "ja": "アップルバレー",
        "pt": "Vale da Apple"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5328,
        "lng": -117.2104
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11084,
      "name": "Novoaltaysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.4,
        "lng": 83.9333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11059,
      "name": "Tongye",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9679,
        "lng": 114.3782
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11069,
      "name": "Castrop-Rauxel",
      "names": {
        "zh": "卡斯特罗普-rauxel"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.55,
        "lng": 7.3167
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11111,
      "name": "Lala",
      "names": {
        "zh": "拉拉",
        "ja": "ララ",
        "th": "ลาลา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.9667,
        "lng": 123.75
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11129,
      "name": "Troitsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.0833,
        "lng": 61.5667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11132,
      "name": "Picos",
      "names": {
        "zh": "皮库斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.0769,
        "lng": -41.4669
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11096,
      "name": "Sundsvall",
      "names": {
        "zh": "松兹瓦尔"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 62.3902,
        "lng": 17.3067
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 11137,
      "name": "Jose Abad Santos",
      "names": {
        "es": "José Abad Santos",
        "zh": "何塞阿巴德桑托斯",
        "pt": "José Abad Santos"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 5.9167,
        "lng": 125.65
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11124,
      "name": "Irece",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -11.3039,
        "lng": -41.8558
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11148,
      "name": "Sault Ste. Marie",
      "names": {
        "zh": "sault ste。 玛丽",
        "ja": "Sault Ste。 マリー",
        "th": "Sault Ste มารี"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.5333,
        "lng": -84.35
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11146,
      "name": "Chapadinha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.7419,
        "lng": -43.36
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5366,
      "name": "Mpanda",
      "names": {
        "zh": "姆潘达"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.3436,
        "lng": 31.0694
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 11151,
      "name": "Mansfield",
      "names": {
        "zh": "曼斯菲尔德",
        "ja": "マンスフィールド",
        "th": "แมนส์ฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7656,
        "lng": -82.5275
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5481,
      "name": "Caguas",
      "names": {},
      "countryCode": "PR",
      "latLng": {
        "lat": 18.2319,
        "lng": -66.0388
      },
      "country": "Puerto Rico",
      "importance": 3
    },
    {
      "id": 11074,
      "name": "Nizhyn",
      "names": {
        "th": "นีชิน"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 51.05,
        "lng": 31.9
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 11152,
      "name": "Cuamba",
      "names": {
        "zh": "库安巴"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -14.82,
        "lng": 36.5486
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 11078,
      "name": "Hoorn",
      "names": {
        "zh": "霍伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6533,
        "lng": 5.0733
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11126,
      "name": "Quillota",
      "names": {
        "th": "กิโยตา"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -32.8799,
        "lng": -71.2474
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 5378,
      "name": "Crewe",
      "names": {
        "zh": "克鲁",
        "ja": "クルー",
        "th": "ครูว์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.099,
        "lng": -2.44
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11158,
      "name": "Tan-Tan",
      "names": {
        "zh": "谭-谭",
        "ja": "タン・タン",
        "th": "ตาล-ตาล"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 28.4333,
        "lng": -11.1
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 11155,
      "name": "Tokar",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 18.4333,
        "lng": 37.7333
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 5483,
      "name": "Fada Ngourma",
      "names": {
        "pt": "Ngourma fada"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.05,
        "lng": 0.3667
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 11102,
      "name": "Konin",
      "names": {
        "zh": "科宁"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.2275,
        "lng": 18.2614
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11157,
      "name": "Shakargarh",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.2628,
        "lng": 75.1583
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 11130,
      "name": "Bula",
      "names": {
        "zh": "布拉"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4667,
        "lng": 123.2833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5485,
      "name": "San Jose de las Lajas",
      "names": {
        "zh": "圣何塞德拉斯拉哈斯",
        "id": "San Jose de laJas",
        "th": "ซานโฮเซ่เดอลาจาส"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9678,
        "lng": -82.1558
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 5458,
      "name": "Nabeul",
      "names": {
        "zh": "纳布勒"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.4514,
        "lng": 10.7361
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 11139,
      "name": "Brakpan",
      "names": {
        "zh": "柏莱克潘"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -26.2353,
        "lng": 28.37
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 11147,
      "name": "Shibukawa",
      "names": {
        "zh": "涩川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4833,
        "lng": 139.0
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11162,
      "name": "Moa",
      "names": {
        "zh": "金属氧化物避雷器",
        "th": "ซื้อขายเรือ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.6556,
        "lng": -74.9417
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 11119,
      "name": "Chaigoubu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.6687,
        "lng": 114.4157
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11161,
      "name": "Santa Rosa de Cabal",
      "names": {
        "zh": "圣罗莎de Cabal",
        "ja": "サンタローザデマル",
        "th": "ซานตาโรซ่าเดอคาล"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8672,
        "lng": -75.6211
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11171,
      "name": "Alegrete",
      "names": {
        "zh": "阿莱格雷蒂"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.7839,
        "lng": -55.7911
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5402,
      "name": "Nek\u0027emte",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 9.0905,
        "lng": 36.53
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 11165,
      "name": "Naqadeh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.95,
        "lng": 45.3833
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 11164,
      "name": "Itapira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4361,
        "lng": -46.8217
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11175,
      "name": "San Vicente del Caguan",
      "names": {
        "zh": "圣维森特德德·德尔奇銮",
        "ja": "サンビセンテデルカガン"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.1153,
        "lng": -74.77
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5415,
      "name": "Jinja",
      "names": {
        "zh": "金贾",
        "de": "Jinjas",
        "ja": "ジンジャ"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.4431,
        "lng": 33.2128
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 5436,
      "name": "Calais",
      "names": {
        "zh": "加来",
        "ja": "カレー",
        "th": "กาเลส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.9481,
        "lng": 1.8564
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11115,
      "name": "Inuyama",
      "names": {
        "zh": "犬山"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3833,
        "lng": 136.95
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11168,
      "name": "La Dorada",
      "names": {
        "ja": "ラドラダ",
        "th": "ลาโดราดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4538,
        "lng": -74.6647
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11163,
      "name": "Otawara",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.8667,
        "lng": 140.0167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5421,
      "name": "Antibes",
      "names": {
        "zh": "昂蒂布",
        "ja": "アンティーブ",
        "th": "ออง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.5808,
        "lng": 7.1239
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5487,
      "name": "Dabou",
      "names": {
        "zh": "达布"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 5.3256,
        "lng": -4.3767
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 11109,
      "name": "Kostiantynivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5333,
        "lng": 37.7167
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 11178,
      "name": "Schaumburg",
      "names": {
        "zh": "绍姆堡",
        "th": "ชัม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0307,
        "lng": -88.0838
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11179,
      "name": "Pocatello",
      "names": {
        "zh": "波卡特洛"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.8724,
        "lng": -112.4646
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5491,
      "name": "Lappeenranta",
      "names": {
        "zh": "拉彭兰塔"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.0583,
        "lng": 28.1861
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 11172,
      "name": "Vsevolozhsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 60.016,
        "lng": 30.6663
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11185,
      "name": "Woodbury",
      "names": {
        "zh": "伍德伯里",
        "ja": "ウッドベリー",
        "th": "วูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.9056,
        "lng": -92.923
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11183,
      "name": "Crateus",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1778,
        "lng": -40.6778
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11123,
      "name": "Jingzhou",
      "names": {
        "zh": "荆州"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.6911,
        "lng": 116.2672
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5362,
      "name": "Perote",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.562,
        "lng": -97.242
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11160,
      "name": "Chapayevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.9833,
        "lng": 49.7167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11184,
      "name": "Pavia",
      "names": {
        "zh": "帕维亚",
        "ja": "パヴィア",
        "th": "ปาเวีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.1853,
        "lng": 9.155
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 11181,
      "name": "Tres Coracoes",
      "names": {
        "es": "Tres Coraces",
        "fr": "Tres coraces",
        "it": "Coracoi di tres",
        "id": "Corako",
        "ja": "Coracoes tres"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.6947,
        "lng": -45.2553
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11170,
      "name": "Catanauan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5917,
        "lng": 122.325
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5435,
      "name": "Spijkenisse",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8333,
        "lng": 4.3167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11187,
      "name": "Aracati",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.5619,
        "lng": -37.77
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11135,
      "name": "Quang Tri",
      "names": {
        "id": "Qual tri"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 16.7504,
        "lng": 107.2
      },
      "country": "Vietnam",
      "importance": 3
    },
    {
      "id": 11188,
      "name": "Southfield",
      "names": {
        "th": "เซาท์ฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4765,
        "lng": -83.2605
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5398,
      "name": "Tamazunchale",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2667,
        "lng": -98.7833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11191,
      "name": "Ellicott City",
      "names": {
        "es": "Ciudad de Ellicott",
        "zh": "艾利科特市",
        "ja": "エリコット市",
        "th": "เมือง Ellicott",
        "pt": "Cidade ellicott"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.2773,
        "lng": -76.8344
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11190,
      "name": "Yafran",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 32.0628,
        "lng": 12.5267
      },
      "country": "Libya",
      "importance": 3
    },
    {
      "id": 11150,
      "name": "Carles",
      "names": {
        "zh": "卡莱斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.5667,
        "lng": 123.1333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11193,
      "name": "Dale City",
      "names": {
        "es": "Ciudad de Dale",
        "zh": "戴尔城",
        "de": "Dalestadt",
        "it": "Città di Dale",
        "id": "Kota dale",
        "ja": "デールシティ",
        "th": "เมืองเดล",
        "pt": "Cidade dale"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6473,
        "lng": -77.3459
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11194,
      "name": "Maple Grove",
      "names": {
        "es": "Arboleda",
        "fr": "Érable",
        "zh": "枫树林",
        "de": "Ahornhain",
        "it": "Acero",
        "ja": "メープルグローブ",
        "th": "เมเปิ้ลโกรฟ",
        "pt": "Bordo de bordo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.1089,
        "lng": -93.4626
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11143,
      "name": "Ludenscheid",
      "names": {
        "de": "Lüdenscheid",
        "it": "Lüdenscheid"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2198,
        "lng": 7.6273
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11197,
      "name": "Pittsburg",
      "names": {
        "zh": "匹兹堡",
        "ja": "ピッツバーグ",
        "th": "พิตส์เบิร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.0182,
        "lng": -121.8964
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5442,
      "name": "Inowroclaw",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.7958,
        "lng": 18.2611
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11145,
      "name": "Vinhedo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.03,
        "lng": -46.975
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11140,
      "name": "Zhuolu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.3753,
        "lng": 115.215
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11136,
      "name": "Lanxi",
      "names": {
        "zh": "兰溪"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.2664,
        "lng": 126.276
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11200,
      "name": "Concepcion del Uruguay",
      "names": {
        "es": "Concepción del uruguay",
        "ja": "コンセプシオンデルウルグアイ",
        "pt": "Concepcion del Uruguai"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.4833,
        "lng": -58.2333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 5454,
      "name": "Masvingo",
      "names": {
        "zh": "马斯温戈"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -20.0744,
        "lng": 30.8328
      },
      "country": "Zimbabwe",
      "importance": 1
    },
    {
      "id": 11176,
      "name": "Belovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.4167,
        "lng": 86.3
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11149,
      "name": "Nepalganj",
      "names": {
        "zh": "尼泊尔根杰"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 28.05,
        "lng": 81.6167
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 5497,
      "name": "New Britain",
      "names": {
        "es": "Nueva Gran Bretaña",
        "fr": "Nouvelle-Bretagne",
        "zh": "新英国",
        "de": "Neue Großbritannien",
        "it": "Nuova Gran Bretagna",
        "id": "Inggris baru",
        "ja": "ニューイギリス",
        "th": "ใหม่อังกฤษ",
        "pt": "Bretanha nova"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6758,
        "lng": -72.7862
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11167,
      "name": "Bacacay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2925,
        "lng": 123.7917
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5453,
      "name": "Hekinan",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8833,
        "lng": 137.0
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11186,
      "name": "Ede",
      "names": {
        "zh": "埃德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0436,
        "lng": 5.6667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11133,
      "name": "Almelo",
      "names": {
        "zh": "阿尔默洛"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3575,
        "lng": 6.6667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11202,
      "name": "Morgantown",
      "names": {
        "zh": "摩根镇",
        "ja": "モーガンタウン",
        "th": "มอร์แกนทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.638,
        "lng": -79.9468
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11192,
      "name": "Sipalay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.75,
        "lng": 122.4
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11201,
      "name": "Belo Jardim",
      "names": {
        "ja": "ベロジャルディム"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.3358,
        "lng": -36.4239
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5444,
      "name": "Dazaifu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5128,
        "lng": 130.5239
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5451,
      "name": "Longkoucun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.5726,
        "lng": 116.7238
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11204,
      "name": "Mansfield",
      "names": {
        "zh": "曼斯菲尔德",
        "ja": "マンスフィールド",
        "th": "แมนส์ฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.569,
        "lng": -97.1211
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11205,
      "name": "Hammond",
      "names": {
        "zh": "哈蒙德",
        "ja": "ハモンド",
        "th": "แฮมมอนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.5061,
        "lng": -90.4563
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5440,
      "name": "Vlaardingen",
      "names": {
        "zh": "弗拉尔丁恩"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9167,
        "lng": 4.35
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11144,
      "name": "Landshut",
      "names": {
        "zh": "兰茨胡特",
        "ja": "ランツフート"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.5397,
        "lng": 12.1508
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11189,
      "name": "Keshan",
      "names": {
        "zh": "克山病"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.0263,
        "lng": 125.8659
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11206,
      "name": "Dothan",
      "names": {
        "zh": "多森"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.2335,
        "lng": -85.4069
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11207,
      "name": "Harrisonburg",
      "names": {
        "zh": "哈里森堡"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.4361,
        "lng": -78.8735
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11198,
      "name": "Pigcawayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.2833,
        "lng": 124.4333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11210,
      "name": "Wenatchee",
      "names": {
        "zh": "韦纳奇"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.4356,
        "lng": -120.3283
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11211,
      "name": "Goya",
      "names": {
        "zh": "戈雅",
        "ja": "ゴヤ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -29.1333,
        "lng": -59.25
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 5496,
      "name": "Oroquieta",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.4833,
        "lng": 123.8
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 11182,
      "name": "Pacatuba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.9839,
        "lng": -38.62
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11213,
      "name": "Waukesha",
      "names": {
        "zh": "沃基肖"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0087,
        "lng": -88.2464
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11177,
      "name": "Xiangjiaba",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.6282,
        "lng": 104.4211
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11166,
      "name": "Montepuez",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -13.1167,
        "lng": 39.0
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 5499,
      "name": "Abancay",
      "names": {
        "zh": "阿班凯"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.6333,
        "lng": -72.8833
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 5298,
      "name": "Campo Formoso",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -10.5089,
        "lng": -40.3208
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11195,
      "name": "Penafiel",
      "names": {
        "zh": "德佩尼亚菲耶尔",
        "th": "พีนาฟีล"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2,
        "lng": -8.2833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 11153,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1667,
        "lng": 123.7
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5390,
      "name": "Esbjerg",
      "names": {
        "zh": "埃斯比约"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.467,
        "lng": 8.45
      },
      "country": "Denmark",
      "importance": 2
    },
    {
      "id": 11217,
      "name": "Casa Nova",
      "names": {
        "ja": "カーサノバ",
        "th": "คาซ่าโนวา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.1619,
        "lng": -40.9708
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5455,
      "name": "Yoshikawa",
      "names": {
        "zh": "吉川",
        "ja": "吉川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8958,
        "lng": 139.8556
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5506,
      "name": "Pawtucket",
      "names": {
        "th": "พอว์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8744,
        "lng": -71.3743
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5314,
      "name": "Drancy",
      "names": {
        "zh": "德朗西"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.93,
        "lng": 2.45
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 11220,
      "name": "Tibati",
      "names": {},
      "countryCode": "CM",
      "latLng": {
        "lat": 6.4669,
        "lng": 12.6158
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 11221,
      "name": "Cremona",
      "names": {
        "zh": "克雷莫纳",
        "ja": "クレモナ",
        "th": "เครโมนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.1333,
        "lng": 10.0333
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 5504,
      "name": "Nizwa",
      "names": {},
      "countryCode": "OM",
      "latLng": {
        "lat": 22.9264,
        "lng": 57.5314
      },
      "country": "Oman",
      "importance": 1
    },
    {
      "id": 11141,
      "name": "Kirovo-Chepetsk",
      "names": {
        "zh": "基洛沃-chepetsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 58.55,
        "lng": 50.0167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11196,
      "name": "Camalig",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.1333,
        "lng": 123.6667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11208,
      "name": "Brandenburg",
      "names": {
        "fr": "Brandebourg",
        "zh": "勃兰登堡",
        "it": "Brandeburgo",
        "ja": "ブランデンブルク",
        "th": "บรันเดนบูร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4117,
        "lng": 12.5561
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5509,
      "name": "Najran",
      "names": {
        "zh": "奈季兰"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 17.4917,
        "lng": 44.1322
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 11113,
      "name": "Majadahonda",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 40.4728,
        "lng": -3.8722
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5505,
      "name": "Haskovo",
      "names": {
        "zh": "哈斯科沃",
        "ja": "ハスコヴォ"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 41.9346,
        "lng": 25.5556
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 11131,
      "name": "Ostrowiec Swietokrzyski",
      "names": {
        "ja": "SwietokrzyskiOstrowiec"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.9333,
        "lng": 21.4
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 5420,
      "name": "Bella Vista",
      "names": {
        "zh": "贝拉维斯塔",
        "ja": "ベラビスタ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5333,
        "lng": -58.6667
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 11219,
      "name": "Colon",
      "names": {
        "fr": "Côlon",
        "zh": "结肠",
        "id": "Usus besar",
        "ja": "コロン",
        "th": "ลำไส้ใหญ่",
        "pt": "Cólon"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7225,
        "lng": -80.9067
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 5507,
      "name": "Borongan",
      "names": {
        "zh": "博龙岸"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.6,
        "lng": 125.4333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 5447,
      "name": "San Andres",
      "names": {
        "es": "San Andrés",
        "zh": "圣安德烈斯",
        "ja": "サンアンドレス",
        "th": "ซานแอนเดรส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 12.5847,
        "lng": -81.7006
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 11154,
      "name": "Wenping",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.193,
        "lng": 103.5461
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11212,
      "name": "Gumaca",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.921,
        "lng": 122.1002
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11228,
      "name": "Redmond",
      "names": {
        "zh": "雷德蒙德",
        "it": "Di Redmond",
        "ja": "レドモンド",
        "th": "เรดมันด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.6762,
        "lng": -122.1169
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11203,
      "name": "Estepona",
      "names": {
        "ja": "エステポナ",
        "th": "เอสเตโปนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.4264,
        "lng": -5.1472
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5412,
      "name": "Chiryu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0,
        "lng": 137.1167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11229,
      "name": "Quixeramobim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1989,
        "lng": -39.2928
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5511,
      "name": "Cacak",
      "names": {
        "zh": "查查克"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8914,
        "lng": 20.3497
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 5515,
      "name": "Lauderhill",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 26.1605,
        "lng": -80.2242
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11226,
      "name": "Mun\u0027gyong",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.5939,
        "lng": 128.2014
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 11216,
      "name": "Ishioka",
      "names": {
        "zh": "石冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1833,
        "lng": 140.2833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11230,
      "name": "Valenca",
      "names": {
        "es": "Valença",
        "fr": "Valença",
        "zh": "瓦伦萨",
        "pt": "Valença"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.2458,
        "lng": -43.7
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5472,
      "name": "Antalya",
      "names": {
        "zh": "安塔利亚",
        "ja": "アンタルヤ",
        "th": "อันตัลยา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.9081,
        "lng": 30.6956
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 11223,
      "name": "Calauag",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9575,
        "lng": 122.2875
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11209,
      "name": "Cajamar",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3558,
        "lng": -46.8769
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11224,
      "name": "Bouafle",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 6.9903,
        "lng": -5.7442
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 11159,
      "name": "Sambrial",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.475,
        "lng": 74.3522
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 11125,
      "name": "Shrewsbury",
      "names": {
        "zh": "什鲁斯伯里",
        "ja": "シュルーズベリー",
        "th": "ชรูว์สบิวรี่"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.708,
        "lng": -2.754
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11234,
      "name": "Florida",
      "names": {
        "fr": "Floride",
        "zh": "佛罗里达",
        "ja": "フロリダ州",
        "th": "ฟลอริดา",
        "pt": "Flórida"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.5294,
        "lng": -78.2228
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 11180,
      "name": "Bakixanov",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.4217,
        "lng": 49.9644
      },
      "country": "Azerbaijan",
      "importance": 3
    },
    {
      "id": 11225,
      "name": "Kawm Umbu",
      "names": {
        "es": "Umbu kawm"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 24.4667,
        "lng": 32.95
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 11243,
      "name": "Sarnia",
      "names": {
        "zh": "萨尼亚"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.9994,
        "lng": -82.3089
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11244,
      "name": "Wood Buffalo",
      "names": {
        "es": "Búfalo de madera",
        "fr": "Buffalo bois",
        "zh": "木屑",
        "de": "Holzbüffel",
        "it": "Bufalo di legno",
        "id": "Kerbau",
        "ja": "ウッドバッファロー",
        "th": "ควายไม้",
        "pt": "Búfalo de madeira"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 57.6042,
        "lng": -111.3284
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11199,
      "name": "Pililla",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4833,
        "lng": 121.3
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11222,
      "name": "Santo Tirso",
      "names": {
        "ja": "サントタルソ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3425,
        "lng": -8.4775
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 5457,
      "name": "Gosport",
      "names": {
        "zh": "戈斯波特"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.7948,
        "lng": -1.1243
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11246,
      "name": "Sumter",
      "names": {
        "zh": "萨姆特",
        "ja": "サムター"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9392,
        "lng": -80.393
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11247,
      "name": "Redlands",
      "names": {
        "zh": "雷德兰兹",
        "th": "เรดแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0511,
        "lng": -117.1712
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11233,
      "name": "Parang",
      "names": {
        "es": "Machete malayo",
        "zh": "帕朗",
        "ja": "パラン",
        "th": "ฮอกกี้",
        "pt": "Parão"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 5.9167,
        "lng": 120.9167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11251,
      "name": "Daphne",
      "names": {
        "zh": "达芙妮",
        "ja": "ダフネ",
        "th": "ต้นแดฟนิ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.6286,
        "lng": -87.8866
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5349,
      "name": "Lomas del Mirador",
      "names": {
        "ja": "ローマデルミラドール"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6667,
        "lng": -58.5297
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 5523,
      "name": "Salama",
      "names": {
        "zh": "萨拉马"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1052,
        "lng": -90.316
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 11240,
      "name": "Lisburn",
      "names": {
        "zh": "利斯本"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.5167,
        "lng": -6.0333
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5431,
      "name": "Gouda",
      "names": {
        "zh": "豪达",
        "ja": "グーダ",
        "th": "เกาดา"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0181,
        "lng": 4.7056
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11215,
      "name": "Lajeado",
      "names": {
        "zh": "拉热阿杜"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.4669,
        "lng": -51.9608
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11253,
      "name": "Mount Vernon",
      "names": {
        "fr": "Mont Vernon",
        "zh": "弗农山",
        "de": "Büschel",
        "ja": "マウントバーノン",
        "pt": "Monte Vernon"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 48.4202,
        "lng": -122.3115
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11248,
      "name": "Izmail",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.35,
        "lng": 28.8333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 11231,
      "name": "Binalbagan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2,
        "lng": 122.8667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11238,
      "name": "Katori",
      "names": {
        "zh": "香取"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8978,
        "lng": 140.4992
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11255,
      "name": "Villa Tunari",
      "names": {
        "de": "Villa-Tunari"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -16.9725,
        "lng": -65.42
      },
      "country": "Bolivia",
      "importance": 3
    },
    {
      "id": 11235,
      "name": "Goa",
      "names": {
        "zh": "果阿",
        "ja": "ゴア",
        "th": "กัว"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7,
        "lng": 123.5
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11174,
      "name": "Paterna",
      "names": {
        "zh": "帕特纳"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.5028,
        "lng": -0.4406
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11156,
      "name": "Ostend",
      "names": {
        "fr": "Ostende",
        "zh": "奥斯坦德",
        "de": "Ostende",
        "th": "ออสเตนด์",
        "pt": "Oostende"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.2333,
        "lng": 2.9333
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 11173,
      "name": "Sotik",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.6796,
        "lng": 35.12
      },
      "country": "Kenya",
      "importance": 3
    },
    {
      "id": 11232,
      "name": "Talibon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.1167,
        "lng": 124.2833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5395,
      "name": "Las Piedras",
      "names": {
        "zh": "拉斯佩兹拉斯",
        "ja": "ラスピエドラ"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.7167,
        "lng": -56.2167
      },
      "country": "Uruguay",
      "importance": 3
    },
    {
      "id": 11258,
      "name": "Verkhnyaya Pyshma",
      "names": {
        "id": "Pyshma verkhnyaya",
        "th": "Verkhnyaya pyshama"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.9667,
        "lng": 60.5833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5437,
      "name": "Jinsha",
      "names": {
        "zh": "金沙"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.5329,
        "lng": 116.6165
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11257,
      "name": "Placetas",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.3158,
        "lng": -79.6556
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 5419,
      "name": "Musashimurayama",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7547,
        "lng": 139.3875
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5467,
      "name": "Salima",
      "names": {
        "zh": "萨利马"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -13.7829,
        "lng": 34.4333
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 11266,
      "name": "Weston",
      "names": {
        "zh": "韦斯顿",
        "ja": "ウェストン",
        "th": "เวสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.1006,
        "lng": -80.4054
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5519,
      "name": "Mamou",
      "names": {
        "zh": "马木"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.3833,
        "lng": -12.0833
      },
      "country": "Guinea",
      "importance": 1
    },
    {
      "id": 11169,
      "name": "Morong",
      "names": {
        "zh": "莫龙"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5119,
        "lng": 121.2389
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11237,
      "name": "Carpi",
      "names": {
        "zh": "卡尔皮",
        "ja": "カルピ",
        "th": "คาร์"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.7833,
        "lng": 10.885
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11254,
      "name": "San Francisco del Rincon",
      "names": {
        "zh": "旧金山德尔林康",
        "ja": "サンフランシスコデルリンコン",
        "th": "ซานฟรานซิสโกเดลรินคอน",
        "pt": "São Francisco del Rincon"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0228,
        "lng": -101.86
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11262,
      "name": "Gibara",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 21.1072,
        "lng": -76.1367
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 11236,
      "name": "Bocholt",
      "names": {
        "zh": "博霍尔特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8333,
        "lng": 6.6167
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11249,
      "name": "Markapur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.735,
        "lng": 79.27
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11242,
      "name": "Bunbury",
      "names": {
        "zh": "班伯里"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -33.3272,
        "lng": 115.6369
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 11261,
      "name": "Mauban",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1911,
        "lng": 121.7308
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5331,
      "name": "Tejupilco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9058,
        "lng": -100.1528
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5463,
      "name": "Warzat",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 30.9167,
        "lng": -6.9167
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 11271,
      "name": "St. Charles",
      "names": {
        "zh": "圣查尔斯",
        "ja": "セントチャールズ",
        "th": "เซนต์ชาร์ลส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.7954,
        "lng": -90.5157
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5531,
      "name": "New Westminster",
      "names": {
        "es": "Nueva Westminster",
        "fr": "Nouveau Westminster",
        "zh": "新威斯敏斯特",
        "de": "Neue Westminster",
        "it": "Nuovo Westminster",
        "ja": "新しいウェストミンスター",
        "th": "ใหม่ Westminster"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2069,
        "lng": -122.9111
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11272,
      "name": "Cherry Hill",
      "names": {
        "es": "Cereza",
        "fr": "Colline de cerise",
        "zh": "樱桃山",
        "de": "Kirschhügel",
        "it": "Ciliegio collina",
        "id": "Bukit ceri",
        "ja": "チェリーヒル",
        "pt": "Colina de cereja"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9034,
        "lng": -74.9947
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11256,
      "name": "Malaut",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 30.19,
        "lng": 74.499
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5449,
      "name": "Lowestoft",
      "names": {
        "zh": "洛斯托夫特"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.48,
        "lng": 1.75
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11227,
      "name": "Yukuhashi",
      "names": {
        "zh": "行桥"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.7286,
        "lng": 130.9831
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11241,
      "name": "Quartu Sant\u0027Elena",
      "names": {
        "id": "Kuartu sant\u0027elena"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.2291,
        "lng": 9.2649
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 5528,
      "name": "Phitsanulok",
      "names": {
        "zh": "彭世洛",
        "th": "พิษณุโลก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8158,
        "lng": 100.2636
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 11267,
      "name": "Corozal",
      "names": {
        "zh": "科罗萨尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.3169,
        "lng": -75.2933
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5532,
      "name": "Kumanovo",
      "names": {
        "zh": "库马诺沃"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.1322,
        "lng": 21.7144
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 5517,
      "name": "Ajaccio",
      "names": {
        "zh": "阿雅克肖",
        "ja": "アジャクシオ",
        "th": "ในอายาชชอ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 41.9267,
        "lng": 8.7369
      },
      "country": "France",
      "importance": 1
    },
    {
      "id": 11214,
      "name": "Merignac",
      "names": {
        "fr": "Mérignac",
        "zh": "梅里尼亚克"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.8386,
        "lng": -0.6436
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 11281,
      "name": "Chateauguay",
      "names": {
        "zh": "沙托盖"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.38,
        "lng": -73.75
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5529,
      "name": "Zinjibar",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 13.1283,
        "lng": 45.3803
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 5524,
      "name": "Yambol",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.4824,
        "lng": 26.5038
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 11245,
      "name": "Bapatla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.8889,
        "lng": 80.47
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11279,
      "name": "Cacador",
      "names": {
        "zh": "卡萨多尔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.775,
        "lng": -51.015
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5477,
      "name": "Debre Mark\u0027os",
      "names": {
        "es": "Debe Mark\u0027os",
        "zh": "德布雷克"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 10.34,
        "lng": 37.72
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 11285,
      "name": "Janesville",
      "names": {
        "zh": "简斯维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6855,
        "lng": -89.0136
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11282,
      "name": "Novomoskovsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6328,
        "lng": 35.2239
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 11277,
      "name": "Tumauini",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 17.2667,
        "lng": 121.8
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11289,
      "name": "Sherman",
      "names": {
        "zh": "谢尔曼",
        "ja": "シャーマン",
        "th": "เชอร์แมน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6274,
        "lng": -96.6218
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5480,
      "name": "Sirvan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.9323,
        "lng": 48.9203
      },
      "country": "Azerbaijan",
      "importance": 3
    },
    {
      "id": 11291,
      "name": "North Richland Hills",
      "names": {
        "zh": "北富豪山",
        "it": "Hills del Nord Richland",
        "id": "Bukit Richland Utara",
        "ja": "ノースリッチランドヒルズ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8605,
        "lng": -97.218
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5396,
      "name": "Rugby",
      "names": {
        "zh": "橄榄球",
        "id": "Ragbi",
        "ja": "ラグビー",
        "th": "รักบี้"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.37,
        "lng": -1.26
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11278,
      "name": "Badvel",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.75,
        "lng": 79.05
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11287,
      "name": "Loule",
      "names": {
        "es": "Loulé",
        "fr": "Loulé",
        "pt": "Loulé"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.144,
        "lng": -8.0235
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 11218,
      "name": "Saint-Nazaire",
      "names": {
        "zh": "圣纳泽尔",
        "ja": "サン・nazaire",
        "th": "เซนต์-nazaire"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.2806,
        "lng": -2.2086
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11260,
      "name": "Yinying",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.941,
        "lng": 113.5602
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5538,
      "name": "Chiang Rai",
      "names": {
        "zh": "清莱",
        "ja": "チェンライ",
        "th": "เชียงราย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.9094,
        "lng": 99.8275
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 5448,
      "name": "Poblacion",
      "names": {
        "zh": "波夫拉西翁"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.25,
        "lng": 123.95
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11250,
      "name": "Shujaabad",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 29.8803,
        "lng": 71.295
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 11288,
      "name": "Stafford",
      "names": {
        "zh": "斯塔福德",
        "ja": "スタッフォード",
        "th": "คัสติญิโอเน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.807,
        "lng": -2.117
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11269,
      "name": "Djemmal",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 35.64,
        "lng": 10.76
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 11259,
      "name": "Cabudare",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0331,
        "lng": -69.2633
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 11286,
      "name": "Zenica",
      "names": {
        "zh": "泽尼察"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.2017,
        "lng": 17.9039
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 11293,
      "name": "Reconquista",
      "names": {
        "zh": "雷孔基斯塔",
        "ja": "レコンキスタ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -29.1443,
        "lng": -59.6438
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 11292,
      "name": "Chekhov",
      "names": {
        "es": "Chéjov",
        "fr": "Tchekhov",
        "zh": "契诃夫",
        "de": "Tschechow",
        "ja": "チェーホフ"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.1481,
        "lng": 37.4769
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11268,
      "name": "Bundaberg",
      "names": {
        "zh": "班达伯格"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -24.8661,
        "lng": 152.3494
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 11239,
      "name": "Aschaffenburg",
      "names": {
        "zh": "阿沙芬堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.9757,
        "lng": 9.1478
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11290,
      "name": "Altamura",
      "names": {
        "zh": "阿尔塔穆拉"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.8167,
        "lng": 16.55
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11296,
      "name": "Caldas Novas",
      "names": {
        "ja": "カルダスノバス",
        "th": "คัลดาสน์โนวา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -17.7439,
        "lng": -48.6258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11265,
      "name": "Baiquan",
      "names": {
        "zh": "百泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.6018,
        "lng": 126.0819
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5536,
      "name": "Mindelo",
      "names": {
        "zh": "明德洛"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 16.8914,
        "lng": -24.988
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 11298,
      "name": "Broomfield",
      "names": {
        "zh": "布鲁姆菲尔德",
        "ja": "ブルームフィールド"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9541,
        "lng": -105.0527
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11295,
      "name": "Sao Sebastiao do Paraiso",
      "names": {
        "zh": "Sao Sebastiao做Paraiso",
        "id": "Sao Sebastiao melakukan paraiso",
        "ja": "サンセバステアオパライソ",
        "th": "Sao Sebastiao ทำ Paraiso",
        "pt": "São Sebastião do Paraiso"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.9169,
        "lng": -46.9908
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11294,
      "name": "Bur Fu\u0027ad",
      "names": {
        "ja": "バーフアド"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.2314,
        "lng": 32.3253
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 5490,
      "name": "Pavia",
      "names": {
        "zh": "帕维亚",
        "ja": "パヴィア",
        "th": "ปาเวีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.775,
        "lng": 122.5417
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5484,
      "name": "Yawata-shimizui",
      "names": {
        "zh": "八幡-shimizui"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8756,
        "lng": 135.7078
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5548,
      "name": "Aksaray",
      "names": {
        "zh": "阿克萨赖"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3686,
        "lng": 34.0297
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 11252,
      "name": "Orani",
      "names": {
        "zh": "奥拉尼"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8,
        "lng": 120.5333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11299,
      "name": "Santa Maria La Pila",
      "names": {
        "zh": "圣玛丽亚洛杉矶皮拉",
        "ja": "サンタマリアラピラ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.6056,
        "lng": -89.8125
      },
      "country": "Guatemala",
      "importance": 3
    },
    {
      "id": 5545,
      "name": "Sunyani",
      "names": {
        "zh": "苏尼亚尼"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 7.336,
        "lng": -2.336
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 5521,
      "name": "Slatina",
      "names": {
        "zh": "斯拉蒂纳"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.4297,
        "lng": 24.3642
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 11309,
      "name": "El Paso de Robles",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 35.6394,
        "lng": -120.656
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11310,
      "name": "Bristol",
      "names": {
        "zh": "布里斯托尔",
        "ja": "ブリストル",
        "th": "บริสตอล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.5572,
        "lng": -82.2154
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5495,
      "name": "Benalmadena",
      "names": {
        "es": "Benalmádena",
        "ja": "ベナルマデナ",
        "th": "เบนัลมาเดนา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.6,
        "lng": -4.5167
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11276,
      "name": "Hujra Shah Muqim",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.7408,
        "lng": 73.8219
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 5473,
      "name": "Sa`dah",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 16.94,
        "lng": 43.7592
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 11283,
      "name": "Rizal",
      "names": {
        "zh": "黎刹"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6833,
        "lng": 121.1667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11300,
      "name": "Tinambac",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8167,
        "lng": 123.3333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5551,
      "name": "Wilmington",
      "names": {
        "zh": "威尔明顿",
        "ja": "ウィルミントン",
        "th": "วิลมิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7415,
        "lng": -75.5413
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11311,
      "name": "Casas Adobes",
      "names": {
        "id": "Adobes Casas",
        "ja": "カーサスアディス",
        "th": "คาซัส adobes"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.3423,
        "lng": -111.0114
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11312,
      "name": "Walnut Creek",
      "names": {
        "fr": "Ruisseau walnut",
        "zh": "核桃溪",
        "ja": "クルミクリーク",
        "th": "วอลนุทครีก",
        "pt": "Nogueira riacho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9024,
        "lng": -122.0398
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5502,
      "name": "Havirov",
      "names": {},
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.7778,
        "lng": 18.4228
      },
      "country": "Czechia",
      "importance": 3
    },
    {
      "id": 11280,
      "name": "Kabirwala",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.4053,
        "lng": 71.8681
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 11304,
      "name": "Eniwa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 42.8826,
        "lng": 141.5778
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11317,
      "name": "Qo`ng`irot Shahri",
      "names": {
        "de": "Qo`ng`nngirot shahri",
        "pt": "Qo`ng`ng`irart shahri"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 43.0758,
        "lng": 58.9067
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 11303,
      "name": "Guzhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.9452,
        "lng": 108.5238
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11270,
      "name": "Pan\u0027an",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7575,
        "lng": 105.1135
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5465,
      "name": "Tsurugashima",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9344,
        "lng": 139.3931
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11263,
      "name": "Erd",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3833,
        "lng": 18.9167
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 11320,
      "name": "Cahama",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -16.2833,
        "lng": 14.3167
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 11318,
      "name": "Oshnaviyeh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.0397,
        "lng": 45.0983
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 788,
      "name": "Castries",
      "names": {
        "zh": "卡斯特里",
        "ja": "カストリーズ",
        "th": "แคสตรีส์"
      },
      "countryCode": "LC",
      "latLng": {
        "lat": 14.0167,
        "lng": -60.9833
      },
      "country": "Saint Lucia",
      "importance": 0
    },
    {
      "id": 5486,
      "name": "Thakhek",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 17.4,
        "lng": 104.8
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 5553,
      "name": "Gardez",
      "names": {
        "zh": "加德兹"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.5931,
        "lng": 69.2297
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 11306,
      "name": "Zhanggu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.8795,
        "lng": 101.8822
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11315,
      "name": "Az Zubaydiyah",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 32.7588,
        "lng": 45.1773
      },
      "country": "Iraq",
      "importance": 3
    },
    {
      "id": 11316,
      "name": "Phu Quoc",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 10.2289,
        "lng": 103.9572
      },
      "country": "Vietnam",
      "importance": 2
    },
    {
      "id": 5489,
      "name": "San Antonio",
      "names": {
        "zh": "圣安东尼奥",
        "ja": "サンアントニオ",
        "th": "ซานอันโตนิโอ"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.3797,
        "lng": -57.6097
      },
      "country": "Paraguay",
      "importance": 3
    },
    {
      "id": 11264,
      "name": "Entebbe",
      "names": {
        "zh": "恩德培"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.05,
        "lng": 32.46
      },
      "country": "Uganda",
      "importance": 3
    },
    {
      "id": 11322,
      "name": "Cianorte",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.6628,
        "lng": -52.605
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11327,
      "name": "Poinciana",
      "names": {
        "zh": "凤凰木",
        "ja": "ホウオウボク"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.1217,
        "lng": -81.482
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11307,
      "name": "Imola",
      "names": {
        "zh": "伊莫拉"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.3531,
        "lng": 11.7147
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 5552,
      "name": "Sagaing",
      "names": {
        "zh": "实皆"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 21.8822,
        "lng": 95.9786
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 11326,
      "name": "Qingquan",
      "names": {
        "zh": "清泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7823,
        "lng": 101.0826
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5560,
      "name": "Lynwood",
      "names": {
        "zh": "林伍德"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.924,
        "lng": -118.2017
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5559,
      "name": "Moquegua",
      "names": {
        "zh": "莫克瓜"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -17.2,
        "lng": -70.9333
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 11335,
      "name": "Glen Burnie",
      "names": {
        "zh": "格伦伯恩",
        "it": "Burie di Glen",
        "ja": "グレンバーニー"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1559,
        "lng": -76.6072
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5555,
      "name": "Encarnacion",
      "names": {
        "zh": "恩卡纳西翁",
        "de": "Encarnación"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -27.3333,
        "lng": -55.8667
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 11313,
      "name": "Villa del Rosario",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.8353,
        "lng": -72.4736
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11334,
      "name": "Russas",
      "names": {
        "zh": "鲁萨斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.94,
        "lng": -37.9758
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11328,
      "name": "Garzon",
      "names": {
        "es": "Garzón",
        "zh": "加尔松"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.1961,
        "lng": -75.6292
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5380,
      "name": "San Miguel de Allende",
      "names": {
        "ja": "サンミゲルデアジェンデ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9142,
        "lng": -100.7436
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5438,
      "name": "Kiyosu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2,
        "lng": 136.85
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11332,
      "name": "Cataguases",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.3889,
        "lng": -42.6969
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5554,
      "name": "Szolnok",
      "names": {
        "zh": "索尔诺克"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1747,
        "lng": 20.1764
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 11337,
      "name": "San Juan de los Lagos",
      "names": {
        "zh": "圣胡安de los拉各斯",
        "ja": "サンファンデロスラゴス",
        "th": "ซานฮวนเดอลอสลากอส"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2489,
        "lng": -102.3304
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11343,
      "name": "Lehi",
      "names": {
        "th": "เลหิ",
        "pt": "Leí"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4136,
        "lng": -111.8726
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5310,
      "name": "Los Reyes de Salgado",
      "names": {
        "ja": "ロスリーレスデサルガード",
        "th": "ลอสเรเยสเดอ Salgado"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5833,
        "lng": -102.4667
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 11321,
      "name": "Basavakalyan",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.87,
        "lng": 76.95
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5566,
      "name": "Passaic",
      "names": {
        "zh": "帕塞伊克"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8574,
        "lng": -74.1282
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11331,
      "name": "Minami-Alps",
      "names": {
        "es": "Minami-Alpes",
        "fr": "Minami-Alpes",
        "zh": "南-阿尔卑斯山",
        "de": "Minami-Alpen",
        "it": "Minami-Alpi",
        "ja": "南・アルプス",
        "th": "มินามิ-เทือกเขาแอลป์",
        "pt": "Minami-Alpes"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6083,
        "lng": 138.465
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11339,
      "name": "Uwajima",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.2233,
        "lng": 132.5606
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11341,
      "name": "Suwalki",
      "names": {
        "zh": "苏瓦乌基"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.0833,
        "lng": 22.9333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11345,
      "name": "Roxas",
      "names": {
        "zh": "罗哈斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3333,
        "lng": 119.3333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5492,
      "name": "Arba Minch\u0027",
      "names": {
        "es": "Arba Minch \u0027",
        "fr": "Arba Minch \u0027",
        "de": "Arba Minch \u0027",
        "it": "Arba Minch \u0027",
        "id": "Arba Minth \u0027",
        "ja": "アーバミンチ \u0027",
        "th": "Arba Minch \u0027",
        "pt": "Arba minch \u0027"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 6.04,
        "lng": 37.55
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 5426,
      "name": "Rioverde",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.93,
        "lng": -99.98
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11347,
      "name": "Paragominas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.9667,
        "lng": -47.4833
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11324,
      "name": "Celle",
      "names": {
        "zh": "策勒",
        "ja": "ツェレ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.6256,
        "lng": 10.0825
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11274,
      "name": "Ritto",
      "names": {
        "zh": "栗东"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0217,
        "lng": 135.9981
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11350,
      "name": "Saint-Jerome",
      "names": {
        "fr": "Saint-Jérôme",
        "zh": "圣 - 杰罗姆",
        "ja": "サン・ジェローム",
        "th": "เซนต์เจอโรม"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.7833,
        "lng": -74.0
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11333,
      "name": "Tournai",
      "names": {
        "zh": "图尔奈",
        "ja": "トゥルネー"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6,
        "lng": 3.3833
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 11354,
      "name": "Bom Jesus da Lapa",
      "names": {
        "zh": "BOM耶稣达拉帕",
        "it": "BOM GESÙ DA LAPA",
        "th": "Bom พระเยซูดาลาปา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -13.255,
        "lng": -43.4178
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11356,
      "name": "Homestead",
      "names": {
        "zh": "宅基地",
        "ja": "ホームステッド",
        "th": "ไร่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.4665,
        "lng": -80.4472
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11352,
      "name": "Samch\u0027ok",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4406,
        "lng": 129.1708
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 11330,
      "name": "Sanlucar de Barrameda",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 36.7667,
        "lng": -6.35
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11358,
      "name": "West Bend",
      "names": {
        "fr": "Bend de West",
        "zh": "西弯",
        "de": "Westniede",
        "id": "Tikungan barat",
        "ja": "ウェストベンド",
        "th": "เวสต์โค้ง",
        "pt": "Curva Ocidental"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.4184,
        "lng": -88.1824
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11349,
      "name": "Macaiba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.8578,
        "lng": -35.3539
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5493,
      "name": "Thohoyandou",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -22.95,
        "lng": 30.4833
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 11360,
      "name": "Delray Beach",
      "names": {
        "es": "Playa de della",
        "fr": "Plage de Delray",
        "zh": "德国海滩",
        "de": "Delay-Strand",
        "id": "Pantai Delray",
        "ja": "デルレイビーチ",
        "th": "เดลเรย์บีช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.455,
        "lng": -80.0905
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5569,
      "name": "L\u0027Aquila",
      "names": {
        "zh": "拉奎拉",
        "ja": "ラクイラ",
        "th": "ลากวิลา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.354,
        "lng": 13.392
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 11364,
      "name": "Sheboygan",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 43.7403,
        "lng": -87.7316
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5501,
      "name": "Toyoake",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.05,
        "lng": 137.0167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5514,
      "name": "Al Qusiyah",
      "names": {
        "ja": "アルQusiyah",
        "th": "อัล qusiyah"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 27.4403,
        "lng": 30.8183
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 11366,
      "name": "Aqsu",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 52.0333,
        "lng": 76.9167
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 5568,
      "name": "Balikesir",
      "names": {
        "zh": "巴勒克埃西尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.6511,
        "lng": 27.8842
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 11365,
      "name": "Chisec",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.8125,
        "lng": -90.3217
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 11367,
      "name": "Planeta Rica",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.4089,
        "lng": -75.5819
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11373,
      "name": "Lake Elsinore",
      "names": {
        "es": "Lago Elsinore",
        "fr": "Lac Elsinore",
        "zh": "埃尔斯林湖",
        "de": "See Elsinore",
        "it": "Lago Elsinore",
        "id": "Danau Elsinore",
        "ja": "エルシノア湖",
        "th": "ทะเลสาบเอลซีน",
        "pt": "Lago Elsinore"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.6847,
        "lng": -117.3344
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11351,
      "name": "Don Carlos",
      "names": {
        "zh": "唐卡洛斯",
        "ja": "ドンカルロス",
        "th": "ดอนคาร์ลอส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6833,
        "lng": 125.0
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11370,
      "name": "Bafia",
      "names": {
        "zh": "巴菲亚"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 4.7425,
        "lng": 11.2247
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 11355,
      "name": "Cawayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.0333,
        "lng": 123.6833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11357,
      "name": "Anan",
      "names": {
        "zh": "阿南",
        "ja": "アナン",
        "th": "อนันต์"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.9167,
        "lng": 134.6667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11372,
      "name": "Brumado",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -14.2039,
        "lng": -41.665
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11323,
      "name": "Shangchuankou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.3283,
        "lng": 102.8015
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11362,
      "name": "Ongjang",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 37.9371,
        "lng": 125.3571
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 11379,
      "name": "Daytona Beach",
      "names": {
        "es": "Playa Daytona",
        "fr": "Plage de Daytona",
        "zh": "代托纳海滩",
        "id": "Pantai Daytona",
        "ja": "デイトナビーチ",
        "th": "เดย์โทนาบีช",
        "pt": "Praia Daytona"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.1994,
        "lng": -81.0982
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11319,
      "name": "Fanzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7771,
        "lng": 114.962
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11305,
      "name": "Rongcheng",
      "names": {
        "zh": "荣成"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.05,
        "lng": 115.8657
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11275,
      "name": "Benidorm",
      "names": {
        "zh": "贝尼多姆",
        "ja": "ベニドーム",
        "th": "เบนิดอร์ม"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.5342,
        "lng": -0.1314
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11380,
      "name": "Necocli",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.4258,
        "lng": -76.7839
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11297,
      "name": "Kovel",
      "names": {
        "zh": "科韦利",
        "th": "โคเวล"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 51.2167,
        "lng": 24.7167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 11336,
      "name": "Mansehra",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 34.3333,
        "lng": 73.2
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 11381,
      "name": "Mocuba",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -16.8391,
        "lng": 36.9855
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 11340,
      "name": "Massa",
      "names": {
        "zh": "马萨",
        "ja": "マッサ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.0333,
        "lng": 10.1333
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 11314,
      "name": "Jorhat",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.75,
        "lng": 94.2167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11390,
      "name": "Los Lunas",
      "names": {
        "zh": "洛斯伦纳斯",
        "ja": "ロスルナ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.8115,
        "lng": -106.7803
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11301,
      "name": "San Pascual",
      "names": {
        "fr": "San pascule",
        "zh": "圣人士",
        "it": "San pascuale",
        "ja": "サンパスカール",
        "th": "ซานพังสว"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8,
        "lng": 121.0333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11375,
      "name": "Kamsar",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 10.65,
        "lng": -14.6167
      },
      "country": "Guinea",
      "importance": 3
    },
    {
      "id": 11371,
      "name": "Ko Samui",
      "names": {
        "id": "Koamui",
        "ja": "桜のサムイ",
        "th": "เกาะสมุย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.5157,
        "lng": 99.9436
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 11273,
      "name": "Moriya",
      "names": {
        "zh": "守屋"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9514,
        "lng": 139.9756
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11385,
      "name": "Tanabe",
      "names": {
        "zh": "田边",
        "ja": "田辺"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.7333,
        "lng": 135.3833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11391,
      "name": "Mocuba",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -16.8496,
        "lng": 38.26
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 11369,
      "name": "Korba",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.5667,
        "lng": 10.8667
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 11378,
      "name": "Huishi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6918,
        "lng": 105.0531
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11344,
      "name": "Yitiaoshan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1889,
        "lng": 104.0571
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11353,
      "name": "La Paz",
      "names": {
        "zh": "拉巴斯",
        "ja": "ラパス"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.4431,
        "lng": 120.7289
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11302,
      "name": "Rosetta",
      "names": {
        "zh": "罗塞塔",
        "ja": "ロゼッタ"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 31.4014,
        "lng": 30.4194
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 11386,
      "name": "Bagumbayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.5339,
        "lng": 124.5633
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11374,
      "name": "Liuhe",
      "names": {
        "zh": "六合"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.2669,
        "lng": 125.7404
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11325,
      "name": "Kempten",
      "names": {
        "zh": "肯普滕"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.7333,
        "lng": 10.3167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5513,
      "name": "La Chorrera",
      "names": {
        "fr": "La choréra",
        "ja": "ラコレラ"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.8792,
        "lng": -79.7822
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 11284,
      "name": "Kladno",
      "names": {
        "zh": "克拉德诺"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.1431,
        "lng": 14.1053
      },
      "country": "Czechia",
      "importance": 3
    },
    {
      "id": 11388,
      "name": "Buenavista",
      "names": {
        "zh": "布埃纳维斯塔",
        "ja": "ブエナビスタ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.9744,
        "lng": 125.409
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11396,
      "name": "Tiangua",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.7319,
        "lng": -40.9919
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5579,
      "name": "San Jose del Guaviare",
      "names": {
        "zh": "圣何塞德尔瓜亚雷",
        "ja": "サンホセルグアビアレ",
        "th": "ซานโฮเซ่เดลฝรั่ง",
        "pt": "San Jose Del Guaviáre"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.5653,
        "lng": -72.6386
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 11404,
      "name": "Lima",
      "names": {
        "zh": "利马",
        "ja": "リマ",
        "th": "ลิมา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.741,
        "lng": -84.1121
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11407,
      "name": "Decatur",
      "names": {
        "zh": "迪凯特",
        "ja": "ディケーター",
        "th": "ดีเคเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.573,
        "lng": -86.9906
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11383,
      "name": "Aparri",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 18.355,
        "lng": 121.6419
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11403,
      "name": "Wum",
      "names": {
        "zh": "武姆"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 6.3833,
        "lng": 10.0667
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 11399,
      "name": "Chulucanas",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -5.1092,
        "lng": -80.1625
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 5418,
      "name": "Ixtaczoquitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.85,
        "lng": -97.0667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11409,
      "name": "Rocklin",
      "names": {
        "th": "ร็อกลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.8075,
        "lng": -121.2488
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11400,
      "name": "Sousa",
      "names": {
        "zh": "索萨"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.7608,
        "lng": -38.2308
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5580,
      "name": "Ponta Delgada",
      "names": {
        "ja": "ポンタデルガダ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.74,
        "lng": -25.67
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 11405,
      "name": "Mococa",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.4678,
        "lng": -47.0047
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11389,
      "name": "Purisima de Bustos",
      "names": {
        "it": "Purisima de Busto",
        "ja": "プリシマデブストス",
        "th": "Puriisima de Bustos"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0333,
        "lng": -101.8667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11392,
      "name": "Arcoverde",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.4189,
        "lng": -37.0539
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11401,
      "name": "Al Ahmadi",
      "names": {
        "ja": "アル・アフマディ",
        "th": "อัลอามาดิ"
      },
      "countryCode": "KW",
      "latLng": {
        "lat": 29.0769,
        "lng": 48.0838
      },
      "country": "Kuwait",
      "importance": 3
    },
    {
      "id": 11397,
      "name": "Say\u0027un",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 15.943,
        "lng": 48.7873
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 11342,
      "name": "Colmar",
      "names": {
        "zh": "科尔马",
        "ja": "コルマール",
        "th": "โคลมาร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.0817,
        "lng": 7.3556
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11346,
      "name": "Indang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2,
        "lng": 120.8833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5516,
      "name": "Zaandam",
      "names": {
        "zh": "赞丹"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4417,
        "lng": 4.8422
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11417,
      "name": "Rogers",
      "names": {
        "zh": "罗杰斯",
        "ja": "ロジャーズ",
        "th": "โรเจอร์ส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3172,
        "lng": -94.1526
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11359,
      "name": "Sabae",
      "names": {
        "zh": "鲭江"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9567,
        "lng": 136.1844
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11412,
      "name": "Alamada",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.3868,
        "lng": 124.5534
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11329,
      "name": "Isna",
      "names": {
        "zh": "伊斯纳"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 25.2933,
        "lng": 32.5564
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 11414,
      "name": "Concordia",
      "names": {
        "zh": "康科迪亚",
        "ja": "コンコルディア",
        "th": "คอนคอร์เดีย"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.2339,
        "lng": -52.0278
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11387,
      "name": "Oliveira de Azemeis",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.8333,
        "lng": -8.4833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 11338,
      "name": "Solok",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.7883,
        "lng": 100.6542
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 11422,
      "name": "Drummondville",
      "names": {},
      "countryCode": "CA",
      "latLng": {
        "lat": 45.8833,
        "lng": -72.4833
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11418,
      "name": "Odate",
      "names": {
        "id": "Noshiro"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 40.2717,
        "lng": 140.5647
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11413,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.8708,
        "lng": -54.4808
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11376,
      "name": "Fulda",
      "names": {
        "zh": "富尔达",
        "ja": "フルダ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.5528,
        "lng": 9.6775
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5533,
      "name": "Valle Hermoso",
      "names": {
        "ja": "ヴァッレエルモソ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.6736,
        "lng": -97.8144
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11406,
      "name": "Soja",
      "names": {
        "zh": "大豆"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6728,
        "lng": 133.7467
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5575,
      "name": "Bouira",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.3783,
        "lng": 3.8925
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 11308,
      "name": "Smila",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.2167,
        "lng": 31.8667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 11393,
      "name": "San Leonardo",
      "names": {
        "zh": "圣莱昂纳多",
        "ja": "サンレオナルド",
        "th": "ซานลีโอนาโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3667,
        "lng": 120.9667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11419,
      "name": "Zapotlanejo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6228,
        "lng": -103.0689
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11420,
      "name": "Doboj",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.7333,
        "lng": 18.1333
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 11426,
      "name": "Castle Rock",
      "names": {
        "es": "Roca del castillo",
        "fr": "Château",
        "zh": "城堡摇滚",
        "de": "Schlossfelsen",
        "it": "Castello rock",
        "id": "Benteng",
        "ja": "キャッスルロック",
        "th": "หินปราสาท",
        "pt": "Castelo rock"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.3763,
        "lng": -104.8535
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11377,
      "name": "Mamungan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.1167,
        "lng": 124.2167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5482,
      "name": "Cabo San Lucas",
      "names": {
        "ja": "カボサンルーカス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.8897,
        "lng": -109.9156
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 11410,
      "name": "Tangub",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.0667,
        "lng": 123.75
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11408,
      "name": "Aalen",
      "names": {
        "zh": "阿伦",
        "ja": "アーレン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8372,
        "lng": 10.0936
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5587,
      "name": "Saki",
      "names": {
        "zh": "萨基",
        "ja": "サキ"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.1919,
        "lng": 47.1706
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 11363,
      "name": "Velsen-Zuid",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4667,
        "lng": 4.6167
      },
      "country": "Netherlands",
      "importance": 3
    },
    {
      "id": 11431,
      "name": "Ash Shihr",
      "names": {
        "es": "Ceniza shihr",
        "id": "Abu shihr",
        "ja": "灰Shihr",
        "th": "เถ้า shihr",
        "pt": "Cinza shihr"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.7608,
        "lng": 49.6069
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 11435,
      "name": "Turkmenbasy",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 40.023,
        "lng": 52.9697
      },
      "country": "Turkmenistan",
      "importance": 3
    },
    {
      "id": 5550,
      "name": "Saldanha",
      "names": {
        "zh": "萨尔达尼亚"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -32.9978,
        "lng": 17.9456
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 5591,
      "name": "Adrar",
      "names": {
        "zh": "阿德拉尔"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 27.8742,
        "lng": -0.2939
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 5226,
      "name": "Issy-les-Moulineaux",
      "names": {
        "zh": "伊西莱穆利诺",
        "ja": "issy・レ・moulineaux",
        "th": "issy-เลส์-moulineaux"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8239,
        "lng": 2.27
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 11433,
      "name": "Tuymazy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.6,
        "lng": 53.7
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5508,
      "name": "Matara",
      "names": {
        "zh": "马塔拉"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 5.95,
        "lng": 80.5333
      },
      "country": "Sri Lanka",
      "importance": 3
    },
    {
      "id": 11437,
      "name": "Lodja",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -3.5242,
        "lng": 23.5964
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 11424,
      "name": "Otukpo",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 7.1904,
        "lng": 8.13
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 11348,
      "name": "Gniezno",
      "names": {
        "zh": "格涅兹诺",
        "ja": "グニェズノ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.5333,
        "lng": 17.6
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 5539,
      "name": "Tynemouth",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 55.017,
        "lng": -1.423
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11428,
      "name": "Sipocot",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7667,
        "lng": 122.9833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11427,
      "name": "Tuburan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7333,
        "lng": 123.8333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11442,
      "name": "Hanover",
      "names": {
        "fr": "Hanovre",
        "zh": "汉诺威",
        "de": "Hannover",
        "it": "Hannover",
        "ja": "ハノーバー",
        "th": "ฮันโนเวอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.8117,
        "lng": -76.9835
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11443,
      "name": "Bossier City",
      "names": {
        "es": "Ciudad de Bossier",
        "fr": "City Bossier",
        "zh": "Bossier城市",
        "de": "Bühnerstadt",
        "it": "Città di Bossier",
        "ja": "ボージーシティ",
        "th": "เมืองโบเซอร์",
        "pt": "Cidade Bossier"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.5227,
        "lng": -93.6666
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11440,
      "name": "Conceicao do Coite",
      "names": {
        "es": "Conceicao do coito",
        "fr": "Conceicao faire",
        "zh": "康西岛做糖果",
        "de": "Concecao do coite",
        "it": "Conceicao do Coii",
        "id": "Conceicao melakukan coite",
        "ja": "コンセコオ石鉱",
        "th": "conceicao ทำ coite"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.5639,
        "lng": -39.2828
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11439,
      "name": "Ceara-Mirim",
      "names": {
        "zh": "塞阿拉-米林",
        "th": "เซอารา-mirim",
        "pt": "Ceará-Mirim"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.6339,
        "lng": -35.4258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5478,
      "name": "Noisy-le-Grand",
      "names": {
        "es": "Ruidoso-le-Grand",
        "fr": "Bruyant-le-grand",
        "zh": "噪声了-大",
        "de": "Laut-Le-Grand",
        "ja": "ノイズの多い・ル・グランド",
        "th": "มีเสียงดัง-เลอ-แกรนด์",
        "pt": "Barulhento-Le-Grand"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8478,
        "lng": 2.5528
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 11438,
      "name": "Fernandopolis",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.2839,
        "lng": -50.2458
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11423,
      "name": "Miagao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6442,
        "lng": 122.2352
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11445,
      "name": "Dubuque",
      "names": {
        "zh": "迪比克",
        "ja": "ドゥビューク",
        "th": "บิวก์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5008,
        "lng": -90.7067
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11448,
      "name": "Rockville",
      "names": {
        "zh": "罗克维尔",
        "ja": "ロックビル",
        "th": "ร็อกวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0834,
        "lng": -77.1552
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11449,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.8285,
        "lng": -96.985
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11429,
      "name": "Cuyapo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7833,
        "lng": 120.6667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5526,
      "name": "Torremolinos",
      "names": {
        "zh": "多列毛利诺斯",
        "ja": "トレモリーノス",
        "th": "ตอร์เรโมลิโนส"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.6218,
        "lng": -4.5003
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11451,
      "name": "Saratoga Springs",
      "names": {
        "it": "Sartatoga Springs",
        "ja": "サラトガスプリングス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0674,
        "lng": -73.7775
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5599,
      "name": "Guaynabo",
      "names": {},
      "countryCode": "PR",
      "latLng": {
        "lat": 18.3839,
        "lng": -66.1134
      },
      "country": "Puerto Rico",
      "importance": 3
    },
    {
      "id": 5598,
      "name": "Hameenlinna",
      "names": {
        "zh": "海门林纳",
        "it": "Hämeenlinna"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.0,
        "lng": 24.4414
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 11382,
      "name": "Minusinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.7,
        "lng": 91.6833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5601,
      "name": "Gaithersburg",
      "names": {
        "zh": "盖瑟斯堡"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1346,
        "lng": -77.2132
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5602,
      "name": "Union City",
      "names": {
        "zh": "联盟城",
        "de": "Union",
        "id": "Kota serikat",
        "ja": "ユニオンシティ",
        "th": "เมืองยูเนียน",
        "pt": "Cidade da União"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7674,
        "lng": -74.0323
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11441,
      "name": "Xinhua",
      "names": {
        "zh": "新华社",
        "ja": "新華",
        "th": "สำนักข่าวซินหัว"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6272,
        "lng": 105.62
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11432,
      "name": "Ixtlahuacan de los Membrillos",
      "names": {
        "fr": "Membrillos iXTLAHUACAN DE LOS",
        "it": "Ixtlahuacan de los membllos",
        "id": "Ixlahuacan de los membrillos",
        "ja": "Ixtlahuacan de losの膜"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.35,
        "lng": -103.1833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5572,
      "name": "Putrajaya",
      "names": {
        "zh": "普特拉贾亚",
        "th": "ปุตราจายา"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 2.93,
        "lng": 101.69
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 5573,
      "name": "Liepaja",
      "names": {
        "zh": "利耶帕亚"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.5083,
        "lng": 21.0111
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 11446,
      "name": "La Barca",
      "names": {
        "ja": "ラ・バルカ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2833,
        "lng": -102.5667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11456,
      "name": "Trapani",
      "names": {
        "zh": "特拉帕尼",
        "ja": "トラパニ",
        "th": "ตราปานี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.0175,
        "lng": 12.515
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 11457,
      "name": "Ames",
      "names": {
        "zh": "艾姆斯",
        "ja": "エームズ",
        "th": "อาเมส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0259,
        "lng": -93.6215
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11368,
      "name": "Yanghe",
      "names": {
        "zh": "洋河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.2727,
        "lng": 106.2496
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11425,
      "name": "Lippstadt",
      "names": {
        "zh": "利普施塔特",
        "th": "ลิพพ์ชตัดท์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6667,
        "lng": 8.35
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11458,
      "name": "West Des Moines",
      "names": {
        "fr": "Ouest des Moines",
        "zh": "西德梅因",
        "id": "Des moines",
        "ja": "ウェストデモイン",
        "th": "เวสต์เดสมอนส์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5522,
        "lng": -93.7805
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11421,
      "name": "Castillejos",
      "names": {
        "zh": "卡斯蒂列霍斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9333,
        "lng": 120.2
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11453,
      "name": "Kstovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1517,
        "lng": 44.1956
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5439,
      "name": "Siuri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.9167,
        "lng": 87.5333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11447,
      "name": "Przemysl",
      "names": {
        "zh": "普热梅希尔"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.7835,
        "lng": 22.7842
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11434,
      "name": "Chengjiao Chengguanzhen",
      "names": {
        "zh": "成娇成庄镇",
        "ja": "成都Chengguanzhen"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.4362,
        "lng": 104.0306
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11454,
      "name": "Viterbo",
      "names": {
        "zh": "维泰博",
        "ja": "ヴィテルボ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.4186,
        "lng": 12.1042
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 11459,
      "name": "Longshan",
      "names": {
        "zh": "龙山"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.4519,
        "lng": 106.9718
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5605,
      "name": "South San Francisco",
      "names": {
        "fr": "Sud San Francisco",
        "zh": "南旧金山",
        "it": "Sud San Francisco",
        "id": "San Francisco Selatan",
        "ja": "サウスサンフランシスコ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.6536,
        "lng": -122.4197
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11436,
      "name": "Bishnupur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.0833,
        "lng": 87.3167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11415,
      "name": "Cannock",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 52.691,
        "lng": -2.027
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5549,
      "name": "Kashiwara",
      "names": {
        "zh": "柏原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5792,
        "lng": 135.6286
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5427,
      "name": "Matehuala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 23.6528,
        "lng": -100.6444
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5593,
      "name": "Bayombong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.4833,
        "lng": 121.15
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 11464,
      "name": "Yorba Linda",
      "names": {
        "ja": "ヨルバリンダ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.889,
        "lng": -117.7714
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11444,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8231,
        "lng": 122.5344
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5520,
      "name": "Youssoufia",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 32.25,
        "lng": -8.53
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 5600,
      "name": "Vaasa",
      "names": {
        "zh": "瓦萨"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.1,
        "lng": 21.6167
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 11467,
      "name": "Santa Catarina Otzolotepec",
      "names": {
        "zh": "圣卡塔琳娜otzolotepec",
        "ja": "サンタカタリーナオートゾロテペック",
        "pt": "Santa Catarina Otzoloteepec"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5667,
        "lng": -97.25
      },
      "country": "Mexico",
      "importance": 3
    },
    {
      "id": 5561,
      "name": "Heroica Caborca",
      "names": {
        "pt": "Heróica caborca"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.7167,
        "lng": -112.15
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5609,
      "name": "Molepolole",
      "names": {
        "zh": "莫莱波洛莱"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -24.4066,
        "lng": 25.4951
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 11470,
      "name": "Casper",
      "names": {
        "zh": "卡斯帕",
        "ja": "キャスパー",
        "th": "แคสเปอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.842,
        "lng": -106.3207
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5512,
      "name": "Walsall",
      "names": {
        "zh": "沃尔索尔",
        "th": "วอลซอลล์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.58,
        "lng": -1.98
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11468,
      "name": "Manaure",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 11.775,
        "lng": -72.4444
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5590,
      "name": "Assen",
      "names": {
        "zh": "阿森",
        "ja": "アッセン"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.0,
        "lng": 6.55
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 11394,
      "name": "Stargard Szczecinski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.3387,
        "lng": 15.0448
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11472,
      "name": "Saint John",
      "names": {
        "es": "San Juan",
        "zh": "圣约翰",
        "it": "San Giovanni",
        "id": "Orang lain",
        "ja": "セントジョン",
        "th": "เซนต์จอห์น",
        "pt": "Santo john"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.2806,
        "lng": -66.0761
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11469,
      "name": "Yuksekova",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5667,
        "lng": 44.2833
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 5564,
      "name": "Ashford",
      "names": {
        "zh": "阿什福德",
        "ja": "アシュフォード"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.1465,
        "lng": 0.8676
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5540,
      "name": "Abnub",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 27.2667,
        "lng": 31.15
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 11398,
      "name": "Dinslaken",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5667,
        "lng": 6.7333
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11474,
      "name": "Soio",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -6.1333,
        "lng": 12.3667
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 5597,
      "name": "Aregua",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -25.3,
        "lng": -57.4169
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 11477,
      "name": "Palatine",
      "names": {
        "fr": "Palatin",
        "zh": "巴拉丁",
        "it": "Palatino",
        "ja": "パラティーノ",
        "th": "เกี่ยวกับเพดานปาก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1181,
        "lng": -88.043
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11479,
      "name": "Corvallis",
      "names": {
        "zh": "科瓦利斯"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.5697,
        "lng": -123.278
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11361,
      "name": "Villanueva y Geltru",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 41.2243,
        "lng": 1.7259
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11463,
      "name": "Yuquan",
      "names": {
        "zh": "玉泉"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.4202,
        "lng": 114.0865
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11480,
      "name": "Zihuatanejo",
      "names": {
        "ja": "ジワタネホ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.6444,
        "lng": -101.5511
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11460,
      "name": "Fuefuki",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6472,
        "lng": 138.64
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11395,
      "name": "Wagga Wagga",
      "names": {
        "ja": "ワグガワグガ"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -35.1189,
        "lng": 147.3689
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 11485,
      "name": "Ankeny",
      "names": {
        "zh": "安克尼"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7285,
        "lng": -93.6033
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5613,
      "name": "Dimbokro",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 6.6505,
        "lng": -4.71
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 5615,
      "name": "Mount Vernon",
      "names": {
        "fr": "Mont Vernon",
        "zh": "弗农山",
        "de": "Büschel",
        "ja": "マウントバーノン",
        "pt": "Monte Vernon"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9136,
        "lng": -73.8291
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11489,
      "name": "Rowlett",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 32.9157,
        "lng": -96.5488
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11491,
      "name": "Lakeville",
      "names": {
        "zh": "莱克维尔",
        "th": "เลกวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.6774,
        "lng": -93.252
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11461,
      "name": "Embu-Guacu",
      "names": {
        "zh": "恩布-苏",
        "pt": "Embu-Guaçu"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.8322,
        "lng": -46.8114
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11494,
      "name": "Tejen",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 37.3786,
        "lng": 60.496
      },
      "country": "Turkmenistan",
      "importance": 3
    },
    {
      "id": 11487,
      "name": "Venustiano Carranza",
      "names": {
        "it": "Venusiano Carranza",
        "ja": "Venutiano Carranza"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3,
        "lng": -92.6
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11473,
      "name": "Tolanaro",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -25.0325,
        "lng": 46.9833
      },
      "country": "Madagascar",
      "importance": 2
    },
    {
      "id": 11430,
      "name": "Lala Musa",
      "names": {
        "zh": "拉拉穆萨",
        "ja": "ララマサ"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.7006,
        "lng": 73.9558
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 5585,
      "name": "Kasese",
      "names": {
        "zh": "卡塞塞"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.23,
        "lng": 29.9883
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 11493,
      "name": "Caico",
      "names": {
        "zh": "凯科"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.4578,
        "lng": -37.0978
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11496,
      "name": "Itamaraju",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -17.0389,
        "lng": -39.5308
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11455,
      "name": "Tzaneen",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -23.8333,
        "lng": 30.1667
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 11384,
      "name": "Cosenza",
      "names": {
        "zh": "科森扎",
        "ja": "コゼンツァ",
        "th": "โคเซนซา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.3,
        "lng": 16.25
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 5500,
      "name": "Castelldefels",
      "names": {
        "ja": "カステルデフェルス",
        "th": "กัสเตล"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.28,
        "lng": 1.9767
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11501,
      "name": "Alpharetta",
      "names": {
        "ja": "アルファレッタ",
        "th": "อัลฟาเรตตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0704,
        "lng": -84.2739
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5610,
      "name": "Potenza",
      "names": {
        "zh": "波滕扎",
        "ja": "ポテンザ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6333,
        "lng": 15.8
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 5608,
      "name": "Lao Cai",
      "names": {
        "zh": "老蔡",
        "ja": "ラオカイ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 22.4194,
        "lng": 103.995
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 11411,
      "name": "Bay",
      "names": {
        "es": "Bahía",
        "fr": "Baie",
        "zh": "海湾",
        "de": "Küste",
        "ja": "ベイ",
        "th": "อ่าว",
        "pt": "Baía"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1833,
        "lng": 121.2833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5562,
      "name": "Washington",
      "names": {
        "zh": "华盛顿",
        "ja": "ワシントン",
        "th": "วอชิงตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.9,
        "lng": -1.52
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11450,
      "name": "Wanzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.5683,
        "lng": 116.5941
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11471,
      "name": "Lushar",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.4971,
        "lng": 101.564
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5300,
      "name": "Nea Ionia",
      "names": {
        "fr": "Néa Ionia",
        "ja": "ネアイオニア"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0333,
        "lng": 23.75
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 5525,
      "name": "Venissieux",
      "names": {
        "fr": "Vénissieux"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.6978,
        "lng": 4.8867
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5405,
      "name": "Dolores Hidalgo Cuna de la Independencia Nacional",
      "names": {
        "zh": "Dolores Hidalgo Cuna de la Indoolencia Nacional",
        "ja": "Dolores Hidalgo Cuna de La Pendencia Nacional"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1516,
        "lng": -100.9369
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11478,
      "name": "Sa\u0027ada",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 31.6258,
        "lng": -8.1028
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 11505,
      "name": "Castro",
      "names": {
        "zh": "卡斯特罗",
        "ja": "カストロ",
        "th": "คาสโตร"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.7908,
        "lng": -50.0119
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11507,
      "name": "Longview",
      "names": {
        "zh": "朗维尤",
        "ja": "ロングビュー",
        "th": "วิว"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.1461,
        "lng": -122.9629
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11488,
      "name": "San Pedro Sacatepequez",
      "names": {
        "fr": "San Pedro Sacatepequezez",
        "ja": "サンペドロ神聖ペケツ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9664,
        "lng": -91.7775
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 5565,
      "name": "Georgiyevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.15,
        "lng": 43.4667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11475,
      "name": "Sagunto",
      "names": {
        "zh": "萨贡托"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.6764,
        "lng": -0.2733
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5494,
      "name": "Chiyoda-ku",
      "names": {
        "zh": "千代田ku",
        "ja": "千代田区ku",
        "th": "ชิโยดะ-ku"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.694,
        "lng": 139.7536
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11465,
      "name": "Kashima",
      "names": {
        "zh": "鹿岛",
        "ja": "鹿島"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9658,
        "lng": 140.645
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11402,
      "name": "Glogow",
      "names": {
        "zh": "格沃古夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.6589,
        "lng": 16.0803
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11509,
      "name": "Lupon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.8981,
        "lng": 126.0096
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 11513,
      "name": "DeKalb",
      "names": {
        "zh": "迪卡",
        "ja": "デカルブ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.9313,
        "lng": -88.7482
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11515,
      "name": "Petaluma",
      "names": {
        "zh": "佩塔卢马",
        "th": "เปตาลูมา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.2423,
        "lng": -122.6267
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5574,
      "name": "Kati",
      "names": {
        "zh": "卡蒂",
        "ja": "カティ"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 12.7504,
        "lng": -8.08
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 11510,
      "name": "Guines",
      "names": {
        "es": "Guînes",
        "fr": "Guînes"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8475,
        "lng": -82.0236
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 11416,
      "name": "Beypore",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 11.18,
        "lng": 75.82
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11516,
      "name": "El Seibo",
      "names": {
        "ja": "エルシュボ",
        "pt": "El lavibo"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.77,
        "lng": -69.04
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 5462,
      "name": "Grays",
      "names": {
        "es": "Grises",
        "fr": "Gris",
        "zh": "灰色",
        "de": "Grau",
        "it": "Grigi",
        "ja": "グレイ",
        "th": "สีเทา",
        "pt": "Cinzas"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.475,
        "lng": 0.33
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11508,
      "name": "Bongabon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.6321,
        "lng": 121.1448
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11490,
      "name": "Pelileo",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -1.3306,
        "lng": -78.5428
      },
      "country": "Ecuador",
      "importance": 3
    },
    {
      "id": 11482,
      "name": "Kissidougou",
      "names": {
        "zh": "基西杜古"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 9.1905,
        "lng": -10.12
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 11466,
      "name": "Bergen op Zoom",
      "names": {
        "zh": "卑尔根op zoom",
        "de": "Bergen op zoomen",
        "ja": "ベルゲンopズーム",
        "pt": "Zoom Op Bergen"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5,
        "lng": 4.3
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11498,
      "name": "Cosmopolis",
      "names": {
        "zh": "国际大都市",
        "ja": "京"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.6458,
        "lng": -47.1961
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11519,
      "name": "Janauba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -15.8028,
        "lng": -43.3089
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5542,
      "name": "Aksum",
      "names": {
        "fr": "Axoum",
        "zh": "阿克苏姆"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 14.1284,
        "lng": 38.7173
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 11503,
      "name": "Janiuay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.0,
        "lng": 122.5833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11512,
      "name": "Godalming",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 51.18,
        "lng": -0.61
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11517,
      "name": "Capanema",
      "names": {
        "zh": "卡帕内马"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.1958,
        "lng": -47.1808
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5626,
      "name": "Redondo Beach",
      "names": {
        "es": "Playa de redondo",
        "fr": "Plage de Redondo",
        "zh": "雷东多海滩",
        "it": "Spiaggia di Redondo",
        "id": "Pantai Redondo",
        "ja": "レドンドビーチ",
        "th": "ชายหาด Redondo",
        "pt": "Praia Redondo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8574,
        "lng": -118.3766
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11520,
      "name": "Botolan",
      "names": {
        "zh": "博托兰"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.2896,
        "lng": 120.0245
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5628,
      "name": "Tamarac",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 26.2056,
        "lng": -80.2542
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11462,
      "name": "Rosales",
      "names": {
        "zh": "罗萨莱斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.8944,
        "lng": 120.6328
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5557,
      "name": "Viladecans",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 41.3158,
        "lng": 2.0194
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5581,
      "name": "Hihya",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.6687,
        "lng": 31.5904
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 11523,
      "name": "Hit",
      "names": {
        "es": "Golpear",
        "fr": "Frappé",
        "zh": "击中",
        "it": "Colpire",
        "id": "Memukul",
        "ja": "ヒット",
        "th": "ตี",
        "pt": "Bater"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.6417,
        "lng": 42.825
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 5627,
      "name": "Kraljevo",
      "names": {
        "zh": "克拉列沃"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.7234,
        "lng": 20.687
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 5623,
      "name": "Bolgatanga",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 10.7856,
        "lng": -0.8514
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 11504,
      "name": "La Carlota",
      "names": {
        "ja": "ラカルロタ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.4167,
        "lng": 122.9167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11500,
      "name": "San Mateo",
      "names": {
        "zh": "圣马特奥",
        "ja": "サンマテオ",
        "th": "ซานมาเทโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.8833,
        "lng": 121.5833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11525,
      "name": "Las Heras",
      "names": {
        "fr": "Héras Las",
        "zh": "拉斯牧民",
        "ja": "ラスヘラス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.825,
        "lng": -68.8017
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 5634,
      "name": "Nalut",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 31.8804,
        "lng": 10.97
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 11481,
      "name": "Herford",
      "names": {
        "zh": "黑尔福德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1146,
        "lng": 8.6734
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5475,
      "name": "Walton upon Thames",
      "names": {
        "es": "Walton sobre Thames",
        "fr": "Walton sur la Thames",
        "zh": "瓦尔顿在泰晤士河上",
        "de": "Walton auf der Themse",
        "it": "Walton su Tamigi",
        "ja": "Walton on Thames",
        "th": "วอลตันกับเทมส์",
        "pt": "Walton no Thames"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.3868,
        "lng": -0.4133
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5527,
      "name": "Ramos Arizpe",
      "names": {
        "zh": "拉莫斯亚利普"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.55,
        "lng": -100.9667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5633,
      "name": "Veliko Tarnovo",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.0787,
        "lng": 25.6283
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 11502,
      "name": "Aflao",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 6.1188,
        "lng": 1.1946
      },
      "country": "Ghana",
      "importance": 3
    },
    {
      "id": 5498,
      "name": "Karakax",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.2714,
        "lng": 79.7267
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5534,
      "name": "Novi Pazar",
      "names": {
        "zh": "诺维加帕",
        "ja": "ノヴィパザール"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.15,
        "lng": 20.5167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 5537,
      "name": "Capelle aan den IJssel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9357,
        "lng": 4.5782
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11483,
      "name": "Koratla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.82,
        "lng": 78.72
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11535,
      "name": "Caledon",
      "names": {
        "zh": "卡利登"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.8667,
        "lng": -79.8667
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11530,
      "name": "Saiki",
      "names": {
        "zh": "才木"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.9603,
        "lng": 131.8994
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11486,
      "name": "Weiyuan",
      "names": {
        "zh": "威远"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8413,
        "lng": 101.9557
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11492,
      "name": "Yachimata",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6659,
        "lng": 140.3179
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5635,
      "name": "Zacapa",
      "names": {
        "zh": "萨卡帕"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9667,
        "lng": -89.5333
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 11542,
      "name": "Kalush",
      "names": {
        "zh": "卡卢什"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0442,
        "lng": 24.3597
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 5488,
      "name": "Cuscatancingo",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.7333,
        "lng": -89.1833
      },
      "country": "El Salvador",
      "importance": 3
    },
    {
      "id": 11544,
      "name": "Laguna Niguel",
      "names": {
        "ja": "ラグーナニゲル",
        "th": "ลากูน่าไนเกล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.5275,
        "lng": -117.705
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11484,
      "name": "Guangping",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.4791,
        "lng": 114.941
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11545,
      "name": "Eagan",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 44.8169,
        "lng": -93.1638
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11548,
      "name": "Kenner",
      "names": {
        "zh": "肯纳"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.0109,
        "lng": -90.2549
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11524,
      "name": "Opol",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.5167,
        "lng": 124.5667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5510,
      "name": "Cergy",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 49.0361,
        "lng": 2.0631
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11531,
      "name": "Huinan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.6229,
        "lng": 126.2614
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11476,
      "name": "Hashima",
      "names": {
        "zh": "羽岛"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3167,
        "lng": 136.7
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11549,
      "name": "Itaberaba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.5278,
        "lng": -40.3069
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11546,
      "name": "Slavyansk-na-Kubani",
      "names": {
        "zh": "斯拉维扬斯克-钠kubani",
        "ja": "slavyansk-をNA-kubani",
        "th": "slavyansk-นา-kubani"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 45.2586,
        "lng": 38.1247
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 11541,
      "name": "Huquan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.7603,
        "lng": 114.2834
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11539,
      "name": "P\u0027yongsan",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 38.3367,
        "lng": 126.3866
      },
      "country": "North Korea",
      "importance": 3
    },
    {
      "id": 11452,
      "name": "Zanhuang",
      "names": {
        "zh": "赞皇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.659,
        "lng": 114.3775
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5639,
      "name": "Prilep",
      "names": {
        "zh": "普里莱普"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.3464,
        "lng": 21.5542
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 5589,
      "name": "Zamosc",
      "names": {
        "zh": "扎莫希奇",
        "th": "ซามอชช์"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.7206,
        "lng": 23.2586
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11522,
      "name": "Kerpen",
      "names": {
        "zh": "科彭"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8719,
        "lng": 6.6961
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11526,
      "name": "Ocoyoacac",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2739,
        "lng": -99.4606
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11527,
      "name": "Belogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 50.9167,
        "lng": 128.4833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11543,
      "name": "Shiojiri",
      "names": {
        "zh": "盐尻"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1167,
        "lng": 137.95
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11506,
      "name": "Vidnoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.55,
        "lng": 37.7
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11555,
      "name": "Lencois Paulista",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5986,
        "lng": -48.8003
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11547,
      "name": "Ilo",
      "names": {
        "es": "La OIT",
        "fr": "L\u0027OIT",
        "zh": "国际劳工组织",
        "de": "IAO",
        "ja": "ILOの",
        "th": "อิล",
        "pt": "OIT"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -17.6486,
        "lng": -71.3306
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 11557,
      "name": "Obera",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -27.4833,
        "lng": -55.1333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 11518,
      "name": "Genk",
      "names": {
        "zh": "亨克",
        "th": "เกนก์"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.95,
        "lng": 5.5
      },
      "country": "Belgium",
      "importance": 3
    },
    {
      "id": 5644,
      "name": "Goz-Beida",
      "names": {
        "zh": "goz-贝达"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 12.2236,
        "lng": 21.4144
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 11561,
      "name": "Bay City",
      "names": {
        "es": "Ciudad",
        "fr": "Ville de la baie",
        "zh": "湾城",
        "de": "Buchtstadt",
        "it": "Baia",
        "ja": "ベイシティ",
        "th": "อ่าวเมือง",
        "pt": "Cidade baía"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.5903,
        "lng": -83.8886
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5596,
      "name": "Ban Suan",
      "names": {
        "es": "Ban Suean",
        "zh": "班苏南",
        "de": "Ban-Suan",
        "ja": "スアンを禁止する",
        "th": "บ้านสวน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.3616,
        "lng": 100.9795
      },
      "country": "Thailand",
      "importance": 3
    },
    {
      "id": 11551,
      "name": "Oas",
      "names": {
        "es": "OEA",
        "fr": "OEA",
        "zh": "美洲国家组织",
        "pt": "OEA"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2589,
        "lng": 123.4953
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11499,
      "name": "Moita",
      "names": {
        "zh": "莫伊塔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.65,
        "lng": -8.9833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 11495,
      "name": "Agoo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.322,
        "lng": 120.3647
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11559,
      "name": "Uniao dos Palmares",
      "names": {
        "de": "Uniao dos palnen",
        "ja": "ユニアオドスパームレス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.1628,
        "lng": -36.0319
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11564,
      "name": "El Carmen de Bolivar",
      "names": {
        "es": "El Carmen de Bolívar"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.7183,
        "lng": -75.1225
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 5479,
      "name": "Gravesend",
      "names": {
        "zh": "格雷夫森德"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.4415,
        "lng": 0.3685
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11565,
      "name": "Stupino",
      "names": {
        "zh": "斯图皮诺"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.8869,
        "lng": 38.0772
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11558,
      "name": "Lantapan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.0005,
        "lng": 125.0235
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11567,
      "name": "Venancio Aires",
      "names": {
        "fr": "VeniCio Aires",
        "ja": "ヴェネチョアのお気に入り"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6058,
        "lng": -52.1919
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5567,
      "name": "Castellammare di Stabia",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6947,
        "lng": 14.4803
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11570,
      "name": "Lahat",
      "names": {
        "zh": "拉哈特"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.8,
        "lng": 103.5333
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 11575,
      "name": "North Little Rock",
      "names": {
        "zh": "北小岩石",
        "it": "Nord piccolo rock",
        "id": "Batu kecil utara",
        "ja": "ノースリトルロック",
        "th": "หินน้อยนอร์ท",
        "pt": "Rocha Norte"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.7808,
        "lng": -92.2371
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11577,
      "name": "Sammamish",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 47.6017,
        "lng": -122.0416
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5351,
      "name": "Garissa",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.4569,
        "lng": 39.6583
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 5503,
      "name": "Wilde",
      "names": {
        "zh": "王尔德",
        "ja": "ワイルド",
        "th": "ไวลด์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7,
        "lng": -58.3167
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 11572,
      "name": "Coron",
      "names": {
        "zh": "科伦",
        "th": "โครอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.0,
        "lng": 120.2
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11556,
      "name": "Roxas",
      "names": {
        "zh": "罗哈斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.1167,
        "lng": 121.6167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5091,
      "name": "Levallois-Perret",
      "names": {
        "zh": "勒瓦卢瓦 - 佩雷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.895,
        "lng": 2.2872
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 11581,
      "name": "Shawnee",
      "names": {
        "zh": "肖尼",
        "ja": "ショーニー",
        "th": "ชอว์นี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0158,
        "lng": -94.8076
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11582,
      "name": "Jupiter",
      "names": {
        "es": "Júpiter",
        "zh": "木星",
        "it": "Giove",
        "ja": "ジュピター",
        "th": "ดาวพฤหัสบดี",
        "pt": "Júpiter"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.9199,
        "lng": -80.1128
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11550,
      "name": "Korfez",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.7706,
        "lng": 29.7661
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 11583,
      "name": "Old Bridge",
      "names": {
        "es": "Puente viejo",
        "fr": "Ancien pont",
        "zh": "老桥梁",
        "de": "Alte Brücke",
        "it": "Vecchio ponte",
        "id": "Jembatan tua",
        "ja": "古い橋",
        "th": "สะพานเก่า",
        "pt": "Ponte velha"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4004,
        "lng": -74.3126
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11571,
      "name": "Kiamba",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.9833,
        "lng": 124.6167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11511,
      "name": "Fukutsu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.7669,
        "lng": 130.4911
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11540,
      "name": "San Remigio",
      "names": {
        "zh": "圣汇",
        "ja": "サンレミオ",
        "th": "ซานรีโมจิโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.0,
        "lng": 123.95
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11587,
      "name": "Doral",
      "names": {
        "zh": "多柔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.8151,
        "lng": -80.3565
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11553,
      "name": "Sodo",
      "names": {
        "zh": "索多"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 6.9,
        "lng": 37.75
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 11579,
      "name": "Asagicinik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2719,
        "lng": 36.3508
      },
      "country": "Turkey",
      "importance": 3
    },
    {
      "id": 5654,
      "name": "Florence-Graham",
      "names": {
        "es": "Florencia, Graham",
        "zh": "佛罗伦萨-·格雷厄姆",
        "de": "Florenz-Graham",
        "it": "Firenze-Graham",
        "ja": "フィレンツェ・グラハム",
        "th": "ฟลอเรนซ์-เกรแฮม",
        "pt": "Florença-Graham"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9682,
        "lng": -118.2447
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11521,
      "name": "Pulivendla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 14.4167,
        "lng": 78.2333
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11576,
      "name": "Santo Tome",
      "names": {
        "es": "Santo tomo",
        "ja": "サントトーム",
        "pt": "Santo"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.6667,
        "lng": -60.7667
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 11591,
      "name": "Carbondale",
      "names": {
        "zh": "卡本代尔",
        "ja": "カーボン",
        "th": "คาร์บอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7221,
        "lng": -89.2238
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11584,
      "name": "Ina",
      "names": {
        "zh": "INA进口",
        "ja": "INAは"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8275,
        "lng": 137.9539
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11585,
      "name": "Tupa",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.935,
        "lng": -50.5139
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11573,
      "name": "Menzel Temime",
      "names": {
        "de": "Menzel-Tempau",
        "id": "Peringatan menzel",
        "ja": "メンセル鋳造",
        "th": "อารมณ์ Menzel",
        "pt": "Temime de Menzel"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 36.7833,
        "lng": 10.9833
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 11578,
      "name": "Lutayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6,
        "lng": 124.85
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5607,
      "name": "Cabedelo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -6.9808,
        "lng": -34.8339
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11534,
      "name": "Angat",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9281,
        "lng": 121.0293
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11594,
      "name": "Blaine",
      "names": {
        "zh": "布莱恩",
        "ja": "ブレイン",
        "th": "เบลน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.1696,
        "lng": -93.2077
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5280,
      "name": "Kirtipur",
      "names": {
        "zh": "吉尔蒂布尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 27.6667,
        "lng": 85.2833
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 11497,
      "name": "Rio Negro",
      "names": {
        "fr": "Rio nègre",
        "zh": "里约热内卢",
        "ja": "リオネグロ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.1,
        "lng": -49.79
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5578,
      "name": "Veenendaal",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.025,
        "lng": 5.555
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11597,
      "name": "St. Albert",
      "names": {
        "es": "San Albert",
        "fr": "Saint Albert",
        "zh": "圣艾伯特",
        "ja": "セントアルバート",
        "th": "เซนต์อัลเบิร์ต"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 53.6303,
        "lng": -113.6258
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11598,
      "name": "Weirton",
      "names": {
        "zh": "威尔顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.406,
        "lng": -80.5671
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5652,
      "name": "Ioannina",
      "names": {
        "zh": "约阿尼纳",
        "id": "Ioánnina"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 39.6636,
        "lng": 20.8522
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 11528,
      "name": "Qarqan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.1338,
        "lng": 85.5333
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5645,
      "name": "San Marcos",
      "names": {
        "zh": "圣马科斯",
        "ja": "サンマルコス",
        "th": "ซานมาร์กอส"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9653,
        "lng": -91.7958
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 11600,
      "name": "Franklin",
      "names": {
        "zh": "富兰克林",
        "ja": "フランクリン",
        "th": "แฟรงคลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4759,
        "lng": -74.5515
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5611,
      "name": "El Prat de Llobregat",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 41.3246,
        "lng": 2.0953
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11568,
      "name": "San Francisco El Alto",
      "names": {
        "zh": "旧金山埃尔尔特",
        "ja": "サンフランシスコエルアルト",
        "th": "ซานฟรานซิสโกเอลอัลโต",
        "pt": "São Francisco El Alto"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.95,
        "lng": -91.45
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 5655,
      "name": "Trabzon",
      "names": {
        "zh": "特拉布宗"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.005,
        "lng": 39.7225
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 11603,
      "name": "Tulare",
      "names": {
        "zh": "图莱里"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1996,
        "lng": -119.34
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5582,
      "name": "Al Minshah",
      "names": {
        "ja": "アルミンシャ",
        "th": "อัลมินดาห์"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 26.4769,
        "lng": 31.8036
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 11607,
      "name": "Beloretsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.9667,
        "lng": 58.4
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11602,
      "name": "Los Amates",
      "names": {
        "fr": "Los amate",
        "zh": "洛杉矶申请",
        "de": "Los Amate",
        "ja": "ロスアメートン",
        "th": "ลอสเอะอะ",
        "pt": "Los amados"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.2667,
        "lng": -89.1
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 11538,
      "name": "Xieqiaocun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4973,
        "lng": 120.6106
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11608,
      "name": "Jaisalmer",
      "names": {
        "zh": "斋沙默尔",
        "ja": "ジャイサルメール",
        "th": "ไจซาลเมอร์"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.9167,
        "lng": 70.9167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11609,
      "name": "Januaria",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -15.4878,
        "lng": -44.3619
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11605,
      "name": "Ico",
      "names": {
        "zh": "意"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.4008,
        "lng": -38.8619
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11532,
      "name": "Russelsheim",
      "names": {
        "es": "Rüsselsheim",
        "fr": "Rüsselsheim",
        "de": "Rüsselsheim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.995,
        "lng": 8.4119
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11610,
      "name": "Ishimbay",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.45,
        "lng": 56.0333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11613,
      "name": "Wellington",
      "names": {
        "zh": "惠灵顿",
        "ja": "ウェリントン",
        "th": "เวลลิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.6461,
        "lng": -80.2699
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5640,
      "name": "Trnava",
      "names": {
        "zh": "特尔纳瓦"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.3777,
        "lng": 17.5862
      },
      "country": "Slovakia",
      "importance": 1
    },
    {
      "id": 11615,
      "name": "Pflugerville",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 30.452,
        "lng": -97.6022
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5641,
      "name": "Masaka",
      "names": {
        "zh": "马萨卡"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.3296,
        "lng": 31.73
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 11616,
      "name": "Palo Alto",
      "names": {
        "zh": "帕洛阿尔托",
        "ja": "パロアルト"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3913,
        "lng": -122.1468
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11562,
      "name": "Most",
      "names": {
        "es": "Más",
        "fr": "Plus",
        "zh": "最",
        "de": "Die meisten",
        "it": "Più",
        "id": "Kebanyakan",
        "ja": "最も",
        "th": "มากที่สุด",
        "pt": "Mais"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.5031,
        "lng": 13.6367
      },
      "country": "Czechia",
      "importance": 3
    },
    {
      "id": 11596,
      "name": "Purulha",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.2353,
        "lng": -90.235
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 11618,
      "name": "Middletown",
      "names": {
        "zh": "米德尔敦",
        "ja": "ミドルタウン",
        "th": "มิดเดิลทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.3893,
        "lng": -74.082
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5577,
      "name": "Tamluk",
      "names": {
        "zh": "塔姆鲁克"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 22.3,
        "lng": 87.9167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5614,
      "name": "Katwijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2008,
        "lng": 4.4153
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11601,
      "name": "Jocotitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7072,
        "lng": -99.7867
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11586,
      "name": "Solano",
      "names": {
        "zh": "索拉诺"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.5239,
        "lng": 121.1919
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11554,
      "name": "Kungur",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.4333,
        "lng": 56.9333
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 11617,
      "name": "Quezon",
      "names": {
        "zh": "奎松",
        "ja": "ケソン",
        "th": "เควซอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.2333,
        "lng": 118.0333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5663,
      "name": "Schenectady",
      "names": {
        "zh": "斯克内克塔迪",
        "th": "สเกเนกทาดี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.8025,
        "lng": -73.9276
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11614,
      "name": "Birnin Konni",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 13.7904,
        "lng": 5.2599
      },
      "country": "Niger",
      "importance": 2
    },
    {
      "id": 11595,
      "name": "Alipur Duar",
      "names": {
        "zh": "alipur双子",
        "de": "Alipur-Duar"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.5,
        "lng": 89.52
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5556,
      "name": "Debre Birhan",
      "names": {
        "es": "Debe Birhan",
        "zh": "德布勒比尔",
        "de": "Dekre Birhan"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 9.6804,
        "lng": 39.53
      },
      "country": "Ethiopia",
      "importance": 3
    },
    {
      "id": 4761,
      "name": "Pateros",
      "names": {
        "zh": "帕特罗斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5417,
        "lng": 121.0667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11611,
      "name": "Peruibe",
      "names": {
        "pt": "Peruíbe"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.31,
        "lng": -47.0
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11569,
      "name": "Xibang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9412,
        "lng": 120.8872
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11622,
      "name": "Halabjah",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 35.1833,
        "lng": 45.9833
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 5586,
      "name": "Kitamoto",
      "names": {
        "zh": "北本"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0269,
        "lng": 139.53
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5657,
      "name": "Calarasi",
      "names": {
        "zh": "克勒拉希"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 44.2,
        "lng": 27.3333
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 11590,
      "name": "Zarechnyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.2,
        "lng": 45.1667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5649,
      "name": "Tatabanya",
      "names": {
        "zh": "陶陶巴尼奥",
        "de": "Tatabánya"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.5862,
        "lng": 18.3949
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 11588,
      "name": "Daule",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -1.8667,
        "lng": -79.9833
      },
      "country": "Ecuador",
      "importance": 3
    },
    {
      "id": 11533,
      "name": "Ishim",
      "names": {
        "zh": "伊希姆"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1167,
        "lng": 69.5
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5637,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.75,
        "lng": 121.95
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 11627,
      "name": "Great Falls",
      "names": {
        "es": "Grandes caídas",
        "fr": "Grandes chutes",
        "zh": "大瀑布",
        "de": "Tolles Fälle",
        "it": "Grandi cascate",
        "id": "Jatuh besar",
        "ja": "素晴らしい滝",
        "th": "น้ำตกที่ยิ่งใหญ่",
        "pt": "Grandes quedas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.5022,
        "lng": -111.2995
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11625,
      "name": "Formiga",
      "names": {
        "zh": "福米加"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.4639,
        "lng": -45.4258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11580,
      "name": "Weimar",
      "names": {
        "zh": "魏玛",
        "ja": "ワイマール",
        "th": "ไวมาร์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9833,
        "lng": 11.3167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5594,
      "name": "Buynaksk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 42.8167,
        "lng": 47.1167
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 11632,
      "name": "Michigan City",
      "names": {
        "es": "Ciudad de Michigan",
        "fr": "Ville de Michigan",
        "zh": "密歇根州市",
        "de": "Michigan-Stadt",
        "id": "Kota michigan",
        "ja": "ミシガンシティ",
        "th": "เมืองมิชิแกน",
        "pt": "Cidade de michigan"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7099,
        "lng": -86.8705
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11574,
      "name": "Villasis",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.9,
        "lng": 120.5833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11626,
      "name": "Mecheria",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.55,
        "lng": -0.2833
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 5606,
      "name": "Farnborough",
      "names": {
        "zh": "法恩伯勒",
        "th": "เมา"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.29,
        "lng": -0.75
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11606,
      "name": "Boras",
      "names": {
        "fr": "Borås",
        "zh": "布罗斯",
        "id": "Borås"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.7304,
        "lng": 12.92
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 11623,
      "name": "Viana",
      "names": {
        "zh": "维亚纳"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.39,
        "lng": -40.4958
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5669,
      "name": "Solwezi",
      "names": {
        "zh": "索卢韦齐"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -12.1833,
        "lng": 26.4
      },
      "country": "Zambia",
      "importance": 1
    },
    {
      "id": 11628,
      "name": "Esquipulas",
      "names": {
        "zh": "埃斯基普拉斯"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6167,
        "lng": -89.2
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 11635,
      "name": "Svyetlahorsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.6333,
        "lng": 29.7333
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 11514,
      "name": "Pabianice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.6642,
        "lng": 19.35
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11630,
      "name": "Caripito",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.111,
        "lng": -63.1048
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 11631,
      "name": "Maasin",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.8667,
        "lng": 125.0
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5674,
      "name": "Bayonne",
      "names": {
        "es": "Bayona",
        "zh": "巴约讷",
        "ja": "バヨンヌ",
        "th": "บาย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6661,
        "lng": -74.1141
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11642,
      "name": "Eden Prairie",
      "names": {
        "zh": "伊甸园草原",
        "pt": "Prairie do Eden"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.8488,
        "lng": -93.4595
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11536,
      "name": "Gukovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 48.05,
        "lng": 39.9333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11589,
      "name": "Nagcarlan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1364,
        "lng": 121.4165
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11560,
      "name": "Sindelfingen",
      "names": {
        "zh": "辛德尔芬根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7133,
        "lng": 9.0028
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5592,
      "name": "Tomigusuku",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 26.1772,
        "lng": 127.6811
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11647,
      "name": "Port Orange",
      "names": {
        "es": "Naranja",
        "fr": "Orange",
        "zh": "端口橙色",
        "de": "Portorange",
        "it": "Porta arancione",
        "id": "Oranye",
        "ja": "ポートオレンジ",
        "th": "พอร์ตส้ม",
        "pt": "Laranja"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.1084,
        "lng": -81.0137
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11649,
      "name": "Dublin",
      "names": {
        "es": "Dublín",
        "zh": "都柏林",
        "it": "Dublino",
        "ja": "ダブリン",
        "th": "ดับลิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7161,
        "lng": -121.8963
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11592,
      "name": "Bordj Menaiel",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7417,
        "lng": 3.7231
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 5588,
      "name": "Afragola",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 40.9167,
        "lng": 14.3167
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11529,
      "name": "Gwacheon",
      "names": {
        "zh": "果川",
        "ja": "果川"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 37.4289,
        "lng": 126.9892
      },
      "country": "South Korea",
      "importance": 3
    },
    {
      "id": 11599,
      "name": "Ferrol",
      "names": {
        "zh": "费罗尔"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.4844,
        "lng": -8.2328
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11651,
      "name": "Rafha",
      "names": {},
      "countryCode": "SA",
      "latLng": {
        "lat": 29.6202,
        "lng": 43.4948
      },
      "country": "Saudi Arabia",
      "importance": 3
    },
    {
      "id": 11652,
      "name": "Grand Forks",
      "names": {
        "fr": "Gros fourchettes",
        "zh": "大叉子",
        "it": "Grandi forcelle",
        "id": "Garpu Grand",
        "ja": "グランドフォーク",
        "th": "ส้อมแกรนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.9218,
        "lng": -97.0886
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5670,
      "name": "As Suwayda\u0027",
      "names": {
        "es": "Como suwayda \u0027",
        "fr": "Comme suwayda \u0027",
        "zh": "苏韦达\u0027",
        "de": "Als suwayda \u0027",
        "it": "Come suwayda \u0027",
        "id": "Sebagai suwayda \u0027",
        "ja": "Suwayda \u0027として",
        "th": "เช่นเดียวกับ Suwayda \u0027",
        "pt": "Como Suayda \u0027"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.7,
        "lng": 36.5667
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 11537,
      "name": "Valence",
      "names": {
        "zh": "价",
        "de": "Valenz",
        "ja": "ヴァランス",
        "th": "ความจุ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.9333,
        "lng": 4.8917
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11655,
      "name": "Chipindo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -13.8244,
        "lng": 15.8
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 5676,
      "name": "Faizabad",
      "names": {
        "zh": "法扎巴德"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 37.1298,
        "lng": 70.5792
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 11593,
      "name": "Bourges",
      "names": {
        "zh": "布尔日"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.0844,
        "lng": 2.3964
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11661,
      "name": "Noblesville",
      "names": {
        "th": "โนเบิล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0354,
        "lng": -86.0042
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11658,
      "name": "Meulaboh",
      "names": {
        "zh": "米拉务"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 4.1333,
        "lng": 96.1167
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 11624,
      "name": "Marinilla",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1744,
        "lng": -75.3389
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11657,
      "name": "Santo Domingo Tehuantepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3184,
        "lng": -95.2478
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11660,
      "name": "Binga",
      "names": {
        "zh": "宾加"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.3834,
        "lng": 20.42
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 5622,
      "name": "Lechang",
      "names": {
        "zh": "乐昌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6415,
        "lng": 111.4633
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11619,
      "name": "Neuwied",
      "names": {
        "zh": "新维德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.4286,
        "lng": 7.4614
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5675,
      "name": "Faro",
      "names": {
        "zh": "法鲁",
        "ja": "ファロ",
        "th": "แฟโร"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.0161,
        "lng": -7.935
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 11670,
      "name": "San Clemente",
      "names": {
        "zh": "圣克莱门特",
        "ja": "サンクレメンテ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.4498,
        "lng": -117.6103
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11643,
      "name": "Isabela",
      "names": {
        "zh": "伊莎贝拉",
        "ja": "イサベラ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.2,
        "lng": 122.9833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11646,
      "name": "Hilongos",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3667,
        "lng": 124.75
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5616,
      "name": "Arrecife",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 28.9625,
        "lng": -13.5506
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11676,
      "name": "Brentwood",
      "names": {
        "zh": "布伦特伍德",
        "ja": "ブレントウッド",
        "th": "แนชวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9355,
        "lng": -121.7191
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11637,
      "name": "Ito",
      "names": {
        "zh": "伊藤",
        "ja": "伊藤",
        "th": "อิโตะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9658,
        "lng": 139.1019
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5563,
      "name": "Rivera",
      "names": {
        "zh": "里维拉",
        "ja": "リベラ",
        "th": "ริเวร่า"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -30.9025,
        "lng": -55.5506
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 11664,
      "name": "Rahat",
      "names": {
        "zh": "拉哈特",
        "ja": "ラハット",
        "th": "ฮัต"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 31.3925,
        "lng": 34.7544
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 11677,
      "name": "Carmichael",
      "names": {
        "zh": "卡迈克尔",
        "ja": "カーマイケル",
        "th": "คาร์ไมเคิล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6337,
        "lng": -121.323
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11667,
      "name": "Apaseo el Alto",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.45,
        "lng": -100.6167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5678,
      "name": "Sokhumi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 43.0033,
        "lng": 41.0153
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 5683,
      "name": "Yoro",
      "names": {
        "zh": "养老"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.1333,
        "lng": -87.1333
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 5541,
      "name": "Paignton",
      "names": {
        "zh": "佩恩顿"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.4353,
        "lng": -3.5625
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11674,
      "name": "Estancia",
      "names": {
        "zh": "埃斯坦西亚",
        "ja": "エスタンシア"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.2678,
        "lng": -37.4378
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11621,
      "name": "Chunian",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.9639,
        "lng": 73.9803
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 11552,
      "name": "Malvar",
      "names": {
        "zh": "马尔瓦尔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0417,
        "lng": 121.1583
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11566,
      "name": "Pessac",
      "names": {
        "zh": "佩萨克",
        "th": "ในเปสแซก"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.8067,
        "lng": -0.6311
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5686,
      "name": "East Orange",
      "names": {
        "es": "Orange Oriente",
        "fr": "Orange est",
        "zh": "东橙",
        "de": "Östlich",
        "id": "Oranye timur",
        "ja": "イーストオレンジ",
        "th": "สีส้มตะวันออก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7651,
        "lng": -74.2117
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11679,
      "name": "Ouricuri",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.8828,
        "lng": -40.0819
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5624,
      "name": "Martil",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 35.61,
        "lng": -5.27
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 11644,
      "name": "Sandefjord",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.1288,
        "lng": 10.2197
      },
      "country": "Norway",
      "importance": 3
    },
    {
      "id": 11653,
      "name": "Sibulan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.35,
        "lng": 123.2833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11629,
      "name": "Dormagen",
      "names": {
        "zh": "多马根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0964,
        "lng": 6.84
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5658,
      "name": "Denizli",
      "names": {
        "zh": "代尼兹利",
        "ja": "デニズリ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7731,
        "lng": 29.0878
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 11645,
      "name": "Saravia",
      "names": {
        "zh": "萨拉维亚"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8833,
        "lng": 122.9667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11563,
      "name": "Seraing",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.5986,
        "lng": 5.5122
      },
      "country": "Belgium",
      "importance": 3
    },
    {
      "id": 11671,
      "name": "Atimonan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0036,
        "lng": 121.9199
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5522,
      "name": "Misantla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9333,
        "lng": -96.85
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11656,
      "name": "Kodad",
      "names": {
        "zh": "柯达"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 16.9978,
        "lng": 79.9653
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11682,
      "name": "Cienaga de Oro",
      "names": {
        "ja": "Cienaga deoro"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.875,
        "lng": -75.6211
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11669,
      "name": "Vittoria",
      "names": {
        "zh": "维多利亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 36.95,
        "lng": 14.5333
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11666,
      "name": "Tamana",
      "names": {
        "zh": "玉名"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.9281,
        "lng": 130.5597
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11663,
      "name": "Cruz das Almas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.67,
        "lng": -39.1019
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11686,
      "name": "Bamei",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.2634,
        "lng": 105.0809
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11668,
      "name": "Qinggang",
      "names": {
        "zh": "青冈"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 46.69,
        "lng": 126.1
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11684,
      "name": "Ghardimaou",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.4503,
        "lng": 8.4397
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 11696,
      "name": "Eastvale",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.9617,
        "lng": -117.5802
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11638,
      "name": "Tadepalle",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.4667,
        "lng": 80.6
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11640,
      "name": "Yanagawa",
      "names": {
        "zh": "柳川",
        "ja": "柳川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.1631,
        "lng": 130.4061
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11665,
      "name": "San Felipe",
      "names": {
        "zh": "圣费利佩",
        "ja": "サンフェリペ",
        "th": "ซานเฟลิเป้"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -32.7507,
        "lng": -70.7251
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 11694,
      "name": "Bandar-e Genaveh",
      "names": {
        "ja": "バンダルe Genaveh"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 29.5833,
        "lng": 50.5167
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 11692,
      "name": "Asbest",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.0,
        "lng": 61.4667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11648,
      "name": "Neubrandenburg",
      "names": {
        "zh": "新勃兰登堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.5569,
        "lng": 13.2608
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11695,
      "name": "Penedo",
      "names": {
        "zh": "佩内杜"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.29,
        "lng": -36.5858
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5612,
      "name": "Teresa",
      "names": {
        "zh": "特里萨",
        "ja": "テレサ",
        "th": "เทเรซา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.5586,
        "lng": 121.2083
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11701,
      "name": "Chapel Hill",
      "names": {
        "es": "Colina de la capilla",
        "fr": "Chapelle colline",
        "zh": "教堂山",
        "de": "Kapellenhügel",
        "id": "Bukit kapit",
        "ja": "チャペルヒル",
        "pt": "Colina de capela"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.927,
        "lng": -79.0391
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11702,
      "name": "Armur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.79,
        "lng": 78.29
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5410,
      "name": "Palaio Faliro",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.932,
        "lng": 23.7003
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 11703,
      "name": "Rocky Mount",
      "names": {
        "es": "Monte rocoso",
        "fr": "Montage rocheux",
        "zh": "洛基山",
        "de": "Felsiger Berg",
        "it": "Monte roccioso",
        "id": "Mount",
        "ja": "ロッキーマウント",
        "th": "ร็อคกี้เมา",
        "pt": "Montagem rochosa"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9676,
        "lng": -77.8048
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11705,
      "name": "Haverhill",
      "names": {
        "zh": "黑弗里尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7838,
        "lng": -71.0871
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11662,
      "name": "Sodegaura",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.43,
        "lng": 139.9547
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5680,
      "name": "Solola",
      "names": {
        "es": "Sololá",
        "zh": "索洛拉"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7667,
        "lng": -91.1833
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 11687,
      "name": "Tuao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 17.7333,
        "lng": 121.45
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5595,
      "name": "Bankra",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.63,
        "lng": 88.3
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11673,
      "name": "Nawalgarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.85,
        "lng": 75.27
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11683,
      "name": "Crotone",
      "names": {
        "zh": "克罗托内",
        "ja": "クロトーネ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.0833,
        "lng": 17.1167
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 11620,
      "name": "Zeist",
      "names": {
        "zh": "宰斯特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0833,
        "lng": 5.2333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 11712,
      "name": "San Jose de Bocay",
      "names": {
        "zh": "圣何塞德博节",
        "ja": "サンノゼ・デ・ボケイ",
        "th": "ซานโฮเซ่เดอโบไซค์"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.542,
        "lng": -85.5394
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 11716,
      "name": "Bognor Regis",
      "names": {
        "de": "Bissor Regis"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.7824,
        "lng": -0.6764
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5695,
      "name": "Latacunga",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -0.9333,
        "lng": -78.6167
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 11707,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7667,
        "lng": 119.9167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5690,
      "name": "Inhambane",
      "names": {
        "zh": "伊尼扬巴内"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -23.865,
        "lng": 35.3833
      },
      "country": "Mozambique",
      "importance": 1
    },
    {
      "id": 5665,
      "name": "Songkhla",
      "names": {
        "zh": "宋卡",
        "th": "สงขลา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.2061,
        "lng": 100.5967
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 11697,
      "name": "Sibalom",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7833,
        "lng": 122.0167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5642,
      "name": "Collado-Villalba",
      "names": {
        "es": "Collado Villalba,",
        "fr": "-Collado Villalba",
        "zh": "科利亚多-维拉尔巴",
        "it": "-Collado Villalba"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.6333,
        "lng": -4.0083
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11698,
      "name": "Kangbao",
      "names": {
        "zh": "康宝"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.8513,
        "lng": 114.6091
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 11634,
      "name": "Santa Catarina Pinula",
      "names": {
        "fr": "Pinula Santa Catarina",
        "zh": "圣卡塔琳娜·佩拉",
        "ja": "サンタカタリーナピュニュラ",
        "th": "ซานต้าคาตาร์ดิน่า Pinula"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5644,
        "lng": -90.488
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 11725,
      "name": "Beloit",
      "names": {
        "zh": "伯洛伊特",
        "th": "เบลัวต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.523,
        "lng": -89.0184
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11675,
      "name": "Tenri",
      "names": {
        "ja": "天理"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5967,
        "lng": 135.8375
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11710,
      "name": "Ponferrada",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 42.5461,
        "lng": -6.5908
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11723,
      "name": "Zarand",
      "names": {
        "zh": "扎兰德"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 30.8128,
        "lng": 56.5639
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 5618,
      "name": "Guamuchil",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.4639,
        "lng": -108.0794
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11709,
      "name": "Tsuruga",
      "names": {
        "zh": "敦贺",
        "ja": "敦賀"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.65,
        "lng": 136.0667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11685,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.5781,
        "lng": 120.6819
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11681,
      "name": "Plauen",
      "names": {
        "zh": "普劳恩",
        "ja": "プラウエン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.495,
        "lng": 12.1383
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11728,
      "name": "Gloucester",
      "names": {
        "zh": "告士打道",
        "ja": "グロスター",
        "th": "กลอสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.7924,
        "lng": -75.0363
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11654,
      "name": "Jatani",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 20.17,
        "lng": 85.7
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11659,
      "name": "Chokwe",
      "names": {
        "zh": "绍奎"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -24.5253,
        "lng": 33.0086
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 11724,
      "name": "Kurihara",
      "names": {
        "zh": "栗原",
        "ja": "栗原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.7303,
        "lng": 141.0214
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11717,
      "name": "Zvornik",
      "names": {
        "zh": "兹沃尔尼克"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.3833,
        "lng": 19.1
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 11690,
      "name": "Nawa",
      "names": {
        "zh": "纳瓦",
        "ja": "名和",
        "th": "ยานนาวา"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.8833,
        "lng": 36.05
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 11730,
      "name": "Chincha Alta",
      "names": {
        "ja": "チンチャアルタ"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -13.45,
        "lng": -76.1333
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 11711,
      "name": "Jose Panganiban",
      "names": {
        "zh": "何塞普纳班班"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.3,
        "lng": 122.7
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11726,
      "name": "Guiglo",
      "names": {
        "zh": "吉格洛"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 6.5436,
        "lng": -7.4933
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 11680,
      "name": "Pomezia",
      "names": {
        "th": "โพเมเซีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.6693,
        "lng": 12.5021
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11719,
      "name": "Farroupilha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.225,
        "lng": -51.3478
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11639,
      "name": "Donskoy",
      "names": {
        "zh": "顿斯科伊"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.9667,
        "lng": 38.3167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11689,
      "name": "Grevenbroich",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0883,
        "lng": 6.5875
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5700,
      "name": "Dedougou",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.4667,
        "lng": -3.4667
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 11700,
      "name": "Maidenhead",
      "names": {
        "zh": "梅登黑德",
        "ja": "メーデンヘッド",
        "th": "สาว"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5217,
        "lng": -0.7177
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11708,
      "name": "Yangqingcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 21.3594,
        "lng": 110.1164
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11691,
      "name": "At Tall",
      "names": {
        "es": "En alto",
        "fr": "À hauteur",
        "zh": "高高",
        "de": "In großem Zustand",
        "it": "Alto",
        "id": "Dgn tinggi badan",
        "ja": "背の高い",
        "th": "สูง",
        "pt": "Em alto"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.6103,
        "lng": 36.3106
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 5584,
      "name": "Merthyr Tudful",
      "names": {
        "es": "Merthyr tachul",
        "fr": "Merthyr arroussé",
        "zh": "Merthyr cudful",
        "it": "Merthyr Buckful",
        "ja": "MERTHYR"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.743,
        "lng": -3.378
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11688,
      "name": "Aliaga",
      "names": {
        "zh": "阿利阿"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.4988,
        "lng": 120.841
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5694,
      "name": "Alba Iulia",
      "names": {
        "ja": "アルバイーリア"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.0764,
        "lng": 23.5728
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 11612,
      "name": "Korosten",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.95,
        "lng": 28.65
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 11727,
      "name": "Velika Gorica",
      "names": {
        "id": "Gorica velika"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.7,
        "lng": 16.0667
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 11729,
      "name": "Escada",
      "names": {
        "zh": "埃斯卡达",
        "ja": "エスカーダ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.3592,
        "lng": -35.2236
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11706,
      "name": "Asahi",
      "names": {
        "zh": "朝日",
        "ja": "朝日",
        "th": "อาซาฮี"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7161,
        "lng": 140.6481
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11604,
      "name": "Leszno",
      "names": {
        "zh": "莱什诺"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8403,
        "lng": 16.5749
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11736,
      "name": "Glens Falls",
      "names": {
        "es": "Gens Falls",
        "fr": "Les listes tombent",
        "zh": "格伦瀑布",
        "de": "Glens fällt",
        "ja": "グライスが落ちます",
        "th": "เกล็นส์ฟอลส์",
        "pt": "Glens cai"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.3109,
        "lng": -73.6459
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11718,
      "name": "Bhairahawa",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 27.5,
        "lng": 83.45
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 11721,
      "name": "Turbaco",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.3319,
        "lng": -75.4142
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11737,
      "name": "Salto de Agua",
      "names": {
        "ja": "サルタアグア"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.55,
        "lng": -92.3333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11633,
      "name": "Pokrovsk",
      "names": {
        "zh": "波克罗夫斯克"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2833,
        "lng": 37.1833
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 11740,
      "name": "Granby",
      "names": {
        "zh": "格兰比",
        "ja": "グランビー"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.4,
        "lng": -72.7333
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5660,
      "name": "Rundu",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -17.9167,
        "lng": 19.7667
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 11735,
      "name": "Ain Sefra",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 32.75,
        "lng": -0.5833
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 11693,
      "name": "Povoa de Varzim",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3916,
        "lng": -8.7571
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 5707,
      "name": "Brentwood",
      "names": {
        "zh": "布伦特伍德",
        "ja": "ブレントウッド",
        "th": "แนชวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7839,
        "lng": -73.2522
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5708,
      "name": "Karakol",
      "names": {
        "zh": "喀拉口尔"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.5,
        "lng": 78.3833
      },
      "country": "Kyrgyzstan",
      "importance": 1
    },
    {
      "id": 5650,
      "name": "La Linea de la Concepcion",
      "names": {
        "es": "La Linea de la Concepción",
        "ja": "Lainea de la Concepcion"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.1611,
        "lng": -5.3486
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5546,
      "name": "Fujiidera",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5747,
        "lng": 135.5975
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11636,
      "name": "Rosenheim",
      "names": {
        "zh": "罗森海姆",
        "ja": "ローゼンハイム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.8561,
        "lng": 12.1289
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11738,
      "name": "Rolandia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.31,
        "lng": -51.3689
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5469,
      "name": "Ivry-sur-Seine",
      "names": {
        "es": "Ivry-sur-Sena",
        "zh": "伊夫里塞纳河畔",
        "ja": "ivryシュルセーヌ",
        "th": "ivry-ซูร์แม่น้ำเซน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8078,
        "lng": 2.3747
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5619,
      "name": "Stourbridge",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4575,
        "lng": -2.1479
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5704,
      "name": "Kahramanmaras",
      "names": {
        "zh": "卡赫拉曼马拉什",
        "th": "คาห์รามานมารัส"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5875,
        "lng": 36.9453
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 11748,
      "name": "Abu Qurqas",
      "names": {
        "zh": "阿布古琴",
        "id": "Ajaran Abu Qurqas",
        "th": "abu Quraqas"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 27.9308,
        "lng": 30.8364
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 11749,
      "name": "Medicine Hat",
      "names": {
        "es": "Sombrero de medicina",
        "fr": "Chapeau de médecine",
        "zh": "医学帽",
        "de": "Medizinhut",
        "it": "Cappello di medicina",
        "id": "Topi obat",
        "ja": "薬の帽子",
        "th": "หมวกยา"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 50.0417,
        "lng": -110.6775
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11747,
      "name": "Santo Antonio do Descoberto",
      "names": {
        "de": "Santo Antonio do descaberto",
        "ja": "Santo Antonio descoberto",
        "th": "Santo Antonio Descoberto",
        "pt": "Santo Antonio do DescroBerto"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.94,
        "lng": -48.255
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5661,
      "name": "Kitale",
      "names": {
        "zh": "基塔莱"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 1.0167,
        "lng": 35.0
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 11751,
      "name": "Fairbanks",
      "names": {
        "zh": "费尔班克斯",
        "ja": "フェアバンクス",
        "th": "แฟร์แบงค์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 64.8353,
        "lng": -147.6534
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11753,
      "name": "Springfield",
      "names": {
        "zh": "斯普林菲尔德",
        "ja": "スプリングフィールド",
        "th": "สปริงฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0538,
        "lng": -122.981
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5697,
      "name": "Gorno-Altaysk",
      "names": {
        "zh": "戈尔诺-altaysk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 51.96,
        "lng": 85.96
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 11752,
      "name": "Volsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.05,
        "lng": 47.3833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5711,
      "name": "Anuradhapura",
      "names": {
        "zh": "阿努拉德普勒"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 8.335,
        "lng": 80.4108
      },
      "country": "Sri Lanka",
      "importance": 1
    },
    {
      "id": 11755,
      "name": "Bethesda",
      "names": {
        "zh": "贝塞斯达",
        "ja": "ベテスダ",
        "th": "เบเทสดา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9866,
        "lng": -77.1188
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11704,
      "name": "Lugano",
      "names": {
        "zh": "卢加诺",
        "ja": "ルガノ",
        "th": "ลูกาโน"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.0103,
        "lng": 8.9625
      },
      "country": "Switzerland",
      "importance": 3
    },
    {
      "id": 11650,
      "name": "Gadwal",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.23,
        "lng": 77.8
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11715,
      "name": "Quimper",
      "names": {
        "zh": "坎佩尔",
        "ja": "カンペール"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.9967,
        "lng": -4.0964
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11757,
      "name": "Grande Prairie",
      "names": {
        "zh": "格兰德大草原",
        "id": "Pedagang grand",
        "ja": "グランデプレーリー",
        "th": "แกรนด์ทุ่งหญ้า",
        "pt": "Pradaria grande"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 55.1708,
        "lng": -118.7947
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11713,
      "name": "Vigevano",
      "names": {
        "zh": "维杰瓦诺"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.3167,
        "lng": 8.8667
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11760,
      "name": "Johnstown",
      "names": {
        "zh": "约翰斯敦",
        "ja": "ジョンズタウン",
        "th": "จอห์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.3258,
        "lng": -78.9193
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11756,
      "name": "Novaya Balakhna",
      "names": {
        "id": "Novaya balakhhna"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.4943,
        "lng": 43.5944
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11720,
      "name": "Nankana Sahib",
      "names": {
        "zh": "南亚纳萨希布",
        "ja": "南南サヒブ"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.4492,
        "lng": 73.7124
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 11766,
      "name": "Puerto Asis",
      "names": {
        "zh": "波多黎各",
        "ja": "プエルトアシス",
        "th": "เปอร์โตซ่าน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.5006,
        "lng": -76.4989
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11767,
      "name": "West Hartford",
      "names": {
        "zh": "西哈特福德",
        "id": "Hartford Barat",
        "ja": "ウェストハートフォード"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7669,
        "lng": -72.7536
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11744,
      "name": "Nago",
      "names": {
        "zh": "名护",
        "ja": "名護"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 26.5917,
        "lng": 127.9775
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11762,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.9167,
        "lng": 125.3333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5659,
      "name": "Nieuwegein",
      "names": {
        "zh": "尼沃海恩"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0314,
        "lng": 5.0919
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 5629,
      "name": "Hereford",
      "names": {
        "zh": "赫里福德",
        "ja": "ヘレフォード",
        "th": "เฮียร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.0565,
        "lng": -2.716
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11769,
      "name": "Dundalk",
      "names": {
        "zh": "邓多克",
        "th": "ดอลก์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.2703,
        "lng": -76.4942
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11770,
      "name": "Castro Valley",
      "names": {
        "fr": "Vallée de Castro",
        "zh": "卡斯特罗山谷",
        "de": "Castro-Tal",
        "id": "Lembah Castro",
        "ja": "カストロバレー",
        "th": "หุบเขาคาสโตร",
        "pt": "Vale do Castro"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7091,
        "lng": -122.0631
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11768,
      "name": "Kasongo",
      "names": {
        "zh": "卡松戈"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -4.45,
        "lng": 26.66
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 11772,
      "name": "Coon Rapids",
      "names": {
        "zh": "浣熊急流",
        "de": "COON RAPTS",
        "it": "Rapids Coon",
        "th": "คูน",
        "pt": "Rapids de Coon"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.1755,
        "lng": -93.3095
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11754,
      "name": "Kaga",
      "names": {
        "zh": "加贺",
        "ja": "加賀",
        "th": "กะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3028,
        "lng": 136.315
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11771,
      "name": "Sao Borja",
      "names": {
        "zh": "圣博尔贾",
        "ja": "サンボルヤ",
        "th": "เซาบอร์จา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.6608,
        "lng": -56.0039
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11774,
      "name": "Elmira",
      "names": {
        "zh": "埃尔迈拉"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0938,
        "lng": -76.8097
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5558,
      "name": "Rochester",
      "names": {
        "zh": "罗切斯特",
        "ja": "ロチェスター",
        "th": "โรเชสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.375,
        "lng": 0.5
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11641,
      "name": "Miyoshi",
      "names": {
        "zh": "三芳"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0897,
        "lng": 137.0744
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5643,
      "name": "Dewsbury",
      "names": {
        "zh": "迪斯伯里",
        "th": "ดิวสเบอรี่"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.691,
        "lng": -1.633
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5716,
      "name": "Beledweyne",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 4.736,
        "lng": 45.204
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 11714,
      "name": "Klintsy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.7528,
        "lng": 32.2361
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11678,
      "name": "Irun",
      "names": {
        "zh": "伊伦",
        "th": "ไอรัน"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 43.3378,
        "lng": -1.7888
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11773,
      "name": "Pesqueira",
      "names": {
        "zh": "佩斯凯拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.3617,
        "lng": -36.6947
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11699,
      "name": "Mahdasht",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7283,
        "lng": 50.8133
      },
      "country": "Iran",
      "importance": 3
    },
    {
      "id": 11777,
      "name": "Yurimaguas",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -5.9,
        "lng": -76.0833
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 5710,
      "name": "Sopron",
      "names": {
        "zh": "肖普朗",
        "ja": "ショプロン"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6833,
        "lng": 16.5833
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 11731,
      "name": "Graaff-Reinet",
      "names": {
        "zh": "格拉夫-里内特"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -32.2522,
        "lng": 24.5406
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 11780,
      "name": "Mankato",
      "names": {
        "zh": "曼凯托",
        "th": "แมนเคโท"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.1712,
        "lng": -93.9773
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5653,
      "name": "La Seyne-sur-Mer",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1,
        "lng": 5.883
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 11743,
      "name": "Balboa Heights",
      "names": {
        "fr": "Hauteur de balba",
        "zh": "Balboa高度",
        "de": "Balboa-Höhen",
        "it": "Altezze Balboa",
        "ja": "バルボハイツ",
        "pt": "Alturas de balboa"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.95,
        "lng": -79.5667
      },
      "country": "Panama",
      "importance": 3
    },
    {
      "id": 11781,
      "name": "Albany",
      "names": {
        "zh": "奥尔巴尼",
        "ja": "アルバニー",
        "th": "ออลบานี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.6274,
        "lng": -123.0966
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5571,
      "name": "Antony",
      "names": {
        "zh": "安东尼",
        "ja": "アントニー",
        "th": "แอนโทนี"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7539,
        "lng": 2.2975
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11764,
      "name": "Calinog",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1333,
        "lng": 122.5
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11775,
      "name": "Palmela",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.5675,
        "lng": -8.8991
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 11784,
      "name": "Rossosh",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 50.1983,
        "lng": 39.5672
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 5722,
      "name": "North Miami",
      "names": {
        "es": "Norte de Miami",
        "zh": "北迈阿密",
        "de": "Nordmiami",
        "it": "Miami del Nord",
        "id": "Miami utara",
        "ja": "ノースマイアミ",
        "th": "ไมอามีเหนือ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.9007,
        "lng": -80.1686
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11783,
      "name": "Cruz Alta",
      "names": {
        "ja": "クルスアルタ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -28.6386,
        "lng": -53.6064
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11732,
      "name": "Moncada",
      "names": {
        "zh": "蒙卡达"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7333,
        "lng": 120.5667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11722,
      "name": "Woking",
      "names": {
        "zh": "沃金",
        "th": "โวกิงก์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.3162,
        "lng": -0.561
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11790,
      "name": "Oriximina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -1.7658,
        "lng": -55.8658
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11788,
      "name": "Camaqua",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -30.8528,
        "lng": -51.8153
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11761,
      "name": "Bansalan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.7833,
        "lng": 125.2167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11782,
      "name": "Pavlovskiy Posad",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.7833,
        "lng": 38.65
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11672,
      "name": "Lingshou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.3063,
        "lng": 114.3879
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11795,
      "name": "Encinitas",
      "names": {
        "zh": "恩西尼塔斯"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.049,
        "lng": -117.2613
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5723,
      "name": "Skokie",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 42.0359,
        "lng": -87.74
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11763,
      "name": "Lomza",
      "names": {
        "zh": "沃姆扎"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1833,
        "lng": 22.0833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11792,
      "name": "Revda",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.8,
        "lng": 59.9167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5718,
      "name": "Tandag",
      "names": {
        "zh": "丹达"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.0667,
        "lng": 126.1833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 11793,
      "name": "Borisoglebsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 51.3667,
        "lng": 42.0833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11779,
      "name": "Nkawkaw",
      "names": {
        "zh": "恩考考"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 6.5505,
        "lng": -0.78
      },
      "country": "Ghana",
      "importance": 3
    },
    {
      "id": 11776,
      "name": "Fujioka",
      "names": {
        "zh": "藤冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.2667,
        "lng": 139.0667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11799,
      "name": "Leander",
      "names": {
        "zh": "莱安德",
        "it": "Leandro"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.5728,
        "lng": -97.8618
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11801,
      "name": "Greenwich",
      "names": {
        "zh": "格林威治",
        "ja": "グリニッジ",
        "th": "กรีนิช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0665,
        "lng": -73.6368
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11787,
      "name": "Kapatagan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.9,
        "lng": 123.7667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11804,
      "name": "Parkersburg",
      "names": {
        "zh": "帕克尔斯堡",
        "th": "พาร์เกอร์สบูร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.2624,
        "lng": -81.542
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11741,
      "name": "Carrara",
      "names": {
        "zh": "卡拉拉",
        "ja": "カラーラ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.0833,
        "lng": 10.1
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11802,
      "name": "Leninogorsk",
      "names": {
        "zh": "列宁诺戈尔斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.5989,
        "lng": 52.4423
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11805,
      "name": "Kayes",
      "names": {
        "zh": "卡耶斯"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.1806,
        "lng": 13.2889
      },
      "country": "Congo (Brazzaville)",
      "importance": 3
    },
    {
      "id": 11734,
      "name": "Budennovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.7833,
        "lng": 44.15
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11809,
      "name": "Waltham",
      "names": {
        "zh": "沃尔瑟姆",
        "ja": "ウォルサム",
        "th": "วอลแทม"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3889,
        "lng": -71.2423
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5216,
      "name": "Clichy",
      "names": {
        "zh": "克利希",
        "th": "กลิชี่"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9044,
        "lng": 2.3064
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5632,
      "name": "Granollers",
      "names": {
        "zh": "格拉诺列尔斯"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.6083,
        "lng": 2.2889
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5662,
      "name": "Nagakute",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1842,
        "lng": 137.0486
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11800,
      "name": "Mobarakeh",
      "names": {
        "zh": "穆巴拉克"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 32.3464,
        "lng": 51.5044
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 11811,
      "name": "Tefe",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.3539,
        "lng": -64.7108
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5668,
      "name": "Singida",
      "names": {
        "zh": "辛吉达"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -4.8186,
        "lng": 34.7506
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 11808,
      "name": "Villa Carlos Paz",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -31.4,
        "lng": -64.5167
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 5547,
      "name": "Ain Harrouda",
      "names": {
        "es": "Ain Harudauda",
        "it": "Ain Harrorouda"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.6372,
        "lng": -7.4483
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 11733,
      "name": "Wenxicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.1565,
        "lng": 120.3824
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11810,
      "name": "Tanjungpandan",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.75,
        "lng": 107.65
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 11797,
      "name": "Camboriu",
      "names": {
        "pt": "Camboriú"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.025,
        "lng": -48.6539
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11819,
      "name": "Port Charlotte",
      "names": {
        "zh": "夏洛特港",
        "ja": "ポートシャーロット",
        "th": "พอร์ตชาร์ลอตต์",
        "pt": "Porto charlotte"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.9918,
        "lng": -82.114
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11816,
      "name": "Caltanissetta",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 37.4915,
        "lng": 14.0624
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 5583,
      "name": "Villa Dominico",
      "names": {
        "ja": "ヴィラドミニコ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6917,
        "lng": -58.3333
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 11821,
      "name": "Palm Harbor",
      "names": {
        "es": "Palmero",
        "fr": "Portier",
        "zh": "棕榈港",
        "de": "Palmhafen",
        "it": "Porto di palma",
        "id": "Pelabuhan",
        "ja": "パームハーバー",
        "th": "ปาล์มฮาร์เบอร์",
        "pt": "Porto de palma"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.0847,
        "lng": -82.7481
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11745,
      "name": "Roeselare",
      "names": {
        "fr": "Roulers",
        "zh": "鲁瑟拉勒"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.9447,
        "lng": 3.1233
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 11822,
      "name": "Puerto Penasco",
      "names": {
        "es": "Puerto Peñasco",
        "ja": "プエルトペナスコ",
        "th": "เปอร์โตเพนาสโก"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.3167,
        "lng": -113.5369
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11812,
      "name": "Riosucio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4208,
        "lng": -75.7028
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11824,
      "name": "San Luis Obispo",
      "names": {
        "zh": "圣路易斯奥比斯波",
        "ja": "サンルイスオビスポ",
        "th": "ซานหลุยส์โอบิสโป"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2669,
        "lng": -120.6691
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11798,
      "name": "Shangtangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 21.5989,
        "lng": 111.5907
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11815,
      "name": "Boryspil",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.35,
        "lng": 30.95
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 11820,
      "name": "Tuapse",
      "names": {
        "zh": "图阿普谢"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 44.1,
        "lng": 39.0833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 5728,
      "name": "Kolda",
      "names": {
        "zh": "科尔达"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 12.8958,
        "lng": -14.9408
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 5687,
      "name": "Nagari",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.33,
        "lng": 79.58
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11828,
      "name": "Sebring",
      "names": {
        "zh": "赛百灵",
        "ja": "セブリング",
        "th": "ซีบริง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.477,
        "lng": -81.453
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11796,
      "name": "Zelenogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1,
        "lng": 94.5833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5731,
      "name": "Napier",
      "names": {
        "zh": "纳皮尔",
        "ja": "ネイピア",
        "th": "เนเปียร์"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -39.4833,
        "lng": 176.9167
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 5729,
      "name": "Santarem",
      "names": {
        "zh": "圣塔伦",
        "pt": "Santarém"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.2392,
        "lng": -8.6869
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 11829,
      "name": "Chistopol",
      "names": {
        "zh": "奇斯托波尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.3648,
        "lng": 50.6407
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11750,
      "name": "Bannu",
      "names": {
        "zh": "班努"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.9889,
        "lng": 70.6056
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 11831,
      "name": "Council Bluffs",
      "names": {
        "es": "Bluffs del consejo",
        "fr": "Le Conseil Bluffs",
        "zh": "理事会虚张声势",
        "de": "Ratsbluffs",
        "it": "Bluffs del Consiglio",
        "id": "Gertakan",
        "ja": "カウンシルブラフ",
        "th": "ป้านสภา",
        "pt": "Bluffs do Conselho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2369,
        "lng": -95.8517
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11786,
      "name": "Palin",
      "names": {
        "zh": "佩林",
        "ja": "ペイリン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4039,
        "lng": -90.6986
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 11825,
      "name": "Figueira da Foz",
      "names": {
        "ja": "figeira da foz"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.1508,
        "lng": -8.8618
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 11833,
      "name": "Itapecuru Mirim",
      "names": {
        "ja": "イタプセルミリム"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.3928,
        "lng": -44.3589
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11823,
      "name": "Aurora",
      "names": {
        "zh": "奥罗拉",
        "ja": "オーロラ",
        "th": "ออโรรา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.35,
        "lng": 122.5167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11835,
      "name": "Ipira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.1578,
        "lng": -39.7369
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11839,
      "name": "Grajau",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.8189,
        "lng": -46.1389
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11841,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.3938,
        "lng": -84.5653
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11843,
      "name": "Viareggio",
      "names": {
        "zh": "维亚雷焦",
        "ja": "ヴィアレッジョ",
        "th": "วีอาเรจโจ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.8672,
        "lng": 10.2506
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11832,
      "name": "Penapolis",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.42,
        "lng": -50.0778
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11840,
      "name": "Morada Nova",
      "names": {
        "zh": "莫拉达新星",
        "ja": "モラダノバ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1069,
        "lng": -38.3728
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11847,
      "name": "Moore",
      "names": {
        "zh": "摩尔",
        "ja": "ムーア",
        "th": "มัวร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.3293,
        "lng": -97.4758
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5738,
      "name": "Pico Rivera",
      "names": {
        "ja": "ピコリバーラ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9902,
        "lng": -118.0888
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11848,
      "name": "Zaraza",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.3394,
        "lng": -65.3167
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 5735,
      "name": "Madang",
      "names": {
        "zh": "马当"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -5.2248,
        "lng": 145.7853
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 11844,
      "name": "Ferkessedougou",
      "names": {
        "zh": "费尔凯塞杜古"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 9.5928,
        "lng": -5.1944
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 5673,
      "name": "Elk",
      "names": {
        "zh": "麋鹿",
        "ja": "エルク",
        "th": "กวาง",
        "pt": "Alces"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.8167,
        "lng": 22.35
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11838,
      "name": "Naro-Fominsk",
      "names": {
        "zh": "纳罗-fominsk"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.3833,
        "lng": 36.7333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11826,
      "name": "Nahuala",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8429,
        "lng": -91.317
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 11849,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.4356,
        "lng": -62.0714
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 11758,
      "name": "Koshizuka",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 32.8858,
        "lng": 130.7897
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5631,
      "name": "Troyes",
      "names": {
        "zh": "特鲁瓦",
        "ja": "トロワ",
        "th": "ทรัวส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.2997,
        "lng": 4.0792
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11806,
      "name": "Tendo",
      "names": {
        "zh": "天童",
        "ja": "天童",
        "pt": "Tendão"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.3622,
        "lng": 140.3783
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11850,
      "name": "Casa Grande",
      "names": {
        "ja": "カサグランデ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.9068,
        "lng": -111.7624
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11851,
      "name": "Monessen",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.1519,
        "lng": -79.8828
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5739,
      "name": "Montebello",
      "names": {
        "zh": "蒙特韦约"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0155,
        "lng": -118.1108
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5667,
      "name": "Tagajo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 38.2939,
        "lng": 141.0044
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11807,
      "name": "Menzel Bourguiba",
      "names": {
        "es": "Menzel Bourgguiba",
        "zh": "Menzel Bouruiba"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 37.15,
        "lng": 9.7833
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 5726,
      "name": "Rayong",
      "names": {
        "zh": "罗勇",
        "ja": "ラヨーン",
        "th": "ระยอง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.6742,
        "lng": 101.2789
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 11837,
      "name": "Cambanugoy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.5386,
        "lng": 125.7508
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11858,
      "name": "Satbayev",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 47.9,
        "lng": 67.5333
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 11818,
      "name": "Villach",
      "names": {
        "zh": "菲拉赫"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.6167,
        "lng": 13.8333
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 5740,
      "name": "Bamyan",
      "names": {
        "zh": "巴米扬"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.8167,
        "lng": 67.8167
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 11857,
      "name": "Plato",
      "names": {
        "es": "Platón",
        "fr": "Platon",
        "zh": "柏拉图",
        "it": "Platone",
        "ja": "プラトン",
        "th": "เพลโต",
        "pt": "Platão"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.7925,
        "lng": -74.7825
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11856,
      "name": "Polevskoy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.45,
        "lng": 60.1833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11834,
      "name": "Mitoyo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.1475,
        "lng": 133.6958
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11842,
      "name": "Pacajus",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.1728,
        "lng": -38.4608
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11859,
      "name": "Piripiri",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.2728,
        "lng": -41.7769
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11794,
      "name": "Kotlas",
      "names": {
        "zh": "科特拉斯"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 61.25,
        "lng": 46.65
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11739,
      "name": "Herten",
      "names": {
        "zh": "黑尔滕"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6,
        "lng": 7.1333
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5691,
      "name": "Runcorn",
      "names": {
        "zh": "朗科恩",
        "ja": "ランコーン",
        "th": "รันคอร์น"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.341,
        "lng": -2.729
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11864,
      "name": "Camabatela",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.1833,
        "lng": 15.3667
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 5744,
      "name": "Rovaniemi",
      "names": {
        "zh": "罗瓦涅米"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 66.5028,
        "lng": 25.7285
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 11861,
      "name": "Hita",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.3211,
        "lng": 130.9414
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11865,
      "name": "Lysva",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.1004,
        "lng": 57.8043
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5725,
      "name": "Saraburi",
      "names": {
        "zh": "沙拉武里",
        "th": "สระบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5286,
        "lng": 100.9114
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 5604,
      "name": "Scarborough",
      "names": {
        "zh": "斯卡伯勒",
        "ja": "スカーバラ",
        "th": "สการ์เบอโร"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.2773,
        "lng": -0.4017
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11869,
      "name": "Fairfield",
      "names": {
        "zh": "费尔菲尔德",
        "ja": "フェアフィールド",
        "th": "แฟร์ฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1775,
        "lng": -73.2733
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11867,
      "name": "Coroata",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.13,
        "lng": -44.1239
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11846,
      "name": "Jhargram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.45,
        "lng": 86.98
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11872,
      "name": "St. Thomas",
      "names": {
        "es": "Santo Tomás",
        "fr": "Saint-Thomas",
        "zh": "圣托马斯",
        "ja": "セントトーマス",
        "th": "เซนต์โทมัส"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.775,
        "lng": -81.1833
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11863,
      "name": "Santo Amaro",
      "names": {
        "ja": "サントアマロ",
        "th": "ซานโตอามาโร"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.5469,
        "lng": -38.7119
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11813,
      "name": "Tarnowskie Gory",
      "names": {
        "de": "Tarnowskie-Gory"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.4455,
        "lng": 18.8615
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11742,
      "name": "Shiroi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7917,
        "lng": 140.0564
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5570,
      "name": "Cortazar",
      "names": {
        "zh": "科塔萨尔"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.483,
        "lng": -100.933
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11866,
      "name": "Trikala",
      "names": {
        "zh": "特里卡拉"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 39.555,
        "lng": 21.7683
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 11814,
      "name": "San Juan Chamelco",
      "names": {
        "zh": "圣胡安变色科",
        "ja": "サンファンカメルコ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4257,
        "lng": -90.3263
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 11853,
      "name": "San Antonio del Tachira",
      "names": {
        "id": "San Antonio del Takhira",
        "ja": "サンアントニオデルタチラ",
        "th": "ซานอันโตนิโอเดล Tachira"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 7.8145,
        "lng": -72.4431
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 5749,
      "name": "North Bergen",
      "names": {
        "es": "Bergen del Norte",
        "fr": "Nord bergen",
        "zh": "北卑尔根",
        "de": "Nordbergen",
        "id": "Bergen Utara",
        "ja": "ノースベルゲン",
        "pt": "Norte Bergen"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7938,
        "lng": -74.0242
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11827,
      "name": "Bergheim",
      "names": {
        "zh": "贝格海姆",
        "th": "เบิร์กไฮม์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9667,
        "lng": 6.65
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5647,
      "name": "Wrecsam",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 53.046,
        "lng": -2.993
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11746,
      "name": "Chelm",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.1333,
        "lng": 23.4833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11880,
      "name": "Airdrie",
      "names": {
        "zh": "艾尔德里",
        "th": "รีย"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 51.2917,
        "lng": -114.0144
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 789,
      "name": "Cayenne",
      "names": {
        "es": "Pimentón",
        "zh": "卡宴",
        "it": "Peperoncino di Cayenna",
        "ja": "カイエンヌ",
        "th": "พริกป่น",
        "pt": "Pimenta de caiena"
      },
      "countryCode": "GF",
      "latLng": {
        "lat": 4.933,
        "lng": -52.33
      },
      "country": "French Guiana",
      "importance": 0
    },
    {
      "id": 11765,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.0939,
        "lng": 120.7681
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11884,
      "name": "Rome",
      "names": {
        "es": "Roma",
        "zh": "罗马",
        "de": "Rom",
        "it": "Roma",
        "id": "Roma",
        "ja": "ローマ",
        "th": "โรม",
        "pt": "Roma"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.2661,
        "lng": -85.1862
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5750,
      "name": "Seinajoki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.7903,
        "lng": 22.8403
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 11836,
      "name": "Asker",
      "names": {
        "zh": "选择帮助提问者",
        "id": "Penanya",
        "ja": "アスカー",
        "th": "ถาม"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.8331,
        "lng": 10.4392
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 11885,
      "name": "Hato Mayor",
      "names": {
        "es": "Hato alcalde",
        "fr": "Chapeau maire",
        "de": "Hato Bürgermeister",
        "it": "Mayor hato",
        "id": "Walikota Hato",
        "ja": "市長鳩",
        "th": "นายกเทศมนตรี Hato"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.767,
        "lng": -69.267
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 5753,
      "name": "The Hammocks",
      "names": {
        "es": "Las hamacas",
        "fr": "Les hamacs",
        "zh": "吊床",
        "de": "Die Hängematten",
        "it": "Le amache",
        "id": "Tempat tidur gantung",
        "ja": "ハンモック",
        "th": "เปลญวน",
        "pt": "As redes"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.67,
        "lng": -80.4483
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11759,
      "name": "Oudtshoorn",
      "names": {
        "zh": "奥茨胡恩"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -33.5833,
        "lng": 22.2
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 11878,
      "name": "Itogon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.3667,
        "lng": 120.6833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11845,
      "name": "Baao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4535,
        "lng": 123.3654
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5698,
      "name": "Hasuda",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9942,
        "lng": 139.6622
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11785,
      "name": "Widnes",
      "names": {
        "zh": "威德尼斯",
        "th": "เนส"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.363,
        "lng": -2.728
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5703,
      "name": "Taal",
      "names": {
        "es": "Idioma",
        "zh": "塔尔",
        "ja": "タール",
        "th": "ภาษา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8833,
        "lng": 120.9333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11881,
      "name": "Djibo",
      "names": {
        "zh": "吉博"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 14.1012,
        "lng": -1.6279
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 11889,
      "name": "Millcreek",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.6892,
        "lng": -111.8291
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11882,
      "name": "Iztapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 13.9331,
        "lng": -90.7075
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 11890,
      "name": "Sanford",
      "names": {
        "zh": "桑福德",
        "ja": "サンフォード",
        "th": "แซนฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.7893,
        "lng": -81.276
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11868,
      "name": "Xinmin",
      "names": {
        "zh": "新民"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.118,
        "lng": 108.0986
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11883,
      "name": "Cajazeiras",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -6.89,
        "lng": -38.5619
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11876,
      "name": "Sawahlunto",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.6828,
        "lng": 100.7783
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 11891,
      "name": "Slutsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.0167,
        "lng": 27.55
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 5754,
      "name": "National City",
      "names": {
        "es": "Ciudad nacional",
        "fr": "Ville nationale",
        "zh": "国家城市",
        "de": "Nationalstadt",
        "it": "Città nazionale",
        "id": "Kota nasional",
        "ja": "国立都市",
        "th": "เมืองแห่งชาติ",
        "pt": "Cidade nacional"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.6654,
        "lng": -117.0983
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5530,
      "name": "Hatogaya-honcho",
      "names": {
        "zh": "鸠谷-本町",
        "ja": "hatogaya-班長"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.8267,
        "lng": 139.7412
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11896,
      "name": "Mayaguez",
      "names": {
        "es": "Mayagüez",
        "zh": "马亚圭斯"
      },
      "countryCode": "PR",
      "latLng": {
        "lat": 18.2003,
        "lng": -67.1397
      },
      "country": "Puerto Rico",
      "importance": 3
    },
    {
      "id": 5745,
      "name": "Giurgiu",
      "names": {
        "zh": "久尔久"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 43.9,
        "lng": 25.9667
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 11894,
      "name": "Vacaria",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -28.5119,
        "lng": -50.9339
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11898,
      "name": "Burnsville",
      "names": {
        "zh": "伯恩斯维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.7648,
        "lng": -93.2795
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11887,
      "name": "Ararangua",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -28.935,
        "lng": -49.4858
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5603,
      "name": "Vyronas",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9556,
        "lng": 23.76
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 11791,
      "name": "Kolomyia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5167,
        "lng": 25.0333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 11875,
      "name": "Sibay",
      "names": {
        "zh": "锡拜"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.7,
        "lng": 58.65
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5743,
      "name": "Yala",
      "names": {
        "zh": "亚拉",
        "th": "ยะลา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.5397,
        "lng": 101.2811
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 5757,
      "name": "Coconut Creek",
      "names": {
        "fr": "Creek de coco",
        "zh": "椰子溪",
        "de": "Kokosnuss-Creek",
        "ja": "ココナッツクリーク",
        "pt": "Coco riacho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.2803,
        "lng": -80.1842
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5684,
      "name": "Pruszkow",
      "names": {
        "de": "Pruszków"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.1667,
        "lng": 20.8
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11803,
      "name": "Wels",
      "names": {
        "zh": "韦尔斯",
        "ja": "ウェルス"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.15,
        "lng": 14.0167
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 11789,
      "name": "Margate",
      "names": {
        "zh": "马盖特",
        "ja": "マーゲート"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.385,
        "lng": 1.3838
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11899,
      "name": "Abuyog",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7458,
        "lng": 125.0122
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5758,
      "name": "Coyhaique",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -45.5712,
        "lng": -72.0685
      },
      "country": "Chile",
      "importance": 1
    },
    {
      "id": 11900,
      "name": "Acarau",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.8858,
        "lng": -40.12
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5759,
      "name": "Gaalkacyo",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 6.7697,
        "lng": 47.4308
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 11892,
      "name": "Rio do Sul",
      "names": {
        "zh": "里约热内卢",
        "it": "Rio dole",
        "ja": "リオデルスル"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -27.2139,
        "lng": -49.6428
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11874,
      "name": "Schwabisch Gmund",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8,
        "lng": 9.8
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11852,
      "name": "Ar Rastan",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.9167,
        "lng": 36.7333
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 11901,
      "name": "Morristown",
      "names": {
        "zh": "莫里斯敦",
        "th": "มอร์ริส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.2046,
        "lng": -83.3006
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11902,
      "name": "Halton Hills",
      "names": {
        "zh": "哈尔顿山",
        "id": "Bukit Halton",
        "ja": "ハロンヒルズ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 43.63,
        "lng": -79.95
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 11904,
      "name": "Reston",
      "names": {
        "zh": "雷斯顿",
        "ja": "レストン",
        "th": "เรสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9497,
        "lng": -77.3461
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11778,
      "name": "Deva",
      "names": {
        "zh": "天神",
        "ja": "デバ",
        "th": "เทพ"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.8719,
        "lng": 22.9117
      },
      "country": "Romania",
      "importance": 3
    },
    {
      "id": 11877,
      "name": "Cabatuan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.8833,
        "lng": 122.4833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11855,
      "name": "Santo Domingo",
      "names": {
        "ja": "サントドミンゴ",
        "th": "ซานโตโดมิงโก"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.5833,
        "lng": 120.8667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5679,
      "name": "Ellesmere Port",
      "names": {
        "es": "Puerto de Ellesmere",
        "fr": "Port de ellesmere",
        "zh": "ellesmere港口",
        "de": "Ellesmere Hafen",
        "it": "Porto di Ellesmere",
        "id": "Pelabuhan Ellesmere",
        "ja": "ellesmereポート",
        "th": "พอร์ต Ellesmere",
        "pt": "Porto Ellesmere"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.279,
        "lng": -2.897
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11908,
      "name": "Idiofa",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -4.9596,
        "lng": 19.5986
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 11914,
      "name": "Lakewood",
      "names": {
        "zh": "莱克伍德",
        "ja": "レイクウッド",
        "th": "เลกวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.1628,
        "lng": -122.5299
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11913,
      "name": "Tabatinga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -4.2525,
        "lng": -69.9381
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5689,
      "name": "Bangor",
      "names": {
        "zh": "班戈",
        "ja": "バンゴー",
        "th": "บันกอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.66,
        "lng": -5.67
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5712,
      "name": "Cherbourg",
      "names": {
        "zh": "瑟堡"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.6333,
        "lng": -1.6
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11919,
      "name": "Hamden",
      "names": {
        "zh": "哈姆登"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.3961,
        "lng": -72.9215
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11888,
      "name": "Fano",
      "names": {
        "zh": "法诺"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.8435,
        "lng": 13.0198
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11903,
      "name": "Tulunan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.8333,
        "lng": 124.8833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11920,
      "name": "Spring",
      "names": {
        "es": "Primavera",
        "fr": "Printemps",
        "zh": "春天",
        "de": "Frühling",
        "it": "Primavera",
        "ja": "春",
        "th": "ฤดูใบไม้ผลิ",
        "pt": "PRIMAVERA"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 30.0613,
        "lng": -95.383
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11893,
      "name": "Montauban",
      "names": {
        "zh": "蒙托邦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.0181,
        "lng": 1.3558
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11879,
      "name": "Wanparti",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.361,
        "lng": 78.0627
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11923,
      "name": "Gadsden",
      "names": {
        "zh": "加兹登"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.009,
        "lng": -86.0156
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11924,
      "name": "Taylor",
      "names": {
        "zh": "泰勒",
        "ja": "テイラー",
        "th": "เทย์เลอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.226,
        "lng": -83.2688
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11862,
      "name": "Aisai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.15,
        "lng": 136.7333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5709,
      "name": "Tsushima",
      "names": {
        "zh": "对马岛",
        "ja": "対馬"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1833,
        "lng": 136.7333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11830,
      "name": "Itoman",
      "names": {
        "zh": "伊藤万"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 26.1181,
        "lng": 127.6872
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11926,
      "name": "Novi",
      "names": {
        "zh": "诺维",
        "ja": "ノビ",
        "th": "โนวี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4786,
        "lng": -83.4893
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11927,
      "name": "Nambuangongo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -8.0483,
        "lng": 14.3372
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 5636,
      "name": "Los Polvorines",
      "names": {
        "ja": "ロスポルボリン"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.5,
        "lng": -58.7
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 11928,
      "name": "Marietta",
      "names": {
        "zh": "玛丽埃塔",
        "ja": "マリエッタ",
        "th": "มารีเอตตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9533,
        "lng": -84.5422
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11870,
      "name": "Friedrichshafen",
      "names": {
        "zh": "腓特烈港"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.6542,
        "lng": 9.4792
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11916,
      "name": "Jinoba-an",
      "names": {
        "es": "Jinoba-un",
        "fr": "Jinoba-un",
        "zh": "jinoba-一个",
        "it": "Jinoba un-",
        "ja": "jinoba-た",
        "th": "jinoba ข้อ",
        "pt": "Jinoba-um"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.6018,
        "lng": 122.4668
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11922,
      "name": "Tamba",
      "names": {
        "zh": "坦巴",
        "ja": "丹波"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1772,
        "lng": 135.0358
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11933,
      "name": "Tartagal",
      "names": {
        "zh": "塔塔加尔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -22.5,
        "lng": -63.8333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 11907,
      "name": "Chamrajnagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 11.9236,
        "lng": 76.94
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5682,
      "name": "Yamatotakada",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.515,
        "lng": 135.7364
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11817,
      "name": "Meihua",
      "names": {
        "zh": "梅花"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.8862,
        "lng": 114.8204
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11873,
      "name": "Orion",
      "names": {
        "es": "Orión",
        "zh": "猎户",
        "ja": "オリオン",
        "th": "นายพราน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.6206,
        "lng": 120.5817
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11937,
      "name": "Hot Springs",
      "names": {
        "es": "Aguas termales",
        "fr": "Sources d\u0027eau chaude",
        "zh": "温泉",
        "de": "Heiße Quellen",
        "it": "Sorgenti calde",
        "id": "Air panas",
        "ja": "熱水泉",
        "th": "น้ำพุร้อน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.4892,
        "lng": -93.0501
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11886,
      "name": "Garbsen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4183,
        "lng": 9.5981
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11860,
      "name": "Sarandi",
      "names": {
        "zh": "萨兰迪"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6833,
        "lng": -58.3333
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 11930,
      "name": "Villamaria",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0456,
        "lng": -75.5153
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11934,
      "name": "Shihe",
      "names": {
        "zh": "石河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.2708,
        "lng": 113.5478
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5746,
      "name": "Puerto Limon",
      "names": {
        "zh": "波多黎隆",
        "de": "Puerto-Limon",
        "ja": "プエルトリモン",
        "th": "เปอร์โตลิมอน"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 10.0022,
        "lng": -83.084
      },
      "country": "Costa Rica",
      "importance": 1
    },
    {
      "id": 5772,
      "name": "Oyem",
      "names": {
        "zh": "奥耶姆"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": 1.6,
        "lng": 11.5667
      },
      "country": "Gabon",
      "importance": 1
    },
    {
      "id": 11942,
      "name": "Igarape-Miri",
      "names": {
        "zh": "igarape-里",
        "ja": "igarape-ミリ",
        "th": "igarape-มีรี",
        "pt": "Igarapé-Miri"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.975,
        "lng": -48.96
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5544,
      "name": "Huatusco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1489,
        "lng": -96.9661
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5671,
      "name": "Euclides da Cunha",
      "names": {
        "fr": "Euclide da cunha",
        "ja": "ユキクレッドダ・チェンジ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.5078,
        "lng": -39.0139
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11921,
      "name": "Castilla",
      "names": {
        "zh": "卡斯蒂利亚",
        "ja": "カスティーリャ",
        "th": "กัสตียา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9486,
        "lng": 123.8822
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11947,
      "name": "Savona",
      "names": {
        "zh": "萨沃纳"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.308,
        "lng": 8.481
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 11854,
      "name": "Mielec",
      "names": {
        "zh": "梅莱茨"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2833,
        "lng": 21.4333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 11931,
      "name": "Laoang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.5667,
        "lng": 125.0167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11948,
      "name": "Woodland",
      "names": {
        "es": "Bosques",
        "fr": "Bois",
        "zh": "林地",
        "de": "Wald",
        "ja": "森林",
        "th": "ป่าไม้",
        "pt": "Floresta"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.6712,
        "lng": -121.75
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5769,
      "name": "Trang",
      "names": {
        "zh": "芽庄",
        "ja": "トラン",
        "th": "ตรัง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.5561,
        "lng": 99.6114
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 11945,
      "name": "Batatais",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.8911,
        "lng": -47.585
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5774,
      "name": "Juigalpa",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 12.0833,
        "lng": -85.4
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 5773,
      "name": "Lokoja",
      "names": {
        "zh": "洛科贾"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 7.8019,
        "lng": 6.7442
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 11946,
      "name": "Autlan de Navarro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7667,
        "lng": -104.3667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11915,
      "name": "Yongyang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.1017,
        "lng": 106.7332
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11910,
      "name": "Navegantes",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -26.8989,
        "lng": -48.6539
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5776,
      "name": "Fountainebleau",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 25.7723,
        "lng": -80.3458
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11951,
      "name": "Sao Miguel dos Campos",
      "names": {
        "zh": "圣米格尔DOS坎波斯",
        "ja": "サンミゲルドスカンポス",
        "th": "เซามิเกล Dos Campos",
        "pt": "São Miguel dos Campos"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.7808,
        "lng": -36.0939
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5761,
      "name": "Hunedoara",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 45.7697,
        "lng": 22.9203
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 11897,
      "name": "Tigaon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.6333,
        "lng": 123.5
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5778,
      "name": "La Habra",
      "names": {
        "pt": "Labra"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9278,
        "lng": -117.9513
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11936,
      "name": "Matanao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.75,
        "lng": 125.2333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5692,
      "name": "Taunton",
      "names": {
        "zh": "汤顿",
        "ja": "トーントン",
        "th": "ทอน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.019,
        "lng": -3.1
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5779,
      "name": "Malden",
      "names": {
        "zh": "马尔登"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4305,
        "lng": -71.0576
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5620,
      "name": "Fryazino",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.95,
        "lng": 38.05
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11962,
      "name": "Bensalem",
      "names": {
        "zh": "本萨勒"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.1086,
        "lng": -74.9431
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11960,
      "name": "Sao Gabriel",
      "names": {
        "zh": "圣加布里埃尔",
        "it": "Sao gabriele",
        "ja": "サンガブルエル",
        "th": "เซากาเบรียล"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -30.3358,
        "lng": -54.32
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11949,
      "name": "Matera",
      "names": {
        "zh": "马泰拉",
        "ja": "マテーラ",
        "th": "มาเตรา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6667,
        "lng": 16.6
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 11965,
      "name": "Xo`jayli Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 42.4047,
        "lng": 59.4517
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 5782,
      "name": "South Whittier",
      "names": {
        "es": "Whittier del sur",
        "zh": "南惠蒂尔",
        "de": "Südwhittier",
        "id": "Selatan whittier",
        "ja": "サウスウィッティー",
        "pt": "Whittier sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9336,
        "lng": -118.0311
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11932,
      "name": "Wesel",
      "names": {
        "zh": "韦瑟尔"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6586,
        "lng": 6.6178
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11974,
      "name": "Commerce City",
      "names": {
        "es": "Ciudad de Comercio",
        "fr": "City Commerce",
        "zh": "商业城市",
        "de": "Handelsstadt",
        "ja": "コマースシティ",
        "th": "เมืองพาณิชย์",
        "pt": "Cidade de comércio"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.8642,
        "lng": -104.8434
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11967,
      "name": "Sayanogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.1,
        "lng": 91.4
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11943,
      "name": "Zamora",
      "names": {
        "zh": "萨莫拉",
        "ja": "サモラ",
        "th": "ซาโมร่า"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.5033,
        "lng": -5.7556
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 11939,
      "name": "Gubat",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9167,
        "lng": 124.1167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5724,
      "name": "Leskovac",
      "names": {
        "zh": "莱斯科瓦茨"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 42.9981,
        "lng": 21.9461
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 5717,
      "name": "Wallasey",
      "names": {
        "zh": "沃拉西"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4158,
        "lng": -3.0233
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11911,
      "name": "Rosario",
      "names": {
        "zh": "罗萨里奥",
        "ja": "ロザリオ",
        "th": "โรซาริโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.2333,
        "lng": 120.4833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11973,
      "name": "Esmeraldas",
      "names": {
        "zh": "埃斯梅拉达斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.7628,
        "lng": -44.3139
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11964,
      "name": "Olbia",
      "names": {
        "zh": "奥尔比亚",
        "ja": "オルビア",
        "th": "โอลเบีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.9167,
        "lng": 9.5
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 11959,
      "name": "Belladere",
      "names": {},
      "countryCode": "HT",
      "latLng": {
        "lat": 18.85,
        "lng": -71.7833
      },
      "country": "Haiti",
      "importance": 3
    },
    {
      "id": 11955,
      "name": "Paracale",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2833,
        "lng": 122.7833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11972,
      "name": "Yame",
      "names": {
        "zh": "八女"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.2119,
        "lng": 130.5578
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11895,
      "name": "Hurth",
      "names": {
        "es": "Hürth",
        "zh": "胡尔特",
        "it": "Hürth",
        "id": "Hürth"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8775,
        "lng": 6.8761
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 11979,
      "name": "Belebey",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.1,
        "lng": 54.1333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5701,
      "name": "Legnano",
      "names": {
        "zh": "莱尼亚诺"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5781,
        "lng": 8.9183
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11989,
      "name": "South Hill",
      "names": {
        "es": "Colina sur",
        "zh": "南山",
        "it": "Collina del sud",
        "id": "Bukit selatan",
        "ja": "サウスヒル",
        "pt": "Colina sul"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.1203,
        "lng": -122.2848
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11953,
      "name": "Kumertau",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.7667,
        "lng": 55.7833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 11980,
      "name": "Labinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.6333,
        "lng": 40.7333
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 11984,
      "name": "Camocim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.9019,
        "lng": -40.8408
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11990,
      "name": "San Mariano",
      "names": {
        "zh": "圣马里亚诺",
        "ja": "サンマリアーノ",
        "th": "ซานมาริโน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.9833,
        "lng": 122.0167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11977,
      "name": "Cubulco",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1083,
        "lng": -90.6306
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 5648,
      "name": "Samut Sakhon",
      "names": {
        "ja": "サムッツサコン",
        "th": "สมุทรสาคร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5486,
        "lng": 100.2775
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 11995,
      "name": "Kapchagay",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.8833,
        "lng": 77.0833
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 11956,
      "name": "Yongbei",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.6897,
        "lng": 100.7463
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5543,
      "name": "Jinxing",
      "names": {
        "zh": "金星"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9869,
        "lng": 106.2027
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11918,
      "name": "Malay",
      "names": {
        "zh": "马来",
        "id": "Melayu",
        "ja": "マレー語",
        "th": "มาเลย์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.9,
        "lng": 121.9167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11912,
      "name": "Campo Bom",
      "names": {
        "id": "Capo bom",
        "pt": "Mamadeira"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6789,
        "lng": -51.0528
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11993,
      "name": "Towada",
      "names": {
        "zh": "十和"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 40.6128,
        "lng": 141.2058
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11952,
      "name": "Hashimoto",
      "names": {
        "zh": "桥本",
        "ja": "橋本"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.3167,
        "lng": 135.6
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11981,
      "name": "Nordre Fale",
      "names": {
        "fr": "Fale Nordre",
        "zh": "Nordre Wale",
        "ja": "ノルドレ"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.75,
        "lng": 10.8667
      },
      "country": "Norway",
      "importance": 3
    },
    {
      "id": 11976,
      "name": "Manhica",
      "names": {
        "pt": "Manhiça"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -25.4,
        "lng": 32.8
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 12006,
      "name": "Plymouth",
      "names": {
        "zh": "普利茅斯",
        "ja": "プリマス",
        "th": "พลีมัธ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8783,
        "lng": -70.6309
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5713,
      "name": "Kratie",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 12.4881,
        "lng": 106.0188
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 11905,
      "name": "Nahariyya",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 33.0036,
        "lng": 35.0925
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 11994,
      "name": "Suifenhe",
      "names": {
        "zh": "绥芬河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.3998,
        "lng": 131.1478
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12003,
      "name": "Bayji",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 34.9292,
        "lng": 43.4931
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 12005,
      "name": "Kribi",
      "names": {
        "zh": "克里比"
      },
      "countryCode": "CM",
      "latLng": {
        "lat": 2.95,
        "lng": 9.9167
      },
      "country": "Cameroon",
      "importance": 3
    },
    {
      "id": 12007,
      "name": "Maues",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.3836,
        "lng": -57.7186
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11906,
      "name": "Obando",
      "names": {
        "zh": "奥班多"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.7,
        "lng": 120.9167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12002,
      "name": "San Marcos",
      "names": {
        "zh": "圣马科斯",
        "ja": "サンマルコス",
        "th": "ซานมาร์กอส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.6622,
        "lng": -75.1289
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 11988,
      "name": "Urus-Martan",
      "names": {
        "zh": "乌鲁斯-马尔坦"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.1224,
        "lng": 45.5366
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 11871,
      "name": "Rubizhne",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0167,
        "lng": 38.3667
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 12008,
      "name": "Bristol",
      "names": {
        "zh": "布里斯托尔",
        "ja": "ブリストル",
        "th": "บริสตอล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6812,
        "lng": -72.9407
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11987,
      "name": "Chandralapadu",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.715,
        "lng": 80.2089
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5476,
      "name": "Neuilly-sur-Seine",
      "names": {
        "es": "Neuilly-sur-Sena",
        "zh": "讷塞纳河畔",
        "ja": "neuillyシュルセーヌ",
        "th": "neuilly-ซูร์แม่น้ำเซน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8881,
        "lng": 2.2686
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5646,
      "name": "Loughborough",
      "names": {
        "es": "De Loughborough",
        "zh": "拉夫堡"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.7705,
        "lng": -1.2046
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 11954,
      "name": "Ponnuru",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.0667,
        "lng": 80.5667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 11917,
      "name": "Acerra",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 40.95,
        "lng": 14.3667
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12012,
      "name": "Columbus",
      "names": {
        "zh": "哥伦布",
        "ja": "コロンバス",
        "th": "โคลัมบัส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.2093,
        "lng": -85.9183
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12001,
      "name": "Sigaboy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.65,
        "lng": 126.0667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5792,
      "name": "West Allis",
      "names": {
        "zh": "西Allis",
        "id": "Allis Barat",
        "ja": "西アリス",
        "th": "เวสต์อัลลิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.0068,
        "lng": -88.0296
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5699,
      "name": "Marano di Napoli",
      "names": {
        "ja": "マラノディナポリ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.9,
        "lng": 14.1833
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 11991,
      "name": "Tecpan Guatemala",
      "names": {
        "zh": "Tecpan危地马拉",
        "th": "Tecpan กัวเตมาลา"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7667,
        "lng": -91.0
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 12000,
      "name": "Ilog",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.0333,
        "lng": 122.7667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5706,
      "name": "Nkhotakota",
      "names": {
        "zh": "恩科塔科塔"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -12.9163,
        "lng": 34.3
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 11992,
      "name": "Benevides",
      "names": {
        "zh": "贝内维德斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.3608,
        "lng": -48.245
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 11996,
      "name": "Aranjuez",
      "names": {
        "zh": "阿兰胡埃斯",
        "ja": "アランフェス"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.0333,
        "lng": -3.6028
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5796,
      "name": "Taylorsville",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.657,
        "lng": -111.9493
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5734,
      "name": "Chornomorsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.3017,
        "lng": 30.6569
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 11978,
      "name": "Benevento",
      "names": {
        "zh": "贝内文托"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.1333,
        "lng": 14.7833
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 5785,
      "name": "Kaposvar",
      "names": {
        "fr": "Kaposvár"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.3667,
        "lng": 17.7833
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 11909,
      "name": "Skhirate",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.85,
        "lng": -7.03
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 11935,
      "name": "Dongcun",
      "names": {
        "zh": "东村"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.28,
        "lng": 111.6751
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12017,
      "name": "Bangor",
      "names": {
        "zh": "班戈",
        "ja": "バンゴー",
        "th": "บันกอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.8322,
        "lng": -68.7906
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12004,
      "name": "Lagoa Santa",
      "names": {
        "zh": "Lagoa圣诞老人",
        "ja": "ラゴアサンタ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -19.6269,
        "lng": -43.89
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12011,
      "name": "Ragay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8183,
        "lng": 122.7923
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5719,
      "name": "Khartsyzk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0333,
        "lng": 38.15
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 5794,
      "name": "Uzice",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.85,
        "lng": 19.85
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 11968,
      "name": "Lakhdaria",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.6167,
        "lng": 3.5833
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 11997,
      "name": "Vinukonda",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.05,
        "lng": 79.75
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5630,
      "name": "Agia Paraskevi",
      "names": {
        "ja": "アギアパラスク"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0053,
        "lng": 23.8208
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 11998,
      "name": "Kananya",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1833,
        "lng": 124.5667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12013,
      "name": "Hardenberg",
      "names": {
        "zh": "哈登贝格"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5758,
        "lng": 6.6194
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 5800,
      "name": "Monterey Park",
      "names": {
        "fr": "Parc de Monterey",
        "zh": "蒙特雷公园",
        "id": "Taman Monterey",
        "ja": "モントレー公園",
        "th": "มอนเทอเรย์พาร์ค",
        "pt": "Parque monterey"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0497,
        "lng": -118.1326
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12015,
      "name": "Huajiang",
      "names": {
        "zh": "花江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 25.7491,
        "lng": 105.6063
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 11999,
      "name": "Sanyo-Onoda",
      "names": {
        "zh": "三洋-小野田",
        "ja": "三洋-onoda",
        "th": "ซันโย-onoda"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.0033,
        "lng": 131.1819
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11966,
      "name": "Offenburg",
      "names": {
        "zh": "奥芬堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4708,
        "lng": 7.9408
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 11929,
      "name": "Sennan",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.3658,
        "lng": 135.2736
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12028,
      "name": "Saint-Hyacinthe",
      "names": {
        "zh": "圣 - 亚森特",
        "ja": "サン・hyacinthe",
        "th": "เซนต์ hyacinthe"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.6167,
        "lng": -72.95
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5666,
      "name": "Ouezzane",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 34.8,
        "lng": -5.6
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 11963,
      "name": "Medellin",
      "names": {
        "es": "Medellín",
        "zh": "麦德林",
        "ja": "メデイン",
        "pt": "Medellín"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1286,
        "lng": 123.9622
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5775,
      "name": "Vaxjo",
      "names": {
        "es": "Växjö",
        "fr": "Växjö",
        "zh": "韦克舍",
        "de": "Växjö",
        "pt": "Växjö"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.8837,
        "lng": 14.8167
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 11969,
      "name": "Maimbung",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 5.9333,
        "lng": 121.0333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11982,
      "name": "Lianzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1146,
        "lng": 115.7594
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12030,
      "name": "Goianesia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -15.3169,
        "lng": -49.1169
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5617,
      "name": "Altamira",
      "names": {
        "zh": "阿尔塔米拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.3375,
        "lng": -97.8694
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12022,
      "name": "Palmares",
      "names": {
        "fr": "Palmarès",
        "zh": "帕尔马雷斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.6828,
        "lng": -35.5919
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12018,
      "name": "Lesosibirsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.2333,
        "lng": 92.4833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5518,
      "name": "Givatayim",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0697,
        "lng": 34.8117
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 12041,
      "name": "Sayaxche",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 16.5167,
        "lng": -90.1833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 5793,
      "name": "Itanagar",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.1,
        "lng": 93.62
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 12040,
      "name": "Esfarayen",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.0764,
        "lng": 57.51
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 11958,
      "name": "Molfetta",
      "names": {
        "zh": "莫尔费塔"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.2,
        "lng": 16.6
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12035,
      "name": "Santa Isabel do Para",
      "names": {
        "zh": "圣诞老人isabel做帕拉",
        "ja": "サンタイザベルドパラ",
        "th": "ซานต้าอิซาเบลทำพารา",
        "pt": "Santa Isabel do Pará"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.2989,
        "lng": -48.1608
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12044,
      "name": "Greenwood",
      "names": {
        "zh": "格林伍德",
        "ja": "グリーンウッド",
        "th": "กรีนวูด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.6019,
        "lng": -86.1073
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5760,
      "name": "Ben Tre",
      "names": {
        "zh": "本特雷",
        "ja": "ベントレ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.2333,
        "lng": 106.3833
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 12048,
      "name": "Bartlett",
      "names": {
        "zh": "巴特利特",
        "ja": "バートレット",
        "th": "บาร์ตเลต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.2337,
        "lng": -89.8195
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12049,
      "name": "Bradenton",
      "names": {
        "zh": "布雷登顿",
        "ja": "ブレーデントン",
        "th": "เบรเดนตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.49,
        "lng": -82.5739
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12050,
      "name": "Pontiac",
      "names": {
        "zh": "庞蒂亚克",
        "ja": "ポンティアック",
        "th": "รถปอนเตี๊ยก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.6493,
        "lng": -83.2878
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12045,
      "name": "Lucas do Rio Verde",
      "names": {
        "zh": "卢卡斯做RIO VERDE",
        "th": "ลูคัสทำ rio verde"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -13.05,
        "lng": -55.9108
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12051,
      "name": "Staunton",
      "names": {
        "zh": "斯汤顿",
        "ja": "ストーントン",
        "th": "สทอนตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.1593,
        "lng": -79.0611
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5807,
      "name": "Merida",
      "names": {
        "es": "Mérida",
        "zh": "梅里达",
        "ja": "メリダ",
        "th": "เมริดา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.9,
        "lng": -6.3333
      },
      "country": "Spain",
      "importance": 1
    },
    {
      "id": 11957,
      "name": "Rzhev",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.2656,
        "lng": 34.3276
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12034,
      "name": "Montenegro",
      "names": {
        "fr": "Monténégro",
        "zh": "黑山",
        "ja": "モンテネグロ",
        "th": "มอนเตเนโกร"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6889,
        "lng": -51.4608
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12039,
      "name": "Yara",
      "names": {
        "zh": "亚拉"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.2767,
        "lng": -76.9469
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 11985,
      "name": "Shimotsuke",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3872,
        "lng": 139.8422
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 11944,
      "name": "Druzhkivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6203,
        "lng": 37.5278
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 12058,
      "name": "Meriden",
      "names": {
        "zh": "梅里登",
        "th": "เมริเดน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.5367,
        "lng": -72.7944
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12043,
      "name": "Los Andes",
      "names": {
        "zh": "洛杉矶",
        "ja": "ロスアンデス"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -32.8337,
        "lng": -70.5982
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 11950,
      "name": "Greifswald",
      "names": {
        "zh": "格赖夫斯瓦尔德",
        "ja": "グライフスヴァルト"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.0833,
        "lng": 13.3833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12042,
      "name": "Moron",
      "names": {
        "es": "Morón",
        "fr": "Idiot",
        "zh": "笨蛋",
        "de": "Trottel",
        "id": "Bodoh",
        "ja": "バカ",
        "th": "คนโง่",
        "pt": "Imbecil"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.1108,
        "lng": -78.6278
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 12047,
      "name": "Hua Hin",
      "names": {
        "zh": "华欣",
        "ja": "ホアヒン",
        "th": "หัวหิน",
        "pt": "Hua"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.5686,
        "lng": 99.9578
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 12060,
      "name": "Clay",
      "names": {
        "es": "Arcilla",
        "fr": "Argile",
        "zh": "粘土",
        "de": "Ton",
        "it": "Argille",
        "ja": "粘土",
        "th": "ดิน",
        "pt": "Argila"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.1809,
        "lng": -76.1955
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12009,
      "name": "Comitancillo",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0906,
        "lng": -91.7486
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 12057,
      "name": "Esperanza",
      "names": {
        "zh": "埃斯佩兰萨",
        "ja": "エスペランサ",
        "th": "เอสเปอรัน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.676,
        "lng": 125.6454
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 11938,
      "name": "Koga",
      "names": {
        "zh": "古贺",
        "ja": "古賀"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.7333,
        "lng": 130.4667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12038,
      "name": "Kesennuma",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 38.9083,
        "lng": 141.57
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5741,
      "name": "Ruhengeri",
      "names": {
        "zh": "鲁亨盖里"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -1.4944,
        "lng": 29.6417
      },
      "country": "Rwanda",
      "importance": 3
    },
    {
      "id": 5810,
      "name": "Gardena",
      "names": {
        "zh": "加迪纳"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8944,
        "lng": -118.3073
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12033,
      "name": "Agrigento",
      "names": {
        "fr": "Agrigente",
        "zh": "阿格里真托",
        "ja": "アグリジェント"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.3111,
        "lng": 13.5765
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 12059,
      "name": "Aleksandrov",
      "names": {
        "zh": "亚历山德罗夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.3936,
        "lng": 38.715
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5721,
      "name": "Stryi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.25,
        "lng": 23.85
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 12054,
      "name": "Carazinho",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -28.2839,
        "lng": -52.7858
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12055,
      "name": "El Cua",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.3679,
        "lng": -85.6728
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 11986,
      "name": "Alfonso",
      "names": {
        "zh": "阿方索·",
        "ja": "アルフォンソ",
        "th": "อัลฟอง"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1379,
        "lng": 120.8552
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12020,
      "name": "Zhoujiajing",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.1116,
        "lng": 121.0518
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12064,
      "name": "Apex",
      "names": {
        "es": "Apéndice",
        "zh": "顶尖",
        "it": "Apice",
        "ja": "頂点",
        "th": "ปลาย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.7239,
        "lng": -78.8728
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12066,
      "name": "Royal Oak",
      "names": {
        "es": "Roble real",
        "fr": "Chêne royal",
        "zh": "皇家橡木",
        "de": "Königliche Eiche",
        "it": "Rovere reale",
        "ja": "ロイヤルオーク",
        "th": "ต้นโอ๊ก",
        "pt": "Carvalho real"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5084,
        "lng": -83.1539
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5812,
      "name": "Cupertino",
      "names": {
        "zh": "诺",
        "ja": "クパチーノ",
        "th": "คูเปอร์ติโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3168,
        "lng": -122.0465
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12046,
      "name": "Hyuga",
      "names": {
        "zh": "日向",
        "ja": "ヒュウガ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.4228,
        "lng": 131.6239
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12026,
      "name": "Irosin",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.7,
        "lng": 124.0333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5814,
      "name": "La Mesa",
      "names": {
        "zh": "拉梅萨"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.7703,
        "lng": -117.0204
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12053,
      "name": "Gannan",
      "names": {
        "zh": "赣南"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.9117,
        "lng": 123.4978
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12016,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.65,
        "lng": 124.35
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12023,
      "name": "Joso",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0236,
        "lng": 139.9939
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12072,
      "name": "Benton Harbor",
      "names": {
        "es": "Puerto de Benton",
        "fr": "Benton Harbour",
        "zh": "宾顿港",
        "ja": "ベントンハーバー",
        "th": "Benton Harbour",
        "pt": "Porto de benton"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1159,
        "lng": -86.4488
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 11941,
      "name": "Stralsund",
      "names": {
        "zh": "斯特拉尔松",
        "ja": "シュトラールズント",
        "th": "เนส"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.3092,
        "lng": 13.0819
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5766,
      "name": "Larnaca",
      "names": {
        "zh": "拉纳卡",
        "ja": "ラルナカ",
        "th": "ลาร์นาคา"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 34.9167,
        "lng": 33.6333
      },
      "country": "Cyprus",
      "importance": 1
    },
    {
      "id": 5714,
      "name": "Sabinas",
      "names": {
        "zh": "萨维纳斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.8489,
        "lng": -101.12
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 11983,
      "name": "San Simon",
      "names": {
        "es": "San Simón",
        "zh": "圣西蒙",
        "ja": "サンサイモン",
        "th": "ซานไซมอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.998,
        "lng": 120.78
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5818,
      "name": "Brookline",
      "names": {
        "zh": "布鲁克林"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3243,
        "lng": -71.1408
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5816,
      "name": "Artemisa",
      "names": {
        "zh": "阿特米萨"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8136,
        "lng": -82.7633
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 12031,
      "name": "Alcoy",
      "names": {
        "zh": "阿尔科伊"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.6983,
        "lng": -0.4736
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 11925,
      "name": "Chalkida",
      "names": {
        "it": "Calcide"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.4625,
        "lng": 23.595
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 5820,
      "name": "Viedma",
      "names": {
        "zh": "别德马"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.8,
        "lng": -63.0
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 12067,
      "name": "Mansalay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.5204,
        "lng": 121.4385
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12083,
      "name": "Damghan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.1681,
        "lng": 54.3481
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 12073,
      "name": "Chichibu",
      "names": {
        "zh": "秩父",
        "ja": "秩父"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9917,
        "lng": 139.0856
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5797,
      "name": "Antigua Guatemala",
      "names": {
        "zh": "安提瓜危地马拉",
        "ja": "アンティグアグアテマラ",
        "pt": "Guatemala Antigua"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5567,
        "lng": -90.7337
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 12056,
      "name": "Concepcion Tutuapa",
      "names": {
        "es": "Concepción Tutuapa",
        "ja": "コンセプシオンチュタパ",
        "pt": "Tutuapa de concepcion"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.2833,
        "lng": -91.7833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 5535,
      "name": "Pantin",
      "names": {
        "zh": "庞坦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8966,
        "lng": 2.4017
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12010,
      "name": "Niort",
      "names": {
        "zh": "尼奥尔",
        "ja": "ニオール",
        "th": "นีออร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3258,
        "lng": -0.4606
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 11975,
      "name": "Boadilla del Monte",
      "names": {
        "th": "Baadilla del Monte"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.4069,
        "lng": -3.875
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12082,
      "name": "Constanza",
      "names": {
        "zh": "康斯坦萨"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.91,
        "lng": -70.75
      },
      "country": "Dominican Republic",
      "importance": 3
    },
    {
      "id": 12070,
      "name": "Shirakawa",
      "names": {
        "zh": "白川",
        "ja": "白川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.1264,
        "lng": 140.2108
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12089,
      "name": "Lower Merion",
      "names": {
        "es": "Mérito inferior",
        "fr": "Mérimètre inférieur",
        "zh": "梅里吉利",
        "de": "Niedrigerer Mersion",
        "it": "Minore merita",
        "id": "Merion bawah",
        "ja": "下部メリオン",
        "th": "การถือครองที่ต่ำกว่า",
        "pt": "Mérmino inferior"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.0282,
        "lng": -75.2807
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5819,
      "name": "Yevlax",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.6172,
        "lng": 47.15
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 11961,
      "name": "Mossel Bay",
      "names": {
        "fr": "Baie de Mossel",
        "zh": "Mossel湾",
        "de": "Mossel-Bucht",
        "it": "Baia di Mossel",
        "id": "Teluk Mossel",
        "ja": "モソ湾",
        "th": "อ่าวมอสเซล",
        "pt": "Bela mossel"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -34.1833,
        "lng": 22.1333
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 11940,
      "name": "Belchatow",
      "names": {
        "zh": "贝乌哈图夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.3667,
        "lng": 19.3667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 12063,
      "name": "Tahara",
      "names": {
        "zh": "田原"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.6414,
        "lng": 137.1831
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5825,
      "name": "Kabinda",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -6.1296,
        "lng": 24.48
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 12084,
      "name": "Upi",
      "names": {
        "zh": "乌皮"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.0289,
        "lng": 124.165
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12081,
      "name": "Florida",
      "names": {
        "fr": "Floride",
        "zh": "佛罗里达",
        "ja": "フロリダ州",
        "th": "ฟลอริดา",
        "pt": "Flórida"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.3217,
        "lng": -76.2347
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12090,
      "name": "St. Clair Shores",
      "names": {
        "zh": "圣克莱尔海岸",
        "it": "S. Clair Shores",
        "ja": "セントクレアショアーズ",
        "th": "เซนต์แคลร์ชายฝั่ง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4921,
        "lng": -82.8957
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12080,
      "name": "Pamplona",
      "names": {
        "fr": "Pampelune",
        "zh": "潘普洛纳",
        "ja": "パンプローナ",
        "th": "ปัมโปลนา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.3761,
        "lng": -72.6483
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12092,
      "name": "Barra do Garcas",
      "names": {
        "zh": "巴拉做garcas"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.89,
        "lng": -52.2569
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12078,
      "name": "Kattaqo\u0027rg\u0027on Shahri",
      "names": {
        "zh": "哈哈里·沙赫里"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.8958,
        "lng": 66.2656
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 11971,
      "name": "Langenfeld",
      "names": {
        "zh": "朗根费尔德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1167,
        "lng": 6.95
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12075,
      "name": "Santa Maria Chiquimula",
      "names": {
        "zh": "Santa Maria Chiquiula",
        "ja": "サンタマリアチキミュラ",
        "th": "ซานตามาเรีย chiquimula"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0292,
        "lng": -91.3294
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 11970,
      "name": "San Vicente del Raspeig",
      "names": {
        "de": "San Vicente del Raspäus",
        "ja": "サンビセンテデルラスペヒ"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.3964,
        "lng": -0.5253
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 5822,
      "name": "Cerro de Pasco",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -10.6864,
        "lng": -76.2625
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 12099,
      "name": "Des Plaines",
      "names": {
        "es": "Des llan",
        "de": "Des Ungläubigen",
        "it": "Des Plaundes",
        "id": "Des darm",
        "ja": "デスプレーン",
        "th": "des ธรรมดา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0345,
        "lng": -87.9009
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12088,
      "name": "Xikeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.0505,
        "lng": 116.8538
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12101,
      "name": "Lac-Brome",
      "names": {
        "zh": "拉丁美洲和加勒比-溴",
        "ja": "LACは、ブロム",
        "th": "ครั่ง-บร็อมเม่"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.2167,
        "lng": -72.5167
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 12095,
      "name": "Gattaran",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 18.0667,
        "lng": 121.65
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12019,
      "name": "Tezpur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.6338,
        "lng": 92.8
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12036,
      "name": "Roxas",
      "names": {
        "zh": "罗哈斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.5833,
        "lng": 121.5
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5715,
      "name": "Ban Rangsit",
      "names": {
        "fr": "Interdiction",
        "zh": "禁止rangsit",
        "it": "Banksit di Ban",
        "ja": "禁止rangsit",
        "th": "บ้านรังสิต"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.0167,
        "lng": 100.75
      },
      "country": "Thailand",
      "importance": 3
    },
    {
      "id": 5747,
      "name": "Chambery",
      "names": {
        "es": "Chambéry",
        "fr": "Chambéry",
        "zh": "尚贝里",
        "id": "Chambéry"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.57,
        "lng": 5.9118
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12079,
      "name": "Canlaon",
      "names": {
        "zh": "坎拉翁"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3667,
        "lng": 123.2
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5651,
      "name": "Sarcelles",
      "names": {
        "zh": "萨塞勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9956,
        "lng": 2.3808
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12100,
      "name": "Jarabacoa",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 19.1197,
        "lng": -70.6383
      },
      "country": "Dominican Republic",
      "importance": 3
    },
    {
      "id": 12105,
      "name": "Lewiston",
      "names": {
        "zh": "刘易斯顿",
        "ja": "ルイストン",
        "th": "ลูอิสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.0915,
        "lng": -70.1681
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12086,
      "name": "Faenza",
      "names": {
        "zh": "法恩莎"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.2856,
        "lng": 11.8833
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 5830,
      "name": "Margate",
      "names": {
        "zh": "马盖特",
        "ja": "マーゲート"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.2466,
        "lng": -80.2119
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5685,
      "name": "Manouba",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8,
        "lng": 10.1
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 12109,
      "name": "Midland",
      "names": {
        "zh": "米德兰",
        "ja": "ミッドランド",
        "th": "มิดแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.6241,
        "lng": -84.2319
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12062,
      "name": "El Salvador",
      "names": {
        "zh": "萨尔瓦多",
        "ja": "エルサルバドール",
        "th": "เอลซัลวาดอร์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.5667,
        "lng": 124.5167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12027,
      "name": "Narasapur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.4333,
        "lng": 81.6833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5834,
      "name": "Carson City",
      "names": {
        "es": "Carrera de Carson",
        "zh": "卡森市",
        "ja": "カーソンシティ",
        "th": "เมืองคาร์สัน",
        "pt": "Cidade de carson"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1512,
        "lng": -119.7474
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 12068,
      "name": "Chikuma",
      "names": {
        "zh": "筑摩"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.5307,
        "lng": 138.1149
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5829,
      "name": "Krusevac",
      "names": {
        "zh": "克鲁舍瓦茨"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.5833,
        "lng": 21.3267
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 12097,
      "name": "Pedro Leopoldo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -19.6178,
        "lng": -44.0428
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12069,
      "name": "Calatagan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8322,
        "lng": 120.6322
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12111,
      "name": "Baganga",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.5752,
        "lng": 126.5585
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12052,
      "name": "Neu-Ulm",
      "names": {
        "zh": "神经乌尔姆",
        "ja": "ノイ-ウルム",
        "th": "นัก-ulm"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.3833,
        "lng": 10.0
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12094,
      "name": "Sandnes",
      "names": {
        "th": "แซนด์เนส"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.85,
        "lng": 5.7333
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 12114,
      "name": "Izberbash",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 42.5667,
        "lng": 47.8667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12106,
      "name": "Jovellanos",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8106,
        "lng": -81.1981
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 5836,
      "name": "Dosso",
      "names": {
        "zh": "多索"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 13.05,
        "lng": 3.2
      },
      "country": "Niger",
      "importance": 1
    },
    {
      "id": 12108,
      "name": "Bezerros",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.2333,
        "lng": -35.75
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5755,
      "name": "Koja",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 26.3344,
        "lng": 127.8057
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12024,
      "name": "Pasrur",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 32.2681,
        "lng": 74.6675
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 12117,
      "name": "Bowie",
      "names": {
        "zh": "鲍伊",
        "ja": "ボウイ",
        "th": "โบวี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9569,
        "lng": -76.7409
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12065,
      "name": "Unna",
      "names": {
        "zh": "翁纳"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5347,
        "lng": 7.6889
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12104,
      "name": "Wuyang",
      "names": {
        "zh": "五羊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.057,
        "lng": 108.3959
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5842,
      "name": "Port Coquitlam",
      "names": {
        "es": "Puerto coquitlam",
        "zh": "港口崇拜",
        "de": "Port-Zusammenarbeit",
        "it": "Porto Coquitlam",
        "ja": "ポートコキトラム",
        "th": "พอร์ตโคควิทแลม",
        "pt": "Coquitlam"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 49.2625,
        "lng": -122.7811
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5736,
      "name": "Lytkarino",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.5833,
        "lng": 37.8833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12123,
      "name": "Aketi",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": 2.7405,
        "lng": 23.78
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 5835,
      "name": "Tenkodogo",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 11.7833,
        "lng": -0.3667
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 5702,
      "name": "Osakasayama",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5036,
        "lng": 135.5556
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5845,
      "name": "Middletown",
      "names": {
        "zh": "米德尔敦",
        "ja": "ミドルタウン",
        "th": "มิดเดิลทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.4459,
        "lng": -74.4236
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12032,
      "name": "San Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "ซานหลุยส์"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.04,
        "lng": 120.7919
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12085,
      "name": "Motril",
      "names": {
        "zh": "莫特里尔"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 36.75,
        "lng": -3.5167
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12118,
      "name": "Cerignola",
      "names": {
        "zh": "切里尼奥拉"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.2667,
        "lng": 15.9
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12113,
      "name": "Surubim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.8319,
        "lng": -35.7558
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12061,
      "name": "Ibaan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8176,
        "lng": 121.133
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12091,
      "name": "Kelibia",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8475,
        "lng": 11.0939
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 12135,
      "name": "Caldwell",
      "names": {
        "zh": "考德威尔",
        "ja": "コールドウェル",
        "th": "เวลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.6453,
        "lng": -116.6591
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12139,
      "name": "San Rafael",
      "names": {
        "zh": "圣拉斐尔",
        "ja": "サンラファエル",
        "th": "ซานราฟาเอล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.9905,
        "lng": -122.5222
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12138,
      "name": "Ma\u0027erkang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.9046,
        "lng": 102.2167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12074,
      "name": "Tianningcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.8938,
        "lng": 120.8009
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12136,
      "name": "San Pedro",
      "names": {
        "zh": "圣佩德罗",
        "ja": "サンペドロ",
        "th": "ซานเปโดร"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.2196,
        "lng": -64.87
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 12120,
      "name": "Qaskeleng",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 43.1983,
        "lng": 76.6311
      },
      "country": "Kazakhstan",
      "importance": 3
    },
    {
      "id": 12141,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -34.5839,
        "lng": -70.9891
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 5693,
      "name": "Littlehampton",
      "names": {
        "zh": "利特尔汉普顿"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.8094,
        "lng": -0.5409
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12076,
      "name": "Zengcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.2451,
        "lng": 114.7367
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12145,
      "name": "Jiantang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.8205,
        "lng": 99.7043
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12151,
      "name": "Nyagan",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 62.1333,
        "lng": 65.3833
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12029,
      "name": "Ogori",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.3964,
        "lng": 130.5556
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5762,
      "name": "Limerick",
      "names": {
        "zh": "利默里克",
        "ja": "リメリック",
        "th": "โคลง"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.6653,
        "lng": -8.6238
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 12147,
      "name": "Punta Alta",
      "names": {
        "zh": "蓬塔阿尔塔",
        "ja": "プンタアルタ",
        "th": "ปุนตาอัลตา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.88,
        "lng": -62.075
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 12112,
      "name": "Palompon",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.05,
        "lng": 124.3833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12115,
      "name": "Sucun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 31.0554,
        "lng": 118.1057
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5847,
      "name": "Bondoukou",
      "names": {
        "zh": "邦杜库"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 8.0304,
        "lng": -2.8
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 5853,
      "name": "Union",
      "names": {
        "es": "Unión",
        "zh": "联盟",
        "it": "Unione",
        "id": "Persatuan",
        "ja": "連合",
        "th": "สหภาพ",
        "pt": "União"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6953,
        "lng": -74.2697
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12144,
      "name": "Serres",
      "names": {
        "zh": "塞尔"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 41.0833,
        "lng": 23.55
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 12146,
      "name": "Ishikari",
      "names": {
        "zh": "石狩"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 43.1783,
        "lng": 141.3067
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12129,
      "name": "Lambayeque",
      "names": {
        "zh": "兰巴耶克"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -6.7,
        "lng": -79.9
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 12149,
      "name": "La Gomera",
      "names": {
        "ja": "ラゴメラ",
        "th": "ลาเกเมอร์"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.0833,
        "lng": -91.05
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 12157,
      "name": "Hamburg",
      "names": {
        "es": "Hamburgo",
        "fr": "Hambourg",
        "zh": "汉堡",
        "it": "Amburgo",
        "ja": "ハンブルク",
        "th": "ฮัมบูร์ก",
        "pt": "Hamburgo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.7394,
        "lng": -78.8581
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12093,
      "name": "Rass el Djebel",
      "names": {
        "fr": "Rasse El Djebel"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 37.215,
        "lng": 10.12
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 12025,
      "name": "Mouscron",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.7444,
        "lng": 3.2156
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 12137,
      "name": "Date",
      "names": {
        "es": "Fecha",
        "zh": "约会",
        "de": "Datum",
        "it": "Data",
        "id": "Tanggal",
        "ja": "日付",
        "th": "วันที่",
        "pt": "Encontro"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.8189,
        "lng": 140.5631
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12158,
      "name": "Richland",
      "names": {
        "zh": "丽晶",
        "ja": "リッチランド",
        "th": "แลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.2824,
        "lng": -119.2938
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12159,
      "name": "Fredericton",
      "names": {
        "zh": "弗雷德里克顿",
        "th": "เฟรดริกตัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.9636,
        "lng": -66.6431
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 12161,
      "name": "St. Peters",
      "names": {
        "es": "San Pedro",
        "zh": "圣彼得斯",
        "it": "San Pietro",
        "ja": "サンクトペター",
        "th": "เซนต์ปีเตอร์ส",
        "pt": "São Peters"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.7824,
        "lng": -90.6061
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12162,
      "name": "Wesley Chapel",
      "names": {
        "es": "Capilla de Wesley",
        "fr": "Chapelle Wesley",
        "zh": "韦斯利教堂",
        "de": "Wesley-Kapelle",
        "it": "Cappella di Wesley",
        "id": "Kapel Wesley",
        "ja": "ウェズリーチャペル",
        "pt": "Capela de Wesley"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.2106,
        "lng": -82.3238
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12148,
      "name": "Biguacu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -27.4939,
        "lng": -48.6558
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5837,
      "name": "Naval",
      "names": {
        "fr": "Navale",
        "zh": "海军",
        "it": "Navale",
        "ja": "海軍",
        "th": "กองทัพเรือ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.5833,
        "lng": 124.45
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 12107,
      "name": "Barotac Nuevo",
      "names": {
        "fr": "Barottac nuevo",
        "zh": "巴利克·诺沃"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.9,
        "lng": 122.7
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12134,
      "name": "Guarabira",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -6.855,
        "lng": -35.49
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12154,
      "name": "Samana",
      "names": {
        "zh": "萨马纳",
        "ja": "サマナ川"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.2053,
        "lng": -69.3364
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 5843,
      "name": "Veszprem",
      "names": {
        "fr": "Veszprém",
        "zh": "维斯普林",
        "de": "Veszprém"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1,
        "lng": 17.9167
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 12169,
      "name": "Tikhvin",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 59.6442,
        "lng": 33.5422
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12071,
      "name": "Halesowen",
      "names": {
        "zh": "黑尔斯欧文"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4502,
        "lng": -2.0509
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12143,
      "name": "Kokawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2697,
        "lng": 135.3625
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12122,
      "name": "Kitahiroshima",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 42.9857,
        "lng": 141.5636
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12014,
      "name": "Shuya",
      "names": {
        "zh": "舒亚"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 56.85,
        "lng": 41.3667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12176,
      "name": "Hendersonville",
      "names": {
        "ja": "ヘンダーソンヴィル"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3063,
        "lng": -86.5998
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5856,
      "name": "White Plains",
      "names": {
        "es": "Llanuras blancas",
        "fr": "Plaines blanches",
        "zh": "白皮",
        "de": "Weiße Ebenen",
        "it": "Pianure bianche",
        "id": "Dataran putih",
        "ja": "白い平野",
        "th": "ที่ราบสีขาว",
        "pt": "Planícies brancas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.022,
        "lng": -73.7549
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12177,
      "name": "Huntersville",
      "names": {
        "ja": "ハンターズビル",
        "th": "ฮันเตอร์สวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.4058,
        "lng": -80.8727
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12180,
      "name": "Southampton",
      "names": {
        "zh": "南安普敦",
        "ja": "サザンプトン",
        "th": "เซาแธมป์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8996,
        "lng": -72.4937
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12182,
      "name": "Santee",
      "names": {
        "zh": "桑蒂",
        "th": "แซนที"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8554,
        "lng": -116.9851
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12126,
      "name": "Felgueiras",
      "names": {
        "zh": "费尔盖拉什"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3667,
        "lng": -8.2
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 12102,
      "name": "Timargara",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 34.8281,
        "lng": 71.8408
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 5859,
      "name": "Jefferson City",
      "names": {
        "es": "Ciudad de Jefferson",
        "zh": "杰斐逊城",
        "id": "Kota Jefferson",
        "ja": "ジェファーソンシティ",
        "th": "เจฟเฟอร์สันซิตี้"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.5676,
        "lng": -92.1759
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 12021,
      "name": "Chibuto",
      "names": {},
      "countryCode": "MZ",
      "latLng": {
        "lat": -24.6867,
        "lng": 33.5306
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 12184,
      "name": "Meleuz",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.9647,
        "lng": 55.9328
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5852,
      "name": "Bekescsaba",
      "names": {
        "de": "Békéscsaba",
        "it": "Békéscsaba"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6833,
        "lng": 21.0833
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 5857,
      "name": "Huehuetenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3147,
        "lng": -91.4761
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 12077,
      "name": "Masantol",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.9,
        "lng": 120.7167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12173,
      "name": "Pradera",
      "names": {
        "zh": "普拉德拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.4189,
        "lng": -76.2439
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12174,
      "name": "Gaspar",
      "names": {
        "zh": "加斯帕"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.9308,
        "lng": -48.9589
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12131,
      "name": "Sunshi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7631,
        "lng": 116.4876
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12142,
      "name": "Euskirchen",
      "names": {
        "zh": "奥伊斯基兴"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6613,
        "lng": 6.7873
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5621,
      "name": "Acambaro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0361,
        "lng": -100.7314
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12153,
      "name": "Barneveld",
      "names": {
        "zh": "巴讷费尔德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1333,
        "lng": 5.5833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12164,
      "name": "Avila",
      "names": {
        "es": "Ávila",
        "zh": "阿维拉",
        "ja": "アビラ",
        "pt": "Ávila"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.65,
        "lng": -4.6833
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 12193,
      "name": "Beckley",
      "names": {
        "zh": "贝克利",
        "ja": "ベックリー",
        "th": "เบกลีย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.7878,
        "lng": -81.1841
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5862,
      "name": "Arcadia",
      "names": {
        "zh": "阿卡迪亚",
        "ja": "アルカディア",
        "th": "อาร์เคเดีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1342,
        "lng": -118.0373
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12096,
      "name": "Panskura",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.42,
        "lng": 87.7
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12172,
      "name": "New Corella",
      "names": {
        "fr": "Corella",
        "zh": "新的科特拉",
        "de": "Neue Corella",
        "it": "Nuova Corella",
        "id": "Corella baru",
        "ja": "ニューコロラ",
        "th": "ใหม่ Corella",
        "pt": "Nova corelha"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.5866,
        "lng": 125.8237
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12125,
      "name": "Cogan",
      "names": {
        "zh": "科甘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.5833,
        "lng": 123.9667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12187,
      "name": "Allanmyo",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 19.3783,
        "lng": 95.2279
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 12189,
      "name": "Aleksin",
      "names": {
        "zh": "阿列克辛"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.5,
        "lng": 37.0667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12190,
      "name": "Berezovskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.9,
        "lng": 60.8
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12196,
      "name": "Titusville",
      "names": {
        "zh": "泰特斯维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.5727,
        "lng": -80.8193
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12037,
      "name": "Prokhladnyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.7575,
        "lng": 44.0297
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 12198,
      "name": "Cartersville",
      "names": {
        "zh": "卡特斯维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1639,
        "lng": -84.8007
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12200,
      "name": "Walla Walla",
      "names": {
        "th": "วาลลาวาลลา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.067,
        "lng": -118.3366
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12179,
      "name": "Mirassol",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.8189,
        "lng": -49.5208
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12201,
      "name": "Orland Park",
      "names": {
        "es": "Parque de orland",
        "fr": "Parc orland",
        "zh": "奥兰多公园",
        "de": "Orland-Park",
        "it": "Parco orland",
        "id": "Taman orland",
        "ja": "オーランドパーク",
        "th": "ออร์แลนด์พาร์ค"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6074,
        "lng": -87.8619
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12186,
      "name": "Antonio Enes",
      "names": {
        "th": "อันโตนิโอ"
      },
      "countryCode": "MZ",
      "latLng": {
        "lat": -16.2308,
        "lng": 39.9105
      },
      "country": "Mozambique",
      "importance": 3
    },
    {
      "id": 12124,
      "name": "Rampur Hat",
      "names": {
        "es": "Sombrero de rampur",
        "fr": "Chapeau de rampeur",
        "zh": "rampur帽子",
        "de": "Rampur-Hut",
        "it": "Cappello di rampura",
        "id": "Topi rampung",
        "ja": "ランペュールハット",
        "th": "หมวก rampur",
        "pt": "Boné"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 24.17,
        "lng": 87.78
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12110,
      "name": "Mikhaylovka",
      "names": {
        "zh": "米哈伊洛夫卡"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.0667,
        "lng": 43.2333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12132,
      "name": "Dumanjog",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.05,
        "lng": 123.4833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12206,
      "name": "Towson",
      "names": {
        "zh": "陶森"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.3944,
        "lng": -76.619
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12195,
      "name": "Alexandroupoli",
      "names": {
        "it": "Alessandropoli"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.85,
        "lng": 25.8667
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 12116,
      "name": "Asingan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0023,
        "lng": 120.6695
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12209,
      "name": "Manchester",
      "names": {
        "zh": "曼彻斯特",
        "ja": "マンチェスター",
        "th": "แมนเชสเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7753,
        "lng": -72.5242
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5789,
      "name": "Sidi Yahya Zaer",
      "names": {
        "zh": "西迪yahya zaer"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 33.7105,
        "lng": -6.8831
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 5752,
      "name": "Suileng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 47.246,
        "lng": 127.106
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12208,
      "name": "Orito",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 0.6661,
        "lng": -76.8708
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12181,
      "name": "Xiezhou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.9124,
        "lng": 110.8517
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5768,
      "name": "Royal Tunbridge Wells",
      "names": {
        "zh": "皇家Tunbridge Wells",
        "ja": "ロイヤルタンブリッジウェルズ",
        "th": "รอยัลทูนบริดจ์เวลส์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.132,
        "lng": 0.263
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12205,
      "name": "Tikhoretsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.85,
        "lng": 40.1167
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 5781,
      "name": "Swidnica",
      "names": {
        "ja": "シュフィドニツァ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.8406,
        "lng": 16.4925
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 12211,
      "name": "Qunghirot",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 43.0704,
        "lng": 58.9
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 12213,
      "name": "Weymouth",
      "names": {
        "zh": "韦茅斯",
        "ja": "ウェイマス"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1984,
        "lng": -70.9466
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12197,
      "name": "Tarsus",
      "names": {
        "es": "Tarso",
        "fr": "Tarse",
        "zh": "睑板",
        "it": "Tarso",
        "ja": "足根",
        "th": "เท้า",
        "pt": "Tarso"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.9167,
        "lng": 34.9
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 5733,
      "name": "Linares",
      "names": {
        "zh": "利纳雷斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.8604,
        "lng": -99.57
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12220,
      "name": "Parker",
      "names": {
        "zh": "帕克",
        "ja": "パーカー",
        "th": "ปาร์กเกอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.5084,
        "lng": -104.7753
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12221,
      "name": "Palm Beach Gardens",
      "names": {
        "es": "Jardines de Palm Beach",
        "fr": "Jardins de palme",
        "zh": "棕榈滩花园",
        "de": "Palm Beach Gärten",
        "id": "Taman Palm Beach",
        "ja": "パームビーチガーデンズ",
        "th": "สวนปาล์มบีช"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 26.8466,
        "lng": -80.1679
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12171,
      "name": "Lonavale",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.7481,
        "lng": 73.4072
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12223,
      "name": "Bozeman",
      "names": {
        "zh": "博兹曼",
        "ja": "ボーズマン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.6832,
        "lng": -111.0552
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12219,
      "name": "Floriano",
      "names": {
        "zh": "弗洛里亚努"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.7669,
        "lng": -43.0228
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12150,
      "name": "Kosai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.7186,
        "lng": 137.5317
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12216,
      "name": "Ibitinga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.7578,
        "lng": -48.8289
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12204,
      "name": "Ciudad Cuauhtemoc",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.1833,
        "lng": -97.8333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12217,
      "name": "Pavlovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.9619,
        "lng": 43.09
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12225,
      "name": "Salsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.4833,
        "lng": 41.5333
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 12165,
      "name": "Gengzhuangqiao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.4453,
        "lng": 114.9804
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12119,
      "name": "Yanguancun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.4541,
        "lng": 120.5545
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12203,
      "name": "Kothapet",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.3333,
        "lng": 79.4833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12228,
      "name": "East Stroudsburg",
      "names": {
        "zh": "东斯特鲁兹堡",
        "id": "Stroudsburg Timur",
        "ja": "イーストストアバーグ",
        "th": "อีสต์สบูร์ก",
        "pt": "Stroudsburg do leste"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.0023,
        "lng": -75.1779
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12163,
      "name": "Togane",
      "names": {
        "zh": "东金"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.56,
        "lng": 140.3661
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12130,
      "name": "Goppingen",
      "names": {
        "es": "Göppingen",
        "fr": "Göppingen",
        "de": "Göppingen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7025,
        "lng": 9.6528
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12156,
      "name": "Doetinchem",
      "names": {
        "zh": "杜廷赫姆"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9667,
        "lng": 6.2833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12215,
      "name": "Campo Alegre",
      "names": {
        "ja": "カンポアレグレ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.7819,
        "lng": -36.3508
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12226,
      "name": "Milagros",
      "names": {
        "zh": "米拉格罗斯",
        "ja": "ミラグロス"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.2333,
        "lng": 123.5
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12160,
      "name": "Choshi",
      "names": {
        "ja": "銚子"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7347,
        "lng": 140.8267
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12202,
      "name": "Santa Maria",
      "names": {
        "zh": "圣玛丽亚",
        "ja": "サンタマリア",
        "th": "ซานตามาเรีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.55,
        "lng": 125.4667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12222,
      "name": "Krasnotur\u0027insk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 59.7733,
        "lng": 60.1853
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12224,
      "name": "Diriamba",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 11.85,
        "lng": -86.2333
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 12183,
      "name": "Hameln",
      "names": {
        "zh": "哈默尔恩",
        "ja": "ハーメルン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1031,
        "lng": 9.36
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5873,
      "name": "Huntington Park",
      "names": {
        "fr": "Parc de Huntington",
        "zh": "亨廷顿公园",
        "id": "Taman Huntington",
        "ja": "ハンチントン公園",
        "th": "ฮันติงตันพาร์ค",
        "pt": "Parque Huntington"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.98,
        "lng": -118.2167
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12128,
      "name": "Tokoname",
      "names": {
        "zh": "常滑"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8833,
        "lng": 136.8333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12127,
      "name": "Ejmiatsin",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.1728,
        "lng": 44.2925
      },
      "country": "Armenia",
      "importance": 3
    },
    {
      "id": 12207,
      "name": "Biala Podlaska",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.0333,
        "lng": 23.1167
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 12231,
      "name": "Santiago Tuxtla",
      "names": {
        "zh": "圣地亚哥Tuxtla"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.4654,
        "lng": -95.3
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12237,
      "name": "Taunton",
      "names": {
        "zh": "汤顿",
        "ja": "トーントン",
        "th": "ทอน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.9036,
        "lng": -71.0943
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12166,
      "name": "Tanauan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1167,
        "lng": 125.0167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12241,
      "name": "Midwest City",
      "names": {
        "es": "Ciudad Midwest",
        "fr": "Ville de Midwest",
        "zh": "中西部城市",
        "de": "Midwest-Stadt",
        "id": "Kota Midwest",
        "ja": "中西部の街",
        "th": "เมืองมิดเวสต์",
        "pt": "Cidade midwest"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.463,
        "lng": -97.3709
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12236,
      "name": "Buluan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.7154,
        "lng": 124.7854
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12238,
      "name": "Istaravshan",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 39.9108,
        "lng": 69.0064
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 12234,
      "name": "Ponte Nova",
      "names": {
        "ja": "ポンテノーカ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.4158,
        "lng": -42.9089
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12155,
      "name": "Huaniu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.5658,
        "lng": 105.8215
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12194,
      "name": "Qianwu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.1635,
        "lng": 113.2217
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5877,
      "name": "Medford",
      "names": {
        "zh": "梅德福",
        "ja": "メドフォード",
        "th": "เมดฟอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4234,
        "lng": -71.1087
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5737,
      "name": "Bebington",
      "names": {
        "zh": "贝宾顿"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.35,
        "lng": -3.003
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12175,
      "name": "Roermond",
      "names": {
        "zh": "鲁尔蒙德",
        "ja": "ルールモント"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.1933,
        "lng": 5.9872
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12247,
      "name": "Coruripe",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -10.1258,
        "lng": -36.1758
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12214,
      "name": "Macherla",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.48,
        "lng": 79.43
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5871,
      "name": "Boac",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.45,
        "lng": 121.8333
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 12255,
      "name": "Goldsboro",
      "names": {
        "zh": "戈尔兹伯勒",
        "th": "โกลด์สโบโร"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.3778,
        "lng": -77.972
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12253,
      "name": "Andradina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.8958,
        "lng": -51.3789
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12235,
      "name": "Icara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -28.7128,
        "lng": -49.3
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12218,
      "name": "Kandukur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 15.2165,
        "lng": 79.9042
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12140,
      "name": "Moncalieri",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 45.0,
        "lng": 7.6833
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12252,
      "name": "Krymsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.9333,
        "lng": 38.0
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 12248,
      "name": "Riosucio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.4386,
        "lng": -77.1133
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12121,
      "name": "Aldershot",
      "names": {
        "zh": "奥尔德肖特",
        "th": "ชอท"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.248,
        "lng": -0.758
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12087,
      "name": "Bilhorod-Dnistrovskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.1833,
        "lng": 30.3333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 12258,
      "name": "Euless",
      "names": {
        "th": "ยูเลส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.8508,
        "lng": -97.0799
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12230,
      "name": "Alangalang",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.2,
        "lng": 124.85
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5801,
      "name": "Samannud",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 30.9622,
        "lng": 31.2425
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 5881,
      "name": "Belize City",
      "names": {
        "es": "Ciudad de Belice",
        "zh": "伯利兹城市",
        "de": "Belize-Stadt",
        "id": "Kota",
        "ja": "ベリーズ市",
        "th": "เมืองเบลีซ",
        "pt": "Cidade de beleza"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 17.4986,
        "lng": -88.1886
      },
      "country": "Belize",
      "importance": 1
    },
    {
      "id": 12233,
      "name": "Uson",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.2253,
        "lng": 123.7834
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12103,
      "name": "Boufarik",
      "names": {
        "zh": "布法里克"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.575,
        "lng": 2.9097
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 12098,
      "name": "Melchor Ocampo",
      "names": {
        "zh": "梅尔皮奥郡",
        "ja": "メルカーコオカンポ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7083,
        "lng": -99.1444
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12191,
      "name": "Ping\u0027an",
      "names": {
        "zh": "平安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.502,
        "lng": 102.1082
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5688,
      "name": "Le Blanc-Mesnil",
      "names": {
        "th": "เลอแบลแคลส์เมสมันล"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9387,
        "lng": 2.4614
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5677,
      "name": "Cosamaloapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3667,
        "lng": -95.8
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5870,
      "name": "Al Mafraq",
      "names": {
        "ja": "アルマフラック"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 32.2833,
        "lng": 36.2333
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 12170,
      "name": "Benslimane",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.6122,
        "lng": -7.1211
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 12243,
      "name": "Uki",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 32.6478,
        "lng": 130.6843
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5756,
      "name": "Nonoichi",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 36.5194,
        "lng": 136.6097
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5784,
      "name": "Lorient",
      "names": {
        "zh": "洛里昂",
        "ja": "ロリアン",
        "th": "ลอเรียง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.75,
        "lng": -3.36
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 5884,
      "name": "Maun",
      "names": {
        "zh": "马翁",
        "ja": "マウン"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -19.9833,
        "lng": 23.4167
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 5875,
      "name": "Mafeteng",
      "names": {
        "zh": "马费滕"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -29.8167,
        "lng": 27.25
      },
      "country": "Lesotho",
      "importance": 1
    },
    {
      "id": 12210,
      "name": "Jasaan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.65,
        "lng": 124.75
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12168,
      "name": "Pula",
      "names": {
        "zh": "普拉",
        "ja": "プーラ",
        "th": "พูลา"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 44.8667,
        "lng": 13.8333
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 12270,
      "name": "Manhattan",
      "names": {
        "zh": "曼哈顿",
        "ja": "マンハッタン",
        "th": "แมนฮัตตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1886,
        "lng": -96.6046
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12269,
      "name": "General Pico",
      "names": {
        "fr": "Général pico",
        "zh": "普通的普通",
        "de": "Allgemeines Pico",
        "it": "Generale Pico",
        "id": "Pico umum",
        "ja": "一般ピコ",
        "th": "ทั่วไปปิโก"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -35.6587,
        "lng": -63.7577
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 5883,
      "name": "Vratsa",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.2121,
        "lng": 23.5444
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 12271,
      "name": "Shoreline",
      "names": {
        "es": "Costa",
        "fr": "Littoral",
        "zh": "海岸线",
        "de": "Küste",
        "id": "Garis pantai",
        "ja": "海岸線",
        "th": "ชายฝั่ง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.7564,
        "lng": -122.3426
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12239,
      "name": "Tonsberg",
      "names": {
        "zh": "滕斯贝格"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.2981,
        "lng": 10.4236
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 12242,
      "name": "Frankfurt (Oder)",
      "names": {
        "fr": "Francfort (Oder)",
        "zh": "法兰克福（奥德）",
        "it": "Francoforte (oder)",
        "ja": "フランクフルト（ODER）",
        "th": "แฟรงค์เฟิร์ต (ODER)"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.3419,
        "lng": 14.5517
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12273,
      "name": "Neryungri",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.6583,
        "lng": 124.725
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12212,
      "name": "Minokamo",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.4333,
        "lng": 137.0167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12257,
      "name": "Foligno",
      "names": {
        "zh": "福利尼奥",
        "ja": "フォリーニョ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.9561,
        "lng": 12.7033
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 5786,
      "name": "Zushi",
      "names": {
        "zh": "逗子"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.2948,
        "lng": 139.5781
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12260,
      "name": "Talagante",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -33.6667,
        "lng": -70.9333
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 12265,
      "name": "Ribnita",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.7664,
        "lng": 29.0006
      },
      "country": "Moldova",
      "importance": 3
    },
    {
      "id": 5803,
      "name": "Rodos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 36.4412,
        "lng": 28.2225
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 12280,
      "name": "Jackson",
      "names": {
        "zh": "杰克逊",
        "ja": "ジャクソン",
        "th": "แจ็คสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.098,
        "lng": -74.3579
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12279,
      "name": "Cuemba",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.15,
        "lng": 18.0833
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 5783,
      "name": "Tezonapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6058,
        "lng": -96.6875
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5889,
      "name": "Ushuaia",
      "names": {
        "zh": "乌斯怀亚",
        "ja": "ウシュアイア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -54.8072,
        "lng": -68.3044
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 5770,
      "name": "Ban Bang Kaeo",
      "names": {
        "es": "BANG BAG KAEO",
        "zh": "禁止砰砰声",
        "it": "Bang Bang Kaeo",
        "th": "บ้านบางแก้ว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6371,
        "lng": 100.6636
      },
      "country": "Thailand",
      "importance": 3
    },
    {
      "id": 12278,
      "name": "Puerto Boyaca",
      "names": {
        "zh": "波多黎各博纳卡",
        "ja": "プエルトボヤカ",
        "th": "เปอร์โตบอยคา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9761,
        "lng": -74.5875
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12256,
      "name": "Azzaba",
      "names": {
        "zh": "阿扎巴"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7333,
        "lng": 7.1
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 12240,
      "name": "Hagonoy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.6833,
        "lng": 125.3
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12267,
      "name": "Manfredonia",
      "names": {
        "zh": "曼弗雷多尼亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.6333,
        "lng": 15.9167
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 5846,
      "name": "Kitgum",
      "names": {
        "zh": "基特古姆"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.3004,
        "lng": 32.87
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 12286,
      "name": "Piscataway",
      "names": {
        "ja": "ピスカタウェイ",
        "th": "พิสแคต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5467,
        "lng": -74.4636
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12188,
      "name": "Samalkot",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.0531,
        "lng": 82.1695
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12290,
      "name": "Lake Havasu City",
      "names": {
        "es": "Ciudad del lago Havasu",
        "zh": "哈瓦苏湖城",
        "id": "Kota Danau Havasu",
        "ja": "レイクハバスシティ",
        "th": "เมืองทะเลสาบ Havasu",
        "pt": "Cidade do Lago Havasu"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.5006,
        "lng": -114.3113
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12250,
      "name": "Bobbili",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.5729,
        "lng": 83.3587
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12251,
      "name": "Irpin",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.5167,
        "lng": 30.25
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 5876,
      "name": "Chinhoyi",
      "names": {},
      "countryCode": "ZW",
      "latLng": {
        "lat": -17.35,
        "lng": 30.2
      },
      "country": "Zimbabwe",
      "importance": 1
    },
    {
      "id": 12245,
      "name": "Stolberg",
      "names": {
        "zh": "施托尔贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.7667,
        "lng": 6.2333
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12292,
      "name": "Jacunda",
      "names": {
        "zh": "雅昆达"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.4508,
        "lng": -49.1158
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5864,
      "name": "Slavonski Brod",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.1553,
        "lng": 18.0144
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 12232,
      "name": "Nova Odessa",
      "names": {
        "zh": "新敖德萨",
        "ja": "ノヴァオデッサ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7778,
        "lng": -47.2958
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12167,
      "name": "Heerhugowaard",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.668,
        "lng": 4.841
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12259,
      "name": "Shicun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.5383,
        "lng": 116.1096
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12192,
      "name": "Kalna",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.22,
        "lng": 88.37
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12249,
      "name": "Sattenapalle",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.3962,
        "lng": 80.1497
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5808,
      "name": "Al Hoceima",
      "names": {
        "it": "Al hocceima",
        "ja": "アルホセイマ",
        "th": "อัลโฮเซิม่า",
        "pt": "Al hocceima"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.2472,
        "lng": -3.9322
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 12300,
      "name": "Viseu",
      "names": {
        "zh": "维塞乌"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.1969,
        "lng": -46.14
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12288,
      "name": "Lagonoy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7333,
        "lng": 123.5167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5730,
      "name": "Fussa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7386,
        "lng": 139.3267
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12294,
      "name": "Murakami",
      "names": {
        "zh": "村上",
        "ja": "村上",
        "th": "มุราคามิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.2239,
        "lng": 139.4801
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12274,
      "name": "Moreno",
      "names": {
        "zh": "莫雷诺",
        "ja": "モレノ",
        "th": "เรโน"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.1186,
        "lng": -35.0922
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12289,
      "name": "Alcobaca",
      "names": {
        "es": "Alcobaça",
        "zh": "阿尔科巴萨",
        "pt": "Alcobaça"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.55,
        "lng": -8.975
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 12293,
      "name": "Basey",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.2817,
        "lng": 125.0683
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12305,
      "name": "Smyrna",
      "names": {
        "zh": "士麦那",
        "ja": "スマーナ",
        "th": "ในสเมอร์นา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8633,
        "lng": -84.5168
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12254,
      "name": "Kudamatsu",
      "names": {
        "zh": "下松"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.015,
        "lng": 131.8703
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5802,
      "name": "Hod HaSharon",
      "names": {
        "it": "HADHARON HOD",
        "th": "ฮอดฮารอน"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.15,
        "lng": 34.8833
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 5888,
      "name": "Duzce",
      "names": {
        "zh": "迪兹杰"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8417,
        "lng": 31.1583
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 12264,
      "name": "Kan\u0027onjicho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.1283,
        "lng": 133.6628
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12306,
      "name": "Salgueiro",
      "names": {
        "zh": "萨尔盖鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.0669,
        "lng": -39.1222
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5893,
      "name": "Mugla",
      "names": {
        "es": "De mugla",
        "zh": "穆拉",
        "ja": "ムーラ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.2167,
        "lng": 28.3667
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 12261,
      "name": "Vrindavan",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 27.5728,
        "lng": 77.6933
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12303,
      "name": "Mengla",
      "names": {
        "zh": "勐腊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 21.4866,
        "lng": 101.5639
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12275,
      "name": "Chini",
      "names": {
        "zh": "奇尼"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.3916,
        "lng": 113.0755
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12152,
      "name": "Beauvais",
      "names": {
        "zh": "博韦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.4303,
        "lng": 2.0952
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12133,
      "name": "Iskitim",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.6333,
        "lng": 83.3
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5896,
      "name": "Evora",
      "names": {
        "zh": "埃武拉",
        "ja": "エヴォラ",
        "th": "เอโวรา",
        "pt": "Évora"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.5725,
        "lng": -7.9072
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 12302,
      "name": "Taquaritinga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.4058,
        "lng": -48.505
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12227,
      "name": "Beni Enzar",
      "names": {
        "it": "Beni Enotar",
        "ja": "ベニエンザル"
      },
      "countryCode": "MA",
      "latLng": {
        "lat": 35.2569,
        "lng": -2.9342
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 5742,
      "name": "Macclesfield",
      "names": {
        "zh": "麦克尔斯菲尔德",
        "th": "แฟรงก์ส"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.2581,
        "lng": -2.1274
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12301,
      "name": "Pangantocan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.8333,
        "lng": 124.8333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12313,
      "name": "Taybad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.74,
        "lng": 60.7756
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 12304,
      "name": "Bertioga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.8539,
        "lng": -46.1389
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12317,
      "name": "Cape Girardeau",
      "names": {
        "es": "Cabo Girardeau",
        "de": "Kap Girardeau",
        "it": "Capo Girardeau",
        "id": "Jempol",
        "ja": "ケープジラードー",
        "th": "เคปกิเรรา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 37.3108,
        "lng": -89.5597
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12297,
      "name": "Tivoli",
      "names": {
        "zh": "的Tivoli",
        "ja": "のTivoli"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.9667,
        "lng": 12.8
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12291,
      "name": "Linares",
      "names": {
        "zh": "利纳雷斯"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.0833,
        "lng": -3.6333
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12281,
      "name": "Ramon",
      "names": {
        "zh": "拉蒙",
        "ja": "ラモン",
        "th": "รามอน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.7833,
        "lng": 121.5333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12320,
      "name": "Tome-Acu",
      "names": {
        "es": "Tomo-ACU",
        "fr": "Tomé-acu",
        "zh": "美-急性",
        "ja": "本-acu",
        "th": "เล่ม-acu"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.4189,
        "lng": -48.1519
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12318,
      "name": "Bartolome Maso",
      "names": {
        "ja": "バルトロームマソ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.1686,
        "lng": -76.9428
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 12319,
      "name": "Amealco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1881,
        "lng": -100.1439
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12316,
      "name": "Bianyang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.6194,
        "lng": 106.535
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12276,
      "name": "Tiwi",
      "names": {
        "zh": "提维"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4585,
        "lng": 123.6805
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5865,
      "name": "Magong",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 23.5667,
        "lng": 119.5833
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 12277,
      "name": "Tama",
      "names": {
        "zh": "多摩",
        "ja": "多摩",
        "th": "มะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.4919,
        "lng": 133.9458
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5767,
      "name": "Rafael Calzada",
      "names": {
        "zh": "拉斐尔卡尔茨达"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.7833,
        "lng": -58.3667
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 12328,
      "name": "Monte Alegre",
      "names": {
        "ja": "モンテアレグレ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.0078,
        "lng": -54.0689
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12263,
      "name": "Eschweiler",
      "names": {
        "zh": "埃施韦勒"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8167,
        "lng": 6.2833
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12229,
      "name": "Binalonan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.05,
        "lng": 120.6
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5765,
      "name": "Longxing",
      "names": {
        "zh": "龙兴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6091,
        "lng": 111.2304
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12325,
      "name": "Araci",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -11.3328,
        "lng": -38.9669
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12332,
      "name": "Blainville",
      "names": {
        "zh": "布兰维尔"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.67,
        "lng": -73.88
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 12199,
      "name": "Bedzin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.325,
        "lng": 19.1333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 5898,
      "name": "Zouerate",
      "names": {
        "zh": "祖埃拉特"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 22.7344,
        "lng": -12.4725
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 12262,
      "name": "Pacos de Ferreira",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2833,
        "lng": -8.3833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 12309,
      "name": "Placer",
      "names": {
        "zh": "砂矿",
        "ja": "砂鉱",
        "th": "จัดวาง"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.8667,
        "lng": 123.9167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5844,
      "name": "Rosh Ha`Ayin",
      "names": {
        "it": "Rosh ha`in"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.0833,
        "lng": 34.95
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 5850,
      "name": "Novohrad-Volynskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.5833,
        "lng": 27.6333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 12266,
      "name": "Langenhagen",
      "names": {
        "zh": "朗根哈根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4394,
        "lng": 9.74
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12295,
      "name": "Cuneo",
      "names": {
        "zh": "库内奥",
        "ja": "クーネオ",
        "th": "คูเอโน"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 44.3833,
        "lng": 7.55
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 12272,
      "name": "Lian",
      "names": {
        "zh": "涟",
        "ja": "リアン",
        "th": "เหลียน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0333,
        "lng": 120.65
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12333,
      "name": "Registro",
      "names": {
        "zh": "登记"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.4878,
        "lng": -47.8439
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12312,
      "name": "Libungan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.25,
        "lng": 124.5167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12323,
      "name": "Amarante",
      "names": {
        "zh": "阿马兰特"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2667,
        "lng": -8.0833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 12335,
      "name": "Limoeiro do Norte",
      "names": {
        "zh": "Limoeiro做北部",
        "ja": "リムエイロドノルテ",
        "th": "Limoeiro ทำ Norte"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1458,
        "lng": -38.0978
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12321,
      "name": "Magsaysay",
      "names": {
        "zh": "麦格赛赛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.7667,
        "lng": 125.1833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5799,
      "name": "Klimovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.3667,
        "lng": 37.5333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12246,
      "name": "M\u0027diq",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 35.68,
        "lng": -5.32
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 5656,
      "name": "Kettering",
      "names": {
        "zh": "凯特林",
        "th": "เค็ตเตอริง"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.3931,
        "lng": -0.7229
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5903,
      "name": "Tamiami",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 25.7556,
        "lng": -80.4016
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12282,
      "name": "Gangarampur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.4,
        "lng": 88.52
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12339,
      "name": "Irati",
      "names": {
        "zh": "伊拉蒂"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -25.4669,
        "lng": -50.6508
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5892,
      "name": "Zalau",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 47.1911,
        "lng": 23.0572
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 12315,
      "name": "Hohoe",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 7.149,
        "lng": 0.4746
      },
      "country": "Ghana",
      "importance": 3
    },
    {
      "id": 12268,
      "name": "Meerbusch",
      "names": {
        "zh": "梅尔布施"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2667,
        "lng": 6.6667
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5905,
      "name": "Kendale Lakes",
      "names": {
        "es": "Lagos de Kendale",
        "fr": "Lacs de Kendale",
        "zh": "肯德莱湖",
        "de": "Kendale-Seen",
        "ja": "ケンダールの湖",
        "pt": "Lagos de kendale"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.7081,
        "lng": -80.4078
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12307,
      "name": "Quezaltepeque",
      "names": {
        "zh": "克萨尔特佩克"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.8312,
        "lng": -89.2722
      },
      "country": "El Salvador",
      "importance": 3
    },
    {
      "id": 790,
      "name": "Sao Tome",
      "names": {
        "zh": "圣多美",
        "ja": "サントーム",
        "pt": "São Tomé"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 0.3333,
        "lng": 6.7333
      },
      "country": "Sao Tome And Principe",
      "importance": 0
    },
    {
      "id": 5705,
      "name": "Mukocho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.9486,
        "lng": 135.6983
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12185,
      "name": "Khlong Luang",
      "names": {
        "th": "คลองหลวง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.0647,
        "lng": 100.6458
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 12296,
      "name": "Tilakpur",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 28.5278,
        "lng": 81.1189
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 12341,
      "name": "Xanthi",
      "names": {
        "zh": "克桑西",
        "id": "Xánthi"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 41.1333,
        "lng": 24.8833
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 5907,
      "name": "Castelo Branco",
      "names": {
        "ja": "カステロブランコ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.8228,
        "lng": -7.4931
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 12178,
      "name": "Mizuho",
      "names": {
        "zh": "瑞穗",
        "ja": "みずほ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3919,
        "lng": 136.6908
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12338,
      "name": "Diffun",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.6,
        "lng": 121.4667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12308,
      "name": "Cui\u0027erzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.2897,
        "lng": 116.5472
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5839,
      "name": "Mandapeta",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.87,
        "lng": 81.93
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12334,
      "name": "Takizawa",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 39.7347,
        "lng": 141.0769
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12329,
      "name": "Pithoragarh",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 29.58,
        "lng": 80.22
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12314,
      "name": "Trani",
      "names": {
        "ja": "トラーニ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.2667,
        "lng": 16.4167
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 5672,
      "name": "Forest",
      "names": {
        "es": "Bosque",
        "fr": "Forêt",
        "zh": "森林",
        "de": "Wald",
        "it": "Foresta",
        "id": "Hutan",
        "ja": "森林",
        "th": "ป่า",
        "pt": "Floresta"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.8117,
        "lng": 4.3181
      },
      "country": "Belgium",
      "importance": 3
    },
    {
      "id": 5894,
      "name": "Sfantu-Gheorghe",
      "names": {
        "zh": "sfantu-格奥尔基"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 45.8653,
        "lng": 25.7878
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 5900,
      "name": "Santa Rosa de Copan",
      "names": {
        "zh": "圣罗莎de Copan",
        "ja": "サンタロサデパン"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.7667,
        "lng": -88.7833
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 12350,
      "name": "Katerini",
      "names": {
        "id": "Kateríni"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.2667,
        "lng": 22.5
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 12310,
      "name": "Opava",
      "names": {
        "zh": "奥帕瓦"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.9381,
        "lng": 17.9044
      },
      "country": "Czechia",
      "importance": 3
    },
    {
      "id": 12342,
      "name": "Zarrin Shahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.3894,
        "lng": 51.3764
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 12347,
      "name": "General Tinio",
      "names": {
        "fr": "Général Tinio",
        "zh": "一般锡奥",
        "de": "Allgemeines Tinio",
        "it": "Generale Tinio",
        "ja": "一般的なティニオ",
        "th": "นายพล tinio"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.35,
        "lng": 121.05
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5771,
      "name": "Takaishi",
      "names": {
        "zh": "高石"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5206,
        "lng": 135.4422
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5638,
      "name": "Maisons-Alfort",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8058,
        "lng": 2.4378
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12283,
      "name": "Poblacion",
      "names": {
        "zh": "波夫拉西翁"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.4667,
        "lng": 123.9667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5787,
      "name": "Bungoma",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.5666,
        "lng": 34.5666
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 12344,
      "name": "Longmen",
      "names": {
        "zh": "龙门",
        "ja": "龍門"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6119,
        "lng": 110.5746
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12343,
      "name": "Khan Shaykhun",
      "names": {
        "ja": "カーンシェイフン",
        "th": "ข่าน Shaykhun"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.4419,
        "lng": 36.6508
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 12322,
      "name": "Belampalli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 19.0558,
        "lng": 79.4931
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12358,
      "name": "Changling",
      "names": {
        "zh": "长岭"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 44.27,
        "lng": 123.99
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5840,
      "name": "Barlad",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 46.2167,
        "lng": 27.6667
      },
      "country": "Romania",
      "importance": 3
    },
    {
      "id": 12364,
      "name": "Grants Pass",
      "names": {
        "es": "Pase de subvenciones",
        "fr": "Passe les subventions",
        "zh": "拨款通过",
        "de": "Zuschüsse passieren",
        "it": "Sovvenzionare il passaggio",
        "id": "Hibah lulus",
        "ja": "付与パス",
        "th": "เงินช่วยเหลือ",
        "pt": "Subsídios"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4333,
        "lng": -123.3317
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12365,
      "name": "Blue Springs",
      "names": {
        "es": "Muelles azules",
        "fr": "Ressorts bleus",
        "zh": "蓝色泉水",
        "de": "Blaue Federn",
        "it": "Molle blu",
        "id": "Mata air biru",
        "ja": "青いばね",
        "th": "น้ำพุสีน้ำเงิน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0124,
        "lng": -94.2721
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12345,
      "name": "Lozova",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.8892,
        "lng": 36.3161
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 5764,
      "name": "Sidi Bennour",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 32.655,
        "lng": -8.4292
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 12368,
      "name": "Jaru",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -10.4389,
        "lng": -62.4664
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12352,
      "name": "Bambang",
      "names": {
        "zh": "总统"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.3825,
        "lng": 121.11
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12373,
      "name": "Southaven",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 34.9514,
        "lng": -89.9787
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12370,
      "name": "Tucano",
      "names": {
        "zh": "巨嘴鸟"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.9628,
        "lng": -38.7869
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12374,
      "name": "Tinley Park",
      "names": {
        "es": "Parque de tinley",
        "fr": "Parc Tinley Park",
        "zh": "tinley公园",
        "id": "Taman Tinley",
        "ja": "ティンリーパーク",
        "pt": "Parque tinley"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.567,
        "lng": -87.805
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12244,
      "name": "Sankt Augustin",
      "names": {
        "ja": "サンクトオーガスティン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.77,
        "lng": 7.1867
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5863,
      "name": "Hilden",
      "names": {
        "zh": "希尔登"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1714,
        "lng": 6.9394
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5919,
      "name": "Ohrid",
      "names": {
        "zh": "奥赫里德",
        "ja": "オフリド",
        "th": "โอริด"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.1169,
        "lng": 20.8019
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 12327,
      "name": "Gulariya",
      "names": {},
      "countryCode": "NP",
      "latLng": {
        "lat": 28.2056,
        "lng": 81.3472
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 12372,
      "name": "Umm Ruwaba",
      "names": {
        "zh": "嗯鲁瓦巴",
        "ja": "UMMルワバ",
        "th": "อืมรูวิบะ"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 12.9058,
        "lng": 31.2156
      },
      "country": "Sudan",
      "importance": 3
    },
    {
      "id": 5813,
      "name": "Royal Leamington Spa",
      "names": {
        "zh": "皇家leamington spa",
        "ja": "ロイヤルリーディントンスパ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.292,
        "lng": -1.537
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12377,
      "name": "Azul",
      "names": {
        "zh": "阿苏尔",
        "ja": "アズール"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -36.7833,
        "lng": -59.85
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 12353,
      "name": "Boituva",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.2833,
        "lng": -47.6722
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12379,
      "name": "Diamond Bar",
      "names": {
        "es": "Bar de diamantes",
        "fr": "Barreau de diamant",
        "zh": "钻石吧",
        "de": "Diamantbar",
        "id": "Bar berlian",
        "ja": "ダイヤモンドバー",
        "th": "เพชรบาร์",
        "pt": "Barro"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9991,
        "lng": -117.8161
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12361,
      "name": "Catu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -12.3497,
        "lng": -38.3831
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12378,
      "name": "Taua",
      "names": {
        "pt": "Tauá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -6.0028,
        "lng": -40.2928
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12376,
      "name": "Apatity",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 67.5675,
        "lng": 33.3933
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12311,
      "name": "Nogata",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.7439,
        "lng": 130.7297
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12381,
      "name": "Pittsfield",
      "names": {
        "zh": "皮茨菲尔德",
        "ja": "ピッツフィールド",
        "th": "พิตต์สฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4517,
        "lng": -73.2605
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12284,
      "name": "Paombong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 14.8311,
        "lng": 120.7892
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12384,
      "name": "Eagle Pass",
      "names": {
        "fr": "Passe-fort",
        "zh": "鹰通",
        "de": "Adlerpass",
        "it": "Aquila Pass",
        "id": "Lulus",
        "ja": "イーグルパス",
        "pt": "Passagem de águia"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.7125,
        "lng": -100.484
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12348,
      "name": "Parachinar",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 33.8992,
        "lng": 70.1008
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 5923,
      "name": "New Brunswick",
      "names": {
        "es": "Nuevo Brunswick",
        "fr": "Nouveau-Brunswick",
        "zh": "新的不伦瑞克",
        "de": "Neues Brunswick",
        "it": "Nuovo Brunswick",
        "id": "Brunswick baru",
        "ja": "ニューブランズウィック",
        "th": "ใหม่บรันสวิก",
        "pt": "Novo brunswick"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.487,
        "lng": -74.445
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5815,
      "name": "Dzerzhinskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.6333,
        "lng": 37.85
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5918,
      "name": "Halmstad",
      "names": {
        "zh": "哈尔姆斯塔德"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.6754,
        "lng": 12.8587
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 12386,
      "name": "Aran Bidgol",
      "names": {
        "th": "Aran Bodgol"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.0578,
        "lng": 51.4842
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 12367,
      "name": "Hashtgerd",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.9619,
        "lng": 50.68
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 12299,
      "name": "Kandi",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 23.95,
        "lng": 88.03
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12391,
      "name": "Lenexa",
      "names": {
        "th": "เลเนซา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9609,
        "lng": -94.8018
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12330,
      "name": "Oosterhout",
      "names": {
        "zh": "奥斯特豪特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6431,
        "lng": 4.8569
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12354,
      "name": "Portimao",
      "names": {
        "zh": "波尔蒂芒",
        "id": "Portimão",
        "th": "ปอร์ติเมา",
        "pt": "Portimão"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1333,
        "lng": -8.5333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 12355,
      "name": "Den Helder",
      "names": {
        "fr": "Cravate",
        "de": "Helder",
        "id": "Pelan",
        "ja": "デンバー",
        "th": "เดนเฮลเดอร์",
        "pt": "Dendeir"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.9583,
        "lng": 4.7589
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12393,
      "name": "Hazleton",
      "names": {
        "zh": "黑兹利顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9504,
        "lng": -75.9724
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5921,
      "name": "Guliston",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.4833,
        "lng": 68.7833
      },
      "country": "Uzbekistan",
      "importance": 1
    },
    {
      "id": 12349,
      "name": "Yongqing",
      "names": {
        "zh": "永庆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7522,
        "lng": 106.1312
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5899,
      "name": "Trencin",
      "names": {
        "zh": "特伦钦"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.8942,
        "lng": 18.0406
      },
      "country": "Slovakia",
      "importance": 1
    },
    {
      "id": 12326,
      "name": "Gorlitz",
      "names": {
        "es": "Görlitz",
        "fr": "Görlitz",
        "de": "Görlitz",
        "it": "Görlitz",
        "pt": "Görlitz"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1528,
        "lng": 14.9872
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5917,
      "name": "Iba",
      "names": {
        "zh": "伊巴",
        "th": "ไอบีเอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.3333,
        "lng": 119.9833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 12380,
      "name": "Mulanay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5222,
        "lng": 122.4042
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12363,
      "name": "Xiva",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.3783,
        "lng": 60.3639
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 12340,
      "name": "Urbiztondo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.8227,
        "lng": 120.3295
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12396,
      "name": "Twin Falls",
      "names": {
        "es": "Caídas gemelas",
        "zh": "双胞胎瀑布",
        "ja": "双子が落ちます",
        "th": "ทวินฟอลส์",
        "pt": "Gêmeo cai"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5645,
        "lng": -114.4615
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12397,
      "name": "Brookhaven",
      "names": {
        "zh": "布鲁克海文",
        "th": "บรุกเฮเวน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8744,
        "lng": -84.3314
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5866,
      "name": "Nuevo Casas Grandes",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 30.4167,
        "lng": -107.9167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5869,
      "name": "Kidderminster",
      "names": {
        "zh": "基德明斯特",
        "th": "คิดเดอร์มินส์เตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.3885,
        "lng": -2.249
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12394,
      "name": "El Banco",
      "names": {
        "ja": "エルバンコ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.0008,
        "lng": -73.9744
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12402,
      "name": "Novato",
      "names": {
        "zh": "诺瓦托"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.092,
        "lng": -122.5576
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5911,
      "name": "Sankt Polten",
      "names": {
        "es": "Sankt polen"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2,
        "lng": 15.6167
      },
      "country": "Austria",
      "importance": 1
    },
    {
      "id": 12403,
      "name": "Tigard",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 45.4237,
        "lng": -122.7845
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12366,
      "name": "Batac",
      "names": {
        "zh": "巴塔克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 18.0554,
        "lng": 120.5649
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12400,
      "name": "Villarrica",
      "names": {
        "zh": "比亚里卡",
        "th": "บียาร์รีกา"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -39.2667,
        "lng": -72.2167
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 5912,
      "name": "Zalaegerszeg",
      "names": {
        "zh": "佐洛埃格塞格"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.8392,
        "lng": 16.8511
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 5832,
      "name": "Qiryat Ata",
      "names": {
        "ja": "カイヤットATA"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.8,
        "lng": 35.1
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 12409,
      "name": "Abington",
      "names": {
        "zh": "阿宾顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.1108,
        "lng": -75.1146
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12298,
      "name": "Waiblingen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8303,
        "lng": 9.3169
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12362,
      "name": "Baden-Baden",
      "names": {
        "zh": "巴登 - 巴登",
        "ja": "バーデンバーデン",
        "th": "บาเดน-บาเดน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7619,
        "lng": 8.2408
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12405,
      "name": "Jaguaquara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -13.5308,
        "lng": -39.9708
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12411,
      "name": "Aurora",
      "names": {
        "zh": "奥罗拉",
        "ja": "オーロラ",
        "th": "ออโรรา"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.0,
        "lng": -79.4667
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 12388,
      "name": "Limoeiro",
      "names": {
        "zh": "利穆埃鲁"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.875,
        "lng": -35.45
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5849,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.2833,
        "lng": -61.4667
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 12414,
      "name": "Highland",
      "names": {
        "zh": "高原",
        "ja": "ハイランド",
        "th": "ไฮแลนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.1113,
        "lng": -117.1654
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5823,
      "name": "Meaux",
      "names": {
        "zh": "莫"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9603,
        "lng": 2.8883
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12336,
      "name": "Xinqing",
      "names": {
        "zh": "新青"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 48.2363,
        "lng": 129.5059
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12389,
      "name": "Monte Mor",
      "names": {
        "zh": "蒙特尔",
        "id": "Mor",
        "ja": "モンテル",
        "th": "หมอมอนเต"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.9467,
        "lng": -47.315
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5904,
      "name": "Vaslui",
      "names": {
        "zh": "瓦斯卢伊",
        "th": "วาสลุย"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.6468,
        "lng": 27.7387
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 12410,
      "name": "Rolim de Moura",
      "names": {
        "ja": "ロリムデモーラ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.7254,
        "lng": -61.7778
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12375,
      "name": "Ovar",
      "names": {
        "zh": "奥瓦尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.8667,
        "lng": -8.6333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 12383,
      "name": "Asturias",
      "names": {
        "zh": "阿斯图里亚斯",
        "ja": "アストゥリアス",
        "th": "อัสตูเรียส",
        "pt": "Astúrias"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.5679,
        "lng": 123.7172
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12360,
      "name": "Toki",
      "names": {
        "zh": "土岐",
        "ja": "トキ",
        "th": "โทกิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.3525,
        "lng": 137.1833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12346,
      "name": "Bisceglie",
      "names": {
        "zh": "比谢列"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.2409,
        "lng": 16.5021
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12382,
      "name": "Narbonne",
      "names": {
        "zh": "纳博讷",
        "ja": "ナルボンヌ",
        "th": "นาร์บอนน์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1836,
        "lng": 3.0042
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 5931,
      "name": "Fountain Valley",
      "names": {
        "es": "Valle de la fuente",
        "fr": "Vallée de la fontaine",
        "zh": "喷泉山谷",
        "de": "Brunnen-Tal",
        "it": "Valle della Fontana",
        "id": "Lembah air mancur",
        "ja": "噴水渓谷",
        "th": "หุบเขาน้ำพุ",
        "pt": "Vale da fonte"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7105,
        "lng": -117.9514
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12416,
      "name": "Dearborn Heights",
      "names": {
        "fr": "Charborn hauteurs",
        "zh": "亲爱的高度",
        "de": "Dearborn-Höhen",
        "it": "Earborn Heights",
        "id": "Tinggi Dearborn",
        "ja": "ダイナボーンの高さ",
        "th": "ความสูงที่เดียร์บอร์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.3164,
        "lng": -83.2769
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12408,
      "name": "Yamasa",
      "names": {
        "ja": "ヤマサ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.7667,
        "lng": -70.0167
      },
      "country": "Dominican Republic",
      "importance": 3
    },
    {
      "id": 5901,
      "name": "Jelgava",
      "names": {
        "zh": "叶尔加瓦"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.6522,
        "lng": 23.7244
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 12387,
      "name": "Mercedes",
      "names": {
        "zh": "梅赛德斯",
        "ja": "メルセデス",
        "th": "เมอร์เซเดส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1093,
        "lng": 123.0109
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12324,
      "name": "Winneba",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 5.35,
        "lng": -0.6333
      },
      "country": "Ghana",
      "importance": 3
    },
    {
      "id": 12413,
      "name": "Barbalha",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.305,
        "lng": -39.3019
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5791,
      "name": "Midalt",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 32.68,
        "lng": -4.74
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 5868,
      "name": "Umm el Fahm",
      "names": {
        "zh": "嗯el fahm"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 32.5158,
        "lng": 35.1525
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 12424,
      "name": "Grapevine",
      "names": {
        "fr": "La vigne",
        "zh": "葡萄",
        "ja": "グレープバイン",
        "th": "เกรปไวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.9343,
        "lng": -97.0744
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12385,
      "name": "Narutocho-mitsuishi",
      "names": {
        "zh": "narutocho-三石"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.1667,
        "lng": 134.6167
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12356,
      "name": "Wangguanzhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.0183,
        "lng": 115.5773
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12404,
      "name": "Hoogeveen",
      "names": {
        "zh": "霍赫芬"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.7167,
        "lng": 6.4667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12401,
      "name": "Porirua",
      "names": {
        "zh": "波里鲁瓦"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.1333,
        "lng": 174.85
      },
      "country": "New Zealand",
      "importance": 3
    },
    {
      "id": 12422,
      "name": "Pombal",
      "names": {
        "zh": "蓬巴尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.9161,
        "lng": -8.6281
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 12428,
      "name": "Fond du Lac",
      "names": {
        "zh": "喜欢杜拉",
        "it": "Affettuoso du lac",
        "ja": "フォンデュラ",
        "th": "รัก du lac"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.7718,
        "lng": -88.4396
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12287,
      "name": "Verviers",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.5833,
        "lng": 5.85
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 12430,
      "name": "Hacienda Heights",
      "names": {
        "fr": "Hacienda hauteur",
        "de": "Hacienda-Höhen",
        "it": "Height Heights",
        "ja": "ハシエンダハイツ",
        "pt": "Horas Haciendas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.997,
        "lng": -117.9728
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12399,
      "name": "Horizonte",
      "names": {
        "zh": "奥里藏特",
        "th": "โอรีซอนตี"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -4.0989,
        "lng": -38.4958
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12431,
      "name": "Juruti",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.1519,
        "lng": -56.0919
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5841,
      "name": "Chelles",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8833,
        "lng": 2.6
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5935,
      "name": "Skien",
      "names": {
        "zh": "希恩"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.2081,
        "lng": 9.5528
      },
      "country": "Norway",
      "importance": 1
    },
    {
      "id": 5937,
      "name": "Vranje",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.5542,
        "lng": 21.8972
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 5939,
      "name": "Ithaca",
      "names": {
        "zh": "伊萨卡",
        "ja": "イサカ",
        "th": "อิธาก้า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4442,
        "lng": -76.5032
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12435,
      "name": "Apple Valley",
      "names": {
        "es": "Valle de manzana",
        "fr": "Vallée de pomme",
        "zh": "苹果谷",
        "de": "Apfeltal",
        "id": "Lembah Apple",
        "ja": "アップルバレー",
        "pt": "Vale da Apple"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.7457,
        "lng": -93.2006
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12412,
      "name": "Bitonto",
      "names": {
        "zh": "比通托"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.1083,
        "lng": 16.6917
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12437,
      "name": "Chicopee",
      "names": {
        "zh": "奇科皮"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1764,
        "lng": -72.5719
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5858,
      "name": "Shijonawate",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.74,
        "lng": 135.6394
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12395,
      "name": "Manapla",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.958,
        "lng": 123.123
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5861,
      "name": "Bhimunipatnam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.8864,
        "lng": 83.4471
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12407,
      "name": "Hyeres",
      "names": {
        "es": "Hyères",
        "de": "Hyères",
        "it": "Hyères",
        "id": "Hyères",
        "ja": "イエール"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1199,
        "lng": 6.1316
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12442,
      "name": "Ucuma",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -12.85,
        "lng": 15.0667
      },
      "country": "Angola",
      "importance": 3
    },
    {
      "id": 12285,
      "name": "Bagheria",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 38.0833,
        "lng": 13.5
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 5936,
      "name": "Lampang",
      "names": {
        "zh": "南邦府",
        "th": "ลำปาง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.3,
        "lng": 99.5
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 5940,
      "name": "Oak Lawn",
      "names": {
        "es": "Césped de roble",
        "fr": "Pelouse de chêne",
        "zh": "橡木草坪",
        "de": "Eichenrasen",
        "it": "Prato in quercia",
        "id": "Lapangan kayu ek",
        "ja": "オークの芝生",
        "th": "สนามหญ้าโอ๊ค",
        "pt": "Gramado de carvalho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7139,
        "lng": -87.7528
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12427,
      "name": "Chino",
      "names": {
        "zh": "奇诺",
        "ja": "チノ",
        "th": "สีกากี"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9956,
        "lng": 138.1589
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12447,
      "name": "Cathedral City",
      "names": {
        "es": "Ciudad de la Catedral",
        "fr": "Ville de la cathédrale",
        "zh": "大教堂城市",
        "de": "Kathedrale",
        "it": "Città della cattedrale",
        "id": "Kota katedral",
        "ja": "大聖堂の街",
        "th": "เมืองมหาวิหาร",
        "pt": "Cidade da Catedral"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8363,
        "lng": -116.4642
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12351,
      "name": "Frydek-Mistek",
      "names": {
        "es": "Frýdek-místek",
        "de": "Frýdek-místek",
        "it": "Frýdek-místek"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.6881,
        "lng": 18.3536
      },
      "country": "Czechia",
      "importance": 3
    },
    {
      "id": 12436,
      "name": "Tiquisate",
      "names": {
        "zh": "蒂基萨特"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.2833,
        "lng": -91.3667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 12371,
      "name": "Martin",
      "names": {
        "zh": "马丁",
        "ja": "マーティン",
        "th": "มาร์ติน"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0636,
        "lng": 18.9214
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 5664,
      "name": "Villejuif",
      "names": {
        "zh": "维勒瑞夫"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7919,
        "lng": 2.3636
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12453,
      "name": "Alenquer",
      "names": {
        "zh": "阿伦克尔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.9419,
        "lng": -54.7378
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12449,
      "name": "Vicosa do Ceara",
      "names": {
        "zh": "vicosa做ceara"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.5619,
        "lng": -41.0919
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12455,
      "name": "Barreirinhas",
      "names": {
        "zh": "巴雷里尼亚斯"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -2.7469,
        "lng": -42.8258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12392,
      "name": "Corby",
      "names": {
        "zh": "科比",
        "th": "คอร์บี้"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.4877,
        "lng": -0.7013
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12443,
      "name": "Santa Isabel",
      "names": {
        "zh": "圣徒伊莎贝尔",
        "ja": "サンタアイサベル",
        "th": "ซานตาอิซาเบล"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.3158,
        "lng": -46.2208
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12432,
      "name": "Nandod",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.8704,
        "lng": 73.5026
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12456,
      "name": "Delano",
      "names": {
        "zh": "德拉诺",
        "ja": "デラノ",
        "th": "เดลาโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.767,
        "lng": -119.2637
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12458,
      "name": "Bentonville",
      "names": {
        "zh": "本顿维尔",
        "th": "เบนตันวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.3546,
        "lng": -94.2306
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12459,
      "name": "Stonecrest",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.6842,
        "lng": -84.1372
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12390,
      "name": "Canterbury",
      "names": {
        "zh": "坎特伯雷",
        "ja": "カンタベリー",
        "th": "อังกฤษ"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.28,
        "lng": 1.08
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12441,
      "name": "Zhushan",
      "names": {
        "zh": "竹山"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 23.6889,
        "lng": 120.7289
      },
      "country": "Taiwan",
      "importance": 3
    },
    {
      "id": 5878,
      "name": "Pithapuram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.1167,
        "lng": 82.2667
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12462,
      "name": "Kettering",
      "names": {
        "zh": "凯特林",
        "th": "เค็ตเตอริง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.6957,
        "lng": -84.1495
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12452,
      "name": "Indaial",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -26.8978,
        "lng": -49.2319
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12465,
      "name": "Colton",
      "names": {
        "zh": "科尔顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0538,
        "lng": -117.3254
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12457,
      "name": "Minamiuonuma",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 37.0667,
        "lng": 138.8833
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12470,
      "name": "Kingston",
      "names": {
        "zh": "金士顿",
        "ja": "キングストン",
        "th": "คิงส์ตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.9295,
        "lng": -73.9968
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5625,
      "name": "Epinay-sur-Seine",
      "names": {
        "es": "Epinay-sur-Sena",
        "zh": "epinay塞纳河畔",
        "it": "Epinay sur-Seine",
        "ja": "epinayシュルセーヌ",
        "th": "epinay-ซูร์แม่น้ำเซน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9553,
        "lng": 2.3092
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12415,
      "name": "La Roche-sur-Yon",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 46.6705,
        "lng": -1.426
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12451,
      "name": "Porto Ferreira",
      "names": {
        "zh": "波尔图弗雷拉",
        "ja": "ポルトフェレイラ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.8539,
        "lng": -47.4789
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5805,
      "name": "Tacuarembo",
      "names": {
        "es": "Tacuarembó"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -31.7144,
        "lng": -55.9828
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 12429,
      "name": "Punganuru",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.3667,
        "lng": 78.5833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12475,
      "name": "Monte Santo",
      "names": {
        "zh": "蒙特桑托",
        "ja": "モンテサント"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.4378,
        "lng": -39.3328
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12359,
      "name": "Hidaka",
      "names": {
        "zh": "日高",
        "ja": "日高"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9078,
        "lng": 139.3392
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12461,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.4833,
        "lng": 122.0333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12444,
      "name": "San Gil",
      "names": {
        "zh": "圣吉尔",
        "ja": "サンギル",
        "th": "ซานกิล"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.555,
        "lng": -73.1336
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12464,
      "name": "Sarpsborg",
      "names": {
        "zh": "萨尔普斯堡"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.2839,
        "lng": 11.1096
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 5946,
      "name": "Mikkeli",
      "names": {
        "zh": "米凯利"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.6875,
        "lng": 27.2736
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 12438,
      "name": "Carigara",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.3,
        "lng": 124.6833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12472,
      "name": "Taquara",
      "names": {
        "zh": "塔夸拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6508,
        "lng": -50.7808
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12337,
      "name": "Antratsyt",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.1167,
        "lng": 39.0833
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 12454,
      "name": "Sara",
      "names": {
        "zh": "萨拉",
        "ja": "サラ",
        "th": "ซาร่า"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.25,
        "lng": 123.0167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12483,
      "name": "West Haven",
      "names": {
        "zh": "西避风港",
        "de": "Westhafen",
        "id": "Surga barat",
        "ja": "ウェストヘイブン",
        "th": "เวสต์สวรรค์",
        "pt": "Paraíso ocidental"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2739,
        "lng": -72.9672
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5943,
      "name": "Malisheve",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.4828,
        "lng": 20.7458
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 12331,
      "name": "Pila",
      "names": {
        "zh": "皮拉"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.2333,
        "lng": 121.3667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12476,
      "name": "Chota",
      "names": {
        "zh": "焦达"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -6.55,
        "lng": -78.65
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 12468,
      "name": "Annaka",
      "names": {
        "zh": "安中"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3264,
        "lng": 138.8872
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12473,
      "name": "Terneuzen",
      "names": {
        "zh": "泰尔讷曾"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.2931,
        "lng": 3.8339
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12489,
      "name": "St. Cloud",
      "names": {
        "es": "Nube",
        "fr": "St. nuage",
        "zh": "圣云",
        "de": "St. Wolke",
        "ja": "聖雲",
        "th": "เซนต์คลาวด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.2294,
        "lng": -81.2829
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5576,
      "name": "Portici",
      "names": {
        "zh": "波蒂奇"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.8197,
        "lng": 14.3411
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12420,
      "name": "Hattingen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3992,
        "lng": 7.1858
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5821,
      "name": "Mundka",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 28.6794,
        "lng": 77.0284
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5874,
      "name": "Weymouth",
      "names": {
        "zh": "韦茅斯",
        "ja": "ウェイマス"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.613,
        "lng": -2.457
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12369,
      "name": "Gus\u0027-Khrustal\u0027nyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.6167,
        "lng": 40.65
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12478,
      "name": "Jinchang",
      "names": {
        "zh": "金昌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.4858,
        "lng": 112.9643
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12426,
      "name": "Modica",
      "names": {
        "es": "De modica",
        "zh": "莫迪卡"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 36.8672,
        "lng": 14.7614
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12406,
      "name": "San Remo",
      "names": {
        "zh": "圣雷莫",
        "ja": "サンレモ",
        "th": "ซานเรโม"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.8175,
        "lng": 7.775
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12479,
      "name": "Masinloc",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 15.5333,
        "lng": 119.95
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12357,
      "name": "Volzhsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.8667,
        "lng": 48.35
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12490,
      "name": "Chivilcoy",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.9,
        "lng": -60.0167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 12463,
      "name": "Mali",
      "names": {
        "es": "Malí",
        "zh": "马里",
        "ja": "マリ",
        "th": "มาลี"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1277,
        "lng": 104.7029
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12450,
      "name": "Pontevedra",
      "names": {
        "zh": "庞特维德拉",
        "ja": "ポンテベドラ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.3667,
        "lng": 122.8833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12487,
      "name": "Trento",
      "names": {
        "zh": "特伦托",
        "de": "Trient",
        "ja": "トレント",
        "th": "เทรนโต"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 8.0459,
        "lng": 126.0614
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12500,
      "name": "Normal",
      "names": {
        "fr": "Ordinaire",
        "zh": "正常",
        "it": "Normale",
        "ja": "通常の",
        "th": "ปกติ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5218,
        "lng": -88.9881
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12418,
      "name": "Songlindian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.41,
        "lng": 115.9235
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 5886,
      "name": "Biel/Bienne",
      "names": {
        "es": "Biel / bienne",
        "fr": "Biel / Bienne",
        "zh": "比尔/比安",
        "de": "Biel / bienne",
        "it": "Biel / Bienne",
        "id": "Biel / bienne",
        "ja": "ビール/ bienne",
        "th": "บีล / bienne",
        "pt": "Biel / bienne"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.1372,
        "lng": 7.2472
      },
      "country": "Switzerland",
      "importance": 3
    },
    {
      "id": 12433,
      "name": "Melton",
      "names": {
        "zh": "梅尔顿",
        "ja": "メルトン",
        "th": "ฅัน"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -37.6833,
        "lng": 144.5833
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 12486,
      "name": "Nihonmatsu",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 37.5847,
        "lng": 140.4314
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12492,
      "name": "Tapas",
      "names": {
        "zh": "小吃",
        "ja": "スペイン風居酒屋料理",
        "th": "ทาปาส"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.2667,
        "lng": 122.5333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12477,
      "name": "Lingen",
      "names": {
        "zh": "林根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.5233,
        "lng": 7.3172
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12434,
      "name": "Konan",
      "names": {
        "zh": "湖南",
        "ja": "甲南"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0042,
        "lng": 136.085
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5952,
      "name": "Berwyn",
      "names": {
        "zh": "伯温"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8433,
        "lng": -87.7909
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12493,
      "name": "Sao Jose do Rio Pardo",
      "names": {
        "zh": "圣何塞做Rio Pardo",
        "id": "Sao Jose melakukan Rio Pardo",
        "ja": "サンノゼはリオポルドをしています",
        "th": "Sao Jose ทำ Rio Pardo",
        "pt": "São José do Rio Pardo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.5958,
        "lng": -46.8889
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12419,
      "name": "San Isidro",
      "names": {
        "zh": "圣伊斯丹",
        "ja": "サンアイズロ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.2667,
        "lng": 120.9
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5798,
      "name": "Azrou",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 33.4362,
        "lng": -5.2218
      },
      "country": "Morocco",
      "importance": 3
    },
    {
      "id": 12495,
      "name": "Shidong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.6193,
        "lng": 112.0701
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12460,
      "name": "Sakurai",
      "names": {
        "zh": "樱井",
        "ja": "桜井"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.5189,
        "lng": 135.8519
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12425,
      "name": "Islampur",
      "names": {
        "zh": "伊斯兰布尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 26.27,
        "lng": 88.2
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12480,
      "name": "Teramo",
      "names": {
        "zh": "泰拉莫"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.6589,
        "lng": 13.7039
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 12507,
      "name": "El Rama",
      "names": {
        "ja": "エルラマ"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.2333,
        "lng": -84.3043
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 12498,
      "name": "Huixtla",
      "names": {
        "zh": "维斯特拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.1429,
        "lng": -92.5122
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12512,
      "name": "Milford",
      "names": {
        "zh": "米尔福德",
        "ja": "ミルフォード",
        "th": "มิลฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2256,
        "lng": -73.0616
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12494,
      "name": "Kotka",
      "names": {
        "zh": "科特卡"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4667,
        "lng": 26.9458
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 12505,
      "name": "Sado",
      "names": {
        "zh": "佐渡",
        "ja": "佐渡"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.0183,
        "lng": 138.3683
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12515,
      "name": "Zarafshon Shahri",
      "names": {
        "ja": "ザラフションShahri"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.5667,
        "lng": 64.2
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 5954,
      "name": "Aloha",
      "names": {
        "zh": "阿罗哈",
        "ja": "アロハ",
        "th": "อโลฮา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 45.492,
        "lng": -122.8726
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12511,
      "name": "Cachoeiras de Macacu",
      "names": {
        "id": "Cachoeiras de Macabu",
        "ja": "カチョウィラスマカチ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.4628,
        "lng": -42.6528
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5732,
      "name": "Bobigny",
      "names": {
        "zh": "博比尼"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9106,
        "lng": 2.4397
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12481,
      "name": "San Manuel",
      "names": {
        "zh": "圣曼努埃尔",
        "de": "Sanuel",
        "ja": "サンマヌエル",
        "th": "ซานมานูเอล",
        "pt": "Sanguinência"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.0656,
        "lng": 120.6667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12421,
      "name": "Bad Homburg",
      "names": {
        "zh": "坏鸿斯堡",
        "de": "Schlechte Homburg",
        "it": "Cattivo homburg",
        "ja": "悪いホームブルク"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.2292,
        "lng": 8.6105
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12448,
      "name": "Huaishu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0828,
        "lng": 115.0591
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12517,
      "name": "Valle del Guamuez",
      "names": {
        "es": "Valle del Guamüez",
        "id": "Valle del Guamunez",
        "ja": "ヴァッレデルグアメス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.4253,
        "lng": -76.9053
      },
      "country": "Colombia",
      "importance": 3
    },
    {
      "id": 12423,
      "name": "Christchurch",
      "names": {
        "zh": "基督城",
        "ja": "クライストチャーチ",
        "th": "ไครสต์เชิร์ช"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 50.73,
        "lng": -1.78
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12521,
      "name": "Rotorua",
      "names": {
        "zh": "罗托鲁瓦",
        "ja": "ロトルア",
        "th": "โรโตรัว"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -38.1378,
        "lng": 176.2514
      },
      "country": "New Zealand",
      "importance": 3
    },
    {
      "id": 12491,
      "name": "Montesilvano",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 42.5119,
        "lng": 14.1373
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12524,
      "name": "Barra",
      "names": {
        "zh": "巴拉",
        "ja": "バーラ",
        "th": "รา"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -11.0889,
        "lng": -43.1419
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12467,
      "name": "Cholet",
      "names": {
        "zh": "绍莱"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.06,
        "lng": -0.8783
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12519,
      "name": "Hashtpar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.7992,
        "lng": 48.9053
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 12527,
      "name": "Arroyo Grande",
      "names": {
        "ja": "アロヨグランデ",
        "pt": "Arroio grande"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1241,
        "lng": -120.5845
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12522,
      "name": "Ixhuatlan de Madero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6833,
        "lng": -98.0167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12503,
      "name": "Itupeva",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.1531,
        "lng": -47.0578
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12482,
      "name": "Bad Salzuflen",
      "names": {
        "fr": "Mauvais salzuflen",
        "zh": "坏salzuflen",
        "de": "Schlechte Salzuflen",
        "ja": "悪いザラフレン",
        "th": "salzuflen ที่ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.0875,
        "lng": 8.7506
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12417,
      "name": "Puttur",
      "names": {
        "zh": "布杜尔"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 13.45,
        "lng": 79.55
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12509,
      "name": "Jalor",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.35,
        "lng": 72.6167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12531,
      "name": "Acara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -1.9608,
        "lng": -48.1969
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5961,
      "name": "Irvington",
      "names": {
        "zh": "欧文顿"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7242,
        "lng": -74.2318
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12513,
      "name": "Tarma",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -11.4167,
        "lng": -75.6833
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 12466,
      "name": "Pulheim",
      "names": {
        "zh": "普尔海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0,
        "lng": 6.8
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12535,
      "name": "Minnetonka",
      "names": {
        "ja": "ミネトンカ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.9322,
        "lng": -93.4598
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5963,
      "name": "Rosemead",
      "names": {
        "zh": "罗斯米德",
        "ja": "ローズミード"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.0689,
        "lng": -118.0823
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5696,
      "name": "Bondy",
      "names": {
        "zh": "邦迪"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9022,
        "lng": 2.4828
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12499,
      "name": "Palmaner",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.2,
        "lng": 78.75
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12398,
      "name": "Schweinfurt",
      "names": {
        "zh": "施韦因富特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.05,
        "lng": 10.2333
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12496,
      "name": "Kavala",
      "names": {
        "zh": "卡瓦拉",
        "ja": "カバラ"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.9396,
        "lng": 24.4069
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 5959,
      "name": "Prachuap Khiri Khan",
      "names": {
        "th": "ประจวบคีรีขันธ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 11.8089,
        "lng": 99.798
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 12530,
      "name": "Tagkawayan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9667,
        "lng": 122.5333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12529,
      "name": "Titay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.8682,
        "lng": 122.5613
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12537,
      "name": "Puerto Libertador",
      "names": {
        "ja": "プエルトリベタードール",
        "th": "เปอร์โตลิเบอร์ตตาเทียน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.8881,
        "lng": -75.6717
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12536,
      "name": "Cuenca",
      "names": {
        "zh": "昆卡",
        "ja": "クエンカ",
        "th": "เควงคา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.0667,
        "lng": -2.15
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 12540,
      "name": "New Plymouth",
      "names": {
        "fr": "Nouveau Plymouth",
        "zh": "新普利茅斯",
        "de": "Neue Plymouth",
        "it": "Nuovo Plymouth",
        "id": "Plymouth baru",
        "ja": "新しいプリマス",
        "th": "นิวพลีมั ธ",
        "pt": "Novo plymouth"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -39.0578,
        "lng": 174.0742
      },
      "country": "New Zealand",
      "importance": 3
    },
    {
      "id": 12501,
      "name": "Minalabac",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5667,
        "lng": 123.1833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12520,
      "name": "Ruteng",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -8.6118,
        "lng": 120.4698
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 5809,
      "name": "Hamura",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.7672,
        "lng": 139.3111
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12484,
      "name": "Chenab Nagar",
      "names": {
        "ja": "チェナブナガル"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 31.75,
        "lng": 72.9167
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 5965,
      "name": "Paramount",
      "names": {
        "fr": "Primordiale",
        "zh": "派拉蒙",
        "id": "Terpenting",
        "ja": "パラマウント",
        "th": "สิ่งสำคัญยิ่ง"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.8976,
        "lng": -118.1652
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12543,
      "name": "Wayne",
      "names": {
        "zh": "韦恩",
        "ja": "ウェイン",
        "th": "เวย์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9481,
        "lng": -74.2453
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5910,
      "name": "Vigan",
      "names": {
        "zh": "维甘"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.5747,
        "lng": 120.3869
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 12549,
      "name": "Yucaipa",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 34.0336,
        "lng": -117.0429
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12553,
      "name": "Brunswick",
      "names": {
        "fr": "-Brunswick",
        "zh": "不伦瑞克省",
        "de": "Braunschweig",
        "ja": "ブランズウィック",
        "th": "สวิก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.145,
        "lng": -81.474
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5855,
      "name": "Chania",
      "names": {
        "fr": "La Canée",
        "zh": "干尼亚",
        "ja": "ハニア"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 35.5167,
        "lng": 24.0167
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 12533,
      "name": "Avellino",
      "names": {
        "zh": "阿韦利诺",
        "ja": "アヴェッリーノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.9153,
        "lng": 14.7897
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 12506,
      "name": "Siena",
      "names": {
        "fr": "Sienne",
        "zh": "锡耶纳",
        "ja": "シエナ",
        "th": "เซีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.3183,
        "lng": 11.3314
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 12539,
      "name": "Cabagan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 17.4333,
        "lng": 121.7667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12545,
      "name": "Liski",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 50.9822,
        "lng": 39.4994
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12551,
      "name": "Salo",
      "names": {
        "zh": "萨洛"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.3831,
        "lng": 23.1331
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 5860,
      "name": "Legionowo",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.3833,
        "lng": 20.8833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 12554,
      "name": "Bijar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.8741,
        "lng": 47.5937
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 12485,
      "name": "Dayin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.9358,
        "lng": 115.7102
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12552,
      "name": "Impasugong",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.3,
        "lng": 125.0
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12557,
      "name": "Williamsport",
      "names": {
        "zh": "威廉斯波特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2398,
        "lng": -77.0371
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12547,
      "name": "Tres Pontas",
      "names": {
        "id": "Pontas tres",
        "ja": "トレスポンタス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -21.3669,
        "lng": -45.5128
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5902,
      "name": "Saint-Quentin",
      "names": {
        "zh": "圣 - 昆汀",
        "ja": "サン・クエンティン",
        "th": "เซนต์เควนติน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.8486,
        "lng": 3.2864
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12559,
      "name": "Sao Francisco",
      "names": {
        "zh": "圣弗朗西斯科",
        "ja": "サンフランシスコ",
        "th": "เซาฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -15.9489,
        "lng": -44.8639
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12538,
      "name": "Shali",
      "names": {
        "zh": "沙利"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.15,
        "lng": 45.9
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 12555,
      "name": "Magpet",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.1167,
        "lng": 125.1167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12556,
      "name": "Kitaotao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6397,
        "lng": 125.0089
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5957,
      "name": "Louangphabang",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 19.8931,
        "lng": 102.1381
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 12534,
      "name": "Dholka",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.72,
        "lng": 72.47
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12541,
      "name": "Tajumulco",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0839,
        "lng": -91.9233
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 12514,
      "name": "Frejus",
      "names": {
        "fr": "Fréjus",
        "zh": "弗雷瑞斯",
        "id": "Fréjus"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.433,
        "lng": 6.737
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12528,
      "name": "Kampen",
      "names": {
        "zh": "坎彭"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.55,
        "lng": 5.9
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12564,
      "name": "Union",
      "names": {
        "es": "Unión",
        "zh": "联盟",
        "it": "Unione",
        "id": "Persatuan",
        "ja": "連合",
        "th": "สหภาพ",
        "pt": "União"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.1258,
        "lng": -76.0329
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12569,
      "name": "Elyria",
      "names": {
        "zh": "伊利里亚",
        "th": "อิลิเรีย"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.3761,
        "lng": -82.1063
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12560,
      "name": "Hamada",
      "names": {
        "zh": "滨田",
        "ja": "浜田"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8992,
        "lng": 132.08
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12558,
      "name": "Sao Francisco do Sul",
      "names": {
        "ja": "サンフランシスコドサル",
        "pt": "São Francisco do Sul"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.2428,
        "lng": -48.6378
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12546,
      "name": "Markala",
      "names": {},
      "countryCode": "ML",
      "latLng": {
        "lat": 13.6739,
        "lng": -6.075
      },
      "country": "Mali",
      "importance": 3
    },
    {
      "id": 5885,
      "name": "Bac Giang",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 21.2731,
        "lng": 106.1947
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 12570,
      "name": "Leesburg",
      "names": {
        "ja": "リーズバーグ"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1058,
        "lng": -77.5544
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12572,
      "name": "Mount Prospect",
      "names": {
        "fr": "Perspective",
        "zh": "山峰前景",
        "de": "Bergblick",
        "it": "Monte Prospect",
        "id": "Mount prospek",
        "ja": "見通しを取り付けます",
        "th": "หวังว่าจะมีโอกาส",
        "pt": "Monte Prospecto"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0641,
        "lng": -87.9375
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12439,
      "name": "Funato",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.2564,
        "lng": 135.3111
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12469,
      "name": "Xielu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.0359,
        "lng": 115.6922
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12576,
      "name": "Upata",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.0204,
        "lng": -62.41
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 12550,
      "name": "Svobodnyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 51.3833,
        "lng": 128.1333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5953,
      "name": "Charikar",
      "names": {
        "zh": "恰里卡尔"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 35.0183,
        "lng": 69.1679
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 12581,
      "name": "Bristol",
      "names": {
        "zh": "布里斯托尔",
        "ja": "ブリストル",
        "th": "บริสตอล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.1216,
        "lng": -74.8667
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5790,
      "name": "Evry",
      "names": {
        "zh": "埃夫里"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6239,
        "lng": 2.4294
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12563,
      "name": "Kongoussi",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 13.3333,
        "lng": -1.5333
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 12582,
      "name": "Pinellas Park",
      "names": {
        "es": "Parque pinellas",
        "zh": "皮内拉斯公园",
        "de": "Pinellas-Park",
        "id": "Taman Pinellas",
        "th": "สวน Pinellas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 27.8589,
        "lng": -82.7078
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12575,
      "name": "Krasnokamsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.0833,
        "lng": 55.75
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5969,
      "name": "Sakon Nakhon",
      "names": {
        "zh": "佐仓纳霍恩",
        "ja": "サコンナション",
        "th": "สกลนคร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.1564,
        "lng": 104.1456
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 12580,
      "name": "Enerhodar",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.4989,
        "lng": 34.6558
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 5962,
      "name": "Tulcan",
      "names": {
        "es": "Tulcán",
        "zh": "图尔坎"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": 0.8117,
        "lng": -77.7186
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 12586,
      "name": "Encarnacion de Diaz",
      "names": {
        "es": "Encarnacion de Díaz"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.5167,
        "lng": -102.2333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12591,
      "name": "Bellevue",
      "names": {
        "zh": "贝尔维尤",
        "ja": "ベルビュー",
        "th": "แบล"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.1535,
        "lng": -95.9357
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12504,
      "name": "Pinto",
      "names": {
        "zh": "平托",
        "ja": "ピント",
        "th": "ม้าลาย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.25,
        "lng": -3.7
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12510,
      "name": "Kaliyaganj",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 25.63,
        "lng": 88.32
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12548,
      "name": "Jaggayyapeta",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.892,
        "lng": 80.0976
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12590,
      "name": "Pijijiapan",
      "names": {
        "zh": "皮希希亚潘"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.6867,
        "lng": -93.2092
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12592,
      "name": "West Sacramento",
      "names": {
        "zh": "西萨克拉门托",
        "id": "Sacramento Barat",
        "ja": "ウェストサクラメント",
        "th": "เวสต์แซคราเมนโต",
        "pt": "Sacramento Ocidental"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.5557,
        "lng": -121.5504
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12544,
      "name": "La Lima",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 15.433,
        "lng": -87.917
      },
      "country": "Honduras",
      "importance": 3
    },
    {
      "id": 12594,
      "name": "Frutal",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.025,
        "lng": -48.9408
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12565,
      "name": "Ajuy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1725,
        "lng": 123.0196
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12562,
      "name": "Pasacao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5167,
        "lng": 123.05
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12573,
      "name": "Marco de Canavezes",
      "names": {
        "es": "Marco de Canavlazes",
        "fr": "Marco de Canaves",
        "zh": "Marco de Canovezes",
        "ja": "マルコデカベズ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1833,
        "lng": -8.15
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 12601,
      "name": "Apopka",
      "names": {
        "zh": "阿波普卡"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 28.7014,
        "lng": -81.5304
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12440,
      "name": "Vannes",
      "names": {
        "zh": "瓦纳"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6559,
        "lng": -2.7603
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12508,
      "name": "Hanyu",
      "names": {
        "zh": "汉语"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.1728,
        "lng": 139.5486
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12567,
      "name": "Huesca",
      "names": {
        "zh": "韦斯卡"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 42.1333,
        "lng": -0.4167
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 5916,
      "name": "Gallarate",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 45.6649,
        "lng": 8.7914
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12542,
      "name": "Tuni",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.35,
        "lng": 82.55
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12523,
      "name": "Narva",
      "names": {
        "zh": "纳尔瓦"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3792,
        "lng": 28.2006
      },
      "country": "Estonia",
      "importance": 2
    },
    {
      "id": 12561,
      "name": "Sibonga",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.0333,
        "lng": 123.5667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12526,
      "name": "Zhigulevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.4,
        "lng": 49.5
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12568,
      "name": "Nordhorn",
      "names": {
        "zh": "诺德霍恩"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4333,
        "lng": 7.0667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12603,
      "name": "Pryluky",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.6,
        "lng": 32.4
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 12598,
      "name": "Mutsu",
      "names": {
        "zh": "陆奥",
        "ja": "むつ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 41.2931,
        "lng": 141.1831
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12604,
      "name": "Lewiston",
      "names": {
        "zh": "刘易斯顿",
        "ja": "ルイストン",
        "th": "ลูอิสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.3934,
        "lng": -116.9934
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12596,
      "name": "Pirapora",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -17.3478,
        "lng": -44.9436
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12577,
      "name": "Guiuan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.0333,
        "lng": 125.7333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12602,
      "name": "Olimpia",
      "names": {
        "zh": "奥林匹亚",
        "th": "โอลิมเปีย"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.7369,
        "lng": -48.915
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12445,
      "name": "Keighley",
      "names": {
        "zh": "基斯利",
        "th": "ไคลีย์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.867,
        "lng": -1.911
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12613,
      "name": "Millcreek",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 42.0861,
        "lng": -80.1193
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12614,
      "name": "Grand Island",
      "names": {
        "es": "Gran isla",
        "fr": "Grande île",
        "zh": "大岛",
        "de": "Große Insel",
        "it": "Grande Isola",
        "id": "Pulau Grand",
        "ja": "グランドアイランド",
        "th": "เกาะแกรนด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.9214,
        "lng": -98.3584
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12618,
      "name": "Palm Desert",
      "names": {
        "es": "Desierto de palma",
        "fr": "Palmer",
        "zh": "棕榈沙漠",
        "de": "Palmwüste",
        "it": "Palma deserto",
        "id": "Gurun",
        "ja": "パーム砂漠",
        "th": "ฝ่ามือ",
        "pt": "Deserto de palma"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.7378,
        "lng": -116.3695
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12617,
      "name": "Tame",
      "names": {
        "fr": "Apprivoiser",
        "zh": "驯服",
        "de": "Zähmen",
        "it": "Domare",
        "id": "Jinak",
        "ja": "飼いならされた",
        "th": "เชื่อง"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4583,
        "lng": -71.7447
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12619,
      "name": "Cangucu",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -31.395,
        "lng": -52.6758
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12611,
      "name": "Sao Bento do Una",
      "names": {
        "zh": "圣本站做UNA",
        "ja": "サンベントはええです",
        "th": "Sao Bento ทำ UNA",
        "pt": "São Bento do Una"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -8.5228,
        "lng": -36.4439
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5933,
      "name": "Amalapuram",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.5833,
        "lng": 82.0167
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12525,
      "name": "Tokmok",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 42.839,
        "lng": 75.291
      },
      "country": "Kyrgyzstan",
      "importance": 3
    },
    {
      "id": 12620,
      "name": "Acu",
      "names": {
        "zh": "急性"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.5769,
        "lng": -36.9089
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12597,
      "name": "Yangmei",
      "names": {
        "zh": "杨梅"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 22.8728,
        "lng": 112.7802
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12622,
      "name": "Vyksa",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.3194,
        "lng": 42.1731
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 5976,
      "name": "San Vicente",
      "names": {
        "zh": "圣维森特",
        "ja": "サンビセンテ"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.6333,
        "lng": -88.8
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 12497,
      "name": "Qingan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.8719,
        "lng": 127.5118
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12446,
      "name": "Estancia",
      "names": {
        "zh": "埃斯坦西亚",
        "ja": "エスタンシア"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.45,
        "lng": 123.15
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12627,
      "name": "Milford city",
      "names": {
        "fr": "Ville de Milford",
        "zh": "米尔福德城",
        "ja": "ミルフォードシティ",
        "th": "เมืองมิลฟอร์ด",
        "pt": "Cidade de milford"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.2256,
        "lng": -73.0625
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12608,
      "name": "Santo Estevao",
      "names": {
        "ja": "サントエステベオ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -12.43,
        "lng": -39.2508
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5926,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.777,
        "lng": -4.039
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12578,
      "name": "Velletri",
      "names": {
        "zh": "韦莱特里"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.6667,
        "lng": 12.7833
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12599,
      "name": "Barbosa",
      "names": {
        "zh": "巴博萨"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4375,
        "lng": -75.3306
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12629,
      "name": "Watertown",
      "names": {
        "zh": "沃特敦",
        "ja": "ウォータータウン",
        "th": "วอเตอร์ทาวน์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.9734,
        "lng": -75.9094
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12610,
      "name": "Capivari",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.995,
        "lng": -47.5078
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5788,
      "name": "Pinotepa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3412,
        "lng": -98.0537
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12585,
      "name": "Neustadt",
      "names": {
        "zh": "诺伊施塔特",
        "ja": "ノイシュタット",
        "th": "ตัดท์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3443,
        "lng": 8.1952
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12605,
      "name": "Barobo",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.55,
        "lng": 126.2
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 12626,
      "name": "Leopoldina",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -21.5319,
        "lng": -42.6428
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12518,
      "name": "Vikarabad",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.33,
        "lng": 77.9
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12606,
      "name": "Molave",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.0833,
        "lng": 123.4833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12630,
      "name": "Acopiara",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -6.095,
        "lng": -39.4528
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12621,
      "name": "Karlovac",
      "names": {
        "zh": "卡尔洛瓦茨"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.4931,
        "lng": 15.5558
      },
      "country": "Croatia",
      "importance": 3
    },
    {
      "id": 12488,
      "name": "Cava de\u0027 Tirreni",
      "names": {
        "es": "Cava de \u0027Tirreni",
        "fr": "Cava de \u0027Tirreni",
        "zh": "Cava de\u0027Tirreni",
        "de": "Cava de \u0027Tirreni",
        "it": "Cava de \u0027Tirreni",
        "id": "Cava de \u0027Tirreni",
        "ja": "カヴァデ \u0027チラレニ",
        "th": "Cava de \u0027tirreni",
        "pt": "Cava de \u0027Tirreni"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.7008,
        "lng": 14.7056
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 5838,
      "name": "Baruipur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.3654,
        "lng": 88.4325
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12634,
      "name": "Little Elm",
      "names": {
        "es": "Pequeño olmo",
        "fr": "Petit orme",
        "zh": "小榆树",
        "de": "Kleines Ulm",
        "it": "Piccolo olmo",
        "id": "Elm kecil",
        "ja": "リトルエルム",
        "th": "เอลน้อย",
        "pt": "Pequeno olmo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.1856,
        "lng": -96.929
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12474,
      "name": "Dandarah",
      "names": {},
      "countryCode": "EG",
      "latLng": {
        "lat": 26.1422,
        "lng": 32.6697
      },
      "country": "Egypt",
      "importance": 3
    },
    {
      "id": 5915,
      "name": "Dunfermline",
      "names": {
        "zh": "邓弗姆林",
        "ja": "ダンファームリン",
        "th": "ดันเฟิร์มลิน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 56.0719,
        "lng": -3.4393
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12593,
      "name": "Lucban",
      "names": {
        "zh": "卢克班"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1133,
        "lng": 121.5569
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12636,
      "name": "Chambersburg",
      "names": {
        "zh": "钱伯斯堡",
        "th": "ชอมเบอร์สบูร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.9315,
        "lng": -77.6556
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12631,
      "name": "Sagua la Grande",
      "names": {
        "ja": "サグアラグランデ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8086,
        "lng": -80.0711
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 5984,
      "name": "Revere",
      "names": {
        "zh": "里维尔",
        "ja": "リビア",
        "th": "เคารพนับถือบูชา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.4192,
        "lng": -71.0036
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12637,
      "name": "Peabody",
      "names": {
        "zh": "皮博迪",
        "ja": "ピーボディー",
        "th": "พีบอดี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.5335,
        "lng": -70.9724
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12638,
      "name": "Wylie",
      "names": {
        "zh": "威利",
        "ja": "ワイリー",
        "th": "ไวลี"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0362,
        "lng": -96.5161
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12624,
      "name": "San Bartolome",
      "names": {
        "es": "San Bartolomé",
        "zh": "圣Bartolome",
        "it": "San Bartolo",
        "ja": "サンバルトローム",
        "th": "ซานบาร์โดลอม"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 27.9254,
        "lng": -15.5726
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12639,
      "name": "Manicore",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.8089,
        "lng": -61.3
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12640,
      "name": "Beaufort",
      "names": {
        "zh": "博福特",
        "ja": "ビューフォート",
        "th": "โบฟอร์ต"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.4597,
        "lng": -80.7235
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5988,
      "name": "Aspen Hill",
      "names": {
        "zh": "阿斯彭山",
        "de": "Aspenhügel",
        "ja": "アスペンヒル",
        "th": "แอสเพนเนิน",
        "pt": "Colina de aspen"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0928,
        "lng": -77.0823
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12623,
      "name": "Kalamata",
      "names": {
        "zh": "卡拉马塔",
        "ja": "カラマタ"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.0378,
        "lng": 22.1111
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 12588,
      "name": "Naka",
      "names": {
        "zh": "那珂",
        "ja": "那珂"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.4575,
        "lng": 140.4867
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12628,
      "name": "San Severo",
      "names": {
        "zh": "圣塞弗洛",
        "th": "ซานเซเวอร์"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.6951,
        "lng": 15.3793
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12615,
      "name": "Aurora",
      "names": {
        "zh": "奥罗拉",
        "ja": "オーロラ",
        "th": "ออโรรา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.9484,
        "lng": 123.5819
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12645,
      "name": "DeSoto",
      "names": {
        "zh": "德索托"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.5992,
        "lng": -96.8633
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5980,
      "name": "Gabrovo",
      "names": {
        "zh": "加布罗沃"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.8742,
        "lng": 25.3178
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 12579,
      "name": "Wetzlar",
      "names": {
        "zh": "韦茨拉尔",
        "ja": "ヴェッツラー"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.5667,
        "lng": 8.5
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5897,
      "name": "Phra Nakhon Si Ayutthaya",
      "names": {
        "th": "พระนครศรีอยุธยา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.3594,
        "lng": 100.5761
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 12642,
      "name": "Mercedes",
      "names": {
        "zh": "梅赛德斯",
        "ja": "メルセデス",
        "th": "เมอร์เซเดส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.65,
        "lng": -59.4333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 5993,
      "name": "Helena",
      "names": {
        "fr": "Hélène",
        "zh": "海伦娜",
        "ja": "ヘレナ",
        "th": "เฮเลนา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 46.5965,
        "lng": -112.0202
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 5831,
      "name": "Mineiros",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -17.5689,
        "lng": -52.5508
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12648,
      "name": "Alvarado",
      "names": {
        "zh": "阿尔瓦拉多"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.7811,
        "lng": -95.7572
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5826,
      "name": "Clamart",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8014,
        "lng": 2.2628
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12649,
      "name": "Mafra",
      "names": {
        "zh": "马夫拉"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.1108,
        "lng": -49.805
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12595,
      "name": "Gudermes",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.35,
        "lng": 46.1
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 12600,
      "name": "Xincheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.9883,
        "lng": 112.4673
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12471,
      "name": "Ostroleka",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.0833,
        "lng": 21.5667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 12612,
      "name": "Buenavista",
      "names": {
        "zh": "布埃纳维斯塔",
        "ja": "ブエナビスタ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7,
        "lng": 122.6333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5985,
      "name": "Maha Sarakham",
      "names": {
        "zh": "马哈萨拉克",
        "ja": "マハサラカム",
        "th": "มหาสารคาม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.1772,
        "lng": 103.3008
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 12660,
      "name": "Edina",
      "names": {
        "zh": "伊代纳"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.8914,
        "lng": -93.3602
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12657,
      "name": "Kobryn",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.2167,
        "lng": 24.3667
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 12516,
      "name": "Xingcheng",
      "names": {
        "zh": "兴城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.1399,
        "lng": 118.303
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12663,
      "name": "Krasnokamensk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 50.1,
        "lng": 118.0333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12651,
      "name": "Chone",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -0.6833,
        "lng": -80.1
      },
      "country": "Ecuador",
      "importance": 3
    },
    {
      "id": 5982,
      "name": "Hrazdan",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.5,
        "lng": 44.7667
      },
      "country": "Armenia",
      "importance": 1
    },
    {
      "id": 5833,
      "name": "Aversa",
      "names": {
        "zh": "阿韦尔萨"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.973,
        "lng": 14.2065
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12566,
      "name": "Port Shepstone",
      "names": {
        "zh": "塞斯通港",
        "ja": "ポート羊岩",
        "th": "พอร์ต Shepstone",
        "pt": "Porto shepstone"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -30.7411,
        "lng": 30.4547
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 12664,
      "name": "Tutoia",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -2.7619,
        "lng": -42.2739
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12650,
      "name": "Lorica",
      "names": {
        "pt": "Carapaça"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2419,
        "lng": -75.816
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12668,
      "name": "Capitao Poco",
      "names": {
        "zh": "Cabitao Poco",
        "id": "Kapitao poco"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.745,
        "lng": -47.065
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12656,
      "name": "Usa",
      "names": {
        "es": "Estados Unidos",
        "fr": "États-Unis",
        "zh": "美国",
        "it": "Stati Uniti d\u0027America",
        "id": "Amerika Serikat",
        "ja": "アメリカ",
        "th": "สหรัฐอเมริกา",
        "pt": "EUA"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.5319,
        "lng": 131.3494
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12665,
      "name": "Canoinhas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -26.1769,
        "lng": -50.39
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5930,
      "name": "Ercolano",
      "names": {
        "zh": "埃尔科拉诺"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.8068,
        "lng": 14.3526
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12671,
      "name": "Wheaton",
      "names": {
        "zh": "惠顿",
        "ja": "ウィートン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8561,
        "lng": -88.1083
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12666,
      "name": "Uniao da Vitoria",
      "names": {
        "pt": "Uniao da Vitória"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -26.23,
        "lng": -51.0858
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5997,
      "name": "West New York",
      "names": {
        "es": "West Nueva York",
        "fr": "Ouest de New York",
        "zh": "西纽约",
        "id": "New York Barat",
        "ja": "ウェストニューヨーク",
        "th": "เวสต์นิวยอร์ก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7856,
        "lng": -74.0093
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5922,
      "name": "Madinat Hamad",
      "names": {},
      "countryCode": "BH",
      "latLng": {
        "lat": 26.1128,
        "lng": 50.5139
      },
      "country": "Bahrain",
      "importance": 3
    },
    {
      "id": 12653,
      "name": "Imaricho-ko",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 33.2647,
        "lng": 129.8808
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12644,
      "name": "Venkatagiri",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 13.9667,
        "lng": 79.5833
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12632,
      "name": "Hamtic",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7,
        "lng": 121.9833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5998,
      "name": "Hoboken",
      "names": {
        "zh": "霍博肯",
        "ja": "ホーボーケン",
        "th": "นิวเจอร์ซี่"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.7453,
        "lng": -74.0279
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12633,
      "name": "Pasaje",
      "names": {
        "zh": "帕萨赫"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -3.3269,
        "lng": -79.8049
      },
      "country": "Ecuador",
      "importance": 3
    },
    {
      "id": 12681,
      "name": "Granja",
      "names": {
        "zh": "格兰哈"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -3.12,
        "lng": -40.8258
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12667,
      "name": "San Jose Villa de Allende",
      "names": {
        "es": "San José Villa de Allende",
        "zh": "圣何塞别墅de Allende",
        "ja": "サンノゼヴィラデアジェンデ",
        "th": "ซานโฮเซ่วิลล่าเดออัลเลน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3747,
        "lng": -100.1475
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12609,
      "name": "Yaozhuangcun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.9113,
        "lng": 120.9573
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12670,
      "name": "Minami-Soma",
      "names": {
        "zh": "南-索马",
        "ja": "南-相馬",
        "th": "มินามิ-โสม"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.6422,
        "lng": 140.9572
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12661,
      "name": "San Joaquin",
      "names": {
        "es": "San Joaquín",
        "zh": "圣乔金",
        "ja": "サンジョアキン",
        "th": "ซานโจควิน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6,
        "lng": 122.0833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12584,
      "name": "Zhangjiazhuang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.1753,
        "lng": 114.7395
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12646,
      "name": "Sihor",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 21.7,
        "lng": 71.97
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12654,
      "name": "Bani",
      "names": {
        "zh": "巴尼",
        "ja": "バニ",
        "th": "ซุ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.1869,
        "lng": 119.8592
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12690,
      "name": "Lacey",
      "names": {
        "zh": "莱西",
        "ja": "レーシー",
        "th": "ลาเซย์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 47.046,
        "lng": -122.7934
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5950,
      "name": "Brentwood",
      "names": {
        "zh": "布伦特伍德",
        "ja": "ブレントウッド",
        "th": "แนชวิลล์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.6204,
        "lng": 0.305
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12643,
      "name": "Ahlen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7633,
        "lng": 7.8911
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 5999,
      "name": "Bodo",
      "names": {
        "zh": "博多"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 67.2833,
        "lng": 14.3833
      },
      "country": "Norway",
      "importance": 1
    },
    {
      "id": 12571,
      "name": "Elda",
      "names": {
        "zh": "埃尔达"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.4789,
        "lng": -0.7967
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12694,
      "name": "Summerville",
      "names": {
        "zh": "萨默维尔"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0015,
        "lng": -80.1799
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12688,
      "name": "Sertolovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 60.1417,
        "lng": 30.2119
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 6000,
      "name": "Mongu",
      "names": {
        "zh": "芒古"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -15.2796,
        "lng": 23.12
      },
      "country": "Zambia",
      "importance": 1
    },
    {
      "id": 12693,
      "name": "Caetite",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -14.0689,
        "lng": -42.475
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12689,
      "name": "President Roxas",
      "names": {
        "es": "Presidente Roxas",
        "fr": "Président Roxas",
        "zh": "罗克斯总统",
        "de": "Präsident Roxas",
        "it": "Presidente Roxas",
        "id": "Presiden Roxas",
        "ja": "ロキサス大統領",
        "th": "ประธานาธิบดี Roxas",
        "pt": "Presidente Roxas"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.1544,
        "lng": 125.0558
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12676,
      "name": "Burauen",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.9833,
        "lng": 124.9
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12502,
      "name": "Tlacotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6882,
        "lng": -97.6489
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12695,
      "name": "Vyazma",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.2103,
        "lng": 34.285
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12683,
      "name": "An Nabk",
      "names": {
        "es": "Un nabk",
        "fr": "Un nabk",
        "zh": "一个nabk",
        "de": "Ein Nabk",
        "it": "Un nabk",
        "id": "Nabk",
        "ja": "ナッキー",
        "th": "nabk",
        "pt": "Um nabk"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.025,
        "lng": 36.7344
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 12692,
      "name": "Qabqa",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.2814,
        "lng": 100.6131
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12697,
      "name": "Boa Viagem",
      "names": {
        "fr": "Boa viagème",
        "zh": "蟒蛇威犹书",
        "ja": "ボアビアゲム",
        "th": "VIAGEM BOA"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -5.1278,
        "lng": -39.7319
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12672,
      "name": "Thongwa",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 16.7547,
        "lng": 96.5193
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 12682,
      "name": "Pacora",
      "names": {
        "zh": "帕科拉"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 9.08,
        "lng": -79.28
      },
      "country": "Panama",
      "importance": 2
    },
    {
      "id": 12532,
      "name": "Baduria",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 22.74,
        "lng": 88.79
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12679,
      "name": "Jaen",
      "names": {
        "es": "Jaén",
        "zh": "哈恩"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -5.6992,
        "lng": -78.8009
      },
      "country": "Peru",
      "importance": 3
    },
    {
      "id": 12574,
      "name": "Dauis",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.6253,
        "lng": 123.8658
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 12659,
      "name": "Mongagua",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -24.0869,
        "lng": -46.6289
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12669,
      "name": "Colmenar Viejo",
      "names": {
        "zh": "科尔马尔Viejo"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.6589,
        "lng": -3.7658
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12691,
      "name": "Claveria",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.61,
        "lng": 124.8947
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 5972,
      "name": "Pursat",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 12.5337,
        "lng": 103.9167
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 12583,
      "name": "Frechen",
      "names": {
        "zh": "弗雷兴"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9167,
        "lng": 6.8167
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12641,
      "name": "Arsenyev",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.1667,
        "lng": 133.2667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12625,
      "name": "Passau",
      "names": {
        "zh": "帕绍",
        "ja": "パッサウ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.5667,
        "lng": 13.4667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5945,
      "name": "Padangpanjang",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.4644,
        "lng": 100.4008
      },
      "country": "Indonesia",
      "importance": 3
    },
    {
      "id": 5872,
      "name": "Altrincham",
      "names": {},
      "countryCode": "GB",
      "latLng": {
        "lat": 53.3838,
        "lng": -2.3547
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12702,
      "name": "Parsippany-Troy Hills",
      "names": {
        "es": "Parsiptany-Troy Hills",
        "it": "Colline di parrsippany-troy",
        "id": "Bukit Parsippany-Troy",
        "pt": "Parsippany-troy colinas"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.8601,
        "lng": -74.4238
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12655,
      "name": "Jangaon",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.72,
        "lng": 79.18
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12684,
      "name": "Miyakojima",
      "names": {
        "zh": "宫古岛",
        "ja": "宮古島"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 24.8056,
        "lng": 125.2811
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 6005,
      "name": "Oak Park",
      "names": {
        "es": "Parque de roble",
        "fr": "Parc de chêne",
        "zh": "橡树园",
        "de": "Eichenpark",
        "id": "Pohon ek",
        "ja": "オークパーク",
        "th": "สวนโอ๊ค",
        "pt": "Parque de carvalho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.8872,
        "lng": -87.7899
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12587,
      "name": "Anzio",
      "names": {
        "zh": "安齐奥"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.4472,
        "lng": 12.6283
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12708,
      "name": "Navirai",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -23.065,
        "lng": -54.1908
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12677,
      "name": "Ibajay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 11.8167,
        "lng": 122.1667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12687,
      "name": "Mandamari",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.9822,
        "lng": 79.4811
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 792,
      "name": "Hamilton",
      "names": {
        "zh": "汉密尔顿",
        "ja": "ハミルトン",
        "th": "แฮมิลตัน"
      },
      "countryCode": "BM",
      "latLng": {
        "lat": 32.2942,
        "lng": -64.7839
      },
      "country": "Bermuda",
      "importance": 0
    },
    {
      "id": 12647,
      "name": "Sison",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.1667,
        "lng": 120.5167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12713,
      "name": "Welland",
      "names": {
        "zh": "韦兰"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 42.9833,
        "lng": -79.2333
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5973,
      "name": "Marondera",
      "names": {
        "zh": "马龙德拉"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.1833,
        "lng": 31.55
      },
      "country": "Zimbabwe",
      "importance": 1
    },
    {
      "id": 12616,
      "name": "Akbou",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.4667,
        "lng": 4.5333
      },
      "country": "Algeria",
      "importance": 3
    },
    {
      "id": 5848,
      "name": "Sartrouville",
      "names": {
        "zh": "萨特鲁维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9372,
        "lng": 2.1644
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12589,
      "name": "Acireale",
      "names": {
        "zh": "阿奇雷亚莱"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.6125,
        "lng": 15.1656
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12711,
      "name": "Delmiro Gouveia",
      "names": {
        "ja": "デルミロGouveia"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.3858,
        "lng": -37.9958
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12701,
      "name": "Marechal Deodoro",
      "names": {
        "fr": "MARCHAL DEODORO",
        "de": "Marachales Deodoro",
        "it": "DEODORO MARECHAL",
        "pt": "Deodoro Marechal"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.71,
        "lng": -35.895
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5748,
      "name": "Fontenay-sous-Bois",
      "names": {
        "zh": "丰特内-苏布瓦",
        "ja": "fontenay-スー・ボワ",
        "th": "fontenay-Sous-บัว"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8517,
        "lng": 2.4772
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5934,
      "name": "Lancaster",
      "names": {
        "zh": "兰开斯特",
        "ja": "ランカスター",
        "th": "แลงคาสเตอร์"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 54.047,
        "lng": -2.801
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5929,
      "name": "Lautoka",
      "names": {
        "zh": "劳托卡"
      },
      "countryCode": "FJ",
      "latLng": {
        "lat": -17.6242,
        "lng": 177.4528
      },
      "country": "Fiji",
      "importance": 3
    },
    {
      "id": 12704,
      "name": "Sape",
      "names": {
        "zh": "萨佩"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.095,
        "lng": -35.2328
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12709,
      "name": "Taxco de Alarcon",
      "names": {
        "id": "Takco de alarcon",
        "th": "แท็กซี่เดออะลีร์สัน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5564,
        "lng": -99.605
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12715,
      "name": "Basankusu",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": 1.2222,
        "lng": 19.8028
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 12678,
      "name": "Naguilian",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.5333,
        "lng": 120.4
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12716,
      "name": "Novovolynsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.7333,
        "lng": 24.1667
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 6006,
      "name": "Jinotepe",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 11.85,
        "lng": -86.2
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 12662,
      "name": "Wolfenbuttel",
      "names": {
        "es": "Wolfenbüttel",
        "de": "Wolfenbüttel",
        "it": "Wolfenbüttel"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1622,
        "lng": 10.5369
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12718,
      "name": "Cruz del Eje",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -30.7333,
        "lng": -64.8
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 12719,
      "name": "Portel",
      "names": {
        "zh": "波特尔"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.9358,
        "lng": -50.8208
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12686,
      "name": "Horishni Plavni",
      "names": {
        "fr": "Horrishni plavni"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0107,
        "lng": 33.6562
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 12675,
      "name": "Alesund",
      "names": {
        "es": "Ålesund",
        "fr": "Ålesund",
        "zh": "奥勒松",
        "it": "Ålesund",
        "id": "Ålesund",
        "th": "เอลซุนด์"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 62.4723,
        "lng": 6.1549
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 12685,
      "name": "Bato",
      "names": {
        "zh": "马头"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.3561,
        "lng": 123.3639
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12723,
      "name": "Maricopa",
      "names": {
        "zh": "马里科帕"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.0404,
        "lng": -112.0006
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12724,
      "name": "Stratford",
      "names": {
        "zh": "斯特拉特福",
        "ja": "ストラットフォード",
        "th": "สแตรทฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.207,
        "lng": -73.1305
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12722,
      "name": "Buique",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -8.6233,
        "lng": -37.1564
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5958,
      "name": "Shiraoka",
      "names": {
        "zh": "白冈"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0189,
        "lng": 139.6769
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12721,
      "name": "Belorechensk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.7667,
        "lng": 39.8667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 5867,
      "name": "Acatzingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9817,
        "lng": -97.7822
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12699,
      "name": "Diu",
      "names": {
        "zh": "第乌",
        "ja": "ディーウ",
        "th": "ดีอู"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.715,
        "lng": 70.9844
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12607,
      "name": "Hervey Bay",
      "names": {
        "zh": "赫维湾",
        "it": "BAY BAY",
        "id": "Teluk Hervey",
        "ja": "ハーヴィーベイ",
        "th": "อ่าว Hervey"
      },
      "countryCode": "AU",
      "latLng": {
        "lat": -25.29,
        "lng": 152.84
      },
      "country": "Australia",
      "importance": 3
    },
    {
      "id": 12658,
      "name": "Poprad",
      "names": {
        "zh": "波普拉德",
        "ja": "ポプラト"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.05,
        "lng": 20.3
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 12726,
      "name": "Albu Kamal",
      "names": {
        "ja": "アルブカマール",
        "th": "อัลบูคามาล"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.45,
        "lng": 40.9186
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 12732,
      "name": "Lompoc",
      "names": {
        "zh": "隆波克",
        "th": "ลอมพอก"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.6618,
        "lng": -120.4714
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 6011,
      "name": "Lamia",
      "names": {
        "zh": "拉米亚",
        "ja": "鬼女",
        "th": "นครราชสีมา"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.8972,
        "lng": 22.4311
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 5992,
      "name": "Takeo",
      "names": {
        "zh": "武雄",
        "ja": "タケオ"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 10.9833,
        "lng": 104.7833
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 5941,
      "name": "Shiogama",
      "names": {
        "zh": "盐釜"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 38.3144,
        "lng": 141.0222
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5828,
      "name": "Huamantla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3133,
        "lng": -97.9228
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12730,
      "name": "Trinidad",
      "names": {
        "fr": "Trinité",
        "zh": "特立尼达和多巴哥",
        "ja": "トリニダード",
        "th": "ตรินิแดด"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.8,
        "lng": -79.9667
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 12729,
      "name": "San Onofre",
      "names": {
        "zh": "圣onofre",
        "ja": "サンオンフレ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.7372,
        "lng": -75.5256
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12710,
      "name": "Leon",
      "names": {
        "es": "León",
        "zh": "莱昂",
        "ja": "レオン",
        "th": "ลีออง"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.7808,
        "lng": 122.3894
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12739,
      "name": "Howell",
      "names": {
        "zh": "豪威尔",
        "ja": "ハウエル",
        "th": "ธรรมด๊าธรรมดา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.1819,
        "lng": -74.1977
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12736,
      "name": "Eilat",
      "names": {
        "zh": "埃拉特",
        "th": "ไอแลต"
      },
      "countryCode": "IL",
      "latLng": {
        "lat": 29.55,
        "lng": 34.95
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 5951,
      "name": "Muban Saeng Bua Thong",
      "names": {
        "es": "MUBAN SAENG BUA TANGA",
        "zh": "穆坎萨恩布瓦丁字裤",
        "ja": "ムバン・ソネ・ブア・トーン",
        "th": "Muban แสงบัวทอง",
        "pt": "Muban saeng bua tanga"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.9424,
        "lng": 100.3913
      },
      "country": "Thailand",
      "importance": 3
    },
    {
      "id": 12731,
      "name": "Porto Feliz",
      "names": {
        "ja": "ポルトフェリス"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -23.215,
        "lng": -47.5239
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12734,
      "name": "Timashevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.6167,
        "lng": 38.9333
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 12714,
      "name": "Jaguariuna",
      "names": {
        "pt": "Jaguariúna"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.68,
        "lng": -46.99
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12706,
      "name": "Ibbenburen",
      "names": {
        "es": "Ibbenbüren",
        "zh": "伊本比伦",
        "de": "Ibbenbüren",
        "it": "Ibbenbüren"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2778,
        "lng": 7.7167
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12741,
      "name": "Gwadar",
      "names": {
        "zh": "瓜达尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 25.1264,
        "lng": 62.3225
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 12747,
      "name": "Kentwood",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 42.8852,
        "lng": -85.5925
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12728,
      "name": "Izumi",
      "names": {
        "zh": "泉",
        "ja": "泉",
        "th": "อิซูมิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.0906,
        "lng": 130.3528
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12727,
      "name": "Wangjiazhai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.6895,
        "lng": 104.8043
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12744,
      "name": "El Bagre",
      "names": {
        "ja": "エルバグレ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.6047,
        "lng": -74.8086
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12652,
      "name": "Tomiya",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 38.4,
        "lng": 140.8953
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12635,
      "name": "Padre Garcia",
      "names": {
        "ja": "パドレガルシア"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8833,
        "lng": 121.2167
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 6014,
      "name": "Vila Real",
      "names": {
        "fr": "Vila réel",
        "zh": "Vila真实",
        "it": "Vila reale",
        "id": "Vila nyata",
        "ja": "ビラリアル",
        "th": "vila จริง"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2953,
        "lng": -7.7461
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 12720,
      "name": "Granadilla de Abona",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 28.1167,
        "lng": -16.5833
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12707,
      "name": "Kleve",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.79,
        "lng": 6.14
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5879,
      "name": "Melo",
      "names": {
        "zh": "梅洛",
        "ja": "メロ"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -32.3667,
        "lng": -54.1833
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 12754,
      "name": "Levittown",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.1537,
        "lng": -74.853
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12755,
      "name": "Itupiranga",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -5.135,
        "lng": -49.3269
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5924,
      "name": "M.A. Rasulzada",
      "names": {
        "ja": "rasulzada"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.4344,
        "lng": 49.8336
      },
      "country": "Azerbaijan",
      "importance": 3
    },
    {
      "id": 12749,
      "name": "Covilha",
      "names": {
        "es": "Covilhã",
        "zh": "科维良",
        "de": "Covilhã",
        "id": "Covilhã",
        "pt": "Covilhã"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.2833,
        "lng": -7.5
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 5817,
      "name": "Crosby",
      "names": {
        "zh": "克罗斯比",
        "ja": "クロスビー",
        "th": "แพนด้า"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4872,
        "lng": -3.0343
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5932,
      "name": "Rijswijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0456,
        "lng": 4.33
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12761,
      "name": "Caleta Olivia",
      "names": {
        "ja": "カレタオリビア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -46.4333,
        "lng": -67.5333
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 12762,
      "name": "Sierra Vista",
      "names": {
        "zh": "塞拉维斯塔",
        "ja": "シエラビスタ",
        "th": "เซียร์ราวิสต้า"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 31.563,
        "lng": -110.3153
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12740,
      "name": "Caldas da Rainha",
      "names": {
        "ja": "カルダスダレインハ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4069,
        "lng": -9.1363
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 12743,
      "name": "Caramoan",
      "names": {
        "zh": "卡拉莫安"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7707,
        "lng": 123.8631
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12748,
      "name": "Berriozabal",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7985,
        "lng": -93.2716
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12698,
      "name": "Tamboril",
      "names": {
        "zh": "坦博里尔"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.48,
        "lng": -70.6
      },
      "country": "Dominican Republic",
      "importance": 3
    },
    {
      "id": 12674,
      "name": "Beykoz",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.125,
        "lng": 29.1056
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 12705,
      "name": "Girardota",
      "names": {
        "zh": "希拉多塔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3769,
        "lng": -75.4461
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6030,
      "name": "Levittown",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.7241,
        "lng": -73.5125
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12696,
      "name": "Alaminos",
      "names": {
        "zh": "阿拉米诺斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.0635,
        "lng": 121.2451
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12760,
      "name": "Solnechnogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.1844,
        "lng": 36.995
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12751,
      "name": "Pola de Siero",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 43.3833,
        "lng": -5.65
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12756,
      "name": "Mulongo",
      "names": {
        "zh": "穆隆戈"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -7.8349,
        "lng": 26.9985
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 12758,
      "name": "Huazangsi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.9836,
        "lng": 103.1265
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12712,
      "name": "Civitavecchia",
      "names": {
        "zh": "奇维塔韦基亚",
        "ja": "チヴィタヴェッキア"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.1,
        "lng": 11.8
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12774,
      "name": "Madison",
      "names": {
        "zh": "麦迪逊",
        "ja": "マディソン",
        "th": "เมดิสัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 34.7114,
        "lng": -86.7626
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12775,
      "name": "Smyrna",
      "names": {
        "zh": "士麦那",
        "ja": "スマーナ",
        "th": "ในสเมอร์นา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.9722,
        "lng": -86.5253
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12735,
      "name": "Calvia",
      "names": {
        "es": "Calvià",
        "zh": "卡尔维亚",
        "th": "คาลเวีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.5657,
        "lng": 2.5062
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12771,
      "name": "Sao Miguel do Guama",
      "names": {
        "zh": "圣米格尔做了古马",
        "ja": "サンミゲルドグアマ",
        "pt": "São Miguel do Guama"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -1.6269,
        "lng": -47.4828
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12778,
      "name": "North Bay",
      "names": {
        "zh": "北湾",
        "de": "Nordbucht",
        "it": "Baia del Nord",
        "ja": "ノースベイ",
        "th": "อ่าวเหนือ"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.3,
        "lng": -79.45
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 12767,
      "name": "Campo Belo",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -20.8969,
        "lng": -45.2769
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12780,
      "name": "Glendora",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 34.1449,
        "lng": -117.8468
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12765,
      "name": "Tulsipur",
      "names": {
        "zh": "杜尔西布尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 28.1278,
        "lng": 82.2956
      },
      "country": "Nepal",
      "importance": 3
    },
    {
      "id": 12742,
      "name": "Bando",
      "names": {
        "zh": "半岛",
        "ja": "坂東"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.0483,
        "lng": 139.8889
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5880,
      "name": "Barri",
      "names": {
        "zh": "巴里"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.405,
        "lng": -3.27
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12737,
      "name": "Parobe",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -29.6289,
        "lng": -50.835
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12786,
      "name": "Burien",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 47.4762,
        "lng": -122.3394
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12717,
      "name": "Abdul Hakim",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 30.5522,
        "lng": 72.1278
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 12764,
      "name": "Mazara del Vallo",
      "names": {
        "ja": "マザラ・デルバロ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.6517,
        "lng": 12.5875
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12768,
      "name": "Guapimirim",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5369,
        "lng": -42.9819
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12782,
      "name": "Zhexiang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.2591,
        "lng": 98.2826
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 6028,
      "name": "Sombor",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.78,
        "lng": 19.12
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 12777,
      "name": "Bantacan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.5333,
        "lng": 126.1333
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 791,
      "name": "Port-Vila",
      "names": {
        "zh": "端口维拉",
        "it": "Port Vila",
        "ja": "ポートビラ",
        "th": "พอร์ตวิลา",
        "pt": "Port Vila"
      },
      "countryCode": "VU",
      "latLng": {
        "lat": -17.7333,
        "lng": 168.3167
      },
      "country": "Vanuatu",
      "importance": 0
    },
    {
      "id": 5956,
      "name": "Willenhall",
      "names": {
        "zh": "威伦霍尔"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 52.5798,
        "lng": -2.0605
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 6012,
      "name": "Mangochi",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -14.4722,
        "lng": 35.2639
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 12790,
      "name": "Trairi",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -3.2778,
        "lng": -39.2689
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12788,
      "name": "Ribeira do Pombal",
      "names": {
        "fr": "Ribeira faire pombale",
        "zh": "Ribeira做庞巴美",
        "th": "Ribeira ทำ Pombal"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -10.8339,
        "lng": -38.5358
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5955,
      "name": "Cagnes-sur-Mer",
      "names": {
        "zh": "cagnes-滨海",
        "ja": "cagnes \u003dシュル\u003dメール",
        "th": "cagnes-ซูร์ mer"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6644,
        "lng": 7.1489
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 5968,
      "name": "Bayonne",
      "names": {
        "es": "Bayona",
        "zh": "巴约讷",
        "ja": "バヨンヌ",
        "th": "บาย"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.49,
        "lng": -1.48
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 5925,
      "name": "Bootle",
      "names": {
        "zh": "布特尔"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 53.4457,
        "lng": -2.9891
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 6032,
      "name": "Perth Amboy",
      "names": {
        "zh": "珀斯安博",
        "it": "Amboy di Perth",
        "id": "Amboy Perth",
        "ja": "パースの雰囲気",
        "th": "แอมบอยเพิร์ท"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.5203,
        "lng": -74.2724
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5827,
      "name": "Stratford",
      "names": {
        "zh": "斯特拉特福",
        "ja": "ストラットフォード",
        "th": "สแตรทฟอร์ด"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.5423,
        "lng": -0.0026
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12789,
      "name": "Uzlovaya",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.9791,
        "lng": 38.1601
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12781,
      "name": "Pau d\u0027Alho",
      "names": {
        "ja": "Pau D\u0027hoo"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.8969,
        "lng": -35.18
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12796,
      "name": "Herriman",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 40.4899,
        "lng": -112.0171
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5806,
      "name": "Folkestone",
      "names": {
        "zh": "福克斯通"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.081,
        "lng": 1.166
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12799,
      "name": "Tadmur",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.56,
        "lng": 38.2672
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 5795,
      "name": "Samut Prakan",
      "names": {
        "zh": "萨默库尔帕金",
        "ja": "サムットプラカン",
        "th": "สมุทรปราการ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6004,
        "lng": 100.5964
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 12802,
      "name": "Farmington",
      "names": {
        "zh": "法明顿",
        "ja": "ファーミントン",
        "th": "ฟาร์มิงตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.7555,
        "lng": -108.1823
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12803,
      "name": "New Bern",
      "names": {
        "es": "New Berna",
        "fr": "Nouveau berne",
        "zh": "新伯尔尼",
        "de": "Neuer Bern",
        "it": "New Berna",
        "id": "Belern baru",
        "ja": "新しいベルン",
        "th": "ใหม่เบิร์น",
        "pt": "Novo berna"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.0955,
        "lng": -77.0723
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12804,
      "name": "Humaita",
      "names": {
        "zh": "乌迈塔",
        "pt": "Humaitá"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -7.5061,
        "lng": -63.0208
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 6013,
      "name": "Lankaran",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.7536,
        "lng": 48.8511
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 12773,
      "name": "Guarne",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2792,
        "lng": -75.4419
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12806,
      "name": "Segovia",
      "names": {
        "fr": "Ségovie",
        "zh": "塞哥维亚",
        "ja": "セゴビア",
        "th": "เซโกเวีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.9481,
        "lng": -4.1183
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 6035,
      "name": "Kardzhali",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 41.6447,
        "lng": 25.375
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 12680,
      "name": "Torrelavega",
      "names": {},
      "countryCode": "ES",
      "latLng": {
        "lat": 43.3531,
        "lng": -4.0458
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 6040,
      "name": "Placentia",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.8807,
        "lng": -117.8553
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5854,
      "name": "Sevran",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9333,
        "lng": 2.5333
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12795,
      "name": "Montijo",
      "names": {
        "zh": "蒙蒂霍"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7049,
        "lng": -8.9757
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 12798,
      "name": "Severomorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 69.0692,
        "lng": 33.4167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12812,
      "name": "Hinesville",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 31.8247,
        "lng": -81.6135
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5994,
      "name": "Vejle",
      "names": {},
      "countryCode": "DK",
      "latLng": {
        "lat": 55.709,
        "lng": 9.535
      },
      "country": "Denmark",
      "importance": 1
    },
    {
      "id": 6024,
      "name": "Eger",
      "names": {
        "zh": "埃格尔",
        "th": "เอเกอร์"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.8989,
        "lng": 20.3747
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 6038,
      "name": "Seguela",
      "names": {
        "zh": "塞盖拉"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 7.9611,
        "lng": -6.6731
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 12763,
      "name": "Woerden",
      "names": {
        "zh": "武尔登"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0858,
        "lng": 4.8833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 5964,
      "name": "Korce",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 40.6167,
        "lng": 20.7667
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 5920,
      "name": "Mollet",
      "names": {
        "zh": "莫列特"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.5356,
        "lng": 2.2107
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12770,
      "name": "Rovigo",
      "names": {
        "zh": "罗维戈",
        "ja": "ロヴィーゴ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.0809,
        "lng": 11.794
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 12808,
      "name": "Utrera",
      "names": {
        "zh": "乌特雷拉"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 37.183,
        "lng": -5.767
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12759,
      "name": "Tsukubamirai",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.9631,
        "lng": 140.0372
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12725,
      "name": "Villareal",
      "names": {
        "zh": "比利亚雷亚尔"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 39.9378,
        "lng": -0.1014
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12700,
      "name": "Pordenone",
      "names": {
        "zh": "波代诺内",
        "ja": "ポルデノーネ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.9626,
        "lng": 12.6563
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 6025,
      "name": "Jihlava",
      "names": {
        "zh": "伊赫拉瓦"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.4003,
        "lng": 15.5906
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 5913,
      "name": "Fuchu",
      "names": {
        "zh": "府中",
        "ja": "府中"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.3925,
        "lng": 132.5044
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12779,
      "name": "Knysna",
      "names": {
        "zh": "克尼斯纳"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -34.0356,
        "lng": 23.0489
      },
      "country": "South Africa",
      "importance": 3
    },
    {
      "id": 6043,
      "name": "Sar-e Pul",
      "names": {
        "es": "Sar-e pulg"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 36.2214,
        "lng": 65.9278
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 12784,
      "name": "Ocampo",
      "names": {
        "zh": "奥坎波"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5594,
        "lng": 123.3761
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12825,
      "name": "Beaumont",
      "names": {
        "zh": "博蒙特",
        "ja": "ボーモント",
        "th": "โบมอนต์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9076,
        "lng": -116.9766
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12819,
      "name": "Asenovgrad",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.0082,
        "lng": 24.8773
      },
      "country": "Bulgaria",
      "importance": 3
    },
    {
      "id": 12807,
      "name": "San Narciso",
      "names": {
        "zh": "圣纳卡多",
        "ja": "サンナルシソ",
        "th": "ซานโนอาร์ซา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.5677,
        "lng": 122.5667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12813,
      "name": "Snezhinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0833,
        "lng": 60.7333
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12827,
      "name": "Collierville",
      "names": {
        "th": "คอเลียร์วิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.0471,
        "lng": -89.6988
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12823,
      "name": "Arles",
      "names": {
        "zh": "阿尔勒",
        "ja": "アルル",
        "th": "อาร์ลส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6767,
        "lng": 4.6278
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 12753,
      "name": "Shimencun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 30.6263,
        "lng": 120.4409
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12745,
      "name": "Battipaglia",
      "names": {
        "zh": "巴蒂帕利亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.6167,
        "lng": 14.9833
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12817,
      "name": "Nanao",
      "names": {
        "zh": "南澳"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 37.0333,
        "lng": 136.9667
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12831,
      "name": "West Lafayette",
      "names": {
        "zh": "西拉斐特",
        "de": "West-Lafayette",
        "id": "Lafayette Barat",
        "ja": "ウェストラファイエット",
        "th": "ลาฟาแยตตะวันตก",
        "pt": "Lafayette ocidental"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.4432,
        "lng": -86.9239
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 6044,
      "name": "Komotini",
      "names": {
        "id": "Komotiní"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 41.1167,
        "lng": 25.4
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 12785,
      "name": "Tucuru",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3,
        "lng": -90.0667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 12821,
      "name": "Timbauba",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -7.505,
        "lng": -35.3178
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 5927,
      "name": "Corbeil-Essonnes",
      "names": {
        "zh": "花篮装饰-埃索讷",
        "it": "Corbeil essonnes-"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6139,
        "lng": 2.482
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12836,
      "name": "Florissant",
      "names": {
        "zh": "弗洛里森特"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.7996,
        "lng": -90.3269
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12750,
      "name": "Bad Kreuznach",
      "names": {
        "es": "Mal kreuznach",
        "zh": "坏kreuznach",
        "de": "Böse Kreuznach",
        "id": "Kreuznach buruk",
        "th": "Kreuznach ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.8469,
        "lng": 7.8669
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12837,
      "name": "Hoffman Estates",
      "names": {
        "fr": "Hoffman domestiques",
        "zh": "霍夫曼庄园",
        "ja": "ホフマンエステート",
        "pt": "Estates Hoffman"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.0639,
        "lng": -88.1468
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 5948,
      "name": "Cumbernauld",
      "names": {
        "zh": "坎伯诺尔德"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.945,
        "lng": -3.994
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 5981,
      "name": "Rho",
      "names": {
        "zh": "罗镇",
        "ja": "ロー",
        "th": "โร"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5333,
        "lng": 9.0333
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12824,
      "name": "Esher",
      "names": {
        "zh": "伊舍"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.3691,
        "lng": -0.365
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12738,
      "name": "Metpalli",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 18.8297,
        "lng": 78.5878
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12757,
      "name": "Karvina",
      "names": {
        "fr": "Karviná",
        "zh": "卡尔维纳",
        "it": "Karviná"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 49.8542,
        "lng": 18.5428
      },
      "country": "Czechia",
      "importance": 3
    },
    {
      "id": 12834,
      "name": "Kalamansig",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.5667,
        "lng": 124.05
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12842,
      "name": "Queen Creek",
      "names": {
        "fr": "Reine Creek",
        "zh": "女王溪",
        "de": "Queencreek",
        "id": "Ratu",
        "ja": "クイーンクリーク",
        "pt": "Rainha riacho"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.2386,
        "lng": -111.6343
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 6053,
      "name": "Aliso Viejo",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 33.5792,
        "lng": -117.7289
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12840,
      "name": "Kirishi",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 59.4497,
        "lng": 32.0086
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12811,
      "name": "Repalle",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 16.02,
        "lng": 80.85
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 12793,
      "name": "Cuyotenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5421,
        "lng": -91.5723
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 12673,
      "name": "Huaiyang",
      "names": {
        "zh": "淮阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7558,
        "lng": 114.5364
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12733,
      "name": "Mabini",
      "names": {
        "zh": "马比尼"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7167,
        "lng": 120.9
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12835,
      "name": "Kyotango",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.6242,
        "lng": 135.0611
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12841,
      "name": "Miyako",
      "names": {
        "zh": "宫古",
        "ja": "都",
        "th": "มิยาโกะ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 39.6414,
        "lng": 141.9572
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12828,
      "name": "Campos do Jordao",
      "names": {
        "zh": "坎波斯做jordao",
        "it": "Campos fai Jordao",
        "ja": "カンポスヨルダオ"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.7394,
        "lng": -45.5914
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12850,
      "name": "Kannapolis",
      "names": {
        "zh": "坎纳波利斯"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.4764,
        "lng": -80.6403
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12752,
      "name": "Livingston",
      "names": {
        "fr": "De Livingston",
        "zh": "利文斯顿",
        "ja": "リビングストン",
        "th": "ลิฟวิงสตัน"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 55.8834,
        "lng": -3.5157
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12849,
      "name": "Bani Walid",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 31.7455,
        "lng": 13.9835
      },
      "country": "Libya",
      "importance": 3
    },
    {
      "id": 5978,
      "name": "Vaulx-en-Velin",
      "names": {
        "zh": "vaulx-连接velin",
        "ja": "vaulx-専用velin",
        "th": "vaulx-ห้อง velin"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.7768,
        "lng": 4.9186
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12805,
      "name": "Cheremkhovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 53.15,
        "lng": 103.0667
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12853,
      "name": "Turtkul",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.55,
        "lng": 61.0
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 12839,
      "name": "Ye",
      "names": {
        "fr": "Vous",
        "zh": "你们",
        "ja": "あなたがたは",
        "th": "พวกเจ้า"
      },
      "countryCode": "MM",
      "latLng": {
        "lat": 15.2533,
        "lng": 97.8679
      },
      "country": "Myanmar",
      "importance": 3
    },
    {
      "id": 12855,
      "name": "Beloeil",
      "names": {
        "zh": "伯勒伊"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.5667,
        "lng": -73.2
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 6027,
      "name": "Whangarei",
      "names": {
        "zh": "旺格雷",
        "ja": "ファンガレイ"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -35.725,
        "lng": 174.3236
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 12815,
      "name": "Zabid",
      "names": {
        "zh": "宰比德"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.2,
        "lng": 43.3167
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 12769,
      "name": "Chieti",
      "names": {
        "zh": "基耶蒂",
        "ja": "キエーティ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.3511,
        "lng": 14.1674
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 12845,
      "name": "Goshogawara",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 40.8081,
        "lng": 140.44
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12826,
      "name": "Goslar",
      "names": {
        "zh": "戈斯拉尔",
        "ja": "ゴスラー"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.906,
        "lng": 10.4292
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6054,
      "name": "Jarash",
      "names": {},
      "countryCode": "JO",
      "latLng": {
        "lat": 32.2723,
        "lng": 35.8914
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 12859,
      "name": "Mahalapye",
      "names": {},
      "countryCode": "BW",
      "latLng": {
        "lat": -23.1041,
        "lng": 26.8142
      },
      "country": "Botswana",
      "importance": 3
    },
    {
      "id": 5811,
      "name": "Zumpango",
      "names": {
        "zh": "孙潘戈"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7969,
        "lng": -99.0992
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12861,
      "name": "Belleville",
      "names": {
        "zh": "贝尔维尔",
        "ja": "ベルビル",
        "th": "แบล"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 44.1667,
        "lng": -77.3833
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 5908,
      "name": "Rio Tinto",
      "names": {
        "zh": "里奥蒂托",
        "ja": "リオチント"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1833,
        "lng": -8.5667
      },
      "country": "Portugal",
      "importance": 3
    },
    {
      "id": 12703,
      "name": "Roman",
      "names": {
        "es": "Romano",
        "fr": "Romaine",
        "zh": "罗马",
        "de": "Römische",
        "it": "Romano",
        "ja": "ローマ",
        "th": "โรมัน",
        "pt": "Romana"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.93,
        "lng": 26.93
      },
      "country": "Romania",
      "importance": 3
    },
    {
      "id": 12863,
      "name": "Methuen Town",
      "names": {
        "fr": "Town Methuen",
        "zh": "Methuen镇",
        "de": "Methuen-Stadt",
        "it": "Città di Methuen",
        "id": "Kota methuen",
        "ja": "メスレンの町",
        "th": "เมืองเมธูน",
        "pt": "Cidade methuen"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 42.734,
        "lng": -71.1889
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12862,
      "name": "Beruniy",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.6833,
        "lng": 60.75
      },
      "country": "Uzbekistan",
      "importance": 3
    },
    {
      "id": 12809,
      "name": "Gummersbach",
      "names": {
        "zh": "古默斯巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0333,
        "lng": 7.5667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 5979,
      "name": "Scafati",
      "names": {
        "zh": "斯卡法蒂"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.7536,
        "lng": 14.5253
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 12860,
      "name": "Mweka",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -4.84,
        "lng": 21.57
      },
      "country": "Congo (Kinshasa)",
      "importance": 3
    },
    {
      "id": 5727,
      "name": "Saint-Ouen",
      "names": {
        "zh": "圣 -  ouen",
        "ja": "サン・ouen",
        "th": "เซนต์-ouen"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9123,
        "lng": 2.3342
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12787,
      "name": "Scandicci",
      "names": {
        "th": "สกันดิกซี"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.7544,
        "lng": 11.1894
      },
      "country": "Italy",
      "importance": 3
    },
    {
      "id": 5938,
      "name": "Ibiza",
      "names": {
        "zh": "伊维萨",
        "ja": "イビサ島",
        "th": "อิบิซา"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 38.9089,
        "lng": 1.4328
      },
      "country": "Spain",
      "importance": 3
    },
    {
      "id": 12868,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -29.1919,
        "lng": -54.8669
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12847,
      "name": "Fafe",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.45,
        "lng": -8.1667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 5914,
      "name": "Massy",
      "names": {
        "zh": "马西",
        "ja": "マッシー",
        "th": "หนาแน่น"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7309,
        "lng": 2.2713
      },
      "country": "France",
      "importance": 3
    },
    {
      "id": 12816,
      "name": "Ravensburg",
      "names": {
        "zh": "拉芬斯堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.7831,
        "lng": 9.6114
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12844,
      "name": "Cataingan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.0,
        "lng": 123.9833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12873,
      "name": "Sahuarita",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 31.9323,
        "lng": -110.9654
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12856,
      "name": "El Jem",
      "names": {
        "ja": "エルジェム",
        "th": "เอลเจม",
        "pt": "Elixo"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 35.3,
        "lng": 10.7167
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 12797,
      "name": "Willich",
      "names": {
        "zh": "维利希"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2631,
        "lng": 6.5492
      },
      "country": "Germany",
      "importance": 3
    },
    {
      "id": 12822,
      "name": "Sakaidecho",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 34.3164,
        "lng": 133.8606
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12791,
      "name": "Arao",
      "names": {
        "zh": "荒尾"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 32.9867,
        "lng": 130.4333
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12870,
      "name": "La Calera",
      "names": {
        "zh": "拉卡拉",
        "ja": "ラカレラ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -32.7833,
        "lng": -71.2167
      },
      "country": "Chile",
      "importance": 3
    },
    {
      "id": 12880,
      "name": "Clacton-on-Sea",
      "names": {
        "fr": "Clacton-sur-mer",
        "zh": "clacton对海",
        "ja": "clacton・オン・海",
        "th": "clacton บนทะเล"
      },
      "countryCode": "GB",
      "latLng": {
        "lat": 51.7918,
        "lng": 1.1457
      },
      "country": "United Kingdom",
      "importance": 3
    },
    {
      "id": 12884,
      "name": "Traverse City",
      "names": {
        "fr": "Ville de traverse",
        "zh": "遍历城市",
        "de": "Traverse-Stadt",
        "it": "Città attraversata",
        "id": "Kota lintasan",
        "ja": "トラバースシティ",
        "th": "ทราเวิร์สซิตี้",
        "pt": "Cidade Traverse"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.7547,
        "lng": -85.6035
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12885,
      "name": "Mirabel",
      "names": {
        "zh": "米拉贝尔"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 45.65,
        "lng": -74.0833
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 12846,
      "name": "Susono",
      "names": {},
      "countryCode": "JP",
      "latLng": {
        "lat": 35.1739,
        "lng": 138.9067
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12864,
      "name": "Gloria",
      "names": {
        "zh": "凯莱",
        "ja": "グロリア",
        "th": "กลอเรีย"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9167,
        "lng": 121.4667
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12887,
      "name": "Severn",
      "names": {
        "zh": "塞文",
        "ja": "セヴァーン",
        "th": "เวิร์น"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.1355,
        "lng": -76.6958
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12879,
      "name": "El Nido",
      "names": {
        "ja": "エルニド"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.1956,
        "lng": 119.4075
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 6063,
      "name": "Gbadolite",
      "names": {
        "zh": "巴多利特"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 4.275,
        "lng": 21.0
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 12886,
      "name": "San Ignacio",
      "names": {
        "th": "ซานอิกนาซิโอ"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -26.8867,
        "lng": -57.0283
      },
      "country": "Paraguay",
      "importance": 3
    },
    {
      "id": 12877,
      "name": "Tebourba",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.8295,
        "lng": 9.8411
      },
      "country": "Tunisia",
      "importance": 3
    },
    {
      "id": 12889,
      "name": "Catalina Foothills",
      "names": {
        "es": "Foothills de Catalina",
        "fr": "Catalina contreforts",
        "zh": "Catalina山麓",
        "de": "Catalina-Ausläufer",
        "it": "Foothills Catalina",
        "id": "Kaki katalina",
        "ja": "カタリナフットヒル",
        "th": "เชิงเขา Catalina",
        "pt": "Foothills Catalina"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.3041,
        "lng": -110.8835
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12890,
      "name": "Galveston",
      "names": {
        "zh": "加尔维斯顿",
        "ja": "ガルベストン",
        "th": "กัลเวสตัน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 29.2487,
        "lng": -94.891
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 6067,
      "name": "Country Club",
      "names": {
        "es": "Club de campo",
        "zh": "乡村俱乐部",
        "de": "Landclub",
        "it": "Campagna",
        "id": "Klub pedesaan",
        "ja": "カントリークラブ",
        "th": "ประเทศสโมสร",
        "pt": "Clube de campo"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 25.9407,
        "lng": -80.3102
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12766,
      "name": "El Talar de Pacheco",
      "names": {
        "id": "El Talas de Pacheco",
        "pt": "El talar de paceco"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.4719,
        "lng": -58.655
      },
      "country": "Argentina",
      "importance": 3
    },
    {
      "id": 12893,
      "name": "Cookeville",
      "names": {
        "zh": "库克维尔",
        "th": "คุกวิลล์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1484,
        "lng": -85.5114
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12820,
      "name": "Konibodom",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 40.2833,
        "lng": 70.4167
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 12829,
      "name": "Nazarovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.0064,
        "lng": 90.3914
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 6055,
      "name": "Bangued",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 17.5965,
        "lng": 120.6179
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 12833,
      "name": "Aringay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 16.3982,
        "lng": 120.3555
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12776,
      "name": "Speyer",
      "names": {
        "zh": "施派尔",
        "ja": "シュパイアー"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3194,
        "lng": 8.4311
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12888,
      "name": "Wao",
      "names": {
        "zh": "氧化法"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6404,
        "lng": 124.7257
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12897,
      "name": "Mishawaka",
      "names": {
        "zh": "米沙沃卡"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.6736,
        "lng": -86.1669
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 6001,
      "name": "Myrnohrad",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.3022,
        "lng": 37.2614
      },
      "country": "Ukraine",
      "importance": 3
    },
    {
      "id": 6072,
      "name": "Ma`an",
      "names": {},
      "countryCode": "JO",
      "latLng": {
        "lat": 30.192,
        "lng": 35.736
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 12901,
      "name": "Rowland Heights",
      "names": {
        "zh": "罗兰高地",
        "it": "Altezze di Rowland",
        "ja": "ローランドの高さ",
        "pt": "Alturas de rowland"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 33.9716,
        "lng": -117.8911
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12882,
      "name": "Amulung",
      "names": {
        "zh": "阿穆隆"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.8387,
        "lng": 121.7235
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 6075,
      "name": "Plainfield",
      "names": {
        "zh": "普兰菲尔德",
        "ja": "プレーンフィールド",
        "th": "เพลนฟิลด์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 40.6154,
        "lng": -74.4157
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12891,
      "name": "Chinu",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1097,
        "lng": -75.3981
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12907,
      "name": "Bullhead City",
      "names": {
        "es": "Ciudad de Bullhead",
        "fr": "Bullhead",
        "zh": "斗牛头市",
        "de": "Bullhead-Stadt",
        "id": "Kota Bullhead",
        "ja": "ブルヘッドシティ",
        "th": "เมือง Bullhead",
        "pt": "Cidade de bullhead"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 35.1205,
        "lng": -114.546
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 6051,
      "name": "Sisophon",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 13.5859,
        "lng": 102.9737
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 12908,
      "name": "Irondequoit",
      "names": {},
      "countryCode": "US",
      "latLng": {
        "lat": 43.2096,
        "lng": -77.5705
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12902,
      "name": "Penablanca",
      "names": {
        "zh": "佩尼亚布兰卡"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.6333,
        "lng": 121.7833
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12909,
      "name": "Stillwater",
      "names": {
        "zh": "斯蒂尔沃特",
        "th": "สติลวอเตอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 36.1316,
        "lng": -97.074
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12875,
      "name": "Donsol",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9167,
        "lng": 123.6
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12914,
      "name": "East Hartford",
      "names": {
        "zh": "东哈特福德",
        "id": "Hartford Timur",
        "ja": "イーストハートフォード",
        "th": "ตะวันออกฮาร์ตฟอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 41.7634,
        "lng": -72.6152
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12911,
      "name": "Macaubas",
      "names": {},
      "countryCode": "BR",
      "latLng": {
        "lat": -13.0189,
        "lng": -42.6989
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12867,
      "name": "Baranoa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7956,
        "lng": -74.9194
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6070,
      "name": "Gracias",
      "names": {
        "zh": "格拉西亚斯"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.5833,
        "lng": -88.5833
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 12916,
      "name": "Forney",
      "names": {
        "zh": "福尼"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 32.744,
        "lng": -96.4529
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 6060,
      "name": "Jurmala",
      "names": {
        "zh": "尤尔马拉",
        "th": "เจอร์มาลา"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9722,
        "lng": 23.7969
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 12883,
      "name": "Artur Nogueira",
      "names": {
        "de": "Artur Noggira"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -22.5731,
        "lng": -47.1725
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 6077,
      "name": "Wheaton",
      "names": {
        "zh": "惠顿",
        "ja": "ウィートン"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 39.0492,
        "lng": -77.0572
      },
      "country": "United States",
      "importance": 3
    },
    {
      "id": 12783,
      "name": "Butare",
      "names": {
        "zh": "布塔雷"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -2.6,
        "lng": 29.75
      },
      "country": "Rwanda",
      "importance": 3
    },
    {
      "id": 12832,
      "name": "Yuki",
      "names": {
        "zh": "雪",
        "ja": "由紀",
        "th": "ยูกิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 36.3053,
        "lng": 139.8775
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5975,
      "name": "Nes Ziyyona",
      "names": {},
      "countryCode": "IL",
      "latLng": {
        "lat": 31.9333,
        "lng": 34.8
      },
      "country": "Israel",
      "importance": 3
    },
    {
      "id": 12922,
      "name": "Alta Floresta",
      "names": {
        "ja": "アルタフロレスト"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -9.8758,
        "lng": -56.0858
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12848,
      "name": "Nakagawa",
      "names": {
        "zh": "中川",
        "ja": "中川"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.4994,
        "lng": 130.4222
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 5974,
      "name": "Moskovskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 55.5991,
        "lng": 37.355
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12927,
      "name": "Borovichi",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 58.4,
        "lng": 33.9167
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12746,
      "name": "Cardona",
      "names": {
        "zh": "卡多纳"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.4861,
        "lng": 121.2289
      },
      "country": "Philippines",
      "importance": 3
    },
    {
      "id": 12878,
      "name": "Qinhe",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.5047,
        "lng": 112.3335
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 5996,
      "name": "Bang Bua Thong",
      "names": {
        "es": "Bug bua tanga",
        "fr": "Bang bua strong",
        "zh": "邦布瓦丁字裤",
        "ja": "バングバトン",
        "th": "บางบัวทอง",
        "pt": "Bang bua tanga"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.9099,
        "lng": 100.4263
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 12794,
      "name": "Boblingen",
      "names": {
        "fr": "Böblingen",
        "zh": "伯布林根",
        "de": "Böblingen",
        "it": "Böblingen",
        "pt": "Böblingen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.6833,
        "lng": 9.0
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12930,
      "name": "Roslavl",
      "names": {
        "zh": "罗斯拉夫尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 53.9492,
        "lng": 32.8569
      },
      "country": "Russia",
      "importance": 3
    },
    {
      "id": 12920,
      "name": "Sao Joaquim da Barra",
      "names": {
        "ja": "サンジョアキムダバララ",
        "pt": "São Joaquim da Barra"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -20.5808,
        "lng": -47.855
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12928,
      "name": "Itarare",
      "names": {
        "zh": "伊塔拉雷"
      },
      "countryCode": "BR",
      "latLng": {
        "lat": -24.1125,
        "lng": -49.3317
      },
      "country": "Brazil",
      "importance": 3
    },
    {
      "id": 12898,
      "name": "Bhadrachalam",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 17.67,
        "lng": 80.88
      },
      "country": "India",
      "importance": 3
    },
    {
      "id": 5906,
      "name": "Francisco I. Madero",
      "names": {
        "ja": "フランシスコI.マデロ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7753,
        "lng": -103.2731
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12915,
      "name": "Nanjian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 25.053,
        "lng": 100.5231
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6076,
      "name": "Tubod",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.05,
        "lng": 123.8
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 6079,
      "name": "Moyobamba",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -6.0333,
        "lng": -76.9667
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 12931,
      "name": "Gogrial",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 8.5337,
        "lng": 28.1167
      },
      "country": "South Sudan",
      "importance": 3
    },
    {
      "id": 12942,
      "name": "Shawinigan",
      "names": {
        "th": "แชวินิกัน"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.5667,
        "lng": -72.75
      },
      "country": "Canada",
      "importance": 3
    },
    {
      "id": 12866,
      "name": "Yasu",
      "names": {
        "zh": "夜须"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 35.0675,
        "lng": 136.0258
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12933,
      "name": "Miyoshi",
      "names": {
        "zh": "三芳"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 34.8058,
        "lng": 132.8517
      },
      "country": "Japan",
      "importance": 3
    },
    {
      "id": 12801,
      "name": "Hassan Abdal",
      "names": {
        "zh": "哈桑ABDAL",
        "ja": "ハッサンのアブダル"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.8195,
        "lng": 72.689
      },
      "country": "Pakistan",
      "importance": 3
    },
    {
      "id": 12910,
      "name": "Huanghuajie",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.995,
        "lng": 103.55
      },
      "country": "China",
      "importance": 3
    },
    {
      "id": 12929,
      "name": "Cizre",
      "names": {
        "zh": "吉兹雷"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3302,
        "lng": 42.1848
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 793,
      "name": "Bandar Seri Begawan",
      "names": {
        "fr": "Bandar Seri Begrawan",
        "ja": "バンダルセリブガワン"
      },
      "countryCode": "BN",
      "latLng": {
        "lat": 4.9167,
        "lng": 114.9167
      },
      "country": "Brunei",
      "importance": 0
    },
    {
      "id": 5944,
      "name": "Gutao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.1989,
        "lng": 112.1767
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12925,
      "name": "Lindong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.9673,
        "lng": 119.357
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12937,
      "name": "Sardasht",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.1553,
        "lng": 45.4789
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 12947,
      "name": "Nenjiang",
      "names": {
        "zh": "嫩江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 49.1745,
        "lng": 125.216
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6080,
      "name": "Al Kharjah",
      "names": {
        "ja": "アルカルジャ",
        "th": "อัล karjah"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 25.44,
        "lng": 30.55
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 5947,
      "name": "Chake Chake",
      "names": {
        "es": "Chance",
        "fr": "Chakke chake",
        "de": "Kake",
        "it": "Chake Bosta",
        "ja": "チャクチェイク",
        "pt": "Chake"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.2395,
        "lng": 39.77
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 12896,
      "name": "Peine",
      "names": {
        "zh": "派纳"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.3203,
        "lng": 10.2336
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12945,
      "name": "Porvoo",
      "names": {
        "zh": "波尔沃"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.3931,
        "lng": 25.6639
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 12899,
      "name": "Emden",
      "names": {
        "zh": "埃姆登",
        "th": "แอ็ม"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.3669,
        "lng": 7.2061
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12858,
      "name": "Houten",
      "names": {
        "zh": "霍滕",
        "th": "ฮูเต็น"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0261,
        "lng": 5.1728
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6050,
      "name": "Alytus",
      "names": {
        "zh": "阿利图斯"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 54.4014,
        "lng": 24.0492
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 6088,
      "name": "Odienne",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 9.5,
        "lng": -7.5667
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 6087,
      "name": "Zaranj",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 30.96,
        "lng": 61.86
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 12943,
      "name": "Tepeapulco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7856,
        "lng": -98.5517
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 12904,
      "name": "Weert",
      "names": {
        "zh": "韦尔特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.25,
        "lng": 5.7
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12852,
      "name": "Pulawy",
      "names": {
        "zh": "普瓦维"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.4166,
        "lng": 21.9694
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 12959,
      "name": "Slonim",
      "names": {
        "zh": "斯洛宁"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 53.0833,
        "lng": 25.3167
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 12960,
      "name": "Ramhormoz",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 31.2783,
        "lng": 49.6064
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 12871,
      "name": "Rastatt",
      "names": {
        "zh": "拉施塔特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8572,
        "lng": 8.2031
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6073,
      "name": "Selibe Phikwe",
      "names": {
        "it": "Selebe Phikwe",
        "ja": "セレピフ"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -21.9667,
        "lng": 27.9167
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 12966,
      "name": "Gualan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1333,
        "lng": -89.3667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 12917,
      "name": "Moss",
      "names": {
        "es": "Musgo",
        "zh": "苔藓",
        "it": "Muschio",
        "ja": "モス",
        "th": "มอส"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.434,
        "lng": 10.6577
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 12985,
      "name": "Cimitarra",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3133,
        "lng": -73.95
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12830,
      "name": "Laval",
      "names": {
        "zh": "拉瓦尔",
        "ja": "ラヴァル",
        "th": "ลาวาล"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.0733,
        "lng": -0.7689
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6095,
      "name": "Huancavelica",
      "names": {
        "zh": "万卡韦利卡"
      },
      "countryCode": "PE",
      "latLng": {
        "lat": -12.785,
        "lng": -74.9714
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 12951,
      "name": "Heidenheim",
      "names": {
        "zh": "海登海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.6761,
        "lng": 10.1544
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 12969,
      "name": "Sesimbra",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.4437,
        "lng": -9.0996
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 5891,
      "name": "San Pedro",
      "names": {
        "zh": "圣佩德罗",
        "ja": "サンペドロ",
        "th": "ซานเปโดร"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.7578,
        "lng": -102.9831
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5960,
      "name": "Gao",
      "names": {
        "zh": "高",
        "ja": "ガオ",
        "th": "เก่า"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 16.2667,
        "lng": -0.05
      },
      "country": "Mali",
      "importance": 1
    },
    {
      "id": 12874,
      "name": "Sittard",
      "names": {
        "zh": "锡塔德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.0,
        "lng": 5.8667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 12869,
      "name": "Shakhtarsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0333,
        "lng": 38.4833
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 12990,
      "name": "Maria la Baja",
      "names": {
        "ja": "マリアラ・バハ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.9811,
        "lng": -75.2986
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13006,
      "name": "Villaguay",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -31.85,
        "lng": -59.0167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 12923,
      "name": "Mantova",
      "names": {
        "zh": "曼托瓦",
        "ja": "マントヴァ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.1564,
        "lng": 10.7911
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 12876,
      "name": "Lorrach",
      "names": {
        "fr": "Lörrach",
        "de": "Lörrach"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.6156,
        "lng": 7.6614
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6106,
      "name": "Nelson",
      "names": {
        "zh": "尼尔森",
        "ja": "ネルソン",
        "th": "เนลสัน"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.2931,
        "lng": 173.2381
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 13031,
      "name": "Libertador General San Martin",
      "names": {
        "fr": "Libertador général San Martin",
        "zh": "Libertador San Martin",
        "pt": "Libertador Geral San Martin"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -23.8,
        "lng": -64.7833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 6078,
      "name": "Campobasso",
      "names": {
        "zh": "坎波巴索",
        "ja": "カンポバッソ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.561,
        "lng": 14.6684
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 12843,
      "name": "Kizlyar",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.85,
        "lng": 46.7167
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 13035,
      "name": "Agustin Codazzi",
      "names": {
        "es": "Agustin CODZZI",
        "zh": "阿古斯丁代佐",
        "id": "Agustin codezzi"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.0367,
        "lng": -73.2369
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 12903,
      "name": "Baicheng",
      "names": {
        "zh": "白城"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.7957,
        "lng": 81.8715
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13019,
      "name": "Samaniego",
      "names": {
        "zh": "萨马涅戈"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.3364,
        "lng": -77.5925
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13023,
      "name": "Yong\u0027an",
      "names": {
        "zh": "永安"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 31.0504,
        "lng": 109.5167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6098,
      "name": "Lulea",
      "names": {
        "zh": "律勒欧",
        "de": "Luleå"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 65.5838,
        "lng": 22.1915
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 6037,
      "name": "Guozhen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.3668,
        "lng": 107.198
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 12935,
      "name": "Albi",
      "names": {
        "zh": "阿尔比",
        "ja": "アルビ",
        "th": "อัลบี"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.9289,
        "lng": 2.1464
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 13043,
      "name": "Palencia",
      "names": {
        "zh": "帕伦西亚",
        "th": "ปาเลนเซีย"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6676,
        "lng": -90.3575
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 13063,
      "name": "Al Mayadin",
      "names": {
        "ja": "アルマヤディン",
        "th": "อัลมาเดน"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.0208,
        "lng": 40.4533
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 6091,
      "name": "Kangar",
      "names": {
        "zh": "加央"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 6.4333,
        "lng": 100.2
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 13030,
      "name": "Panay",
      "names": {
        "zh": "班乃"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 11.55,
        "lng": 122.8
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 12954,
      "name": "Grasse",
      "names": {
        "zh": "格拉斯",
        "ja": "グラース",
        "th": "แกรสส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6667,
        "lng": 6.9167
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 5987,
      "name": "Jimenez",
      "names": {
        "es": "Jiménez",
        "zh": "希门尼斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.13,
        "lng": -104.9067
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6100,
      "name": "Couva",
      "names": {},
      "countryCode": "TT",
      "latLng": {
        "lat": 10.4167,
        "lng": -61.45
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 13074,
      "name": "Waingapu",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -9.6582,
        "lng": 120.253
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 13053,
      "name": "Ascoli Piceno",
      "names": {
        "id": "Askoli piceno",
        "ja": "アスコリピチェノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.8547,
        "lng": 13.5753
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 13077,
      "name": "Sanare",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.7822,
        "lng": -69.7931
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 12872,
      "name": "Starogard Gdanski",
      "names": {
        "es": "Starrogard gdanski",
        "de": "Starbard gdanski"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.9667,
        "lng": 18.5333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6020,
      "name": "Barendrecht",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.85,
        "lng": 4.5333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 13091,
      "name": "Garmsar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.2167,
        "lng": 52.3333
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 13042,
      "name": "Freising",
      "names": {
        "zh": "弗赖辛",
        "th": "ไฟรซิง"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4028,
        "lng": 11.7489
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13099,
      "name": "Angol",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -37.7988,
        "lng": -72.7086
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 6133,
      "name": "Serowe",
      "names": {
        "zh": "塞罗韦"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -22.3833,
        "lng": 26.7167
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 6130,
      "name": "Nueva Loja",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": 0.0844,
        "lng": -76.8911
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 6137,
      "name": "Salekhard",
      "names": {
        "id": "Salehard"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 66.5333,
        "lng": 66.6333
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 12987,
      "name": "Liujiaxia",
      "names": {
        "zh": "刘家峡"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.9423,
        "lng": 103.3146
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13116,
      "name": "Kurganinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.8833,
        "lng": 40.6
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 6068,
      "name": "Cojutepeque",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 13.7167,
        "lng": -88.9333
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 6123,
      "name": "Asadabad",
      "names": {
        "zh": "阿萨达巴德"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.8742,
        "lng": 71.1528
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 13089,
      "name": "Kozlu",
      "names": {
        "zh": "科兹卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4333,
        "lng": 31.75
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 13130,
      "name": "Damavand",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7178,
        "lng": 52.065
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 6102,
      "name": "Waterford",
      "names": {
        "zh": "沃特福德",
        "ja": "ウォーターフォード",
        "th": "วอเตอร์ฟอร์ด"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.2583,
        "lng": -7.119
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 13098,
      "name": "Cuxhaven",
      "names": {
        "zh": "库克斯港"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.8667,
        "lng": 8.7
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13120,
      "name": "San Pedro Mixtepec",
      "names": {
        "it": "San pedro mixttepec",
        "ja": "サンペドロミックステープ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.0,
        "lng": -97.1169
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 5970,
      "name": "Alajuela",
      "names": {
        "zh": "阿拉胡埃拉"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 10.0311,
        "lng": -84.2041
      },
      "country": "Costa Rica",
      "importance": 1
    },
    {
      "id": 12973,
      "name": "IJmuiden",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4586,
        "lng": 4.6194
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6115,
      "name": "Karlovy Vary",
      "names": {
        "es": "Karlovy varía",
        "fr": "Karlovy varie",
        "zh": "卡洛维各不相同",
        "de": "Karlsbad variiert",
        "it": "Karlovy varia",
        "id": "Karlovy bervariasi",
        "ja": "カルロビーはさまざまです",
        "th": "Karlovy แตกต่างกันไป",
        "pt": "Karlovy varia"
      },
      "countryCode": "CZ",
      "latLng": {
        "lat": 50.2306,
        "lng": 12.8725
      },
      "country": "Czechia",
      "importance": 1
    },
    {
      "id": 6134,
      "name": "Mandeville",
      "names": {
        "zh": "曼德维尔"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.0333,
        "lng": -77.5
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 6136,
      "name": "Nong Khai",
      "names": {
        "zh": "龚凯海",
        "th": "หนองคาย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.8842,
        "lng": 102.7467
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 13147,
      "name": "Titao",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 13.7667,
        "lng": -2.0736
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 13065,
      "name": "Waalwijk",
      "names": {
        "zh": "瓦尔韦克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6833,
        "lng": 5.0667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 13037,
      "name": "Lecco",
      "names": {
        "zh": "莱科",
        "ja": "レッコ",
        "th": "เลกโก"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.85,
        "lng": 9.4
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 13134,
      "name": "Longtoushan Jiezi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.1157,
        "lng": 103.3817
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6047,
      "name": "Gori",
      "names": {
        "zh": "哥里",
        "ja": "ゴーリ"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 41.9817,
        "lng": 44.1124
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 13154,
      "name": "Alta Gracia",
      "names": {
        "ja": "アルタグラシア"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.6667,
        "lng": -64.4333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 13145,
      "name": "Zhujiacun",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.3164,
        "lng": 104.3893
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13142,
      "name": "Santa Catarina Ixtahuacan",
      "names": {
        "ja": "サンタカタリーナイスタフアカン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8,
        "lng": -91.3667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 13127,
      "name": "Dornbirn",
      "names": {
        "zh": "多恩比恩",
        "ja": "ドルンビルン"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.4167,
        "lng": 9.75
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 6042,
      "name": "Cuetzalan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0333,
        "lng": -97.5167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13170,
      "name": "Mengmeng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.4718,
        "lng": 99.8336
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6125,
      "name": "Middelburg",
      "names": {
        "zh": "米德尔堡"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4997,
        "lng": 3.6136
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 13172,
      "name": "Carepa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.7581,
        "lng": -76.6553
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13177,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 16.8017,
        "lng": -89.9342
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 6135,
      "name": "Birendranagar",
      "names": {
        "zh": "比兰德拉纳加尔"
      },
      "countryCode": "NP",
      "latLng": {
        "lat": 28.6,
        "lng": 81.6333
      },
      "country": "Nepal",
      "importance": 1
    },
    {
      "id": 13168,
      "name": "Vila Verde",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.65,
        "lng": -8.4333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 13195,
      "name": "Golpayegan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.4536,
        "lng": 50.2883
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 13175,
      "name": "Skierniewice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.9528,
        "lng": 20.1417
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6019,
      "name": "Ratnapura",
      "names": {
        "zh": "拉特纳普勒"
      },
      "countryCode": "LK",
      "latLng": {
        "lat": 6.693,
        "lng": 80.386
      },
      "country": "Sri Lanka",
      "importance": 1
    },
    {
      "id": 13135,
      "name": "Tarnobrzeg",
      "names": {
        "zh": "塔尔诺布热格",
        "th": "ทาร์นอบเซก"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.5833,
        "lng": 21.6833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 13119,
      "name": "Straubing",
      "names": {
        "zh": "施特劳宾"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8772,
        "lng": 12.5758
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13211,
      "name": "Luwuk",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -0.9396,
        "lng": 122.79
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 13203,
      "name": "Agueda",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.5805,
        "lng": -8.4417
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6155,
      "name": "Mamburao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.2233,
        "lng": 120.596
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 6149,
      "name": "Leribe",
      "names": {
        "zh": "莱里贝"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -28.8734,
        "lng": 28.0416
      },
      "country": "Lesotho",
      "importance": 1
    },
    {
      "id": 13224,
      "name": "Amahai",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -3.3331,
        "lng": 128.919
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 13047,
      "name": "Starachowice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.05,
        "lng": 21.0667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6158,
      "name": "Invercargill",
      "names": {
        "zh": "因弗卡吉尔",
        "ja": "インバーカーギル"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -46.429,
        "lng": 168.362
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 13107,
      "name": "Harderwijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3506,
        "lng": 5.6172
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 13232,
      "name": "`Amuda",
      "names": {
        "zh": "`阿穆达"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 37.1042,
        "lng": 40.93
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 6165,
      "name": "Kokkola",
      "names": {
        "zh": "科科拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.8376,
        "lng": 23.132
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 13241,
      "name": "Kutno",
      "names": {
        "zh": "库特诺"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.2333,
        "lng": 19.3667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 13187,
      "name": "Stade",
      "names": {
        "zh": "体育场",
        "ja": "スタッド",
        "th": "สนามกีฬา"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.6008,
        "lng": 9.4764
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13244,
      "name": "Azna",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.4539,
        "lng": 49.455
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 13183,
      "name": "Soest",
      "names": {
        "zh": "索斯特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5711,
        "lng": 8.1092
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13249,
      "name": "San Pedro",
      "names": {
        "zh": "圣佩德罗",
        "ja": "サンペドロ",
        "th": "ซานเปโดร"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.6794,
        "lng": -59.6669
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 13231,
      "name": "Rieti",
      "names": {
        "zh": "列蒂"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 42.4044,
        "lng": 12.8567
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 13112,
      "name": "Zutphen",
      "names": {
        "zh": "聚特芬"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.14,
        "lng": 6.195
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 13242,
      "name": "La Vega",
      "names": {
        "zh": "拉维加",
        "ja": "ラベガ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.0008,
        "lng": -76.7778
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13084,
      "name": "Dachau",
      "names": {
        "zh": "达豪",
        "th": "ดาเชา"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.2603,
        "lng": 11.4342
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13260,
      "name": "Chaparral",
      "names": {
        "zh": "丛林",
        "ja": "茂み",
        "th": "โอ๊ก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.7236,
        "lng": -75.4847
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13201,
      "name": "Lousada",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2833,
        "lng": -8.2833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 13264,
      "name": "Villa Constitucion",
      "names": {
        "zh": "别墅成立",
        "de": "Villa-Grundkurs",
        "id": "Konstituus villa",
        "ja": "ヴィラ構成",
        "th": "วิลล่าเซสซีน"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.2333,
        "lng": -60.3333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 13259,
      "name": "Sanski Most",
      "names": {
        "es": "Sanski mas",
        "fr": "Sanski le plus",
        "zh": "Sanski最多",
        "de": "Sanski am meisten",
        "it": "Sanski di più",
        "id": "Sanski sebagian besar",
        "ja": "サンスキはほとんど",
        "th": "Sanski มากที่สุด",
        "pt": "Sanski mais"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.7667,
        "lng": 16.6667
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 13263,
      "name": "Jobabo",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 20.9075,
        "lng": -77.2819
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 13274,
      "name": "Kingisepp",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 59.3667,
        "lng": 28.6
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 13257,
      "name": "San Andres de Sotavento",
      "names": {
        "es": "San Andrés de Sotavento",
        "zh": "圣安德烈斯德萨萨托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1453,
        "lng": -75.5086
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13250,
      "name": "San Jose Pinula",
      "names": {
        "es": "San José Pinula",
        "zh": "圣何塞·佩拉",
        "ja": "サンノゼピュニュラ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5446,
        "lng": -90.4083
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 13282,
      "name": "Ayapel",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.3125,
        "lng": -75.145
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6171,
      "name": "Rosso",
      "names": {
        "zh": "罗索",
        "ja": "ロッソ"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 16.5128,
        "lng": -15.805
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 13294,
      "name": "Marhanets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.648,
        "lng": 34.6167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 13275,
      "name": "Yabrud",
      "names": {
        "zh": "耶卜鲁德"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.9672,
        "lng": 36.6572
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 13298,
      "name": "Tres Arroyos",
      "names": {
        "ja": "トレスアロヨス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.3667,
        "lng": -60.2667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 13297,
      "name": "Urrao",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3156,
        "lng": -76.1342
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13296,
      "name": "Poso",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -1.3786,
        "lng": 120.7624
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 5928,
      "name": "Busia",
      "names": {
        "zh": "布西亚"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.4669,
        "lng": 34.09
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 13256,
      "name": "Qingyuan",
      "names": {
        "zh": "清远",
        "ja": "清遠"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.5004,
        "lng": 108.6667
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13303,
      "name": "Ha Tien",
      "names": {
        "zh": "哈蒂恩"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 10.3831,
        "lng": 104.4875
      },
      "country": "Vietnam",
      "importance": 2
    },
    {
      "id": 6031,
      "name": "Tizimin",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1425,
        "lng": -88.1647
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6178,
      "name": "Boosaaso",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 11.28,
        "lng": 49.18
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 6093,
      "name": "Belfort",
      "names": {
        "zh": "贝尔福",
        "ja": "ベルフォート",
        "th": "เบลฟอร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.64,
        "lng": 6.85
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 13328,
      "name": "Agrinio",
      "names": {
        "th": "อ่างทอง"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.6167,
        "lng": 21.4
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 13327,
      "name": "Valencia",
      "names": {
        "fr": "Valence",
        "zh": "瓦伦西亚",
        "ja": "バレンシア",
        "th": "วาเลนเซีย",
        "pt": "Valência"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2592,
        "lng": -76.1469
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13312,
      "name": "Kabasalan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 7.7968,
        "lng": 122.7627
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 6162,
      "name": "New Amsterdam",
      "names": {
        "fr": "Nouveau Amsterdam",
        "zh": "新阿姆斯特丹",
        "de": "Neue Amsterdam",
        "it": "Nuova Amsterdam",
        "id": "Amsterdam baru",
        "ja": "ニューアムステルダム",
        "th": "ใหม่อัมสเตอร์ดัม",
        "pt": "Novo Amsterdão"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.25,
        "lng": -57.5167
      },
      "country": "Guyana",
      "importance": 1
    },
    {
      "id": 6174,
      "name": "Nagykanizsa",
      "names": {
        "zh": "瑙吉考尼饶"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4558,
        "lng": 16.9975
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 13300,
      "name": "Nysa",
      "names": {
        "zh": "尼斯"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.4714,
        "lng": 17.3339
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6184,
      "name": "Kanye",
      "names": {
        "zh": "坎耶·",
        "ja": "カニエ"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -24.9833,
        "lng": 25.35
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 13320,
      "name": "Roldanillo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4136,
        "lng": -76.1547
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13331,
      "name": "Lohja",
      "names": {
        "zh": "洛赫亚"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.25,
        "lng": 24.0667
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 13336,
      "name": "Ciudad Serdan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9833,
        "lng": -97.45
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13252,
      "name": "Santa Ana Chiautempan",
      "names": {
        "zh": "圣安娜·奇亚普潘",
        "de": "Santa Ana Chiauutempan",
        "id": "Santa Ana Chiautemban",
        "ja": "サンタアナチアテンパン"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3167,
        "lng": -98.1833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13309,
      "name": "Al Qusayr",
      "names": {
        "th": "อัล qusayr"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.5089,
        "lng": 36.5797
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 6185,
      "name": "Choma",
      "names": {
        "zh": "乔马"
      },
      "countryCode": "ZM",
      "latLng": {
        "lat": -16.8095,
        "lng": 26.97
      },
      "country": "Zambia",
      "importance": 1
    },
    {
      "id": 13333,
      "name": "Lukavac",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.5333,
        "lng": 18.5333
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 6124,
      "name": "Sariyer",
      "names": {
        "zh": "萨勒耶尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1911,
        "lng": 29.0094
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 6151,
      "name": "Njombe",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -9.3296,
        "lng": 34.77
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 13111,
      "name": "Kolobrzeg",
      "names": {
        "zh": "科沃布热格"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.1667,
        "lng": 15.5667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 13123,
      "name": "Evreux",
      "names": {
        "zh": "埃夫勒",
        "de": "Évreux"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.02,
        "lng": 1.15
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6189,
      "name": "Aweil",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 8.7666,
        "lng": 27.4
      },
      "country": "South Sudan",
      "importance": 1
    },
    {
      "id": 13267,
      "name": "Tursunzoda",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 38.5108,
        "lng": 68.2303
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 13287,
      "name": "Landau",
      "names": {
        "zh": "兰道",
        "ja": "ランダウ",
        "th": "รถม้าสี่ล้อ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.1994,
        "lng": 8.1231
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13239,
      "name": "Brive-la-Gaillarde",
      "names": {
        "zh": "brive-的La-gaillarde",
        "ja": "・ラ-gaillarde brive",
        "th": "brive-ลา-gaillarde"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.1583,
        "lng": 1.5321
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6163,
      "name": "Hajjah",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 15.695,
        "lng": 43.5975
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 13238,
      "name": "Schagen",
      "names": {
        "zh": "斯哈亨"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.78,
        "lng": 4.8
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6193,
      "name": "Dori",
      "names": {
        "zh": "多利",
        "ja": "ドリ"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 14.05,
        "lng": 0.05
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 13362,
      "name": "Jocotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2863,
        "lng": -103.4304
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13288,
      "name": "Carcassonne",
      "names": {
        "es": "Carcasona",
        "zh": "卡尔卡松",
        "ja": "カルカソンヌ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.21,
        "lng": 2.35
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 13219,
      "name": "Saint-Malo",
      "names": {
        "zh": "圣马洛",
        "ja": "サン・マロ",
        "th": "เซนต์มาโล"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6481,
        "lng": -2.0075
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 13304,
      "name": "Neunkirchen",
      "names": {
        "zh": "诺因基兴"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3448,
        "lng": 7.1799
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13367,
      "name": "Hyvinkaa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.6306,
        "lng": 24.8597
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 6132,
      "name": "Kita",
      "names": {
        "zh": "北",
        "ja": "北区",
        "th": "คิตะ"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 13.0504,
        "lng": -9.4833
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 13204,
      "name": "Charleville-Mezieres",
      "names": {
        "es": "Charleville-mézières",
        "zh": "沙勒维尔 - 梅济耶尔",
        "de": "Charleville-mézières"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.7719,
        "lng": 4.7161
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6199,
      "name": "Sibenik",
      "names": {
        "zh": "希贝尼克",
        "ja": "シベニク",
        "th": "ซีเบนิก"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.7339,
        "lng": 15.8956
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 6139,
      "name": "Bindura",
      "names": {
        "zh": "宾杜拉"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -17.3,
        "lng": 31.3333
      },
      "country": "Zimbabwe",
      "importance": 1
    },
    {
      "id": 6205,
      "name": "Farim",
      "names": {},
      "countryCode": "GW",
      "latLng": {
        "lat": 12.4833,
        "lng": -15.2167
      },
      "country": "Guinea-Bissau",
      "importance": 1
    },
    {
      "id": 6201,
      "name": "Cobija",
      "names": {
        "zh": "科维哈"
      },
      "countryCode": "BO",
      "latLng": {
        "lat": -11.0183,
        "lng": -68.7537
      },
      "country": "Bolivia",
      "importance": 1
    },
    {
      "id": 13176,
      "name": "Ridderkerk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8667,
        "lng": 4.6
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 13291,
      "name": "Soest",
      "names": {
        "zh": "索斯特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1833,
        "lng": 5.2833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6172,
      "name": "Chitre",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 7.9667,
        "lng": -80.4333
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 13391,
      "name": "Chichigalpa",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 12.5667,
        "lng": -87.0333
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 13347,
      "name": "Vercelli",
      "names": {
        "zh": "韦尔切利",
        "ja": "ヴェルチェッリ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.3256,
        "lng": 8.4231
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 6190,
      "name": "Ostersund",
      "names": {
        "zh": "厄斯特松德",
        "id": "Östersund"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 63.1775,
        "lng": 14.6414
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 13407,
      "name": "Akhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.9167,
        "lng": 27.8333
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 13364,
      "name": "Puerto Tejada",
      "names": {
        "zh": "波多黎雅达",
        "ja": "プエルトテヤダ",
        "th": "เปอร์โตเตจาดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.23,
        "lng": -76.4175
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13311,
      "name": "Frosinone",
      "names": {
        "zh": "弗罗西诺内"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 41.6333,
        "lng": 13.35
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 13299,
      "name": "Prievidza",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7714,
        "lng": 18.6242
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 13415,
      "name": "Wittenberg",
      "names": {
        "zh": "维滕贝格",
        "ja": "ウィッテンベルク",
        "th": "วิต"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8671,
        "lng": 12.6484
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13421,
      "name": "Bajil",
      "names": {
        "zh": "巴吉勒"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 15.0583,
        "lng": 43.285
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 6140,
      "name": "Isiolo",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.35,
        "lng": 37.5833
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 13401,
      "name": "Santiago Atitlan",
      "names": {
        "zh": "圣地亚哥阿特特兰",
        "ja": "サンティアゴアテトラン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6386,
        "lng": -91.2299
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 6026,
      "name": "Harasta",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.5586,
        "lng": 36.365
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 6179,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.1004,
        "lng": -80.9833
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 13437,
      "name": "Ourem",
      "names": {
        "pt": "Ourém"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.65,
        "lng": -8.5833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 13350,
      "name": "Hof",
      "names": {
        "zh": "HOF酒店",
        "ja": "ホフ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3167,
        "lng": 11.9167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6216,
      "name": "Vidin",
      "names": {
        "zh": "维丁"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.9887,
        "lng": 22.8741
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 13322,
      "name": "Fastiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.0747,
        "lng": 29.9181
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 13420,
      "name": "Koupela",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.1794,
        "lng": -0.3517
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 6207,
      "name": "Slobozia",
      "names": {},
      "countryCode": "RO",
      "latLng": {
        "lat": 44.5639,
        "lng": 27.3661
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 13302,
      "name": "Blois",
      "names": {
        "zh": "布卢瓦",
        "ja": "ブロワ",
        "th": "บลัว"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.5939,
        "lng": 1.3281
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 13388,
      "name": "Tallbisah",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.8406,
        "lng": 36.7308
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 6219,
      "name": "Gbarnga",
      "names": {},
      "countryCode": "LR",
      "latLng": {
        "lat": 7.0104,
        "lng": -9.49
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 13342,
      "name": "Lubny",
      "names": {
        "zh": "卢布内"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.0186,
        "lng": 32.9869
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 6036,
      "name": "Wajir",
      "names": {
        "zh": "瓦吉尔"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 1.7472,
        "lng": 40.0572
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 13392,
      "name": "Gotha",
      "names": {
        "zh": "哥达",
        "ja": "ゴータ",
        "th": "ก็อด"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9489,
        "lng": 10.7183
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13469,
      "name": "Santa Maria Huatulco",
      "names": {
        "zh": "圣玛丽亚Huatulco",
        "ja": "サンタマリアウアトゥルコ",
        "th": "ซานตามาเรีย Huatulco"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.85,
        "lng": -96.3333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6229,
      "name": "Buta",
      "names": {
        "zh": "布塔",
        "ja": "ブータ"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": 2.82,
        "lng": 24.74
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 13379,
      "name": "Dendermonde",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0167,
        "lng": 4.1
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 6159,
      "name": "Kerkrade",
      "names": {
        "zh": "凯尔克拉德",
        "ja": "ケルクラーデ"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.8667,
        "lng": 6.0667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 13235,
      "name": "Chaidari",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0167,
        "lng": 23.65
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 13473,
      "name": "Remedios",
      "names": {
        "zh": "雷梅迪奥斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.4922,
        "lng": -79.5458
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 13268,
      "name": "Tangdukou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.9949,
        "lng": 111.2708
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6231,
      "name": "Tindouf",
      "names": {
        "es": "Tinduf",
        "zh": "廷杜夫"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 27.6753,
        "lng": -8.1286
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 6230,
      "name": "Nueva Gerona",
      "names": {
        "fr": "Nueva gérone"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.8847,
        "lng": -82.8011
      },
      "country": "Cuba",
      "importance": 1
    },
    {
      "id": 6232,
      "name": "Bluefields",
      "names": {
        "zh": "布卢菲尔兹"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.014,
        "lng": -83.7645
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 13479,
      "name": "Cuautepec de Hinojosa",
      "names": {
        "id": "Cuaiopec de hinjosa",
        "ja": "羽尾デフノヨーサ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.15,
        "lng": -98.4333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13457,
      "name": "Xisa",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.4372,
        "lng": 104.6714
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13468,
      "name": "Oranienburg",
      "names": {
        "zh": "奥拉宁堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.7544,
        "lng": 13.2369
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13487,
      "name": "Gaya",
      "names": {
        "zh": "格雅",
        "ja": "ガヤー"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 11.8878,
        "lng": 3.4467
      },
      "country": "Niger",
      "importance": 2
    },
    {
      "id": 13504,
      "name": "Bafq",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 31.6053,
        "lng": 55.4028
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 6222,
      "name": "Alexandria",
      "names": {
        "es": "Alejandría",
        "fr": "Alexandrie",
        "zh": "亚历山大",
        "it": "Alessandria",
        "ja": "アレクサンドリア",
        "th": "ซานเดรีย"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 43.9686,
        "lng": 25.3333
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 13471,
      "name": "Olhao",
      "names": {
        "pt": "Olhão"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.0278,
        "lng": -7.8389
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 13498,
      "name": "San Pelayo",
      "names": {
        "zh": "圣佩莱奥",
        "ja": "サンペラヨ",
        "th": "ซานเปลีโย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.9578,
        "lng": -75.8375
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6226,
      "name": "Palayan City",
      "names": {
        "es": "Ciudad palayan",
        "fr": "Ville de Palayan",
        "zh": "Palayan市",
        "de": "Palay-Stadt",
        "it": "Città palayana",
        "id": "Kota Palayan",
        "ja": "パラヤンシティ",
        "th": "เมือง Palayan",
        "pt": "Cidade de palayan"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.5333,
        "lng": 121.0833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 6233,
      "name": "Liberia",
      "names": {
        "fr": "Libéria",
        "zh": "利比里亚",
        "ja": "リベリア",
        "th": "ประเทศไลบีเรีย",
        "pt": "Libéria"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 10.6338,
        "lng": -85.4333
      },
      "country": "Costa Rica",
      "importance": 1
    },
    {
      "id": 13349,
      "name": "Veldhoven",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.42,
        "lng": 5.405
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6241,
      "name": "Igdir",
      "names": {
        "zh": "伊迪尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.9237,
        "lng": 44.045
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 13506,
      "name": "Ciudad de Huajuapam de Leon",
      "names": {
        "es": "Ciudad de Huajuapam de León"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.8097,
        "lng": -97.7764
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13518,
      "name": "Svitlovodsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0833,
        "lng": 33.25
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 13522,
      "name": "Vallenar",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -28.5751,
        "lng": -70.7616
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 13398,
      "name": "Lodi",
      "names": {
        "zh": "洛迪",
        "ja": "ロディ",
        "th": "โลดิ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.3167,
        "lng": 9.5
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 13484,
      "name": "Rada`",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 14.4295,
        "lng": 44.8341
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 13552,
      "name": "Montemorelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.1872,
        "lng": -99.8267
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13545,
      "name": "Aksay",
      "names": {
        "zh": "阿克赛河"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.2667,
        "lng": 39.8667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 5887,
      "name": "Chiapa de Corzo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7083,
        "lng": -93.0169
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6252,
      "name": "Concepcion",
      "names": {
        "es": "Concepción",
        "zh": "康塞普西翁",
        "ja": "コンセプシオン",
        "th": "กอนเซปซิยอน"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -23.4025,
        "lng": -57.4414
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 6245,
      "name": "Arendal",
      "names": {
        "zh": "阿伦达尔",
        "ja": "アーレンダール"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.4667,
        "lng": 8.7667
      },
      "country": "Norway",
      "importance": 1
    },
    {
      "id": 5966,
      "name": "Iganga",
      "names": {
        "zh": "伊甘加"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.6092,
        "lng": 33.4686
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 13500,
      "name": "Pinamar",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.1,
        "lng": -56.85
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 13559,
      "name": "Rabinal",
      "names": {
        "zh": "拉维纳尔"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0833,
        "lng": -90.4917
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 13485,
      "name": "San Pedro Ayampuc",
      "names": {
        "fr": "San Pedro Ayamduc",
        "ja": "サンペドロアヤンプー"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7785,
        "lng": -90.4525
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 13530,
      "name": "Tank",
      "names": {
        "es": "Tanque",
        "fr": "Réservoir",
        "zh": "坦克",
        "de": "Panzer",
        "it": "Serbatoio",
        "id": "Tangki",
        "ja": "タンク",
        "th": "ถัง",
        "pt": "Tanque de guerra"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 32.2167,
        "lng": 70.3833
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 13558,
      "name": "Tres Valles",
      "names": {
        "ja": "トレスバリュ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.2367,
        "lng": -96.1369
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13503,
      "name": "Shazhou",
      "names": {
        "zh": "沙洲"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 40.1376,
        "lng": 94.6638
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6110,
      "name": "Meoqui",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 28.2722,
        "lng": -105.4819
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13584,
      "name": "Drama",
      "names": {
        "fr": "Drame",
        "zh": "戏剧",
        "it": "Dramma",
        "ja": "ドラマ",
        "th": "ละคร"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 41.1514,
        "lng": 24.1392
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 6157,
      "name": "Chalon-sur-Saone",
      "names": {
        "fr": "Chalon-sur-Saône",
        "zh": "夏龙索恩河畔",
        "ja": "chalonシュルsaone",
        "th": "chalon-ซูร์-saone"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.7806,
        "lng": 4.8528
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6261,
      "name": "Kyustendil",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.2797,
        "lng": 22.687
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 13486,
      "name": "Cajica",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9167,
        "lng": -74.025
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6255,
      "name": "Kabale",
      "names": {
        "zh": "卡巴莱"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -1.2496,
        "lng": 29.98
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 13611,
      "name": "Arboletes",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.8511,
        "lng": -76.4272
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6188,
      "name": "Trollhattan",
      "names": {
        "fr": "Trollhättan",
        "zh": "特罗尔海坦",
        "de": "Trollhättan",
        "pt": "Trollhättan"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.2671,
        "lng": 12.3
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 13540,
      "name": "Wiener Neustadt",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.8167,
        "lng": 16.25
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 13389,
      "name": "Zwijndrecht",
      "names": {
        "zh": "兹韦恩德雷赫特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.824,
        "lng": 4.6126
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 13622,
      "name": "Banaybanay",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 6.9699,
        "lng": 126.0126
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 13623,
      "name": "Vlissingen",
      "names": {
        "zh": "弗利辛恩"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.45,
        "lng": 3.5833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6266,
      "name": "Umm al Qaywayn",
      "names": {
        "de": "Ähm al qaywayn",
        "ja": "うーんアルカイウェイン"
      },
      "countryCode": "AE",
      "latLng": {
        "lat": 25.5653,
        "lng": 55.5533
      },
      "country": "United Arab Emirates",
      "importance": 1
    },
    {
      "id": 13637,
      "name": "San Juan y Martinez",
      "names": {
        "es": "San Juan y Martínez",
        "zh": "圣胡安Y Martinez",
        "ja": "サンファンyマルチネス",
        "th": "ซานฮวน Y มาร์ติเนซ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.2667,
        "lng": -83.8333
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 13526,
      "name": "Biella",
      "names": {
        "zh": "比耶拉",
        "ja": "ビエッラ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.5664,
        "lng": 8.0533
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 13596,
      "name": "Jisr ash Shughur",
      "names": {
        "fr": "JISR Ash Shugghur",
        "de": "JISR Asche Shughur",
        "ja": "JISR灰のシュウグリ",
        "pt": "Jisr cinza shughur"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.8,
        "lng": 36.3167
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 13642,
      "name": "Simojovel de Allende",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.15,
        "lng": -92.7167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13418,
      "name": "Roskilde",
      "names": {
        "zh": "罗斯基勒",
        "ja": "ロスキレ",
        "th": "กิลด์"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.65,
        "lng": 12.0833
      },
      "country": "Denmark",
      "importance": 2
    },
    {
      "id": 13647,
      "name": "Tuodian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.6907,
        "lng": 101.6382
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13446,
      "name": "Chalons-en-Champagne",
      "names": {
        "es": "Chalons-en-champán",
        "fr": "Châlons-en-Champagne",
        "zh": "沙隆-连接香槟",
        "it": "Chalons en-champagne",
        "ja": "chalons-専用シャンパン",
        "th": "chalons-ห้องแชมเปญ",
        "pt": "Châlons-en-champagne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9575,
        "lng": 4.365
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6236,
      "name": "Pattani",
      "names": {
        "zh": "北大年",
        "th": "ปัตตานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.8664,
        "lng": 101.2508
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 6121,
      "name": "Mantes-la-Jolie",
      "names": {
        "zh": "芒特拉若利",
        "ja": "mantes・ラ・ジョリー",
        "th": "mantes-ลาโจลี่"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.9908,
        "lng": 1.7172
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6253,
      "name": "Dunaujvaros",
      "names": {
        "zh": "多瑙新城"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9833,
        "lng": 18.9167
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 6209,
      "name": "Saint-Brieuc",
      "names": {
        "zh": "圣布里厄",
        "ja": "サン・brieuc",
        "th": "เซนต์-brieuc"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5136,
        "lng": -2.7653
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 13582,
      "name": "Turnhout",
      "names": {
        "zh": "蒂伦豪特"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.3167,
        "lng": 4.9333
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 6273,
      "name": "Afyonkarahisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7581,
        "lng": 30.5386
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 6214,
      "name": "Vilvoorde",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.9281,
        "lng": 4.4245
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 13510,
      "name": "Dunakeszi",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6297,
        "lng": 19.1381
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 13524,
      "name": "Debica",
      "names": {
        "zh": "登比察"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0515,
        "lng": 21.4114
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6268,
      "name": "Chimaltenango",
      "names": {
        "zh": "奇马尔特南戈"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6622,
        "lng": -90.8208
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 13711,
      "name": "Nabire",
      "names": {
        "zh": "纳比雷"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.3515,
        "lng": 135.5134
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 13691,
      "name": "Comendador",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.876,
        "lng": -71.707
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 13571,
      "name": "Kaufbeuren",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 47.88,
        "lng": 10.6225
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13697,
      "name": "Zongolica",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6642,
        "lng": -97.0011
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13699,
      "name": "Hinatuan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.3667,
        "lng": 126.3333
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 13636,
      "name": "Memmingen",
      "names": {
        "zh": "梅明根",
        "ja": "メミンゲン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.9878,
        "lng": 10.1811
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6176,
      "name": "Ciudad Constitucion",
      "names": {
        "es": "CIUDAD CONSTRUCCION",
        "id": "Ciudad konstituus",
        "ja": "Ciudad構成",
        "pt": "Ciudad constituição"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.0322,
        "lng": -111.6703
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6277,
      "name": "Baler",
      "names": {
        "es": "Enfardadora",
        "fr": "Presse à balles",
        "zh": "打包机",
        "de": "Ballenpresse",
        "it": "Rotopressa",
        "ja": "ベーラー",
        "th": "วิดน้ำ",
        "pt": "Atadeira de fardos"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 15.7583,
        "lng": 121.5625
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 13619,
      "name": "Etten-Leur",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5706,
        "lng": 4.6356
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6227,
      "name": "Thun",
      "names": {
        "zh": "图恩",
        "ja": "トゥーン",
        "th": "เทียน"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.759,
        "lng": 7.63
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 13681,
      "name": "Badian",
      "names": {
        "zh": "巴迪安"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.8694,
        "lng": 123.3959
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 6293,
      "name": "Murzuq",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 25.9136,
        "lng": 13.9336
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 6288,
      "name": "Veles",
      "names": {
        "zh": "韦莱斯"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.7153,
        "lng": 21.7753
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 13735,
      "name": "Takab",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.4008,
        "lng": 47.1131
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 6291,
      "name": "Stip",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.7358,
        "lng": 22.1914
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 6298,
      "name": "Concord",
      "names": {
        "zh": "康科德",
        "it": "A Concord",
        "ja": "コンコルド",
        "th": "คองคอร์ด"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 43.2305,
        "lng": -71.5595
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 6112,
      "name": "San Isidro",
      "names": {
        "zh": "圣伊斯丹",
        "ja": "サンアイズロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.4667,
        "lng": -58.5167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 13741,
      "name": "Roseller Lim",
      "names": {
        "zh": "罗塞尔林",
        "id": "Lim",
        "ja": "ロゼラーLIM",
        "pt": "Rosner lim"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 7.6568,
        "lng": 122.4701
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 13717,
      "name": "Villeta",
      "names": {
        "zh": "比耶塔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0128,
        "lng": -74.4731
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13754,
      "name": "Ponte de Lima",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.7667,
        "lng": -8.5833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 13667,
      "name": "Zevenaar",
      "names": {
        "zh": "泽弗纳尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9167,
        "lng": 6.0833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 13766,
      "name": "Bahia Honda",
      "names": {
        "es": "Bahía Honda",
        "zh": "巴伊亚本田",
        "ja": "バイアホンダ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9064,
        "lng": -83.1639
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 13729,
      "name": "Sambava",
      "names": {
        "zh": "桑巴瓦"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -14.2662,
        "lng": 50.1666
      },
      "country": "Madagascar",
      "importance": 2
    },
    {
      "id": 13550,
      "name": "Chateauroux",
      "names": {
        "zh": "沙托鲁"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.8103,
        "lng": 1.6911
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6017,
      "name": "Loreto",
      "names": {
        "zh": "洛雷托",
        "th": "เรโต"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.2667,
        "lng": -101.9833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13727,
      "name": "Istres",
      "names": {
        "zh": "伊斯特尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.5151,
        "lng": 4.9895
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6192,
      "name": "Valenciennes",
      "names": {
        "zh": "瓦朗谢讷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.358,
        "lng": 3.5233
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6239,
      "name": "Actopan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5036,
        "lng": -96.6192
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13703,
      "name": "Oxchuc",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.85,
        "lng": -92.4167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13747,
      "name": "Xihuachi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.8164,
        "lng": 108.0125
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13755,
      "name": "Venray",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5258,
        "lng": 5.9747
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6304,
      "name": "Hodmezovasarhely",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4303,
        "lng": 20.3189
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 13783,
      "name": "Hueyapan de Ocampo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.15,
        "lng": -95.15
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13785,
      "name": "Esperanza",
      "names": {
        "zh": "埃斯佩兰萨",
        "ja": "エスペランサ",
        "th": "เอสเปอรัน"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.4489,
        "lng": -60.9317
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 6243,
      "name": "Pinneberg",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 53.6591,
        "lng": 9.8009
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6145,
      "name": "Villa de Zaachila",
      "names": {
        "ja": "ヴィラデザチラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.9508,
        "lng": -96.7492
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13794,
      "name": "Andes",
      "names": {
        "zh": "安第斯山脉",
        "de": "Anden",
        "it": "Ande",
        "ja": "アンデス",
        "th": "เทือกเขาแอนดีส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6556,
        "lng": -75.8803
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13780,
      "name": "Alenquer",
      "names": {
        "zh": "阿伦克尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.05,
        "lng": -9.0167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6269,
      "name": "Kapan",
      "names": {
        "zh": "卡潘"
      },
      "countryCode": "AM",
      "latLng": {
        "lat": 39.2011,
        "lng": 46.415
      },
      "country": "Armenia",
      "importance": 1
    },
    {
      "id": 13801,
      "name": "Aguazul",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.1689,
        "lng": -72.5467
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13808,
      "name": "Veroia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.5203,
        "lng": 22.2019
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 13714,
      "name": "Baniyas",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.1822,
        "lng": 35.9403
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 13818,
      "name": "Cumaribo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4461,
        "lng": -69.7956
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13803,
      "name": "Ambatondrazaka",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -17.8329,
        "lng": 48.4167
      },
      "country": "Madagascar",
      "importance": 2
    },
    {
      "id": 13704,
      "name": "Khutubi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 44.1874,
        "lng": 86.8946
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13790,
      "name": "Rantepao",
      "names": {
        "zh": "兰特包"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -2.969,
        "lng": 119.9
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 13734,
      "name": "Macenta",
      "names": {
        "zh": "马森塔"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 8.5504,
        "lng": -9.48
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 13813,
      "name": "Nansan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.7784,
        "lng": 98.8253
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13824,
      "name": "Jesus Maria",
      "names": {
        "fr": "Jésus Maria",
        "zh": "耶稣玛丽亚",
        "it": "Gesù Maria",
        "id": "Yesus Maria",
        "ja": "イエスマリア",
        "th": "พระเยซูมาเรีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.9667,
        "lng": -102.35
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13835,
      "name": "Gualeguay",
      "names": {
        "zh": "瓜莱瓜伊"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.15,
        "lng": -59.3167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 13828,
      "name": "Bihac",
      "names": {
        "zh": "比哈奇"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.8167,
        "lng": 15.8667
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 6319,
      "name": "Garbahaarrey",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 3.35,
        "lng": 42.2667
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 6254,
      "name": "Zugdidi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.5083,
        "lng": 41.8667
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 13834,
      "name": "Poptun",
      "names": {
        "es": "Poptún"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 16.3222,
        "lng": -89.4222
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 6316,
      "name": "Franceville",
      "names": {
        "zh": "弗朗斯维尔"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -1.6333,
        "lng": 13.5833
      },
      "country": "Gabon",
      "importance": 1
    },
    {
      "id": 13809,
      "name": "Ciechanow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.8817,
        "lng": 20.6106
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 13836,
      "name": "Jatibonico",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 21.9464,
        "lng": -79.1675
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 13765,
      "name": "Nijkerk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2167,
        "lng": 5.4833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 13682,
      "name": "San Cristobal Totonicapan",
      "names": {
        "es": "San Cristóbal Totononicapan",
        "fr": "San Cristobal Totononicapan",
        "it": "San Cristobal Toonicapan",
        "ja": "サンクリストバールトトコパン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9197,
        "lng": -91.44
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 6173,
      "name": "Badulla",
      "names": {},
      "countryCode": "LK",
      "latLng": {
        "lat": 6.9847,
        "lng": 81.0564
      },
      "country": "Sri Lanka",
      "importance": 1
    },
    {
      "id": 13758,
      "name": "Noordwijk",
      "names": {
        "zh": "诺德韦克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2333,
        "lng": 4.4333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6259,
      "name": "Swidnik",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.2333,
        "lng": 22.7
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 13748,
      "name": "Otwock",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.1167,
        "lng": 21.2667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 13862,
      "name": "Vawkavysk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.1667,
        "lng": 24.4667
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 13788,
      "name": "Lorenskog",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.8989,
        "lng": 10.9642
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 13857,
      "name": "Nurmijarvi",
      "names": {
        "de": "Nurmijärvi"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4667,
        "lng": 24.8083
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 6272,
      "name": "Fort Portal",
      "names": {
        "es": "Portal de fort",
        "fr": "Portail fort",
        "zh": "堡垒门户",
        "de": "Fortportal",
        "it": "Portale di Fort",
        "id": "Portal",
        "ja": "フォートポータル",
        "th": "พอร์ทัลป้อม"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.671,
        "lng": 30.275
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 6328,
      "name": "Quinhamel",
      "names": {},
      "countryCode": "GW",
      "latLng": {
        "lat": 11.8869,
        "lng": -15.8556
      },
      "country": "Guinea-Bissau",
      "importance": 1
    },
    {
      "id": 13839,
      "name": "Miranda",
      "names": {
        "zh": "米兰达",
        "ja": "ミランダ",
        "th": "มิแรนดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.2503,
        "lng": -76.2286
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6325,
      "name": "Dubrovnik",
      "names": {
        "zh": "杜布罗夫尼克",
        "ja": "ドブロブニク",
        "th": "ดูบรอฟนิก"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 42.6403,
        "lng": 18.1083
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 13888,
      "name": "Imperia",
      "names": {
        "zh": "因佩里亚",
        "ja": "インペリア"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.8865,
        "lng": 8.0297
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 13889,
      "name": "Charlottetown",
      "names": {
        "zh": "夏洛特敦",
        "ja": "シャーロットタウン",
        "th": "ชาร์ลอตต์ทาวน์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 46.2403,
        "lng": -63.1347
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 13761,
      "name": "Wismar",
      "names": {
        "zh": "维斯马",
        "ja": "ヴィスマール"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.8925,
        "lng": 11.465
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6335,
      "name": "Guarda",
      "names": {
        "zh": "瓜达",
        "th": "กวาร์ดา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.5364,
        "lng": -7.2683
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 13860,
      "name": "Borken",
      "names": {
        "zh": "博尔肯"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8439,
        "lng": 6.8583
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13899,
      "name": "El Charco",
      "names": {
        "fr": "El charbon",
        "zh": "埃尔科斯",
        "ja": "エルチャコ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.4775,
        "lng": -78.1111
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13848,
      "name": "Loufan",
      "names": {
        "zh": "娄烦"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0694,
        "lng": 111.7911
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13846,
      "name": "Gifhorn",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.4886,
        "lng": 10.5464
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13882,
      "name": "Mariel",
      "names": {
        "zh": "马列尔"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9936,
        "lng": -82.7539
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 13852,
      "name": "Zvolen",
      "names": {
        "zh": "兹沃伦"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.5831,
        "lng": 19.1331
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 6249,
      "name": "Tarbes",
      "names": {
        "zh": "塔布"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.23,
        "lng": 0.07
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 13819,
      "name": "Singhanakhon",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 7.2389,
        "lng": 100.5506
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 6336,
      "name": "Si Sa Ket",
      "names": {
        "fr": "Si SA SA KET",
        "ja": "SAケット",
        "th": "ศรีสะเกษ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.1072,
        "lng": 104.3291
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 6170,
      "name": "Bang Kruai",
      "names": {
        "zh": "邦克瑞",
        "ja": "バングクルイ",
        "th": "บางกรวย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8042,
        "lng": 100.4755
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 13867,
      "name": "Eisenach",
      "names": {
        "zh": "爱森纳赫",
        "ja": "アイゼナハ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9747,
        "lng": 10.3244
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13884,
      "name": "Kotabumi",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -4.8333,
        "lng": 104.9
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 13906,
      "name": "Cuihua",
      "names": {
        "zh": "催化"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.7527,
        "lng": 103.8906
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13918,
      "name": "President Quirino",
      "names": {
        "es": "Presidente Quirino",
        "fr": "Président Quirino",
        "zh": "Quirino总统",
        "de": "Präsident Quirino",
        "it": "Presidente Quirino",
        "id": "Presiden Quirino",
        "ja": "Quiriino大統領",
        "th": "ประธาน Quiriino",
        "pt": "Presidente Quirino"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.7,
        "lng": 124.7333
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 13936,
      "name": "Claveria",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 12.9035,
        "lng": 123.2457
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 13844,
      "name": "Sieradz",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.6,
        "lng": 18.75
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 13977,
      "name": "Caceres",
      "names": {
        "es": "Cáceres",
        "zh": "卡塞雷斯",
        "id": "Cáceres",
        "ja": "カセレス",
        "th": "คาเซเรส",
        "pt": "Cáceres"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.5794,
        "lng": -75.3503
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13992,
      "name": "Coyuca de Catalan",
      "names": {
        "es": "COYUCA DE CATALANO",
        "fr": "Coyeuca de Catalan",
        "zh": "Coyuca de加泰罗尼亚州",
        "it": "Coyuca de catalano",
        "id": "Ciyuca de catalan",
        "pt": "Coyuca de Catalão"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3256,
        "lng": -100.6992
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13988,
      "name": "Sarab",
      "names": {
        "zh": "萨拉卜"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 37.9408,
        "lng": 47.5367
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 13891,
      "name": "Mikolow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.1961,
        "lng": 18.8619
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6341,
      "name": "Miercurea-Ciuc",
      "names": {
        "fr": "-Miercurea ciuc"
      },
      "countryCode": "RO",
      "latLng": {
        "lat": 46.361,
        "lng": 25.524
      },
      "country": "Romania",
      "importance": 1
    },
    {
      "id": 13993,
      "name": "Korenovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.4667,
        "lng": 39.45
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 13999,
      "name": "Tequila",
      "names": {
        "zh": "龙舌兰酒",
        "ja": "テキーラ",
        "th": "เตกีล่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8794,
        "lng": -103.8356
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14002,
      "name": "Santa Rosa del Sur",
      "names": {
        "zh": "圣罗莎del sur",
        "ja": "サンタローザデルシュール"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.9633,
        "lng": -74.0533
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13979,
      "name": "Lingquan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.9985,
        "lng": 110.8417
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13964,
      "name": "Aurich",
      "names": {
        "zh": "奥里希"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.4714,
        "lng": 7.4836
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13814,
      "name": "Tiel",
      "names": {
        "zh": "蒂尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8833,
        "lng": 5.4333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6141,
      "name": "Mercedes",
      "names": {
        "zh": "梅赛德斯",
        "ja": "メルセデス",
        "th": "เมอร์เซเดส"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -33.25,
        "lng": -58.0319
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 13871,
      "name": "Amberg",
      "names": {
        "zh": "安贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4444,
        "lng": 11.8483
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13928,
      "name": "Heinsberg",
      "names": {
        "zh": "海因斯贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0631,
        "lng": 6.0964
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 795,
      "name": "Gitega",
      "names": {
        "zh": "基特加"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.4283,
        "lng": 29.925
      },
      "country": "Burundi",
      "importance": 0
    },
    {
      "id": 13922,
      "name": "Tactic",
      "names": {
        "es": "Táctica",
        "fr": "Tactique",
        "zh": "策略",
        "de": "Taktik",
        "it": "Tattica",
        "id": "Taktik",
        "ja": "戦術",
        "th": "กลยุทธ์",
        "pt": "Tática"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3167,
        "lng": -90.3511
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 14017,
      "name": "Uspantan",
      "names": {
        "es": "Uspantán"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3458,
        "lng": -90.8694
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 5971,
      "name": "Ocosingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.9072,
        "lng": -92.0961
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13974,
      "name": "Montenegro",
      "names": {
        "fr": "Monténégro",
        "zh": "黑山",
        "ja": "モンテネグロ",
        "th": "มอนเตเนโกร"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5653,
        "lng": -75.7511
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 13954,
      "name": "Ansbach",
      "names": {
        "zh": "安斯巴赫",
        "ja": "アンスバッハ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3,
        "lng": 10.5833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14005,
      "name": "Yinggen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 19.0372,
        "lng": 109.8283
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 13950,
      "name": "Homburg",
      "names": {
        "zh": "毡帽",
        "ja": "ホンブルク",
        "th": "ฮอม"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3167,
        "lng": 7.3333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6247,
      "name": "Coyotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7756,
        "lng": -99.2056
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13961,
      "name": "Castres",
      "names": {
        "zh": "卡斯特尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6,
        "lng": 2.25
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 13965,
      "name": "Nordhausen",
      "names": {
        "zh": "诺德豪森"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.505,
        "lng": 10.7911
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14009,
      "name": "Tadif",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.348,
        "lng": 37.53
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 13929,
      "name": "Uden",
      "names": {
        "zh": "于登"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6667,
        "lng": 5.6167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 13959,
      "name": "Macerata",
      "names": {
        "zh": "马切拉塔",
        "ja": "マチェラータ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.3003,
        "lng": 13.4533
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 13756,
      "name": "Angouleme",
      "names": {
        "fr": "Angoulême",
        "zh": "昂古莱姆"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.65,
        "lng": 0.16
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6305,
      "name": "Kampong Chhnang",
      "names": {
        "th": "กม. ชนัง"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 12.25,
        "lng": 104.6667
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 6265,
      "name": "Boumerdes",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7594,
        "lng": 3.4728
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 14060,
      "name": "Pueblo Nuevo",
      "names": {
        "zh": "普韦布洛·努沃"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.505,
        "lng": -75.5075
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14025,
      "name": "Bayt al Faqih",
      "names": {
        "ja": "ベイアルFAQIH"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.5167,
        "lng": 43.3167
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 6225,
      "name": "Jaltipan de Morelos",
      "names": {
        "zh": "朱莉丹德莫雷斯",
        "pt": "Jalctipan de Morelos"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9703,
        "lng": -94.7144
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 13989,
      "name": "Su-ngai Kolok",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 6.0297,
        "lng": 101.9668
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 14056,
      "name": "Santa Cruz de Los Taques",
      "names": {
        "fr": "Santa Cruz de los Tatques",
        "ja": "サンタクルスデロスタクス"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 11.8231,
        "lng": -70.2535
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 6250,
      "name": "Arras",
      "names": {
        "zh": "阿拉斯",
        "ja": "アラス",
        "th": "อาร์ราส"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.292,
        "lng": 2.78
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 14045,
      "name": "Amuntai",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.4177,
        "lng": 115.2494
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 6183,
      "name": "Lindi",
      "names": {
        "zh": "林迪"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -9.9969,
        "lng": 39.7144
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 14083,
      "name": "Yarumal",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.9633,
        "lng": -75.4172
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6368,
      "name": "Puntarenas",
      "names": {
        "zh": "蓬塔雷纳斯",
        "th": "ปันตาเรนาส"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 9.9764,
        "lng": -84.8339
      },
      "country": "Costa Rica",
      "importance": 1
    },
    {
      "id": 14065,
      "name": "Swinoujscie",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.9167,
        "lng": 14.25
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 13986,
      "name": "Poti",
      "names": {
        "zh": "波季"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 42.15,
        "lng": 41.6667
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 13805,
      "name": "Siegburg",
      "names": {
        "zh": "锡格堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8014,
        "lng": 7.2044
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6382,
      "name": "Lusambo",
      "names": {
        "zh": "卢桑博"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -4.9696,
        "lng": 23.43
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 13916,
      "name": "Shepetivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.1833,
        "lng": 27.0667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 6299,
      "name": "Kalmar",
      "names": {
        "zh": "卡尔马",
        "it": "Di Kalmar",
        "ja": "カルマル",
        "th": "คาลมาร์"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.6694,
        "lng": 16.3218
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 14015,
      "name": "San",
      "names": {
        "zh": "圣",
        "ja": "サン",
        "th": "ซาน"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 13.3004,
        "lng": -4.9
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 6383,
      "name": "Kars",
      "names": {
        "zh": "卡尔斯",
        "ja": "カルス"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6078,
        "lng": 43.0958
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 6347,
      "name": "Bambari",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 5.7667,
        "lng": 20.6833
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 14069,
      "name": "Tuchin",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1858,
        "lng": -75.5553
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6370,
      "name": "Narathiwat",
      "names": {
        "zh": "那拉提瓦",
        "th": "นราธิวาส"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.4264,
        "lng": 101.8231
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 6251,
      "name": "Teoloyucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7442,
        "lng": -99.1811
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14112,
      "name": "Nazilli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9125,
        "lng": 28.3206
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 14121,
      "name": "El Tocuyo",
      "names": {
        "fr": "El ttucuyo",
        "ja": "エルツクイオ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.7822,
        "lng": -69.7931
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 14124,
      "name": "Arauquita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0261,
        "lng": -71.4272
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14123,
      "name": "Niquero",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 20.0472,
        "lng": -77.5781
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 6377,
      "name": "La Paz",
      "names": {
        "zh": "拉巴斯",
        "ja": "ラパス"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.3169,
        "lng": -87.6831
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 13983,
      "name": "Coburg",
      "names": {
        "zh": "科堡",
        "ja": "コーブルク",
        "th": "โคเบิร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.2585,
        "lng": 10.9579
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 13842,
      "name": "Bourg-en-Bresse",
      "names": {
        "zh": "布尔-连接伯黑斯",
        "ja": "城下町-専用bresse",
        "th": "Bourg-ห้อง bresse"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.2056,
        "lng": 5.2289
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 14127,
      "name": "Chaves",
      "names": {
        "zh": "查韦斯"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.7399,
        "lng": -7.4707
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6313,
      "name": "Beverwijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4864,
        "lng": 4.6572
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6385,
      "name": "Villarrica",
      "names": {
        "zh": "比亚里卡",
        "th": "บียาร์รีกา"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.75,
        "lng": -56.4333
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 14142,
      "name": "Guaduas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0694,
        "lng": -74.5981
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14141,
      "name": "Temryuk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.2667,
        "lng": 37.3833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 6302,
      "name": "Mityana",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.4004,
        "lng": 32.05
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 14137,
      "name": "Ceyhan",
      "names": {
        "zh": "杰伊汗"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0283,
        "lng": 35.8139
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 6352,
      "name": "Cao Bang",
      "names": {
        "fr": "Caro bang",
        "ja": "カオバン",
        "th": "เฉาปัง"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 22.6731,
        "lng": 106.25
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 6374,
      "name": "Mambajao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.25,
        "lng": 124.7167
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 6169,
      "name": "Embu",
      "names": {
        "zh": "恩布"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.5333,
        "lng": 37.45
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 6394,
      "name": "Kozani",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.3007,
        "lng": 21.789
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 14164,
      "name": "Ciudad Dario",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 12.7333,
        "lng": -86.1167
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 14171,
      "name": "Chambas",
      "names": {
        "zh": "钱巴斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.1967,
        "lng": -78.9133
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 14177,
      "name": "Segovia",
      "names": {
        "fr": "Ségovie",
        "zh": "塞哥维亚",
        "ja": "セゴビア",
        "th": "เซโกเวีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0781,
        "lng": -74.7017
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14068,
      "name": "Eberswalde",
      "names": {
        "zh": "埃伯斯瓦尔德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.8331,
        "lng": 13.8331
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14095,
      "name": "Wijchen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8067,
        "lng": 5.7211
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6402,
      "name": "Famagusta",
      "names": {
        "fr": "Famagouste",
        "zh": "法马古斯塔",
        "it": "Famagosta"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 35.1167,
        "lng": 33.95
      },
      "country": "Cyprus",
      "importance": 1
    },
    {
      "id": 14200,
      "name": "Dronten",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5242,
        "lng": 5.7125
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 14073,
      "name": "Saint-Germain-en-Laye",
      "names": {
        "zh": "圣日耳曼-连接图层",
        "ja": "・サンジェルマン・専用laye",
        "th": "แซงต์แชร์กแมงออง-laye"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8989,
        "lng": 2.0938
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 13894,
      "name": "Ales",
      "names": {
        "zh": "啤酒",
        "ja": "エール",
        "th": "เบียร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.1281,
        "lng": 4.0817
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 14046,
      "name": "Schwabach",
      "names": {
        "zh": "施瓦巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3292,
        "lng": 11.0208
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14219,
      "name": "Gonabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.3528,
        "lng": 58.6836
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 14221,
      "name": "Pie de Pato",
      "names": {
        "it": "Torta de pato",
        "ja": "パイパト",
        "th": "พายเดอพาโต"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5153,
        "lng": -76.9747
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6404,
      "name": "Puerto Francisco de Orellana",
      "names": {
        "ja": "プエルトフランシスコデオレリーナ",
        "th": "เปอร์โตฟรานซิสโกเดอออเรเซลน่า"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -0.4625,
        "lng": -76.9842
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 14149,
      "name": "Shanhe",
      "names": {
        "zh": "山河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.4844,
        "lng": 108.364
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 14218,
      "name": "Tomar",
      "names": {
        "zh": "托马尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.6,
        "lng": -8.4167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6234,
      "name": "Boulogne-sur-Mer",
      "names": {
        "zh": "布洛涅 - 滨海",
        "ja": "ブローニュ\u003dシュル\u003dメール",
        "th": "boulogne-ซูร์ mer"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.7264,
        "lng": 1.6147
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6196,
      "name": "Artigas",
      "names": {
        "zh": "阿蒂加斯"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -30.4028,
        "lng": -56.4704
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 6413,
      "name": "Samkir",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.8297,
        "lng": 46.0189
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 14228,
      "name": "Vahdat",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 38.5531,
        "lng": 69.0197
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 14195,
      "name": "Gap",
      "names": {
        "es": "Brecha",
        "fr": "Écart",
        "zh": "差距",
        "de": "Lücke",
        "ja": "ギャップ",
        "th": "ช่องว่าง",
        "pt": "Lacuna"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.5594,
        "lng": 6.0786
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6403,
      "name": "Romblon",
      "names": {
        "zh": "朗布隆"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 12.5789,
        "lng": 122.2747
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 14128,
      "name": "Compiegne",
      "names": {
        "fr": "Compiègne",
        "zh": "贡比涅",
        "ja": "コンピエーニュ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.4149,
        "lng": 2.8231
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 14181,
      "name": "El Tumbador",
      "names": {
        "fr": "El tambadeur",
        "pt": "El tumpador"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8667,
        "lng": -91.9333
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 14113,
      "name": "Szigetszentmiklos",
      "names": {
        "de": "Szigetszentmiklós"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3453,
        "lng": 19.0483
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 14261,
      "name": "Cumbal",
      "names": {
        "zh": "昆巴尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.9078,
        "lng": -77.7908
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14115,
      "name": "Sovetsk",
      "names": {
        "zh": "苏维埃茨克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.0833,
        "lng": 21.8833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 14130,
      "name": "Thionville",
      "names": {
        "zh": "蒂翁维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.3589,
        "lng": 6.1692
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 14207,
      "name": "Schwabisch Hall",
      "names": {
        "es": "Hall de Schwabisch",
        "zh": "施瓦巴斯霍尔",
        "de": "Schwäbige Halle",
        "id": "Aula schwabisch",
        "ja": "シュヴァイビスホール",
        "th": "ห้องโถง Schwabisch",
        "pt": "Salão de schwabisch"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.1122,
        "lng": 9.7375
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14169,
      "name": "Pirmasens",
      "names": {
        "zh": "皮尔马森斯"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.2,
        "lng": 7.6
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14281,
      "name": "Aracataca",
      "names": {
        "zh": "阿拉卡塔卡"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.5911,
        "lng": -74.185
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14277,
      "name": "Asuncion Mita",
      "names": {
        "es": "Asunción mita",
        "zh": "亚松森mita",
        "ja": "アスンシオンMITA",
        "pt": "Assunção Mita"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.3333,
        "lng": -89.7167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 6425,
      "name": "Yambio",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 4.5705,
        "lng": 28.4163
      },
      "country": "South Sudan",
      "importance": 1
    },
    {
      "id": 6426,
      "name": "Bouar",
      "names": {
        "zh": "布阿尔"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 5.95,
        "lng": 15.6
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 14237,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5331,
        "lng": -76.1006
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14193,
      "name": "Romny",
      "names": {
        "zh": "罗姆内"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.7428,
        "lng": 33.4879
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 6427,
      "name": "Montana",
      "names": {
        "zh": "蒙大拿",
        "ja": "モンタナ",
        "th": "มอนแทนา"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.4055,
        "lng": 23.2242
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 6422,
      "name": "Bjelovar",
      "names": {
        "zh": "别洛瓦尔"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.8989,
        "lng": 16.8422
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 14304,
      "name": "Belaya Kalitva",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 48.1667,
        "lng": 40.7833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 14264,
      "name": "Halberstadt",
      "names": {
        "zh": "哈尔伯施塔特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8958,
        "lng": 11.0467
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14323,
      "name": "Camargo",
      "names": {
        "zh": "卡马戈"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.667,
        "lng": -105.167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6430,
      "name": "El Golea",
      "names": {
        "ja": "エルゴレア"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 30.6,
        "lng": 2.9
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 6346,
      "name": "Myrhorod",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.964,
        "lng": 33.6124
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 14292,
      "name": "Tuquerres",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.0872,
        "lng": -77.6189
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14061,
      "name": "Podilsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.7419,
        "lng": 29.535
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 14186,
      "name": "Taicheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7206,
        "lng": 113.2437
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 14309,
      "name": "Gourcy",
      "names": {
        "zh": "古尔西"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 13.2063,
        "lng": -2.3586
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 14334,
      "name": "Oroqen Zizhiqi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 50.5667,
        "lng": 123.7167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 14320,
      "name": "San Agustin Acasaguastlan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.95,
        "lng": -89.9667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 6432,
      "name": "Inongo",
      "names": {
        "zh": "伊农戈"
      },
      "countryCode": "CD",
      "latLng": {
        "lat": -1.94,
        "lng": 18.28
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 14134,
      "name": "Jarvenpaa",
      "names": {
        "fr": "Järvenpää",
        "zh": "耶尔文佩"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4722,
        "lng": 25.0889
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 14333,
      "name": "Raxruha",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.8666,
        "lng": -90.0424
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 14344,
      "name": "Altagracia de Orituco",
      "names": {
        "fr": "Altagracia de Orruco"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.8504,
        "lng": -66.38
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 14129,
      "name": "Hellevoetsluis",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8333,
        "lng": 4.1333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6400,
      "name": "Panaji",
      "names": {
        "zh": "帕纳吉"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 15.48,
        "lng": 73.83
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 14036,
      "name": "Kwidzyn",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.7358,
        "lng": 18.9308
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 14270,
      "name": "Neumarkt",
      "names": {
        "zh": "诺伊马克特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.2833,
        "lng": 11.4667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6436,
      "name": "Retalhuleu",
      "names": {
        "zh": "雷塔卢莱乌"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5333,
        "lng": -91.6833
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 14347,
      "name": "Isfara",
      "names": {
        "zh": "伊斯法拉"
      },
      "countryCode": "TJ",
      "latLng": {
        "lat": 40.1167,
        "lng": 70.6333
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 14351,
      "name": "Alashankou",
      "names": {
        "zh": "阿拉山口"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 45.1714,
        "lng": 82.5731
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6258,
      "name": "Melun",
      "names": {
        "zh": "默伦",
        "th": "เมลัน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5406,
        "lng": 2.66
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 14038,
      "name": "Chojnice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.6955,
        "lng": 17.557
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 14267,
      "name": "Yian",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 47.8804,
        "lng": 125.3
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 14348,
      "name": "Jayrud",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.8072,
        "lng": 36.7403
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 14318,
      "name": "Saraqib",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.8644,
        "lng": 36.8058
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 6439,
      "name": "Xacmaz",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.4681,
        "lng": 48.8028
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 6440,
      "name": "Brcko",
      "names": {
        "zh": "布尔奇科"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.8783,
        "lng": 18.8092
      },
      "country": "Bosnia And Herzegovina",
      "importance": 1
    },
    {
      "id": 6386,
      "name": "Ngozi",
      "names": {
        "zh": "恩戈齐"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -2.9,
        "lng": 29.8167
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 14369,
      "name": "Acevedo",
      "names": {
        "zh": "阿塞韦多"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.8047,
        "lng": -75.8897
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6441,
      "name": "Targovishte",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.2414,
        "lng": 26.5719
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 14230,
      "name": "Freiberg",
      "names": {
        "zh": "弗莱贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9119,
        "lng": 13.3428
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6443,
      "name": "Koumra",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 8.9,
        "lng": 17.55
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 14313,
      "name": "Povazska Bystrica",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.1167,
        "lng": 18.45
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 14366,
      "name": "Rauma",
      "names": {
        "zh": "劳马"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.1167,
        "lng": 21.5
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 14401,
      "name": "Dolores",
      "names": {
        "zh": "多洛雷斯",
        "ja": "ドロレス",
        "th": "โดโลเรส"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 16.5142,
        "lng": -89.4158
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 14279,
      "name": "Hofheim",
      "names": {
        "zh": "霍夫海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.0876,
        "lng": 8.4447
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14329,
      "name": "Hangu",
      "names": {
        "zh": "汉沽"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.5281,
        "lng": 71.0572
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 14398,
      "name": "Apsheronsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.4608,
        "lng": 39.7406
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 6450,
      "name": "Massawa",
      "names": {
        "zh": "马萨瓦"
      },
      "countryCode": "ER",
      "latLng": {
        "lat": 15.6,
        "lng": 39.4333
      },
      "country": "Eritrea",
      "importance": 1
    },
    {
      "id": 14175,
      "name": "Maarssen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1408,
        "lng": 5.0394
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6330,
      "name": "Daman",
      "names": {
        "zh": "达曼",
        "ja": "ダマン",
        "th": "ดามัน"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 20.4169,
        "lng": 72.834
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 6446,
      "name": "Surin",
      "names": {
        "zh": "苏林海滩",
        "ja": "スリン",
        "th": "สุรินทร์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.8864,
        "lng": 103.4932
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 14185,
      "name": "Novozybkov",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 52.5333,
        "lng": 31.9333
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 14091,
      "name": "Nowa Sol",
      "names": {
        "zh": "现在是索尔",
        "id": "Nona Sol",
        "th": "ตอนนี้โซล"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8,
        "lng": 15.7167
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 14402,
      "name": "Bosconia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.9761,
        "lng": -73.8903
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6456,
      "name": "Mochudi",
      "names": {},
      "countryCode": "BW",
      "latLng": {
        "lat": -24.4167,
        "lng": 26.15
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 14386,
      "name": "Soria",
      "names": {
        "zh": "索里亚",
        "ja": "ソリア",
        "th": "โซเรีย"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 41.7667,
        "lng": -2.4667
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 6454,
      "name": "Santa Elena",
      "names": {
        "zh": "圣艾琳娜",
        "ja": "サンタエレナ",
        "th": "ซานตาเอเลน่า"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -2.2267,
        "lng": -80.8583
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 6399,
      "name": "Oshakati",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -17.8,
        "lng": 15.6833
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 14399,
      "name": "Libano",
      "names": {
        "zh": "利巴诺"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9206,
        "lng": -75.0611
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6362,
      "name": "Douai",
      "names": {
        "zh": "杜埃"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.3714,
        "lng": 3.08
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6437,
      "name": "Chachoengsao",
      "names": {
        "th": "ฉะเชิงเทรา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6903,
        "lng": 101.0703
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 14370,
      "name": "Villa de San Diego de Ubate",
      "names": {
        "ja": "ヴィラデサンディエゴデウベイト"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.3072,
        "lng": -73.8144
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6447,
      "name": "Jordan",
      "names": {
        "es": "Jordania",
        "fr": "Jordanie",
        "zh": "约旦",
        "de": "Jordanien",
        "it": "Giordania",
        "id": "Yordania",
        "ja": "ヨルダン",
        "th": "จอร์แดน"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.6,
        "lng": 122.6
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 14411,
      "name": "Salcedo",
      "names": {
        "zh": "萨尔塞多"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.42,
        "lng": -70.39
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 14342,
      "name": "Skovde",
      "names": {
        "zh": "舍夫德",
        "de": "Skövde",
        "id": "Skövde"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.3995,
        "lng": 13.8538
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 14454,
      "name": "San Juan del Cesar",
      "names": {
        "zh": "圣胡安del Cesar",
        "ja": "サンファンデルセザー",
        "th": "ซานฮวนเดลซีซาร์"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7708,
        "lng": -73.0031
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14448,
      "name": "San Jose de Ocoa",
      "names": {
        "zh": "圣何塞德ocoa",
        "it": "San Jose de ocaa",
        "ja": "サンノゼ斗",
        "th": "ซานโฮเซ่เดอโอโค"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.55,
        "lng": -70.5
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 14439,
      "name": "Stendal",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.6,
        "lng": 11.85
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6417,
      "name": "Parnu",
      "names": {
        "zh": "派尔努",
        "de": "Pärnu"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.3844,
        "lng": 24.4989
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 14451,
      "name": "Borlange",
      "names": {
        "de": "Borlänge",
        "pt": "Borlänge"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 60.4833,
        "lng": 15.4167
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 14150,
      "name": "Boleslawiec",
      "names": {
        "zh": "博莱斯瓦维茨"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.2667,
        "lng": 15.5667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6465,
      "name": "Touggourt",
      "names": {
        "zh": "图古尔特"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.1,
        "lng": 6.0667
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 14442,
      "name": "Sanjiang",
      "names": {
        "zh": "三江"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 24.7265,
        "lng": 112.2884
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 14475,
      "name": "Abrantes",
      "names": {
        "zh": "阿夫兰特斯"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4644,
        "lng": -8.1978
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 14477,
      "name": "Puerto Berrio",
      "names": {
        "ja": "プエルトベルリオ",
        "th": "เปอร์โตริโก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4906,
        "lng": -74.4047
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14490,
      "name": "Taraza",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.5839,
        "lng": -75.4003
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14476,
      "name": "Apan",
      "names": {
        "zh": "阿潘"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7,
        "lng": -98.4333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14486,
      "name": "Baghlan",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.1328,
        "lng": 68.7
      },
      "country": "Afghanistan",
      "importance": 2
    },
    {
      "id": 6479,
      "name": "Annapolis",
      "names": {
        "zh": "安纳波利斯",
        "ja": "アナポリス",
        "th": "แอนนาโปลิส"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.9706,
        "lng": -76.5047
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 6285,
      "name": "Playa Vicente",
      "names": {
        "id": "Orang tua",
        "ja": "プラヤビセンテ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.8333,
        "lng": -95.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6475,
      "name": "Agcabadi",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.0489,
        "lng": 47.4502
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 6340,
      "name": "Kampot",
      "names": {
        "zh": "贡布"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 10.6,
        "lng": 104.1667
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 14505,
      "name": "El Viejo",
      "names": {
        "ja": "エルビエホ"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.6667,
        "lng": -87.1667
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 14226,
      "name": "Heemskerk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5108,
        "lng": 4.6728
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 14379,
      "name": "Michalovce",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7575,
        "lng": 21.9183
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 14526,
      "name": "Jiquipilas",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.6683,
        "lng": -93.6444
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14388,
      "name": "Draguignan",
      "names": {
        "zh": "德拉吉尼昂"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.5403,
        "lng": 6.4667
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 14297,
      "name": "Oswiecim",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0333,
        "lng": 19.2333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 14542,
      "name": "Toumodi",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 6.552,
        "lng": -5.019
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 6419,
      "name": "Dun Dealgan",
      "names": {
        "zh": "DUN DENAGAN",
        "it": "Dun deangan",
        "ja": "Dun Deargan",
        "pt": "Dealdade dun"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 54.009,
        "lng": -6.4049
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 14530,
      "name": "Al Malikiyah",
      "names": {
        "id": "Al Mangiyah",
        "ja": "アルマリキヤ"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 37.1031,
        "lng": 42.0822
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 14446,
      "name": "Trofa",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3374,
        "lng": -8.5596
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6326,
      "name": "Ciudad Melchor Muzquiz",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 27.8775,
        "lng": -101.5164
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6387,
      "name": "Xam Nua",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 20.4133,
        "lng": 104.048
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 6487,
      "name": "Tromso",
      "names": {
        "es": "Tromsø",
        "zh": "特罗姆瑟",
        "th": "ทรอมโซ",
        "pt": "Tromsø"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 69.6546,
        "lng": 18.9637
      },
      "country": "Norway",
      "importance": 1
    },
    {
      "id": 14425,
      "name": "La Chaux-de-Fonds",
      "names": {
        "ja": "La Chaux-de-Fongs",
        "pt": "La chaux-de-afligornos"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.0996,
        "lng": 6.8296
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 14495,
      "name": "Yakacik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.75,
        "lng": 36.2167
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 14565,
      "name": "Galeana",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 24.8333,
        "lng": -100.0667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6309,
      "name": "Juan Rodriguez Clara",
      "names": {
        "ja": "Juan Rodriguezクララ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.0,
        "lng": -95.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6397,
      "name": "Malbork",
      "names": {
        "zh": "马尔堡"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.0285,
        "lng": 19.0444
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6271,
      "name": "Empalme",
      "names": {
        "zh": "恩帕尔梅"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.9617,
        "lng": -110.8125
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14519,
      "name": "Xiwanzi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.9717,
        "lng": 115.2737
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6489,
      "name": "Frankfort",
      "names": {
        "fr": "Francfort",
        "zh": "法兰克福",
        "de": "Frankfurt",
        "th": "แฟรงก์เฟิร์ต",
        "pt": "Frankfurt"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 38.1924,
        "lng": -84.8643
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 14415,
      "name": "Mettmann",
      "names": {
        "zh": "梅特曼"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.25,
        "lng": 6.9667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14579,
      "name": "Balcarce",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.8464,
        "lng": -58.2556
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 14365,
      "name": "Wageningen",
      "names": {
        "zh": "瓦赫宁根"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9644,
        "lng": 5.6631
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 14593,
      "name": "Los Palacios",
      "names": {
        "zh": "洛杉矶帕拉西奥斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.5822,
        "lng": -83.2489
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 14603,
      "name": "Dzhankoi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.7086,
        "lng": 34.3933
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 14590,
      "name": "Cajibio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.6233,
        "lng": -76.5731
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14588,
      "name": "Yako",
      "names": {
        "zh": "矢向"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.9667,
        "lng": -2.2667
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 14571,
      "name": "Marinha Grande",
      "names": {
        "ja": "マリンハグランデ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.75,
        "lng": -8.9333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6376,
      "name": "Zacatelco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2167,
        "lng": -98.2333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14598,
      "name": "Kirkkonummi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.1167,
        "lng": 24.4167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 14587,
      "name": "Tuusula",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4028,
        "lng": 25.0292
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 6314,
      "name": "Armavir",
      "names": {
        "zh": "阿尔马维尔"
      },
      "countryCode": "AM",
      "latLng": {
        "lat": 40.15,
        "lng": 44.04
      },
      "country": "Armenia",
      "importance": 1
    },
    {
      "id": 14586,
      "name": "Dabola",
      "names": {
        "zh": "达博拉"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.75,
        "lng": -11.1167
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 14537,
      "name": "Ilhavo",
      "names": {
        "pt": "Ílhavo"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6,
        "lng": -8.6667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 14636,
      "name": "Karditsa",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.3647,
        "lng": 21.9219
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 14625,
      "name": "Abinsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.8667,
        "lng": 38.1667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 14637,
      "name": "Khalkhal",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.6189,
        "lng": 48.5258
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 6497,
      "name": "Barda",
      "names": {
        "zh": "巴尔达"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.3831,
        "lng": 47.1186
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 6498,
      "name": "Kline",
      "names": {
        "zh": "克莱恩"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.6167,
        "lng": 20.5667
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 14640,
      "name": "Caibarien",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.5158,
        "lng": -79.4722
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 14612,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9667,
        "lng": -89.2833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 6315,
      "name": "Minas",
      "names": {
        "zh": "矿业",
        "ja": "ミナス",
        "th": "มินัส"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.3667,
        "lng": -55.2333
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 14627,
      "name": "Lanyi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.7048,
        "lng": 111.5601
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6414,
      "name": "Chartres",
      "names": {
        "zh": "沙特尔",
        "ja": "シャルトル",
        "th": "ชาตร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.456,
        "lng": 1.484
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 14604,
      "name": "Sibate",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4908,
        "lng": -74.2594
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14641,
      "name": "Sassandra",
      "names": {
        "zh": "萨桑德拉"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 4.9504,
        "lng": -6.0833
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 6339,
      "name": "Fribourg",
      "names": {
        "zh": "弗里堡",
        "de": "Freiburg",
        "ja": "friborg",
        "th": "ฟรีบูร์ก"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.8,
        "lng": 7.15
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 6496,
      "name": "Ha Giang",
      "names": {
        "zh": "哈广亮",
        "ja": "ハハガン",
        "th": "ฮ่า ๆ ๆ"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 22.8233,
        "lng": 104.9836
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 14656,
      "name": "Eregli",
      "names": {
        "zh": "埃雷利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5142,
        "lng": 34.048
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 14409,
      "name": "Steyr",
      "names": {
        "zh": "斯太尔"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.05,
        "lng": 14.4167
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 14679,
      "name": "Krasnyy Sulin",
      "names": {
        "de": "Krasny-Sulin"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.8833,
        "lng": 40.0667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 14554,
      "name": "Pirna",
      "names": {
        "zh": "皮尔纳"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9622,
        "lng": 13.9403
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6515,
      "name": "Am-Timan",
      "names": {
        "fr": "Suis-timan",
        "zh": "上午timan",
        "ja": "午前-timan",
        "th": "กำลัง-timan"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 11.0333,
        "lng": 20.2833
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 14643,
      "name": "Siyang",
      "names": {
        "zh": "泗阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.2116,
        "lng": 108.7463
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6356,
      "name": "Xico",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.417,
        "lng": -97.0
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14607,
      "name": "Nove Zamky",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 47.9831,
        "lng": 18.1728
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 6511,
      "name": "Choybalsan",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 48.0706,
        "lng": 114.5228
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 6410,
      "name": "Cananea",
      "names": {
        "zh": "卡纳内阿"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.9819,
        "lng": -110.3006
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6519,
      "name": "Trujillo",
      "names": {
        "zh": "特鲁希略",
        "ja": "トルヒーヨ",
        "th": "ตรูฮีโย"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.3658,
        "lng": -70.4369
      },
      "country": "Venezuela",
      "importance": 1
    },
    {
      "id": 14630,
      "name": "Veghel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6169,
        "lng": 5.5481
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6523,
      "name": "Kikinda",
      "names": {
        "zh": "基金达"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.8244,
        "lng": 20.4592
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 6033,
      "name": "Tlapa de Comonfort",
      "names": {
        "de": "Tlaapa de comonfort",
        "ja": "Tlapa de Comport"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5461,
        "lng": -98.5764
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14639,
      "name": "Bautzen",
      "names": {
        "zh": "包岑"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1814,
        "lng": 14.4239
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6520,
      "name": "Kranj",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2389,
        "lng": 14.3556
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 6527,
      "name": "Ali Sabieh",
      "names": {
        "zh": "阿里·萨比海",
        "ja": "アリ・サビエ",
        "th": "อาลี sabieh"
      },
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.1558,
        "lng": 42.7125
      },
      "country": "Djibouti",
      "importance": 1
    },
    {
      "id": 14718,
      "name": "Hajin",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.6894,
        "lng": 40.8308
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 14705,
      "name": "Sampues",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1847,
        "lng": -75.3786
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14720,
      "name": "Andkhoy",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.95,
        "lng": 65.1167
      },
      "country": "Afghanistan",
      "importance": 2
    },
    {
      "id": 14739,
      "name": "Santiago",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンティアゴ",
        "th": "ซันติอาโก"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.4333,
        "lng": -100.1333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6468,
      "name": "Celje",
      "names": {
        "zh": "采列"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2291,
        "lng": 15.2641
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 14460,
      "name": "Tlalixcoyan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8031,
        "lng": -96.0611
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14747,
      "name": "Dibulla",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 11.2725,
        "lng": -73.3089
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14714,
      "name": "Guira de Melena",
      "names": {
        "it": "Guiva de Melena"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8019,
        "lng": -82.5047
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 14713,
      "name": "Yajalon",
      "names": {
        "es": "Yajalón"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.1667,
        "lng": -92.3333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6411,
      "name": "Al Bayda\u0027",
      "names": {
        "es": "Al Bayda \u0027",
        "fr": "Al Bayda \u0027",
        "de": "Al Bayda \u0027",
        "it": "Al bayda \u0027",
        "id": "Al Bayda \u0027",
        "ja": "アルベイダ \u0027",
        "th": "Al Bayda \u0027",
        "pt": "Al bayda \u0027"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 13.979,
        "lng": 45.574
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 14753,
      "name": "Al Qaryatayn",
      "names": {
        "fr": "Al QaryAyn",
        "th": "อัล Qaryatayn"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.2294,
        "lng": 37.2406
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 14633,
      "name": "Falavarjan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.5553,
        "lng": 51.5097
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 14703,
      "name": "Goes",
      "names": {
        "es": "Va",
        "fr": "Va",
        "zh": "去",
        "de": "Geht",
        "it": "Va",
        "id": "Pergi",
        "ja": "行く",
        "th": "ไป",
        "pt": "Vai"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5,
        "lng": 3.8833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 797,
      "name": "Apia",
      "names": {
        "zh": "阿皮亚",
        "ja": "アピア",
        "th": "เอเปีย"
      },
      "countryCode": "WS",
      "latLng": {
        "lat": -13.8333,
        "lng": -171.8333
      },
      "country": "Samoa",
      "importance": 0
    },
    {
      "id": 14578,
      "name": "Furstenfeldbruck",
      "names": {
        "fr": "Fürstenfeldbruck",
        "zh": "福斯滕费尔德布鲁克",
        "de": "Fürstenfeldbruck",
        "it": "Fürstenfeldbruck"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.1778,
        "lng": 11.2556
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14659,
      "name": "Venlo",
      "names": {
        "zh": "芬洛"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.37,
        "lng": 6.1681
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 14764,
      "name": "Aboisso",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 5.4667,
        "lng": -3.2
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 14652,
      "name": "Yanqi",
      "names": {
        "zh": "焉耆"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.0608,
        "lng": 86.5686
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 14781,
      "name": "Kalinkavichy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.1333,
        "lng": 29.3333
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 6538,
      "name": "Kajaani",
      "names": {
        "zh": "卡亚尼"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 64.225,
        "lng": 27.7333
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 6539,
      "name": "Salto del Guaira",
      "names": {
        "zh": "萨尔托德尔·雷拉"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -24.02,
        "lng": -54.34
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 14536,
      "name": "Landgraaf",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.9083,
        "lng": 6.0297
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 14566,
      "name": "Dubno",
      "names": {
        "zh": "杜布诺",
        "th": "ดับโน"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 50.3931,
        "lng": 25.735
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 6390,
      "name": "Kisii",
      "names": {
        "zh": "基西"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.6698,
        "lng": 34.7675
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 14770,
      "name": "Raalte",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4,
        "lng": 6.2833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6438,
      "name": "Phonsavan",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 19.45,
        "lng": 103.2
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 14806,
      "name": "El Bordo",
      "names": {
        "ja": "エルボルド",
        "pt": "El bicodo"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.1142,
        "lng": -76.9831
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14621,
      "name": "Zary",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.6333,
        "lng": 15.1333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 14749,
      "name": "Ma`arratmisrin",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.0,
        "lng": 36.6667
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 14631,
      "name": "Jaroslaw",
      "names": {
        "zh": "雅罗斯拉夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0162,
        "lng": 22.6778
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6462,
      "name": "Sopot",
      "names": {
        "zh": "索波特",
        "ja": "ソポト",
        "th": "โซพอต"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.4419,
        "lng": 18.5478
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 14827,
      "name": "Santa Rosa de Osos",
      "names": {
        "zh": "圣罗莎de Osos",
        "id": "Santa Rosa de Oso",
        "ja": "サンタローザデオス",
        "th": "ซานตาโรซ่าเดอโอส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.6472,
        "lng": -75.4606
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14821,
      "name": "Nandaime",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 11.75,
        "lng": -86.05
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 14803,
      "name": "Progreso",
      "names": {
        "zh": "普罗格雷索"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.28,
        "lng": -89.67
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6550,
      "name": "Tozeur",
      "names": {
        "zh": "托泽尔"
      },
      "countryCode": "TN",
      "latLng": {
        "lat": 33.9197,
        "lng": 8.1336
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 14746,
      "name": "Spisska Nova Ves",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.95,
        "lng": 20.5667
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 14769,
      "name": "Haugesund",
      "names": {
        "zh": "海于格松",
        "th": "เฮาเกสซุนด์"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.4102,
        "lng": 5.2755
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 6554,
      "name": "`Ataq",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 14.55,
        "lng": 46.8
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 14856,
      "name": "Tibu",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.64,
        "lng": -72.7381
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6420,
      "name": "Mariano Escobedo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9167,
        "lng": -97.1333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14814,
      "name": "Fermo",
      "names": {
        "zh": "费尔莫",
        "ja": "フェルモ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 43.1604,
        "lng": 13.7181
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 14829,
      "name": "Molndal",
      "names": {
        "es": "Mölndal",
        "zh": "默恩达尔"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.6542,
        "lng": 12.0139
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 14834,
      "name": "Warendorf",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.9539,
        "lng": 7.9933
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14840,
      "name": "Melgar",
      "names": {
        "zh": "梅尔加"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.2039,
        "lng": -74.6428
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6257,
      "name": "Salvatierra",
      "names": {
        "zh": "萨尔瓦铁拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2156,
        "lng": -100.8961
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14855,
      "name": "Kizilyurt",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.2,
        "lng": 46.8667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 14878,
      "name": "Ciudad Manuel Doblado",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7303,
        "lng": -101.9533
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14882,
      "name": "Silves",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1833,
        "lng": -8.4333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 14849,
      "name": "Masyaf",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.0653,
        "lng": 36.3421
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 796,
      "name": "Port of Spain",
      "names": {
        "es": "Puerto España",
        "fr": "Port d\u0027Espagne",
        "zh": "西班牙港",
        "de": "Port von Spanien",
        "it": "Porto di Spagna",
        "id": "Pelabuhan",
        "ja": "スペインの港",
        "th": "พอร์ตของสเปน",
        "pt": "Porto de Espanha"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.6667,
        "lng": -61.5167
      },
      "country": "Trinidad And Tobago",
      "importance": 0
    },
    {
      "id": 6565,
      "name": "Prijepolje",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.5439,
        "lng": 19.6514
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 6566,
      "name": "Al Qunaytirah",
      "names": {
        "ja": "アルキーティラ",
        "th": "อัล qunaytirah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.1256,
        "lng": 35.8239
      },
      "country": "Syria",
      "importance": 1
    },
    {
      "id": 14876,
      "name": "Suhl",
      "names": {
        "zh": "苏尔"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6106,
        "lng": 10.6931
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6409,
      "name": "Shengli",
      "names": {
        "zh": "胜利"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.9842,
        "lng": 106.1967
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6431,
      "name": "Swords",
      "names": {
        "es": "Espadas",
        "fr": "Épées",
        "zh": "剑",
        "de": "Schwerter",
        "it": "Spade",
        "id": "Pedang",
        "ja": "剣",
        "th": "ดาบ",
        "pt": "Espadas"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.4597,
        "lng": -6.2181
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 6568,
      "name": "Chaiyaphum",
      "names": {
        "th": "ชัยภูมิ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.8056,
        "lng": 102.0311
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 14939,
      "name": "Chimboy Shahri",
      "names": {
        "ja": "キムボーイシャーリ"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 42.9311,
        "lng": 59.7708
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 14936,
      "name": "Dagua",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.6575,
        "lng": -76.6917
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14922,
      "name": "San Miguel Chicaj",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1,
        "lng": -90.4
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 14923,
      "name": "Caimito",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9575,
        "lng": -82.5964
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 14841,
      "name": "Morondava",
      "names": {
        "zh": "莫鸿达瓦"
      },
      "countryCode": "MG",
      "latLng": {
        "lat": -20.2833,
        "lng": 44.2833
      },
      "country": "Madagascar",
      "importance": 2
    },
    {
      "id": 14952,
      "name": "Qaladizay",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.1811,
        "lng": 45.1286
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 14964,
      "name": "La Macarena",
      "names": {
        "zh": "洛马纳",
        "it": "Lacarena"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.1828,
        "lng": -73.7847
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14945,
      "name": "Sayula",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8836,
        "lng": -103.5972
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6353,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.3333,
        "lng": -56.7167
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 14951,
      "name": "Torres Novas",
      "names": {
        "zh": "托雷斯诺瓦斯",
        "id": "Novas torres"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4833,
        "lng": -8.5333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 14975,
      "name": "Esquel",
      "names": {
        "zh": "埃斯克尔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -42.9,
        "lng": -71.3167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 14982,
      "name": "Belalcazar",
      "names": {
        "zh": "贝拉尔卡萨"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.6469,
        "lng": -75.9717
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14976,
      "name": "Cantanhede",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3462,
        "lng": -8.5941
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6545,
      "name": "Schaffhausen",
      "names": {
        "zh": "沙夫豪森",
        "ja": "シャフハウゼン",
        "th": "ชาฟฟ์เฮาเซิน"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.6965,
        "lng": 8.6339
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 14961,
      "name": "Jaral del Progreso",
      "names": {
        "pt": "Jaral del progresso"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3667,
        "lng": -101.0667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 14994,
      "name": "Nuoro",
      "names": {
        "zh": "努奥罗"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 40.3201,
        "lng": 9.3281
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 14813,
      "name": "Chrzanow",
      "names": {
        "zh": "赫扎努夫"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.1333,
        "lng": 19.4
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6588,
      "name": "Ebebiyin",
      "names": {},
      "countryCode": "GQ",
      "latLng": {
        "lat": 2.15,
        "lng": 11.3167
      },
      "country": "Equatorial Guinea",
      "importance": 1
    },
    {
      "id": 14962,
      "name": "`Afrin",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.5119,
        "lng": 36.8694
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 14972,
      "name": "Porsgrunn",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.1419,
        "lng": 9.6568
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 14990,
      "name": "Haomen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3757,
        "lng": 101.622
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 14983,
      "name": "Neiba",
      "names": {},
      "countryCode": "DO",
      "latLng": {
        "lat": 18.49,
        "lng": -71.42
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 6344,
      "name": "Berat",
      "names": {
        "zh": "贝拉"
      },
      "countryCode": "AL",
      "latLng": {
        "lat": 40.7049,
        "lng": 19.9497
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 15013,
      "name": "Nueve de Julio",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -35.45,
        "lng": -60.8833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 6488,
      "name": "Zinacantan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7601,
        "lng": -92.7236
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6557,
      "name": "Bossangoa",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 6.4833,
        "lng": 17.45
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 6573,
      "name": "Falun",
      "names": {
        "es": "Los practicantes de Falun",
        "zh": "法轮",
        "ja": "ファールン",
        "th": "ฝ่าหลุน"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 60.613,
        "lng": 15.647
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 14893,
      "name": "Erding",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.3001,
        "lng": 11.9082
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6237,
      "name": "Lecherias",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.1889,
        "lng": -64.6951
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 15020,
      "name": "Sonson",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.7094,
        "lng": -75.3106
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 14993,
      "name": "Tocancipa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9661,
        "lng": -73.9111
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6493,
      "name": "Tuyen Quang",
      "names": {
        "th": "ตุ๋นคาง"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.8281,
        "lng": 105.2156
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 794,
      "name": "Monaco",
      "names": {
        "es": "Mónaco",
        "zh": "摩纳哥",
        "id": "Monako",
        "ja": "モナコ",
        "th": "โมนาโก"
      },
      "countryCode": "MC",
      "latLng": {
        "lat": 43.7396,
        "lng": 7.4069
      },
      "country": "Monaco",
      "importance": 0
    },
    {
      "id": 15045,
      "name": "San Pablo",
      "names": {
        "zh": "圣巴勃罗",
        "ja": "サンパブロ",
        "th": "ซานปาโบล"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.4764,
        "lng": -73.9231
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6579,
      "name": "Ratchaburi",
      "names": {
        "th": "ราชบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5367,
        "lng": 99.8169
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 6433,
      "name": "Villefranche-sur-Saone",
      "names": {
        "fr": "Villefranche-sur-Saône",
        "zh": "自由城索恩河畔",
        "ja": "villefrancheシュルsaone",
        "th": "villefranche-ซูร์-saone"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.9833,
        "lng": 4.7167
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 14860,
      "name": "Coatepec Harinas",
      "names": {
        "ja": "コアテピーハリナス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9,
        "lng": -99.7167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6492,
      "name": "Esch-sur-Alzette",
      "names": {
        "zh": "埃施畔埃施",
        "ja": "eschシュルalzette",
        "th": "esch-ซูร์-alzette"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.4969,
        "lng": 5.9806
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 15019,
      "name": "Coesfeld",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.9458,
        "lng": 7.1675
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15065,
      "name": "Smarhon\u0027",
      "names": {
        "es": "Smarhon \u0027",
        "fr": "Smarhon \u0027",
        "de": "Smarhon \u0027",
        "it": "Smarhon \u0027",
        "id": "Smarhon \u0027",
        "ja": "smarhon \u0027",
        "th": "smarhon \u0027",
        "pt": "Smarhon \u0027"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 54.4836,
        "lng": 26.4
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 6594,
      "name": "Goycay",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.6553,
        "lng": 47.7389
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 14989,
      "name": "Muhlhausen",
      "names": {
        "es": "Mühlhausen",
        "de": "Mühlhausen",
        "id": "Mühlhausen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2167,
        "lng": 10.45
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15053,
      "name": "San Bernardo del Viento",
      "names": {
        "ja": "サンベルナルドデルビエンヨ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.355,
        "lng": -75.9544
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15072,
      "name": "Jose Maria Morelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.75,
        "lng": -88.7
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15074,
      "name": "Zacatepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6833,
        "lng": -99.1833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6389,
      "name": "Ameca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5486,
        "lng": -104.0431
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6610,
      "name": "Kamenice",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.5839,
        "lng": 21.575
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 15100,
      "name": "Ramsar",
      "names": {
        "fr": "La convention de Ramsar",
        "zh": "拉姆萨尔"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.9169,
        "lng": 50.6736
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 6616,
      "name": "Bartin",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.6344,
        "lng": 32.3375
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 6609,
      "name": "Krong Kep",
      "names": {
        "id": "Krong kepe",
        "ja": "クローゴケプ"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 10.4833,
        "lng": 104.3167
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 6531,
      "name": "Paphos",
      "names": {
        "zh": "帕福斯",
        "ja": "パフォス",
        "th": "ปาฟอส"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 34.7761,
        "lng": 32.4265
      },
      "country": "Cyprus",
      "importance": 1
    },
    {
      "id": 15130,
      "name": "Kakhovka",
      "names": {
        "zh": "卡霍夫卡"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 46.8,
        "lng": 33.4667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 14768,
      "name": "Daiyue",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.5284,
        "lng": 112.8056
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 15089,
      "name": "San Antonio",
      "names": {
        "zh": "圣安东尼奥",
        "ja": "サンアントニオ",
        "th": "ซานอันโตนิโอ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9,
        "lng": 121.3
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 6522,
      "name": "Brzeg",
      "names": {
        "zh": "布热格"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.8667,
        "lng": 17.4833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 15132,
      "name": "Chernyakhovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.6333,
        "lng": 21.8167
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 14988,
      "name": "Santa Cruz Verapaz",
      "names": {
        "ja": "サンタクルスベラパズ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3736,
        "lng": -90.4306
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 6622,
      "name": "Beja",
      "names": {
        "zh": "贝雅"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.0156,
        "lng": -7.8653
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 6619,
      "name": "Gostivar",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.8,
        "lng": 20.9167
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 15147,
      "name": "Karak",
      "names": {
        "zh": "加叻"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 33.1167,
        "lng": 71.0833
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 6509,
      "name": "Tomatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9333,
        "lng": -105.2333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15022,
      "name": "Castricum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5517,
        "lng": 4.6583
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 15143,
      "name": "Mengdong",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 23.1499,
        "lng": 99.2462
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 15155,
      "name": "Pak Chong",
      "names": {
        "es": "Chong de pak",
        "ja": "パックチョン",
        "th": "ปากช่อง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6796,
        "lng": 101.3976
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 15083,
      "name": "Tuttlingen",
      "names": {
        "zh": "图特林根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.985,
        "lng": 8.8233
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15139,
      "name": "Kumo",
      "names": {
        "zh": "云"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 10.0431,
        "lng": 11.2183
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 15127,
      "name": "Belluno",
      "names": {
        "zh": "贝卢诺",
        "ja": "ベッルーノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 46.1408,
        "lng": 12.2156
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 6625,
      "name": "Vrsac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.1206,
        "lng": 21.2986
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 15165,
      "name": "Teruel",
      "names": {
        "zh": "特鲁埃尔"
      },
      "countryCode": "ES",
      "latLng": {
        "lat": 40.3456,
        "lng": -1.1065
      },
      "country": "Spain",
      "importance": 2
    },
    {
      "id": 15098,
      "name": "Turgutlu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5,
        "lng": 27.7
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 15168,
      "name": "Santiago de Tolu",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.525,
        "lng": -75.5817
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15153,
      "name": "Guacari",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.7647,
        "lng": -76.3322
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15197,
      "name": "Kuala Kapuas",
      "names": {
        "zh": "吉隆卡瓦斯",
        "ja": "クアラカプアス",
        "th": "กัวลากะพภา"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.0996,
        "lng": 114.35
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 15119,
      "name": "Chortoq",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.0689,
        "lng": 71.8153
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 15109,
      "name": "Rongwo",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5077,
        "lng": 102.0121
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6495,
      "name": "Palaiseau",
      "names": {
        "zh": "帕莱索"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7145,
        "lng": 2.2457
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 15206,
      "name": "Cha-am",
      "names": {
        "zh": "茶上午",
        "ja": "チャアム",
        "th": "ชะอำ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.7992,
        "lng": 99.9683
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 15203,
      "name": "San Sebastian de Yali",
      "names": {
        "es": "San Sebastián de Yali",
        "zh": "圣塞巴斯蒂安德亚利",
        "th": "ซานเซบาสเตียนเดอยูลี"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.3,
        "lng": -86.1833
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 15233,
      "name": "Savonlinna",
      "names": {
        "zh": "萨翁林纳"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.8667,
        "lng": 28.8831
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 6466,
      "name": "Miahuatlan de Porfirio Diaz",
      "names": {
        "es": "Miahuatlan de Porfirio Díaz",
        "id": "MAHAATLAN DE PORFIRIO DIAZ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3283,
        "lng": -96.5961
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6635,
      "name": "Usak",
      "names": {
        "zh": "乌沙克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6833,
        "lng": 29.4
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 15216,
      "name": "Gigante",
      "names": {
        "zh": "希甘特",
        "ja": "ヒガンテ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.3867,
        "lng": -75.5461
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15212,
      "name": "Coevorden",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6667,
        "lng": 6.75
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 15248,
      "name": "Cartagena del Chaira",
      "names": {
        "es": "Cartagena del Presa",
        "fr": "Carthagène du président au président",
        "zh": "Cartagena del主席",
        "de": "Cartagena del Cotting",
        "it": "Cartagena del Senda",
        "id": "Cartagena del camar",
        "ja": "カルタヘナデルレジャー",
        "pt": "Cartagena del Preso"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.3361,
        "lng": -74.8467
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15196,
      "name": "Bougouni",
      "names": {
        "zh": "布古尼"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 11.4177,
        "lng": -7.4832
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 15030,
      "name": "Jaslo",
      "names": {
        "zh": "亚斯沃"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.7333,
        "lng": 21.4667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 15144,
      "name": "Tallkalakh",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.6683,
        "lng": 36.2597
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 15140,
      "name": "Dayr Hafir",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.1592,
        "lng": 37.704
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 15225,
      "name": "Zorgo",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.25,
        "lng": -0.6167
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 15268,
      "name": "Millerovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 48.9167,
        "lng": 40.4
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 14846,
      "name": "Lebork",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.55,
        "lng": 17.75
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 15224,
      "name": "Meppen",
      "names": {
        "zh": "梅彭"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.6906,
        "lng": 7.291
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15270,
      "name": "La Palma",
      "names": {
        "zh": "拉帕尔马",
        "ja": "ラパルマ"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7472,
        "lng": -83.5525
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 6650,
      "name": "Braganca",
      "names": {
        "es": "Bragança",
        "fr": "Bragança",
        "zh": "布拉干萨",
        "de": "Bragança",
        "pt": "Bragança"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.8,
        "lng": -6.75
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 15246,
      "name": "Cegled",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1772,
        "lng": 19.7981
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 6639,
      "name": "Timbuktu",
      "names": {
        "es": "Tombuctú",
        "fr": "Tombouctou",
        "zh": "廷巴克图",
        "ja": "トンブクトゥ"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 16.7733,
        "lng": -2.9994
      },
      "country": "Mali",
      "importance": 1
    },
    {
      "id": 6333,
      "name": "Tonala",
      "names": {
        "zh": "托纳拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.0894,
        "lng": -93.7514
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15035,
      "name": "Kerava",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4028,
        "lng": 25.1
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 15228,
      "name": "Quimbaya",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6239,
        "lng": -75.7631
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15253,
      "name": "San Miguel Ixtahuacan",
      "names": {
        "zh": "圣米格尔·伊斯塔豪萨州"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.25,
        "lng": -91.75
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 14949,
      "name": "Gorinchem",
      "names": {
        "zh": "霍林赫姆"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8306,
        "lng": 4.9742
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 15108,
      "name": "Limburg",
      "names": {
        "es": "Limburgo",
        "fr": "Limbourg",
        "zh": "林堡",
        "ja": "リンブルフ",
        "th": "ลิมเบอร์ก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3833,
        "lng": 8.0667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15301,
      "name": "Voznesensk",
      "names": {
        "zh": "沃兹涅先斯克"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.5725,
        "lng": 31.3119
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 6552,
      "name": "Thonon-les-Bains",
      "names": {
        "zh": "托农-莱斯班",
        "ja": "トノン・レ・バン",
        "th": "thonon-เลสแบงส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3627,
        "lng": 6.475
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6644,
      "name": "Roi Et",
      "names": {
        "zh": "ROI等",
        "ja": "Roiet",
        "th": "ร้อยเอ็ด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.0533,
        "lng": 103.6513
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 6583,
      "name": "Karlskrona",
      "names": {
        "zh": "卡尔斯克鲁纳"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.1611,
        "lng": 15.5881
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 15302,
      "name": "Fonseca",
      "names": {
        "zh": "丰塞卡"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.8858,
        "lng": -72.8481
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6664,
      "name": "Bria",
      "names": {
        "zh": "布里亚"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 6.5369,
        "lng": 21.9919
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 6660,
      "name": "Luebo",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -5.3495,
        "lng": 21.41
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 15123,
      "name": "Praya",
      "names": {
        "th": "พระยา"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -8.7223,
        "lng": 116.2923
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 6643,
      "name": "Talas",
      "names": {
        "zh": "塔拉斯"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.5184,
        "lng": 72.2429
      },
      "country": "Kyrgyzstan",
      "importance": 1
    },
    {
      "id": 6505,
      "name": "Bucha",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.5464,
        "lng": 30.235
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 6541,
      "name": "Soteapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.2333,
        "lng": -94.8667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15150,
      "name": "Ingelheim",
      "names": {
        "zh": "殷格翰"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.9747,
        "lng": 8.0564
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15332,
      "name": "San Vicente de Chucuri",
      "names": {
        "ja": "サンビセンテデチュクリー"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.8819,
        "lng": -73.4119
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15345,
      "name": "Puerto Lopez",
      "names": {
        "es": "Puerto lópez",
        "ja": "プエルトロペス",
        "th": "เปอร์โตโลเปซ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.0897,
        "lng": -72.9619
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15166,
      "name": "Al Hisn",
      "names": {
        "de": "Al-Hisn",
        "th": "อัลเขา"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 32.487,
        "lng": 35.88
      },
      "country": "Jordan",
      "importance": 2
    },
    {
      "id": 15319,
      "name": "Umarkot",
      "names": {
        "zh": "欧迈尔果德"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 25.3614,
        "lng": 69.7361
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 15350,
      "name": "Casilda",
      "names": {
        "zh": "卡西尔达"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.0442,
        "lng": -61.1681
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 6653,
      "name": "Phatthalung",
      "names": {
        "de": "Phattalung",
        "th": "พัทลุง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.6178,
        "lng": 100.0778
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 6601,
      "name": "Chur",
      "names": {
        "zh": "库尔",
        "th": "คูร์"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.8521,
        "lng": 9.5297
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 15342,
      "name": "San Luis de Since",
      "names": {
        "es": "San Luis de desde entonces",
        "fr": "San Luis de depuis",
        "zh": "San Luis de",
        "de": "San Luis de seitdem",
        "it": "San Luis de",
        "id": "San Luis De sejak itu",
        "ja": "それ以来サンルイスデ",
        "th": "ซานหลุยส์เดอตั้งแต่",
        "pt": "San Luis de desde então"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2447,
        "lng": -75.1458
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6679,
      "name": "San Pedro de Ycuamandiyu",
      "names": {
        "ja": "サンペドロデ・ユヤマンドイ"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -24.1,
        "lng": -57.0833
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 15337,
      "name": "Huehuetan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.0319,
        "lng": -92.3844
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6673,
      "name": "Maidan Shahr",
      "names": {
        "ja": "マーダンシェール"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.3972,
        "lng": 68.8697
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 6645,
      "name": "Taibao",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 23.45,
        "lng": 120.3333
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 6658,
      "name": "Santa Cruz del Quiche",
      "names": {
        "zh": "圣克鲁斯德尔乳蛋饼",
        "ja": "サンタクルスデルキッシュ",
        "th": "ซานตาครูซเดลคูชี"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.05,
        "lng": -91.25
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 6681,
      "name": "Dikhil",
      "names": {
        "zh": "迪基勒"
      },
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.1086,
        "lng": 42.3667
      },
      "country": "Djibouti",
      "importance": 1
    },
    {
      "id": 15297,
      "name": "Tall Salhab",
      "names": {
        "es": "Salón alto",
        "fr": "Grand salhab",
        "zh": "高大的沙拉",
        "de": "Hoher Salhen",
        "it": "Salhab alto",
        "id": "Salhab tinggi",
        "ja": "背の高いサラブ",
        "th": "ซัลฮาบสูง",
        "pt": "Salcalha alta"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.2609,
        "lng": 36.3822
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 15336,
      "name": "Champerico",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.293,
        "lng": -91.914
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 15349,
      "name": "Bochil",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.9953,
        "lng": -92.8903
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 799,
      "name": "Oranjestad",
      "names": {
        "zh": "奥拉涅斯塔德",
        "it": "Libero stato dell\u0027orange",
        "ja": "オラニエスタッド"
      },
      "countryCode": "AW",
      "latLng": {
        "lat": 12.5186,
        "lng": -70.0358
      },
      "country": "Aruba",
      "importance": 0
    },
    {
      "id": 6678,
      "name": "Marijampole",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.5567,
        "lng": 23.3544
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 15310,
      "name": "Ieper",
      "names": {
        "es": "Ypres",
        "fr": "Ypres",
        "zh": "伊珀尔"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.85,
        "lng": 2.8833
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 15258,
      "name": "Leer",
      "names": {
        "zh": "莱尔",
        "ja": "うすら笑い",
        "th": "มองค้อน"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.2308,
        "lng": 7.4528
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 14987,
      "name": "Montlucon",
      "names": {
        "es": "Montluçon",
        "fr": "Montluçon",
        "zh": "蒙吕松",
        "it": "Montluçon"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3408,
        "lng": 2.6033
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 15358,
      "name": "Campoalegre",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.6867,
        "lng": -75.3256
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15267,
      "name": "Cloppenburg",
      "names": {
        "zh": "克洛彭堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.8478,
        "lng": 8.0439
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15230,
      "name": "Ikot Abasi",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 4.5704,
        "lng": 7.56
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 15314,
      "name": "Winsen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 53.3667,
        "lng": 10.2167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6617,
      "name": "Viborg",
      "names": {
        "zh": "维堡"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 56.4333,
        "lng": 9.4
      },
      "country": "Denmark",
      "importance": 1
    },
    {
      "id": 6691,
      "name": "Naryn",
      "names": {
        "zh": "纳里恩",
        "ja": "ナルイン"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 41.4333,
        "lng": 76.0
      },
      "country": "Kyrgyzstan",
      "importance": 1
    },
    {
      "id": 15292,
      "name": "Yarim",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 14.2978,
        "lng": 44.3778
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 15361,
      "name": "Haguenau",
      "names": {
        "zh": "阿格诺"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.82,
        "lng": 7.79
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 15360,
      "name": "Baja",
      "names": {
        "zh": "下加",
        "ja": "バハ",
        "th": "รองเท้าบาจา"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.1833,
        "lng": 18.9536
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 15257,
      "name": "Auxerre",
      "names": {
        "zh": "欧塞尔",
        "th": "โอแซร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.7986,
        "lng": 3.5672
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6602,
      "name": "Adjumani",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 3.3614,
        "lng": 31.8097
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 15355,
      "name": "Samandag",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.085,
        "lng": 35.9806
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 15420,
      "name": "Sarpol-e Zahab",
      "names": {
        "ja": "サルポル\u003d Zahab"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.4614,
        "lng": 45.8647
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 15423,
      "name": "Buenos Aires",
      "names": {
        "zh": "布宜诺斯艾利斯",
        "ja": "ブエノスアイレス",
        "th": "บัวโนสไอเรส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.0164,
        "lng": -76.6411
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15443,
      "name": "Chacabuco",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.65,
        "lng": -60.49
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 6682,
      "name": "Ocotal",
      "names": {
        "zh": "奥科塔尔"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.6333,
        "lng": -86.4833
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 15436,
      "name": "Bugojno",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.0572,
        "lng": 17.4508
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 15278,
      "name": "Saarlouis",
      "names": {
        "zh": "萨尔路易斯",
        "ja": "ザールルイ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3167,
        "lng": 6.75
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6614,
      "name": "Byumba",
      "names": {
        "zh": "温巴"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -1.5794,
        "lng": 30.0694
      },
      "country": "Rwanda",
      "importance": 1
    },
    {
      "id": 15451,
      "name": "Sabaneta",
      "names": {
        "zh": "萨瓦内塔"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.4833,
        "lng": -71.35
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 6648,
      "name": "Gisborne",
      "names": {
        "zh": "吉斯伯恩"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -38.6625,
        "lng": 178.0178
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 15463,
      "name": "Baft",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.2331,
        "lng": 56.6022
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 15178,
      "name": "Cieszyn",
      "names": {
        "zh": "切申"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.75,
        "lng": 18.6333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 15379,
      "name": "Balingen",
      "names": {
        "zh": "巴林根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.2731,
        "lng": 8.8506
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15449,
      "name": "Ocuilan de Arteaga",
      "names": {
        "id": "Ocuilan de artega"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0,
        "lng": -99.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6700,
      "name": "Silistra",
      "names": {
        "zh": "锡利斯特拉"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 44.1092,
        "lng": 27.2654
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 15469,
      "name": "Gerash",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 27.665,
        "lng": 54.1369
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 15468,
      "name": "Banes",
      "names": {
        "zh": "巴内斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.9697,
        "lng": -75.7117
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 15141,
      "name": "Sambir",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.5167,
        "lng": 23.2
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 15198,
      "name": "Uster",
      "names": {
        "zh": "乌斯特"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3492,
        "lng": 8.7192
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 15393,
      "name": "Mosonmagyarovar",
      "names": {
        "zh": "莫雄马扎尔古堡"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.8667,
        "lng": 17.2667
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 6697,
      "name": "Kalasin",
      "names": {
        "zh": "加拉信",
        "th": "กาฬสินธุ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.4333,
        "lng": 103.5
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 15482,
      "name": "Merauke",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -8.4932,
        "lng": 140.4018
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 15305,
      "name": "Gorizia",
      "names": {
        "zh": "戈里齐亚"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.9352,
        "lng": 13.6193
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 15496,
      "name": "Santa Ana Nextlalpan",
      "names": {
        "es": "Santa Ana Newlalpan",
        "pt": "Santa Ana Nextlpan"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7167,
        "lng": -99.0667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6481,
      "name": "Durazno",
      "names": {
        "zh": "杜拉斯诺",
        "th": "ดูราซโน"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -33.3833,
        "lng": -56.5167
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 15066,
      "name": "Zhmerynka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0425,
        "lng": 28.0992
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 15417,
      "name": "Phra Phutthabat",
      "names": {
        "it": "Phrra Phutthabat",
        "th": "พระพุทธบาท"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.7212,
        "lng": 100.8047
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 15339,
      "name": "Wavre",
      "names": {
        "zh": "瓦夫尔"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.7167,
        "lng": 4.6
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 6702,
      "name": "Swakopmund",
      "names": {
        "zh": "斯瓦科普蒙德",
        "ja": "スワコプムンド",
        "th": "สวากอปมุนด์"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -22.6667,
        "lng": 14.5333
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 15442,
      "name": "Esposende",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5333,
        "lng": -8.7833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6526,
      "name": "La Huacana",
      "names": {
        "ja": "ラ・ハカナ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9625,
        "lng": -101.8069
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15218,
      "name": "Krasnik",
      "names": {
        "zh": "克拉斯尼克"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.9167,
        "lng": 22.2167
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 15514,
      "name": "Gulkevichi",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.3603,
        "lng": 40.6945
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 15430,
      "name": "Zweibrucken",
      "names": {
        "fr": "Zweibrücken",
        "de": "Zweibrücken",
        "pt": "Zweibrücken"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.2494,
        "lng": 7.3608
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6532,
      "name": "Karonga",
      "names": {
        "zh": "卡龙加"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -9.9329,
        "lng": 33.9333
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 15525,
      "name": "Shahin Dezh",
      "names": {
        "zh": "沙林德扎"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.6792,
        "lng": 46.5667
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 15431,
      "name": "IJsselstein",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.02,
        "lng": 5.0422
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6713,
      "name": "Bor",
      "names": {
        "zh": "博尔",
        "th": "บ่อ"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.1303,
        "lng": 22.1036
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 15521,
      "name": "Caldono",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.7969,
        "lng": -76.4828
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15561,
      "name": "San Agustin",
      "names": {
        "es": "San Agustín",
        "zh": "圣阿古斯丁",
        "th": "ซานอาคัลติน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.8792,
        "lng": -76.2683
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15502,
      "name": "Qingyang",
      "names": {
        "zh": "庆阳"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.1985,
        "lng": 113.4313
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 15492,
      "name": "Steinfurt",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1504,
        "lng": 7.3366
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6636,
      "name": "Aosta",
      "names": {
        "fr": "Aoste",
        "zh": "奥斯塔",
        "ja": "アオスタ",
        "th": "ออสตา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.7372,
        "lng": 7.3206
      },
      "country": "Italy",
      "importance": 1
    },
    {
      "id": 15428,
      "name": "Merseburg",
      "names": {
        "zh": "梅泽堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3544,
        "lng": 11.9928
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15583,
      "name": "San Juan Nepomuceno",
      "names": {
        "zh": "圣胡安Nepomuceno",
        "ja": "サンファンネポムチェノ",
        "th": "ซานฮวนเนปึมิโซโน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.9522,
        "lng": -75.0811
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15586,
      "name": "Majagual",
      "names": {
        "zh": "马哈瓜尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.5408,
        "lng": -74.6297
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15528,
      "name": "Quinchia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.3394,
        "lng": -75.7294
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6615,
      "name": "Roanne",
      "names": {
        "zh": "罗阿讷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.0367,
        "lng": 4.0689
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6711,
      "name": "Fushe Kosove",
      "names": {
        "de": "Fushe kopf",
        "it": "Kosove Fushe",
        "id": "Fushe koshove",
        "ja": "ケージを埋めます"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.63,
        "lng": 21.12
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 6721,
      "name": "Shariff Aguak",
      "names": {
        "es": "Skaliff aguak",
        "de": "Harngänger aguak",
        "it": "Tariff Aguak",
        "ja": "aguak shariff"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.8647,
        "lng": 124.4417
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 15541,
      "name": "Peyziwat",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.4905,
        "lng": 76.7389
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6725,
      "name": "Kakata",
      "names": {
        "zh": "卡卡塔"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 6.53,
        "lng": -10.3517
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 6717,
      "name": "Uttaradit",
      "names": {
        "zh": "程逸",
        "th": "อุตรดิตถ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.6256,
        "lng": 100.0942
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 6604,
      "name": "San Juan Evangelista",
      "names": {
        "zh": "圣胡安Evangelista",
        "ja": "サンファンエヴァンエリスタ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.8833,
        "lng": -95.1333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6718,
      "name": "Nykoping",
      "names": {
        "es": "Nyköping",
        "zh": "尼雪平",
        "id": "Nyköping"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.7582,
        "lng": 17.0185
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 15579,
      "name": "Reo",
      "names": {
        "zh": "选举事务处"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.3167,
        "lng": -2.4667
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 15576,
      "name": "Lianga",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 8.633,
        "lng": 126.0932
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 15588,
      "name": "Sneek",
      "names": {
        "zh": "斯内克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.0325,
        "lng": 5.66
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6706,
      "name": "Azogues",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -2.7333,
        "lng": -78.8333
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 15634,
      "name": "Bell Ville",
      "names": {
        "es": "Ville",
        "zh": "贝尔维尔",
        "it": "Campana ville",
        "id": "Lonceng",
        "ja": "ベルビル",
        "th": "เบลล์วิลล์",
        "pt": "Villa"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.6333,
        "lng": -62.6833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 6577,
      "name": "Parras de la Fuente",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.4403,
        "lng": -102.1792
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15577,
      "name": "San Antonio de los Banos",
      "names": {
        "ja": "サンアントニオデロスバノス"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8889,
        "lng": -82.4989
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 15354,
      "name": "Macon",
      "names": {
        "zh": "梅肯",
        "ja": "メーコン",
        "th": "แมคอน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3063,
        "lng": 4.8313
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 15632,
      "name": "Tekes",
      "names": {
        "zh": "特克斯"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.2181,
        "lng": 81.8372
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6405,
      "name": "Zacatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9319,
        "lng": -97.96
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15657,
      "name": "Chilecito",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -29.1667,
        "lng": -67.5
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 15644,
      "name": "Leo",
      "names": {
        "zh": "狮子座",
        "ja": "レオ",
        "th": "ราศีสิงห์"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 11.1,
        "lng": -2.1
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 15664,
      "name": "Puerto Rico",
      "names": {
        "es": "Puertón rico",
        "fr": "Porto Rico",
        "zh": "波多黎各",
        "it": "Porto Rico",
        "ja": "プエルトリコ",
        "th": "เปอร์โตริโก",
        "pt": "Porto Rico"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.9075,
        "lng": -75.1583
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15671,
      "name": "San Antero",
      "names": {
        "zh": "圣黑子",
        "ja": "サンアノテオ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.3775,
        "lng": -75.7603
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15498,
      "name": "Vibo Valentia",
      "names": {
        "ja": "バイブバレンティア"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 38.6753,
        "lng": 16.0959
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 6467,
      "name": "Florida",
      "names": {
        "fr": "Floride",
        "zh": "佛罗里达",
        "ja": "フロリダ州",
        "th": "ฟลอริดา",
        "pt": "Flórida"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.1,
        "lng": -56.2167
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 15656,
      "name": "Todos Santos Cuchumatan",
      "names": {
        "es": "Todos los santos cuchumatan",
        "pt": "Todos Santos Cucumatan"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.5116,
        "lng": -91.6051
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 15631,
      "name": "Uelzen",
      "names": {
        "zh": "于尔岑"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.9647,
        "lng": 10.5658
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6741,
      "name": "Bilwi",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 14.05,
        "lng": -83.3833
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 6737,
      "name": "Koh Kong",
      "names": {
        "zh": "象孔",
        "th": "เกาะกง"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.6167,
        "lng": 102.9833
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 15654,
      "name": "Lochem",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.15,
        "lng": 6.4167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 15593,
      "name": "Deggendorf",
      "names": {
        "ja": "デッゲンドルフ"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8353,
        "lng": 12.9644
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15277,
      "name": "Qadsayya",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.5333,
        "lng": 36.2167
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 15551,
      "name": "Meppel",
      "names": {
        "zh": "梅珀尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.7033,
        "lng": 6.1917
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6694,
      "name": "Jacmel",
      "names": {
        "zh": "雅克梅勒"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.235,
        "lng": -72.537
      },
      "country": "Haiti",
      "importance": 1
    },
    {
      "id": 15544,
      "name": "Nowy Targ",
      "names": {
        "id": "TARG SEGERA",
        "ja": "今日のターク",
        "th": "ตอนนี้ targ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.4833,
        "lng": 20.0333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6739,
      "name": "Cabarroguis",
      "names": {
        "zh": "卡巴罗吉斯"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.5833,
        "lng": 121.5
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 800,
      "name": "Saint Helier",
      "names": {
        "es": "Santa Helier",
        "fr": "Saint hélicole",
        "zh": "圣战者",
        "de": "Heiliger Helier",
        "ja": "セントヘリエ",
        "th": "เซนต์เฮลเอะ",
        "pt": "São Helier"
      },
      "countryCode": "JE",
      "latLng": {
        "lat": 49.1858,
        "lng": -2.11
      },
      "country": "Jersey",
      "importance": 0
    },
    {
      "id": 6738,
      "name": "Chumphon",
      "names": {
        "zh": "春蓬",
        "th": "ชุมพร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 10.4939,
        "lng": 99.18
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 15605,
      "name": "Biberach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0981,
        "lng": 9.7886
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15680,
      "name": "Corinto",
      "names": {
        "zh": "科林托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.1739,
        "lng": -76.2594
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6655,
      "name": "Neuchatel",
      "names": {
        "fr": "Neuchâtel",
        "zh": "纳沙泰尔",
        "de": "Neuenburg",
        "id": "Neuchâtel"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.9903,
        "lng": 6.9306
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 15444,
      "name": "Humenne",
      "names": {
        "it": "Humenné"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.9306,
        "lng": 21.9122
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 15692,
      "name": "Ciudad Tecun Uman",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6833,
        "lng": -92.1333
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 15695,
      "name": "Castro",
      "names": {
        "zh": "卡斯特罗",
        "ja": "カストロ",
        "th": "คาสโตร"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -42.4824,
        "lng": -73.7643
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 6581,
      "name": "Paso de Ovejas",
      "names": {
        "ja": "Paso deovejas"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.285,
        "lng": -96.44
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15713,
      "name": "Kos",
      "names": {
        "zh": "科斯",
        "ja": "コス",
        "th": "คอส"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 36.8153,
        "lng": 27.1103
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 6126,
      "name": "Tulum",
      "names": {
        "zh": "图伦",
        "ja": "トゥルム"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2119,
        "lng": -87.4658
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6727,
      "name": "Ventspils",
      "names": {
        "zh": "文茨皮尔斯"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 57.3897,
        "lng": 21.5644
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 6747,
      "name": "Sisak",
      "names": {
        "zh": "锡萨克"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.4872,
        "lng": 16.3761
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 15390,
      "name": "Ilawa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.5964,
        "lng": 19.5656
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 15725,
      "name": "Nokia",
      "names": {
        "zh": "诺基亚",
        "ja": "ノキア",
        "th": "โนเกีย"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4767,
        "lng": 23.5053
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 15599,
      "name": "Baltiysk",
      "names": {
        "zh": "波罗的斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.65,
        "lng": 19.9167
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 15320,
      "name": "Nevers",
      "names": {
        "zh": "讷韦尔",
        "th": "เนเวิร์ส"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.9933,
        "lng": 3.1572
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6589,
      "name": "Calpulalpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5869,
        "lng": -98.5683
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6752,
      "name": "Juticalpa",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 14.6664,
        "lng": -86.2186
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 6724,
      "name": "Kampong Speu",
      "names": {
        "th": "กำปงปเว่"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.452,
        "lng": 104.519
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 15764,
      "name": "Pivijay",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4608,
        "lng": -74.6153
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15769,
      "name": "Bragado",
      "names": {
        "zh": "布拉加多"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -35.1167,
        "lng": -60.5
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 6750,
      "name": "Kyrenia",
      "names": {
        "zh": "凯里尼亚"
      },
      "countryCode": "CY",
      "latLng": {
        "lat": 35.3403,
        "lng": 33.3192
      },
      "country": "Cyprus",
      "importance": 1
    },
    {
      "id": 15757,
      "name": "Obukhiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.1,
        "lng": 30.6167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 6753,
      "name": "Lovech",
      "names": {
        "zh": "洛维奇",
        "th": "โลเว็ตช์"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.1348,
        "lng": 24.7115
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 15779,
      "name": "Khvaf",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.5764,
        "lng": 60.1408
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 15750,
      "name": "Police",
      "names": {
        "es": "La Policía",
        "zh": "警察",
        "de": "Polizei",
        "it": "Polizia",
        "id": "Polisi",
        "ja": "警察",
        "th": "ตำรวจ",
        "pt": "Polícia"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.55,
        "lng": 14.5708
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 15747,
      "name": "Epe",
      "names": {
        "zh": "珍珠棉"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3333,
        "lng": 5.9167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6634,
      "name": "Chuhuiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.8353,
        "lng": 36.6756
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 15405,
      "name": "Dzierzoniow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.7281,
        "lng": 16.6511
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6472,
      "name": "Panuco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.05,
        "lng": -98.1833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15738,
      "name": "Bandirma",
      "names": {
        "zh": "班德尔玛"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.3542,
        "lng": 27.9725
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 15508,
      "name": "Olawa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.9333,
        "lng": 17.3
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6754,
      "name": "Mukdahan",
      "names": {
        "zh": "穆达汉",
        "th": "มุกดาหาร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.5431,
        "lng": 104.7228
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 15763,
      "name": "Temascalapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8,
        "lng": -98.9
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6762,
      "name": "Timimoun",
      "names": {
        "zh": "提米蒙"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 29.25,
        "lng": 0.2333
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 15804,
      "name": "San Luis",
      "names": {
        "zh": "圣路易斯",
        "ja": "サンルイス",
        "th": "ซานหลุยส์"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.2828,
        "lng": -83.7681
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 6761,
      "name": "Razgrad",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.5409,
        "lng": 26.5288
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 15806,
      "name": "Puebloviejo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.9942,
        "lng": -74.2833
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15790,
      "name": "Sanarate",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.795,
        "lng": -90.1922
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 15652,
      "name": "Jishi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.8511,
        "lng": 102.4788
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6623,
      "name": "Agen",
      "names": {
        "zh": "阿根",
        "ja": "アジャン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.2049,
        "lng": 0.6212
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 15702,
      "name": "Panjakent",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 39.5031,
        "lng": 67.615
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 15794,
      "name": "Laojiezi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.86,
        "lng": 103.1306
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 15768,
      "name": "Djenne",
      "names": {
        "fr": "Djenné"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 13.9,
        "lng": -4.55
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 15722,
      "name": "Vac",
      "names": {
        "fr": "Acc",
        "zh": "疫苗",
        "id": "Liburan",
        "ja": "VACの",
        "th": "ชาตินิยม"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.7753,
        "lng": 19.1311
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 6264,
      "name": "Nogent-sur-Marne",
      "names": {
        "zh": "诺让马恩河畔",
        "ja": "nogentシュルマルヌ",
        "th": "nogent-ซูร์-Marne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8375,
        "lng": 2.4833
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6763,
      "name": "Amasya",
      "names": {
        "zh": "阿马西亚"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.65,
        "lng": 35.8333
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 15772,
      "name": "Fraijanes",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4622,
        "lng": -90.4386
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 15824,
      "name": "Mataquescuintla",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5336,
        "lng": -90.1838
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 15844,
      "name": "San Felipe",
      "names": {
        "zh": "圣费利佩",
        "ja": "サンフェリペ",
        "th": "ซานเฟลิเป้"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.4833,
        "lng": -101.2167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6767,
      "name": "Pilar",
      "names": {
        "zh": "皮拉尔",
        "ja": "ピラル",
        "th": "ปีลาร์"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -26.8569,
        "lng": -58.3039
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 6628,
      "name": "Maassluis",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9189,
        "lng": 4.2567
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 15861,
      "name": "Bocas de Satinga",
      "names": {
        "fr": "Bocas de Satinera",
        "it": "Bocas de Satingsa",
        "ja": "ボカス・デ・セティーズ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.3469,
        "lng": -78.3256
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15845,
      "name": "Puerto Escondido",
      "names": {
        "ja": "プエルトエスコンディド",
        "th": "เปอร์โตเอสโซโดโด"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.0192,
        "lng": -76.2614
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15857,
      "name": "Ylojarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.55,
        "lng": 23.5833
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 6384,
      "name": "Sayula de Aleman",
      "names": {
        "es": "Sayula de Alemán",
        "zh": "Sayula de Alman"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.8833,
        "lng": -94.95
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15863,
      "name": "Ansongo",
      "names": {},
      "countryCode": "ML",
      "latLng": {
        "lat": 15.665,
        "lng": 0.5028
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 15871,
      "name": "Silvia",
      "names": {
        "zh": "西尔维娅",
        "ja": "シルビア",
        "th": "ซิลเวีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.6108,
        "lng": -76.3789
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15825,
      "name": "Bernburg",
      "names": {
        "zh": "贝恩堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8,
        "lng": 11.7333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6773,
      "name": "Harper",
      "names": {
        "zh": "哈珀",
        "ja": "ハーパー",
        "th": "ฮาร์เปอร์"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 4.3754,
        "lng": -7.717
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 6561,
      "name": "Putla Villa de Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0321,
        "lng": -97.9293
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15884,
      "name": "El Dificil",
      "names": {
        "fr": "Elsif",
        "zh": "el diicil",
        "de": "Elficil",
        "it": "El dannil",
        "ja": "エルフィシル",
        "pt": "Eliminente"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.8469,
        "lng": -74.2367
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15808,
      "name": "Liptovsky Mikulas",
      "names": {
        "pt": "Mikulas liptovsky"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0811,
        "lng": 19.6181
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 15853,
      "name": "Kaarina",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4069,
        "lng": 22.3722
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 15812,
      "name": "Bardejov",
      "names": {
        "zh": "巴尔代约夫"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.295,
        "lng": 21.2758
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 15900,
      "name": "Inza",
      "names": {
        "zh": "因扎"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.5503,
        "lng": -76.0636
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15731,
      "name": "Kohtla-Jarve",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.4,
        "lng": 27.2833
      },
      "country": "Estonia",
      "importance": 2
    },
    {
      "id": 15859,
      "name": "Payshamba Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.005,
        "lng": 66.2264
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 6788,
      "name": "I-n-Salah",
      "names": {
        "zh": "我-n-·萨拉赫",
        "it": "Ho-n-Salah",
        "id": "Saya-n-shalat",
        "ja": "私は、n-サラーレフ",
        "th": "ฉัน n-เชลาห์"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 27.1936,
        "lng": 2.4606
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 15515,
      "name": "Cambrai",
      "names": {
        "zh": "康布雷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.1767,
        "lng": 3.2356
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6789,
      "name": "Mazeikiai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 56.3111,
        "lng": 22.3361
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 15915,
      "name": "Rethymno",
      "names": {
        "fr": "Réthymnon",
        "zh": "雷斯蒙"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 35.3689,
        "lng": 24.4739
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 15775,
      "name": "Fucheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.3678,
        "lng": 103.7074
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6792,
      "name": "Leticia",
      "names": {
        "zh": "莱蒂西亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -4.215,
        "lng": -69.9411
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 15858,
      "name": "Vechta",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.7306,
        "lng": 8.2886
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6513,
      "name": "Tenosique",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.4756,
        "lng": -91.4225
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6671,
      "name": "Bussum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2733,
        "lng": 5.1611
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 15891,
      "name": "Naumburg",
      "names": {
        "zh": "瑙姆堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1521,
        "lng": 11.8098
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15902,
      "name": "Deurne",
      "names": {
        "zh": "德尔讷"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4639,
        "lng": 5.7947
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 15732,
      "name": "La Virginia",
      "names": {
        "fr": "La Virginie",
        "ja": "ラ・バージニア",
        "th": "ลาเวอร์จิเนีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8967,
        "lng": -75.8839
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15956,
      "name": "San Pedro de Uraba",
      "names": {
        "ja": "サンペドロデウルバ",
        "th": "ซานเปโดรเดอปรา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.275,
        "lng": -76.3769
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 15972,
      "name": "Menglie",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 22.5833,
        "lng": 101.866
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6782,
      "name": "Salgotarjan",
      "names": {
        "zh": "绍尔戈陶尔扬"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 48.0853,
        "lng": 19.7867
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 6656,
      "name": "Papendrecht",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8333,
        "lng": 4.6833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 15998,
      "name": "Kangasala",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4639,
        "lng": 24.065
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 15866,
      "name": "Epinal",
      "names": {
        "zh": "埃皮纳勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1744,
        "lng": 6.4512
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 15672,
      "name": "Cantel",
      "names": {
        "es": "Partito",
        "id": "Potongan",
        "pt": "Cantil"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8112,
        "lng": -91.4555
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 15843,
      "name": "Safita",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 34.8208,
        "lng": 36.1173
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 15980,
      "name": "Santo Domingo Suchitepequez",
      "names": {
        "es": "Santo Domingo Suchitequez",
        "fr": "Santo Domingo SomptPequez",
        "zh": "Santo domingo umitepeozz",
        "de": "Santo domingo suchepequezz",
        "pt": "Santo Domingo Suchitequez"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4667,
        "lng": -91.4833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 15968,
      "name": "Kristianstad",
      "names": {
        "zh": "克里斯蒂安斯塔德",
        "th": "คริสเตียนสตัด"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 56.0337,
        "lng": 14.1333
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 16011,
      "name": "Primorsko-Akhtarsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.05,
        "lng": 38.1833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 16012,
      "name": "Xangda",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.2056,
        "lng": 96.4751
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6703,
      "name": "Homa Bay",
      "names": {
        "es": "Bahía de casa",
        "zh": "homa湾",
        "it": "Baia di Homa",
        "id": "Teluk Homa",
        "th": "อ่าวฮา",
        "pt": "Baía de homa"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.5167,
        "lng": 34.45
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 15838,
      "name": "Forchheim",
      "names": {
        "zh": "福希海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7197,
        "lng": 11.0581
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 15976,
      "name": "Buguey",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 18.2882,
        "lng": 121.8331
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 15909,
      "name": "Grodzisk Mazowiecki",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.1089,
        "lng": 20.625
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 16043,
      "name": "Zapala",
      "names": {
        "zh": "萨帕拉"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.9028,
        "lng": -70.065
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 6814,
      "name": "Boende",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -0.2817,
        "lng": 20.8806
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 6811,
      "name": "Rumbek",
      "names": {
        "zh": "伦拜克"
      },
      "countryCode": "SS",
      "latLng": {
        "lat": 6.8,
        "lng": 29.6833
      },
      "country": "South Sudan",
      "importance": 1
    },
    {
      "id": 15864,
      "name": "Altenburg",
      "names": {
        "zh": "阿尔滕堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.985,
        "lng": 12.4333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 802,
      "name": "Mamoudzou",
      "names": {},
      "countryCode": "YT",
      "latLng": {
        "lat": -12.7871,
        "lng": 45.275
      },
      "country": "Mayotte",
      "importance": 0
    },
    {
      "id": 16050,
      "name": "Remedios",
      "names": {
        "zh": "雷梅迪奥斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0275,
        "lng": -74.6939
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6690,
      "name": "Ciudad Sabinas Hidalgo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.5,
        "lng": -100.1833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6805,
      "name": "Chachapoyas",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -6.2167,
        "lng": -77.85
      },
      "country": "Peru",
      "importance": 1
    },
    {
      "id": 16053,
      "name": "Cabaiguan",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.0839,
        "lng": -79.4953
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 6824,
      "name": "Juneau",
      "names": {
        "zh": "朱诺",
        "ja": "ジュノー",
        "th": "จูโน"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 58.4546,
        "lng": -134.1739
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 15926,
      "name": "Levice",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.2136,
        "lng": 18.6069
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 15977,
      "name": "San Andres Itzapa",
      "names": {
        "es": "San Andrés Itzapa",
        "zh": "圣安德烈斯伊茨拉帕"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6167,
        "lng": -90.85
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 16093,
      "name": "Petatlan",
      "names": {
        "es": "Petatlán",
        "zh": "佩塔特兰"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5383,
        "lng": -101.2739
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16066,
      "name": "Nilka",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.7826,
        "lng": 82.5089
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6580,
      "name": "Paso del Macho",
      "names": {
        "ja": "パソ・デルマッチョ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9667,
        "lng": -96.7167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15816,
      "name": "Itzehoe",
      "names": {
        "zh": "伊策霍"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.925,
        "lng": 9.5164
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 16101,
      "name": "Turicato",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.05,
        "lng": -101.4167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6687,
      "name": "Kampong Thom",
      "names": {
        "ja": "カンポントム",
        "th": "กม."
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 12.7,
        "lng": 104.9
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 6831,
      "name": "Molde",
      "names": {
        "zh": "莫尔德",
        "ja": "モルデ",
        "th": "โมลด์"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 62.7333,
        "lng": 7.1833
      },
      "country": "Norway",
      "importance": 1
    },
    {
      "id": 16077,
      "name": "Paipa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.78,
        "lng": -73.1175
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16096,
      "name": "Guachaves",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.2219,
        "lng": -77.6772
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16107,
      "name": "Touba",
      "names": {
        "zh": "图巴"
      },
      "countryCode": "CI",
      "latLng": {
        "lat": 8.2833,
        "lng": -7.6833
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 2
    },
    {
      "id": 15748,
      "name": "Oldenzaal",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3125,
        "lng": 6.9292
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 16079,
      "name": "Santa Rita",
      "names": {
        "zh": "圣丽塔",
        "ja": "サンタリタ",
        "th": "ซานตาริต้า"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.5367,
        "lng": -71.5108
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 16068,
      "name": "Stadskanaal",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.9864,
        "lng": 6.9589
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 16122,
      "name": "Chajul",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4872,
        "lng": -91.0347
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 15988,
      "name": "Godollo",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6,
        "lng": 19.3667
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 16004,
      "name": "Tuzantan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.15,
        "lng": -92.4167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 15975,
      "name": "Chatellerault",
      "names": {
        "es": "Châtellerault"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.8178,
        "lng": 0.5461
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 15892,
      "name": "Aalsmeer",
      "names": {
        "zh": "阿尔斯梅尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2639,
        "lng": 4.7625
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 16138,
      "name": "Sitionuevo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7758,
        "lng": -74.7203
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6605,
      "name": "Amecameca de Juarez",
      "names": {
        "ja": "アメカメカデュレズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1238,
        "lng": -98.7665
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6723,
      "name": "Zhaoyu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.3512,
        "lng": 112.3193
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 16017,
      "name": "Jasim",
      "names": {
        "zh": "贾希姆"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.9922,
        "lng": 36.06
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 16055,
      "name": "Oristano",
      "names": {
        "zh": "奥里斯塔诺",
        "ja": "オリスターノ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.9058,
        "lng": 8.5916
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 6710,
      "name": "Lens",
      "names": {
        "es": "Lente",
        "fr": "Lentille",
        "zh": "镜片",
        "de": "Linse",
        "it": "Lente",
        "id": "Lensa",
        "ja": "レンズ",
        "th": "เลนส์",
        "pt": "Lente"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.4322,
        "lng": 2.8333
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 16141,
      "name": "Jesus Maria",
      "names": {
        "fr": "Jésus Maria",
        "zh": "耶稣玛丽亚",
        "it": "Gesù Maria",
        "id": "Yesus Maria",
        "ja": "イエスマリア",
        "th": "พระเยซูมาเรีย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.9817,
        "lng": -64.0942
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 16130,
      "name": "Chum Phae",
      "names": {
        "de": "Chum-Phae",
        "ja": "チャム・ファエ",
        "th": "ชุมแพ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.5431,
        "lng": 102.1104
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 16094,
      "name": "Galapa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.9003,
        "lng": -74.8853
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16150,
      "name": "Zepce",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.4333,
        "lng": 18.0333
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 15942,
      "name": "Olintepeque",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8833,
        "lng": -91.5167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 16087,
      "name": "I`zaz",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.5888,
        "lng": 37.0441
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 16170,
      "name": "Mae Sot",
      "names": {
        "fr": "Mae",
        "ja": "メーソット",
        "th": "แม่สอด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.7101,
        "lng": 98.5707
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 16187,
      "name": "Puerto Wilches",
      "names": {
        "ja": "プエルトウィルチス",
        "th": "เปอร์โตวิลช์"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.3483,
        "lng": -73.8983
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16147,
      "name": "San Pedro Sacatepequez",
      "names": {
        "fr": "San Pedro Sacatepequezez",
        "ja": "サンペドロ神聖ペケツ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6862,
        "lng": -90.6423
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 6744,
      "name": "Masindi",
      "names": {
        "zh": "马辛迪"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.6744,
        "lng": 31.715
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 6800,
      "name": "Bangassou",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 4.737,
        "lng": 22.819
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 16218,
      "name": "Trebinje",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 42.7089,
        "lng": 18.3217
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 6470,
      "name": "L\u0027Hay-les-Roses",
      "names": {
        "es": "L\u0027hay-les-Rosas",
        "zh": "欧莱雅干草-莱斯-玫瑰",
        "de": "L\u0027Heu-Les-Rosen",
        "ja": "のl\u0027ヘイ・レ・バラ",
        "th": "แมงหญ้าแห้ง-เลส์-กุหลาบ",
        "pt": "L\u0027feno-Les-Rosas"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.78,
        "lng": 2.3374
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 16200,
      "name": "Neira",
      "names": {
        "zh": "内拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.1664,
        "lng": -75.5189
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16216,
      "name": "Halden",
      "names": {
        "zh": "哈尔登"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.1264,
        "lng": 11.4828
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 16236,
      "name": "Kudymkar",
      "names": {
        "zh": "库德姆卡尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 59.0167,
        "lng": 54.6667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 16171,
      "name": "Yawatahama-shi",
      "names": {
        "zh": "yawatahama市",
        "ja": "yawatahama市",
        "th": "yawatahama-ชิ"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.4631,
        "lng": 132.4233
      },
      "country": "Japan",
      "importance": 2
    },
    {
      "id": 16233,
      "name": "Cauquenes",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -35.9671,
        "lng": -72.3154
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 15752,
      "name": "Zgorzelec",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.1528,
        "lng": 15.0
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 801,
      "name": "Banjul",
      "names": {
        "zh": "班珠尔",
        "th": "บันจุล"
      },
      "countryCode": "GM",
      "latLng": {
        "lat": 13.4531,
        "lng": -16.5775
      },
      "country": "The Gambia",
      "importance": 0
    },
    {
      "id": 16229,
      "name": "Guamo",
      "names": {
        "zh": "瓜莫"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.0281,
        "lng": -74.97
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16224,
      "name": "Wenxian Chengguanzhen",
      "names": {
        "zh": "文贤成庄镇"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9421,
        "lng": 104.687
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 16226,
      "name": "Skelleftea",
      "names": {
        "zh": "谢莱夫特奥",
        "pt": "Skellefteå"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 64.65,
        "lng": 20.85
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 6857,
      "name": "Imisli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.8697,
        "lng": 48.06
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 6852,
      "name": "Zaqatala",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.6336,
        "lng": 46.6433
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 16259,
      "name": "Buurhakaba",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 2.7837,
        "lng": 44.0833
      },
      "country": "Somalia",
      "importance": 2
    },
    {
      "id": 16177,
      "name": "Ozd",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.2192,
        "lng": 20.2869
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 16173,
      "name": "Kosonsoy",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 41.2492,
        "lng": 71.5458
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 16198,
      "name": "Tongeren",
      "names": {
        "zh": "通厄伦"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.7794,
        "lng": 5.4631
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 16174,
      "name": "Oudenaarde",
      "names": {
        "zh": "奥德纳德"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.85,
        "lng": 3.6
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 16298,
      "name": "Pichucalco",
      "names": {
        "zh": "皮丘卡尔科"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5,
        "lng": -93.1167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16293,
      "name": "Chitral",
      "names": {
        "zh": "吉德拉尔"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 35.8511,
        "lng": 71.7889
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 16310,
      "name": "Rio Grande",
      "names": {
        "zh": "里奥格兰德",
        "ja": "リオグランデ",
        "th": "ริโอแกรนด์",
        "pt": "Rude"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -53.7914,
        "lng": -67.699
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 16282,
      "name": "Lagos",
      "names": {
        "zh": "拉戈斯",
        "ja": "ラゴス",
        "th": "ลากอส"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1,
        "lng": -8.6667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 15925,
      "name": "Espinho",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.01,
        "lng": -8.64
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 16295,
      "name": "Tokmak",
      "names": {
        "zh": "托克马克"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.2514,
        "lng": 35.7058
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 6873,
      "name": "Diffa",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 13.3171,
        "lng": 12.6089
      },
      "country": "Niger",
      "importance": 1
    },
    {
      "id": 6839,
      "name": "Babati",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -4.2117,
        "lng": 35.7475
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 6733,
      "name": "Hendrik-Ido-Ambacht",
      "names": {
        "zh": "德里克 - 伊多文-ambacht",
        "ja": "ヘンドリック-イド語-ambacht"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.85,
        "lng": 4.63
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6860,
      "name": "Szekszard",
      "names": {
        "zh": "塞克萨德",
        "de": "Szekszárd"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.3558,
        "lng": 18.7039
      },
      "country": "Hungary",
      "importance": 1
    },
    {
      "id": 6871,
      "name": "Cuilapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.2783,
        "lng": -90.2992
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 6836,
      "name": "Jeremie",
      "names": {
        "zh": "热雷米"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 18.6339,
        "lng": -74.1184
      },
      "country": "Haiti",
      "importance": 1
    },
    {
      "id": 16222,
      "name": "Valkenswaard",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.35,
        "lng": 5.4592
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6884,
      "name": "Tripoli",
      "names": {
        "es": "Trípoli",
        "zh": "的黎波里",
        "de": "Tripolis",
        "ja": "トリポリ",
        "th": "ตริโปลี",
        "pt": "Trípoli"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.5083,
        "lng": 22.375
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 16327,
      "name": "Ski",
      "names": {
        "es": "Esquí",
        "zh": "滑雪",
        "it": "Sciare",
        "ja": "スキー",
        "th": "เล่นสกี"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.7419,
        "lng": 10.8939
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 15995,
      "name": "Capulhuac",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2,
        "lng": -99.4667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6882,
      "name": "Ungheni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.2042,
        "lng": 27.7958
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 6510,
      "name": "Escuinapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.9822,
        "lng": -105.7031
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6696,
      "name": "Busia",
      "names": {
        "zh": "布西亚"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 0.4608,
        "lng": 34.1108
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 16379,
      "name": "Neuruppin",
      "names": {
        "zh": "新鲁平"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.9222,
        "lng": 12.8
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6774,
      "name": "Santiago Sacatepequez",
      "names": {
        "fr": "Santiago sacatepequezez"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.653,
        "lng": -90.6524
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 16271,
      "name": "Boxtel",
      "names": {
        "zh": "博克斯特尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5911,
        "lng": 5.3275
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6835,
      "name": "Pallisa",
      "names": {
        "zh": "帕利萨"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.145,
        "lng": 33.7094
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 6494,
      "name": "Vrilissia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0391,
        "lng": 23.8378
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 16359,
      "name": "Amatenango de la Frontera",
      "names": {
        "fr": "Amaenango de la Frontera",
        "de": "Amateango de la frontera",
        "it": "Amatengo de la Frontera"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.4333,
        "lng": -92.1167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16406,
      "name": "Synelnykove",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.3178,
        "lng": 35.5119
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 16188,
      "name": "Verbania",
      "names": {
        "zh": "韦尔巴尼亚",
        "ja": "ヴェルバニア",
        "th": "เวอร์เบเนีย"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 45.9228,
        "lng": 8.5519
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 16398,
      "name": "Hajduboszormeny",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6667,
        "lng": 21.5167
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 16383,
      "name": "Tepetlaoxtoc",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5731,
        "lng": -98.8203
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16091,
      "name": "Dreux",
      "names": {
        "zh": "德勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7372,
        "lng": 1.3664
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6646,
      "name": "Lahij",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 13.05,
        "lng": 44.8833
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 16429,
      "name": "Mercedes",
      "names": {
        "zh": "梅赛德斯",
        "ja": "メルセデス",
        "th": "เมอร์เซเดส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -29.1796,
        "lng": -58.08
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 16419,
      "name": "Madruga",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9164,
        "lng": -81.9239
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 16433,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.6167,
        "lng": -60.1667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 16427,
      "name": "Banamba",
      "names": {
        "zh": "巴南巴"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 13.55,
        "lng": -7.45
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 16067,
      "name": "Mohyliv-Podilskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.45,
        "lng": 27.7833
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 16417,
      "name": "Odemis",
      "names": {
        "zh": "厄代米什"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2311,
        "lng": 27.9719
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 16292,
      "name": "Ciudad Vieja",
      "names": {
        "ja": "シウダードビエハ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5233,
        "lng": -90.7667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 6827,
      "name": "Zug",
      "names": {
        "zh": "楚格",
        "ja": "ツーク",
        "th": "ซัก"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.1681,
        "lng": 8.5169
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 6903,
      "name": "Asosa",
      "names": {
        "zh": "阿索萨"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 10.067,
        "lng": 34.5333
      },
      "country": "Ethiopia",
      "importance": 1
    },
    {
      "id": 16431,
      "name": "Thung Song",
      "names": {
        "es": "Canción thung",
        "fr": "Chanson de Thung",
        "zh": "龙宋",
        "de": "Thung-Song",
        "it": "Canzone del Thung",
        "id": "Lagu thung",
        "ja": "猫の歌",
        "th": "เพลงทุ่ง",
        "pt": "Canção de thung"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.1669,
        "lng": 99.6745
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 6897,
      "name": "Krabi",
      "names": {
        "zh": "甲米",
        "ja": "クラビ",
        "th": "กระบี่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.0592,
        "lng": 98.9189
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 16449,
      "name": "G\u0027ijduvon Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.1,
        "lng": 64.6833
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 6885,
      "name": "Cakovec",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 46.3833,
        "lng": 16.4333
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 6743,
      "name": "Mandera",
      "names": {
        "zh": "曼德拉"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 3.9167,
        "lng": 41.8333
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 6670,
      "name": "Magdalena de Kino",
      "names": {
        "ja": "マグダレナデキノ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.6167,
        "lng": -111.05
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6906,
      "name": "Salyan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.5961,
        "lng": 48.9847
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 16484,
      "name": "Horki",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.2833,
        "lng": 30.9833
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 16152,
      "name": "Lowicz",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.1,
        "lng": 19.9333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 16416,
      "name": "Papa",
      "names": {
        "zh": "爸爸",
        "ja": "パパ",
        "th": "พ่อ"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3306,
        "lng": 17.4658
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 16272,
      "name": "Lukow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.9167,
        "lng": 22.3833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 16410,
      "name": "Amaga",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0383,
        "lng": -75.7028
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6868,
      "name": "Nebbi",
      "names": {
        "zh": "内比"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.4758,
        "lng": 31.1025
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 16099,
      "name": "Temascaltepec de Gonzalez",
      "names": {
        "es": "Temascaltepec de González",
        "de": "TemaSaStepec de Gonzalez",
        "it": "Teascaltepec de Gonzalez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0433,
        "lng": -100.0414
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6698,
      "name": "Huatabampo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.8275,
        "lng": -109.6422
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16213,
      "name": "Bielsk Podlaski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.7641,
        "lng": 23.1902
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 16492,
      "name": "Telimele",
      "names": {
        "zh": "泰利梅莱"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.905,
        "lng": -13.043
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 16509,
      "name": "Chimichagua",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2578,
        "lng": -73.8133
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16457,
      "name": "Osterholz-Scharmbeck",
      "names": {
        "zh": "奥斯特霍尔茨-沙姆贝克"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.2269,
        "lng": 8.7947
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6574,
      "name": "Ometepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.6833,
        "lng": -98.4167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16471,
      "name": "Korinthos",
      "names": {
        "zh": "科林斯"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9386,
        "lng": 22.9272
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 6796,
      "name": "Yverdon-les-Bains",
      "names": {
        "zh": "伊韦尔东-莱斯班",
        "ja": "yverdon・レ・バン",
        "th": "yverdon-เลสแบงส์"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.7785,
        "lng": 6.6408
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 16527,
      "name": "Augustow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.85,
        "lng": 22.9667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 16524,
      "name": "Kombissiri",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.0667,
        "lng": -1.3333
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 16546,
      "name": "Shangpa",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.9052,
        "lng": 98.8679
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 16562,
      "name": "Planadas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.1964,
        "lng": -75.6444
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16316,
      "name": "Bochnia",
      "names": {
        "zh": "博赫尼亚"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.9833,
        "lng": 20.4333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6760,
      "name": "Perigueux",
      "names": {
        "fr": "Périgueux",
        "zh": "佩里格",
        "id": "Périgueux"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.1929,
        "lng": 0.7217
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 16586,
      "name": "Villa Angela",
      "names": {
        "ja": "ヴィラアンジェラ",
        "th": "วิลล่าแองเจล่า"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.5833,
        "lng": -60.7167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 6546,
      "name": "Nanchital de Lazaro Cardenas del Rio",
      "names": {
        "es": "Nanchital de Lázaro Cardenas del Rio",
        "fr": "Nancital de Lazaro Cardenas Del Rio",
        "zh": "南北德拉扎罗卡诺斯德尔里奥",
        "ja": "ナンチャイタルデラザロカルデナスデルリオ",
        "pt": "Nancital de Lazaro Cardenas del Rio"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.0667,
        "lng": -94.4167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16448,
      "name": "Leusden",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1331,
        "lng": 5.4297
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6742,
      "name": "Kericho",
      "names": {
        "zh": "凯里乔"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.3692,
        "lng": 35.2839
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 6925,
      "name": "Cahul",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 45.9075,
        "lng": 28.1944
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 803,
      "name": "Majuro",
      "names": {
        "zh": "马朱罗",
        "ja": "マジュロ",
        "th": "มาจูโร"
      },
      "countryCode": "MH",
      "latLng": {
        "lat": 7.0918,
        "lng": 171.3802
      },
      "country": "Marshall Islands",
      "importance": 0
    },
    {
      "id": 6924,
      "name": "Mutsamudu",
      "names": {},
      "countryCode": "KM",
      "latLng": {
        "lat": -12.1675,
        "lng": 44.3939
      },
      "country": "Comoros",
      "importance": 1
    },
    {
      "id": 6934,
      "name": "Trujillo",
      "names": {
        "zh": "特鲁希略",
        "ja": "トルヒーヨ",
        "th": "ตรูฮีโย"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.9167,
        "lng": -86.0
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 16578,
      "name": "Condega",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.365,
        "lng": -86.3985
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 16281,
      "name": "Eshtehard",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7256,
        "lng": 50.3661
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 16606,
      "name": "Asipovichy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.3,
        "lng": 28.65
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 6820,
      "name": "Chon Buri",
      "names": {
        "ja": "チョンブリ",
        "th": "ชลบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.3611,
        "lng": 100.985
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 16589,
      "name": "Meschede",
      "names": {
        "zh": "梅舍德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3503,
        "lng": 8.2836
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6936,
      "name": "Subotica",
      "names": {
        "zh": "苏博蒂察"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 46.0983,
        "lng": 19.67
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 16169,
      "name": "San Andres Xecul",
      "names": {
        "es": "San Andrés Xecul",
        "zh": "圣安德里斯Xecul",
        "ja": "サンアンドレスXecul"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9,
        "lng": -91.4833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 16426,
      "name": "Mlawa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1341,
        "lng": 20.3812
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 16415,
      "name": "Best",
      "names": {
        "es": "Mejor",
        "fr": "Meilleur",
        "zh": "最好",
        "it": "Migliori",
        "id": "Terbaik",
        "ja": "ベスト",
        "th": "ดีที่สุด",
        "pt": "O melhor"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5108,
        "lng": 5.3922
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6889,
      "name": "Bregenz",
      "names": {
        "zh": "布雷根茨",
        "ja": "ブレゲンツ"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.505,
        "lng": 9.7492
      },
      "country": "Austria",
      "importance": 1
    },
    {
      "id": 16547,
      "name": "Bad Hersfeld",
      "names": {
        "es": "Mal Hersfeld",
        "fr": "Mauvais hersfeld",
        "zh": "糟糕的hersfeld",
        "de": "Schlechtes Hersfeld",
        "id": "Hersfeld buruk",
        "ja": "悪いハースフェルト",
        "th": "hersfeld ที่ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8683,
        "lng": 9.7067
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 16645,
      "name": "Tequisquiapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5206,
        "lng": -99.8958
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16627,
      "name": "Fresno",
      "names": {
        "zh": "弗雷斯诺",
        "ja": "フレズノ",
        "th": "เฟรสโน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.1536,
        "lng": -75.0369
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16621,
      "name": "Giannitsa",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.7962,
        "lng": 22.4145
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 16655,
      "name": "Purificacion",
      "names": {
        "es": "Purificación"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.8567,
        "lng": -74.9325
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6916,
      "name": "Mount Hagen",
      "names": {
        "es": "Monte Hagen",
        "zh": "哈格山",
        "de": "Hagen",
        "it": "Monte Hagen",
        "ja": "ハーゲン山",
        "th": "เมาฮะเกน",
        "pt": "Monte Hagen"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -5.8667,
        "lng": 144.2167
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 16674,
      "name": "Santiago do Cacem",
      "names": {
        "zh": "圣地亚哥做CACEM",
        "th": "Santiago ทำ Cacem"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.0,
        "lng": -8.6833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 16603,
      "name": "Merzig",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4471,
        "lng": 6.6312
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6938,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.3369,
        "lng": -87.8439
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 16614,
      "name": "Arlon",
      "names": {
        "zh": "阿尔隆"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 49.6836,
        "lng": 5.8167
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 16685,
      "name": "Atbasar",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.8,
        "lng": 68.3667
      },
      "country": "Kazakhstan",
      "importance": 2
    },
    {
      "id": 16564,
      "name": "Tabio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9158,
        "lng": -74.0983
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16584,
      "name": "Heesch",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7314,
        "lng": 5.53
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 16463,
      "name": "Mont-de-Marsan",
      "names": {
        "zh": "勃朗峰 - 去马尔桑",
        "ja": "モン\u003dド\u003dマルサン",
        "th": "มองต์เดอ-marsan"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.89,
        "lng": -0.5
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 16595,
      "name": "Neuburg",
      "names": {
        "zh": "诺伊堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7333,
        "lng": 11.1833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6939,
      "name": "Stung Treng",
      "names": {
        "es": "Picado",
        "fr": "Piquant",
        "it": "Stug Treng",
        "ja": "スズメチェング",
        "th": "กิ่งตัน"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 13.5259,
        "lng": 105.9683
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 16276,
      "name": "Yingshouyingzi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.5451,
        "lng": 117.656
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 16692,
      "name": "Bosanska Krupa",
      "names": {
        "ja": "ボサンスカクルパ"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.8833,
        "lng": 16.15
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 16701,
      "name": "Toksun",
      "names": {
        "zh": "托克逊"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 42.7918,
        "lng": 88.6536
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6837,
      "name": "Gizycko",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.04,
        "lng": 21.7589
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 16635,
      "name": "San Rafael",
      "names": {
        "zh": "圣拉斐尔",
        "ja": "サンラファエル",
        "th": "ซานราฟาเอล"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1889,
        "lng": -96.8658
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16676,
      "name": "Alquizar",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8067,
        "lng": -82.5828
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 6957,
      "name": "Agdas",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.65,
        "lng": 47.4833
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 6956,
      "name": "Doba",
      "names": {
        "zh": "多巴"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 8.65,
        "lng": 16.85
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 16646,
      "name": "Villa Gesell",
      "names": {
        "ja": "ヴィラ・ジェセル"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.2556,
        "lng": -56.9681
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 16371,
      "name": "Vienne",
      "names": {
        "zh": "维埃纳",
        "ja": "ビエンヌ",
        "th": "เวียน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.5242,
        "lng": 4.8781
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 16571,
      "name": "Barbosa",
      "names": {
        "zh": "巴博萨"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9331,
        "lng": -73.6147
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16641,
      "name": "Xalatlaco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1811,
        "lng": -99.4164
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16742,
      "name": "Ja`ar",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 13.2167,
        "lng": 45.3
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 16493,
      "name": "Uithoorn",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2422,
        "lng": 4.825
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 16670,
      "name": "Flandes",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.2844,
        "lng": -74.8142
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6695,
      "name": "Escarcega",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6067,
        "lng": -90.7344
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16588,
      "name": "Saalfeld",
      "names": {
        "zh": "萨尔费尔德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6506,
        "lng": 11.3542
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6972,
      "name": "Backa Palanka",
      "names": {
        "es": "Palanka backa",
        "id": "Baca palanka",
        "ja": "バックパパンカ"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.2506,
        "lng": 19.3886
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 16720,
      "name": "Juchitepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0997,
        "lng": -98.8792
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6779,
      "name": "Krimpen aan den IJssel",
      "names": {
        "id": "KRimpen Aan den IJSEL",
        "ja": "Kripemen Aan Den Ijssel"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.92,
        "lng": 4.6
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 16776,
      "name": "Ra\u0027s al `Ayn",
      "names": {
        "es": "Ra\u0027s al \u0027Ayn",
        "fr": "Ra al `ayn",
        "zh": "ra\u0027s al` ayn",
        "de": "Ra\u0027s Al` Ayn",
        "ja": "RAのAL `Ayn",
        "th": "ra\u0027s `ayn",
        "pt": "Ra\u0027s Al \u0027Ayn"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.8503,
        "lng": 40.0706
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 16769,
      "name": "Gyula",
      "names": {
        "zh": "久洛"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.65,
        "lng": 21.2828
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 16770,
      "name": "Kiskunfelegyhaza",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.705,
        "lng": 19.85
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 6975,
      "name": "Linden",
      "names": {
        "zh": "菩提树",
        "ja": "リンデン",
        "th": "ลินเด็น"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.0,
        "lng": -58.3
      },
      "country": "Guyana",
      "importance": 1
    },
    {
      "id": 6979,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.9167,
        "lng": -88.2333
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 6973,
      "name": "Chalatenango",
      "names": {},
      "countryCode": "SV",
      "latLng": {
        "lat": 14.0333,
        "lng": -88.9333
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 16746,
      "name": "Riihimaki",
      "names": {
        "zh": "里希迈基"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.7333,
        "lng": 24.7667
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 16784,
      "name": "Quivican",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.8247,
        "lng": -82.3558
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 16715,
      "name": "Gustrow",
      "names": {
        "de": "Güstrow",
        "it": "Güstrow",
        "id": "Güstrow"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.7939,
        "lng": 12.1764
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6970,
      "name": "Bac Kan",
      "names": {
        "es": "Bac"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 22.1514,
        "lng": 105.8377
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 16821,
      "name": "Fundao",
      "names": {
        "zh": "丰当",
        "pt": "Fundão"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.1333,
        "lng": -7.5
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 16819,
      "name": "Canada de Gomez",
      "names": {
        "es": "Canadá de Gómez",
        "zh": "加拿大德梅兹",
        "de": "Kanada de Gomez",
        "id": "Kanada de Gomez",
        "ja": "カナダデゴメス",
        "th": "แคนาดาเดอโกเมซ",
        "pt": "Canadá de gomez"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.8167,
        "lng": -61.4
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 6864,
      "name": "Diemen",
      "names": {
        "zh": "迪门"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3439,
        "lng": 4.9625
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6585,
      "name": "Zapotiltic",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.627,
        "lng": -103.417
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16624,
      "name": "Svendborg",
      "names": {
        "zh": "斯文堡"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.0704,
        "lng": 10.6167
      },
      "country": "Denmark",
      "importance": 2
    },
    {
      "id": 16677,
      "name": "Friedberg",
      "names": {
        "zh": "弗里德伯格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3353,
        "lng": 8.755
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6980,
      "name": "Kamphaeng Phet",
      "names": {
        "es": "Phet kamphaeng",
        "fr": "Phott-Phot",
        "id": "Phet kamphaeng",
        "ja": "カンファエンペケット",
        "th": "กำแพงเพชร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.4811,
        "lng": 99.5222
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 16829,
      "name": "Aipe",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.2219,
        "lng": -75.2375
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6858,
      "name": "San Fernando",
      "names": {
        "zh": "圣费尔南多",
        "ja": "サンフェルナンド",
        "th": "ซานเฟอร์นันโด"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.8504,
        "lng": -98.16
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16773,
      "name": "Ath",
      "names": {
        "zh": "阿特"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6311,
        "lng": 3.7769
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 16811,
      "name": "Anadia",
      "names": {
        "zh": "阿纳迪亚"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.4333,
        "lng": -8.4333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 16719,
      "name": "Landsberg",
      "names": {
        "zh": "兰茨伯格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0528,
        "lng": 10.8689
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 16777,
      "name": "Sopo",
      "names": {
        "zh": "索普"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9081,
        "lng": -73.9403
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16849,
      "name": "San Cristobal",
      "names": {
        "es": "San Cristóbal",
        "ja": "サンクリストバル"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7169,
        "lng": -83.0511
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 16814,
      "name": "Monitos",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.2461,
        "lng": -76.1286
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16845,
      "name": "Primero de Enero",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 21.9453,
        "lng": -78.4189
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 16693,
      "name": "Ozumba",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0392,
        "lng": -98.7936
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16801,
      "name": "Northeim",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7067,
        "lng": 10.0011
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 16778,
      "name": "Suran",
      "names": {
        "zh": "苏兰"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.2897,
        "lng": 36.7433
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 16794,
      "name": "Boxmeer",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6483,
        "lng": 5.9444
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6781,
      "name": "Kitui",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -1.3667,
        "lng": 38.0167
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 16871,
      "name": "Nouna",
      "names": {
        "zh": "努纳"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 12.7329,
        "lng": -3.8622
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 16874,
      "name": "Benavente",
      "names": {
        "zh": "贝纳文特"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.9833,
        "lng": -8.8167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6535,
      "name": "Reforma",
      "names": {
        "zh": "改革",
        "ja": "レフォルマ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.8658,
        "lng": -93.1472
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16757,
      "name": "Sint-Michielsgestel",
      "names": {
        "zh": "圣彼-michielsgestel",
        "ja": "シント・michielsgestel",
        "th": "ซิงต์-michielsgestel"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6433,
        "lng": 5.3586
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 16899,
      "name": "Nenton",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.8012,
        "lng": -91.7552
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 16880,
      "name": "La Dorada",
      "names": {
        "ja": "ラドラダ",
        "th": "ลาโดราดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.3436,
        "lng": -76.9108
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16896,
      "name": "Villa Comaltitlan",
      "names": {
        "it": "Villa Comalitlan",
        "ja": "ヴィラコマルティン"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.2167,
        "lng": -92.5667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16898,
      "name": "Tondela",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.5167,
        "lng": -8.0833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6987,
      "name": "Siquijor",
      "names": {
        "zh": "锡基霍尔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.215,
        "lng": 123.514
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 16861,
      "name": "Ban Phai",
      "names": {
        "es": "Prohibir",
        "zh": "班菲",
        "de": "Phai Ban",
        "ja": "バンパイ",
        "th": "บ้านไผ่",
        "pt": "Pro proai"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.073,
        "lng": 102.7362
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 16892,
      "name": "Salihli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4811,
        "lng": 28.1392
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 16900,
      "name": "La Calera",
      "names": {
        "zh": "拉卡拉",
        "ja": "ラカレラ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7197,
        "lng": -73.97
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16868,
      "name": "Winterswijk",
      "names": {
        "zh": "温特斯韦克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9667,
        "lng": 6.7167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 16563,
      "name": "Brodnica",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.25,
        "lng": 19.4
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7001,
      "name": "Corum",
      "names": {
        "zh": "科隆",
        "ja": "コルム"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.5489,
        "lng": 34.9533
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 16882,
      "name": "Schwandorf",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.3236,
        "lng": 12.0993
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 16917,
      "name": "Caicedonia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.3347,
        "lng": -75.8281
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16901,
      "name": "Hoxter",
      "names": {
        "es": "Höxter",
        "de": "Höxter"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7667,
        "lng": 9.3667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 16923,
      "name": "Hirado",
      "names": {
        "zh": "平户"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.3681,
        "lng": 129.5539
      },
      "country": "Japan",
      "importance": 2
    },
    {
      "id": 16915,
      "name": "Krotoszyn",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.697,
        "lng": 17.4357
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 798,
      "name": "Tarawa",
      "names": {
        "zh": "塔拉瓦",
        "ja": "タラワ"
      },
      "countryCode": "KI",
      "latLng": {
        "lat": 1.3382,
        "lng": 173.0176
      },
      "country": "Kiribati",
      "importance": 0
    },
    {
      "id": 16738,
      "name": "Carpentras",
      "names": {
        "zh": "卡庞特拉",
        "ja": "カルパントラ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.0558,
        "lng": 5.0489
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 6988,
      "name": "Penonome",
      "names": {
        "es": "Penonomé",
        "zh": "佩诺诺梅"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.51,
        "lng": -80.36
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 6966,
      "name": "Katima Mulilo",
      "names": {
        "ja": "カイマムリロ"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -17.5,
        "lng": 24.2667
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 16986,
      "name": "Hatonuevo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 11.0672,
        "lng": -72.7631
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 16979,
      "name": "San Salvador",
      "names": {
        "zh": "圣萨尔瓦多",
        "ja": "サンサルバドール",
        "th": "ซานซัลวาดอร์"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2833,
        "lng": -99.0153
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6641,
      "name": "Pabellon de Arteaga",
      "names": {
        "id": "Pabellon de artega"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.15,
        "lng": -102.2667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17018,
      "name": "Byaroza",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.5333,
        "lng": 24.9825
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 7007,
      "name": "Buri Ram",
      "names": {
        "es": "Birmante",
        "fr": "Buri",
        "ja": "ブリムラ",
        "th": "บุรีรัมย์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.9942,
        "lng": 103.1022
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 7004,
      "name": "Manga",
      "names": {
        "zh": "漫画",
        "ja": "漫画",
        "th": "มังงะ"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 11.6667,
        "lng": -1.0667
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 17021,
      "name": "San Carlos",
      "names": {
        "zh": "圣卡洛斯",
        "ja": "サンカルロス",
        "th": "ซานคาร์ลอส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.7944,
        "lng": -75.6994
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6875,
      "name": "Dieppe",
      "names": {
        "zh": "迪耶普"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.92,
        "lng": 1.08
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 16759,
      "name": "Culemborg",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.95,
        "lng": 5.2333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 16628,
      "name": "Schwelm",
      "names": {
        "zh": "施韦尔姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2904,
        "lng": 7.2972
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7016,
      "name": "Smolyan",
      "names": {
        "zh": "斯莫梁"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 41.5753,
        "lng": 24.7128
      },
      "country": "Bulgaria",
      "importance": 1
    },
    {
      "id": 17045,
      "name": "Baradero",
      "names": {
        "zh": "巴拉德罗"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.8,
        "lng": -59.5167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 17027,
      "name": "Isnos",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.9289,
        "lng": -76.2158
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6887,
      "name": "Soissons",
      "names": {
        "zh": "苏瓦松"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.3817,
        "lng": 3.3236
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 16904,
      "name": "Nivelles",
      "names": {
        "zh": "尼韦勒"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.5964,
        "lng": 4.3236
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 17015,
      "name": "Dalfsen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5031,
        "lng": 6.2592
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 16966,
      "name": "Heerenveen",
      "names": {
        "zh": "海伦芬"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.95,
        "lng": 5.9333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7009,
      "name": "Olgiy",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 48.9656,
        "lng": 89.9632
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 6997,
      "name": "Samut Songkhram",
      "names": {
        "th": "สมุทรสงคราม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.4097,
        "lng": 100.0017
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 17046,
      "name": "Lillehammer",
      "names": {
        "zh": "利勒哈默尔",
        "ja": "リレハンメル"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 61.1146,
        "lng": 10.4674
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 7015,
      "name": "Mendefera",
      "names": {},
      "countryCode": "ER",
      "latLng": {
        "lat": 14.8833,
        "lng": 38.8167
      },
      "country": "Eritrea",
      "importance": 1
    },
    {
      "id": 16709,
      "name": "Rendsburg",
      "names": {
        "zh": "伦茨堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.3044,
        "lng": 9.6644
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17061,
      "name": "Venezuela",
      "names": {
        "zh": "委内瑞拉",
        "ja": "ベネズエラ",
        "th": "เวเนซุเอลา"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 21.7511,
        "lng": -78.7792
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 17071,
      "name": "Coyaima",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.7975,
        "lng": -75.1939
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17047,
      "name": "Tha Yang",
      "names": {
        "zh": "泰文",
        "th": "ท่าหยาง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.9658,
        "lng": 99.8924
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 17094,
      "name": "Raseborg",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 59.975,
        "lng": 23.4361
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 17075,
      "name": "Chortkiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0167,
        "lng": 25.8
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7034,
      "name": "Mitu",
      "names": {
        "zh": "米图"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.2503,
        "lng": -70.235
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 17112,
      "name": "Toli",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 45.9313,
        "lng": 83.6039
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 17098,
      "name": "Ciro Redondo",
      "names": {
        "pt": "Cirão redondo"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.0189,
        "lng": -78.7031
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 7028,
      "name": "Kocani",
      "names": {
        "zh": "科查尼"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9167,
        "lng": 22.4125
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 16857,
      "name": "Waddinxveen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0333,
        "lng": 4.6333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6982,
      "name": "Hillerod",
      "names": {
        "fr": "Hillerød",
        "zh": "希勒罗德",
        "de": "Hillerød"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.9333,
        "lng": 12.3167
      },
      "country": "Denmark",
      "importance": 1
    },
    {
      "id": 17120,
      "name": "Guamal",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1442,
        "lng": -74.2236
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17140,
      "name": "Alga",
      "names": {
        "fr": "Algue",
        "zh": "藻类",
        "ja": "藻類",
        "th": "สาหร่าย"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 49.9032,
        "lng": 57.335
      },
      "country": "Kazakhstan",
      "importance": 2
    },
    {
      "id": 17084,
      "name": "Carbonia",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 39.1668,
        "lng": 8.522
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 17109,
      "name": "Las Rosas",
      "names": {
        "zh": "拉斯罗莎",
        "ja": "ラスロサ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3556,
        "lng": -92.3672
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17125,
      "name": "Gusev",
      "names": {
        "zh": "古瑟夫"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 54.5922,
        "lng": 22.1997
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 17148,
      "name": "Bad Neuenahr-Ahrweiler",
      "names": {
        "es": "Mal neuenhr-ahweiler",
        "zh": "坏Neuenahr-Ahrweiler",
        "id": "Neuenahr-ahrweiler yang buruk"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.5447,
        "lng": 7.1133
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6931,
      "name": "Otjiwarongo",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -20.4642,
        "lng": 16.6528
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 17151,
      "name": "Bardaskan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.2631,
        "lng": 57.9722
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 17121,
      "name": "Khust",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.1814,
        "lng": 23.2978
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 17145,
      "name": "Zacoalco de Torres",
      "names": {
        "ja": "ザコアルコデトーレス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2333,
        "lng": -103.5833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7041,
      "name": "Popondetta",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -8.7656,
        "lng": 148.2347
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 7045,
      "name": "Ouesso",
      "names": {},
      "countryCode": "CG",
      "latLng": {
        "lat": 1.6167,
        "lng": 16.05
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 17006,
      "name": "Gyongyos",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.7833,
        "lng": 19.9333
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 17166,
      "name": "Guma",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 37.6168,
        "lng": 78.2809
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 17079,
      "name": "Circasia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.62,
        "lng": -75.6347
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17100,
      "name": "Warin Chamrap",
      "names": {
        "es": "Camarón warin",
        "pt": "Chanfrap warin"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2008,
        "lng": 104.8612
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 16879,
      "name": "Meissen",
      "names": {
        "zh": "迈森",
        "de": "Meißen",
        "ja": "マイセン"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1636,
        "lng": 13.4775
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17156,
      "name": "Capljina",
      "names": {
        "zh": "查普利纳"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.11,
        "lng": 17.7
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 16688,
      "name": "Brunssum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.95,
        "lng": 5.9667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6977,
      "name": "Khorugh",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 37.4917,
        "lng": 71.5575
      },
      "country": "Tajikistan",
      "importance": 1
    },
    {
      "id": 17191,
      "name": "Khulm",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.6833,
        "lng": 67.6833
      },
      "country": "Afghanistan",
      "importance": 2
    },
    {
      "id": 17170,
      "name": "Dajabon",
      "names": {
        "zh": "达哈翁"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.5667,
        "lng": -71.71
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 17129,
      "name": "Esztergom",
      "names": {
        "zh": "埃斯泰尔戈姆"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.7856,
        "lng": 18.7403
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 17134,
      "name": "Ajka",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1006,
        "lng": 17.5522
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 17040,
      "name": "Lucenec",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.3314,
        "lng": 19.6708
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 17230,
      "name": "San Martin de los Andes",
      "names": {
        "zh": "圣马丁德洛斯斯",
        "ja": "サンマーティンデロスアンデス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.1667,
        "lng": -71.35
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7052,
      "name": "Hamar",
      "names": {
        "zh": "哈马尔",
        "th": "ฮามาร์"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.7944,
        "lng": 11.0678
      },
      "country": "Norway",
      "importance": 1
    },
    {
      "id": 16805,
      "name": "Khmilnyk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.55,
        "lng": 27.9667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 804,
      "name": "Douglas",
      "names": {
        "zh": "道格拉斯",
        "ja": "ダグラス",
        "th": "ดักลาส"
      },
      "countryCode": "IM",
      "latLng": {
        "lat": 54.15,
        "lng": -4.4819
      },
      "country": "Isle Of Man",
      "importance": 0
    },
    {
      "id": 17236,
      "name": "Nochistlan de Mejia",
      "names": {
        "id": "Nolchistlan de mejia"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.3642,
        "lng": -102.8464
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17004,
      "name": "Emmendingen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.1214,
        "lng": 7.8492
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7049,
      "name": "Mytilini",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.1047,
        "lng": 26.5536
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 17216,
      "name": "Quiroga",
      "names": {
        "zh": "基罗加"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6638,
        "lng": -101.524
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17268,
      "name": "Villa Luvianos",
      "names": {
        "zh": "别墅卢瓦亚州",
        "it": "Villa Luvinos",
        "pt": "Villa Luvolsos"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.92,
        "lng": -100.2983
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17242,
      "name": "Sabaneta",
      "names": {
        "zh": "萨瓦内塔"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.7522,
        "lng": -69.9325
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 17270,
      "name": "Salinas Victoria",
      "names": {
        "zh": "萨利纳斯维多利亚",
        "th": "ซาลินาสวิคตอเรีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.9667,
        "lng": -100.3
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17274,
      "name": "Zolotonosha",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.6833,
        "lng": 32.0333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 17212,
      "name": "Uchturpan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.2136,
        "lng": 79.2319
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 17222,
      "name": "Imatra",
      "names": {
        "zh": "伊马特拉",
        "ja": "イマトラ"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.1931,
        "lng": 28.7764
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 6904,
      "name": "Ketrzyn",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.0833,
        "lng": 21.3833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 17269,
      "name": "Vyshhorod",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.5833,
        "lng": 30.5
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 17247,
      "name": "Tlalpujahua de Rayon",
      "names": {
        "es": "Tlalpujahua de rayón",
        "zh": "Tlalpujahua deyon"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8052,
        "lng": -100.1741
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17200,
      "name": "Inegol",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.0806,
        "lng": 29.5097
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 17298,
      "name": "Vinales",
      "names": {
        "es": "Viñales",
        "zh": "比尼亚莱斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.6153,
        "lng": -83.7158
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 6791,
      "name": "Soledad de Doblado",
      "names": {
        "id": "Soledad de dublado"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0447,
        "lng": -96.4233
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7065,
      "name": "Bongor",
      "names": {
        "zh": "邦戈尔"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 10.2806,
        "lng": 15.3722
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 7067,
      "name": "Mongo",
      "names": {
        "zh": "蒙戈",
        "ja": "モンゴ",
        "th": "มองโก"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 12.1837,
        "lng": 18.7
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 17279,
      "name": "Fuman",
      "names": {
        "zh": "富曼"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 37.2239,
        "lng": 49.3125
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 17188,
      "name": "Peniche",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.35,
        "lng": -9.3667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 16835,
      "name": "Ornskoldsvik",
      "names": {
        "de": "Örnsköldsvik"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 63.294,
        "lng": 18.7122
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 17308,
      "name": "Gabasumdo",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.2554,
        "lng": 100.5693
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 805,
      "name": "George Town",
      "names": {
        "fr": "Ville de George",
        "zh": "乔治镇",
        "ja": "ジョージタウン",
        "th": "จอร์จทาวน์",
        "pt": "Cidade de george"
      },
      "countryCode": "KY",
      "latLng": {
        "lat": 19.2866,
        "lng": -81.3744
      },
      "country": "Cayman Islands",
      "importance": 0
    },
    {
      "id": 17322,
      "name": "Kongsberg",
      "names": {
        "zh": "康斯堡"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.665,
        "lng": 9.6464
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 17128,
      "name": "Piest\u0027any",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.5833,
        "lng": 17.8333
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 17336,
      "name": "Argelia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.2558,
        "lng": -77.2492
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17110,
      "name": "Ban Chang",
      "names": {
        "es": "Prohibir",
        "fr": "Interdire",
        "zh": "班昌",
        "it": "Bandito",
        "ja": "バンチャン",
        "th": "บ้านช้าง",
        "pt": "Proibir chang"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.7209,
        "lng": 101.0669
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 17271,
      "name": "Atlautla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0,
        "lng": -98.7167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6652,
      "name": "Catemaco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.4167,
        "lng": -95.1167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6843,
      "name": "Contla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3333,
        "lng": -98.1667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17277,
      "name": "Soignies",
      "names": {
        "zh": "苏瓦尼"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.5667,
        "lng": 4.0667
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 17347,
      "name": "Soke",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7482,
        "lng": 27.4061
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 17276,
      "name": "Zaltbommel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8,
        "lng": 5.25
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17178,
      "name": "Szentendre",
      "names": {
        "zh": "圣安德烈"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6733,
        "lng": 19.0725
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 17373,
      "name": "Siverek",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.75,
        "lng": 39.3167
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 17355,
      "name": "Hulst",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.3158,
        "lng": 4.0539
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17396,
      "name": "Abarkuh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 31.1289,
        "lng": 53.2822
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 17367,
      "name": "Oroshaza",
      "names": {
        "de": "Orosháza"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.5678,
        "lng": 20.6428
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 17281,
      "name": "Veendam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.1,
        "lng": 6.8667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17296,
      "name": "Zakopane",
      "names": {
        "zh": "扎科帕内"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.2994,
        "lng": 19.9519
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 17335,
      "name": "Supia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4506,
        "lng": -75.6514
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17342,
      "name": "Nunspeet",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3333,
        "lng": 5.7833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17377,
      "name": "Tongyangdao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.7676,
        "lng": 109.9711
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 17388,
      "name": "Ciudad Bolivar",
      "names": {
        "es": "Ciudad Bolívar",
        "zh": "Ciudad玻利亚尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8494,
        "lng": -76.0203
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17299,
      "name": "Sola",
      "names": {
        "zh": "索拉",
        "ja": "クサネム",
        "th": "รัชทายาท"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.88,
        "lng": 5.6286
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 6917,
      "name": "Los Reyes de Juarez",
      "names": {
        "es": "Los Reyes de Juárez",
        "ja": "ロスレイレスデュレズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9267,
        "lng": -97.7983
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 16911,
      "name": "Toqsu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 41.5417,
        "lng": 82.604
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 7082,
      "name": "Arar",
      "names": {
        "zh": "阿拉尔"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 30.9833,
        "lng": 41.0167
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 7086,
      "name": "Al Ghayzah",
      "names": {
        "de": "Alghayzah",
        "ja": "アル・ガイツァ",
        "th": "อัล Ghayzah"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 16.2394,
        "lng": 52.1638
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 6558,
      "name": "Abasolo",
      "names": {
        "zh": "阿瓦索洛"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4511,
        "lng": -101.5289
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6867,
      "name": "Tuxpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5661,
        "lng": -100.4625
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17056,
      "name": "Gorlice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 49.6556,
        "lng": 21.1604
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 17439,
      "name": "Bakhchysarai",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 44.7528,
        "lng": 33.8608
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 17428,
      "name": "Zempoala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9167,
        "lng": -98.6667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7079,
      "name": "Shtime",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.4333,
        "lng": 21.0333
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 6862,
      "name": "Castanos",
      "names": {
        "es": "Castaños",
        "fr": "Castaños"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.7833,
        "lng": -101.4167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17435,
      "name": "Cueramaro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6258,
        "lng": -101.6739
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17307,
      "name": "Arnstadt",
      "names": {
        "zh": "阿恩施塔特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8342,
        "lng": 10.9464
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17036,
      "name": "Bilgoraj",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.55,
        "lng": 22.7333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6926,
      "name": "Heemstede",
      "names": {
        "zh": "海姆斯泰德"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3528,
        "lng": 4.62
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17451,
      "name": "Tire",
      "names": {
        "es": "Neumáticos",
        "fr": "Pneus",
        "zh": "轮胎",
        "de": "Reifen",
        "it": "Pneumatico",
        "id": "Ban",
        "ja": "タイヤ",
        "th": "ยาง",
        "pt": "Pneu"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.0833,
        "lng": 27.7333
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 17468,
      "name": "Enna",
      "names": {
        "zh": "恩纳",
        "ja": "エンナ",
        "th": "เอนนา"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 37.5667,
        "lng": 14.2667
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 17480,
      "name": "Ixtapa",
      "names": {
        "zh": "伊斯塔帕",
        "ja": "イスタパ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8,
        "lng": -92.9
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17460,
      "name": "Garmisch-Partenkirchen",
      "names": {
        "zh": "加米施 - 帕腾基兴",
        "ja": "ガルミッシュ-partenkirchen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.5,
        "lng": 11.0833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17135,
      "name": "Klodzko",
      "names": {
        "th": "คลอดซ์โก"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.4378,
        "lng": 16.6528
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 17391,
      "name": "Corlu",
      "names": {
        "zh": "乔尔卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1597,
        "lng": 27.8028
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 17507,
      "name": "Tauramena",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0167,
        "lng": -72.75
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17463,
      "name": "Ixchiguan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1642,
        "lng": -91.9333
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 17461,
      "name": "Sibinal",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1333,
        "lng": -92.05
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 17508,
      "name": "Vilyeyka",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.4833,
        "lng": 26.9167
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 17486,
      "name": "San Juan de Uraba",
      "names": {
        "zh": "圣胡安de乌拉巴",
        "ja": "サンファンデウルバ",
        "th": "ซานฮวนเดอปุ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.7611,
        "lng": -76.5286
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17503,
      "name": "Lier",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.8675,
        "lng": 10.2142
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 6986,
      "name": "Leiderdorp",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1617,
        "lng": 4.5283
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17436,
      "name": "Tall Rif`at",
      "names": {
        "es": "Rif tall`at",
        "fr": "Grand rif`at",
        "zh": "高大的rif`at",
        "it": "Alto rif`",
        "id": "Rif`at tinggi",
        "ja": "背の高いrif`at",
        "th": "rif`at สูง",
        "pt": "Alto rif`"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.4733,
        "lng": 37.0972
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 17524,
      "name": "Szentes",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6519,
        "lng": 20.2572
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 6540,
      "name": "Villagran",
      "names": {
        "pt": "Villagrán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.517,
        "lng": -100.983
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17517,
      "name": "Mayskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.6333,
        "lng": 44.0667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 17434,
      "name": "Klosterneuburg",
      "names": {
        "zh": "克洛斯特新堡"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.3042,
        "lng": 16.3167
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 17555,
      "name": "Amatepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.65,
        "lng": -100.15
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17478,
      "name": "Aalten",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.925,
        "lng": 6.5808
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17564,
      "name": "Pueblo Bello",
      "names": {
        "zh": "普埃布洛贝洛"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4164,
        "lng": -73.5867
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17573,
      "name": "Marcos Juarez",
      "names": {
        "es": "Marcos Juárez",
        "ja": "マルコスフアレス",
        "th": "มาร์กอส Juarez"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.7,
        "lng": -62.1
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7105,
      "name": "Catio",
      "names": {
        "zh": "钙钛矿"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 11.2833,
        "lng": -15.25
      },
      "country": "Guinea-Bissau",
      "importance": 1
    },
    {
      "id": 17490,
      "name": "Estarreja",
      "names": {
        "zh": "埃斯塔雷雅"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.75,
        "lng": -8.5667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 7076,
      "name": "Rize",
      "names": {
        "zh": "里泽"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0167,
        "lng": 40.5167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 7100,
      "name": "Sensuntepeque",
      "names": {
        "zh": "森孙特佩克"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.8667,
        "lng": -88.6333
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 17164,
      "name": "Rapperswil-Jona",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 47.2286,
        "lng": 8.8317
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 16921,
      "name": "Nava",
      "names": {
        "zh": "纳瓦",
        "th": "นว"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.4214,
        "lng": -100.7675
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7103,
      "name": "Siliana",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.0819,
        "lng": 9.3747
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 17568,
      "name": "Zuitou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.0622,
        "lng": 107.3127
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 17522,
      "name": "Werkendam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8097,
        "lng": 4.8928
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17340,
      "name": "Rambouillet",
      "names": {
        "zh": "朗布依埃",
        "ja": "ランブイエ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6444,
        "lng": 1.8308
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 17553,
      "name": "Sabanilla",
      "names": {
        "zh": "萨瓦尼亚"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2833,
        "lng": -92.55
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7058,
      "name": "Lobatse",
      "names": {
        "zh": "洛巴策"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -25.2167,
        "lng": 25.6667
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 17520,
      "name": "Ermelo",
      "names": {
        "zh": "埃尔默洛"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3,
        "lng": 5.6331
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17557,
      "name": "Ruzomberok",
      "names": {
        "fr": "Ružomberok",
        "de": "Ružomberok"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0786,
        "lng": 19.3083
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 17221,
      "name": "Chios",
      "names": {
        "zh": "希俄斯",
        "ja": "キオス",
        "th": "ชิโอ"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.3725,
        "lng": 26.1375
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 7030,
      "name": "Rezekne",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.5067,
        "lng": 27.3308
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 17623,
      "name": "Majagua",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 21.9244,
        "lng": -78.9906
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 17474,
      "name": "Bergerac",
      "names": {
        "zh": "贝格拉克",
        "ja": "ベルジュラック"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.85,
        "lng": 0.48
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 17636,
      "name": "Bafoulabe",
      "names": {},
      "countryCode": "ML",
      "latLng": {
        "lat": 13.8064,
        "lng": -10.8322
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 17551,
      "name": "Geldermalsen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8833,
        "lng": 5.2833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7118,
      "name": "Nola",
      "names": {
        "zh": "诺拉"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 3.5337,
        "lng": 16.0666
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 17603,
      "name": "Khanabad",
      "names": {
        "zh": "汗阿巴德"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 36.6831,
        "lng": 69.1636
      },
      "country": "Afghanistan",
      "importance": 2
    },
    {
      "id": 17638,
      "name": "Fortul",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.7931,
        "lng": -71.7714
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17613,
      "name": "Iglesias",
      "names": {
        "zh": "伊格莱西亚斯",
        "ja": "イグレシアス"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.3103,
        "lng": 8.5372
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 17647,
      "name": "Qarah Zia\u0027 od Din",
      "names": {
        "es": "Qarah Zia \u0027od DIN",
        "fr": "Qarah Zia \u0027Od Din",
        "zh": "Qarah Zia\u0027od Din",
        "de": "QARAH ZIA \u0027OD DIN",
        "it": "Qarah Zia \u0027Da Din",
        "id": "Qarah Zia \u0027Od Din",
        "ja": "カラーズイアの喧騒",
        "th": "Qarah Zia \u0027OD DIN",
        "pt": "Qarah Zia \u0027OD DIN"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 38.8914,
        "lng": 45.0256
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 7069,
      "name": "Batman",
      "names": {
        "zh": "蝙蝠侠",
        "it": "Uomo Pipistrello",
        "ja": "バットマン",
        "th": "แบทแมน",
        "pt": "Homem Morcego"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8833,
        "lng": 41.1333
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 17664,
      "name": "Morales",
      "names": {
        "zh": "莫拉莱斯",
        "ja": "モラレス",
        "th": "โมราเลส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.7603,
        "lng": -76.6339
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17651,
      "name": "Los Cordobas",
      "names": {
        "fr": "Los Cordoues",
        "ja": "ロスコルドバス",
        "pt": "Los Cordovas"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.8953,
        "lng": -76.3547
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17684,
      "name": "Kiruna",
      "names": {
        "zh": "基律纳",
        "ja": "キルナ"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 67.8494,
        "lng": 20.2544
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 17632,
      "name": "Lamego",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.0833,
        "lng": -7.8667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 17691,
      "name": "Betong",
      "names": {
        "zh": "勿洞",
        "th": "เบตง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 5.7731,
        "lng": 101.0725
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 6845,
      "name": "Champoton",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.35,
        "lng": -90.7167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17620,
      "name": "Brzozow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 49.6953,
        "lng": 22.0194
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 17578,
      "name": "Saumur",
      "names": {
        "zh": "索米尔",
        "ja": "ソミュール"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.26,
        "lng": -0.0769
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 17591,
      "name": "Puerto Colombia",
      "names": {
        "fr": "Puerto Colombie",
        "zh": "波多哥伦比亚",
        "de": "Puerto Kolumbien",
        "id": "Puerto Kolombia",
        "ja": "プエルトコロンビア",
        "th": "เปอร์โตโคลัมเบีย",
        "pt": "Puerto Colômbia"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.9922,
        "lng": -74.9528
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17718,
      "name": "San Benito Abad",
      "names": {
        "zh": "圣贝尼托阿巴德",
        "id": "Abad san benito",
        "ja": "サンベニートアバド",
        "th": "ซานเบนิโตะบ้า"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.9272,
        "lng": -75.0264
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7132,
      "name": "Voinjama",
      "names": {
        "zh": "沃因贾马"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 8.4167,
        "lng": -9.75
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 17305,
      "name": "Sens",
      "names": {
        "zh": "意义",
        "th": "ซองส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1975,
        "lng": 3.2877
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 17686,
      "name": "Kiskunhalas",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4319,
        "lng": 19.4883
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 17693,
      "name": "Lanquin",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.5758,
        "lng": -89.9811
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 17726,
      "name": "San Alberto",
      "names": {
        "zh": "圣阿尔贝托",
        "ja": "サンアルベルト",
        "th": "ซานอัลเบอร์โต"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.7592,
        "lng": -73.3931
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17702,
      "name": "Jaszbereny",
      "names": {
        "zh": "亚斯贝雷尼"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.5,
        "lng": 19.9167
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 17738,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -40.2952,
        "lng": -73.0822
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 17593,
      "name": "Lauf",
      "names": {
        "zh": "劳夫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.5103,
        "lng": 11.2772
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17734,
      "name": "San Antonio del Sur",
      "names": {
        "ja": "サンアントニオデルシュル",
        "th": "ซานอันโตนิโอเดลซูร์"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 20.0569,
        "lng": -74.8078
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 7139,
      "name": "Utena",
      "names": {
        "zh": "乌田纳"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.5,
        "lng": 25.6028
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 806,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "SC",
      "latLng": {
        "lat": -4.6236,
        "lng": 55.4544
      },
      "country": "Seychelles",
      "importance": 0
    },
    {
      "id": 6863,
      "name": "Wete",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.0567,
        "lng": 39.7281
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 7135,
      "name": "Nakhon Phanom",
      "names": {
        "ja": "ナコーン・ファノム",
        "th": "นครพนม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4069,
        "lng": 104.7808
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 7143,
      "name": "Jonava",
      "names": {
        "zh": "约纳瓦"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.0722,
        "lng": 24.2806
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 7146,
      "name": "Dongola",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 19.1666,
        "lng": 30.4833
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 7128,
      "name": "Blenheim",
      "names": {
        "zh": "布莱尼姆",
        "ja": "ブレナム",
        "th": "เบลนไฮม์"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.5167,
        "lng": 173.95
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 17521,
      "name": "Vught",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.65,
        "lng": 5.3
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6941,
      "name": "Tralee",
      "names": {
        "ja": "トラリー",
        "th": "ทราลี"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.2675,
        "lng": -9.6962
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 17773,
      "name": "Balboa",
      "names": {
        "zh": "巴波亚",
        "ja": "バルボア"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.0406,
        "lng": -77.2164
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17609,
      "name": "Jaltenco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7511,
        "lng": -99.0931
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17803,
      "name": "Pensilvania",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.384,
        "lng": -75.1612
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17789,
      "name": "Ondokuzmayis",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4944,
        "lng": 36.0789
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 17196,
      "name": "Tehuipango",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5167,
        "lng": -97.05
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7149,
      "name": "Ulaangom",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 49.9754,
        "lng": 92.0661
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 17788,
      "name": "Sangerhausen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.4667,
        "lng": 11.3
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17694,
      "name": "Az Zabadani",
      "names": {
        "pt": "Zabadani az"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.7247,
        "lng": 36.1003
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 17714,
      "name": "As Sanamayn",
      "names": {
        "es": "Como sanamayn",
        "fr": "Comme Sanamayn",
        "zh": "作为Sanamayn",
        "de": "Als Sanamayn",
        "it": "Come Sanamayn",
        "id": "Sebagai Sanamayn",
        "ja": "サナマインとして",
        "th": "ในฐานะ Sanamayn",
        "pt": "Como Sanamayn"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.0711,
        "lng": 36.1842
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 17850,
      "name": "Semirom",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 31.4142,
        "lng": 51.5694
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 7126,
      "name": "Bayanhongor",
      "names": {
        "zh": "巴彦洪戈尔"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 46.1944,
        "lng": 100.7181
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 7145,
      "name": "Mendi",
      "names": {
        "zh": "门迪"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -6.1478,
        "lng": 143.6572
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 17861,
      "name": "Paz de Ariporo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8811,
        "lng": -71.8917
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6951,
      "name": "Colonia del Sacramento",
      "names": {
        "ja": "コロニアデルサクラメント"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.4714,
        "lng": -57.8442
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 17842,
      "name": "Buesaco",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.3847,
        "lng": -77.1564
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17712,
      "name": "Wassenaar",
      "names": {
        "zh": "瓦森纳"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1453,
        "lng": 4.4006
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17843,
      "name": "Montemor-o-Velho",
      "names": {
        "zh": "montemor-o-旧",
        "th": "montemor-o-เวลโย"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.1667,
        "lng": -8.6833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 17876,
      "name": "Tavira",
      "names": {
        "zh": "塔维拉",
        "ja": "タヴィラ",
        "th": "ตาวีรา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1309,
        "lng": -7.6506
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 7144,
      "name": "Suphan Buri",
      "names": {
        "ja": "スーパーブリ",
        "th": "สุพรรณบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4675,
        "lng": 100.1169
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 17829,
      "name": "Kluczbork",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.9833,
        "lng": 18.2167
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 17806,
      "name": "Jarocin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.9667,
        "lng": 17.5
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 17772,
      "name": "Oisterwijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5833,
        "lng": 5.2
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17874,
      "name": "Khashuri",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.9975,
        "lng": 43.5986
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 17841,
      "name": "Perevalsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.4333,
        "lng": 38.8167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 6896,
      "name": "Tuxpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.8667,
        "lng": -105.45
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17904,
      "name": "Toktogul",
      "names": {
        "zh": "托克托古尔"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 41.8826,
        "lng": 72.9372
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 7044,
      "name": "Kathu",
      "names": {
        "th": "อำเภอกะทู้"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.9112,
        "lng": 98.3475
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 17898,
      "name": "Mahates",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.2322,
        "lng": -75.1911
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17915,
      "name": "Odemira",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 37.5833,
        "lng": -8.6333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 17614,
      "name": "Dongen",
      "names": {
        "zh": "东让"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6258,
        "lng": 4.9433
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7156,
      "name": "Rivas",
      "names": {
        "zh": "里瓦斯",
        "th": "วาส"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.4333,
        "lng": -85.8333
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 17588,
      "name": "Chuimatan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.7166,
        "lng": 102.8771
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 17909,
      "name": "Eidsvoll",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.3475,
        "lng": 11.2508
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 17863,
      "name": "Swiecie",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.4167,
        "lng": 18.4333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7168,
      "name": "Hovd",
      "names": {
        "zh": "科布多",
        "ja": "ホブド",
        "th": "ฮอฟด์"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 48.0167,
        "lng": 91.5667
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 17747,
      "name": "Heppenheim",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6415,
        "lng": 8.645
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7164,
      "name": "Bajina Basta",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.9731,
        "lng": 19.5597
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 17938,
      "name": "Diapaga",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.0667,
        "lng": 1.7833
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 17924,
      "name": "Puerto Piritu",
      "names": {
        "ja": "プエルトピリツ"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.0667,
        "lng": -65.05
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 17951,
      "name": "Istmina",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.1633,
        "lng": -76.6867
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17969,
      "name": "Dolores",
      "names": {
        "zh": "多洛雷斯",
        "ja": "ドロレス",
        "th": "โดโลเรส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -36.3132,
        "lng": -57.6792
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 17968,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.3194,
        "lng": -74.5706
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17883,
      "name": "Pfaffenhofen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.5333,
        "lng": 11.5167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17885,
      "name": "Kulmbach",
      "names": {
        "zh": "库尔姆巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1,
        "lng": 11.4333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17860,
      "name": "Kothen",
      "names": {
        "de": "Köthen",
        "it": "Köthen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7511,
        "lng": 11.9779
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17988,
      "name": "San Martin",
      "names": {
        "es": "San Martín",
        "zh": "圣马丁",
        "ja": "サンマーティン",
        "th": "ซานมาร์ติน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.6969,
        "lng": -73.6986
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17851,
      "name": "Colotenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.4054,
        "lng": -91.7156
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 17784,
      "name": "Beuningen",
      "names": {
        "zh": "布宁根"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8667,
        "lng": 5.7667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17957,
      "name": "Marmara Ereglisi",
      "names": {
        "zh": "马尔马拉ereglisi",
        "ja": "マルマラエレグリジ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9697,
        "lng": 27.9553
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 17913,
      "name": "Sankt Wendel",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4667,
        "lng": 7.1667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7171,
      "name": "Amnat Charoen",
      "names": {
        "th": "อำนาจเจริญ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.8758,
        "lng": 104.6223
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 7170,
      "name": "Calilabad",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.2042,
        "lng": 48.4958
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 17963,
      "name": "San Juan Ixcoy",
      "names": {
        "zh": "圣胡安IXCOY",
        "ja": "サンファンIXCOY",
        "th": "ซานฮวน ixcoy"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.6,
        "lng": -91.45
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 17398,
      "name": "Montbeliard",
      "names": {
        "fr": "Montbéliard",
        "zh": "蒙贝利亚尔",
        "de": "Montbéliard",
        "pt": "Montbéliard"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.51,
        "lng": 6.8
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18021,
      "name": "Nechi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.0958,
        "lng": -74.775
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18023,
      "name": "Ceylanpinar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.8461,
        "lng": 40.0489
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 17964,
      "name": "Tholen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5847,
        "lng": 4.1211
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7043,
      "name": "Alencon",
      "names": {
        "zh": "阿朗松"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.4306,
        "lng": 0.0931
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18014,
      "name": "Yany Kapu",
      "names": {
        "ja": "yach kapu"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 45.9675,
        "lng": 33.8003
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7068,
      "name": "Koper",
      "names": {
        "zh": "科佩尔港"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5475,
        "lng": 13.7307
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 17985,
      "name": "Lourinha",
      "names": {
        "zh": "洛里尼扬",
        "pt": "Lourinhã"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.25,
        "lng": -9.3167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6675,
      "name": "Nea Filadelfeia",
      "names": {
        "de": "Nea Filadeleia",
        "pt": "Nea Filadelia"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.035,
        "lng": 23.7381
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 17847,
      "name": "Helmstedt",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2281,
        "lng": 11.0106
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17917,
      "name": "Vierzon",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 47.2225,
        "lng": 2.0694
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18022,
      "name": "Huejotzingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1594,
        "lng": -98.4073
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7186,
      "name": "Zwedru",
      "names": {
        "zh": "绥德鲁"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 6.0704,
        "lng": -8.13
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 18054,
      "name": "Haisyn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.8094,
        "lng": 29.3906
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 18008,
      "name": "Santa Lucia La Reforma",
      "names": {
        "es": "Santa Lucía La Reforma",
        "zh": "圣卢西亚洛杉矶改革机",
        "ja": "サンタルシアラLe Reforma"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1333,
        "lng": -91.2333
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 18018,
      "name": "Luleburgaz",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4056,
        "lng": 27.3569
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 18028,
      "name": "Batabano",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7167,
        "lng": -82.2833
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 7181,
      "name": "Kanchanaburi",
      "names": {
        "zh": "北碧府",
        "ja": "カンチャナブリー",
        "th": "กาญจนบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.0194,
        "lng": 99.5311
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 18004,
      "name": "San Lucas Toliman",
      "names": {
        "zh": "圣卢卡斯托里曼",
        "ja": "サンルーカストリマー",
        "th": "ซานลูคัสโทลิมาน"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6333,
        "lng": -91.1333
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 18076,
      "name": "Yolombo",
      "names": {
        "zh": "约隆博"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.5978,
        "lng": -75.0122
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7075,
      "name": "Velenje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3667,
        "lng": 15.1167
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 7174,
      "name": "El Tarf",
      "names": {
        "zh": "eltf"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.7669,
        "lng": 8.3136
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 18109,
      "name": "Jalpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2167,
        "lng": -99.4725
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18124,
      "name": "Solano",
      "names": {
        "zh": "索拉诺"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.6983,
        "lng": -75.2539
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 17792,
      "name": "Aurillac",
      "names": {
        "ja": "オーリヤック"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.9261,
        "lng": 2.4406
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18110,
      "name": "Tougue",
      "names": {
        "zh": "图盖"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 11.44,
        "lng": -11.67
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 17793,
      "name": "Topol\u0027cany",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.55,
        "lng": 18.1833
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 7196,
      "name": "Siirt",
      "names": {
        "zh": "锡尔特"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8417,
        "lng": 41.9458
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 7074,
      "name": "Voorschoten",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.125,
        "lng": 4.445
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 6937,
      "name": "Treinta y Tres",
      "names": {},
      "countryCode": "UY",
      "latLng": {
        "lat": -33.2308,
        "lng": -54.3822
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 18077,
      "name": "Siofok",
      "names": {
        "zh": "希欧福克",
        "th": "ซิโลฟอก"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9,
        "lng": 18.05
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 18151,
      "name": "Vistahermosa",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.1239,
        "lng": -73.7514
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18154,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.6967,
        "lng": -78.2453
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6978,
      "name": "Rocha",
      "names": {
        "zh": "罗沙"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.4833,
        "lng": -54.35
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 18149,
      "name": "Limonar",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9561,
        "lng": -81.4086
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 18097,
      "name": "Vynohradiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.1397,
        "lng": 23.0331
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 17949,
      "name": "Salamina",
      "names": {
        "zh": "萨拉米纳"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9649,
        "lng": 23.4993
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 17928,
      "name": "Duiven",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9472,
        "lng": 6.0211
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 17813,
      "name": "Schleswig",
      "names": {
        "zh": "石勒苏益格",
        "ja": "シュレスヴィヒ",
        "th": "สวิก"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.5153,
        "lng": 9.5697
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18155,
      "name": "Samtredia",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.1625,
        "lng": 42.3417
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 7210,
      "name": "Lambarene",
      "names": {
        "zh": "兰巴雷内"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -0.6883,
        "lng": 10.2319
      },
      "country": "Gabon",
      "importance": 1
    },
    {
      "id": 18040,
      "name": "Gross-Gerau",
      "names": {
        "es": "Bruto-gerau",
        "fr": "Brut-gerau",
        "zh": "总-gerau",
        "de": "Groß-Gerau",
        "ja": "総-gerau",
        "th": "รวม-gerau"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.9214,
        "lng": 8.4818
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 17989,
      "name": "Etampes",
      "names": {
        "zh": "埃唐普"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.4343,
        "lng": 2.1615
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18087,
      "name": "Rottweil",
      "names": {
        "zh": "罗特韦尔"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.1681,
        "lng": 8.6247
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18161,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.6053,
        "lng": -77.1297
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18178,
      "name": "Aksehir",
      "names": {
        "zh": "阿克谢希尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3592,
        "lng": 31.4164
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 18188,
      "name": "Choconta",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.1467,
        "lng": -73.6825
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18168,
      "name": "Albergaria-a-Velha",
      "names": {
        "zh": "阿尔贝加里亚-一个-旧",
        "ja": "albergaria・ア・velha",
        "th": "albergaria เป็น-velha"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6936,
        "lng": -8.4806
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 6946,
      "name": "Miahuatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5667,
        "lng": -97.4333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7187,
      "name": "Tipasa",
      "names": {
        "zh": "提帕萨"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.5942,
        "lng": 2.443
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 18150,
      "name": "Sandona",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.2847,
        "lng": -77.4711
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18232,
      "name": "Sastamala",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.3417,
        "lng": 22.9083
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 18133,
      "name": "Maaseik",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 51.1019,
        "lng": 5.7856
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 17869,
      "name": "San Lucas Sacatepequez",
      "names": {
        "fr": "San Lucas Sacatepequezez",
        "ja": "サンルーカス神聖ペケツケ",
        "th": "ซานลูคัส SCATATEPEQUEZ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6095,
        "lng": -90.6568
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 18245,
      "name": "Morozovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 48.35,
        "lng": 41.8333
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 18164,
      "name": "Saydnaya",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.6958,
        "lng": 36.3775
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 7064,
      "name": "Bethune",
      "names": {
        "zh": "白求恩"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.5303,
        "lng": 2.6408
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18258,
      "name": "Raahe",
      "names": {
        "zh": "拉赫"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 64.6847,
        "lng": 24.4792
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 18066,
      "name": "Saintes",
      "names": {
        "zh": "桑特",
        "th": "แซงต์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.7464,
        "lng": -0.6333
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7222,
      "name": "Wewak",
      "names": {
        "zh": "韦瓦克"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -3.55,
        "lng": 143.6333
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 18257,
      "name": "Santa Maria Tonameca",
      "names": {
        "ja": "サンタマリア砺波市"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.7458,
        "lng": -96.5472
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18291,
      "name": "Whitehorse",
      "names": {
        "zh": "白马",
        "ja": "ホワイトホース",
        "th": "ไวท์ฮอร์ส"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 60.7029,
        "lng": -135.0691
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 7226,
      "name": "Sirnak",
      "names": {
        "zh": "舍尔纳克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5164,
        "lng": 42.4611
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 18249,
      "name": "Steenbergen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5833,
        "lng": 4.25
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18278,
      "name": "Wolfsberg",
      "names": {
        "zh": "沃尔夫斯贝格"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.8419,
        "lng": 14.8408
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 17649,
      "name": "Sliedrecht",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8222,
        "lng": 4.7744
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 807,
      "name": "Kingstown",
      "names": {
        "zh": "金斯敦",
        "ja": "キングスタウン",
        "th": "คิงส์ทาวน์"
      },
      "countryCode": "VC",
      "latLng": {
        "lat": 13.1667,
        "lng": -61.2333
      },
      "country": "Saint Vincent And The Grenadines",
      "importance": 0
    },
    {
      "id": 18174,
      "name": "La Blanca",
      "names": {
        "ja": "ラブランカ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5791,
        "lng": -92.1414
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 18243,
      "name": "Shaqlawah",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.3964,
        "lng": 44.3436
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 7218,
      "name": "Mohale\u0027s Hoek",
      "names": {
        "es": "Hoek de Mohale",
        "fr": "Hoek de Mohale",
        "zh": "Mohale的Hoek",
        "it": "Hoek di Mohale",
        "id": "Hoek Mohale",
        "ja": "モハレの鍬",
        "th": "hoek ของ Mohale",
        "pt": "Heek de Mohale"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -30.159,
        "lng": 27.48
      },
      "country": "Lesotho",
      "importance": 1
    },
    {
      "id": 18312,
      "name": "Sultepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8667,
        "lng": -99.95
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17922,
      "name": "Stein",
      "names": {
        "zh": "斯坦",
        "ja": "シュタイン",
        "th": "สไตน์",
        "pt": "Caneca de cerveja"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.9679,
        "lng": 5.7652
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18095,
      "name": "Walcz",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.2667,
        "lng": 16.4667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7198,
      "name": "Visby",
      "names": {
        "zh": "维斯比"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.629,
        "lng": 18.3071
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 7220,
      "name": "Vukovar",
      "names": {
        "zh": "武科瓦尔"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.35,
        "lng": 19.0033
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 18169,
      "name": "Cuijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7269,
        "lng": 5.8794
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7236,
      "name": "Portalegre",
      "names": {
        "zh": "波塔莱格雷"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.3167,
        "lng": -7.4167
      },
      "country": "Portugal",
      "importance": 1
    },
    {
      "id": 18060,
      "name": "Pinillos",
      "names": {
        "zh": "皮尼略斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.915,
        "lng": -74.4619
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 6989,
      "name": "Hunucma",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0153,
        "lng": -89.8744
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17855,
      "name": "Elefsina",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0414,
        "lng": 23.5453
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 18099,
      "name": "Kazincbarcika",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.2531,
        "lng": 20.6456
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 18229,
      "name": "Pezinok",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.2667,
        "lng": 17.2667
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 6876,
      "name": "Ixhuatlancillo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9,
        "lng": -97.15
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18251,
      "name": "Socorro",
      "names": {
        "zh": "索科罗"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4603,
        "lng": -73.27
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7200,
      "name": "Puyo",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -1.483,
        "lng": -77.987
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 18153,
      "name": "Sabanagrande",
      "names": {
        "zh": "萨瓦纳格兰德"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7903,
        "lng": -74.7556
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18373,
      "name": "Pavlovsk",
      "names": {
        "zh": "巴甫洛夫斯克"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 50.45,
        "lng": 40.0667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 6999,
      "name": "Vichy",
      "names": {
        "zh": "维希",
        "ja": "ヴィシー",
        "th": "วิชี"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.1278,
        "lng": 3.4267
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7180,
      "name": "Kerkyra",
      "names": {
        "es": "Corfú"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 39.6239,
        "lng": 19.9214
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 18387,
      "name": "Colon",
      "names": {
        "fr": "Côlon",
        "zh": "结肠",
        "id": "Usus besar",
        "ja": "コロン",
        "th": "ลำไส้ใหญ่",
        "pt": "Cólon"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.2241,
        "lng": -58.1419
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 18230,
      "name": "Muret",
      "names": {
        "zh": "米雷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.4611,
        "lng": 1.3267
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18333,
      "name": "Madaoua",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 14.0762,
        "lng": 5.9586
      },
      "country": "Niger",
      "importance": 2
    },
    {
      "id": 18408,
      "name": "Lincoln",
      "names": {
        "zh": "林肯",
        "ja": "リンカーン",
        "th": "ลิงคอล์น"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.85,
        "lng": -61.5167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7179,
      "name": "Nar\u0027yan-Mar",
      "names": {
        "zh": "nar\u0027yan-3月",
        "ja": "nar\u0027yan-03月",
        "th": "nar\u0027yan มี.ค."
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 67.6378,
        "lng": 53.0067
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 18137,
      "name": "Baarn",
      "names": {
        "zh": "巴伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2125,
        "lng": 5.2861
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7123,
      "name": "Farafangana",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -22.8166,
        "lng": 47.8332
      },
      "country": "Madagascar",
      "importance": 2
    },
    {
      "id": 18424,
      "name": "Ziketan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5885,
        "lng": 99.9866
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 6895,
      "name": "Jamay",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2944,
        "lng": -102.7097
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18244,
      "name": "Bad Oldesloe",
      "names": {
        "es": "Malo oledesloe",
        "fr": "Mauvais ansLoe",
        "zh": "坏老人",
        "id": "Oldesloe buruk",
        "ja": "悪い昔ながら",
        "pt": "Mau oldesloe"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.8117,
        "lng": 10.3742
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18421,
      "name": "Villanueva",
      "names": {
        "zh": "维拉纽瓦"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6087,
        "lng": -72.9288
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18409,
      "name": "Harstad",
      "names": {
        "zh": "哈尔斯塔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 68.7988,
        "lng": 16.5393
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 6719,
      "name": "Zumpango del Rio",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.65,
        "lng": -99.5
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18405,
      "name": "Delfzijl",
      "names": {
        "zh": "代尔夫宰尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.3333,
        "lng": 6.9167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18207,
      "name": "Laon",
      "names": {
        "zh": "拉昂"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.5639,
        "lng": 3.6244
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18433,
      "name": "Algeciras",
      "names": {
        "zh": "阿尔赫西拉斯",
        "ja": "アルヘシラス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.5219,
        "lng": -75.3144
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18297,
      "name": "Ma`bar",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 14.7939,
        "lng": 44.2936
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 18428,
      "name": "Seia",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.422,
        "lng": -7.7024
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 18350,
      "name": "San Martin Sacatepequez",
      "names": {
        "fr": "San Martin Sacatepequezez",
        "zh": "圣马丁萨豆豆",
        "id": "San Martin Sacatepequezz"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8246,
        "lng": -91.6425
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 18390,
      "name": "Santa Rosa",
      "names": {
        "zh": "圣罗莎",
        "ja": "サンタローザ",
        "th": "ซานตาโรซ่า"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4456,
        "lng": -75.3686
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18337,
      "name": "Olpe",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0289,
        "lng": 7.8514
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18445,
      "name": "Monte Cristi",
      "names": {
        "zh": "蒙特克里斯蒂",
        "ja": "モンテクリスティ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 19.85,
        "lng": -71.65
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 7231,
      "name": "Kaga Bandoro",
      "names": {
        "ja": "カガバンドロ"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 7.0006,
        "lng": 19.1808
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 18377,
      "name": "Leoben",
      "names": {
        "zh": "莱奥本"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.3817,
        "lng": 15.0972
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 7258,
      "name": "Rawson",
      "names": {
        "zh": "罗森"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -43.3,
        "lng": -65.1
      },
      "country": "Argentina",
      "importance": 1
    },
    {
      "id": 18290,
      "name": "Krems an der Donau",
      "names": {
        "ja": "クレムan der Donau"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.4167,
        "lng": 15.6167
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 18463,
      "name": "Shangtianba",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.039,
        "lng": 103.8584
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 18356,
      "name": "Trebisov",
      "names": {
        "it": "Trebišov"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.6333,
        "lng": 21.7167
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 18452,
      "name": "Calamar",
      "names": {
        "zh": "卡拉马尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.25,
        "lng": -74.9158
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7114,
      "name": "Carlos A. Carrillo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3667,
        "lng": -95.75
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18461,
      "name": "Takhli",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2667,
        "lng": 100.35
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 18514,
      "name": "La Huerta",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4833,
        "lng": -104.65
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18517,
      "name": "El Retorno",
      "names": {
        "fr": "El revenir",
        "it": "El Retorner",
        "ja": "エルレトルノ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.3306,
        "lng": -72.6278
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18509,
      "name": "Zernograd",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.85,
        "lng": 40.3
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 18510,
      "name": "Valdivia",
      "names": {
        "zh": "瓦尔迪维亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.1636,
        "lng": -75.4392
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7081,
      "name": "Ciudad Altamirano",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3583,
        "lng": -100.6667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 17990,
      "name": "Wagrowiec",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.8,
        "lng": 17.2
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6954,
      "name": "Lamu",
      "names": {
        "zh": "拉穆"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -2.262,
        "lng": 40.9197
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 18067,
      "name": "Libourne",
      "names": {
        "ja": "リブルヌ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.92,
        "lng": -0.24
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18538,
      "name": "Mahdishahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.7108,
        "lng": 53.3539
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 18375,
      "name": "Cabrican",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0768,
        "lng": -91.65
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 18491,
      "name": "Cartaxo",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.15,
        "lng": -8.7833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 18558,
      "name": "Tocopilla",
      "names": {
        "zh": "托科皮利亚"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -22.0941,
        "lng": -70.201
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 18508,
      "name": "Nittedal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.0731,
        "lng": 10.8722
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 18435,
      "name": "Tamahu",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.3069,
        "lng": -90.2342
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 7021,
      "name": "Oegstgeest",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1667,
        "lng": 4.4667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7191,
      "name": "Fray Bentos",
      "names": {
        "fr": "Bough Bentos",
        "it": "Bentos sfaccia",
        "id": "Bentos fray",
        "ja": "争いベントス",
        "pt": "BENTOS DE FRASOS"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -33.1333,
        "lng": -58.3
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 7224,
      "name": "Tbeng Meanchey",
      "names": {
        "es": "Tbeng meandchey",
        "zh": "tbeng vectchey",
        "de": "Tbeng bedeutete",
        "it": "Meanchey Tbeng",
        "id": "TBENG MeAlchey",
        "pt": "Tbeng meachey"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 13.8167,
        "lng": 104.9667
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 18547,
      "name": "Pyrgos",
      "names": {
        "zh": "皮尔戈斯"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.6667,
        "lng": 21.4333
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 6749,
      "name": "Birkirkara",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9,
        "lng": 14.4667
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 18600,
      "name": "Puerto Guzman",
      "names": {
        "zh": "波多国普国邦",
        "ja": "プエルトグッサン",
        "th": "เปอร์โตสุขสันต์"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.9636,
        "lng": -76.4086
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18495,
      "name": "Wierden",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.35,
        "lng": 6.5833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18572,
      "name": "Porto de Mos",
      "names": {
        "ja": "ポルトデモ",
        "th": "ปอร์โตเดอมอส"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.6,
        "lng": -8.8167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 18606,
      "name": "La Montanita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.4792,
        "lng": -75.4361
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7142,
      "name": "Rodez",
      "names": {
        "zh": "罗德兹"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.3506,
        "lng": 2.575
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18549,
      "name": "Xinxing",
      "names": {
        "zh": "新兴"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 47.1601,
        "lng": 123.8
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 18440,
      "name": "Cadca",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.4386,
        "lng": 18.7883
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 6892,
      "name": "Avellaneda",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -34.6625,
        "lng": -58.3678
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 18117,
      "name": "Oud-Beijerland",
      "names": {
        "zh": "旧拜耶兰",
        "ja": "ウード-beijerland",
        "th": "อู๊ด-beijerland"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.82,
        "lng": 4.42
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18414,
      "name": "Raisio",
      "names": {
        "zh": "赖西奥"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4861,
        "lng": 22.1694
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 18619,
      "name": "Saint-Laurent-du-Maroni",
      "names": {
        "zh": "圣-·罗兰-du-马罗尼",
        "ja": "サン・ローラン-du-maroni",
        "th": "เซนต์โลรองต์-du-maroni"
      },
      "countryCode": "GF",
      "latLng": {
        "lat": 5.4976,
        "lng": -54.0325
      },
      "country": "French Guiana",
      "importance": 2
    },
    {
      "id": 18618,
      "name": "Stjordal",
      "names": {
        "zh": "斯彻达尔",
        "de": "Stjørdal"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 63.475,
        "lng": 11.1708
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 18279,
      "name": "Saky",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.1336,
        "lng": 33.5772
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 18543,
      "name": "Haaksbergen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1547,
        "lng": 6.7419
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18612,
      "name": "Nueva Paz",
      "names": {
        "ja": "ヌエバPaz"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7667,
        "lng": -81.75
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 18557,
      "name": "Senftenberg",
      "names": {
        "zh": "森夫滕贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5167,
        "lng": 14.0167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7275,
      "name": "Carlow",
      "names": {
        "zh": "卡洛",
        "th": "โลว์"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.8306,
        "lng": -6.9317
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 7166,
      "name": "Ennis",
      "names": {
        "zh": "恩尼斯",
        "th": "เอนนิส"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.8463,
        "lng": -8.9807
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 7281,
      "name": "Fatick",
      "names": {
        "zh": "法蒂克"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.3167,
        "lng": -16.4167
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 6812,
      "name": "El Salto",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 23.7823,
        "lng": -105.3585
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7256,
      "name": "Suhbaatar",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 50.2364,
        "lng": 106.2064
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 18483,
      "name": "Meiningen",
      "names": {
        "zh": "迈宁根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.55,
        "lng": 10.4167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18531,
      "name": "Waldshut-Tiengen",
      "names": {
        "zh": "瓦尔茨胡特-tiengen"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.6231,
        "lng": 8.2144
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18613,
      "name": "Donmatias",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4858,
        "lng": -75.3953
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18674,
      "name": "Uchquduq Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 42.1567,
        "lng": 63.5556
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 18554,
      "name": "Putten",
      "names": {
        "zh": "普当"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2579,
        "lng": 5.608
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18262,
      "name": "Gyal",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3822,
        "lng": 19.2136
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 18314,
      "name": "Sandomierz",
      "names": {
        "zh": "桑多梅日"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.6833,
        "lng": 21.75
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7242,
      "name": "Novo Mesto",
      "names": {
        "zh": "诺莫梅斯托",
        "ja": "ノボメスト"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7981,
        "lng": 15.1628
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 18589,
      "name": "Rathenow",
      "names": {
        "zh": "拉特诺"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.6,
        "lng": 12.3333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18673,
      "name": "Krychaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.7194,
        "lng": 31.7139
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 7295,
      "name": "Agri",
      "names": {
        "fr": "Agro",
        "zh": "农业",
        "th": "เกษตร",
        "pt": "Agro"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.7225,
        "lng": 43.0544
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 7268,
      "name": "Lop Buri",
      "names": {
        "zh": "罗普布里",
        "it": "Lop buli",
        "ja": "ロップビリ",
        "th": "ลพบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.8,
        "lng": 100.6269
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 18681,
      "name": "Velingrad",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.0276,
        "lng": 23.9913
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 18292,
      "name": "Bialogard",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.007,
        "lng": 15.9875
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 18698,
      "name": "Rioblanco",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.5292,
        "lng": -75.6447
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7305,
      "name": "Al Jawf",
      "names": {
        "zh": "jawf",
        "it": "Al maia",
        "ja": "アルジャーフ"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 24.2167,
        "lng": 23.3
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 18621,
      "name": "Marsella",
      "names": {
        "zh": "马尔塞亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9367,
        "lng": -75.7392
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18683,
      "name": "Bergama",
      "names": {
        "zh": "贝尔加马"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.1228,
        "lng": 27.1783
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 18623,
      "name": "Edremit",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.5961,
        "lng": 27.0244
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 7301,
      "name": "Vrbas",
      "names": {
        "zh": "弗尔巴斯"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.5697,
        "lng": 19.6378
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 7302,
      "name": "Bontoc",
      "names": {
        "zh": "邦都"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.0872,
        "lng": 120.9756
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 18234,
      "name": "Frederikshavn",
      "names": {},
      "countryCode": "DK",
      "latLng": {
        "lat": 57.4337,
        "lng": 10.5333
      },
      "country": "Denmark",
      "importance": 2
    },
    {
      "id": 18620,
      "name": "Cogua",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0619,
        "lng": -73.9794
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7117,
      "name": "Koscian",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.0833,
        "lng": 16.65
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 17994,
      "name": "Santa Maria Colotepec",
      "names": {
        "zh": "圣玛丽亚·柯敦普克",
        "ja": "サンタマリアコロテペック"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.8833,
        "lng": -96.9167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18744,
      "name": "Kourou",
      "names": {
        "zh": "库鲁"
      },
      "countryCode": "GF",
      "latLng": {
        "lat": 5.16,
        "lng": -52.6499
      },
      "country": "French Guiana",
      "importance": 2
    },
    {
      "id": 7303,
      "name": "Atar",
      "names": {
        "zh": "阿塔尔"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 20.5167,
        "lng": -13.05
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 18615,
      "name": "Rimavska Sobota",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.3814,
        "lng": 20.02
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 7307,
      "name": "Kirklareli",
      "names": {
        "zh": "克尔克拉雷利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.7347,
        "lng": 27.2253
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 7259,
      "name": "Baalbek",
      "names": {
        "zh": "巴勒贝克"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 34.0061,
        "lng": 36.2086
      },
      "country": "Lebanon",
      "importance": 1
    },
    {
      "id": 18676,
      "name": "San Marcos",
      "names": {
        "zh": "圣马科斯",
        "ja": "サンマルコス",
        "th": "ซานมาร์กอส"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.9167,
        "lng": -86.2
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 18740,
      "name": "Zolochiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.8075,
        "lng": 24.9031
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7183,
      "name": "Svay Rieng",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 11.0833,
        "lng": 105.8
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 7315,
      "name": "Owando",
      "names": {
        "zh": "奥旺多"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -0.4833,
        "lng": 15.9
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 18237,
      "name": "Solin",
      "names": {
        "zh": "索林"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.5317,
        "lng": 16.4947
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 18769,
      "name": "El Zulia",
      "names": {
        "ja": "エルズリア"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.9325,
        "lng": -72.6025
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7024,
      "name": "Koulikoro",
      "names": {
        "zh": "库利科罗"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 12.8833,
        "lng": -7.55
      },
      "country": "Mali",
      "importance": 1
    },
    {
      "id": 18774,
      "name": "Hollola",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.9886,
        "lng": 25.5128
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 7317,
      "name": "Guaranda",
      "names": {},
      "countryCode": "EC",
      "latLng": {
        "lat": -1.6056,
        "lng": -79.0031
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 18770,
      "name": "Hajduszoboszlo",
      "names": {
        "es": "Hajdúszoboszló",
        "de": "Hajdúszoboszló"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.45,
        "lng": 21.3833
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 18822,
      "name": "Tecolutla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4797,
        "lng": -97.01
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7018,
      "name": "Dunleary",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 53.3,
        "lng": -6.14
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 18815,
      "name": "Mirandela",
      "names": {
        "zh": "米兰德拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.4833,
        "lng": -7.1833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 18824,
      "name": "Tepalcatepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1833,
        "lng": -102.85
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7290,
      "name": "Chanthaburi",
      "names": {
        "zh": "庄他武里",
        "th": "จันทบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.6086,
        "lng": 102.1039
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 18711,
      "name": "Sonneberg",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.35,
        "lng": 11.1667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18765,
      "name": "Paraiso",
      "names": {
        "zh": "帕拉伊索",
        "ja": "パライソ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3961,
        "lng": -93.2128
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18806,
      "name": "Honda",
      "names": {
        "zh": "本田",
        "ja": "ホンダ",
        "th": "ฮอนด้า"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.2069,
        "lng": -74.7367
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18832,
      "name": "Svishtov",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.6113,
        "lng": 25.3569
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 18607,
      "name": "Goirle",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5203,
        "lng": 5.0671
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18747,
      "name": "Hoh Ereg",
      "names": {
        "es": "Hoh enereg",
        "ja": "hoh / erg",
        "th": "hoh eereg"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.0955,
        "lng": 111.4408
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 18590,
      "name": "Dole",
      "names": {
        "zh": "尔",
        "ja": "ドール",
        "th": "เงินที่รัฐบาลแจก"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.0931,
        "lng": 5.4906
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18365,
      "name": "Wil",
      "names": {
        "zh": "维尔"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.4664,
        "lng": 9.0497
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 18657,
      "name": "Wijk bij Duurstede",
      "names": {
        "ja": "Wijk Bij Duustede"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9761,
        "lng": 5.3369
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18228,
      "name": "Koscierzyna",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.1167,
        "lng": 17.9833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 18880,
      "name": "Luninyets",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.25,
        "lng": 26.8
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 18837,
      "name": "Berehove",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2025,
        "lng": 22.6375
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 18877,
      "name": "Fria",
      "names": {
        "es": "Fría",
        "zh": "弗里亚"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.3804,
        "lng": -13.55
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 18812,
      "name": "Comapa",
      "names": {
        "zh": "科马帕"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.1114,
        "lng": -89.7497
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 18346,
      "name": "Rishton",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.3567,
        "lng": 71.2847
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 18652,
      "name": "Salqin",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.1394,
        "lng": 36.4539
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 7330,
      "name": "Bayburt",
      "names": {
        "zh": "巴伊布尔特"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.2546,
        "lng": 40.226
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 7127,
      "name": "Cazones de Herrera",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7,
        "lng": -97.3
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18853,
      "name": "Olopa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6833,
        "lng": -89.35
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 18712,
      "name": "Amstetten",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.1167,
        "lng": 14.8667
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 18888,
      "name": "Salzwedel",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.85,
        "lng": 11.15
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7160,
      "name": "Bang Sao Thong",
      "names": {
        "fr": "Bang sao strong",
        "de": "Bang Sao Tanga",
        "ja": "バンサコトン",
        "th": "บางแส้ทอง",
        "pt": "BANG SAO TANG"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5812,
        "lng": 100.7957
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 18931,
      "name": "Coronel Suarez",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.4667,
        "lng": -61.9333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7324,
      "name": "Satun",
      "names": {
        "zh": "沙敦",
        "th": "สตูล"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.6147,
        "lng": 100.0681
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 18914,
      "name": "Koziatyn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.7167,
        "lng": 28.8333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 18842,
      "name": "Oldebroek",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4667,
        "lng": 5.9167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18763,
      "name": "Calw",
      "names": {
        "zh": "卡尔夫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7144,
        "lng": 8.7375
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18477,
      "name": "Rochefort",
      "names": {
        "zh": "罗什福尔",
        "th": "เช"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.9421,
        "lng": -0.9588
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 18871,
      "name": "Korbach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.2719,
        "lng": 8.8731
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7087,
      "name": "Cerro Azul",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2,
        "lng": -97.7331
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 18897,
      "name": "Bauta",
      "names": {
        "zh": "包塔"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9919,
        "lng": -82.5492
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 18966,
      "name": "El Paso",
      "names": {
        "ja": "エルパソ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.6622,
        "lng": -73.7519
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18957,
      "name": "Canalete",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.79,
        "lng": -76.2411
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7340,
      "name": "At Tafilah",
      "names": {
        "es": "En Tafilah",
        "fr": "À Tafilah",
        "zh": "在Tafilah",
        "de": "Bei Tafilah",
        "it": "A TAFILAH",
        "id": "Di tafilah",
        "ja": "タフィラで",
        "th": "ที่ทาฟลาห์",
        "pt": "Em Tafilah"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 30.8375,
        "lng": 35.6044
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 18696,
      "name": "Suonan",
      "names": {
        "zh": "索南"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 35.6634,
        "lng": 103.3923
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 18818,
      "name": "Starnberg",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 47.9972,
        "lng": 11.3406
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18847,
      "name": "Padre Burgos",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.9226,
        "lng": 121.8116
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 7338,
      "name": "Kastamonu",
      "names": {
        "zh": "卡斯塔莫努"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.3833,
        "lng": 33.7833
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 18866,
      "name": "Tata",
      "names": {
        "zh": "塔塔",
        "ja": "タタ",
        "th": "ทาทา"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.65,
        "lng": 18.3167
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 18466,
      "name": "Heiloo",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6011,
        "lng": 4.7019
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18982,
      "name": "Megara",
      "names": {
        "zh": "麦加拉",
        "ja": "メガラ",
        "th": "กา"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0,
        "lng": 23.3333
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 18896,
      "name": "Freudenstadt",
      "names": {
        "zh": "弗罗伊登施塔特"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4633,
        "lng": 8.4111
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7294,
      "name": "Gjirokaster",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 40.0758,
        "lng": 20.1389
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 18898,
      "name": "Silopi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.2497,
        "lng": 42.4717
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 18984,
      "name": "Nagykoros",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0331,
        "lng": 19.7839
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 18999,
      "name": "San Rafael del Sur",
      "names": {
        "ja": "サンラファエルデルシュール"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.85,
        "lng": -86.45
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 18632,
      "name": "Sattahip",
      "names": {
        "zh": "梭桃邑",
        "th": "สัตหีบ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.6636,
        "lng": 100.9036
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7098,
      "name": "San Buenaventura",
      "names": {
        "zh": "圣布纳留村",
        "ja": "サンブナベントゥーラ",
        "th": "ซานบัวเวนทูรา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.0625,
        "lng": -101.5467
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19020,
      "name": "Veinticinco de Mayo",
      "names": {
        "fr": "Veeticinco de Mayo",
        "de": "VEINTICININCO de Mayo"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -35.4167,
        "lng": -60.1667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 18753,
      "name": "Si Racha",
      "names": {
        "th": "ศรีราชา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.1744,
        "lng": 100.9306
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 18870,
      "name": "Mosbach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.35,
        "lng": 9.1333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18825,
      "name": "Saint-Dizier",
      "names": {
        "zh": "圣•狄斯尔",
        "ja": "サン・dizier",
        "th": "เซนต์-dizier"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6383,
        "lng": 4.9497
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 19023,
      "name": "Teorama",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.4353,
        "lng": -73.2864
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19000,
      "name": "Almeirim",
      "names": {
        "zh": "阿尔梅林"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.2167,
        "lng": -8.6333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 18977,
      "name": "Ahualulco de Mercado",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6992,
        "lng": -103.9556
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19017,
      "name": "Doctor Mora",
      "names": {
        "es": "Mora mora",
        "fr": "Docteur mora",
        "zh": "莫拉医生",
        "de": "Arzt Mora",
        "id": "Dokter Mora",
        "ja": "モーラドクター",
        "th": "หมอโมรา",
        "pt": "Mora Mora"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1425,
        "lng": -100.3192
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6935,
      "name": "Sai Mai",
      "names": {
        "zh": "赛马",
        "ja": "サイマイ",
        "th": "สายไหม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8882,
        "lng": 100.462
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7353,
      "name": "Tena",
      "names": {
        "zh": "泰纳"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -0.989,
        "lng": -77.8159
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 19036,
      "name": "Achkhoy-Martan",
      "names": {
        "zh": "achkhoy-马尔坦"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.1939,
        "lng": 45.2833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 18855,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.4739,
        "lng": -77.0808
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18863,
      "name": "Santa Lucia Utatlan",
      "names": {
        "es": "Santa Lucía Utatlan",
        "zh": "圣卢西亚Utatlan",
        "ja": "サンタルシアユタトラン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7667,
        "lng": -91.2667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 19085,
      "name": "Ozuluama de Mascarenas",
      "names": {
        "th": "Ozuluama de Maskarenas"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.6667,
        "lng": -97.85
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7327,
      "name": "Phetchaburi",
      "names": {
        "th": "เพชรบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.1119,
        "lng": 99.9458
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 810,
      "name": "Nuku`alofa",
      "names": {},
      "countryCode": "TO",
      "latLng": {
        "lat": -21.1347,
        "lng": -175.2083
      },
      "country": "Tonga",
      "importance": 0
    },
    {
      "id": 18719,
      "name": "Borne",
      "names": {
        "es": "Transmitidas",
        "fr": "La charge",
        "zh": "承担",
        "de": "Getragen",
        "it": "A carico",
        "id": "Ditanggung",
        "ja": "負担",
        "th": "ที่เกิด",
        "pt": "Suportados"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3,
        "lng": 6.75
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18968,
      "name": "Hisor",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 38.5264,
        "lng": 68.5381
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 18958,
      "name": "Alcala",
      "names": {
        "es": "Alcalá",
        "zh": "阿尔卡拉",
        "ja": "アルカラ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6736,
        "lng": -75.7825
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18725,
      "name": "Husum",
      "names": {
        "zh": "胡苏姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.4769,
        "lng": 9.0511
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 18978,
      "name": "Santo Tomas",
      "names": {
        "ja": "サントトマス",
        "th": "ซานโตโทมัส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7575,
        "lng": -74.7558
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19053,
      "name": "Huitiupan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.1667,
        "lng": -92.6833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19088,
      "name": "Tototlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5333,
        "lng": -102.7833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19120,
      "name": "Puerto Concordia",
      "names": {
        "zh": "波多科迪亚",
        "ja": "プエルトコンコルディア",
        "th": "เปอร์โตริโกคอนคอร์เดีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.6233,
        "lng": -72.7592
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18892,
      "name": "Soledad Atzompa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.755,
        "lng": -97.1522
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19124,
      "name": "Tamiahua",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2788,
        "lng": -97.4462
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6983,
      "name": "Rafael Delgado",
      "names": {
        "zh": "拉斐尔德尔加多",
        "id": "Delgado rafael"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8167,
        "lng": -97.0667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19080,
      "name": "El Tejar",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.65,
        "lng": -90.8
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 19006,
      "name": "Elburg",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4333,
        "lng": 5.85
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 19160,
      "name": "Achi",
      "names": {
        "zh": "阿智"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.5692,
        "lng": -74.5561
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7308,
      "name": "Kibaha",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.7586,
        "lng": 38.9289
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 19065,
      "name": "Oliveira do Bairro",
      "names": {
        "zh": "Oliveira做贝雷罗",
        "ja": "オリビラはベイロを行います"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.5167,
        "lng": -8.5
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 19168,
      "name": "Curumani",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1997,
        "lng": -73.5425
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19083,
      "name": "Palmar de Varela",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7389,
        "lng": -74.7547
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19163,
      "name": "Gyegu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.0166,
        "lng": 96.7333
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 19159,
      "name": "Wichian Buri",
      "names": {
        "zh": "威安伯利",
        "id": "Wichan buri",
        "ja": "ウィチアンブリ",
        "th": "วิเชียรบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.6565,
        "lng": 101.1072
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7229,
      "name": "Bartoszyce",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.2535,
        "lng": 20.8082
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7373,
      "name": "Ogre",
      "names": {
        "es": "Ogro",
        "zh": "食人魔",
        "de": "Menschenfresser",
        "ja": "鬼",
        "th": "ผีปอบ"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.8169,
        "lng": 24.6047
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 7298,
      "name": "Valmiera",
      "names": {
        "zh": "瓦尔米耶拉"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 57.5381,
        "lng": 25.4231
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 19198,
      "name": "Dabeiba",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0014,
        "lng": -76.2611
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19188,
      "name": "Taxisco",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.0716,
        "lng": -90.4645
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 19208,
      "name": "Znamianka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.7136,
        "lng": 32.6733
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 19127,
      "name": "Kilkis",
      "names": {
        "zh": "基尔基斯"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.9954,
        "lng": 22.8765
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 19206,
      "name": "La Democracia",
      "names": {
        "fr": "La Democacia",
        "zh": "洛桑民主党",
        "ja": "ラ民主党"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.2308,
        "lng": -90.9472
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 19236,
      "name": "Thebes",
      "names": {
        "es": "Tebas",
        "fr": "Thèbes",
        "zh": "底比斯",
        "de": "Theben",
        "it": "Tebe",
        "ja": "テーベ",
        "th": "ธีบส์",
        "pt": "Tebas"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.3242,
        "lng": 23.3236
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 19243,
      "name": "Kalat",
      "names": {
        "zh": "卡拉特"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 29.0258,
        "lng": 66.59
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 19197,
      "name": "Luruaco",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.6083,
        "lng": -75.1417
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19194,
      "name": "Guadalupe",
      "names": {
        "zh": "瓜达卢佩",
        "ja": "グアダルペ",
        "th": "กัวดาลูป"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.025,
        "lng": -75.7564
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19176,
      "name": "Vale de Cambra",
      "names": {
        "ja": "ヴァレ・ド・カンブラ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.85,
        "lng": -8.4
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 19192,
      "name": "Vagos",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.55,
        "lng": -8.6667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 19242,
      "name": "Arcos de Valdevez",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.85,
        "lng": -8.4167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 19234,
      "name": "Zacualpa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0272,
        "lng": -90.8778
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 19170,
      "name": "Guanajay",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 22.9306,
        "lng": -82.6881
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 18900,
      "name": "Abbeville",
      "names": {
        "zh": "阿布维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.1058,
        "lng": 1.8358
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 19262,
      "name": "Tunuyan",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -33.5667,
        "lng": -69.0167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 19044,
      "name": "Qatana",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.4333,
        "lng": 36.0833
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 7225,
      "name": "Szczytno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.5628,
        "lng": 20.9853
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7391,
      "name": "Sibiti",
      "names": {},
      "countryCode": "CG",
      "latLng": {
        "lat": -3.6819,
        "lng": 13.3498
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 7385,
      "name": "Bilasuvar",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.4481,
        "lng": 48.5428
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 18667,
      "name": "Lisse",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2597,
        "lng": 4.5611
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 18843,
      "name": "Ostrow Mazowiecka",
      "names": {
        "ja": "オストロウマゾウィエッカ",
        "th": "attrow mazowiecka"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.8,
        "lng": 21.9
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 6914,
      "name": "Frontera",
      "names": {
        "zh": "Frontera的",
        "ja": "フロンテーラ",
        "th": "ฟรงเตอรา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5336,
        "lng": -92.6469
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19204,
      "name": "Albufeira",
      "names": {
        "zh": "阿尔布费拉",
        "ja": "アルブフェイラ",
        "th": "อัลบูเฟรา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.0897,
        "lng": -8.2458
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 7377,
      "name": "Canakkale",
      "names": {
        "zh": "恰纳卡莱",
        "de": "Çanakkale"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.15,
        "lng": 26.4
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 19237,
      "name": "Westerstede",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 53.25,
        "lng": 7.9167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7029,
      "name": "Cartago",
      "names": {
        "zh": "卡塔戈",
        "ja": "カルタゴ",
        "th": "คาร์ตาโก"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 9.8667,
        "lng": -83.9167
      },
      "country": "Costa Rica",
      "importance": 1
    },
    {
      "id": 19244,
      "name": "Banovici",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.4089,
        "lng": 18.5287
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 19284,
      "name": "Santa Fe de Antioquia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.5564,
        "lng": -75.8275
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7398,
      "name": "Madingou",
      "names": {
        "zh": "马丁古"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.1536,
        "lng": 13.55
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 18771,
      "name": "Jawor",
      "names": {
        "zh": "亚沃尔"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.0676,
        "lng": 16.185
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7213,
      "name": "Ojinaga",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 29.5644,
        "lng": -104.4164
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19330,
      "name": "Amalfi",
      "names": {
        "zh": "阿马尔菲",
        "ja": "アマルフィ",
        "th": "อมาลฟี"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.9092,
        "lng": -75.0767
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19323,
      "name": "Buenavista",
      "names": {
        "zh": "布埃纳维斯塔",
        "ja": "ブエナビスタ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2233,
        "lng": -75.4817
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7241,
      "name": "Valle Nacional",
      "names": {
        "zh": "豪华",
        "id": "Nacional",
        "ja": "けいれん弁"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.7667,
        "lng": -96.3
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7401,
      "name": "Kedainiai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.2833,
        "lng": 23.9667
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 19286,
      "name": "Nizip",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0097,
        "lng": 37.7942
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 18913,
      "name": "Partizanske",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.6333,
        "lng": 18.3667
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 19337,
      "name": "Lefkada",
      "names": {
        "zh": "莱夫卡达",
        "id": "Lefkas"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.7178,
        "lng": 20.6439
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 19045,
      "name": "Dunajska Streda",
      "names": {
        "pt": "Dunajska strede"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 47.9961,
        "lng": 17.6147
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 19348,
      "name": "Magdalena",
      "names": {
        "zh": "马格达莱纳",
        "ja": "マグダレナ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9092,
        "lng": -103.9803
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19132,
      "name": "Komlo",
      "names": {
        "zh": "科姆洛"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.1911,
        "lng": 18.2611
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 19275,
      "name": "Royken",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.7336,
        "lng": 10.4289
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 19333,
      "name": "Manzanares",
      "names": {
        "zh": "曼萨纳雷斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.2519,
        "lng": -75.1569
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19264,
      "name": "Losser",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2617,
        "lng": 7.0044
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 808,
      "name": "Andorra la Vella",
      "names": {
        "fr": "Andorre la vella",
        "zh": "安道尔拉维拉",
        "ja": "アンドララベリャ",
        "th": "อันดอร์ราลาเวลลา"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.5,
        "lng": 1.5
      },
      "country": "Andorra",
      "importance": 0
    },
    {
      "id": 19222,
      "name": "San Raimundo",
      "names": {
        "zh": "圣雷姆蒙多",
        "ja": "サンラムンド",
        "th": "ซานไรมรันโด"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7647,
        "lng": -90.5956
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 19344,
      "name": "El Bosque",
      "names": {
        "ja": "エルボスケ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0667,
        "lng": -92.7167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19371,
      "name": "Lajas",
      "names": {
        "zh": "拉哈斯"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.4164,
        "lng": -80.2906
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 7397,
      "name": "Savanna-la-Mar",
      "names": {
        "es": "Sabana-la-mar",
        "fr": "Savane-la-Mar",
        "zh": "稀树草原-的La-3月",
        "de": "Savanne-La-Mar",
        "ja": "サバンナ・ラ・マール",
        "th": "เซวันนาลา มี.ค."
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.2167,
        "lng": -78.1333
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 19089,
      "name": "Vranov nad Topl\u0027ou",
      "names": {
        "pt": "Vranov nad topl\u0027o"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.8806,
        "lng": 21.6731
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 19384,
      "name": "Yahualica de Gonzalez Gallo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1781,
        "lng": -102.886
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19401,
      "name": "Sardinata",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.0836,
        "lng": -72.8003
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19226,
      "name": "Rucphen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5319,
        "lng": 4.5597
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 19405,
      "name": "Polatli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.5842,
        "lng": 32.1472
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 19379,
      "name": "Goleniow",
      "names": {
        "it": "Goleniów"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.55,
        "lng": 14.8167
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7208,
      "name": "Tlacolula de Matamoros",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.9542,
        "lng": -96.4792
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19382,
      "name": "Lempaala",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.3139,
        "lng": 23.7528
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 19394,
      "name": "Mako",
      "names": {
        "zh": "马可",
        "ja": "真子"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.22,
        "lng": 20.4789
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 19440,
      "name": "Mutata",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.2433,
        "lng": -76.4358
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7411,
      "name": "Bafata",
      "names": {
        "zh": "巴法塔"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 12.1719,
        "lng": -14.6575
      },
      "country": "Guinea-Bissau",
      "importance": 1
    },
    {
      "id": 7407,
      "name": "Burdur",
      "names": {
        "zh": "布尔杜尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7167,
        "lng": 30.2833
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 19390,
      "name": "Burg",
      "names": {
        "zh": "伯格",
        "id": "Kota",
        "ja": "ブルク",
        "th": "เมือง"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2725,
        "lng": 11.855
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 19253,
      "name": "Weilheim",
      "names": {
        "zh": "魏尔海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.8333,
        "lng": 11.1333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 19396,
      "name": "Erzin",
      "names": {
        "zh": "埃尔津"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.9539,
        "lng": 36.2022
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 19148,
      "name": "Yuli",
      "names": {
        "zh": "尤利"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.3351,
        "lng": 86.2614
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 7416,
      "name": "Mouila",
      "names": {
        "zh": "穆伊拉"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -1.8667,
        "lng": 11.055
      },
      "country": "Gabon",
      "importance": 1
    },
    {
      "id": 19134,
      "name": "Baclayon",
      "names": {
        "zh": "巴克拉永"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.6227,
        "lng": 123.9135
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 19398,
      "name": "Puente Nacional",
      "names": {
        "ja": "プエンテネシャル"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3333,
        "lng": -96.4833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19444,
      "name": "Nadvirna",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6333,
        "lng": 24.5833
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 19308,
      "name": "Bad Kissingen",
      "names": {
        "es": "Mal besos",
        "fr": "Mal embrassant",
        "zh": "糟糕的吻",
        "de": "Schlechtes Kissingen",
        "id": "Kissingen Buruk",
        "ja": "悪いキスインゲン",
        "th": "kissingen ที่ไม่ดี",
        "pt": "Beijo ruim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.2,
        "lng": 10.0667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7384,
      "name": "Moyo",
      "names": {
        "zh": "莫约"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.6504,
        "lng": 31.72
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 7395,
      "name": "Sibut",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 5.7378,
        "lng": 19.0867
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 19453,
      "name": "Akcakoca",
      "names": {
        "zh": "阿克恰科贾"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0806,
        "lng": 31.1297
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 19474,
      "name": "La Jagua de Ibirico",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.5611,
        "lng": -73.3364
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 18918,
      "name": "Buyukcekmece",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.02,
        "lng": 28.5775
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 19511,
      "name": "Ar Rutbah",
      "names": {
        "fr": "Ar rubah",
        "ja": "rutbah",
        "th": "arrutbah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 33.0333,
        "lng": 40.2833
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 7161,
      "name": "Torcy",
      "names": {
        "zh": "托尔西"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8502,
        "lng": 2.6508
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 19476,
      "name": "Arouca",
      "names": {
        "zh": "阿罗卡"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.9338,
        "lng": -8.2449
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 19495,
      "name": "Lebu",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -37.6103,
        "lng": -73.6561
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 7423,
      "name": "Loei",
      "names": {
        "zh": "黎",
        "ja": "ルーイ",
        "th": "เลย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4853,
        "lng": 101.7307
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 19336,
      "name": "Beek en Donk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5161,
        "lng": 5.6208
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 19057,
      "name": "Epernay",
      "names": {
        "zh": "艾培涅"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.0403,
        "lng": 3.96
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 19527,
      "name": "Natagaima",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.6231,
        "lng": -75.0936
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19496,
      "name": "Zeewolde",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3333,
        "lng": 5.5167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 19538,
      "name": "El Doncello",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.68,
        "lng": -75.285
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19355,
      "name": "Baraki Barak",
      "names": {
        "zh": "巴拉克基巴拉克",
        "ja": "バラキバラック",
        "th": "บารากิบาราว"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.9667,
        "lng": 68.9667
      },
      "country": "Afghanistan",
      "importance": 2
    },
    {
      "id": 7415,
      "name": "Phichit",
      "names": {
        "th": "พิจิตร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.4431,
        "lng": 100.3467
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 19456,
      "name": "Zhedao",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 24.8098,
        "lng": 98.2961
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 19494,
      "name": "Acatlan",
      "names": {
        "es": "Acatlán",
        "zh": "阿卡特兰"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4242,
        "lng": -103.6014
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19389,
      "name": "Stadthagen",
      "names": {
        "zh": "斯塔特哈根"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.3247,
        "lng": 9.2069
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 19459,
      "name": "El Colegio",
      "names": {
        "ja": "エルコレゴ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5808,
        "lng": -74.4425
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19537,
      "name": "San Andres Villa Seca",
      "names": {
        "es": "San Andrés Villa Seca",
        "zh": "圣安德烈斯别墅Seca",
        "ja": "サンアンドレスヴィラセコ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5667,
        "lng": -91.5833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 19369,
      "name": "Huangzhai",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0605,
        "lng": 112.6701
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 7439,
      "name": "Sao Filipe",
      "names": {
        "fr": "Sao filpe",
        "zh": "撒底菲利普",
        "ja": "サンフィラ",
        "pt": "São Filipe"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 14.8951,
        "lng": -24.5004
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 19427,
      "name": "El Progreso",
      "names": {
        "pt": "El progresso"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.35,
        "lng": -89.85
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 19338,
      "name": "Sal\u0027a",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.15,
        "lng": 17.8833
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 19450,
      "name": "Auch",
      "names": {
        "zh": "奥赫",
        "ja": "オーシュ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.6465,
        "lng": 0.5855
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7431,
      "name": "Soroca",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.1558,
        "lng": 28.2975
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 19578,
      "name": "Repelon",
      "names": {
        "zh": "雷佩隆"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4944,
        "lng": -75.1242
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7445,
      "name": "Kilkenny",
      "names": {
        "zh": "基尔肯尼",
        "ja": "キルケニー",
        "th": "คิลเคนนี่"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.6477,
        "lng": -7.2561
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 19451,
      "name": "Maribojoc",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 9.75,
        "lng": 123.85
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 7429,
      "name": "Mbaiki",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 3.8833,
        "lng": 18.0
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 19582,
      "name": "Salvaterra de Magos",
      "names": {
        "id": "Salvaterra de mago"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.0167,
        "lng": -8.7833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 7428,
      "name": "Adiyaman",
      "names": {
        "zh": "阿德亚曼"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7644,
        "lng": 38.2763
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 19587,
      "name": "San Rafael del Norte",
      "names": {
        "zh": "圣拉斐尔del Norte",
        "ja": "サンラファエルデルノルテ"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.2,
        "lng": -86.1
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 18891,
      "name": "Lubartow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.4667,
        "lng": 22.6
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7441,
      "name": "Phetchabun",
      "names": {
        "zh": "碧差汶",
        "th": "เพชรบูรณ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.4169,
        "lng": 101.1533
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 19655,
      "name": "Augusta",
      "names": {
        "zh": "奥古斯塔",
        "ja": "オーガスタ",
        "th": "ออกัสตา"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.3341,
        "lng": -69.7319
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 19620,
      "name": "Qapqal",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.834,
        "lng": 81.1581
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 7322,
      "name": "Nkhata Bay",
      "names": {
        "es": "Bahía de Nkhata",
        "zh": "Nkhata湾",
        "th": "อ่าว Nkhata",
        "pt": "Baía de nkhata"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -11.6333,
        "lng": 34.3
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 19646,
      "name": "Pailitas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.9597,
        "lng": -73.625
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19666,
      "name": "Timbiqui",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.7719,
        "lng": -77.665
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19629,
      "name": "Lom",
      "names": {
        "zh": "洛美",
        "th": "ลม"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.8254,
        "lng": 23.2374
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 19673,
      "name": "Tiquisio",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.5578,
        "lng": -74.2639
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7254,
      "name": "Dongta",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.0824,
        "lng": 106.339
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 7453,
      "name": "Telsiai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9833,
        "lng": 22.25
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 7451,
      "name": "Mocoa",
      "names": {
        "zh": "莫科阿"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.1492,
        "lng": -76.6464
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 19674,
      "name": "Semikarakorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.5167,
        "lng": 40.8
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 19627,
      "name": "Silvania",
      "names": {
        "zh": "席尔瓦尼亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4033,
        "lng": -74.3881
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19455,
      "name": "Apolda",
      "names": {
        "zh": "阿波尔达"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.0247,
        "lng": 11.5139
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7440,
      "name": "Uliastay",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 47.7428,
        "lng": 96.8433
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 19641,
      "name": "Millau",
      "names": {
        "zh": "米洛"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.0986,
        "lng": 3.0783
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7461,
      "name": "Ghat",
      "names": {
        "zh": "迦特",
        "ja": "ガート",
        "th": "แม่น้ำ"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 24.9611,
        "lng": 10.175
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 19508,
      "name": "Corinto",
      "names": {
        "zh": "科林托"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.4833,
        "lng": -87.1833
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 19349,
      "name": "Tecali",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9011,
        "lng": -97.97
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19501,
      "name": "Chaumont",
      "names": {
        "zh": "肖蒙"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1117,
        "lng": 5.1389
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 19644,
      "name": "Swiedbodzin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.25,
        "lng": 15.5333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 19678,
      "name": "Taminango",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.57,
        "lng": -77.2806
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7424,
      "name": "Al Bahah",
      "names": {
        "ja": "アルバハー",
        "th": "อัลบาห์"
      },
      "countryCode": "SA",
      "latLng": {
        "lat": 20.0129,
        "lng": 41.4677
      },
      "country": "Saudi Arabia",
      "importance": 1
    },
    {
      "id": 19059,
      "name": "Hillegom",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2917,
        "lng": 4.5794
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 19647,
      "name": "Villa de Leyva",
      "names": {
        "ja": "ヴィラデレイヴァ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6331,
        "lng": -73.5256
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19720,
      "name": "Iisalmi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.5611,
        "lng": 27.1889
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 19522,
      "name": "Harim",
      "names": {
        "zh": "闺房",
        "th": "ฮาริม"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.2076,
        "lng": 36.5192
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 19305,
      "name": "Svetlyy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.6833,
        "lng": 20.1333
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 19730,
      "name": "Tornio",
      "names": {
        "zh": "托尔尼奥"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 65.8497,
        "lng": 24.1441
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 809,
      "name": "Saint John\u0027s",
      "names": {
        "es": "San Juan",
        "fr": "Saint John",
        "zh": "圣约翰的",
        "it": "San Giovanni",
        "ja": "セントジョンズ",
        "th": "เซนต์จอห์น",
        "pt": "São João"
      },
      "countryCode": "AG",
      "latLng": {
        "lat": 17.1211,
        "lng": -61.8447
      },
      "country": "Antigua And Barbuda",
      "importance": 0
    },
    {
      "id": 19606,
      "name": "Villeneuve-sur-Lot",
      "names": {
        "es": "Villeneuve-sur-mucho",
        "zh": "新城畔很多",
        "de": "Villeneuve-sur-Menge",
        "ja": "ヴィルヌーヴシュルたくさん",
        "th": "เนิฟ-ซูร์มาก"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.4081,
        "lng": 0.705
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 19703,
      "name": "Axapusco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7194,
        "lng": -98.7972
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7169,
      "name": "Krathum Baen",
      "names": {
        "th": "กระทุ่มแบน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6519,
        "lng": 100.2572
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 19165,
      "name": "Grajewo",
      "names": {
        "zh": "格拉耶沃"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.65,
        "lng": 22.45
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 19680,
      "name": "Povoa de Lanhoso",
      "names": {
        "ja": "ポボアデラニョソ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5667,
        "lng": -8.2667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 19726,
      "name": "Wieliczka",
      "names": {
        "zh": "维利奇卡"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.9894,
        "lng": 20.0661
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 19434,
      "name": "Heide",
      "names": {
        "zh": "海德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.1961,
        "lng": 9.0933
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 19510,
      "name": "Binnish",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.95,
        "lng": 36.7
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 19672,
      "name": "Vanersborg",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.363,
        "lng": 12.33
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 19757,
      "name": "Ginebra",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.7244,
        "lng": -76.2672
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19748,
      "name": "Azambuja",
      "names": {
        "zh": "阿赞布雅"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.0667,
        "lng": -8.8667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 19650,
      "name": "Leczna",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.3,
        "lng": 22.8833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 18901,
      "name": "Kreuzlingen",
      "names": {
        "zh": "克罗伊茨林根"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.6458,
        "lng": 9.1783
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 19777,
      "name": "Siilinjarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.075,
        "lng": 27.66
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 19721,
      "name": "Opoczno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.3833,
        "lng": 20.2833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7427,
      "name": "Nsanje",
      "names": {
        "zh": "恩桑杰"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -16.9167,
        "lng": 35.2667
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 7368,
      "name": "Kayanza",
      "names": {
        "zh": "卡扬扎"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -2.9167,
        "lng": 29.6167
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 19713,
      "name": "Kemi",
      "names": {
        "zh": "凯米"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 65.7336,
        "lng": 24.5634
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 19515,
      "name": "Chapa de Mota",
      "names": {
        "id": "Capa de mota",
        "ja": "チャパデモ",
        "pt": "Capa de mota"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8144,
        "lng": -99.5269
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19784,
      "name": "Puerto Triunfo",
      "names": {
        "ja": "プエルトトリウォンフォ",
        "th": "เปอร์โตไตรปืน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8728,
        "lng": -74.6397
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19806,
      "name": "Kurikka",
      "names": {
        "zh": "库里卡"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.6167,
        "lng": 22.4
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 19733,
      "name": "Osterode",
      "names": {
        "zh": "奥斯特罗德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7286,
        "lng": 10.2522
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7012,
      "name": "Tixtla de Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5667,
        "lng": -99.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19679,
      "name": "Hlohovec",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.4311,
        "lng": 17.8031
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 7279,
      "name": "Sao Joao da Madeira",
      "names": {
        "fr": "Sao Joao da Madère",
        "ja": "サンジョアーダマデイラ",
        "pt": "São João da Madeira"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.9,
        "lng": -8.5
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 7403,
      "name": "Kayunga",
      "names": {
        "zh": "卡永加"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.7025,
        "lng": 32.8886
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 19618,
      "name": "Kitzingen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7333,
        "lng": 10.1667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7060,
      "name": "Sombrerete",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 23.6333,
        "lng": -103.6397
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7479,
      "name": "Bolu",
      "names": {
        "zh": "玻鲁"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.7333,
        "lng": 31.6
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 7443,
      "name": "Caacupe",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -25.387,
        "lng": -57.14
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 19086,
      "name": "Kolo",
      "names": {
        "zh": "科洛",
        "ja": "コロ"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.2,
        "lng": 18.6333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7466,
      "name": "Al Karak",
      "names": {
        "ja": "アルカラック",
        "th": "อัลคารัค"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 31.1833,
        "lng": 35.7
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 19771,
      "name": "Ocotlan de Morelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7914,
        "lng": -96.675
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7489,
      "name": "Nigde",
      "names": {
        "zh": "尼代"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9667,
        "lng": 34.6792
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 19195,
      "name": "Forbach",
      "names": {
        "zh": "福尔巴赫"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.19,
        "lng": 6.9
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 19833,
      "name": "San Jacinto",
      "names": {
        "zh": "圣雅加托",
        "th": "ซานจาคินโต"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.8311,
        "lng": -75.1219
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19354,
      "name": "Sondrio",
      "names": {
        "zh": "桑得里奥",
        "ja": "ソンドリオ"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 46.1697,
        "lng": 9.87
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 7405,
      "name": "Jekabpils",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.4975,
        "lng": 25.8664
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 19862,
      "name": "San Justo",
      "names": {
        "it": "San jousto"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.7833,
        "lng": -60.5833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 19792,
      "name": "Zundert",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4703,
        "lng": 4.66
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 19859,
      "name": "Nueva Granada",
      "names": {
        "fr": "Nueva Grenade",
        "zh": "Nueva格拉纳达",
        "th": "นูวากรานาดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.8011,
        "lng": -74.3917
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7456,
      "name": "Ashtarak",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.2975,
        "lng": 44.3617
      },
      "country": "Armenia",
      "importance": 1
    },
    {
      "id": 19029,
      "name": "Cuilapan de Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.9972,
        "lng": -96.7817
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7073,
      "name": "Chapala",
      "names": {
        "zh": "查帕拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2933,
        "lng": -103.1897
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19851,
      "name": "Senaki",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.2689,
        "lng": 42.0678
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 19845,
      "name": "Kant",
      "names": {
        "zh": "康德",
        "ja": "カント",
        "th": "คานท์"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.8833,
        "lng": 74.85
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 19861,
      "name": "La Resolana",
      "names": {
        "zh": "拉索纳",
        "it": "La Rimolana",
        "ja": "La Resirana"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6031,
        "lng": -104.4362
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19855,
      "name": "San Nicolas",
      "names": {
        "es": "San Nicolás",
        "zh": "圣尼古拉斯",
        "ja": "サンニコラス",
        "th": "ซานนิโคลัส"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7819,
        "lng": -81.9069
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 19872,
      "name": "Galeras",
      "names": {
        "zh": "加勒拉斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1586,
        "lng": -75.0489
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19541,
      "name": "Golcuk",
      "names": {
        "zh": "格尔居克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6667,
        "lng": 29.8333
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 19896,
      "name": "Jamsa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.8639,
        "lng": 25.1903
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 19699,
      "name": "Sonthofen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 47.5142,
        "lng": 10.2817
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 19881,
      "name": "Acatic",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7803,
        "lng": -102.91
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19649,
      "name": "Vecses",
      "names": {
        "de": "Vecsés"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4108,
        "lng": 19.2722
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 7495,
      "name": "Nong Bua Lamphu",
      "names": {
        "th": "หนองบัวลำภู"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.2042,
        "lng": 102.4444
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 19909,
      "name": "Dean Funes",
      "names": {
        "es": "Decan Funes",
        "ja": "ディーンのファン",
        "th": "คณบดีสนุก",
        "pt": "Diversos Dean"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.4333,
        "lng": -64.35
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7503,
      "name": "Taurage",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.2514,
        "lng": 22.2903
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 19492,
      "name": "Dudelange",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.4806,
        "lng": 6.0875
      },
      "country": "Luxembourg",
      "importance": 2
    },
    {
      "id": 19869,
      "name": "Sondershausen",
      "names": {
        "zh": "松德斯豪森"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3667,
        "lng": 10.8667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 19781,
      "name": "San Martin de las Piramides",
      "names": {
        "zh": "圣马丁德拉斯吡米德",
        "ja": "サンマーティンデラスピラミド"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7333,
        "lng": -98.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7470,
      "name": "Goroka",
      "names": {
        "zh": "戈罗卡"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -6.0833,
        "lng": 145.3833
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 19900,
      "name": "Ano Syros",
      "names": {
        "zh": "Ano锡罗斯",
        "ja": "アノシロス",
        "pt": "Xo syros"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.45,
        "lng": 24.9
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 19910,
      "name": "Villagarzon",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.0294,
        "lng": -76.6164
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19878,
      "name": "Nang Rong",
      "names": {
        "zh": "南戎",
        "ja": "ナンロン",
        "th": "นางรอง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6363,
        "lng": 102.7746
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 19788,
      "name": "San Bernardo",
      "names": {
        "zh": "圣伯纳多",
        "ja": "サンベルナルド",
        "th": "ซานเบอร์นาร์โด"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.5164,
        "lng": -77.0467
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19934,
      "name": "Puerto Natales",
      "names": {
        "zh": "波多特·纳塔尔斯",
        "ja": "プエルトナタレス",
        "th": "เปอร์โตนาเลส"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -51.7263,
        "lng": -72.5062
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 19925,
      "name": "Zile",
      "names": {
        "zh": "济莱"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.3,
        "lng": 35.8833
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 19692,
      "name": "Kotli",
      "names": {},
      "countryCode": "PK",
      "latLng": {
        "lat": 33.5156,
        "lng": 73.9019
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 19930,
      "name": "El Paujil",
      "names": {
        "ja": "エルパウジル"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.5694,
        "lng": -75.3264
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19848,
      "name": "Aichach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.45,
        "lng": 11.1333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 6865,
      "name": "Pefki",
      "names": {
        "th": "เพฟกี"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0667,
        "lng": 23.8
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 19908,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8747,
        "lng": -75.5661
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7465,
      "name": "Isparta",
      "names": {
        "zh": "伊斯帕尔塔",
        "th": "อิสปาร์ตา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7667,
        "lng": 30.55
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 19922,
      "name": "Moniquira",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8744,
        "lng": -73.5717
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7354,
      "name": "Naas",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 53.2158,
        "lng": -6.6669
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 7137,
      "name": "Heroica Ciudad de Tlaxiaco",
      "names": {
        "zh": "Heroica Ciudad de Tlaxiamco"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2077,
        "lng": -97.6798
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19960,
      "name": "Kozluk",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.1833,
        "lng": 41.4926
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 19917,
      "name": "Livadeia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.4361,
        "lng": 22.875
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 7269,
      "name": "Allende",
      "names": {
        "zh": "阿连德",
        "ja": "アジェンデ",
        "th": "อัลเลน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.3333,
        "lng": -100.8333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19928,
      "name": "Almaguer",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.9131,
        "lng": -76.8561
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7247,
      "name": "Fortin de las Flores",
      "names": {
        "zh": "福蒂宁德兰弗洛雷斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9,
        "lng": -97.0
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20002,
      "name": "Youganning",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.7356,
        "lng": 101.5978
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 19325,
      "name": "Mragowo",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.8644,
        "lng": 21.3051
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 19916,
      "name": "Wang Nam Yen",
      "names": {
        "zh": "王南日元",
        "ja": "王のナム円",
        "th": "วังน้ำเยน",
        "pt": "Wang nam iene"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5004,
        "lng": 102.1806
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7417,
      "name": "Artashat",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 39.9539,
        "lng": 44.5506
      },
      "country": "Armenia",
      "importance": 1
    },
    {
      "id": 19809,
      "name": "Huy",
      "names": {
        "zh": "于伊",
        "th": "ฮุย"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.5167,
        "lng": 5.2333
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 20014,
      "name": "Elverum",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.8833,
        "lng": 11.5667
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 7509,
      "name": "Virovitica",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.8333,
        "lng": 17.3833
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 19939,
      "name": "Antsohihy",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -14.8661,
        "lng": 47.9834
      },
      "country": "Madagascar",
      "importance": 2
    },
    {
      "id": 19258,
      "name": "Dzialdowo",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.2333,
        "lng": 20.1833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 19966,
      "name": "Tubbergen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4,
        "lng": 6.7667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 20003,
      "name": "Belovodskoe",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 42.8333,
        "lng": 74.1167
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 7375,
      "name": "Aarau",
      "names": {
        "zh": "阿劳",
        "th": "อาเรา"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3923,
        "lng": 8.0446
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 19945,
      "name": "Prudnik",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.3197,
        "lng": 17.5792
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 20049,
      "name": "Aguadas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6092,
        "lng": -75.4564
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 20059,
      "name": "Montecristo",
      "names": {
        "zh": "蒙特克里斯托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2958,
        "lng": -74.4728
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7240,
      "name": "Degollado",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4667,
        "lng": -102.15
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7519,
      "name": "Ukmerge",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.2667,
        "lng": 24.75
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 19991,
      "name": "Gryfino",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.2524,
        "lng": 14.4883
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7523,
      "name": "Obock",
      "names": {},
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.9667,
        "lng": 43.2833
      },
      "country": "Djibouti",
      "importance": 1
    },
    {
      "id": 20044,
      "name": "Rio Maior",
      "names": {
        "zh": "里奥迈阿里",
        "it": "Rio maor",
        "id": "Rio maya",
        "ja": "リオメーバー",
        "pt": "Rústico"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.3333,
        "lng": -8.9333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 20101,
      "name": "Bologoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.8787,
        "lng": 34.0789
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 20083,
      "name": "Varkaus",
      "names": {
        "zh": "瓦尔考斯"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.3167,
        "lng": 27.8833
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 19741,
      "name": "Hallein",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.6667,
        "lng": 13.0833
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 20099,
      "name": "Florencia",
      "names": {
        "zh": "弗洛伦西亚"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.1475,
        "lng": -78.9669
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 20118,
      "name": "El Calafate",
      "names": {
        "ja": "elカラファテ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -50.3395,
        "lng": -72.2649
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7511,
      "name": "Nevsehir",
      "names": {
        "zh": "内夫谢希尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.625,
        "lng": 34.7122
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 20100,
      "name": "Suaza",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.975,
        "lng": -75.7956
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7528,
      "name": "Luena",
      "names": {
        "zh": "卢恩阿"
      },
      "countryCode": "AO",
      "latLng": {
        "lat": -11.79,
        "lng": 19.9
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 6919,
      "name": "Nogales",
      "names": {
        "zh": "诺加利斯",
        "ja": "ノガレス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8167,
        "lng": -97.1667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7201,
      "name": "Chocaman",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0122,
        "lng": -97.0328
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7006,
      "name": "Altepexi",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3676,
        "lng": -97.3004
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20064,
      "name": "Sadao",
      "names": {
        "zh": "贞雄",
        "th": "สะเดา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.6386,
        "lng": 100.424
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 20051,
      "name": "Brezno",
      "names": {
        "zh": "布雷兹诺"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.8036,
        "lng": 19.6381
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 20134,
      "name": "Stange",
      "names": {
        "zh": "施坦格"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.6494,
        "lng": 11.3664
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 7525,
      "name": "Orhei",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.3831,
        "lng": 28.8231
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 20151,
      "name": "Chascomus",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -35.575,
        "lng": -58.0089
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7510,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "SV",
      "latLng": {
        "lat": 13.7,
        "lng": -88.1
      },
      "country": "El Salvador",
      "importance": 1
    },
    {
      "id": 20119,
      "name": "Villapinzon",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.2158,
        "lng": -73.5967
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7414,
      "name": "Saint Paul\u0027s Bay",
      "names": {
        "es": "Bahía de San Pablo",
        "zh": "圣保罗海湾",
        "it": "Baia di San Paolo",
        "id": "Teluk Saint Paul",
        "ja": "セントポール湾",
        "th": "อ่าวเซนต์พอล",
        "pt": "Baía de Saint Paul"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9483,
        "lng": 14.4017
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 19866,
      "name": "Leerdam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8939,
        "lng": 5.0914
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7527,
      "name": "La Esperanza",
      "names": {
        "fr": "La espéranza"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 14.3,
        "lng": -88.1833
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 20139,
      "name": "La Colonia Tovar",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.4167,
        "lng": -67.2833
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 20124,
      "name": "Fredonia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9267,
        "lng": -75.6739
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 20154,
      "name": "El Reten",
      "names": {
        "es": "El retorno",
        "zh": "el请重新调整",
        "de": "El Retmen",
        "it": "El ritenzione",
        "ja": "el sten"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.6106,
        "lng": -74.2683
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 20179,
      "name": "Bugalagrande",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.2075,
        "lng": -76.1575
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7055,
      "name": "Renens",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 46.5353,
        "lng": 6.5897
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 7513,
      "name": "Giresun",
      "names": {
        "zh": "吉雷松",
        "th": "กิเรซุน"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9,
        "lng": 38.4167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 19882,
      "name": "Eeklo",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 51.1858,
        "lng": 3.5639
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 7116,
      "name": "Texistepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9,
        "lng": -94.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7399,
      "name": "Mulanje",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -16.0333,
        "lng": 35.5
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 20253,
      "name": "Sotomayor",
      "names": {
        "zh": "索托马约尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.4933,
        "lng": -77.5214
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19875,
      "name": "Kara-Suu",
      "names": {
        "zh": "卡拉-suu",
        "ja": "カラ-のSuu",
        "th": "คาร่า-ซู"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 40.7,
        "lng": 72.8833
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 7543,
      "name": "Impfondo",
      "names": {},
      "countryCode": "CG",
      "latLng": {
        "lat": 1.6333,
        "lng": 18.0667
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 20223,
      "name": "Oliveira do Hospital",
      "names": {
        "zh": "oliveira做医院",
        "de": "Oliveira do Krankenhaus",
        "id": "Oliveira do Rumah Sakit",
        "ja": "オリビラは病院です",
        "th": "โรงพยาบาล Oliveira do"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3667,
        "lng": -7.8667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 20249,
      "name": "Ovejas",
      "names": {
        "zh": "奥韦哈斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.5258,
        "lng": -75.2272
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 19950,
      "name": "Sanhe",
      "names": {
        "zh": "三河"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.5643,
        "lng": 105.6401
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 20216,
      "name": "Siquinala",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.3082,
        "lng": -90.9659
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 7507,
      "name": "Maralal",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 1.1,
        "lng": 36.7
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 20237,
      "name": "Kremenets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.1081,
        "lng": 25.7275
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 19911,
      "name": "Sarreguemines",
      "names": {
        "zh": "萨尔格米纳"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.11,
        "lng": 7.07
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 20265,
      "name": "Valkeakoski",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.2667,
        "lng": 24.0306
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 7545,
      "name": "Quba",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.3653,
        "lng": 48.5264
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 19811,
      "name": "Germersheim",
      "names": {
        "zh": "格尔斯海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.2167,
        "lng": 8.3667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 20210,
      "name": "Urk",
      "names": {
        "zh": "乌尔克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6653,
        "lng": 5.6058
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 20246,
      "name": "Samaca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4919,
        "lng": -73.4867
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7544,
      "name": "Gavarr",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.3589,
        "lng": 45.1267
      },
      "country": "Armenia",
      "importance": 1
    },
    {
      "id": 19783,
      "name": "Dax",
      "names": {
        "zh": "达克斯",
        "ja": "ダックス"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.71,
        "lng": -1.05
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 20311,
      "name": "Illapel",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -31.6327,
        "lng": -71.1683
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 19986,
      "name": "Beaune",
      "names": {
        "zh": "博纳",
        "ja": "ボーヌ",
        "th": "โบน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.025,
        "lng": 4.8397
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 20295,
      "name": "Lebedinovka",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 42.88,
        "lng": 74.68
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 7476,
      "name": "Yalova",
      "names": {
        "zh": "亚洛瓦"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6556,
        "lng": 29.275
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 20143,
      "name": "Gunzburg",
      "names": {
        "fr": "Günzburg",
        "de": "Günzburg",
        "it": "Günzburg"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4525,
        "lng": 10.2711
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 20325,
      "name": "Elvas",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.88,
        "lng": -7.1628
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 20342,
      "name": "Menaka",
      "names": {
        "zh": "梅纳卡"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 15.9167,
        "lng": 2.4
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 20204,
      "name": "Mae Sai",
      "names": {
        "ja": "メーサイ",
        "th": "แม่สาย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 20.4266,
        "lng": 99.8841
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 20225,
      "name": "Brummen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1,
        "lng": 6.1667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7553,
      "name": "Ati",
      "names": {
        "zh": "ATI的",
        "ja": "ATIの"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 13.2139,
        "lng": 18.3403
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 20319,
      "name": "Dolyna",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.9703,
        "lng": 24.0108
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 20328,
      "name": "Mantsala",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.6331,
        "lng": 25.3167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 7501,
      "name": "Bozoum",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 6.3172,
        "lng": 16.3783
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 7552,
      "name": "Ismayilli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.7839,
        "lng": 48.1439
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 20326,
      "name": "Melena del Sur",
      "names": {
        "ja": "メレナデルール"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7814,
        "lng": -82.1486
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 20332,
      "name": "Pueblo Nuevo",
      "names": {
        "zh": "普韦布洛·努沃"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.3833,
        "lng": -86.4833
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 20293,
      "name": "Forst (Lausitz)",
      "names": {
        "zh": "福尔斯特（洛乌斯）",
        "ja": "フォースト（Lausitz）"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.7333,
        "lng": 14.6333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 19883,
      "name": "Hajnowka",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.7333,
        "lng": 23.5667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 20320,
      "name": "Na Klang",
      "names": {
        "th": "นากลาง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.3043,
        "lng": 102.1964
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 20339,
      "name": "Jitotol",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0667,
        "lng": -92.8667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7554,
      "name": "Nan",
      "names": {
        "zh": "南",
        "ja": "ナン",
        "th": "น่าน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.7893,
        "lng": 100.7766
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 20413,
      "name": "Gjovik",
      "names": {
        "de": "Gjøvik"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.7957,
        "lng": 10.6916
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 20178,
      "name": "Luckenwalde",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.0831,
        "lng": 13.1667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7555,
      "name": "Cuprija",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.9231,
        "lng": 21.3686
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 20428,
      "name": "Qagan Us",
      "names": {
        "zh": "QAGAN我们",
        "it": "Qagan noi",
        "id": "Qagan kita",
        "pt": "Qagan EUA"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 36.2979,
        "lng": 98.0937
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 7406,
      "name": "Modling",
      "names": {
        "de": "Mödling"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.0856,
        "lng": 16.2831
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 20442,
      "name": "Laboulaye",
      "names": {
        "zh": "拉波拉耶"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.1267,
        "lng": -63.3911
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 19454,
      "name": "Fougeres",
      "names": {
        "zh": "富热尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3525,
        "lng": -1.1986
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 20374,
      "name": "Baiao",
      "names": {
        "zh": "拜昂",
        "pt": "Baião"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1667,
        "lng": -8.0333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 20224,
      "name": "Traunstein",
      "names": {
        "zh": "特劳恩施泰因"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.8683,
        "lng": 12.6433
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7163,
      "name": "Atoyac de Alvarez",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2,
        "lng": -100.4333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20444,
      "name": "Tyrnyauz",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.4,
        "lng": 42.9167
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 7485,
      "name": "Ijevan",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 40.8792,
        "lng": 45.1472
      },
      "country": "Armenia",
      "importance": 1
    },
    {
      "id": 20448,
      "name": "Khvansar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.2206,
        "lng": 50.315
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 20432,
      "name": "Timana",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 1.9499,
        "lng": -75.9139
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 20387,
      "name": "As",
      "names": {
        "es": "Como",
        "fr": "Comme",
        "zh": "作为",
        "de": "Wie",
        "it": "Come",
        "id": "Sebagai",
        "ja": "のように",
        "th": "เช่น",
        "pt": "Como"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.6603,
        "lng": 10.7836
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 19740,
      "name": "Chelmno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.3492,
        "lng": 18.4261
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 20398,
      "name": "Mealhada",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3833,
        "lng": -8.45
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 20454,
      "name": "Cinfaes",
      "names": {
        "pt": "Cinfães"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.0667,
        "lng": -8.0833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 20393,
      "name": "Pak Phanang",
      "names": {
        "ja": "パックフィナン",
        "th": "ปากพระหาน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.3538,
        "lng": 100.2023
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 19969,
      "name": "Aigio",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.25,
        "lng": 22.0833
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 20334,
      "name": "Tielt",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.9989,
        "lng": 3.3258
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 7560,
      "name": "Yasothon",
      "names": {
        "th": "ยโสธร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.7972,
        "lng": 104.1431
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 20473,
      "name": "San Lorenzo",
      "names": {
        "zh": "圣洛伦佐",
        "ja": "サンロレンツォ",
        "th": "ซานลอเรนโซ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.5039,
        "lng": -77.2178
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7563,
      "name": "Zaghouan",
      "names": {},
      "countryCode": "TN",
      "latLng": {
        "lat": 36.4028,
        "lng": 10.1433
      },
      "country": "Tunisia",
      "importance": 1
    },
    {
      "id": 20445,
      "name": "Pleszew",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8974,
        "lng": 17.7856
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 20322,
      "name": "Senica",
      "names": {
        "zh": "塞尼察"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.6806,
        "lng": 17.3667
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 20034,
      "name": "Santa Maria Chilchotla",
      "names": {
        "ja": "サンタマリアチルコトラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.2333,
        "lng": -96.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20185,
      "name": "Muhldorf",
      "names": {
        "zh": "米尔多夫",
        "de": "Mühldorf",
        "it": "Mühldorf"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.2456,
        "lng": 12.5228
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 20513,
      "name": "Wittmund",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 53.5747,
        "lng": 7.7808
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7574,
      "name": "Senta",
      "names": {
        "zh": "森塔"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.9314,
        "lng": 20.09
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 7573,
      "name": "Somoto",
      "names": {
        "zh": "索莫托"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.4833,
        "lng": -86.5833
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 20463,
      "name": "Gostyn",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8792,
        "lng": 17.0125
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 20589,
      "name": "Ciudad Melchor de Mencos",
      "names": {
        "pt": "Ciudad Melchor de Menco"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 17.0667,
        "lng": -89.15
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 20530,
      "name": "Teculutan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9877,
        "lng": -89.717
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 20519,
      "name": "Concordia",
      "names": {
        "zh": "康科迪亚",
        "ja": "コンコルディア",
        "th": "คอนคอร์เดีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0456,
        "lng": -75.9075
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 20586,
      "name": "Simiti",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.9564,
        "lng": -73.9461
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 20491,
      "name": "Tenjo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8697,
        "lng": -74.1439
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 20576,
      "name": "Villa Hidalgo",
      "names": {
        "zh": "别墅Hidalgo",
        "ja": "ヴィラヒダルゴ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.6762,
        "lng": -102.588
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20590,
      "name": "Rovira",
      "names": {
        "zh": "罗维拉"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.2392,
        "lng": -75.2403
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 20312,
      "name": "Bad Salzungen",
      "names": {
        "zh": "糟糕的萨尔岑",
        "de": "Schlechte Salzenungen",
        "ja": "悪いザルサンゲン",
        "pt": "Salzungen ruim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.8117,
        "lng": 10.2333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7362,
      "name": "Mosta",
      "names": {
        "zh": "莫斯塔"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9,
        "lng": 14.4333
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 20582,
      "name": "Uzunkopru",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2669,
        "lng": 26.6875
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 20574,
      "name": "Kozan",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.4507,
        "lng": 35.8123
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 20474,
      "name": "Ocos",
      "names": {
        "zh": "奥科斯"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5138,
        "lng": -92.1909
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 20569,
      "name": "Marneuli",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.4969,
        "lng": 44.8108
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 19842,
      "name": "Entroncamento",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4667,
        "lng": -8.4667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 7579,
      "name": "Agsu",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5708,
        "lng": 48.3928
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 7580,
      "name": "Agstafa",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.1167,
        "lng": 45.45
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 20634,
      "name": "Benemerito",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.5172,
        "lng": -90.6531
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7438,
      "name": "Wexford",
      "names": {
        "zh": "韦克斯福德",
        "ja": "ウェクスフォード",
        "th": "เว็กซ์ฟอร์ด"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.3342,
        "lng": -6.4575
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 20499,
      "name": "Bladel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.3667,
        "lng": 5.2167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 20625,
      "name": "Cruz Grande",
      "names": {
        "zh": "克鲁兹格兰德",
        "de": "Kruz Grande",
        "ja": "クルスグランデ",
        "th": "ครูซแกรนด์"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7333,
        "lng": -99.1333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19823,
      "name": "Lisieux",
      "names": {
        "zh": "里济厄",
        "th": "ลิซิเออซ์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.15,
        "lng": 0.23
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 20567,
      "name": "Bella Vista",
      "names": {
        "zh": "贝拉维斯塔",
        "ja": "ベラビスタ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.5833,
        "lng": -92.2167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 6815,
      "name": "Kartal",
      "names": {
        "zh": "卡尔塔尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9108,
        "lng": 29.1617
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 20659,
      "name": "Bolvadin",
      "names": {
        "zh": "博尔瓦丁"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7167,
        "lng": 31.05
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 20577,
      "name": "Lichtenfels",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1333,
        "lng": 11.0333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 20598,
      "name": "Huanimaro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3675,
        "lng": -101.4969
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20376,
      "name": "Senec",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.2189,
        "lng": 17.3997
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 7586,
      "name": "Comrat",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.3003,
        "lng": 28.6572
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 20553,
      "name": "Greiz",
      "names": {
        "zh": "格赖茨"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6547,
        "lng": 12.1997
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 20638,
      "name": "Celorico de Basto",
      "names": {
        "fr": "Célorico de Basto",
        "zh": "Celoricoo de Basto"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3869,
        "lng": -8.0022
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 20552,
      "name": "Donauworth",
      "names": {
        "de": "Donauwörth",
        "it": "Donauwörth"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.7184,
        "lng": 10.777
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 20559,
      "name": "Hatvan",
      "names": {
        "zh": "豪特万"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6681,
        "lng": 19.6697
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 20693,
      "name": "Naranjos",
      "names": {
        "zh": "纳兰霍斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.3472,
        "lng": -97.6833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 19677,
      "name": "Alblasserdam",
      "names": {
        "zh": "阿尔布拉瑟丹"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8667,
        "lng": 4.6667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 20354,
      "name": "Nove Mesto nad Vahom",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7833,
        "lng": 17.8333
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 20608,
      "name": "Torgau",
      "names": {
        "zh": "托尔高"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.5603,
        "lng": 13.0056
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 20292,
      "name": "Pita",
      "names": {
        "es": "Agave",
        "zh": "皮塔",
        "de": "Pita-Brot",
        "ja": "ピタ",
        "th": "ไฟลนก้น",
        "pt": "Pão Pita"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 11.08,
        "lng": -12.401
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 7587,
      "name": "Guastatoya",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8539,
        "lng": -90.0686
      },
      "country": "Guatemala",
      "importance": 1
    },
    {
      "id": 20735,
      "name": "Alagir",
      "names": {
        "zh": "阿拉吉尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 43.0417,
        "lng": 44.2106
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 20465,
      "name": "Lannion",
      "names": {
        "zh": "拉尼永"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7325,
        "lng": -3.4553
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 20722,
      "name": "Castillo de Teayo",
      "names": {
        "es": "Castillo de Teyeo",
        "fr": "Castillo de Teyo",
        "de": "Castillo de Teeyo",
        "id": "Castillo de Teyo",
        "pt": "Castillo de Teeyo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.75,
        "lng": -97.6333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7568,
      "name": "Bubanza",
      "names": {
        "zh": "布班扎"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.0833,
        "lng": 29.4
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 20489,
      "name": "Rhenen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9597,
        "lng": 5.5689
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7593,
      "name": "Arvayheer",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 46.2639,
        "lng": 102.775
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 7606,
      "name": "Hakha",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 22.65,
        "lng": 93.6167
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 7334,
      "name": "Ciudad Miguel Aleman",
      "names": {
        "es": "Ciudad Miguel Alemán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.4003,
        "lng": -99.0253
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20629,
      "name": "Holzminden",
      "names": {
        "zh": "霍尔茨明登"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.8297,
        "lng": 9.4483
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7610,
      "name": "Tidjikja",
      "names": {},
      "countryCode": "MR",
      "latLng": {
        "lat": 18.55,
        "lng": -11.4166
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 20500,
      "name": "Vianen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9833,
        "lng": 5.1
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 20790,
      "name": "Coruche",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.95,
        "lng": -8.5333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 20788,
      "name": "Xiaqiaotou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 27.2167,
        "lng": 100.15
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 7609,
      "name": "Ulcinj",
      "names": {
        "zh": "乌尔齐尼"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 41.9236,
        "lng": 19.2056
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 20803,
      "name": "Aanekoski",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.6042,
        "lng": 25.7264
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 20633,
      "name": "Cahors",
      "names": {
        "zh": "卡奥尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.4475,
        "lng": 1.4406
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 20761,
      "name": "El Arenal",
      "names": {
        "ja": "エルアレナル"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7754,
        "lng": -103.6935
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20699,
      "name": "Peshtera",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.034,
        "lng": 24.3025
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 7618,
      "name": "Saynshand",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 44.8917,
        "lng": 110.1367
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 20767,
      "name": "Torokszentmiklos",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1833,
        "lng": 20.4167
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 20782,
      "name": "Mangualde",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6,
        "lng": -7.7667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 20712,
      "name": "Kuysinjaq",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 36.0828,
        "lng": 44.6286
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 20825,
      "name": "Brandsen",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -35.1667,
        "lng": -58.2167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 20830,
      "name": "Hamina",
      "names": {
        "zh": "哈米纳"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.5697,
        "lng": 27.1981
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 7549,
      "name": "Canelones",
      "names": {
        "zh": "卡内洛内斯"
      },
      "countryCode": "UY",
      "latLng": {
        "lat": -34.5228,
        "lng": -56.2778
      },
      "country": "Uruguay",
      "importance": 1
    },
    {
      "id": 20613,
      "name": "Haren",
      "names": {
        "zh": "哈伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.1667,
        "lng": 6.6
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 20814,
      "name": "Comapa",
      "names": {
        "zh": "科马帕"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1667,
        "lng": -96.8666
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20646,
      "name": "Snina",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.9881,
        "lng": 22.1567
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 7379,
      "name": "Zimatlan de Alvarez",
      "names": {
        "ja": "ジマートランデアルバレス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8667,
        "lng": -96.7833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7620,
      "name": "Kavieng",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -2.5667,
        "lng": 150.8
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 20753,
      "name": "Kleppe",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.7772,
        "lng": 5.5967
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 20603,
      "name": "Tepetlixpa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0006,
        "lng": -98.8178
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20583,
      "name": "Dingolfing",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.6333,
        "lng": 12.5
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 20857,
      "name": "General Martin Miguel de Guemes",
      "names": {
        "fr": "Général Martin Miguel de Guemes",
        "zh": "一般马丁米格尔德·普雷斯",
        "it": "Generale Martin Miguel de Guemes",
        "id": "Jenderal Martin Miguel de Guemes"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.6667,
        "lng": -65.05
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7625,
      "name": "Inirida",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 3.8708,
        "lng": -67.9211
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 20624,
      "name": "Nesoddtangen",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.8086,
        "lng": 10.6556
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 20816,
      "name": "Ban Na San",
      "names": {
        "zh": "禁止纳圣",
        "ja": "禁止さん",
        "th": "บ้านนาซาน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.7997,
        "lng": 99.3635
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 20484,
      "name": "Annaberg-Buchholz",
      "names": {
        "zh": "安娜贝格-布赫霍尔茨"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.58,
        "lng": 13.0022
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 20855,
      "name": "Puerto Armuelles",
      "names": {
        "de": "Puerto-Armaturen",
        "it": "Puerto Arguells",
        "ja": "プエルトアルムセル"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.2777,
        "lng": -82.8621
      },
      "country": "Panama",
      "importance": 2
    },
    {
      "id": 20807,
      "name": "Os",
      "names": {
        "zh": "操作系统",
        "ja": "OSは",
        "th": "ระบบปฏิบัติการ"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.2269,
        "lng": 5.4758
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 20862,
      "name": "Bressuire",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 46.84,
        "lng": -0.4886
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 20898,
      "name": "Karcag",
      "names": {
        "zh": "考尔曹格"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3167,
        "lng": 20.9333
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 20669,
      "name": "Saint-Die-des-Vosges",
      "names": {
        "es": "Saint-morir des vosges",
        "fr": "Saint-mourir-des-Vosges",
        "zh": "圣 - 模具之-孚日",
        "id": "Saint-mati-des-vosges",
        "ja": "サン・ダイのdes-vosges",
        "th": "เซนต์ตายเดส์-vosges"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.2842,
        "lng": 6.9492
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 20928,
      "name": "Hongshui",
      "names": {
        "zh": "洪水"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 38.5077,
        "lng": 100.8814
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 20865,
      "name": "Busra ash Sham",
      "names": {
        "zh": "布什阿什假",
        "ja": "ブスラの灰の偽",
        "pt": "Shamsh Sham"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.5167,
        "lng": 36.4833
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 20780,
      "name": "Leek",
      "names": {
        "es": "Puerro",
        "fr": "Poireau",
        "zh": "韭菜",
        "de": "Lauch",
        "it": "Porro",
        "ja": "リーキ",
        "th": "กระเทียมหอม",
        "pt": "Alho frances"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.15,
        "lng": 6.3833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7207,
      "name": "Chiconcuac",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.55,
        "lng": -98.9
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20805,
      "name": "Komarom",
      "names": {
        "de": "Komárom",
        "it": "Komárom"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.74,
        "lng": 18.1217
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 7638,
      "name": "Telavi",
      "names": {
        "zh": "泰拉维"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 41.9167,
        "lng": 45.4833
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 20950,
      "name": "Urumita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.5603,
        "lng": -73.0136
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 20982,
      "name": "Rawah",
      "names": {},
      "countryCode": "IQ",
      "latLng": {
        "lat": 34.4792,
        "lng": 41.9083
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 20818,
      "name": "Eschwege",
      "names": {
        "zh": "埃施韦格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1881,
        "lng": 10.0528
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7359,
      "name": "Hidalgotitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.7833,
        "lng": -94.6333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21007,
      "name": "Heinola",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.2052,
        "lng": 26.0348
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 21026,
      "name": "Yellowknife",
      "names": {
        "zh": "耶洛奈夫",
        "ja": "イエローナイフ",
        "th": "เยลโลว์"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 62.4709,
        "lng": -114.4053
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 7478,
      "name": "Moulins",
      "names": {
        "zh": "穆兰"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.5647,
        "lng": 3.3325
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 21014,
      "name": "Candelaria",
      "names": {
        "zh": "坎德拉里亚"
      },
      "countryCode": "CU",
      "latLng": {
        "lat": 22.7439,
        "lng": -82.9581
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 21024,
      "name": "Hoxut",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 42.2552,
        "lng": 86.8607
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 20952,
      "name": "Naklo nad Notecia",
      "names": {
        "fr": "NAKLO NAD NOCIA",
        "it": "Naklo nad notacia"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1403,
        "lng": 17.5928
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 21040,
      "name": "Tapalpa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9445,
        "lng": -103.7585
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20924,
      "name": "Varpalota",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1972,
        "lng": 18.1394
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 7631,
      "name": "Faranah",
      "names": {
        "zh": "法拉纳"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.0404,
        "lng": -10.75
      },
      "country": "Guinea",
      "importance": 1
    },
    {
      "id": 21065,
      "name": "Jesus Maria",
      "names": {
        "fr": "Jésus Maria",
        "zh": "耶稣玛丽亚",
        "it": "Gesù Maria",
        "id": "Yesus Maria",
        "ja": "イエスマリア",
        "th": "พระเยซูมาเรีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6068,
        "lng": -102.223
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20934,
      "name": "Polonuevo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7772,
        "lng": -74.8528
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 20959,
      "name": "Jakobstad",
      "names": {
        "zh": "雅各布斯塔德"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.6667,
        "lng": 22.7
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 811,
      "name": "Ashgabat",
      "names": {
        "zh": "阿什哈巴德",
        "th": "อาชกาบัต"
      },
      "countryCode": "TM",
      "latLng": {
        "lat": 37.95,
        "lng": 58.3833
      },
      "country": "Turkmenistan",
      "importance": 0
    },
    {
      "id": 21098,
      "name": "Sipoo",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.3764,
        "lng": 25.2722
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 21116,
      "name": "Dalanzadgad",
      "names": {
        "zh": "达兰扎达嘎德"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 43.57,
        "lng": 104.4258
      },
      "country": "Mongolia",
      "importance": 2
    },
    {
      "id": 21082,
      "name": "Kantharalak",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6536,
        "lng": 104.6278
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 20977,
      "name": "Keszthely",
      "names": {
        "zh": "凯斯特海伊",
        "ja": "ケストヘイ"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.7675,
        "lng": 17.2463
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 7234,
      "name": "Coquimatlan",
      "names": {
        "es": "Coquimatlán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2038,
        "lng": -103.8086
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7653,
      "name": "Lai",
      "names": {
        "zh": "叶面积指数",
        "ja": "ライ",
        "th": "ลาย"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 9.4,
        "lng": 16.3
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 7658,
      "name": "Tchibanga",
      "names": {
        "zh": "奇班加"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -2.9331,
        "lng": 10.9831
      },
      "country": "Gabon",
      "importance": 1
    },
    {
      "id": 20892,
      "name": "Selestat",
      "names": {
        "zh": "赛勒斯塔特",
        "de": "Sélestat"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.2594,
        "lng": 7.4542
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 21145,
      "name": "Balta",
      "names": {
        "zh": "巴尔塔",
        "th": "บอลต้า"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.9361,
        "lng": 29.6225
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 21054,
      "name": "Amatan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.3667,
        "lng": -92.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20946,
      "name": "Ban Bueng",
      "names": {
        "fr": "Banancier",
        "ja": "バンブンジェン",
        "th": "บ้านบึง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.314,
        "lng": 101.1114
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7400,
      "name": "Jose Cardel",
      "names": {
        "zh": "何塞心皮"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3667,
        "lng": -96.3667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20664,
      "name": "Weesp",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3069,
        "lng": 5.0417
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21042,
      "name": "Someren",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.3847,
        "lng": 5.7119
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7388,
      "name": "Mzimba",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -11.9,
        "lng": 33.6
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 21186,
      "name": "Korsholm",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.1125,
        "lng": 21.6778
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 21203,
      "name": "Dolynska",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.1111,
        "lng": 32.7648
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 21164,
      "name": "Lieto",
      "names": {
        "zh": "列托"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.5,
        "lng": 22.4497
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 7661,
      "name": "Tillaberi",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 14.212,
        "lng": 1.4531
      },
      "country": "Niger",
      "importance": 1
    },
    {
      "id": 21202,
      "name": "Henichesk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.1769,
        "lng": 34.7989
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 21160,
      "name": "Haldensleben",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.2833,
        "lng": 11.4167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 21192,
      "name": "Soure",
      "names": {
        "zh": "索里"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.05,
        "lng": -8.6333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 21188,
      "name": "Moncao",
      "names": {
        "zh": "蒙桑",
        "pt": "Monção"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 42.0733,
        "lng": -8.48
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 21129,
      "name": "Pultusk",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.7025,
        "lng": 21.0828
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 21078,
      "name": "Magitang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.9484,
        "lng": 102.0273
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 20940,
      "name": "Kufstein",
      "names": {
        "zh": "库夫施泰因"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.5833,
        "lng": 12.1667
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 7656,
      "name": "Budva",
      "names": {
        "ja": "ブドヴァ"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.2847,
        "lng": 18.8453
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 21225,
      "name": "Kabudarahang",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.2083,
        "lng": 48.7239
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 20890,
      "name": "Tingloy",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.65,
        "lng": 120.8667
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 20571,
      "name": "Albertville",
      "names": {
        "zh": "阿尔贝维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.6758,
        "lng": 6.3925
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 21177,
      "name": "Texcaltitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9297,
        "lng": -99.9372
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21248,
      "name": "Lumphat",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 13.507,
        "lng": 106.981
      },
      "country": "Cambodia",
      "importance": 2
    },
    {
      "id": 21237,
      "name": "Jamiltepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.2783,
        "lng": -97.82
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7617,
      "name": "Attapu",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 14.8,
        "lng": 106.8333
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 7624,
      "name": "Sligo",
      "names": {
        "zh": "斯莱戈",
        "ja": "スライゴ",
        "th": "สลิโก"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 54.2667,
        "lng": -8.4833
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 20938,
      "name": "San Jose Ojetenam",
      "names": {
        "zh": "圣何塞ojetenam",
        "ja": "サンノゼオヘテナム"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.2167,
        "lng": -91.9667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 20996,
      "name": "Roznava",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.6586,
        "lng": 20.5314
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 20957,
      "name": "Ziar nad Hronom",
      "names": {
        "zh": "ziar nad hromen"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.5919,
        "lng": 18.8533
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 21264,
      "name": "Vera",
      "names": {
        "zh": "维拉",
        "ja": "ヴェラ",
        "th": "หางจระเข้"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -29.4667,
        "lng": -60.2167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 21131,
      "name": "Izra",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.8567,
        "lng": 36.2469
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 21089,
      "name": "Vila Real de Santo Antonio",
      "names": {
        "pt": "Vila Real de Santo Antônio"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.2,
        "lng": -7.4167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 21278,
      "name": "Pastavy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.1167,
        "lng": 26.8333
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 7516,
      "name": "Senguio",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7539,
        "lng": -100.3377
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7675,
      "name": "Pljevlja",
      "names": {
        "zh": "普列夫利亚"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 43.3566,
        "lng": 19.3502
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 21280,
      "name": "La Ligua",
      "names": {
        "ja": "ラリグア"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -32.4494,
        "lng": -71.2317
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 21307,
      "name": "Ashkezar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.0,
        "lng": 54.2044
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 21247,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9728,
        "lng": -75.3611
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 21185,
      "name": "Eersel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.3572,
        "lng": 5.3147
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21171,
      "name": "San Antonio Sacatepequez",
      "names": {
        "fr": "San Antonio SacatePequezez",
        "de": "San Antonio Sacatpequez",
        "th": "ซานอันโตนิโอ Sacatepequez"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9667,
        "lng": -91.7333
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 20409,
      "name": "Chodziez",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.9901,
        "lng": 16.9122
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7059,
      "name": "Cheran",
      "names": {
        "zh": "切兰"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6833,
        "lng": -101.95
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21317,
      "name": "Ixtlahuacan del Rio",
      "names": {
        "id": "Ixtahuacan del rio"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8667,
        "lng": -103.25
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 20643,
      "name": "Le Puy-en-Velay",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.0433,
        "lng": 3.885
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7654,
      "name": "Son La",
      "names": {
        "es": "Hijo la",
        "fr": "Fils la",
        "zh": "儿子洛杉矶",
        "de": "Sohn la",
        "it": "Figlio la",
        "id": "Putra la",
        "ja": "息子のLa",
        "th": "ลูกชายลา",
        "pt": "Filho la"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.327,
        "lng": 103.9141
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 21337,
      "name": "Preveza",
      "names": {
        "zh": "普雷韦扎"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.95,
        "lng": 20.75
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 21217,
      "name": "Sommerda",
      "names": {
        "de": "Sömmerda"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.1617,
        "lng": 11.1169
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 21352,
      "name": "Pruzhany",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.5567,
        "lng": 24.4644
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 21355,
      "name": "Proletarsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.7031,
        "lng": 41.7192
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 20860,
      "name": "Saint-Lo",
      "names": {
        "zh": "圣 - 罗",
        "ja": "サン・LOの",
        "th": "นักบุญทองหล่อ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.1144,
        "lng": -1.0917
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7243,
      "name": "Cotija de la Paz",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.81,
        "lng": -102.7047
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21000,
      "name": "Riom",
      "names": {
        "zh": "里永"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.8936,
        "lng": 3.1125
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7685,
      "name": "Mao",
      "names": {
        "zh": "毛泽东",
        "ja": "真央",
        "th": "เหมา"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 14.1194,
        "lng": 15.3133
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 21148,
      "name": "Wittlich",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.9869,
        "lng": 6.8897
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 21309,
      "name": "Vista Hermosa de Negrete",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2728,
        "lng": -102.4806
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7202,
      "name": "Heredia",
      "names": {
        "zh": "埃雷迪亚",
        "ja": "エレディア"
      },
      "countryCode": "CR",
      "latLng": {
        "lat": 9.9985,
        "lng": -84.1169
      },
      "country": "Costa Rica",
      "importance": 1
    },
    {
      "id": 7659,
      "name": "Macas",
      "names": {
        "zh": "马卡斯",
        "pt": "Maçãs"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -2.3,
        "lng": -78.1167
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 21259,
      "name": "El Quetzal",
      "names": {
        "ja": "エルケツァル"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7667,
        "lng": -91.8167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 21240,
      "name": "Dillingen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.5667,
        "lng": 10.4667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 20990,
      "name": "Norak",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 38.3883,
        "lng": 69.325
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 21277,
      "name": "Tak Bai",
      "names": {
        "zh": "塔布",
        "ja": "タクバイ",
        "th": "ตากใบ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.2592,
        "lng": 102.053
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7646,
      "name": "Mubende",
      "names": {
        "zh": "穆本德"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.5904,
        "lng": 31.37
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 20981,
      "name": "Malacky",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.4381,
        "lng": 17.0236
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 20999,
      "name": "Meerssen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.8858,
        "lng": 5.7519
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21428,
      "name": "Contamana",
      "names": {},
      "countryCode": "PE",
      "latLng": {
        "lat": -7.3493,
        "lng": -75.0086
      },
      "country": "Peru",
      "importance": 2
    },
    {
      "id": 21340,
      "name": "Zuidhorn",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2468,
        "lng": 6.4077
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21408,
      "name": "Naantali",
      "names": {
        "zh": "纳坦利"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4681,
        "lng": 22.0264
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 21432,
      "name": "Pirkkala",
      "names": {
        "zh": "皮尔卡拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4667,
        "lng": 23.65
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 21402,
      "name": "Kalynivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4472,
        "lng": 28.5231
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 21425,
      "name": "Tado",
      "names": {
        "zh": "多度"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.2633,
        "lng": -76.56
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 21228,
      "name": "Dolny Kubin",
      "names": {
        "pt": "Golny kubin"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.2117,
        "lng": 19.2992
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 21439,
      "name": "Mo i Rana",
      "names": {
        "zh": "莫伊尔朗纳",
        "de": "Mo ich rana",
        "ja": "Mo Irana",
        "pt": "Mo eu rana"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 66.3167,
        "lng": 14.1667
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 21395,
      "name": "Jiaoxiyakou",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 26.1274,
        "lng": 102.4502
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 7684,
      "name": "Ribeira Grande",
      "names": {
        "ja": "リベイラグランデ"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 17.1833,
        "lng": -25.0667
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 21312,
      "name": "Amares",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.6333,
        "lng": -8.35
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 21431,
      "name": "Polohy",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.4796,
        "lng": 36.2611
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 21422,
      "name": "Naousa",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.6306,
        "lng": 22.0642
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 7690,
      "name": "Paraguari",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -25.62,
        "lng": -57.16
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 7694,
      "name": "Hun",
      "names": {
        "zh": "浑",
        "de": "Hunne",
        "ja": "フン族",
        "th": "หุ่น"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 29.1167,
        "lng": 15.9333
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 7689,
      "name": "Lagawe",
      "names": {
        "zh": "拉加韦"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 16.8167,
        "lng": 121.1
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 21206,
      "name": "Groesbeek",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7833,
        "lng": 5.9333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21442,
      "name": "Laukaa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.4167,
        "lng": 25.95
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 21407,
      "name": "Stord",
      "names": {
        "zh": "斯图尔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.8081,
        "lng": 5.4664
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 21392,
      "name": "Prenzlau",
      "names": {
        "zh": "普伦茨劳"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.3167,
        "lng": 13.8667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7695,
      "name": "Kimbe",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -5.55,
        "lng": 150.143
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 21459,
      "name": "Piatykhatky",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.4126,
        "lng": 33.7034
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 21394,
      "name": "Qitai",
      "names": {
        "zh": "奇台"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 41.5494,
        "lng": 113.5339
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 21451,
      "name": "Velez",
      "names": {
        "es": "Vélez",
        "zh": "贝莱斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0131,
        "lng": -73.6736
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 21258,
      "name": "Huehuetla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1075,
        "lng": -97.6233
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21128,
      "name": "Bad Tolz",
      "names": {
        "es": "Mal tolz",
        "fr": "Mauvais tolz",
        "zh": "糟糕的totz",
        "de": "Böse Tölz",
        "it": "Cattivo tolz",
        "id": "Tolz buruk",
        "ja": "悪いトルツ",
        "pt": "Mau tolz"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.7603,
        "lng": 11.5567
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 21495,
      "name": "Sortavala",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 61.7,
        "lng": 30.6667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 21231,
      "name": "Druten",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8889,
        "lng": 5.6044
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21490,
      "name": "Starobilsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.2667,
        "lng": 38.9167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 21464,
      "name": "Tocaima",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.4578,
        "lng": -74.6347
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 21435,
      "name": "Paks",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6219,
        "lng": 18.8558
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 21288,
      "name": "Tlalnelhuayocan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5667,
        "lng": -96.9667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7713,
      "name": "Bitlis",
      "names": {
        "zh": "比特利斯",
        "ja": "ビトリス"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4,
        "lng": 42.1167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 7704,
      "name": "Mansa Konko",
      "names": {
        "ja": "マンサコンコ"
      },
      "countryCode": "GM",
      "latLng": {
        "lat": 13.3773,
        "lng": -15.6786
      },
      "country": "The Gambia",
      "importance": 1
    },
    {
      "id": 7719,
      "name": "Siyazan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.0783,
        "lng": 49.1061
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 21567,
      "name": "Vovchansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.2881,
        "lng": 36.9461
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7420,
      "name": "Aldama",
      "names": {
        "zh": "阿尔达马"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.8386,
        "lng": -105.9111
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21519,
      "name": "Soma",
      "names": {
        "zh": "索马",
        "ja": "相馬",
        "th": "โสม"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.1833,
        "lng": 27.6056
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 21527,
      "name": "Orodara",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 10.974,
        "lng": -4.908
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 21114,
      "name": "Krasnystaw",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.0,
        "lng": 23.1667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 20873,
      "name": "Cognac",
      "names": {
        "es": "Coñac",
        "zh": "干邑",
        "ja": "コニャック",
        "th": "คอนยัค",
        "pt": "Conhaque"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.6958,
        "lng": -0.3292
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 21500,
      "name": "Oirschot",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5047,
        "lng": 5.3128
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21096,
      "name": "Starodub",
      "names": {
        "zh": "斯塔罗杜布"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 52.5833,
        "lng": 32.7667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 7724,
      "name": "Sabirabad",
      "names": {
        "zh": "萨比拉巴德"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.0053,
        "lng": 48.4719
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 7711,
      "name": "Hinche",
      "names": {
        "zh": "安什"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.143,
        "lng": -72.004
      },
      "country": "Haiti",
      "importance": 1
    },
    {
      "id": 7732,
      "name": "Tarrafal",
      "names": {
        "zh": "塔拉法尔"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.2787,
        "lng": -23.7516
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 21515,
      "name": "Heerde",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4,
        "lng": 6.0667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21533,
      "name": "Marktoberdorf",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 47.7667,
        "lng": 10.6167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 21344,
      "name": "Alzey",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7517,
        "lng": 8.1161
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 21321,
      "name": "Gostynin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.4294,
        "lng": 19.4619
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 21652,
      "name": "Asuncion Nochixtlan",
      "names": {
        "es": "Asunción nochixtlan",
        "zh": "亚松森诺奇克兰",
        "it": "Asuncion Nchixtlan",
        "pt": "Assunção Nochixtlan"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.4581,
        "lng": -97.2233
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7733,
      "name": "Tsetserleg",
      "names": {
        "zh": "车车尔勒格"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 47.4769,
        "lng": 101.4503
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 7701,
      "name": "Visaginas",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.6,
        "lng": 26.4333
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 20881,
      "name": "Enkhuizen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.7,
        "lng": 5.2833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7707,
      "name": "Harnosand",
      "names": {
        "id": "Härnösand"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 62.6323,
        "lng": 17.9379
      },
      "country": "Sweden",
      "importance": 1
    },
    {
      "id": 21565,
      "name": "Bergeijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.3203,
        "lng": 5.3592
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21667,
      "name": "Kherameh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.5,
        "lng": 53.3164
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 21472,
      "name": "Monor",
      "names": {
        "zh": "莫诺尔"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3475,
        "lng": 19.4489
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 21579,
      "name": "Bad Durkheim",
      "names": {
        "es": "Mal durkheim",
        "zh": "糟糕的durkheim",
        "de": "Bad Dürkheim",
        "it": "Cattivo durkheim",
        "ja": "悪いDurkheim"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.4618,
        "lng": 8.1724
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 21584,
      "name": "Weissenburg",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.0306,
        "lng": 10.9719
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 21700,
      "name": "Taft",
      "names": {
        "zh": "塔夫脱",
        "th": "เทฟท์"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 31.7475,
        "lng": 54.2042
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 21657,
      "name": "Miedzyrzecz",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.4446,
        "lng": 15.578
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 21690,
      "name": "Nara",
      "names": {
        "zh": "奈良",
        "ja": "奈良",
        "th": "นารา"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 15.18,
        "lng": -7.28
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 7469,
      "name": "Coalcoman de Vazquez Pallares",
      "names": {
        "es": "Coalcoman de Vázquez Pallares",
        "fr": "COALCOMAN DE VAZQUEZ PALALARES",
        "zh": "煤炭德·维萨克斯普拉雷斯",
        "ja": "コールマンデバズケスパラエス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.7833,
        "lng": -103.1667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21673,
      "name": "Ponedera",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.6419,
        "lng": -74.7531
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 21614,
      "name": "Orestiada",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 41.5,
        "lng": 26.5333
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 21722,
      "name": "Svatove",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4092,
        "lng": 38.1619
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 21663,
      "name": "San Blas Atempa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3167,
        "lng": -95.2167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7741,
      "name": "Straseni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.1414,
        "lng": 28.6103
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 21576,
      "name": "Filomeno Mata",
      "names": {
        "id": "Filomeno tata"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2,
        "lng": -97.7
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21751,
      "name": "Nytva",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.9333,
        "lng": 55.3333
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 7182,
      "name": "Santiago Ixcuintla",
      "names": {
        "fr": "Santiago ixcutintla",
        "it": "Santiago Ixcuinta"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.811,
        "lng": -105.2079
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21665,
      "name": "Campo de la Cruz",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.3778,
        "lng": -74.8814
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 21357,
      "name": "Banovce nad Bebravou",
      "names": {
        "es": "Banove nad bebravou",
        "de": "Banovce nad Braun"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7186,
        "lng": 18.2581
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 812,
      "name": "Nuuk",
      "names": {
        "zh": "努克",
        "ja": "ヌーク",
        "th": "นุก"
      },
      "countryCode": "GL",
      "latLng": {
        "lat": 64.175,
        "lng": -51.7333
      },
      "country": "Greenland",
      "importance": 0
    },
    {
      "id": 21704,
      "name": "Bekes",
      "names": {
        "de": "Békés"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.7769,
        "lng": 21.125
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 21664,
      "name": "Tha Bo",
      "names": {
        "zh": "博博",
        "th": "ท่าบ่อ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.8494,
        "lng": 102.5858
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 21718,
      "name": "San Jose Tenango",
      "names": {
        "zh": "圣何塞Tenango",
        "id": "San Jose Tenaango",
        "ja": "サンノゼテナンゴ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.15,
        "lng": -96.7333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21780,
      "name": "Dobrush",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.4167,
        "lng": 31.3167
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 7460,
      "name": "Mchinji",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -13.8167,
        "lng": 32.9
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 21791,
      "name": "Charata",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -27.2144,
        "lng": -61.1881
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 21545,
      "name": "Bad Reichenhall",
      "names": {
        "es": "Mal reichenhall",
        "zh": "坏乐园",
        "id": "Reichenhall buruk",
        "ja": "悪いレイチェンボール",
        "th": "Reichenhall ที่ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.7247,
        "lng": 12.8769
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 21802,
      "name": "Lobos",
      "names": {
        "zh": "洛沃斯",
        "ja": "ロボス",
        "th": "บอส"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -35.1864,
        "lng": -59.0961
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 21639,
      "name": "Kristiansund",
      "names": {
        "zh": "克里斯蒂安松"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 63.1105,
        "lng": 7.7279
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 21818,
      "name": "Isfana",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 39.8389,
        "lng": 69.5275
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 7759,
      "name": "Kedougou",
      "names": {
        "fr": "Kédougou",
        "zh": "凯杜古"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 12.5556,
        "lng": -12.1807
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 21851,
      "name": "Pieksamaki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.3,
        "lng": 27.1583
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 7762,
      "name": "Filadelfia",
      "names": {
        "zh": "菲拉德尔菲亚"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -22.3333,
        "lng": -60.0167
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 21795,
      "name": "Sikhio",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 14.8764,
        "lng": 101.7202
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 21899,
      "name": "Cuautitlan",
      "names": {
        "es": "Cuautitlán"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4333,
        "lng": -104.3
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21911,
      "name": "Feyzabad",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.0189,
        "lng": 58.7833
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 21917,
      "name": "Coronda",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -31.9667,
        "lng": -60.9167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 21940,
      "name": "General Juan Madariaga",
      "names": {
        "fr": "Général Juan Madariaga",
        "zh": "祝娟麦拉亚省",
        "it": "Generale Juan Madariaga",
        "id": "Jenderal Juan Madariaga",
        "ja": "ジェネラルファンマダリアガ",
        "th": "นายพลฮวนมาดาร์เลย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.0167,
        "lng": -57.1333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 21447,
      "name": "Hardinxveld-Giessendam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.83,
        "lng": 4.87
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21766,
      "name": "Middelharnis",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7535,
        "lng": 4.1647
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21881,
      "name": "Parchim",
      "names": {
        "zh": "帕尔希姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.4167,
        "lng": 11.8333
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 21855,
      "name": "Lask",
      "names": {
        "zh": "拉斯克"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.5924,
        "lng": 19.1332
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7780,
      "name": "Porto Novo",
      "names": {
        "zh": "波尔图诺沃",
        "ja": "ポルトノノ"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 17.0195,
        "lng": -25.0646
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 21306,
      "name": "Luneville",
      "names": {
        "fr": "Lunéville",
        "de": "Lunéville",
        "it": "Lunéville"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5894,
        "lng": 6.5017
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7706,
      "name": "Rwamagana",
      "names": {},
      "countryCode": "RW",
      "latLng": {
        "lat": -1.9525,
        "lng": 30.4378
      },
      "country": "Rwanda",
      "importance": 1
    },
    {
      "id": 21861,
      "name": "Carsamba",
      "names": {
        "zh": "恰尔尚巴"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.198,
        "lng": 36.726
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 7783,
      "name": "Orange Walk",
      "names": {
        "es": "Paseo naranja",
        "fr": "Marche orange",
        "zh": "橙色步行",
        "de": "Orangefarben",
        "it": "Arancia",
        "id": "Berjalan-jalan",
        "ja": "オレンジウォーク",
        "th": "สีส้มเดิน",
        "pt": "Caminhada laranja"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 18.075,
        "lng": -88.5583
      },
      "country": "Belize",
      "importance": 1
    },
    {
      "id": 21856,
      "name": "Dombovar",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.3769,
        "lng": 18.131
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 21269,
      "name": "Cuquio",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9275,
        "lng": -103.0239
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22012,
      "name": "Sabana de Torres",
      "names": {
        "ja": "サバナデトーレス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.3903,
        "lng": -73.5003
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7723,
      "name": "Ptuj",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4186,
        "lng": 15.8714
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 7775,
      "name": "Torit",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 4.4167,
        "lng": 32.5667
      },
      "country": "South Sudan",
      "importance": 1
    },
    {
      "id": 21895,
      "name": "Juanacatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5,
        "lng": -103.1667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21736,
      "name": "Bodegraven",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0808,
        "lng": 4.7486
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7412,
      "name": "Mapastepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.4417,
        "lng": -92.8917
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22016,
      "name": "Po",
      "names": {
        "zh": "采购订单",
        "ja": "POを",
        "th": "ปณ"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 11.1667,
        "lng": -1.15
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 7619,
      "name": "Kapsabet",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.2,
        "lng": 35.1
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 22042,
      "name": "Union de San Antonio",
      "names": {
        "es": "Unión de san antonio",
        "zh": "联盟de San Antonio",
        "ja": "ユニオンデサンアントニオ",
        "th": "ยูเนี่ยนเดอซานอันโตนิโอ",
        "pt": "União de San Antonio"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.128,
        "lng": -102.006
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22025,
      "name": "Pelaya",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.6892,
        "lng": -73.6653
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7768,
      "name": "Salaspils",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.8614,
        "lng": 24.35
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 7786,
      "name": "Saatli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.9311,
        "lng": 48.3697
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 22056,
      "name": "Nguigmi",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 14.2532,
        "lng": 13.1108
      },
      "country": "Niger",
      "importance": 2
    },
    {
      "id": 22003,
      "name": "Oborniki",
      "names": {
        "zh": "奥博尔尼基"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.65,
        "lng": 16.8167
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 21882,
      "name": "Sint-Oedenrode",
      "names": {
        "zh": "圣彼-oedenrode",
        "ja": "シント・oedenrode",
        "th": "ซิงต์-oedenrode"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5636,
        "lng": 5.4608
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 22035,
      "name": "Zapotlan del Rey",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4674,
        "lng": -102.9248
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22071,
      "name": "Yuanquan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.5004,
        "lng": 95.8
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 7748,
      "name": "Zonguldak",
      "names": {
        "zh": "宗古尔达克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.45,
        "lng": 31.7833
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 7792,
      "name": "Sa Kaeo",
      "names": {
        "th": "สระแก้ว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8206,
        "lng": 102.0589
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 21822,
      "name": "San Kamphaeng",
      "names": {
        "zh": "圣坎皮",
        "it": "San Kamamphaeng",
        "ja": "サンカンファエン",
        "th": "สันกำแพง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.75,
        "lng": 99.1167
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 22087,
      "name": "Kongsvinger",
      "names": {
        "zh": "孔斯温厄尔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.1912,
        "lng": 11.9992
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 7771,
      "name": "Sing Buri",
      "names": {
        "es": "Cantar buri",
        "fr": "Chanter Buri",
        "zh": "唱伯里",
        "it": "Cantare Buri",
        "id": "Bernyanyi buri",
        "ja": "ブリを歌う",
        "th": "สิงห์บุรี",
        "pt": "Cantar buri"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.8911,
        "lng": 100.4031
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 22076,
      "name": "San Bartolo Tutotepec",
      "names": {
        "zh": "圣巴托洛图德普克",
        "ja": "サンバルトロTutotepec",
        "pt": "San Bartolo TutôEpec"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4,
        "lng": -98.2
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22067,
      "name": "Ommen",
      "names": {
        "zh": "奥门"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5167,
        "lng": 6.4167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21843,
      "name": "Puchov",
      "names": {
        "zh": "普霍夫"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.12,
        "lng": 18.3306
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 22107,
      "name": "Salgar",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9639,
        "lng": -75.9775
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 21980,
      "name": "Oroszlany",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4892,
        "lng": 18.3164
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 22141,
      "name": "Hlybokaye",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.1333,
        "lng": 27.6833
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 21959,
      "name": "Anda",
      "names": {
        "zh": "安达",
        "id": "Andari",
        "th": "อันดา"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.744,
        "lng": 124.576
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 7803,
      "name": "Khasab",
      "names": {},
      "countryCode": "OM",
      "latLng": {
        "lat": 26.1833,
        "lng": 56.25
      },
      "country": "Oman",
      "importance": 1
    },
    {
      "id": 7766,
      "name": "Prachin Buri",
      "names": {
        "th": "ปราจีนบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.0567,
        "lng": 101.3739
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 21343,
      "name": "Rawa Mazowiecka",
      "names": {
        "fr": "Raw mazowiecka",
        "th": "raawa mazowiecka"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.7667,
        "lng": 20.25
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 21871,
      "name": "Klaeng",
      "names": {
        "th": "แกลง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.7778,
        "lng": 101.6483
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 21102,
      "name": "Lancut",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0687,
        "lng": 22.2291
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7663,
      "name": "Ban Houayxay",
      "names": {
        "zh": "禁止houayxay",
        "ja": "houayxayを禁止します",
        "th": "บ้านห้วยเซย์",
        "pt": "Ban Houayxoy"
      },
      "countryCode": "LA",
      "latLng": {
        "lat": 20.2833,
        "lng": 100.4167
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 7696,
      "name": "Dong Ha",
      "names": {
        "zh": "董哈",
        "ja": "ドンハ",
        "th": "ดงฮ่า"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 16.8056,
        "lng": 107.0906
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 7778,
      "name": "Tunapuna",
      "names": {
        "zh": "图纳普纳"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.6333,
        "lng": -61.3833
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 22219,
      "name": "Arsanjan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 29.9122,
        "lng": 53.3083
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 22264,
      "name": "La Primavera",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4906,
        "lng": -70.4092
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 22205,
      "name": "Benito Juarez",
      "names": {
        "es": "Benito Juárez",
        "ja": "ベニートフアレス",
        "th": "เบนิโต Juarez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8833,
        "lng": -98.2
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 21619,
      "name": "Sam Phran",
      "names": {
        "zh": "萨姆·普尔",
        "ja": "サムプラーン",
        "th": "สามพราน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.727,
        "lng": 100.2153
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 22223,
      "name": "Totoro",
      "names": {
        "zh": "龙猫"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.5103,
        "lng": -76.4019
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 22179,
      "name": "Lousa",
      "names": {
        "es": "Lousã",
        "fr": "Lousã",
        "de": "Lousã",
        "pt": "Lousã"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.1125,
        "lng": -8.2469
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 21748,
      "name": "Garwolin",
      "names": {
        "zh": "加尔沃林"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.9,
        "lng": 21.6167
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 22256,
      "name": "Tougan",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 13.0667,
        "lng": -3.0667
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 21942,
      "name": "Muhradah",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.2478,
        "lng": 36.5725
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 22126,
      "name": "Aliaga",
      "names": {
        "zh": "阿利阿"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.8008,
        "lng": 26.9728
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 21962,
      "name": "Yupiltepeque",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.1941,
        "lng": -89.7913
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 22298,
      "name": "Compostela",
      "names": {
        "fr": "Compostelle",
        "zh": "波斯特拉",
        "ja": "コンポステラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2389,
        "lng": -104.9
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22198,
      "name": "Alcochete",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7553,
        "lng": -8.9608
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 7824,
      "name": "Tak",
      "names": {
        "zh": "德",
        "th": "ตาก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8711,
        "lng": 99.125
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 22227,
      "name": "Sipacapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.2128,
        "lng": -91.6342
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 7679,
      "name": "Pakwach",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.45,
        "lng": 31.5
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 21379,
      "name": "Salcaja",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8833,
        "lng": -91.45
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 22051,
      "name": "Marmande",
      "names": {
        "zh": "马尔芒德"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.5,
        "lng": 0.1653
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 22217,
      "name": "Guidan Roumdji",
      "names": {},
      "countryCode": "NE",
      "latLng": {
        "lat": 13.6575,
        "lng": 6.6958
      },
      "country": "Niger",
      "importance": 2
    },
    {
      "id": 21638,
      "name": "Moloacan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9833,
        "lng": -94.35
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7828,
      "name": "Fuzuli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.6003,
        "lng": 47.1431
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 22296,
      "name": "Boulsa",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.6667,
        "lng": -0.5833
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 22344,
      "name": "Karukh",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 34.4868,
        "lng": 62.5918
      },
      "country": "Afghanistan",
      "importance": 2
    },
    {
      "id": 22295,
      "name": "Dabas",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1833,
        "lng": 19.3167
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 7524,
      "name": "Pajapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.2667,
        "lng": -94.6833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7381,
      "name": "Tepatlaxco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0667,
        "lng": -97.9667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7829,
      "name": "Phayao",
      "names": {
        "zh": "拍尧",
        "th": "พะเยา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.1652,
        "lng": 99.9036
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 22285,
      "name": "Marche-en-Famenne",
      "names": {
        "zh": "马尔什昂法梅讷",
        "ja": "マルシェ-専用famenne",
        "th": "มาร์เก-ห้อง famenne"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.2275,
        "lng": 5.3444
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 22408,
      "name": "Puerto Aysen",
      "names": {
        "zh": "波多黎各",
        "ja": "プエルトアイザン",
        "th": "เปอร์โต aysen"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -45.4068,
        "lng": -72.6977
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 22400,
      "name": "Montemor-o-Novo",
      "names": {
        "zh": "montemor-o-诺和",
        "ja": "montemor-o-ノボ",
        "th": "montemor-o-โนโว"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.65,
        "lng": -8.2167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 22415,
      "name": "Veinticinco de Mayo",
      "names": {
        "fr": "Veeticinco de Mayo",
        "de": "VEINTICININCO de Mayo"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.771,
        "lng": -67.7173
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 22248,
      "name": "Totutla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2167,
        "lng": -96.9667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22366,
      "name": "Forssa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8167,
        "lng": 23.6167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 22385,
      "name": "Guaranda",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.4672,
        "lng": -74.5367
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 22399,
      "name": "Bolhrad",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.6855,
        "lng": 28.6134
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 22137,
      "name": "Pontarlier",
      "names": {
        "zh": "蓬塔利耶"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.9061,
        "lng": 6.3547
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 22326,
      "name": "Alotenango",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4878,
        "lng": -90.8058
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 22424,
      "name": "Eldorado",
      "names": {
        "zh": "埃尔多拉多",
        "ja": "エルドラド",
        "th": "เอลโดราโด"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.4,
        "lng": -54.6333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 22276,
      "name": "Brignoles",
      "names": {
        "th": "บริกโนลส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.4058,
        "lng": 6.0617
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 22460,
      "name": "Ayacucho",
      "names": {
        "zh": "阿亚库乔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.1525,
        "lng": -58.4882
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7827,
      "name": "Mehtar Lam",
      "names": {
        "ja": "メヒタールラム"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.6683,
        "lng": 70.2089
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 22389,
      "name": "Skadovsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.1167,
        "lng": 32.9167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7642,
      "name": "Lons-le-Saunier",
      "names": {
        "zh": "隆勒索涅",
        "ja": "lons・ル・saunier",
        "th": "lons-เลอ-saunier"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.6744,
        "lng": 5.5539
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 22423,
      "name": "Suesca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.1017,
        "lng": -73.7981
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 22444,
      "name": "Betulia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1122,
        "lng": -75.9839
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 22451,
      "name": "Berezhany",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4464,
        "lng": 24.9436
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 22258,
      "name": "As Suqaylibiyah",
      "names": {
        "es": "Como suqaylibiyah",
        "fr": "Comme Suqaylibiyah",
        "zh": "作为Suqaylibiyah",
        "de": "Als suqaylibiyah",
        "it": "Come suqaylibiyah",
        "id": "Sebagai suqaylibiyah",
        "ja": "Suqaylibiyahとして",
        "th": "เช่นเดียวกับ suqaylibiyah",
        "pt": "Como suqaylibiyah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.3697,
        "lng": 36.38
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 7313,
      "name": "Mecayapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.2167,
        "lng": -94.8333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22175,
      "name": "Ban Pong",
      "names": {
        "fr": "Interdiction",
        "zh": "禁止乒乓球",
        "de": "Ban-Pong",
        "it": "Divieto",
        "ja": "禁止ポン",
        "th": "บ้านพงษ์",
        "pt": "Proibir pong"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8174,
        "lng": 99.883
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 22470,
      "name": "Guryevsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.7667,
        "lng": 20.6
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 22390,
      "name": "Sigmaringen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0867,
        "lng": 9.2164
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 22488,
      "name": "Turhal",
      "names": {
        "zh": "图尔哈尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.3871,
        "lng": 36.0863
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 22143,
      "name": "San Felipe",
      "names": {
        "zh": "圣费利佩",
        "ja": "サンフェリペ",
        "th": "ซานเฟลิเป้"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6206,
        "lng": -91.5961
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 21880,
      "name": "Bad Segeberg",
      "names": {
        "fr": "Mauvais segeberg",
        "zh": "坏地贝格",
        "it": "Cattivo Segeberg",
        "id": "Segeberg Bad",
        "ja": "悪いSegeberg",
        "th": "Seegeberg ที่ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.9356,
        "lng": 10.3089
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 22442,
      "name": "Rattaphum",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 7.1412,
        "lng": 100.2905
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 22525,
      "name": "Tecolotlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2024,
        "lng": -104.0504
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22398,
      "name": "El Rosal",
      "names": {
        "it": "El rosale",
        "ja": "エルロロサ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8519,
        "lng": -74.2628
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7853,
      "name": "Illizi",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 26.508,
        "lng": 8.4829
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 22379,
      "name": "Echt",
      "names": {
        "zh": "化学疗法"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.1058,
        "lng": 5.8706
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21995,
      "name": "Waalre",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.3867,
        "lng": 5.4456
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 22518,
      "name": "Kangaba",
      "names": {
        "zh": "康加巴"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 11.9333,
        "lng": -8.4167
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 813,
      "name": "Belmopan",
      "names": {
        "zh": "贝尔莫潘"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 17.25,
        "lng": -88.7675
      },
      "country": "Belize",
      "importance": 0
    },
    {
      "id": 21810,
      "name": "Huitan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0486,
        "lng": -91.64
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 22027,
      "name": "Naarden",
      "names": {
        "zh": "纳尔登"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2953,
        "lng": 5.1622
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21973,
      "name": "Almolonga",
      "names": {
        "zh": "阿尔莫隆加"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8122,
        "lng": -91.4944
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 22178,
      "name": "Brielle",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9042,
        "lng": 4.1639
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 22582,
      "name": "Nallihan",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1883,
        "lng": 31.3509
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 7855,
      "name": "Trstenik",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.6186,
        "lng": 20.9972
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 22189,
      "name": "Verdun",
      "names": {
        "es": "Verdún",
        "zh": "凡尔登",
        "ja": "ヴェルダン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.1597,
        "lng": 5.3828
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 22605,
      "name": "Konstantinovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.5833,
        "lng": 41.1
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 22501,
      "name": "Sawankhalok",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.3099,
        "lng": 99.8263
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 22503,
      "name": "Peso da Regua",
      "names": {
        "ja": "ペソダレグア",
        "th": "เงินเปโซดา Regua",
        "pt": "Peso da Rugua"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1632,
        "lng": -7.789
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 7850,
      "name": "Marsabit",
      "names": {
        "zh": "马萨比特"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 2.3333,
        "lng": 37.9833
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 22584,
      "name": "Balmazujvaros",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6167,
        "lng": 21.35
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 22450,
      "name": "Heilbad Heiligenstadt",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 51.3775,
        "lng": 10.1344
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 22628,
      "name": "Ignacio de la Llave",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6618,
        "lng": -95.9721
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22232,
      "name": "Schijndel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6183,
        "lng": 5.4364
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 22633,
      "name": "San Juan del Sur",
      "names": {
        "zh": "圣胡安del sur",
        "ja": "サンファンデルシュル",
        "th": "ซานฮวนเดลซูร์"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.25,
        "lng": -85.8667
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 22509,
      "name": "Braunau am Inn",
      "names": {
        "zh": "Braunau am旅馆",
        "ja": "ブラウナウアムイン"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2583,
        "lng": 13.0333
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 22552,
      "name": "Mohacs",
      "names": {
        "zh": "莫哈奇"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 45.9958,
        "lng": 18.6797
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 22577,
      "name": "Condeixa-a-Nova",
      "names": {
        "fr": "Condeixa-a-nouvelle-",
        "zh": "孔代沙-一个-的Nova",
        "ja": "condeixa・ア・ノヴァ",
        "th": "condeixa รูปแบบโนวา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.1167,
        "lng": -8.5
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 22639,
      "name": "Zvenyhorodka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0833,
        "lng": 30.9667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7862,
      "name": "Leeuwarden",
      "names": {
        "zh": "吕伐登"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2,
        "lng": 5.7833
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 22575,
      "name": "Kozienice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.5833,
        "lng": 21.5667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 22428,
      "name": "Gennep",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6942,
        "lng": 5.9733
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 7869,
      "name": "Makokou",
      "names": {
        "zh": "马科库"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": 0.5667,
        "lng": 12.8667
      },
      "country": "Gabon",
      "importance": 1
    },
    {
      "id": 22563,
      "name": "Kempele",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.9125,
        "lng": 25.5083
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 22409,
      "name": "Kafr Zayta",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.3736,
        "lng": 36.6017
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 22718,
      "name": "Santa Maria",
      "names": {
        "zh": "圣玛丽亚",
        "ja": "サンタマリア",
        "th": "ซานตามาเรีย"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.6833,
        "lng": -66.0333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 22441,
      "name": "Zandvoort",
      "names": {
        "zh": "赞德福特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.3667,
        "lng": 4.5333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 22645,
      "name": "Staphorst",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.6333,
        "lng": 6.2
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 22609,
      "name": "Nederweert",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.2828,
        "lng": 5.7472
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 21761,
      "name": "Braniewo",
      "names": {
        "zh": "布拉涅沃"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 54.3833,
        "lng": 19.8333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 22726,
      "name": "Ihosy",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -22.3996,
        "lng": 46.1167
      },
      "country": "Madagascar",
      "importance": 2
    },
    {
      "id": 7875,
      "name": "Lodwar",
      "names": {
        "zh": "洛德瓦尔"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": 3.1167,
        "lng": 35.6
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 7879,
      "name": "San Ignacio",
      "names": {
        "th": "ซานอิกนาซิโอ"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 17.1588,
        "lng": -89.0696
      },
      "country": "Belize",
      "importance": 1
    },
    {
      "id": 22715,
      "name": "Retiro",
      "names": {
        "zh": "雷蒂罗"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0597,
        "lng": -75.5039
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 22447,
      "name": "Eutin",
      "names": {
        "zh": "奥伊廷"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.1378,
        "lng": 10.6181
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 22749,
      "name": "Ashmyany",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.425,
        "lng": 25.9375
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 22443,
      "name": "Alcantara",
      "names": {
        "zh": "阿尔坎塔拉",
        "ja": "アルカンタラ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.9715,
        "lng": 123.4047
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 22657,
      "name": "Cham",
      "names": {
        "zh": "湛",
        "ja": "チャム",
        "th": "จาม"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.2183,
        "lng": 12.6658
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 22429,
      "name": "Son en Breugel",
      "names": {
        "es": "Hijo en braugel",
        "fr": "Fils en breugel",
        "zh": "儿子en breugel",
        "de": "Sohn en bheugel",
        "it": "Figlio en breugel",
        "id": "Son en breuugel",
        "ja": "息子en Breugel",
        "pt": "Filho en breugel"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5158,
        "lng": 5.5022
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 22795,
      "name": "Dehaqan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 31.94,
        "lng": 51.6478
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 22725,
      "name": "Bytow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.1667,
        "lng": 17.5
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 22709,
      "name": "Diepholz",
      "names": {
        "zh": "迪普霍尔茨"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.6072,
        "lng": 8.3711
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7782,
      "name": "Viljandi",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 58.3633,
        "lng": 25.5956
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 22647,
      "name": "Kronach",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.2411,
        "lng": 11.3281
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7091,
      "name": "Sliema",
      "names": {
        "zh": "斯利马",
        "ja": "スリーマ",
        "th": "สลีมา"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9122,
        "lng": 14.5042
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 22772,
      "name": "Pran Buri",
      "names": {
        "zh": "普兰伯里",
        "ja": "プランブリー",
        "th": "ปราณบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.3939,
        "lng": 99.9159
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 22807,
      "name": "Sao Pedro do Sul",
      "names": {
        "ja": "サンペドロドスル",
        "pt": "São Pedro do Sul"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.75,
        "lng": -8.0667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 22813,
      "name": "Hajdunanas",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.85,
        "lng": 21.4333
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 7878,
      "name": "Marupe",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9069,
        "lng": 24.0575
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 22593,
      "name": "Brzesko",
      "names": {
        "zh": "布热斯科"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.9667,
        "lng": 20.6167
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7891,
      "name": "Keetmanshoop",
      "names": {
        "zh": "基特曼斯胡普"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -26.5833,
        "lng": 18.1333
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 7893,
      "name": "Astara",
      "names": {
        "zh": "阿斯塔拉"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.44,
        "lng": 48.875
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 7859,
      "name": "Ma\u0027rib",
      "names": {
        "zh": "马里卜"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 15.4228,
        "lng": 45.3375
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 22883,
      "name": "Kauhava",
      "names": {
        "zh": "考哈瓦"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.1014,
        "lng": 23.0639
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 7591,
      "name": "Qormi",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8794,
        "lng": 14.4722
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 7650,
      "name": "Solothurn",
      "names": {
        "zh": "索洛图恩",
        "th": "โซโลทูร์น"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.2081,
        "lng": 7.5375
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 7898,
      "name": "Puerto Carreno",
      "names": {
        "es": "Puerto Carreño",
        "fr": "Puerto Carréno",
        "zh": "波雷托克罗诺",
        "ja": "プエルトカロノー",
        "th": "เปอร์โตริโก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1889,
        "lng": -67.4858
      },
      "country": "Colombia",
      "importance": 1
    },
    {
      "id": 22751,
      "name": "Grojec",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8656,
        "lng": 20.8675
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 22890,
      "name": "El Pinon",
      "names": {
        "ja": "エルピノン",
        "pt": "Elixo"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4039,
        "lng": -74.8231
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 22568,
      "name": "Cajola",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9252,
        "lng": -91.616
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 7894,
      "name": "Plunge",
      "names": {
        "es": "Hundir",
        "fr": "Plonger",
        "zh": "跳水",
        "de": "Tauchen",
        "it": "Tuffo",
        "id": "Terjun",
        "ja": "プランジ",
        "th": "กระโดด",
        "pt": "Mergulho"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9167,
        "lng": 21.85
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 22796,
      "name": "Shahba",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.8539,
        "lng": 36.6294
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 22843,
      "name": "Chinampa de Gorostiza",
      "names": {
        "ja": "チャンパデゴロスザ",
        "th": "ไชน่าเดอกอร์สซอซะ",
        "pt": "Chinama de Gorostiza"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.3667,
        "lng": -97.7333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22842,
      "name": "Diksmuide",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0333,
        "lng": 2.8667
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 22898,
      "name": "Kaiwen",
      "names": {
        "zh": "开文"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.1548,
        "lng": 99.8371
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 22916,
      "name": "Ponte de Sor",
      "names": {
        "ja": "ポンテデソル"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.25,
        "lng": -8.0167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 22773,
      "name": "Krapkowice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.4751,
        "lng": 17.9654
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7812,
      "name": "Kukes",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 42.0758,
        "lng": 20.4231
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 22787,
      "name": "Kelheim",
      "names": {
        "zh": "凯尔海姆"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.9167,
        "lng": 11.8667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 22774,
      "name": "Asten",
      "names": {
        "zh": "阿斯滕"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4031,
        "lng": 5.7472
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 22892,
      "name": "Cabeceiras de Basto",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5167,
        "lng": -8.0
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 7901,
      "name": "Tukums",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9669,
        "lng": 23.1531
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 22757,
      "name": "Jilotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6113,
        "lng": -96.9224
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7889,
      "name": "Phrae",
      "names": {
        "th": "แพร่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.1436,
        "lng": 100.1417
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 22954,
      "name": "Zequ",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.0376,
        "lng": 101.4606
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 22964,
      "name": "Federacion",
      "names": {
        "fr": "Fédération",
        "zh": "联合会"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.9833,
        "lng": -57.9167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 22956,
      "name": "Guroymak",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5762,
        "lng": 42.0207
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 22498,
      "name": "Noordwijkerhout",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2594,
        "lng": 4.4919
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 22882,
      "name": "Pajapita",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7243,
        "lng": -92.0334
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 22814,
      "name": "Menemen",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6,
        "lng": 27.0667
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 23008,
      "name": "Xinyuan",
      "names": {
        "zh": "鑫源"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.2953,
        "lng": 99.0341
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 814,
      "name": "Roseau",
      "names": {
        "zh": "罗索",
        "ja": "ロゾー",
        "th": "โรโซ"
      },
      "countryCode": "DM",
      "latLng": {
        "lat": 15.3,
        "lng": -61.3833
      },
      "country": "Dominica",
      "importance": 0
    },
    {
      "id": 7908,
      "name": "Kretinga",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.8942,
        "lng": 21.2472
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 22763,
      "name": "Mateszalka",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.9431,
        "lng": 22.3167
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 7903,
      "name": "Struga",
      "names": {
        "zh": "斯特鲁加"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.1775,
        "lng": 20.6789
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 21955,
      "name": "Rypin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.0667,
        "lng": 19.45
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 22990,
      "name": "Panagyurishte",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.5026,
        "lng": 24.1802
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 23001,
      "name": "Gryfice",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.9167,
        "lng": 15.2
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7612,
      "name": "Ciudad Guadalupe Victoria",
      "names": {
        "zh": "Ciudad Guadalupe维多利亚",
        "th": "Ciudad Guadalupe วิคตอเรีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.4497,
        "lng": -104.1225
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23058,
      "name": "Ivanava",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.1333,
        "lng": 25.55
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 7915,
      "name": "Herceg Novi",
      "names": {
        "ja": "ヘルシュニューノヴィ"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.4531,
        "lng": 18.5375
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 22999,
      "name": "Zapotlan de Juarez",
      "names": {
        "es": "Zapotlán de Juárez",
        "th": "คนซ่งฉลอง",
        "pt": "Zapotel de Juarez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9667,
        "lng": -98.85
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22635,
      "name": "Kezmarok",
      "names": {
        "es": "Kežmarok"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.1383,
        "lng": 20.4292
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 22062,
      "name": "Zlotoryja",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.1264,
        "lng": 15.9198
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 23067,
      "name": "Reyhanli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.2679,
        "lng": 36.5675
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 23084,
      "name": "Loimaa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8514,
        "lng": 23.0583
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 23108,
      "name": "Anori",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0736,
        "lng": -75.1469
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7925,
      "name": "Bordj Mokhtar",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 21.3289,
        "lng": 0.9542
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 22994,
      "name": "Hannut",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.6725,
        "lng": 5.078
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 23103,
      "name": "Bilohirsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.0544,
        "lng": 34.6022
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7883,
      "name": "Ntungamo",
      "names": {
        "zh": "恩通加莫"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.8794,
        "lng": 30.2642
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 22969,
      "name": "Santa Cruz Naranjo",
      "names": {
        "zh": "圣克鲁斯纳兰茹",
        "ja": "サンタクルスナランジョ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.3833,
        "lng": -90.3667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 22644,
      "name": "Palestina de los Altos",
      "names": {
        "zh": "巴勒斯蒂娜de los Altos",
        "de": "Palästina de Los Altos",
        "th": "ปาเลสไตน์เดอลอสอัลโตส"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9333,
        "lng": -91.7
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 23114,
      "name": "Olecko",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.0333,
        "lng": 22.5
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7718,
      "name": "Juchique de Ferrer",
      "names": {
        "it": "JUCHIQQUE DE FERRER"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8333,
        "lng": -96.7
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22459,
      "name": "Cosautlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3333,
        "lng": -96.9833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7942,
      "name": "Moussoro",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 13.6333,
        "lng": 16.4833
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 22823,
      "name": "Stara L\u0027ubovna",
      "names": {
        "es": "Stara l\u0027ubobna",
        "id": "Sara l\u0027Ubovna"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.3167,
        "lng": 20.6833
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 23096,
      "name": "Digne-les-Bains",
      "names": {
        "zh": "迪涅-莱斯班",
        "ja": "digne・レ・バン",
        "th": "digne-เลสแบงส์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.0925,
        "lng": 6.2356
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 23188,
      "name": "Orimattila",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8042,
        "lng": 25.7333
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 7807,
      "name": "Gobabis",
      "names": {
        "zh": "戈巴比斯"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -22.45,
        "lng": 18.9667
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 23201,
      "name": "Shahriston",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 39.7667,
        "lng": 68.8167
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 23102,
      "name": "Ayvalik",
      "names": {
        "zh": "艾瓦勒克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.3167,
        "lng": 26.7
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 23249,
      "name": "San Antonio Oeste",
      "names": {
        "zh": "圣安东尼奥oeste",
        "ja": "サンアントニオエステ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.7333,
        "lng": -64.95
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7950,
      "name": "Ardahan",
      "names": {
        "zh": "阿尔达汉"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1167,
        "lng": 42.7
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 7951,
      "name": "Naama",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.2678,
        "lng": -0.3111
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 23132,
      "name": "Sparti",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.0739,
        "lng": 22.4294
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 7697,
      "name": "Oteapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.0,
        "lng": -94.6667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7955,
      "name": "Koulamoutou",
      "names": {
        "zh": "库拉穆图"
      },
      "countryCode": "GA",
      "latLng": {
        "lat": -1.1333,
        "lng": 12.4833
      },
      "country": "Gabon",
      "importance": 1
    },
    {
      "id": 7954,
      "name": "Kabugao",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 18.0277,
        "lng": 121.1889
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 23194,
      "name": "Aranyaprathet",
      "names": {
        "th": "อรัญประเทศ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6928,
        "lng": 102.5017
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7761,
      "name": "Xaignabouli",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 19.2575,
        "lng": 101.7103
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 7948,
      "name": "Nakhon Nayok",
      "names": {
        "it": "Nayok nakhon",
        "ja": "ナコン・ナヨク",
        "th": "นครนายก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.2031,
        "lng": 101.215
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 22589,
      "name": "Sedan",
      "names": {
        "es": "Sedán",
        "fr": "Berline",
        "zh": "轿车",
        "de": "Limousine",
        "it": "Berlina",
        "ja": "セダン",
        "th": "ซีดาน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.7019,
        "lng": 4.9403
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 23255,
      "name": "Guanagazapa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.2333,
        "lng": -90.65
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 23315,
      "name": "As Sukhnah",
      "names": {
        "es": "Como sukhnah",
        "fr": "Comme Sukhnah",
        "zh": "作为Sukhnah",
        "de": "Als Sukhnah",
        "it": "Come Sukhnah",
        "id": "Sebagai suknah",
        "ja": "スクナとして",
        "th": "เช่นเดียวกับ Sukhnah",
        "pt": "Como Sukhnah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.8868,
        "lng": 38.8721
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 7958,
      "name": "Backa Topola",
      "names": {
        "id": "Baca topola",
        "ja": "バックアトポーラ"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.8089,
        "lng": 19.635
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 23126,
      "name": "San Cristobal Cucho",
      "names": {
        "es": "San Cristóbal Cucho",
        "fr": "Cucho de San Cristobal",
        "ja": "サンクリストバルチェチョ",
        "th": "ซาน Cristobal Cucho"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9,
        "lng": -91.7833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 23174,
      "name": "Rygge",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.3747,
        "lng": 10.7147
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 7534,
      "name": "Dispur",
      "names": {},
      "countryCode": "IN",
      "latLng": {
        "lat": 26.15,
        "lng": 91.77
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 23308,
      "name": "Bar",
      "names": {
        "zh": "杠",
        "ja": "バー",
        "th": "บาร์",
        "pt": "Barra"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0781,
        "lng": 27.6831
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 22874,
      "name": "Vendome",
      "names": {
        "zh": "旺多姆",
        "ja": "vendôme"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.7928,
        "lng": 1.0656
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7962,
      "name": "Kinkala",
      "names": {},
      "countryCode": "CG",
      "latLng": {
        "lat": -4.35,
        "lng": 14.76
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 23175,
      "name": "Bang Phae",
      "names": {
        "zh": "邦Phae",
        "de": "Bang-Phae",
        "id": "Orang bodoh",
        "ja": "バーンパ",
        "th": "บางแพ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6983,
        "lng": 99.9068
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7935,
      "name": "Ranong",
      "names": {
        "zh": "拉廊",
        "th": "ระนอง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.9619,
        "lng": 98.6389
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 23256,
      "name": "Mezokovesd",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.8067,
        "lng": 20.5647
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 23354,
      "name": "Chinggil",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 46.666,
        "lng": 90.3794
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 23142,
      "name": "Chai Prakan",
      "names": {
        "zh": "柴普山",
        "ja": "チャイプラカン",
        "th": "ชัยปราพัน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.7322,
        "lng": 99.1403
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 23378,
      "name": "Aquitania",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5194,
        "lng": -72.8836
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 23341,
      "name": "Mezotur",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0042,
        "lng": 20.6181
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 23051,
      "name": "Kisvarda",
      "names": {
        "zh": "小瓦尔道"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 48.2264,
        "lng": 22.0844
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 7599,
      "name": "Santiago Tulantepec",
      "names": {
        "zh": "圣地亚哥Tulantepec"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0397,
        "lng": -98.3575
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23356,
      "name": "Csongrad",
      "names": {
        "de": "Csongrád"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.7111,
        "lng": 20.1403
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 7594,
      "name": "Ciudad de Huitzuco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.3,
        "lng": -99.35
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23361,
      "name": "Alasehir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.35,
        "lng": 28.5167
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 23233,
      "name": "Thermi",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.5472,
        "lng": 23.0197
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 7536,
      "name": "El Rosario",
      "names": {
        "ja": "エルロザリオ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.9922,
        "lng": -105.8572
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 22333,
      "name": "Hoganas",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 56.196,
        "lng": 12.5769
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 23419,
      "name": "Dianga",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 34.0587,
        "lng": 103.2074
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 23106,
      "name": "Tasil",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.8353,
        "lng": 35.9714
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 23036,
      "name": "Yaypan",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.3758,
        "lng": 70.8156
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 7715,
      "name": "Vicente Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 23.75,
        "lng": -103.9833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23455,
      "name": "Chivolo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.0264,
        "lng": -74.6214
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7971,
      "name": "Causeni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.6442,
        "lng": 29.4139
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 22959,
      "name": "Beek",
      "names": {
        "zh": "贝克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.9394,
        "lng": 5.7961
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 23439,
      "name": "Ban Dung",
      "names": {
        "es": "Prohibir estiércol",
        "fr": "Buêle",
        "zh": "禁止粪便",
        "de": "Ban-Mist",
        "it": "Divieto",
        "ja": "糞を禁止する",
        "th": "บ้านมูล",
        "pt": "Esterco"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.704,
        "lng": 103.2623
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7977,
      "name": "Silute",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.35,
        "lng": 21.4833
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 23454,
      "name": "Bastogne",
      "names": {
        "zh": "巴斯托涅"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.0042,
        "lng": 5.72
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 23486,
      "name": "San Julian",
      "names": {
        "zh": "圣朱利安",
        "it": "San Giuliano",
        "ja": "サンジュリアン",
        "th": "ซานจูเลียน",
        "pt": "San juliano"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0167,
        "lng": -102.1667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23534,
      "name": "Skvyra",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.7333,
        "lng": 29.6833
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7979,
      "name": "Bijelo Polje",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 43.0292,
        "lng": 19.7456
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 22242,
      "name": "Lam Luk Ka",
      "names": {
        "zh": "林禄川",
        "th": "ลำลูกกา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.9297,
        "lng": 100.7375
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 23514,
      "name": "Serta",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.8008,
        "lng": -8.1003
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 23369,
      "name": "Incirliova",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8547,
        "lng": 27.7236
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 23264,
      "name": "Bruck an der Mur",
      "names": {
        "zh": "布鲁克疯狂",
        "ja": "マルを囲みました",
        "th": "Bruck Aner Mur"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.4106,
        "lng": 15.2686
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 23112,
      "name": "Harlingen",
      "names": {
        "zh": "哈林根",
        "th": "ฮาร์ลิงเจน"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.1736,
        "lng": 5.4272
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 23409,
      "name": "Atescatempa",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.175,
        "lng": -89.7417
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 7682,
      "name": "Tlaltetela",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3167,
        "lng": -96.9
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23479,
      "name": "Batalha",
      "names": {
        "zh": "巴塔利亚"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.65,
        "lng": -8.8167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 7983,
      "name": "Maliana",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.9917,
        "lng": 125.2197
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 7990,
      "name": "Altay",
      "names": {
        "zh": "阿勒泰"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 46.3728,
        "lng": 96.2572
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 23570,
      "name": "Ixhuatlan del Sureste",
      "names": {
        "fr": "Ixhuatlan del Surette",
        "zh": "Ixhuatlan del Sureeste",
        "ja": "Ixhuatlan del Sureeste",
        "th": "ixhuatlan del sueste"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.017,
        "lng": -94.38
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7993,
      "name": "Whakatane",
      "names": {
        "zh": "瓦卡塔尼"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -37.964,
        "lng": 176.984
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 23561,
      "name": "Tenango de Doria",
      "names": {
        "it": "Tenano de Doria",
        "id": "Tauno de doria"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3356,
        "lng": -98.2267
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23625,
      "name": "Voss",
      "names": {
        "zh": "福斯",
        "th": "โวส"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 60.7025,
        "lng": 6.4231
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 23623,
      "name": "Macedo de Cavaleiros",
      "names": {
        "fr": "Macédo de Cavaleiros",
        "ja": "マイシオデカカレロス"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5333,
        "lng": -6.95
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 23644,
      "name": "Guasca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8658,
        "lng": -73.8772
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 23136,
      "name": "Przeworsk",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.0667,
        "lng": 22.5
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 23305,
      "name": "Okhtyrka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.3074,
        "lng": 34.9016
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 23670,
      "name": "Si Satchanalai",
      "names": {
        "fr": "Si Satchatanalai",
        "ja": "SATATHANALAI",
        "th": "ศรีสัชนาลัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4155,
        "lng": 99.8152
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 23701,
      "name": "Kuusamo",
      "names": {
        "zh": "库萨莫"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 65.9667,
        "lng": 29.1667
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 7996,
      "name": "Mus",
      "names": {
        "zh": "亩"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7333,
        "lng": 41.4911
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 23685,
      "name": "Tiran",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.7025,
        "lng": 51.1536
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 7645,
      "name": "La Cruz",
      "names": {
        "zh": "拉克鲁斯",
        "ja": "ラクルーズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.9214,
        "lng": -106.8919
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23003,
      "name": "Montbrison",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.6075,
        "lng": 4.0653
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7922,
      "name": "Villa Hayes",
      "names": {
        "zh": "别墅海耶斯",
        "ja": "ヴィラヘイズ",
        "th": "วิลล่าเฮย์ส"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -25.09,
        "lng": -57.53
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 23595,
      "name": "Belen",
      "names": {
        "zh": "贝伦"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.4917,
        "lng": 36.1917
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 23745,
      "name": "Serpa",
      "names": {
        "zh": "塞尔帕"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.9333,
        "lng": -7.5833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 23720,
      "name": "Rakhiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.05,
        "lng": 24.2167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7997,
      "name": "Sukhothai",
      "names": {
        "zh": "素可泰",
        "th": "สุโขทัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.0142,
        "lng": 99.8219
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 23736,
      "name": "Bozyazi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.1,
        "lng": 32.975
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 7430,
      "name": "Tatahuicapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.25,
        "lng": -94.7667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23755,
      "name": "Mangqu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 35.5707,
        "lng": 100.7798
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 7800,
      "name": "Dedza",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -14.3667,
        "lng": 34.3333
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 7980,
      "name": "Anadyr",
      "names": {
        "zh": "阿纳德尔"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 64.7333,
        "lng": 177.5167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 23491,
      "name": "Pamiers",
      "names": {
        "zh": "帕米耶"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1164,
        "lng": 1.6108
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 23767,
      "name": "At-Bashy",
      "names": {
        "es": "En-bashy",
        "fr": "À-bashy",
        "zh": "在-bashy",
        "it": "A-bashy",
        "id": "Di-bashy",
        "ja": "アット・bashy",
        "th": "ที่ bashy"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 41.1725,
        "lng": 75.7968
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 22491,
      "name": "Leczyca",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.0583,
        "lng": 19.2
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 8008,
      "name": "Erzincan",
      "names": {
        "zh": "埃尔津詹",
        "ja": "エルジンジャン"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.7464,
        "lng": 39.4914
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 23774,
      "name": "Najasa",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 21.0836,
        "lng": -77.7472
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 23763,
      "name": "Bohodukhiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.1608,
        "lng": 35.5164
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 23618,
      "name": "Juma Shahri",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 39.7094,
        "lng": 66.6614
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 23725,
      "name": "Szarvas",
      "names": {
        "zh": "索尔沃什"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.85,
        "lng": 20.6
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 23787,
      "name": "Sarikamis",
      "names": {
        "zh": "萨瑞卡密斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.3314,
        "lng": 42.5903
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 23700,
      "name": "Einsiedeln",
      "names": {
        "zh": "艾因西德伦"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.1278,
        "lng": 8.7431
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 23474,
      "name": "Monte Plata",
      "names": {
        "zh": "蒙特普拉塔",
        "ja": "モンテプラタ"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.807,
        "lng": -69.784
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 23456,
      "name": "Santa Maria de Jesus",
      "names": {
        "es": "Santa María de Jesús",
        "fr": "Santa Maria de Jésus",
        "zh": "圣玛丽亚德耶斯",
        "it": "Santa Maria de Gesù",
        "id": "Santa Maria de Yesus",
        "ja": "サンタマリアデ・イエス",
        "th": "ซานตามาเรียเดอพระเยซู"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4933,
        "lng": -90.7094
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 23772,
      "name": "Mandal",
      "names": {
        "fr": "De mandal",
        "zh": "曼德尔",
        "ja": "マーンダル",
        "th": "ดัล"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.0333,
        "lng": 7.4833
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 8015,
      "name": "Edinet",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.1681,
        "lng": 27.305
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 7633,
      "name": "Lezhe",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 41.7805,
        "lng": 19.6434
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 23823,
      "name": "Uusikaupunki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.7833,
        "lng": 21.4167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 22759,
      "name": "Saint-Julien-en-Genevois",
      "names": {
        "zh": "圣 - 朱利安-连接genevois",
        "ja": "サンジュリアン・専用genevois",
        "th": "เซนต์มั๊ย-ห้อง genevois"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.1442,
        "lng": 6.0842
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 23826,
      "name": "Zelenogradsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.95,
        "lng": 20.4833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 23639,
      "name": "Kalocsa",
      "names": {
        "zh": "考洛乔"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.5333,
        "lng": 18.9856
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 23759,
      "name": "Jedrzejow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.6333,
        "lng": 20.3
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 23463,
      "name": "Toul",
      "names": {
        "zh": "图勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.675,
        "lng": 5.8917
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 23056,
      "name": "Differdange",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.5242,
        "lng": 5.8914
      },
      "country": "Luxembourg",
      "importance": 2
    },
    {
      "id": 23608,
      "name": "Kafr Nubl",
      "names": {
        "fr": "Kafr nud",
        "id": "Kafr nobr",
        "th": "kafr nuli"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.6139,
        "lng": 36.5611
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 23888,
      "name": "Churumuco de Morelos",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6167,
        "lng": -101.6333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23740,
      "name": "Kinna",
      "names": {
        "zh": "金纳"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.4954,
        "lng": 12.6805
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 7777,
      "name": "Madera",
      "names": {
        "zh": "马德拉",
        "ja": "マデラ",
        "th": "มาเดรา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 29.19,
        "lng": -108.1414
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8012,
      "name": "Uthai Thani",
      "names": {
        "de": "Athai danke",
        "ja": "UTHAI THENI",
        "th": "อุทัยธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.38,
        "lng": 100.025
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 22864,
      "name": "Jiblah",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 13.9167,
        "lng": 44.15
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 23539,
      "name": "Nuth",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.9167,
        "lng": 5.8833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 23815,
      "name": "Khao Yoi",
      "names": {
        "ja": "カオヨー",
        "th": "เขาโยโย่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.2403,
        "lng": 99.8254
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 23675,
      "name": "Spittal an der Drau",
      "names": {
        "zh": "Spittal A der Drau",
        "id": "Spittal an der drum",
        "ja": "スピタールアンデルドラウ",
        "th": "spittal an der dru"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.7917,
        "lng": 13.4958
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 23598,
      "name": "Bad Neustadt",
      "names": {
        "es": "Mala neustadt",
        "zh": "坏Neustadt",
        "de": "Schlechtes Neustadt",
        "id": "Neustadt yang buruk",
        "ja": "悪いニュスタット",
        "th": "Neustadt ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3219,
        "lng": 10.2161
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 23922,
      "name": "Klimavichy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.6167,
        "lng": 31.95
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 23856,
      "name": "Fontainebleau",
      "names": {
        "zh": "枫丹白露",
        "ja": "フォンテーヌブロー",
        "th": "ฟองเตนโบล"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.4089,
        "lng": 2.7017
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 23910,
      "name": "Kerouane",
      "names": {
        "zh": "凯鲁阿内"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 9.2704,
        "lng": -9.02
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 7750,
      "name": "Zabbar",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8772,
        "lng": 14.5381
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 23929,
      "name": "Landazuri",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2181,
        "lng": -73.8114
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 23933,
      "name": "Villa del Rosario",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -31.5833,
        "lng": -63.5333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 23777,
      "name": "Kunzelsau",
      "names": {
        "de": "Künzelsau",
        "it": "Künzelsau"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.2833,
        "lng": 9.6833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 8031,
      "name": "Kurdamir",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.3383,
        "lng": 48.1608
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 8018,
      "name": "Palanga",
      "names": {
        "zh": "帕兰加",
        "th": "ปาลังกา"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9167,
        "lng": 21.0639
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 7434,
      "name": "Ayutla de los Libres",
      "names": {
        "de": "Ayutla de los libre",
        "ja": "Ayutla de Losのライブラリ",
        "pt": "Ayutla de los lianges"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.9,
        "lng": -99.2167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23957,
      "name": "Thoen",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.61,
        "lng": 99.2234
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 23239,
      "name": "Chateau-Thierry",
      "names": {
        "fr": "Château-Thierry",
        "zh": "城堡-蒂埃里",
        "ja": "シャトー-thierry",
        "th": "ชาโตว์-ธีรี่ร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.0464,
        "lng": 3.4031
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 23931,
      "name": "Amatitan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8333,
        "lng": -103.7167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23991,
      "name": "Alta",
      "names": {
        "zh": "阿尔塔",
        "ja": "アルタ",
        "th": "อัลตา"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 69.9686,
        "lng": 23.2714
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 23964,
      "name": "Castro Daire",
      "names": {
        "fr": "Castro-Daire",
        "zh": "卡斯特罗·迪尔尔",
        "ja": "カストロデール"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.9,
        "lng": -7.9333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 23422,
      "name": "Swidwin",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.7667,
        "lng": 15.7833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 23989,
      "name": "Lahoysk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.2,
        "lng": 27.85
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 23860,
      "name": "Hilvarenbeek",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.4861,
        "lng": 5.1367
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 23966,
      "name": "Chaltyr",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.2833,
        "lng": 39.5
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 23770,
      "name": "Khamir",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 15.99,
        "lng": 43.95
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 7628,
      "name": "Cardenas",
      "names": {
        "es": "Cárdenas",
        "zh": "卡德纳斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.0103,
        "lng": -99.6522
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24005,
      "name": "Villa Paranacito",
      "names": {
        "zh": "帕拉腊迦尼托别墅",
        "ja": "ヴィラパラナシート"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.7,
        "lng": -58.6833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 23911,
      "name": "Birecik",
      "names": {
        "zh": "比雷吉克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.025,
        "lng": 37.9769
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 24001,
      "name": "Loviisa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4569,
        "lng": 26.225
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 23934,
      "name": "Conguaco",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.047,
        "lng": -90.0336
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 7767,
      "name": "Erongaricuaro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5833,
        "lng": -101.7167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23494,
      "name": "Maardu",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.4781,
        "lng": 25.0161
      },
      "country": "Estonia",
      "importance": 2
    },
    {
      "id": 23987,
      "name": "Chilcuautla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3333,
        "lng": -99.2333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8056,
      "name": "Radviliskis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.8,
        "lng": 23.55
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 24009,
      "name": "Penacova",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.2667,
        "lng": -8.2667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8058,
      "name": "Yozgat",
      "names": {
        "zh": "约兹加特",
        "ja": "ヨズガト"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8208,
        "lng": 34.8083
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 24053,
      "name": "Tamesis",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6647,
        "lng": -75.7144
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 23453,
      "name": "Issoire",
      "names": {
        "zh": "伊苏瓦尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.5442,
        "lng": 3.2489
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 23895,
      "name": "San Rafael Pie de la Cuesta",
      "names": {
        "ja": "サンラファエルパーデラシースター"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9333,
        "lng": -91.9167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 24061,
      "name": "La Apartada",
      "names": {
        "fr": "La Apartaada"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.05,
        "lng": -75.3356
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 24092,
      "name": "Masty",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.4,
        "lng": 24.5333
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 8030,
      "name": "Schwyz",
      "names": {
        "zh": "施维茨"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.0205,
        "lng": 8.6583
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 23784,
      "name": "Siemiatycze",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.4272,
        "lng": 22.8625
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 23728,
      "name": "Waremme",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.7,
        "lng": 5.25
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 24114,
      "name": "Moura",
      "names": {
        "zh": "莫拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.1397,
        "lng": -7.4505
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 23996,
      "name": "Nazare",
      "names": {
        "zh": "纳扎雷",
        "de": "Nazaré"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.6,
        "lng": -9.0667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 24101,
      "name": "Samobor",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.8011,
        "lng": 15.711
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 24096,
      "name": "San Antonio La Paz",
      "names": {
        "ja": "サンアントニオラパス",
        "th": "ซานอันโตนิโอลาปาซ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.75,
        "lng": -90.2833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 24130,
      "name": "Joghtay",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 36.6361,
        "lng": 57.0728
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 23690,
      "name": "Kysucke Nove Mesto",
      "names": {
        "ja": "Kusucke Nove Mesto"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.3,
        "lng": 18.7833
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 7941,
      "name": "Mahikeng",
      "names": {},
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.8653,
        "lng": 25.6442
      },
      "country": "South Africa",
      "importance": 1
    },
    {
      "id": 24104,
      "name": "Choszczno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1667,
        "lng": 15.4
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 24139,
      "name": "Gardabani",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.45,
        "lng": 45.1
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 7795,
      "name": "Huitzilan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9667,
        "lng": -97.6833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23858,
      "name": "Budakeszi",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.5111,
        "lng": 18.93
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8078,
      "name": "Kidal",
      "names": {
        "zh": "基达尔"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 18.4333,
        "lng": 1.4
      },
      "country": "Mali",
      "importance": 1
    },
    {
      "id": 8064,
      "name": "Phongsali",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 21.6833,
        "lng": 102.1
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 24029,
      "name": "Tapolca",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.8828,
        "lng": 17.4411
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 24035,
      "name": "Sarvar",
      "names": {
        "zh": "萨尔瓦尔"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2539,
        "lng": 16.9353
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 24191,
      "name": "Tulchyn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6744,
        "lng": 28.8497
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 24151,
      "name": "Amlash",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.0975,
        "lng": 50.1864
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 23498,
      "name": "Boskoop",
      "names": {
        "zh": "博斯科普"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0667,
        "lng": 4.6333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 24085,
      "name": "Unye",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1271,
        "lng": 37.2882
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 24216,
      "name": "Ylivieska",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.0722,
        "lng": 24.5375
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 24124,
      "name": "Zozocolco de Hidalgo",
      "names": {
        "de": "Zozcolco de Hidalgo",
        "ja": "Zosocolo de Hidalgo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1333,
        "lng": -97.5833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23882,
      "name": "Galanta",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.1914,
        "lng": 17.7308
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 24056,
      "name": "Mindelheim",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0333,
        "lng": 10.4667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 7449,
      "name": "Acala",
      "names": {
        "zh": "阿卡拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.5533,
        "lng": -92.8069
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7984,
      "name": "Richmond",
      "names": {
        "zh": "里士满",
        "ja": "リッチモンド",
        "th": "ริชมอนด์"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -41.3333,
        "lng": 173.1833
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 8071,
      "name": "Paro",
      "names": {
        "es": "En paro",
        "zh": "帕罗",
        "th": "พาโร"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.4333,
        "lng": 89.4167
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 8076,
      "name": "Quthing",
      "names": {
        "zh": "古廷"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -30.4001,
        "lng": 27.7002
      },
      "country": "Lesotho",
      "importance": 1
    },
    {
      "id": 8085,
      "name": "Fayroz Koh",
      "names": {
        "ja": "フェイロツKOH",
        "th": "Fayroz เกาะ"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.5225,
        "lng": 65.2517
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 24210,
      "name": "Salkhad",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.4914,
        "lng": 36.7106
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 24252,
      "name": "Bonanza",
      "names": {
        "zh": "幸运",
        "ja": "ボナンザ",
        "th": "โบนันซ่า"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 14.0167,
        "lng": -84.5833
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 24264,
      "name": "Chaguaramas",
      "names": {
        "zh": "查瓜拉马斯"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 9.3385,
        "lng": -66.2525
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 24182,
      "name": "Battalgazi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4228,
        "lng": 38.3656
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 24275,
      "name": "Verdal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 63.7922,
        "lng": 11.4817
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 7939,
      "name": "Rakvere",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3506,
        "lng": 26.3611
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 24231,
      "name": "Obando",
      "names": {
        "zh": "奥班多"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5761,
        "lng": -75.9731
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 22995,
      "name": "Vesoul",
      "names": {
        "zh": "沃苏勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6222,
        "lng": 6.1553
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 24094,
      "name": "Skalica",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.8436,
        "lng": 17.2264
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 23982,
      "name": "Brake",
      "names": {
        "es": "Freno",
        "fr": "Frein",
        "zh": "制动",
        "de": "Bremse",
        "it": "Freno",
        "id": "Rem",
        "ja": "ブレーキ",
        "th": "เบรค",
        "pt": "Freio"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.3333,
        "lng": 8.4833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 8090,
      "name": "Baucau",
      "names": {
        "zh": "包考"
      },
      "countryCode": "TL",
      "latLng": {
        "lat": -8.4667,
        "lng": 126.45
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 22844,
      "name": "Westervoort",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9667,
        "lng": 5.9667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 24282,
      "name": "Vennesla",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.3106,
        "lng": 7.8569
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 7857,
      "name": "Calkini",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.3667,
        "lng": -90.05
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7588,
      "name": "Tecuala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.4004,
        "lng": -105.46
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24093,
      "name": "Bitburg",
      "names": {
        "zh": "比特堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.9747,
        "lng": 6.5256
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 24188,
      "name": "La Fleche",
      "names": {
        "fr": "La flèche",
        "de": "La Fiege"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6997,
        "lng": -0.0761
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 23844,
      "name": "Senlis",
      "names": {
        "zh": "桑利斯"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.2072,
        "lng": 2.5867
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 24256,
      "name": "Mariestad",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.705,
        "lng": 13.828
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 24310,
      "name": "Nastola",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.9477,
        "lng": 25.9301
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 23866,
      "name": "Pontivy",
      "names": {
        "zh": "蓬蒂维"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.0686,
        "lng": -2.9628
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 24090,
      "name": "Kilimli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4833,
        "lng": 31.8333
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 23346,
      "name": "Sassenheim",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2258,
        "lng": 4.5225
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 24405,
      "name": "Kontiolahti",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.7667,
        "lng": 29.85
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 24402,
      "name": "Grandola",
      "names": {
        "pt": "Grândola"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.1768,
        "lng": -8.5689
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 24298,
      "name": "Tall Abyad",
      "names": {
        "es": "Alto Abyad",
        "fr": "Grande abyade",
        "zh": "高大的abyad",
        "de": "Hoher Abyad",
        "it": "Abyad alto",
        "id": "Abyad tinggi",
        "ja": "背の高い上手",
        "th": "แก่นแท้",
        "pt": "Alto Abyad"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.6975,
        "lng": 38.9567
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 24213,
      "name": "Alexandreia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.6283,
        "lng": 22.4454
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 8112,
      "name": "Tadjourah",
      "names": {},
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.7833,
        "lng": 42.8833
      },
      "country": "Djibouti",
      "importance": 1
    },
    {
      "id": 24324,
      "name": "Cabanas",
      "names": {
        "es": "Cabañas",
        "zh": "小屋",
        "ja": "カバナ",
        "th": "คาบาน่า"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9333,
        "lng": -89.8
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 8099,
      "name": "Cesis",
      "names": {
        "zh": "采西斯"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 57.3131,
        "lng": 25.2747
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 7702,
      "name": "Huixcolotla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9219,
        "lng": -97.7708
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7717,
      "name": "Cerritos",
      "names": {
        "zh": "塞里托斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.4275,
        "lng": -100.2783
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24316,
      "name": "Levoca",
      "names": {
        "zh": "莱沃恰"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.0228,
        "lng": 20.5906
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 24357,
      "name": "Vrbovec",
      "names": {
        "zh": "弗尔博韦茨"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.8833,
        "lng": 16.4333
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 7773,
      "name": "Montargis",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 47.9969,
        "lng": 2.7325
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8108,
      "name": "Ozurgeti",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.9269,
        "lng": 42.0006
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 24480,
      "name": "Maqat",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 47.65,
        "lng": 53.3167
      },
      "country": "Kazakhstan",
      "importance": 2
    },
    {
      "id": 24290,
      "name": "Detva",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.5572,
        "lng": 19.4208
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 24474,
      "name": "Tamuin",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.0,
        "lng": -98.7833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8083,
      "name": "Masalli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.0324,
        "lng": 48.6722
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 23831,
      "name": "Limanowa",
      "names": {
        "zh": "利马诺瓦"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 49.7167,
        "lng": 20.4667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 24412,
      "name": "Slochteren",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2167,
        "lng": 6.8
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 24446,
      "name": "Tepetzintla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1452,
        "lng": -97.8558
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 23981,
      "name": "Bar-le-Duc",
      "names": {
        "zh": "巴勒迪克山",
        "ja": "バー・ル・duc",
        "th": "บาร์เลอ-ดูค"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7717,
        "lng": 5.1672
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 24006,
      "name": "Morlaix",
      "names": {
        "zh": "莫尔莱"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5775,
        "lng": -3.8278
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 24499,
      "name": "Zabkowice Slaskie",
      "names": {
        "pt": "Slaskie de zabkowice"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 50.5897,
        "lng": 16.8124
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 23749,
      "name": "Saint-Omer",
      "names": {
        "zh": "圣 - 奥马尔",
        "ja": "サン・オメル",
        "th": "เซนต์เอโอแมร์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 50.7483,
        "lng": 2.2608
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7693,
      "name": "Loreto",
      "names": {
        "zh": "洛雷托",
        "th": "เรโต"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.0128,
        "lng": -111.3433
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8123,
      "name": "Ondorhaan",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 47.3167,
        "lng": 110.65
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 24436,
      "name": "Ivanic-Grad",
      "names": {
        "es": "Ivanic-de posgrado",
        "zh": "伊万尼奇-毕业生",
        "ja": "ivanic-卒業生",
        "th": "ivanic-สำเร็จการศึกษา",
        "pt": "Ivanic-graduado"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.7081,
        "lng": 16.3947
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 7518,
      "name": "Le Raincy",
      "names": {
        "es": "Le lluvia",
        "fr": "Le plaincy",
        "it": "Le piove",
        "ja": "ルレイン",
        "th": "le indcy",
        "pt": "Le Rouccy"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8992,
        "lng": 2.5231
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 24032,
      "name": "Tulle",
      "names": {
        "es": "Tul",
        "zh": "薄纱",
        "de": "Tüll",
        "ja": "チュール",
        "th": "ผ้าโปร่งสำหรับคลุมหน้าสตรี",
        "pt": "Tule"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.2658,
        "lng": 1.7722
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 24518,
      "name": "Ochakiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.6186,
        "lng": 31.5392
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 24536,
      "name": "Ardestan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.3797,
        "lng": 52.3725
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 24399,
      "name": "Grodzisk Wielkopolski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.2333,
        "lng": 16.3667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7597,
      "name": "Tlaxcala",
      "names": {
        "zh": "特拉斯卡拉",
        "ja": "トラスカラ",
        "th": "ตลัซกาลา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3169,
        "lng": -98.2382
      },
      "country": "Mexico",
      "importance": 1
    },
    {
      "id": 24529,
      "name": "Carmen de Areco",
      "names": {
        "de": "Carmen de Aeco",
        "ja": "カルメンデレコ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.3833,
        "lng": -59.8167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 24467,
      "name": "Berettyoujfalu",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2167,
        "lng": 21.55
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 24379,
      "name": "Thuin",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 50.3397,
        "lng": 4.287
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 8040,
      "name": "Dzuunmod",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 47.7069,
        "lng": 106.9528
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 24557,
      "name": "Condoto",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0917,
        "lng": -76.65
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8130,
      "name": "Pierre",
      "names": {
        "zh": "皮埃尔·",
        "ja": "ピエール",
        "th": "ปิแอร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.3748,
        "lng": -100.3205
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 8131,
      "name": "Djanet",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 24.555,
        "lng": 9.4853
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 24157,
      "name": "Ratzeburg",
      "names": {
        "zh": "拉策堡"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.7017,
        "lng": 10.7567
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 24291,
      "name": "Tiszaujvaros",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.9228,
        "lng": 21.0519
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 24336,
      "name": "Mineral del Monte",
      "names": {
        "fr": "Minéral del monte",
        "ja": "ミネラルデルモンテ",
        "th": "แร่เดลมอนเต"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1333,
        "lng": -98.6667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24567,
      "name": "Goure",
      "names": {
        "zh": "古雷"
      },
      "countryCode": "NE",
      "latLng": {
        "lat": 13.9874,
        "lng": 10.27
      },
      "country": "Niger",
      "importance": 2
    },
    {
      "id": 7520,
      "name": "Tecpan de Galeana",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.25,
        "lng": -100.6833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24556,
      "name": "Nannestad",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.2456,
        "lng": 10.9528
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 24333,
      "name": "Woudrichem",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8133,
        "lng": 5.0006
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 24565,
      "name": "Sepidan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 30.2606,
        "lng": 51.9842
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 24545,
      "name": "Zontecomatlan de Lopez y Fuentes",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7667,
        "lng": -98.3333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24500,
      "name": "Xincun",
      "names": {
        "zh": "新村"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 27.6739,
        "lng": 103.8739
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 24603,
      "name": "Lapua",
      "names": {
        "zh": "拉普阿"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.9708,
        "lng": 23.0069
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 24513,
      "name": "Wschowa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8,
        "lng": 16.3
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 24597,
      "name": "Radomyshl",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.4947,
        "lng": 29.2333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7627,
      "name": "Ciudad Hidalgo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 14.6792,
        "lng": -92.1497
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24340,
      "name": "Paiania",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.95,
        "lng": 23.85
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 24646,
      "name": "Breza",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.021,
        "lng": 18.261
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 24648,
      "name": "Mocha",
      "names": {
        "fr": "Moka",
        "zh": "摩卡",
        "de": "Mokka",
        "ja": "モカ",
        "th": "มอคค่า"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 13.3167,
        "lng": 43.25
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 8124,
      "name": "Debar",
      "names": {
        "es": "Vedar",
        "fr": "Interdire",
        "zh": "禁止",
        "de": "Verhindern",
        "it": "Precludere",
        "id": "Mencegah",
        "ja": "禁ずる",
        "th": "ห้าม",
        "pt": "Excluir"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.525,
        "lng": 20.5272
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 23303,
      "name": "Les Sables-d\u0027Olonne",
      "names": {
        "zh": "les stables-d\u0027olonne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.4972,
        "lng": -1.7833
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 24246,
      "name": "Bludenz",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.1533,
        "lng": 9.8219
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 24255,
      "name": "Sarmin",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.9,
        "lng": 36.7167
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 24667,
      "name": "Tsimlyansk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.6467,
        "lng": 42.0947
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 24619,
      "name": "Cadaval",
      "names": {
        "zh": "卡达瓦尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.25,
        "lng": -9.1
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8089,
      "name": "Escaldes-Engordany",
      "names": {
        "zh": "escaldes-恩戈尔达"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.5089,
        "lng": 1.5408
      },
      "country": "Andorra",
      "importance": 1
    },
    {
      "id": 24594,
      "name": "Anapoima",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5503,
        "lng": -74.5361
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 7822,
      "name": "Guachochi",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.8194,
        "lng": -107.07
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8009,
      "name": "Kiboga",
      "names": {
        "zh": "基博加"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9161,
        "lng": 31.7742
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 24193,
      "name": "Pilisvorosvar",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6211,
        "lng": 18.9108
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 24508,
      "name": "Askim",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.5832,
        "lng": 11.1629
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 8115,
      "name": "Eisenstadt",
      "names": {
        "zh": "艾森斯塔特"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.8456,
        "lng": 16.5189
      },
      "country": "Austria",
      "importance": 1
    },
    {
      "id": 24572,
      "name": "Lopik",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9722,
        "lng": 4.9464
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 24685,
      "name": "Sopetran",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.5017,
        "lng": -75.7433
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8081,
      "name": "Gwanda",
      "names": {
        "zh": "关达"
      },
      "countryCode": "ZW",
      "latLng": {
        "lat": -20.945,
        "lng": 29.025
      },
      "country": "Zimbabwe",
      "importance": 1
    },
    {
      "id": 24621,
      "name": "Lagoa",
      "names": {
        "zh": "拉戈阿"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.1333,
        "lng": -8.45
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8154,
      "name": "Gabu",
      "names": {
        "zh": "加布"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 12.2833,
        "lng": -14.2167
      },
      "country": "Guinea-Bissau",
      "importance": 1
    },
    {
      "id": 24306,
      "name": "Nioro",
      "names": {},
      "countryCode": "ML",
      "latLng": {
        "lat": 15.1833,
        "lng": -9.55
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 8150,
      "name": "Port Antonio",
      "names": {
        "es": "Antonio",
        "zh": "港口安东尼奥",
        "it": "Porto Antonio",
        "ja": "ポートアントニオ",
        "th": "พอร์ตอันโตนิโอ"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.1667,
        "lng": -76.45
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 8158,
      "name": "Sigulda",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.1539,
        "lng": 24.8544
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 23447,
      "name": "Chiconquiaco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.75,
        "lng": -96.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8068,
      "name": "Liestal",
      "names": {
        "zh": "利斯塔尔"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.4839,
        "lng": 7.735
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 24814,
      "name": "San Cristobal",
      "names": {
        "es": "San Cristóbal",
        "ja": "サンクリストバル"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.3105,
        "lng": -61.2372
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 8144,
      "name": "Basse Santa Su",
      "names": {
        "ja": "サンタスース"
      },
      "countryCode": "GM",
      "latLng": {
        "lat": 13.31,
        "lng": -14.223
      },
      "country": "The Gambia",
      "importance": 1
    },
    {
      "id": 7745,
      "name": "Santa Rosalia",
      "names": {
        "zh": "圣塔洛韦亚洲",
        "ja": "サンタロザリア",
        "th": "ซานตาโรซาเลีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.3389,
        "lng": -112.2669
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24795,
      "name": "San Andres Timilpan",
      "names": {
        "es": "San Andrés Timilpan",
        "zh": "圣安德烈斯·蒂姆彭"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8667,
        "lng": -99.7333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24813,
      "name": "Pomorie",
      "names": {
        "zh": "帕莫瑞"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.5569,
        "lng": 27.6405
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 24866,
      "name": "Tenerife",
      "names": {
        "fr": "Ténérife",
        "zh": "特内里费",
        "de": "Teneriffa",
        "ja": "テネリフェ島",
        "th": "เตเนรีเฟ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.8989,
        "lng": -74.8589
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 24860,
      "name": "Estremoz",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.8422,
        "lng": -7.5881
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 24855,
      "name": "Bor",
      "names": {
        "zh": "博尔",
        "th": "บ่อ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8963,
        "lng": 34.5627
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 24588,
      "name": "Jever",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 53.5744,
        "lng": 7.9008
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 8147,
      "name": "Baruun-Urt",
      "names": {
        "zh": "baruun-法院"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 46.6829,
        "lng": 113.2786
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 24728,
      "name": "Rovinj",
      "names": {
        "zh": "罗维尼",
        "it": "Rovigno"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.0833,
        "lng": 13.6333
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 23506,
      "name": "Dinan",
      "names": {
        "zh": "迪南",
        "ja": "ディナン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.4556,
        "lng": -2.0503
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8161,
      "name": "Ucar",
      "names": {
        "zh": "炭素公司"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5183,
        "lng": 47.6542
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 24822,
      "name": "Pak Thong Chai",
      "names": {
        "de": "Pak Tanga Chai",
        "ja": "パック・トン・チャイ",
        "th": "ปากทองชัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.7302,
        "lng": 102.0263
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 24834,
      "name": "Cienega de Flores",
      "names": {
        "pt": "Cienaga de flores"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.95,
        "lng": -100.1833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24811,
      "name": "Det Udom",
      "names": {
        "es": "Deturona",
        "de": "Det-Udomat",
        "ja": "デットムダム",
        "th": "det urdom",
        "pt": "Det UDM"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.906,
        "lng": 105.0784
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 24371,
      "name": "Balassagyarmat",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.0712,
        "lng": 19.2937
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8169,
      "name": "Neftcala",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.3791,
        "lng": 49.2486
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 24891,
      "name": "Sines",
      "names": {
        "zh": "锡尼什",
        "ja": "正弦",
        "th": "ไซนส์"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.9546,
        "lng": -8.8644
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8170,
      "name": "Qusar",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.4219,
        "lng": 48.4214
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 24897,
      "name": "Kouroussa",
      "names": {
        "zh": "库鲁萨"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.653,
        "lng": -9.892
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 24606,
      "name": "Nafplio",
      "names": {
        "zh": "拉夫普里欧"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.5667,
        "lng": 22.8
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 24826,
      "name": "Feldkirchen",
      "names": {
        "zh": "费尔德基兴"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.7236,
        "lng": 14.0919
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 24914,
      "name": "Almoloya de Alquisiras",
      "names": {
        "it": "Almoya de Alquisiras",
        "th": "Almoloya de alquesiras",
        "pt": "Almoloya de Alquisira"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.85,
        "lng": -99.85
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24786,
      "name": "Haaren",
      "names": {
        "zh": "哈伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6017,
        "lng": 5.2228
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 24923,
      "name": "Juan de Acosta",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.8286,
        "lng": -75.035
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 24998,
      "name": "Puerto Deseado",
      "names": {
        "fr": "Puerto Desado",
        "zh": "波多黎各",
        "de": "Puerto Desado",
        "ja": "プエルトデスデード",
        "th": "เปอร์โตเด ธ โอ",
        "pt": "Puerto desado"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -47.75,
        "lng": -65.9167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 24871,
      "name": "Bua Yai",
      "names": {
        "th": "บัวใหญ่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.5858,
        "lng": 102.4337
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7806,
      "name": "Juan Aldama",
      "names": {
        "zh": "胡安阿尔多玛",
        "ja": "フアンアルダマ",
        "th": "ฮวนอัลดามา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.291,
        "lng": -103.394
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 7652,
      "name": "Grajales",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.25,
        "lng": -97.7833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24955,
      "name": "Puspokladany",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3197,
        "lng": 21.1139
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8168,
      "name": "Kilis",
      "names": {
        "zh": "基利斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.7167,
        "lng": 37.1167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 25030,
      "name": "Narvik",
      "names": {
        "zh": "纳尔维克",
        "th": "นาร์วิค"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 68.4383,
        "lng": 17.4278
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 25027,
      "name": "Astravyets",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.6136,
        "lng": 25.9553
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 25012,
      "name": "Kerben",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 41.5,
        "lng": 71.75
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 24756,
      "name": "Sai Buri",
      "names": {
        "zh": "赛伯里",
        "ja": "サイブリ",
        "th": "ไทรบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.7012,
        "lng": 101.6181
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 25046,
      "name": "Anatuya",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -28.4667,
        "lng": -62.8333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 7596,
      "name": "Tepoztlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9853,
        "lng": -99.0997
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24937,
      "name": "Valenca",
      "names": {
        "es": "Valença",
        "fr": "Valença",
        "zh": "瓦伦萨",
        "pt": "Valença"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 42.0282,
        "lng": -8.6339
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 25050,
      "name": "Natanz",
      "names": {
        "zh": "纳坦兹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 33.5111,
        "lng": 51.9181
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 24946,
      "name": "El Porvenir de Velasco Suarez",
      "names": {
        "it": "El Porvir de Velasco Suarez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.4556,
        "lng": -92.2794
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8091,
      "name": "Sironko",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.2294,
        "lng": 34.2481
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 24991,
      "name": "Titiribi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0625,
        "lng": -75.7936
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 24994,
      "name": "Beilen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.8567,
        "lng": 6.5111
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 8185,
      "name": "Sjenica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2667,
        "lng": 20.0
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 7815,
      "name": "Ezequiel Montes",
      "names": {
        "zh": "Ezequiel蒙特斯",
        "de": "Ezequiel monten"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6667,
        "lng": -99.8833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25073,
      "name": "Tempio Pausania",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 40.9015,
        "lng": 9.1044
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 25122,
      "name": "El Carmen de Atrato",
      "names": {
        "de": "El Carmen de atorato",
        "it": "El Carmen de Atro"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8983,
        "lng": -76.1431
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 25097,
      "name": "Gouveia",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.5,
        "lng": -7.6
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 25081,
      "name": "Merzifon",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8836,
        "lng": 35.4711
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 25121,
      "name": "Rio de Oro",
      "names": {
        "zh": "里约热内卢",
        "ja": "リオデオロ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2917,
        "lng": -73.3872
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 25037,
      "name": "Nelas",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.5167,
        "lng": -7.85
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 25034,
      "name": "Lubben (Spreewald)",
      "names": {
        "zh": "Lubben（Spreewald）",
        "ja": "Lubben（Spreewald）"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.95,
        "lng": 13.9
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 25093,
      "name": "Villacidro",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 39.4578,
        "lng": 8.7424
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 25135,
      "name": "Famenin",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.1139,
        "lng": 48.9717
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 8191,
      "name": "Chinsali",
      "names": {},
      "countryCode": "ZM",
      "latLng": {
        "lat": -10.5496,
        "lng": 32.06
      },
      "country": "Zambia",
      "importance": 1
    },
    {
      "id": 25132,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.8533,
        "lng": -77.5178
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8190,
      "name": "Akhaltsikhe",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.6389,
        "lng": 42.9861
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 25179,
      "name": "Nesebar",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.6595,
        "lng": 27.7247
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 24843,
      "name": "Montfoort",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.045,
        "lng": 4.9508
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 8194,
      "name": "Cetinje",
      "names": {
        "zh": "采蒂涅"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.3933,
        "lng": 18.9219
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 24816,
      "name": "Koundara",
      "names": {
        "zh": "孔达拉"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 12.48,
        "lng": -13.296
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 7813,
      "name": "Pathum Thani",
      "names": {
        "th": "ปทุมธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.9841,
        "lng": 100.5512
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 25124,
      "name": "Guazacapan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.0751,
        "lng": -90.4161
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 24875,
      "name": "Raydah",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 15.8233,
        "lng": 44.0386
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 25094,
      "name": "Mjolby",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.3321,
        "lng": 15.1312
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 25106,
      "name": "Bang Racham",
      "names": {
        "zh": "邦里拉姆",
        "ja": "バンラチャーム",
        "th": "บางราคัม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.8864,
        "lng": 100.3235
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 25118,
      "name": "Mor",
      "names": {
        "zh": "铁道部",
        "ja": "粗腐植",
        "th": "หมอ"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3717,
        "lng": 18.2086
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 25077,
      "name": "Castelsarrasin",
      "names": {
        "zh": "卡斯特尔萨拉桑"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.04,
        "lng": 1.1069
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8203,
      "name": "Nacaome",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 13.5333,
        "lng": -87.4833
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 24599,
      "name": "Pastores",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5967,
        "lng": -90.7547
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 25226,
      "name": "Tucacas",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 10.7978,
        "lng": -68.3175
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 8044,
      "name": "Kamnik",
      "names": {
        "zh": "卡姆尼克"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.225,
        "lng": 14.6097
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 25228,
      "name": "Babaeski",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4325,
        "lng": 27.0931
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 25284,
      "name": "Kauhajoki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.4319,
        "lng": 22.1794
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 24285,
      "name": "Czluchow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.65,
        "lng": 17.3667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 25160,
      "name": "Taphan Hin",
      "names": {
        "it": "Tafano",
        "ja": "タパンヒン",
        "th": "ตะพังตะพัง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.2108,
        "lng": 100.4188
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 25199,
      "name": "Alcanena",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4667,
        "lng": -8.6667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 25306,
      "name": "Guapi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 2.5604,
        "lng": -77.86
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 25223,
      "name": "Polotitlan de la Ilustracion",
      "names": {
        "ja": "Polotitlan de laiustacion"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2231,
        "lng": -99.8147
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25209,
      "name": "Kiskoros",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6203,
        "lng": 19.2836
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8215,
      "name": "Plav",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.5956,
        "lng": 19.945
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 25326,
      "name": "Horodyshche",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.2925,
        "lng": 31.4581
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 25308,
      "name": "Demre",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.255,
        "lng": 29.9978
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 25328,
      "name": "Apostolove",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.6595,
        "lng": 33.717
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 25338,
      "name": "Islahiye",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0264,
        "lng": 36.6322
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 25391,
      "name": "La Quiaca",
      "names": {
        "ja": "ラキアカ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -22.1056,
        "lng": -65.6
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 25378,
      "name": "Hola Prystan",
      "names": {
        "ja": "ホーラプリステン"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 46.5167,
        "lng": 32.5167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 25353,
      "name": "Jardin",
      "names": {
        "zh": "花园",
        "ja": "ジャルダン",
        "th": "จาร์ดีน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5983,
        "lng": -75.8197
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 25109,
      "name": "Enghien",
      "names": {
        "zh": "昂吉安"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.7,
        "lng": 4.0333
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 24719,
      "name": "Argentan",
      "names": {
        "zh": "阿尔让坦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7444,
        "lng": -0.0203
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 24801,
      "name": "Schwaz",
      "names": {
        "zh": "施瓦茨"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.35,
        "lng": 11.7
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 25417,
      "name": "Xireg",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.9257,
        "lng": 98.4837
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 8224,
      "name": "Bar",
      "names": {
        "zh": "杠",
        "ja": "バー",
        "th": "บาร์",
        "pt": "Barra"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.1,
        "lng": 19.1
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 25387,
      "name": "Goldap",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.3063,
        "lng": 22.3036
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 8229,
      "name": "Tovuz",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.9864,
        "lng": 45.6275
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 25090,
      "name": "Montabaur",
      "names": {
        "zh": "蒙塔鲍尔"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.4375,
        "lng": 7.8258
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 25443,
      "name": "San Pedro Pochutla",
      "names": {
        "fr": "San Pedro Pokutla",
        "ja": "サンペドロポチュラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.7463,
        "lng": -96.4652
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8217,
      "name": "Saint Ann\u0027s Bay",
      "names": {
        "es": "Bahía de San Ann",
        "zh": "圣安的湾",
        "ja": "セントアンズベイ",
        "th": "อ่าวเซนต์แอน",
        "pt": "Baía de Saint Ann"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.435,
        "lng": -77.2017
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 25432,
      "name": "Altos del Rosario",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.7914,
        "lng": -74.1636
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 25289,
      "name": "Erbach",
      "names": {
        "zh": "埃尔巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6569,
        "lng": 8.9931
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 25366,
      "name": "Lauterbach",
      "names": {
        "zh": "劳特巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6378,
        "lng": 9.3944
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 24887,
      "name": "Svetlogorsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.9333,
        "lng": 20.15
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 7943,
      "name": "Aldama",
      "names": {
        "zh": "阿尔达马"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.9194,
        "lng": -98.0736
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25348,
      "name": "Jindayris",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.3947,
        "lng": 36.6889
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 25447,
      "name": "Tavsanli",
      "names": {
        "zh": "塔夫尚勒"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.5461,
        "lng": 29.4922
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 25406,
      "name": "Filandia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6733,
        "lng": -75.6583
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 25307,
      "name": "Hassfurt",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.0353,
        "lng": 10.5123
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 25555,
      "name": "San Javier",
      "names": {
        "zh": "圣哈维尔",
        "th": "ซานเจวา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.5833,
        "lng": -59.95
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 25572,
      "name": "Frias",
      "names": {
        "fr": "Frías",
        "zh": "弗里亚斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -28.6496,
        "lng": -65.15
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 25511,
      "name": "Glubczyce",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2,
        "lng": 17.8333
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 25553,
      "name": "Agua Blanca",
      "names": {
        "ja": "アグアブランカ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4833,
        "lng": -89.6494
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 25457,
      "name": "Winsum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.3312,
        "lng": 6.5157
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 25419,
      "name": "Satoraljaujhely",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.3941,
        "lng": 21.6561
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 25565,
      "name": "Nidzica",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.3583,
        "lng": 20.425
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 25474,
      "name": "Dinant",
      "names": {
        "zh": "迪南",
        "ja": "ディナン"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.2564,
        "lng": 4.9136
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 25584,
      "name": "Kaharlyk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.8522,
        "lng": 30.8092
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 25629,
      "name": "Chanaral",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -26.3479,
        "lng": -70.6224
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 24976,
      "name": "Uitgeest",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.5292,
        "lng": 4.7103
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 25354,
      "name": "Eichstatt",
      "names": {
        "de": "Eichstätt"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8919,
        "lng": 11.1839
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 25468,
      "name": "San Jorge",
      "names": {
        "zh": "圣乔治",
        "ja": "サンホルジュ",
        "th": "ซานจอร์อร์"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9253,
        "lng": -89.5897
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 25626,
      "name": "Chalma",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2167,
        "lng": -98.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25563,
      "name": "Wolsztyn",
      "names": {
        "zh": "沃尔什滕"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.1167,
        "lng": 16.1167
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 25676,
      "name": "Fereydunshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.9411,
        "lng": 50.1211
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 25628,
      "name": "Ilamatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7833,
        "lng": -98.45
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25295,
      "name": "Niquinohomo",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 11.9,
        "lng": -86.1
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 7969,
      "name": "Ascension",
      "names": {
        "es": "Ascensión",
        "zh": "升天",
        "de": "Himmelfahrt",
        "it": "Ascensione",
        "id": "Kenaikan",
        "ja": "アセンション",
        "th": "เสด็จขึ้นสู่สวรรค์",
        "pt": "Ascensão"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.0928,
        "lng": -107.9964
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25652,
      "name": "Manati",
      "names": {
        "zh": "马纳蒂"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4481,
        "lng": -74.9592
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8246,
      "name": "San Carlos",
      "names": {
        "zh": "圣卡洛斯",
        "ja": "サンカルロス",
        "th": "ซานคาร์ลอส"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.1894,
        "lng": -84.7759
      },
      "country": "Nicaragua",
      "importance": 1
    },
    {
      "id": 25649,
      "name": "Dok Kham Tai",
      "names": {
        "ja": "ドックカムタイ",
        "th": "ด๊อคขามไท"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.162,
        "lng": 99.9926
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 25680,
      "name": "Union de Tula",
      "names": {
        "zh": "联盟de tula",
        "ja": "ユニオンデトゥーラ",
        "th": "สหภาพเดอตูล"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.957,
        "lng": -104.268
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8142,
      "name": "Naxxar",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.915,
        "lng": 14.4447
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 25719,
      "name": "Khoyniki",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 51.9,
        "lng": 29.9667
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 25714,
      "name": "San Jacinto del Cauca",
      "names": {
        "th": "ซานจาคินโซ่คอซกา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.2497,
        "lng": -74.72
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 25696,
      "name": "Kaspi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.925,
        "lng": 44.4222
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 25022,
      "name": "Nafpaktos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.3917,
        "lng": 21.8275
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 7592,
      "name": "Chitipa",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -9.7024,
        "lng": 33.2697
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 25724,
      "name": "Develi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3886,
        "lng": 35.4925
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8260,
      "name": "Faya",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 17.93,
        "lng": 19.1031
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 25748,
      "name": "Bollnas",
      "names": {
        "de": "Bollnäs"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 61.3484,
        "lng": 16.3883
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 8118,
      "name": "Luganville",
      "names": {},
      "countryCode": "VU",
      "latLng": {
        "lat": -15.5126,
        "lng": 167.1766
      },
      "country": "Vanuatu",
      "importance": 1
    },
    {
      "id": 25616,
      "name": "Arruda dos Vinhos",
      "names": {
        "zh": "Aruda DoS Vinhos"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.9833,
        "lng": -9.0667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 25541,
      "name": "Kastoria",
      "names": {
        "zh": "卡斯托利亚"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.5181,
        "lng": 21.2688
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 25796,
      "name": "Joaquin V. Gonzalez",
      "names": {
        "es": "Joaquin V. González",
        "zh": "Joaquin V.Gonzalez"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -25.1171,
        "lng": -64.1247
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 25603,
      "name": "Feldbach",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 46.955,
        "lng": 15.8883
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 8247,
      "name": "Sinop",
      "names": {
        "zh": "锡诺普"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 42.0267,
        "lng": 35.1511
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 25815,
      "name": "Karakocan",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.9551,
        "lng": 40.0375
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8046,
      "name": "Cadereyta",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.7,
        "lng": -99.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8104,
      "name": "Jesenice",
      "names": {
        "zh": "耶塞尼采"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4366,
        "lng": 14.0602
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 25013,
      "name": "Rheinfelden",
      "names": {
        "zh": "莱因费尔顿"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.5539,
        "lng": 7.7958
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 816,
      "name": "Torshavn",
      "names": {
        "fr": "Tórshavn",
        "de": "Tórshavn",
        "id": "Tórshavn"
      },
      "countryCode": "FO",
      "latLng": {
        "lat": 62.0,
        "lng": -6.7833
      },
      "country": "Faroe Islands",
      "importance": 0
    },
    {
      "id": 25793,
      "name": "Bozuyuk",
      "names": {
        "zh": "博聚于克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.9078,
        "lng": 30.0367
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 25785,
      "name": "Grimstad",
      "names": {
        "zh": "格里姆斯塔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.3405,
        "lng": 8.5934
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 25464,
      "name": "Fontenay-le-Comte",
      "names": {
        "zh": "丰特奈 - 勒孔特",
        "ja": "fontenay・ル・コント",
        "th": "fontenay-เลอ-ไหน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.4669,
        "lng": -0.8064
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 25761,
      "name": "Rosas",
      "names": {
        "zh": "罗萨斯",
        "ja": "ロサス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 2.26,
        "lng": -76.74
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 25791,
      "name": "Soyaniquilpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9892,
        "lng": -99.4361
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8232,
      "name": "Ang Thong",
      "names": {
        "fr": "Lanières",
        "zh": "ang丁字裤",
        "de": "Ang Tanga",
        "ja": "アンクトン",
        "th": "อ่างทอง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5926,
        "lng": 100.4574
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 8269,
      "name": "Kirsehir",
      "names": {
        "zh": "克尔谢希尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.145,
        "lng": 34.1608
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 25520,
      "name": "Trogir",
      "names": {
        "zh": "特罗吉尔",
        "ja": "トロギル"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.5167,
        "lng": 16.25
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 8006,
      "name": "Peshkopi",
      "names": {},
      "countryCode": "AL",
      "latLng": {
        "lat": 41.6831,
        "lng": 20.4289
      },
      "country": "Albania",
      "importance": 1
    },
    {
      "id": 25898,
      "name": "Usumatlan",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9489,
        "lng": -89.7767
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 25454,
      "name": "Ad Duraykish",
      "names": {
        "zh": "广告Duraykish",
        "de": "Ad duraykisch",
        "it": "Duraykish",
        "id": "Ad durayatkish",
        "ja": "広告デュライキー",
        "th": "โฆษณา Duraykish"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.8969,
        "lng": 36.1346
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 25926,
      "name": "Ulvila",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4292,
        "lng": 21.875
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 25745,
      "name": "Tauberbischofsheim",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6225,
        "lng": 9.6628
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 25930,
      "name": "Gvardeysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.65,
        "lng": 21.0667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 815,
      "name": "Basseterre",
      "names": {
        "zh": "巴斯特尔"
      },
      "countryCode": "KN",
      "latLng": {
        "lat": 17.2983,
        "lng": -62.7342
      },
      "country": "Saint Kitts And Nevis",
      "importance": 0
    },
    {
      "id": 8262,
      "name": "Domzale",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1333,
        "lng": 14.6
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 25899,
      "name": "Beyla",
      "names": {
        "zh": "贝拉"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 8.687,
        "lng": -8.657
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 25856,
      "name": "Bombarral",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.2667,
        "lng": -9.15
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 25767,
      "name": "Gmunden",
      "names": {
        "zh": "格蒙登"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.9181,
        "lng": 13.7994
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 25969,
      "name": "Vila Pouca de Aguiar",
      "names": {
        "ja": "ヴィラプッシャデアギアール"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.4833,
        "lng": -7.6333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 25951,
      "name": "Erbaa",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6667,
        "lng": 36.5667
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 25646,
      "name": "Woudenberg",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0806,
        "lng": 5.4164
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 25971,
      "name": "Vasylivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.4344,
        "lng": 35.2808
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 25911,
      "name": "Trzebnica",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.305,
        "lng": 17.0614
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 7833,
      "name": "Miahuatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.2833,
        "lng": -97.2833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25810,
      "name": "Autun",
      "names": {
        "zh": "欧坦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.9511,
        "lng": 4.2986
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7998,
      "name": "Villa Juarez",
      "names": {
        "es": "Villa Juárez",
        "zh": "别墅Juarez",
        "ja": "ヴィラフアレス",
        "th": "วิลล่า Juarez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.1278,
        "lng": -109.8426
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8274,
      "name": "Drochia",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.0308,
        "lng": 27.8126
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 8027,
      "name": "Santa Maria Jalapa del Marques",
      "names": {
        "es": "Santa María Jalapa del Marques",
        "zh": "圣玛丽亚Jalapa del Marques",
        "ja": "サンタマリアジャラパデルマルケス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.4401,
        "lng": -95.4434
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26004,
      "name": "Singuilucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9675,
        "lng": -98.5178
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25936,
      "name": "Bergen",
      "names": {
        "zh": "卑尔根",
        "ja": "ベルゲン",
        "th": "เบอร์เกน"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5992,
        "lng": 6.0336
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 25983,
      "name": "Ansiao",
      "names": {
        "pt": "Ansião"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.9167,
        "lng": -8.4333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 25859,
      "name": "Neustadt",
      "names": {
        "zh": "诺伊施塔特",
        "ja": "ノイシュタット",
        "th": "ตัดท์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.58,
        "lng": 10.6089
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 25711,
      "name": "Brig-Glis",
      "names": {
        "fr": "Le brigadier-glis",
        "zh": "布里格-glis",
        "it": "-Brig glis",
        "ja": "拘置所-glis",
        "th": "สำเภา-glis"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.3159,
        "lng": 7.9876
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 25985,
      "name": "Miranda do Corvo",
      "names": {
        "zh": "米兰达Do Corvo",
        "th": "มิแรนดาทำ Corvo"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.1,
        "lng": -8.3333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8206,
      "name": "Kuressaare",
      "names": {
        "zh": "库雷斯撒雷"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.2533,
        "lng": 22.4861
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 25996,
      "name": "Mazatlan Villa de Flores",
      "names": {
        "es": "Mazatlán Villa de Flores"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.0167,
        "lng": -96.9167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25669,
      "name": "Gex",
      "names": {
        "zh": "热克斯"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3333,
        "lng": 6.0578
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 26094,
      "name": "Chos Malal",
      "names": {
        "fr": "Chos malole"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.3833,
        "lng": -70.2667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 25641,
      "name": "Kussnacht",
      "names": {
        "de": "Küssnacht",
        "it": "Küssnacht"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.0828,
        "lng": 8.4408
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 8028,
      "name": "Phra Samut Chedi",
      "names": {
        "th": "พระสมุทรเจดีย์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5934,
        "lng": 100.5801
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 25635,
      "name": "Chateaudun",
      "names": {
        "fr": "Châteaudun",
        "zh": "沙托丹"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.0708,
        "lng": 1.3378
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 25371,
      "name": "Wlodawa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.55,
        "lng": 23.55
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 26145,
      "name": "Alcacer do Sal",
      "names": {
        "zh": "Alcacer做萨尔",
        "ja": "アルカセルはSalをします"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.3725,
        "lng": -8.5133
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 26163,
      "name": "Shahr-e Herat",
      "names": {
        "zh": "沙哈尔赫拉特",
        "pt": "Herat shahr-e"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 30.0542,
        "lng": 54.3731
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 8295,
      "name": "Nova Gorica",
      "names": {
        "ja": "ノヴァゴリカ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9667,
        "lng": 13.65
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 24392,
      "name": "Bayeux",
      "names": {
        "zh": "巴约",
        "ja": "バイユー"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.2786,
        "lng": -0.7039
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 26139,
      "name": "Tetiiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.3708,
        "lng": 29.69
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 26144,
      "name": "Kaji",
      "names": {
        "zh": "加持"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.0249,
        "lng": 102.7873
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 8226,
      "name": "Kumi",
      "names": {
        "zh": "来未",
        "ja": "クミ",
        "th": "สิบ"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.4608,
        "lng": 33.9361
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 26141,
      "name": "Vieira do Minho",
      "names": {
        "zh": "Vieira做Minho",
        "ja": "ヴィエイラはミンホをします"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.6333,
        "lng": -8.1333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8285,
      "name": "Bocas del Toro",
      "names": {
        "ja": "ボカスデルトロ"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 9.3403,
        "lng": -82.242
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 26191,
      "name": "Notodden",
      "names": {
        "zh": "诺托登"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.5617,
        "lng": 9.2658
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 26194,
      "name": "Karapinar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7147,
        "lng": 33.5508
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 7866,
      "name": "Nacozari de Garcia",
      "names": {
        "ja": "ナコザリデガルシア"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.3833,
        "lng": -109.6833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 24886,
      "name": "Korneuburg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.3453,
        "lng": 16.3331
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 8304,
      "name": "Steinkjer",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 64.0147,
        "lng": 11.4942
      },
      "country": "Norway",
      "importance": 1
    },
    {
      "id": 26027,
      "name": "Bonyhad",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.3,
        "lng": 18.53
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 26236,
      "name": "Maravilla Tenejapa",
      "names": {
        "ja": "マラビラテネジャパ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.1333,
        "lng": -91.2833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26098,
      "name": "Song Phi Nong",
      "names": {
        "es": "Canción phi nong",
        "zh": "宋皮恩",
        "it": "Canzone phi nong",
        "ja": "ソングピオン",
        "th": "เพลงพี่หนอง",
        "pt": "Canção phi nong"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.2306,
        "lng": 100.0389
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 25987,
      "name": "Balatonfured",
      "names": {
        "zh": "巴拉顿菲赖德"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.95,
        "lng": 17.8833
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 7798,
      "name": "Valparaiso",
      "names": {
        "es": "Valparaíso",
        "zh": "瓦尔帕莱索",
        "th": "บัลปาราอีโซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.7667,
        "lng": -103.5667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26202,
      "name": "Bahce",
      "names": {
        "zh": "巴赫切"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.2039,
        "lng": 36.5825
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 25725,
      "name": "Schinnen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.9428,
        "lng": 5.8894
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 26175,
      "name": "Anolaima",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7633,
        "lng": -74.4647
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 26235,
      "name": "Idil",
      "names": {
        "zh": "伊迪尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3347,
        "lng": 41.8894
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8135,
      "name": "Kisoro",
      "names": {
        "zh": "基索罗"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -1.3539,
        "lng": 29.6983
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 25794,
      "name": "Gueret",
      "names": {
        "zh": "盖雷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.1706,
        "lng": 1.8683
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 26238,
      "name": "Yavoriv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.9469,
        "lng": 23.3931
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8315,
      "name": "Obo",
      "names": {
        "zh": "奥博"
      },
      "countryCode": "CF",
      "latLng": {
        "lat": 5.4,
        "lng": 26.5
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 26215,
      "name": "Tapilula",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2536,
        "lng": -93.0093
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26304,
      "name": "Sungurlu",
      "names": {
        "zh": "松古尔卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.161,
        "lng": 34.377
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 26310,
      "name": "Malaryta",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 51.7972,
        "lng": 24.0808
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 8316,
      "name": "Daru",
      "names": {
        "zh": "达鲁"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -9.0833,
        "lng": 143.2
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 25662,
      "name": "Neunkirchen",
      "names": {
        "zh": "诺因基兴"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.7269,
        "lng": 16.0817
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 25651,
      "name": "Mayenne",
      "names": {
        "zh": "马耶纳"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3031,
        "lng": -0.6136
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 26338,
      "name": "Novhorod-Siverskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 51.9833,
        "lng": 33.2667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8017,
      "name": "Nuevo Ideal",
      "names": {
        "fr": "Nuevo Idéal",
        "zh": "Nuevo理想",
        "it": "Nuevo ideale",
        "ja": "Nuevoは理想的です",
        "th": "Nuevo เหมาะอย่างยิ่ง"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.8875,
        "lng": -105.0728
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26344,
      "name": "Chiquimulilla",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.0858,
        "lng": -90.3822
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 26386,
      "name": "Mora",
      "names": {
        "zh": "莫拉",
        "ja": "モーラ",
        "th": "โมรา"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 61.0096,
        "lng": 14.5635
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 26359,
      "name": "Berkovitsa",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.2371,
        "lng": 23.1251
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 8280,
      "name": "Nieuw Nickerie",
      "names": {
        "fr": "Nickerie Nieuw",
        "id": "Nieuw naleri"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.9261,
        "lng": -56.9731
      },
      "country": "Suriname",
      "importance": 1
    },
    {
      "id": 26348,
      "name": "Chiatura",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.2903,
        "lng": 43.2819
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 26419,
      "name": "Rosario del Tala",
      "names": {
        "ja": "ロザリオデルタラ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.3008,
        "lng": -59.1389
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 26380,
      "name": "Bershad",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.3728,
        "lng": 29.5325
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 26273,
      "name": "Kapelle",
      "names": {
        "zh": "卡佩勒"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5,
        "lng": 3.95
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 26395,
      "name": "Gyomaendrod",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9361,
        "lng": 20.8233
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 26220,
      "name": "Franeker",
      "names": {
        "zh": "弗拉讷克"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.1875,
        "lng": 5.54
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 25837,
      "name": "Oudenbosch",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.5892,
        "lng": 4.5239
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 26430,
      "name": "Pichilemu",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -34.3851,
        "lng": -72.0049
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 26214,
      "name": "Perez",
      "names": {
        "es": "Pérez",
        "zh": "·佩雷斯",
        "ja": "・ペレス",
        "th": "เปเรซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1833,
        "lng": 121.9333
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 7758,
      "name": "Kamuli",
      "names": {
        "zh": "卡穆利"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9472,
        "lng": 33.1197
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 26018,
      "name": "Agdangan",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 13.8758,
        "lng": 121.9122
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 26297,
      "name": "Kaeng Khoi",
      "names": {
        "th": "แก่งคอย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5864,
        "lng": 100.9967
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 26043,
      "name": "Adygeysk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.88,
        "lng": 39.19
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 8333,
      "name": "Rokiskis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9616,
        "lng": 25.5807
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 8336,
      "name": "At Tur",
      "names": {
        "es": "En tur",
        "fr": "À Tur",
        "zh": "在tur",
        "de": "In Tur",
        "it": "A Tur",
        "id": "Di tur",
        "ja": "TURで",
        "th": "ตึง",
        "pt": "Na tur"
      },
      "countryCode": "EG",
      "latLng": {
        "lat": 28.2333,
        "lng": 33.6167
      },
      "country": "Egypt",
      "importance": 1
    },
    {
      "id": 26494,
      "name": "San Salvador",
      "names": {
        "zh": "圣萨尔瓦多",
        "ja": "サンサルバドール",
        "th": "ซานซัลวาดอร์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.6167,
        "lng": -58.5
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 26398,
      "name": "Diebougou",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 10.9667,
        "lng": -3.25
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 26205,
      "name": "Chaiyo",
      "names": {
        "th": "ไชโย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6666,
        "lng": 100.4702
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8318,
      "name": "Ziniare",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.5833,
        "lng": -1.3
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 25954,
      "name": "Sabinov",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.1,
        "lng": 21.1
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 8277,
      "name": "Delemont",
      "names": {
        "fr": "Delémont",
        "de": "Delémont",
        "it": "Delémont",
        "id": "Delémont",
        "pt": "Delémont"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3653,
        "lng": 7.3472
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 26528,
      "name": "Derventa",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.98,
        "lng": 17.91
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 26290,
      "name": "Pfarrkirchen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.4419,
        "lng": 12.9443
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 26414,
      "name": "Hajduhadhaz",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6833,
        "lng": 21.6667
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 26526,
      "name": "Los Santos",
      "names": {
        "zh": "洛杉矶桑托斯",
        "ja": "ロスサントス",
        "th": "ลอสซานโตส"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.7561,
        "lng": -73.1022
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 25861,
      "name": "Al Muzayrib",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.7109,
        "lng": 36.0266
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 26561,
      "name": "Kamianka-Dniprovska",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.4792,
        "lng": 34.4232
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8133,
      "name": "Maxcanu",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5833,
        "lng": -89.9833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26611,
      "name": "Kalajoki",
      "names": {
        "zh": "卡拉约基"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 64.2597,
        "lng": 23.9486
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 26616,
      "name": "Junin de los Andes",
      "names": {
        "id": "Junin de los and"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -39.9506,
        "lng": -71.0694
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 26607,
      "name": "Santa Barbara de Pinto",
      "names": {
        "es": "Santa Bárbara de Pinto",
        "zh": "圣巴巴拉德皮托",
        "ja": "サンタバーバラデピント",
        "th": "ซานต้าบาร์บาร่าเดอปินโต"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.4353,
        "lng": -74.7017
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8317,
      "name": "Lamphun",
      "names": {
        "zh": "南奔",
        "th": "ลำพูน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.5803,
        "lng": 99.0072
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 26621,
      "name": "Niksar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.5833,
        "lng": 36.9667
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 817,
      "name": "Pago Pago",
      "names": {
        "id": "Pago",
        "ja": "パゴパゴ"
      },
      "countryCode": "AS",
      "latLng": {
        "lat": -14.274,
        "lng": -170.7046
      },
      "country": "American Samoa",
      "importance": 0
    },
    {
      "id": 26533,
      "name": "Chok Chai",
      "names": {
        "th": "โชคชัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.7284,
        "lng": 102.1652
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8041,
      "name": "El Fuerte",
      "names": {
        "zh": "el fererte"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.4214,
        "lng": -108.62
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8344,
      "name": "Tartar",
      "names": {
        "es": "El sarro",
        "fr": "Tartre",
        "zh": "鞑靼人",
        "de": "Zahnstein",
        "it": "Tartaro",
        "ja": "タルタル",
        "th": "การเคลือบฟัน",
        "pt": "Tártaro"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.3353,
        "lng": 46.9303
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 26180,
      "name": "Bonneville",
      "names": {
        "zh": "博纳维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.0789,
        "lng": 6.4008
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 26636,
      "name": "Rong Kwang",
      "names": {
        "zh": "荣思",
        "th": "ร่องกวาง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.3392,
        "lng": 100.3172
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 26439,
      "name": "Sankt Veit an der Glan",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 46.7667,
        "lng": 14.3603
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 24972,
      "name": "Golub-Dobrzyn",
      "names": {
        "zh": "戈卢布-dobrzyn"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1,
        "lng": 19.05
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 26711,
      "name": "Sabugal",
      "names": {
        "zh": "萨布加尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.35,
        "lng": -7.0833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8337,
      "name": "Chai Nat",
      "names": {
        "zh": "柴NAT",
        "th": "ชัยนาท"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.1872,
        "lng": 100.1283
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 26289,
      "name": "Ban Laem",
      "names": {
        "zh": "班拉",
        "de": "Laisenban",
        "id": "Ban LaM",
        "ja": "ベロムを禁止します",
        "th": "บ้านแหลม",
        "pt": "Proibir laem"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.2168,
        "lng": 99.9767
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 26718,
      "name": "Koriukivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 51.7833,
        "lng": 32.25
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 26731,
      "name": "La Carlota",
      "names": {
        "ja": "ラカルロタ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.4179,
        "lng": -63.2935
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 26574,
      "name": "Kantang",
      "names": {
        "th": "กันตัง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.4067,
        "lng": 99.515
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7848,
      "name": "San Gwann",
      "names": {
        "zh": "圣金公司",
        "ja": "サングワン"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9094,
        "lng": 14.4786
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 8313,
      "name": "Ialoveni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.9431,
        "lng": 28.7778
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 26655,
      "name": "Gorazde",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 43.6667,
        "lng": 18.9778
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 26523,
      "name": "Ocotepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2191,
        "lng": -93.1724
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26646,
      "name": "Tiszavasvari",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.9511,
        "lng": 21.3689
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 26285,
      "name": "Brzeziny",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.8,
        "lng": 19.75
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 8357,
      "name": "Culfa",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.9558,
        "lng": 45.6308
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 7986,
      "name": "Isla Mujeres",
      "names": {
        "ja": "イスラミューエス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2084,
        "lng": -86.7115
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8350,
      "name": "Hincesti",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.8258,
        "lng": 28.5936
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 26753,
      "name": "Kamin-Kashyrskyi",
      "names": {
        "zh": "卡明，kashyrskyi",
        "th": "ขมิ้น-kashyrskyi"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 51.6242,
        "lng": 24.9606
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 26677,
      "name": "Pyrzyce",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.1417,
        "lng": 14.8917
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 26244,
      "name": "Grave",
      "names": {
        "es": "Tumba",
        "fr": "La tombe",
        "zh": "墓穴",
        "de": "Grab",
        "it": "Tomba",
        "id": "Kuburan",
        "ja": "墓",
        "th": "หลุมฝังศพ"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7592,
        "lng": 5.7383
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 8273,
      "name": "Mariental",
      "names": {
        "zh": "马林塔尔"
      },
      "countryCode": "NA",
      "latLng": {
        "lat": -24.6333,
        "lng": 17.9667
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 26436,
      "name": "Tenango del Aire",
      "names": {
        "it": "Tenano del Aire",
        "id": "Tauno del aire"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1575,
        "lng": -98.8581
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26671,
      "name": "Santa Ana Maya",
      "names": {
        "zh": "圣安娜玛雅",
        "ja": "サンタアナマヤ",
        "th": "ซานตาอานามายา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0,
        "lng": -101.0167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8356,
      "name": "Singerei",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.6406,
        "lng": 28.1419
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 26701,
      "name": "Mira",
      "names": {
        "zh": "米拉",
        "ja": "ミラ",
        "th": "มิร่า"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.4285,
        "lng": -8.7363
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 25163,
      "name": "San Bartolo",
      "names": {
        "zh": "圣巴托洛",
        "ja": "サンバルトロ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0844,
        "lng": -91.4558
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 26732,
      "name": "Urla",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3222,
        "lng": 26.7647
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 26368,
      "name": "Voerendaal",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.8833,
        "lng": 5.9167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 26774,
      "name": "Satao",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7333,
        "lng": -7.7167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 26614,
      "name": "Alanya",
      "names": {
        "zh": "阿兰亚",
        "ja": "アラニヤ",
        "th": "อาลานยา"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.5436,
        "lng": 31.9997
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 26823,
      "name": "Balchik",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.4102,
        "lng": 28.1662
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 26313,
      "name": "Lubaczow",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.1556,
        "lng": 23.123
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 26699,
      "name": "Neerijnen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8333,
        "lng": 5.2833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 8082,
      "name": "San Pedro Jicayan",
      "names": {
        "zh": "圣佩德罗·贾亚扬",
        "ja": "サンペドロジカヤン",
        "th": "ซานเปโดรเจวิคยัน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.4167,
        "lng": -97.9833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8375,
      "name": "Zamora",
      "names": {
        "zh": "萨莫拉",
        "ja": "サモラ",
        "th": "ซาโมร่า"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -4.0692,
        "lng": -78.9567
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 8061,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.5406,
        "lng": -111.1205
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26864,
      "name": "Saboya",
      "names": {
        "zh": "萨沃亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6969,
        "lng": -73.7631
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 26776,
      "name": "Dokkum",
      "names": {
        "zh": "多克姆"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.3253,
        "lng": 5.9989
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 25114,
      "name": "Aleksandrow Kujawski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.875,
        "lng": 18.7
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 8093,
      "name": "Forecariah",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 9.43,
        "lng": -13.098
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 26790,
      "name": "Zunil",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7836,
        "lng": -91.4844
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 26827,
      "name": "Sever do Vouga",
      "names": {
        "es": "Sever do huouga",
        "fr": "See Sever Do Vouga",
        "zh": "切断做vouga",
        "de": "Trium do vouga",
        "ja": "ヴューガを留めてください"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7167,
        "lng": -8.3667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8374,
      "name": "Taraclia",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 45.9,
        "lng": 28.6689
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 26830,
      "name": "Rezvanshahr",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 37.5511,
        "lng": 49.1394
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 26896,
      "name": "Vobkent Shahri",
      "names": {
        "de": "Voknent shahri"
      },
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.0233,
        "lng": 64.5139
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 8366,
      "name": "Encamp",
      "names": {
        "zh": "恩坎普",
        "ja": "野営",
        "th": "ตั้งค่าย"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.5361,
        "lng": 1.5828
      },
      "country": "Andorra",
      "importance": 1
    },
    {
      "id": 26820,
      "name": "Huai Yot",
      "names": {
        "zh": "淮差",
        "ja": "淮米",
        "th": "ห้วยยอด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 7.7894,
        "lng": 99.6347
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 26364,
      "name": "Nong Khae",
      "names": {
        "zh": "作响",
        "th": "หนองแค"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.3352,
        "lng": 100.8727
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 26953,
      "name": "Foca",
      "names": {
        "zh": "福查",
        "de": "BAZL"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.5,
        "lng": 18.7833
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 26676,
      "name": "Mecatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2135,
        "lng": -97.6574
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26107,
      "name": "Sarrebourg",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7347,
        "lng": 7.0539
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8282,
      "name": "Kibuye",
      "names": {
        "zh": "基布耶"
      },
      "countryCode": "RW",
      "latLng": {
        "lat": -2.0594,
        "lng": 29.3481
      },
      "country": "Rwanda",
      "importance": 1
    },
    {
      "id": 26838,
      "name": "Kalymnos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 36.9512,
        "lng": 26.9832
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 8370,
      "name": "Bulgan",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 48.8125,
        "lng": 103.5347
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 26595,
      "name": "Emiliano Zapata",
      "names": {
        "ja": "Emilianoザパタ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.65,
        "lng": -98.55
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26859,
      "name": "Nagykata",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4178,
        "lng": 19.7414
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 26943,
      "name": "Hatillo de Loba",
      "names": {
        "ja": "鳩尾デロッボ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.9556,
        "lng": -74.0769
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 27017,
      "name": "Pargas",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.3,
        "lng": 22.3
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 27023,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.5867,
        "lng": -74.8272
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8381,
      "name": "Beylaqan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.7692,
        "lng": 47.6156
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 8391,
      "name": "Ermoupoli",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.4504,
        "lng": 24.9333
      },
      "country": "Greece",
      "importance": 1
    },
    {
      "id": 26703,
      "name": "Revuca",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.6833,
        "lng": 20.1167
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 27056,
      "name": "Emet",
      "names": {
        "zh": "太网"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.3415,
        "lng": 29.2586
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 26741,
      "name": "Ebersberg",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.0833,
        "lng": 11.9667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 26880,
      "name": "Cuichapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.7667,
        "lng": -96.8667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26999,
      "name": "Haskoy",
      "names": {
        "zh": "哈斯柯伊"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6864,
        "lng": 41.6936
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 7865,
      "name": "Tempoal de Sanchez",
      "names": {
        "de": "Tempo de Sanchez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.5167,
        "lng": -98.3833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25937,
      "name": "Sillamae",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3931,
        "lng": 27.7742
      },
      "country": "Estonia",
      "importance": 2
    },
    {
      "id": 26707,
      "name": "Mende",
      "names": {
        "zh": "门代"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.5183,
        "lng": 3.5006
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8398,
      "name": "Qalat",
      "names": {
        "zh": "卡拉特"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 32.1061,
        "lng": 66.9069
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 8312,
      "name": "Valga",
      "names": {
        "zh": "巴尔加"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 57.7769,
        "lng": 26.0311
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 27098,
      "name": "Ahram",
      "names": {
        "zh": "金字塔"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 28.8825,
        "lng": 51.2744
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 27104,
      "name": "Chanal",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.6489,
        "lng": -92.2214
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26267,
      "name": "Vocklabruck",
      "names": {
        "de": "Vöcklabruck"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.0086,
        "lng": 13.6558
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 26546,
      "name": "Leibnitz",
      "names": {
        "zh": "莱布尼茨"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.7831,
        "lng": 15.545
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 26562,
      "name": "Hattem",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4744,
        "lng": 6.0697
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 27084,
      "name": "Kirkagac",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.1056,
        "lng": 27.6731
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 27068,
      "name": "Na Wa",
      "names": {
        "ja": "ワワー",
        "th": "นาวา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4692,
        "lng": 104.102
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 27153,
      "name": "Ilmajoki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.7333,
        "lng": 22.5833
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 27089,
      "name": "Ebejico",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3264,
        "lng": -75.7661
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 27171,
      "name": "Liperi",
      "names": {
        "zh": "利佩里"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.5333,
        "lng": 29.3833
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 27150,
      "name": "Arganil",
      "names": {
        "zh": "阿加尼尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.2167,
        "lng": -8.05
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 27026,
      "name": "Grootegast",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2091,
        "lng": 6.2674
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 25894,
      "name": "Cocotitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2167,
        "lng": -98.85
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27080,
      "name": "Sapna",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.4917,
        "lng": 19.0028
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 8165,
      "name": "Marsaskala",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8625,
        "lng": 14.5675
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 8400,
      "name": "Batken",
      "names": {
        "zh": "巴特肯"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 40.0625,
        "lng": 70.8194
      },
      "country": "Kyrgyzstan",
      "importance": 1
    },
    {
      "id": 27174,
      "name": "Ortakoy",
      "names": {
        "de": "Ortaköy"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7372,
        "lng": 34.0386
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 27194,
      "name": "Eura",
      "names": {
        "zh": "埃乌拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.1333,
        "lng": 22.0833
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 27188,
      "name": "Beryslav",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.8333,
        "lng": 33.4167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 26288,
      "name": "Vel\u0027ky Krtis",
      "names": {
        "it": "VEL\u0027KY KRIS",
        "ja": "ベル語Krtis"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.215,
        "lng": 19.3381
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 27054,
      "name": "Citlaltepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.3366,
        "lng": -97.8784
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 26825,
      "name": "Alapli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1833,
        "lng": 31.3833
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 27254,
      "name": "San Carlos Yautepec",
      "names": {
        "zh": "圣卡洛斯yautepec"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.5,
        "lng": -96.1
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27016,
      "name": "Zupanja",
      "names": {
        "zh": "茹帕尼亚"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.0667,
        "lng": 18.7
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 27252,
      "name": "Caimito",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.7894,
        "lng": -75.1161
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8408,
      "name": "Falesti",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.5736,
        "lng": 27.7092
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 27216,
      "name": "Tabua",
      "names": {
        "fr": "Tábua",
        "pt": "Tábua"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3667,
        "lng": -8.0333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 27149,
      "name": "Kozloduy",
      "names": {
        "zh": "科兹洛杜伊"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.7764,
        "lng": 23.7294
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 27229,
      "name": "Curiti",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.6044,
        "lng": -73.0681
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 27219,
      "name": "Biga",
      "names": {
        "zh": "比加"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.2281,
        "lng": 27.2422
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 27255,
      "name": "Oiba",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2639,
        "lng": -73.2992
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 27228,
      "name": "Ponte da Barca",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.8,
        "lng": -8.4167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8419,
      "name": "Druskininkai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.0206,
        "lng": 23.9725
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 27310,
      "name": "Alavus",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.5861,
        "lng": 23.6194
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 27235,
      "name": "Perleberg",
      "names": {
        "zh": "佩勒贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 53.0667,
        "lng": 11.8667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 8401,
      "name": "Assomada",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 15.0949,
        "lng": -23.6654
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 27329,
      "name": "Huite",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9175,
        "lng": -89.7172
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 26929,
      "name": "Chateaubriant",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 47.7169,
        "lng": -1.3761
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 26411,
      "name": "Provins",
      "names": {
        "zh": "普罗万"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5589,
        "lng": 3.2994
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 27369,
      "name": "Bilovodsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.1992,
        "lng": 39.5756
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8420,
      "name": "Floresti",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.8933,
        "lng": 28.3014
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 8423,
      "name": "Cimislia",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.52,
        "lng": 28.7842
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 27292,
      "name": "Wang Saphung",
      "names": {
        "es": "Wang Safung",
        "zh": "王山",
        "ja": "王サプン",
        "th": "วังสะพุง",
        "pt": "Wang Sapung"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.2995,
        "lng": 101.7624
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 27288,
      "name": "Tiszakecske",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9311,
        "lng": 20.095
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 27384,
      "name": "Volosovo",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 59.4472,
        "lng": 29.4847
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 8428,
      "name": "Dundo",
      "names": {},
      "countryCode": "AO",
      "latLng": {
        "lat": -7.38,
        "lng": 20.83
      },
      "country": "Angola",
      "importance": 1
    },
    {
      "id": 8427,
      "name": "Robertsport",
      "names": {
        "zh": "罗伯茨"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 6.7533,
        "lng": -11.3686
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 27238,
      "name": "Bicske",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.4907,
        "lng": 18.6363
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 27355,
      "name": "Nong Bua",
      "names": {
        "it": "Bua di Nong",
        "th": "หนองบัว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.8647,
        "lng": 100.5858
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 27421,
      "name": "Mykhailivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.2717,
        "lng": 35.2248
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 27413,
      "name": "Alijo",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2764,
        "lng": -7.4749
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 27453,
      "name": "Sogndal",
      "names": {
        "zh": "松达尔"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 61.2297,
        "lng": 7.1006
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 27392,
      "name": "Sarbogard",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.8878,
        "lng": 18.6193
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 27308,
      "name": "Don Sak",
      "names": {
        "zh": "唐萨克",
        "id": "Don",
        "ja": "ドンサク",
        "th": "ดอนสัก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.3169,
        "lng": 99.6944
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 25411,
      "name": "Schoonhoven",
      "names": {
        "zh": "斯洪霍芬"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9475,
        "lng": 4.8486
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 27496,
      "name": "Aheqi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 40.9372,
        "lng": 78.4543
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 27253,
      "name": "Burhaniye",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.5108,
        "lng": 26.9786
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 27090,
      "name": "Mitontic",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8667,
        "lng": -92.6333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8275,
      "name": "Zebbug",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8733,
        "lng": 14.4417
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 27282,
      "name": "Ried im Innkreis",
      "names": {
        "zh": "Ried iminkreis",
        "ja": "Im Innkreisがリードしました",
        "th": "ried imnkreis"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.21,
        "lng": 13.4894
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 7811,
      "name": "Balancan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.8,
        "lng": -91.53
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27539,
      "name": "Ojuelos de Jalisco",
      "names": {
        "ja": "オジュルスデ・イラスコ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.8642,
        "lng": -101.5933
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27423,
      "name": "Janow Lubelski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.7,
        "lng": 22.4
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 8003,
      "name": "Jaltenango",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.8725,
        "lng": -92.725
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27555,
      "name": "El Palmar",
      "names": {
        "ja": "エルパルマー"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.65,
        "lng": -91.5833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 27533,
      "name": "Silifke",
      "names": {
        "zh": "锡利夫凯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.3783,
        "lng": 33.9261
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 27568,
      "name": "Abasolo",
      "names": {
        "zh": "阿瓦索洛"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.0559,
        "lng": -98.3733
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27337,
      "name": "Bad Fallingbostel",
      "names": {
        "es": "Mal caída de bostales",
        "fr": "Mauvais cheachbostel",
        "zh": "糟糕的下降堡",
        "id": "Fallingbostel yang buruk",
        "ja": "悪いFallingbostel"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.8675,
        "lng": 9.6967
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 27597,
      "name": "Malargue",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -35.4745,
        "lng": -69.5853
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 27530,
      "name": "Vendas Novas",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.678,
        "lng": -8.4555
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 26674,
      "name": "Lienz",
      "names": {
        "zh": "利恩茨"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.8297,
        "lng": 12.7697
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 27395,
      "name": "Hildburghausen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.4261,
        "lng": 10.7289
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 27380,
      "name": "Nyirbator",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.8353,
        "lng": 22.13
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 27190,
      "name": "Nova Gradiska",
      "names": {
        "zh": "Nova Gradista",
        "ja": "ノヴァグラディスカ"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.25,
        "lng": 17.3833
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 26867,
      "name": "Pfaffikon",
      "names": {
        "es": "Pfäffikon",
        "fr": "Pfäffikon",
        "zh": "晋费菲孔",
        "de": "Pfäffikon",
        "it": "Pfäffikon",
        "id": "Pfäffikon"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3667,
        "lng": 8.7822
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 27631,
      "name": "Haradok",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.4667,
        "lng": 29.9833
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 27096,
      "name": "Chateau-Gontier",
      "names": {
        "zh": "城堡-gontier",
        "ja": "シャトー-gontier",
        "th": "ชาโตว์-gontier"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.8286,
        "lng": -0.7028
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 27357,
      "name": "Koszeg",
      "names": {
        "th": "โคสเซ็ก"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3817,
        "lng": 16.5519
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 27373,
      "name": "Ghabaghib",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 33.1839,
        "lng": 36.2264
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 27510,
      "name": "Veurne",
      "names": {
        "fr": "Furnes",
        "zh": "弗尔讷"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 51.0722,
        "lng": 2.6622
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 27653,
      "name": "Cafayate",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -26.07,
        "lng": -65.98
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 27340,
      "name": "Villefranche-de-Rouergue",
      "names": {
        "zh": "自由城去rouergue",
        "ja": "villefranche・ド・rouergue",
        "th": "villefranche-เดอ rouergue"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.3525,
        "lng": 2.0342
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 27309,
      "name": "Opmeer",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.7033,
        "lng": 4.9444
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 27331,
      "name": "Thiers",
      "names": {
        "zh": "梯也尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.8564,
        "lng": 3.5475
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8452,
      "name": "Berane",
      "names": {
        "ja": "ベラネ"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.8473,
        "lng": 19.8694
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 27683,
      "name": "Lieksa",
      "names": {
        "zh": "列克萨"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.3167,
        "lng": 30.0167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 27673,
      "name": "Kankaanpaa",
      "names": {
        "zh": "坎康佩"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.8042,
        "lng": 22.3944
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8458,
      "name": "Ndele",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 8.4091,
        "lng": 20.653
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 27516,
      "name": "Villa Aldama",
      "names": {
        "zh": "别墅阿尔多玛",
        "ja": "ヴィラアルダマ",
        "th": "วิลล่าอัลแดมา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.65,
        "lng": -97.2333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27584,
      "name": "Kuchinarai",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.5318,
        "lng": 104.044
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 27603,
      "name": "Tolcayuca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.95,
        "lng": -98.9167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 25614,
      "name": "Vitry-le-Francois",
      "names": {
        "zh": "维特里了-弗朗索瓦",
        "ja": "vitry・ル・フランソワ",
        "th": "vitry-เลอ-ฟรอง"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7247,
        "lng": 4.5844
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 27569,
      "name": "Ovidiopol",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.2667,
        "lng": 30.4333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 27721,
      "name": "Bajestan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.5164,
        "lng": 58.1844
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 7852,
      "name": "San Nicolas de los Ranchos",
      "names": {
        "zh": "圣尼古拉斯德洛斯·兰乔斯",
        "ja": "サンニコラスデロスランチョス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0667,
        "lng": -98.4833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27348,
      "name": "Altstatten",
      "names": {
        "fr": "Altstätten",
        "de": "Altstätten",
        "it": "Altstätten"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.378,
        "lng": 9.5488
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 27294,
      "name": "San Antonio Palopo",
      "names": {
        "zh": "圣安东尼奥帕洛普",
        "ja": "サンアントニオパロポ",
        "th": "ซานอันโตนิโอปาโปโล"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7,
        "lng": -91.1167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 8449,
      "name": "Bingol",
      "names": {
        "zh": "宾格尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.8861,
        "lng": 40.5017
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 27135,
      "name": "Appingedam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.3167,
        "lng": 6.8667
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 27708,
      "name": "Puente Nacional",
      "names": {
        "ja": "プエンテネシャル"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8772,
        "lng": -73.6786
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 27356,
      "name": "Torbali",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.1619,
        "lng": 27.3583
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 27463,
      "name": "Myjava",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.7578,
        "lng": 17.5686
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 8462,
      "name": "Bol",
      "names": {
        "zh": "平原",
        "ja": "ボル"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 13.4586,
        "lng": 14.7147
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 27736,
      "name": "Nemyriv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.9794,
        "lng": 28.8439
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 27379,
      "name": "Issoudun",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 46.9481,
        "lng": 1.9933
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 7970,
      "name": "Angel R. Cabada",
      "names": {
        "zh": "天使r. cabada",
        "ja": "天使R.カバダ",
        "th": "แองเจิลอาร์ Cabada"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5969,
        "lng": -95.4453
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27642,
      "name": "Obidos",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.3585,
        "lng": -9.176
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 27678,
      "name": "Lom Sak",
      "names": {
        "ja": "ロムサク",
        "th": "หล่มสัก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.7775,
        "lng": 101.2468
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 27787,
      "name": "Sereflikochisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.9444,
        "lng": 33.5419
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8421,
      "name": "Izola",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5395,
        "lng": 13.6604
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 27701,
      "name": "Hollabrunn",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.5667,
        "lng": 16.0833
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 27724,
      "name": "Buldan",
      "names": {
        "zh": "布尔丹"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.045,
        "lng": 28.8306
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 27780,
      "name": "Prymorsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.7333,
        "lng": 36.3596
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 27704,
      "name": "Gora",
      "names": {
        "zh": "戈拉",
        "ja": "強羅"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 51.6667,
        "lng": 16.55
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 27793,
      "name": "Oliva",
      "names": {
        "zh": "奥利瓦",
        "ja": "オリバ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -32.0416,
        "lng": -63.5698
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 8460,
      "name": "Skofja Loka",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1655,
        "lng": 14.3064
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 7433,
      "name": "Fgura",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8725,
        "lng": 14.5228
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 27745,
      "name": "Deutschlandsberg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 46.8161,
        "lng": 15.215
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 27619,
      "name": "Opatija",
      "names": {
        "zh": "欧帕提亚",
        "ja": "オパティア",
        "th": "ออปาติจา"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3347,
        "lng": 14.3069
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 27822,
      "name": "Lyuban\u0027",
      "names": {
        "es": "Lyuban \u0027",
        "fr": "Lyuban \u0027",
        "de": "Lyuban \u0027",
        "it": "Lyuban \u0027",
        "id": "Lyuban \u0027",
        "ja": "lyuban \u0027",
        "th": "lyuban \u0027",
        "pt": "Lyuban \u0027"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 52.7819,
        "lng": 28.0525
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 26928,
      "name": "Mixtla de Altamirano",
      "names": {
        "es": "MIXTA DE ALTAMIRANO",
        "ja": "アルタミラノのミックス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6,
        "lng": -97.0
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27649,
      "name": "Labin",
      "names": {
        "zh": "拉宾"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.0833,
        "lng": 14.1167
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 27686,
      "name": "Besikduzu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.052,
        "lng": 39.2329
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 27760,
      "name": "Candelaria",
      "names": {
        "zh": "坎德拉里亚"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.4592,
        "lng": -74.8806
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 27821,
      "name": "Tlachichilco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.6217,
        "lng": -98.1994
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27018,
      "name": "Weiz",
      "names": {
        "zh": "魏茨"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.2189,
        "lng": 15.6253
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 8472,
      "name": "Alotau",
      "names": {
        "zh": "阿洛陶"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -10.3167,
        "lng": 150.4333
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 26478,
      "name": "Tepetzintla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9667,
        "lng": -97.8333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27853,
      "name": "Mala Vyska",
      "names": {
        "ja": "マラ・ビスカ"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 48.65,
        "lng": 31.6333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 27747,
      "name": "Sint Anthonis",
      "names": {
        "de": "Sint-Anthonis",
        "ja": "シントアントニス"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6258,
        "lng": 5.8811
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 27350,
      "name": "Saint-Gaudens",
      "names": {
        "zh": "圣 -  gaudens",
        "ja": "サン・gaudens",
        "th": "เซนต์-gaudens"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1081,
        "lng": 0.7233
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 27864,
      "name": "Kalmiuske",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.6667,
        "lng": 38.0667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 27781,
      "name": "Santa Comba Dao",
      "names": {
        "zh": "圣诞老人组合道",
        "ja": "サンタコンボダ",
        "th": "ซานตาคอมบาแด"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.4,
        "lng": -8.1333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 27852,
      "name": "Drawsko Pomorskie",
      "names": {
        "fr": "Dessinko Pomorskie"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.5333,
        "lng": 15.8
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 27905,
      "name": "Shengping",
      "names": {
        "zh": "升平"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 28.4865,
        "lng": 98.913
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 27659,
      "name": "Phon",
      "names": {
        "zh": "响度",
        "ja": "ホン",
        "th": "พล",
        "pt": "Telefone"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.8084,
        "lng": 102.6018
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8469,
      "name": "Elektrenai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.7886,
        "lng": 24.6612
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 27637,
      "name": "Santa Lucia",
      "names": {
        "es": "Santa Lucía",
        "zh": "圣卢西亚",
        "ja": "サンタルシア",
        "th": "ซานตาลูเซีย"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.3247,
        "lng": -74.9589
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 27378,
      "name": "Zlate Moravce",
      "names": {
        "de": "Zlatte Moravce"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.3781,
        "lng": 18.3964
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 27677,
      "name": "Jarabulus",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.8175,
        "lng": 38.0111
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 26092,
      "name": "Arcachon",
      "names": {
        "zh": "阿尔卡雄",
        "ja": "アルカション"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.6586,
        "lng": -1.1689
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 27890,
      "name": "La Union",
      "names": {
        "zh": "洛杉勃",
        "it": "Launione",
        "id": "Serikat pekerja",
        "pt": "La união"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.8572,
        "lng": -75.2767
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 27529,
      "name": "Eijsden",
      "names": {
        "zh": "埃斯登"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.7778,
        "lng": 5.7108
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 27818,
      "name": "Loutraki",
      "names": {
        "id": "Loutráki",
        "pt": "Loutráki"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.975,
        "lng": 22.9767
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 27501,
      "name": "Miesbach",
      "names": {
        "zh": "米斯巴赫"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 47.789,
        "lng": 11.8338
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 27855,
      "name": "Mistelbach",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.5667,
        "lng": 16.5667
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 27646,
      "name": "Cisneros",
      "names": {
        "zh": "锡斯内罗斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.5383,
        "lng": -75.0886
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 25756,
      "name": "Espita",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0128,
        "lng": -88.3047
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27982,
      "name": "Esil",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.9556,
        "lng": 66.4042
      },
      "country": "Kazakhstan",
      "importance": 2
    },
    {
      "id": 27896,
      "name": "Den Chai",
      "names": {
        "zh": "书柴",
        "ja": "デンチャイ",
        "th": "เดนชัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.9835,
        "lng": 100.0519
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 27027,
      "name": "Weinfelden",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 47.5698,
        "lng": 9.112
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 8380,
      "name": "Voru",
      "names": {
        "de": "Võru"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 57.8486,
        "lng": 26.9928
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 27940,
      "name": "Epazoyucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0177,
        "lng": -98.6361
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27994,
      "name": "Cholpon-Ata",
      "names": {
        "zh": "乔尔蓬-数据",
        "ja": "cholpon-のATA"
      },
      "countryCode": "KG",
      "latLng": {
        "lat": 42.65,
        "lng": 77.0833
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 28036,
      "name": "Coronel Dorrego",
      "names": {
        "pt": "Coronel dorego"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.7,
        "lng": -61.2667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 8297,
      "name": "Zejtun",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8556,
        "lng": 14.5333
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 27929,
      "name": "Cerrillos",
      "names": {
        "zh": "塞里约斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.9,
        "lng": -65.4833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 8438,
      "name": "Rabat",
      "names": {
        "es": "De Rabat",
        "zh": "拉巴特",
        "ja": "ラバト",
        "th": "ราบัต"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8817,
        "lng": 14.3989
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 27959,
      "name": "Cayeli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.093,
        "lng": 40.7292
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28029,
      "name": "Guayabal",
      "names": {
        "zh": "瓜亚瓦尔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.0306,
        "lng": -74.8844
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 27498,
      "name": "Landsmeer",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4293,
        "lng": 4.9136
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 28042,
      "name": "Yakymivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.6967,
        "lng": 35.1664
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 28033,
      "name": "Myronivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.65,
        "lng": 30.9833
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 28049,
      "name": "Poldasht",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 39.3481,
        "lng": 45.0711
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 8453,
      "name": "Fort Liberte",
      "names": {
        "es": "Fuerte liberte",
        "zh": "堡垒自由",
        "it": "Forte liberte",
        "id": "Kaum benteng",
        "ja": "リバルト砦"
      },
      "countryCode": "HT",
      "latLng": {
        "lat": 19.6656,
        "lng": -71.8448
      },
      "country": "Haiti",
      "importance": 1
    },
    {
      "id": 28080,
      "name": "Klyetsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.0636,
        "lng": 26.6372
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 8492,
      "name": "Mariehamn",
      "names": {
        "zh": "玛丽港"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.0986,
        "lng": 19.9444
      },
      "country": "Finland",
      "importance": 1
    },
    {
      "id": 27965,
      "name": "Satuek",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 15.3008,
        "lng": 103.3013
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8125,
      "name": "Sonoita",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 31.8614,
        "lng": -112.8544
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28103,
      "name": "Egersund",
      "names": {
        "zh": "埃格松"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.45,
        "lng": 6.0067
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 27322,
      "name": "Tlacolulan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6667,
        "lng": -97.0
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28139,
      "name": "Kavarna",
      "names": {
        "zh": "卡瓦尔纳"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.436,
        "lng": 28.3392
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 8488,
      "name": "Sanniquellie",
      "names": {},
      "countryCode": "LR",
      "latLng": {
        "lat": 7.371,
        "lng": -8.685
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 8494,
      "name": "Qax",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.4194,
        "lng": 46.9181
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 28156,
      "name": "Jimani",
      "names": {
        "es": "Jimaní"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.4833,
        "lng": -71.85
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 27681,
      "name": "Ganserndorf",
      "names": {
        "de": "Gänserndorf"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.3406,
        "lng": 16.7175
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 8050,
      "name": "Santa Maria Huazolotitlan",
      "names": {
        "fr": "Santa Maria HuazoloTlan",
        "ja": "サンタマリアHuazolotitlan"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3014,
        "lng": -97.9125
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27902,
      "name": "Ulricehamn",
      "names": {
        "zh": "乌尔里瑟港"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 57.7917,
        "lng": 13.4186
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 8500,
      "name": "Yuscaran",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 13.9433,
        "lng": -86.8667
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 8503,
      "name": "Hadibu",
      "names": {},
      "countryCode": "YE",
      "latLng": {
        "lat": 12.6519,
        "lng": 54.0239
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 28227,
      "name": "Monte Quemado",
      "names": {
        "ja": "モンテクイマド"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -25.8072,
        "lng": -62.8278
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 8261,
      "name": "Mwanza",
      "names": {
        "zh": "姆万扎"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -15.6167,
        "lng": 34.5167
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 28236,
      "name": "Onoto",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 9.5958,
        "lng": -65.1897
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 28249,
      "name": "Humahuaca",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -23.2054,
        "lng": -65.3505
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 28121,
      "name": "Resende",
      "names": {
        "zh": "雷森迪"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1,
        "lng": -7.95
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 28251,
      "name": "Yangirabot",
      "names": {},
      "countryCode": "UZ",
      "latLng": {
        "lat": 40.0333,
        "lng": 65.9667
      },
      "country": "Uzbekistan",
      "importance": 2
    },
    {
      "id": 27882,
      "name": "Bytca",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.2228,
        "lng": 18.5581
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 28039,
      "name": "Zierikzee",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6497,
        "lng": 3.9164
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 28267,
      "name": "Belen",
      "names": {
        "zh": "贝伦"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -27.6496,
        "lng": -67.0333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 8387,
      "name": "Las Tablas",
      "names": {
        "fr": "Tablas Las",
        "de": "Las-Tablas",
        "ja": "ラスタブラス"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 7.7604,
        "lng": -80.28
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 27559,
      "name": "Zaragoza",
      "names": {
        "fr": "Saragosse",
        "zh": "萨拉戈萨",
        "it": "Saragozza",
        "ja": "サラゴサ",
        "th": "ซาราโกซา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.9487,
        "lng": -94.6436
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28028,
      "name": "Tekkekoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2125,
        "lng": 36.4569
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28221,
      "name": "Talaigua Nuevo",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.3028,
        "lng": -74.5678
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 28082,
      "name": "Batonyterenye",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.9892,
        "lng": 19.8286
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 28149,
      "name": "Virton",
      "names": {},
      "countryCode": "BE",
      "latLng": {
        "lat": 49.5675,
        "lng": 5.5325
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 27700,
      "name": "Randaberg",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.0017,
        "lng": 5.6153
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 26563,
      "name": "Pionerskiy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.95,
        "lng": 20.2167
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 28205,
      "name": "Sarospatak",
      "names": {
        "zh": "沙罗什帕塔克"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 48.3189,
        "lng": 21.5661
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 27752,
      "name": "Saverne",
      "names": {
        "zh": "萨韦尔讷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7414,
        "lng": 7.3619
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 28288,
      "name": "Bayindir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2192,
        "lng": 27.6481
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28186,
      "name": "Covenas",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 9.4014,
        "lng": -75.68
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 28340,
      "name": "Illintsi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.1,
        "lng": 29.2
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 28310,
      "name": "Susurluk",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.9136,
        "lng": 28.1578
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28306,
      "name": "Sogne",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.0942,
        "lng": 7.7725
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 28374,
      "name": "El Playon",
      "names": {
        "it": "Playon",
        "ja": "エルプラヤン"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.4703,
        "lng": -73.2033
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 28248,
      "name": "Lang Suan",
      "names": {
        "zh": "郎苏"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.9519,
        "lng": 99.0813
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 28365,
      "name": "Anamur",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.0836,
        "lng": 32.8261
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28367,
      "name": "Tosya",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.017,
        "lng": 34.0383
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28320,
      "name": "Krosno Odrzanskie",
      "names": {
        "ja": "KrosnoOdrzanskie"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 52.0542,
        "lng": 15.1
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 28135,
      "name": "Kormend",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0108,
        "lng": 16.6056
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 28241,
      "name": "Sankt Johann im Pongau",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.35,
        "lng": 13.2
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 28382,
      "name": "Krizevci",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 46.0333,
        "lng": 16.5333
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 28348,
      "name": "Kaset Wisai",
      "names": {
        "ja": "カセットウィダイ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.6556,
        "lng": 103.5836
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8211,
      "name": "Gonzalez",
      "names": {
        "es": "González",
        "zh": "·冈萨雷斯",
        "ja": "ゴンザレス",
        "th": "กอนซาเลซ",
        "pt": "Gonzales"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.8281,
        "lng": -98.4306
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27914,
      "name": "Temamatla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2028,
        "lng": -98.87
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8520,
      "name": "Vanimo",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -2.6817,
        "lng": 141.3014
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 28238,
      "name": "Gardony",
      "names": {
        "de": "Gárdony"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1973,
        "lng": 18.6091
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 27391,
      "name": "Blaricum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2728,
        "lng": 5.2422
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 28465,
      "name": "Qasr-e Shirin",
      "names": {
        "ja": "QASR-Eシリン"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 34.5156,
        "lng": 45.5792
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 28436,
      "name": "Morazan",
      "names": {
        "es": "Morazán",
        "zh": "莫拉桑",
        "pt": "Morazán"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9322,
        "lng": -90.1433
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 28474,
      "name": "Staryya Darohi",
      "names": {
        "fr": "Staryya Darhi",
        "de": "Starysta Darohi",
        "ja": "ダローギスタリヤ"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 53.0394,
        "lng": 28.265
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 28425,
      "name": "Sanjiaocheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.8993,
        "lng": 100.9872
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 27269,
      "name": "Laren",
      "names": {
        "zh": "拉伦"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2544,
        "lng": 5.2317
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 28110,
      "name": "Bad Schwalbach",
      "names": {
        "ja": "悪いシュワルバッハ",
        "pt": "Mau schwalbach"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1401,
        "lng": 8.0694
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 27970,
      "name": "Salaya",
      "names": {
        "zh": "萨拉亚",
        "th": "ศาลายา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8023,
        "lng": 100.3208
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 27809,
      "name": "Hinwil",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3033,
        "lng": 8.8444
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 28376,
      "name": "Marcali",
      "names": {
        "zh": "毛尔曹利"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.5831,
        "lng": 17.4064
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 28454,
      "name": "San Jose de Aerocuar",
      "names": {
        "zh": "圣何塞德航空",
        "ja": "サンノゼ・デアロカー"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 10.6014,
        "lng": -63.3278
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 27924,
      "name": "Padre Burgos",
      "names": {},
      "countryCode": "PH",
      "latLng": {
        "lat": 10.0333,
        "lng": 125.0167
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 26361,
      "name": "Zaragoza",
      "names": {
        "fr": "Saragosse",
        "zh": "萨拉戈萨",
        "it": "Saragozza",
        "ja": "サラゴサ",
        "th": "ซาราโกซา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.4869,
        "lng": -100.9175
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27381,
      "name": "Doesburg",
      "names": {
        "zh": "杜斯堡"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0167,
        "lng": 6.1333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 28484,
      "name": "Wegorzewo",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 54.2167,
        "lng": 21.75
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 27850,
      "name": "Panajachel",
      "names": {
        "zh": "巴纳哈契"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7361,
        "lng": -91.1558
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 8214,
      "name": "Villanueva",
      "names": {
        "zh": "维拉纽瓦"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.3536,
        "lng": -102.8831
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27823,
      "name": "Esquipulas Palo Gordo",
      "names": {
        "pt": "Esquípulas Palo Gordo"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9333,
        "lng": -91.8167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 28472,
      "name": "Santa Ana Huista",
      "names": {
        "zh": "圣安娜霍斯塔",
        "ja": "サンタアナハイスタ",
        "th": "ซานตาอานาฮูสตา"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 15.6833,
        "lng": -91.8167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 28461,
      "name": "Sansare",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7478,
        "lng": -90.1158
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 28045,
      "name": "Crikvenica",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.1833,
        "lng": 14.7
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 28393,
      "name": "Mineral de Angangueo",
      "names": {
        "fr": "Minéral de Angangueo",
        "zh": "矿物质矿园",
        "ja": "ミネラルデアンガニュオ",
        "th": "แร่ DE Angangueo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6206,
        "lng": -100.2844
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28555,
      "name": "Tha Chang",
      "names": {
        "id": "Kekacauan",
        "th": "ท่าช้าง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.2674,
        "lng": 99.1922
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8440,
      "name": "Murska Sobota",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6586,
        "lng": 16.1619
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 27858,
      "name": "Svidnik",
      "names": {
        "zh": "斯维德尼克"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.3056,
        "lng": 21.5678
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 28284,
      "name": "Bang Ban",
      "names": {
        "es": "Prohibición",
        "fr": "Interdiction de qn",
        "zh": "爆炸禁令",
        "de": "Knallverbot",
        "id": "Larangan",
        "ja": "バン禁止",
        "th": "บางห้าม",
        "pt": "Banir proibir"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4247,
        "lng": 100.4758
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 28507,
      "name": "Si Wilai",
      "names": {
        "th": "ศรีวิไล"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.1865,
        "lng": 103.7408
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 28090,
      "name": "Briancon",
      "names": {
        "fr": "Briançon",
        "it": "Briançon",
        "id": "Briançon"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.8958,
        "lng": 6.635
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8502,
      "name": "Mpigi",
      "names": {
        "zh": "姆皮吉"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.225,
        "lng": 32.3136
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 28277,
      "name": "Tortoli",
      "names": {
        "zh": "托尔托利",
        "id": "Tortolì"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.9333,
        "lng": 9.65
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 28636,
      "name": "Belen de los Andaquies",
      "names": {
        "fr": "Belen de los andakies",
        "zh": "Belen de Los Andaquers",
        "it": "BELEN DE LOS ANDAQUE",
        "id": "Belen de los danaquies",
        "ja": "Belen de los Andaques"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.4164,
        "lng": -75.8727
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 28606,
      "name": "Pedernales",
      "names": {
        "zh": "佩德纳莱斯"
      },
      "countryCode": "DO",
      "latLng": {
        "lat": 18.038,
        "lng": -71.741
      },
      "country": "Dominican Republic",
      "importance": 2
    },
    {
      "id": 28445,
      "name": "Tyrnavos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.7353,
        "lng": 22.2869
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 28585,
      "name": "El Aguila",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9081,
        "lng": -76.0422
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 28583,
      "name": "Viransehir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.2306,
        "lng": 39.7653
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28670,
      "name": "Villa La Angostura",
      "names": {
        "ja": "ヴィララゴスラ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.7616,
        "lng": -71.6448
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 28602,
      "name": "Mysliborz",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 52.925,
        "lng": 14.8667
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 28392,
      "name": "Joquicingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0556,
        "lng": -99.5467
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28599,
      "name": "Saraykoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9265,
        "lng": 28.9267
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28680,
      "name": "Chornomorske",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.5031,
        "lng": 32.705
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 26692,
      "name": "Tezoatlan de Segura y Luna",
      "names": {
        "it": "Tezoalanda de Segura y Luna",
        "ja": "Tezoatlan de Seguray Luna"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.65,
        "lng": -97.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8535,
      "name": "Rezina",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.7492,
        "lng": 28.9622
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 27964,
      "name": "Phanat Nikhom",
      "names": {
        "ja": "ファナットニックトン"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.4458,
        "lng": 101.1844
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 28409,
      "name": "Apt",
      "names": {
        "zh": "公寓",
        "id": "Tepat",
        "ja": "APTの",
        "th": "อพาร์ทเมนต์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.8761,
        "lng": 5.3964
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8021,
      "name": "Saucillo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 28.0333,
        "lng": -105.2833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28518,
      "name": "Regen",
      "names": {
        "zh": "再生",
        "ja": "リジェネ",
        "th": "ฟื้นฟู"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.97,
        "lng": 13.1264
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 8536,
      "name": "Loikaw",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 19.6742,
        "lng": 97.2092
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 8541,
      "name": "Biltine",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 14.5275,
        "lng": 20.9267
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 28558,
      "name": "Al `Amadiyah",
      "names": {
        "zh": "Al`amadiyah",
        "de": "Al` Amadiyah",
        "ja": "Al` Amadiyah",
        "th": "อัล `amadiyah",
        "pt": "Al \u0027Amadiyah"
      },
      "countryCode": "IQ",
      "latLng": {
        "lat": 37.0922,
        "lng": 43.4878
      },
      "country": "Iraq",
      "importance": 2
    },
    {
      "id": 27471,
      "name": "Nowe Miasto Lubawskie",
      "names": {
        "fr": "Now Miasto Lubawskie",
        "th": "ตอนนี้ Miasto Lubawskie"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.4167,
        "lng": 19.5833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 28584,
      "name": "Agua de Dios",
      "names": {
        "ja": "アグアデイオス"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.3781,
        "lng": -74.6683
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 28559,
      "name": "Tolna",
      "names": {
        "zh": "托尔瑙"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4167,
        "lng": 18.7833
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 28627,
      "name": "Fao Rai",
      "names": {
        "zh": "粮农组织rai",
        "th": "fao ไร่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.0175,
        "lng": 103.3057
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8270,
      "name": "Acanceh",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8133,
        "lng": -89.4524
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28742,
      "name": "Kiskunmajsa",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4922,
        "lng": 19.7367
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 28729,
      "name": "Zhydachiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.3833,
        "lng": 24.1333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 26772,
      "name": "Alamos",
      "names": {
        "zh": "洛斯阿拉莫斯",
        "ja": "アラモス",
        "th": "อาลามอส"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.0275,
        "lng": -108.94
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28788,
      "name": "El Tarra",
      "names": {
        "zh": "埃尔塔拉",
        "ja": "エルタラ",
        "pt": "El tu tarra"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 8.5756,
        "lng": -73.0944
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 28582,
      "name": "Rackeve",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.1608,
        "lng": 18.9456
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 28730,
      "name": "Volkermarkt",
      "names": {
        "de": "Völkermarkt",
        "it": "Völkermarkt"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 46.6622,
        "lng": 14.6344
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 28797,
      "name": "Rokytne",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.6897,
        "lng": 30.4751
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 28753,
      "name": "Texcatepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5833,
        "lng": -98.3667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28820,
      "name": "Neman",
      "names": {
        "zh": "涅曼河"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 55.0333,
        "lng": 22.0333
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 8351,
      "name": "Attard",
      "names": {
        "zh": "阿塔尔德"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9,
        "lng": 14.45
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 28866,
      "name": "Emirdag",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0197,
        "lng": 31.1503
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28867,
      "name": "Yalvac",
      "names": {
        "zh": "亚尔瓦奇"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3,
        "lng": 31.1833
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28837,
      "name": "Zwettl",
      "names": {
        "zh": "茨韦特尔"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.6033,
        "lng": 15.1689
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 28893,
      "name": "Bobrynets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0578,
        "lng": 32.1581
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8560,
      "name": "Beni Abbes",
      "names": {
        "it": "Abbes Beni"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 30.08,
        "lng": -2.1
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 28323,
      "name": "Eisenberg",
      "names": {
        "zh": "艾森伯格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.9667,
        "lng": 11.9
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 28818,
      "name": "Sultanhani",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2481,
        "lng": 33.5465
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28922,
      "name": "Nivala",
      "names": {
        "zh": "尼瓦拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.9292,
        "lng": 24.9778
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8548,
      "name": "Anenii Noi",
      "names": {
        "fr": "Anenii no"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 46.8817,
        "lng": 29.2308
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 28774,
      "name": "Estanzuela",
      "names": {
        "zh": "埃斯坦苏埃拉"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9979,
        "lng": -89.5705
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 28909,
      "name": "Sagarejo",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.7333,
        "lng": 45.3333
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 8551,
      "name": "Strumica",
      "names": {
        "zh": "斯特鲁米察"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4375,
        "lng": 22.6431
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 28798,
      "name": "Phrai Bueng",
      "names": {
        "zh": "彭布恩",
        "it": "Phai Bueng",
        "th": "ไพรบึง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.749,
        "lng": 104.3574
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8559,
      "name": "Vinica",
      "names": {
        "zh": "维尼察"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.8828,
        "lng": 22.5092
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 28914,
      "name": "Davos",
      "names": {
        "zh": "达沃斯",
        "ja": "ダボス",
        "th": "ดาวอส"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.8091,
        "lng": 9.8398
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 28936,
      "name": "Mogotes",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4764,
        "lng": -72.9703
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 28968,
      "name": "Sarmiento",
      "names": {
        "zh": "萨米恩托"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -45.6,
        "lng": -69.0833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 28768,
      "name": "Phibun Mangsahan",
      "names": {
        "th": "พิบูลมังสาหาร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2482,
        "lng": 105.2296
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7978,
      "name": "Villa Corzo",
      "names": {
        "ja": "ヴィラコルゾ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.1848,
        "lng": -93.2677
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28899,
      "name": "Khlung",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 12.4525,
        "lng": 102.2267
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 28995,
      "name": "Kitee",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.0986,
        "lng": 30.1375
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 28920,
      "name": "Pua",
      "names": {
        "zh": "聚丙交酯",
        "th": "ปัว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.1799,
        "lng": 100.9089
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 28991,
      "name": "Orsta",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.2003,
        "lng": 6.1322
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 28978,
      "name": "Reguengos de Monsaraz",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.4167,
        "lng": -7.5333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8563,
      "name": "Probistip",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9936,
        "lng": 22.1767
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 8444,
      "name": "Zurrieq",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8292,
        "lng": 14.4744
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 8558,
      "name": "Calarasi",
      "names": {
        "zh": "克勒拉希"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 47.2544,
        "lng": 28.3081
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 28987,
      "name": "Tatvan",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5022,
        "lng": 42.2814
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29052,
      "name": "Volda",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.1468,
        "lng": 6.068
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 28990,
      "name": "Ixtacomitan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.4267,
        "lng": -93.0943
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29032,
      "name": "Kurman",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.4978,
        "lng": 34.295
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 29036,
      "name": "Malkara",
      "names": {
        "zh": "马尔卡拉"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8933,
        "lng": 26.9042
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 28429,
      "name": "Vire",
      "names": {
        "zh": "维尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.8386,
        "lng": -0.8892
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 29051,
      "name": "Santo Domingo",
      "names": {
        "ja": "サントドミンゴ",
        "th": "ซานโตโดมิงโก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4708,
        "lng": -75.1658
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8117,
      "name": "Villa Union",
      "names": {
        "fr": "Villa",
        "zh": "别墅联盟",
        "it": "Villaggio",
        "id": "Uni Villa",
        "ja": "ヴィラユニオン",
        "pt": "União da Villa"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.9667,
        "lng": -104.0333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29025,
      "name": "Wang Sombun",
      "names": {
        "zh": "王森林",
        "it": "Wang Skorbun",
        "th": "วังสมบูรณ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.3515,
        "lng": 102.1833
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 29053,
      "name": "Tiszafured",
      "names": {
        "de": "Tiszafüred"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.619,
        "lng": 20.76
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 29045,
      "name": "Tezontepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8833,
        "lng": -98.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29127,
      "name": "Santa Barbara",
      "names": {
        "zh": "圣巴巴拉",
        "ja": "サンタバーバラ",
        "th": "ซานตาบาร์บาร่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.8133,
        "lng": -105.8203
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8553,
      "name": "Karuzi",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -3.1,
        "lng": 30.163
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 29180,
      "name": "Villa Purificacion",
      "names": {
        "es": "Purificacion de villa",
        "fr": "Villa Purificion",
        "zh": "别墅纯粹",
        "ja": "ヴィラ精製",
        "th": "วิลล่า Purificacion"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7858,
        "lng": -104.7078
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8575,
      "name": "Daskasan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5181,
        "lng": 46.0828
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 28956,
      "name": "Sam Ko",
      "names": {
        "zh": "萨姆ko",
        "ja": "サムカ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6013,
        "lng": 100.2602
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8582,
      "name": "Kavaratti",
      "names": {
        "zh": "卡瓦拉蒂"
      },
      "countryCode": "IN",
      "latLng": {
        "lat": 10.5626,
        "lng": 72.6369
      },
      "country": "India",
      "importance": 1
    },
    {
      "id": 8578,
      "name": "Bela Crkva",
      "names": {
        "zh": "贝拉克罗卡"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.8975,
        "lng": 21.4172
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 29210,
      "name": "Hameenkyro",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.6333,
        "lng": 23.2
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 29158,
      "name": "Sao Bras de Alportel",
      "names": {
        "it": "Sao Bras de Aliportel",
        "ja": "サンブラスデアル輸入",
        "pt": "São Bras de Alportel"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.15,
        "lng": -7.8833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 29235,
      "name": "Chuqung",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.3743,
        "lng": 97.0637
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 28716,
      "name": "Al Atarib",
      "names": {
        "de": "Alten Atarib",
        "ja": "アルタリブ"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.1389,
        "lng": 36.83
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 28647,
      "name": "Stropkov",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.205,
        "lng": 21.6514
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 28984,
      "name": "Celldomolk",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2575,
        "lng": 17.1525
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 29228,
      "name": "Almoloya",
      "names": {
        "zh": "阿尔莫洛亚"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7,
        "lng": -98.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29245,
      "name": "Knin",
      "names": {
        "zh": "克宁"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 44.0333,
        "lng": 16.1833
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 29072,
      "name": "Oloron-Sainte-Marie",
      "names": {
        "zh": "oloron-圣玛丽",
        "ja": "oloron-サント・マリー",
        "th": "oloron-เซนต์ค-มารี"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.1942,
        "lng": -0.6067
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8069,
      "name": "Escuintla",
      "names": {
        "zh": "埃斯昆特拉"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.3193,
        "lng": -92.6586
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29243,
      "name": "Paimio",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.4569,
        "lng": 22.6861
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 29320,
      "name": "Benito Juarez",
      "names": {
        "es": "Benito Juárez",
        "ja": "ベニートフアレス",
        "th": "เบนิโต Juarez"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -37.6833,
        "lng": -59.8
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 29247,
      "name": "Tubara",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.8742,
        "lng": -74.9786
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 28619,
      "name": "Tournon-sur-Rhone",
      "names": {
        "es": "Tournon-sur-Rhône",
        "fr": "Tournon-sur-Rhône",
        "zh": "图尔农畔普瓦图 - ",
        "ja": "tournonシュルローヌ",
        "th": "tournon-ซูร์โรนห์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.0672,
        "lng": 4.8328
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8598,
      "name": "Al Mahwit",
      "names": {
        "th": "อัลมาห์วิทย์"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 15.4694,
        "lng": 43.5453
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 29329,
      "name": "Peschanokopskoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 46.1961,
        "lng": 41.0775
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 28925,
      "name": "Boekel",
      "names": {
        "zh": "布克尔"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.6011,
        "lng": 5.6742
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 29164,
      "name": "Murtosa",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7369,
        "lng": -8.6386
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 29319,
      "name": "Jablanica",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 43.6583,
        "lng": 17.7583
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 29314,
      "name": "Vouzela",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7,
        "lng": -8.1167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8600,
      "name": "Ada",
      "names": {
        "zh": "阿达",
        "ja": "ADAの"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.8014,
        "lng": 20.1222
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 8568,
      "name": "Johvi",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3575,
        "lng": 27.4122
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 29404,
      "name": "Montalegre",
      "names": {
        "zh": "蒙塔莱格里"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.8167,
        "lng": -7.7833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 29310,
      "name": "Ergani",
      "names": {
        "zh": "埃尔加尼"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2692,
        "lng": 39.7617
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29445,
      "name": "Sotkamo",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.1333,
        "lng": 28.3833
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 28514,
      "name": "Simpelveld",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 50.835,
        "lng": 5.9822
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 29453,
      "name": "Sortland",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 68.6958,
        "lng": 15.4131
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 29349,
      "name": "Imst",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.2394,
        "lng": 10.7381
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 8113,
      "name": "Tierra Colorada",
      "names": {
        "ja": "ティエラコロラダ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.1656,
        "lng": -99.5264
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29360,
      "name": "Barcs",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 45.96,
        "lng": 17.46
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 29465,
      "name": "Regidor",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.6664,
        "lng": -73.8222
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 29270,
      "name": "Marum",
      "names": {
        "zh": "马鲁姆"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.1333,
        "lng": 6.25
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 8137,
      "name": "Coacoatzintla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.65,
        "lng": -96.9333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27750,
      "name": "Leidschendam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0883,
        "lng": 4.3944
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 29182,
      "name": "Bedum",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 53.3,
        "lng": 6.6
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 29503,
      "name": "Huittinen",
      "names": {
        "zh": "胡伊蒂宁"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.1764,
        "lng": 22.6986
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 28179,
      "name": "Kastav",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3726,
        "lng": 14.349
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 29431,
      "name": "Serinhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5779,
        "lng": 29.2639
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29096,
      "name": "Al Musayfirah",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 32.6322,
        "lng": 36.3386
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 29516,
      "name": "Lyngdal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.1333,
        "lng": 7.0833
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 29535,
      "name": "Olevsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 51.2278,
        "lng": 27.6481
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 29477,
      "name": "Phai Sali",
      "names": {
        "de": "Phai-Sali",
        "ja": "ファイサー",
        "th": "ไผ่ซอย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.6,
        "lng": 100.6494
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8412,
      "name": "Ntcheu",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -14.8167,
        "lng": 34.6333
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 29460,
      "name": "El Chol",
      "names": {
        "pt": "Elogio elogio"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9611,
        "lng": -90.4878
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 29497,
      "name": "Caglayancerit",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.7508,
        "lng": 37.2922
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29217,
      "name": "Szigetvar",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.0481,
        "lng": 17.8125
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 29577,
      "name": "Kragero",
      "names": {
        "zh": "克拉格勒",
        "de": "Kragerø",
        "it": "Kragerø",
        "id": "Kragerø"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.8869,
        "lng": 9.3469
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 8605,
      "name": "Trat",
      "names": {
        "th": "ตราด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.2419,
        "lng": 102.5149
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 8611,
      "name": "Phangnga",
      "names": {
        "zh": "攀牙",
        "th": "พังงา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.4644,
        "lng": 98.5317
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 29468,
      "name": "Prakhon Chai",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6092,
        "lng": 103.0818
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 29300,
      "name": "Santiago Suchilquitongo",
      "names": {
        "fr": "Santiago telilquitongo",
        "zh": "Santiago如此",
        "de": "Santiago daneiilquitongo",
        "it": "Santiagochilquitongo",
        "ja": "Santiago Sivilequitongo",
        "th": "ซันติอาโกซึ่ยquitongo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.25,
        "lng": -96.8833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29031,
      "name": "Kolno",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 53.4106,
        "lng": 21.9339
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 8291,
      "name": "Cuatro Cienegas de Carranza",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.9861,
        "lng": -102.0664
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29552,
      "name": "Midyat",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.4167,
        "lng": 41.3531
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29541,
      "name": "Salanso",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.1833,
        "lng": -4.0833
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 8597,
      "name": "Greenville",
      "names": {
        "zh": "格林维尔",
        "ja": "グリーンヴィル",
        "th": "กรีนวิลล์"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 5.0111,
        "lng": -9.0388
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 8618,
      "name": "Ordubad",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.9047,
        "lng": 46.0231
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 8610,
      "name": "Sarnen",
      "names": {
        "zh": "萨尔嫩"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.8969,
        "lng": 8.2469
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 29677,
      "name": "Kapyl\u0027",
      "names": {
        "es": "Kapyl \u0027",
        "fr": "Kapyl \u0027",
        "de": "Kapyl \u0027",
        "it": "Kapyl \u0027",
        "id": "Kapyl \u0027",
        "ja": "kapyl \u0027",
        "th": "kapyl \u0027",
        "pt": "Kapyl \u0027"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 53.15,
        "lng": 27.0917
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 29578,
      "name": "Phon Charoen",
      "names": {
        "ja": "フォンチャルエン",
        "th": "โพนเจริญ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.025,
        "lng": 103.706
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 29671,
      "name": "Knezha",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.4931,
        "lng": 24.0806
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 29605,
      "name": "Coroneo",
      "names": {
        "zh": "科罗内奥"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1333,
        "lng": -100.3333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8179,
      "name": "Canatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 24.52,
        "lng": -104.78
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29709,
      "name": "Forde",
      "names": {
        "zh": "福德",
        "th": "ฟอร์ด"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 61.4519,
        "lng": 5.8569
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 29668,
      "name": "Havza",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9667,
        "lng": 35.6667
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8525,
      "name": "Magas",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 43.1667,
        "lng": 44.8167
      },
      "country": "Russia",
      "importance": 1
    },
    {
      "id": 29708,
      "name": "Levanger",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 63.7464,
        "lng": 11.2996
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 8620,
      "name": "Olaine",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.7844,
        "lng": 23.9369
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 29759,
      "name": "Teocuitatlan de Corona",
      "names": {
        "zh": "Teocitatlan de Corona",
        "it": "Teocuititlan de Corona",
        "ja": "Teocuitaturan de Corona"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0918,
        "lng": -103.3785
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29318,
      "name": "Monnickendam",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4547,
        "lng": 5.0353
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 29687,
      "name": "Verkhnodniprovsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6561,
        "lng": 34.3283
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 29660,
      "name": "El Realejo",
      "names": {
        "es": "El reorejo",
        "zh": "el Reaejo",
        "it": "El relejo"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 12.5431,
        "lng": -87.1647
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 29803,
      "name": "Cumra",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.575,
        "lng": 32.7747
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29355,
      "name": "Armanaz",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 36.0833,
        "lng": 36.5
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 29861,
      "name": "Hecelchakan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1667,
        "lng": -90.1333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29825,
      "name": "Yahyali",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.099,
        "lng": 35.359
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29772,
      "name": "Lenguazaque",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.3069,
        "lng": -73.7117
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 29778,
      "name": "Oliveira de Frades",
      "names": {
        "fr": "Oliveira de Fades",
        "zh": "oliveira de thrades",
        "de": "Oliveira de Frade",
        "ja": "オリビラデフリーデス"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7333,
        "lng": -8.1667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 29719,
      "name": "Kut Chap",
      "names": {
        "es": "Kut cap",
        "fr": "Kut",
        "de": "Kusp",
        "it": "Kut cap",
        "id": "Kut"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4262,
        "lng": 102.5646
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 29790,
      "name": "Chavinda",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0167,
        "lng": -102.45
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8617,
      "name": "Siparia",
      "names": {},
      "countryCode": "TT",
      "latLng": {
        "lat": 10.1333,
        "lng": -61.5
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 8362,
      "name": "Avranches",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.6844,
        "lng": -1.3569
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 28569,
      "name": "Parthenay",
      "names": {
        "zh": "帕尔特奈"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.6486,
        "lng": -0.2469
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8632,
      "name": "Samux",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.7642,
        "lng": 46.4083
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 29762,
      "name": "Selcuk",
      "names": {
        "zh": "塞尔丘克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.95,
        "lng": 27.3667
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29617,
      "name": "Tha Muang",
      "names": {
        "ja": "黄金",
        "th": "ท่าทาง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.9611,
        "lng": 99.6411
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 29728,
      "name": "Nagyatad",
      "names": {
        "zh": "瑙吉奥塔德"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.2294,
        "lng": 17.3575
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 29920,
      "name": "Moimenta da Beira",
      "names": {
        "fr": "Mouimenta da Beira",
        "zh": "莫斯塔达贝拉",
        "it": "Moimta da beira",
        "id": "Moenta da beira"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.9819,
        "lng": -7.6158
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 29955,
      "name": "Charala",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2875,
        "lng": -73.1467
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8361,
      "name": "Tlalixtac de Cabrera",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0667,
        "lng": -96.65
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29903,
      "name": "Slatina",
      "names": {
        "zh": "斯拉蒂纳"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.7,
        "lng": 17.7
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 30001,
      "name": "Medina",
      "names": {
        "zh": "麦地那",
        "ja": "メディナ",
        "th": "เมดินา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5092,
        "lng": -73.3494
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 29986,
      "name": "Shyroke",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.6882,
        "lng": 33.2654
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 29562,
      "name": "Oudewater",
      "names": {
        "zh": "奥得瓦特"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0267,
        "lng": 4.8686
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 29996,
      "name": "Mstsislaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.0196,
        "lng": 31.7247
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 8255,
      "name": "Tetela del Volcan",
      "names": {
        "es": "Tetela del Volcán",
        "de": "Tetela del vulkan",
        "it": "Tetela del vulcan",
        "ja": "テテラデル火山"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8931,
        "lng": -98.7297
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29968,
      "name": "Tarashcha",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.55,
        "lng": 30.5
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 7387,
      "name": "Phra Pradaeng",
      "names": {
        "es": "Phra pradeng",
        "de": "Phra Pradag",
        "th": "พระประแดง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.659,
        "lng": 100.5329
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30031,
      "name": "San Blas",
      "names": {
        "zh": "圣巴拉斯",
        "ja": "サンブラス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.5397,
        "lng": -105.2856
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 27485,
      "name": "Clermont",
      "names": {
        "zh": "克莱蒙",
        "ja": "クレルモン",
        "th": "มอนต์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.3789,
        "lng": 2.4125
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8647,
      "name": "Birao",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 10.2837,
        "lng": 22.7833
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 29587,
      "name": "San Juan La Laguna",
      "names": {
        "zh": "圣胡安拉拉古纳",
        "ja": "サンファンララグーナ",
        "th": "ซานฮวนลาลากูน่า"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7,
        "lng": -91.2833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 29787,
      "name": "Kranuan",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.7081,
        "lng": 103.0811
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7873,
      "name": "Mexicaltzingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2092,
        "lng": -99.5858
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29908,
      "name": "Kapuvar",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6,
        "lng": 17.0331
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 29742,
      "name": "Sobral de Monte Agraco",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.0167,
        "lng": -9.15
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 29379,
      "name": "San Pedro La Laguna",
      "names": {
        "zh": "圣佩德罗拉拉古纳",
        "ja": "サンペドロララグーナ",
        "th": "ซานเปโดรลาลากูน่า"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.6918,
        "lng": -91.273
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 30107,
      "name": "Opochka",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.7167,
        "lng": 28.65
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 8649,
      "name": "Birzai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 56.2,
        "lng": 24.75
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 30120,
      "name": "Choele Choel",
      "names": {
        "es": "Choillero",
        "fr": "Choele choquel",
        "de": "Chofel",
        "it": "Choele mosso",
        "ja": "チェイレの字句"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -39.2858,
        "lng": -65.6542
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 30002,
      "name": "Kocaali",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0636,
        "lng": 30.8581
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29369,
      "name": "Doorn",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0333,
        "lng": 5.35
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 30141,
      "name": "Chamusca",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.35,
        "lng": -8.4833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 29272,
      "name": "Altotting",
      "names": {
        "zh": "旧厄廷",
        "de": "Altötting",
        "it": "Altötting",
        "id": "Altötting",
        "pt": "Altötting"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 48.2264,
        "lng": 12.6759
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 30075,
      "name": "Granada",
      "names": {
        "fr": "Grenade",
        "zh": "格拉纳达",
        "ja": "グラナダ",
        "th": "กรานาดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1442,
        "lng": -75.1853
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 30155,
      "name": "Keuruu",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.2597,
        "lng": 24.7069
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 29615,
      "name": "Limoux",
      "names": {
        "zh": "利穆"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.0569,
        "lng": 2.2186
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8656,
      "name": "Kuldiga",
      "names": {
        "zh": "库尔迪加"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9672,
        "lng": 21.97
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 8330,
      "name": "Cuapiaxtla de Madero",
      "names": {
        "es": "Cuapiasxta de Madero",
        "fr": "Cuapiastla de Madero"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9167,
        "lng": -97.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8626,
      "name": "Naftalan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5058,
        "lng": 46.8192
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 29805,
      "name": "Banska Stiavnica",
      "names": {
        "it": "Banska Striavnica",
        "id": "Banska Stivnica",
        "ja": "バンスカスタアヴニカ"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.4586,
        "lng": 18.8931
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 30136,
      "name": "Bucak",
      "names": {
        "zh": "布贾克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.4592,
        "lng": 30.595
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29466,
      "name": "Vaals",
      "names": {
        "zh": "瓦尔斯"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 50.7694,
        "lng": 6.0181
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 30034,
      "name": "Nong Ki",
      "names": {
        "it": "Non ki",
        "th": "หนองคี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6867,
        "lng": 102.5325
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8606,
      "name": "Mellieha",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.95,
        "lng": 14.3667
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 8234,
      "name": "Chichihualco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.655,
        "lng": -99.674
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 30234,
      "name": "Hongliuwan",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 39.6348,
        "lng": 94.3386
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 28794,
      "name": "Keila",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3086,
        "lng": 24.4225
      },
      "country": "Estonia",
      "importance": 2
    },
    {
      "id": 8638,
      "name": "La Massana",
      "names": {
        "ja": "ラマサナ"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.5442,
        "lng": 1.5164
      },
      "country": "Andorra",
      "importance": 1
    },
    {
      "id": 30219,
      "name": "Kaman",
      "names": {
        "zh": "卡曼"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.3575,
        "lng": 33.7239
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8661,
      "name": "Mojkovac",
      "names": {
        "zh": "莫伊科瓦茨"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.96,
        "lng": 19.58
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 8659,
      "name": "Vrnjacka Banja",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.6167,
        "lng": 20.9
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 8272,
      "name": "Swieqi",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9208,
        "lng": 14.48
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 8655,
      "name": "Nisporeni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.0814,
        "lng": 28.1783
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 30097,
      "name": "Sung Noen",
      "names": {
        "es": "Hung noen",
        "zh": "唱noen",
        "th": "ร้องไห้"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.8992,
        "lng": 101.8208
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8345,
      "name": "Ciudad Tula",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 23.0,
        "lng": -99.72
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8596,
      "name": "Kirundo",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -2.5833,
        "lng": 30.1
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 8281,
      "name": "Pajacuaran",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1178,
        "lng": -102.5667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 30309,
      "name": "San Pedro Huamelula",
      "names": {
        "zh": "圣佩德罗惠梅拉",
        "it": "San Pedro Huaamelula",
        "ja": "サンペドロフアメーラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.0167,
        "lng": -95.6667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 30320,
      "name": "Valozhyn",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.0833,
        "lng": 26.5167
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 8257,
      "name": "Platon Sanchez",
      "names": {
        "zh": "柏拉图桑切斯",
        "ja": "プラトンサンチェス",
        "th": "พลาตันซานเชซ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2833,
        "lng": -98.3667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 30315,
      "name": "Suaita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1019,
        "lng": -73.4406
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 30340,
      "name": "Alajarvi",
      "names": {
        "zh": "阿拉耶尔维"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.0,
        "lng": 23.8167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8625,
      "name": "Butha-Buthe",
      "names": {},
      "countryCode": "LS",
      "latLng": {
        "lat": -28.7833,
        "lng": 28.2333
      },
      "country": "Lesotho",
      "importance": 1
    },
    {
      "id": 8667,
      "name": "Tarin Kot",
      "names": {
        "zh": "塔林kot",
        "ja": "タルリン箱"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 32.6333,
        "lng": 65.8667
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 28943,
      "name": "Amioun",
      "names": {},
      "countryCode": "LB",
      "latLng": {
        "lat": 34.2994,
        "lng": 35.8097
      },
      "country": "Lebanon",
      "importance": 2
    },
    {
      "id": 30356,
      "name": "Gar",
      "names": {
        "zh": "加尔",
        "ja": "ガー",
        "th": "การ์"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 32.2004,
        "lng": 79.9833
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 30203,
      "name": "Heves",
      "names": {
        "zh": "赫维什"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6,
        "lng": 20.2833
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 30362,
      "name": "Pyetrykaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.1333,
        "lng": 28.5
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 30370,
      "name": "Lapinlahti",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.3667,
        "lng": 27.3833
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 30391,
      "name": "Ii",
      "names": {
        "zh": "二",
        "ja": "IIの",
        "th": "ที่สอง"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 65.3167,
        "lng": 25.3722
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 30170,
      "name": "Judenburg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.1725,
        "lng": 14.6603
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 30233,
      "name": "Makale",
      "names": {
        "zh": "默克莱"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": -3.086,
        "lng": 119.8469
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 30389,
      "name": "Bastak",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 27.1992,
        "lng": 54.3667
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 30418,
      "name": "Leppavirta",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.4917,
        "lng": 27.7875
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 29705,
      "name": "Bernay",
      "names": {
        "zh": "贝尔奈"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.0886,
        "lng": 0.5983
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 30419,
      "name": "Sorkheh",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 35.4633,
        "lng": 53.2139
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 29984,
      "name": "Sniatyn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.45,
        "lng": 25.5667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 30205,
      "name": "Kamalasai",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.3383,
        "lng": 103.5756
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30426,
      "name": "Liminka",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.8083,
        "lng": 25.4167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 30479,
      "name": "Jilotlan de los Dolores",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3708,
        "lng": -103.0197
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 30485,
      "name": "Saarijarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.7056,
        "lng": 25.2569
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 30429,
      "name": "Amatenango del Valle",
      "names": {
        "fr": "Amaenango del Valle",
        "de": "Amateango del Valle",
        "it": "Amatengo del Valle",
        "ja": "アマテナンゴデルバレー"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.5333,
        "lng": -92.4333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 30476,
      "name": "Byalynichy",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.9956,
        "lng": 29.7096
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 30095,
      "name": "Phak Hai",
      "names": {
        "th": "ผักไหม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4626,
        "lng": 100.3667
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 27915,
      "name": "Sursee",
      "names": {
        "zh": "苏尔塞"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.1742,
        "lng": 8.1081
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 30494,
      "name": "Talachyn",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.4167,
        "lng": 29.7
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 30512,
      "name": "Trancoso",
      "names": {
        "zh": "特兰科苏"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7833,
        "lng": -7.35
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 30388,
      "name": "Teotitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.1333,
        "lng": -97.0833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 30503,
      "name": "Margarita",
      "names": {
        "zh": "玛格丽塔",
        "it": "Margherita",
        "ja": "マルガリータ",
        "th": "เทล"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.1531,
        "lng": -74.2881
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 29359,
      "name": "Scherpenzeel",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0792,
        "lng": 5.4894
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 30557,
      "name": "Ruoqiang",
      "names": {
        "zh": "若羌"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 39.0181,
        "lng": 88.1681
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 8671,
      "name": "Kanjiza",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 46.0667,
        "lng": 20.05
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 30540,
      "name": "Beypazari",
      "names": {
        "zh": "贝伊帕扎勒"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1703,
        "lng": 31.9211
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 30452,
      "name": "Bogande",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 12.9714,
        "lng": -0.1436
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 30560,
      "name": "Norosi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 8.5261,
        "lng": -74.0378
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 30346,
      "name": "Jerez",
      "names": {
        "zh": "赫雷斯",
        "id": "Jerez de La Frontera",
        "ja": "ヘレス"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.1,
        "lng": -89.75
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 30573,
      "name": "Berezivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.2039,
        "lng": 30.9125
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 30430,
      "name": "Tha Mai",
      "names": {
        "ja": "マイ",
        "th": "ท่าใหม่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.6196,
        "lng": 102.0112
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30569,
      "name": "Kobeliaky",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.1474,
        "lng": 34.1993
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8685,
      "name": "Bauska",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.4067,
        "lng": 24.1875
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 8207,
      "name": "Etchojoa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.8667,
        "lng": -109.65
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 30524,
      "name": "Nong Wua So",
      "names": {
        "es": "Nong wua asi",
        "fr": "Nong wua donc",
        "zh": "作响所以",
        "it": "Nonnz\u0027ong wua così",
        "id": "Nong wua begitu",
        "ja": "Nong Wuaそう",
        "th": "หนอง wua ดังนั้น",
        "pt": "Nong wua tão"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.2702,
        "lng": 102.5985
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30403,
      "name": "Daruvar",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.6,
        "lng": 17.2167
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 8294,
      "name": "Candelaria",
      "names": {
        "zh": "坎德拉里亚"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.1844,
        "lng": -91.0461
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28264,
      "name": "Kiato",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0117,
        "lng": 22.7467
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 30242,
      "name": "Bergambacht",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.9314,
        "lng": 4.756
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 30511,
      "name": "Csorna",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6167,
        "lng": 17.25
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 30574,
      "name": "Muurame",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.1292,
        "lng": 25.6722
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 30599,
      "name": "Ahmetli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5289,
        "lng": 27.9447
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 30281,
      "name": "Figeac",
      "names": {
        "zh": "菲雅克"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.6086,
        "lng": 2.0317
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 30571,
      "name": "Bueng Khong Long",
      "names": {
        "es": "Bueng Khong largo",
        "zh": "布庚康龙",
        "de": "Bueng Khong lang",
        "th": "บึงโขงยาว",
        "pt": "Bueng Khong"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.9684,
        "lng": 104.0484
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30594,
      "name": "Chom Thong",
      "names": {
        "es": "Chom tanga",
        "fr": "Chom dring",
        "zh": "丁字裤",
        "de": "Chom-Tanga",
        "ja": "チョンソン",
        "th": "จอมทอง",
        "pt": "Choca"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.418,
        "lng": 98.6758
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30644,
      "name": "T\u0027q\u0027ibuli",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.3503,
        "lng": 42.9983
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 30455,
      "name": "Zell am See",
      "names": {
        "es": "Zell am ver",
        "fr": "Zell am on se voit",
        "zh": "Zell我看到了",
        "th": "Zell ฉันเห็น"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.3233,
        "lng": 12.7981
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 30659,
      "name": "Cay",
      "names": {
        "zh": "沙洲",
        "ja": "岩礁",
        "th": "สันดอน"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5926,
        "lng": 31.0274
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 30697,
      "name": "Hostotipaquillo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0603,
        "lng": -104.0509
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29720,
      "name": "Oostzaan",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.4406,
        "lng": 4.8789
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 30738,
      "name": "Argostoli",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.1739,
        "lng": 20.4883
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 30732,
      "name": "Puerto Narino",
      "names": {
        "zh": "波多黎各",
        "ja": "プエルトナリノ",
        "th": "เปอร์โตนาริโน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": -3.7703,
        "lng": -70.3831
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 30478,
      "name": "Cosne sur Loire",
      "names": {
        "ja": "COSNE SURロワール"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.4103,
        "lng": 2.925
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 30341,
      "name": "Fatsa",
      "names": {
        "zh": "法特萨"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0222,
        "lng": 37.4919
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8571,
      "name": "Birzebbuga",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8267,
        "lng": 14.5278
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 30496,
      "name": "Non Sung",
      "names": {
        "es": "No cantado",
        "fr": "Non chanté",
        "zh": "非唱",
        "de": "Nicht gesungen",
        "it": "Non cantato",
        "id": "Nenang",
        "ja": "歌われていない",
        "th": "ไม่ร้องไห้",
        "pt": "Não cantado"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.1788,
        "lng": 102.2514
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8690,
      "name": "Bilecik",
      "names": {
        "zh": "比莱吉克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1431,
        "lng": 29.9792
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 30649,
      "name": "Chiang Klang",
      "names": {
        "zh": "蒋介康",
        "ja": "チェン・クラン",
        "th": "เชียงใหม่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.293,
        "lng": 100.8739
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30749,
      "name": "Vesele",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.016,
        "lng": 34.9124
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 30770,
      "name": "Idanha-a-Nova",
      "names": {
        "fr": "Idanha-a-nouvelle-",
        "zh": "idanha-一个-的Nova",
        "ja": "idanha・ア・ノヴァ",
        "th": "idanha รูปแบบโนวา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.9167,
        "lng": -7.2333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8706,
      "name": "Jurbarkas",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.0778,
        "lng": 22.7756
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 30636,
      "name": "Masku",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.5708,
        "lng": 22.1
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 30448,
      "name": "Mae Ai",
      "names": {
        "ja": "メーアイ",
        "th": "แม่ไอ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 20.0296,
        "lng": 99.2847
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30782,
      "name": "Imias",
      "names": {},
      "countryCode": "CU",
      "latLng": {
        "lat": 20.0694,
        "lng": -74.6314
      },
      "country": "Cuba",
      "importance": 2
    },
    {
      "id": 30705,
      "name": "Santo Tomas de los Platanos",
      "names": {
        "ja": "サントトマスデロスプラタノ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1817,
        "lng": -100.2589
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 29818,
      "name": "Bad Ems",
      "names": {
        "es": "Mal ems",
        "fr": "Mauvais ems",
        "zh": "坏血病",
        "de": "Schlechte EMS",
        "it": "Cattivo ems",
        "id": "Ems buruk",
        "ja": "悪いems",
        "th": "ems ที่ไม่ดี"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.3381,
        "lng": 7.7106
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 8714,
      "name": "Saldus",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.6667,
        "lng": 22.4936
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 8715,
      "name": "Tshabong",
      "names": {},
      "countryCode": "BW",
      "latLng": {
        "lat": -26.02,
        "lng": 22.4056
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 30722,
      "name": "Sarkad",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.74,
        "lng": 21.3778
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 30661,
      "name": "Thap Khlo",
      "names": {
        "ja": "khloをタップします",
        "th": "ทับ khlo"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.16,
        "lng": 100.5967
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8604,
      "name": "Haapsalu",
      "names": {
        "zh": "哈普萨卢"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.9469,
        "lng": 23.5369
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 8373,
      "name": "San Gregorio Atzompa",
      "names": {
        "zh": "圣格雷戈里奥atzompa",
        "ja": "サングレゴリオアトツマ",
        "th": "ซานเกรโกริโอ Atzompa"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0224,
        "lng": -98.3445
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 28572,
      "name": "Tepetlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.6667,
        "lng": -96.8
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8718,
      "name": "Djambala",
      "names": {
        "zh": "兼巴拉"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -2.55,
        "lng": 14.75
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 30840,
      "name": "Farsund",
      "names": {
        "zh": "法尔松"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.0828,
        "lng": 6.7528
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 30872,
      "name": "Fauske",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 67.2596,
        "lng": 15.3941
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 30879,
      "name": "Pinhel",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7833,
        "lng": -7.0667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 30893,
      "name": "Uracoa",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.9956,
        "lng": -62.3521
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 30853,
      "name": "Krasnogvardeyskoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 45.1167,
        "lng": 39.5667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 30332,
      "name": "Nogent-le-Rotrou",
      "names": {
        "zh": "诺让了-rotrou",
        "ja": "nogent・ル・rotrou",
        "th": "nogent-เลอ-rotrou"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3217,
        "lng": 0.8217
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8650,
      "name": "Muyinga",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -2.85,
        "lng": 30.3333
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 30906,
      "name": "Izium",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.2238,
        "lng": 37.2915
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 30885,
      "name": "Mortagua",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3833,
        "lng": -8.2167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8721,
      "name": "Postojna",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7743,
        "lng": 14.2153
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 30757,
      "name": "Yalaguina",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.4833,
        "lng": -86.4833
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 8396,
      "name": "Xicotencatl",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.9958,
        "lng": -98.9447
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 30708,
      "name": "Kiziltepe",
      "names": {
        "zh": "克孜勒泰佩"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.1939,
        "lng": 40.5861
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 30668,
      "name": "Wiang Sa",
      "names": {
        "zh": "沃恩萨",
        "ja": "ウィアンサー",
        "th": "เวียงสา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.6364,
        "lng": 99.3683
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30593,
      "name": "Photharam",
      "names": {
        "th": "โพธาราม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.6918,
        "lng": 99.8531
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30695,
      "name": "Mae Rim",
      "names": {
        "es": "Mae borde",
        "fr": "Mae jante",
        "it": "Mae bordo",
        "ja": "メーリム",
        "th": "แม่ริม",
        "pt": "Mae aro"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.9163,
        "lng": 98.9605
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30940,
      "name": "Mizhhiria",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5286,
        "lng": 23.5019
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 30932,
      "name": "Bilozerka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.6333,
        "lng": 32.4333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 30972,
      "name": "Vares",
      "names": {
        "zh": "瓦雷什"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.1644,
        "lng": 18.3283
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 30744,
      "name": "Ussel",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.5481,
        "lng": 2.3092
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 31004,
      "name": "Chita",
      "names": {
        "zh": "赤塔",
        "ja": "チタ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.1878,
        "lng": -72.4725
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 31006,
      "name": "Mogadouro",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3333,
        "lng": -6.7167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 30353,
      "name": "Saint-Amand-Montrond",
      "names": {
        "fr": "Saint-Amand Montrond-",
        "zh": "圣 -  amand-montrond",
        "it": "Saint-amand montrond",
        "ja": "サン・amand-montrond",
        "th": "เซนต์ amand-montrond"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.7228,
        "lng": 2.505
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8726,
      "name": "Pedra Badejo",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 15.1375,
        "lng": -23.5333
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 31046,
      "name": "Pagqen",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.9739,
        "lng": 99.9083
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 8309,
      "name": "Psychiko",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.018,
        "lng": 23.7804
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 31023,
      "name": "Pohrebyshche",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4869,
        "lng": 29.2733
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8305,
      "name": "Tlahualilo de Zaragoza",
      "names": {
        "it": "Tlahualilo de Saragoza",
        "id": "Tidaualilo de zaragoza"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.1167,
        "lng": -103.45
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8711,
      "name": "Basco",
      "names": {
        "zh": "巴斯科"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 20.45,
        "lng": 121.9667
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 29863,
      "name": "Santa Clara La Laguna",
      "names": {
        "zh": "圣克拉拉拉拉古纳",
        "ja": "サンタクララララグーナ",
        "th": "ซานตาคลาร่าลาลากูน่า"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7167,
        "lng": -91.3
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 30960,
      "name": "Cepin",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.5236,
        "lng": 18.5633
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 30977,
      "name": "Lwowek Slaski",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 51.1167,
        "lng": 15.5833
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 30943,
      "name": "Kanchanadit",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 9.1653,
        "lng": 99.4706
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 29302,
      "name": "Kauniainen",
      "names": {
        "zh": "考尼艾宁"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.2097,
        "lng": 24.7264
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 30754,
      "name": "Corella",
      "names": {
        "zh": "科雷利亚"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.6833,
        "lng": 123.9167
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 31088,
      "name": "Nyzhnohirskyi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 45.4464,
        "lng": 34.7344
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 30450,
      "name": "Foix",
      "names": {
        "zh": "富瓦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 42.9653,
        "lng": 1.6069
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8738,
      "name": "Vilkaviskis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.6667,
        "lng": 23.0333
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 31130,
      "name": "Comala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3208,
        "lng": -103.7603
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8119,
      "name": "Rorschach",
      "names": {
        "zh": "罗夏"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.4786,
        "lng": 9.4936
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 31031,
      "name": "Luchow",
      "names": {
        "de": "Lüchow"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.9667,
        "lng": 11.15
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 28777,
      "name": "San Juanito de Escobedo",
      "names": {
        "zh": "圣Juanito de Escocedo",
        "ja": "サンファニートデスエスコプリ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8,
        "lng": -104.0
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8741,
      "name": "Rozaje",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.8442,
        "lng": 20.1679
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 31215,
      "name": "Castelli",
      "names": {
        "zh": "卡斯泰利",
        "ja": "カステッリ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -25.95,
        "lng": -60.6167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 31185,
      "name": "Boyabat",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4689,
        "lng": 34.7667
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8482,
      "name": "Halacho",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4764,
        "lng": -90.0819
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 31221,
      "name": "Orivesi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.6778,
        "lng": 24.3569
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8731,
      "name": "Hani i Elezit",
      "names": {
        "fr": "Hani je elezit",
        "zh": "哈尼我elezit",
        "pt": "Hani eu elezit"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.1475,
        "lng": 21.2992
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 30756,
      "name": "Voitsberg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.0483,
        "lng": 15.1503
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 8629,
      "name": "Altdorf",
      "names": {
        "zh": "阿尔特多夫"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.8806,
        "lng": 8.6394
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 8710,
      "name": "Makamba",
      "names": {
        "zh": "马坎巴"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -4.1333,
        "lng": 29.8
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 31245,
      "name": "Braslaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.6397,
        "lng": 27.0397
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 31254,
      "name": "Narpes",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.4736,
        "lng": 21.3375
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8724,
      "name": "Kundiawa",
      "names": {
        "zh": "孔迪亚瓦"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -6.023,
        "lng": 144.96
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 31037,
      "name": "Ecatzingo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.95,
        "lng": -98.75
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 31000,
      "name": "Langnau",
      "names": {
        "zh": "朗瑙"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.9433,
        "lng": 7.7853
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 31082,
      "name": "Miltenberg",
      "names": {
        "zh": "米尔滕贝格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7039,
        "lng": 9.2644
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 28719,
      "name": "Orlu",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 5.7837,
        "lng": 7.0333
      },
      "country": "Nigeria",
      "importance": 2
    },
    {
      "id": 31314,
      "name": "Siteia",
      "names": {
        "id": "Sitia"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 35.2,
        "lng": 26.1
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 31196,
      "name": "Bulancak",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9381,
        "lng": 38.2314
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 30443,
      "name": "Nea Moudania",
      "names": {
        "ja": "ネアモダニア"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 40.2386,
        "lng": 23.2814
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 30041,
      "name": "Molsheim",
      "names": {
        "zh": "莫尔塞姆"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5428,
        "lng": 7.4922
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 31375,
      "name": "Kaleybar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 38.8667,
        "lng": 47.0333
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 31329,
      "name": "Pesca",
      "names": {
        "zh": "佩斯卡"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5589,
        "lng": -73.0503
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 28962,
      "name": "San Pablo Huixtepec",
      "names": {
        "de": "San Pablo Huixtpec",
        "ja": "サンパブロHUIXTEPEC"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8167,
        "lng": -96.7833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 31015,
      "name": "Bang Khla",
      "names": {
        "th": "บางคล้า"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.7268,
        "lng": 101.2105
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8402,
      "name": "Choix",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.7061,
        "lng": -108.3219
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 31170,
      "name": "Farsala",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.2833,
        "lng": 22.3833
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 31121,
      "name": "Sindos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.6667,
        "lng": 22.8
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 31257,
      "name": "Tysmenytsia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.9008,
        "lng": 24.8492
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 31398,
      "name": "Nakhon Thai",
      "names": {
        "es": "Nakhon tailandés",
        "it": "Nakhon tailandese",
        "ja": "ナコーンタイ",
        "th": "นครไทย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.1011,
        "lng": 100.8296
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31459,
      "name": "Pozo Almonte",
      "names": {
        "ja": "ポゾアルモンテ"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -20.2597,
        "lng": -69.7862
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 31281,
      "name": "Irshava",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.3172,
        "lng": 23.0375
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 30768,
      "name": "Gebze",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8028,
        "lng": 29.4306
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 31438,
      "name": "Didymoteicho",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 41.35,
        "lng": 26.5
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 31343,
      "name": "Khotyn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5078,
        "lng": 26.486
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8749,
      "name": "Riscani",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.9561,
        "lng": 27.5536
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 31211,
      "name": "Wunsiedel",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.0374,
        "lng": 11.9994
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 31462,
      "name": "Aljustrel",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 37.8833,
        "lng": -8.1667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 31483,
      "name": "Guarare",
      "names": {
        "zh": "瓜拉雷"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 7.7667,
        "lng": -80.2833
      },
      "country": "Panama",
      "importance": 2
    },
    {
      "id": 31123,
      "name": "Granada",
      "names": {
        "fr": "Grenade",
        "zh": "格拉纳达",
        "ja": "グラナダ",
        "th": "กรานาดา"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5186,
        "lng": -74.3514
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 31365,
      "name": "Vila Nova de Cerveira",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.9333,
        "lng": -8.7333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8757,
      "name": "Kasane",
      "names": {
        "zh": "卡萨内"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -17.7983,
        "lng": 25.1536
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 8664,
      "name": "Hamrun",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8861,
        "lng": 14.4894
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 30135,
      "name": "Santa Isabel Cholula",
      "names": {
        "zh": "圣诞老人isabel cholula",
        "ja": "サンタイザベルチョルラ",
        "th": "ซานตาอิซาเบลโคโลล่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0,
        "lng": -98.3667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 31442,
      "name": "Philippeville",
      "names": {
        "zh": "菲利普维尔"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 50.1958,
        "lng": 4.5431
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 31508,
      "name": "Kalanchak",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.255,
        "lng": 33.2906
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 31341,
      "name": "Nagykallo",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.8831,
        "lng": 21.85
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 31427,
      "name": "Nong Kung Si",
      "names": {
        "th": "หนองกุง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.65,
        "lng": 103.3
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31504,
      "name": "Melgaco",
      "names": {
        "zh": "梅尔加苏",
        "pt": "Melgaço"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 42.1167,
        "lng": -8.2667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 31431,
      "name": "Coapilla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.1333,
        "lng": -93.1667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 31518,
      "name": "Pong Nam Ron",
      "names": {
        "th": "โป่งน้ำรอน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.9057,
        "lng": 102.2663
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31298,
      "name": "Edeleny",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.2967,
        "lng": 20.7442
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8101,
      "name": "Altamirano",
      "names": {
        "zh": "阿尔塔米拉诺"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.7361,
        "lng": -92.0389
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 31226,
      "name": "Spata",
      "names": {
        "zh": "斯帕塔"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.9667,
        "lng": 23.9167
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 31488,
      "name": "Paredes de Coura",
      "names": {
        "fr": "Pars de Coura",
        "ja": "パデスクーラ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.9127,
        "lng": -8.5622
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 31326,
      "name": "Tvrdosin",
      "names": {
        "de": "Tvrdošín"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 49.3369,
        "lng": 19.5503
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 31620,
      "name": "Tibana",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.3172,
        "lng": -73.3969
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 31454,
      "name": "Suwannaphum",
      "names": {
        "th": "สุวรรณภูมิ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.6078,
        "lng": 103.8
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31573,
      "name": "Yenice",
      "names": {
        "zh": "耶尼杰"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2,
        "lng": 32.3333
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 31600,
      "name": "Palu",
      "names": {
        "zh": "帕卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6914,
        "lng": 39.9294
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8761,
      "name": "Zabalj",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.3667,
        "lng": 20.0667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 31664,
      "name": "Divrigi",
      "names": {
        "zh": "迪夫里伊"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.3711,
        "lng": 38.1136
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8750,
      "name": "Sant Julia de Loria",
      "names": {
        "ja": "サンジュリアデロリア"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.47,
        "lng": 1.49
      },
      "country": "Andorra",
      "importance": 1
    },
    {
      "id": 8765,
      "name": "Evinayong",
      "names": {},
      "countryCode": "GQ",
      "latLng": {
        "lat": 1.45,
        "lng": 10.5667
      },
      "country": "Equatorial Guinea",
      "importance": 1
    },
    {
      "id": 30716,
      "name": "Redon",
      "names": {
        "zh": "雷东"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6514,
        "lng": -2.0847
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8395,
      "name": "Amatlan de los Reyes",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8457,
        "lng": -96.9149
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 31056,
      "name": "Belley",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.7592,
        "lng": 5.6881
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 31276,
      "name": "Eemnes",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2539,
        "lng": 5.2572
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 31464,
      "name": "Horodenka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6675,
        "lng": 25.5003
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 31703,
      "name": "San Zenon",
      "names": {
        "es": "San Zenón",
        "zh": "圣泽顿",
        "ja": "サンゼノン",
        "th": "ซานเดนอน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.245,
        "lng": -74.4992
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 31542,
      "name": "Saint-Claude",
      "names": {
        "zh": "圣克劳德·",
        "ja": "サン・クロード",
        "th": "เซนต์คลอดด์"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.3872,
        "lng": 5.8633
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 31561,
      "name": "Paszto",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.9194,
        "lng": 19.6978
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8771,
      "name": "Dangriga",
      "names": {},
      "countryCode": "BZ",
      "latLng": {
        "lat": 16.9667,
        "lng": -88.2167
      },
      "country": "Belize",
      "importance": 1
    },
    {
      "id": 31784,
      "name": "Somero",
      "names": {
        "zh": "神龙"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.6292,
        "lng": 23.5139
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 31535,
      "name": "Akyazi",
      "names": {
        "zh": "阿克亚泽"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6833,
        "lng": 30.6253
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 31787,
      "name": "Tutrakan",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 44.039,
        "lng": 26.6194
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 8770,
      "name": "Cankiri",
      "names": {
        "zh": "坎开莱"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6,
        "lng": 33.6167
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 31700,
      "name": "Canilla",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.1671,
        "lng": -90.8481
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 31834,
      "name": "Vinhais",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.8167,
        "lng": -7.0
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 31845,
      "name": "Muhos",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.8,
        "lng": 26.0
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8781,
      "name": "Ewo",
      "names": {},
      "countryCode": "CG",
      "latLng": {
        "lat": -0.8667,
        "lng": 14.8167
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 31830,
      "name": "Livno",
      "names": {
        "zh": "利夫诺"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.8269,
        "lng": 17.0081
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 31821,
      "name": "Herzberg",
      "names": {
        "zh": "赫茨伯格"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 51.6831,
        "lng": 13.2331
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 31900,
      "name": "Flekkefjord",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.3272,
        "lng": 6.6667
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 8783,
      "name": "Manatuto",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.5167,
        "lng": 126.0167
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 8785,
      "name": "Odzaci",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.5167,
        "lng": 19.2667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 31770,
      "name": "Tiachiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0114,
        "lng": 23.5722
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 31793,
      "name": "Akat Amnuai",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.5898,
        "lng": 103.9859
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31741,
      "name": "Kaeng Khro",
      "names": {
        "th": "แก้งโคร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.1086,
        "lng": 102.2581
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31820,
      "name": "Tha Luang",
      "names": {
        "ja": "洛陽",
        "th": "ท่าหลวง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.0697,
        "lng": 101.1182
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 7293,
      "name": "Baabda",
      "names": {},
      "countryCode": "LB",
      "latLng": {
        "lat": 33.8333,
        "lng": 35.5333
      },
      "country": "Lebanon",
      "importance": 1
    },
    {
      "id": 31902,
      "name": "Kozova",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4318,
        "lng": 25.1544
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 31980,
      "name": "Chamical",
      "names": {
        "zh": "查米卡尔"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.3592,
        "lng": -66.3133
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 31790,
      "name": "Kosum Phisai",
      "names": {
        "ja": "コサムピサイ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.243,
        "lng": 103.0627
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31987,
      "name": "Tepechitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.6667,
        "lng": -103.3333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 31746,
      "name": "Thung Sai",
      "names": {
        "th": "ทุ่งไส"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.2955,
        "lng": 99.8089
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31957,
      "name": "Karkkila",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.5347,
        "lng": 24.2097
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 31933,
      "name": "Bolnisi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.45,
        "lng": 44.5333
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 30848,
      "name": "Tzitzio",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4449,
        "lng": -100.9085
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32015,
      "name": "Nuqui",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.7069,
        "lng": -77.2703
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 31728,
      "name": "Cinarcik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6422,
        "lng": 29.1203
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 29989,
      "name": "Pithiviers",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1719,
        "lng": 2.2519
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 31930,
      "name": "Thap Than",
      "names": {
        "es": "Thap que",
        "fr": "THAP que",
        "zh": "比",
        "de": "Thap als",
        "it": "THE",
        "id": "Thap daripada",
        "ja": "THAPよりも",
        "th": "ขอบคุณ",
        "pt": "Thap do que"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.457,
        "lng": 99.8961
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31545,
      "name": "Abdurahmoni Jomi",
      "names": {
        "ja": "アブラフモニjomi",
        "pt": "Abduraahmoni jomi"
      },
      "countryCode": "TJ",
      "latLng": {
        "lat": 37.9794,
        "lng": 68.689
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 32063,
      "name": "Floro",
      "names": {
        "zh": "弗洛罗"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 61.5986,
        "lng": 5.0172
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 32087,
      "name": "Taxkorgan",
      "names": {
        "zh": "塔什库尔干"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 37.7729,
        "lng": 75.2272
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 31474,
      "name": "Santa Maria del Tule",
      "names": {
        "zh": "圣玛丽亚del Tule",
        "ja": "サンタマリアデルタール"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0465,
        "lng": -96.6363
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32021,
      "name": "Kui Buri",
      "names": {
        "ja": "クイブリ",
        "th": "กุยบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.0702,
        "lng": 99.8667
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 31677,
      "name": "Plon",
      "names": {
        "zh": "普隆",
        "de": "Plön"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 54.1622,
        "lng": 10.4214
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 31822,
      "name": "Siklos",
      "names": {
        "zh": "希克洛什"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 45.8519,
        "lng": 18.2986
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 30404,
      "name": "Santa Cruz Atizapan",
      "names": {
        "zh": "圣克鲁斯atizapan",
        "ja": "サンタクルスアトザパン"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1756,
        "lng": -99.4886
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32082,
      "name": "Kulu",
      "names": {
        "zh": "古卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0901,
        "lng": 33.0807
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 32123,
      "name": "Makhambet",
      "names": {},
      "countryCode": "KZ",
      "latLng": {
        "lat": 47.6714,
        "lng": 51.5798
      },
      "country": "Kazakhstan",
      "importance": 2
    },
    {
      "id": 32132,
      "name": "San Jose de Gracia",
      "names": {
        "es": "San José de Gracia",
        "zh": "圣何塞德格拉西亚",
        "ja": "サンノゼ・デグシア",
        "th": "ซานโฮเซ่เดอเกียเซีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.15,
        "lng": -102.4167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8746,
      "name": "Vrhnika",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9622,
        "lng": 14.2936
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 32046,
      "name": "Tlumach",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.8669,
        "lng": 25.0012
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 31846,
      "name": "Chrysoupoli",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.9833,
        "lng": 24.7
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 8157,
      "name": "Cuencame de Ceniceros",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 24.8667,
        "lng": -103.7
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32144,
      "name": "Alvarado",
      "names": {
        "zh": "阿尔瓦拉多"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.5672,
        "lng": -74.9533
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8531,
      "name": "Tataltepec de Valdes",
      "names": {
        "es": "Tataltepec de valdees"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3064,
        "lng": -97.5461
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 31976,
      "name": "Szentgotthard",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9525,
        "lng": 16.2736
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 32070,
      "name": "Hanko",
      "names": {
        "zh": "汉科"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 59.8236,
        "lng": 22.9681
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 32039,
      "name": "Schleiz",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.5833,
        "lng": 11.8167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 32181,
      "name": "Gilgit",
      "names": {
        "zh": "吉尔吉特"
      },
      "countryCode": "PK",
      "latLng": {
        "lat": 35.9208,
        "lng": 74.3144
      },
      "country": "Pakistan",
      "importance": 2
    },
    {
      "id": 32204,
      "name": "Buda-Kashalyova",
      "names": {
        "zh": "布达-kashalyova",
        "ja": "ブダ-kashalyova",
        "th": "บูดา-kashalyova"
      },
      "countryCode": "BY",
      "latLng": {
        "lat": 52.7167,
        "lng": 30.5667
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 32002,
      "name": "Guatape",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2325,
        "lng": -75.1586
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 32241,
      "name": "Tuzluca",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.0494,
        "lng": 43.6608
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 31691,
      "name": "Hish",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.5464,
        "lng": 36.6431
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 8805,
      "name": "Talsi",
      "names": {
        "zh": "塔尔西"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 57.2447,
        "lng": 22.5889
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 32122,
      "name": "Takua Pa",
      "names": {
        "ja": "タクアPa"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.8658,
        "lng": 98.3413
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8806,
      "name": "Mokhotlong",
      "names": {},
      "countryCode": "LS",
      "latLng": {
        "lat": -29.2885,
        "lng": 29.0656
      },
      "country": "Lesotho",
      "importance": 1
    },
    {
      "id": 32173,
      "name": "Kamien Pomorski",
      "names": {
        "id": "Kamiien Pomorski"
      },
      "countryCode": "PL",
      "latLng": {
        "lat": 53.9697,
        "lng": 14.7858
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 32330,
      "name": "Kuhmo",
      "names": {
        "zh": "库赫莫"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 64.125,
        "lng": 29.5167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8807,
      "name": "Corozal",
      "names": {
        "zh": "科罗萨尔"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 18.4,
        "lng": -88.4
      },
      "country": "Belize",
      "importance": 1
    },
    {
      "id": 32163,
      "name": "Santa Maria Xadani",
      "names": {
        "zh": "圣玛丽亚Xadani",
        "ja": "サンタマリアXadani",
        "th": "ซานตามาเรียซาดานี"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3667,
        "lng": -95.0167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32003,
      "name": "Sarlat-la-Caneda",
      "names": {
        "es": "Sarlat-la-canéda",
        "fr": "Sarlat-la-canéda",
        "zh": "萨尔拉-的La-caneda",
        "de": "Sarlat-La-canéda",
        "it": "Sarlat-la-canéda",
        "id": "Sarlat-La-canéda",
        "ja": "サルララカネダ",
        "th": "sarlat-ลา-caneda",
        "pt": "Sarlat-la-canéda"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.89,
        "lng": 1.2167
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 32045,
      "name": "Strijen",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 51.7422,
        "lng": 4.5514
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 32346,
      "name": "Kukrahill",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 12.25,
        "lng": -83.75
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 32203,
      "name": "Bojaca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7336,
        "lng": -74.3422
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 32297,
      "name": "Kryzhopil",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.3842,
        "lng": 28.8625
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 32370,
      "name": "Sodankyla",
      "names": {
        "de": "Sodankylä",
        "th": "โซดานกูลา"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 67.4149,
        "lng": 26.5907
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8676,
      "name": "Nueva Ocotepeque",
      "names": {},
      "countryCode": "HN",
      "latLng": {
        "lat": 14.4333,
        "lng": -89.1833
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 32362,
      "name": "Zapatoca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.815,
        "lng": -73.2683
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 31853,
      "name": "Pa Mok",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4921,
        "lng": 100.4448
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8813,
      "name": "Joniskis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 56.2433,
        "lng": 23.6179
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 29592,
      "name": "Lachen",
      "names": {
        "zh": "拉亨"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.1911,
        "lng": 8.8567
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 8799,
      "name": "Sremski Karlovci",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.2,
        "lng": 19.9333
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 32327,
      "name": "Nam Som",
      "names": {
        "th": "นัมส้ม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.7694,
        "lng": 102.1435
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 32176,
      "name": "Kolbuszowa",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2441,
        "lng": 21.7761
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 8816,
      "name": "Dobele",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.6258,
        "lng": 23.2811
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 32427,
      "name": "Junin",
      "names": {
        "zh": "朱宁"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7903,
        "lng": -73.6633
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8818,
      "name": "Artvin",
      "names": {
        "zh": "阿尔特温",
        "th": "อาร์ตวิน"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1822,
        "lng": 41.8194
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 32456,
      "name": "Ogulin",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.2669,
        "lng": 15.2248
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 32355,
      "name": "Tirschenreuth",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.8789,
        "lng": 12.3369
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 32336,
      "name": "Iles",
      "names": {
        "zh": "伊莱斯"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 0.9706,
        "lng": -77.5208
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 32258,
      "name": "Phimai",
      "names": {
        "th": "พิมาย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2167,
        "lng": 102.5
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 32469,
      "name": "Szeghalom",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0239,
        "lng": 21.1678
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8817,
      "name": "Glodeni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.7708,
        "lng": 27.5144
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 32324,
      "name": "Yang Talat",
      "names": {
        "zh": "杨塔拉特",
        "th": "ยางย่าง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.3997,
        "lng": 103.3678
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 32471,
      "name": "Tlanalapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8167,
        "lng": -98.6
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32548,
      "name": "Chyhyryn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.0833,
        "lng": 32.6667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8819,
      "name": "Gadabay",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5656,
        "lng": 45.8161
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 32485,
      "name": "Rakitovo",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 41.9906,
        "lng": 24.0902
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 32592,
      "name": "Yesilhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.35,
        "lng": 35.0867
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 32578,
      "name": "La Libertad",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 16.7804,
        "lng": -90.12
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 32229,
      "name": "Pa Sang",
      "names": {
        "es": "Pa cantante",
        "fr": "Pa chanson",
        "it": "Pa cantava",
        "id": "Pa bernyanyi",
        "th": "ป่าซาง",
        "pt": "PA cantou"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.5261,
        "lng": 98.9394
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 32319,
      "name": "Balatonalmadi",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0292,
        "lng": 18.0219
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 32585,
      "name": "Ferreira do Zezere",
      "names": {
        "zh": "Ferreira做Zezere",
        "id": "Ferreira melakukan zezere",
        "ja": "フェリーラはゼーゼレをします",
        "th": "Ferreira ทำ Zezere"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.7,
        "lng": -8.2833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 32421,
      "name": "Vasarosnameny",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.1267,
        "lng": 22.3183
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 32518,
      "name": "Tibro",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.419,
        "lng": 14.158
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 32689,
      "name": "Kiuruvesi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.6528,
        "lng": 26.6194
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 32646,
      "name": "La Belleza",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8575,
        "lng": -73.9656
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8034,
      "name": "Rayon",
      "names": {
        "es": "Rayón",
        "fr": "Rayonne",
        "zh": "人造丝",
        "ja": "レーヨン",
        "th": "เรยอน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.1481,
        "lng": -99.58
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8024,
      "name": "Tarxien",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.865,
        "lng": 14.5111
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 8570,
      "name": "Ahumada",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 30.6186,
        "lng": -106.5122
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32557,
      "name": "El Espinal",
      "names": {
        "de": "El",
        "ja": "エルスピナー"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.4906,
        "lng": -95.0444
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32728,
      "name": "Torre de Moncorvo",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2,
        "lng": -7.1333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 32704,
      "name": "Na Yung",
      "names": {
        "zh": "呐",
        "th": "นายูง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.9142,
        "lng": 102.2403
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 32618,
      "name": "Usiacuri",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 10.7428,
        "lng": -74.9758
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8591,
      "name": "Ayotoxco de Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1,
        "lng": -97.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32690,
      "name": "Muzo",
      "names": {
        "zh": "木佐"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5297,
        "lng": -74.1044
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8240,
      "name": "Imsida",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8978,
        "lng": 14.4894
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 32803,
      "name": "Dubovskoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.4092,
        "lng": 42.7575
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 32138,
      "name": "Taftanaz",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.9981,
        "lng": 36.7847
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 32804,
      "name": "Krupki",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.325,
        "lng": 29.1361
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 7991,
      "name": "Bartica",
      "names": {
        "zh": "巴蒂卡"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.4,
        "lng": -58.6167
      },
      "country": "Guyana",
      "importance": 1
    },
    {
      "id": 32740,
      "name": "Dogubayazit",
      "names": {
        "zh": "多乌巴亚泽特"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.55,
        "lng": 44.0833
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 32671,
      "name": "Kosiv",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.315,
        "lng": 25.0953
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 32828,
      "name": "Laitila",
      "names": {
        "zh": "莱蒂拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8792,
        "lng": 21.6931
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 32811,
      "name": "Habo",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 57.9066,
        "lng": 14.0856
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 30682,
      "name": "Hueyotlipan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.9,
        "lng": -97.85
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8522,
      "name": "Tepeojuma",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.7167,
        "lng": -98.45
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8841,
      "name": "Anyksciai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.5344,
        "lng": 25.1072
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 32693,
      "name": "Sawang Daen Din",
      "names": {
        "ja": "Saiang Daen Din"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.475,
        "lng": 103.4569
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8843,
      "name": "Varena",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.2111,
        "lng": 24.5722
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 32339,
      "name": "Bad Zurzach",
      "names": {
        "es": "Mal zurzach",
        "zh": "坏zurzach",
        "id": "Zurzach buruk",
        "ja": "悪いZurzach"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.5877,
        "lng": 8.2933
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 32499,
      "name": "Szerencs",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.1622,
        "lng": 21.205
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8838,
      "name": "Basarabeasca",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.3333,
        "lng": 28.9667
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 32929,
      "name": "Namsos",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 64.467,
        "lng": 11.494
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 32928,
      "name": "Bunyan",
      "names": {
        "zh": "本仁"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.8486,
        "lng": 35.8592
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 32771,
      "name": "Sanluri",
      "names": {},
      "countryCode": "IT",
      "latLng": {
        "lat": 39.5611,
        "lng": 8.9
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 32894,
      "name": "Lypovets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.2161,
        "lng": 29.0561
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 32969,
      "name": "Orocue",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7897,
        "lng": -71.3392
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 32639,
      "name": "Goundam",
      "names": {
        "zh": "贡达姆"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 16.4144,
        "lng": -3.6708
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 32921,
      "name": "Campo Maior",
      "names": {
        "zh": "坎波迈阿里"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.0167,
        "lng": -7.0667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 31738,
      "name": "Coutances",
      "names": {
        "zh": "库唐斯"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.0453,
        "lng": -1.4453
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8845,
      "name": "Prienai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.6333,
        "lng": 23.9417
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 8839,
      "name": "Zardab",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.2111,
        "lng": 47.7108
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 32263,
      "name": "Zoeterwoude",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.1136,
        "lng": 4.5058
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 32787,
      "name": "Non Sang",
      "names": {
        "es": "No cantado",
        "fr": "Non chanté",
        "zh": "非唱歌",
        "de": "Nicht sangen",
        "it": "Non cantato",
        "id": "Tak bernyanyi",
        "ja": "歌っていない",
        "th": "ไม่ร้องเพลง",
        "pt": "Não cantou"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8699,
        "lng": 102.564
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 32903,
      "name": "Kemer",
      "names": {
        "zh": "凯梅尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.6,
        "lng": 30.55
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 32774,
      "name": "Bang Mun Nak",
      "names": {
        "zh": "邦蒙纳克",
        "id": "Orang bodoh",
        "ja": "バンマンナック",
        "th": "บางกระญษฎร์ธานี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.0278,
        "lng": 100.3792
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 30122,
      "name": "Arteaga",
      "names": {
        "zh": "阿特阿加"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.45,
        "lng": -100.8333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8577,
      "name": "Altar",
      "names": {
        "fr": "Autel",
        "zh": "祭坛",
        "it": "Altare",
        "id": "Mezbah",
        "ja": "祭壇",
        "th": "แท่นบูชา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 30.7136,
        "lng": -111.8353
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8359,
      "name": "San Ignacio Rio Muerto",
      "names": {
        "th": "ซานอิกนาซิโอริโอมิเรโต้"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.415,
        "lng": -110.245
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32616,
      "name": "Bang Len",
      "names": {
        "zh": "邦琳",
        "de": "Banglu",
        "id": "Lensa",
        "ja": "バンレン",
        "th": "บางเฉิน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.0219,
        "lng": 100.1719
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 33021,
      "name": "Vyetka",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 52.5592,
        "lng": 31.1808
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 33053,
      "name": "Magtymguly",
      "names": {},
      "countryCode": "TM",
      "latLng": {
        "lat": 38.4333,
        "lng": 56.2833
      },
      "country": "Turkmenistan",
      "importance": 2
    },
    {
      "id": 33022,
      "name": "Tavas",
      "names": {
        "zh": "塔瓦斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.5729,
        "lng": 29.071
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 32935,
      "name": "Derecske",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.3537,
        "lng": 21.5718
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8774,
      "name": "Katakwi",
      "names": {
        "zh": "卡塔奎"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.8911,
        "lng": 33.9661
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 33038,
      "name": "Kadinhani",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2397,
        "lng": 32.2114
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8769,
      "name": "Stans",
      "names": {
        "zh": "施坦斯",
        "ja": "シュタンス",
        "th": "ภูเขา"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.9594,
        "lng": 8.3667
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 8386,
      "name": "Celestun",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8583,
        "lng": -90.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8822,
      "name": "Aleg",
      "names": {
        "zh": "阿莱格"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 17.058,
        "lng": -13.909
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 32494,
      "name": "Perg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2503,
        "lng": 14.6336
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 33099,
      "name": "Keminmaa",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 65.8014,
        "lng": 24.5444
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 33018,
      "name": "Guabito",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 9.4949,
        "lng": -82.6117
      },
      "country": "Panama",
      "importance": 2
    },
    {
      "id": 32962,
      "name": "Fethiye",
      "names": {
        "zh": "费特希耶",
        "ja": "フェティエ",
        "th": "เฟทิเย"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.6206,
        "lng": 29.1142
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 33102,
      "name": "Puerres",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 0.8839,
        "lng": -77.5039
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 29975,
      "name": "Seye",
      "names": {
        "zh": "塞耶"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8372,
        "lng": -89.3719
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8811,
      "name": "Siggiewi",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8542,
        "lng": 14.4383
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 33167,
      "name": "Narowlya",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 51.8,
        "lng": 29.5
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 31889,
      "name": "Privas",
      "names": {
        "zh": "普里瓦"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.735,
        "lng": 4.5992
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 33149,
      "name": "San Miguel del Puerto",
      "names": {
        "ja": "サンミゲルデルプエルト"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.9167,
        "lng": -96.1667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32933,
      "name": "Kumphawapi",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.1151,
        "lng": 103.0186
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8853,
      "name": "Brokopondo",
      "names": {
        "zh": "布罗科蓬多"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.0404,
        "lng": -55.02
      },
      "country": "Suriname",
      "importance": 1
    },
    {
      "id": 33106,
      "name": "Janoshalma",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.2967,
        "lng": 19.3228
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 33243,
      "name": "Suomussalmi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.8833,
        "lng": 28.9167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 33077,
      "name": "Suluova",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8313,
        "lng": 35.6479
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 33248,
      "name": "Kalampaka",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.7044,
        "lng": 21.6269
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 8476,
      "name": "Concordia",
      "names": {
        "zh": "康科迪亚",
        "ja": "コンコルディア",
        "th": "คอนคอร์เดีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.2883,
        "lng": -106.0675
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 33216,
      "name": "Texmelucan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.5833,
        "lng": -97.2
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 33193,
      "name": "Vila Vicosa",
      "names": {
        "pt": "Vila vidosa"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.75,
        "lng": -7.4333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 33247,
      "name": "Proenca-a-Nova",
      "names": {
        "fr": "Proenca-a-nouvelle-",
        "zh": "proenca-一个-的Nova",
        "ja": "proenca・ア・ノヴァ",
        "th": "proenca รูปแบบโนวา"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.75,
        "lng": -7.9167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 33063,
      "name": "Phayakkhaphum Phisai",
      "names": {
        "it": "Phisai Phyakkhaphum",
        "th": "พยัคฆภูมิพิสัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.5183,
        "lng": 103.1921
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 33308,
      "name": "San Francisco",
      "names": {
        "zh": "旧金山",
        "id": "San fransisco",
        "ja": "サンフランシスコ",
        "th": "ซานฟรานซิสโก",
        "pt": "São Francisco"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9711,
        "lng": -74.2892
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 33143,
      "name": "Chaiwan",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.285,
        "lng": 103.2281
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 33264,
      "name": "Akhalkalaki",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.4056,
        "lng": 43.4861
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 33067,
      "name": "Kitzbuhel",
      "names": {
        "es": "En Kitzbühel",
        "fr": "Kitzbühel",
        "zh": "基茨比厄尔",
        "de": "Kitzbühel",
        "it": "Kitzbühel",
        "id": "Kitzbühel",
        "pt": "Kitzbühel"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.4464,
        "lng": 12.3919
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 33269,
      "name": "Cine",
      "names": {
        "zh": "电影",
        "ja": "映画",
        "th": "ภาพยนตร์"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.6127,
        "lng": 28.0591
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 33258,
      "name": "Aratoca",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.6956,
        "lng": -73.0175
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 33134,
      "name": "Khanu Woralaksaburi",
      "names": {
        "de": "Khanu Waldaalaksaburi",
        "pt": "Khanu Woraleksaburi"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.074,
        "lng": 99.8574
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 33406,
      "name": "Pudasjarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 65.3583,
        "lng": 27.0
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 33417,
      "name": "Ferreira do Alentejo",
      "names": {
        "zh": "Ferreira做Alentejo",
        "ja": "フェレイラはアレンテージョを行います"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.0589,
        "lng": -8.1156
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 33234,
      "name": "Ikata-cho",
      "names": {
        "zh": "ikata町",
        "ja": "ikata町",
        "th": "ikata-โช"
      },
      "countryCode": "JP",
      "latLng": {
        "lat": 33.4883,
        "lng": 132.3542
      },
      "country": "Japan",
      "importance": 2
    },
    {
      "id": 33343,
      "name": "Uramita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.8986,
        "lng": -76.1736
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 33326,
      "name": "Kunszentmiklos",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0264,
        "lng": 19.1228
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 33041,
      "name": "U Thong",
      "names": {
        "es": "Tu tanga",
        "fr": "U string",
        "zh": "你丁字裤",
        "de": "U tanga",
        "it": "U perizoma",
        "id": "Kamu thong",
        "ja": "ひも",
        "th": "คุณทอง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.3761,
        "lng": 99.8922
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 33150,
      "name": "Neusiedl am See",
      "names": {
        "es": "Neusiedl am ver",
        "fr": "Neusiedl suis voir",
        "zh": "neusiedl我看到了",
        "de": "Neussiedl am See",
        "ja": "Neusiedlが見ています",
        "th": "Neusiedl ฉันเห็น",
        "pt": "Neusiedl am veja"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.9486,
        "lng": 16.8431
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 32452,
      "name": "Nakhon Chai Si",
      "names": {
        "ja": "ナコン・チャイ・シリ",
        "th": "นครชัยศรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.8005,
        "lng": 100.1869
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 33292,
      "name": "Bohorodchany",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.8,
        "lng": 24.5333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 33451,
      "name": "Rakkestad",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.3731,
        "lng": 11.4203
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 8872,
      "name": "Slovenska Bistrica",
      "names": {
        "ja": "スロベンスカビストリサ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3941,
        "lng": 15.5707
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 32866,
      "name": "Ouderkerk aan de Amstel",
      "names": {
        "de": "Oüderkerk Aan de Amstel",
        "id": "Ougerkerk Aan de Amstel"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.2972,
        "lng": 4.9117
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 33375,
      "name": "Septemvri",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.2164,
        "lng": 24.125
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 33358,
      "name": "Liezen",
      "names": {
        "zh": "利岑"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 47.5667,
        "lng": 14.2333
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 30545,
      "name": "Nopaltepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7819,
        "lng": -98.7125
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 33575,
      "name": "Uribe",
      "names": {
        "zh": "乌里韦"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 3.2408,
        "lng": -74.3536
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 33527,
      "name": "Novi Sanzhary",
      "names": {
        "zh": "诺维三宗"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.3354,
        "lng": 34.3162
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 33550,
      "name": "Kirawsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.2686,
        "lng": 29.4736
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 33576,
      "name": "Laprida",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.5333,
        "lng": -60.8167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 33561,
      "name": "Loppi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.7181,
        "lng": 24.4417
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8821,
      "name": "Kocevje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.643,
        "lng": 14.8594
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 33386,
      "name": "Tidaholm",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.1794,
        "lng": 13.9599
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 33207,
      "name": "Chinon",
      "names": {
        "zh": "启侬",
        "ja": "シノン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.1669,
        "lng": 0.2428
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 33310,
      "name": "Chonnabot",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.084,
        "lng": 102.6193
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 33571,
      "name": "Hokksund",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.7708,
        "lng": 9.9099
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 30602,
      "name": "La Tour-du-Pin",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.5658,
        "lng": 5.445
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 33428,
      "name": "Sawang Wirawong",
      "names": {
        "es": "Sawang WINAWONG",
        "zh": "Sawang Wiwong",
        "ja": "サワン航空",
        "th": "สว่างวีระวงศ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2417,
        "lng": 105.0922
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 33589,
      "name": "Dong Luang",
      "names": {
        "zh": "董銮",
        "ja": "ドンルアン",
        "th": "ดงหลวง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8066,
        "lng": 104.5337
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8870,
      "name": "Balakan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.7186,
        "lng": 46.4165
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 8802,
      "name": "Gleno",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.7239,
        "lng": 125.4361
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 32965,
      "name": "Lure",
      "names": {
        "es": "Señuelo",
        "fr": "Attirer",
        "zh": "饵",
        "id": "Memikat",
        "ja": "ルアー",
        "th": "ล่อ"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6831,
        "lng": 6.4967
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 33684,
      "name": "Manhush",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.05,
        "lng": 37.3
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 33357,
      "name": "Hongwansi",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 38.8384,
        "lng": 99.6159
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 33646,
      "name": "Tarutyne",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.1846,
        "lng": 29.1495
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 33639,
      "name": "Gumushacikoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8667,
        "lng": 35.2167
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 33431,
      "name": "Camas",
      "names": {
        "zh": "卡马斯",
        "ja": "カマス",
        "th": "มาส"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9026,
        "lng": 37.528
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 33388,
      "name": "Almirante",
      "names": {
        "zh": "海军上将"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 9.3,
        "lng": -82.4
      },
      "country": "Panama",
      "importance": 2
    },
    {
      "id": 30654,
      "name": "Huaniqueo de Morales",
      "names": {
        "es": "Huanico de morales",
        "zh": "华居大道"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8946,
        "lng": -101.5122
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32972,
      "name": "Tha Maka",
      "names": {
        "ja": "マカ",
        "th": "ท่ามาคา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.9203,
        "lng": 99.7667
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8504,
      "name": "Acacoyagua",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.3408,
        "lng": -92.6747
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8884,
      "name": "Gumushane",
      "names": {
        "zh": "居米什哈内"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.4597,
        "lng": 39.4778
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 33338,
      "name": "Les Andelys",
      "names": {
        "fr": "Les olles",
        "zh": "谎言",
        "de": "Les Anpelys",
        "ja": "Lesidelys",
        "pt": "Les e,"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.2456,
        "lng": 1.4117
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 33704,
      "name": "San Martin Hidalgo",
      "names": {
        "zh": "圣马丁Hidalgo",
        "ja": "サンマーティンヒダルゴ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.435,
        "lng": -103.9286
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 33728,
      "name": "Laihia",
      "names": {
        "zh": "莱希亚"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.9764,
        "lng": 22.0111
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 33370,
      "name": "Ron Phibun",
      "names": {
        "zh": "罗恩皮亨",
        "ja": "ロンパイブン",
        "th": "รอนพิมพันธ์",
        "pt": "Ron phian"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.1722,
        "lng": 99.8533
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 33736,
      "name": "Solosuchiapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.4,
        "lng": -93.0167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 33577,
      "name": "Bedekovcina",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 46.0333,
        "lng": 16.0
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 33786,
      "name": "Hammerfest",
      "names": {
        "zh": "哈默弗斯特"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 70.6634,
        "lng": 23.6821
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 8283,
      "name": "Tacotalpa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5931,
        "lng": -92.8258
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 33600,
      "name": "Beeskow",
      "names": {
        "zh": "贝斯科"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 52.1667,
        "lng": 14.25
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 33697,
      "name": "Afsin",
      "names": {
        "zh": "阿夫欣"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.25,
        "lng": 36.9167
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8397,
      "name": "Rafael Lucio",
      "names": {
        "zh": "拉斐尔卢西奥",
        "th": "ราฟาเอลลูซิโอ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.5922,
        "lng": -96.9819
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 33539,
      "name": "Motavita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5772,
        "lng": -73.3672
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 33787,
      "name": "Pedraza",
      "names": {
        "zh": "佩德拉萨"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 10.1886,
        "lng": -74.9156
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 33703,
      "name": "Toijala",
      "names": {
        "zh": "托伊亚拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.1667,
        "lng": 23.8681
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8867,
      "name": "Beocin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.1922,
        "lng": 19.7203
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 33842,
      "name": "Semenivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 52.1772,
        "lng": 32.5797
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 33682,
      "name": "Tarouca",
      "names": {
        "zh": "塔罗卡"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.0167,
        "lng": -7.7833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 33176,
      "name": "Domat/Ems",
      "names": {
        "es": "Domat / el ccsme",
        "fr": "Domat / EMS",
        "zh": "多马特/环境管理体系",
        "de": "Domat / Ems",
        "it": "Domat / EMS",
        "id": "Domat / EMS",
        "ja": "domat / EMSの",
        "th": "domat / อีเอ็มเอส",
        "pt": "Domat / EMS"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.8198,
        "lng": 9.4545
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 33776,
      "name": "Kunszentmarton",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.84,
        "lng": 20.29
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8264,
      "name": "Gzira",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.905,
        "lng": 14.4939
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 33803,
      "name": "Gurjaani",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.7444,
        "lng": 45.8
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 33948,
      "name": "Puerto Parra",
      "names": {
        "ja": "プエルトパララ",
        "th": "เปอร์โตริโก"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.6514,
        "lng": -74.0569
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 33525,
      "name": "Chinique",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0411,
        "lng": -91.0269
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 33917,
      "name": "Cukurca",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.2469,
        "lng": 43.6124
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 33895,
      "name": "Simav",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0833,
        "lng": 28.9833
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 33970,
      "name": "Nurmes",
      "names": {
        "zh": "努尔梅斯"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.5444,
        "lng": 29.1333
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 33972,
      "name": "Helvecia",
      "names": {
        "zh": "埃尔韦西亚"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.1,
        "lng": -60.0833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 33854,
      "name": "Tamasi",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6167,
        "lng": 18.3
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8899,
      "name": "Sokobanja",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.6394,
        "lng": 21.8694
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 33671,
      "name": "Fehergyarmat",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.985,
        "lng": 22.5169
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 33664,
      "name": "Daun",
      "names": {
        "zh": "道恩"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1986,
        "lng": 6.8319
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 8902,
      "name": "Greymouth",
      "names": {
        "zh": "格雷茅斯"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -42.4667,
        "lng": 171.2
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 33537,
      "name": "Albarradas",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0667,
        "lng": -96.2
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 33950,
      "name": "Penalva do Castelo",
      "names": {
        "zh": "Bargva做Castelo",
        "de": "Strapeva do castelo"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6667,
        "lng": -7.6833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 33986,
      "name": "Almyros",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.1803,
        "lng": 22.7606
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 33456,
      "name": "Sibilia",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 15.0,
        "lng": -91.6167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 34064,
      "name": "San Luis de Palenque",
      "names": {
        "zh": "圣路易斯德帕伦克",
        "ja": "サンルイスパレネック",
        "th": "ซานหลุยส์เดอปาลุค"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.4222,
        "lng": -71.7314
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 32667,
      "name": "Simmern",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.9833,
        "lng": 7.5167
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 8509,
      "name": "San Diego de la Union",
      "names": {
        "es": "San Diego de la Unión",
        "zh": "圣地亚哥德拉联盟",
        "ja": "サンディエゴデラユニオン",
        "th": "ซานดิเอโกเดอลายูเนี่ยน"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.4656,
        "lng": -100.8736
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 34059,
      "name": "Bosanski Petrovac",
      "names": {
        "ja": "ボサンスキペトロバック"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.55,
        "lng": 16.3667
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 33858,
      "name": "Chumsaeng",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 15.898,
        "lng": 100.311
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8898,
      "name": "Mtskheta",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.85,
        "lng": 44.7167
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 34001,
      "name": "Lillesand",
      "names": {
        "zh": "利勒桑"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.2494,
        "lng": 8.3773
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 34090,
      "name": "Slawharad",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.4453,
        "lng": 30.9964
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 34087,
      "name": "Bogazliyan",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.1929,
        "lng": 35.2462
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 34063,
      "name": "Tonila",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.4261,
        "lng": -103.5319
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 34094,
      "name": "Mrkonjic Grad",
      "names": {
        "es": "Graduado mrkonjic",
        "id": "Grad Mrkonjic",
        "pt": "Graduado mrkonjic"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 44.4167,
        "lng": 17.0833
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 34123,
      "name": "Cherykaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.5667,
        "lng": 31.3667
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 32850,
      "name": "Freistadt",
      "names": {
        "zh": "弗赖施塔特"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.5117,
        "lng": 14.5061
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 33997,
      "name": "Bahsili",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8136,
        "lng": 33.4716
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8887,
      "name": "Port Maria",
      "names": {
        "es": "Puerto maria",
        "zh": "玛丽亚港",
        "de": "Port-Maria",
        "ja": "ポートマリア",
        "th": "พอร์ตมาเรีย",
        "pt": "Porto maria"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.3703,
        "lng": -76.8903
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 8825,
      "name": "Paide",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 58.8833,
        "lng": 25.5667
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 34181,
      "name": "San Julian",
      "names": {
        "zh": "圣朱利安",
        "it": "San Giuliano",
        "ja": "サンジュリアン",
        "th": "ซานจูเลียน",
        "pt": "San juliano"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -49.307,
        "lng": -67.7298
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 34020,
      "name": "Krupina",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.35,
        "lng": 19.0669
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 34117,
      "name": "Nasice",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.4833,
        "lng": 18.1
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 33496,
      "name": "Bruck an der Leitha",
      "names": {
        "zh": "布鲁克and der Leitha",
        "ja": "ブラックアンデルレイザ"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.0255,
        "lng": 16.779
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 34182,
      "name": "Jalasjarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.4917,
        "lng": 22.7667
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8909,
      "name": "San Juan Bautista",
      "names": {
        "zh": "圣胡安Bautista"
      },
      "countryCode": "PY",
      "latLng": {
        "lat": -26.68,
        "lng": -57.15
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 33866,
      "name": "Namestovo",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.41,
        "lng": 19.48
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 34161,
      "name": "Sao Joao da Pesqueira",
      "names": {
        "pt": "São João da Pesqueira"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1333,
        "lng": -7.4
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8545,
      "name": "Paola",
      "names": {
        "zh": "保拉",
        "ja": "パオラ"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8728,
        "lng": 14.5075
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 34228,
      "name": "Mynamaki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.6833,
        "lng": 21.9833
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 34032,
      "name": "Koufalia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.7792,
        "lng": 22.5767
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 34259,
      "name": "Myory",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.6167,
        "lng": 27.6167
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 8572,
      "name": "El Higo",
      "names": {
        "ja": "エルヒゴ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.7667,
        "lng": -98.45
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 34284,
      "name": "Paratebueno",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.3753,
        "lng": -73.2131
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 34219,
      "name": "Alacam",
      "names": {
        "zh": "阿拉恰姆"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.61,
        "lng": 35.595
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 33920,
      "name": "Ceret",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 42.4881,
        "lng": 2.7514
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 33009,
      "name": "Muri",
      "names": {
        "zh": "穆里"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.2747,
        "lng": 8.3417
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 34257,
      "name": "Khondab",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.3928,
        "lng": 49.1839
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 34279,
      "name": "Toprakkale",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0639,
        "lng": 36.1469
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 33747,
      "name": "Kirchheimbolanden",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.6664,
        "lng": 8.0117
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 34283,
      "name": "Rohatyn",
      "names": {
        "zh": "罗哈廷"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4167,
        "lng": 24.6167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 34386,
      "name": "Santa Ana",
      "names": {
        "zh": "圣安娜",
        "ja": "サンタアナ",
        "th": "ซานตาอานา"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 16.8069,
        "lng": -89.8272
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 8915,
      "name": "Bentiu",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 9.25,
        "lng": 29.8
      },
      "country": "South Sudan",
      "importance": 1
    },
    {
      "id": 34317,
      "name": "Khon Buri",
      "names": {
        "zh": "凯武山",
        "th": "ขอนบุรี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5686,
        "lng": 102.1564
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8911,
      "name": "Falmouth",
      "names": {
        "zh": "法尔茅斯",
        "ja": "ファルマス",
        "th": "ฟัลเมาท์"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.4936,
        "lng": -77.6558
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 34433,
      "name": "Yerkoy",
      "names": {
        "zh": "耶尔柯伊"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.6381,
        "lng": 34.4672
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 34457,
      "name": "Kemijarvi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 66.715,
        "lng": 27.4306
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 32999,
      "name": "Bremgarten",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3528,
        "lng": 8.34
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 33934,
      "name": "Lagkadas",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.75,
        "lng": 23.0667
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 34455,
      "name": "Santa Rosa de Rio Primero",
      "names": {
        "fr": "Père Santa Rosa de Rio Primero",
        "zh": "圣罗莎德里奥·菲利亚",
        "ja": "サンタローザデリオプリオロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.15,
        "lng": -63.3833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 34251,
      "name": "Thung Saliam",
      "names": {
        "fr": "Thung Saluam",
        "th": "ทุ่งเสลี่ยม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.324,
        "lng": 99.5671
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 34326,
      "name": "Soeng Sang",
      "names": {
        "es": "SOGG SANG",
        "zh": "唱桑",
        "it": "Soeng cantava",
        "id": "Sung",
        "ja": "浴衣",
        "th": "จิงซัง",
        "pt": "Soeng cantou"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4264,
        "lng": 102.4606
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8349,
      "name": "San Giljan",
      "names": {
        "es": "San Gilján",
        "zh": "圣吉朗",
        "ja": "サンギルジャン",
        "th": "ซานกิลจัน"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9186,
        "lng": 14.49
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 31684,
      "name": "Guerrero",
      "names": {
        "zh": "格雷罗",
        "ja": "ゲレーロ州",
        "th": "เกร์เรโร"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.5478,
        "lng": -107.4856
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 34362,
      "name": "Ban Phue",
      "names": {
        "es": "Prohibición de fue",
        "fr": "Interdire la phue",
        "zh": "禁止噬菌体",
        "de": "Banphone",
        "it": "Bandito",
        "id": "Larangan phue",
        "ja": "音節を禁止します",
        "th": "บ้านผือ",
        "pt": "Proibir phue"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.6874,
        "lng": 102.4756
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 42387,
      "name": "Iqaluit",
      "names": {
        "zh": "伊卡卢伊特",
        "ja": "イカルイト"
      },
      "countryCode": "CA",
      "latLng": {
        "lat": 63.7598,
        "lng": -68.5107
      },
      "country": "Canada",
      "importance": 1
    },
    {
      "id": 8924,
      "name": "Luba",
      "names": {
        "zh": "卢巴",
        "th": "ลูบา"
      },
      "countryCode": "GQ",
      "latLng": {
        "lat": 3.45,
        "lng": 8.55
      },
      "country": "Equatorial Guinea",
      "importance": 1
    },
    {
      "id": 34390,
      "name": "Chaiya",
      "names": {
        "th": "ไชยา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.3778,
        "lng": 99.2728
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 34475,
      "name": "San Diego",
      "names": {
        "zh": "圣地亚哥",
        "ja": "サンディエゴ",
        "th": "ซานดิเอโก"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7833,
        "lng": -89.7833
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 33512,
      "name": "Landeck",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.1333,
        "lng": 10.5667
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 33239,
      "name": "Thann",
      "names": {
        "zh": "坦恩"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.8067,
        "lng": 7.1044
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 34438,
      "name": "Kinik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0872,
        "lng": 27.3833
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 34502,
      "name": "Kryve Ozero",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.9333,
        "lng": 30.3333
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8768,
      "name": "Grosuplje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9551,
        "lng": 14.6562
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 34431,
      "name": "Alpiarca",
      "names": {
        "zh": "阿尔皮亚萨"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.2593,
        "lng": -8.585
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 33890,
      "name": "Langres",
      "names": {
        "zh": "朗格勒"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.8625,
        "lng": 5.3331
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 34535,
      "name": "Celorico da Beira",
      "names": {
        "fr": "Célorico da Beira",
        "zh": "Celorico达贝拉",
        "id": "Celori da beira"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.6333,
        "lng": -7.4
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 34368,
      "name": "Santana",
      "names": {
        "zh": "桑塔纳",
        "ja": "サンタナ"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0572,
        "lng": -73.4811
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 34346,
      "name": "Beloslav",
      "names": {
        "zh": "贝洛斯拉夫"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.1937,
        "lng": 27.7067
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 33215,
      "name": "Saint-Jean-de-Maurienne",
      "names": {
        "zh": "圣让 - 去maurienne",
        "ja": "サン・ジャン・ド・maurienne",
        "th": "เซนต์ฌองเดอ maurienne"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.2767,
        "lng": 6.345
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 34499,
      "name": "Neufchateau",
      "names": {
        "es": "Neufchâteau",
        "zh": "讷沙托",
        "de": "Neufchâteau",
        "it": "Neufchâteau"
      },
      "countryCode": "BE",
      "latLng": {
        "lat": 49.8411,
        "lng": 5.4353
      },
      "country": "Belgium",
      "importance": 2
    },
    {
      "id": 34487,
      "name": "Nyiradony",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6833,
        "lng": 21.9167
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 34611,
      "name": "Dykanka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.8222,
        "lng": 34.5341
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 34660,
      "name": "Zara",
      "names": {
        "zh": "扎拉",
        "ja": "ザラ",
        "th": "ซาร่า"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.895,
        "lng": 37.7531
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 34649,
      "name": "Coromoro",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.295,
        "lng": -73.0406
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 34542,
      "name": "Trostianets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.512,
        "lng": 29.2137
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 34608,
      "name": "Chapultenango",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.3333,
        "lng": -93.1333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 34655,
      "name": "Lice",
      "names": {
        "es": "Piojos",
        "fr": "Les Poux",
        "zh": "虱子",
        "de": "Läuse",
        "it": "Pidocchi",
        "id": "Kutu",
        "ja": "シラミ",
        "th": "เหา",
        "pt": "Piolhos"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4549,
        "lng": 40.6519
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 33844,
      "name": "Heyin",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 36.0451,
        "lng": 101.4242
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 8907,
      "name": "Yeghegnadzor",
      "names": {},
      "countryCode": "AM",
      "latLng": {
        "lat": 39.7611,
        "lng": 45.3333
      },
      "country": "Armenia",
      "importance": 1
    },
    {
      "id": 34710,
      "name": "Solone",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2054,
        "lng": 34.8686
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 34718,
      "name": "Loten",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.8253,
        "lng": 11.3908
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 8931,
      "name": "Yardimli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.9206,
        "lng": 48.2372
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 34769,
      "name": "Oulainen",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.2667,
        "lng": 24.8167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8447,
      "name": "Tlacotalpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6167,
        "lng": -95.6667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 34256,
      "name": "Akcaabat",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0167,
        "lng": 39.5833
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 34777,
      "name": "Sykkylven",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.3896,
        "lng": 6.5782
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 33677,
      "name": "Peronne",
      "names": {
        "zh": "佩罗讷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.9322,
        "lng": 2.9364
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 34794,
      "name": "Kokemaki",
      "names": {
        "zh": "科凯迈基"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.2556,
        "lng": 22.3486
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 34603,
      "name": "Novoselytsia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2167,
        "lng": 26.2667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 34732,
      "name": "Porec",
      "names": {
        "zh": "波雷奇",
        "ja": "ポレチュ"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.2167,
        "lng": 13.5833
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 34560,
      "name": "Nusaybin",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0833,
        "lng": 41.2167
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 34440,
      "name": "Oberwart",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.2878,
        "lng": 16.2031
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 34743,
      "name": "Rade",
      "names": {
        "zh": "贸易"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.3489,
        "lng": 10.855
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 34870,
      "name": "Nykarleby",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.5167,
        "lng": 22.5333
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 34819,
      "name": "Ambalema",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.7817,
        "lng": -74.7639
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 34884,
      "name": "Bereznehuvate",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.3114,
        "lng": 32.8478
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 31896,
      "name": "San Pablo Villa de Mitla",
      "names": {
        "ja": "サンパブロヴィラデMitla",
        "th": "ซานปาโบลวิลล่าเดอมิทลา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.917,
        "lng": -96.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 34667,
      "name": "Al Mazra`ah",
      "names": {
        "de": "Al Mazra",
        "it": "Al mazra`",
        "ja": "アルマツラワ",
        "pt": "Al Mazra`"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 32.7828,
        "lng": 36.4833
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 8730,
      "name": "Tenabo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0333,
        "lng": -90.2167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 34795,
      "name": "Tarso",
      "names": {
        "zh": "塔尔索"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.865,
        "lng": -75.8225
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 34926,
      "name": "Kruhlaye",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.2479,
        "lng": 29.7966
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 34813,
      "name": "Omitlan de Juarez",
      "names": {
        "es": "Omitlan de Juárez",
        "th": "ออมทองเดอ Juarez"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1697,
        "lng": -98.6478
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 33401,
      "name": "Catarina",
      "names": {
        "zh": "卡塔琳娜",
        "th": "ตากาตารินา"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.9119,
        "lng": -86.0751
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 34118,
      "name": "Rethel",
      "names": {
        "zh": "雷泰尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.5097,
        "lng": 4.3675
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 34876,
      "name": "Kunhegyes",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.37,
        "lng": 20.6308
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 8580,
      "name": "Roatan",
      "names": {
        "es": "Roatán",
        "zh": "罗阿坦",
        "ja": "ロアタン島"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 16.33,
        "lng": -86.519
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 34958,
      "name": "Roshtkhvar",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.975,
        "lng": 59.6247
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 8461,
      "name": "Cuauhtemoc",
      "names": {
        "fr": "Cuauhtémoc",
        "zh": "夸特莫克"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3281,
        "lng": -103.6028
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 34989,
      "name": "Drvar",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 44.3739,
        "lng": 16.3808
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 8946,
      "name": "Sen Monorom",
      "names": {
        "zh": "森莫诺林",
        "ja": "モノロムを求めてください",
        "th": "เซน"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 12.4558,
        "lng": 107.1881
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 8948,
      "name": "Kelme",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.6333,
        "lng": 22.9333
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 34937,
      "name": "Mondim de Basto",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.4,
        "lng": -7.95
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 34976,
      "name": "Phu Kradueng",
      "names": {
        "fr": "Phu KraduNeng",
        "th": "ภูกระ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8842,
        "lng": 101.8847
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35012,
      "name": "Gediz",
      "names": {
        "zh": "盖迪兹"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.9939,
        "lng": 29.3914
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35039,
      "name": "Miranda do Douro",
      "names": {
        "zh": "米兰达做杜罗",
        "de": "Miranda do douro do douro",
        "th": "มิแรนดาโดดูโร"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5,
        "lng": -6.2667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 34895,
      "name": "Nong Phai",
      "names": {
        "zh": "作响",
        "th": "หนองไผ่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.9972,
        "lng": 101.0652
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 34965,
      "name": "Maripi",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5489,
        "lng": -74.0067
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8956,
      "name": "Ludza",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.5439,
        "lng": 27.7211
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 34801,
      "name": "Stromstad",
      "names": {
        "zh": "斯特伦斯塔德",
        "it": "Strömstad",
        "th": "สตรอมสตัด"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 58.9441,
        "lng": 11.1864
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 34770,
      "name": "Gaoual",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 11.754,
        "lng": -13.213
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 34437,
      "name": "Lodeve",
      "names": {
        "fr": "Lodève"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.7317,
        "lng": 3.3194
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 35074,
      "name": "Beysehir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.6867,
        "lng": 31.7306
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35072,
      "name": "Krasnokutsk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.0567,
        "lng": 35.1492
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 35000,
      "name": "Aybasti",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6867,
        "lng": 37.3992
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35115,
      "name": "Nisa",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.5167,
        "lng": -7.65
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35127,
      "name": "Almodovar",
      "names": {
        "zh": "瓦",
        "pt": "Almodóvar"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.5114,
        "lng": -8.0603
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8950,
      "name": "Leova",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.4786,
        "lng": 28.2553
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 35150,
      "name": "Sivrihisar",
      "names": {
        "zh": "锡夫里希萨尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.451,
        "lng": 31.5367
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35149,
      "name": "Haapajarvi",
      "names": {
        "zh": "哈帕耶尔维"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.7486,
        "lng": 25.3181
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 34852,
      "name": "Uthumphon Phisai",
      "names": {
        "de": "Uthumhon phisai",
        "ja": "ウスモンピサイ",
        "th": "อุทุมีย์พิสัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.097,
        "lng": 104.1643
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35163,
      "name": "Tsarychanka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.9432,
        "lng": 34.4748
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 34644,
      "name": "San Miguel Siguila",
      "names": {
        "fr": "SIGUIL SIGUILA SAN MIGUEL"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9,
        "lng": -91.6167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 8910,
      "name": "Mahmud-e Raqi",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 35.0167,
        "lng": 69.3333
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 35105,
      "name": "Kriz",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.65,
        "lng": 16.5167
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 8959,
      "name": "Goranboy",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.61,
        "lng": 46.7872
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 34244,
      "name": "Damnoen Saduak",
      "names": {
        "id": "Sialan saduak",
        "ja": "ダムノンサドゥック"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.5177,
        "lng": 99.9545
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35284,
      "name": "Simacota",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4431,
        "lng": -73.3375
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 35282,
      "name": "Suonenjoki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.625,
        "lng": 27.1222
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 34348,
      "name": "Kusadasi",
      "names": {
        "zh": "库萨达斯",
        "ja": "クシャダス",
        "th": "คูซาดาซี"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8586,
        "lng": 27.2594
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8889,
      "name": "Nangan",
      "names": {},
      "countryCode": "TW",
      "latLng": {
        "lat": 26.1598,
        "lng": 119.9432
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 34561,
      "name": "Bang Pa-in",
      "names": {
        "zh": "爆炸pa-in",
        "de": "Bang-Pa-in",
        "id": "Bang",
        "ja": "バンパイイン",
        "th": "ปังป่า"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.22,
        "lng": 100.5813
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35089,
      "name": "Anna Paulowna",
      "names": {
        "zh": "安娜波洛纳",
        "ja": "アンナパウロウナ",
        "pt": "Anna Paulooda"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 52.85,
        "lng": 4.8167
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 34139,
      "name": "Langon",
      "names": {
        "zh": "朗贡"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.5531,
        "lng": -0.2494
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8971,
      "name": "Montpelier",
      "names": {
        "zh": "蒙彼利埃",
        "th": "มอนต์เพเลียร์"
      },
      "countryCode": "US",
      "latLng": {
        "lat": 44.2658,
        "lng": -72.5717
      },
      "country": "United States",
      "importance": 1
    },
    {
      "id": 35355,
      "name": "Bruzual",
      "names": {
        "zh": "布鲁苏阿尔"
      },
      "countryCode": "VE",
      "latLng": {
        "lat": 8.0494,
        "lng": -69.3316
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 8965,
      "name": "Kirikkale",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8417,
        "lng": 33.5139
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 8958,
      "name": "Ravne na Koroskem",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5437,
        "lng": 14.9642
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 35354,
      "name": "Arraiolos",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7229,
        "lng": -7.9843
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35341,
      "name": "Komijan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 34.7192,
        "lng": 49.3267
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 35374,
      "name": "Santa Marta de Penaguiao",
      "names": {
        "zh": "圣玛塔德比奥奥",
        "ja": "サンタマルタデパネガリア",
        "th": "ซานตามาร์ตาเดอตางูโอะ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2,
        "lng": -7.7833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 34790,
      "name": "Mattersburg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.7381,
        "lng": 16.3969
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 35362,
      "name": "San Diego de Alejandria",
      "names": {
        "zh": "圣地亚哥德阿莱·德里亚",
        "ja": "サンディエゴドアリャンドリア",
        "th": "ซานดิเอโกเดอเลตเจนเดรีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8667,
        "lng": -101.9
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 35153,
      "name": "Lenti",
      "names": {
        "zh": "伦蒂"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.6236,
        "lng": 16.5458
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 35242,
      "name": "Sa Bot",
      "names": {
        "ja": "SAボット",
        "th": "บอท"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2084,
        "lng": 100.834
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35256,
      "name": "Elassona",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.8947,
        "lng": 22.1886
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 35399,
      "name": "Macao",
      "names": {
        "zh": "澳门",
        "id": "Makao",
        "ja": "マカオ",
        "th": "มาเก๊า",
        "pt": "Macau"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.55,
        "lng": -8.0
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35258,
      "name": "Serik",
      "names": {
        "zh": "谢里克"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.9167,
        "lng": 31.1
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35325,
      "name": "Borba",
      "names": {
        "zh": "战斗报"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.8,
        "lng": -7.45
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35106,
      "name": "Vila Nova da Barquinha",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.45,
        "lng": -8.4333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35365,
      "name": "La Concordia",
      "names": {
        "zh": "洛杉矶哥伦比亚",
        "ja": "ラコンコルディア"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.1833,
        "lng": -86.1667
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 8926,
      "name": "Aracinovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0264,
        "lng": 21.5617
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 8969,
      "name": "Briceni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.3628,
        "lng": 27.085
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 35525,
      "name": "Vila Nova de Foz Coa",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.0833,
        "lng": -7.1333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35519,
      "name": "Klichaw",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.4828,
        "lng": 29.3411
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 35315,
      "name": "Na Chaluai",
      "names": {
        "ja": "ナルアイ",
        "th": "นาชาว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5309,
        "lng": 105.2431
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35433,
      "name": "San Antonino Monteverde",
      "names": {
        "zh": "圣安东尼诺蒙特尔德",
        "ja": "サンアントニーノモンテディーベッド"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5322,
        "lng": -97.7208
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8970,
      "name": "Lerik",
      "names": {
        "zh": "莱里克"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 38.7753,
        "lng": 48.4153
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 35407,
      "name": "Harjavalta",
      "names": {
        "zh": "哈尔亚瓦尔塔"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.3139,
        "lng": 22.1417
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8665,
      "name": "Hopelchen",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7444,
        "lng": -89.8453
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 35140,
      "name": "Ten Boer",
      "names": {
        "es": "Diez boer",
        "fr": "Dix boer",
        "zh": "十个布尔",
        "de": "Zehn Bur",
        "it": "Dieci boer",
        "id": "Sepuluh boer",
        "ja": "10ベア",
        "th": "สิบเออร์",
        "pt": "Dez boer"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 53.2833,
        "lng": 6.6833
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 34784,
      "name": "Hang Dong",
      "names": {
        "es": "Colgar dong",
        "fr": "Pendre",
        "zh": "挂东",
        "id": "Menggantung",
        "ja": "ぶらぶら",
        "th": "ดง",
        "pt": "Pendurar dong"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.6886,
        "lng": 98.9193
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 8976,
      "name": "Kraslava",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 55.8956,
        "lng": 27.1644
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 35481,
      "name": "Alvaiazere",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.8333,
        "lng": -8.3833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35411,
      "name": "Tall Tamr",
      "names": {
        "es": "Tamr altura",
        "fr": "Taille Tamr",
        "zh": "高大的tamr",
        "de": "Hoher Tamr",
        "it": "TAMR TALL",
        "id": "Tamr tinggi",
        "ja": "背の高いタム",
        "th": "THAL TAMR"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.6606,
        "lng": 40.3714
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 35368,
      "name": "Poiares",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.2167,
        "lng": -8.25
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8963,
      "name": "Kicevo",
      "names": {
        "zh": "基切沃"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.5142,
        "lng": 20.9631
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 35573,
      "name": "Castro Verde",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 37.6976,
        "lng": -8.0819
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35598,
      "name": "Mertola",
      "names": {
        "pt": "Mértola"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.6333,
        "lng": -7.65
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35114,
      "name": "Ra-ngae",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 6.2967,
        "lng": 101.7283
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35238,
      "name": "Fuzesabony",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.751,
        "lng": 20.409
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 35382,
      "name": "Thung Fon",
      "names": {
        "ja": "ティックフォン",
        "th": "ตุง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4722,
        "lng": 103.2626
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35529,
      "name": "Soata",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3328,
        "lng": -72.6839
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8974,
      "name": "Ocnita",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.4167,
        "lng": 27.4833
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 35600,
      "name": "Terras de Bouro",
      "names": {
        "ja": "テラ・ド・ブーロ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.7167,
        "lng": -8.3
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8980,
      "name": "Mongomo",
      "names": {},
      "countryCode": "GQ",
      "latLng": {
        "lat": 1.6287,
        "lng": 11.3168
      },
      "country": "Equatorial Guinea",
      "importance": 1
    },
    {
      "id": 8978,
      "name": "Slovenj Gradec",
      "names": {
        "de": "Slowenj-Gradec",
        "ja": "スロベンジャグレード"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5093,
        "lng": 15.079
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 35472,
      "name": "Yssingeaux",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.1428,
        "lng": 4.1236
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 35669,
      "name": "Almeida",
      "names": {
        "zh": "阿尔梅达",
        "ja": "アルメイダ",
        "th": "ไมย์"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.7259,
        "lng": -6.9056
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35653,
      "name": "Tona",
      "names": {
        "zh": "托纳"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.2019,
        "lng": -72.9664
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 35672,
      "name": "Shyriaieve",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.3878,
        "lng": 30.1911
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 8984,
      "name": "Telenesti",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.4997,
        "lng": 28.3656
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 35664,
      "name": "Belene",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.644,
        "lng": 25.1254
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 8662,
      "name": "Huiloapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8167,
        "lng": -97.15
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 35536,
      "name": "Cavle",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.35,
        "lng": 14.48
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 8849,
      "name": "Krsko",
      "names": {
        "zh": "克尔什科"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.959,
        "lng": 15.4922
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 35770,
      "name": "Ikaalinen",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.7694,
        "lng": 23.0681
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 35668,
      "name": "Havran",
      "names": {
        "zh": "哈夫兰"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.5583,
        "lng": 27.0983
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8986,
      "name": "Naujoji Akmene",
      "names": {
        "fr": "Naujoji Akmène",
        "id": "Naujoji akmenene",
        "ja": "ナウジョジア青江"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 56.325,
        "lng": 22.8833
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 35761,
      "name": "Zacualpan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.4993,
        "lng": -98.3393
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 35828,
      "name": "Carhue",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.1667,
        "lng": -62.7333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 35534,
      "name": "Karpenisi",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.9203,
        "lng": 21.7833
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 8979,
      "name": "Mobaye",
      "names": {},
      "countryCode": "CF",
      "latLng": {
        "lat": 4.3167,
        "lng": 21.1833
      },
      "country": "Central African Republic",
      "importance": 1
    },
    {
      "id": 32622,
      "name": "Conkal",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0733,
        "lng": -89.5208
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 35798,
      "name": "Marathonas",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.155,
        "lng": 23.9636
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 32244,
      "name": "Ciudad Cerralvo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 26.0899,
        "lng": -99.6147
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8868,
      "name": "Soro",
      "names": {
        "zh": "索罗"
      },
      "countryCode": "DK",
      "latLng": {
        "lat": 55.433,
        "lng": 11.5667
      },
      "country": "Denmark",
      "importance": 1
    },
    {
      "id": 35887,
      "name": "Haapavesi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.1375,
        "lng": 25.3667
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 35523,
      "name": "Freyung",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 48.8075,
        "lng": 13.5475
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 35795,
      "name": "Hanga Roa",
      "names": {
        "it": "Hangha roa",
        "id": "Roa",
        "pt": "Sanga Roa"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -27.1492,
        "lng": -109.4323
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 35832,
      "name": "San Vicente Pacaya",
      "names": {
        "ja": "サンビセンテパカヤ"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.4161,
        "lng": -90.6392
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 35680,
      "name": "Koshekhabl",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.9,
        "lng": 40.4833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 8985,
      "name": "Adazi",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.0769,
        "lng": 24.3236
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 8539,
      "name": "Kantunilkin",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.1,
        "lng": -87.4833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 35722,
      "name": "Bamnet Narong",
      "names": {
        "fr": "Narong Bamnet",
        "ja": "バメットナロン"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.5034,
        "lng": 101.681
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35503,
      "name": "Yesilyurt",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3048,
        "lng": 38.2512
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35921,
      "name": "Myadzyel",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.8756,
        "lng": 26.9386
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 8981,
      "name": "Lapovo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.1833,
        "lng": 21.1
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 35432,
      "name": "Si Chiang Mai",
      "names": {
        "ja": "Siチェンマイ",
        "th": "ศรีเชียงใหม่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.9564,
        "lng": 102.5867
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35850,
      "name": "Socha",
      "names": {
        "zh": "索哈"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9978,
        "lng": -72.6922
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8968,
      "name": "Ruyigi",
      "names": {
        "zh": "鲁伊吉"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.4667,
        "lng": 30.25
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 35931,
      "name": "Outokumpu",
      "names": {
        "zh": "奥托昆普"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.725,
        "lng": 29.0181
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8709,
      "name": "Santa Eulalia",
      "names": {
        "zh": "圣雅利亚",
        "ja": "サンタエゥラリア",
        "th": "ซานตาเอโลเลีย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.5939,
        "lng": -105.8878
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 35880,
      "name": "Slivnitsa",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 42.8515,
        "lng": 23.0383
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 35997,
      "name": "Stryn",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 61.8386,
        "lng": 6.8628
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 35980,
      "name": "Taskopru",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.5097,
        "lng": 34.2142
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 36018,
      "name": "Akhmeta",
      "names": {
        "zh": "艾哈迈塔"
      },
      "countryCode": "GE",
      "latLng": {
        "lat": 42.0333,
        "lng": 45.2
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 35918,
      "name": "Bagneres-de-Bigorre",
      "names": {
        "fr": "Bagnères-de-Bigorre",
        "zh": "巴涅尔 - 德比戈尔",
        "de": "Bagneres de bigorre",
        "it": "Bagneres De bigorre",
        "ja": "bagneres・ド・bigorre",
        "th": "บานช์แยร์เดอ-bigorre"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.0642,
        "lng": 0.1497
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 36001,
      "name": "Salento",
      "names": {
        "zh": "萨伦托"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.6372,
        "lng": -75.5708
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 8995,
      "name": "Donduseni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 48.2244,
        "lng": 27.5853
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 35917,
      "name": "Saruhanli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7344,
        "lng": 27.5681
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35951,
      "name": "Bo Phloi",
      "names": {
        "it": "Bo floi",
        "th": "บ่อพล่อย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.3284,
        "lng": 99.5154
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35458,
      "name": "Nakhon Luang",
      "names": {
        "ja": "ナコンルアン",
        "th": "นครหลวง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4628,
        "lng": 100.6083
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35922,
      "name": "Chuarrancho",
      "names": {},
      "countryCode": "GT",
      "latLng": {
        "lat": 14.8167,
        "lng": -90.5167
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 36067,
      "name": "Okhansk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.7167,
        "lng": 55.3833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 33273,
      "name": "Chicomuselo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 15.7442,
        "lng": -92.2833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 35999,
      "name": "Boavita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.3308,
        "lng": -72.5842
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 35480,
      "name": "Lavrio",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.7144,
        "lng": 24.0565
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 35994,
      "name": "San Jose de Cusmapa",
      "names": {
        "zh": "圣何塞德·科斯玛帕",
        "ja": "サンノゼ・デ・カスマパ",
        "th": "ซานโฮเซเดอคัสมาปะ"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.2833,
        "lng": -86.65
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 36147,
      "name": "Sakyla",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.05,
        "lng": 22.35
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 36008,
      "name": "Isliam-Terek",
      "names": {
        "zh": "isliam-捷列克"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 45.2256,
        "lng": 35.2033
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 35836,
      "name": "Polykastro",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.9954,
        "lng": 22.5714
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 36080,
      "name": "Susehri",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.1628,
        "lng": 38.0855
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35833,
      "name": "La Grandeza",
      "names": {
        "it": "La Granrateza",
        "ja": "ラグランドーザ",
        "th": "ลาแกรนด์ซ่า"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 15.5333,
        "lng": -92.2333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8944,
      "name": "Diekirch",
      "names": {
        "zh": "迪基希"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.8678,
        "lng": 6.1558
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 9006,
      "name": "In Guezzam",
      "names": {
        "es": "En guezzam",
        "fr": "À Guezzam",
        "zh": "在格瓦姆",
        "id": "Di guezzam",
        "ja": "Guezzamで",
        "th": "ใน Guzzam",
        "pt": "Em guezzam"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 19.5686,
        "lng": 5.7722
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 8993,
      "name": "Aizkraukle",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.6008,
        "lng": 25.255
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 36137,
      "name": "Aydincik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.1667,
        "lng": 33.35
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 36161,
      "name": "Chadegan",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 32.7683,
        "lng": 50.6286
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 9004,
      "name": "Livani",
      "names": {
        "zh": "利瓦尼"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 56.3539,
        "lng": 26.1761
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 35992,
      "name": "Ko Pha-Ngan",
      "names": {
        "de": "Kopha-ngan",
        "ja": "KO-NGAN",
        "th": "เกาะพะงัน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 9.7176,
        "lng": 99.9949
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 36203,
      "name": "Redondo",
      "names": {
        "zh": "雷东多",
        "ja": "レドンド"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.6464,
        "lng": -7.5464
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 8773,
      "name": "Thyolo",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -16.0667,
        "lng": 35.1333
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 36126,
      "name": "Sharhorod",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.75,
        "lng": 28.0833
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 36153,
      "name": "Novska",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3333,
        "lng": 16.9833
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 32799,
      "name": "San Pablo Huitzo",
      "names": {
        "zh": "圣巴勃罗·惠特佐",
        "ja": "サンパブロHuitzo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2764,
        "lng": -96.8825
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8778,
      "name": "Guingamp",
      "names": {
        "zh": "甘冈"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.5625,
        "lng": -3.1511
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 36246,
      "name": "Polessk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.8667,
        "lng": 21.1
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 36304,
      "name": "Chaek",
      "names": {},
      "countryCode": "KG",
      "latLng": {
        "lat": 41.93,
        "lng": 74.51
      },
      "country": "Kyrgyzstan",
      "importance": 2
    },
    {
      "id": 36278,
      "name": "Tenamaxtlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2167,
        "lng": -104.167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 36328,
      "name": "Virrat",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.2403,
        "lng": 23.7708
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 36234,
      "name": "Litochoro",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.1028,
        "lng": 22.5069
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 35486,
      "name": "Ciftlikkoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.6603,
        "lng": 29.3236
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35041,
      "name": "Birkenfeld",
      "names": {
        "zh": "比肯费尔德"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.65,
        "lng": 7.1833
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 9010,
      "name": "Gulbene",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.175,
        "lng": 26.7564
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 36386,
      "name": "Oppdal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.5667,
        "lng": 9.6
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 35172,
      "name": "Tha Ruea",
      "names": {
        "es": "Thauea",
        "it": "Tu",
        "ja": "ruea",
        "th": "ท่าเรือ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5596,
        "lng": 100.7226
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 36381,
      "name": "Kazanka",
      "names": {
        "zh": "卡赞卡"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 47.8381,
        "lng": 32.8261
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 36324,
      "name": "Turka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.1544,
        "lng": 23.03
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 36366,
      "name": "Guatavita",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.9344,
        "lng": -73.8344
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 36402,
      "name": "Vestnes",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.5844,
        "lng": 7.0183
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 36393,
      "name": "Tomakivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.8163,
        "lng": 34.7471
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 36453,
      "name": "San Buenaventura",
      "names": {
        "zh": "圣布纳留村",
        "ja": "サンブナベントゥーラ",
        "th": "ซานบัวเวนทูรา"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 29.8333,
        "lng": -107.4667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 36431,
      "name": "Vyerkhnyadzvinsk",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 55.7667,
        "lng": 27.9333
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 36444,
      "name": "Sunndalsora",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.6128,
        "lng": 8.6342
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 36441,
      "name": "Dubrowna",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 54.5667,
        "lng": 30.6833
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 33225,
      "name": "Tumbiscatio de Ruiz",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5167,
        "lng": -102.383
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 36456,
      "name": "Askale",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.9211,
        "lng": 40.6947
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 36411,
      "name": "Kubrat",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.7965,
        "lng": 26.5001
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 35352,
      "name": "Segre",
      "names": {
        "zh": "瑟格雷"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6864,
        "lng": -0.8725
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8323,
      "name": "Santa Venera",
      "names": {
        "zh": "圣景观",
        "ja": "サンタヴェネラ",
        "th": "ซานตาเวเนรา"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8903,
        "lng": 14.4778
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 8982,
      "name": "Hola",
      "names": {
        "zh": "霍拉"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.5,
        "lng": 40.03
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 8784,
      "name": "Valle de Guadalupe",
      "names": {
        "ja": "ヴァッレデグアダルーペ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.0,
        "lng": -102.6167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 32770,
      "name": "Imuris",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 30.7667,
        "lng": -110.8333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8990,
      "name": "Nakasongola",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.3089,
        "lng": 32.4564
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 36298,
      "name": "Kistelek",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4731,
        "lng": 19.98
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 36312,
      "name": "Polgardi",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.0538,
        "lng": 18.3049
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 36466,
      "name": "Sivasli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4996,
        "lng": 29.6808
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 36565,
      "name": "Guican",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4625,
        "lng": -72.4119
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 36545,
      "name": "Seraitang",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 32.9368,
        "lng": 100.7356
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 36106,
      "name": "Sikatuna",
      "names": {
        "zh": "锡卡图纳"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 9.6833,
        "lng": 123.9667
      },
      "country": "Philippines",
      "importance": 2
    },
    {
      "id": 36327,
      "name": "Zlatograd",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 41.3805,
        "lng": 25.0947
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 36340,
      "name": "Velika Kladusa",
      "names": {
        "ja": "Kladusa velika"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 45.1667,
        "lng": 15.8
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 8753,
      "name": "Victoria",
      "names": {
        "zh": "维多利亚",
        "ja": "ビクトリア",
        "th": "วิกตอเรีย"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 36.05,
        "lng": 14.25
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 36264,
      "name": "Pinotepa de Don Luis",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.4333,
        "lng": -97.9667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 9012,
      "name": "Limbazi",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.5147,
        "lng": 24.7131
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 35681,
      "name": "Saint-Jean-d\u0027Angely",
      "names": {
        "zh": "圣让 -  d\u0027angely",
        "ja": "サン・ジャン\u003d d\u0027angely",
        "th": "เซนต์ฌอง d\u0027angely"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.9442,
        "lng": -0.5211
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 9011,
      "name": "Oguz",
      "names": {
        "zh": "奥乌兹"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.0708,
        "lng": 47.4583
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 35731,
      "name": "Tomatlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0203,
        "lng": -96.9956
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 36470,
      "name": "Charoen Sin",
      "names": {
        "es": "Pecado de caroen",
        "zh": "夏洛森罪",
        "de": "Charoen Sünde",
        "id": "Dosa charoen",
        "ja": "チャルエン罪",
        "th": "เจริญนครศวรรย์",
        "pt": "Pecado de charoen"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.5837,
        "lng": 103.5422
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 36223,
      "name": "Aigina",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.7467,
        "lng": 23.4275
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 36607,
      "name": "Jevnaker",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 60.2389,
        "lng": 10.3931
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 36564,
      "name": "Belmonte",
      "names": {
        "zh": "贝尔蒙特",
        "th": "เบลมอนต์"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.3667,
        "lng": -7.35
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 36450,
      "name": "Nerac",
      "names": {
        "fr": "Nérac",
        "zh": "内拉克",
        "it": "Nérac"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 44.1361,
        "lng": 0.3394
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 36504,
      "name": "Baarle-Nassau",
      "names": {
        "zh": "巴勒拿骚",
        "ja": "baarle-ナッソー",
        "th": "baarle-นัสเซา"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.45,
        "lng": 4.9333
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 8916,
      "name": "Ajdovscina",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8884,
        "lng": 13.9052
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9009,
      "name": "Brezice",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9044,
        "lng": 15.5925
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 36757,
      "name": "Chugqensumdo",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 33.4352,
        "lng": 101.4866
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 35331,
      "name": "Keskin",
      "names": {
        "zh": "凯斯金"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.6731,
        "lng": 33.6136
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 36374,
      "name": "Zirc",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.2633,
        "lng": 17.8725
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 36839,
      "name": "Inari",
      "names": {
        "zh": "伊纳里",
        "ja": "稲荷"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 68.9055,
        "lng": 27.0176
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 36803,
      "name": "Bilmak",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.3589,
        "lng": 36.65
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 36555,
      "name": "Menderes",
      "names": {
        "zh": "曼德列斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.254,
        "lng": 27.134
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 36785,
      "name": "Mozonte",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.65,
        "lng": -86.45
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 36827,
      "name": "Tyrnava",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 64.75,
        "lng": 25.65
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 36843,
      "name": "Kristinestad",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.2736,
        "lng": 21.3778
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 9017,
      "name": "Madona",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.8542,
        "lng": 26.22
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 36143,
      "name": "Nyons",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 44.3608,
        "lng": 5.1406
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 8519,
      "name": "Ciudad Ayala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.7667,
        "lng": -98.9833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 36905,
      "name": "Sarkisla",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.3506,
        "lng": 36.4095
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 36934,
      "name": "Parkano",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.0097,
        "lng": 23.025
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 9021,
      "name": "Kovacica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.1117,
        "lng": 20.6214
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 36855,
      "name": "Risor",
      "names": {
        "pt": "Risør"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 58.7247,
        "lng": 9.23
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 36818,
      "name": "San Francisco del Norte",
      "names": {
        "zh": "旧金山del Norte",
        "ja": "サンフランシスコデルノルテ",
        "th": "ซานฟรานซิสโกเดล Norte",
        "pt": "São Francisco del Norte"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 13.1992,
        "lng": -86.7717
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 36808,
      "name": "Chiang Kham",
      "names": {
        "zh": "蒋克汉姆",
        "ja": "チェンカム",
        "th": "เชียงคาย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.5233,
        "lng": 100.3
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 36906,
      "name": "Na Duang",
      "names": {
        "zh": "娜杜",
        "th": "น้าดวง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.4743,
        "lng": 101.98
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 36954,
      "name": "Sofiivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.0727,
        "lng": 33.8937
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 36940,
      "name": "Castro Marim",
      "names": {
        "id": "Mari",
        "ja": "カストロマリム"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.2167,
        "lng": -7.45
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 36825,
      "name": "Hornostaivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.007,
        "lng": 33.728
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 36918,
      "name": "Bozdogan",
      "names": {
        "zh": "博兹多安"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.6728,
        "lng": 28.3103
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 36988,
      "name": "Cihanbeyli",
      "names": {
        "zh": "吉汉贝伊利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6581,
        "lng": 32.9281
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 36909,
      "name": "`Ayn `Isa",
      "names": {
        "es": "\u0027Ayn\u0027 ISA",
        "fr": "`Ayn` isa",
        "zh": "`Ayn`isa",
        "de": "`Ayn` Isa",
        "it": "`Ayn` isa",
        "id": "`Ayn` Isa",
        "ja": "`ayn` isa",
        "th": "`Ayn` isa",
        "pt": "`Ayn` isa"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.3858,
        "lng": 38.8472
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 37051,
      "name": "Cubara",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 7.0008,
        "lng": -72.1081
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 36680,
      "name": "Ban Haet",
      "names": {
        "fr": "Banane",
        "zh": "禁止哈丁",
        "de": "Hals",
        "it": "Bandata",
        "id": "Melarang",
        "ja": "ヘェットを禁止します",
        "th": "บ้านห้าม",
        "pt": "Proibir haet"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.2,
        "lng": 102.7492
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37078,
      "name": "Morelos",
      "names": {
        "zh": "莫雷洛斯",
        "ja": "モレロス",
        "th": "มอเรโลส"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.4075,
        "lng": -100.8856
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 9023,
      "name": "Salcininkai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.3111,
        "lng": 25.3806
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 37076,
      "name": "Vora",
      "names": {
        "zh": "沃拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.1333,
        "lng": 22.25
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 9020,
      "name": "Criuleni",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.2167,
        "lng": 29.1614
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 36939,
      "name": "Reutte",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.4833,
        "lng": 10.7167
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 37068,
      "name": "Tuxcueca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1553,
        "lng": -103.1847
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 37075,
      "name": "Vila Flor",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.3,
        "lng": -7.15
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 37170,
      "name": "Rinconada",
      "names": {
        "zh": "林科纳达"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -22.4403,
        "lng": -66.1672
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 36993,
      "name": "Gorom-Gorom",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 14.4446,
        "lng": -0.2345
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 37064,
      "name": "Derik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3644,
        "lng": 40.2689
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 37087,
      "name": "Seydisehir",
      "names": {
        "zh": "塞伊迪谢希尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.4183,
        "lng": 31.8506
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 37172,
      "name": "Kronoby",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.725,
        "lng": 23.0333
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 35883,
      "name": "Brioude",
      "names": {
        "zh": "布里尤德"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.2942,
        "lng": 3.3842
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 37108,
      "name": "San Juan Mixtepec",
      "names": {
        "zh": "圣胡安mixtepec",
        "it": "San Juan Mixttepec",
        "ja": "サンファンミックステープ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.3,
        "lng": -97.8333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 33925,
      "name": "Tenampa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.25,
        "lng": -96.8833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 37181,
      "name": "Palkane",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.3389,
        "lng": 24.2681
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8905,
      "name": "Litija",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0565,
        "lng": 14.8303
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 36991,
      "name": "Santiago Maravatio",
      "names": {
        "zh": "圣地亚哥马拉维塔奥",
        "th": "ซันติอาโก Maravatio"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.1667,
        "lng": -101.0
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 37216,
      "name": "Viitasaari",
      "names": {
        "zh": "维塔萨里"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.075,
        "lng": 25.8597
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 37168,
      "name": "Lityn",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.3255,
        "lng": 28.0872
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 37028,
      "name": "Enying",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9296,
        "lng": 18.243
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 36448,
      "name": "Hartberg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.2806,
        "lng": 15.97
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 37230,
      "name": "Ar Rawdah",
      "names": {
        "ja": "Rawdah",
        "th": "arrawadah"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.48,
        "lng": 47.27
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 36967,
      "name": "Ibrany",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.1282,
        "lng": 21.7135
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 34570,
      "name": "Villa Diaz Ordaz",
      "names": {
        "es": "Villa Díaz Ordaz"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.9981,
        "lng": -96.4314
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 37302,
      "name": "Rissa",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 63.6556,
        "lng": 10.0397
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 36550,
      "name": "Neufchateau",
      "names": {
        "es": "Neufchâteau",
        "zh": "讷沙托",
        "de": "Neufchâteau",
        "it": "Neufchâteau"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3556,
        "lng": 5.6961
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 9001,
      "name": "Trindade",
      "names": {
        "zh": "特林达迪"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 0.3,
        "lng": 6.6667
      },
      "country": "Sao Tome And Principe",
      "importance": 1
    },
    {
      "id": 37004,
      "name": "Ambert",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 45.5494,
        "lng": 3.7417
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 37315,
      "name": "Zavetnoye",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 47.1172,
        "lng": 43.8908
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 37169,
      "name": "Staryi Sambir",
      "names": {
        "de": "Staryst Sambir",
        "ja": "スタリリサンビール"
      },
      "countryCode": "UA",
      "latLng": {
        "lat": 49.4333,
        "lng": 23.0
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 35895,
      "name": "Kato Achaia",
      "names": {
        "zh": "凯托·凯西",
        "ja": "カトアカイア"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.15,
        "lng": 21.55
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 37337,
      "name": "Yemilchyne",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 50.8708,
        "lng": 27.8028
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 36985,
      "name": "Medzilaborce",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 49.2717,
        "lng": 21.9042
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 37467,
      "name": "Trysil",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 61.31,
        "lng": 12.315
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 37434,
      "name": "Pryazovske",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 46.7378,
        "lng": 35.6416
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 33910,
      "name": "Cosala",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 24.4125,
        "lng": -106.6917
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 37421,
      "name": "Sarayonu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.2661,
        "lng": 32.4064
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 37350,
      "name": "Ban Tak",
      "names": {
        "es": "Prohibir",
        "fr": "Banaliser",
        "zh": "班德",
        "it": "Bank tak",
        "ja": "禁止",
        "th": "บ้านตาก",
        "pt": "Banimento"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.05,
        "lng": 99.0742
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37476,
      "name": "Ishqoshim",
      "names": {},
      "countryCode": "TJ",
      "latLng": {
        "lat": 36.7248,
        "lng": 71.6133
      },
      "country": "Tajikistan",
      "importance": 2
    },
    {
      "id": 36283,
      "name": "Aridaia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.975,
        "lng": 22.0583
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 37483,
      "name": "Mut",
      "names": {
        "zh": "突变型"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.6458,
        "lng": 33.4375
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 37514,
      "name": "Haparanda",
      "names": {
        "zh": "哈帕兰达"
      },
      "countryCode": "SE",
      "latLng": {
        "lat": 65.8342,
        "lng": 24.117
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 37554,
      "name": "Juva",
      "names": {
        "zh": "尤瓦"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 61.8972,
        "lng": 27.8569
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 37454,
      "name": "Ipsala",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9181,
        "lng": 26.3831
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 37486,
      "name": "Ribeira de Pena",
      "names": {
        "ja": "リベイラデペナ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.5167,
        "lng": -7.8
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 37409,
      "name": "Ploce",
      "names": {
        "zh": "普洛切"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.0525,
        "lng": 17.4367
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 9038,
      "name": "Aluksne",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.4239,
        "lng": 27.0503
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 37092,
      "name": "Phanom Sarakham",
      "names": {
        "th": "ธาตุพนม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.7478,
        "lng": 101.3553
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 9039,
      "name": "Mae Hong Son",
      "names": {
        "es": "Mae hong hijo",
        "fr": "Mae hong fils",
        "zh": "毛洪子",
        "de": "Mae Hong Sohn",
        "ja": "メーホンソン",
        "th": "แม่ฮ่องสอน"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.3011,
        "lng": 97.97
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 37183,
      "name": "Horn",
      "names": {
        "es": "Cuerno",
        "fr": "Corne",
        "zh": "号角",
        "it": "Corno",
        "ja": "角",
        "th": "ฮอร์น",
        "pt": "Chifre"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.6653,
        "lng": 15.6558
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 37383,
      "name": "Belisce",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.6825,
        "lng": 18.4069
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 37582,
      "name": "Melhus",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 63.2856,
        "lng": 10.2781
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 36971,
      "name": "Avallon",
      "names": {
        "zh": "阿瓦隆"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.49,
        "lng": 3.9083
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 37484,
      "name": "Condom",
      "names": {
        "es": "Condón",
        "fr": "Préservatif",
        "zh": "避孕套",
        "de": "Kondom",
        "it": "Preservativo",
        "id": "Kondom",
        "ja": "コンドーム",
        "th": "ถุงยางอนามัย",
        "pt": "Preservativo"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 43.9575,
        "lng": 0.3725
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 37667,
      "name": "Silacayoapam",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.5,
        "lng": -98.1333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 37192,
      "name": "That Phanom",
      "names": {
        "es": "Ese phanom",
        "fr": "Ce phanom",
        "zh": "那个菲尼",
        "de": "Das Phanom",
        "it": "Quel Phanom",
        "id": "Phanom itu",
        "ja": "そのファノム",
        "th": "ธาตุนั่น",
        "pt": "Esse phanom"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.9364,
        "lng": 104.7103
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 9043,
      "name": "Bogatic",
      "names": {
        "zh": "博加蒂奇"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.8333,
        "lng": 19.4833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 37447,
      "name": "Lat Yao",
      "names": {
        "zh": "拉瑶",
        "th": "ลาดยาว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.7543,
        "lng": 99.7808
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37654,
      "name": "Bagrationovsk",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 54.3833,
        "lng": 20.6333
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 37656,
      "name": "Bacsalmas",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.1239,
        "lng": 19.3261
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 37838,
      "name": "Ingeniero Guillermo N. Juarez",
      "names": {
        "es": "Ingeniero Guillermo N. Juárez",
        "zh": "Ingeniero Guillermo N.Juarez",
        "ja": "Ingeniero Guillermo N. Guarez",
        "pt": "Ingenero Guillermo N. Juarez"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -23.9,
        "lng": -61.85
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 37506,
      "name": "Phu Khiao",
      "names": {
        "th": "ภูเขียว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.3682,
        "lng": 102.1344
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37739,
      "name": "Grayvoron",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 50.4833,
        "lng": 35.6667
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 818,
      "name": "Valletta",
      "names": {
        "es": "La Valeta",
        "fr": "La Valette",
        "zh": "瓦莱塔",
        "ja": "バレッタ",
        "th": "วัลเลตตา"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8978,
        "lng": 14.5125
      },
      "country": "Malta",
      "importance": 0
    },
    {
      "id": 37646,
      "name": "Dan Khun Thot",
      "names": {
        "zh": "丹坤塞",
        "th": "แดนขุนทอด"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2099,
        "lng": 101.7667
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37891,
      "name": "Portel",
      "names": {
        "zh": "波特尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.3,
        "lng": -7.7
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 37610,
      "name": "Pa Daet",
      "names": {
        "es": "Pa Ataet",
        "zh": "帕刀",
        "id": "Daet PA",
        "th": "ป่า",
        "pt": "Pa doet"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.505,
        "lng": 99.9925
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37779,
      "name": "Bua Chet",
      "names": {
        "es": "Chet bua",
        "id": "Bua"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.5326,
        "lng": 103.9301
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37946,
      "name": "Kittila",
      "names": {
        "zh": "基蒂莱",
        "it": "Kittilä",
        "id": "Kittilä"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 67.6531,
        "lng": 24.9114
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 37008,
      "name": "Saint-Girons",
      "names": {
        "zh": "圣 -  girons",
        "ja": "サン・girons",
        "th": "เซนต์ girons"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 42.9847,
        "lng": 1.1458
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 37914,
      "name": "Tervel",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.7486,
        "lng": 27.4065
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 37191,
      "name": "Louhans",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 46.6264,
        "lng": 5.2247
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 37465,
      "name": "Putnok",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.2936,
        "lng": 20.4367
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 37859,
      "name": "Lanchkhuti",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.0875,
        "lng": 42.0333
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 37961,
      "name": "Albania",
      "names": {
        "fr": "L\u0027Albanie",
        "zh": "阿尔巴尼亚",
        "de": "Albanien",
        "ja": "アルバニア",
        "th": "แอลเบเนีย",
        "pt": "Albânia"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 1.3289,
        "lng": -75.8783
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 37471,
      "name": "Turcianske Teplice",
      "names": {
        "fr": "Teplice Turcianske",
        "id": "Teplice Turcianse"
      },
      "countryCode": "SK",
      "latLng": {
        "lat": 48.8589,
        "lng": 18.8636
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 38002,
      "name": "Carrazeda de Anciaes",
      "names": {
        "zh": "Carrazeda de Ancianes",
        "it": "Carrazeda de Anciales",
        "id": "Carrazeda de ancies",
        "ja": "Carazeda de Anciaes",
        "pt": "Carrazeda de Ancias"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.25,
        "lng": -7.3
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 38069,
      "name": "General Toshevo",
      "names": {
        "fr": "Général Toshevo",
        "zh": "一般Toshevo",
        "it": "Generale Toshevo",
        "id": "Jenderal Toshevo",
        "ja": "一般的なToshevo",
        "th": "ทั่วไป Toshevo"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 43.7003,
        "lng": 28.0366
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 37419,
      "name": "Saint-Flour",
      "names": {
        "es": "Saint-harina",
        "zh": "圣 - 面粉",
        "de": "Saint-Mehl",
        "it": "Saint-Farina",
        "id": "Saint-tepung",
        "ja": "サン・小麦粉",
        "th": "เซนต์แป้ง",
        "pt": "Saint-farinha"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.0336,
        "lng": 3.0928
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 9050,
      "name": "Pasvalys",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 56.0611,
        "lng": 24.4028
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 37983,
      "name": "Sabrosa",
      "names": {
        "zh": "萨布罗萨"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.2651,
        "lng": -7.5751
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 37990,
      "name": "Tasco",
      "names": {
        "zh": "塔斯科"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.9092,
        "lng": -72.7814
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 9049,
      "name": "Sal Rei",
      "names": {
        "de": "Sals reif",
        "ja": "サルリーイ"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 16.176,
        "lng": -22.9171
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 37911,
      "name": "Hjo",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.3044,
        "lng": 14.2786
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 37112,
      "name": "Yaring",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 6.868,
        "lng": 101.3587
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 38137,
      "name": "Villa Cura Brochero",
      "names": {
        "ja": "ヴィラカーラブローチェロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -31.71,
        "lng": -65.02
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 37966,
      "name": "Phop Phra",
      "names": {
        "th": "ภูพี"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.3865,
        "lng": 98.6924
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37996,
      "name": "Tabuaco",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1167,
        "lng": -7.5667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 35814,
      "name": "Ixtapan del Oro",
      "names": {
        "ja": "イスタパンデルオーロ",
        "pt": "Ixapan del oro"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.2614,
        "lng": -100.2628
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 37794,
      "name": "Dalaba",
      "names": {
        "zh": "达拉巴"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.656,
        "lng": -12.272
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 37250,
      "name": "Nong Chik",
      "names": {
        "th": "หนองจิ๊ก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.8436,
        "lng": 101.1781
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 38147,
      "name": "Mantta",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.0292,
        "lng": 24.6236
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 38107,
      "name": "Stavyshche",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.3914,
        "lng": 30.1917
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 38159,
      "name": "Mahdalynivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.9147,
        "lng": 34.9154
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 37830,
      "name": "Nikhom Kham Soi",
      "names": {
        "th": "Nikhom Kham ซอย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.3749,
        "lng": 104.5551
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 38216,
      "name": "Pinarbasi",
      "names": {
        "zh": "珀纳尔巴舍"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.722,
        "lng": 36.391
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 38219,
      "name": "Svislach",
      "names": {},
      "countryCode": "BY",
      "latLng": {
        "lat": 53.0333,
        "lng": 24.1
      },
      "country": "Belarus",
      "importance": 2
    },
    {
      "id": 37925,
      "name": "Le Blanc",
      "names": {
        "zh": "勒碧",
        "ja": "ルブラン"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.6336,
        "lng": 1.0628
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 36235,
      "name": "Tuzamapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0833,
        "lng": -97.5667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38262,
      "name": "Astrakhan",
      "names": {
        "zh": "阿斯特拉罕",
        "de": "Astrachan",
        "ja": "アストラカン",
        "th": "มณฑลแอซทระแคน"
      },
      "countryCode": "KZ",
      "latLng": {
        "lat": 51.5228,
        "lng": 69.795
      },
      "country": "Kazakhstan",
      "importance": 2
    },
    {
      "id": 38046,
      "name": "Manavgat",
      "names": {
        "zh": "马纳夫加特",
        "ja": "マナビガト"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 36.7913,
        "lng": 31.4415
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 9052,
      "name": "Ghanzi",
      "names": {
        "zh": "杭济"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -21.7,
        "lng": 21.65
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 38128,
      "name": "Yayladagi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 35.9517,
        "lng": 36.0975
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 38238,
      "name": "Sindirgi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.24,
        "lng": 28.175
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35776,
      "name": "Huautepec",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.1,
        "lng": -96.7833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38171,
      "name": "Armamar",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 41.1167,
        "lng": -7.6833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 37738,
      "name": "Zarnovica",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.4833,
        "lng": 18.7167
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 38175,
      "name": "Krk",
      "names": {
        "zh": "克尔克"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.0261,
        "lng": 14.5756
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 38270,
      "name": "Teplyk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.6589,
        "lng": 29.7499
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 38327,
      "name": "Tammela",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8,
        "lng": 23.7667
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 38074,
      "name": "Sai Mun",
      "names": {
        "zh": "泗门",
        "ja": "サイムン",
        "th": "ทรายมูล"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.9477,
        "lng": 104.2101
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 38232,
      "name": "Florian",
      "names": {
        "zh": "弗洛里安",
        "ja": "フロリアン",
        "th": "ฟลอเรียน"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.8028,
        "lng": -73.9714
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 38338,
      "name": "Arbuzynka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.9067,
        "lng": 31.3131
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 36769,
      "name": "Altenkirchen",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 50.6872,
        "lng": 7.6456
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 38206,
      "name": "Zalaszentgrot",
      "names": {
        "zh": "佐洛圣格罗特"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9469,
        "lng": 17.0789
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 38381,
      "name": "Figueira de Castelo Rodrigo",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.9,
        "lng": -6.9667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 38405,
      "name": "Aradan",
      "names": {
        "zh": "阿拉丹"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 35.2497,
        "lng": 52.4933
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 37821,
      "name": "Loches",
      "names": {
        "zh": "洛什"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.1286,
        "lng": 0.9953
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 38451,
      "name": "Gole",
      "names": {
        "zh": "戈尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.7936,
        "lng": 42.6092
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 9056,
      "name": "Kavadarci",
      "names": {
        "zh": "卡瓦达尔齐"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4328,
        "lng": 22.0117
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 36852,
      "name": "Phan Thong",
      "names": {
        "zh": "藩丁",
        "de": "Phan-Tanga",
        "it": "Phan perizoma",
        "ja": "ファンのひも",
        "th": "พานทอง",
        "pt": "Tanga de phan"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 13.4681,
        "lng": 101.0953
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 821,
      "name": "Palikir",
      "names": {
        "zh": "帕利基尔"
      },
      "countryCode": "FM",
      "latLng": {
        "lat": 6.9178,
        "lng": 158.185
      },
      "country": "Federated States of Micronesia",
      "importance": 0
    },
    {
      "id": 38400,
      "name": "Donji Miholjac",
      "names": {
        "it": "Donji Mioljac",
        "ja": "Miholjac Donji"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.75,
        "lng": 18.15
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 37801,
      "name": "Wiset Chaichan",
      "names": {
        "fr": "Wiset Chaican",
        "zh": "聪明的Chaichan",
        "ja": "座席を担当します",
        "pt": "Chaichan da Wiset"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.589,
        "lng": 100.3482
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 38536,
      "name": "Khur",
      "names": {},
      "countryCode": "IR",
      "latLng": {
        "lat": 33.775,
        "lng": 55.0831
      },
      "country": "Iran",
      "importance": 2
    },
    {
      "id": 37453,
      "name": "Aszod",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.6514,
        "lng": 19.4894
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 37860,
      "name": "Halych",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.1128,
        "lng": 24.7217
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 38398,
      "name": "Ivankovo",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 45.2833,
        "lng": 18.6833
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 38611,
      "name": "Prinzapolka",
      "names": {},
      "countryCode": "NI",
      "latLng": {
        "lat": 13.4,
        "lng": -83.5667
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 35588,
      "name": "Villa Union",
      "names": {
        "fr": "Villa",
        "zh": "别墅联盟",
        "it": "Villaggio",
        "id": "Uni Villa",
        "ja": "ヴィラユニオン",
        "pt": "União da Villa"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.2167,
        "lng": -100.7167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38501,
      "name": "Bulanik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.095,
        "lng": 42.2667
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 37908,
      "name": "Si Prachan",
      "names": {
        "fr": "Si Prachaan",
        "it": "Si pracan",
        "th": "ศรีประต้น"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.6204,
        "lng": 100.1444
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37972,
      "name": "Szentlorinc",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.0422,
        "lng": 17.9856
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 38394,
      "name": "Saray",
      "names": {
        "zh": "萨拉伊",
        "th": "สะเหร่"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.4428,
        "lng": 27.9206
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 38566,
      "name": "Siuntio",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.1333,
        "lng": 24.2167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 37236,
      "name": "Montdidier",
      "names": {
        "zh": "蒙迪迪耶"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 49.6481,
        "lng": 2.57
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 9054,
      "name": "Espargos",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 16.7546,
        "lng": -22.9453
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 36997,
      "name": "Sobrance",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.746,
        "lng": 22.179
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 38577,
      "name": "Figueiro dos Vinhos",
      "names": {
        "ja": "figueirodos vinhos"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.9,
        "lng": -8.2667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 38424,
      "name": "San Andres Huaxpaltepec",
      "names": {
        "es": "San Andrés Huaxpaltepec",
        "zh": "圣安德里斯Huaxpaltepec"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3333,
        "lng": -97.9167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38648,
      "name": "Dusheti",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.0845,
        "lng": 44.6961
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 9062,
      "name": "Preili",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.2942,
        "lng": 26.7247
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 38680,
      "name": "Mantyharju",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.4181,
        "lng": 26.8792
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 9057,
      "name": "Backi Petrovac",
      "names": {
        "fr": "BASDI PETROVAC",
        "ja": "バックティペトロバック"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.3564,
        "lng": 19.5883
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 8748,
      "name": "Gizo",
      "names": {
        "zh": "吉佐"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -8.1,
        "lng": 156.85
      },
      "country": "Solomon Islands",
      "importance": 1
    },
    {
      "id": 9066,
      "name": "Siteki",
      "names": {},
      "countryCode": "SZ",
      "latLng": {
        "lat": -26.45,
        "lng": 31.95
      },
      "country": "Swaziland",
      "importance": 1
    },
    {
      "id": 38677,
      "name": "Adilcevaz",
      "names": {
        "zh": "阿迪尔杰瓦兹"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.8058,
        "lng": 42.7469
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 38529,
      "name": "Morahalom",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.2167,
        "lng": 19.8833
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 38690,
      "name": "Kuybyshevo",
      "names": {
        "zh": "古比雪沃"
      },
      "countryCode": "RU",
      "latLng": {
        "lat": 47.8183,
        "lng": 38.9083
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 38631,
      "name": "Sorgun",
      "names": {
        "zh": "索尔贡"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8086,
        "lng": 35.1831
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 9070,
      "name": "Puerto Baquerizo Moreno",
      "names": {
        "ja": "プエルトバキェリゾヨーエノ"
      },
      "countryCode": "EC",
      "latLng": {
        "lat": -0.9025,
        "lng": -89.6092
      },
      "country": "Ecuador",
      "importance": 1
    },
    {
      "id": 38637,
      "name": "Sachkhere",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.3389,
        "lng": 43.4039
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 38609,
      "name": "Heliconia",
      "names": {
        "zh": "海丽空苑"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.2106,
        "lng": -75.73
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 38525,
      "name": "Phanna Nikhom",
      "names": {
        "fr": "Phana nikhom",
        "ja": "パンナニコム",
        "th": "พรรณนานิคม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.3456,
        "lng": 103.8478
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 38406,
      "name": "Prathai",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 15.5341,
        "lng": 102.7175
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 38639,
      "name": "San Cristobal Acasaguastlan",
      "names": {
        "es": "San Cristóbal Acasaguastlan",
        "ja": "サンクリストバルAcasaguastlan"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.9189,
        "lng": -89.8722
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 38647,
      "name": "Devin",
      "names": {
        "zh": "德文"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 41.7432,
        "lng": 24.3967
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 38241,
      "name": "Encs",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.3306,
        "lng": 21.1217
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 38686,
      "name": "Tonya",
      "names": {
        "zh": "托尼亚",
        "ja": "トーニャ"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.8856,
        "lng": 39.2922
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 38699,
      "name": "Polygyros",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.3783,
        "lng": 23.4453
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 38769,
      "name": "Arapgir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.0411,
        "lng": 38.4953
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 37680,
      "name": "Balsthal",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3164,
        "lng": 7.6944
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 38380,
      "name": "Vodice",
      "names": {
        "ja": "ヴォディツェ"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 43.7506,
        "lng": 15.7789
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 38379,
      "name": "Silivri",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0739,
        "lng": 28.2464
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 38696,
      "name": "Rusko",
      "names": {
        "zh": "鲁斯科"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.5417,
        "lng": 22.2222
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 38428,
      "name": "Nong Song Hong",
      "names": {
        "es": "Nong canción hong",
        "zh": "浓松洪",
        "it": "Canto di nong di hong",
        "th": "หนองเพลงหง",
        "pt": "Nong canção hong"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.7359,
        "lng": 102.7909
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 38825,
      "name": "Chiquilistlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.0896,
        "lng": -103.8617
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38790,
      "name": "Bo",
      "names": {
        "zh": "博",
        "ja": "ボー",
        "th": "บ่อ"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 59.4572,
        "lng": 9.0314
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 38576,
      "name": "Gelnica",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.855,
        "lng": 20.9397
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 38805,
      "name": "Suhut",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5311,
        "lng": 30.5458
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 38783,
      "name": "Aralik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8728,
        "lng": 44.5192
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 38866,
      "name": "Concepcion de Buenos Aires",
      "names": {
        "es": "Concepción de Buenos Aires",
        "ja": "コンセプシオンデブエノスエアーズ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8667,
        "lng": -103.15
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 9077,
      "name": "Bac",
      "names": {
        "zh": "杆状",
        "ja": "のBAC",
        "th": "บัค"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.3886,
        "lng": 19.2353
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 38524,
      "name": "Rong Kham",
      "names": {
        "zh": "荣kham",
        "th": "จำขาม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.271,
        "lng": 103.7474
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 38712,
      "name": "Amasra",
      "names": {
        "zh": "阿马萨"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.7494,
        "lng": 32.3864
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 9065,
      "name": "Junik",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.4761,
        "lng": 20.2775
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9064,
      "name": "Viqueque",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.85,
        "lng": 126.3667
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 8745,
      "name": "Kolonia",
      "names": {},
      "countryCode": "FM",
      "latLng": {
        "lat": 6.9639,
        "lng": 158.2081
      },
      "country": "Federated States of Micronesia",
      "importance": 1
    },
    {
      "id": 9075,
      "name": "Cabrayil",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.4,
        "lng": 47.0261
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 38930,
      "name": "Ahtari",
      "names": {
        "id": "Ähtäri"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 62.55,
        "lng": 24.0694
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 38871,
      "name": "Klaebu",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 63.2761,
        "lng": 10.5142
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 38201,
      "name": "Messini",
      "names": {
        "zh": "麦西尼"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 37.05,
        "lng": 22.0
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 38768,
      "name": "Lom Kao",
      "names": {
        "ja": "ロムカオ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.8847,
        "lng": 101.2336
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37363,
      "name": "Prades",
      "names": {
        "zh": "普拉德斯"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 42.6172,
        "lng": 2.4219
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 38890,
      "name": "Dinguiraye",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 11.299,
        "lng": -10.726
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 38946,
      "name": "Sandnessjoen",
      "names": {
        "zh": "桑内舍恩"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 66.0167,
        "lng": 12.6333
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 9084,
      "name": "Zarasai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.7306,
        "lng": 26.2472
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 38003,
      "name": "Santa Maria Yucuhiti",
      "names": {
        "zh": "圣玛丽亚尤加利",
        "ja": "サンタマリアユーヒティ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0167,
        "lng": -97.7667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38977,
      "name": "Monchique",
      "names": {
        "zh": "蒙希克"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.3167,
        "lng": -8.6
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 38902,
      "name": "Spydeberg",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.6153,
        "lng": 11.0764
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 38691,
      "name": "Khok Samrong",
      "names": {
        "th": "โคกสำโรง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.0691,
        "lng": 100.7234
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 39002,
      "name": "Magdalena Tequisistlan",
      "names": {
        "fr": "Magdalena Tequisisistlan",
        "zh": "Magdalena Tequnisistlan",
        "it": "Magdalena Tequisisistlan",
        "id": "Tequisistlan magdalena",
        "pt": "Tequisistlan de Magdalena"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3992,
        "lng": -95.6033
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 9083,
      "name": "Sezana",
      "names": {
        "zh": "塞扎纳"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7,
        "lng": 13.85
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 38770,
      "name": "Sumeg",
      "names": {
        "de": "Sümeg"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 46.9775,
        "lng": 17.2817
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 38585,
      "name": "Takhtamukay",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 44.9214,
        "lng": 38.9958
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 822,
      "name": "Funafuti",
      "names": {
        "zh": "富纳富提",
        "ja": "フナフティ",
        "th": "ฟูนะฟูตี"
      },
      "countryCode": "TV",
      "latLng": {
        "lat": -8.5243,
        "lng": 179.1942
      },
      "country": "Tuvalu",
      "importance": 0
    },
    {
      "id": 9085,
      "name": "Zagorje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1342,
        "lng": 14.9942
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 39071,
      "name": "Chepes",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -31.35,
        "lng": -66.6
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 38976,
      "name": "Coloso",
      "names": {
        "zh": "科洛索"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 9.4942,
        "lng": -75.3525
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 38983,
      "name": "San Simon de Guerrero",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.0225,
        "lng": -100.0067
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8794,
      "name": "San Antonino Castillo Velasco",
      "names": {
        "ja": "サンアントニーノカスティーヨヴェラスコ",
        "th": "ซานอันโตนิโน Castillo Velasco"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.8031,
        "lng": -96.6839
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39092,
      "name": "Froland",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.5831,
        "lng": 8.5722
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 9067,
      "name": "Lucea",
      "names": {},
      "countryCode": "JM",
      "latLng": {
        "lat": 18.4509,
        "lng": -78.1736
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 38497,
      "name": "Carsibasi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0833,
        "lng": 39.3833
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 38655,
      "name": "Gyangze",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 28.9203,
        "lng": 89.5996
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 9060,
      "name": "Schaan",
      "names": {
        "zh": "沙恩"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.1667,
        "lng": 9.5167
      },
      "country": "Liechtenstein",
      "importance": 1
    },
    {
      "id": 9074,
      "name": "Glarus",
      "names": {
        "es": "Glaris",
        "zh": "格拉鲁斯",
        "ja": "グラールス",
        "th": "กลารุส"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.0333,
        "lng": 9.0667
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 39077,
      "name": "Volodarka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.52,
        "lng": 29.9153
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 39098,
      "name": "Akcadag",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3442,
        "lng": 37.9742
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39142,
      "name": "Porvenir",
      "names": {
        "zh": "波韦尼尔"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -53.2956,
        "lng": -70.3687
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 38339,
      "name": "Nogent-sur-Seine",
      "names": {
        "es": "Nogent-sur-Sena",
        "zh": "诺让塞纳河畔",
        "ja": "nogentシュルセーヌ",
        "th": "nogent-ซูร์แม่น้ำเซน"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.4936,
        "lng": 3.5025
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 39059,
      "name": "Penela",
      "names": {
        "zh": "佩内拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.0333,
        "lng": -8.3833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 38975,
      "name": "Alban",
      "names": {
        "zh": "阿尔万",
        "ja": "アルバン",
        "th": "เบลล่า"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.8783,
        "lng": -74.4383
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 39171,
      "name": "Murca",
      "names": {
        "zh": "穆尔萨"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.4,
        "lng": -7.45
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 38877,
      "name": "Pueai Noi",
      "names": {
        "es": "Peai noi",
        "fr": "Puaii no",
        "ja": "牡羊座ノイ",
        "th": "Pieai Noi",
        "pt": "Puai noi"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.8707,
        "lng": 102.9064
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 9013,
      "name": "Luqa",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8597,
        "lng": 14.4892
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 38968,
      "name": "Turkoglu",
      "names": {
        "zh": "特科格鲁"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.3816,
        "lng": 36.851
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39262,
      "name": "Dedoplists\u0027q\u0027aro",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.4652,
        "lng": 46.1045
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 39261,
      "name": "Eurajoki",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.2,
        "lng": 21.7333
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8999,
      "name": "Monaghan",
      "names": {
        "zh": "莫内根",
        "ja": "モナハン",
        "th": "โมนาฮัน"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 54.2479,
        "lng": -6.9708
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 39251,
      "name": "Vidigueira",
      "names": {
        "zh": "维迪盖拉"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.2,
        "lng": -7.8
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 39245,
      "name": "San Miguel Panixtlahuaca",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.2597,
        "lng": -97.3781
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38864,
      "name": "Maintirano",
      "names": {},
      "countryCode": "MG",
      "latLng": {
        "lat": -18.0666,
        "lng": 44.0167
      },
      "country": "Madagascar",
      "importance": 2
    },
    {
      "id": 39323,
      "name": "Kvinesdal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 58.3381,
        "lng": 7.0231
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 37717,
      "name": "Millingen aan de Rijn",
      "names": {
        "es": "Milingen aan de rijn",
        "ja": "ミレンゲンアアンデリジン"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 51.8667,
        "lng": 6.05
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 39318,
      "name": "Yildizeli",
      "names": {
        "zh": "耶尔德泽利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.8667,
        "lng": 36.5995
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39238,
      "name": "Lagodekhi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 41.8268,
        "lng": 46.2767
      },
      "country": "Georgia",
      "importance": 2
    },
    {
      "id": 39191,
      "name": "Sutamarchan",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.6206,
        "lng": -73.6214
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 39250,
      "name": "Kungsor",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 59.4227,
        "lng": 16.1059
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 39088,
      "name": "Montmorillon",
      "names": {
        "zh": "蒙莫里永"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 46.4261,
        "lng": 0.8708
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 9095,
      "name": "Kupiskis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.8333,
        "lng": 24.9667
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 39161,
      "name": "Wanon Niwat",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 17.6358,
        "lng": 103.7549
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 39362,
      "name": "Cifteler",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.3928,
        "lng": 31.0436
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39311,
      "name": "Mucuchies",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 8.75,
        "lng": -70.9167
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 9097,
      "name": "Idrija",
      "names": {
        "zh": "伊德里亚"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0025,
        "lng": 14.0275
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 39415,
      "name": "Aljezur",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 37.3178,
        "lng": -8.8
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 39349,
      "name": "Ahlat",
      "names": {
        "zh": "阿赫拉特"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7542,
        "lng": 42.4877
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8903,
      "name": "Santa Maria del Oro",
      "names": {
        "zh": "圣玛丽亚del Oro",
        "ja": "サンタマリアデルオーロ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.9333,
        "lng": -105.3667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 34725,
      "name": "Dzilam Gonzalez",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.28,
        "lng": -88.9292
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39422,
      "name": "Murovani Kurylivtsi",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.7222,
        "lng": 27.515
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 39501,
      "name": "Surnadal",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 62.9475,
        "lng": 8.77
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 38179,
      "name": "Ihsim",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.72,
        "lng": 36.5608
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 39445,
      "name": "Visegrad",
      "names": {
        "zh": "维谢格拉德"
      },
      "countryCode": "BA",
      "latLng": {
        "lat": 43.7833,
        "lng": 19.2833
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 9069,
      "name": "Rutana",
      "names": {
        "zh": "鲁塔纳"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.9236,
        "lng": 30.0061
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 39206,
      "name": "Kazimierza Wielka",
      "names": {},
      "countryCode": "PL",
      "latLng": {
        "lat": 50.2736,
        "lng": 20.4844
      },
      "country": "Poland",
      "importance": 2
    },
    {
      "id": 39570,
      "name": "Alandroal",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 38.7,
        "lng": -7.4
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 9105,
      "name": "Balvi",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.1292,
        "lng": 27.2667
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 39252,
      "name": "Kao Liao",
      "names": {
        "zh": "高廖",
        "ja": "カオリオ",
        "th": "Kao เหลียว"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.8506,
        "lng": 100.0792
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 39112,
      "name": "Los Reyes",
      "names": {
        "zh": "洛杉矶雷耶斯",
        "ja": "ロスレイ",
        "th": "ลอสเรเยส"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6731,
        "lng": -97.0456
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39575,
      "name": "Verkhovyna",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.1517,
        "lng": 24.8136
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 39306,
      "name": "Psachna",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.5828,
        "lng": 23.6328
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 39487,
      "name": "Can",
      "names": {
        "es": "Puedo",
        "fr": "Pouvez",
        "zh": "能够",
        "de": "Können",
        "it": "Può",
        "id": "Bisa",
        "ja": "できる",
        "th": "สามารถ",
        "pt": "Posso"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.0323,
        "lng": 27.053
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39464,
      "name": "Vargarda",
      "names": {},
      "countryCode": "SE",
      "latLng": {
        "lat": 58.0341,
        "lng": 12.8083
      },
      "country": "Sweden",
      "importance": 2
    },
    {
      "id": 8879,
      "name": "Guadalupe y Calvo",
      "names": {
        "zh": "瓜达卢佩y calvo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 26.095,
        "lng": -106.9644
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39663,
      "name": "Chernihivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.1942,
        "lng": 36.2023
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 39517,
      "name": "`Assal al Ward",
      "names": {
        "zh": "“assal ar病房",
        "it": "`Assal al reparto",
        "ja": "`asal al区",
        "pt": "`Assal al enfermaria"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 33.8658,
        "lng": 36.4133
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 39546,
      "name": "Catalca",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.15,
        "lng": 28.4503
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 9110,
      "name": "Lorengau",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -2.0208,
        "lng": 147.2667
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 39463,
      "name": "Dikili",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.071,
        "lng": 26.8902
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39656,
      "name": "Valer",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.4725,
        "lng": 10.9169
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 39231,
      "name": "Lesparre-Medoc",
      "names": {
        "es": "Lesparre-médoc",
        "fr": "Lesparre-Médoc",
        "zh": "lesparre-梅多克",
        "ja": "-medoc lesparre"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 45.3069,
        "lng": -0.9378
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 820,
      "name": "Grand Turk",
      "names": {
        "zh": "大土耳其人",
        "it": "Grande turco",
        "ja": "グランドトルコ"
      },
      "countryCode": "TC",
      "latLng": {
        "lat": 21.4664,
        "lng": -71.136
      },
      "country": "Turks And Caicos Islands",
      "importance": 0
    },
    {
      "id": 824,
      "name": "Lobamba",
      "names": {},
      "countryCode": "SZ",
      "latLng": {
        "lat": -26.4465,
        "lng": 31.2064
      },
      "country": "Swaziland",
      "importance": 0
    },
    {
      "id": 9101,
      "name": "Ranillug",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.492,
        "lng": 21.559
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 39673,
      "name": "Sveio",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.5736,
        "lng": 5.3633
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 39352,
      "name": "Buharkent",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9646,
        "lng": 28.7425
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39638,
      "name": "Tancoco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2861,
        "lng": -97.7906
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39485,
      "name": "Mezokovacshaza",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 46.4119,
        "lng": 20.9108
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 39730,
      "name": "Veselynove",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.3583,
        "lng": 31.2397
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 39512,
      "name": "Moeiwadi",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.39,
        "lng": 104.1544
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 39674,
      "name": "Civril",
      "names": {
        "zh": "奇夫里尔"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.3014,
        "lng": 29.7404
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39766,
      "name": "Debesy",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 57.65,
        "lng": 53.8167
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 39728,
      "name": "Incesu",
      "names": {
        "zh": "因杰苏"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6222,
        "lng": 35.1847
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39460,
      "name": "Huai Mek",
      "names": {
        "zh": "淮梅克",
        "th": "ห้วยเม็ก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.5897,
        "lng": 103.2356
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 39447,
      "name": "Borabue",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 16.037,
        "lng": 103.1231
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 37098,
      "name": "Tatatila",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.7,
        "lng": -97.1167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39621,
      "name": "Varshets",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 43.1955,
        "lng": 23.2882
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 39775,
      "name": "Dobrovelychkivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.35,
        "lng": 31.1667
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 39755,
      "name": "Tomislavgrad",
      "names": {},
      "countryCode": "BA",
      "latLng": {
        "lat": 43.7135,
        "lng": 17.2266
      },
      "country": "Bosnia And Herzegovina",
      "importance": 2
    },
    {
      "id": 9078,
      "name": "Appenzell",
      "names": {
        "zh": "阿彭策尔"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.3306,
        "lng": 9.4086
      },
      "country": "Switzerland",
      "importance": 1
    },
    {
      "id": 38865,
      "name": "Megalopoli",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.4,
        "lng": 22.1333
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 39808,
      "name": "Acigol",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5503,
        "lng": 34.5092
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39486,
      "name": "San Juan Numi",
      "names": {
        "es": "San Juan Numbi",
        "zh": "圣胡安Numi",
        "ja": "サンファン・ナンディ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.4,
        "lng": -97.7
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39739,
      "name": "Metapa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 14.8333,
        "lng": -92.1833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39856,
      "name": "Viana do Alentejo",
      "names": {
        "zh": "Viana做Alentejo"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.3342,
        "lng": -8.0014
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 9027,
      "name": "Stratford",
      "names": {
        "zh": "斯特拉特福",
        "ja": "ストラットフォード",
        "th": "สแตรทฟอร์ด"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -39.341,
        "lng": 174.284
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 37416,
      "name": "Echallens",
      "names": {},
      "countryCode": "CH",
      "latLng": {
        "lat": 46.6333,
        "lng": 6.6333
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 39695,
      "name": "Phayuha Khiri",
      "names": {
        "ja": "ファヤハKhiri",
        "th": "พยัยคิริ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.4553,
        "lng": 100.1353
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 38294,
      "name": "Neustadt",
      "names": {
        "zh": "诺伊施塔特",
        "ja": "ノイシュタット",
        "th": "ตัดท์"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 49.7306,
        "lng": 12.1706
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 39869,
      "name": "Popilnia",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.9458,
        "lng": 29.4594
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 39744,
      "name": "Tirebolu",
      "names": {
        "zh": "蒂雷博卢"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0069,
        "lng": 38.8139
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39947,
      "name": "Oleiros",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 39.9167,
        "lng": -7.9167
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 39745,
      "name": "Ao Luek",
      "names": {
        "de": "Ao Leuk",
        "th": "อ่าวลุย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.378,
        "lng": 98.7211
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 39914,
      "name": "Pong",
      "names": {
        "zh": "乒乓",
        "ja": "ポン",
        "th": "พงษ์"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.1507,
        "lng": 100.2652
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 35741,
      "name": "Colipa",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9167,
        "lng": -96.7
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39890,
      "name": "Doganhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.145,
        "lng": 31.6767
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39839,
      "name": "Tamsweg",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 47.1167,
        "lng": 13.8
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 39091,
      "name": "Bascharage",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.5667,
        "lng": 5.9167
      },
      "country": "Luxembourg",
      "importance": 2
    },
    {
      "id": 38267,
      "name": "Myrina",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 39.8797,
        "lng": 25.0742
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 39036,
      "name": "Tapa",
      "names": {
        "zh": "塔帕",
        "th": "ปาสร้อย"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 59.2644,
        "lng": 25.9628
      },
      "country": "Estonia",
      "importance": 2
    },
    {
      "id": 39702,
      "name": "Otatitlan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.1772,
        "lng": -96.0342
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38314,
      "name": "Altkirch",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6231,
        "lng": 7.2394
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 9120,
      "name": "Gospic",
      "names": {
        "zh": "戈斯皮奇"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 44.5469,
        "lng": 15.3744
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 39483,
      "name": "Dawran ad Daydah",
      "names": {
        "zh": "Dawran广告Daydah",
        "de": "Dawran-Anzeigen-Daydah",
        "ja": "Dawran ADデイダ",
        "pt": "Dawran adydah"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.7608,
        "lng": 44.1904
      },
      "country": "Yemen",
      "importance": 2
    },
    {
      "id": 39484,
      "name": "Poltar",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.4306,
        "lng": 19.7975
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 36097,
      "name": "Tixpehual",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.9778,
        "lng": -89.4417
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39977,
      "name": "Chitaraque",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0283,
        "lng": -73.4469
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 39684,
      "name": "Non Sila",
      "names": {
        "es": "No sila",
        "zh": "非SILA",
        "de": "Non-Sila",
        "id": "Non-sila",
        "ja": "非シラ",
        "th": "ไม่ใช่ศิลา",
        "pt": "Não sila"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.9617,
        "lng": 102.6911
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 40072,
      "name": "Penamacor",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.1667,
        "lng": -7.1667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 39583,
      "name": "Cestica",
      "names": {},
      "countryCode": "HR",
      "latLng": {
        "lat": 46.37,
        "lng": 16.12
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 40021,
      "name": "Avanos",
      "names": {
        "zh": "阿瓦诺斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.715,
        "lng": 34.8467
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40078,
      "name": "Novhorodka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.3565,
        "lng": 32.6466
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 9123,
      "name": "Cacheu",
      "names": {},
      "countryCode": "GW",
      "latLng": {
        "lat": 12.278,
        "lng": -16.165
      },
      "country": "Guinea-Bissau",
      "importance": 1
    },
    {
      "id": 39475,
      "name": "Briey",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 49.2486,
        "lng": 5.9394
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 39807,
      "name": "Ku Kaeo",
      "names": {
        "es": "Kaeo",
        "de": "Ku kaoo"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.182,
        "lng": 103.1612
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 39572,
      "name": "Martonvasar",
      "names": {
        "zh": "毛尔通瓦沙尔"
      },
      "countryCode": "HU",
      "latLng": {
        "lat": 47.314,
        "lng": 18.7885
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 40064,
      "name": "Sernancelhe",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.9,
        "lng": -7.4833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 39972,
      "name": "Mezocsat",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.8233,
        "lng": 20.9028
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 38474,
      "name": "Elva",
      "names": {
        "zh": "萧亚轩"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.2275,
        "lng": 26.4158
      },
      "country": "Estonia",
      "importance": 2
    },
    {
      "id": 823,
      "name": "Vaduz",
      "names": {
        "zh": "瓦杜兹",
        "th": "วาดุซ"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.1397,
        "lng": 9.5219
      },
      "country": "Liechtenstein",
      "importance": 0
    },
    {
      "id": 39958,
      "name": "Saraykent",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.6936,
        "lng": 35.51
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 38723,
      "name": "Kayl",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.4864,
        "lng": 6.0392
      },
      "country": "Luxembourg",
      "importance": 2
    },
    {
      "id": 40151,
      "name": "Villa Huidobro",
      "names": {
        "zh": "卢迪波罗别墅",
        "ja": "ヴィラフイドブロ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -34.8333,
        "lng": -64.5833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 39871,
      "name": "Patnos",
      "names": {
        "zh": "帕特诺斯"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.2337,
        "lng": 42.8637
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40097,
      "name": "Nakkila",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.3653,
        "lng": 22.0042
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 9094,
      "name": "Echternach",
      "names": {
        "zh": "埃希特纳赫"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.8117,
        "lng": 6.4217
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 9128,
      "name": "Kerema",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -7.927,
        "lng": 145.838
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 40195,
      "name": "San Sebastian del Oeste",
      "names": {
        "ja": "サンセバスチャンデルオースト"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 20.8511,
        "lng": -104.819
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40215,
      "name": "Vadso",
      "names": {
        "it": "Vadsø"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 70.0733,
        "lng": 29.7497
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 40207,
      "name": "Huejucar",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 22.3591,
        "lng": -103.2108
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 36569,
      "name": "Saue",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3231,
        "lng": 24.5622
      },
      "country": "Estonia",
      "importance": 2
    },
    {
      "id": 40245,
      "name": "Kangasniemi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.99,
        "lng": 26.6417
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 34944,
      "name": "Mapimi",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 25.8331,
        "lng": -103.8478
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38920,
      "name": "Steinfort",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.66,
        "lng": 5.9156
      },
      "country": "Luxembourg",
      "importance": 2
    },
    {
      "id": 37906,
      "name": "Rumelange",
      "names": {},
      "countryCode": "LU",
      "latLng": {
        "lat": 49.4597,
        "lng": 6.0306
      },
      "country": "Luxembourg",
      "importance": 2
    },
    {
      "id": 40255,
      "name": "Atarjea",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2678,
        "lng": -99.7183
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40130,
      "name": "Urgup",
      "names": {
        "ja": "ユルギュップ",
        "th": "เออร์กัป"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.6294,
        "lng": 34.9119
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 9125,
      "name": "Babak",
      "names": {
        "zh": "巴巴克"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.1111,
        "lng": 45.4114
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 39566,
      "name": "Phachi",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 14.4453,
        "lng": 100.7367
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 36598,
      "name": "Interlaken",
      "names": {
        "zh": "茵特拉根",
        "ja": "インターラーケン",
        "th": "ในอินเตอร์เลเคน"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 46.6881,
        "lng": 7.8646
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 40256,
      "name": "Savastepe",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.3839,
        "lng": 27.6547
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40284,
      "name": "Oguzeli",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 36.965,
        "lng": 37.5092
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35310,
      "name": "Rosales",
      "names": {
        "zh": "罗萨莱斯"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 28.2,
        "lng": -105.55
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40185,
      "name": "Zipacon",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 4.76,
        "lng": -74.3797
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 40240,
      "name": "Yenipazar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8236,
        "lng": 28.1955
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 39193,
      "name": "Al Qutaylibiyah",
      "names": {
        "ja": "アルQutaylibiyah",
        "th": "อัล Qutaylibiyah"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 35.2889,
        "lng": 36.0153
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 8891,
      "name": "Panindicuaro",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.9828,
        "lng": -101.7606
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40246,
      "name": "Golhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.1481,
        "lng": 29.512
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 36009,
      "name": "Sotuta",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.5968,
        "lng": -89.0082
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40462,
      "name": "San Nicolas Tolentino",
      "names": {
        "zh": "圣尼古拉斯托莱蒂诺",
        "ja": "サンニコラストレンティーノ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.2489,
        "lng": -100.5525
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40460,
      "name": "Malax",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.9417,
        "lng": 21.55
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 40455,
      "name": "Inga",
      "names": {
        "zh": "印加",
        "ja": "インガ"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.0458,
        "lng": 24.0056
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 40468,
      "name": "Dabancheng",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.3552,
        "lng": 88.306
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 9121,
      "name": "Sisimiut",
      "names": {},
      "countryCode": "GL",
      "latLng": {
        "lat": 66.9389,
        "lng": -53.6722
      },
      "country": "Greenland",
      "importance": 1
    },
    {
      "id": 9134,
      "name": "Kazlu Ruda",
      "names": {
        "ja": "ラーダkazlu ruda"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 54.7492,
        "lng": 23.4865
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 39900,
      "name": "Melk",
      "names": {
        "zh": "修道院"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.2269,
        "lng": 15.3439
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 40429,
      "name": "Tomashpil",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.5471,
        "lng": 28.5157
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 9014,
      "name": "Salavan",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 15.7167,
        "lng": 106.4167
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 40364,
      "name": "Beydag",
      "names": {
        "zh": "贝伊达"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 38.0869,
        "lng": 28.2089
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40535,
      "name": "Kannus",
      "names": {
        "zh": "坎努斯"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.9017,
        "lng": 23.9151
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 9063,
      "name": "Mamushe",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3167,
        "lng": 20.7167
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9136,
      "name": "Caazapa",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -26.2,
        "lng": -56.38
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 39201,
      "name": "Kivioli",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 59.3517,
        "lng": 26.9611
      },
      "country": "Estonia",
      "importance": 2
    },
    {
      "id": 40276,
      "name": "Waidhofen an der Thaya",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.8167,
        "lng": 15.2833
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 39332,
      "name": "Xylokastro",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0667,
        "lng": 22.6333
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 38970,
      "name": "Ra\u0027s al Khashufah",
      "names": {
        "fr": "Ra Al Khashaufah",
        "zh": "ra的al khashufah",
        "ja": "RAのアルカシュファ",
        "pt": "Ra al Khasfá"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.8833,
        "lng": 36.1333
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 9139,
      "name": "Moletai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.2333,
        "lng": 25.4167
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9096,
      "name": "Crnomelj",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5711,
        "lng": 15.1889
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 37678,
      "name": "San Jeronimo Tlacochahuaya",
      "names": {
        "es": "San Jerónimo Tlacochahuaya",
        "fr": "San Jeronimo Tlacocochahuaya",
        "zh": "San Jeronimo Tlacochahauaya",
        "ja": "サンエロニモトラコシャヤ",
        "pt": "San Jeronimo Tlacochauaya"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.0083,
        "lng": -96.5556
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40490,
      "name": "Siatista",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.2564,
        "lng": 21.5522
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 40674,
      "name": "Ardesen",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.1914,
        "lng": 40.9857
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40014,
      "name": "Ilava",
      "names": {},
      "countryCode": "SK",
      "latLng": {
        "lat": 48.9994,
        "lng": 18.2361
      },
      "country": "Slovakia",
      "importance": 2
    },
    {
      "id": 40656,
      "name": "Naco",
      "names": {
        "zh": "碳酸钠"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 31.3269,
        "lng": -109.9478
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40541,
      "name": "Chiquiza",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.61,
        "lng": -73.4889
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 40683,
      "name": "General Conesa",
      "names": {
        "fr": "Consa général",
        "zh": "一般科塞萨",
        "it": "Conesa generale",
        "ja": "一般的なコナサ",
        "th": "โคซ่าทั่วไป",
        "pt": "Conesa geral"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.1,
        "lng": -64.4333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 40663,
      "name": "Teuva",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.4861,
        "lng": 21.7472
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 40689,
      "name": "San Antonio de los Cobres",
      "names": {
        "de": "San Antonio de los Copfres",
        "ja": "サンアントニオデロスコブレス",
        "th": "ซานอันโตนิโอเดอลอสโคโบ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -24.2256,
        "lng": -66.3192
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 40413,
      "name": "Mali",
      "names": {
        "es": "Malí",
        "zh": "马里",
        "ja": "マリ",
        "th": "มาลี"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 12.084,
        "lng": -12.301
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 37799,
      "name": "Atengo",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2167,
        "lng": -104.0833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40366,
      "name": "Szecseny",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.0808,
        "lng": 19.5194
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 40643,
      "name": "Aguiar da Beira",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.818,
        "lng": -7.5414
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 40518,
      "name": "Golega",
      "names": {
        "zh": "古勒冈"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 39.4,
        "lng": -8.4833
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 40724,
      "name": "Macachin",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -37.1377,
        "lng": -63.662
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9086,
      "name": "Muramvya",
      "names": {
        "zh": "穆拉姆维亚"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.25,
        "lng": 29.6
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 40368,
      "name": "Xoxocotla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.65,
        "lng": -97.15
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 825,
      "name": "Avarua",
      "names": {
        "zh": "阿瓦鲁阿"
      },
      "countryCode": "CK",
      "latLng": {
        "lat": -21.207,
        "lng": -159.771
      },
      "country": "Cook Islands",
      "importance": 0
    },
    {
      "id": 40734,
      "name": "Ko Chang",
      "names": {
        "ja": "コチャン",
        "th": "เกาะช้าง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 12.1033,
        "lng": 102.3522
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 40456,
      "name": "Villanueva",
      "names": {
        "zh": "维拉纽瓦"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.6719,
        "lng": -73.1747
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 40759,
      "name": "Hisya\u0027",
      "names": {
        "es": "Hisya \u0027",
        "fr": "Hisya \u0027",
        "de": "Hisya \u0027",
        "it": "Hisya \u0027",
        "id": "Hisya \u0027",
        "ja": "hisya \u0027",
        "th": "hisya \u0027",
        "pt": "Hisya \u0027"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 34.4108,
        "lng": 36.7586
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 9122,
      "name": "Wiltz",
      "names": {
        "zh": "维尔茨"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.9689,
        "lng": 5.9319
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 40789,
      "name": "Jokioinen",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.8042,
        "lng": 23.4861
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 9140,
      "name": "Thaba-Tseka",
      "names": {},
      "countryCode": "LS",
      "latLng": {
        "lat": -29.522,
        "lng": 28.6084
      },
      "country": "Lesotho",
      "importance": 1
    },
    {
      "id": 40701,
      "name": "Cermik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.1353,
        "lng": 39.456
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40720,
      "name": "Caramanta",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5467,
        "lng": -75.6442
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 40867,
      "name": "Skydra",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 40.7667,
        "lng": 22.15
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 40930,
      "name": "Kusel",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 49.5347,
        "lng": 7.3981
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 39738,
      "name": "Mondorf-les-Bains",
      "names": {
        "zh": "蒙多夫-莱斯班",
        "ja": "mondorf・レ・バン",
        "th": "mondorf-เลสแบงส์"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.5069,
        "lng": 6.2806
      },
      "country": "Luxembourg",
      "importance": 2
    },
    {
      "id": 40543,
      "name": "Kae Dam",
      "names": {
        "es": "Kae presa",
        "zh": "凯恩大坝",
        "de": "Kae Damm",
        "id": "Bendungan kaus",
        "ja": "Kaeダム",
        "th": "เขื่อน",
        "pt": "Dam Kae"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.0233,
        "lng": 103.3862
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 40935,
      "name": "Sebezh",
      "names": {},
      "countryCode": "RU",
      "latLng": {
        "lat": 56.2833,
        "lng": 28.4833
      },
      "country": "Russia",
      "importance": 2
    },
    {
      "id": 8893,
      "name": "Palo Alto",
      "names": {
        "zh": "帕洛阿尔托",
        "ja": "パロアルト"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 21.9167,
        "lng": -101.9667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40474,
      "name": "Commercy",
      "names": {
        "zh": "科梅尔西"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.7642,
        "lng": 5.5917
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 40947,
      "name": "Haymana",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.4311,
        "lng": 32.4956
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 8930,
      "name": "Cospicua",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8822,
        "lng": 14.5222
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 40573,
      "name": "Seelow",
      "names": {},
      "countryCode": "DE",
      "latLng": {
        "lat": 52.5167,
        "lng": 14.3831
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 40810,
      "name": "Valle de Juarez",
      "names": {
        "es": "Valle de Juárez",
        "ja": "ヴァレデフアレス",
        "th": "วัณโรค"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.8667,
        "lng": -102.8167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40962,
      "name": "Ourique",
      "names": {
        "zh": "欧里克"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.65,
        "lng": -8.225
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 40673,
      "name": "Lanusei",
      "names": {
        "zh": "拉努塞伊"
      },
      "countryCode": "IT",
      "latLng": {
        "lat": 39.8787,
        "lng": 9.5415
      },
      "country": "Italy",
      "importance": 2
    },
    {
      "id": 40948,
      "name": "Tota",
      "names": {
        "zh": "托塔"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5603,
        "lng": -72.9861
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 40609,
      "name": "Kham Sakae Saeng",
      "names": {
        "ja": "カムサカエーベ",
        "th": "คำสากราก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.3322,
        "lng": 102.1728
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 40988,
      "name": "General Bravo",
      "names": {
        "fr": "Général bravo",
        "zh": "一般布拉沃",
        "de": "Allgemeiner Bravo",
        "it": "Generale Bravo",
        "ja": "一般的なブラボー",
        "th": "นายพลไชโย"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.8,
        "lng": -99.1667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40923,
      "name": "Ixtapangajoya",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 17.4972,
        "lng": -93.0017
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40894,
      "name": "Hobol",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.5931,
        "lng": 10.9458
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 40895,
      "name": "El Limon",
      "names": {
        "de": "El-Limoner"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 19.75,
        "lng": -104.0167
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38961,
      "name": "Munchwilen",
      "names": {
        "fr": "Münchwilen",
        "de": "Münchwilen",
        "it": "Münchwilen"
      },
      "countryCode": "CH",
      "latLng": {
        "lat": 47.4815,
        "lng": 8.9924
      },
      "country": "Switzerland",
      "importance": 2
    },
    {
      "id": 40630,
      "name": "Phanom Thuan",
      "names": {
        "ja": "ファノムトゥアン",
        "th": "ธาตุพนม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.1315,
        "lng": 99.7029
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 40374,
      "name": "Gmund",
      "names": {
        "es": "Gmünd",
        "fr": "Gmünd"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.7667,
        "lng": 14.9833
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 40862,
      "name": "Ayvacik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0036,
        "lng": 36.6319
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 41022,
      "name": "Totontepec Villa de Morelos",
      "names": {
        "de": "Totontecec Villa de Morelos"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.2567,
        "lng": -96.0269
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 39530,
      "name": "Cerkezkoy",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.2894,
        "lng": 28.0046
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 41023,
      "name": "Acula",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.5167,
        "lng": -95.7833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 41033,
      "name": "Trogstad",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 59.6514,
        "lng": 11.3397
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 9141,
      "name": "Qal`ah-ye Now",
      "names": {
        "es": "Qal`ah-ye ahora",
        "fr": "Qal`ah-ye maintenant",
        "zh": "Qal`ah-ye现在",
        "de": "Qal`ah-ye jetzt",
        "it": "QAL B`ah-ye ora",
        "id": "Qal`ah-ye sekarang",
        "ja": "Qal`ah-Ye今",
        "th": "qal`ah-ye ตอนนี้",
        "pt": "Qal`ah-ye agora"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 34.99,
        "lng": 63.12
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 39997,
      "name": "San Andres Huayapam",
      "names": {
        "es": "San Andrés Huayapam",
        "zh": "圣安德雷斯·沃亚塔普姆",
        "ja": "サンアンドレスhuayapam"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 17.1,
        "lng": -96.6667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 41148,
      "name": "Ilomantsi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.6667,
        "lng": 30.9333
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 37342,
      "name": "Gomez Farias",
      "names": {
        "zh": "戈麦斯法罗斯",
        "ja": "ゴメスファレアス"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 29.3583,
        "lng": -107.7361
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 41118,
      "name": "Krumovgrad",
      "names": {},
      "countryCode": "BG",
      "latLng": {
        "lat": 41.4709,
        "lng": 25.6546
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 41174,
      "name": "Mapire",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 7.7411,
        "lng": -64.7107
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 9053,
      "name": "Polva",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 58.0536,
        "lng": 27.0556
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 41016,
      "name": "Darda",
      "names": {
        "zh": "达尔达"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.6261,
        "lng": 18.6925
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 41086,
      "name": "Pishchanka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2058,
        "lng": 28.8889
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 41131,
      "name": "Ayancik",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.95,
        "lng": 34.5833
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40615,
      "name": "Espiye",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 40.95,
        "lng": 38.7333
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40062,
      "name": "El Rosario",
      "names": {
        "ja": "エルロザリオ"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 11.8333,
        "lng": -86.1667
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 41173,
      "name": "Bratske",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.8636,
        "lng": 31.5781
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 40436,
      "name": "Cochem",
      "names": {
        "zh": "科赫姆",
        "ja": "コッヘム"
      },
      "countryCode": "DE",
      "latLng": {
        "lat": 50.1469,
        "lng": 7.1667
      },
      "country": "Germany",
      "importance": 2
    },
    {
      "id": 41204,
      "name": "Uzumlu",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 39.71,
        "lng": 39.7017
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 41207,
      "name": "Ruokolahti",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.2917,
        "lng": 28.8167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 40992,
      "name": "Kisber",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 47.5017,
        "lng": 18.0267
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 40853,
      "name": "Sam Sung",
      "names": {
        "fr": "Sam chanté",
        "zh": "山姆唱",
        "it": "Sam cantato",
        "th": "แซมร้อง",
        "pt": "Sam cantado"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.5415,
        "lng": 103.0796
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41243,
      "name": "Lampazos de Naranjo",
      "names": {
        "zh": "Lamagazos de Naranjo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 27.025,
        "lng": -100.5056
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 8934,
      "name": "Nombre de Dios",
      "names": {
        "zh": "Nombrede dios"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 23.85,
        "lng": -104.2333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 9132,
      "name": "Partesh",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.4019,
        "lng": 21.4336
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 40384,
      "name": "Darkush",
      "names": {},
      "countryCode": "SY",
      "latLng": {
        "lat": 35.9925,
        "lng": 36.3931
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 41058,
      "name": "Surmene",
      "names": {
        "zh": "叙尔梅内"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 40.9097,
        "lng": 40.1058
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 35957,
      "name": "Texhuacan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.6167,
        "lng": -97.0333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40387,
      "name": "Bang Sai",
      "names": {
        "zh": "邦西",
        "th": "บางไทร"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 14.3347,
        "lng": 100.3037
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 40929,
      "name": "San Miguel Panan",
      "names": {
        "zh": "圣米格尔·帕南",
        "ja": "サンミゲルパナン"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.5333,
        "lng": -91.3667
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 37989,
      "name": "Penon Blanco",
      "names": {
        "ja": "ペノンブランコ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 24.7833,
        "lng": -104.0333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40987,
      "name": "Seferhisar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.1975,
        "lng": 26.8388
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40502,
      "name": "Renswoude",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 52.0739,
        "lng": 5.5406
      },
      "country": "Netherlands",
      "importance": 2
    },
    {
      "id": 35707,
      "name": "Tlilapan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 18.8,
        "lng": -97.1
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40949,
      "name": "Szikszo",
      "names": {},
      "countryCode": "HU",
      "latLng": {
        "lat": 48.195,
        "lng": 20.9461
      },
      "country": "Hungary",
      "importance": 2
    },
    {
      "id": 41312,
      "name": "Vila do Bispo",
      "names": {
        "ja": "ビラはビスポをします"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 37.0825,
        "lng": -8.9119
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 39754,
      "name": "Turi",
      "names": {
        "zh": "图里"
      },
      "countryCode": "EE",
      "latLng": {
        "lat": 58.8094,
        "lng": 25.4292
      },
      "country": "Estonia",
      "importance": 2
    },
    {
      "id": 37587,
      "name": "Scharding",
      "names": {
        "de": "Schärding"
      },
      "countryCode": "AT",
      "latLng": {
        "lat": 48.4569,
        "lng": 13.4317
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 41250,
      "name": "Cordoba",
      "names": {
        "es": "Córdoba",
        "fr": "Cordoue",
        "zh": "科尔多瓦",
        "ja": "コルドバ",
        "th": "คอร์โดบา",
        "pt": "Córdoba"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 4.3911,
        "lng": -75.6878
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 9160,
      "name": "Albina",
      "names": {
        "zh": "阿尔宾娜",
        "ja": "アルビナ"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.5,
        "lng": -54.05
      },
      "country": "Suriname",
      "importance": 1
    },
    {
      "id": 41247,
      "name": "Tha Wang Pha",
      "names": {
        "zh": "王皮",
        "th": "ท่าวังผา"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.0945,
        "lng": 100.8097
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41390,
      "name": "El Cocuy",
      "names": {
        "fr": "El cocouy",
        "zh": "El椰子",
        "pt": "El cocia"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.4094,
        "lng": -72.4444
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 41354,
      "name": "Arcabuco",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.7544,
        "lng": -73.4375
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 41433,
      "name": "Hankasalmi",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.3889,
        "lng": 26.4361
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 41416,
      "name": "Novomykolayivka",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 47.9779,
        "lng": 35.9099
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 41428,
      "name": "Orchomenos",
      "names": {
        "zh": "奥尔霍迈诺斯"
      },
      "countryCode": "GR",
      "latLng": {
        "lat": 38.4933,
        "lng": 22.9749
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 9157,
      "name": "Tarrafal",
      "names": {
        "zh": "塔拉法尔"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 16.566,
        "lng": -24.3568
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 41464,
      "name": "Parikkala",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 61.55,
        "lng": 29.5
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8901,
      "name": "Panaba",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 21.2964,
        "lng": -88.2706
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 38397,
      "name": "Mamers",
      "names": {
        "zh": "马梅尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.3494,
        "lng": 0.3694
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 9145,
      "name": "Triesen",
      "names": {
        "zh": "特里森"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.1,
        "lng": 9.5167
      },
      "country": "Liechtenstein",
      "importance": 1
    },
    {
      "id": 41298,
      "name": "San Jose de Pare",
      "names": {
        "zh": "圣何塞德派",
        "ja": "サンノゼ・デパレ",
        "th": "ซานโฮเซ่เดอตัด"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 6.0192,
        "lng": -73.5467
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 41417,
      "name": "Besiri",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.92,
        "lng": 41.29
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 40950,
      "name": "Eynesil",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0632,
        "lng": 39.1409
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 41528,
      "name": "Meda",
      "names": {
        "zh": "梅达",
        "ja": "メダ"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 40.9667,
        "lng": -7.2667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 9155,
      "name": "Kalangala",
      "names": {
        "zh": "卡兰加拉"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.3089,
        "lng": 32.225
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 41349,
      "name": "Nong Muang",
      "names": {
        "zh": "锣曼",
        "it": "Nonnnocchia",
        "th": "หนองม่วง"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.2348,
        "lng": 100.6524
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 40839,
      "name": "Chateaulin",
      "names": {
        "fr": "Châteaulin"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 48.1967,
        "lng": -4.09
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 41469,
      "name": "Buje",
      "names": {
        "it": "Buie"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.4,
        "lng": 13.65
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 41545,
      "name": "Bobov Dol",
      "names": {
        "es": "Botov dol",
        "zh": "鲍勃DOL",
        "pt": "Dol do Bobov"
      },
      "countryCode": "BG",
      "latLng": {
        "lat": 42.3638,
        "lng": 22.9979
      },
      "country": "Bulgaria",
      "importance": 2
    },
    {
      "id": 41560,
      "name": "Vila Nova de Paiva",
      "names": {},
      "countryCode": "PT",
      "latLng": {
        "lat": 40.85,
        "lng": -7.7333
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 41271,
      "name": "Waeng Yai",
      "names": {
        "zh": "伟城亚",
        "ja": "ウェンヤイ",
        "th": "วาย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.9624,
        "lng": 102.5419
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41530,
      "name": "Jinzhong",
      "names": {
        "zh": "晋中"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 26.3504,
        "lng": 103.4167
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 9168,
      "name": "Slovenske Konjice",
      "names": {
        "ja": "スロベンスキーコニス"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3362,
        "lng": 15.421
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 41537,
      "name": "San Jose del Golfo",
      "names": {
        "zh": "圣何塞德德尔戈尔戈",
        "ja": "サンホセルゴルフー"
      },
      "countryCode": "GT",
      "latLng": {
        "lat": 14.7629,
        "lng": -90.3726
      },
      "country": "Guatemala",
      "importance": 2
    },
    {
      "id": 9174,
      "name": "Liquica",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.5935,
        "lng": 125.3273
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 9173,
      "name": "Smiltene",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.4242,
        "lng": 25.9064
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 41575,
      "name": "San Cristobal Amatlan",
      "names": {
        "es": "San Cristóbal Amatlan",
        "ja": "サンクリストバルアマトラン",
        "th": "ซาน Cristobal Amatlan"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.3167,
        "lng": -96.4
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 41602,
      "name": "Larsmo",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.75,
        "lng": 22.8
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 41177,
      "name": "Hareid",
      "names": {
        "zh": "哈雷德"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 62.3642,
        "lng": 6.0014
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 8935,
      "name": "Pitiquito",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 30.6761,
        "lng": -112.0539
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40804,
      "name": "Bodrum",
      "names": {
        "zh": "博德鲁姆",
        "ja": "ボドルム",
        "th": "โบดรัม"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 37.0383,
        "lng": 27.4292
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 9165,
      "name": "Kekava",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.8266,
        "lng": 24.23
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 41137,
      "name": "Kyparissia",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 37.2508,
        "lng": 21.6705
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 41651,
      "name": "Volovets",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.7242,
        "lng": 23.2
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 41680,
      "name": "Pornainen",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 60.475,
        "lng": 25.375
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 8860,
      "name": "Tayoltita",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 24.0833,
        "lng": -105.9333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 41341,
      "name": "Kupiansk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 49.7064,
        "lng": 37.6167
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 41753,
      "name": "Sievi",
      "names": {
        "zh": "谢维"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 63.9069,
        "lng": 24.5167
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 41422,
      "name": "Karystos",
      "names": {},
      "countryCode": "GR",
      "latLng": {
        "lat": 38.0158,
        "lng": 24.4203
      },
      "country": "Greece",
      "importance": 2
    },
    {
      "id": 41659,
      "name": "Kut Bak",
      "names": {
        "zh": "洗甲",
        "id": "Kucing",
        "th": "กุดบาก"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 17.0857,
        "lng": 103.8204
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41773,
      "name": "Betulia",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 6.9,
        "lng": -73.2836
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 41784,
      "name": "Joroinen",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 62.1833,
        "lng": 27.8333
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 41752,
      "name": "Askola",
      "names": {
        "zh": "阿斯科拉"
      },
      "countryCode": "FI",
      "latLng": {
        "lat": 60.5278,
        "lng": 25.6
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 41781,
      "name": "Alfandega da Fe",
      "names": {
        "pt": "Alfandaga da fe"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 41.35,
        "lng": -6.9667
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 41815,
      "name": "Labranzagrande",
      "names": {},
      "countryCode": "CO",
      "latLng": {
        "lat": 5.5633,
        "lng": -72.5775
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 36628,
      "name": "Ocampo",
      "names": {
        "zh": "奥坎波"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 22.833,
        "lng": -99.333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 40718,
      "name": "Teojomulco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 16.6,
        "lng": -97.2333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 41833,
      "name": "Oyer",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 61.2653,
        "lng": 10.4133
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 8923,
      "name": "Angostura",
      "names": {
        "zh": "安戈斯图拉",
        "ja": "アンゴスチュラ"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 25.3653,
        "lng": -108.1622
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 41649,
      "name": "Mae Chai",
      "names": {
        "ja": "メーチャイ",
        "th": "แม่ชัย"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 19.3453,
        "lng": 99.8138
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41769,
      "name": "Cobanlar",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.7014,
        "lng": 30.7828
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 9183,
      "name": "Skuodas",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 56.2667,
        "lng": 21.5333
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 41510,
      "name": "Nong Na Kham",
      "names": {
        "th": "หนองนาคำ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 16.802,
        "lng": 102.3404
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41684,
      "name": "Gokcebey",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.3067,
        "lng": 32.1442
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 41737,
      "name": "Si Sakhon",
      "names": {
        "de": "Siakhon",
        "ja": "シェサコン",
        "th": "ศรีสะเกษ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 6.2235,
        "lng": 101.5033
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41526,
      "name": "Sanom",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 15.1988,
        "lng": 103.7567
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41818,
      "name": "Phanom",
      "names": {
        "zh": "帕侬",
        "th": "พนม"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.8584,
        "lng": 98.8122
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41847,
      "name": "Sousel",
      "names": {
        "zh": "索西尔"
      },
      "countryCode": "PT",
      "latLng": {
        "lat": 38.9532,
        "lng": -7.6757
      },
      "country": "Portugal",
      "importance": 2
    },
    {
      "id": 9051,
      "name": "Jogeva",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 58.7461,
        "lng": 26.3956
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 41805,
      "name": "Ilok",
      "names": {
        "zh": "伊洛克"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.2225,
        "lng": 19.3728
      },
      "country": "Croatia",
      "importance": 2
    },
    {
      "id": 41892,
      "name": "Chaiten",
      "names": {},
      "countryCode": "CL",
      "latLng": {
        "lat": -42.9193,
        "lng": -72.7088
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 9068,
      "name": "Rapla",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 58.9944,
        "lng": 24.8011
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 41612,
      "name": "Forcalquier",
      "names": {},
      "countryCode": "FR",
      "latLng": {
        "lat": 43.9592,
        "lng": 5.7797
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 41305,
      "name": "Shaykh al Hadid",
      "names": {
        "id": "Syekh Al Hadid"
      },
      "countryCode": "SY",
      "latLng": {
        "lat": 36.5,
        "lng": 36.5992
      },
      "country": "Syria",
      "importance": 2
    },
    {
      "id": 40917,
      "name": "Yarang",
      "names": {},
      "countryCode": "TH",
      "latLng": {
        "lat": 6.761,
        "lng": 101.3012
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41911,
      "name": "Esme",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.4006,
        "lng": 28.9691
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 41927,
      "name": "Basmakci",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.8973,
        "lng": 30.0101
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 41956,
      "name": "Juuka",
      "names": {},
      "countryCode": "FI",
      "latLng": {
        "lat": 63.2417,
        "lng": 29.25
      },
      "country": "Finland",
      "importance": 2
    },
    {
      "id": 41633,
      "name": "In Buri",
      "names": {
        "es": "En buri",
        "fr": "À Buri",
        "zh": "在Buri",
        "id": "Di buri",
        "ja": "ブリの中で",
        "th": "ในจังหวัดบุรี",
        "pt": "Em buri"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 15.0054,
        "lng": 100.3264
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 41790,
      "name": "Vakfikebir",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 41.0458,
        "lng": 39.2764
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 41950,
      "name": "Emirgazi",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 37.9022,
        "lng": 33.8372
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 9191,
      "name": "Punta Gorda",
      "names": {
        "ja": "プンタゴルダ",
        "th": "ปุนตากอร์ดา"
      },
      "countryCode": "BZ",
      "latLng": {
        "lat": 16.1,
        "lng": -88.8
      },
      "country": "Belize",
      "importance": 1
    },
    {
      "id": 41963,
      "name": "San Jeronimo Coatlan",
      "names": {
        "es": "SAN JERONIMO ACOPLAN",
        "zh": "San Jeronimo Toodlan",
        "ja": "サンエロニモコートラン",
        "pt": "Coatlan de San Jeronimo"
      },
      "countryCode": "MX",
      "latLng": {
        "lat": 16.2333,
        "lng": -96.8667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 41819,
      "name": "Thung Yai",
      "names": {
        "th": "ทุ่งใหญ่"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 8.3132,
        "lng": 99.3631
      },
      "country": "Thailand",
      "importance": 2
    },
    {
      "id": 9171,
      "name": "Pale",
      "names": {
        "es": "Pálido",
        "fr": "Pâle",
        "zh": "苍白",
        "de": "Blass",
        "it": "Pallido",
        "id": "Pucat",
        "ja": "淡い",
        "th": "ซีด",
        "pt": "Pálido"
      },
      "countryCode": "GQ",
      "latLng": {
        "lat": -1.4043,
        "lng": 5.6322
      },
      "country": "Equatorial Guinea",
      "importance": 1
    },
    {
      "id": 41851,
      "name": "Jalcomulco",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 19.3333,
        "lng": -96.7667
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 41798,
      "name": "Montbard",
      "names": {
        "zh": "蒙巴尔"
      },
      "countryCode": "FR",
      "latLng": {
        "lat": 47.6231,
        "lng": 4.3369
      },
      "country": "France",
      "importance": 2
    },
    {
      "id": 40866,
      "name": "Grieskirchen",
      "names": {},
      "countryCode": "AT",
      "latLng": {
        "lat": 48.235,
        "lng": 13.8319
      },
      "country": "Austria",
      "importance": 2
    },
    {
      "id": 41998,
      "name": "Baskil",
      "names": {},
      "countryCode": "TR",
      "latLng": {
        "lat": 38.5674,
        "lng": 38.8236
      },
      "country": "Turkey",
      "importance": 2
    },
    {
      "id": 42002,
      "name": "Chechelnyk",
      "names": {},
      "countryCode": "UA",
      "latLng": {
        "lat": 48.2122,
        "lng": 29.3622
      },
      "country": "Ukraine",
      "importance": 2
    },
    {
      "id": 819,
      "name": "Gaza",
      "names": {
        "fr": "La bande de Gaza",
        "zh": "加沙",
        "ja": "ガザ",
        "th": "กาซา"
      },
      "countryCode": "XG",
      "latLng": {
        "lat": 31.5069,
        "lng": 34.456
      },
      "country": "Gaza Strip",
      "importance": 0
    },
    {
      "id": 9158,
      "name": "Wangdue Phodrang",
      "names": {
        "zh": "王德菲堡",
        "id": "Phodrang wangdue"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.4333,
        "lng": 89.9167
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9189,
      "name": "Zalec",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2516,
        "lng": 15.1674
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9192,
      "name": "Punakha",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.6167,
        "lng": 89.8667
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9195,
      "name": "Sakiai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.9556,
        "lng": 23.0444
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9169,
      "name": "Grevenmacher",
      "names": {
        "zh": "格雷文马赫"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.6747,
        "lng": 6.4419
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 9193,
      "name": "Samtse",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 26.8667,
        "lng": 89.1
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9194,
      "name": "Gevgelija",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.1392,
        "lng": 22.5025
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9111,
      "name": "Hrastnik",
      "names": {
        "zh": "赫拉斯特尼克"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1461,
        "lng": 15.0814
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9199,
      "name": "Sentjur",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2225,
        "lng": 15.3973
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9196,
      "name": "Aibak",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 36.2534,
        "lng": 68.0394
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 9201,
      "name": "Nieuw Amsterdam",
      "names": {
        "zh": "nieuw阿姆斯特丹",
        "ja": "Nieuwアムステルダム",
        "th": "Nieuw อัมสเตอร์ดัม"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.91,
        "lng": -55.07
      },
      "country": "Suriname",
      "importance": 1
    },
    {
      "id": 9200,
      "name": "Bled",
      "names": {
        "zh": "布莱德",
        "ja": "ブレッド",
        "th": "เบลด"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3667,
        "lng": 14.1167
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9146,
      "name": "Xaghra",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0503,
        "lng": 14.2675
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9187,
      "name": "Vrapciste",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.8337,
        "lng": 20.8851
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9148,
      "name": "Ros Comain",
      "names": {
        "es": "Rosada",
        "fr": "Ros Comité",
        "zh": "罗斯蒙太此",
        "it": "Ros comein",
        "id": "Comain",
        "pt": "Ros comin"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.6333,
        "lng": -8.1833
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9024,
      "name": "Opuwo",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -18.05,
        "lng": 13.8333
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 9202,
      "name": "Puerto Lempira",
      "names": {
        "ja": "プエルトレンピラ",
        "th": "เปอร์โตลมพิศาล"
      },
      "countryCode": "HN",
      "latLng": {
        "lat": 15.2653,
        "lng": -83.7744
      },
      "country": "Honduras",
      "importance": 1
    },
    {
      "id": 9205,
      "name": "Irig",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.1011,
        "lng": 19.8583
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9203,
      "name": "Bolama",
      "names": {
        "zh": "博拉马"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 11.5776,
        "lng": -15.4742
      },
      "country": "Guinea-Bissau",
      "importance": 1
    },
    {
      "id": 9092,
      "name": "Ghaxaq",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8483,
        "lng": 14.5172
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9213,
      "name": "Coka",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.9389,
        "lng": 20.1394
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 42022,
      "name": "Svolvaer",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 68.2333,
        "lng": 14.5667
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 9214,
      "name": "Radovis",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.6381,
        "lng": 22.4644
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9185,
      "name": "Ilulissat",
      "names": {
        "zh": "伊卢利萨特"
      },
      "countryCode": "GL",
      "latLng": {
        "lat": 69.2167,
        "lng": -51.1
      },
      "country": "Greenland",
      "importance": 1
    },
    {
      "id": 9207,
      "name": "Klaksvik",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.2375,
        "lng": -6.539
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9216,
      "name": "Silale",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.4917,
        "lng": 22.1778
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9206,
      "name": "Balzers",
      "names": {
        "zh": "巴尔查斯"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.0667,
        "lng": 9.5
      },
      "country": "Liechtenstein",
      "importance": 1
    },
    {
      "id": 9098,
      "name": "Prevalje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5469,
        "lng": 14.9208
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 42025,
      "name": "Perito Moreno",
      "names": {
        "zh": "佩里托莫雷诺",
        "ja": "ペリトモレノ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -46.59,
        "lng": -70.9297
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9091,
      "name": "Outapi",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -17.5,
        "lng": 14.9833
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 9217,
      "name": "Ordino",
      "names": {
        "zh": "奥尔迪诺"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.555,
        "lng": 1.5331
      },
      "country": "Andorra",
      "importance": 1
    },
    {
      "id": 9220,
      "name": "Sevnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0092,
        "lng": 15.3039
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9221,
      "name": "Valka",
      "names": {
        "zh": "瓦尔卡"
      },
      "countryCode": "LV",
      "latLng": {
        "lat": 57.7753,
        "lng": 26.0214
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 9179,
      "name": "Nadur",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0381,
        "lng": 14.295
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 42029,
      "name": "Gobernador Gregores",
      "names": {
        "zh": "戈伯纳多尔格雷格"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -48.7509,
        "lng": -70.2485
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9219,
      "name": "Bueng Kan",
      "names": {
        "zh": "布恩侃",
        "de": "Bueng Kane",
        "ja": "ブエンカン",
        "th": "บึงกาฬ"
      },
      "countryCode": "TH",
      "latLng": {
        "lat": 18.3669,
        "lng": 103.6552
      },
      "country": "Thailand",
      "importance": 1
    },
    {
      "id": 42031,
      "name": "Abra Pampa",
      "names": {
        "ja": "アブラパンパ"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -22.7167,
        "lng": -65.7
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9210,
      "name": "Bururi",
      "names": {
        "zh": "布鲁里"
      },
      "countryCode": "BI",
      "latLng": {
        "lat": -3.9333,
        "lng": 29.6167
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 9204,
      "name": "Eschen",
      "names": {
        "zh": "埃申"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.2,
        "lng": 9.5167
      },
      "country": "Liechtenstein",
      "importance": 1
    },
    {
      "id": 42033,
      "name": "Victorica",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -36.2167,
        "lng": -65.434
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9223,
      "name": "Susa",
      "names": {
        "zh": "苏萨",
        "ja": "スーサ",
        "th": "ซูซา"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.7602,
        "lng": 46.7504
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9224,
      "name": "Trakai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.6333,
        "lng": 24.9333
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9197,
      "name": "Mauren",
      "names": {
        "zh": "毛伦"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.2197,
        "lng": 9.5428
      },
      "country": "Liechtenstein",
      "importance": 1
    },
    {
      "id": 9088,
      "name": "Marsa",
      "names": {
        "zh": "马尔萨",
        "ja": "マルサ"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8833,
        "lng": 14.4947
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 42035,
      "name": "La Paz",
      "names": {
        "zh": "拉巴斯",
        "ja": "ラパス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -33.4661,
        "lng": -67.55
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9227,
      "name": "Pakruojis",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.9809,
        "lng": 23.8551
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9229,
      "name": "Ilirska Bistrica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5651,
        "lng": 14.2493
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 826,
      "name": "Saint George\u0027s",
      "names": {
        "zh": "圣乔治",
        "de": "Saint George",
        "ja": "セントジョージの",
        "th": "เซนต์จอร์จ",
        "pt": "São Jorge"
      },
      "countryCode": "GD",
      "latLng": {
        "lat": 12.0444,
        "lng": -61.7417
      },
      "country": "Grenada",
      "importance": 0
    },
    {
      "id": 42042,
      "name": "Comandante Fontana",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -25.3333,
        "lng": -59.6833
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9225,
      "name": "Black River",
      "names": {
        "es": "Río negro",
        "fr": "Rivière noire",
        "zh": "黑河",
        "de": "Schwarzen Fluss",
        "it": "Fiume nero",
        "ja": "ブラックリバー",
        "th": "แม่น้ำดำ",
        "pt": "Rio preto"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 18.0256,
        "lng": -77.8508
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 9208,
      "name": "Kuala Belait",
      "names": {
        "fr": "Kuala Belaïl",
        "zh": "吉隆坡贝泰",
        "id": "Kuala biji",
        "ja": "クアラレイト",
        "th": "กัวลาลัย"
      },
      "countryCode": "BN",
      "latLng": {
        "lat": 4.5828,
        "lng": 114.1918
      },
      "country": "Brunei",
      "importance": 1
    },
    {
      "id": 9234,
      "name": "Ruse",
      "names": {
        "zh": "鲁塞",
        "ja": "虚勢",
        "th": "อุบาย"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5383,
        "lng": 15.5153
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9239,
      "name": "Tunceli",
      "names": {
        "zh": "通杰利"
      },
      "countryCode": "TR",
      "latLng": {
        "lat": 39.1061,
        "lng": 39.5481
      },
      "country": "Turkey",
      "importance": 1
    },
    {
      "id": 9238,
      "name": "Calheta de Sao Miguel",
      "names": {
        "pt": "Calheta de São Miguel"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.1875,
        "lng": -23.5917
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 9242,
      "name": "Canillo",
      "names": {
        "zh": "卡尼略"
      },
      "countryCode": "AD",
      "latLng": {
        "lat": 42.5664,
        "lng": 1.6009
      },
      "country": "Andorra",
      "importance": 1
    },
    {
      "id": 9237,
      "name": "Bensonville",
      "names": {},
      "countryCode": "LR",
      "latLng": {
        "lat": 6.4456,
        "lng": -10.6097
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 9246,
      "name": "Svencionys",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.1333,
        "lng": 26.1556
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 827,
      "name": "San Marino",
      "names": {
        "fr": "Saint marin",
        "zh": "圣马力诺",
        "ja": "サンマリノ",
        "th": "ซานมาริโน"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.932,
        "lng": 12.4484
      },
      "country": "San Marino",
      "importance": 0
    },
    {
      "id": 8727,
      "name": "Pieta",
      "names": {
        "fr": "Pietà",
        "zh": "圣母怜子图",
        "ja": "ビエタ"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8833,
        "lng": 14.4833
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9231,
      "name": "Cerknica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7964,
        "lng": 14.3581
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 8235,
      "name": "Naifaru",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 5.4442,
        "lng": 73.3662
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 8947,
      "name": "Balzan",
      "names": {
        "zh": "巴尔赞"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8975,
        "lng": 14.4533
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9247,
      "name": "Wabag",
      "names": {
        "zh": "瓦巴格"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -5.4833,
        "lng": 143.7
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 9251,
      "name": "Trebnje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9104,
        "lng": 15.0086
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 42055,
      "name": "Finnsnes",
      "names": {},
      "countryCode": "NO",
      "latLng": {
        "lat": 69.2406,
        "lng": 18.0086
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 42056,
      "name": "Sapouy",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 11.5478,
        "lng": -1.775
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 9252,
      "name": "Decan",
      "names": {
        "pt": "Decano"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.5333,
        "lng": 20.2833
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9222,
      "name": "Remich",
      "names": {
        "zh": "雷米希"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.5444,
        "lng": 6.3667
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 9249,
      "name": "Agdam",
      "names": {
        "zh": "阿格达姆"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.9833,
        "lng": 46.9167
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9255,
      "name": "Kalvarija",
      "names": {
        "zh": "卡尔瓦里亚"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 54.4147,
        "lng": 23.2281
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9254,
      "name": "Piran",
      "names": {
        "zh": "皮兰",
        "it": "Pirano"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5271,
        "lng": 13.5685
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 42065,
      "name": "Ulaan-Uul",
      "names": {
        "zh": "乌兰-多山"
      },
      "countryCode": "MN",
      "latLng": {
        "lat": 44.3337,
        "lng": 111.2333
      },
      "country": "Mongolia",
      "importance": 2
    },
    {
      "id": 9235,
      "name": "Sempeter pri Gorici",
      "names": {
        "ja": "セッピターPRI GORICI"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9283,
        "lng": 13.6378
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9260,
      "name": "Massenya",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 11.4028,
        "lng": 16.17
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 9243,
      "name": "Ziri",
      "names": {
        "de": "Zlri"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.046,
        "lng": 14.1083
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9162,
      "name": "Pembroke",
      "names": {
        "zh": "彭布罗克",
        "ja": "ペンブローク",
        "th": "เพมโบรก"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9264,
        "lng": 14.4808
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9261,
      "name": "Imgarr",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9197,
        "lng": 14.3664
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9262,
      "name": "Lazdijai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.2319,
        "lng": 23.517
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9257,
      "name": "Cankuzo",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -3.2194,
        "lng": 30.5528
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 9263,
      "name": "Berovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.7078,
        "lng": 22.8564
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 42069,
      "name": "Yomou",
      "names": {},
      "countryCode": "GN",
      "latLng": {
        "lat": 7.566,
        "lng": -9.2533
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 42072,
      "name": "Tessalit",
      "names": {},
      "countryCode": "ML",
      "latLng": {
        "lat": 20.2011,
        "lng": 1.0125
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 9236,
      "name": "Dingli",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8603,
        "lng": 14.3814
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9256,
      "name": "Janjanbureh",
      "names": {},
      "countryCode": "GM",
      "latLng": {
        "lat": 13.551,
        "lng": -14.767
      },
      "country": "The Gambia",
      "importance": 1
    },
    {
      "id": 9265,
      "name": "Krusevo",
      "names": {
        "th": "ครูเซโว"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.37,
        "lng": 21.2483
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9232,
      "name": "Marsaxlokk",
      "names": {
        "zh": "马尔萨什洛克"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8417,
        "lng": 14.5447
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 42075,
      "name": "Kirkenes",
      "names": {
        "zh": "希尔克内斯"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 69.7271,
        "lng": 30.0451
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 9269,
      "name": "Sveti Nikole",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.865,
        "lng": 21.9425
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9273,
      "name": "Alibunar",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.0808,
        "lng": 20.9658
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9271,
      "name": "Cantemir",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 46.2781,
        "lng": 28.2008
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 9245,
      "name": "Kaberamaido",
      "names": {
        "zh": "卡贝拉马伊多"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.7389,
        "lng": 33.1594
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9218,
      "name": "Imqabba",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8442,
        "lng": 14.4669
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9248,
      "name": "Xewkija",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0331,
        "lng": 14.2583
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9233,
      "name": "Lasko",
      "names": {
        "fr": "Laško",
        "zh": "拉什科",
        "it": "Laško"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1563,
        "lng": 15.2386
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9278,
      "name": "Lenart v Slovenskih Goricah",
      "names": {
        "fr": "Lenart v Slovenski Goricah",
        "id": "Lenart v slovenskih goriicah",
        "ja": "Lenart vスロベンスkih Goricah"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5755,
        "lng": 15.8306
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9281,
      "name": "Demir Kapija",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4114,
        "lng": 22.2422
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 42080,
      "name": "Sebba",
      "names": {},
      "countryCode": "BF",
      "latLng": {
        "lat": 13.4392,
        "lng": 0.5289
      },
      "country": "Burkina Faso",
      "importance": 2
    },
    {
      "id": 9253,
      "name": "Kardla",
      "names": {},
      "countryCode": "EE",
      "latLng": {
        "lat": 58.9944,
        "lng": 22.75
      },
      "country": "Estonia",
      "importance": 1
    },
    {
      "id": 9285,
      "name": "Metlika",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.6472,
        "lng": 15.3142
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9267,
      "name": "Louang Namtha",
      "names": {
        "de": "Louang Namha"
      },
      "countryCode": "LA",
      "latLng": {
        "lat": 20.95,
        "lng": 101.4167
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 9264,
      "name": "Ankaran",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5793,
        "lng": 13.7379
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9284,
      "name": "Groningen",
      "names": {
        "zh": "格罗宁根",
        "ja": "グローニンゲン",
        "th": "โกร"
      },
      "countryCode": "SR",
      "latLng": {
        "lat": 5.797,
        "lng": -55.481
      },
      "country": "Suriname",
      "importance": 1
    },
    {
      "id": 9287,
      "name": "Ghajnsielem",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0269,
        "lng": 14.2903
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 42087,
      "name": "Sinnamary",
      "names": {},
      "countryCode": "GF",
      "latLng": {
        "lat": 5.38,
        "lng": -52.96
      },
      "country": "French Guiana",
      "importance": 2
    },
    {
      "id": 9280,
      "name": "Mezica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5206,
        "lng": 14.8536
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9290,
      "name": "Rietavas",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.725,
        "lng": 21.9278
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9198,
      "name": "Iklin",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9042,
        "lng": 14.4544
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9291,
      "name": "Sahbuz",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.4073,
        "lng": 45.5733
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9282,
      "name": "Saulkrasti",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 57.2636,
        "lng": 24.4164
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 9279,
      "name": "Ulbroka",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9408,
        "lng": 24.2861
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 9286,
      "name": "Qaqortoq",
      "names": {},
      "countryCode": "GL",
      "latLng": {
        "lat": 60.7167,
        "lng": -46.0333
      },
      "country": "Greenland",
      "importance": 1
    },
    {
      "id": 9226,
      "name": "Dravograd",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.59,
        "lng": 15.018
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9244,
      "name": "Gornja Radgona",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.681,
        "lng": 15.9883
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9275,
      "name": "Ainaro",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.9833,
        "lng": 125.5
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 9104,
      "name": "Lija",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9014,
        "lng": 14.4472
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9295,
      "name": "Aasiaat",
      "names": {},
      "countryCode": "GL",
      "latLng": {
        "lat": 68.7097,
        "lng": -52.8694
      },
      "country": "Greenland",
      "importance": 1
    },
    {
      "id": 9293,
      "name": "Porto Ingles",
      "names": {
        "zh": "波尔图锭",
        "ja": "ポルトイン",
        "th": "ปอร์โตเก่ง",
        "pt": "Ingles do porto"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.1375,
        "lng": -23.2083
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 9294,
      "name": "Delcevo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9661,
        "lng": 22.7747
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9215,
      "name": "Kalkara",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8892,
        "lng": 14.5294
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9299,
      "name": "Lethem",
      "names": {},
      "countryCode": "GY",
      "latLng": {
        "lat": 3.3833,
        "lng": -59.8
      },
      "country": "Guyana",
      "importance": 1
    },
    {
      "id": 9230,
      "name": "Gudja",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8483,
        "lng": 14.5025
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9301,
      "name": "Mandalgovi",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 45.7667,
        "lng": 106.2708
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 9300,
      "name": "Mabaruma",
      "names": {
        "zh": "马巴鲁马"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 8.2,
        "lng": -59.7833
      },
      "country": "Guyana",
      "importance": 1
    },
    {
      "id": 9302,
      "name": "Lendava",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5662,
        "lng": 16.4499
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9303,
      "name": "Bogdanci",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.2031,
        "lng": 22.5728
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 42100,
      "name": "Pampa del Infierno",
      "names": {
        "id": "Pampa del inferno"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.5167,
        "lng": -61.1667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 42054,
      "name": "Nautla",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 20.2167,
        "lng": -96.7833
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 9311,
      "name": "Radlje ob Dravi",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6149,
        "lng": 15.2226
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9310,
      "name": "Sao Domingos",
      "names": {
        "es": "Sao Domingo",
        "zh": "圣多明斯",
        "th": "เซาโดมิงโก",
        "pt": "São Domingos"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.025,
        "lng": -23.5625
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 42101,
      "name": "Kailu",
      "names": {},
      "countryCode": "CN",
      "latLng": {
        "lat": 43.5837,
        "lng": 121.2
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 42108,
      "name": "Cochrane",
      "names": {
        "zh": "科克伦",
        "ja": "コクラン"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -47.2539,
        "lng": -72.5732
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 8766,
      "name": "Senglea",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8878,
        "lng": 14.5169
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9241,
      "name": "Gharghur",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.9241,
        "lng": 14.4534
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9277,
      "name": "Qrendi",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8342,
        "lng": 14.4589
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9312,
      "name": "Kerewan",
      "names": {},
      "countryCode": "GM",
      "latLng": {
        "lat": 13.494,
        "lng": -16.095
      },
      "country": "The Gambia",
      "importance": 1
    },
    {
      "id": 42109,
      "name": "Saint-Georges",
      "names": {
        "zh": "圣 - 乔治·",
        "ja": "サン・ジョルジュ",
        "th": "นักบุญจอร์จ"
      },
      "countryCode": "GF",
      "latLng": {
        "lat": 3.9105,
        "lng": -51.81
      },
      "country": "French Guiana",
      "importance": 2
    },
    {
      "id": 9316,
      "name": "Barclayville",
      "names": {},
      "countryCode": "LR",
      "latLng": {
        "lat": 4.8,
        "lng": -8.1667
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 9319,
      "name": "Negotino",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4839,
        "lng": 22.0892
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9292,
      "name": "Borovnica",
      "names": {
        "zh": "博罗夫尼察"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.918,
        "lng": 14.3642
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9317,
      "name": "Triesenberg",
      "names": {
        "zh": "特里森贝格"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.1167,
        "lng": 9.5333
      },
      "country": "Liechtenstein",
      "importance": 1
    },
    {
      "id": 9082,
      "name": "Vittoriosa",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8881,
        "lng": 14.5225
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 42112,
      "name": "Rorvik",
      "names": {
        "zh": "勒尔维克"
      },
      "countryCode": "NO",
      "latLng": {
        "lat": 64.868,
        "lng": 11.2053
      },
      "country": "Norway",
      "importance": 2
    },
    {
      "id": 9324,
      "name": "Tofol",
      "names": {},
      "countryCode": "FM",
      "latLng": {
        "lat": 5.3258,
        "lng": 163.0086
      },
      "country": "Federated States of Micronesia",
      "importance": 1
    },
    {
      "id": 9323,
      "name": "Cestos City",
      "names": {
        "es": "CESTOS CIUDAD",
        "fr": "CESTIR CESTOS",
        "zh": "卡斯托斯市",
        "it": "Città di Cesos",
        "id": "Kota cestos",
        "ja": "セストスシティ",
        "th": "เมือง Cestos",
        "pt": "Cidade de cestos"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 5.4697,
        "lng": -9.5817
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 9266,
      "name": "Imtarfa",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8908,
        "lng": 14.3969
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 42085,
      "name": "Mkokotoni",
      "names": {
        "zh": "姆科科托尼"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.88,
        "lng": 39.2731
      },
      "country": "Tanzania",
      "importance": 2
    },
    {
      "id": 42115,
      "name": "Chumbicha",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -28.8666,
        "lng": -66.2333
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9326,
      "name": "Garoowe",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 8.4,
        "lng": 48.4833
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 829,
      "name": "Capitol Hill",
      "names": {
        "es": "Capitolio",
        "zh": "国会山",
        "id": "Bukit Capitol",
        "ja": "キャピトルヒル",
        "th": "ศาลากลางเนินเขา",
        "pt": "Capitólio"
      },
      "countryCode": "MP",
      "latLng": {
        "lat": 15.2137,
        "lng": 145.7546
      },
      "country": "Northern Mariana Islands",
      "importance": 0
    },
    {
      "id": 5413,
      "name": "Un\u0027goofaaru",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 5.6681,
        "lng": 73.0302
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9304,
      "name": "Vojnik",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2931,
        "lng": 15.3028
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9331,
      "name": "Fuerte Olimpo",
      "names": {},
      "countryCode": "PY",
      "latLng": {
        "lat": -21.0696,
        "lng": -57.9
      },
      "country": "Paraguay",
      "importance": 1
    },
    {
      "id": 9332,
      "name": "Beltinci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.606,
        "lng": 16.2328
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9330,
      "name": "Vevcani",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.2403,
        "lng": 20.5931
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9334,
      "name": "Nida",
      "names": {
        "zh": "奈达"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.304,
        "lng": 21.0053
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 42129,
      "name": "Jurado",
      "names": {
        "zh": "胡拉多"
      },
      "countryCode": "CO",
      "latLng": {
        "lat": 7.1114,
        "lng": -77.7714
      },
      "country": "Colombia",
      "importance": 2
    },
    {
      "id": 42127,
      "name": "Boffa",
      "names": {
        "zh": "博法"
      },
      "countryCode": "GN",
      "latLng": {
        "lat": 10.185,
        "lng": -14.043
      },
      "country": "Guinea",
      "importance": 2
    },
    {
      "id": 9336,
      "name": "Birstonas",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.6056,
        "lng": 24.0292
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9325,
      "name": "Ruggell",
      "names": {},
      "countryCode": "LI",
      "latLng": {
        "lat": 47.245,
        "lng": 9.5332
      },
      "country": "Liechtenstein",
      "importance": 1
    },
    {
      "id": 9321,
      "name": "Qala",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0353,
        "lng": 14.3103
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 42137,
      "name": "Roura",
      "names": {},
      "countryCode": "GF",
      "latLng": {
        "lat": 4.73,
        "lng": -52.33
      },
      "country": "French Guiana",
      "importance": 2
    },
    {
      "id": 9347,
      "name": "Gradsko",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.5775,
        "lng": 21.9428
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 830,
      "name": "Stanley",
      "names": {
        "zh": "斯坦利",
        "ja": "スタンレー",
        "th": "สแตนลีย์"
      },
      "countryCode": "FK",
      "latLng": {
        "lat": -51.7,
        "lng": -57.85
      },
      "country": "Falkland Islands (Islas Malvinas)",
      "importance": 0
    },
    {
      "id": 9212,
      "name": "Floriana",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8933,
        "lng": 14.5058
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9240,
      "name": "Kirkop",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8419,
        "lng": 14.485
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9340,
      "name": "Lacin",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.6408,
        "lng": 46.5469
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9337,
      "name": "Vianden",
      "names": {
        "zh": "菲安登"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.935,
        "lng": 6.2089
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 9348,
      "name": "Cidade Velha",
      "names": {
        "id": "Velha Cidade",
        "ja": "ベルハを準備します"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 14.9167,
        "lng": -23.6042
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 9297,
      "name": "Safi",
      "names": {
        "zh": "萨菲",
        "ja": "サフィ",
        "th": "โครก"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8333,
        "lng": 14.485
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9320,
      "name": "Sannat",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0244,
        "lng": 14.2458
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9354,
      "name": "Onverwacht",
      "names": {},
      "countryCode": "SR",
      "latLng": {
        "lat": 5.6,
        "lng": -55.2
      },
      "country": "Suriname",
      "importance": 1
    },
    {
      "id": 9356,
      "name": "Same",
      "names": {
        "es": "Mismo",
        "fr": "Même",
        "zh": "一样",
        "de": "Gleich",
        "it": "Stesso",
        "id": "Yang sama",
        "ja": "同じ",
        "th": "เหมือนกัน",
        "pt": "Mesmo"
      },
      "countryCode": "TL",
      "latLng": {
        "lat": -9.0,
        "lng": 125.65
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 9362,
      "name": "Vipava",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8463,
        "lng": 13.9622
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9363,
      "name": "Ambrolauri",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.5194,
        "lng": 43.15
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 9360,
      "name": "Sofifi",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 0.7244,
        "lng": 127.5806
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 9364,
      "name": "Radece",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0659,
        "lng": 15.1729
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9365,
      "name": "Valandovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.3169,
        "lng": 22.5611
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9342,
      "name": "Capellen",
      "names": {
        "zh": "卡佩伦"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.6444,
        "lng": 5.9897
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 9370,
      "name": "Kriva Palanka",
      "names": {
        "ja": "クリバパロンカ"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.2017,
        "lng": 22.3317
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9367,
      "name": "Ormoz",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4071,
        "lng": 16.1505
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9341,
      "name": "Kercem",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0406,
        "lng": 14.2297
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9374,
      "name": "Zabljak",
      "names": {
        "zh": "扎布利亚克"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 43.1583,
        "lng": 19.1303
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 9372,
      "name": "Kratovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0783,
        "lng": 22.175
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9376,
      "name": "Krivogastani",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.3358,
        "lng": 21.3331
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9377,
      "name": "Zrnovci",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.8542,
        "lng": 22.4442
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9384,
      "name": "La Palma",
      "names": {
        "zh": "拉帕尔马",
        "ja": "ラパルマ"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.3982,
        "lng": -78.1402
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 8972,
      "name": "Ta\u0027 Xbiex",
      "names": {
        "es": "Ta \u0027xbiex",
        "fr": "Ta \u0027xbiex",
        "zh": "ta\u0027xbiex",
        "de": "Ta \u0027xbiex",
        "it": "Ta \u0027XBIEX",
        "id": "Ta \u0027xbiex",
        "ja": "Ta \u0027Xbiex",
        "th": "ta \u0027xbiex",
        "pt": "Ta \u0027xbiex"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8992,
        "lng": 14.4981
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9346,
      "name": "Naklo",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2749,
        "lng": 14.3176
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9272,
      "name": "Xghajra",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8864,
        "lng": 14.5472
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9392,
      "name": "Varaklani",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.6078,
        "lng": 26.755
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 9395,
      "name": "Pehcevo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.7592,
        "lng": 22.8906
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9396,
      "name": "Totness",
      "names": {},
      "countryCode": "SR",
      "latLng": {
        "lat": 5.8904,
        "lng": -56.32
      },
      "country": "Suriname",
      "importance": 1
    },
    {
      "id": 9375,
      "name": "Gamprin",
      "names": {
        "zh": "甘普林"
      },
      "countryCode": "LI",
      "latLng": {
        "lat": 47.2199,
        "lng": 9.5099
      },
      "country": "Liechtenstein",
      "importance": 1
    },
    {
      "id": 9397,
      "name": "Ropazi",
      "names": {},
      "countryCode": "LV",
      "latLng": {
        "lat": 56.9718,
        "lng": 24.6318
      },
      "country": "Latvia",
      "importance": 1
    },
    {
      "id": 42159,
      "name": "Puerto Williams",
      "names": {
        "zh": "波多黎各威廉姆斯",
        "ja": "プエルトウィリアムズ",
        "th": "เปอร์โตวิลเลียมส์"
      },
      "countryCode": "CL",
      "latLng": {
        "lat": -54.9341,
        "lng": -67.6109
      },
      "country": "Chile",
      "importance": 2
    },
    {
      "id": 9399,
      "name": "Xuddur",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 4.12,
        "lng": 43.8878
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 9383,
      "name": "Odranci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5833,
        "lng": 16.2833
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 832,
      "name": "Alofi",
      "names": {
        "zh": "阿洛菲"
      },
      "countryCode": "NU",
      "latLng": {
        "lat": -19.056,
        "lng": -169.921
      },
      "country": "Niue",
      "importance": 0
    },
    {
      "id": 42161,
      "name": "Trancas",
      "names": {
        "zh": "特兰卡斯"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -26.2308,
        "lng": -65.2781
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9409,
      "name": "Bovec",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3378,
        "lng": 13.5522
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9400,
      "name": "Plasnica",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4667,
        "lng": 21.1167
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9355,
      "name": "Chiradzulu",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -15.6746,
        "lng": 35.1407
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 42163,
      "name": "Alto Rio Senguer",
      "names": {
        "zh": "Alto Rio Sengueer",
        "ja": "アルトリオセンファー"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -45.0419,
        "lng": -70.8234
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 42166,
      "name": "Sierra Colorada",
      "names": {
        "zh": "Sierra Colotada",
        "ja": "シエラコロラダ",
        "th": "เซียร์ราโคลดา"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -40.5875,
        "lng": -67.7583
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9381,
      "name": "Gharb",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0611,
        "lng": 14.2092
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9345,
      "name": "Geita",
      "names": {
        "zh": "盖塔"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -2.8714,
        "lng": 32.2294
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 42167,
      "name": "Iracoubo",
      "names": {},
      "countryCode": "GF",
      "latLng": {
        "lat": 5.4804,
        "lng": -53.22
      },
      "country": "French Guiana",
      "importance": 2
    },
    {
      "id": 9368,
      "name": "Vuzenica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5992,
        "lng": 15.1669
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9416,
      "name": "Bu\u0027aale",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 1.0833,
        "lng": 42.5833
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 9417,
      "name": "Mogila",
      "names": {
        "zh": "莫吉拉"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.1083,
        "lng": 21.3786
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9405,
      "name": "Tearce",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0775,
        "lng": 21.0519
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9411,
      "name": "Clervaux",
      "names": {
        "zh": "克莱沃"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 50.0547,
        "lng": 6.0314
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 9369,
      "name": "Munxar",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0303,
        "lng": 14.2353
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9361,
      "name": "Sentjernej",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8389,
        "lng": 15.3362
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9393,
      "name": "Aioun",
      "names": {},
      "countryCode": "MR",
      "latLng": {
        "lat": 16.6667,
        "lng": -9.6167
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 9390,
      "name": "Machinga",
      "names": {
        "zh": "马钦加"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -14.9667,
        "lng": 35.5167
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 9422,
      "name": "Xocali",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.9131,
        "lng": 46.7903
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 42181,
      "name": "Jaque",
      "names": {
        "zh": "哈克"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 7.519,
        "lng": -78.166
      },
      "country": "Panama",
      "importance": 2
    },
    {
      "id": 42178,
      "name": "Tarutung",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": 2.0167,
        "lng": 98.9667
      },
      "country": "Indonesia",
      "importance": 2
    },
    {
      "id": 9429,
      "name": "Santo Antonio",
      "names": {
        "ja": "サントアントニオ"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 1.6806,
        "lng": 7.4394
      },
      "country": "Sao Tome And Principe",
      "importance": 1
    },
    {
      "id": 9434,
      "name": "Novaci",
      "names": {
        "zh": "诺瓦奇"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.0419,
        "lng": 21.4561
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9437,
      "name": "Rosoman",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.5161,
        "lng": 21.9497
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9432,
      "name": "Novo Selo",
      "names": {
        "ja": "ノボセロ"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4128,
        "lng": 22.88
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 42186,
      "name": "Las Lajas",
      "names": {
        "zh": "拉马斯",
        "ja": "ラスラジアス"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -38.5208,
        "lng": -70.3667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9373,
      "name": "Kaabong",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 3.5204,
        "lng": 34.12
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 42193,
      "name": "Susques",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -23.4006,
        "lng": -66.3672
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9426,
      "name": "Schellenberg",
      "names": {},
      "countryCode": "LI",
      "latLng": {
        "lat": 47.2283,
        "lng": 9.5395
      },
      "country": "Liechtenstein",
      "importance": 1
    },
    {
      "id": 9458,
      "name": "Kobarid",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2471,
        "lng": 13.5796
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9460,
      "name": "Andrijevica",
      "names": {
        "zh": "安德里耶维察"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.7339,
        "lng": 19.7919
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 9451,
      "name": "Bosilovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4406,
        "lng": 22.7278
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 42147,
      "name": "Mazatan",
      "names": {},
      "countryCode": "MX",
      "latLng": {
        "lat": 29.0167,
        "lng": -110.1333
      },
      "country": "Mexico",
      "importance": 2
    },
    {
      "id": 834,
      "name": "Hagta",
      "names": {},
      "countryCode": "GU",
      "latLng": {
        "lat": 13.4745,
        "lng": 144.7504
      },
      "country": "Guam",
      "importance": 0
    },
    {
      "id": 9161,
      "name": "Soroti",
      "names": {
        "zh": "索罗提"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.6833,
        "lng": 33.6167
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9439,
      "name": "Oranjestad",
      "names": {
        "zh": "奥拉涅斯塔德",
        "it": "Libero stato dell\u0027orange",
        "ja": "オラニエスタッド"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 17.4833,
        "lng": -62.9833
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 828,
      "name": "Al Quds",
      "names": {
        "fr": "Alternatif",
        "zh": "alquds",
        "de": "Al-Quds",
        "it": "Albbes",
        "id": "Quds",
        "ja": "アルquds",
        "pt": "Quds al"
      },
      "countryCode": "XW",
      "latLng": {
        "lat": 31.7764,
        "lng": 35.2269
      },
      "country": "West Bank",
      "importance": 0
    },
    {
      "id": 835,
      "name": "Marigot",
      "names": {
        "zh": "马里戈特"
      },
      "countryCode": "MF",
      "latLng": {
        "lat": 18.0706,
        "lng": -63.0847
      },
      "country": "Saint Martin",
      "importance": 0
    },
    {
      "id": 838,
      "name": "Philipsburg",
      "names": {
        "zh": "菲利普斯堡",
        "th": "ฟิลิปส์เบิร์ก"
      },
      "countryCode": "SX",
      "latLng": {
        "lat": 18.0256,
        "lng": -63.0492
      },
      "country": "Sint Maarten",
      "importance": 0
    },
    {
      "id": 839,
      "name": "Yaren",
      "names": {},
      "countryCode": "NR",
      "latLng": {
        "lat": -0.5477,
        "lng": 166.9209
      },
      "country": "Nauru",
      "importance": 0
    },
    {
      "id": 840,
      "name": "Pristina",
      "names": {
        "zh": "普里什蒂纳",
        "ja": "プリシュティナ"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.6633,
        "lng": 21.1622
      },
      "country": "Kosovo",
      "importance": 0
    },
    {
      "id": 841,
      "name": "Gustavia",
      "names": {},
      "countryCode": "BL",
      "latLng": {
        "lat": 17.8958,
        "lng": -62.8508
      },
      "country": "Saint Barthelemy",
      "importance": 0
    },
    {
      "id": 842,
      "name": "Road Town",
      "names": {
        "es": "Ciudad de carretera",
        "fr": "Ville routière",
        "zh": "公路镇",
        "de": "Straßenstadt",
        "it": "Città stradale",
        "id": "Kota jalan",
        "ja": "ロードタウン",
        "th": "เมืองถนน",
        "pt": "Cidade rodoviária"
      },
      "countryCode": "VG",
      "latLng": {
        "lat": 18.4167,
        "lng": -64.6167
      },
      "country": "British Virgin Islands",
      "importance": 0
    },
    {
      "id": 844,
      "name": "Saint-Pierre",
      "names": {
        "zh": "圣皮埃尔",
        "ja": "サンピエール",
        "th": "เซนต์ปิแอร์"
      },
      "countryCode": "PM",
      "latLng": {
        "lat": 46.7811,
        "lng": -56.1764
      },
      "country": "Saint Pierre And Miquelon",
      "importance": 0
    },
    {
      "id": 845,
      "name": "The Valley",
      "names": {
        "es": "El valle",
        "fr": "La vallée",
        "zh": "山谷",
        "de": "Das Tal",
        "it": "La valle",
        "id": "Lembah",
        "ja": "谷",
        "th": "หุบเขา",
        "pt": "O vale"
      },
      "countryCode": "AI",
      "latLng": {
        "lat": 18.2167,
        "lng": -63.05
      },
      "country": "Anguilla",
      "importance": 0
    },
    {
      "id": 846,
      "name": "Mata-Utu",
      "names": {
        "zh": "马塔-乌图",
        "ja": "マタ-utu",
        "th": "มาต้า-utu"
      },
      "countryCode": "WF",
      "latLng": {
        "lat": -13.2825,
        "lng": -176.1736
      },
      "country": "Wallis And Futuna",
      "importance": 0
    },
    {
      "id": 847,
      "name": "Kingston",
      "names": {
        "zh": "金士顿",
        "ja": "キングストン",
        "th": "คิงส์ตัน"
      },
      "countryCode": "NF",
      "latLng": {
        "lat": -29.0569,
        "lng": 167.9617
      },
      "country": "Norfolk Island",
      "importance": 0
    },
    {
      "id": 848,
      "name": "Longyearbyen",
      "names": {
        "zh": "朗伊尔城"
      },
      "countryCode": "XR",
      "latLng": {
        "lat": 78.2167,
        "lng": 15.6333
      },
      "country": "Svalbard",
      "importance": 0
    },
    {
      "id": 849,
      "name": "Tifariti",
      "names": {},
      "countryCode": "MA",
      "latLng": {
        "lat": 26.0928,
        "lng": -10.6089
      },
      "country": "Morocco",
      "importance": 0
    },
    {
      "id": 850,
      "name": "Adamstown",
      "names": {
        "zh": "亚当斯敦"
      },
      "countryCode": "PN",
      "latLng": {
        "lat": -25.0667,
        "lng": -130.0833
      },
      "country": "Pitcairn Islands",
      "importance": 0
    },
    {
      "id": 851,
      "name": "Flying Fish Cove",
      "names": {
        "es": "Pescado volador",
        "fr": "Voling Fish Cove",
        "zh": "飞鱼湾",
        "de": "Fliegende Fischbucht",
        "it": "Baia di pesce volante",
        "id": "Flight Fish Cove",
        "ja": "フライングフィッシュコーブ",
        "pt": "Angra de peixe voador"
      },
      "countryCode": "CX",
      "latLng": {
        "lat": -10.4167,
        "lng": 105.7167
      },
      "country": "Christmas Island",
      "importance": 0
    },
    {
      "id": 42197,
      "name": "Yelimane",
      "names": {
        "zh": "耶利马内"
      },
      "countryCode": "ML",
      "latLng": {
        "lat": 15.1337,
        "lng": -10.5666
      },
      "country": "Mali",
      "importance": 2
    },
    {
      "id": 9308,
      "name": "Fontana",
      "names": {
        "zh": "丰塔纳",
        "ja": "フォンタナ",
        "th": "ตานา"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0364,
        "lng": 14.2422
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9470,
      "name": "Konce",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4958,
        "lng": 22.3825
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9488,
      "name": "Rostusa",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.61,
        "lng": 20.6
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 831,
      "name": "Vatican City",
      "names": {
        "es": "Ciudad del Vaticano",
        "fr": "Cité du Vatican",
        "zh": "梵蒂冈城",
        "de": "Vatikanische Stadt",
        "it": "Città del Vaticano",
        "id": "Kota Vatikan",
        "ja": "バチカン市",
        "th": "เมืองวาติกัน",
        "pt": "Cidade do vaticano"
      },
      "countryCode": "VA",
      "latLng": {
        "lat": 41.9033,
        "lng": 12.4534
      },
      "country": "Vatican City",
      "importance": 0
    },
    {
      "id": 9477,
      "name": "San Lawrenz",
      "names": {
        "es": "San Crudo",
        "zh": "圣劳伦茨",
        "ja": "サンローレンツ",
        "th": "ซาน Lawrenz"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 36.055,
        "lng": 14.2042
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9510,
      "name": "Sirvintos",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.047,
        "lng": 24.942
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9512,
      "name": "Rankovce",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.1719,
        "lng": 22.1167
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 836,
      "name": "Jamestown",
      "names": {
        "zh": "詹姆斯敦",
        "ja": "ジェームズタウン",
        "th": "เจมส์ทาวน์"
      },
      "countryCode": "SH",
      "latLng": {
        "lat": -15.9251,
        "lng": -5.7179
      },
      "country": "Saint Helena, Ascension, And Tristan Da Cunha",
      "importance": 0
    },
    {
      "id": 9520,
      "name": "Hvalba",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.6,
        "lng": -6.9556
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9517,
      "name": "Lakatoro",
      "names": {},
      "countryCode": "VU",
      "latLng": {
        "lat": -16.1069,
        "lng": 167.4208
      },
      "country": "Vanuatu",
      "importance": 1
    },
    {
      "id": 42222,
      "name": "Rodeo",
      "names": {
        "zh": "竞技",
        "ja": "ロデオ",
        "th": "ปศุสัตว์"
      },
      "countryCode": "AR",
      "latLng": {
        "lat": -30.2156,
        "lng": -69.14
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9379,
      "name": "Sopiste",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.95,
        "lng": 21.4333
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9471,
      "name": "Kostanjevica na Krki",
      "names": {
        "it": "Kostanjevica na kri"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8463,
        "lng": 15.4249
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 42216,
      "name": "Linxi",
      "names": {
        "zh": "林西"
      },
      "countryCode": "CN",
      "latLng": {
        "lat": 43.5171,
        "lng": 118.0333
      },
      "country": "China",
      "importance": 2
    },
    {
      "id": 9506,
      "name": "Oblesevo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.8839,
        "lng": 22.332
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9529,
      "name": "Karbinci",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.8167,
        "lng": 22.2375
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9522,
      "name": "Qubadli",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.3439,
        "lng": 46.5797
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9530,
      "name": "Vasilevo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4758,
        "lng": 22.6417
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9532,
      "name": "Bangar",
      "names": {
        "zh": "邦阿"
      },
      "countryCode": "BN",
      "latLng": {
        "lat": 4.7086,
        "lng": 115.0739
      },
      "country": "Brunei",
      "importance": 1
    },
    {
      "id": 42231,
      "name": "Gastre",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -42.2667,
        "lng": -69.2167
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 42232,
      "name": "Tinogasta",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -28.0667,
        "lng": -67.5667
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9513,
      "name": "Petrovec",
      "names": {
        "zh": "彼得罗韦茨"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9389,
        "lng": 21.615
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9553,
      "name": "Makedonski Brod",
      "names": {
        "ja": "マケドンスキーBROD"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.5133,
        "lng": 21.2153
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9559,
      "name": "Staro Nagoricane",
      "names": {
        "de": "Staro Nagorican"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.2,
        "lng": 21.83
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9438,
      "name": "Lata",
      "names": {
        "zh": "拉塔"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -10.738,
        "lng": 165.8567
      },
      "country": "Solomon Islands",
      "importance": 1
    },
    {
      "id": 42236,
      "name": "Telsen",
      "names": {},
      "countryCode": "AR",
      "latLng": {
        "lat": -42.3833,
        "lng": -66.95
      },
      "country": "Argentina",
      "importance": 2
    },
    {
      "id": 9561,
      "name": "Demir Hisar",
      "names": {
        "es": "Demir Herar",
        "zh": "Demir Misar",
        "id": "Hisar",
        "ja": "デミールハール",
        "pt": "Demir Har"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.2208,
        "lng": 21.2031
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9547,
      "name": "Ghasri",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0583,
        "lng": 14.2278
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9571,
      "name": "Lozovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.7817,
        "lng": 21.9025
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9538,
      "name": "The Bottom",
      "names": {
        "es": "La parte inferior",
        "fr": "Le fond",
        "zh": "底部",
        "de": "Der Boden",
        "it": "Il fondo",
        "id": "Bagian bawah",
        "ja": "ボトム",
        "th": "ด้านล่าง",
        "pt": "O fundo"
      },
      "countryCode": "NL",
      "latLng": {
        "lat": 17.6261,
        "lng": -63.2492
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 9556,
      "name": "Planken",
      "names": {},
      "countryCode": "LI",
      "latLng": {
        "lat": 47.1833,
        "lng": 9.5333
      },
      "country": "Liechtenstein",
      "importance": 1
    },
    {
      "id": 9575,
      "name": "Centar Zupa",
      "names": {
        "es": "Valles zupa",
        "fr": "Zupa Centatr",
        "ja": "中心ズパ",
        "th": "เซ็นาร์ซู่ซู่",
        "pt": "Zupa cento"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4775,
        "lng": 20.5578
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9503,
      "name": "Selibaby",
      "names": {},
      "countryCode": "MR",
      "latLng": {
        "lat": 15.167,
        "lng": -12.1833
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 9603,
      "name": "Brvenica",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9672,
        "lng": 20.9808
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9604,
      "name": "Fada",
      "names": {
        "zh": "法达"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 17.1833,
        "lng": 21.5833
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 9601,
      "name": "Dhuusamarreeb",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 5.5375,
        "lng": 46.3875
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 9586,
      "name": "Skopun",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.9125,
        "lng": -6.8719
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9605,
      "name": "Belcista",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.3028,
        "lng": 20.8303
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9608,
      "name": "Crna Trava",
      "names": {
        "th": "เกรียงไกรทราฟา",
        "pt": "CRNA TRAV"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 42.8101,
        "lng": 22.299
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9611,
      "name": "Hvannasund",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.2833,
        "lng": -6.5167
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9590,
      "name": "Gornji Petrovci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.8053,
        "lng": 16.2225
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9613,
      "name": "Tisina",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6556,
        "lng": 16.0933
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 837,
      "name": "Brades",
      "names": {},
      "countryCode": "MS",
      "latLng": {
        "lat": 16.7928,
        "lng": -62.2106
      },
      "country": "Montserrat",
      "importance": 0
    },
    {
      "id": 9622,
      "name": "Dolneni",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.4264,
        "lng": 21.4536
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9259,
      "name": "Moroto",
      "names": {
        "zh": "莫罗托"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.5403,
        "lng": 34.64
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9645,
      "name": "Akjoujt",
      "names": {},
      "countryCode": "MR",
      "latLng": {
        "lat": 19.747,
        "lng": -14.391
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 9580,
      "name": "Jegunovce",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0731,
        "lng": 21.1231
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 833,
      "name": "Basse-Terre",
      "names": {
        "zh": "巴斯 - 大地",
        "ja": "バース・テッレ",
        "th": "บาส-แตร์"
      },
      "countryCode": "GP",
      "latLng": {
        "lat": 16.0104,
        "lng": -61.7055
      },
      "country": "Guadeloupe",
      "importance": 0
    },
    {
      "id": 9673,
      "name": "Porkeri",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.4814,
        "lng": -6.7458
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9508,
      "name": "Imdina",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 35.8858,
        "lng": 14.4031
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 42259,
      "name": "Greytown",
      "names": {
        "zh": "格雷敦"
      },
      "countryCode": "NI",
      "latLng": {
        "lat": 10.9167,
        "lng": -83.7
      },
      "country": "Nicaragua",
      "importance": 2
    },
    {
      "id": 9671,
      "name": "Cucer-Sandevo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0975,
        "lng": 21.3877
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9686,
      "name": "Zelenikovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.8867,
        "lng": 21.5869
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 843,
      "name": "Ngerulmud",
      "names": {},
      "countryCode": "PW",
      "latLng": {
        "lat": 7.5006,
        "lng": 134.6242
      },
      "country": "Palau",
      "importance": 0
    },
    {
      "id": 9705,
      "name": "Kenge",
      "names": {},
      "countryCode": "CD",
      "latLng": {
        "lat": -4.8296,
        "lng": 16.8999
      },
      "country": "Congo (Kinshasa)",
      "importance": 1
    },
    {
      "id": 9657,
      "name": "Bistrica ob Sotli",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0569,
        "lng": 15.6625
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9695,
      "name": "Waitangi",
      "names": {
        "zh": "怀唐伊"
      },
      "countryCode": "NZ",
      "latLng": {
        "lat": -43.951,
        "lng": -176.561
      },
      "country": "New Zealand",
      "importance": 1
    },
    {
      "id": 42263,
      "name": "La Esmeralda",
      "names": {},
      "countryCode": "VE",
      "latLng": {
        "lat": 3.1738,
        "lng": -65.5466
      },
      "country": "Venezuela",
      "importance": 2
    },
    {
      "id": 9724,
      "name": "Zelino",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9794,
        "lng": 21.0619
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9853,
      "name": "Star Dojran",
      "names": {
        "es": "Estrella doyran",
        "zh": "明星莫德兰",
        "it": "Stella dojran",
        "id": "Bintang dojran",
        "ja": "スタードジュラン",
        "th": "ดาว Dojran",
        "pt": "Estrela dojran"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.1865,
        "lng": 22.7203
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 8862,
      "name": "Tevragh Zeina",
      "names": {
        "ja": "テヴラギゼーナ"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 18.0989,
        "lng": -15.9885
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 8997,
      "name": "Mahibadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 3.7571,
        "lng": 72.9689
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9019,
      "name": "Eydhafushi",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 5.1038,
        "lng": 73.0699
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9055,
      "name": "Funadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 6.1509,
        "lng": 73.2901
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9129,
      "name": "Koboko",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 3.4136,
        "lng": 30.9599
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9143,
      "name": "Kanifing",
      "names": {},
      "countryCode": "GM",
      "latLng": {
        "lat": 13.45,
        "lng": -16.6667
      },
      "country": "The Gambia",
      "importance": 1
    },
    {
      "id": 9150,
      "name": "Kiambu",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -1.1714,
        "lng": 36.8356
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9209,
      "name": "Hung Yen",
      "names": {
        "es": "Yen",
        "fr": "Yen accroché",
        "zh": "挂日元",
        "de": "Aufgehängt",
        "it": "Schiatoio",
        "id": "Menggantung yen",
        "ja": "ハングした円",
        "th": "แขวนเยน",
        "pt": "Pendurado iene"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.6464,
        "lng": 106.0511
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 9211,
      "name": "Gambela",
      "names": {
        "zh": "甘贝拉"
      },
      "countryCode": "ET",
      "latLng": {
        "lat": 8.25,
        "lng": 34.5833
      },
      "country": "Ethiopia",
      "importance": 1
    },
    {
      "id": 9228,
      "name": "Joao Teves",
      "names": {
        "id": "Teves Joao"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.0669,
        "lng": -23.5892
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 9250,
      "name": "Bugiri",
      "names": {
        "zh": "布吉里"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.5714,
        "lng": 33.7417
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9258,
      "name": "Kudahuvadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 2.6717,
        "lng": 72.8936
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9268,
      "name": "Xai",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 20.7,
        "lng": 101.8167
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 9270,
      "name": "Tutin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.9875,
        "lng": 20.3256
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9274,
      "name": "Hoima",
      "names": {
        "zh": "霍伊马"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.4356,
        "lng": 31.3436
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9276,
      "name": "Kulhudhuffushi",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 6.6223,
        "lng": 73.0702
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9283,
      "name": "Thinadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 0.5303,
        "lng": 72.9967
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9288,
      "name": "Balaka",
      "names": {
        "zh": "巴拉卡",
        "th": "บาลากา"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -14.9889,
        "lng": 34.9591
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 9289,
      "name": "Banlung",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 13.7394,
        "lng": 106.9873
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 9296,
      "name": "Rumphi",
      "names": {
        "zh": "龙皮"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -11.0153,
        "lng": 33.7722
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 9298,
      "name": "Tallaght",
      "names": {
        "zh": "塔拉特"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.2878,
        "lng": -6.3411
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9305,
      "name": "Wicklow",
      "names": {
        "zh": "威克洛",
        "th": "วิกโคลว์"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 52.9779,
        "lng": -6.033
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9306,
      "name": "Thulusdhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 4.3742,
        "lng": 73.6517
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9307,
      "name": "Arima",
      "names": {
        "zh": "阿里马",
        "ja": "有馬"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.6374,
        "lng": -61.2823
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 9309,
      "name": "Ta Khmau",
      "names": {
        "th": "ตาคาโม"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 11.4833,
        "lng": 104.95
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 9313,
      "name": "Arandelovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3042,
        "lng": 20.5561
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9314,
      "name": "Rasdhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 4.2631,
        "lng": 72.9919
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9315,
      "name": "Dhihdhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 6.8874,
        "lng": 73.114
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9318,
      "name": "Ar Rayyan",
      "names": {
        "es": "Rayyan"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.2919,
        "lng": 51.4244
      },
      "country": "Qatar",
      "importance": 1
    },
    {
      "id": 9322,
      "name": "Bariadi",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -2.8,
        "lng": 33.9833
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 9327,
      "name": "Wakiso",
      "names": {
        "zh": "瓦基索"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.4044,
        "lng": 32.4594
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9328,
      "name": "Kapchorwa",
      "names": {
        "zh": "卡普乔鲁瓦"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.3965,
        "lng": 34.4509
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9329,
      "name": "Ivanjica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.5811,
        "lng": 20.2297
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9333,
      "name": "Kabarnet",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.4919,
        "lng": 35.743
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9335,
      "name": "Phu Ly",
      "names": {
        "fr": "Phu",
        "th": "ภูสด",
        "pt": "Phu"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 20.5453,
        "lng": 105.9122
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 9338,
      "name": "Bundibugyo",
      "names": {
        "zh": "本迪布焦"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.7085,
        "lng": 30.0634
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9339,
      "name": "Yumbe",
      "names": {
        "zh": "永贝"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.4651,
        "lng": 31.2469
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9343,
      "name": "Mukono",
      "names": {
        "zh": "穆科诺"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.3533,
        "lng": 32.7553
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9344,
      "name": "Sihanoukville",
      "names": {
        "th": "สีหนุวิลล์"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 10.6333,
        "lng": 103.5
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 9349,
      "name": "Priboj",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.5836,
        "lng": 19.5258
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9350,
      "name": "Clonmel",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 52.355,
        "lng": -7.7039
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9351,
      "name": "Migori",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -1.0634,
        "lng": 34.4731
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9352,
      "name": "Phalombe",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -15.8,
        "lng": 35.65
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 9353,
      "name": "Iten",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.6703,
        "lng": 35.5081
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9357,
      "name": "Jagodina",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.975,
        "lng": 21.2564
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9358,
      "name": "Kerugoya",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.4989,
        "lng": 37.2803
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9359,
      "name": "Veymandoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 2.1878,
        "lng": 73.095
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9366,
      "name": "Buchanan",
      "names": {
        "zh": "布坎南",
        "ja": "ブキャナン",
        "th": "ทอมบูแคนาน"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 5.8811,
        "lng": -10.0447
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 9371,
      "name": "Viligili",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 0.7539,
        "lng": 73.4353
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9378,
      "name": "Nyamira",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.5633,
        "lng": 34.9358
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9380,
      "name": "Trim",
      "names": {
        "es": "Recorte",
        "fr": "Garniture",
        "zh": "修剪",
        "id": "Memangkas",
        "ja": "トリム",
        "th": "ตัด",
        "pt": "Guarnição"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.555,
        "lng": -6.7917
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9382,
      "name": "Lyantonde",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.4031,
        "lng": 31.1572
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9385,
      "name": "Hithadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": -0.6,
        "lng": 73.0833
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9386,
      "name": "Kirakira",
      "names": {
        "zh": "基拉基拉"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -10.4544,
        "lng": 161.9205
      },
      "country": "Solomon Islands",
      "importance": 1
    },
    {
      "id": 9387,
      "name": "Sabac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.7558,
        "lng": 19.6939
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9388,
      "name": "Auki",
      "names": {
        "zh": "奥基"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -8.7678,
        "lng": 160.6978
      },
      "country": "Solomon Islands",
      "importance": 1
    },
    {
      "id": 9389,
      "name": "Dar Naim",
      "names": {
        "zh": "达纳姆",
        "de": "Darnaim",
        "ja": "ダルナイム",
        "th": "ดาร์"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 18.0973,
        "lng": -15.9327
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 9391,
      "name": "Carrick on Shannon",
      "names": {
        "es": "Carrick en Shannon",
        "fr": "Carrick sur Shannon",
        "zh": "香农卡里克",
        "de": "Carrick auf Shannon",
        "it": "Carrick su Shannon",
        "id": "Carrick di Shannon",
        "ja": "シャノンのキャリック",
        "th": "carrick บนแชนนอน"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.9469,
        "lng": -8.09
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9394,
      "name": "Vinh Yen",
      "names": {
        "zh": "vinh日元",
        "ja": "ヴィン円",
        "th": "vinh เยน"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 21.31,
        "lng": 105.5967
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 9401,
      "name": "Smederevo",
      "names": {
        "zh": "斯梅代雷沃"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.6633,
        "lng": 20.9289
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9402,
      "name": "Fomboni",
      "names": {},
      "countryCode": "KM",
      "latLng": {
        "lat": -12.2822,
        "lng": 43.7419
      },
      "country": "Comoros",
      "importance": 1
    },
    {
      "id": 9403,
      "name": "Bududa",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.0112,
        "lng": 34.3311
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9404,
      "name": "Arilje",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.7531,
        "lng": 20.0956
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9406,
      "name": "Vladicin Han",
      "names": {
        "zh": "汉语汉族"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 42.7,
        "lng": 22.0667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9407,
      "name": "Sotik Post",
      "names": {
        "es": "Publicación de Sotik",
        "pt": "Post"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.7813,
        "lng": 35.3416
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9408,
      "name": "Felidhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 3.4718,
        "lng": 73.547
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9410,
      "name": "Nova Varos",
      "names": {
        "ja": "ノヴァヴァルス"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.4667,
        "lng": 19.8203
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9412,
      "name": "Tullamore",
      "names": {
        "zh": "塔拉莫尔"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.2667,
        "lng": -7.5
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9413,
      "name": "Manafwa",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9784,
        "lng": 34.3743
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9414,
      "name": "Murang\u0027a",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -0.721,
        "lng": 37.1526
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9415,
      "name": "Foammulah",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": -0.3,
        "lng": 73.4256
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9418,
      "name": "Kursumlija",
      "names": {
        "zh": "库尔舒姆利亚"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.1408,
        "lng": 21.2678
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9419,
      "name": "Aleksandrovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.4553,
        "lng": 21.0514
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9420,
      "name": "Xekong",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 15.3503,
        "lng": 106.7286
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 9421,
      "name": "Castlebar",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 53.85,
        "lng": -9.3
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9423,
      "name": "Nilandhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 3.0567,
        "lng": 72.89
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9424,
      "name": "Pakxan",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 18.3831,
        "lng": 103.6669
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 9425,
      "name": "Taro",
      "names": {
        "zh": "芋头",
        "ja": "太郎",
        "th": "เผือก"
      },
      "countryCode": "SB",
      "latLng": {
        "lat": -6.7111,
        "lng": 156.3972
      },
      "country": "Solomon Islands",
      "importance": 1
    },
    {
      "id": 9427,
      "name": "Kurunegala",
      "names": {},
      "countryCode": "LK",
      "latLng": {
        "lat": 7.4833,
        "lng": 80.3667
      },
      "country": "Sri Lanka",
      "importance": 1
    },
    {
      "id": 9428,
      "name": "Vreed-en-Hoop",
      "names": {
        "es": "Vreed-en-aro",
        "zh": "vreed-连接箍",
        "ja": "vreed-専用フープ",
        "th": "vreed-ห้องห่วง"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.8,
        "lng": -58.1833
      },
      "country": "Guyana",
      "importance": 1
    },
    {
      "id": 9430,
      "name": "Gornji Milanovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.0212,
        "lng": 20.456
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9431,
      "name": "Svrljig",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.4167,
        "lng": 22.1167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9433,
      "name": "Tam Ky",
      "names": {
        "zh": "谭凯",
        "ja": "タムキー",
        "pt": "Tambor"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 15.5667,
        "lng": 108.4833
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 9435,
      "name": "Luwero",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.8492,
        "lng": 32.4731
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9436,
      "name": "Radovljica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3444,
        "lng": 14.1744
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9440,
      "name": "Mullingar",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 53.5333,
        "lng": -7.35
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9441,
      "name": "Mayuge",
      "names": {
        "zh": "马尤盖"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.4578,
        "lng": 33.4806
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9442,
      "name": "`Amran",
      "names": {
        "zh": "`阿姆兰"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 15.6594,
        "lng": 43.9439
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 9443,
      "name": "Ain Temouchent",
      "names": {
        "es": "Ain Temauchent",
        "fr": "Ain Temoucher",
        "id": "Ain Temouchl",
        "ja": "AINテーマ？",
        "th": "temouchent ain",
        "pt": "TEMOUTHENT AIN"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 35.3044,
        "lng": -1.14
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 9444,
      "name": "Raska",
      "names": {
        "zh": "拉什卡"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2856,
        "lng": 20.6092
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9445,
      "name": "Longford",
      "names": {
        "zh": "朗福德",
        "th": "ลองฟอร์ด"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.727,
        "lng": -7.7998
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9446,
      "name": "Pirot",
      "names": {
        "zh": "皮罗特"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.1519,
        "lng": 22.585
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9447,
      "name": "Serravalle",
      "names": {
        "zh": "塞拉瓦莱"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9683,
        "lng": 12.4811
      },
      "country": "San Marino",
      "importance": 1
    },
    {
      "id": 9448,
      "name": "Prokuplje",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2339,
        "lng": 21.5861
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9449,
      "name": "Brus",
      "names": {
        "zh": "布鲁斯"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.3836,
        "lng": 21.0336
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9450,
      "name": "Surdulica",
      "names": {
        "zh": "苏尔杜利察"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 42.695,
        "lng": 22.1672
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9452,
      "name": "Trbovlje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.155,
        "lng": 15.0533
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9453,
      "name": "Ivancna Gorica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9383,
        "lng": 14.8044
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9454,
      "name": "Nenagh",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 52.8619,
        "lng": -8.1967
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9455,
      "name": "Ub",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.45,
        "lng": 20.0667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9456,
      "name": "Lucani",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8667,
        "lng": 20.1333
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9457,
      "name": "Muli",
      "names": {
        "zh": "穆利"
      },
      "countryCode": "MV",
      "latLng": {
        "lat": 2.9217,
        "lng": 73.5811
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9459,
      "name": "Labuan",
      "names": {
        "zh": "纳闽",
        "ja": "ラブアン",
        "th": "ลาบวน"
      },
      "countryCode": "MY",
      "latLng": {
        "lat": 5.2803,
        "lng": 115.2475
      },
      "country": "Malaysia",
      "importance": 1
    },
    {
      "id": 9461,
      "name": "Halba",
      "names": {
        "zh": "哈勒巴"
      },
      "countryCode": "LB",
      "latLng": {
        "lat": 34.5428,
        "lng": 36.0797
      },
      "country": "Lebanon",
      "importance": 1
    },
    {
      "id": 9462,
      "name": "Pozega",
      "names": {
        "zh": "波热加"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8459,
        "lng": 20.0368
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9463,
      "name": "Neno",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -15.3981,
        "lng": 34.6534
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 9464,
      "name": "Isale",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -3.3444,
        "lng": 29.4833
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 9465,
      "name": "Smarje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2272,
        "lng": 15.5192
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9466,
      "name": "Siaya",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.0607,
        "lng": 34.2881
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9467,
      "name": "Dien Bien Phu",
      "names": {},
      "countryCode": "VN",
      "latLng": {
        "lat": 21.3924,
        "lng": 103.016
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 9468,
      "name": "Mahonda",
      "names": {
        "zh": "马洪达"
      },
      "countryCode": "TZ",
      "latLng": {
        "lat": -5.9897,
        "lng": 39.2519
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 9469,
      "name": "Store",
      "names": {
        "es": "Tienda",
        "fr": "Boutique",
        "zh": "店",
        "de": "Geschäft",
        "id": "Toko",
        "ja": "店",
        "th": "ร้านค้า",
        "pt": "Loja"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2208,
        "lng": 15.3139
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9472,
      "name": "Smederevska Palanka",
      "names": {
        "fr": "Smedevska Palanka"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3655,
        "lng": 20.9587
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9473,
      "name": "Cerklje na Gorenjskem",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2542,
        "lng": 14.4886
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9474,
      "name": "Kasungu",
      "names": {
        "zh": "卡松古"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -13.0364,
        "lng": 33.4822
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 9475,
      "name": "Rogaska Slatina",
      "names": {
        "ja": "ログカスカスラティナ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2375,
        "lng": 15.6397
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9476,
      "name": "Chikwawa",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -16.035,
        "lng": 34.801
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 9478,
      "name": "Sostanj",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.38,
        "lng": 15.0486
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9479,
      "name": "Namutumba",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.8363,
        "lng": 33.6858
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9480,
      "name": "Fonadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 1.8342,
        "lng": 73.5031
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9481,
      "name": "Port Laoise",
      "names": {
        "fr": "Port de port",
        "zh": "洛伊斯港",
        "de": "Anschlusshaken",
        "ja": "ポートラオアス",
        "th": "พอร์ตลาว",
        "pt": "Porto laoise"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.0309,
        "lng": -7.3008
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9482,
      "name": "Rumonge",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -3.9736,
        "lng": 29.4386
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 9483,
      "name": "Babusnica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.068,
        "lng": 22.4115
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9484,
      "name": "Vlasotince",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.9667,
        "lng": 22.1333
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9485,
      "name": "Ljubovija",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.1869,
        "lng": 19.3728
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9486,
      "name": "Manadhoo",
      "names": {},
      "countryCode": "MV",
      "latLng": {
        "lat": 5.7669,
        "lng": 73.4136
      },
      "country": "Maldives",
      "importance": 1
    },
    {
      "id": 9487,
      "name": "Lajkovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3667,
        "lng": 20.1667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9489,
      "name": "Scarborough",
      "names": {
        "zh": "斯卡伯勒",
        "ja": "スカーバラ",
        "th": "สการ์เบอโร"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 11.1811,
        "lng": -60.7333
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 9490,
      "name": "An Cabhan",
      "names": {
        "es": "Un cabañal",
        "fr": "Un cabhan",
        "zh": "卡哈山",
        "de": "Ein Cabhan",
        "it": "Un cabhan",
        "id": "Seorang cabang",
        "ja": "カバン",
        "th": "รถกะหล่ำปลี",
        "pt": "Um cabrão"
      },
      "countryCode": "IE",
      "latLng": {
        "lat": 53.9908,
        "lng": -7.3606
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9491,
      "name": "Kosjeric",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.0,
        "lng": 19.9167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9492,
      "name": "Mionica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.25,
        "lng": 20.0833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9493,
      "name": "Al Khawr",
      "names": {
        "ja": "アルカウル",
        "th": "อัล khawr"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.6839,
        "lng": 51.5058
      },
      "country": "Qatar",
      "importance": 1
    },
    {
      "id": 9494,
      "name": "Kaliro",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.8949,
        "lng": 33.5048
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9495,
      "name": "Bela Palanka",
      "names": {
        "es": "Palanka",
        "ja": "ベラパルランカ"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2178,
        "lng": 22.3067
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9496,
      "name": "Asaba",
      "names": {
        "zh": "阿萨巴"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.1833,
        "lng": 6.75
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 9497,
      "name": "Eenhana",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -17.4797,
        "lng": 16.3333
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 9498,
      "name": "Amudat",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.95,
        "lng": 34.95
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9499,
      "name": "Ain Defla",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.2583,
        "lng": 1.9583
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 9500,
      "name": "Bukwo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.3,
        "lng": 34.75
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9501,
      "name": "Diego Martin",
      "names": {
        "zh": "迭戈马丁",
        "de": "Wütende",
        "th": "ดิเอโกมาร์ติน"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.7167,
        "lng": -61.5667
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 9502,
      "name": "Prebold",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2369,
        "lng": 15.0925
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9504,
      "name": "Point Fortin",
      "names": {
        "es": "Fortina de punto",
        "fr": "Point fort",
        "zh": "指向福特印度",
        "de": "Punktieren",
        "it": "Punto fortin",
        "id": "Fortin",
        "ja": "ポイントフォーチン",
        "th": "จุด fortin",
        "pt": "Ponto fortin"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.1741,
        "lng": -61.6841
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 9505,
      "name": "Madaba",
      "names": {
        "zh": "马达巴"
      },
      "countryCode": "JO",
      "latLng": {
        "lat": 31.7167,
        "lng": 35.8
      },
      "country": "Jordan",
      "importance": 1
    },
    {
      "id": 9507,
      "name": "Tulagi",
      "names": {},
      "countryCode": "SB",
      "latLng": {
        "lat": -9.1031,
        "lng": 160.1506
      },
      "country": "Solomon Islands",
      "importance": 1
    },
    {
      "id": 9509,
      "name": "Kapenguria",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 1.2389,
        "lng": 35.1119
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9511,
      "name": "Kibiito",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.4772,
        "lng": 30.1925
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9514,
      "name": "Yenagoa",
      "names": {},
      "countryCode": "NG",
      "latLng": {
        "lat": 4.9267,
        "lng": 6.2676
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 9515,
      "name": "Varazdin",
      "names": {
        "it": "Varaždin"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 46.3,
        "lng": 16.3333
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 9516,
      "name": "Umm Salal `Ali",
      "names": {
        "zh": "嗯salal`阿里",
        "de": "Ähm Salal `ali",
        "th": "อืมนัล `อาลี"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.4697,
        "lng": 51.3975
      },
      "country": "Qatar",
      "importance": 1
    },
    {
      "id": 9518,
      "name": "Kibuku",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.0433,
        "lng": 33.7975
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9519,
      "name": "Sremska Mitrovica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.9661,
        "lng": 19.6106
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9521,
      "name": "Miklavz na Dravskem Polju",
      "names": {
        "pt": "Miklavz na drravskem polju"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5058,
        "lng": 15.6972
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9523,
      "name": "Aranguez",
      "names": {},
      "countryCode": "TT",
      "latLng": {
        "lat": 10.6472,
        "lng": -61.4461
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 9524,
      "name": "Maracha",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 3.2704,
        "lng": 30.9553
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9525,
      "name": "Mozirje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3394,
        "lng": 14.9633
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9526,
      "name": "Pozarevac",
      "names": {
        "zh": "波扎雷瓦茨"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.62,
        "lng": 21.1897
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9527,
      "name": "Krupanj",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3667,
        "lng": 19.3667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9528,
      "name": "Lai Chau",
      "names": {
        "zh": "赖洲",
        "th": "สายพันธุ์"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 22.3991,
        "lng": 103.4393
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 9531,
      "name": "Polzela",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2833,
        "lng": 15.0667
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9533,
      "name": "Knjazevac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.5,
        "lng": 22.4333
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9534,
      "name": "Dong Xoai",
      "names": {
        "zh": "董泽",
        "th": "ดง xoai"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.5349,
        "lng": 106.8832
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 9535,
      "name": "Picos",
      "names": {
        "zh": "皮库斯"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 15.0833,
        "lng": -23.65
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 9536,
      "name": "Zajecar",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.9042,
        "lng": 22.2847
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9537,
      "name": "Paracin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8667,
        "lng": 21.4167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9539,
      "name": "Lebane",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.9167,
        "lng": 21.7333
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9540,
      "name": "Dowa",
      "names": {
        "zh": "多瓦"
      },
      "countryCode": "MW",
      "latLng": {
        "lat": -13.6532,
        "lng": 33.9385
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 9541,
      "name": "Cibitoke",
      "names": {},
      "countryCode": "BI",
      "latLng": {
        "lat": -2.8869,
        "lng": 29.1248
      },
      "country": "Burundi",
      "importance": 1
    },
    {
      "id": 9542,
      "name": "Buikwe",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.3375,
        "lng": 33.0106
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9543,
      "name": "Kagadi",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9378,
        "lng": 30.8089
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9544,
      "name": "Borgo Maggiore",
      "names": {
        "ja": "ボルゴマッジョーレ"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9408,
        "lng": 12.4475
      },
      "country": "San Marino",
      "importance": 1
    },
    {
      "id": 9545,
      "name": "Da Lat",
      "names": {
        "zh": "大拉特",
        "th": "ดาลาด",
        "pt": "Do lat"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 11.9359,
        "lng": 108.4429
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 9546,
      "name": "Binyin",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.4167,
        "lng": 34.5333
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9548,
      "name": "Raca",
      "names": {
        "zh": "拉查"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2333,
        "lng": 20.9833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9549,
      "name": "Mila",
      "names": {
        "zh": "米拉",
        "de": "Mlla",
        "ja": "ミラ",
        "th": "มิลา"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 36.4481,
        "lng": 6.2622
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 9550,
      "name": "Bulambuli",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.1667,
        "lng": 34.3833
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9551,
      "name": "Suong",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 11.9118,
        "lng": 105.6582
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 9552,
      "name": "Aileu",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.7281,
        "lng": 125.5664
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 9554,
      "name": "Aleksinac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.5383,
        "lng": 21.7047
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9555,
      "name": "Vwawa",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -9.1081,
        "lng": 32.9347
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 9557,
      "name": "Al Buraymi",
      "names": {
        "fr": "Al Burayme",
        "ja": "アルバイミ",
        "th": "อัลบูรณ"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 24.2558,
        "lng": 55.8025
      },
      "country": "Oman",
      "importance": 1
    },
    {
      "id": 9558,
      "name": "Medvode",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1333,
        "lng": 14.4333
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9560,
      "name": "Osecina",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3667,
        "lng": 19.6
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9562,
      "name": "Weno",
      "names": {},
      "countryCode": "FM",
      "latLng": {
        "lat": 7.45,
        "lng": 151.85
      },
      "country": "Federated States of Micronesia",
      "importance": 1
    },
    {
      "id": 9563,
      "name": "Komenda",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2038,
        "lng": 14.5407
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9564,
      "name": "Jincheng",
      "names": {
        "zh": "金城",
        "ja": "金城"
      },
      "countryCode": "TW",
      "latLng": {
        "lat": 24.4167,
        "lng": 118.3167
      },
      "country": "Taiwan",
      "importance": 1
    },
    {
      "id": 9565,
      "name": "Brezovica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0333,
        "lng": 14.4
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9566,
      "name": "Smartno",
      "names": {
        "it": "Šmartno"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0444,
        "lng": 14.8442
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9567,
      "name": "Moravce",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1369,
        "lng": 14.745
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9568,
      "name": "Despotovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.0833,
        "lng": 21.4333
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9569,
      "name": "Cajetina",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.75,
        "lng": 19.7167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9570,
      "name": "Namayingo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.2398,
        "lng": 33.8849
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9572,
      "name": "Apac",
      "names": {
        "fr": "Asie-Pacifique",
        "zh": "亚太地区",
        "ja": "アジア太平洋地域",
        "th": "ในภูมิภาคเอเชียแปซิฟิก"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 1.9845,
        "lng": 32.534
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9573,
      "name": "Skofljica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9833,
        "lng": 14.5767
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9574,
      "name": "Santa Cruz",
      "names": {
        "zh": "圣克鲁斯",
        "ja": "サンタクルーズ",
        "th": "ซานตาครูซ"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 14.1167,
        "lng": 121.2833
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 9576,
      "name": "Domagnano",
      "names": {
        "th": "โดมัญญาโน"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9492,
        "lng": 12.4686
      },
      "country": "San Marino",
      "importance": 1
    },
    {
      "id": 9577,
      "name": "Ljig",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2213,
        "lng": 20.2375
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9578,
      "name": "Indija",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.0492,
        "lng": 20.0792
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9579,
      "name": "Poljcane",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3119,
        "lng": 15.5792
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9581,
      "name": "Menges",
      "names": {
        "zh": "门杰斯"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1669,
        "lng": 14.575
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9582,
      "name": "Butebo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.1942,
        "lng": 33.9222
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9583,
      "name": "Koani",
      "names": {},
      "countryCode": "TZ",
      "latLng": {
        "lat": -6.1333,
        "lng": 39.2833
      },
      "country": "Tanzania",
      "importance": 1
    },
    {
      "id": 9584,
      "name": "Tolmin",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1864,
        "lng": 13.7361
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9585,
      "name": "Varvarin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.7167,
        "lng": 21.3667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9587,
      "name": "Buala",
      "names": {},
      "countryCode": "SB",
      "latLng": {
        "lat": -8.1448,
        "lng": 159.5905
      },
      "country": "Solomon Islands",
      "importance": 1
    },
    {
      "id": 9588,
      "name": "Ogujin",
      "names": {},
      "countryCode": "KP",
      "latLng": {
        "lat": 38.8028,
        "lng": 125.5925
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 9589,
      "name": "Samga-ri",
      "names": {
        "it": "Samga ri-"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 38.878,
        "lng": 125.5871
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 9591,
      "name": "Preddvor",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3025,
        "lng": 14.4231
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9592,
      "name": "Ash Shihaniyah",
      "names": {
        "id": "Abu shihaniyah",
        "ja": "灰Shihaniyah",
        "pt": "Cinza shihaniyah"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.3722,
        "lng": 51.2047
      },
      "country": "Qatar",
      "importance": 1
    },
    {
      "id": 9593,
      "name": "Kiryandongo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.8763,
        "lng": 32.0622
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9594,
      "name": "Nazarje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3176,
        "lng": 14.9467
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9595,
      "name": "Kon Tum",
      "names": {
        "fr": "Top kon",
        "de": "Konzentrieren",
        "id": "Kon",
        "ja": "コーントン"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 14.3544,
        "lng": 108.0075
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 9596,
      "name": "Zrece",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3833,
        "lng": 15.3667
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9597,
      "name": "Acquaviva",
      "names": {
        "zh": "阿夸维瓦"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9453,
        "lng": 12.4183
      },
      "country": "San Marino",
      "importance": 1
    },
    {
      "id": 9598,
      "name": "Busesa",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.6263,
        "lng": 33.6003
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9599,
      "name": "Butaleja",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9166,
        "lng": 33.9563
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9600,
      "name": "Dragomer",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0167,
        "lng": 14.3833
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9602,
      "name": "Miren",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8956,
        "lng": 13.6075
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9606,
      "name": "Radenci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6472,
        "lng": 16.0442
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9607,
      "name": "Spodnji Duplek",
      "names": {
        "pt": "Duplek de spodnji"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5031,
        "lng": 15.7453
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9609,
      "name": "Mali Zvornik",
      "names": {
        "zh": "马里Zvornik",
        "ja": "マリズボルン",
        "th": "มาลี zvornik"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3992,
        "lng": 19.1214
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9610,
      "name": "Kanal",
      "names": {
        "zh": "运河",
        "th": "ช่อง"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0886,
        "lng": 13.6397
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9612,
      "name": "Afega",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.7973,
        "lng": -171.8531
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 9614,
      "name": "Muta",
      "names": {
        "zh": "变异",
        "ja": "牟田"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6114,
        "lng": 15.1661
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9615,
      "name": "Bukomansimbi",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.1578,
        "lng": 31.6042
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9616,
      "name": "Dogbo",
      "names": {},
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.8167,
        "lng": 1.7833
      },
      "country": "Benin",
      "importance": 1
    },
    {
      "id": 9617,
      "name": "Selnica ob Dravi",
      "names": {
        "de": "Selnica Obs Dravi",
        "id": "Selnica obravi"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.55,
        "lng": 15.495
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9618,
      "name": "Fiorentino",
      "names": {
        "zh": "奥伦蒂诺",
        "ja": "フィオレンティーノ",
        "th": "เรนติโน"
      },
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9092,
        "lng": 12.4581
      },
      "country": "San Marino",
      "importance": 1
    },
    {
      "id": 9619,
      "name": "Luuka Town",
      "names": {
        "es": "Ciudad de luka",
        "fr": "Ville de Luuka",
        "zh": "鲁瓦卡镇",
        "it": "Luca Città",
        "id": "Kota Luuka",
        "ja": "ルカの町",
        "th": "เมือง luuka",
        "pt": "Cidade de luuka"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.7008,
        "lng": 33.3002
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9620,
      "name": "Spodnje Hoce",
      "names": {
        "es": "Hoche Spodnje",
        "id": "SPOTNJE HOCE",
        "th": "สปิปนเจ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5,
        "lng": 15.65
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9621,
      "name": "Sentrupert",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9778,
        "lng": 15.0956
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9623,
      "name": "Abakaliki",
      "names": {
        "zh": "阿巴卡利基"
      },
      "countryCode": "NG",
      "latLng": {
        "lat": 6.3249,
        "lng": 8.1137
      },
      "country": "Nigeria",
      "importance": 1
    },
    {
      "id": 9624,
      "name": "Ljutomer",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5208,
        "lng": 16.1975
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9625,
      "name": "Namsan",
      "names": {
        "zh": "南山"
      },
      "countryCode": "KP",
      "latLng": {
        "lat": 38.3442,
        "lng": 125.5972
      },
      "country": "North Korea",
      "importance": 1
    },
    {
      "id": 9626,
      "name": "Blace",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2906,
        "lng": 21.2847
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9627,
      "name": "Stari Trg",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7128,
        "lng": 14.4694
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9628,
      "name": "Ruma",
      "names": {
        "zh": "鲁马"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.0031,
        "lng": 19.8289
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9629,
      "name": "Doljevac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.1968,
        "lng": 21.8334
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9630,
      "name": "Pombas",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 17.1503,
        "lng": -25.0201
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 9631,
      "name": "Az Za`ayin",
      "names": {
        "pt": "Az Za`yin"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 25.5669,
        "lng": 51.4847
      },
      "country": "Qatar",
      "importance": 1
    },
    {
      "id": 9632,
      "name": "Batocina",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.15,
        "lng": 21.0833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9633,
      "name": "Mwatate",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -3.505,
        "lng": 38.3772
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9634,
      "name": "Colonia",
      "names": {
        "zh": "科洛尼亚",
        "ja": "コロニア",
        "th": "โคโลเนีย"
      },
      "countryCode": "FM",
      "latLng": {
        "lat": 9.5144,
        "lng": 138.1292
      },
      "country": "Federated States of Micronesia",
      "importance": 1
    },
    {
      "id": 9635,
      "name": "Miragoane",
      "names": {},
      "countryCode": "HT",
      "latLng": {
        "lat": 18.4411,
        "lng": -73.0883
      },
      "country": "Haiti",
      "importance": 1
    },
    {
      "id": 9636,
      "name": "Ferizaj",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3667,
        "lng": 21.1667
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9637,
      "name": "Race",
      "names": {
        "es": "Raza",
        "fr": "Course",
        "zh": "竞赛",
        "de": "Rennen",
        "it": "Gara",
        "id": "Ras",
        "ja": "レース",
        "th": "การแข่งขัน",
        "pt": "Raça"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4519,
        "lng": 15.6814
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9638,
      "name": "Sencur",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2456,
        "lng": 14.4197
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9639,
      "name": "Oplotnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3878,
        "lng": 15.4467
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9640,
      "name": "Debe",
      "names": {},
      "countryCode": "TT",
      "latLng": {
        "lat": 10.2,
        "lng": -61.45
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 9641,
      "name": "Wote",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -1.7808,
        "lng": 37.6288
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9642,
      "name": "Serere",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.518,
        "lng": 33.4589
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9643,
      "name": "Zombo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.5135,
        "lng": 30.9091
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9644,
      "name": "Nsiika",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.3831,
        "lng": 30.465
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9646,
      "name": "Smartno",
      "names": {
        "it": "Šmartno"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3333,
        "lng": 15.0333
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9647,
      "name": "Negotin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2167,
        "lng": 22.5167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9648,
      "name": "Lifford",
      "names": {},
      "countryCode": "IE",
      "latLng": {
        "lat": 54.8356,
        "lng": -7.4779
      },
      "country": "Ireland",
      "importance": 1
    },
    {
      "id": 9649,
      "name": "Rubanda",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -1.1883,
        "lng": 29.8461
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9650,
      "name": "Ad Dali`",
      "names": {
        "zh": "广告dali`",
        "ja": "広告Dali`",
        "th": "โฆษณา Dali`"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 13.6957,
        "lng": 44.7314
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 9651,
      "name": "Kajiado",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": -1.85,
        "lng": 36.7833
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9652,
      "name": "Ntara",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.0044,
        "lng": 30.3658
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9653,
      "name": "Sakete",
      "names": {},
      "countryCode": "BJ",
      "latLng": {
        "lat": 6.7362,
        "lng": 2.6587
      },
      "country": "Benin",
      "importance": 1
    },
    {
      "id": 9654,
      "name": "Mirna",
      "names": {
        "zh": "的miRNA",
        "ja": "のmiRNA"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9553,
        "lng": 15.0619
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9655,
      "name": "Prizren",
      "names": {
        "zh": "普里兹伦"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.2128,
        "lng": 20.7392
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9656,
      "name": "Isangel",
      "names": {},
      "countryCode": "VU",
      "latLng": {
        "lat": -19.5417,
        "lng": 169.2817
      },
      "country": "Vanuatu",
      "importance": 1
    },
    {
      "id": 9658,
      "name": "Montegiardino",
      "names": {},
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9092,
        "lng": 12.4833
      },
      "country": "San Marino",
      "importance": 1
    },
    {
      "id": 9659,
      "name": "Dapaong",
      "names": {},
      "countryCode": "TG",
      "latLng": {
        "lat": 10.8623,
        "lng": 0.2076
      },
      "country": "Togo",
      "importance": 1
    },
    {
      "id": 9660,
      "name": "Toftir",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.0978,
        "lng": -6.7369
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9661,
      "name": "Kalungu",
      "names": {
        "zh": "卡隆古"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.1667,
        "lng": 31.7569
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9662,
      "name": "Rukungiri",
      "names": {
        "zh": "鲁昆吉里"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.8411,
        "lng": 29.9419
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9663,
      "name": "Nelspruit",
      "names": {
        "zh": "内尔斯普雷特",
        "th": "สปรุต"
      },
      "countryCode": "ZA",
      "latLng": {
        "lat": -25.4745,
        "lng": 30.9703
      },
      "country": "South Africa",
      "importance": 1
    },
    {
      "id": 9664,
      "name": "Vitanje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3817,
        "lng": 15.2958
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9665,
      "name": "Bushenyi",
      "names": {
        "zh": "布谢尼"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.5853,
        "lng": 30.2114
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9666,
      "name": "Samraong",
      "names": {},
      "countryCode": "KH",
      "latLng": {
        "lat": 14.1817,
        "lng": 103.5176
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 9667,
      "name": "Koprivnica",
      "names": {
        "zh": "科普里夫尼察"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 46.15,
        "lng": 16.8167
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 9668,
      "name": "Gombe",
      "names": {
        "zh": "贡贝"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.1818,
        "lng": 32.1158
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9669,
      "name": "Kanungu",
      "names": {
        "zh": "卡农古"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.9575,
        "lng": 29.7897
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9670,
      "name": "Bupoto",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.9061,
        "lng": 34.3578
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9672,
      "name": "Veliko Gradiste",
      "names": {
        "fr": "Veliko Gradiate",
        "id": "Gradiste Veliko",
        "ja": "卒業生の卒業生"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.75,
        "lng": 21.5167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9674,
      "name": "Rogatec",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2294,
        "lng": 15.7003
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9675,
      "name": "Horjul",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0236,
        "lng": 14.2992
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9676,
      "name": "Santa Lucija",
      "names": {
        "zh": "圣卢卡",
        "ja": "サンタルシャ",
        "th": "ซานตาลูจิ"
      },
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0431,
        "lng": 14.2172
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9677,
      "name": "Dimitrovgrad",
      "names": {
        "zh": "季米特洛夫格勒"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.0167,
        "lng": 22.7833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9678,
      "name": "Al Jabin",
      "names": {
        "ja": "アルハビン",
        "th": "อัลจิบิน"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 14.704,
        "lng": 43.599
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 9679,
      "name": "Pivka",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.6794,
        "lng": 14.1967
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9680,
      "name": "Rubirizi",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.2989,
        "lng": 30.1336
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9681,
      "name": "Velika Plana",
      "names": {
        "id": "Plana velika",
        "ja": "ベリカプラナ",
        "th": "เวลิกา"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3333,
        "lng": 21.0833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9682,
      "name": "Petrovac na Mlavi",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3783,
        "lng": 21.4194
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9683,
      "name": "Svilajnac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2167,
        "lng": 21.2
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9684,
      "name": "Boljevac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8247,
        "lng": 21.9519
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9685,
      "name": "Kyenjojo",
      "names": {
        "zh": "基恩乔乔"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.6328,
        "lng": 30.6214
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9687,
      "name": "Kyegegwa",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.5022,
        "lng": 31.0414
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9688,
      "name": "Kaedi",
      "names": {
        "zh": "卡埃迪"
      },
      "countryCode": "MR",
      "latLng": {
        "lat": 16.1503,
        "lng": -13.5037
      },
      "country": "Mauritania",
      "importance": 1
    },
    {
      "id": 9689,
      "name": "Braslovce",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2897,
        "lng": 15.0389
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9690,
      "name": "Kole",
      "names": {
        "zh": "科莱"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.4002,
        "lng": 32.8003
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9691,
      "name": "Ibanda",
      "names": {
        "zh": "伊班达"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.1539,
        "lng": 30.5319
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9692,
      "name": "Narok",
      "names": {
        "zh": "纳罗克"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -1.0833,
        "lng": 35.8667
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9693,
      "name": "Bulisa",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.1178,
        "lng": 31.4116
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9694,
      "name": "Ouled Djellal",
      "names": {
        "fr": "Djellal Ouled",
        "zh": "oulled djellal",
        "it": "Djellal inottato",
        "ja": "WLED Djellal",
        "pt": "Djellal Ouled"
      },
      "countryCode": "DZ",
      "latLng": {
        "lat": 34.4167,
        "lng": 5.0667
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 9696,
      "name": "Tivat",
      "names": {
        "zh": "蒂瓦特"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.43,
        "lng": 18.7
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 9697,
      "name": "Kladovo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.6039,
        "lng": 22.6072
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9698,
      "name": "Dobrna",
      "names": {
        "zh": "多布尔纳"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3375,
        "lng": 15.2264
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9699,
      "name": "Pesnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6069,
        "lng": 15.6767
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9700,
      "name": "Gorisnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4147,
        "lng": 16.0139
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9701,
      "name": "Luce",
      "names": {
        "zh": "卢斯",
        "ja": "ルーチェ",
        "th": "ลูซ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3561,
        "lng": 14.7467
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9702,
      "name": "Videm pri Ptuju",
      "names": {
        "fr": "Videm Pri, Ptuju"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3686,
        "lng": 15.9064
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9703,
      "name": "Mparo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -1.1647,
        "lng": 30.0378
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9704,
      "name": "Stara Pazova",
      "names": {
        "id": "Sara Pazova"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.9833,
        "lng": 20.1667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9706,
      "name": "Mitoma",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.6842,
        "lng": 30.07
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9707,
      "name": "Sid",
      "names": {
        "zh": "的SID",
        "ja": "SIDを",
        "th": "ซิด"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.1283,
        "lng": 19.2264
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9708,
      "name": "Lovrenc na Pohorju",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5406,
        "lng": 15.3931
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9709,
      "name": "Logatec",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9144,
        "lng": 14.2258
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9710,
      "name": "Crna na Koroskem",
      "names": {
        "ja": "CRNA NAコロスキム"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4667,
        "lng": 14.85
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9711,
      "name": "Bukedea",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.3169,
        "lng": 34.0506
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9712,
      "name": "Amolatar",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.6378,
        "lng": 32.8448
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9713,
      "name": "Ribnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7386,
        "lng": 14.7275
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9714,
      "name": "Ol Kalou",
      "names": {
        "zh": "洛杉矶",
        "de": "Olkelnd",
        "ja": "オルカロス"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -0.2643,
        "lng": 36.3788
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9715,
      "name": "Kasanda",
      "names": {
        "zh": "卡桑达"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.5567,
        "lng": 31.8022
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9716,
      "name": "Majsperk",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3517,
        "lng": 15.7336
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9717,
      "name": "Kasaali",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.6167,
        "lng": 31.55
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9718,
      "name": "Vransko",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2439,
        "lng": 14.9514
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9719,
      "name": "Sentilj",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6817,
        "lng": 15.6481
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9720,
      "name": "Bojnik",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.0142,
        "lng": 21.718
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9721,
      "name": "Dokolo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.9167,
        "lng": 33.172
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9722,
      "name": "Zebbug",
      "names": {},
      "countryCode": "MT",
      "latLng": {
        "lat": 36.0722,
        "lng": 14.2358
      },
      "country": "Malta",
      "importance": 1
    },
    {
      "id": 9723,
      "name": "Kakumiro",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.7806,
        "lng": 31.3236
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9725,
      "name": "Trzin",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1333,
        "lng": 14.5667
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9726,
      "name": "Sembabule",
      "names": {
        "zh": "森巴布莱"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.0772,
        "lng": 31.4567
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9727,
      "name": "Dobrovo",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9964,
        "lng": 13.5264
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9728,
      "name": "Rakai",
      "names": {
        "zh": "拉卡伊"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.72,
        "lng": 31.4839
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9729,
      "name": "Gllogovc",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.6167,
        "lng": 20.9
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9730,
      "name": "Gjilan",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.4647,
        "lng": 21.4669
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9731,
      "name": "Mitrovice",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.8833,
        "lng": 20.8667
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9732,
      "name": "Heydarabad",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.7229,
        "lng": 44.8485
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9734,
      "name": "Ig",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9603,
        "lng": 14.5289
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9735,
      "name": "Kinoni",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.6583,
        "lng": 30.4581
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9736,
      "name": "Sari",
      "names": {
        "zh": "纱丽",
        "ja": "サリ",
        "th": "ส่าหรี"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 36.5633,
        "lng": 53.0601
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 9737,
      "name": "Obiliq",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.69,
        "lng": 21.0778
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9738,
      "name": "Rahovec",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3994,
        "lng": 20.6547
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9739,
      "name": "Trzic",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3667,
        "lng": 14.3167
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9740,
      "name": "Koronadal",
      "names": {
        "zh": "科罗纳达尔"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 6.2541,
        "lng": 124.9922
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 9741,
      "name": "Kibingo",
      "names": {
        "zh": "基宾戈"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": -0.626,
        "lng": 30.4359
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9742,
      "name": "Recica",
      "names": {
        "zh": "雷契察"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3167,
        "lng": 14.9167
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9743,
      "name": "Omuthiya",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -18.3592,
        "lng": 16.5795
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 9744,
      "name": "Dol",
      "names": {
        "zh": "杜比"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.0886,
        "lng": 14.6008
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9745,
      "name": "Guadalupe",
      "names": {
        "zh": "瓜达卢佩",
        "ja": "グアダルペ",
        "th": "กัวดาลูป"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 0.3792,
        "lng": 6.6375
      },
      "country": "Sao Tome And Principe",
      "importance": 1
    },
    {
      "id": 9746,
      "name": "Pailin",
      "names": {
        "th": "ไพลิน"
      },
      "countryCode": "KH",
      "latLng": {
        "lat": 12.8489,
        "lng": 102.6093
      },
      "country": "Cambodia",
      "importance": 1
    },
    {
      "id": 9747,
      "name": "Vushtrri",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.8222,
        "lng": 20.9694
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9749,
      "name": "Leulumoega",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.823,
        "lng": -171.9613
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 9750,
      "name": "Lwengo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.4161,
        "lng": 31.4081
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9751,
      "name": "Butalangu",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.7011,
        "lng": 32.2481
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9752,
      "name": "Techiman",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 7.5905,
        "lng": -1.9395
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 9753,
      "name": "Kara",
      "names": {
        "zh": "卡拉",
        "ja": "カラ",
        "th": "คาร่า"
      },
      "countryCode": "TG",
      "latLng": {
        "lat": 9.5511,
        "lng": 1.1861
      },
      "country": "Togo",
      "importance": 1
    },
    {
      "id": 9754,
      "name": "Kanoni",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.1772,
        "lng": 31.8811
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9755,
      "name": "Gia Nghia",
      "names": {
        "id": "Gia ngia"
      },
      "countryCode": "VN",
      "latLng": {
        "lat": 12.0042,
        "lng": 107.6907
      },
      "country": "Vietnam",
      "importance": 1
    },
    {
      "id": 9756,
      "name": "Alebtong",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.2447,
        "lng": 33.2547
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9757,
      "name": "Topola",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.2525,
        "lng": 20.6761
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9758,
      "name": "Suai",
      "names": {
        "zh": "苏艾",
        "th": "สรวย"
      },
      "countryCode": "TL",
      "latLng": {
        "lat": -9.3129,
        "lng": 125.2565
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 9759,
      "name": "Gorenja Vas",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1072,
        "lng": 14.1481
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9760,
      "name": "Dornava",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4367,
        "lng": 15.9536
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9761,
      "name": "Pante Macassar",
      "names": {
        "ja": "パンテマッサー"
      },
      "countryCode": "TL",
      "latLng": {
        "lat": -9.2,
        "lng": 124.3833
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 9762,
      "name": "Kokopo",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -4.35,
        "lng": 152.2736
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 9763,
      "name": "Zelezniki",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2333,
        "lng": 14.1667
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9764,
      "name": "Ramotswa",
      "names": {},
      "countryCode": "BW",
      "latLng": {
        "lat": -24.8667,
        "lng": 25.8167
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 9765,
      "name": "Amuria",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.0036,
        "lng": 33.6511
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9766,
      "name": "Abim",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.7017,
        "lng": 33.6761
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9767,
      "name": "Ngora",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.4314,
        "lng": 33.7772
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9768,
      "name": "Lukovica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1667,
        "lng": 14.7
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9769,
      "name": "Kalaki",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.816,
        "lng": 33.337
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9770,
      "name": "Lipjan",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.53,
        "lng": 21.1386
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9771,
      "name": "Turnisce",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6278,
        "lng": 16.3203
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9772,
      "name": "Al `Aziziyah",
      "names": {
        "zh": "Al`aziziyah",
        "ja": "Aziziyah",
        "th": "อัล `Aziziyah"
      },
      "countryCode": "LY",
      "latLng": {
        "lat": 32.5319,
        "lng": 13.0175
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 9773,
      "name": "Suhareke",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.38,
        "lng": 20.8219
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9774,
      "name": "Cerkno",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1256,
        "lng": 13.9817
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9775,
      "name": "Smarjeske Toplice",
      "names": {
        "id": "Toplice Smarjeske"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.862,
        "lng": 15.2231
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9776,
      "name": "Chiesanuova",
      "names": {},
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9061,
        "lng": 12.4214
      },
      "country": "San Marino",
      "importance": 1
    },
    {
      "id": 9777,
      "name": "Saltangara",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.1156,
        "lng": -6.7206
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9778,
      "name": "Kamwenge",
      "names": {
        "zh": "卡姆文盖"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 0.2111,
        "lng": 30.4208
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9779,
      "name": "Mulifanua",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.8318,
        "lng": -172.036
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 9780,
      "name": "Ljubno",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3456,
        "lng": 14.835
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9781,
      "name": "Lospalos",
      "names": {},
      "countryCode": "TL",
      "latLng": {
        "lat": -8.5167,
        "lng": 127.0333
      },
      "country": "Timor-Leste",
      "importance": 1
    },
    {
      "id": 9782,
      "name": "Bohinjska Bistrica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2769,
        "lng": 13.955
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9783,
      "name": "Starse",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4658,
        "lng": 15.7672
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9784,
      "name": "Isingiro",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.8686,
        "lng": 30.8302
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9785,
      "name": "Ilinden",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9945,
        "lng": 21.58
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9786,
      "name": "Tabor",
      "names": {
        "zh": "塔博尔",
        "ja": "テイバー",
        "th": "ทาโบร์"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2361,
        "lng": 15.0183
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9787,
      "name": "Rabak",
      "names": {},
      "countryCode": "SD",
      "latLng": {
        "lat": 13.188,
        "lng": 32.7437
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 9788,
      "name": "Kidricevo",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4036,
        "lng": 15.7911
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9789,
      "name": "Tsirang",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.0219,
        "lng": 90.1229
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9790,
      "name": "Gjakove",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3833,
        "lng": 20.4333
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9791,
      "name": "Tutong",
      "names": {
        "zh": "都东"
      },
      "countryCode": "BN",
      "latLng": {
        "lat": 4.8067,
        "lng": 114.6592
      },
      "country": "Brunei",
      "importance": 1
    },
    {
      "id": 9792,
      "name": "Divaca",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.6847,
        "lng": 13.9703
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9793,
      "name": "Kwale",
      "names": {
        "zh": "夸莱"
      },
      "countryCode": "KE",
      "latLng": {
        "lat": -4.1737,
        "lng": 39.4521
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9794,
      "name": "Sveti Jurij",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5695,
        "lng": 16.0235
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9795,
      "name": "Santana",
      "names": {
        "zh": "桑塔纳",
        "ja": "サンタナ"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 0.26,
        "lng": 6.7414
      },
      "country": "Sao Tome And Principe",
      "importance": 1
    },
    {
      "id": 9796,
      "name": "Faetano",
      "names": {},
      "countryCode": "SM",
      "latLng": {
        "lat": 43.9261,
        "lng": 12.4981
      },
      "country": "San Marino",
      "importance": 1
    },
    {
      "id": 9797,
      "name": "Pozega",
      "names": {
        "zh": "波热加"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.3314,
        "lng": 17.6744
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 9798,
      "name": "Straza",
      "names": {
        "de": "Straža"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.78,
        "lng": 15.0728
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9799,
      "name": "Sejong",
      "names": {
        "zh": "世宗"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 36.6092,
        "lng": 127.2919
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 9800,
      "name": "Merosina",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2833,
        "lng": 21.7167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9801,
      "name": "Princes Town",
      "names": {
        "es": "Ciudad de Princes",
        "fr": "Princes",
        "zh": "王子镇",
        "id": "Kota pangeran",
        "th": "เมืองเจ้าชาย",
        "pt": "Cidade príncipes"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.2667,
        "lng": -61.3833
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 9802,
      "name": "Peje",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.6603,
        "lng": 20.2917
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9803,
      "name": "Viti",
      "names": {
        "es": "Levu"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3167,
        "lng": 21.35
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9804,
      "name": "Temerin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.4053,
        "lng": 19.8869
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9805,
      "name": "Benedikt",
      "names": {
        "zh": "贝内"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6086,
        "lng": 15.8883
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9806,
      "name": "Semic",
      "names": {
        "it": "Semico"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.6461,
        "lng": 15.1822
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9807,
      "name": "Cirkulane",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3453,
        "lng": 15.9952
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9808,
      "name": "Vodice",
      "names": {
        "ja": "ヴォディツェ"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2,
        "lng": 14.5
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9809,
      "name": "Nkurenkuru",
      "names": {},
      "countryCode": "NA",
      "latLng": {
        "lat": -17.6167,
        "lng": 18.6
      },
      "country": "Namibia",
      "importance": 1
    },
    {
      "id": 9810,
      "name": "Sefwi Wiawso",
      "names": {
        "de": "Sefwi Wiwso"
      },
      "countryCode": "GH",
      "latLng": {
        "lat": 6.2058,
        "lng": -2.4894
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 9811,
      "name": "Sveta Ana",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6492,
        "lng": 15.8442
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9812,
      "name": "Rumuruti",
      "names": {},
      "countryCode": "KE",
      "latLng": {
        "lat": 0.2725,
        "lng": 36.5381
      },
      "country": "Kenya",
      "importance": 1
    },
    {
      "id": 9813,
      "name": "Hongseong",
      "names": {},
      "countryCode": "KR",
      "latLng": {
        "lat": 36.6009,
        "lng": 126.665
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 9814,
      "name": "Mersch",
      "names": {
        "zh": "梅尔施"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.7489,
        "lng": 6.1061
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 9815,
      "name": "Muan",
      "names": {
        "zh": "务安",
        "id": "Gwangju Muan International",
        "th": "ม่วน"
      },
      "countryCode": "KR",
      "latLng": {
        "lat": 34.9897,
        "lng": 126.4714
      },
      "country": "South Korea",
      "importance": 1
    },
    {
      "id": 9816,
      "name": "Nova Vas",
      "names": {
        "ja": "ノババス"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7717,
        "lng": 14.5058
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9817,
      "name": "Zgornja Hajdina",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4061,
        "lng": 15.8386
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9818,
      "name": "Kacanik",
      "names": {
        "zh": "卡查尼克"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.2467,
        "lng": 21.2553
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9819,
      "name": "Mirna Pec",
      "names": {
        "th": "Mira Pec"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8603,
        "lng": 15.0833
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9820,
      "name": "Buyende",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.1517,
        "lng": 33.155
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9821,
      "name": "Tuzi",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.3656,
        "lng": 19.3314
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 9822,
      "name": "Bangolo",
      "names": {},
      "countryCode": "CI",
      "latLng": {
        "lat": 7.0123,
        "lng": -7.4864
      },
      "country": "Côte d\u0027Ivoire",
      "importance": 1
    },
    {
      "id": 9823,
      "name": "Saratamata",
      "names": {},
      "countryCode": "VU",
      "latLng": {
        "lat": -15.2869,
        "lng": 167.9906
      },
      "country": "Vanuatu",
      "importance": 1
    },
    {
      "id": 9824,
      "name": "Skenderaj",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.7467,
        "lng": 20.7886
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9825,
      "name": "Strendur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.1096,
        "lng": -6.7617
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9826,
      "name": "Kurumul",
      "names": {},
      "countryCode": "PG",
      "latLng": {
        "lat": -5.855,
        "lng": 144.6311
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 9827,
      "name": "Palenga",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.6131,
        "lng": 32.3369
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9828,
      "name": "Sveta Trojica v Slovenskih Goricah",
      "names": {
        "es": "Sveta Trojica V Eslovenskih Goricah",
        "ja": "Sveta Trojica vスロベンシハGoricah"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5767,
        "lng": 15.8769
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9829,
      "name": "Zgornja Kungota",
      "names": {
        "es": "ZGORNJA KUNDOTA"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6392,
        "lng": 15.6156
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9830,
      "name": "Lamwo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 3.5297,
        "lng": 32.8016
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9831,
      "name": "Oyam",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.2141,
        "lng": 32.3703
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9832,
      "name": "Kula",
      "names": {
        "zh": "库拉",
        "ja": "クラ",
        "th": "กุลา"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.6109,
        "lng": 19.5274
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9833,
      "name": "Destrnik",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5006,
        "lng": 15.875
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9834,
      "name": "Ponta do Sol",
      "names": {
        "zh": "Ponta做溶胶",
        "th": "Ponta ทำโซล"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 17.2014,
        "lng": -25.0917
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 9835,
      "name": "Podujeve",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.9167,
        "lng": 21.2
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9836,
      "name": "Lelydorp",
      "names": {},
      "countryCode": "SR",
      "latLng": {
        "lat": 5.7,
        "lng": -55.2333
      },
      "country": "Suriname",
      "importance": 1
    },
    {
      "id": 9837,
      "name": "Apatin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.6667,
        "lng": 18.9833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9838,
      "name": "Dobrova",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.055,
        "lng": 14.4172
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9839,
      "name": "Makole",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3172,
        "lng": 15.6672
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9840,
      "name": "Zitorada",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.1833,
        "lng": 21.7167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9841,
      "name": "Markovci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3833,
        "lng": 15.95
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9842,
      "name": "Crensovci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5744,
        "lng": 16.2906
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9843,
      "name": "Zvecan",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.9,
        "lng": 20.8333
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9844,
      "name": "Kovin",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.7475,
        "lng": 20.9761
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9845,
      "name": "Koceljeva",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.4708,
        "lng": 19.807
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9846,
      "name": "Verzej",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5836,
        "lng": 16.1653
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9847,
      "name": "Velike Lasce",
      "names": {
        "es": "Lascerce",
        "zh": "柔软骆驼",
        "it": "Lasce velike",
        "id": "Lasce velike",
        "ja": "バイクラスク",
        "pt": "Lasce velrikle"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8322,
        "lng": 14.6364
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9848,
      "name": "Krizevci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5683,
        "lng": 16.1386
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9849,
      "name": "Agago",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.8338,
        "lng": 33.3336
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9850,
      "name": "Razkrizje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5217,
        "lng": 16.2811
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9851,
      "name": "Skocjan",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9067,
        "lng": 15.2914
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9852,
      "name": "Morant Bay",
      "names": {
        "es": "Moreno",
        "fr": "Baie",
        "it": "Baia moranta",
        "id": "Teluk",
        "ja": "モラントベイ",
        "th": "อ่าวอ่านต่อ",
        "pt": "Baía moral"
      },
      "countryCode": "JM",
      "latLng": {
        "lat": 17.8814,
        "lng": -76.4092
      },
      "country": "Jamaica",
      "importance": 1
    },
    {
      "id": 9854,
      "name": "Zuzemberk",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8339,
        "lng": 14.9292
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9855,
      "name": "Puconci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.7067,
        "lng": 16.1564
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9856,
      "name": "Madinat ash Shamal",
      "names": {
        "zh": "麦丽尼特灰血统",
        "de": "Madinat Esche Shamal",
        "id": "Shamal Ash Madinat",
        "pt": "Shamal de Ash Madinat"
      },
      "countryCode": "QA",
      "latLng": {
        "lat": 26.14,
        "lng": 51.22
      },
      "country": "Qatar",
      "importance": 1
    },
    {
      "id": 9857,
      "name": "Gadzin Han",
      "names": {
        "zh": "加辛汉",
        "ja": "ガジンハン"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.2203,
        "lng": 22.0258
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9858,
      "name": "Videm",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.85,
        "lng": 14.6942
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9859,
      "name": "Cicevac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.7167,
        "lng": 21.45
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9860,
      "name": "Pecinci",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.9089,
        "lng": 19.9664
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9861,
      "name": "Zavrc",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3917,
        "lng": 16.0497
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9862,
      "name": "Mongar",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.275,
        "lng": 91.24
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9863,
      "name": "Ilam",
      "names": {
        "zh": "伊拉姆"
      },
      "countryCode": "IR",
      "latLng": {
        "lat": 33.6374,
        "lng": 46.4227
      },
      "country": "Iran",
      "importance": 1
    },
    {
      "id": 9864,
      "name": "Qazax",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.0925,
        "lng": 45.3656
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9866,
      "name": "Bosilegrad",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.5005,
        "lng": 22.4728
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9867,
      "name": "Tari",
      "names": {
        "zh": "塔里",
        "ja": "タリ"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -5.8489,
        "lng": 142.9506
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 9868,
      "name": "Velika Polana",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5719,
        "lng": 16.3469
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9869,
      "name": "Cankova",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.7208,
        "lng": 16.0225
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9870,
      "name": "Vladimirci",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.6167,
        "lng": 19.7833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9871,
      "name": "Haa",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.3685,
        "lng": 89.2918
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9872,
      "name": "Singa",
      "names": {
        "zh": "新加坡"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 13.1483,
        "lng": 33.9311
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 9873,
      "name": "Razanj",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.6667,
        "lng": 21.55
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9874,
      "name": "Tvoroyri",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.5544,
        "lng": -6.8063
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9875,
      "name": "Port Loko",
      "names": {
        "zh": "洛科港",
        "de": "Hafenloko",
        "it": "Porto loko",
        "id": "Pelabuhan loko",
        "ja": "ポートLOKO",
        "th": "พอร์ตโลโกะ",
        "pt": "Porto loko"
      },
      "countryCode": "SL",
      "latLng": {
        "lat": 8.7667,
        "lng": -12.7833
      },
      "country": "Sierra Leone",
      "importance": 1
    },
    {
      "id": 9876,
      "name": "Pul-e `Alam",
      "names": {
        "zh": "pul-e`Alam",
        "ja": "PUL-E \u0027alam"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.9953,
        "lng": 69.0227
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 9877,
      "name": "Kllokot",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.3667,
        "lng": 21.3833
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9878,
      "name": "Kucevo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.4833,
        "lng": 21.6667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9879,
      "name": "Boorama",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 9.9361,
        "lng": 43.1828
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 9880,
      "name": "Komen",
      "names": {
        "zh": "科门",
        "th": "โกเมน"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.8153,
        "lng": 13.7483
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9881,
      "name": "Apace",
      "names": {
        "es": "A buen ritmo",
        "fr": "Rapidement",
        "zh": "快速地",
        "de": "Schnell",
        "it": "Buon passo",
        "id": "Dgn cepat",
        "ja": "たちまち",
        "th": "ด้วยความเร็ว",
        "pt": "Rapidamente"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6972,
        "lng": 15.9106
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9882,
      "name": "Kuzma",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.8369,
        "lng": 16.0833
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9883,
      "name": "San Jose",
      "names": {
        "es": "San José",
        "zh": "圣荷西",
        "ja": "サンノゼ",
        "th": "ซานโฮเซ่"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 10.18,
        "lng": 125.5683
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 9884,
      "name": "Napak",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.2514,
        "lng": 34.2501
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9885,
      "name": "Kaffrine",
      "names": {
        "zh": "卡夫林"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 14.1016,
        "lng": -15.5467
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 9886,
      "name": "Sharan",
      "names": {
        "zh": "沙然"
      },
      "countryCode": "AF",
      "latLng": {
        "lat": 33.1757,
        "lng": 68.7304
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 9887,
      "name": "Istog",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.7833,
        "lng": 20.4833
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9888,
      "name": "Gornji Grad",
      "names": {
        "es": "Gornji graduado",
        "id": "Grad Gornji",
        "ja": "ゴーンジ卒業生",
        "pt": "Graduado de Gornji"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.2953,
        "lng": 14.8083
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9889,
      "name": "Krapina",
      "names": {
        "zh": "克拉皮纳"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 46.1589,
        "lng": 15.8744
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 9890,
      "name": "Plandiste",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.2269,
        "lng": 21.1217
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9891,
      "name": "Dambai",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 8.0662,
        "lng": 0.1795
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 9892,
      "name": "Cerkvenjak",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5706,
        "lng": 15.9436
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9893,
      "name": "Medveda",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.8333,
        "lng": 21.5833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9894,
      "name": "Goaso",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 6.8036,
        "lng": -2.5172
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 9895,
      "name": "Suhar",
      "names": {},
      "countryCode": "OM",
      "latLng": {
        "lat": 24.342,
        "lng": 56.7299
      },
      "country": "Oman",
      "importance": 1
    },
    {
      "id": 9896,
      "name": "Sarur",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.5544,
        "lng": 44.9826
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9897,
      "name": "Sredisce ob Dravi",
      "names": {
        "de": "Sadisce ob dravi",
        "it": "Sardisce ob dravi"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3942,
        "lng": 16.2681
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9898,
      "name": "Redange-sur-Attert",
      "names": {
        "zh": "redange畔attert",
        "ja": "redangeシュルattert",
        "th": "redange-ซูร์ attert"
      },
      "countryCode": "LU",
      "latLng": {
        "lat": 49.7656,
        "lng": 5.8908
      },
      "country": "Luxembourg",
      "importance": 1
    },
    {
      "id": 9899,
      "name": "Jursinci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4847,
        "lng": 15.9714
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9900,
      "name": "Gaoua",
      "names": {
        "zh": "加瓦"
      },
      "countryCode": "BF",
      "latLng": {
        "lat": 10.2992,
        "lng": -3.2508
      },
      "country": "Burkina Faso",
      "importance": 1
    },
    {
      "id": 9901,
      "name": "Dolenjske Toplice",
      "names": {
        "id": "Toplice Dolenjske"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7667,
        "lng": 15.0667
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9902,
      "name": "Kozje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.075,
        "lng": 15.5603
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9903,
      "name": "Cocieri",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.3,
        "lng": 29.1167
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 9904,
      "name": "Podcetrtek",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1569,
        "lng": 15.5986
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9905,
      "name": "Mokronog",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.9342,
        "lng": 15.1408
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9906,
      "name": "Fuglafjordhur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.2448,
        "lng": -6.815
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9907,
      "name": "Becej",
      "names": {
        "zh": "贝切伊"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.6167,
        "lng": 20.0333
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9908,
      "name": "Lufilufi",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.8745,
        "lng": -171.5986
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 9909,
      "name": "Nalerigu",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 10.5273,
        "lng": -0.3698
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 9910,
      "name": "Sangre Grande",
      "names": {
        "id": "Orang bodoh",
        "ja": "サングレグランデ"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.5667,
        "lng": -61.1333
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 9912,
      "name": "Kralendijk",
      "names": {},
      "countryCode": "NL",
      "latLng": {
        "lat": 12.1517,
        "lng": -68.2761
      },
      "country": "Netherlands",
      "importance": 1
    },
    {
      "id": 9913,
      "name": "Zgornje Gorje",
      "names": {
        "es": "ZGORTJE GORJE"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3833,
        "lng": 14.0833
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9914,
      "name": "Rogasovci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.8,
        "lng": 16.0333
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9915,
      "name": "Opovo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.0514,
        "lng": 20.4247
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9916,
      "name": "Ribnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.535,
        "lng": 15.2728
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9917,
      "name": "Kotor",
      "names": {
        "zh": "科托尔"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.4254,
        "lng": 18.7712
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 9918,
      "name": "Rekovac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 43.8667,
        "lng": 21.1333
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9919,
      "name": "Knic",
      "names": {
        "zh": "克尼奇"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 43.9167,
        "lng": 20.7167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9920,
      "name": "Podlehnik",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3353,
        "lng": 15.88
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9921,
      "name": "Sodrazica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7611,
        "lng": 14.6356
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9922,
      "name": "Nhlangano",
      "names": {
        "zh": "纳兰佳诺"
      },
      "countryCode": "SZ",
      "latLng": {
        "lat": -27.1167,
        "lng": 31.2
      },
      "country": "Swaziland",
      "importance": 1
    },
    {
      "id": 9923,
      "name": "Nili",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 33.7218,
        "lng": 66.1302
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 9924,
      "name": "Vitomarci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5275,
        "lng": 15.9394
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9925,
      "name": "Fort Wellington",
      "names": {
        "zh": "惠灵顿堡",
        "ja": "フォートウェリントン",
        "th": "ป้อมเวลลิงตัน",
        "pt": "Forte Wellington"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 6.3909,
        "lng": -57.6038
      },
      "country": "Guyana",
      "importance": 1
    },
    {
      "id": 9926,
      "name": "Samdrup Jongkhar",
      "names": {
        "de": "Samdrupp Jongkhar"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 26.8007,
        "lng": 91.5052
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9927,
      "name": "Osilnica",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5292,
        "lng": 14.6979
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9928,
      "name": "Tabuk",
      "names": {
        "zh": "塔布克"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 17.4084,
        "lng": 121.2785
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 9929,
      "name": "Sarpang",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 26.8639,
        "lng": 90.2674
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9930,
      "name": "Nabilatuk",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.0525,
        "lng": 34.5734
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9931,
      "name": "Neves",
      "names": {
        "zh": "内维斯"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 0.3586,
        "lng": 6.5525
      },
      "country": "Sao Tome And Principe",
      "importance": 1
    },
    {
      "id": 9932,
      "name": "Titel",
      "names": {
        "it": "Titolo"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.2,
        "lng": 20.3
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9933,
      "name": "Nova Sintra",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 14.8714,
        "lng": -24.6956
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 9934,
      "name": "Dragash",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.0611,
        "lng": 20.6528
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9935,
      "name": "Jurovski Dol",
      "names": {
        "pt": "Dol jurovski"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6064,
        "lng": 15.7847
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9936,
      "name": "Ed Daein",
      "names": {
        "zh": "艾德丹",
        "it": "Ed daae",
        "ja": "エドデアイン"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 11.4672,
        "lng": 26.1317
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 9937,
      "name": "Trashigang",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.3333,
        "lng": 91.5528
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9938,
      "name": "Sedhiou",
      "names": {
        "zh": "塞久"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 12.7081,
        "lng": -15.5569
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 9940,
      "name": "Trnovska Vas",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5167,
        "lng": 15.9
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9941,
      "name": "Qabala",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.9825,
        "lng": 47.8491
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9942,
      "name": "Soldanesti",
      "names": {},
      "countryCode": "MD",
      "latLng": {
        "lat": 47.8161,
        "lng": 28.7972
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 9943,
      "name": "Pemagatshel",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.038,
        "lng": 91.4031
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9944,
      "name": "Moravske-Toplice",
      "names": {
        "es": "-Moravske toplice",
        "fr": "Moravske toplice-",
        "zh": "moravske-凯托普利采",
        "it": "Moravske toplice-"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6875,
        "lng": 16.2256
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9945,
      "name": "Mamuju",
      "names": {},
      "countryCode": "ID",
      "latLng": {
        "lat": -2.6786,
        "lng": 118.8933
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 9946,
      "name": "Igreja",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 15.0339,
        "lng": -24.325
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 9947,
      "name": "Podvelka",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.5869,
        "lng": 15.3306
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9948,
      "name": "Savalou",
      "names": {},
      "countryCode": "BJ",
      "latLng": {
        "lat": 7.9281,
        "lng": 1.9756
      },
      "country": "Benin",
      "importance": 1
    },
    {
      "id": 9949,
      "name": "Kiruhura",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": -0.2356,
        "lng": 30.8725
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9950,
      "name": "Kuacjok",
      "names": {},
      "countryCode": "SS",
      "latLng": {
        "lat": 8.31,
        "lng": 27.99
      },
      "country": "South Sudan",
      "importance": 1
    },
    {
      "id": 9951,
      "name": "Secanj",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.3667,
        "lng": 20.7722
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9952,
      "name": "Vagur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.4733,
        "lng": -6.8175
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9953,
      "name": "Tubmanburg",
      "names": {
        "zh": "杜伯曼堡"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 6.8706,
        "lng": -10.8211
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 9954,
      "name": "Goygol",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5858,
        "lng": 46.3189
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9955,
      "name": "Hargeysa",
      "names": {
        "zh": "哈尔格萨"
      },
      "countryCode": "SO",
      "latLng": {
        "lat": 9.56,
        "lng": 44.065
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 9956,
      "name": "Safotulafai",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.6715,
        "lng": -172.1777
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 9957,
      "name": "Qacha\u0027s Nek",
      "names": {
        "es": "Nek de Qacha",
        "fr": "Nek de Qacha",
        "zh": "Qacha的Nek",
        "de": "Qachas Nek",
        "id": "Orangku yang nek",
        "ja": "Qachaのネック",
        "pt": "Nek de Qacha"
      },
      "countryCode": "LS",
      "latLng": {
        "lat": -30.1153,
        "lng": 28.6894
      },
      "country": "Lesotho",
      "importance": 1
    },
    {
      "id": 9958,
      "name": "Tomaz pri Ormozu",
      "names": {
        "fr": "Tomaz Prix Ormozu",
        "ja": "トマズPri Ormozu",
        "th": "Tomaz Prior Ormozu"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4842,
        "lng": 16.0836
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9959,
      "name": "Stefan Voda",
      "names": {
        "ja": "ステファンボーダ",
        "th": "สเตฟานวอดะ"
      },
      "countryCode": "MD",
      "latLng": {
        "lat": 46.5129,
        "lng": 29.6619
      },
      "country": "Moldova",
      "importance": 1
    },
    {
      "id": 9960,
      "name": "Grad",
      "names": {
        "es": "De posgrado",
        "zh": "毕业生",
        "ja": "卒業生",
        "th": "สำเร็จการศึกษา",
        "pt": "Graduado"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.8,
        "lng": 16.1
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9961,
      "name": "Matam",
      "names": {
        "zh": "马塔姆"
      },
      "countryCode": "SN",
      "latLng": {
        "lat": 15.6167,
        "lng": -13.3333
      },
      "country": "Senegal",
      "importance": 1
    },
    {
      "id": 9962,
      "name": "Zabari",
      "names": {
        "zh": "扎巴里"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.3562,
        "lng": 21.2143
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9963,
      "name": "Malo Crnice",
      "names": {
        "fr": "Chrnice malo",
        "id": "Crnice malo",
        "ja": "マロのCrnice"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.5667,
        "lng": 21.2833
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9964,
      "name": "Srbobran",
      "names": {
        "zh": "斯尔博布兰"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.5522,
        "lng": 19.8017
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9965,
      "name": "Kotido",
      "names": {
        "zh": "科蒂多"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 2.9806,
        "lng": 34.1331
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9966,
      "name": "Tsimasham",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.0989,
        "lng": 89.536
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 9967,
      "name": "Pazin",
      "names": {
        "zh": "巴辛"
      },
      "countryCode": "HR",
      "latLng": {
        "lat": 45.2392,
        "lng": 13.9386
      },
      "country": "Croatia",
      "importance": 1
    },
    {
      "id": 9968,
      "name": "Pala",
      "names": {
        "zh": "波罗",
        "ja": "パーラ",
        "th": "พละ"
      },
      "countryCode": "TD",
      "latLng": {
        "lat": 9.3646,
        "lng": 14.9073
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 9969,
      "name": "Shterpce",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.2333,
        "lng": 21.0167
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9970,
      "name": "Dobje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.1367,
        "lng": 15.4089
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9971,
      "name": "Nwoya",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.6342,
        "lng": 32.0011
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9972,
      "name": "Dobrovnik",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6514,
        "lng": 16.3525
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9973,
      "name": "Mali Idos",
      "names": {
        "zh": "马里Idos",
        "id": "Idos Mali",
        "ja": "マリダ"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.7069,
        "lng": 19.6644
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9974,
      "name": "Dalandzadgad",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 43.5708,
        "lng": 104.425
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 9975,
      "name": "Bazarak",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 35.3129,
        "lng": 69.5152
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 9976,
      "name": "Zitiste",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.485,
        "lng": 20.5497
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9977,
      "name": "Pader",
      "names": {
        "zh": "帕德尔"
      },
      "countryCode": "UG",
      "latLng": {
        "lat": 3.05,
        "lng": 33.2167
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9978,
      "name": "Novi Knezevac",
      "names": {
        "zh": "诺维·克纳扎夫"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 46.05,
        "lng": 20.1
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9979,
      "name": "Otuke",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.5004,
        "lng": 33.5007
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9980,
      "name": "Rio Claro",
      "names": {
        "zh": "里奥克洛",
        "ja": "リオクラロ"
      },
      "countryCode": "TT",
      "latLng": {
        "lat": 10.3059,
        "lng": -61.1756
      },
      "country": "Trinidad And Tobago",
      "importance": 1
    },
    {
      "id": 9981,
      "name": "Davaci",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 41.2012,
        "lng": 48.9871
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9982,
      "name": "El Meghaier",
      "names": {},
      "countryCode": "DZ",
      "latLng": {
        "lat": 33.9506,
        "lng": 5.9242
      },
      "country": "Algeria",
      "importance": 1
    },
    {
      "id": 9983,
      "name": "Qivraq",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.3994,
        "lng": 45.1151
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9984,
      "name": "Nordhragota",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.199,
        "lng": -6.7432
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9985,
      "name": "Zubin Potok",
      "names": {
        "it": "Zubin polok"
      },
      "countryCode": "XK",
      "latLng": {
        "lat": 42.9167,
        "lng": 20.6833
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 9986,
      "name": "Petnjica",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.9089,
        "lng": 19.9644
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 9987,
      "name": "Leava",
      "names": {},
      "countryCode": "WF",
      "latLng": {
        "lat": -14.2933,
        "lng": -178.1583
      },
      "country": "Wallis And Futuna",
      "importance": 1
    },
    {
      "id": 9988,
      "name": "Buka",
      "names": {
        "zh": "布卡"
      },
      "countryCode": "PG",
      "latLng": {
        "lat": -5.4219,
        "lng": 154.6728
      },
      "country": "Papua New Guinea",
      "importance": 1
    },
    {
      "id": 9989,
      "name": "Anouvong",
      "names": {},
      "countryCode": "LA",
      "latLng": {
        "lat": 18.8989,
        "lng": 103.0919
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 9990,
      "name": "Mislinja",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4411,
        "lng": 15.1956
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 9991,
      "name": "Novi Becej",
      "names": {
        "it": "Novi Bechej",
        "pt": "Novi Bechej"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 45.6,
        "lng": 20.1167
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 9992,
      "name": "Danilovgrad",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.61,
        "lng": 19.05
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 9993,
      "name": "Ar Rustaq",
      "names": {
        "es": "Ar ridtaq",
        "zh": "arrustaq",
        "ja": "rustaq",
        "pt": "Ar rustraq"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 23.3908,
        "lng": 57.4244
      },
      "country": "Oman",
      "importance": 1
    },
    {
      "id": 9994,
      "name": "Amuru",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 2.8139,
        "lng": 31.9387
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9995,
      "name": "Samamea",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.9338,
        "lng": -171.5312
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 9996,
      "name": "Nakapiripirit",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.9167,
        "lng": 34.7833
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 9997,
      "name": "Satupa`itea",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.7659,
        "lng": -172.3269
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 9998,
      "name": "Haciqabul",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.0387,
        "lng": 48.9429
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 9999,
      "name": "Makedonska Kamenica",
      "names": {
        "ja": "マケドンカカメニカ"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 42.0208,
        "lng": 22.5876
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 10000,
      "name": "Golubac",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.653,
        "lng": 21.632
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10001,
      "name": "Buabidi",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 8.4746,
        "lng": -81.6983
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 10002,
      "name": "Kalbacar",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.1098,
        "lng": 46.0445
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 10003,
      "name": "Niksic",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.78,
        "lng": 18.94
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 10004,
      "name": "Hrib-Loski Potok",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.7011,
        "lng": 14.5911
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 10005,
      "name": "Nova Crnja",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 45.6667,
        "lng": 20.6
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10006,
      "name": "Majdanpek",
      "names": {
        "zh": "马伊丹佩克"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 44.38,
        "lng": 21.944
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10007,
      "name": "Asau",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.5196,
        "lng": -172.6378
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 10008,
      "name": "Leposaviq",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 43.1,
        "lng": 20.8
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 10009,
      "name": "Djibloho",
      "names": {},
      "countryCode": "GQ",
      "latLng": {
        "lat": 1.5917,
        "lng": 10.8222
      },
      "country": "Equatorial Guinea",
      "importance": 1
    },
    {
      "id": 10010,
      "name": "Gracanice",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.6,
        "lng": 21.2
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 10011,
      "name": "Sur",
      "names": {
        "zh": "畔",
        "ja": "シュール",
        "th": "ซูร์"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 22.5667,
        "lng": 59.5289
      },
      "country": "Oman",
      "importance": 1
    },
    {
      "id": 10012,
      "name": "Safotu",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.4513,
        "lng": -172.4018
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 10013,
      "name": "Vailoa",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.7555,
        "lng": -172.307
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 10014,
      "name": "Ribeira Brava",
      "names": {
        "id": "Brava ribeira"
      },
      "countryCode": "CV",
      "latLng": {
        "lat": 16.6158,
        "lng": -24.2983
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 10015,
      "name": "Novoberde",
      "names": {},
      "countryCode": "XK",
      "latLng": {
        "lat": 42.6,
        "lng": 21.4333
      },
      "country": "Kosovo",
      "importance": 1
    },
    {
      "id": 10016,
      "name": "Kobilje",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.6847,
        "lng": 16.3978
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 10017,
      "name": "Qobustan",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.5336,
        "lng": 48.9282
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 10018,
      "name": "Ibra\u0027",
      "names": {
        "es": "Ibra \u0027",
        "fr": "Ibra \u0027",
        "de": "Ibra \u0027",
        "it": "Ibra \"",
        "id": "BPPN \u0027",
        "ja": "ibra \u0027",
        "th": "ibra \u0027",
        "pt": "Ibra \u0027"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 22.6906,
        "lng": 58.5334
      },
      "country": "Oman",
      "importance": 1
    },
    {
      "id": 10019,
      "name": "Kranjska Gora",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4839,
        "lng": 13.7894
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 10020,
      "name": "Massakory",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 13.0,
        "lng": 15.7333
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 10021,
      "name": "Zetale",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.275,
        "lng": 15.7939
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 10022,
      "name": "Rustavi",
      "names": {},
      "countryCode": "GE",
      "latLng": {
        "lat": 42.2897,
        "lng": 43.8543
      },
      "country": "Georgia",
      "importance": 1
    },
    {
      "id": 10023,
      "name": "Sorvagur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.0717,
        "lng": -7.3066
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10024,
      "name": "Buba",
      "names": {
        "zh": "布巴"
      },
      "countryCode": "GW",
      "latLng": {
        "lat": 11.59,
        "lng": -14.99
      },
      "country": "Guinea-Bissau",
      "importance": 1
    },
    {
      "id": 10025,
      "name": "Gusinje",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.5619,
        "lng": 19.8339
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 10026,
      "name": "Al Hazm",
      "names": {
        "ja": "アルハーズ",
        "th": "อัลเฮซ",
        "pt": "Alcova"
      },
      "countryCode": "YE",
      "latLng": {
        "lat": 16.1641,
        "lng": 44.7769
      },
      "country": "Yemen",
      "importance": 1
    },
    {
      "id": 10027,
      "name": "Xocavand",
      "names": {},
      "countryCode": "AZ",
      "latLng": {
        "lat": 39.795,
        "lng": 47.1117
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 10028,
      "name": "Resen",
      "names": {
        "zh": "雷森"
      },
      "countryCode": "MK",
      "latLng": {
        "lat": 41.0893,
        "lng": 21.0109
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 10029,
      "name": "Burco",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 9.5221,
        "lng": 45.5336
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 10030,
      "name": "Cova Figueira",
      "names": {},
      "countryCode": "CV",
      "latLng": {
        "lat": 14.8833,
        "lng": -24.3
      },
      "country": "Cabo Verde",
      "importance": 1
    },
    {
      "id": 10031,
      "name": "Barentu",
      "names": {
        "zh": "巴伦图"
      },
      "countryCode": "ER",
      "latLng": {
        "lat": 15.1058,
        "lng": 37.5907
      },
      "country": "Eritrea",
      "importance": 1
    },
    {
      "id": 10032,
      "name": "Kaisiadorys",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 54.8653,
        "lng": 24.4682
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 10033,
      "name": "Arta",
      "names": {
        "zh": "阿尔塔",
        "ja": "アルタ"
      },
      "countryCode": "DJ",
      "latLng": {
        "lat": 11.5264,
        "lng": 42.8519
      },
      "country": "Djibouti",
      "importance": 1
    },
    {
      "id": 10034,
      "name": "Oyrarbakki",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.2079,
        "lng": -6.9997
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10035,
      "name": "Eidhi",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.2995,
        "lng": -7.0924
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10036,
      "name": "Salovci",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.825,
        "lng": 16.2981
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 10037,
      "name": "Pili",
      "names": {
        "zh": "菌毛",
        "ja": "線毛"
      },
      "countryCode": "PH",
      "latLng": {
        "lat": 13.7177,
        "lng": 123.7448
      },
      "country": "Philippines",
      "importance": 1
    },
    {
      "id": 10038,
      "name": "Sao Joao dos Angolares",
      "names": {
        "zh": "圣若浩Dos Angolares",
        "id": "Sao Joao dos Anglares",
        "ja": "SAO JOAO DOSアンポラリー",
        "pt": "São João dos Angolaares"
      },
      "countryCode": "ST",
      "latLng": {
        "lat": 0.1342,
        "lng": 6.6494
      },
      "country": "Sao Tome And Principe",
      "importance": 1
    },
    {
      "id": 10039,
      "name": "Vestmanna",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.1548,
        "lng": -7.169
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10041,
      "name": "Kyankwanzi",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.1987,
        "lng": 31.8062
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 10042,
      "name": "Raseiniai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.3797,
        "lng": 23.1239
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 10043,
      "name": "Trashi Yangtse",
      "names": {
        "de": "Trashi Ytttse"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.6116,
        "lng": 91.498
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 10044,
      "name": "Kone",
      "names": {
        "zh": "通力",
        "th": "โคน"
      },
      "countryCode": "NC",
      "latLng": {
        "lat": -21.0667,
        "lng": 164.8667
      },
      "country": "New Caledonia",
      "importance": 1
    },
    {
      "id": 10045,
      "name": "Kvivik",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.1186,
        "lng": -7.0737
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10046,
      "name": "Phon-Hong",
      "names": {
        "zh": "响度-香港",
        "ja": "ホン・香港",
        "th": "พล-ฮ่องกง",
        "pt": "Telefone-Hong"
      },
      "countryCode": "LA",
      "latLng": {
        "lat": 18.4953,
        "lng": 102.4153
      },
      "country": "Laos",
      "importance": 1
    },
    {
      "id": 10047,
      "name": "Sumba",
      "names": {
        "zh": "尊巴"
      },
      "countryCode": "FO",
      "latLng": {
        "lat": 61.4055,
        "lng": -6.709
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10048,
      "name": "Sandavagur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.0537,
        "lng": -7.1498
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10049,
      "name": "Trgoviste",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.3514,
        "lng": 22.0921
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10050,
      "name": "Solcava",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.4194,
        "lng": 14.6936
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 10051,
      "name": "Zalingei",
      "names": {
        "zh": "扎林盖"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 12.9092,
        "lng": 23.4706
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 10052,
      "name": "Sola",
      "names": {
        "zh": "索拉",
        "ja": "クサネム",
        "th": "รัชทายาท"
      },
      "countryCode": "VU",
      "latLng": {
        "lat": -13.8761,
        "lng": 167.5517
      },
      "country": "Vanuatu",
      "importance": 1
    },
    {
      "id": 10053,
      "name": "Hodos",
      "names": {
        "zh": "霍多什"
      },
      "countryCode": "SI",
      "latLng": {
        "lat": 46.8233,
        "lng": 16.3342
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 10054,
      "name": "Hov",
      "names": {
        "zh": "霍夫"
      },
      "countryCode": "FO",
      "latLng": {
        "lat": 61.5068,
        "lng": -6.7599
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10055,
      "name": "Semera",
      "names": {},
      "countryCode": "ET",
      "latLng": {
        "lat": 11.7956,
        "lng": 41.0086
      },
      "country": "Ethiopia",
      "importance": 1
    },
    {
      "id": 10056,
      "name": "Anew",
      "names": {
        "es": "De nuevo",
        "fr": "Nouveau",
        "zh": "重新",
        "de": "Neu",
        "it": "Di nuovo",
        "id": "Lagi",
        "ja": "新たに",
        "th": "อีกครั้ง",
        "pt": "De novo"
      },
      "countryCode": "TM",
      "latLng": {
        "lat": 37.8875,
        "lng": 58.516
      },
      "country": "Turkmenistan",
      "importance": 1
    },
    {
      "id": 10057,
      "name": "Masunga",
      "names": {},
      "countryCode": "BW",
      "latLng": {
        "lat": -20.6245,
        "lng": 27.4488
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 10058,
      "name": "Kostel",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 45.5084,
        "lng": 14.9101
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 10059,
      "name": "Tanjung Selor",
      "names": {
        "ja": "タンジュンセロール"
      },
      "countryCode": "ID",
      "latLng": {
        "lat": 2.8375,
        "lng": 117.3653
      },
      "country": "Indonesia",
      "importance": 1
    },
    {
      "id": 10060,
      "name": "Vidhareidhi",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.36,
        "lng": -6.5313
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10061,
      "name": "Saleaula",
      "names": {},
      "countryCode": "WS",
      "latLng": {
        "lat": -13.4489,
        "lng": -172.3352
      },
      "country": "Samoa",
      "importance": 1
    },
    {
      "id": 10062,
      "name": "Xizi",
      "names": {
        "zh": "西子"
      },
      "countryCode": "AZ",
      "latLng": {
        "lat": 40.9111,
        "lng": 49.0694
      },
      "country": "Azerbaijan",
      "importance": 1
    },
    {
      "id": 10063,
      "name": "Trongsa",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.5168,
        "lng": 90.5
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 10064,
      "name": "Damongo",
      "names": {},
      "countryCode": "GH",
      "latLng": {
        "lat": 9.083,
        "lng": -1.8188
      },
      "country": "Ghana",
      "importance": 1
    },
    {
      "id": 10065,
      "name": "Zhemgang",
      "names": {
        "th": "เซมกัง"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.2169,
        "lng": 90.6579
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 10066,
      "name": "Tigoa",
      "names": {},
      "countryCode": "SB",
      "latLng": {
        "lat": -11.5531,
        "lng": 160.0647
      },
      "country": "Solomon Islands",
      "importance": 1
    },
    {
      "id": 10067,
      "name": "Zagubica",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 44.1979,
        "lng": 21.7902
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10068,
      "name": "Sandur",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.8344,
        "lng": -6.8171
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10069,
      "name": "El Fula",
      "names": {
        "zh": "埃尔福拉",
        "it": "El foro",
        "ja": "エルフラ"
      },
      "countryCode": "SD",
      "latLng": {
        "lat": 11.712,
        "lng": 28.3462
      },
      "country": "Sudan",
      "importance": 1
    },
    {
      "id": 10070,
      "name": "Ignalina",
      "names": {
        "zh": "伊格纳利纳"
      },
      "countryCode": "LT",
      "latLng": {
        "lat": 55.3406,
        "lng": 26.1605
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 10071,
      "name": "Parun",
      "names": {},
      "countryCode": "AF",
      "latLng": {
        "lat": 35.4206,
        "lng": 70.9226
      },
      "country": "Afghanistan",
      "importance": 1
    },
    {
      "id": 10072,
      "name": "Lupane",
      "names": {},
      "countryCode": "ZW",
      "latLng": {
        "lat": -18.9315,
        "lng": 27.807
      },
      "country": "Zimbabwe",
      "importance": 1
    },
    {
      "id": 10073,
      "name": "Fish Town",
      "names": {
        "es": "Pueblo de peces",
        "fr": "Ville de pêche",
        "zh": "鱼镇",
        "de": "Fischstadt",
        "it": "Città di pesci",
        "id": "Kota ikan",
        "ja": "魚の町",
        "th": "เมืองปลา",
        "pt": "Cidade de peixe"
      },
      "countryCode": "LR",
      "latLng": {
        "lat": 5.1974,
        "lng": -7.8758
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 10074,
      "name": "Loango",
      "names": {
        "zh": "卢安果"
      },
      "countryCode": "CG",
      "latLng": {
        "lat": -4.6519,
        "lng": 11.8125
      },
      "country": "Congo (Brazzaville)",
      "importance": 1
    },
    {
      "id": 10075,
      "name": "Bopolu",
      "names": {},
      "countryCode": "LR",
      "latLng": {
        "lat": 7.0667,
        "lng": -10.4875
      },
      "country": "Liberia",
      "importance": 1
    },
    {
      "id": 10076,
      "name": "Amdjarass",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 16.0667,
        "lng": 22.8354
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 10077,
      "name": "Laascaanood",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 8.4774,
        "lng": 47.3597
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 10078,
      "name": "Ntoroko",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 1.0411,
        "lng": 30.4811
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 10079,
      "name": "Kolasin",
      "names": {
        "zh": "库拉辛",
        "th": "โกลาสิน"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 42.825,
        "lng": 19.518
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 10080,
      "name": "Husavik",
      "names": {
        "zh": "胡萨维克"
      },
      "countryCode": "FO",
      "latLng": {
        "lat": 61.8099,
        "lng": -6.6813
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10081,
      "name": "Jakar",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.5492,
        "lng": 90.7525
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 10082,
      "name": "Georgetown",
      "names": {
        "zh": "乔治敦",
        "ja": "ジョージタウン",
        "th": "จอร์จทาวน์"
      },
      "countryCode": "SH",
      "latLng": {
        "lat": -7.9286,
        "lng": -14.4119
      },
      "country": "Saint Helena, Ascension, And Tristan Da Cunha",
      "importance": 1
    },
    {
      "id": 10083,
      "name": "Lhuentse",
      "names": {},
      "countryCode": "BT",
      "latLng": {
        "lat": 27.6679,
        "lng": 91.1839
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 10084,
      "name": "Ceerigaabo",
      "names": {},
      "countryCode": "SO",
      "latLng": {
        "lat": 10.6162,
        "lng": 47.3679
      },
      "country": "Somalia",
      "importance": 1
    },
    {
      "id": 10085,
      "name": "Kunoy",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.2917,
        "lng": -6.6702
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10086,
      "name": "Skalavik",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.8314,
        "lng": -6.6623
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10087,
      "name": "Daga",
      "names": {
        "zh": "达加"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.0753,
        "lng": 89.8769
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 10088,
      "name": "We",
      "names": {
        "es": "NOSOTROS",
        "fr": "Nous",
        "zh": "我们",
        "de": "Wir",
        "it": "Noi",
        "id": "Kita",
        "ja": "私達",
        "th": "เรา",
        "pt": "NÓS"
      },
      "countryCode": "NC",
      "latLng": {
        "lat": -20.9,
        "lng": 167.2667
      },
      "country": "New Caledonia",
      "importance": 1
    },
    {
      "id": 10089,
      "name": "Kirkja",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 62.3263,
        "lng": -6.3238
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10090,
      "name": "Sowa Town",
      "names": {
        "es": "Ciudad de Sowa",
        "fr": "Ville de Sowa",
        "zh": "Sowa镇",
        "it": "Città di sowa",
        "id": "Kota Sowa",
        "ja": "ソウラの町",
        "th": "เมืองโซวา",
        "pt": "Cidade de sowa"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -20.5636,
        "lng": 26.2244
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 10091,
      "name": "Sieyik",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 9.3832,
        "lng": -82.6521
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 10092,
      "name": "Zgornje Jezersko",
      "names": {},
      "countryCode": "SI",
      "latLng": {
        "lat": 46.3833,
        "lng": 14.4667
      },
      "country": "Slovenia",
      "importance": 1
    },
    {
      "id": 10093,
      "name": "Awbari",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 26.5833,
        "lng": 12.7667
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 10094,
      "name": "Choyr",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 46.3611,
        "lng": 108.3611
      },
      "country": "Mongolia",
      "importance": 1
    },
    {
      "id": 10095,
      "name": "Savnik",
      "names": {},
      "countryCode": "ME",
      "latLng": {
        "lat": 42.95,
        "lng": 19.1
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 10096,
      "name": "Pluzine",
      "names": {
        "zh": "普卢日内"
      },
      "countryCode": "ME",
      "latLng": {
        "lat": 43.1528,
        "lng": 18.8394
      },
      "country": "Montenegro",
      "importance": 1
    },
    {
      "id": 10097,
      "name": "Edinburgh of the Seven Seas",
      "names": {
        "es": "Edimburgo de los siete mares",
        "fr": "Édimbourg des Seven Seas",
        "zh": "七海的爱丁堡",
        "de": "Edinburgh der sieben Meere",
        "it": "Edimburgo dei sette mari",
        "id": "Edinburgh dari Seven Seas",
        "ja": "セブンの海のエジンバラ",
        "th": "เอดินบะระของทะเลทั้งเจ็ด",
        "pt": "Edimburgo dos sete mares"
      },
      "countryCode": "SH",
      "latLng": {
        "lat": -37.0675,
        "lng": -12.3105
      },
      "country": "Saint Helena, Ascension, And Tristan Da Cunha",
      "importance": 1
    },
    {
      "id": 10098,
      "name": "Skuvoy",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.771,
        "lng": -6.805
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 10099,
      "name": "Ntchisi",
      "names": {},
      "countryCode": "MW",
      "latLng": {
        "lat": -13.3753,
        "lng": 34.0036
      },
      "country": "Malawi",
      "importance": 1
    },
    {
      "id": 10100,
      "name": "Gasa",
      "names": {
        "zh": "加萨"
      },
      "countryCode": "BT",
      "latLng": {
        "lat": 27.9067,
        "lng": 89.7304
      },
      "country": "Bhutan",
      "importance": 1
    },
    {
      "id": 10101,
      "name": "Jwaneng",
      "names": {
        "zh": "吉瓦宁"
      },
      "countryCode": "BW",
      "latLng": {
        "lat": -24.6017,
        "lng": 24.7281
      },
      "country": "Botswana",
      "importance": 1
    },
    {
      "id": 10102,
      "name": "Hayma\u0027",
      "names": {
        "es": "Hayma \u0027",
        "fr": "Hayma \u0027",
        "de": "Hayma \u0027",
        "it": "Hayma \u0027",
        "id": "Hayma \u0027",
        "ja": "hayma \u0027",
        "th": "hayma \u0027",
        "pt": "Hayma \u0027"
      },
      "countryCode": "OM",
      "latLng": {
        "lat": 19.9333,
        "lng": 56.3167
      },
      "country": "Oman",
      "importance": 1
    },
    {
      "id": 10103,
      "name": "Idri",
      "names": {},
      "countryCode": "LY",
      "latLng": {
        "lat": 27.4471,
        "lng": 13.0517
      },
      "country": "Libya",
      "importance": 1
    },
    {
      "id": 10104,
      "name": "Mahdia",
      "names": {
        "zh": "马赫迪耶",
        "th": "มาห์"
      },
      "countryCode": "GY",
      "latLng": {
        "lat": 5.2667,
        "lng": -59.15
      },
      "country": "Guyana",
      "importance": 1
    },
    {
      "id": 10105,
      "name": "Union Choco",
      "names": {
        "zh": "联盟Choco",
        "it": "Unione Choco",
        "ja": "ユニオンチョコ",
        "th": "ยูเนียนช็อคโก"
      },
      "countryCode": "PA",
      "latLng": {
        "lat": 8.0778,
        "lng": -77.5583
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 10106,
      "name": "Bardai",
      "names": {},
      "countryCode": "TD",
      "latLng": {
        "lat": 21.3547,
        "lng": 17.0016
      },
      "country": "Chad",
      "importance": 1
    },
    {
      "id": 10107,
      "name": "Presevo",
      "names": {},
      "countryCode": "RS",
      "latLng": {
        "lat": 42.3067,
        "lng": 21.65
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10108,
      "name": "Bujanovac",
      "names": {
        "zh": "布亚诺瓦茨"
      },
      "countryCode": "RS",
      "latLng": {
        "lat": 42.4667,
        "lng": 21.7667
      },
      "country": "Serbia",
      "importance": 1
    },
    {
      "id": 10109,
      "name": "Kitamilo",
      "names": {},
      "countryCode": "UG",
      "latLng": {
        "lat": 0.2222,
        "lng": 33.2061
      },
      "country": "Uganda",
      "importance": 1
    },
    {
      "id": 42281,
      "name": "Charlotte Amalie",
      "names": {
        "zh": "夏洛特阿马利",
        "ja": "シャーロットアマリエ"
      },
      "countryCode": "VI",
      "latLng": {
        "lat": 18.3419,
        "lng": -64.9332
      },
      "country": "U.S. Virgin Islands",
      "importance": 0
    },
    {
      "id": 9911,
      "name": "Famjin",
      "names": {},
      "countryCode": "FO",
      "latLng": {
        "lat": 61.5264,
        "lng": -6.8769
      },
      "country": "Faroe Islands",
      "importance": 1
    },
    {
      "id": 9748,
      "name": "Studenicani",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9158,
        "lng": 21.5306
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 9939,
      "name": "Pagegiai",
      "names": {},
      "countryCode": "LT",
      "latLng": {
        "lat": 55.1328,
        "lng": 21.8778
      },
      "country": "Lithuania",
      "importance": 1
    },
    {
      "id": 9733,
      "name": "Bogovinje",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 41.9233,
        "lng": 20.9133
      },
      "country": "Macedonia",
      "importance": 1
    },
    {
      "id": 10040,
      "name": "Gaigirgordub",
      "names": {},
      "countryCode": "PA",
      "latLng": {
        "lat": 9.5583,
        "lng": -78.9483
      },
      "country": "Panama",
      "importance": 1
    },
    {
      "id": 42272,
      "name": "Hodrogo",
      "names": {},
      "countryCode": "MN",
      "latLng": {
        "lat": 48.9664,
        "lng": 96.7833
      },
      "country": "Mongolia",
      "importance": 2
    },
    {
      "id": 9398,
      "name": "Mawlamyine",
      "names": {},
      "countryCode": "MM",
      "latLng": {
        "lat": 16.4847,
        "lng": 97.6258
      },
      "country": "Myanmar",
      "importance": 1
    },
    {
      "id": 9865,
      "name": "Lipkovo",
      "names": {},
      "countryCode": "MK",
      "latLng": {
        "lat": 42.1553,
        "lng": 21.5875
      },
      "country": "Macedonia",
      "importance": 1
    }
  ]
}