import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { slideInOutAnimation } from 'src/app/utils/animations';
import { logEventMethod } from 'src/app/utils/logging';
import { setSeoText } from 'src/app/utils/utils';

@Component({
  selector: 'app-partner-ad',
  templateUrl: './partner-ad.component.html',
  styleUrls: ['./partner-ad.component.css', '../../../../app.component.css'],
})
export class PartnerAdComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) public platformId: Object,
    @Inject(DOCUMENT) public _document: Document,
  ) { }

  partnerType;
  whyBePartner;
  howItWorks;
  tourSalesRange = 1
  averageTourPrice = 4.49
  averageSubscriptionPrice = 20
  showingBottomBar = false

  ngOnInit() {
    this.setSeo();
    this.partnerType = this.route.snapshot.queryParamMap.get('partnerType');
    let utmSource = this.route.snapshot.queryParamMap.get('utm_source');
    if (this.partnerType == undefined) {
      this.partnerType = this.ACCOMMODATION;
    }
    this.whyBePartner = this.partnerType == this.INDIVIDUAL ? this.whyBePartnerIndividual : this.whyBePartnerBusiness;
    this.howItWorks = this.partnerType == this.INDIVIDUAL ? this.howsItWorkIndividual : this.howsItWorkBusiness;
    if (isPlatformBrowser(this.platformId)) {
      logEventMethod("partner_ad_page_view", { type: this.partnerType, utm_source: utmSource });
    }
  }

  signup() {
    this.router.navigate(['/become-a-partner/signup']);
  }

  setSeo() {
    let title = 'AllTours - Become A Partner and Earn 40% Commission';
    let description = "Join AllTours and earn a 40% commission for every tour purchased through your referral. Start your journey to unlimited earning potential today!"
    setSeoText(this._document, title, description);
  }

  goToAppStore() {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
        window.location.href = `https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone`;
      } else {
        window.location.href = `https://play.google.com/store/apps/details?id=app.alltours`;
      }
    }
  }

  tourEarnings = [
    {
      name: "Cheapest tour",
      price: 2.99,
      commissionRate: 0.4
    },
    {
      name: "Average tour",
      price: 4.49,
      commissionRate: 0.4
    },
    {
      name: "Most expensive tour",
      price: 5.99,
      commissionRate: 0.4
    }
  ]

  unlimitedEarnings = [
    {
      name: "1 Week",
      price: 7.49,
      commissionRate: 0.3
    },
    {
      name: "1 Month",
      price: 14.99,
      commissionRate: 0.3
    },
    {
      name: "1 Year",
      price: 79.99,
      commissionRate: 0.3
    }
  ]

  whatIsAllTours = [
    {
      title: "Interactive, location-aware audio tours",
      description: "AllTours provides audio that plays automatically based on GPS, allowing travelers to explore without checking their screens.",
      image: 'screenshot_0.png'
    },
    {
      title: "High-quality, story-driven narratives",
      description: "Each tour blends history, culture, and legends for an engaging and educational experience.",
      image: 'screenshot_1.png'
    },
    {
      title: "Offline access for flexible exploration",
      description: "Users can download tours and maps for offline use, ensuring hassle-free exploration.",
      image: 'screenshot_3.png'
    }
  ]


  whyBePartnerBusiness = [
    {
      title: 'Give your guests something to do',
      description: 'Enrich your guests experience, give them a fun, budget friendly way of exploring the city.',
    },
    {
      title: 'Easy setup',
      description: 'Within minutes you could be setup and ready to start earning commission.',
    },
    {
      title: 'Make some extra money',
      description:
        'We pay you 40% on every tour & 30% on every first subscription payment, enabling you to earn up to $20 per guest.',
    },
    {
      title: 'Always have our support',
      description:
        "You will be able to <a href='/contact'>contact Kes</a>, the founder of AllTours, at anytime with your questions or issues.",
    },
  ];

  whyBePartnerIndividual = [
    {
      title: 'Monetize your following',
      description:
        'We pay you 40% on every tour & 30% on every first subscription payment, enabling you to earn up to $20 per referral.',
    },
    {
      title: 'Easy setup',
      description: 'Within minutes you could be setup and ready to start earning commission.',
    },
    {
      title: 'Always have our support',
      description:
        "You will be able to <a href='/contact'>contact Kes</a>, the founder of AllTours, at anytime with your questions or issues.",
    },
  ];

  howsItWorkBusiness = [
    {
      title: 'You display AllTours posters',
      description: "Provide a few basic details about your business, set up your payment preferences, and print eye-catching AllTours posters to display.",
      image: 'partner-ad-1.png'
    },
    {
      title: 'Guests scan & purchase',
      description: "When guests scan the poster's QR code and buy a tour, you earn a generous 40% commission on every purchase.",
      image: 'partner-ad-2.png'
    },
    {
      title: 'Guests explore & enjoy',
      description: "Your guests get to explore the city with fun, budget-friendly tours, enhancing their stay while supporting your business.",
      image: 'partner-ad-3.png'
    },
    {
      title: 'You get paid',
      description: "Payments are sent directly to you every Friday, making it easy to keep track of your earnings.",
      image: 'partner-ad-4.png'
    },
  ];

  howsItWorkIndividual = [
    {
      title: 'Signup to the partnership program',
      description: "Just give us some basic details on yourself & setup how you're going to receive payments.",
    },
    {
      title: 'Download some AllTours marketing assets',
      description:
        "We'll provide you with marketing material and your own unique referral link. You can the post these anywhere you want.",
    },
    {
      title: 'Start earning money',
      description: 'Every time someone visits your link and purchases a tour, you will earn a 40% commission!',
    },
  ];

  faqs = [
    {
      title: 'How often will I be paid?',
      description:
        "You will receive a payment once a month at the end of the month as long as you have earned more than the $10 minimum. If you havn't reached this minimum, your earnings will roll over onto the next month.",
    },
    {
      title: 'How long can I keep earning commission from the same user?',
      description:
        'You can continue to earn commission from a user 2 weeks after they first visit your unique link. After the 2 weeks, you will stop earning commission from that user. If the user purchases 5 tours for $5 each in the first 2 weeks at a 40% commission rate, you will earn 40% of the total $25 spent: $10.',
    },
    {
      title: 'What if a user that I refer has already used AllTours before?',
      description: 'You can only earn commission from new users that you refer to AllTours.',
    },
  ];

  ACCOMMODATION = 'accommodation';
  INDIVIDUAL = 'individual';
  OTHER_BUSINESS = 'other_business';
}
