import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unlimited',
  templateUrl: './unlimited.component.html',
  styleUrls: ['./unlimited.component.css', '../../../app.component.css'],
})
export class UnlimitedComponent implements OnInit {
  landmarks = [];

  constructor() { }

  ngOnInit() { }

  subscribe() { }

  /*
      firebase.firestore().collection("guided-tours")
    .limit(40).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if(doc.data().landmarks != undefined){
          let images = doc.data().landmarks.map(landmark => landmark.imageUrl).filter(url => url != undefined)
          this.landmarks.push(...images)
        }
      })
      this.landmarks.sort(() => (Math.random() > 0.5) ? 1 : -1)
      this.landmarks = [...new Set(this.landmarks)]
    })

</div>
  */
}
