import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import seoText from '../assets/json/seo_text.json'

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    @Inject(DOCUMENT) public _document: Document
  ) { }

  setTourPageSeo(tourId, cityName = undefined) {
    let tour = seoText.tours[tourId]
    if (tour == undefined) {
      return
    }
    if (cityName != undefined) {
      cityName = cityName.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
    }
    let title = tour.title.includes(":") && cityName != undefined ? `${cityName} Audio Tour: ${tour.title.split(":")[1].trim()}` : tour.title
    let description = tour.description.split(". ").slice(0, 2).join(". ")
    this.setSeoText(title, description)
  }

  setCountryPageSeo(countryCode) {
    let country = seoText.countries[countryCode.toLowerCase()]
    if (country != undefined) {
      this.setSeoText(country.title, country.description)
    }
  }

  setCityPageSeo(cityName) {
    cityName = cityName.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
    const hashCode = cityName.split('').reduce((acc, char) => {
      const hash = ((acc << 5) - acc) + char.charCodeAt(0);
      return hash & hash;
    }, 0);

    const subtitleIndex = Math.abs(hashCode) % seoText.cities.subTitles.length;
    const descriptionIndex = Math.abs(hashCode) % seoText.cities.descriptions.length;

    let subTitle = seoText.cities.subTitles[subtitleIndex]
    let description = seoText.cities.descriptions[descriptionIndex].replaceAll("%s", cityName)
    let title = `${cityName} Audio Tours: ${subTitle}`
    this.setSeoText(title, description)
  }

  setSeoText(title, description, image = "https://alltours.app/assets/images/alltours/screenshots/audio.jpg") {
    console.log('setting seo text', title, description)
    if (this._document) {
      if (title != undefined) {
        title = title.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
        this._document.title = title;
      }
      if (this._document.head.children.namedItem('description') && description != undefined) {
        this._document.head.children.namedItem('description')['content'] = description;
      }

      this.setOgProperties({
        'og:title': title,
        'og:description': description != undefined ? description.split(/(?<=[.!?])\s+/)[0] : "Entertaining self guided audio tours.",
        'og:image': image
      });

    }
  }

  setOgProperties(properties) {
    Object.entries(properties).forEach(([property, content]) => {
      let metaTag = this._document.querySelector(`meta[property="${property}"]`);
      if (metaTag) {
        metaTag.setAttribute('content', content as string);
      } else {
        metaTag = this._document.createElement('meta');
        metaTag.setAttribute('property', property);
        metaTag.setAttribute('content', content as string);
        this._document.head.appendChild(metaTag);
      }
    });
  }

}