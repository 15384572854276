import { Component, OnInit, Inject, PLATFORM_ID, WritableSignal, signal } from '@angular/core';
// import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import stringsJson from './../assets/strings/strings.json';
// import { FormsModule } from '@angular/forms';
import { AdminAuthService } from './alltours-app/admin/admin-auth-service';
// import { Purchases } from '@revenuecat/purchases-js';
import { UserService } from './alltours-app/user/user.service';
import { PartnerService } from './alltours-app/partner/partner-service';
// import { PurchasesService } from './alltours-app/user/purchases.service';
// import { animate, state, style, transition, trigger } from '@angular/animations';
// import { slideInOutAnimation } from './utils/animations';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { BreadcrumbService } from './bread-crumb.service';
// import { BreadcrumbService } from './bread-crumb.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  todaysDate = Date.now();
  strings;
  language;
  lang;
  isAdmin;
  userType: UserType
  UserType = UserType
  loadingPage = true;
  isBrowser: WritableSignal<boolean> = signal(false);

  constructor(
    public router: Router,
    @Inject(MatDialog) public dialog: MatDialog,
    private route: ActivatedRoute,
    private adminAuthService: AdminAuthService,
    public userService: UserService,
    public partnerService: PartnerService,
    private breadcrumbService: BreadcrumbService,
    @Inject(PLATFORM_ID) public platformId: Object,
    @Inject(DOCUMENT) public _document: Document,
  ) {
    this._document.title = 'AllTours';
    this.isAdmin = this.adminAuthService.loggedInAsAdmin();
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser.set(true);
      this.language = window.location.hostname.split('.')[0];
    }
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return true;
    // };
    const linkElement = this._document.querySelector('link[rel=icon]');
    linkElement['href'] = '../../assets/images/alltours/favicon.ico';
    this.listenToRouter();
  }

  ngOnInit() {
    this.breadcrumbService.initBreadcrumbs();
  }

  goToApp() {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
        window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
      } else {
        window.open('https://play.google.com/store/apps/details?id=app.alltours');
      }
    }
  }

  listenToRouter() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingPage = true;
      }
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loadingPage = false;
      }
    });
  }
}


export enum UserType {
  USER,
  ADMIN,
  PARTNER
}
