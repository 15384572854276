<div class="container">
  <div class="row">
    <div class="col-lg-6 offset-lg-3">
      <div class="card" style="margin-top: 20px">
        <div class="card-header">
          <h4>Signup</h4>
        </div>
        <div class="card-body">
          <div *ngIf="partnerType == undefined">
            <p>Select a partner type</p>
            <div *ngFor="let type of partnerTypes">
              <div class="rounded-box" (click)="selectPartnerType(type.id)" style="display: flex;">
                <span class="material-symbols-rounded icon" style="align-content: center; padding-right: 8px;">{{
                  type.icon }}</span>
                <div>
                  <b>
                    {{ type.title }}
                  </b><br />
                  <span class="secondaryText">{{ type.description }}</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="partnerType != undefined">
            <p *ngIf="partnerType == ACCOMMODATION || partnerType == OTHER_BUSINESS">Enter the business' details</p>
            <p *ngIf="partnerType == INDIVIDUAL">Enter your details</p>
            <form id="signupForm">
              <div class="mb-3">
                <label for="contactName" class="form-label">Your name</label>
                <input name="contactName" id="contactName" type="text" class="form-control" maxlength="200" required />
                <div class="invalid-feedback">Please provide a name.</div>
              </div>
              <div *ngIf="partnerType == ACCOMMODATION || partnerType == OTHER_BUSINESS">
                <div class="mb-3">
                  <label for="businessName" class="form-label">Business name</label>
                  <input name="businessName" id="businessName" type="text" class="form-control" maxlength="200"
                    required />
                  <div class="invalid-feedback">Please provide a name.</div>
                </div>
                <div class="mb-3">
                  <label *ngIf="selectedCities.length < 2" for="inputCity" class="form-label">City</label>
                  <label *ngIf="selectedCities.length >= 2" for="inputCity" class="form-label">Cities</label>
                  <div class="input-group mb-3" *ngFor="let city of selectedCities">
                    <input value="{{city.name}}" type="text" class="form-control" readonly />
                    <button type="button" class="btn btn-danger" style="margin-left: 8px"
                      (click)="removeCity(city)"><span class="material-symbols-rounded">delete</span></button>
                  </div>
                  <div *ngIf="showCitySearch" class="form-group position-relative">
                    <input id="citySearchInput" type="text" class="form-control"
                      (input)="filterCities($event.target.value)" autocomplete="off">
                    <div *ngIf="filteredCities.length > 0" class="dropdown-menu show">
                      <a *ngFor="let city of filteredCities" class="dropdown-item" (click)="selectCity(city)">
                        {{ city.name }}, {{ city.country }}
                      </a>
                    </div>
                  </div>
                  <a *ngIf="selectedCities.length > 0 && showCitySearch == false" style="color: blue; font-size: 12px;"
                    (click)="showCitySearch = true">Add another city</a>
                  <small *ngIf="selectedCities.length == 0" class="form-text text-muted">City where your
                    hotel/hostel/business is located</small>
                </div>
              </div>
              <div class="mb-3" *ngIf="partnerType == INDIVIDUAL">
                <label for="links" class="form-label">Social media links</label>
                <div class="input-group mb-3" *ngFor="let link of socialLinks">
                  <input value="{{link}}" type="text" class="form-control" readonly />
                  <button type="button" class="btn btn-danger" style="margin-left: 8px"
                    (click)="removeSocialLink(link)"><span class="material-symbols-rounded">delete</span></button>
                </div>
                <input id="newLinkInput" class="form-control" name="links" required />
                <small class="form-text text-muted">Links to pages you plan on sharing
                  AllTours content</small>
                <br />
                <a style="color: blue; font-size: 12px;" (click)="addToLinks()">Add
                  another site</a>
              </div>
              <div class="mb-3">
                <label for="emailAddress" class="form-label">Email address</label>
                <input name="emailAddress" id="emailAddress" type="email" class="form-control" maxlength="200"
                  required />
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input name="password" id="password" type="password" class="form-control" maxlength="200" required />
              </div>
              <div class="mb-3">
                <label for="passwordConfirm" class="form-label">Confirm password</label>
                <input name="passwordConfirm" id="passwordConfirm" type="password" class="form-control" maxlength="200"
                  required />
              </div>
              <div class="form-check">
                <input class="form-check-input" name="agreedToTerms" type="checkbox" value="" id="invalidCheck"
                  required />
                <label class="form-check-label" for="invalidCheck">
                  By checking this box you confirm that you have read the AllTours Partner
                  <a href="/become-a-partner/terms" target="_blank">terms and conditions.</a>
                </label>
              </div>
              <div class="col-12" style="text-align: center">
                <button *ngIf="!loading" class="ctaBtnMini" type="submit"
                  style="width: 100%; margin-top: 16px">Signup</button>
                <div *ngIf="loading" style="text-align: center; margin: auto;">
                  <div class="loader" style="margin: auto; padding: 8px;"></div>
                  Loading...
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>