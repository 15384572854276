<div style="background: #fafafa;">
  <nav style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
    class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="">
        <div style="display: flex">
          <img id="landingIcon" src="../../assets/images/alltours/logo.png" alt="AllTours logo" />
          <span id="landingText">AllTours</span>
        </div>
      </a>
      <div class="navbar" id="navbarNav" style="padding: 0;">
        <ul class="navbar-nav me-auto" style="flex-direction: row;">
          <li class="nav-item" style="margin-right: 16px">
            <button class="loginBtn btn" routerLink="/become-a-partner/login">Login</button>
          </li>
          <li class="nav-item">
            <button class="createAccountBtn ctaBtn" style="background: #daff5e; color: #000; border: solid 1.5px;"
              routerLink="/become-a-partner/signup">Become A Partner</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div style="color: #000; background-color: #fafafa; min-height: 95vh">
    <router-outlet></router-outlet>
  </div>
  <div id="contactFooter" style="color: #000; background-color: #fefcf4; margin-top: 64px;">
    <div class="container">
      <app-inner-contact-us></app-inner-contact-us>
    </div>
  </div>
  <div class="footer" style="background: #fefcf4;">
    <div class="container page-footer white font-small" style="color: grey">
      <div class="row">
        <div class="text-center py-3 col-xl-4 col-sm-6 col-sx-12" style="margin: auto; font-size: 0.75rem">
          <a href="/become-a-partner/terms">Partnership Terms</a>
        </div>
      </div>
    </div>
  </div>
</div>