<div style="display: flex">
  <div style="width: 100%">
    <picture>
      <source srcset="
          ../../../assets/images/alltours/converted/london-480w.webp 480w,
          ../../../assets/images/alltours/converted/london-800w.webp 800w,
          ../../../assets/images/alltours/converted/london.webp 1200w
        " type="image/webp" sizes="100vw" />
      <source srcset="
          ../../../assets/images/alltours/converted/london-480w.jpg 480w,
          ../../../assets/images/alltours/converted/london-800w.jpg 800w,
          ../../../assets/images/alltours/converted/london.jpg 1200w
        " type="image/jpeg" sizes="100vw" />
      <img style="width: 100%; height: 36vh; object-fit: cover"
        src="../../../assets/images/alltours/converted/london.jpg" alt="London skyline background" loading="eager"
        fetchpriority="high" />
    </picture>
  </div>
  <div class="imgCover" style="height: 36vh">
    <div class="container" style="height: 100%">
      <div class="row" style="position: absolute">
        <nav class="col-12" aria-label="breadcrumb">
          <ol class="breadcrumb" style="background-color: #0000">
            <li class="breadcrumb-item">
              <a class="bread-link" href="">{{ strings.home }}</a>
            </li>
            <li class="breadcrumb-item" aria-current="page">{{ breadcrumbName }}</li>
          </ol>
        </nav>
      </div>
      <div class="row" style="height: 100%">
        <div class="col-lg-6 col-md-12" style="align-self: center">
          <h1>{{ strings.create_tours_in_cities }}</h1>
        </div>
        <div class="col-lg-6 cities-img">
          <picture>
            <source srcset="../../../assets/images/alltours/converted/screenshots/city_hero.webp" type="image/webp" />
            <img style="height: 36vh" src="../../../assets/images/alltours/converted/screenshots/city_hero.png"
              alt="City hero illustration" loading="eager" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="background: #fff; min-height: 40vh">
  <div class="container" style="color: #595959">
    <div class="row">
      <div class="col-12" style="display: flex">
        <img *ngIf="selectedCountry != undefined" src="../../../assets/flags/{{ selectedCountry }}.png"
          class="country-img-title" alt="Flag of {{ selectedCountry }}" loading="lazy" />
        <h3 style="padding-top: 16px; padding-bottom: 16px">{{ title }}</h3>
      </div>
      <div *ngFor="let city of cities; trackBy: trackByCity" class="col-lg-2 col-md-3 col-sm-4 col-6">
        <a class="city-name" href="/tour/{{ city.country.toLowerCase() }}/{{ city.name.toLowerCase() }}">
          {{ city.names[language] ?? city.name }}
        </a>
      </div>
      <div *ngIf="countries != undefined" class="col-12">
        <h3 style="padding-top: 16px; padding-bottom: 16px">{{ strings.top_countries }}</h3>
      </div>
      <div *ngFor="let group of countries; trackBy: trackByCountry" class="col-lg-3 col-md-4 col-6 country">
        <img *ngIf="group.countryCode" class="country-img"
          src="../../../assets/flags/{{ group.countryCode.toLowerCase() }}.png"
          alt="Flag of {{ countryNames[group.countryCode]?.[language] }}" loading="lazy" width="32" height="32" />
        <div style="margin-left: 8px">
          <a *ngIf="group.countryCode && countryNames[group.countryCode]?.[language]"
            href="/tour/{{ countryNames[group.countryCode][language].toLowerCase() }}">
            {{ countryNames[group.countryCode][language] }}
          </a><br />
          <span>{{ group.cities?.length || 0 }} {{ strings.cities }}</span>
        </div>
      </div>
    </div>
  </div>
</div>