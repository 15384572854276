<div *ngIf="review != null && review != undefined">
  <div style="display: flex">
    <img *ngIf="review.userImg != undefined" src="imgUrl" style="height: 36px; width: 36px; border-radius: 50%"
      alt="User profile" />
    <span style="color: #000a; font-size: 36px; margin-right: 12px" *ngIf="review.userImg == undefined"
      class="material-symbols-rounded">account_circle</span>
    <div>
      <b style="margin-bottom: 0">{{ review.userName != undefined ? review.userName : "Anonymous Explorer" }}</b>
      <div style="display: flex">
        <div style="font-size: 16px">
          <span class="material-symbols-rounded" [ngClass]="{ checked: review.rating >= 0.5 }">star</span>
          <span class="material-symbols-rounded" [ngClass]="{ checked: review.rating >= 1.5 }">star</span>
          <span class="material-symbols-rounded" [ngClass]="{ checked: review.rating >= 2.5 }">star</span>
          <span class="material-symbols-rounded" [ngClass]="{ checked: review.rating >= 3.5 }">star</span>
          <span class="material-symbols-rounded" [ngClass]="{ checked: review.rating >= 4.5 }">star</span>
        </div>
        <span style="font-size: 12px; margin-left: 4px; color: #000a;">{{ review.date.seconds ? (review.date.seconds *
          1000 | date: 'dd/MM/yyyy') : (review.date.toDate() | date: 'dd/MM/yyyy') }}</span>
      </div>
    </div>
  </div>
  <p style="margin-top: 8px">{{ review.review }}</p>
</div>

<style>
  .material-symbols-rounded {
    font-size: inherit;
    line-height: inherit;
    vertical-align: middle;
  }
</style>