<div>
  <nav *ngIf="!router.url.includes('unlimited')" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
    class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="">
        <div style="display: flex">
          <img id="landingIcon" src="../../assets/images/alltours/logo.png" alt="AllTours logo" />
          <span id="landingText">AllTours</span>
        </div>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mb-2 mb-lg-0" style="margin-right: 0;">
          <li class="nav-item">
            <a class="nav-link" href="/cities">{{ strings.cities }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://alltours-merch.square.site">Merch</a>
          </li>
          <li class="nav-item" *ngIf="!(partnerService.isLoggedIn$ | async)">
            <a class="nav-link" href="/become-a-partner">Partnerships</a>
          </li>
          <li class="nav-item" *ngIf="(partnerService.isLoggedIn$ | async)">
            <a class="nav-link" href="/partner">Partner Dashboard</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/contact">Contact</a>
          </li>
          <li class="nav-item d-block d-lg-none" *ngIf="!(userService.isLoggedIn$ | async)">
            <a class="nav-link" data-bs-target="#appComponentLoginModal" data-bs-toggle="modal">Login</a>
          </li>
          <li class="nav-item d-block d-lg-none" *ngIf="!(userService.isLoggedIn$ | async)">
            <a class="nav-link" data-bs-target="#appComponentLoginModal" data-bs-toggle="modal">Create Account</a>
          </li>
          <li class="nav-item d-block d-lg-none"
            *ngIf="(userService.isLoggedIn$ | async) && !(partnerService.isLoggedIn$ | async)">
            <a class="nav-link" href="/user">Profile</a>
          </li>
        </ul>
        <div class="search-container" *ngIf="router.url != '/'">
          <app-search style="width: 100%;"></app-search>
        </div>
        <ul class="navbar-nav ml-auto d-none d-lg-flex" style="margin-left: auto;">
          <li class="nav-item" *ngIf="!(userService.isLoggedIn$ | async)" style="margin-right: 16px">
            <button class="loginBtn btn" data-bs-toggle="modal" data-bs-target="#appComponentLoginModal">Login</button>
          </li>
          <li class="nav-item" *ngIf="!(userService.isLoggedIn$ | async)">
            <button class="createAccountBtn btn" data-bs-toggle="modal" data-bs-target="#appComponentLoginModal">Create
              Account</button>
          </li>
          <li class="nav-item" *ngIf="(userService.isLoggedIn$ | async) && !(partnerService.isLoggedIn$ | async)">
            <a class="nav-link" href="/user">Profile</a>
          </li>
          <li class="nav-item">
            <select style="display: none; background-image: url('../../assets/flags/globe.svg')"
              class="btn languageDropDown" (change)="onLanguageChange($event.target.value)" [value]="lang">
              <option *ngFor="let langOption of languages">
                {{ langOption.name }}
              </option>
            </select>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div style="color: #000; background-color: #fff; min-height: 95vh">
    <router-outlet></router-outlet>
  </div>
</div>

<style>
  .material-symbols-rounded {
    font-size: inherit;
    line-height: inherit;
    vertical-align: middle;
  }
</style>