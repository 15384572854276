import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { setSeoText } from 'src/app/utils/utils';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent implements OnInit {
  constructor(@Inject(DOCUMENT) public _document: Document) { }

  ngOnInit() {
    setSeoText(this._document, 'AllTours - Terms and Conditions', 'AllTours terms and conditions for using the platform.');
  }
}
