import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { doc, getDoc, getFirestore } from '@angular/fire/firestore';
import { getFunctions, httpsCallableData } from '@angular/fire/functions';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  tours = [];
  loading = false;
  product = {};

  constructor(@Inject(DOCUMENT) public _document: Document, @Inject(PLATFORM_ID) public platformId: Object) { }

  ngOnInit() { }

  search(searchInput: string) {
    this.loading = true;

    const regex = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
    if (regex.test(searchInput)) {
      getDoc(doc(getFirestore(), 'guided-tours', searchInput)).then(doc => {
        if (doc.exists()) {
          this.tours.push(doc.data());
        } else {
          alert('No such document!');
        }
        this.loading = false;
      })
        .catch(error => {
          console.log('Error getting document:', error);
          this.loading = false;
          alert('Error getting document:');
        });
    } else {
      const searchToursCall = httpsCallableData(getFunctions(), 'searchToursCall');
      searchToursCall({ query: searchInput }).subscribe((result: any) => {
        this.tours = result.hits;
        this.loading = false;
      });
    }
  }

  selectTour(tour) {
    this.tours = [];
    this.loading = true;
    const createSupplierTourProductCall = httpsCallableData(getFunctions(), 'createSupplierTourProductCall');
    createSupplierTourProductCall({ tourId: tour.id }).subscribe((result: any) => {
      this.product = result;
      this.loading = false;
    });
  }

  copyToClipboard(target) {
    if (isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(target.value);
    }
  }

  downloadImages() {
    var i = 0;
    for (let image of this.product['images']) {
      i++;
      this.download(image, 'image' + i + '.jpg');
    }
  }

  download(url, filename) {
    console.log('downloading', url, filename);
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = this._document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }
}
