import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { isPlatformBrowser } from '@angular/common';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { collection, getDocs, getFirestore, query, where } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';

@Component({
  selector: 'app-tour-purchased',
  templateUrl: './tour-purchased.component.html',
  styleUrls: ['./tour-purchased.component.css', '../../../app.component.css'],
})
export class TourPurchasedComponent implements OnInit {
  tourId;
  tour;
  tourTitle = "<br/><br/>";
  language;
  purchased = false;
  showDownloadApp = false
  isIphone
  storeIcon
  storeQrCode
  loginText
  isPotentialTour = false

  constructor(private route: ActivatedRoute, private userService: UserService, @Inject(PLATFORM_ID) public platformId: Object, private auth: Auth) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.language = window.location.hostname.split('.')[0];
    }
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.tourId = this.route.snapshot.paramMap.get('tourId');
    this.isPotentialTour = this.tourId.startsWith("p-")
    this.getTour();
    this.listenForPurchase();
    if (isPlatformBrowser(this.platformId)) {
      this.isIphone = navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)
    }
    this.storeIcon = this.isIphone ? 'appstoreicon' : 'playstoreicon';
    this.storeQrCode = this.isIphone ? 'appstoreqrcode' : 'playstoreqrcode';
    this.userService.user$.subscribe(user => {
      if (user == undefined) return;
      this.createLoginText()
    })
    this.createLoginText()
  }

  getTour() {
    getDocs(query(collection(getFirestore(), this.isPotentialTour ? 'potential-tours' : 'guided-tours'), where('id', '==', this.tourId))).then(querySnapshot => {
      this.tour = querySnapshot.docs[0].data();
      this.tourTitle = this.tour.title;
    });
  }

  listenForPurchase() {
    this.userService.user$.subscribe(user => {
      if (user == undefined) return;
      if (user.purchasedTourIds.includes(this.tourId)) {
        this.purchased = true
        setTimeout(() => {
          this.showDownloadApp = true
        }, 1000);
        console.log('Purchase fulfilled, id added to user doc')
      } else {
        console.log('Purchase not yet fulfilled, id not in user doc')
      }
    });
  }

  goToApp() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isIphone) {
        logEvent(getAnalytics(), 'post_purchase_download_click', { type: 'ios' });
        window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
      } else {
        logEvent(getAnalytics(), 'post_purchase_download_click', { type: 'not-ios' });
        window.open('https://play.google.com/store/apps/details?id=app.alltours');
      }
    }
  }

  createLoginText() {
    let user = this.auth.currentUser
    if (user == null) {
      this.loginText = 'With the credentials you just used.'
      return
    }
    let providerId = user.providerData[0].providerId
    let email = user.email
    console.log('providerId', providerId)
    switch (providerId) {
      case 'google.com':
        this.loginText = `Using Google (${email})`;
        break;
      case 'apple.com':
        this.loginText = `Using Apple (${email})`;
        break;
      default:
        this.loginText = `Using your email (${email})`;
    }
  }

}
